import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { CalendarService } from 'src/app/modules/calendar/calendar.service';
import { Video } from 'src/app/modules/video/video';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AddLessonDialogComponent } from '../add-lesson/add-lesson-dialog/add-lesson-dialog.component';


import { Calendar } from '../calendar';

@Component({
  selector: 'app-add-sequence-lesson',
  templateUrl: './add-sequence-lesson.component.html',
  styleUrls: ['./add-sequence-lesson.component.scss']
})
export class AddSequenceLessonComponent implements OnInit {

  @Input()
  public planId: number;
  public calendar: Calendar;
  public saving: boolean;
  public loading: boolean;

  constructor(
    private calendarService: CalendarService,
    private alertService: AlertService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.initNewCalendar();
    this.getPlanCalendar(this.planId);
  }

  initNewCalendar() {
    this.calendar = {
      days: [
        {
          remaining: 0,
          date: '',
          day: 1,
          videos: []
        }
      ]
    };
  }

  syncCalendar() {
    if(this.calendar.days.length > 0){
      if(confirm("Você quer realmente Atualizar este Calendário?")){
        this.saving = true;
        this.calendarService.updateExpireMonthCalendarDays(this.planId, this.calendar.days)
        .subscribe(res => {
          const response = res.body as ResponseApi;
    
          if (!response.error) {
            this.calendar = response.data as Calendar;
            console.info(this.calendar);
            this.alertService.success("Plano atualizado!");
          } else {
            this.alertService.error(response.error);
          }
          this.saving = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar o cronograma. Verifique a conexão e tente novamente');
          this.saving = false;
        });
      }
    }
    
  }

  addNewDay() {
    const day = {
      day: this.calendar.days.length + 1,
      videos: [],
      remaining: 0,
      date: '',
    };
    this.calendar.days.push(day);
  
  }

  openDialog(videos: Video[], dayIndex: number) {
  
    const dialogRef = this.dialog.open(AddLessonDialogComponent, { data: { dayContent: videos, dayVideos: videos } });

    dialogRef.afterClosed().subscribe((updatedVideos: Video[]) => {
      if (updatedVideos) {
       
        this.calendar.days[dayIndex].videos = updatedVideos;
       
      }
    });
  }

  getPlanCalendar(planId: number) {
    this.calendarService.getExpireMonthPlanCalendar(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.calendar = response.data as Calendar;
       
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar o calendário. Verifique a conexão e tente novamente'));
  }

  saveChanges() {
    this.saving = true;
    this.calendarService.updateExpireMonthCalendarDays(this.planId, this.calendar.days)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Cronograma atualizado com sucesso!');
        } else {
          this.alertService.error(response.error);
        }

        this.saving = false;
      }, err => {
        this.alertService.error('Houve um erro ao salvar as alterações do plano. Verifique a conexão e tente novamente');
        this.saving = false;
      });
  }

}
