import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { IntegrationsService } from '../integrations.service';
import { Student } from '../../student/student';
import { AdminService } from '../../admin.service';
import { Subscription } from 'rxjs';
import {  StudentCode } from '../external-identifier';
import { Simulated } from 'src/app/core/models/simulated';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ExportToCSV } from "@molteni/export-csv";
import { ObjectExport, LastStudentCodes, CodeAndNoteStudents,MockDisciplines, Reports, GenerateReport, NameDiscipline, ExternalCode, Code, EditCodes} from "./interfaces";
import { GeneratedFile } from '@angular/compiler';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
 

@Component({
  selector: 'app-external-integration',
  templateUrl: './external-integration.component.html',
  styleUrls: ['./external-integration.component.scss']
})

export class ExternalIntegrationComponent implements OnInit {


  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public addExternalIntegration: FormGroup;
  public editForm: FormGroup;
  public editFormCodes: FormGroup;
  public classId: number;
  public students: Student[];
  public loading: boolean;
  public deleting: boolean;
  private admUpdate$: Subscription;
  public simulated: Simulated[];
  public disciplines: Discipline[];
  public gReport: boolean;
  public plans: StudyPlan[];
  public lastStudentCodes: LastStudentCodes;
  public show: boolean;
  public externalCode: any;
  public mockDisciplines: MockDisciplines;
  public reportsCodeAndNote :CodeAndNoteStudents;
  public editMode: boolean;
  public editCodeList: boolean;
  public listCode: LastStudentCodes;
  public erroCode:number;



  
  constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public last: LastStudentCodes,
        @Optional() public dialogRef: MatDialogRef<ExternalIntegrationComponent>,
        private dialog: MatDialog,
        private integrationsService: IntegrationsService,
        private schoolService: SchoolService,
        private alertService: AlertService,
        public userService: UserService,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        private disciplineService: DisciplineService,
        private studyPlanService: StudyPlanService,
        private exportTXT:ExportToCSV ) {  
    }
    exportToTXT = new ExportToCSV(); 

    ngOnInit() {
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());

        /* this.integrationsService.emitSaveIsDone.subscribe((data)=>{
            setTimeout(() => { 
                this.getLastSavedCodes();
            }, 1000);
            
        });  */
    }

    init(){
        this.addExternalIntegration = this.formBuilder.group({
            schoolId: ['', Validators.required],
            studentId: ['', Validators.required],
            code: ['']
        });
        this.profileInit();
        //this.show = false;

        this.editForm = this.formBuilder.group({
            code: ['', Validators.required]
        }) 
        if (this.last) { this.initializeEditMode(); }
     
    }
    profileInit() {
        if (this.userService.isAdmin()) {
          this.getSchools();
          this.getLastSavedCodes(0);
        } else if (this.userService.isGeneralCoordinator()) {
            const schoolId = this.userService.getUserSchool();

            this.getUnits(schoolId);
            this.addExternalIntegration.get('schoolId').setValue(schoolId);
            this.getLastSavedCodes(schoolId);
        } 
    }
   /*  openList() { 
        //this.show = true; 
    } */
    initializeEditMode() {
        this.editMode = true;
        this.editForm.patchValue(this.last);
        this.addExternalIntegration.patchValue(this.last);
    }
    openEditCode(){
        const editCode = this.addExternalIntegration.getRawValue() as StudentCode;
        if (this.addExternalIntegration.valid) {
            this.integrationsService.getListCodes(editCode)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.lastStudentCodes = response.data as LastStudentCodes;
                        this.show = true;
                    } else {
                        this.alertService.error(response.error);
                    }
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao buscar os códigos. Verifique a conexão e tente novamente');
                });
        }
    }
    edit(){
        this.editCodeList = true;
    }
    editCode(last: LastStudentCodes){
        this.editCodeList = false;
        const dialogRef = this.dialog.open(ExternalIntegrationComponent, {
            minWidth: '60vw',
            data: last
        });
        
        dialogRef.afterClosed().subscribe(result => {
            this.getLastSavedCodes();
        });
    }
    saveStudentCode(){
        const externalCode = this.addExternalIntegration.getRawValue() as StudentCode;
        if (this.addExternalIntegration.valid) {
            this.loading = true;
            this.integrationsService.saveStudentCode(externalCode)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.alertService.success('Código externo adicionado');
                        this.addExternalIntegration.get('code').setValue("");
                        this.getLastSavedCodes(0);
                    } else {
                        this.alertService.error(response.error);
                    }
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao cadastrar o código. Verifique a conexão e tente novamente');
                });
        } 
    }
    removeCode(id:string, schoolId:number){
        if (confirm('Deseja realmente remover o código do aluno?')) {
            this.deleting = true;
            this.integrationsService.removeCode(id, schoolId)
              .subscribe(res => {
                  const response = res.body as ResponseApi;      
                  if (!response.error) {
                      this.alertService.success('Código do aluno removido!');
                      this.addExternalIntegration.get('code').setValue("");
                      this.addExternalIntegration.get('code').setValue("");
                      this.getLastSavedCodes(0);
                      //this.adminService.updateChields();
                  } else {
                      this.alertService.error(response.error);
                  }
      
                  this.deleting = false;
              }, err => {
                  this.alertService.error('Houve um erro ao excluir o código. Falha na comunicação com a API');
                  this.deleting = false;
              });
          }
    }
    saveChanges(id: number, schoolId:number){
        const codeChange = this.editForm.getRawValue() as any;
        if (this.editForm.valid) {
            this.loading = true;
            this.integrationsService.saveChanges(id, schoolId, codeChange)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.alertService.success('Código alterado com sucesso!');
                        this.dialogRef.close();
                    } else {
                        this.alertService.error(response.error);
                    }
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao salvar o código. Verifique a conexão e tente novamente');
                });
        } 
        
    }
    getEqualCode(code:string){
        this.integrationsService.getEqualCode(code)
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.externalCode = response.data;
          } else {
            this.alertService.error(response.error);
            this.erroCode = 1;
          }
          this.loading = false;
        }, err => {
          this.alertService.error('Este código já foi usado anteriormente. Verifique a conexão e tente novamente');
          this.loading = false;
        });

    }
    getLastSavedCodes(schoolId?:number) {
        var school = schoolId;
        if (this.userService.isAdmin()) {
            school = 0
        } else if (this.userService.isGeneralCoordinator()) {
            const schoolId = this.userService.getUserSchool();
            school = schoolId;
        } 

        this.loading = true;
        this.integrationsService.getLastSavedCodes(school)
          .subscribe(res => {
            const response = res.body as ResponseApi;
    
            if (!response.error) {
                this.lastStudentCodes = response.data as LastStudentCodes;
                const displayedColumns = ['name', 'code', 'date', '#', '#'];
                const dataSource = this.lastStudentCodes;
            } else {
              this.alertService.error(response.error);
            }
    
            this.loading = false;
          }, err => {
            this.alertService.error('Houve um erro ao carregar os últimos códigos. Verifique a conexão e tente novamente');
            this.loading = false;
          });
    }
    getDisciplinesSimulated(simulatedId: number) {
        this.integrationsService.getDisciplinesSimulated(simulatedId)
          .subscribe(res => {
            const response = res.body as ResponseApi;
    
            if (!response.error) {
                this.mockDisciplines = response.data as MockDisciplines;
            } else {
              this.alertService.error(response.error);
            }    
          }, err => {
            this.alertService.error('Houve um erro ao buscar as disciplínas. Verifique a conexão e tente novamente');
          });
    }
    getSimulated(planId:number){
        this.integrationsService.getSimulated(planId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.simulated = response.data as Simulated[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao buscar os simulados. Verifique a conexão e tente novamente'));
    }
    getSchools() {
    this.schoolService.getSchools()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.schools = response.data as School[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }
    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }
    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }
    getStudents(classId: number) {
        // classId = this.userService.isGeneralCoordinator() ? this.userService.getUserSchool() : undefined;
        this.loading = true;
        this.classId = classId;
        this.integrationsService.getStudents(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.students = response.data as Student[];
                    //this.filteredStudents = [...this.students];
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }
}
