import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Redaction } from './redaction';

@Injectable({ providedIn: 'root' })
export class RedactionService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  getStudentPlans(studentId: number) {
    return this.http.post(this.API + '/essays/getStudentPlans', { studentId }, { observe: 'response' });
  }

  getStudentPlansWithRedactions(studentId: number) {
    return this.http.post(this.API + '/essays/getStudentPlansWithRedactions', { studentId }, { observe: 'response' });
  }

  getPlanThemes(planId: number, page?: number) {
    return this.http.post(this.API + '/essays/getPlanThemes', { planId, page }, { observe: 'response' });
  }

  getPlanNewThemes(planId: number, page?: number) {
    return this.http.post(this.API + '/essays/getPlanNewThemes', { planId, page }, { observe: 'response' });
  }

  getThisPlanThemes(planId: number) {
    return this.http.post(this.API + '/essays/getThisPlanThemes', { planId}, { observe: 'response' });
  }

  getPlanSelectedTheme(planId: number, themeId:number) {
    return this.http.post(this.API + '/essays/getPlanSelectedTheme', { planId, themeId }, { observe: 'response' });
  }

  getPlanFreeThemes(planId: number) {
    return this.http.post(this.API + '/essays/getPlanFreeThemes', { planId }, { observe: 'response' });
  }

  getTextMotivationalOne(textId: number) {
    return this.http.post(this.API + '/essays/getTextMotivationalOne', { textId }, { observe: 'response' });
  }


  sendRedaction(redaction: any) {
    return this.http.post(this.API + '/essays/sendRedaction', redaction, { observe: 'response' });
  }

  getSentRedactions(studentId: number) {
    return this.http.post(this.API + '/essays/getSentRedactions', { studentId }, { observe: 'response' });
  }

  delSentRedactions(studentId: number, redactionId: number) {
    return this.http.post(this.API + '/essays/delSentRedactions', { studentId, redactionId }, { observe: 'response' });
  }

  getRedactionTypes() {
    return this.http.post(this.API + '/essays/getRedactionTypes', {}, { observe: 'response' });
  }
  checkEssays(studentId: number) {
    return this.http.post(this.API + '/essays/checkEssays', { studentId }, { observe: 'response' });
  }

  checkEssaysAvailable(studentId: number) {
    return this.http.post(this.API + '/essays/checkEssaysAvailable', { studentId }, { observe: 'response' });
  }
}
