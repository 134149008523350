<div class="page-container p-pg page-container-mobile discipline">
  <div class="row m-0">
    <div class="col-md-6 mt-2" [ngClass]="{'col-12': layoutService.isMobile}">
      <ng-container>
        <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
          <span class="page-title">REDAÇÕES</span>
          <div class="mt-3 description-title">
            <h4 *ngIf="!layoutService.isMobile">Envie sua redação</h4>
            <h6 class="mb-0" *ngIf="layoutService.isMobile">Envie sua redação</h6>
            <p [ngClass]="{'fs-13 pb-0': layoutService.isMobile}">Selecione o plano que você deseja estudar</p>
            <!-- Restantes: <b class="text-color-primary">{{plan.redactions - plan.sendRedactions}}</b> -->
          </div>
          <!--    <div class="lesson-back">
                        <button routerLink="../send" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div> -->
        </div>
      </ng-container>
    </div>
    <div class="col-md-6 mt-2 align-self-self-end" [ngClass]="{'col-12': layoutService.isMobile}">
      <ng-container>
        <div *ngIf="showMessageEdu && isInPlansEdu"
          class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
          <strong>Exclusivo para assinantes Diversidades Edu!</strong>
        </div>
        <div class="mb-1 width-100 open-redaction" [ngClass]="{'text-right mb-3': !layoutService.isMobile}">
          <button class="button-send btn-large" nbTooltip="Folha de redação" (click)="openLink()" mat-raised-button
            color="accent">
            Folha de redação
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-5 mt-2">
      <!-- empty plan -->
      <div class="plan-not-selected" *ngIf="!plans">
        <h4>Nenhum plano</h4>
        <br />
        <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
      </div>
      <ng-container *ngIf="!planSelected">
        <!-- SELECT PLAN LISTA DESKTOP -->
        <div class="plan-list" *ngIf="!loadingPlans && !layoutService.isMobile">
          <mat-list *ngIf="!layoutService.isMobile">
            <mat-list-item *ngFor="let plan of plans" (click)="selectPlan(plan.id)">
              <div class="plan-item mb-3 hvr-grow" matRipple
                [ngClass]="{'bg-color-accent color-white': selectedPlanId == plan?.id}">
                <div class="plan-icon">
                  <mat-icon [color]="(selectedPlanId == plan?.id) ? null : 'accent'">class</mat-icon>
                </div>
                <div class="plan-info">
                  <strong class="plan-name">{{ plan?.title }}</strong>
                  <!-- <span class="plan-expire"> Válido até {{ plan?.expireDate | date:'dd/MM/y'}}</span> -->
                </div>
              </div>
            </mat-list-item>
            <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
          </mat-list>
        </div>
        <!-- SELECT PLAN MOBILE -->
        <div *ngIf="!loadingPlans && layoutService.isMobile" id="filter">
          <ng-container>
            <mat-form-field class="width-100 m-0 p-0 form-field" appearance="fill">
              <mat-select class="p-0 m-0 form-select" [(ngModel)]="firstPlanId"
                (selectionChange)="selectPlan($event.value)">
                <mat-option disabled [value]="0">
                  --- Selecione o plano ---
                </mat-option>
                <mat-option [value]="plan.id" *ngFor="let plan of plans">
                  {{plan.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="col-md-7 mt-2">
      <div class="lesson-back" *ngIf="!layoutService.isMobile && (themeSelected || selectedTheme)">
        <button (click)="themeSelected = null; selectedTheme = null ; selectedThemeId = null" color="accent" mat-button
          class="mt-1 mb-1 hvr-grow">
          <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
        </button>
      </div>
      <!-- Select Tema Desktop -->
      <ng-container *ngIf="!selectedTheme && selectedPlanId && !layoutService.isMobile && !noShowTheme">
        <div class="row">
          <div>
            <mat-form-field class="width-100">
              <mat-select placeholder="Selecione o tema"
                (selectionChange)="getPlanSelectedTheme($event.value)">
                <mat-option *ngFor="let theme of typeTheme" [value]="theme.type">
                  {{theme.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="plan-list">
          <mat-list>
            <!-- <mat-list-item *ngFor="let theme of themeFree">
                            <div class="plan-item mb-3 hvr-grow" matRipple [ngClass]="{'bg-color-accent': selectedTheme?.id == theme?.id}">
                                <div class="plan-icon">
                                    <mat-icon [color]="(themeSelected?.name == theme?.name) ? null : 'accent'">class</mat-icon>
                                </div>
                                <div class="plan-name d-flex">
                                    <a class="c-pointer" (click)="openThemeText(theme)" target="_blank">
                                        <mat-icon class="mt-2" [color]="(themeSelected?.name == theme?.name) ? null : 'accent'">visibility</mat-icon>
                                    </a>
                                    <button class="hvr-grow d-none" (click)="selectTheme($event.value, theme)" nbButton outline size="tiny" status="primary"></button>
                                    <strong style="margin-top: 10px;" class="ml-2" (click)="selectTheme($event.value, theme)">[{{theme.name}}] {{theme.typeName}}</strong>
                                </div>
                            </div>
                            </mat-list-item> -->
            <mat-list-item
              *ngFor="let theme of themes | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems: totalRows}">


              <!-- LIST TOTAL THEME -->
              <div class="plan-item mb-3 hvr-grow" matRipple
                [ngClass]="{'bg-color-accent': selectedTheme?.id == theme?.id}">
                <div class="plan-icon">
                  <a class="c-pointer ml-2" (click)="openThemeText(theme)" target="_blank">
                    <mat-icon style="position:relative;top:-5px;left:-5px;" class="mt-2"
                      matTooltipClass="custom-tooltip" matTooltip="Acessar texto motivador"
                      [color]="(themeSelected?.name == theme?.name) ? null : 'accent'">
                      visibility
                    </mat-icon>
                  </a>
                  <span><mat-icon class="ml-3"
                      [color]="(themeSelected?.name == theme?.name) ? null : 'accent'">class</mat-icon></span>
                </div>

                <div class="ml-5 plan-name d-flex" (click)="selectTheme(theme)">
                  <button class="hvr-grow d-none" nbButton outline size="tiny" status="primary"></button>
                  <strong style="margin-top: 10px;" class="ml-2" matTooltipClass="custom-tooltip"
                    matTooltip="Acessar tema" (click)="selectTheme(theme)">[{{theme.name}}] {{theme.typeName}}</strong>
                </div>
              </div>
            </mat-list-item>
            <!-- THEME MOTIVACIONAL
                        <nb-list-item href="#" *ngFor="let theme of themes">
                            <div class="row">
                                <div class="col-6">
                                    <a [href]="" (click)="openThemeText(theme)" target="_blank">[{{theme.name}}] {{theme.typeName}}</a>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="hvr-grow" (click)="selectTheme(theme.motivacionalText)" nbButton outline size="tiny" status="primary">Selecionar</button>
                                </div>
                            </div>
                        </nb-list-item>-->
            <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
          </mat-list>
        </div>
        <pagination-controls (pageChange)="paginate($event)" responsive="true" class="text-center pagination-themes"
          previousLabel="Anterior" nextLabel='Próxima'></pagination-controls>
        <br><br><br><br>
      </ng-container>
      <!-- Select Tema MOBILE -->
      <ng-container *ngIf="layoutService.isMobile && !noShowTheme">
        <!-- Selecionar tema Mobile -->
        <div *ngIf="selectedPlanId" id="filter">
          <mat-form-field class="width-100 m-0 p-0 form-field" appearance="fill">
            <mat-select class="p-0 m-0 form-select" [(ngModel)]="firstTheme"
              (selectionChange)="selectTheme($event.value)">
              <mat-option disabled [value]="0">
                --- Selecione o tema ---
              </mat-option>
              <mat-option [value]="theme" *ngFor="let theme of themes">
                [{{theme.name.substring(0,40)}}] {{theme.typeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="selectedPlanId && showMotivationTheme && layoutService.isMobile" class="plan-name text-center mt-2">
          <a class="fs-13 font-weight-600 c-pointer" (click)="openThemeText(showMotivationTheme)" target="_blank"
            matTooltipClass="custom-tooltip" matTooltip="Acessar texto motivador">
            <mat-icon class="position-icon-motivation"
              [color]="(themeSelected?.name == theme?.name) ? null : 'accent'">visibility</mat-icon>
            <span style="position:relative; top:-5px; left:10px;" class="text-motivation">Acessar o texto
              motivador</span>

          </a>
          <p style="position:relative; top:-5px; left:10px;" class="text-motivation">{{showMotivationTheme.name}}</p>
          <!--    <a class="c-pointer" (click)="openThemeText(theme)" target="_blank">
                                <mat-icon class="mt-2" matTooltipClass="custom-tooltip"  matTooltip="Acessar texto motivador" [color]="(themeSelected?.name == theme?.name) ? null : 'accent'">visibility</mat-icon>
                                Acessar o texto motivador
                            </a> -->
        </div>
      </ng-container>

      <div class="text-center" *ngIf="endPlans || !themeSelected && !plans">
        <h4>Você não tem redação para enviar!</h4>
        <br />
        <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
      </div>
      <!-- select a plan -->
      <div *ngIf="!planSelected && !layoutService.isMobile && noShow || !layoutService.isMobile && noShowTheme"
        class="plan-not-selected" [ngClass]="{'d-none': themeSelected || selectedTheme}">
        <h4>Selecione um plano</h4>
        <br />
        <p>Escolha um plano para carregar os temas</p>
      </div>
      <ng-container *ngIf="layoutService.isMobile || selectedThemeId && selectedPlanId">
        <div [nbSpinner]="sending" nbSpinnerStatus="primary">
          <div class="center" *ngIf="isFreeTheme()">
            <mat-form-field appearance="outline" class="width-100">
              <mat-label>Tema</mat-label>
              <input matInput placeholder="Digite o tema aqui" [(ngModel)]="freeTheme">
            </mat-form-field>
          </div>
          <ng-container *ngIf="!imgSrc && !redactionText">
            <label for="upload" class="width-100">
              <div class="img-redaction center mt-2" [ngClass]="{'height-250 padding-60': !layoutService.isMobile}">
                <span class="send-redaction-icon">
                  <mat-icon color="accent">upload</mat-icon>
                </span>
                <div class="mb-3 width-100">
                  <span class="text-submit-redaction">Envie sua redação</span>
                  <p class="attention-redaction">Atenção! Envie apenas arquivos JPG, JPEG ou PNG.</p>
                </div>
                <input style="display: none;" [disabled]="!selectedTheme"
                  (change)="handleFileInput($event.target.files);" id="upload" accept="image/*" fullWidth
                  status="primary" type="file" nbInput placeholder="Anexar Arquivo">
              </div>
            </label>
          </ng-container>
          <div class="text-center mt-2">
            <div *ngIf="imgSrc && !redactionText" class="text-center">
              <img class="redaction-preview mt-1" [ngClass]="{'width-50': !layoutService.isMobile}" [src]="fileTemp" />
            </div>
            <div class="text-center redactionText" *ngIf="!imgSrc && redactionText">
              <span class="mt-1">{{redactionText}}</span>
            </div>
            <div *ngIf="!imgSrc && !redactionText" [ngClass]="{'my-disable': !selectedTheme}">
              <span class="fs-13">Ou se preferir utilize nosso </span>
              <a (click)="openWriteRedaction()" class="fs-13 text-color-accent font-weight-600 c-pointer">editor de redação
              </a>
            </div>

          </div>
          <div class="pt-10 text-center"
            [ngClass]="{'row mt-4': !layoutService.isMobile && showButton, 'width-100 mt-3': !layoutService.isMobile && !showButton}">
            <div class="pt-10 pb-10"
              [ngClass]="{'col-6': !layoutService.isMobile && showButton, 'col-12 p-0': layoutService.isMobile, 'd-none': layoutService.isMobile && !showButton}">
              <button class="button-comment small-btn back-error-light width-100 cancel-redaction" *ngIf="showButton"
                (click)="cancelRedaction()" nbTooltip="Cancelar Arquivo" mat-raised-button color="error">
                Cancelar
              </button>
            </div>
            <div class="pt-10 pb-10"
              [ngClass]="{'col-6': !layoutService.isMobile && showButton, 'col-12': !layoutService.isMobile && !showButton, 'col-12 pr-0 pl-0': layoutService.isMobile && !showButton}">
              <button class="button-comment small-btn" [disabled]="!showButton" nbTooltip="Enviar Arquivo"
                (click)="sendRedaction()"
                [ngClass]="{'width-60': !layoutService.isMobile && !showButton, ' width-100': !layoutService.isMobile && showButton}"
                mat-raised-button color="accent">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="hidden" *ngIf="!layoutService.isMobile">
        <button class="button-send" routerLink="../send" mat-raised-button color="accent" class="small-btn">
          Nova redação
        </button>
      </div>
      <ng-container>
        <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
      </ng-container>
    </div>
  </div>
</div>
