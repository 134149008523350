import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { UserService } from '../../user/user.service';
import { AdminService } from '../admin.service';
import { SchoolSelect } from '../school/school';
import { SchoolService } from '../school/school.service';
import { Certificate } from './certificate';
import { CertificateService } from './certificate.service';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  certificates: Certificate[];
  dataSource: MatTableDataSource<Certificate>;
  displayedColumns: string[];
  schools: SchoolSelect[];
  public loading: boolean;
  public lottieOptions: AnimationOptions;

  constructor(
    private certificateService: CertificateService,
    public layoutService: LayoutService,
    private userService: UserService,
    private alertService: AlertService,
    private adminService: AdminService,
    private schoolService: SchoolService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.displayedColumns = [ 'certificate', 'edit'];
    this.checkWhoItIs();
  }

  checkWhoItIs(){
    if(this.userService.isAdmin()){
      this.getCertificate(0);
    }else{
      this.getCertificate(this.userService.getUserSchool());
    }
  }

  create(){
    this.router.navigate(['admin/certificates/create']);
  }

  getCertificate(schoolId?: number) {
    this.loading = true;
    this.certificateService.getAllCertificate(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.certificates = response.data as Certificate[];
          this.dataSource = new MatTableDataSource(this.certificates);
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar os certificados. Verifique a conexão e tente novamente'));
  }

  edit(certificate: Certificate){
    const dialogRef = this.dialog.open(EditComponent, {
      data: certificate,
      maxHeight: 500
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        if(this.userService.isAdmin()){
          this.getCertificate(0);
        }else{
          this.getCertificate(this.userService.getUserSchool());
        }
      }
    });
  }

  delete(id:number){
    if(confirm("Deseja realmente excluir?")){
      this.certificateService.delete(id)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.certificates =  this.certificates.filter(c =>{return c.id != id; })
          this.adminService.updateChields();
          this.checkWhoItIs();
          this.alertService.success("Certificado removido com sucesso!");
        } else this.alertService.error(response.error);
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao deletar o Certificado. Verifique a conexão e tente novamente');
        this.loading = false;
      });
    }
  }

  emptyLottieOptionsConfig() {
    this.lottieOptions = {
        path: 'assets/lotties/empty.json',
        loop: true,
        autoplay: true
    };
  }
}
