<div *ngIf="!showform && !editMode && !showList && choiceOption">
    <div class="row mt-2">
        <div class="col-12"><h6 class="text-option">Qual o tipo de notificação você deseja criar?</h6></div>
    </div>
    <div class="row mb-2">
        <div class="col-4 mt-2 mb-2" (click)="choiceForm('formBySchool');" *ngIf="userService.isAdmin()">
            <div class="row m-0 option option-bg-color-accent-200-hover school text-center">
                <div class="col-12">
                    <mat-icon class="material-symbols-outlined home" color="accent">home</mat-icon>
                </div>
                <div class="col-12">
                    <span class="text-center description">Escola</span>
                </div>
            </div>
        </div>
        <div class="col-4 mt-2 mb-2" (click)="choiceForm('formPerPlan');">
            <div class="row m-0 option option-bg-color-accent-200-hover plan text-center ">
                <div class="col-12">
                    <!-- <span><img class="book_plan" src="assets/icons/book_plan.svg"></span> -->
                    <mat-icon class="material-symbols-outlined home" color="accent">menu_book</mat-icon>
                </div>
                <div class="col-12">
                    <span class="text-center description">Plano</span>
                </div>
            </div>
        </div>
        <div class="col-4 mt-2 mb-2" (click)="choiceForm('formPerUnit');"  *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
            <div class="row m-0 option option-bg-color-accent-200-hover plan text-center ">
                <div class="col-12">
                    <mat-icon class="material-symbols-outlined home" color="accent">apartment</mat-icon>
                </div>
                <div class="col-12">
                    <span class="text-center description">Unidade</span>
                </div>
            </div>
        </div>
        <div class="col-4 mt-2" (click)="choiceForm('formByClass');" >
            <div class="row m-0 option option-bg-color-accent-200-hover school text-center">
                <div class="col-12">
                    <mat-icon class="material-symbols-outlined home" color="accent">group</mat-icon>
                </div>
                <div class="col-12">
                    <span class="text-center description">Turma</span>
                </div>
            </div>
        </div>
        <div class="col-4 mt-2 mb-2" (click)="choiceForm('formPerUser');">
            <div class="row m-0 option option-bg-color-accent-200-hover plan text-center ">
                <div class="col-12">
                    <mat-icon class="material-symbols-outlined home" color="accent">person</mat-icon>
                </div>
                <div class="col-12">
                    <span class="text-center description">Usuário</span>
                </div>
            </div>
        </div>
        <div class="col-4 mt-2 mb-2" (click)="choiceForm('formByProfile');">
            <div class="row m-0 option option-bg-color-accent-200-hover plan text-center ">
                <div class="col-12">
                    <mat-icon class="material-symbols-outlined home" color="accent">person_2</mat-icon>
                </div>
                <div class="col-12">
                    <span class="text-center description">Perfil</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-6 width-100">
            <button (click)="openListBeforeCreated()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver notificações cadastradas</button>
        </div>
    </div>
</div>
<div *ngIf="showform">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-9">
                <mat-form-field class="width-100">
                    <mat-label>Título</mat-label>
                    <input matInput placeholder="Título" formControlName="title">
                </mat-form-field>
            </div>
            <div class="col-3" >
                <mat-form-field class="width-100">
                    <mat-select placeholder="Tipo" formControlName="type"  (selectionChange)="getTypesNotifications($event.value)">
                        <mat-option *ngFor="let type of types" [value]="type.name">{{type.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-4" *ngIf="!hidden && !formPerUser">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Perfil"
                            formControlName="profiles" matTooltipClass="custom-tooltip" matTooltip="Escolha o(s) perfis">
                        <mat-option value="1" (click)="addProfilesChip({id:1, name:'Aluno'})">
                            <span title="Aluno">Aluno</span>
                        </mat-option>
                        <mat-option name="Coordenador Unidade" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" value="2"  (click)="addProfilesChip({id:2, name:'Coordenador Unidade'})">
                            <span title="Coordenador Unidade">Coordenador Unidade</span>
                        </mat-option>
                        <mat-option *ngIf="userService.isAdmin()" value="3" (click)="addProfilesChip({id:3, name:'Coordenador Geral'})">
                            <span title="Coordenador Geral">Coordenador Geral</span>
                        </mat-option>
                        <mat-option *ngIf="userService.isAdmin()" value="4" (click)="addProfilesChip({id:4, name:'Admin'})">
                            <span title="Admin">Admin</span>
                        </mat-option>
                        <mat-option *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator()" value="5" (click)="addProfilesChip({id:5, name:'Professor'})">
                            <span title="Professor">Professor</span>
                        </mat-option>
                        <mat-option *ngIf="userService.isAdmin()" value="6" (click)="addProfilesChip({id:6, name:'Pai'})">
                          <span title="Pai">Pai</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-8" *ngIf="!hidden">
                <div *ngIf="selectedProfiles?.length > 0">
                    <mat-label class="mb-2">Perfis selecionados</mat-label>
                    <mat-chip-list *ngIf="selectedProfiles">
                        <mat-chip *ngFor="let profileChip of selectedProfiles" selectable="true" removable="true" (removed)="removeProfileChip(profileChip)">
                            {{profileChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="userService.isAdmin() && formBySchool || userService.isAdmin() && formByProfile">
            <div class="col-4">
                <mat-form-field class="width-100">
                    <mat-select formControlName="schools" placeholder="Escolha a(s) escola(s)" matTooltipClass="custom-tooltip" matTooltip="Escolha a(s) escola(s)*">
                        <mat-option (click)="addSchoolChip(school)" *ngFor="let school of selectSchools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-8" *ngIf="selectedSchools?.length > 0">
                <mat-label class="mb-2">Escolas selecionadas</mat-label>
                <mat-chip-list *ngIf="selectedSchools">
                    <mat-chip *ngFor="let schoolChip of selectedSchools" selectable="true" removable="true" (removed)="removeSchoolChip(schoolChip)">
                        {{schoolChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row" *ngIf="formPerPlan">
            <div class="col-4" >
                <button class="m-3 bg-color-accent-300 border-accent" (click)="openSelectPlans()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Associar ao plano</button>
            </div>
            <div class="col">
                <div *ngIf="selectedPlans?.length > 0">
                    <mat-label class="mb-2">Planos selecionados</mat-label>
                    <mat-chip-list *ngIf="selectedPlans">
                        <mat-chip *ngFor="let planChip of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(planChip)">
                            {{planChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="formPerUnit">
            <div class="col-4" >
                <button class="m-3 bg-color-accent-300 border-accent" (click)="openSelectUnits()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Adicionar unidade</button>
            </div>
            <div class="col-8">
                <div *ngIf="selectedUnits?.length > 0">
                    <mat-label class="mb-2">Unidade(s) selecionada(s)</mat-label>
                    <mat-chip-list *ngIf="selectedUnits">
                        <mat-chip *ngFor="let unitChip of selectedUnits" selectable="true" removable="true" (removed)="removeUnitChip(unitChip)">
                            {{unitChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="formByClass">
            <div class="col-4" >
                <button class="m-3 bg-color-accent-300 border-accent" (click)="openSelectClasses()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Adicionar turma</button>
            </div>
            <div class="col">
                <div *ngIf="selectedClasses?.length > 0">
                    <mat-label class="mb-2">Turma(s) selecionada(s)</mat-label>
                    <mat-chip-list *ngIf="selectedClasses">
                        <mat-chip *ngFor="let classChip of selectedClasses" selectable="true" removable="true" (removed)="removeClassChip(classChip)">
                            {{classChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="formPerUser">
            <div class="col-12">
                <mat-form-field class=" width-100">
                    <input #filterNameRef matInput placeholder="Buscar por nome">
                    <mat-icon class="c-pointer" matSuffix (click)="getUsersByName(filterNameRef.value)">search</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class=" width-100">
                    <input #filterEmailRef matInput placeholder="Buscar por e-mail">
                    <mat-icon class="c-pointer" matSuffix (click)="getUsersByEmail(filterEmailRef.value)">search</mat-icon>
                </mat-form-field>
            </div>

            <ng-container *ngIf="filteredUsers?.length > 0">
                <div class="col-12 mt-3" *ngFor="let user of filteredUsers">
                    <div class="row">
                        <div class="col-10">
                            {{user.name}}
                        </div>
                        <div class="col-2 text-right">
                            <button (click)="addUser(user)" nbButton size="tiny" outline status="primary">+</button>
                        </div>
                    </div>
                </div>
            </ng-container>



            <div class="col-12 mt-3" *ngIf="selectedUsers?.length > 0">
                <mat-label class="mb-2">Usuários selecionados</mat-label>
                <mat-chip-list *ngIf="selectedUsers">
                    <mat-chip *ngFor="let userChip of selectedUsers" selectable="true" removable="true" (removed)="removeUserChip(userChip)">
                        {{userChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row m-0 mt-4" *ngIf="!hidden">
            <ckeditor [editor]="questionEditor" [config]="editorConfig" formControlName="text" class="width-100 center" id="editor-notification"></ckeditor>
        </div>
        <div class="row m-0 mt-3" *ngIf="!hidden">
            <div class="col-12 mb-3">
                <div><span class="mb-3">Selecione o tipo de link (opcional):</span></div>
                <mat-radio-group placeholder="Selecione o tipo de link (opcional)" formControlName="optionLink" (change)="checkChoice($event.value)">
                    <mat-radio-button class="radio-item mr-3" value="nenhum"> Nenhum </mat-radio-button>
                    <mat-radio-button class="radio-item mr-3" value="image"> Imagem </mat-radio-button>
                    <mat-radio-button class="radio-item ml-3 mr-3" value="video"> Vídeo </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" *ngIf="showLink">
                <mat-form-field class="width-100" >
                    <mat-icon matPrefix class="ml-2" *ngIf="optionLink == 'video'" matTooltipClass="custom-tooltip" matTooltip="Para vídeo do Youtube colocar link completo ou para vídeo do Vimeo colocar apenas código.">
                        help
                    </mat-icon>
                    <mat-label [ngClass]="{'ml-4': optionLink == 'video'}">Link</mat-label>
                    <input matInput placeholder="Link" formControlName="link">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Início</mat-label>
                    <input matInput placeholder="Início" formControlName="init" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Término</mat-label>
                    <input matInput placeholder="Término" formControlName="end" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
        </div>
    </form>
    <!-- <div class="row mt-2">
        <div class="col-12 text-right lesson-back">
            <button (click)="preview()" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                <mat-icon>visibility</mat-icon> <span class="back"> Preview notificação</span>
            </button>
        </div>
    </div> -->
    <div class="row mt-2">
        <div class="col">
            <button (click)="backButton()" nbButton size="small" status="primary" outline class="text-color-primary border-primary-thin">Voltar</button>
        </div>
        <div  class="col text-right">
            <button (click)="create()" nbButton size="small" status="primary" nbSpinnerStatus="warning" class="right bg-color-primary border-primary">Cadastrar</button>
        </div>
    </div>
</div>
<div *ngIf="showList" class="">

     <!-- Escola - Unidade - Turma -->
    <div class="row">
        <div class="col-4" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Escola" [(ngModel)]="selectedSchoolId"
                    (selectionChange)="getAllNotificationsCreatedBySchool($event.value); this.getUnitSelection($event.value); getPlansBySchool($event.value)">
                    <mat-option *ngFor="let school of selectSchools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4"
          *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()"
          [ngClass]="{'p-0': userService.isAdmin(), 'col-6': !userService.isAdmin()}">
              <mat-form-field class="width-100">
                  <mat-select placeholder="Unidade" [(ngModel)]="filterUnitId"  (selectionChange)="getNotificationsCreatedToUnit($event.value); this.getClasses($event.value);" >
                      <mat-option *ngFor="let unit of selectUnits" [value]="unit.id">
                          {{unit.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
        </div>
        <div class="col-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator() ">
          <mat-form-field class="width-100">
            <mat-select placeholder="Turma" [(ngModel)]="filterClassId" (selectionChange)="getNotificationsCreatedToClass($event.value); ">
                <mat-option *ngFor="let class of classes" [value]="class.id">
                    {{class.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        </div>
    </div>


     <!-- Plano - Perfil - Tipo -->
    <div class="row">

      <div class="col-4">
          <mat-form-field class="width-100">
              <mat-select placeholder="Plano" [(ngModel)]="filterPlanId" (selectionChange)="getAllNotificationsCreatedByPlan($event.value)">
                  <mat-option *ngFor="let plan of selectPlans" [value]="plan.id">
                      {{plan.name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col-4" *ngIf="userService.isAdmin()" [ngClass]="{'p-0': userService.isAdmin(), 'col-6': !userService.isAdmin()}">
          <mat-form-field class="width-100">
              <mat-select placeholder="Perfil" [(ngModel)]="filterProfile" (selectionChange)="getNotificationsByProfile($event.value)">
                  <mat-option value="1">Aluno</mat-option>
                  <mat-option value="2">Coordenador Unidade</mat-option>
                  <mat-option value="3">Coordenador Geral</mat-option>
                  <mat-option value="4">Admin</mat-option>
                  <mat-option value="5">Professor</mat-option>
                  <mat-option value="6" *ngIf="userService.isAdmin()">Pai</mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col-4" *ngIf="userService.isAdmin()">
          <mat-form-field class="width-100">
              <mat-select placeholder="Tipo" [(ngModel)]="filterType"  (selectionChange)="getNotificationsByType($event.value)">
                  <mat-option *ngFor="let type of types" [value]="type.name">{{type.name}}</mat-option>
              </mat-select>
          </mat-form-field>
      </div>
    </div>



    <!-- Buscar por título -->
    <div class="row">
        <div class="col-12">
            <mat-form-field class="width-100" appearance="outline" class="mt-3" ngDefaultControl>
                <input matInput placeholder="Buscar por título" class="aling-right" [(ngModel)]="filterNotify">
                <mat-icon matSuffix class="pointer" (click)="getNotifyByTitle(filterNotify)">search</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <nb-list *ngIf="!loading" class="width-100 mt-3 center">
        <nb-list-item *ngFor="let notification of notify">
            <div class="row">
                <div class="col-5 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    {{notification.title}}
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span class="date">Início <i>{{notification.init}}</i><br></span>
                    <span class="date">Fim <i>{{notification.end}}</i></span>
                </div>
                <div class="col-3 col-sm-2 col-md-3 col-lg-3 col-xl-3 text-right">
                    <div class="d-flex">
                        <button (click)="edit(notification); editMode = true" nbButton size="tiny" outline status="primary">
                            <nb-icon icon="edit"></nb-icon>
                        </button>
                        <button size="tiny" (click)="remove(notification.id)" status="danger" nbButton ghost>
                            <nb-icon icon="trash"></nb-icon>
                        </button>
                    </div>
                </div>
            </div>
        </nb-list-item>
        <nb-list-item *ngIf="!notify">
            <div class="row">
                <div class="col-12">
                    Nenhum resultado!
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <mat-spinner class="mt-5 center" *ngIf="loading"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="backButton()" class="right" nbButton size="small" status="primary">Voltar</button>
    </div>
</div>
