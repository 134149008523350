import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AnimationOptions } from 'ngx-lottie';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from '../../user/user.service';
import { TaskStudentService } from '../task-student.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

interface Task {
  taskId: number;
  classId: number;
  taskName: string;
  taskDate: string;
  dateSent: string;
  status: string;
  studentName: string;
  taskDescription: string;
  taskLink: string;
  feedback: string;
  score: number;
  taskDoneLink?: string;
  taskDoneText?: string;
  filesend?:string;
}

@Component({
  selector: 'app-sent-tasks',
  templateUrl: './sent-tasks.component.html',
  styleUrls: ['./sent-tasks.component.scss']
})
export class SentTasksComponent implements OnInit {

  public tasks: Task[];
  public status: any;
  public loading: boolean;
  public correction: any;
  public editMode: boolean;

  public channelId: number;

  public editTask: any;
  public taskTextParagraphs: string[];
  public isTaskAfile: boolean = false;
  public displayedColumns: string[];
  public taskDataSource: MatTableDataSource<Task>;
  public lottieOptions: AnimationOptions;
  public size: number;
  public pageIndex = 0;
  public totalRows: number;
  public totalRowsSize: Task[];
  public embedcodeimage : string | SafeUrl
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public task: Task,
    @Optional() public dialogRef: MatDialogRef<SentTasksComponent>,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public taskStudentService: TaskStudentService,
    private alertService: AlertService,
    private router: Router,
    public userService: UserService,
    public layoutService: LayoutService,
    private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.getChannelId();
    this.emptyTasksLottieOptionsConfig();

    if(!this.layoutService.isMobile) this.displayedColumns = ['name', 'teacher', 'score', 'date', 'link',];
    if(this.layoutService.isMobile) this.displayedColumns = [ 'name', 'link'];

    this.taskStudentService.emitSentTask.subscribe((data) => {
      setTimeout(() => {
        this.getAllTaskSentStudent();
      }, 2000);

    });

    if (this.task) { this.initializeEditMode(); }
  }

  getChannelId(): void {
    this.channelId = Number(this.route.snapshot.paramMap.get('channelId'));
    if(this.channelId && this.channelId != undefined){
      this.getChannelTasksByClass(this.channelId);
    }else{
      this.getAllTaskSentStudent();
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const  urlParsed = event.urlAfterRedirects.split("/");
        const name = (urlParsed[2] == 'channel') ? 'channel' : null;
        const channel = (urlParsed && urlParsed[3])? Number(urlParsed[3]) : 0 ;
        if(urlParsed[1] == 'task-student'){
          if(channel > 0  && name == 'channel'){
            this.channelId = channel;
            this.getChannelTasksByClass(this.channelId);
          }else{
            this.getAllTaskSentStudent();
          }
        }
      }
    });
  }

  paginate(event: any) {
    this.pageIndex=event;
    this.totalRowsSize = this.tasks.slice(event * this.size - this.size, event * this.size);
    this.totalRows = this.totalRowsSize.length;
  }

  getChannelTasksByClass(channelId: number){
    this.loading = true;
    this.taskStudentService.getChannelTasksByClass(channelId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.tasks = response.data as Task[];
          this.taskDataSource = new MatTableDataSource([...this.tasks]);
        } else {
          this.taskDataSource = new MatTableDataSource(null);
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar as tarefas do canal. Verifique a conexão e tente novamente'));
  }

  getAllTaskSentStudent() {
    this.loading = true;
    const studentId = this.userService.getUserId();

    this.taskStudentService.getAllTaskSentStudent(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.tasks = response.data as Task[];
          this.taskDataSource = new MatTableDataSource([...this.tasks]);
        } else {
          this.taskDataSource = new MatTableDataSource(null);
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente'));
  }

  getStudentCorrection(task: any) {
    this.loading = true;
    const studentId = this.userService.getUserId();
    this.taskStudentService.getStudentCorrection(task.id, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.tasks = response.data as any;
          this.loading = false;

        } else {
          this.alertService.warning(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar a correção. Verifique a conexão e tente novamente'));
  }

  initializeEditMode() {
    this.editMode = true;

    this.isTaskAfile = (this.task.taskDoneLink && this.task.taskDoneLink != "") ?  true  :  false;

   if(this.task.filesend){
    this.embedcodeimage =  this.sanitizer.bypassSecurityTrustResourceUrl(
      this.task.filesend as string
  );
   }

    this.taskTextParagraphs = this.task.taskDoneText.split("\n");
  }

  getCorrection(task: any) {
    this.editMode = true;
    this.editTask = task;
  }

  emptyTasksLottieOptionsConfig() {
    this.lottieOptions = {
      path: 'assets/lotties/empty.json',
      loop: true,
      autoplay: true
    };
  }

  openTask(task: Task) {
    this.dialog.open(SentTasksComponent, {
      minWidth: '60vw',
      minHeight: '80vh',
      data: task,
      panelClass: 'task-dialog-bg'
    });
  }

  openFile(url: string) {
    window.open(url, '_blank');
  }

  openImage(){

    window.open(this.task.filesend);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
