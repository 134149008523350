// tslint:disable: no-unused-expression
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ExerciseService } from '../../admin/exercise/exercise.service';
import { UserService } from '../../user/user.service';
import { Mock } from '../mock';
import { MockQuestion } from '../mock-question';
import { MockService } from '../mock.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { ExerciseEmbed } from '../exerciseembed';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-mock-reply',
  templateUrl: './mock-reply.component.html',
  styleUrls: ['./mock-reply.component.scss']
})
export class MockReplyComponent implements OnInit, OnChanges {

  @Input() mock: Mock;
  @Input() finalize: boolean;
  @Input() timeLeft: number;
  @Output() startTimer = new EventEmitter();
  @Output() clearMock = new EventEmitter();

  public questions: MockQuestion[];
  public loading: boolean;
  public question: MockQuestion;
  public questionsResponse: string[];
  public questionsSaved: boolean[];
  public questionsCorrect: { correct: string, comment: string }[];
  public lastQuestion: boolean;
  public firstQuestion: boolean;
  public questionsNotReplied: number[];
  public alreadyStarted: boolean;
  public initDate: Date;
  public resultDate: Date;
  public numReplied = 0;
  public allReplied = false;
  public finished: boolean;
  public saving: boolean;
  public replies = 0;
  public i = 0;
  public loadedQuestionsId: number[] = [];
  public mockIsFinished: boolean;
  public exerciseEmbed: ExerciseEmbed;
  public videoUrl = environment.VideoUrl;
  public embedCodeVideo: String | SafeUrl;
  public enumUrl: String | SafeUrl;
  public embedEnum: ExerciseEmbed;


  constructor(
    private userService: UserService,
    private mockService: MockService,
    private alertService: AlertService,
    private exerciseService: ExerciseService,
    private router: Router,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    public layoutService: LayoutService) { }

  ngOnInit(): void {
    this.startDelay();
    this.firstQuestion = true;
  }

  initialize() {
    this.initializeQuestionsArrays(this.mock.numQuestions);
    this.getMockQuestion([], 0);
    this.verifyIsValidToInit();
    setTimeout(() => {
      this.startTimer.emit();
    }, 1000);
    if(this.mock.id){
      this.checkMockEnd();
    }
  }

  verifyInitAndResultDate() {
    if (this.mock.initDate) {
      this.initDate = moment(this.mock.initDate).toDate();
    }
    if (this.mock.resultDate) {
      this.resultDate = moment(this.mock.resultDate, 'DD/MM/YYYY hh:mm:ss').toDate();
    }
    this.verifyInitDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    !!changes.finalize && this.finalizeMock(changes.finalize.currentValue);
  }

  initializeQuestionsArrays(numQuestions: number) {
    this.questionsResponse = [];
    this.questionsSaved = [];
    this.questionsCorrect = [];
    this.questionsNotReplied = [];
    this.questions = [];

    for (let i = 0; i < numQuestions; i++) {

      this.questions.push(null);
      this.questionsResponse.push(null);
      this.questionsSaved.push(false);
      this.questionsCorrect.push({ correct: null, comment: null });
    }
  }

  scrollImgTo(id: string) {
    const imgId = document.getElementById(id);
    imgId.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  getMockQuestion(ignoreQuestions: number[], questionIndex: number) {
    this.loading = true;
    this.mockService.getMockNextQuestion(this.mock.id, ignoreQuestions, this.userService.getUserId(), this.mock.random)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          const question = response.data as MockQuestion;
          this.questions[questionIndex] = question;
          this.loadedQuestionsId.push(question.id);
          this.getVideoEnum(question.id);
          // replied
          if (question.studentResponse) {
            this.numReplied++;
            this.alreadyStarted = true;
            this.questionsSaved[questionIndex] = true;
            this.questionsResponse[questionIndex] = question.studentResponse;
            this.getVideoResponse(question.id);
            if (!this.mock.contest) {
              this.getQuestionCorrectAlternative(question.id, questionIndex);
            }
          } else {
            this.questionsSaved[questionIndex] = false;
          }
          this.question = question;
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar a questão. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  verifyAllQuestionAnswered(){
    console.log(this.mock.navigateQuestions);
  }

  sendQuestionResponse(questionId: number, alternative: string) {

    this.saving = true;

    this.mockService.sendQuestionResponse(questionId, alternative, this.mock.id, this.userService.getUserId(), this.timeLeft)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          if (!this.mock.contest) {
            this.getQuestionCorrectAlternative(questionId, this.i);
            this.getVideoResponse(questionId);
          } else {
            this.questionsSaved[this.i] = true;
            this.getVideoResponse(questionId);
          }
          this.numReplied++;
        } else {
          this.alertService.error(response.error);
        }

        this.saving = false;
      }, err => {
        this.alertService.error('Houve um erro ao salvar a resposta. Verifique a conexão e tente novamente');
        this.saving = false;
      });
  }

  getQuestionCorrectAlternative(questionId: number, exerciseIndex: number) {
    this.exerciseService.getExerciseCorrectAlternative(questionId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questionsCorrect[exerciseIndex] = response.data as { correct, comment };
          this.questionsSaved[exerciseIndex] = true;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar o gabarito. Verifique a conexão e tente novamente'));
  }

  verifyResponseQuestion(questionId: number, studentId: number, exerciseIndex: number) {

    this.mockService.verifyResponseQuestion(this.mock.id, questionId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

          const resp = response.data as string;

          if (resp) {
            this.numReplied++;
            this.alreadyStarted = true;
            this.questionsSaved[exerciseIndex] = true;
            this.questionsResponse[exerciseIndex] = resp;
            if (!this.mock.contest) {
              this.getQuestionCorrectAlternative(questionId, exerciseIndex);
            }
          } else {
            this.questionsSaved[exerciseIndex] = false;
          }

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao verificar o exercício. Verifique a conexão e tente novamente'));
  }

  next() {
    this.loading = true;
    this.firstQuestion = false;
    this.question = this.questions[this.i + 1];

    if (!this.questions[this.i + 1]) { // load next question
      this.getMockQuestion([...this.loadedQuestionsId], this.i + 1);
    } else { // already loaded
      this.loading = false;
      this.getVideoEnum(this.question.id);
    }

    this.i++;
    if (this.i === this.questions.length - 1) {
      this.lastQuestion = true;
    }
  }

  prev() {
    this.loading = true;
    this.lastQuestion = false;
    this.question = this.questions[this.i - 1];
    this.i--;
    this.loading = false;
    this.getVideoResponse(this.question.id);
    this.getVideoEnum(this.question.id);

    if (this.i === 0) {
      this.firstQuestion = true;
    }
  }

  end(endTime: boolean = false) {
    this.mockService.setSelectedMockToReply(null);

    if (endTime) {
      this.finalizeEndTime();
    } else {
      this.verifyShowResult();
    }
  }

  finalizeMock(finalize: boolean) {
    finalize && this.end(true);
  }

  private finalizeEndTime() {
    this.loading = true;
    this.mockService.finalizeMockTimeEnd(this.userService.getUserId(), this.mock.id)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.verifyShowResult();
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao finalizar o simulado. Verifique a conexão e tente novamente'));
  }

  verifyIsValidToInit() {

    if (this.numReplied > 0 && this.numReplied === this.questions.length) {
      this.verifyShowResult();
      this.loading = false;
      return;
    }
    this.loading = false;
  }

  verifyShowResult() {
    if (this.mock.resultDate) {
      this.loading = true;
      this.mockService.verifyMockResultIsReleased(this.mock.id)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            const released = response.data as boolean;

            if (released) {
              this.router.navigate(['/mock/result/' + this.mock.id]);
            } else {
              this.finished = true;
            }

          } else {
            this.finished = true;
          }

          this.loading = false;

        }, err => {
          this.finished = true;
          this.loading = false;
        });
    } else {
      this.router.navigate(['/mock/result/' + this.mock.id]);
    }
  }

  checkMockEnd(){
    this.mockService.verifyMockResultIsReleased(this.mock.id)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      console.log(response.data == false)
      console.log(response.data === false)
      console.log(response.data === 'false')
      console.log(typeof response.data)
      if(!response.error){
        this.mockIsFinished = response.data as boolean;
        if(response.data == false){
          this.verifyAllQuestionsAnswered(this.mock.id);
        }
      }
      else{
        this.verifyAllQuestionsAnswered(this.mock.id);
      }
    })
  }

  verifyInitDate() {
    this.mock && this.initialize();
  }

  verifyAllQuestionsAnswered(mockId: number){
    this.mockService.verifyAllQuestionsAnswered(mockId , this.userService.getUserId())
    .subscribe(res => {

      const response = res.body as ResponseApi;

      if(!response.error){
        this.mockIsFinished = response.data as boolean;
      }
    })
  }

  private startDelay() {
    const awaitTime = (Math.floor(Math.random() * 6) + 1) * 1000; // 0 to 7 seconds;
    setTimeout(() => {
      this.verifyInitAndResultDate();
    }, awaitTime);
  }

  verifyIsCorrectAlternative(i: number, alternative: string) {
    if (this.questionsResponse[i] && this.questionsCorrect[i].correct && !this.mock.contest) {

      if (this.questionsCorrect[i].correct == alternative) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  verifyIsIncorrectAlternative(i: number, alternative: string) {
    if (this.questionsResponse[i] && this.questionsCorrect[i].correct && !this.mock.contest) {

      if (this.questionsCorrect[i].correct != alternative) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getVideoResponse(exerciseId: number){
    this.mockService.getVideoResponse(exerciseId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.exerciseEmbed = response.data[0] as ExerciseEmbed;
        if(this.exerciseEmbed.embed_type.toLowerCase() == 'youtube'){
          this.embedCodeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl.youtube + this.exerciseEmbed.embed_code  as string);
        }
        else{
          this.embedCodeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl.vimeo + this.exerciseEmbed.embed_code  as string);
        }
      }
      else{

      }
    })
  }

  getVideoEnum(exerciseId: number){
    this.mockService.getVideoEnum(exerciseId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.embedEnum = response.data[0] as ExerciseEmbed;
        if(this.embedEnum.embed_type.toLowerCase() == 'youtube'){
          this.enumUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl.youtube + this.embedEnum.embed_code  as string);
        }
        else{
          this.enumUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl.vimeo + this.embedEnum.embed_code  as string);
        }
      }
      else{

      }
    })
  }

}

