<form [formGroup]="formEdit">
    <h4 class="mt-3 mb-3">Editar Notificação</h4>
    <div class="row">
        <div class="col-9">
            <mat-form-field class="width-100">
                <mat-label>Título</mat-label>
                <input matInput placeholder="Título" formControlName="title">
            </mat-form-field>
        </div>
        <div class="col-3" >
            <mat-form-field class="width-100">
                <mat-select placeholder="Tipo" formControlName="type"  (selectionChange)="noShowLink($event.value)">
                    <mat-option *ngFor="let type of types" [value]="type.name">{{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4" *ngIf="!hidden">
            <mat-form-field class="width-100">
                <mat-select placeholder="Perfil" formControlName="profiles" matTooltipClass="custom-tooltip" matTooltip="Escolha o(s) perfis">
                    <!-- <mat-option value="1">Aluno</mat-option>
                    <mat-option *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" value="2">Coordenador Unidade</mat-option>
                    <mat-option *ngIf="userService.isAdmin()" value="3">Coordenador Geral</mat-option>
                    <mat-option *ngIf="userService.isAdmin()" value="4">Admin</mat-option>
                    <mat-option *ngIf="!userService.isTeacher()" value="5">Professor</mat-option> -->
                    <mat-option *ngFor="let profile of profiles" [value]="profile.id" (click)="addProfilesChip(profile)">
                        <span [title]="profile.name">{{profile.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-8" *ngIf="!hidden">
            <div *ngIf="selectedProfiles?.length > 0">
                <mat-label class="mb-2">Perfis selecionados</mat-label>
                <mat-chip-list *ngIf="selectedProfiles">
                    <mat-chip *ngFor="let profileChip of selectedProfiles" selectable="true" removable="true" (removed)="removeProfileChip(profileChip)">
                        {{profileChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="userService.isAdmin() && formBySchool">
        <div class="col-4">
            <mat-form-field class="width-100">
                <mat-select formControlName="schools" placeholder="Escolha a(s) escola(s)" matTooltipClass="custom-tooltip" matTooltip="Escolha a(s) escola(s)">
                    <mat-option (click)="addSchoolChip(school)" *ngFor="let school of selectSchools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-8" *ngIf="selectedSchools?.length > 0">
            <mat-label class="mb-2">Escolas selecionadas</mat-label>
            <mat-chip-list *ngIf="selectedSchools">
                <mat-chip *ngFor="let schoolChip of selectedSchools" selectable="true" removable="true" (removed)="removeSchoolChip(schoolChip)">
                    {{schoolChip.name}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="row" *ngIf="formPerPlan">
        <div class="col-4" >
            <button class="m-3" (click)="openSelectPlans()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Associar ao plano</button>
        </div>
        <div class="col">
            <div *ngIf="selectedPlans?.length > 0">
                <mat-label class="mb-2">Planos selecionados</mat-label>
                <mat-chip-list *ngIf="selectedPlans">
                    <mat-chip *ngFor="let planChip of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(planChip)">
                        {{planChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
   <div class="row m-0 mt-4" *ngIf="!hidden">
        <ckeditor [editor]="questionEditor" [config]="editorConfig" formControlName="text" class="width-100 center" id="editor-notification"></ckeditor>
    </div>
    <div class="row m-0 mt-3" *ngIf="!hidden">
        <div class="col-12 mb-3">
            <div><span class="mb-3">Selecione o tipo de link (opcional):</span></div>
            <mat-radio-group placeholder="Selecione o tipo de link (opcional)" formControlName="optionLink" (change)="checkChoice($event.value)">
                <mat-radio-button class="radio-item mr-3" value="nenhum"> Nenhum </mat-radio-button>
                <mat-radio-button class="radio-item mr-3" value="image"> Imagem </mat-radio-button>
                <mat-radio-button class="radio-item ml-3 mr-3" value="video"> Vídeo </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" *ngIf="showLink">
            <mat-form-field class="width-100">
              <mat-icon matPrefix class="ml-2" *ngIf="notify.video" matTooltipClass="custom-tooltip" matTooltip="Para vídeo do Youtube colocar link completo ou para vídeo do Vimeo colocar apenas código.">
                help
            </mat-icon>
            <mat-label [ngClass]="{'ml-4': notify.video}">Link</mat-label>
            <input matInput placeholder="Link" formControlName="link">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field class="width-100">
                <mat-label>Início</mat-label>
                <input matInput placeholder="Início" formControlName="init" [textMask]="{mask: maskService.dateTime}">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="width-100">
                <mat-label>Término</mat-label>
                <input matInput placeholder="Término" formControlName="end" [textMask]="{mask: maskService.dateTime}">
            </mat-form-field>
        </div>
    </div>
</form>
<div class="row">
    <div class="col">
        <button (click)="closeDialog()" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
    <div  class="col text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small"
            status="primary" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>
