<div class="container pt-5 pb-5">

    <mat-accordion>

        <mat-expansion-panel class="width-70 center">
            <mat-expansion-panel-header>
                Criar Degustação
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-experiment-create></app-experiment-create>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel class="width-70 center mt-4">
            <mat-expansion-panel-header>
                Liberar Degustação
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-experiment-user-add></app-experiment-user-add>
            </ng-template>
        </mat-expansion-panel>

    </mat-accordion>

</div>