import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnimationOptions } from 'ngx-lottie';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Discipline } from 'src/app/modules/admin/discipline/discipline';
import { DisciplineService } from 'src/app/modules/admin/discipline/discipline.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

import { RepositoryService } from '../../repository.service';
import { VideoRepository } from '../video-repository';

@Component({
    selector: 'app-repository-video-list',
    templateUrl: './repository-video-list.component.html',
    styleUrls: ['./repository-video-list.component.scss']
})
export class RepositoryVideoListComponent implements OnInit {

    public disciplines: Discipline[];
    public selectedVideos: VideoRepository[];
    public videos: { folder: string, videos: VideoRepository[] }[];
    public lottieOptions: AnimationOptions;
    public loading: boolean;
    public loadingFolders: boolean;
    public loadingVideos: boolean;
    public videoDataSource: MatTableDataSource<VideoRepository>;
    public displayedColumns: string[];
    public folderSelected: string;
    
    public order: string = 'created_at';
    public reverse: boolean = true;

    constructor(
        private repositoryService: RepositoryService,
        private disciplineService: DisciplineService,
        private alertService: AlertService,
        private userService: UserService,
        private router: Router,
        public deviceService: DeviceDetectorService,
        public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.displayedColumns = ['name', 'link'];
        this.getVideos(this.userService.getUserClass());
        this.lottieOptionsConfig();
    }


    getOrder(el: string) {
        console.log(el);
    
        if (this.order === el) {
          this.reverse = !this.reverse;
        }
        this.order = el;
      }

    getVideos(classId: number) {
        this.loadingFolders = true;
        const schoolId = (this.userService.getUserSchool());
        if(!classId){
            classId = this.userService.getUserClass();
        }
        this.repositoryService.getVideoList(classId, schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.videos = response.data as { folder: string, videos: VideoRepository[] }[];
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingFolders = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
                this.loadingFolders = false;
            });
    }

    selectFolder(videos: VideoRepository[], folderName: string) {
        this.folderSelected = folderName;
        this.selectedVideos = videos;
        //console.log(this.folderSelected);
        //console.log(this.selectedVideos);
        this.videoDataSource = new MatTableDataSource([...videos]);
    }
    /* selectFolderMobile(videos: VideoRepository) {
        this.folderSelected = videos.name;
        this.selectedFolderVideos = videos;
        this.videoDataSource = new MatTableDataSource([...videos]);
    } */

    open(code: string, name: string, external: boolean) {        
        if(external && ( code.indexOf('whatsapp') ||  code.indexOf('telegram'))){
            window.open(code, '_blank');
        }else{
            this.router.navigate(['/repository/video/', code, name]);
        }
    }

    lottieOptionsConfig() {
        this.lottieOptions = {
            path: '../../../../assets/lotties/empty.json',
            loop: true,
            autoplay: true
        };
    }
}
