import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AdminService } from '../../../admin.service';
import { SelectedPlansResp } from '../../../rdo-manager/create-theme/theme';
import { ClassSelect } from '../../../school/class';
import { SchoolSelect } from '../../../school/school';
import { UnitSelect } from '../../../school/unit';
import { Notifications } from '../../notifications';
import { NotificationsService } from '../../notifications.service';
import { TypesNotifications } from '../../types-notifications';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';
import { ResponseApi } from 'src/app/core/models/response-api';
import { SchoolService } from '../../../school/school.service';
import { Profiles } from '../../profiles';
import { UserSelect } from '../../users';
import { Student } from '../../../student/student';

@Component({
  selector: 'app-edit-notifications',
  templateUrl: './edit-notifications.component.html',
  styleUrls: ['./edit-notifications.component.scss']
})
export class EditNotificationsComponent implements OnInit {

  public formBySchool: boolean = false;
  public formPerPlan: boolean = false;
  public showButton: boolean;
  public formEdit: FormGroup;
  public showform: boolean;
  public notify: Notifications;
  public loading: boolean;
  public editMode: boolean;
  public showLink: boolean;
  public optionLink: string;
  public image: string;
  public video: string;
  private apiUrl = environment.ApiUrl;
  public editorConfig;
  public questionEditor = ClassicEditor;
  public exerciseText: string = '';
  public expireDate: Date | string;
  protected onDestroy = new Subject<void>();
  private admUpdate$: Subscription;
  public hidden: boolean;
  public types: TypesNotifications[];
  public profiles: Profiles[];
  public filterName: string;
  public filterEmail: string;
  public selectedUnits: UnitSelect[];
  public selectUnits: UnitSelect[];

  public selectClasses: ClassSelect[];
  public selectedClasses: ClassSelect[];
  public selectPlans: SelectedPlansResp[];
  public selectedPlans: SelectedPlansResp[];
  public selectSchools: SchoolSelect[];
  public selectedSchools: SchoolSelect[];
  public selectedProfiles: Profiles[];
  public selectedUsers: UserSelect[];
  public selectUsers: UserSelect[];

  public students: Student[];
  public filteredStudents: Student[];


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public  data: Notifications,
    @Optional() public dialogRef: MatDialogRef<EditNotificationsComponent>,
    private alertService: AlertService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public maskService: MaskService,
    public userService: UserService,
    public notificationsService: NotificationsService,
    public schoolService: SchoolService) {
      this.notify = this.data;
  }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }


  init(){
    this.formEdit = this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      profiles: ['', Validators.required],
      type: ['', Validators.required],
      text: ['', Validators.required],
      optionLink: [''],
      link: [''],
      init: ['', Validators.required],
      end: ['', Validators.required]
    });
    this.getTypesNotifications();
    this.getProfilesNotifications();
    this.editorConfig = {
      ckfinder: {
        uploadUrl: this.apiUrl + '/upload/exerciseImg',
      },
      image: {
        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
        styles: ['full', 'alignLeft', 'alignRight']
      }
    };
    if (this.data) { this.initializeEditMode(); }
  }

  closeDialog(){
    this.dialogRef.close();
  }

  initializeEditMode(){

    this.getProfilesNotifications();
    this.getNotificationProfiles();
    this.getTypesNotifications();
    this.formEdit.patchValue(this.notify);
    this.showLink = true;
    if(this.notify.video != null){
      this.formEdit.get('optionLink').setValue('video');
      this.formEdit.get('link').setValue(this.notify.video);
    }else if(this.notify.image != null){
      this.formEdit.get('optionLink').setValue('image');
      this.formEdit.get('link').setValue(this.notify.image);
    }else{
      this.showLink = false;
      this.formEdit.get('optionLink').setValue('nenhum');
    }
  }


  getTypesNotifications(){
    this.notificationsService.getTypesNotifications()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.types = response.data as TypesNotifications[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os tipos de notificações. Verifique a conexão e tente novamente'));
  }

  getProfilesNotifications(){
    this.notificationsService.getProfilesNotifications()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.profiles = response.data as Profiles[];

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os tipos de notificações. Verifique a conexão e tente novamente'));
  }

  getNotificationProfiles(){
    this.notificationsService.getNotificationProfiles(this.notify.id)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let result = response.data as number[];

          this.selectedProfiles = this.computeNotificationProfiles(result) as Profiles[];

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os perfis selecionados para esta notificação. Verifique a conexão e tente novamente'));
  }

  private computeNotificationProfiles(profiles: number[]) : Profiles[]{

    let result: Profiles[] = [];

    if(profiles && profiles.length > 0){
      profiles.forEach(profil => {
        result.push({
          id: profil,
          name: (this.profiles.find((p) => p.id == profil)).name
        });
      });
    }

    return result;
  }

  noShowLink(type: string){
    if(type == 'Aula ao Vivo'){ //Sala de aula ao vivo
      this.hidden = true;
    }else{
      this.hidden = false;
    }
  }

  getSchoolsSelection() {
    this.schoolService.getSchoolsSelection()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.selectSchools = response.data as SchoolSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  addSchoolChip(school: SchoolSelect) {
    const index = this.selectedSchools.indexOf(school);
    if (index < 0) {
      this.selectedSchools.push(school);
      this.formEdit.get('schools').reset();
    }
  }

  removeSchoolChip(school: SchoolSelect) {
    const index = this.selectedSchools.indexOf(school);
    if (index >= 0) {
      this.selectedSchools.splice(index, 1);
    }
  }

  getPlansSelection(schoolId?: number){
    this.schoolService.getPlansSelection(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.selectPlans = response.data as SelectedPlansResp[];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente'));
  }

  addPlanChip(plan: { id: number, name: string }) {
    const index = this.selectedPlans.indexOf(plan);
    if (index < 0) { // && !this.planExist
      this.selectedPlans.push(plan);
      this.formEdit.get('plans').reset();
    }
  }

  removePlanChip(plan: SelectedPlansResp) {
    const index = this.selectedPlans.indexOf(plan);
    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }

  getUnitsSelection(schoolId: number) {
    this.schoolService.getUnitsSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.selectUnits = response.data as UnitSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClassesSelection(unitId: number) {
    this.schoolService.getClassesSelection(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.selectClasses = response.data as ClassSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getTeacherClassesSelection(teacherId: number) {
    this.selectClasses = [];
    this.schoolService.getClassesSelection(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.selectClasses = response.data as ClassSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  checkChoice(link: string){
    if(link === 'nenhum'){
      this.showLink = false;
      this.optionLink = 'nenhum';
    }else{
      this.showLink = true;
      if(link === 'image'){
        this.optionLink = 'image';
      }else{
        this.optionLink = 'video';
      }
    }
  }

  saveChanges(){
      const notify = this.formEdit.getRawValue();
      this.notificationsService.updateNotifications(notify)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.editMode = false;
            this.dialogRef.close();
            this.alertService.success('Dados Alterados com sucesso!', 3);
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
  }

}
