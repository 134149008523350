import { Component, OnInit } from '@angular/core';
import { MockService } from '../mock.service';
import { UserService } from '../../user/user.service';
import { ActivatedRoute } from '@angular/router';
import { MockQuestion } from '../mock-question';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ExerciseService } from '../../admin/exercise/exercise.service';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-mock-result',
  templateUrl: './mock-result.component.html',
  styleUrls: ['./mock-result.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MockResultComponent implements OnInit {

  public questions: MockQuestion[];
  public questionsResponse: string[];
  public questionsSaved: boolean[];
  public questionsCorrect: { correct: string, comment: string , questionId: number }[];
  public mockId: number;
  public loading: boolean;
  public dataSource: MatTableDataSource<MockQuestion>;
  public columnsToDisplay: string[];
  public expandedElement: MockQuestion | null;
  public hits = 0;
  public studentId: number;
  public haveResolution: boolean;
  public isReport: boolean;
  public numberIndex : number[];

  constructor(
    private mockService: MockService,
    public userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private exerciseService: ExerciseService,
    public layoutService: LayoutService) { }

  ngOnInit(): void {
    this.numberIndex = [];
    this.isReport = false;
    this.mockId = Number(this.route.snapshot.paramMap.get('mockId'));
    
    if(this.route.snapshot.paramMap.get('isReport')){
      this.isReport = true;
    }
    else{
      this.isReport = false;
    }


    this.loading = true;
    this.profileInit();
    // tslint:disable-next-line: no-unused-expression
    this.mockId && this.getMockQuestions();
    this.columnsToDisplay = [
      'questionNumber',
      'studentResponse',
      'correctResponse',
      'status'
    ];
  }

  profileInit() {
    if (this.userService.isStudent()) {
      this.studentId = this.userService.getUserId();
    } else {
      this.studentId = Number(this.route.snapshot.paramMap.get('studentId'));
    }
    this.getQuestionAlternativesNew(this.studentId);
  }

  getMockQuestions() {
    this.loading = true;
    this.mockService.getMockQuestions(this.mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questions = response.data as MockQuestion[];
          console.log(this.questions);
          this.initializeQuestionsArrays(this.questions.length);
          this.verifyResponseQuestionsNew();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as questões. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  initializeQuestionsArrays(exercisesNumber: number) {
    this.questionsResponse = [];
    this.questionsSaved = [];
    this.questionsCorrect = [];

    for (let i = 0; i < exercisesNumber; i++) {

      this.questionsResponse.push(null);
      this.questionsSaved.push(false);
      this.questionsCorrect.push({ correct: null, comment: null , questionId: null });
    }
  }

  concatResponseIntoQuestions() {

    if (this.verifyArrays()) {
      this.questionsCorrect.map((q, i) => {
        this.questions[i].correctAlternative = q.correct;
        this.questions[i].comment = q.comment;
        this.questions[i].index = i;
        this.questions[i].id = q.questionId;
      });
      this.dataSource = new MatTableDataSource(this.questions);
      this.countHits();
      this.loading = false;
    }
  }

  concatResponseIntoQuestionsNew() {
    if(this.questions){
      this.questions.map((q, i) => {
        this.questions[i].index = i;
      });
    }
  }

  countHits() {
    this.hits = 0;
    if(this.questions){
    this.questions.map(q => {
      if (q.correctAlternative === q.studentResponse) {
        this.hits++;
      }
    });
  }
  }

  private verifyArrays(): boolean {

    this.questionsCorrect.map(q => {
      if (!q.correct) {
        return false;
      }
    });

    this.questionsResponse.map(q => {
      if (!q) {
        return false;
      }
    });

    return true;
  }

  private verifyResponseQuestions() {

    for (let i = 0; i < this.questions.length; i++) {
      this.verifyResponseQuestion(this.questions[i].id, this.studentId, i);
    }

  }

  verifyResponseQuestion(questionId: number, studentId: number, exerciseIndex: number) {

    this.mockService.verifyResponseQuestion(this.mockId, questionId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

          const resp = response.data as string;

          if (resp) {
          this.questionsSaved[exerciseIndex] = true;
          this.questionsResponse[exerciseIndex] = resp;
          this.getQuestionCorrectAlternative(questionId, exerciseIndex);
          } else {
              this.questionsSaved[exerciseIndex] = false;
          }

        } else {
          this.alertService.error(response.error);
        }

        if (this.questions.length - 1 === exerciseIndex) {
          this.loading = false;
        }
      }, err => this.alertService.error('Houve um erro ao verificar o exercício. Verifique a conexão e tente novamente'));
  }

  private verifyResponseQuestionsNew() {

      let arrQuestions = [];

      this.questions.forEach(element => {
        arrQuestions.push(element.id);
      });

      this.getQuestionAlternativesNew(this.studentId);

  }

  getQuestionAlternativesNew(studentId: number) {

    this.mockService.getQuestionAlternativesNew(this.mockId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
           
            this.questions = response.data as MockQuestion[];

            this.dataSource = new MatTableDataSource(this.questions);
            this.countHits();
            this.loading = false;
            this.concatResponseIntoQuestionsNew();
            //this.getQuestionCorrectAlternativeNew(questionId);

      }
      }, err => this.alertService.error('Houve um erro ao verificar o exercício. Verifique a conexão e tente novamente'));
  }

  getQuestionCorrectAlternative(questionIdcom: number, exerciseIndex: number) {
    this.exerciseService.getExerciseCorrectAlternative(questionIdcom)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questionsCorrect[exerciseIndex] = response.data as { correct, comment , questionId };
          this.questionsSaved[exerciseIndex] = true;

          if (this.questionsCorrect[exerciseIndex].comment) {
            this.haveResolution = true;
          }

          this.concatResponseIntoQuestions();

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar o gabarito. Verifique a conexão e tente novamente'));
  }
}
