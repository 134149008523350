import { Discipline } from './../../admin/discipline/discipline';
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input } from '@angular/core';
import { DisciplineService } from '../../admin/discipline/discipline.service';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../user/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AnimationOptions } from 'ngx-lottie';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { MatPaginator } from '@angular/material/paginator';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VideoService } from '../../video/video.service';
import { CalendarService } from '../../calendar/calendar.service';
import { Schedule } from '../../admin/study-plan/schedule';


@Component({
  selector: 'app-discipline-plan-disciplines',
  templateUrl: 'discipline-plan-disciplines.component.html',
  styleUrls: ['./discipline-plan-disciplines.component.scss']
})

export class DisciplinePlanDisciplinesComponent implements OnInit {

  public studyPlans: StudyPlan[];
  public planSelected: StudyPlan;
  public planSelectedName: string;
  public disciplines: Discipline[];
  public disciplineSelected: Discipline[];
  public loadingDisciplines: boolean;
  public selectedStudyPlanId: number;
  public loadingPlans: boolean;
  public showClassrooms: boolean;
  public lottieOptions: AnimationOptions;
  public displayedColumns = [];
  public disciplineDataSource: MatTableDataSource<Discipline>;
  public firstPlanId: StudyPlan;
  public size: number;
  public pageIndex = 0;
  public totalRowsSize: Discipline[];
  public totalRows: number;
  public show: boolean = false;
  public disciplineId: number;
  public planId: number;
  public returnDiscipline: Discipline;
  public version: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public channelId: number;
  public channelName: string;
  public schedule: Schedule;


  constructor(
    private disciplineService: DisciplineService,
    private videoService: VideoService,
    private studyPlanService: StudyPlanService,
    private alertService: AlertService,
    public userService: UserService,
    public calendarService: CalendarService,
    private router: Router,
    private route: ActivatedRoute,
    public deviceService: DeviceDetectorService,
    public layoutService: LayoutService) { }

  ngOnInit() {
    this.getChannelId()

    this.emptyDisciplinesLottieOptionsConfig();
    //this.displayedColumns = this.layoutService.isMobile ? ['discipline'] :  ['discipline', 'total' , 'link'];
    /* Pega os valores da url da disciplina e do plano */
    this.planId = Number(this.route.snapshot.paramMap.get('planId'));
    this.disciplineId = Number(this.route.snapshot.paramMap.get('disciplineId'));
    if(this.planId && this.planId > 0){
      this.getVersionPlan(this.planId);
    }
      this.displayedColumns = this.layoutService.isMobile ? ['discipline'] :  ['discipline','total' ,'link'];
  }
  getChannelId(): void {
    this.channelId = Number(this.route.snapshot.paramMap.get('channelId'));
    if(this.channelId && this.channelId != undefined){
      this.getChannelPlansByDiscipline(this.channelId);
    }else{
      this.getStudentPlansByDiscipline(this.userService.getUserId());
    }

    this.router.events.subscribe(event => {
      //console.log(this.route.snapshot.url, this.planId);
      if (event instanceof NavigationEnd) {

        const  urlParsed = event.urlAfterRedirects.split("/");
        const channel = (urlParsed && urlParsed[3])? Number(urlParsed[3]) :0 ;
        const planId  = (urlParsed && urlParsed[5])? Number(urlParsed[5]) :0 ;
        const name = String(this.route.snapshot.url[1]);
        if(channel > 0  && name == 'channel' && planId === 0 && this.planId === 0){
          this.channelId = channel;
          this.disciplines = null;
          this.getChannelPlansByDiscipline(this.channelId);
        }

        if(planId > 0 ){
          this.channelId = channel;
          this.planId = planId;
          this.disciplines = null;
          this.getVersionPlan(planId);
        }
      }
   })
  }

  getChannelPlansByDiscipline(channelId: number) {
    this.loadingPlans = true;
    this.studyPlanService.getChannelPlansByDiscipline(channelId, this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.studyPlans = response.data as StudyPlan[];

           /* Quando for mobile precisa vir plano selecionado no select */
          if(this.layoutService.isMobile ){

            this.firstPlanId = this.studyPlans[0];
            if(this.studyPlans[0]){

              this.disciplineService.setSelectedPlanIdOnDisciplinesVideosSubject(this.firstPlanId.id);
              this.getVersionPlan(this.firstPlanId.id);
            }

            if(this.disciplineId && this.planId){
              this.router.navigateByUrl('/plan/disciplines/' + this.planId + '/' + this.disciplineId);
            }else if(this.planId){
              this.router.navigateByUrl('/plan/disciplines/' + this.planId);

            }
          }
          /* Quando retornar da página watch-video */
           if(this.planId && this.planId > 0){
            this.studyPlans.forEach(element => {
              if(element.id == this.planId){
                this.getVersionPlan(element.id);
                this.planSelectedName = element.name;
                this.planSelected = element;
                /* Quando voltar da página de vídeos (watch-video) */
                if(this.disciplineId && this.disciplineId != 0){
                  this.disciplineSelected = this.disciplines;
                }
              }
            });
          }

        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }

  getStudentPlansByDiscipline(studentId: number) {
    this.loadingPlans = true;
    this.studyPlanService.getNotHiddenStudentPlansByDiscipline(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.studyPlans = response.data as StudyPlan[];
          //this.studyPlans = this.studyPlans.filter(plan => plan.payment_status == 3);
           /* Quando for mobile precisa vir plano selecionado no select */
          if(this.layoutService.isMobile ){

            this.firstPlanId = this.studyPlans[0];
            if(this.studyPlans[0]){

              this.disciplineService.setSelectedPlanIdOnDisciplinesVideosSubject(this.firstPlanId.id);
              this.getVersionPlan(this.firstPlanId.id);
            }

            if(this.disciplineId && this.planId){
              this.router.navigateByUrl('/plan/disciplines/' + this.planId + '/' + this.disciplineId);
            }else if(this.planId){
              this.router.navigateByUrl('/plan/disciplines/' + this.planId);

            }
          }
          /* Quando retornar da página watch-video */
           if(this.planId && this.planId > 0){
            this.studyPlans.forEach(element => {
              if(element.id == this.planId){
                this.getVersionPlan(element.id);
                this.planSelectedName = element.name;
                this.planSelected = element;
                /* Quando voltar da página de vídeos (watch-video) */
                if(this.disciplineId && this.disciplineId != 0){
                  this.disciplineSelected = this.disciplines;
                }
              }
            });
          }

        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }

  reloadPlan(plan: StudyPlan){
    this.planSelected = null;
    this.getVersionPlan(plan.id);

  }

  selectPlan(selectedPlan: StudyPlan) {
    //console.log(selectedPlan.id, )
    if(selectedPlan.id && !this.channelId){
      this.router.navigateByUrl('/plan/disciplines/' + selectedPlan.id);
    }else{
      this.router.navigateByUrl('/plan/channel/' + this.channelId + '/disciplines/' + selectedPlan.id);
    }
    /* if(selectedPlan.id != this.planId){
      this.router.navigateByUrl('/plan/disciplines/' + selectedPlan.id);
    } */
    if(this.disciplineId){
      this.planSelectedName = selectedPlan.name;
    }
    this.disciplines = null;
    this.disciplineSelected = null;
    this.planSelectedName = selectedPlan.name;
    this.planSelected = selectedPlan;
    this.disciplineService.setSelectedPlanIdOnDisciplinesVideosSubject(selectedPlan.id);
    this.getVersionPlan(selectedPlan.id);
  }

  getVersionPlan(planId?: number){

    if(planId && planId > 0 && this.studyPlans){
      this.studyPlans.forEach(element => {
        //console.log("PLanid",planId,element );
        if(planId === element.id ){
          //console.log("version",planId,element.id, element.version);
          if(element.version == 1){
            this.getPlanDisciplinesV1(element.id);
          }else if(element.version){
            this.getPlanDisciplinesV2(element.id);
            //if(this.layoutService.isMobile && this.disciplineSelected){this.planSelected = element;}
          }
        }
      });
    }

  }

  getPlanDisciplinesV1(planId: number) {
    this.loadingDisciplines = true;
    this.disciplines =[];

    this.studyPlanService.getPlanDisciplinesV1(planId , this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplineDataSource = new MatTableDataSource(this.disciplines);
          /* Quando voltar da página de vídeos (watch-video) */
          if(this.disciplineId && this.disciplineId != 0){
            this.disciplineSelected = this.disciplines;
            /* Para mobile */

            if(this.layoutService.isMobile){
              this.studyPlans.forEach(element => {
                if(element.id == this.planId){
                  this.planSelectedName = element.name;
                  this.planSelected = element;
                  this.planId = element.id;
                  if(this.disciplineId && this.disciplineId != 0){
                    this.disciplineSelected = this.disciplines;
                  }
                }
              });

            }

          }
        } else {

          this.alertService.error(response.error);
        }
        this.loadingDisciplines = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loadingDisciplines = false;
      });
  }

  getPlanDisciplinesV2(planId: number, reload:boolean = true ) {
    let userId = Number(this.userService.getUserId());
    this.disciplines =[];

    this.loadingDisciplines = true;
    this.studyPlanService.getPlanDisciplinesV2(planId , userId , this.userService.getUserSchool())
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
            this.disciplines = response.data as Discipline[];
            if(this.disciplines){
              this.disciplines.sort((a,b) => {
                if(a.name > b.name){
                  return 1;
                }
                if(a.name < b.name){
                  return -1
                }
                return 0;
              });
            }
            this.disciplineDataSource = new MatTableDataSource(this.disciplines);
            /* Recarrega novamente para carregar o calendário -  && !(this.disciplineId) */
            if(this.disciplines.length == 0 && reload){
              this.reloadCalendar(planId , userId );
            }
            /* Quando voltar da página de vídeos (watch-video) */
            if(this.disciplineId && this.disciplineId > 0){
              this.disciplineSelected = this.disciplines;
              if(this.layoutService.isMobile){this.planId = this.planSelected.id;}
            }
        } else{
          if( reload){
            this.reloadCalendar(planId , userId );
          }

        }
        this.loadingDisciplines = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loadingDisciplines = false;
      });
  }
  sendDisciplinePlan(plan: StudyPlan){
    if(plan == undefined || plan == null){
      this.planSelected = this.firstPlanId;
    }
  }

  reloadCalendar(planId: number, studentId: number){
    this.disciplines =[];
    let userId = Number(this.userService.getUserId());
    this.loadingDisciplines = true;


    this.calendarService.getPlanSchedule(planId, studentId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.getPlanDisciplinesV2(planId ,false );
      } else {
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
      this.loadingDisciplines = false;
    });



  }

  checkDiscipline(){
    this.disciplineSelected = null;
    if(this.planId){
      this.loadingDisciplines = false;
      if(this.disciplines){
        this.disciplines.sort((a,b) => {
          if(a.name > b.name){
            return 1;
          }
          if(a.name < b.name){
            return -1
          }
          return 0;
        });
      }
      this.disciplineDataSource = new MatTableDataSource(this.disciplines);
    }
  }
  paginate(event: any) {
    this.pageIndex=event;
    this.totalRowsSize = this.disciplines.slice(event * this.size - this.size, event * this.size);
    this.totalRows = this.totalRowsSize.length;
  }
  openDisciplineVideos(discipline: Discipline) {
    this.disciplineService.setDiscipline(discipline);
    this.router.navigate(['/discipline/videos']);
  }
  setShowClassrooms(show: boolean) {
    this.showClassrooms = show;
  }
  emptyDisciplinesLottieOptionsConfig() {
    this.lottieOptions = {
      path: 'assets/lotties/empty.json',
      loop: true,
      autoplay: true
    };
  }
}
