import { ResponseApi } from "src/app/core/models/response-api"; 
import { ChannelModel } from "./Models/Channel.model";

export   class CreateChannel{

    constructor(
        private channelService:any,
        private alertService:any,
        private channel:any){}

    public execute(){
        const response = ChannelModel.newChannelModel(this.channel).getChannel();  

        this.channelService.createChannel( response).subscribe(res => {

            const response = res.body as ResponseApi;

            if (!response.error) {
                this.channelService.emitChannels.emit({name:"channel", values:response.data})
                this.alertService.success("Canal criado com sucesso!");
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os planos da turma. Verifique a conexão e tente novamente'));
 

    } 
}