import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { Video } from 'src/app/modules/video/video';
import { VideoService } from 'src/app/modules/video/video.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { Annex } from './annex';
import { StudyPlanService } from '../../study-plan/study-plan.service';

@Component({
    selector: 'app-add-video-annex',
    templateUrl: './add-video-annex.component.html'
})
export class AddVideoAnnexComponent implements OnInit {

    public addVideoAnnexForm: FormGroup;
    public videos: Video[];
    public selectedVideos: Video[];
    public showAnnexList: boolean;
    public disciplines: Discipline[];
    public annex: Annex[];
    public filter = '';
    public saving: boolean;
    public file: any;
    public fileMetaData: any;
    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public filterDisciplines: Discipline[];
    public filterVideos: Video[];
    public filterVideoId: number;
    public loading: boolean;

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private videoService: VideoService,
        public userService: UserService,
        private schoolService: SchoolService,
        private disciplineService: DisciplineService,
        private studyPlanService: StudyPlanService) { }

    ngOnInit(): void {

        this.selectedVideos = [];
        this.addVideoAnnexForm = this.formBuilder.group({
            name: ['', Validators.required],
            discipline: ['', Validators.required],
            tag: ['', Validators.required],
            fileMetaData: [''],
            userData: [''],
            video: ['', Validators.required],
            file: ['', Validators.required]
        });

        if (this.userService.isAdmin()) {
            this.getDisciplines();
        } else if (this.userService.isGeneralCoordinator()) {
            this.getSchoolDisciplines(this.userService.getUserSchool());
        } else if (this.userService.isTeacher()) {
            this.getTeacherDisciplines(this.userService.getUserId());
        } else if (this.userService.isUnitCoordinator()) {
            this.getUnitDisciplines(this.userService.getUserUnit());
        }
    }

    getVideos(disciplineId: number) {
        this.videoService.getDisciplineVideos(disciplineId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.videos = response.data as Video[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar os vídeos. Verifique a conexão e tente novamente'));
    }


    handleFileInput(files: FileList): void {
        if (files && files[0]) {
            const fileSizeInMb = files[0].size / 1024 / 1024;

            if (fileSizeInMb > 5) {
                this.alertService.error('Tamanho máximo 5 MB!');
            } else {
                const file = files[0];
                this.fileMetaData = { name: files[0].name, type: files[0].type, size: files[0].size };

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    this.file = reader.result;
                };
            }
        }
    }

    openList() {
        if (this.userService.isAdmin()) {
            this.getDisciplines();
        } else if (this.userService.isGeneralCoordinator()) {
            this.getSchoolDisciplines(this.userService.getUserSchool());
        } else if (this.userService.isUnitCoordinator()) {
            this.getUnitDisciplines(this.userService.getUserUnit());
        }
        this.showAnnexList = true;
    }

    openAnnex(url: string) {
        window.open(url, '_blank');
    }

    removeAnnex(annexId: number) {
        const removeData = {
            annexId,
            userData: this.userService.getMetaData()
        };
        if (confirm('Deseja realmente remover o anexo?')) {
            this.videoService.removeAnnex(removeData)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Anexo removido!', 3);
                        this.getVideoAnnex(this.filterVideoId);
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover o anexo. Verifique a conexão e tente novamente'));
        }
    }

    addAnnex() {

        this.addVideoAnnexForm.get('file').setValue(this.file);
        this.addVideoAnnexForm.get('userData').setValue(this.userService.getMetaData());
        this.addVideoAnnexForm.get('fileMetaData').setValue(this.fileMetaData);
        const videosIds = [];
        this.selectedVideos.forEach(v => videosIds.push(v.id));
        this.addVideoAnnexForm.get('video').setValue(videosIds);

        if (this.addVideoAnnexForm.valid) {
            this.saving = true;
            const annex = this.addVideoAnnexForm.getRawValue() as Annex;

            this.videoService.addAnnex(annex)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Anexo adicionado', 3);
                        this.addVideoAnnexForm.reset();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.saving = false;

                }, err => {
                    this.alertService.error('Houve um erro ao adicionar o anexo. Verifique a conexão e tente novamente');
                    this.saving = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente');
        }
    }

    editAnnex(annex: Annex) {

    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];

                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    verifyFilter(value: string) {
        return value.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 || !this.filter;
    }

    addVideoChip(video: Video) {
        const index = this.selectedVideos.indexOf(video);

        if (index < 0) {
            this.selectedVideos.push(video);
            this.addVideoAnnexForm.get('video').reset();
        }
    }

    removeVideoChip(video: Video) {
        const index = this.selectedVideos.indexOf(video);

        if (index >= 0) {
            this.selectedVideos.splice(index, 1);
        }
    }

    getDisciplines() {
        this.disciplineService.getDisciplines()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                    this.filterDisciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
    }

    getSchoolDisciplines(schoolId: number) {
        this.studyPlanService.getSchoolDisciplines(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                    this.filterDisciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
    }

    getDisciplineVideos(disciplineId: number) {
        this.disciplineService.getDisciplineVideos(disciplineId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.filterVideos = response.data as Video[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente'));
    }

    getVideoAnnex(videoId: number) {
        this.loading = true;
        this.videoService.getVideoAnnex(videoId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.annex = response.data as Annex[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar os anexos do vídeo. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    private getTeacherDisciplines(teacherId: number) {
        this.schoolService.getTeacherDisciplines(teacherId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                    this.filterDisciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde'));
    }

    private getUnitDisciplines(unitId: number) {
        this.schoolService.getUnitDisciplines(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                    this.filterDisciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
    }

}
