import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Monitory } from 'src/app/modules/monitory/monitory';
import { MonitoryService } from 'src/app/modules/monitory/monitory.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { Class } from '../../school/class';
import { SchoolService } from '../../school/school.service';
import { Student } from '../../student/student';
import { StudentService } from '../../student/student.service';

@Component({
  selector: 'app-monitory-create',
  templateUrl: './monitory-create.component.html',
  styleUrls: ['./monitory-create.component.scss']
})
export class MonitoryCreateComponent implements OnInit {

  public form: FormGroup;
  public editMode: boolean;
  public showList: boolean;
  public loading: boolean;
  public classes: Class[];
  public filterClassId: number;
  public monitoring: Monitory[];
  public students: Student[];


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public monitory: Monitory,
    @Optional() public dialogRef: MatDialogRef<MonitoryCreateComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private monitoryService: MonitoryService,
    public maskService: MaskService,
    private studentService: StudentService) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.form = this.formBuilder.group({
      id: [''],
      teacherId: ['', Validators.required],
      studentId: ['', Validators.required],
      endDate: ['', Validators.required],
      initDate: ['', Validators.required],
      link: ['', Validators.required]
    });
    this.profileInit();

    if (this.monitory) { this.initializeEditMode(); }
  }

  profileInit() {
    if (this.userService.isTeacher()) {
      this.getTeacherClasses(this.userService.getUserId());
    }
  }

  initializeEditMode() {
    this.editMode = true;
    this.form.patchValue(this.monitory);
    this.filterClassId = this.monitory.classId;
    this.getStudents(this.filterClassId);
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
          this.classes = this.classes.map(c => {
            c.id = Number(c.id);
            return c;
          })
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  edit(monitory: Monitory) {
    const dialogRef = this.dialog.open(MonitoryCreateComponent, {
      minWidth: '60vw',
      data: monitory
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTeacherMonitoring(this.userService.getUserId());
      }
    });
  }

  openList() {
    this.showList = true;
    this.getTeacherMonitoring(this.userService.getUserId());
  }

  getTeacherMonitoring(teacherId: number) {
    this.loading = true;
    this.monitoryService.getTeacherMonitoring(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.monitoring = response.data as Monitory[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as monitorias da turma. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  create() {
    if (this.form.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    this.form.get('teacherId').setValue(this.userService.getUserId());
    const monitory = this.form.getRawValue() as Monitory;

    this.monitoryService.createMonitory(monitory)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Monitoria agendada!');
          this.form.reset();
          this.getTeacherMonitoring(this.userService.getUserId());
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;

      }, err => {
        this.alertService.error('Houve um erro ao criar a monitoria. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  saveChanges() {
    if (this.form.status != "VALID") {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    const monitory = this.form.getRawValue() as Monitory;

    this.monitoryService.saveMonitoryChanges(monitory)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Monitoria editada!');
          this.form.reset();
          this.dialogRef.close(true);
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;

      }, err => {
        this.alertService.error('Houve um erro ao salvar as alterações da monitoria. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  remove(monitoryId: number) {
    if (confirm('Deseja realmente excluir a sala?')) {
      this.loading = true;
      this.monitoryService.removeMonitory(monitoryId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Removido!');
            this.getTeacherMonitoring(this.userService.getUserId());
          } else {
            this.alertService.error(response.error);
            this.loading = false;
          }


        }, err => {
          this.loading = false;
          this.alertService.error('Houve um erro ao remover a monitoria. Verifique a conexão e tente novamente');
        });
    }
  }

  getStudents(classId: number) {
    this.studentService.getStudents(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.students = response.data as Student[];
          this.students = this.students.map(s => {
            s.id = Number(s.id);
            return s;
          })
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente'));
  }

}
