import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MainTheme, ThemeType } from '../create-theme/theme';
import { SyncService } from '../sync.service';
import { Edital } from './edital';

@Component({
  selector: 'app-create-edital',
  templateUrl: './create-edital.component.html',
  styleUrls: ['./create-edital.component.scss']
})
export class CreateEditalComponent implements OnInit {

  public themes: ThemeType[];
  public editals: Edital[];
  public labelName: string;
  public themeType: number;
  public pageSize: number;
  public pagelength: number;
  public pageIndex: number;
  public totalPage: number;
  public click: boolean;
  public selectedEdital: Edital;
  public dbEditals: Edital[];
  public showList: boolean;
  public editMode: boolean;
  public themeSearch : number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public editalEdit: any,
    @Optional() public dialogRef: MatDialogRef<CreateEditalComponent>,
    private dialog: MatDialog,
    private syncService: SyncService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {

    this.editMode = false;
    this.showList = false;
    this.getThemeType();
    //this.getEditals(0);
    this.click = false;
    if(this.editalEdit){
      this.editMode = true;
      this.selectedEdital = this.editalEdit;
      this.labelName = this.editalEdit.typeName;
    }
  }

  getThemeType() {
    this.syncService.getThemeTypes()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.themes = response.data as ThemeType[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  setValName(t: ThemeType) {
    this.labelName = t.name;
    this.themeType = t.id;

    if(t.name == 'Enem'){
      this.themeSearch = 1;
    }
    else if(t.name == 'Concurso'){
      this.themeSearch = 3;
    }
    else{
      this.themeSearch = 2;
    }

    this.getEditals(1 , this.themeSearch);
    this.pageIndex = 1;
  }

  getEditals(index: number , themeSearch: number) {
    if(index == 0){
      index = 1;
    }
    this.syncService.getEditals(index , themeSearch)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.editals = response.data['data'] as Edital[];
          this.pageSize = response.data['per_page'];
          this.pagelength = response.data['total'];
          this.totalPage = response.data['last_page'];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  pageEvents(event: any) {
    if(this.themeSearch){
      this.getEditals(event.pageIndex , this.themeSearch);
    }
  }

  selectEdital(ed: Edital) {
    this.click = true;
    this.selectedEdital = ed;
    this.selectedEdital.essayThemeTypeId = this.themeType;
  }

  createEdital() {
    if (this.selectedEdital) {
      this.syncService.createEdital(this.selectedEdital)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Edital cadastrado com sucesso!');
          }
          else {
            this.alertService.error(response.error);
          }
        })
    }
    else {
      this.alertService.error('você precisa selecionar um tipo e um edital!');
    }
  }

  getDbEditals() {
    this.syncService.getDbEditals()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.dbEditals = response.data as Edital[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  openList() {
    this.getDbEditals();
    this.showList = true;
  }

  btnBack() {
    this.showList = false;
    this.editMode = false;
  }

  deleteEdital(editalId: number) {
    if (confirm('Voce deseja deletar o edital?')) {
      this.syncService.deleteEdital(editalId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Edital deletado com sucesso!');
            this.getDbEditals();
          }
          else {
            this.alertService.error(response.error);
          }
        });
    }
  }

  openEditMode(el : Edital){
    const dialogRef = this.dialog.open(CreateEditalComponent , {
      minWidth: '80vw',
      maxHeight: '50vw',
      data: el
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.editMode = false;
        this.getDbEditals();
      }
    });
  }

  updateEdital(){


      if(!this.selectedEdital.essayThemeTypeId){
        this.selectedEdital.essayThemeTypeId = this.editalEdit.essayThemeTypeId;
      }

      this.selectedEdital.type_id = this.selectedEdital.id;
      this.selectedEdital.id = this.editalEdit.id;

      if(this.selectedEdital.type_id != this.editalEdit.id){
      this.syncService.updateEdital(this.selectedEdital)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Edital Atualizado com sucesso!');
            this.dialogRef.close(true);
          }
          else {
            this.alertService.error(response.error);
          }
        })
      }
      else{
        this.alertService.error('Os dados não podem ser os mesmos para atualizar,Selecione um edital diferente!');
      }
    }

}
