import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NbPopoverDirective } from '@nebular/theme';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, timer } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { UserService } from '../../user/user.service';
import { Mock } from '../mock';
import { MockService } from '../mock.service';


@Component({
  selector: 'app-mock-list',
  templateUrl: './mock-list.component.html',
  styleUrls: ['./mock-list.component.scss']
})
export class MockListComponent implements OnInit {

  @ViewChild(NbPopoverDirective) timerPopover: NbPopoverDirective;
  public plans: StudyPlan[];
  public mocks: Mock[];
  public filteredMocks:Mock[];
  public selectedPlan: StudyPlan;
  public selectedMock: Mock;
  public loading: boolean;
  public counter$: Observable<string>;
  public mockTypeSelected: string;
  public count = 0;
  public timePassed = 0;
  public timeLimit;
  public circleDashArray;
  public isContest: boolean;
  public releasedResult = false;
  public finalizeMock = false;
  public isTest: boolean
  public showMessageEdu: boolean;
  public isInPlansEdu: boolean;
  public firstPlan: StudyPlan;
  public size: number = 10;
  public page: number;
  public totalRows: number;
  public selected: boolean;
  public totalPage: number;
  public noPagination: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filterMock: string;

  public displayedColumns: string[];
  public mockDataSource: MatTableDataSource<Mock>;
  public loadingMocks: boolean;
  public loadingPlans: boolean;
  public lottieOptions: AnimationOptions;
  public noPlans:boolean;


  public order: string = 'name';
  public reverse: boolean = false;
  public haveRestart: boolean = false;


  constructor(
    private mockService: MockService,
    private alertService: AlertService,
    private userService: UserService,
    private router: Router,
    private studyPlanService: StudyPlanService,
    public deviceService: DeviceDetectorService,
    public layoutService: LayoutService) { }

  ngOnInit(): void {
    this.emptyDisciplinesLottieOptionsConfig();
    this.mockTypeSelected = "SG";
    this.displayedColumns = ['mock', 'type', 'link']
    this.filteredMocks = [];
    this.showMessageEdu = false;
    this.isInPlansEdu = false;
    this.checkSchoolEdu();
    this.checkMessagePlansEdu();
    this.verifyIsTestOrMockPage();
    this.loadSelectedMock();
    if(this.isTest){
      this.getTestsStudentUserAssociation(this.userService.getUserId());
    }else{
      this.getMocksStudentUserAssociation(this.userService.getUserId());
    }
    
  }

  loadSelectedMock() {
    this.mockService.getSelectedMockToReply().pipe(first())
      .subscribe(mock => {
        if (mock) {
          this.verifyMockValidToInit(mock, true);
        } else {
          this.getClassPlans(this.userService.getUserClass(), this.userService.getUserId());
        }
      });
  }

  verifyIsTestOrMockPage() {
    this.isTest = this.router.url === '/test/list' ? true : false;
  }

  checkMessagePlansEdu() {

    this.studyPlanService.getMessageUserPlansEdu(this.userService.getUserId()).subscribe(res => {

      const response = res.body as ResponseApi

      if (response.data == true) {
        this.isInPlansEdu = true
      }
      else {
        this.isInPlansEdu = false;
      }
    })
  }

  changeTypeMocks(val: string) {
    if(!this.isTest && val == 'MS') this.getMocksStudentUserAssociation(this.userService.getUserId());
    if(!this.plans){
      this.noPlans = true;
    }else{
      this.noPlans = false;
    }
    if (val == 'SG') {
      this.mockDataSource = new MatTableDataSource(this.mocks);
    } else {
      this.mockDataSource = new MatTableDataSource(this.filteredMocks);
    }
  }

  startTimer() {
    this.timerPopover.show();

    setTimeout(() => {
      this.timerPopover.hide();
    }, 10000);

    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => {
        if (this.count === 1) {
          this.endMock();
        }
        this.setCircleDasharray();
        return this.secondsToTime(--this.count);
      })
    );
  }

  checkSchoolEdu() {
    const id = Number(this.userService.getUserSchool());
    if (id == 122) {
      this.showMessageEdu = true;
    }
    else {
      this.showMessageEdu = false;
    }
  }

  private secondsToTime(seconds: number) {
    const date = new Date(0);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
  }

  endMock() {
    alert('O seu tempo acabou!');
    this.mockService.setSelectedMockToReply(null);
    this.finalizeMock = true;
  }

  getClassPlans(classId: number, studentId: number) {
    this.loadingPlans = true;
    this.mockService.getNotHiddenClassPlansWithMocks(classId, studentId, this.isTest)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.firstPlan = this.plans[0];
          if(this.firstPlan || this.firstPlan != null){
            this.noPlans = false;
            if (this.isTest && this.layoutService.isMobile) {
              this.getPlanJustTest(this.firstPlan.id);
            } else if(this.layoutService.isMobile){
              this.getPlanJustMocks(this.firstPlan.id);
            }
          }else{
            this.noPlans = true;
          }
        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  selectPlan(plan: StudyPlan) {
    this.selectedPlan = plan;
    if (this.isTest) {
      this.getPlanJustTest(plan.id);
    } else {
      this.getPlanJustMocks(plan.id);
    }
  }

  backBtn(){
    this.selectedMock = null;
    if(this.selectedPlan){
      if (this.isTest) {
        this.getPlanJustTest(this.selectedPlan.id);
      } else {
        this.getPlanJustMocks(this.selectedPlan.id);
      }
    }
    this.count = 0;
  }

  paginate(event: any) {
    this.page = event;

    let planId = this.firstPlan.id;
    if(this.selected){
      planId = this.selectedPlan.id;
    }
    //this.getPlanDisciplinesVideosMobile(planId, this.page);
  }

  getPlanJustMocks(planId: number) {
    this.loadingMocks = true;
    this.mockService.getPlanJustMocks(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
          this.filteredMocks = response.data as Mock[];
          this.mockDataSource = new MatTableDataSource(this.mocks);
          this.verifyHaveRestart();
        } else {
          this.alertService.error(response.error);
        }

        this.loadingMocks = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as avaliações. Verifique a conexão e tente novamente');
        this.loadingMocks = false;
      });
  }

  getMocksStudentUserAssociation(userId: number) {
    this.loading = true;
    //this.filteredMocks = [];
    this.mockService.getMocksStudentUserAssociation(userId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
          this.filteredMocks = response.data as Mock[];
          this.mockDataSource = new MatTableDataSource(this.mocks);

          /* this.filteredMocks = response.data as Mock[];
          this.mockDataSource = new MatTableDataSource(this.mocks); */
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.loading = false;
      });
  }

  getTestsStudentUserAssociation(userId: number) {
    this.loading = true;
    this.mockService.getTestsStudentUserAssociation(userId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          //this.studentsMocks = response.data as Mock[];
          this.filteredMocks = response.data as Mock[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.loading = false;
      });
  }

  getPlanJustTest(planId: number) {
    this.loadingMocks = true;
    this.mockService.getPlanJustTests(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
          this.filteredMocks = response.data as Mock[];
          this.mockDataSource = new MatTableDataSource(this.mocks);
        } else {
          this.alertService.error(response.error);
        }

        this.loadingMocks = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as avaliações. Verifique a conexão e tente novamente');
        this.loadingMocks = false;
      });
  }

  getPlanMocks(planId: number) {
    this.loadingMocks = true;
    this.mockService.getPlanMocks(planId, false)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
          this.filteredMocks = response.data as Mock[];
          this.mockDataSource = new MatTableDataSource(this.mocks);

        } else {
          this.alertService.error(response.error);
        }

        this.loadingMocks = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os Simulados. Verifique a conexão e tente novamente');
        this.loadingMocks = false;
      });
  }

  getTimeLeft(studentId: number, mockId: number) {
    this.timeLimit = Number(this.selectedMock.timeMock);
    this.count = this.timeLimit;
    this.mockService.getTimeLeft(studentId, mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          const timeLeft = response.data as { timeLeft: number };
          this.count = Number(timeLeft.timeLeft) + 3;
          this.startTimer();
        } else {
          this.alertService.error(response.error);
        }

      }, err => this.alertService.error('Houve um erro ao carregar o tempo do simulados. Verifique a conexão e tente novamente'));
  }

  selectMock(mock: Mock) {
    this.verifyMockValidToInit(mock);
  }

  private calculateTimeFraction() {
    const rawTimeFraction = this.count / this.timeLimit;
    return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
  }

  private setCircleDasharray() {
    this.circleDashArray = (this.calculateTimeFraction() * 283).toFixed(0) + ' 283';
  }

  seeResult(mockId: number, summation?: boolean) {
    if (summation) {
      this.router.navigate(['/summation/result/' + mockId]);
    } else {
      this.router.navigate(['/mock/result/' + mockId]);
    }
  }

  clearMock() {
    this.selectedMock = null;
  }

  startTimerEvent() {
    this.getTimeLeft(this.userService.getUserId(), this.selectedMock.id);
  }

  verifyMockValidToInit(mock: Mock, dashboardAccess?: boolean) {
    this.loading = true;
    this.mockService.setSelectedMockToReply(null);
    this.mockService.verifyMockValidToInit(mock.id, this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.selectedMock = mock;
        } else {
          this.loading = false;
          this.loadingMocks = false;
          this.alertService.warning(response.error);
          if (dashboardAccess) {
            this.router.navigateByUrl('/dashboard');
          }
        }
        this.loading = false;
      }, err => this.loading = false);
  }

  selectMockDelay(mock: Mock) {
    this.loadingMocks = true;
    const awaitTime = (Math.floor(Math.random() * 5) + 1) * 1000; // 0 to 5 seconds;
    setTimeout(() => {
      this.selectMock(mock);
    }, awaitTime);
  }

  emptyDisciplinesLottieOptionsConfig() {
    this.lottieOptions = {
      path: 'assets/lotties/empty.json',
      loop: true,
      autoplay: true
    };
  }

  applyFilter() {

    if (this.filterMock.length) {
        this.filteredMocks = [...this.mocks].filter(mock =>

            mock.name.toLocaleLowerCase().indexOf(this.filterMock.toLowerCase()) > -1);

            this.mockDataSource = new MatTableDataSource([...this.filteredMocks]);
    } else {
        this.filteredMocks = [...this.mocks];
        this.mockDataSource = new MatTableDataSource([...this.mocks]);
    }
  }

  resetMock(mockId: number){
    //resetar
    if(confirm('Você deseja realmente excluir todas as respostas e Refazer o simulado?')){
      this.mockService.resetMock(mockId , this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          this.alertService.success(response.data.toString());
        }
        else{
          this.alertService.error(response.error);
        }
      } , err => {
        this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
      })
    }
  }

  getOrder(el: string) {
      console.log(el);

      if (this.order === el) {
        this.reverse = !this.reverse;
      }
      this.order = el;
  }

  verifyHaveRestart() {
    // console.log(this.filteredMocks)
    let i = 0;
    while(i <= this.filteredMocks.length && !this.haveRestart){
      if(this.filteredMocks[i]?.isReset){
        this.haveRestart = true;
      }
      i++;
    }
  }
}
