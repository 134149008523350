import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NbIconModule } from '@nebular/theme';
import { IntercomModule } from 'ng-intercom';
import { OrderModule } from 'ngx-order-pipe';
import { UserModule } from 'src/app/modules/user/user.module';
import { OrderByPipe } from 'src/app/orderBy.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { SortByPipe } from 'src/app/sortBy.pipe';
import { IntegrationPortalComponent } from '../components/integration-portal/integration-portal.component';

import { LoadingModule } from '../components/loading/loading.module';
import { NotificationsModule } from '../components/notifications/notifications.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderComponent } from './header.component';


@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatSidenavModule,
        MatButtonModule,
        LayoutModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        RouterModule,
        LoadingModule,
        MatDividerModule,
        NbIconModule,
        SharedModule,
        MatExpansionModule,
        MatTooltipModule,
        MatBottomSheetModule,
        UserModule,
        FooterModule,
        NotificationsModule,
        OrderModule
    ],
    exports: [HeaderComponent],
    providers: [
        DatePipe, 
        CurrencyPipe, 
        OrderByPipe, 
        SortByPipe
    ]
})
export class HeaderModule { }