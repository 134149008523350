<div class="page-container p-pg page-container-mobile discipline font-poppins">
    <div class="row m-0">
        <div class="mt-2" [ngClass]="{'col-md-7':!layoutService.isMobile, 'col-md-12':layoutService.isMobile}">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'left dash-container':!layoutService.isMobile, 'text-center': layoutService.isMobile, 'text-center width-80': layoutService.isMobile}">
                    <span class="page-title">AULAS / {{planName}} / {{disciplineName}}</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile" class="text-color-accent">{{disciplineName}}</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile" class="text-color-accent">{{disciplineName}}</h6>
                        <p class="fs-13 pb-0">Escolha a matéria que você deseja estudar</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="mt-2 text-right" [ngClass]="{'col-md-5':!layoutService.isMobile, 'col-md-12 p-0':layoutService.isMobile}">
            <button (click)="backPage()" mat-icon-button class="btn-back" [ngClass]="{'w-100px':!layoutService.isMobile, ' w-100':layoutService.isMobile}">
                <mat-icon class="icon-back">west</mat-icon> Voltar
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pt-2 " *ngFor="let topic of topics" >
            <button (click)="selectTopic(topic.id, topic.name)" class="decoration-none p-0 w-100 text-left">
                <mat-card class="border-topic">
                    <mat-card-content class="d-flex" [ngClass]="{'p-topic': layoutService.isMobile}">
                        <mat-icon [ngClass]="{'text-color-accent': topic.totalAssistido === topic.totalAulas}">book</mat-icon>
                        <span class="pl-2 pt-1 f-title"><b>{{topic.name}}</b></span>
                        <span class="f-aulas" [ngClass]="{'rt-aulas':!layoutService.isMobile, 'lt-aulas':layoutService.isMobile}">
                            <span class=" text-color-accent" *ngIf="topic.totalAssistido === topic.totalAulas">Completo</span>
                            <span *ngIf="topic.totalAssistido != topic.totalAulas">{{topic.totalAssistido}}/{{topic.totalAulas}} aulas assistidas</span>
                        </span>
                        <button (click)="selectTopic(topic.id)" class="btn-next" nbButton size="small"><mat-icon class="mr--2">navigate_next</mat-icon></button>
                    </mat-card-content>
                </mat-card>
            </button>
        </div>
    </div>
</div>