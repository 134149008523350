import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LibDisciplines, libTheme } from '../lib-interfaces';
import { MyExerciseLibraryService } from '../my-exercise-library.service';

@Component({
  selector: 'app-create-theme-lib',
  templateUrl: './create-theme-lib.component.html',
  styleUrls: ['./create-theme-lib.component.scss']
})
export class CreateThemeLibComponent implements OnInit {

  public disciplines: LibDisciplines[];
  public selectDiscipline: number
  public themeName: string;
  public themes: libTheme[];
  public selectTheme: number;
  public subThemeName: string;;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public themeConfig: any,
    @Optional() public dialogRef: MatDialogRef<CreateThemeLibComponent>,
    private libraryService: MyExerciseLibraryService,
    private alertService: AlertService,
    public deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.getDisciplines();
    this.getThemes();
    this.initEditMode();
  }

  getDisciplines() {
    this.libraryService.getDisciplines(0,99999999999999)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as LibDisciplines[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  createTheme(themeName: string, disciplineId: number) {

    this.libraryService.createTheme(themeName, disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          this.alertService.success('Tema cadastrado com sucesso!');
          this.dialogRef.close(true);
        }
        else{
          this.alertService.error(response.error);
        }
      })
  }

  getThemes(){
    this.libraryService.getThemes(0,99999999999)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.themes = response.data as libTheme[];
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  createSubTheme(subThemeName: string , DisciplineId: number , themeId: number ){
    this.libraryService.createSubTheme(subThemeName , DisciplineId , themeId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Sub-tema cadastrado com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  updateTheme(){

    this.themeConfig.theme.name = this.themeName;
    this.themeConfig.theme.discipline_id = this.selectDiscipline;


    this.libraryService.updateTheme(this.themeConfig.theme)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Tema atualizado com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  updateSubTheme(){

    this.themeConfig.subTheme.name = this.subThemeName;
    this.themeConfig.subTheme.discipline_id = this.selectDiscipline;
    this.themeConfig.subTheme.theme_id = this.selectTheme;


    this.libraryService.updateSubTheme(this.themeConfig.subTheme)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Tema atualizado com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  initEditMode(){
    if(this.themeConfig.edit && !this.themeConfig.isSub){
      this.selectDiscipline = this.themeConfig.theme.discipline_id;
      this.themeName = this.themeConfig.theme.name;
    }
    else if(this.themeConfig.edit && this.themeConfig.isSub){
      this.selectDiscipline = this.themeConfig.subTheme.discipline_id;
      this.subThemeName = this.themeConfig.subTheme.name;
      this.selectTheme = this.themeConfig.subTheme.theme_id;
    }
  }

}
