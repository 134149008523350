import { ReportStudent } from './reportStudent';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SummationReportService {

  private API = environment.ApiUrl;
  public emitSaveIsDone = new EventEmitter<string>();

  constructor(
      private http: HttpClient,
      private alertService: AlertService) { }

  emitEditIsDone(){
    this.emitSaveIsDone.emit('done'); 
  }
  getStudentsSimulated(simulated: number, planId:number){
    return this.http.post(this.API + '/report/getStudentsSimulated', { simulated, planId }, { observe: 'response' });
  }
  getReportMockSomatoria(mockId: number, userId:number){
    return this.http.post(this.API + '/report/getReportMockSomatoria', { mockId, userId }, { observe: 'response' });
  }
  getReportMockClassUnicSomatoria(mockId: number, classId:number){
    return this.http.post(this.API + '/report/getReportMockClassUnicSomatoria', { mockId, classId }, { observe: 'response' });
  }
  getClassStudent(studentId:number){
    return this.http.post(this.API + '/student/getClassStudent', { studentId }, { observe: 'response' });
  }
  getStudentClassName(studentId:number){
    return this.http.post(this.API + '/student/getStudentClassName', { studentId }, { observe: 'response' });
  }
  getUnitsSimulated(simulated:number){
    return this.http.post(this.API + '/mock/getUnitsSimulated', { simulated }, { observe: 'response' });
  }
  getStudentName(studentId:number){
    return this.http.post(this.API + '/student/getStudentName', { studentId }, { observe: 'response' });
  }
  getReportTxt(reportStudent:ReportStudent){
    return this.http.post(this.API + '/report/getReportTxt', reportStudent , { observe: 'response' });
  }

}
