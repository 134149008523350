<div *ngIf="!showCreditsList">
    <form [formGroup]="addCreditsForm">
        <div class="row">
            <div class="col-md" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" formControlName="schoolId">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Link Iframe" value="value" formControlName="link">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row mt-5">
        <div class="col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Créditos Cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>
</div>

<div *ngIf="showCreditsList" class="">
    <div class="row">
        <div class="col-md-12" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Escola" [(ngModel)]="schoolId" (selectionChange)="getEssayCredits($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-list class="width-90 mt-3 center">
        <ng-container *ngFor="let iframe of iframeCredit">
            <div mat-list-item class="row" style="min-height: 100px">
                <div class="col-8 align-middle">
                    <a href="{{iframe.link}}" target="_blank" class="custom-chip">
                        <span>{{iframe.name}}</span>
                    </a>
                </div>
                <!-- <div class="col-2 text-right align-middle">
                    <button (click)="editIframe(iframe)" nbButton size="tiny" outline status="primary">Editar</button>
                </div> -->
                <div class="col-2 text-right align-middle">
                    <button size="tiny" (click)="removeIframe(iframe.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!iframeCredit">
            <span>Não existe link cadastrado.</span>
        </ng-container>
    </mat-list>

    <div class="text-right width-100">
        <button (click)="showCreditsList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>
