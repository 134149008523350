import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../payment.service';
import { UserService } from '../../user/user.service';
import { Payment } from '../payment';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
    selector: 'app-payment-payments',
    templateUrl: './payment-payments.component.html',
    styleUrls: ['./payment-payments.component.scss']
})
export class PaymentPaymentsComponent implements OnInit {

    payments: Payment[];
    dataSource: MatTableDataSource<Payment>;
    displayedColumns: string[];

    constructor(
        private paymentService: PaymentService,
        private userService: UserService,
        private alertService: AlertService,
        public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.displayedColumns = [
            'register',
            'planName',
            'status',
            'expire',
        ];

        this.getPayments(this.userService.getUserId());
    }

    getPayments(userId: number) {
        this.paymentService.getPayments(userId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.payments = response.data as Payment[];
                    this.dataSource = new MatTableDataSource(this.payments);

                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os pagamentos. Verifique a conexão e tente novamente'));
    }
}
