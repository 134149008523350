<div *ngIf="!showList">
    <form [formGroup]="form">
        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Sala de aula</h4>
        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Link</mat-label>
                    <input matInput placeholder="Link" formControlName="link">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" formControlName="schoolId" (selectionChange)="getUnitsSelection($event.value)">
                        <mat-option *ngFor="let school of schoolsSelect" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col" *ngIf="userService.isAdmin() || this.userService.isGeneralCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Unidade" formControlName="unitId" (selectionChange)="getClassesSelection($event.value)">
                        <mat-option *ngFor="let unit of unitsSelect" [value]="unit.id">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" formControlName="classId">
                        <mat-option *ngFor="let class of classesSelect" [value]="class.id">
                            <ng-container>{{class.name}}</ng-container>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-3">
                <button class="m-3 bg-color-primary border-primary" (click)="openSelectPlans()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Associar ao plano</button>
            </div>
            <div *ngIf="selectedPlans?.length > 0" class="col-md-9">
                <mat-label class="m-1" status="primary">Planos selecionados</mat-label>
                <mat-chip-list class="pt-3">
                    <mat-chip *ngFor="let plan of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(plan)">
                        {{plan.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
              </div>
        </div>
    </form>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges(class)" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>

<div *ngIf="showList">
    <div class="row">
        <div class="col-md-6" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getUnitsSelection($event.value); getPlansSelection($event.value);" placeholder="Escola" [(ngModel)]="selectedSchoolId">
                    <mat-option *ngFor="let school of schoolsSelect" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userService.isGeneralCoordinator() || userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Unidade" (selectionChange)="getClassesSelection($event.value)" [(ngModel)]="selectedUnitId">
                    <mat-option *ngFor="let unit of unitsSelect" [value]="unit.id">
                                {{unit.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-select placeholder="Turma" [(ngModel)]="selectedClassId" >
                    <mat-option *ngFor="let class of classesSelect" [value]="class.id">
                        {{class.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-select placeholder="Planos" (selectionChange)="getPlansSelection($event.value); getPlanLandingPages($event.value)" [(ngModel)]="selectedPlanId">
                    <mat-option *ngFor="let plan of plansSelect" [value]="plan.id">
                        {{plan.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <nb-list class="width-100 mt-2">
        <nb-list-item *ngFor="let lp of lps">
            <div class="row">
                <div class="col-md-4">
                    {{lp.nome}}
                </div>
              <!--   <div class="col-md-4">
                   <span class="hidden">Escola: {{lp.nome_escola}} - <br></span> Turma: {{lp.nome_turma}}
                </div> -->
                <div class="col-md-6">
                    <span *ngIf="lp.token" class="token">
                        <button (click)="copyText(lp.token)" value="click to copy" >{{lp.token}}</button>
                       
                        <nb-icon *ngIf="copy" icon="checkmark-circle-2-outline" matTooltipClass="custom-tooltip" matTooltip="Copiado!"></nb-icon>
                    </span>
                    <span *ngIf="!lp.token">Nenhum token existente</span>
                </div>
                <div class="col-2 text-right">
                    <span class="d-flex">
                        <button size="tiny" *ngIf="lp.enable" status="success" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Ativar Landing Page">
                            <nb-icon icon="checkmark-circle-2-outline" (click)="enableLandingPage(lp.id, lp.token)"></nb-icon>
                        </button>
                        <button size="tiny" *ngIf="!lp.enable" status="secondary" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Desativar Landing Page">
                            <nb-icon icon="slash-outline" (click)="disableLandingPage(lp.id, lp.token)"></nb-icon>
                        </button>
                        <button size="tiny" status="danger" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Excluir Landing Page">
                            <nb-icon icon="trash" (click)="removeLandingPage(lp.id)"></nb-icon>
                        </button>
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="!lp.id"><div class="col-12">Nenhum resultado</div></div>
        </nb-list-item>
    </nb-list>

    <div class="row">
        <div class=" col-6 width-100">
            <button nbButton size="small" outline status="primary" (click)="showList = false;">Voltar</button>
        </div>
    </div>
</div>