<div *ngIf="!showTeachersList">
    <form [formGroup]="createTeacherForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Professor</h4>

        <!-- Name, profile -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Nível de permissão" formControlName="permissionId">
                        <mat-option *ngFor="let permission of permissions" [value]="permission.id"  matTooltipClass="custom-tooltip" [matTooltip]="permission.desc">
                            {{permission.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- login, password -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="E-mail" formControlName="email">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Senha" formControlName="password">
                </mat-form-field>
            </div>
        </div>

        <!-- school, unit -->
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getUnits($event.value); getDisciplines($event.value); getPlans($event.value);" formControlName="schoolId" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="userService.isGeneralCoordinator() || userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="unitId" (selectionChange)="getClasses($event.value)" placeholder="Unidade">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <div class="row">
                                <div class="col-10">
                                    <span [title]="unit.name">{{unit.name}}</span> 
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- classes -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="classId" placeholder="Turmas" (selectionChange)="getClassFolders($event.value)">
                        <mat-option (click)="addClassChip(class)" *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-chip-list *ngIf="selectedClasses">
                    <mat-chip *ngFor="let classChip of selectedClasses" selectable="true" removable="true" (removed)="removeClassChip(classChip)">
                        {{classChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div class="col-md-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select [(ngModel)]="foldersModel" [ngModelOptions]="{standalone: true}" placeholder="Pastas" (selectionChange)="folderSelectionChange($event.value)" multiple [disabled]="!createTeacherForm.get('schoolId').value">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar pasta" noEntriesFoundLabel="Pasta não encontrada" [formControl]="folderFilter" [searching]="filtering">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let folder of filteredFolders | async" [value]="folder.id">
                                <span [title]="folder.name">{{folder.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedFolders?.length > 0">
                    <mat-label class="mb-2">Pastas selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedFolders">
                        <mat-chip *ngFor="let folderChip of selectedFolders" selectable="true" removable="true" (removed)="removeFolderChip(folderChip)">
                            {{folderChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select [(ngModel)]="plansModel" [ngModelOptions]="{standalone: true}" placeholder="Planos" (selectionChange)="planSelectionChange($event.value)" multiple [disabled]="!createTeacherForm.get('schoolId').value">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar plano" noEntriesFoundLabel="Plano não encontrado" [formControl]="planFilter" [searching]="filtering">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let plan of filteredPlans | async" [value]="plan.id">
                                <span [title]="plan.name">{{plan.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedPlans?.length > 0">
                    <mat-label class="mb-2">Planos selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedPlans">
                        <mat-chip *ngFor="let planChip of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(planChip)">
                            {{planChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select [(ngModel)]="plansModelView" [ngModelOptions]="{standalone: true}" placeholder="Planos Liberados apenas Para Visualização" (selectionChange)="planViewSelectionChange($event.value)" multiple [disabled]="!createTeacherForm.get('schoolId').value">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar plano" noEntriesFoundLabel="Plano não encontrado" [formControl]="planFilterView" [searching]="filtering">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let plan of filteredPlansView | async" [value]="plan.id">
                                <span [title]="plan.name">{{plan.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedPlansView?.length > 0">
                    <mat-label class="mb-2">Planos selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedPlansView">
                        <mat-chip *ngFor="let planChip of selectedPlansView" selectable="true" removable="true" (removed)="removeViewPlanChip(planChip)">
                            {{planChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <!-- disciplines, plans-->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select #disciplineSelect [(ngModel)]="disciplinesModel" [ngModelOptions]="{standalone: true}" placeholder="Disciplinas" (selectionChange)="disciplineSelectionChange($event.value)" multiple [disabled]="!createTeacherForm.get('schoolId').value">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar disciplina" noEntriesFoundLabel="Disciplina não encontrada" [formControl]="disciplineFilter" [searching]="filtering">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let discipline of filteredDisciplines | async" [value]="discipline.id">
                                <span [title]="discipline.name">{{discipline.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedDisciplines?.length > 0">
                    <mat-label class="mb-2">Disciplinas selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedDisciplines">
                        <mat-chip *ngFor="let disciplineChip of selectedDisciplines" selectable="true" removable="true" (removed)="removeDisciplineChip(disciplineChip)">
                            {{disciplineChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </form>


    <div *ngIf="!editMode" class="row mt-5">
        <div class="col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Professores
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>

<div *ngIf="showTeachersList" class="">

    <div class="row mt-3">
        <div class="col-md-6" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-label>Escola</mat-label>
                <mat-select (selectionChange)="getTeachers($event.value)" placeholder="Escola">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="row">
                <div class="col-9">
                    <mat-form-field class="width-100">
                        <input #filterEmailRef matInput placeholder="Buscar por e-mail">
                    </mat-form-field>
                </div>
                <div class="col">
                    <button (click)="getTeacherByEmail(filterEmailRef.value)" class="right" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>


    <nb-list class="width-90 mt-3 center" *ngIf="!loading">
        <nb-list-item *ngFor="let teacher of teachers">
            <div class="row">
                <div class="col-4">
                    {{teacher.name}}
                </div>
                <div class="col-4">
                    criado em {{teacher.createDate | date:'dd/MM/yyyy'}}
                </div>
                <div class="col-2 text-right">
                    <button (click)="edit(teacher)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-2 text-right">
                    <button *ngIf="!userService.isUnitCoordinator()" size="tiny" (click)="remove(teacher.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <mat-spinner *ngIf="loading" class="mt-5 center"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showTeachersList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>