import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';

import { ExercisesListService } from '../../exercises-list/exercises-list.service';
import { UserService } from '../../user/user.service';
import { Discipline } from '../discipline/discipline';
import { DisciplineService } from '../discipline/discipline.service';
import { School } from '../school/school';
import { SchoolService } from '../school/school.service';
import { StudyPlanService } from '../study-plan/study-plan.service';
import { ExerciseList } from './exercise-list';

@Component({
    selector: 'app-create-exercise-list',
    templateUrl: './create-exercise-list.component.html'
})
export class CreateExerciseListComponent implements OnInit {

    public createExerciseListForm: FormGroup;
    public disciplines: Discipline[];
    public lists: ExerciseList[];
    public showList: boolean;
    public saving: boolean;
    public editMode: boolean;
    public file;
    public loading: boolean;
    public schools : School[];
    public selectedSchool: number;
    public blockSchoool:boolean = false;
    public getListName: string;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private disciplineService: DisciplineService,
        private exerciseListService: ExercisesListService,
        public userService: UserService,
        private schoolService: SchoolService,
        private studyPlanService: StudyPlanService) { }

    ngOnInit(): void {
        this.createExerciseListForm = this.formBuilder.group({
            id: [],
            name: ['', Validators.required],
            discipline: ['', Validators.required],
            file: ['', Validators.required],
            schoolId: [Validators.required]
        });

        if (this.userService.isAdmin()) {
            //this.getDisciplines();
        } else if (this.userService.isGeneralCoordinator()) {
            this.getSchoolDisciplines(this.userService.getUserSchool());
        } else if (this.userService.isUnitCoordinator()) {
            this.getUnitDisciplines(this.userService.getUserUnit());
        }

        if(this.userService.isAdmin()){
            this.getSchools();
        }
    }
    hasSchool(){
        this.blockSchoool = !this.blockSchoool;
        //console.log(this.blockSchoool);
    }

    getDisciplines() {
        this.disciplineService.getDisciplines()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as disciplínas. Verifique a conexão e tente novamente'));
    }

    getSchoolDisciplines(schoolId: number) {
        this.studyPlanService.getSchoolDisciplines(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }

    private getUnitDisciplines(unitId: number) {
        this.schoolService.getUnitDisciplines(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
    }

    handleFileInput(files: FileList): void {
        if (files && files[0]) {

            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.file = reader.result;
            };
        }
    }

    getSchools(){
        this.schoolService.getSchools()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.schools = response.data as School[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
    }

    createExerciseList() {
        this.createExerciseListForm.get('file').setValue(this.file);

        if (this.createExerciseListForm.valid) {
            this.saving = true;
            let exerciseList = this.createExerciseListForm.getRawValue() as ExerciseList;

            if(!this.userService.isAdmin()){
                exerciseList.schoolId = this.userService.getUserSchool();
                this.blockSchoool = true;
            }
            if(this.blockSchoool === false){
                exerciseList.schoolId = null;
            }
            this.exerciseListService.createExerciseList(exerciseList)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Lista adicionada com sucesso!');
                        this.createExerciseListForm.reset();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.saving = false;
                }, err => {
                    this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente');
                    this.saving = false;
                });

        } else {
            this.alertService.error('Preencha todos os campos corretamente');
        }
    }

    getExerciseLists(schoolId: number) {
        if (!this.userService.isAdmin()) {
            schoolId = this.userService.getUserSchool();
        }
        this.loading = true;
        this.exerciseListService.getExerciseLists(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.lists = response.data as ExerciseList[];
                } else {
                    this.alertService.error(response.error);
                    if ( Array.isArray(response.data) && response.data.length === 0) {
                      this.lists = [];
                    }
                }
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as listas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    removeList(listid: number) {
      if(confirm('Tem certeza que deseja remover?')){
        this.exerciseListService.deleteExerciseList(listid)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.alertService.success('Lista Removida!');

                if(!this.userService.isAdmin()){
                    this.getExerciseLists(this.userService.getUserSchool());
                }
                else{
                    this.getExerciseLists(this.selectedSchool)
                }
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
      }
    }

    link(link: string) {
        window.open(link, '_blank');
    }

    openList() {
        this.getExerciseLists(0);
        this.showList = true;
    }

    getListNamechecker(listName: string){
        if(this.userService.isAdmin()){
            this.getListByName(listName);
        }
        else{
            this.getListByNameCoord(listName);
        }
    }

    getListByName(listName: string){
        this.exerciseListService.getListByName(listName)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.lists = response.data as ExerciseList[];
            }
            else{
                this.alertService.error(response.error);
            }
        },err => {
            this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
        })
    }

    getListByNameCoord(listName: string){
        this.exerciseListService.getListByNameCoord(listName , this.userService.getUserSchool())
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.lists = response.data as ExerciseList[];
            }
            else{
                this.alertService.error(response.error);
            }
        },err => {
            this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
        })
    }



}
