import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SchoolService } from '../../../school/school.service';
import { EngagedStudents } from '../../dashboard';
import { DashboardService } from '../../dashboard.service';
import { School } from '../../../school/school';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})

export class StudentsComponent implements OnInit {

  displayedColumns: string[];
  dataSource: MatTableDataSource<EngagedStudents>;
  public students: EngagedStudents[];
  public schoolId: number;
  public startDate: string;
  public endDate: string;
  public schools: School[]
  protected onDestroy = new Subject<void>();
  private eventSubscription: Subscription;
  constructor(
    public userService: UserService,
    public alertService: AlertService,
    public layoutService: LayoutService,
    public schoolService: SchoolService,
    public dashboardService: DashboardService,
  ) { }

  ngOnInit() {
    this.displayedColumns = ['number', 'name', 'engagement', 'lastAcess'];
    if (!this.userService.isAdmin()) {
      this.getLastEngagement(this.userService.getUserSchool());
    }
    else {
      this.getLastEngagement(this.userService.getUserSchool());
    }

    this.dashboardService
      .getSchoolId()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((schoolId) => {
        this.schoolId = schoolId;
      });

    this.dashboardService
      .getStarDate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((start) => {
        this.startDate = start;
      });

    this.dashboardService
      .getEndDate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((end) => {
        this.endDate = end;
      });

    this.eventSubscription = this.dashboardService.onEventDashboardStudent().subscribe(eventData => {
      if(this.userService.isAdmin()){
        this.getEngagements();
      }
    });


    this.getSchools();
  }

  public getEngagements() {

    this.dashboardService
      .getSchoolId()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((schoolId) => {
        this.schoolId = schoolId;
      });

    this.dashboardService
      .getStarDate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((start) => {
        this.startDate = start;
      });

    this.dashboardService
      .getEndDate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((end) => {
        this.endDate = end;
      });

    if (this.startDate && this.endDate) {
      this.getLastEngagementWithDate(this.schoolId, this.startDate ,this.endDate);
    }
    else {
      this.getLastEngagement(this.schoolId);
    }

  }


  getLastEngagement(SchoolId: number) {
    this.dashboardService.getLastEngagement(SchoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.students = response.data as EngagedStudents[];
          //console.log(this.students);
          this.dataSource = new MatTableDataSource(this.students);
        }
        else {
          //this.alertService.error(response.error);
          //this.students = [];
          this.dataSource = new MatTableDataSource(this.students);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os alunos mais engajados. Verifique a conexão e tente novamente!'));
  }

  getLastEngagementWithDate(SchoolId: number, startDate: string, endDate: string) {
    this.dashboardService.getLastEngagementWithDate(SchoolId , startDate , endDate)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.students = response.data as EngagedStudents[];
          //console.log(this.students);
          this.dataSource = new MatTableDataSource(this.students);
        }
        else {
          this.alertService.error(response.error);
          this.students = [];
          this.dataSource = new MatTableDataSource(this.students);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os alunos mais engajados. Verifique a conexão e tente novamente!'));
  }

  getSchools() {
    this.schoolService.getSchoolSelection()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.schools = response.data as School[];
        else this.alertService.error(response.error);
      }, err => {
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
      });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }
}
