import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { UserService } from '../user/user.service';
import { Video } from '../video/video';
import { VideoService } from '../video/video.service';


@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {

  public rating: number;
  public starCount: number;
  public rattingArr: boolean[];
  public loading: boolean;
  public description;
  public starForm: FormGroup;
  public isEdit: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public video: any,
    @Optional() public dialogRef: MatDialogRef<StarRatingComponent>,
    private userService: UserService,
    private videoService: VideoService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public layoutService : LayoutService) {
    this.rattingArr = Array(this.starCount).fill(false)
  }

  ngOnInit() {
    this.isEdit = false;
    this.loading = false;
    this.rating = 0;
    this.starCount = 5;
    this.rattingArr = [];
    this.rattingArr = Array(this.starCount).fill(false)

    this.starForm = this.formBuilder.group({
      videoId:        [this.video.id],
      userId:         [this.userService.getUserId()],
      rating:         [this.rating],
      videoName:      [ this.video.title],
      description: ['', this.description]
    });

    if(this.video.edit){
      this.isEdit = true;
      this.starForm.patchValue(this.video.rating);
      this.rating = this.video.rating.starScore;
    }

  }

  returnStar(i: number) {
    if (this.rating >= i + 1) {
      return 'star';
    }
    else {
      return 'star_border';
    }
  }

  clickRating(i: number) {
    this.rating = i + 1;
  }

  createRating(){
    this.videoService.ratingVideo(this.video.id , this.rating , this.description , this.userService.getUserId(), this.video.title)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('obrigado por enviar sua avaliação');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  updateRating(){
    const description = this.starForm.get('description').value;
    this.videoService.updateRating(this.video.rating.id , this.rating , description)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.alertService.success('Avaliação atualizada com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }
  
  closeDialogRef(){
    this.dialogRef.close(true);
  }

}
