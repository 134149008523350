import { map } from 'rxjs/operators';
import { Component, Inject, OnInit, Optional, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationBell, Notifications } from 'src/app/modules/admin/notifications/notifications';
import { NotificationsComponent } from '../notifications.component';
import { NotificationsService } from '../notifications.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ModalCentralComponent } from '../modal-central/modal-central.component';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { NotificationStore } from '../notifications.store';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-bell',
  templateUrl: './modal-bell.component.html',
  styleUrls: ['./modal-bell.component.scss']
})
export class ModalBellComponent implements OnInit {
 
  public read: boolean;
  public limited: boolean;
  public active: boolean;
  public notifications: Notifications[] = [];
  @ViewChildren('player_container') playerContainer;
  
  constructor(  
    @Optional() @Inject(MAT_DIALOG_DATA) public data: NotificationStore,
    @Optional() public dialogRef: MatDialogRef<ModalBellComponent>,
    private dialog: MatDialog,
    private adminService: AdminService,
    public notificationsService: NotificationsService,
    public userService: UserService,
    public layoutService: LayoutService,
    private alertService: AlertService,
    public deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
   
    this.notifications = this.data.getAll();
    this.notifications.forEach(element => {
      if(element.video){
        element.linkSanityzed = this.sanitizer.bypassSecurityTrustResourceUrl(element.video);
      }
    });
    
    this.notificationsService.emitCheckNotifications.emit(this.notifications.length);
    
  }

  removeNotification(id:number){
    this.data.remove(id);
    this.notifications = this.data.getAll();
    this.notificationsService.emitCheckNotifications.emit(this.notifications.length);
  }

  markAsRead(notification: Notifications){
    const userId = Number(this.userService.getUserId());
    notification.read = notification.read ? false : true;
    this.notificationsService.markAsRead(notification, userId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        setTimeout(() => {
          this.removeNotification(notification.id);
        }, 1000);       
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao salvar a notificação. Verifique a conexão e tente novamente'));
  }


  viewMore(notification: NotificationBell){
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ModalCentralComponent, {
      data: notification,
      maxWidth: '800px',
      maxHeight: '600px',
      panelClass: 'custom-dialog-container',
    }); 

    dialogRef.afterClosed().subscribe(result => {
      this.markAsRead(notification);
    }); 
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
