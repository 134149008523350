<div>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6 col-xl-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" (selectionChange)="getUnits($event.value)">
                        <mat-option>Selecione uma escola</mat-option>
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Unidade" (selectionChange)="getTeacherUnit($event.value)">
                        <mat-option>Selecione uma unidade</mat-option>
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-6" *ngIf="!userService.isTeacher()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Professor" (selectionChange)="getClassesByTeacher($event.value)">
                        <mat-option>Selecione um professor</mat-option>
                        <mat-option *ngFor="let teacher of teachers" [value]="teacher.teacherId">
                            <span [title]="teacher.teacherName">{{teacher.teacherName}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" formControlName="classId" (selectionChange)="getTasks($event.value)">
                        <mat-option>Selecione uma turma</mat-option>
                        <mat-option *ngFor="let class of classes" [value]="class">
                            <span [title]="class.name">{{class.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" formControlName="planId" (selectionChange)="getTasksSubmittedByPlan($event.value)">
                        <mat-option>Selecione um plano</mat-option>
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            <span [title]="plan.name">{{plan.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="userService.isUnitCoordinator() || userService.isGeneralCoordinator()" class="row mt-3">
            <div class="col-md-3">
                <button class="m-3" (click)="getTasksCoord()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning" class="right bg-color-accent-300 border-accent">Ver Minhas Tarefas Cadastradas</button>
            </div>
        </div>
    </form>
    <div *ngIf="tasks.length > 0">
        <mat-list class="width-100 mt-3 center">
            <div class="row">
                <div class="col-3"><b>Tarefa</b></div>
                <div class="col-4"><b>Turma/Plano</b></div>
                <div class="col-3"><b>Data</b></div>
                <div class="col-1"></div>
                <div class="col-1"></div>
            </div>
        </mat-list>
        <mat-list class="width-100 mt-3 center" *ngFor="let task of tasks">
            <div mat-list-item class="row" style="min-height: 100px">
                <div class="col-3">
                    <a class="hover" matTooltipClass="custom-tooltip" matTooltip="Abrir arquivo" (click)="openLink(task.taskLink)"> {{task.taskName}}</a>
                </div>
                <div class="col-4" *ngIf="task.className">
                    {{className}}
                </div>
                <div class="col-4" *ngIf="!task.className">
                    {{planName}}
                </div>
                <div class="col-3">
                    {{task.taskDate | date: 'dd/MM/yyyy hh:mm'}}
                    <!-- | date: 'dd/MM/yyyy'-->
                </div>
                <div class="col-1">
                    <button color="primary" mat-icon-button (click)="editTasks(task)" class="mr-3 hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Editar">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div class="col-1 text-right">
                    <button size="tiny" (click)="remove(task.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </mat-list>
    </div>
    <div *ngIf="!tasks.length"> <div class="row"> <div class="col-12"> Nenhum resultado! </div> </div> </div>
    <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
</div>
