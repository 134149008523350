<div class="text-center mat-elevation-z2">
    <div class="discipline-header bg-color-primary">{{disciplineReport.name | uppercase}}</div>
    <div class="discipline-content mt-3">

        <div class="two-grid">
            <div class="g-column">
                <span>
                    <button mat-mini-fab color="primary" class="hvr-grow">
                        <nb-icon icon="person-outline"></nb-icon>
                    </button>
                </span>
                <span class="text-color-primary mt-2 mb-2 label">EU</span>
            </div>
            <div class="g-column">
                <span>
                    <button mat-mini-fab color="primary" class="hvr-grow">
                        <nb-icon icon="people-outline"></nb-icon>
                    </button>
                </span>
                <span class="text-color-primary mt-2 mb-2 label">TURMA</span>
            </div>
        </div>

        <div class="two-grid">
            <div class="g-column bg hvr-grow">
                <span class="text-color-primary" matTooltipClass="custom-tooltip" matTooltip="Meus Acertos">
                    <b>{{disciplineReport.studentCorrect}}</b>
                    <nb-icon icon="checkmark-outline" class="hvr-buzz-out"></nb-icon>
                </span>

            </div>
            <div class="g-column bg hvr-grow">
                <span class="text-color-primary" matTooltipClass="custom-tooltip" matTooltip="Acertos da Turma">
                    <b>{{disciplineReport.classCorrect}}</b>
                    <nb-icon icon="checkmark-outline" class="hvr-buzz-out"></nb-icon>
                </span>
            </div>
        </div>
        <div class="two-grid">
            <div class="g-column bg hvr-grow">
                <span class="text-color-primary" matTooltipClass="custom-tooltip" matTooltip="Meus Erros">
                    <b>{{disciplineReport.studentError}}</b>
                    <nb-icon icon="close-outline" class="hvr-buzz-out"></nb-icon>
                </span>
            </div>
            <div class="g-column bg hvr-grow">
                <span class="text-color-primary" matTooltipClass="custom-tooltip" matTooltip="Erros da Turma">
                    <b>{{disciplineReport.classError}}</b>
                    <nb-icon icon="close-outline" class="hvr-buzz-out"></nb-icon>
                </span>
            </div>
        </div>
    </div>

</div>