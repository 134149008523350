import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import { StudyPlan } from '../study-plan/study-plan';
import { Plan } from 'src/app/modules/payment/plan';
import { StudyPlanService } from '../study-plan/study-plan.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PaymentService } from '../../payment/payment.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { UserService } from '../../user/user.service';
import { SchoolService } from '../school/school.service';
import { School } from '../school/school';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

interface Holydays {
  date: string;
  studyPlan: number;
} 
interface StudyPlans {
  name: string;
  id: number;
} 
interface DateArray{
  date: string;
}


@Component({
  selector: 'app-holydays',
  templateUrl: './holydays.component.html',
  styleUrls: ['./holydays.component.scss']
})

export class HolydaysComponent implements OnInit {


  public holydaysForm: FormGroup;
  public holyDays: Holydays[] = null;
  public studyPlans: StudyPlan[];
  public plans: Plan[];
  public editMode: boolean = false;
  public filteredPlans: Plan[];
  public showHolyDaysList: boolean = false;
  public loading: boolean;
  public deleting: boolean;
  public holyDaysFilter: number;
  public schools: School[];
  public saving: boolean;
  public studyPlanArray: StudyPlans[] = [];
  public dateArray: DateArray[] = [];

  constructor(
    private adminService: AdminService,
    private studyPlanService: StudyPlanService,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private alertService: AlertService,
    public maskService: MaskService,
    private schoolService: SchoolService,
    public userService: UserService) { }

  

  ngOnInit(): void {
    //this.getStudyPlans();
     
    this.holydaysForm = this.formBuilder.group({
      studyPlan: ['', Validators.required],
      date: ['', Validators.required],
    })

    this.profileInit(); 

  }
  profileInit() {

      if (this.userService.isAdmin()) {
          this.getSchools();
      } else
          if (this.userService.isGeneralCoordinator()) {
            this.getStudyPlans(this.userService.getUserSchool()); 
          }
  }

  getHolyDays(studyPlanId: number) {
    this.loading = true;
     this.adminService.getHolyDays(studyPlanId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.holyDays = response.data as Holydays[];               
            } else {
                this.holyDays = null;
                this.alertService.error(response.error);
            }

            this.loading = false;
        }, err => {
            this.loading = false;
            this.alertService.error('Houve um erro ao carregar os feriados. Falha na comunicação com a API');
        }); 

    this.loading = false; 
      
 }

  createHolyDays(){
    
    if (this.dateArray.length > 0 && this.studyPlanArray.length > 0){

     
        let dates:any =[];
        this.dateArray.forEach(element => {
          dates.push(element.date);
        });  

        let studyPlansId:any  = [];
        this.studyPlanArray.forEach(element => {
          studyPlansId.push(element.id);
        });
       
    
   
      this.adminService.createHolyDays(dates, studyPlansId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              this.alertService.success('Feriado adicionado');
              this.studyPlanArray = [];
              this.dateArray = [];
              this.holydaysForm.reset();
            } else {
                this.alertService.error(response.error);
            }

            this.loading = false;
        }, err => {
            this.loading = false;
            this.alertService.error('Houve um erro ao carregar os feriados. Falha na comunicação com a API');
        }); 
      this.loading = false;
    }else{
      this.alertService.error('Digite uma data e um plano de estudos para cadastar.');
    }
  }

  removeHolyDays(holyDayId: number){
    if (confirm('Deseja realmente remover o feriado?')) {
      this.deleting = true;
      this.adminService.removeHolyDays(holyDayId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.alertService.success('Feriado removido!');
                this.getHolyDays(this.holyDaysFilter);
            } else {
                this.alertService.error(response.error);
            }

            this.deleting = false;
        }, err => {
            this.alertService.error('Houve um erro ao excluir o feriado. Falha na comunicação com a API');
            this.deleting = false;
        });
    }
  }

  getStudyPlanSchool(schoolId: number){ 
   
      this.loading = true;
      this.adminService.getHolyDays(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.studyPlans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar os planos de estudo. Verifique a conexão e tente novamente')
      });
   
  }

  getStudyPlans(schoolId?: number) {
    this.studyPlanService.getSelectPlans(schoolId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.studyPlans = response.data as StudyPlan[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os planos de estudo. Falha na comunicação com a API'));
  }

  openList() {
     
    //this.getSchools(); 
    this.showHolyDaysList = true;  
  }

  saveHolyDays(data:string, studyPlanId:number){
 

    this.adminService.saveHolyDays(data, studyPlanId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              this.alertService.success('Feriado salvo');
              this.holydaysForm.reset();
            } else {
                this.alertService.error(response.error);
            }

            this.loading = false;
        }, err => {
            this.loading = false;
            this.alertService.error('Houve um erro ao carregar os feriados. Falha na comunicação com a API');
        }); 

    this.loading = false;
  }

  getSchools() {
    this.schoolService.getSchools()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.schools = response.data as School[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  } 

  removeSelectedStudyPlan(id: number){
  
    this.studyPlanArray.forEach(element => {
      if(element.id == id){
        let index = this.studyPlanArray.indexOf(element);
        this.studyPlanArray.splice(index,1);
      }
     
    }); 
  }
  removeSelectedData(date: string){
  
    this.dateArray.forEach(element => {
      if(element.date == date){
        let index = this.dateArray.indexOf(element);
        this.dateArray.splice(index,1);
      }
     
    }); 
  }
  
  addStudyPlanId( id: number){
    if(this.studyPlanArray.length < 15){
      this.studyPlans.forEach(element => {

        if(element.id == id){
            this.studyPlanArray.push({id:element.id, name: element.name}); 
        }
  
      });
      
    }else{
      this.alertService.error("Você pode selecionar no máximo 15 planos de estudos.");
    }
  }
  addDate( date: string){ 

    let dateExist = false;
    if (this.dateArray.length < 10){    

      this.dateArray.forEach(element => {
        if(element.date == date){
          dateExist = true;
        }
      }); 
    
      if(!dateExist){
        this.dateArray.push({date});  
      } 
    }else{
      this.alertService.error("Você pode selecionar no máximo 10 datas para o feriado.");
    }
   
  }

}
