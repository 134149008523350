<mat-spinner *ngIf="loading" class="center" color="accent"></mat-spinner>
<div class="row">

    <div class="col text-center mat-elevation-z2 p-3 m-2">
        <button mat-mini-fab color="primary" class="mb-3" title="Média entre porcentagem dos vídeos assistidos e os exercícios resolvidos e corretos!">
            <mat-icon>show_chart</mat-icon>
        </button>
        <div class="progress-title text-color-primary mb-3">Desempenho</div>
        <div class="percent-rate text-color-primary mb-3">{{averageData.performance}}%</div>
        <nb-progress-bar [value]="averageData.performance" status="primary" size="tiny" [displayValue]="false">
        </nb-progress-bar>
        <div class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
            É o total de <strong> exercícios resolvidos</strong> no período / pelo <strong> total de questões
                disponíveis</strong>.
        </div>
    </div>

    <div class="col text-center mat-elevation-z2 p-3 m-2">
        <button mat-mini-fab color="primary" class="mb-3" title="Inclui Simulados, Plano de estudo, Redação, Exercícios e Fórum. ">
            <mat-icon>comment</mat-icon>
        </button>
        <div class="progress-title text-color-primary mb-3">Participação</div>
        <div class="percent-rate text-color-primary mb-3">{{averageData.participation}}%</div>
        <nb-progress-bar [value]="averageData.participation" status="primary" size="tiny" [displayValue]="false">
        </nb-progress-bar>
        <div class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
            Porcentagem entre o <strong> número de dias</strong> que o aluno fez <strong> login na plataforma</strong> no período selecionado e a quantidade de <strong> dias letivos </strong> deste mesmo período .
        </div>
    </div>

    <div class="col text-center mat-elevation-z2 p-3 m-2">
        <button mat-mini-fab color="primary" class="mb-3" title="Mostra o progresso no seu plano de estudo! Videos visualizados e exercícios feitos.">
            <mat-icon>calendar_today</mat-icon>
        </button>
        <div class="progress-title text-color-primary mb-3">Plano Estudos</div>
        <div class="percent-rate text-color-primary mb-3">{{averageData.studyPlan}}%</div>
        <nb-progress-bar [value]="averageData.studyPlan" status="primary" size="tiny" [displayValue]="false">
        </nb-progress-bar>
        <div class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
            É a quantidade de<strong> vídeos assistidos </strong> no período / quantidade <strong>total de vídeos
            </strong>disponíveis no mesmo intervalo de tempo.
        </div>
    </div>

    <!-- <div class="col text-center mat-elevation-z2 p-3 m-2">
        <button mat-mini-fab
                color="primary"
                class="mb-3"
                title="Quantidade de acessos por exercícios feitos e vídeos assistidos!">
            <mat-icon>show_chart</mat-icon>
        </button>
        <div class="progress-title text-color-primary mb-3">Frequência</div>
        <div class="percent-rate text-color-primary mb-3">{{average.frequency}}</div>
        <nb-progress-bar [value]="average.frequency" status="primary" size="tiny" [displayValue]="false">
        </nb-progress-bar>
        <div class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
            Quantidade de acessos:  por <strong>exercícios feitos</strong> e <strong>vídeos assistidos</strong> .
        </div>
    </div> -->

    <div class="col text-center mat-elevation-z2 p-3 m-2">
        <button mat-mini-fab color="primary" class="mb-3" title="Esta média é a a porcentagem de nota dos acertos e erros dos exercícios.">
            <mat-icon>show_chart</mat-icon>
        </button>
        <div class="progress-title text-color-primary mb-3">Média (Nota)</div>
        <div class="percent-rate text-color-primary mb-3">{{averageData.average}} de 10</div>
        <nb-progress-bar [value]="averageData.average * 10" status="primary" size="tiny" [displayValue]="false">
        </nb-progress-bar>
        <div class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
            Composta pela <strong>média geral (decimal)</strong> entre <strong>desempenho</strong>,
            <strong>participação</strong> e o <strong>plano de estudos</strong>.
        </div>
    </div>
</div>

<mat-spinner *ngIf="!average" color="primary" class="center mt-4"></mat-spinner>