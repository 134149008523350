<div *ngIf="!showSchoolList">

    <form [formGroup]="editRouteForm">

        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input matInput placeholder=Nome formControlName="descr">
                </mat-form-field>
            </div>
            <!--    <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select formControlName="icon" placeholder="Icone">
                        <mat-option *ngFor="let icon of icons" [value]="icon">
                            <div class="row">
                                <div class="col-10">
                                    <mat-icon>{{icon}}</mat-icon>{{icon}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select formControlName="order" placeholder="Ordem(Opcional)">
                        <mat-option *ngFor="let orderroot of orderroots " [value]="orderroot">
                            <div class="row">
                                <div class="col-10">
                                    {{orderroot}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder=Link formControlName="link">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <div>
                  <mat-form-field class="width-100">
                    <mat-select formControlName="schools" (selectionChange)="addSchollChip($event.value)" placeholder="Escola" multiple>
                        <mat-option  *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
                <div *ngIf="selectedschools?.length > 0 && userService.isAdmin()">
                    <mat-label class="mb-2">Links do menu ocultados</mat-label>
                    <mat-chip-list *ngIf="selectedschools">
                        <mat-chip *ngFor="let school of selectedschools" selectable="true" removable="true" (removed)="removeSChoollChip(school)">
                            <span [title]="school.name">{{school.name}}</span>
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                {{iconSelected.value}}
                <mat-form-field class="width-100">
                    <mat-select #iconSelected formControlName="icon" placeholder="Icone">

                        <mat-select>
                            <mat-icon>{{iconSelected.value}}</mat-icon>
                        </mat-select>
                        <mat-option *ngFor="let icon of icons" [value]="icon">
                            <mat-icon>{{icon}}</mat-icon>{{icon}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row mt-5 text-right">
        <button (click)="saveRouteChanges(route.id)" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>
