<div *ngIf="!showList">
    <form [formGroup]="createExerciseListForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Lista de Exercícios</h4>

        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-12" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100" *ngIf="userService.isAdmin()">
                    <mat-select placeholder="Selecione a escola" formControlName="schoolId" (selectionChange)="getSchoolDisciplines($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <input class="ml-3" *ngIf="!editMode" type="checkbox" (click)="hasSchool()">Cadastrar apenas para esta escola?
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Título" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6 mt-3">
                <mat-form-field class="width-100">
                    <mat-select formControlName="discipline" placeholder="Disciplina">
                        <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                            {{discipline.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12 annex">
                <input (change)="handleFileInput($event.target.files);" class="width-100" accept="" fullWidth status="primary" type="file" nbInput placeholder="Anexar Arquivo">
            </div>
        </div>
    </form>

    <div class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver listas Cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createExerciseList()" class="right bg-color-primary border-primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning" nbButton size="small" status="primary">Cadastrar</button>
        </div>
    </div>
</div>

<div *ngIf="showList" class="">

    <div class="row">
        <div class="col-md-12" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100" *ngIf="userService.isAdmin()">
                <mat-select placeholder="Selecione a escola" [(ngModel)]="selectedSchool" (selectionChange)="getExerciseLists($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12 mt-2">
            <mat-form-field class="width-100">
                <input matInput placeholder="Buscar Listas por nome" [(ngModel)]="getListName">
                <mat-icon (click)="getListNamechecker(getListName)" class="mr-1" matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <nb-card size="medium" class="mt-3">
        <nb-card-body>
            <nb-list *ngIf="!loading" class="width-90 mt-3 center">
                <nb-list-item *ngFor="let list of lists">
                    <div class="row">
                        <div class="col-5">
                            <a class="c-pointer" (click)="link(list.link)">{{list.name}}</a>
                        </div>
                        <div class="col-5">
                            {{list.discipline}}
                        </div>
                        <div class="col-2 text-right">
                            <button size="tiny" (click)="removeList(list.id)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
            <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
        </nb-card-body>
    </nb-card>
    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>
