<div *ngIf="!showList">
    <form [formGroup]="createEbookForm">
        <div class="row mt-2">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Selecione a Escola" formControlName="schoolId" (selectionChange)="getPlans($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Selecione o plano" (selectionChange)="addStudyPlanChip($event.value)">
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="studyPlanArray.length > 0" class="col-md-12 mt-3">
            <mat-label class="mb-2">Plano de estudos selecionados</mat-label>
            <mat-chip-list>
                <mat-chip *ngFor="let studyPlan of studyPlanArray" selectable="true" removable="true" (removed)="removeSelectedStudyPlan(studyPlan.id)">
                    {{studyPlan.name}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>

        <div class="mt-3 row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6 annex mt-4">
                <input (change)="handleFileInput($event.target.files);" class="width-100" accept="" fullWidth status="primary" type="file" nbInput placeholder="Anexar Arquivo">
            </div>
        </div>
    </form>

    <div class="row mt-5">
        <div *ngIf="!editMode" class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Ebooks Cadastrados</button>
        </div>
        <div *ngIf="!editMode" class=" col-6 text-right width-100">
            <button (click)="CreateEBook()" class="right bg-color-primary border-primary" nbButton size="small" status="primary">Cadastrar</button>
        </div>
        <div *ngIf="editMode" class=" col-12 text-right width-100">
            <button (click)="updateEbook()" class="right" nbButton size="small" status="primary">Salvar</button>
        </div>
    </div>
</div>

<div *ngIf="showList">
    <div class="row mt-2">
        <div class="col-md-6" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Selecione a Escola" (selectionChange)="getPlans($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-select placeholder="Selecione o plano" [(ngModel)]="selectedPlanId" (selectionChange)="getEbooks($event.value)">
                    <mat-option *ngFor="let plan of plans" [value]="plan.id">
                        {{plan.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-2 ml-2">
        <div *ngIf="ebooks" class="headerlist width-90">
            <ng-container>
                <nb-list class="width-100">
                    <nb-list-item class="bg-color-accent list-header" style="color: white ">
                        <div class="row">
                            <div class="col-md-9">Nome</div>
                            <div class="col-md-3">Ações</div>
                        </div>
                    </nb-list-item>
                    <nb-list-item *ngFor="let ebook of ebooks">
                        <div class="row">
                            <div class="col-md-9">
                                {{ebook.name}}
                            </div>
                            <div class="col-md-1 mt-2"> <button (click)="openEditMode(ebook)" nbButton size="tiny" outline status="primary">Editar</button></div>
                            <div class="col-md-1"> <button (click)="deleteEbook(ebook.id)" size="small" status="danger" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Excluir turma">
                                    <nb-icon icon="trash"></nb-icon>
                                </button>
                            </div>

                        </div>
                    </nb-list-item>

                </nb-list>
            </ng-container>
        </div>
    </div>
    <div class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="btnBack()" nbButton size="small" outline status="primary">Voltar</button>
        </div>
    </div>
</div>