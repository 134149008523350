import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Classroom } from 'src/app/modules/classroom/classroom';
import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AdminService } from '../../admin.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { StudyPlanService } from '../../study-plan/study-plan.service';

interface Classrooms{
  name: string;
  id: number;
}

@Component({
  selector: 'app-edit-classes-by-class',
  templateUrl: './edit-classes-by-class.component.html',
  styleUrls: ['./edit-classes-by-class.component.scss']
})
export class EditClassesByClassComponent implements OnInit {

  public formEditByClass: FormGroup;
  public classrooms: Classroom[];
  public schools: School[];
  private admUpdate$: Subscription;
  public classroomsArray: Classrooms[] = [];
  public editMode: boolean;
  public classes: Classroom[];
  public selectedClasses: { name: string, id: number };
  public classeId: [];
  public nameClassroom:string;
  public schoolIdPlan: number;
  public schoolSelected: number;
  public planIdSelected: number;
  

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public  data: Classroom[], 
    @Optional() public dialogRef: MatDialogRef<EditClassesByClassComponent>,
    private classroomService: ClassroomService,
    private alertService: AlertService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public maskService: MaskService,
    public userService: UserService,
    private studyPlanService: StudyPlanService,
    private schoolService: SchoolService) {
      this.classes = this.data;
    }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init(){
    this.formEditByClass = this.formBuilder.group({
      classroomId: [],
      initDate: [''],
      endDate: [''],
      link: [''],
      recordLink: ['']
    });
    
  }
  closeDialogRef(){
    this.dialogRef.close();
  }
  
  updateClassrooms(){
    this.classroomsArray.forEach(element => {

      this.formEditByClass.get('classroomId').setValue(element.id);
      const classroom = this.formEditByClass.getRawValue() ;

      this.classroomService.updateClassroomsByClass(classroom)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            //this.classrooms = response.data as Classroom[];
            this.alertService.success('Dados Alterados com sucesso!');
            this.dialogRef.close();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
    }); 
  }

  initializeEditMode() { 
    this.editMode = true;
    this.formEditByClass.patchValue(this.data);
  }

  removeSelectedClass(id: number){
    this.classroomsArray.forEach(element => {
        if(element.id == id){
          let index = this.classroomsArray.indexOf(element);
          this.classroomsArray.splice(index,1);
        }
    }); 
  }

  addClasseId(id:number) { 
    let notFind = false;
    let row = Object.values(this.classes);
    for(var index = 0, total =this.classroomsArray.length; index < total; index++){
      var obj = this.classroomsArray[index];
      if(obj.id == id){
        notFind = true;        
      }      
    }
    if(notFind == false){
      row.forEach(element => {
        if(id == element.id){
          this.nameClassroom = element.name;
        }
      });
      this.classroomsArray.push({"id":id, "name": this.nameClassroom});
    }
  }
}
