<div class="container pt-5 pb-5 width-100">
    <h3 class="mb-5">Relatórios de Degustação</h3>
    <mat-card>
        <mat-card-content>
            <div class="row">
                <div class="col-md-6" *ngIf="userService.isAdmin()">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Escola *" (selectionChange)="getPaymentPlans($event.value)" [(ngModel)]="schoolId">
                            <mat-option *ngFor="let school of schools" (click)="this.actualData.school = school;" [value]=" school.id">{{school.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Plano de Pagamento" [(ngModel)]="planId">
                            <mat-option [value]="studyPlan.id" *ngFor="let studyPlan of studyPlans" (click)="this.actualData.plan = studyPlan;">
                                {{studyPlan.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-12 ">
                <button mat-raised-button color="primary" (click)="getReportTasting(planId)">Atualizar</button>
            </div>
        </mat-card-content>
    </mat-card>

    <!--AQUI VEM O RELATORIO-->
    <div *ngIf="btnAtualizar == true" class="container pt-5 pb-5 width-100 row">
        <nb-list class="width-100 mt-3 center Tableperformance">

            <nb-list-item>
                <div class="row">
                    <div class="col-md-4"><button (click)="generateXls()" mat-raised-button color="accent">Download
                            Excell</button></div>
                </div>
            </nb-list-item>

            <!-- List header -->
            <nb-list-item class="bg-color-primary list-header" style="color: white ">
                <div class="row ">
                    <div class="col-md-5">Nome Aluno</div>
                    <div class="col-md-5">E_mail</div>
                    <div class="col-md-2">Aulas Assistidas</div>
                </div>
            </nb-list-item>

            <!-- disciplina report -->
            <nb-list-item *ngFor="let perf of reportTasting">
                <div class="row ">
                    <div class="col-md-5">
                        {{perf.studentName}}
                    </div>
                    <div class="col-md-5">
                        {{perf.studentEmail}}
                    </div>
                    <div class="col-md-2">
                        {{perf.watchClasses}}
                    </div>
                </div>
            </nb-list-item>
        </nb-list>
    </div>
</div>