export var filterDays = {
    today   : "secondary",
    days7   : "secondary",
    days14  : "secondary",
    days30  : "secondary",
    days60  : "secondary", 
    days90  : "secondary", 
    period  : [1],
    average  : {
        performance: 0,
        average: 0,
        frequency: 0,
        participation: 0,
        studyPlan: 0
    },
    averageResult  :[],
    filter(days:number)
    {   
        this.setDefault();
        this.average = this.averageResult[days];
        this.period = [days];

        switch (days) {
            case 1: 
                this.today   = "primary"; 
            break;
            case 7: 
                this.days7   = "primary"; 
            break;
            case 14: 
                this.days14 = "primary"; 
            break; 
            case 30: 
                this.days30 = "primary"; 
            break;
            case 60: 
                this.days60 = "primary"; 
            break;
            case 90: 
                this.days90 = "primary"; 
            break; 
        }
    },
    setDefault()
    {   this.today      = "secondary";
        this.days7      = "secondary";
        this.days14     = "secondary";
        this.days30     = "secondary";
        this.days60     = "secondary"; 
        this.days90     = "secondary";  
    }
}