import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Optional, ViewChild, Input, Directive, ViewChildren} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { ModalVideoLayoutComponent } from 'src/app/core/components/modals/modal-video-layout/modal-video-layout.component';
import { NotificationsService } from 'src/app/core/components/notifications/notifications.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ThemeService } from 'src/app/core/themes/theme.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { ConfigServiceService } from '../../admin/my-configuration/config-service.service';
import { ModalInit } from '../../admin/my-configuration/modal-init/modal-init';

import { School } from '../../admin/school/school';
import { SchoolService } from '../../admin/school/school.service';
import { Unit } from '../../admin/school/unit';
import { Schedule } from '../../admin/study-plan/schedule';
import { ScheduleDay } from '../../admin/study-plan/schedule-day';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { Classroom } from '../../classroom/classroom';
import { Mock } from '../../mock/mock';
import { MockService } from '../../mock/mock.service';
import { ReportPerformance } from '../../report/performance/report-performance';
import { ReportService } from '../../report/report.service';
import { UserService } from '../../user/user.service';
import { DashboardService } from '../dashboard.service';
import { LiveClassComponent } from './live-class/live-class.component';
import { NotificationsComponent } from './notifications/notifications.component';

interface Annex{
  id: number;
  planId: number;
  link: string;
}
interface UrlAnnex{
  url: string;
}

@Component({
  selector: 'app-dashboard-student',
  templateUrl: './dashboard-student.component.html',
  styleUrls: ['./dashboard-student.component.scss']
})
export class DashboardStudentComponent implements OnInit, OnDestroy, AfterViewInit {

  public plans: StudyPlan[];
  public filteredPlans: BehaviorSubject<StudyPlan[]> = new BehaviorSubject<StudyPlan[]>(null);
  public planSelected: StudyPlan;
  public scheduleDay: ScheduleDay;
  public schedule: Schedule;
  public classroom: Classroom;
  // charts
  public watchedVideos: number[];
  public watchedVideosLegend = ['Assistidas', 'Total'];
  public exercisePerf: number[];
  public lineChart = {
    innerLine: [],
    outerLine: [
    ]
  };
  public redactionLegend: { iconColor: string; title: string }[];
  public exercisePerfLegend = ['Acertos', 'Erros'];
  public alive = true;
  public performance: ReportPerformance;
  public showClassrooms: boolean;
  public showMonitory: boolean;
  public activeMocks: Mock[];
  public schools: School[];
  public units: Unit[];
  public loadingPlans: boolean;
  public domain = window.location.hostname;
  public mobileQuery: MediaQueryList;
  public averagePlan: string;
  public annex: Annex;
  public empty: boolean;
  public modal: ModalInit;
  public labelWatchedVideos: any =  [ 'Assistidas', 'Total']  ;
  public labelExercisePerf: any=  [ 'Acertos', 'Erros'] ;
  public ocultMock: boolean;
  public ocultLiveClassroom: boolean;

  public viewType: string = 'carousel';

  private selectedPlanSubscription: Subscription;
  private themeSubscription: Subscription;
  @ViewChild('inputFilterPlan') inputFilterPlan: ElementRef;

  constructor(
    //@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<LiveClassComponent>,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private alertService: AlertService,
    private studyPlanService: StudyPlanService,
    private reportService: ReportService,
    private mockService: MockService,
    private router: Router,
    private themeService: ThemeService,
    private schoolService: SchoolService,
    public dialogVideoNewLayout: MatDialog,
    public userService: UserService,
    private configurationService: ConfigServiceService,
    public notificationsService: NotificationsService,
    public layoutService: LayoutService ) { }

  ngOnInit(): void {
    this.ocultMock = false;
    this.ocultLiveClassroom = false;
    this.getSchoolDefaultRoute();
    this.checkLiveClassNow(this.userService.getUserId(), this.userService.getUserSchool(), this.userService.getUserClass());
    this.selectedPlanSubscription = this.studyPlanService.getPlanSubject()
      .subscribe(plan => {
        this.planSelected = plan;
        this.planSelected && this.planSelected.version == 1 && this.getSchedule(this.userService.getUserId(), plan.id);
      });

    this.getPlans();
    this.getWatchedVideosChartData(this.userService.getUserId());
    this.getExerciseChartDate(this.userService.getUserId());
    this.getRedactionsChartData(this.userService.getUserId());
    this.getStudentAvailableMocks(this.userService.getUserId());

    this.dashboardService.emitLoginIsDone.subscribe((planId)=>{
      setTimeout(() => {
        this.getWatchedVideosChartData(this.userService.getUserId(),planId);
      }, 100);
    });

        if(!window.localStorage.getItem('closeModal')){
        this.getUserModal(this.domain)
        }
        else{
          this.GetModalNotification(this.domain);
        }

    //this.getUserModal('sistema.notamaxima.com.br');

    if(this.userService.isStudent()){
      this.getSchoolShowMock(this.userService.getUserSchool());
    }

    if(this.userService.isFather()){
      this.router.navigateByUrl('father/screen');
    }
  }

  ngAfterViewInit() {
    if(this.inputFilterPlan.nativeElement != undefined){
      fromEvent(this.inputFilterPlan.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((text) => {
          this.filterPlans(this.inputFilterPlan.nativeElement.value);
        })
      )
      .subscribe();
    }
  }

  ngOnDestroy() {
    if (this.selectedPlanSubscription?.closed === false) {
      this.selectedPlanSubscription.unsubscribe();
    }
    if (this.themeSubscription?.closed === false) {
      this.themeSubscription.unsubscribe();
    }
  }


  checkLiveClassNow(studentId: number, schoolId: number, classId:number){
    this.notificationsService.checkLiveClassNow(schoolId, studentId, classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error && response.data != "") {
          const link = response.data as any;
          this.openLiveClass(link);
        } else {
          //this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar o cronograma. Verifique a conexão e tente novamente'));
  }

  openLiveClass(link: string) {
    const dialogRef = this.dialog.open(LiveClassComponent, {
      width: '450px',
      data: link
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  openNotifications(): void {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      maxWidth: '300px',
      maxHeight: '600px',
      data: {name: 'Thais Braga'},
      position: {
        top: '80px',
        right: '30px'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      //this.animal = result;
    });
  }

  downloadAnnex(plan: StudyPlan) {
    let annexId = plan.annex[0];
    this.dashboardService.downloadAnnex(annexId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let annex = response.data as UrlAnnex;
          let valueAnnex = Object.values(annex);
          let link = valueAnnex.toString();
          window.open(link, '_blank');

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar o donwload. Verifique a conexão e tente novamente.'));
  }

  selectPlan(plan: StudyPlan) {

    this.studyPlanService.selectPlan(this.userService.getUserId(), plan)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.planSelected = plan;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao selecionar o plano. Verifique a conexão e tente novamente'));
  }

  getClassPlans(classId: number, studentId: number) {
    this.loadingPlans = true;
    this.studyPlanService.getClassPlans(classId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          const allPlans = response.data as StudyPlan[];

          this.plans = allPlans.filter(plan => plan.expired === false);

          this.plans.forEach(e => {

            if(e.domain){
              if(e.domain == this.userService.getUserDomain()){
                e.showStudent = true;
              } else{
                e.showStudent = false;
              }
            } else{
                if(this.userService.getUserRestrictDomain()){
                  e.showStudent = false;
                } else{
                  e.showStudent = true;
                }
            }
            if(e.domain == undefined){
              e.showStudent = true;
            }

          });
          this.filteredPlans.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }

  getSchoolPlans(schoolId: number) {
    this.loadingPlans = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }

  private getTeacherPlans(teacherId: number) {
    this.plans = [];
    this.filteredPlans.next([...this.plans]);
    this.loadingPlans = true;
    this.schoolService.getTeacherPlans(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos do professor. Verifique a conexão e tente mais tarde');
        this.loadingPlans = false;
      });
  }

  clearSelectedPlan() {
    this.planSelected = null;
    this.schedule = null;
    this.scheduleDay = null;
  }

  getWatchedVideosChartData(studentId: number,planId?: number) {
    this.dashboardService.getWatchedVideosChartData(studentId, planId )
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.watchedVideos = response.data as number[];
          if(this.watchedVideos[0] > this.watchedVideos[1]){
            this.labelWatchedVideos =  [ 'Assistidas: '+this.watchedVideos[1]+' ', 'Total: '+this.watchedVideos[0]+' ']  ;
          }else{
            this.watchedVideos = [];
          }


          //console.log(this.watchedVideos);
        } /* else {
          this.alertService.error(response.error);
        } */
      }, 
      //err => this.alertService.error('Houve um erro ao carregar os graficos. Verifique a conexão e tente novamente')
    );
  }

  getExerciseChartDate(studentId: number) {
    this.dashboardService.getExerciseChartDate(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.exercisePerf = response.data as number[];

          this.labelExercisePerf =  [ 'Acertos: '+this.exercisePerf[1]+' ', 'Erros: '+this.exercisePerf[0]+' '] ;
          this.exercisePerf[0] = this.exercisePerf[0]+this.exercisePerf[1];
          //console.log(this.exercisePerf);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os graficos. Verifique a conexão e tente novamente'));
  }

  getRedactionsChartData(studentId: number) {
    this.dashboardService.getRedactionsChartData(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.lineChart.outerLine = response.data as { label: string, value: number }[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os graficos. Verifique a conexão e tente novamente'));
  }

  getStudentPerformanceReport(studentId: number, studentClass: number) {
    this.reportService.getStudentReportPerformance(studentId, studentClass)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.performance = response.data as ReportPerformance;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os dados do relatório. Verifique a conexão e tente novamente'));
  }

  setShowClassrooms(show: boolean) {
    this.showClassrooms = show;
  }

  setShowMonitory(show: boolean) {
    this.showMonitory = show;
  }

  getStudentAvailableMocks(studentId: number) {
    this.mockService.getStudentAvailableMocks(studentId)
      .subscribe((mocks: Mock[]) => this.activeMocks = mocks);
  }

  selectAvailableMockToReply(mock: Mock) {
    this.mockService.setSelectedMockToReply(mock);
    this.router.navigateByUrl('/mock/list');
  }

  getSchoolDefaultRoute() {
    this.themeSubscription = this.themeService.getThemeObservable()
      .subscribe(theme => {
        if (theme && theme.defaultRouteId && theme.defaultRouteLink) {
          if (Number(theme.defaultRouteId) > 1) {
            this.router.navigateByUrl(`/${theme.defaultRouteLink}`);
          }
        }
      });
  }

  getPlans() {
    if (this.userService.isStudent()) {
      this.getClassPlans(this.userService.getUserClass(), this.userService.getUserId());
    } else
      if (this.userService.isUnitCoordinator()) {
        this.getUnitPlans(this.userService.getUserUnit());
      } else
        if (this.userService.isTeacher()) {
          this.getTeacherPlans(this.userService.getUserId());
        } else
          if (this.userService.isAdmin()) {
            this.getSchools();
          } else
            if (this.userService.isGeneralCoordinator()) {
              this.getUnits(this.userService.getUserSchool());
              this.getPlansBySchool(this.userService.getUserSchool());
            }
  }

  getPlansBySchool(schoolId : number){
    this.plans = [];
    this.filteredPlans.next([...this.plans]);
    this.loadingPlans = true;
    this.studyPlanService.getPlansBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getSchoolShowMock(schoolId : number){
    this.schoolService.getSchoolShowMock(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        const ocultMock = response.data['resultado'].ocultMocksDashboard;
        const ocultLive = response.data['resultado'].ocultLiveClassroom;

        if(ocultMock == 1){
          this.ocultMock = true;
        }
        else{
          this.ocultMock = false;
        }
        if(ocultLive == 1){
          this.ocultLiveClassroom = true;
        }
        else{
          this.ocultLiveClassroom = false;
        }
      }
      else{
        this.ocultMock = false;
        this.ocultLiveClassroom = false;
      }
    })
  }

  private getUnitPlans(unitId: number) {
    this.plans = [];
    this.filteredPlans.next([...this.plans]);
    this.loadingPlans = true;
    this.schoolService.getUnitPlans(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos da unidade. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }

  setAveragePlan(planName: string) {
    this.averagePlan = ` - Plano ${planName}`;
  }

  public filterPlans(filterPlan: string) {
    if (!this.plans) {
      return;
    }
    this.loadingPlans = true;

    setTimeout(() => { // important for correctly carousel render :/

      if (!filterPlan) {
        this.filteredPlans.next([...this.plans]);
        this.loadingPlans = false;
        return;
      }

      this.filteredPlans.next(
        this.plans.filter(plan => plan?.name?.toLowerCase().includes(filterPlan.toLowerCase()))
      );
      this.loadingPlans = false;
    }, 1000);

  }

  /* calendar V1 methods */
  getSchedule(studentId: number, planId: number) {
    this.studyPlanService.getScheduleV1(studentId, planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.schedule = response.data as Schedule;
          this.populateScheduleDay();
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar o cronograma. Verifique a conexão e tente novamente'));
  }

  populateScheduleDay() {
    let i = 0;
    this.schedule.days.map(d => {
      d.scheduleDay = 0 + d.day;
      this.schedule.days[i] = d;
      i++;
    });
  }

  getUserModal(userDomain: string){
    this.configurationService.getUserModal(userDomain)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
       this.modal = response.data as ModalInit;
       if(this.modal.openAfterLogin == 1 && this.userService.isStudent()){
       this.openDialogVideoNewLayout(this.modal);
       }
      }
    }, err => {
      //console.log(err);

    });
  }

  GetModalNotification(userDomain: string){
    this.configurationService.GetModalNotification(userDomain)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
       this.modal = response.data as ModalInit;
       if(this.modal.openAfterLogin == 1 && this.userService.isStudent()){
       this.openDialogVideoNewLayout(this.modal);
       }
      }
    }, err => {
      //console.log(err);

    });
  }

  openDialogVideoNewLayout(modal: ModalInit) {
    let width = 550;
    let height = (width * 0.56);

    if (window.outerWidth > 2000 ) {
      width = 650;
      height = (width * 0.56);

    }
    if (window.outerWidth < 1200 && window.outerWidth >= 850 ) {
      width = 550;
      height = (width * 0.56);

    }else  if ( window.outerWidth < 850 ) {
      width = (window.outerWidth * 0.70);
      height = (width * 0.56);
    }

    const dialogRef = this.dialogVideoNewLayout.open(ModalVideoLayoutComponent,{
      width: '0px',
      height: '0px',
      panelClass: 'custom-dialog-container',
      data: {modal,isMobile:this.mobileQuery, width:width+'px' , height: height+'px' }
    });
    dialogRef.afterClosed().subscribe(result => {
         //window.localStorage.setItem('modalVideo', 'false');
  });
}
  /* End calendar v1 methods */
}
