import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Mock } from 'src/app/modules/mock/mock';
import { MockService } from 'src/app/modules/mock/mock.service';
import { RedactionType } from 'src/app/modules/redaction/redaction-type';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AdminService } from '../../admin.service';
import { Discipline, DisciplinesSelect } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { StudyPlan } from '../study-plan';
import { StudyPlanService } from '../study-plan.service';


@Component({
  selector: 'app-create-study-plan',
  templateUrl: './create-study-plan.component.html',
  styleUrls: ['./create-study-plan.component.scss']
})
export class CreateStudyPlanComponent implements OnInit, OnDestroy {

  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();

  public scholselected: boolean;
  public createStudyPlanForm: FormGroup;
  public disciplines: Discipline[];
  public selectedDisciplines: Discipline[];
  public schools: School[];
  public simulatedTests: Mock[];
  public selectedSimulated: Mock[];
  public selectedWeekDays: { day: string, id: number }[];
  public weekDays: { id: number, day: string }[];
  public editMode: boolean;
  public plans: StudyPlan[];
  public selectedRedactionTypes: RedactionType[];
  public redactionTypes: RedactionType[];
  public filterName: string;
  public filteredPlans: StudyPlan[];
  public loading: boolean;
  public searchingName: boolean;
  public saving: boolean;
  public selectedSchools: any;
  public expireFixed = false;
  public selectedEditPlan: StudyPlan;
  public mocksFilter = new FormControl();
  public filteredMocks: BehaviorSubject<Mock[]> = new BehaviorSubject<Mock[]>(null);
  public disciplineFilter = new FormControl();
  public filteredDisciplines: BehaviorSubject<Discipline[]> = new BehaviorSubject<Discipline[]>(null);
  public filteringMocks: boolean;
  public cordSchool: School;
  public SelectedSchool: number;
  public disciplinesByName: DisciplinesSelect[];


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public plan: StudyPlan,
    @Optional() public dialogRef: MatDialogRef<CreateStudyPlanComponent>,
    private dialog: MatDialog,
    private adminService: AdminService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private studyPlanService: StudyPlanService,
    private disciplineService: DisciplineService,
    private mockService: MockService,
    private schoolService: SchoolService,
    public userService: UserService,
    public maskService: MaskService) { }

  ngOnInit(): void {
    this.weekDays = [
      { id: 0, day: 'Domingo' },
      { id: 1, day: 'Segunda' },
      { id: 2, day: 'Terça' },
      { id: 3, day: 'Quarta' },
      { id: 4, day: 'Quinta' },
      { id: 5, day: 'Sexta' },
      { id: 6, day: 'Sábado' }
    ];
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  init() {
    this.scholselected = false;
    this.profileInit();

    this.redactionTypes = [
      { id: 1, name: 'ENEM' },
      { id: 2, name: 'Concurso' },
      { id: 3, name: 'Vestibular' },
      { id: 4, name: 'UFSC' },
      { id: 5, name: 'UFPR' },
      { id: 6, name: 'UFRGS' },
      { id: 7, name: 'UDESC' },
      { id: 8, name: 'ACAFE' },
      { id: 9, name: 'FUVEST' },
      { id: 10, name: 'UNICAMP' },
    ];

    this.createStudyPlanForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      tag: [''],
      disciplines: [],
      schools: [],
      filterschools: [],
      disciplineSearch: [''],
      simulated: [],
      redactions: [{ value: 0, disabled: !this.userService.isAdmin() }],
      expireDate: [{ value: '', disabled: true }],
      expireMonths: [''],
      beginDate: ['', Validators.required],
      weekDays: [],
      maxDayVideos: [],
      redactionTypes: [''],
      calendarHide: [false],
      disciplinesHide: [false],
      mockHide: [false],
      releaseCalendarVideosUpToday: [false],
      version: [2, Validators.required],
    });

    this.selectedDisciplines = [];
    this.selectedSimulated = [];
    this.selectedWeekDays = [];
    this.selectedRedactionTypes = [];

    // listen for search field value changes
    this.mocksFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterMocks();
      });
    this.disciplineFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterDisciplines();
      });

    if (this.plan) { this.initializeEditMode(this.plan.id); }
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.getSchools();
      //this.getDisciplines();  comentado no filtro de simulados do edu
      //this.getMocks();        comentado no filtro de simulados do edu
      this.selectedSchools = [];

    } else if (this.userService.isGeneralCoordinator()) {

      this.getPlansBySchool(this.userService.getUserSchool());
      this.getSchoolDisciplines(this.userService.getUserSchool());
      this.getMocks(this.userService.getUserSchool());
      this.selectedSchools = [{ id: this.userService.getUserSchool() }];

    } else if (this.userService.isUnitCoordinator()) {

      this.getUnitPlans(this.userService.getUserUnit());
      this.getUnitDisciplines(this.userService.getUserUnit());
      this.getMocks(this.userService.getUserSchool());
      this.selectedSchools = [{ id: this.userService.getUserSchool() }];

    } else if (this.userService.isTeacher()) {
      this.getTeacherPlans(this.userService.getUserId());

      //????
      this.getTeacherDisciplines(this.userService.getUserId());
      this.getMocks(this.userService.getUserSchool());
      this.selectedSchools = [{ id: this.userService.getUserSchool() }];
    }
  }

  getPlans(schoolId?: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlansBySchool(schoolId: number) {
    this.loading = true;
    this.studyPlanService.getSelectedPlansBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlanDisciplinesFilter(planId?: number) {

    if (planId > 0) {
      this.studyPlanService.getPlanDisciplines(planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.disciplines = response.data as Discipline[];
            this.filteredDisciplines.next([...this.disciplines]);
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao buscar as disciplinas. Verifique a conexão e tente novamente'));
    } else {
      if(this.userService.isAdmin()){
        this.getDisciplines();
      }
      else if(this.userService.isGeneralCoordinator()){
        this.getSchoolDisciplines(this.userService.getUserSchool());
      }
      else if(this.userService.isUnitCoordinator()){
        this.getUnitDisciplines(this.userService.getUserUnit());
      }
      else{
        this.getTeacherDisciplines(this.userService.getUserId());
      }
    }

  }

  getDisciplines() {
    this.disciplineService.getDisciplines()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.filteredDisciplines.next([...this.disciplines]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as disciplinas. Verifique a conexão e tente novamente'));
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];

        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getMocks(schoolId?: number) {
    this.scholselected = true;
    if(!this.userService.isAdmin()){
      schoolId = this.userService.getUserSchool();
    }
    this.mockService.getMocks(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.simulatedTests = response.data as Mock[];
          this.filteredMocks.next([...this.simulatedTests]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente'));
  }

  getPlanMocks(planId: number) {
    this.mockService.getPlanMocks(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.simulatedTests = response.data as Mock[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente'));
  }

  getPlanDisciplines(planId: number) {
    this.studyPlanService.getPlanDisciplines(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedDisciplines = response.data as Discipline[];
          this.createStudyPlanForm.get('disciplines').setValue([...this.selectedDisciplines.map(discipline => discipline.id)]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  getDisciplinesByName(disciplineName: string) {
    this.searchingName = true;
    this.disciplinesByName = [];
    if (!disciplineName || disciplineName === "" || disciplineName.length <=3 ) {
      this.alertService.error("Nome da disciplina deve conter mais de 3 caracteres!");
    } else{
      if (!this.userService.isAdmin()) {
        this.disciplineService.getDisciplinesSchoolByName(this.userService.getUserSchool(), disciplineName)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          const data = response.data as DisciplinesSelect[];
          if (!response.error && data.length > 0) {
            this.disciplinesByName = data ;
            this.createStudyPlanForm.get('disciplineSearch').setValue("");
          } else if(data.length === 0) {this.alertService.error("Nenhuma disciplina encontrada!");}
          else {this.alertService.error(response.error);}
          this.searchingName = false;
        }, err => {
          this.alertService.error('Houve um erro ao buscar as disciplinas pelo nome. Verifique a conexão e tente novamente');
          this.searchingName = false;
        });
      }else {
        this.disciplineService.getNameDiscipline(disciplineName)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          const data = response.data as DisciplinesSelect[];
          if (!response.error && data.length > 0) {
            this.disciplinesByName = data ;
            this.createStudyPlanForm.get('disciplineSearch').setValue("");
          } else if(data.length === 0) {this.alertService.error("Nenhuma disciplina encontrada!");}
          else {this.alertService.error(response.error);}
          this.searchingName = false;
        }, err => {
          this.alertService.error('Houve um erro ao buscar as disciplinas pelo nome. Verifique a conexão e tente novamente');
          this.searchingName = false;
        });
      }
    }

  }

  getSchoolDisciplines(schoolId: number) {
    this.studyPlanService.getSchoolDisciplines(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as disciplinas. Verifique a conexão e tente novamente'));
  }

  getPlanSimulateds(planId: number) {
    this.mockService.getPlanMocksEdit(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedSimulated = response.data as Mock[];
          this.createStudyPlanForm.get('simulated').setValue([...this.selectedSimulated.map(mock => mock.id)]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente'));
  }

  getPlanSchools(planSchools: number[]) {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];

          this.schools.map(school => {
            planSchools.map(planSchoolId => {
              if (planSchoolId == school.id) {
                this.selectedSchools.push(school);
              }
            });
          });

        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os planos da(s) escola(s). Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  addSchoolChip(school: School) {
    const index = this.selectedSchools.indexOf(school);

    if (index < 0) {
      this.selectedSchools.push(school);
      this.createStudyPlanForm.get('schools').reset();
    }
  }

  addDisciplineChip(disciplineId: Discipline[]) {

    if (disciplineId.length === 0 ) {
      this.selectedDisciplines = [];
    } else {
      disciplineId.forEach(discipline => {
        this.disciplines.forEach(d => {
          if (d.id == discipline.id) {
            this.selectedDisciplines.push(d);
          }
        });
      });

      console.log(this.selectedDisciplines);
    }
  }

  addSimulatedChip(simulated: Mock) {
    const index = this.selectedSimulated.indexOf(simulated);

    if (index < 0) {
      this.selectedSimulated.push(simulated);
    }
  }

  addRedaction() {
    if (this.userService.isAdmin()) {
      if (this.createStudyPlanForm.get('redactions').value && this.createStudyPlanForm.get('redactions').value > 0) {
        this.createStudyPlanForm.get('redactions')
          .setValue((this.createStudyPlanForm.get('redactions').value as number) + 1);
      } else {
        this.createStudyPlanForm.get('redactions').setValue(1);
      }
    } else {
      this.alertService.warning('Para adicionar redações ao plano, entre em contato com o suporte');
    }
  }

  registerPlan() {

    if(this.createStudyPlanForm.get('expireDate').value || this.createStudyPlanForm.get('expireMonths').value){

    const disciplinesIds = [];
    const schoolsIds = [];
    const simulatedIds = [];
    const weekDaysIds = [];
    const redTypesIds = [];

    this.selectedDisciplines.map(d => disciplinesIds.push(d.id));
    this.selectedSchools.map(d => schoolsIds.push(d.id));
    this.selectedSimulated.map(s => simulatedIds.push(s.id));
    this.selectedWeekDays.map(d => weekDaysIds.push(d.id));
    this.selectedRedactionTypes.map(r => redTypesIds.push(r.id));
    this.createStudyPlanForm.get('disciplines').setValue(disciplinesIds);
    this.createStudyPlanForm.get('schools').setValue(schoolsIds);
    this.createStudyPlanForm.get('simulated').setValue(simulatedIds);
    this.createStudyPlanForm.get('weekDays').setValue(weekDaysIds);
    this.createStudyPlanForm.get('redactionTypes').setValue(redTypesIds);

    let studyPlan = this.createStudyPlanForm.getRawValue() as StudyPlan;

    if(this.userService.isGeneralCoordinator() && this.userService.getUserRestrictDomain()){
      studyPlan.domain = this.userService.getUserDomain();
    }

    if (!this.createStudyPlanForm.valid) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.saving = true;

    console.log("studyPlan: ", studyPlan)

    this.studyPlanService.createNewPlan(studyPlan)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Plano Cadastrado!', 3);

          if (this.userService.isTeacher()) {
            if (this.plans?.length) {
              this.setTeacherPlans(this.userService.getUserId(), [...this.plans.map(p => p.id), (response.data as number)]);
            } else {
              this.setTeacherPlans(this.userService.getUserId(), [response.data as number]);
            }

          } else if (this.userService.isUnitCoordinator()) {
            if (this.plans?.length) {
              this.setUnitPlans(this.userService.getUserUnit(), [...this.plans.map(p => p.id), (response.data as number)]);
            } else {
              this.setUnitPlans(this.userService.getUserUnit(), [response.data as number]);
            }
          }

          this.createStudyPlanForm.reset();
          this.selectedDisciplines = [];
          this.selectedSimulated = [];
          this.selectedWeekDays = [];
          this.reloadPlan();
          this.adminService.updateChields();

        } else {
          this.alertService.error(response.error);
        }
        this.saving = false;
      }, err => {
        this.alertService.error('Houve um erro ao cadastrar o plano. Verifique a conexão e tente novamente');
        this.saving = false;
      });

    }
    else{
      this.alertService.error('O plano de estudos deve ter uma data de encerramento em meses ou em data!');
    }
  }

  duplicatePlan() {

    const disciplinesIds = [];
    const schoolsIds = [];
    const simulatedIds = [];
    const weekDaysIds = [];
    const redTypesIds = [];

    this.selectedDisciplines.map(d => disciplinesIds.push(d.id));
    this.selectedSchools.map(d => schoolsIds.push(d.id));
    this.selectedSimulated.map(s => simulatedIds.push(s.id));
    this.selectedWeekDays.map(d => weekDaysIds.push(d.id));
    this.selectedRedactionTypes.map(r => redTypesIds.push(r.id));
    this.createStudyPlanForm.get('disciplines').setValue(disciplinesIds);
    this.createStudyPlanForm.get('schools').setValue(schoolsIds);
    this.createStudyPlanForm.get('simulated').setValue(simulatedIds);
    this.createStudyPlanForm.get('weekDays').setValue(weekDaysIds);
    this.createStudyPlanForm.get('redactionTypes').setValue(redTypesIds);

    const studyPlan = this.createStudyPlanForm.getRawValue() as StudyPlan;
    studyPlan.name = studyPlan.name + " (Duplicado)";

    if (!this.createStudyPlanForm.valid) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.saving = true;

    this.studyPlanService.registerPlan(studyPlan)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Plano Duplicado!', 3);

          if (this.userService.isTeacher()) {
            if (this.plans?.length) {
              this.setTeacherPlans(this.userService.getUserId(), [...this.plans.map(p => p.id), (response.data as number)]);
            } else {
              this.setTeacherPlans(this.userService.getUserId(), [response.data as number]);
            }

          } else if (this.userService.isUnitCoordinator()) {
            if (this.plans?.length) {
              this.setUnitPlans(this.userService.getUserUnit(), [...this.plans.map(p => p.id), (response.data as number)]);
            } else {
              this.setUnitPlans(this.userService.getUserUnit(), [response.data as number]);
            }
          }

          this.createStudyPlanForm.reset();
          this.selectedDisciplines = [];
          this.selectedSimulated = [];
          this.selectedWeekDays = [];
          this.dialogRef.close(true);
          this.reloadPlan();

          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }
        this.saving = false;
      }, err => {
        this.alertService.error('Houve um erro ao Duplicar o plano. Verifique a conexão e tente novamente');
        this.saving = false;
      });
  }

  addWeekDaysChip(day: { id: number, day: string }) {
    const index = this.selectedWeekDays.indexOf(day);

    if (index < 0) {
      this.selectedWeekDays.push(day);
      this.createStudyPlanForm.get('weekDays').reset();
    }
  }

  setSelectedWeekDays() {
    this.selectedEditPlan.weekDays.map(d => {
      this.selectedWeekDays.push(this.weekDays[d]);
    });
  }

  editPlan(plan: StudyPlan) {
    const dialogRef = this.dialog.open(CreateStudyPlanComponent, {
      minWidth: '60vw',
      data: plan
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.reloadPlan();
      }
    });
  }

  saveChanges() {
    if (!this.createStudyPlanForm.errors) {

      if (!this.createStudyPlanForm.get('expireDate').value && !this.createStudyPlanForm.get('expireMonths').value) {
        this.alertService.error('Selecione uma data ou o número de meses da validade do plano');
        return false;
      }

      this.saving = true;
      const disciplinesIds = [];
      const schoolsIds = [];
      const simulatedIds = [];
      const weekDaysIds = [];
      const redTypesIds = [];

      this.selectedDisciplines.map(d => disciplinesIds.push(d.id));
      this.selectedSchools.map(d => schoolsIds.push(d.id));
      this.selectedSimulated.map(s => simulatedIds.push(s.id));
      this.selectedWeekDays.map(d => weekDaysIds.push(d.id));
      if (this.selectedRedactionTypes?.length) {
        this.selectedRedactionTypes.map(r => redTypesIds.push(r.id));
      }

      this.createStudyPlanForm.get('disciplines').setValue(disciplinesIds);
      this.createStudyPlanForm.get('schools').setValue(schoolsIds);
      this.createStudyPlanForm.get('simulated').setValue(simulatedIds);
      this.createStudyPlanForm.get('weekDays').setValue(weekDaysIds);
      this.createStudyPlanForm.get('redactionTypes').setValue(redTypesIds);

      this.createStudyPlanForm.get('id').setValue(this.selectedEditPlan.id);
      const domainOld = this.selectedEditPlan.domain;
      let plan = this.createStudyPlanForm.getRawValue() as StudyPlan;
      plan.domainOld = domainOld;
      this.studyPlanService.savePlanChanges(plan)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('plano editado com sucesso', 3);
            this.dialogRef.close(true);
            this.reloadPlan();
            // this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
          this.saving = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar as alterações da turma. Verifique a conexão e tente novamente');
          this.saving = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');

    }
  }

  removePlan(plan: StudyPlan) {
    if (confirm('Deseja realmente excluir o plano?')) {
      let planId = plan.id;
      this.studyPlanService.removePlan(planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            if(this.SelectedSchool){
              this.getPlansBySchool(this.SelectedSchool);
            }
            else{
              this.checkUserAndGetPlans();
            }
            this.alertService.success('Plano removido!', 3);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o plano. Verifique a conexão e tente novamente'));
    }
  }

  removeMockChip(index: number) {
    if (index > -1) {
      this.selectedSimulated.splice(index, 1);
    }
  }

  removeSchoolChip(school: School) {
    const index = this.selectedSchools.indexOf(school);

    if (index >= 0) {
      this.selectedSchools.splice(index, 1);
    }
  }

  removeDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.indexOf(discipline);

    if (index >= 0) {
      this.selectedDisciplines.splice(index, 1);
      this.createStudyPlanForm.get('disciplines').setValue([...this.selectedDisciplines.map(d => d.id)]);
    }
  }

  removeDuplicatedDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.indexOf(discipline);

    if (index >= 0) {
      this.selectedDisciplines.splice(index, 1);
      this.createStudyPlanForm.get('disciplines').setValue([...this.selectedDisciplines.map(d => d.id)]);
    }
  }

  removeSelectedRedactionType(index: number) {
    if (this.selectedRedactionTypes.length > 0) {
      this.selectedRedactionTypes.splice(index, 1);
      this.createStudyPlanForm.get('redactionTypes').reset();
    }
  }

  removeWeekDaysChip(day: { id: number, day: string }) {
    const index = this.selectedWeekDays.indexOf(day);

    if (index >= 0) {
      this.selectedWeekDays.splice(index, 1);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  initializeEditMode(planId : number) {
    this.editMode = true;

    this.getPlanEditOne(planId);
    this.createStudyPlanForm.get('version').disable();
    this.getPlanDisciplines(planId);
    this.getPlanSimulateds(planId);
  }

  selectRedactionType(redType: RedactionType) {
    let selected = false;
    this.selectedRedactionTypes.forEach(rt => rt.id == redType.id && (selected = true));
    if (!selected) {
      this.selectedRedactionTypes.push(redType);
      this.createStudyPlanForm.get('redactionTypes').reset();
    }
  }

  applyFilter() {
    if (this.filterName.length) {
      this.filteredPlans = [...this.plans].filter(plan =>
        plan.name.toLowerCase().indexOf(this.filterName.toLowerCase()) > -1
      );

    } else {
      this.filteredPlans = [...this.plans];
    }
  }

  reloadPlan() {
    if (this.userService.isAdmin()) {
      if(this.SelectedSchool){
        this.getPlansBySchool(this.SelectedSchool);
      }
      else{
        this.checkUserAndGetPlans();
      }
      this.selectedDisciplines = [];
    } else if (this.userService.isGeneralCoordinator()) {

      this.getPlansBySchool(this.userService.getUserSchool());

      this.selectedSchools = [{ id: this.userService.getUserSchool() }];
    }
  }

  expireToggle(event: MatSlideToggleChange) {
    if (!event.checked) {
      this.createStudyPlanForm.get('expireDate').disable();
      this.createStudyPlanForm.get('expireDate').setValue(null);
      this.createStudyPlanForm.get('expireMonths').enable();
    } else {
      this.createStudyPlanForm.get('expireDate').enable();
      this.createStudyPlanForm.get('expireMonths').disable();
      this.createStudyPlanForm.get('expireMonths').setValue(null);
    }
  }

  selectMock(mock: Mock) {
    if (!this.selectedSimulated.find(m => m.id == mock.id)) {
      this.selectedSimulated.push(mock);
    }
  }

  checkUserAndGetPlans() {
      if (this.userService.isAdmin()) {
        this.getPlans();
      } else if (this.userService.isGeneralCoordinator()) {
        this.getPlansBySchool(this.userService.getUserSchool());
      } else if (this.userService.isUnitCoordinator()) {
        this.getUnitPlans(this.userService.getUserUnit());
      } else if (this.userService.isTeacher()) {
        this.getTeacherPlans(this.userService.getUserId());
      }
  }

  protected filterMocks() {
    if (!this.simulatedTests) {
      return;
    }
    let search = this.mocksFilter.value;
    if (!search) {
      this.filteredMocks.next(this.simulatedTests.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteringMocks = true;
    // filter mocks
    this.filteredMocks.next(
      this.simulatedTests.filter(mock => mock.name.toLowerCase().indexOf(search) > -1)
    );

    this.filteringMocks = false;
  }

  protected filterDisciplines() {
    if (!this.disciplines) {
      return;
    }
    let search = this.disciplineFilter.value;
    if (!search) {
      this.filteredDisciplines.next(this.disciplines.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteringMocks = true;
    // filter disciplines
    this.filteredDisciplines.next(
      this.disciplines.filter(discipline => discipline.name.toLowerCase().indexOf(search) > -1)
    );

    this.filteringMocks = false;
  }

  private getTeacherPlans(teacherId: number) {
    this.plans = [];
    this.schoolService.getTeacherPlans(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans = response.data as StudyPlan[];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos do professor. Verifique a conexão e tente mais tarde'));
  }

  private getUnitPlans(unitId: number) {
    this.schoolService.getUnitPlans(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos da unidade. Verifique a conexão e tente novamente'));
  }

  getPlanEditOne(planId : number){
    this.studyPlanService.getPlanEditOne(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.selectedEditPlan = response.data as StudyPlan;
        this.createStudyPlanForm.patchValue(this.selectedEditPlan);
        this.selectedRedactionTypes = this.selectedEditPlan.typeEssays as RedactionType[];

        if (!this.selectedRedactionTypes) {
          this.selectedRedactionTypes = [];
        }

        if (this.selectedEditPlan.expireDate) {
          //this.createStudyPlanForm.get('expireDate').setValue(moment(this.selectedEditPlan.expireDate).format('DD/MM/YYYY'));
          this.createStudyPlanForm.get('expireDate').setValue(this.selectedEditPlan.expireDate);
          this.createStudyPlanForm.get('expireDate').enable();
          this.createStudyPlanForm.get('expireMonths').disable();
          this.expireFixed = true;
        }
        if(this.selectedEditPlan.expireDate){
          this.expireFixed = true;
        }
        else{
          this.expireFixed = false;
        }
        this.getPlanSchools(this.selectedEditPlan.schools);
        this.setSelectedWeekDays();
      }
      else{
        //console.log(response.error);
      }
    }, err => this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!'))
  }

  private setUnitPlans(unitId: number, planId: number[]) {
    if (planId?.length) {
      this.schoolService.setUnitPlans(unitId, planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.getUnitPlans(unitId);
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao adicionar os planos da unidade. Verifique a conexão e tente novamente'));
    }
  }

  private setTeacherPlans(teacherId: number, planId: number[]) {
    this.schoolService.setTeacherPlans(teacherId, planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.getTeacherPlans(teacherId);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao atualizar os planos do professor. verifique a conexão e tente mais tarde'));
  }

  private getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedDisciplines = [...response.data as Discipline[]];
          this.filteredDisciplines.next([...this.disciplines]);
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde'));
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedDisciplines = [...response.data as Discipline[]];
          this.filteredDisciplines.next([...this.disciplines]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
  }



}
