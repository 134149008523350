<div *ngIf="!showList">
    <form [formGroup]="experimentUserForm">
        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" (selectionChange)="getUnits($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" (selectionChange)="getUsers($event.value)">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            {{class.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Estudante" formControlName="studentId">
                        <mat-option *ngFor="let student of students" [value]="student.id">
                            {{student.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano Experimente" formControlName="experimentId">
                        <mat-option *ngFor="let experiment of experiments" [value]="experiment.id">
                            {{experiment.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary">Ver usuarios liberados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading"
                [disabled]="loading">Cadastrar</button>
        </div>
    </div>
</div>

<div *ngIf="showList">

    <mat-form-field appeareance="outline">
        <mat-select placeholder="Plano" [(ngModel)]="filterPlan" (selectionChange)="getExperimentUsers($event.value)">
            <mat-option *ngFor="let plan of plans" [value]="plan.id">
                {{plan.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <nb-list class="width-90 mt-3 center" *ngIf="!loading">
        <nb-list-item *ngFor="let experimentUser of experimentUsers">
            <div class="row">
                <div class="col-5">
                    {{experimentUser.studentName}}
                </div>
                <div class="col-5">
                    {{experimentUser.experimentName}}
                </div>
                <div class="col-2 text-right">
                    <button size="tiny" (click)="remove(experimentUser.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>

    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small"
            status="primary">voltar</button>
    </div>
</div>