import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { type } from 'os';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { SlideFile } from 'src/app/modules/slide-file/slide-file';
import { SlideFileService } from 'src/app/modules/slide-file/slide-file.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';

import { AdminService } from '../../admin.service';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';

@Component({
  selector: 'app-slide-file-create',
  templateUrl: './slide-file-create.component.html',
  styleUrls: ['./slide-file-create.component.scss']
})
export class SlideFileCreateComponent implements OnInit {

  private admUpdate$: Subscription;

  public showList: boolean;
  public form: FormGroup;
  public slideFiles: SlideFile[];
  public disciplines: Discipline[];
  public disciplinesAux: Discipline[];
  public disciplineId: number;
  public editMode: boolean;
  public loading: boolean;
  public loadingDisciplines: boolean;
  public filter = '';
  public schools: School[];
  public file: any;
  public empty:boolean;
  public fileMetaData: { name: string, type: string, size: number };

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public slideFile: SlideFile,
    @Optional() public dialogRef: MatDialogRef<SlideFileCreateComponent>,
    private dialog: MatDialog,
    private slideFileService: SlideFileService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private disciplineService: DisciplineService,
    public maskService: MaskService,
    private adminService: AdminService,
    public userService: UserService,
    private schoolService: SchoolService) { }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    

    this.form = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      disciplineId: ['', Validators.required],
      schoolId: ['', Validators.required],
      file: ['', Validators.required],
      fileMetaData: ['', Validators.required],
      type: ['', Validators.required]
    });

    this.profileInit();

    if (this.slideFile) { this.initializeEditMode(); }
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.getSchools();
    } else {
      this.getDisciplines();
      this.form.get('schoolId').setValue(this.userService.getUserSchool());
    }

  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getDisciplineSlideFiles(disciplineId: number) {
    this.loading = true;
    this.slideFiles = [];
    this.slideFileService.getDisciplineSlideFiles(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.slideFiles = response.data as SlideFile[];
          this.empty = false;
          if(response.data == ''){
            this.empty = true;
          }
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os arquivos de aula. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplines(): void {
    if (this.userService.isTeacher()) {
      this.getTeacherDisciplines(this.userService.getUserId());
    } else if (this.userService.isUnitCoordinator()) {
      this.getUnitDisciplines(this.userService.getUserUnit());
    } else if (this.userService.isGeneralCoordinator()) {
      this.getSchoolDisciplines(this.userService.getUserSchool());
    }
  }

  getSchoolDisciplines(schoolId: number) {
    this.disciplineId = null;
    this.disciplines = [];
    this.loadingDisciplines = true;
    this.disciplineService.getSchoolDisciplines(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];

          this.disciplines = this.disciplines.map(discipline => {
            discipline.id = Number(discipline.id);
            return discipline;
          });

          this.disciplinesAux = this.disciplinesAux.map(discipline => {
            discipline.id = Number(discipline.id);
            return discipline;
          });

        } else {
          this.alertService.error(response.error);
        }
        this.loadingDisciplines = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas da escola. Verifique a conexão e tente novamente');
        this.loadingDisciplines = false;
      });
  }

  openList() {
    this.getSchools();
    this.showList = true;
  }

  removeSlideFile(slideFileId: number) {
    if (confirm('Deseja realmente remover o arquivo?')) {
      this.slideFileService.removeSlideFile(slideFileId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Arquivo removido!', 3);
            this.disciplineId && this.getDisciplineSlideFiles(this.disciplineId);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o arquivo. Verifique a conexão e tente novamente'));
    }
  }

  addSlideFile() {
    this.loading = true;
    this.form.get('file').setValue(this.file);
    this.form.get('fileMetaData').setValue(this.fileMetaData);

    const slideFile = this.form.getRawValue() as SlideFile;

    if (this.form.invalid || this.form.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      this.loading = false;
      return;
    }

    this.slideFileService.createSlideFile(slideFile)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Arquivo cadastrado!');
          this.form.reset();
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao cadastrar o arquivo. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  editSlideFile(slideFile: SlideFile) {
    const dialogRef = this.dialog.open(SlideFileCreateComponent, {
      minWidth: '60vw',
      data: slideFile
    });
    this.editMode = true;
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.disciplineId && this.getDisciplineSlideFiles(this.disciplineId);
      }
    });
  }

  initializeEditMode() {
    this.editMode = true;
    this.form.patchValue(this.slideFile);
    this.getSchoolDisciplines(this.slideFile.schoolId);
  }

  saveChanges() {
    this.form.get('file').setValue(this.file);
    this.form.get('fileMetaData').setValue(this.fileMetaData);

    if (this.form.valid || !this.form.errors) {
      this.loading = true;
      this.form.get('id').setValue(this.slideFile.id);
      const slideFile = this.form.getRawValue() as SlideFile;

      this.slideFileService.updateSlideFile(slideFile)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Arquivo editado com sucesso', 3);
            this.dialogRef.close(true);
            this.disciplineId && this.getDisciplineSlideFiles(this.disciplineId);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar as alterações do arquivo. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }

  verifyFilter(value: string): boolean {
    if (value) {
      return !this.filter || value.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
    }
    return true;
  }

  private getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];

          this.disciplines = this.disciplines.map(discipline => {
            discipline.id = Number(discipline.id);
            return discipline;
          });

          this.disciplinesAux = this.disciplinesAux.map(discipline => {
            discipline.id = Number(discipline.id);
            return discipline;
          });

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde'));
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];

          this.disciplines = this.disciplines.map(discipline => {
            discipline.id = Number(discipline.id);
            return discipline;
          });

          this.disciplinesAux = this.disciplinesAux.map(discipline => {
            discipline.id = Number(discipline.id);
            return discipline;
          });
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
  }

  handleFileInput(files: FileList): void {
    if (files && files[0]) {
      const fileSizeInMb = files[0].size / 1024 / 1024;

      if (fileSizeInMb > 5) {
        this.alertService.error('Tamanho máximo 5 MB!');
      } else {
        const file = files[0];
        this.fileMetaData = { name: files[0].name, type: files[0].type, size: files[0].size };
        const extension = this.getFileExtension(this.fileMetaData);

        if (extension == 'pdf') {
          this.form.get('type').setValue(extension);
        } else {
          this.alertService.error('Apenas arquivos .PDF são aceitos!');
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          this.file = reader.result;
        };
      }
    }
  }

  private getFileExtension(fileMetaData: { name: string, type: string, size: number }) {
    let extension = '';
    if (fileMetaData.type) {
      extension = fileMetaData.type.split('/').pop();
    } else {
      extension = fileMetaData.name.split('.').pop();
    }
    return extension.toLowerCase();
  }

  openFile(url: string) {
    window.open(url, '_blank');
  }
}

