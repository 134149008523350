<h3 class="mt-2 mb-2" matDialogTitle>
    <span *ngIf="!isAddPayment">Editar pagamento</span>
    <span *ngIf="isAddPayment">Adicionar pagamento</span>
</h3>
<div mat-dialog-content>
    <form [formGroup]="form">
        <div class="row">
            <div *ngIf="!isAddPayment" class="col-md">
                <mat-form-field class="width-100">
                    <mat-label>Plano</mat-label>
                    <input matInput formControlName="planName" readonly>
                </mat-form-field>
            </div>
            <div *ngIf="isAddPayment" class="col-md">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" formControlName="planId">
                        <mat-option *ngFor="let plan of plans$ | async" [value]="plan.id">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md">
                <mat-form-field class="width-100">
                    <mat-label>Vencimento</mat-label>
                    <input matInput formControlName="expire" [textMask]="{mask: maskService.date}">
                </mat-form-field>
            </div>
            <div class="col-md">
                <mat-form-field class="width-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        <mat-option value="paid">Pago</mat-option>
                        <mat-option value="processing">Processando</mat-option>
                        <mat-option value="refused">Recusado</mat-option>
                        <mat-option value="waiting_payment">Aguardando Pagamento</mat-option>
                        <mat-option value="refunded">Estornado</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div matDialogActions>
    <button class="right" *ngIf="!loading && !isAddPayment" [disabled]="!form.valid" mat-raised-button color="accent"
        (click)="saveChanges()">Salvar</button>
    <button class="right" *ngIf="!loading && isAddPayment" [disabled]="!form.valid" mat-raised-button color="accent"
        (click)="addStudentPayment()">Adicionar</button>
    <mat-spinner class="right mr-3" *ngIf="loading" diameter="30"></mat-spinner>
</div>