<div class="mt-3 mb-3" *ngIf="userService.isAdmin()">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-select [(ngModel)]="school" (selectionChange)="open()"
                    placeholder="Escola">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="showButton">
            <button (click)="downloadSchoolReport()" class="right" nbButton size="small"
                status="primary" nbSpinnerStatus="warning">Gerar Relatório</button>
        </div>
    </div>
</div>