import { EditSchoolRoutesComponent } from './edit-school-routes/edit-school-routes.component';
import { School } from './../school';
import { RouteMain } from './edit-route/route-main';
import { routes } from './../../admin.routing';
import { NativeDateAdapter } from '@angular/material/core';

import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { SchoolService } from '../school.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Unit } from '../unit';
import { ResponseApi } from 'src/app/core/models/response-api';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../admin.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Subscription } from 'rxjs';
import { Routes } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { EditRouteComponent } from './edit-route/edit-route.component';



@Component({
  selector: 'app-create-routes',
  templateUrl: './create-routes.component.html',
  styleUrls: ['./create-routes.component.scss']
})
export class CreateRoutesComponent implements OnInit, OnDestroy {

  public icons: string[];
  public orderroots: number[];
  public createRouteForm: FormGroup;
  public showSchoolList: boolean;
  public schools: School[];
  public selectedschools: School[];
  public schoolchip: { id: number, route: string }[];
  public routes: { id?: number, route?: string, icon?: string, link?: string, order?: number, escolas?: [] }[];
  public teste: string[] = [];
  public editMode: boolean;
  public loading: boolean;
  private admUpdate$: Subscription;
  public filtterRoute: boolean = false;
  public filterSchool: boolean = false;
  public isAdm: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public route: RouteMain,
    @Optional() public dialogRef: MatDialogRef<EditRouteComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private adminService: AdminService) { }


  ngOnInit() {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.isAdm = false;
    this.createRouteForm = this.formBuilder.group({
      id: [''],
      descr: ['', Validators.required],
      icon: [''],
      order: [''],
      schools: [''],
      link: ['', Validators.required],
    });
    this.routes = [];
    this.schools = [];
    this.schoolchip = [];
    this.icons = [];
    this.orderroots = [];
    this.selectedschools = [];
    this.editMode = false;
    this.setOrder();
    this.setIcons();
    this.getSchools();
    this.profileInit();


    if (this.route) { this.initializeEditMode(); }
  }

  setOrder() {
    for (let i = 0; i < 20; i++) {
      this.orderroots[i] = i;
    }
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.isAdm = true;
    }else{
      this.isAdm = false;
    }
  }

  setIcons() {
    this.icons[0] = "dashboard";
    this.icons[1] = "video_library";
    this.icons[2] = "edit";
    this.icons[3] = "visibility"
    this.icons[4] = "add_circle_outline";
    this.icons[5] = "calculate";
    this.icons[6] = "home";
    this.icons[7] = "group_add";
    this.icons[8] = "face";
    this.icons[9] = "archive";
    this.icons[10] = "ondemand_video";
    this.icons[11] = "poll";
    this.icons[12] = "payment";
    this.icons[13] = "rule";
    this.icons[14] = "archives";
    this.icons[15] = "ballot";
    this.icons[16] = "library_music";
    this.icons[17] = "create";
    this.icons[18] = "source";
    this.icons[19] = "forum";
    this.icons[20] = "payments";
    this.icons[21] = "shopping_bag";
    this.icons[22] = "science";
    this.icons[23] = "credit_card";
    this.icons[24] = "groups";
    this.icons[25] = "help_outline";

  }

  getSchools(id?: number) {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  addSchollChip(schoolId: number[]) {
    if (schoolId.length === 0 ) {
      this.selectedschools = [];
    } else {

      this.selectedschools = [];

      schoolId.forEach(schoolId => {
        for (const school of this.schools) {
          if (school.id == schoolId) {
              this.selectedschools.push(school);

            }
          }
      });

      console.log(this.selectedschools);
    }

    let chipsToRemove = this.schools.filter( sf => !this.selectedschools.includes(sf) );

    if(chipsToRemove.length > 0) {
      chipsToRemove.forEach( (school) => this.removeSChoollChip(school) );
    };
  }

  removeSChoollChip(sch: School) {
    const index = this.selectedschools.indexOf(sch);

    if (index >= 0) {
      this.selectedschools.splice(index, 1);
    }
  }

  getRoutes(id?: number) {
    this.adminService.getRoutes().subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.routes = response.data as RouteMain[];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar as rotas. Verifique a conexão e tente novamente'));
  }

  openList() {
    this.filterSchool = false;
    this.filtterRoute = true;
    this.editMode = true;
    this.getRoutes();
    this.showSchoolList = true;
  }

  openSchoolList() {
    this.filtterRoute = false;
    this.filterSchool = true;
    this.editMode = true;
    this.getSchools();
    this.showSchoolList = true;
  }

  initializeEditMode() {
    this.getRoutes(this.route.id);
    this.editMode = true;
    this.createRouteForm.patchValue(this.route);
  }

  /* handleFileInput(files: FileList, from: string): void {
    if (files && files[0]) {

      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        switch (from) {
          case 'logo':
            this.desc = reader.result;
            break;
          case 'login':
            this.loginImg = reader.result;
            break;
          case 'header':
            this.headerImg = reader.result;
            break;
        }
      };
    }
  } */

  createRoute() {

    /* this.createRouteForm.get('name').setValue(name); */

    /* this.createRouteForm.get('icon').setValue(this.icon);
    this.createRouteForm.get('icon').setValue(this.icon);
    this.createRouteForm.get('ordem').setValue(this.ordem);
 */

    const schoolsid = [];
    this.selectedschools.map(s => schoolsid.push(s.id));
    this.createRouteForm.get('schools').setValue(schoolsid);

    this.loading = true;
    const routesScholl = this.createRouteForm.getRawValue();
    if (this.Validaterouteform() == true) {
      this.schoolService.createRoutes(routesScholl)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Rota cadastrada', 3);
            this.createRouteForm.reset();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao criar a Rota. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
    else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }

  removeRoute(Routeid: number) {
    if (confirm('Deseja realmente remover a rota?')) {
      this.schoolService.removeRoute(Routeid)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.alertService.success('Rota removida', 3);
            this.getRoutes();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a Rota. Verifique a conexão e tente novamente'));
    }
  }

  editRoute(route: RouteMain) {
    const dialogRef = this.dialog.open(EditRouteComponent, {
      minWidth: '60vw',
      data: route
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRoutes();
      }
    });
  }

  editSchoolRoutes(school: School) {
    const dialogRef = this.dialog.open(EditSchoolRoutesComponent, {
      minWidth: '60vw',
      data: school
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSchools();
      }
    });
  }
  Validaterouteform(): Boolean {
    const routesScholl = this.createRouteForm.getRawValue();
    if (routesScholl.descr != "") {
      return true;
    }
    if (routesScholl.icon != "") {
      return true;
    }
    if (routesScholl.ordem != "") {
      return true;
    }
    if (routesScholl.escola != "") {
      return true;
    }
    if (routesScholl.link != "") {
      return true;
    }
    return false;
  }

  btnVoltar() {
    this.filterSchool = false;
    this.filtterRoute = false;
    this.showSchoolList = false;
    this.editMode = false;
  }
}
