<div class="container pt-5 pb-5 width-100">
    <h3 class="mb-5">Relatórios de provas e Simulados</h3>
    <!--  <div class="col-md-4"><button (click)="generatePDFs()" mat-raised-button color="accent">Download PDF</button></div> -->
    <mat-card>
        <mat-card-content>
            <div class="row">

                <div class="col-md-12" *ngIf="userService.isAdmin()">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Escola *" (selectionChange)="getUnits($event.value)" [(ngModel)]="schoolId">
                            <mat-option *ngFor="let school of schools" (click)="this.actualData.school = school;" [value]=" school.id">{{school.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row ">
                <div class="col-md ">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Tipo de avaliação *" (selectionChange)="this.actualData.typeSim = $event.value;">
                            <mat-option value="Prova">
                                Prova
                            </mat-option>
                            <mat-option value="Simulado">
                                Simulado
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md " *ngIf="(userService.isAdmin() || userService.isGeneralCoordinator()) ">
                    <mat-form-field appearance="outline" class="width-100 ">
                        <mat-select placeholder="Unidade * " (selectionChange)="getClasses($event.value)" [(ngModel)]="unitId">
                            <mat-option *ngFor="let unit of units" (click)="this.actualData.unit = unit;" [value]="unit.id">{{unit.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md ">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Turma *" (selectionChange)="getMocks($event.value)" [(ngModel)]="classId">
                            <mat-option *ngFor=" let class of classes" (click)="this.actualData.class= class;" [value]="class.id">{{class.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="width-100 ">
                        <mat-select placeholder="Simulado * " [(ngModel)]="mockId">
                            <mat-option *ngFor="let mock of mocks" (click)="this.actualData.nameTest= mock;" [value]="mock.id">
                                {{mock.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md ">
                    <mat-form-field appearance="outline" class="width-100 ">
                        <mat-select placeholder="Tipo de relatorio * " (selectionChange)="getSelectedReport($event.value) ; this.actualData.TypeRelat = $event.value;">
                            <mat-option value="Performance geral">
                                Performance geral
                            </mat-option>
                            <mat-option value="Performance disciplina">
                                Performance disciplina
                            </mat-option>
                            <mat-option value="Nota">
                                Nota
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12 ">
                    <button mat-raised-button color="primary" (click)="getSelectedReportBtn(classId, mockId)">Atualizar</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div *ngIf="typePerformance=='PG' && btnAtualizar == true" class="container pt-5 pb-5 width-100 row">
        <nb-list class="width-100 mt-3 center Tableperformance">

            <nb-list-item>
                <div class="row">
                    <div class="col-md-4"><button (click)="generatePDF()" mat-raised-button color="accent">Download
                            PDF</button></div>
                    <div class="col-md-4"><button (click)="generateXls()" mat-raised-button color="accent">Download
                            Excell</button></div>
                    <div class="col-md-4">
                        <mat-select placeholder="Ordenar por">
                            <mat-option value="perf.correct" (click)="getOrder('perf.correct')">
                                Ranking
                            </mat-option>
                            <mat-option value="perf.studentName" (click)="getOrder('perf.studentName')">
                                Nome do aluno
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </nb-list-item>

            <!-- List header -->
            <nb-list-item class="bg-color-primary list-header" style="color: white ">
                <div class="row ">
                    <div class="col-md-3" (click)="getOrder('perf.studentName')">Nome Aluno</div>
                    <div class="col-md-2" (click)="getOrder('perf.totalexercicies')">Total Questoes</div>
                    <div class="col-md-2">Acertos Aluno</div>
                    <div class="col-md-2">Erros aluno</div>
                    <div class="col-md-1">Porcentagem</div>
                </div>
            </nb-list-item>

            <!-- disciplina report -->
            <nb-list-item *ngFor="let perf of reportPerfresp | orderBy: order:reverse">
                <div class="row ">
                    <div class="col-md-3">
                        {{perf.studentName}}
                    </div>
                    <div class="col-md-2">
                        {{perf.totalexercicies}}
                    </div>
                    <div class="col-md-2">
                        {{perf.correct}}
                    </div>
                    <div class="col-md-2">
                        {{perf.errors}}
                    </div>
                    <div class="col-md-1">
                        {{perf.porcent}}%
                    </div>

                    <div class="col-md-2 ">
                        <button mat-raised-button color="primary" (click)="openMock(perf.studentId, perf.idsimulado , classId)">Ver Simulado</button>

                    </div>

                </div>
            </nb-list-item>
        </nb-list>
    </div>
    <div *ngIf="typePerformance=='PD' && btnAtualizar == true" class="container pt-5 pb-5 width-100 row">
        <nb-list class="width-100 mt-3 center Tableperformance">

            <nb-list-item>
                <div class="row">
                    <div class="col-md-4"><button (click)="generatePDF()" mat-raised-button color="accent">Download
                            PDF</button></div>
                    <div class="col-md-4"><button (click)="generateXls()" mat-raised-button color="accent">Download
                            Excell</button></div>
                    <div class="col-md-4">
                        <mat-select placeholder="Ordenar por">
                            <mat-option value="perf.correct" (click)="getOrder('perf.correct')">
                                Ranking
                            </mat-option>
                            <mat-option value="perf.studentName" (click)="getOrder('perf.studentName')">
                                Nome do aluno
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </nb-list-item>

            <!-- List header -->
            <nb-list-item class="bg-color-primary list-header" style="color: white ">
                <div class="row">
                    <div class="col-md-2">Nome Aluno</div>
                    <div class="col-md-2">Disciplinas</div>
                    <div class="col-md-2">Questoes</div>
                    <div class="col-md-2">Corretas</div>
                    <div class="col-md-1">Incorretas</div>
                    <div class="col-md-1">Pontuação</div>
                </div>
            </nb-list-item>

            <!-- disciplina report -->
            <nb-list-item *ngFor="let perf of reportPerfrespdisc | orderBy: order:reverse">
                <div class="row">
                    <div class="col-md-2">
                        {{perf.studentName}}
                    </div>
                    <div class="col-md-2">
                        {{perf.disciplineName}}
                    </div>
                    <div class="col-md-2">
                        {{perf.totalexercicies}}
                    </div>
                    <div class="col-md-2">
                        {{perf.correct}}
                    </div>
                    <div class="col-md-1">
                        {{perf.errors}}
                    </div>
                    <div class="col-md-1">
                        {{perf.points}}
                    </div>
                    <div class="col-md-2">
                        <button mat-raised-button color="primary" (click)="openMock(perf.studentId, perf.idsimulado , classId)">Ver Simulado</button>

                    </div>
                </div>
            </nb-list-item>
        </nb-list>
    </div>
    <div *ngIf="typePerformance=='NT' && btnAtualizar == true" class="container pt-5 pb-5 width-100 row">
        <nb-list class="width-100 mt-3 center Tableperformance ">

            <nb-list-item>
                <div class="row">
                    <div class="col-md-4"><button (click)="generatePDF()" mat-raised-button color="accent">Download
                            PDF</button></div>
                    <div class="col-md-4"><button (click)="generateXls()" mat-raised-button color="accent">Download
                            Excell</button></div>
                    <div class="col-md-4">
                        <mat-select placeholder="Ordenar por">
                            <mat-option value="perf.note" (click)="getOrder( 'perf.note')">
                                Ranking
                            </mat-option>
                            <mat-option value="perf.studentName" (click)="getOrder( 'perf.studentName')">
                                Nome do aluno
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </nb-list-item>

            <!-- List header -->
            <nb-list-item class="bg-color-primary list-header" style="color: white ">
                <div class="row">
                    <div class="col-md-3">Nome Aluno</div>
                    <div class="col-md-3">Total Respostas</div>
                    <div class="col-md-3">Nota</div>
                </div>
            </nb-list-item>

            <!-- disciplina report -->
            <nb-list-item *ngFor="let perf of reportPerfrespNote | orderBy: order:reverse">
                <div class="row">
                    <div class="col-md-3">
                        {{perf.studentName}}
                    </div>
                    <div class="col-md-3">
                        {{perf.totalDone}}/{{perf.totalexercicies}}
                    </div>
                    <div class="col-md-3">
                        {{perf.note}}
                    </div>
                    <div class="col-md-3">
                        <button mat-raised-button color="primary" (click)="openMock(perf.studentId, perf.idsimulado , classId)">Ver Simulado</button>
                    </div>
                </div>
            </nb-list-item>
        </nb-list>
    </div>