import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap, map } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private userService: UserService,
        private router: Router,
        private alertService: AlertService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = btoa(this.userService.getUserId() + ':' + this.userService.token);

        req = req.clone({
            setHeaders: {
                Authorization: `basic ${token}`,
            },
        });

        return next.handle(req).pipe(tap(resp => {
            if (resp instanceof HttpResponse) {
                if (resp.status === 203) {
                    this.alertService.warning('Sua sessão expirou!');
                    this.userService.logout();
                    return;
                }
            }
        }
        ));

    }
}
