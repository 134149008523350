import { Component, Input, OnInit, Optional } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() percent: number[];
  @Input() title: string;
  @Input() @Optional() customIcon: string;
  @Input() label: [string, string];
  public total: number;

  constructor() { }

  ngOnInit(): void {
    
    this.total = (100 * this.percent[1]) / this.percent[0];
    //console.log(this.total);
  }

}