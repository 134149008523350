 
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AutoSigninComponent } from './core/auth/auto-signin/auto-signin.component';
import { RecoveryComponent } from './core/auth/recovery/recovery.component';
import { SignInComponent } from './core/auth/signin/signin.component';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { SignInGuard } from './core/guards/signin.guard';
import { StudyPlanCalendarComponent } from './modules/dashboard/student/calendar/study-plan-calendar.component';
import { DashboardStudentComponent } from './modules/dashboard/student/dashboard-student.component';
import { DisciplineVideosComponent } from './modules/discipline/videos/discipline-videos.component';
import { ExercisesListComponent } from './modules/exercises-list/exercises-list.component';
import { ExperimentSignupComponent } from './modules/experiment/signup/experiment-signup.component';
import { ForumCommentComponent } from './modules/forum/comment/forum-comment.component';
import { ForumListComponent } from './modules/forum/list/forum-list.component';
import { MockListComponent } from './modules/mock/list/mock-list.component';
import { MockResultComponent } from './modules/mock/result/mock-result.component';
import { PaymentBuyComponent } from './modules/payment/buy/payment-buy.component';
import { PaymentThanksComponent } from './modules/payment/thanks/payment-thanks.component';
import { PodcastComponent } from './modules/podcast/podcast.component';
import { SendRedactionComponent } from './modules/redaction/send/send-redaction.component';
import { SentRedactionsComponent } from './modules/redaction/sent/sent-redactions.component';
import { ReportPerformanceComponent } from './modules/report/performance/report-performance.component';
import { RepositoryFileListComponent } from './modules/repository/file/list/repository-file-list.component';
import { MaterialListComponent } from './modules/repository/material/list/material-list.component';
import { RepositoryVideoListComponent } from './modules/repository/video/list/repository-video-list.component';
import { RepositoryVideoWatchComponent } from './modules/repository/video/watch/repository-video-watch.component';
import { ProfileComponent } from './modules/user/profile/profile.component';
import { WatchVideoComponent } from './modules/video/watch-video/watch-video.component';
import { PaymentExpiredComponent } from './modules/payment/expired/payment-expired.component';
import { DisciplinePlanDisciplinesComponent } from './modules/discipline/plan-disciplines/discipline-plan-disciplines.component';
import { PaymentPaymentsComponent } from './modules/payment/payments/payment-payments.component';
import { TaskStudentComponent } from './modules/task-student/task-student.component';
import { SummationResultComponent } from './modules/mock/summation-result/summation-result.component';
import { ClassroomGeneralListComponent } from './modules/classroom/classroom-general-list/classroom-general-list.component';
import { CalendarComponent } from './modules/calendar/calendar.component';
import { ManageConfiComponent } from './modules/admin/my-configuration/manage-confi/manage-confi.component';
import { BuyCreditComponent } from './modules/redaction/buy-credit/buy-credit.component';
import { CommunityComponent } from './modules/community/community/community.component';
import { AnswerTopicComponent } from './modules/community/community/answer-topic/answer-topic.component';
import { FlipBookComponent } from './modules/flip-book/flip-book.component';
import { MenuNotificationComponent } from './modules/menu-notification/menu-notification.component';
import { PerformanceReportComponent } from './modules/admin/performance-report/performance-report.component';
/* import { CertificatesComponent } from './modules/certificates/certificates.component';
import { FileComponent } from './modules/certificates/file/file.component'; */
import { FlipBookViewComponent } from './modules/flip-book-student/flip-book-view/flip-book-view.component';
import { FatherScreenComponent } from './modules/father-screen/father-screen.component';
import { IntegrationPortalComponent } from './core/components/integration-portal/integration-portal.component';
import { FlipBookStudentComponent } from './modules/flip-book-student/flip-book-student.component';
import { CertificatesComponent } from './modules/certificates/certificates.component';
import { FileComponent } from './modules/certificates/file/file.component';
import { EditChannelComponent } from './modules/admin/channel/edit-channel/edit-channel.component';
import { TopicDisciplineComponent } from './modules/discipline/topic-discipline/topic-discipline.component';
import { PlansTopicComponent } from './modules/discipline/topic-discipline/components/plans-topic/plans-topic.component';
import { DisciplinesTopicComponent } from './modules/discipline/topic-discipline/components/disciplines-topic/disciplines-topic.component';
import { VideoTopicComponent } from './modules/discipline/topic-discipline/components/video-topic/video-topic.component';
import { TopicsComponent } from './modules/discipline/topic-discipline/components/topics/topics.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'signin',
        pathMatch: 'full'
    },
    {
        path: 'signin',
        component: SignInComponent,
        canActivate: [SignInGuard],
    },
    {
        path: 'redirect',
        component: AutoSigninComponent,
        canActivate: [SignInGuard],
    },
    {
        path: 'classrom/generallist',
        component: ClassroomGeneralListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'classrom/generallist/:planId',
        component: ClassroomGeneralListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        component: DashboardStudentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'calendar',
        component: StudyPlanCalendarComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'community',
        component: CommunityComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'topic-answer/:topicId',
        component: AnswerTopicComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'calendar/v2/:planId',
        component: CalendarComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'calendar/v2/:planId/:viewAsStudent',
        component: CalendarComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'config/manage',
        component: ManageConfiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/watch/:videoId',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/watch/:planId/:disciplineId/:videoId',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/watch/:planId/:disciplineId/:videoId/:channelId',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/watch/:planId/:disciplineId/:videoId/teacher',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/watch/:videoId/:topicId',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/list/:planId/:scheduleDay/:i',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/day/:planId/:i',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'video/day/next/:planId/:i',
        component: WatchVideoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'redaction/send',
        component: SendRedactionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'redaction/sent',
        component: SentRedactionsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'podcast',
        component: PodcastComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'exercises-list',
        component: ExercisesListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'discipline/videos',
        component: DisciplineVideosComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'comment',
        component: ForumCommentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'mock/list',
        component: MockListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'test/list',
        component: MockListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'mock/result/:mockId',
        component: MockResultComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'mock/result/:mockId/:studentId/:isReport',
        component: MockResultComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payment/buy/:planHash',
        component: PaymentBuyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payment/thanks',
        component: PaymentThanksComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'repository/file/list',
        component: RepositoryFileListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'repository/video/list',
        component: RepositoryVideoListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'signin/auto/:login/:pass',
        component: AutoSigninComponent,
        canActivate: [SignInGuard]
    },
    {
        path: 'repository/video/:code/:name',
        component: RepositoryVideoWatchComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'signin/recovery',
        component: RecoveryComponent,
        canActivate: [SignInGuard]
    },
    {
        path: 'report/performance',
        component: ReportPerformanceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'performance/report',
        component: PerformanceReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'forum/list',
        component: ForumListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'material',
        component: MaterialListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'experiment/:experimentHash',
        component: ExperimentSignupComponent,
        canActivate: [SignInGuard]
    },
    {
        path: 'payment/expired',
        component: PaymentExpiredComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'plan/disciplines',
        component: DisciplinePlanDisciplinesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'plan/disciplines/:planId',
        component: DisciplinePlanDisciplinesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'plan/disciplines/:planId/:disciplineId',
        component: DisciplinePlanDisciplinesComponent,
        canActivate: [AuthGuard]
    },
    { 
        path: 'mock/contest-list',
        component: MockListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payment/list',
        component: PaymentPaymentsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'task-student',
        component: TaskStudentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'task-student/channel/:channelId',
        component: TaskStudentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'summation/result/:mockId',
        component: SummationResultComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'summation/result/:mockId/:studentId',
        component: SummationResultComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'redaction/buy-credit',
        component: BuyCreditComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'flip-book',
        component: FlipBookComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'notifications',
        component: MenuNotificationComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'certificates',
        component: CertificatesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'certificates/file/:id',
        component: FileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'student/flip-book',
        component : FlipBookStudentComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'view/flip-book/:ebookId',
        component: FlipBookViewComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'integrations-portal',
        component: IntegrationPortalComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'admin/channel/edit',
        component: EditChannelComponent,
        canActivate : [AuthGuard]

    },
    {   path: 'father/screen',
        component: FatherScreenComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'plan/channel/:channelId',
        component: DisciplinePlanDisciplinesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'plan/channel/:channelId/disciplines/:planId',
        component: DisciplinePlanDisciplinesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'plan/channel/disciplines/:planId/:disciplineId',
        component: DisciplinePlanDisciplinesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'repository/channel/:channelId',
        component: RepositoryFileListComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'classroom/channel/:channelId',
        component: ClassroomGeneralListComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'classroom/channel/:channelId/:planId',
        component: ClassroomGeneralListComponent,
        canActivate : [AuthGuard]
    },
    /* {
        path: 'ebook/channel/:channelId',
        component: FlipBookStudentComponent,
        canActivate : [AuthGuard]
    }, */
    {
        path: 'student/flip-book/channel/:channelId',
        component: FlipBookStudentComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'topic/plans',
        component: PlansTopicComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'topic/plan/:planId/disciplines',
        component: DisciplinesTopicComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'topic/plan/:planId/discipline/:disciplineId/topics',
        component: TopicsComponent,
        canActivate : [AuthGuard]
    },
    {
        path: 'topic/plan/:planId/discipline/:disciplineId/topic/:topicId/videos',
        component: VideoTopicComponent,
        canActivate : [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false , onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
