import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { NetworkService } from './core/components/network/network.service';
import { OctadeskService } from './core/services/octadesk.service';
import { ScriptService } from './core/services/script.service';
import { UserService } from './modules/user/user.service';
import { IconService } from './shared/services/icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public offline: boolean;

  constructor(
    private authService: AuthService,
    private networkService: NetworkService,
    private userService: UserService,
    private octadeskService: OctadeskService,
    private iconService: IconService) {
    this.authService.initVersionCheck(10);
    this.networkService.networkOnline()
      .subscribe(isOnline => {
        this.offline = !isOnline;
      });
  }

  ngOnInit() {
    if (this.userService.isLogged()) {
      this.octadeskService.startOctadesk();
    }
  }

}
