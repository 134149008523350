<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-12 mt-2">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title d-none">Acompanhe suas</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Atividades</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Atividades</h6>
                        <p *ngIf="!layoutService.isMobile">&nbsp;</p>
                        <p *ngIf="layoutService.isMobile" class="fs-13 pb-0">Envie e acompanhe as atividades</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-12">
            <div *ngIf="showMessageEdu && isInPlansEdu" class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
                <strong>Exclusivo para assinantes Diversidades Edu!</strong>
            </div>
        </div>
        <div class="col-12 p-0">
            <div class="row m-0">
                <div [ngClass]="{'text-center mb-3 col-12 p-0': layoutService.isMobile, 'col-md-6': !layoutService.isMobile}">
                    <h6 class="pl-2 text-color-orange text-orange">Atividades pendentes</h6>
                    <app-pending-tasks></app-pending-tasks>
                </div>
                <!-- <mat-divider *ngIf="layoutService.isMobile" class="col-md mt-2 mb-2"></mat-divider> -->
                <div [ngClass]="{'text-center mb-3 col-12 p-0': layoutService.isMobile, 'col-md-6': !layoutService.isMobile}">
                    <h6 class="pl-2 text-color-accent" color="accent">Atividades enviadas</h6>
                    <app-sent-tasks></app-sent-tasks>
                </div>
            </div>
        </div>
    </div>
</div>
