import { Injectable } from "@angular/core";
import { Notifications } from "src/app/modules/admin/notifications/notifications";

@Injectable({
    providedIn: 'root',
})

export class NotificationStore {
    
    private notifications:Notifications[] = [];

    constructor(notifications?:Notifications[]){
        if(notifications){
            this.notifications = notifications;
        }
    }
    
    addOne(notification:Notifications)
    {  
        if(notification != null && notification != undefined){
            this.notifications.push(notification); 
        }        
    }
    add(notification:Notifications[])
    {
        if(notification.length > 0){
            notification.forEach(element => {
                if(this.getOne(element.id) == null){
                    this.notifications.push(element);
                }
               
            });
        }         
    }
    getOne(id:number)
    {
        if(id != null && id > 0){
            let obj = this.notifications.filter((ntf)=> {return ntf.id == id }  );
            if(obj){
                return obj;
            }
            return null;
        }
        return null;
    }
    getAll()
    {
        let ntf: Notifications[] = [];
        this.notifications.forEach(element => {
            if(!element.read){
                ntf.push(element);
            }
        });
        return ntf;
    }
    getAllIds()
    {
        let ntf: Number[] = [];
        this.notifications.forEach(element => {
            
            ntf.push(element.id);
           
        });
        return ntf;
    }
    remove(id:number)
    {
        if(id != null && id > 0){
            this.notifications = this.notifications.filter((ntf)=> {return ntf.id != id }  );
        }
    }
    clear()
    {
        this.notifications = [];
    }
    notify(){ 

        if(this.countUnRead() > 0){
            return true;
        }else{
            return false;
        }
    }
    countRead(){
        return this.getRead().length;
    }
    countUnRead(){
        if(this.count() > this.countRead()){
            return this.count() - this.countRead();
        }
        if(this.count() == this.countRead()){
            return this.countRead();
        }
        return 0;        
    }
    getRead(){
        return this.notifications.filter((ntf)=> {return ntf.read === true }  );
    }
    read(id:number){
        if(id != null && id > 0){
            this.notifications.map((ntf)=>{
                if(ntf.id === id){
                    ntf.read =true;
                }
            })
        }        
    }
    unRead(id:number){
        if(id != null && id > 0){
            this.notifications.map((ntf)=>{
                if(ntf.id === id){
                    ntf.read =false;
                }
            })
        }        
    }
    count(){
        return this.notifications.length;
    }
}
