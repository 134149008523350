import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExerciseList } from '../admin/exercises-list/exercise-list';

@Injectable({ providedIn: 'root' })
export class ExercisesListService {

    private API = environment.ApiUrl;

    constructor(private http: HttpClient) { }

    getDisciplinesExercises() {
        return this.http.get(this.API + '/disciplines/exercises', { observe: 'response' });
    }

    createExerciseList(exerciseList: ExerciseList) {
        return this.http.post(this.API + '/miscellaneous/setExercisesList', exerciseList, { observe: 'response' });
    }

    getExerciseLists(schoolId:number) {
        return this.http.post(this.API + '/miscellaneous/getExercisesList', {schoolId}, { observe: 'response' });
    }

    deleteExerciseList(id) {
        return this.http.post(this.API + '/miscellaneous/deleteExerciseList', { id }, { observe: 'response' });
    }

    getExercisesListByDiscipline(studentId: number) {
        return this.http.post(this.API + '/miscellaneous/getExercisesListByDiscipline', { studentId }, { observe: 'response' });
    }
    getDisciplineByStudent(studentId: number) {
        return this.http.post(this.API + '/discipline/getDisciplineByStudent', { studentId }, { observe: 'response' });
    }
    getListExercises(schoolId: number, userId: number, profileId?: number) {
        return this.http.post(this.API + '/miscellaneous/getListExercises', { schoolId, userId, profileId }, { observe: 'response' });
    }
    getListByName(listName: string) {
        return this.http.get(this.API + '/exerciselist/getListsByName/'+listName, { observe: 'response' });
    }

    getListByNameCoord(listName: string , schoolId: number) {
        return this.http.get(this.API + '/exerciselist/getListByNameCoord/'+listName+'/'+schoolId, { observe: 'response' });
    }
}

