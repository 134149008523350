export default class Node<T>{
 
    next: Node<T> | null = null;
    prev: Node<T> | null = null;

    constructor( public data:T){}

    public toString = (): string => {
        return this.data.toString();
    };
}