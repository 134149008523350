import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Exercise } from 'src/app/modules/admin/exercise/exercise';
import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Video } from 'src/app/modules/video/video';
import { VideoService } from 'src/app/modules/video/video.service';
import { Embed } from 'src/app/shared/interfaces/embed';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';

import { AdminService } from '../../admin.service';
import { ClassroomCreateComponent } from '../../classroom/create/classroom-create.component'
import { disciplineTag } from '../../discipline-tag/discipline-tag-create/discipline-tag';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { ExerciseService } from '../../exercise/exercise.service';
import { libQuestions } from '../../my-exercise-library/lib-interfaces';
import { MyExerciseLibraryService } from '../../my-exercise-library/my-exercise-library.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';

interface Classroom {

  id: number;
  name: string;
  classId: number;
  planId?: any;
  schools: [],
  schoolId: number,
  unitId: number;
  link: string;
  initDate: string | Date;
  endDate: string | Date;
  tag: string;
  recordLink?: string;
  planName?: string;
  versionId: number;
  codeVideo?: String;
  live_vimeo_id?: number;
  isVimeo? : boolean;
  liveVideo?: number;
  embedType: string;
  haveYoutubeMask : boolean;
}

interface StudyPlans {
  name: string;
  id: number;
}

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss']
})
export class AddVideoComponent implements OnInit, OnDestroy {

  private admUpdate$: Subscription;

  public showVideosList: boolean;
  public addVideoForm: FormGroup;
  public selectedExercises: Exercise[];
  public selectedQuestions: libQuestions[];
  public exercises: Exercise[];
  public videos: Video[];
  public classrooms: Classroom[];
  public disciplines: Discipline[];
  public disciplinesAux: Discipline[];
  public disciplinesExercise: Discipline[];
  public disciplineId: number;
  public editMode: boolean;
  public loading: boolean;
  public loadingExercises: boolean;
  public loadingDisciplines: boolean;
  public filter = '';
  public filterVideoName: string;
  public filterCountTimeOut: any;
  public role = '';
  public maxChars = 250;
  public plans: StudyPlan[];
  public domain = window.location.hostname;
  public schools: School[];
  public libQuestions: libQuestions[];
  public version: any;
  public schoolIdPlan: number;
  public studyPlanArray: StudyPlans[] = [];
  public initDate: string;
  public endDate: string;
  public versionId: number;
  public schoolId: number;
  public planId: number;
  public classroomLive: Classroom;
  public editLive: boolean;
  public filterPlanIdLive: number;
  public schoolIdEditLive: number;
  public filterClass: string;
  public recordedLink: string;
  public classroomsLiveNew: Classroom[];
  public disciplinesTags: disciplineTag[];
  public selectedDisciplinesTags: disciplineTag[];
  public schoolsForTags: School[];
  public typeSelectClassroomVideo: string;
  public strAuxRecordLinkYoutube: string;
  public recordMaskYTLive: boolean;
  public plansTag: StudyPlan[];
  public disciplinesTagsTags: Discipline[];

  public live: boolean;
  public newVideoId: number;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public video: Video,
    @Optional() public dialogRef: MatDialogRef<AddVideoComponent>,
    private dialog: MatDialog,
    private videoService: VideoService,
    private alertService: AlertService,
    private libraryService: MyExerciseLibraryService,
    private formBuilder: FormBuilder,
    private disciplineService: DisciplineService,
    private exerciseService: ExerciseService,
    public maskService: MaskService,
    private classroomService: ClassroomService,
    private adminService: AdminService,
    private iconRegistry: MatIconRegistry,
    public userService: UserService,
    private studyPlanService: StudyPlanService,
    private sanitizer: DomSanitizer,
    private schoolService: SchoolService) { }

  ngOnInit(): void {
    this.classroomsLiveNew = [];
    this.editLive = false;
    this.classroomLive = new Object() as Classroom;

    this.version = [{ option: 'Calendário modelo antigo', value: 1 }, { option: 'Calendário modelo novo', value: 2 }];

    if (this.video) {
      this.live = this.video.transmissao;
    }
    this.iconRegistry.addSvgIcon(
      'vimeo',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/vimeo.svg'));
    this.iconRegistry.addSvgIcon(
      'youtube',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/youtube.svg'));
      this.iconRegistry.addSvgIcon(
        'panda',
        this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/panda.svg'));
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());

      if(!this.userService.isAdmin()){
        this.getPlansBySchoolTag(this.userService.getUserSchool());
      }
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    if (this.userService.isAdmin()) {
      this.getDisciplines();
    } else if (this.userService.isGeneralCoordinator()) {
      this.getSchoolDisciplines(this.userService.getUserSchool());
    }

    this.getDisciplinesExerciseOnly();

    this.disciplinesTags = [];
    this.selectedExercises = [];
    this.selectedQuestions = [];
    this.selectedDisciplinesTags = [];
    this.addVideoForm = this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      tag: [''],
      embedCode: ['', Validators.required],
      embedType: ['', Validators.required],
      discipline: ['', Validators.required],
      exercises: [''],
      libQuestions: [''],
      transmissao: [false],
      embed_chat: [''],
      description: [''],
      disciplinesTag: [''],
      selectedSchoolForTag:[''],
      youtubeMask:[''],
      schoolOnly:[false]
    });
    this.addVideoForm.get('schoolOnly').setValue(false);
    if (this.video) { this.initializeEditMode(); }

    this.getSchoolsLive();
    if(this.userService.isAdmin()){
      this.getDisciplinesTags();
      this.getSchoolsSelectedTag();
    }
    else{
      this.getDisciplinesTagsBySchool(this.userService.getUserSchool());
    }
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getSchoolsLive() {
    this.plans = [];
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getDisciplinesTags() {
    this.disciplineService.getDisciplinesTags()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplinesTags = response.data as disciplineTag[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getVideoTags(videoId : number){
    this.disciplineService.getVideoTags(videoId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.selectedDisciplinesTags = response.data as disciplineTag[];
      }
    })
  }

  getSchoolPlans(schoolId: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  getClassByName(name: string) {

    if (name) {

      if(this.userService.isAdmin()){
        this.classroomService.getClassByName(false, name)
          .subscribe(res => {
            const response = res.body as ResponseApi;
  
            if (!response.error) {
              this.classrooms = response.data as Classroom[];
            }
  
            else {
              this.alertService.error(response.error);
            }
          })
      } else if(this.userService.isGeneralCoordinator()){
        this.classroomService.getClassBySchoolByName(false, name, this.userService.getUserSchool())
          .subscribe(res => {
            const response = res.body as ResponseApi;
  
            if (!response.error) {
              this.classrooms = response.data as Classroom[];
              console.log(this.classrooms);
            }
  
            else {
              this.alertService.error(response.error);
            }
          })
      } else if(this.userService.isUnitCoordinator()){
        this.classroomService.getClassByUnitByName(false, name, this.userService.getUserUnit())
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.classrooms = response.data as Classroom[];
          }

          else {
            this.alertService.error(response.error);
          }
        })
      }
    } else {
      this.alertService.error('O campo nome não pode estar vazio para fazer a busca!');
    }
  }

  getSchoolPlansByVersion(version: number, schoolId: number) {

    console.log(this.userService.getUserSchool(), 'ide d escola');

    if (schoolId) {
      this.plans = [];
      this.schoolIdPlan = schoolId;
      this.studyPlanService.getPlansByVersion(version, schoolId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.plans = response.data as StudyPlan[];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }
    else if (this.schoolIdPlan) {
      this.studyPlanService.getPlansByVersion(version, this.schoolIdPlan)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.plans = response.data as StudyPlan[];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }
    else{
      this.studyPlanService.getPlansByVersion(version, this.userService.getUserSchool())
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }
  }

  getClassrooms(planId: number) {
    this.loading = true
    var setSchoolId: number;
    if(this.schoolIdEditLive){
      setSchoolId = this.schoolIdEditLive;
    } else {
      setSchoolId = this.userService.getUserSchool();
    }
    console.log(this.schoolIdEditLive);

    this.classroomService.getClassRoomsAdmin(planId, setSchoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classrooms = response.data as Classroom[];
          this.loading = false;
        } else {
          this.alertService.error(response.error);
          this.classrooms = [];
          this.loading = false;
        }
      }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
  }

  editClassroom(classroom: Classroom, schoolId: number) {
    classroom.isVimeo = true;
    const dialogRef = this.dialog.open(ClassroomCreateComponent, {
      minWidth: '60vw',
      data: classroom
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getClassrooms(classroom.planId);
      }
    });
  }

  removeClassroom(classroom: Classroom) {
    if (confirm('Deseja realmente excluir a sala?')) {
      this.classroomService.removeClassroom(classroom)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Removido!');
            this.getClassrooms(classroom.planId);
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a sala. Verifique a conexão e tente novamente'));
    }
  }

  getQuestions() {
    this.loadingExercises = true;
    this.libraryService.getQuestions(0, 99999999999)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.libQuestions = response.data as libQuestions[];
          this.loadingExercises = false;
        }
        else {
          this.alertService.error(response.error);
          this.loadingExercises = false;
        }
      })
  }

  addlibQuestionChip(question: libQuestions) {
    const index = this.selectedQuestions.indexOf(question);

    if (index < 0) {
      this.selectedQuestions.push(question);
      this.addVideoForm.get('libQuestions').reset();
    }
  }

  addStudyPlanId(id: number) {

    let max = 15;

    if (this.editMode) {
      max = 1;
    }

    if (this.studyPlanArray.length < max) {
      this.plans.forEach(element => {
        if (element.id == id) {
          this.studyPlanArray.push({ id: element.id, name: element.name });
        }

      });
    } else {
      this.alertService.error("Você pode selecionar no máximo " + max + " planos de estudos.");
    }
  }

  removeSelectedStudyPlan(id: number) {
    this.studyPlanArray.forEach(element => {
      if (element.id == id) {
        let index = this.studyPlanArray.indexOf(element);
        this.studyPlanArray.splice(index, 1);
      }

    });
  }

  removelibQuestionChip(question: libQuestions) {
    const index = this.selectedQuestions.indexOf(question);

    if (index >= 0) {
      this.selectedQuestions.splice(index, 1);
    }
  }
  checkLive() {
    this.live = !this.addVideoForm.get('transmissao').value;
  }

  addExerciseChip(exercise: Exercise) {
    const index = this.selectedExercises.indexOf(exercise);

    if (index < 0) {
      this.selectedExercises.push(exercise);
      this.addVideoForm.get('exercises').reset();
    }
  }

  removeExerciseChip(exercise: Exercise) {
    const index = this.selectedExercises.indexOf(exercise);

    if (index >= 0) {
      this.selectedExercises.splice(index, 1);
    }
  }

  addDisciplineTagChip(tag: disciplineTag) {
    if(this.selectedDisciplinesTags.length <= 2){
    const index = this.selectedDisciplinesTags.indexOf(tag);

    if (index < 0) {
      this.selectedDisciplinesTags.push(tag);
      this.addVideoForm.get('disciplinesTag').reset();
    }
  }
  else{
    this.alertService.error('Um vídeo pode ter no maximo 3 tags!');
  }
  }

  removeDisciplineTagChip(tag: disciplineTag) {
    const index = this.selectedDisciplinesTags.indexOf(tag);

    if (index >= 0) {
      this.selectedDisciplinesTags.splice(index, 1);
    }
  }


  getDisciplineVideos(disciplineId: number) {
    // tslint:disable-next-line: triple-equals
    if (disciplineId != 0) {
      this.loading = true;
      this.videoService.getDisciplineVideos(disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.videos = response.data as Video[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
  }

  getDisciplines(): void {
    if (this.userService.isAdmin()) {
      this.getAllDisciplines();
    } else if (this.userService.isTeacher()) {
      this.getTeacherDisciplines(this.userService.getUserId());
    } else if (this.userService.isUnitCoordinator()) {
      this.getUnitDisciplines(this.userService.getUserUnit());
    } else {
      this.getSchoolDisciplines(this.userService.getUserSchool());
    }
  }

  getAllDisciplines() {
    this.disciplineService.getDisciplines()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as disciplínas. Verifique a conexão e tente novamente'));
  }

  getSchoolDisciplines(schoolId: number) {
    this.loadingDisciplines = true;
    this.disciplineService.getSchoolDisciplines(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingDisciplines = false;
      }, err => {
        this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente');
        this.loadingDisciplines = false;
      });
  }

  getExercisesByDiscipline(disciplineId: any) {

    if (disciplineId == 'library') {
      this.getQuestions();
    }
    else {
      this.exercises = [];
      this.loadingExercises = true;
      this.exerciseService.getExercisesByDiscipline(disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.exercises = response.data as Exercise[];
          } else {
            this.alertService.error(response.error);
          }

          this.loadingExercises = false;
        }, err => {
          this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente');
          this.loadingExercises = false;
        });
    }
  }

  openList() {
    if (this.userService.isAdmin()){
      this.getSchools();
    } else if (this.userService.isGeneralCoordinator()){
      this.getPlansBySchool(this.userService.getUserSchool());
    } else if (this.userService.isUnitCoordinator()){
      this.getPlansByUnit(this.userService.getUserUnit());
    }
    this.showVideosList = true;
  }

  removeVideo(videoId: number): void {
    if (confirm('Deseja realmente remover o vídeo?')) {
      this.videoService.removeVideo(videoId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Vídeo removido!', 3);
            // tslint:disable-next-line: no-unused-expression
            this.disciplineId && this.getDisciplineVideos(this.disciplineId);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }
  }

  addVideo() {
    this.loading = true;
    const exercisesIds = [];
    const disciplinesTagsIds = [];

    this.selectedExercises.map(e => exercisesIds.push(e.id));
    this.addVideoForm.get('exercises').setValue(exercisesIds);

    this.selectedDisciplinesTags.map(d => disciplinesTagsIds.push(d.id));
    this.addVideoForm.get('disciplinesTag').setValue(disciplinesTagsIds);

    const video = this.addVideoForm.getRawValue() as Video;
    video.libQuestions = this.selectedQuestions;
    video.embed = { code: this.addVideoForm.get('embedCode').value, type: this.addVideoForm.get('embedType').value };

    if (this.addVideoForm.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      this.loading = false;
      return;
    }

    this.videoService.addVideo(video)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Vídeo cadastrado');
          this.addVideoForm.reset();
          this.selectedExercises = [];
          this.selectedDisciplinesTags = [];
          this.adminService.updateChields();
          this.role = "";
        } else {
          this.alertService.error(response.error);
          this.role = "";
          this.loading = false;
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao cadastrar o vídeo. Verifique a conexão e tente novamente');
        this.role = "";
        this.loading = false;
      });
  }

  addVideoLive() {
    this.loading = true;
    const exercisesIds = [];

    this.selectedExercises.map(e => exercisesIds.push(e.id));
    this.addVideoForm.get('exercises').setValue(exercisesIds);

    const video = this.addVideoForm.getRawValue() as Video;
    if(this.addVideoForm.get('embedType').value == 'vimeo'){
    this.typeSelectClassroomVideo = 'vimeo';
    let auxstringVideo = 'https://vimeo.com/event/'+this.addVideoForm.get('embedCode').value+'/embed';
    video.embed = { code: auxstringVideo, type: 'transmissao' };
    video.transmissao = this.live;
    let auxstringchat = 'https://vimeo.com/event/'+this.addVideoForm.get('embedCode').value+'/chat';
    video.embed_chat = { code: auxstringchat , type: this.addVideoForm.get('embedType').value }
    }
    else if(this.addVideoForm.get('embedType').value == 'youtube'){
      this.typeSelectClassroomVideo = 'youtube';
      this.strAuxRecordLinkYoutube = this.addVideoForm.get('embedCode').value;
      this.recordMaskYTLive = this.addVideoForm.get('youtubeMask').value;
      let auxstringVideo = 'https://www.youtube.com/embed/'+this.addVideoForm.get('embedCode').value;
      video.embed = { code: auxstringVideo, type: 'transmissao' };
      video.transmissao = this.live;
      let auxstringchat = 'https://www.youtube.com/live_chat?v='+this.addVideoForm.get('embedCode').value+'&embed_domain=';
      video.embed_chat = { code: auxstringchat , type: this.addVideoForm.get('embedType').value }
    }


    if (this.addVideoForm.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      this.loading = false;
      return;
    }

    if (this.addVideoForm.get('title').value && this.addVideoForm.get('embedCode').value && this.initDate && this.endDate && this.studyPlanArray) {

      this.videoService.addVideoLive(video)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.addVideoForm.reset();
            this.selectedExercises = [];
            this.adminService.updateChields();
            this.role = "";
            let data: any = response.data;
            this.newVideoId = data.id;
            let novastring = 'video/watch/' + this.newVideoId;
            this.createLiveClassroom(video, novastring , this.typeSelectClassroomVideo , this.recordMaskYTLive);
          } else {
            this.loading = false;
            this.alertService.error(response.error);
            this.role = "";
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao cadastrar o vídeo. Verifique a conexão e tente novamente');
          this.role = "";
          this.loading = false;
        });
    } else {
      this.loading = false;
      this.alertService.error('prencha todos os campos corretamente!');
    }
  }

  editVideo(video: Video) {
    this.disciplineId = video.discipline;
    const dialogRef = this.dialog.open(AddVideoComponent, {
      minWidth: '60vw',
      data: video
    });
    this.editMode = true;
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        // tslint:disable-next-line: no-unused-expression
        this.disciplineId && this.getDisciplineVideos(this.disciplineId);
      }
    });
  }

  // edit mode methods


  initializeEditMode() {
    this.editMode = true;
    this.newVideoId = this.video.id;
    this.addVideoForm.patchValue(this.video);
    this.addVideoForm.get('embedType').setValue(this.video.embed.type);
    if (this.video.description) {
      this.role = this.video.description;
    }
    else {
      this.role = "";
    }
    if(this.video.disciplinesTags){
    this.selectedDisciplinesTags = this.video.disciplinesTags;
    }
    else{
      this.selectedDisciplinesTags = [];
    }
    this.addVideoForm.get('embedCode').setValue(this.video.embed.code);
    this.getVideoExercises(this.video.id);
    this.getVideoTags(this.video.id);
  }

  getVideoExercises(videoId: number) {
    this.videoService.getVideoExercises(videoId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedExercises = response.data as Exercise[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  saveChanges() {
    this.loading = true;
    const exercisesIds = [];
    const disciplinesTagsIds = [];

    this.selectedExercises.map(e => exercisesIds.push(e.id));
    this.addVideoForm.get('exercises').setValue(exercisesIds);
    this.selectedDisciplinesTags.map(d => disciplinesTagsIds.push(d.id));
    this.addVideoForm.get('disciplinesTag').setValue(disciplinesTagsIds);

    this.addVideoForm.get('id').setValue(this.video.id);
    const c = this.addVideoForm.getRawValue() as Video;
    c.libQuestions = this.selectedQuestions;
    c.embed = { code: this.addVideoForm.get('embedCode').value, type: this.addVideoForm.get('embedType').value };

    this.videoService.saveVideoChanges(c)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Video editado com sucesso', 3);
          this.adminService.updateChields();
          this.getDisciplineVideos(this.disciplineId);
          this.dialogRef.close(true);
          // tslint:disable-next-line: no-unused-expression


        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao salvar as alterações do Vídeo. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  saveChangesLive() {
    this.loading = true;
    const exercisesIds = [];

    this.selectedExercises.map(e => exercisesIds.push(e.id));
    this.addVideoForm.get('exercises').setValue(exercisesIds);
    this.addVideoForm.get('id').setValue(this.video.id);
    const c = this.addVideoForm.getRawValue() as Video;
    let auxstringVideo = 'https://vimeo.com/event/'+this.addVideoForm.get('embedCode').value+'/embed';
    c.embed = { code: auxstringVideo, type: 'transmissao' };
    let auxstringchat = 'https://vimeo.com/event/'+this.addVideoForm.get('embedCode').value+'/chat';
    c.embed_chat = { code: auxstringchat , type: this.addVideoForm.get('embedType').value }
    c.transmissao = this.live;

    this.videoService.saveVideoChangesLive(c)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Video editado com sucesso', 3);
          this.newVideoId = null;
          this.adminService.updateChields();
          this.getDisciplineVideos(this.disciplineId);
          this.dialogRef.close(true);
          // tslint:disable-next-line: no-unused-expression


        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao salvar as alterações do Vídeo. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  verifyFilter(value: string, videoId: number): boolean {
    if (value) {
      return !this.filter || value.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
    }
    return true;
  }

  createLiveClassroom(video: Video, linkvideo: string , typeVideo:string , haveYtMask : boolean) {
    /*  if (!this.initDate && this.endDate && this.studyPlanArray) {
         this.alertService.error('Preencha todos os campos corretamente');
         return;
     } */

    this.loading = true;


    this.classroomLive.name = video.title;
    this.classroomLive.tag = this.addVideoForm.get('tag').value;
    this.classroomLive.initDate = this.initDate;
    this.classroomLive.endDate = this.endDate;
    this.classroomLive.versionId = this.versionId;
    this.classroomLive.link = linkvideo;
    this.classroomLive.haveYoutubeMask = haveYtMask;

    if(typeVideo == 'youtube'){
      this.classroomLive.recordLink = this.strAuxRecordLinkYoutube;
    }
    else{
      this.classroomLive.recordLink = this.recordedLink;
    }
    this.classroomLive.embedType = typeVideo;

    if (this.userService.isAdmin()) {
      this.classroomLive.schoolId = this.schoolId;

    } else if (this.userService.isGeneralCoordinator()) {
      this.classroomLive.schoolId = Number(this.userService.getUserSchool());

    } else if (this.userService.isUnitCoordinator()) {
      this.classroomLive.schoolId = Number(this.userService.getUserSchool());

    } else if (this.userService.isTeacher) {
      this.classroomLive.schoolId = Number(this.userService.getUserSchool());
    }

    let studyPlansId: number[] = [];
    this.studyPlanArray.forEach(element => {
      studyPlansId.push(element.id);
    });

    var reports = [];

    studyPlansId.forEach(elementId => {

      setTimeout(() => {
        this.classroomLive.isVimeo = true;
        this.classroomLive.planId = elementId;
        this.classroomLive.liveVideo = 1;
        this.classroomService.createClassroom(this.classroomLive)
          .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              //reports.push({ status: "success", alert: 'Sala criada!' });
              this.alertService.success('Vídeo cadastrado');
              this.initDate = null;
              this.endDate = null;
              this.schoolId = null;
              this.studyPlanArray = [];
              this.planId = null;

              this.studyPlanArray = [];
              this.adminService.updateChields();
            } else {
              //reports.push({ status: "error", alert: response.error });

              this.loading = false;
              this.alertService.error('O video foi cadastrado mas houve erro no cadastro da sala de aula por favor cadastre manualmente!');
            }

            //  this.loading = false;

          }, err => {
            reports.push({ status: "error", alert: 'Houve um erro ao criar a sala. Verifique a conexão e tente novamente' });
            this.loading = false;
            //reports.push('Houve um erro ao criar a sala. Verifique a conexão e tente novamente');
            //this.alertService.error('Houve um erro ao criar a sala. Verifique a conexão e tente novamente');
            //  this.loading = false;
          });
      }, 500);//ms
    });
  }

  getVideosByName(name: string) {

    if(this.userService.isAdmin()){
    this.loading = true;
    this.videoService.getVideosByName(name)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
    }
    else{
      this.loading = true;
      this.videoService.getVideosByNameCoord(name , this.userService.getUserSchool())
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.videos = response.data as Video[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
  }

  filterVideoByName(name: string) {
    if (name != "" && name.length > 4) {
      this.getVideosByName(name);
    } else {
      this.alertService.error('Tamanho mínimo para pesquisar são 5 caracteres!');
    }

  }

  filterDisciplines(filter: string) {
    this.disciplines = [...this.disciplinesAux].filter(discipline => {
      return discipline.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
  }

  getDisciplinesExerciseOnly() {
    if (this.userService.isAdmin()) {
      this.getDisciplinesExerciseOnlyAll();
    } else if (!this.userService.isTeacher()) {
      this.getSchoolDisciplinesExerciseOnly(this.userService.getUserSchool());
    }
  }

  getDisciplinesExerciseOnlyAll() {
    this.disciplineService.getDisciplinesExercisesOnly()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplinesExercise = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  getSchoolDisciplinesExerciseOnly(schoolId: number) {
    this.disciplineService.getSchoolDisciplinesExercisesOnly(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplinesExercise = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  private getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde'));
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
  }

  getDisciplinesTagsBySchool(idSchool: number){
      this.disciplineService.getDisciplinesTagsBySchool(idSchool)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          this.disciplinesTags = response.data as disciplineTag[];
        }
        else{
          /*this.alertService.error(response.error);*/
        }
      })
    }

    getSchoolsSelectedTag() {
      this.schoolService.getSchools()
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                  this.schoolsForTags = response.data as School[];
              } else {
                  this.alertService.error(response.error);
              }
          }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  selectScholl(schoolId : number){
    if(!this.addVideoForm.get('schoolOnly').value){
      this.getPlansBySchoolTag(schoolId);
    }
    else{
      this.getDisciplinesTagsBySchool(schoolId);
    }
  }

  getPlansBySchoolTag(schoolId : number){
    this.studyPlanService.getSelectPlans(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plansTag = response.data as StudyPlan[];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error("Não foi possivel buscar os dados verifique a conexão e tente novamente!");
    })
  }

  getPlansBySchool(schoolId : number){
    this.studyPlanService.getSelectPlans(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plans = response.data as StudyPlan[];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error("Não foi possivel buscar os dados verifique a conexão e tente novamente!");
    })
  }

  getDisciplinesByPlan(planId: number){
    this.studyPlanService.getPlanDisciplines(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.disciplinesTagsTags = response.data as Discipline[];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!');
    })
  }

  getDisciplinesTagsByDiscipline( disciplineId : number){
        this.disciplineService.getDisciplinesTagsByDiscipline(disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.disciplinesTags = response.data as disciplineTag[];
          }
          else{
            this.alertService.error('Não existem temas de disciplinas nessa Disciplina!');
          }
        })
  }

  checktoogleTag(evt: boolean){

    if(evt && !this.userService.isAdmin()){
      this.getDisciplinesTagsBySchool(this.userService.getUserSchool());
    }
  }

  getPlansByUnit(unitId: number){
    this.studyPlanService.getPlansByUnit(unitId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.disciplinesTags = response.data as disciplineTag[];
          }
          else{
            this.alertService.error('Não existem temas de disciplinas nessa Disciplina!');
          }
        })
  }
}
