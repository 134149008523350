import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { of, Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { ConfigServiceService } from '../config-service.service';
import { ModalInit } from './modal-init';

@Component({
  selector: 'app-modal-init',
  templateUrl: './modal-init.component.html',
  styleUrls: ['./modal-init.component.scss']
})
export class ModalInitComponent implements OnInit {

  private admUpdate$: Subscription;

  public schools: School[];
  public createModalForm: FormGroup;
  public editMode: boolean;
  public modal: ModalInit;
  public modals: ModalInit[];
  public showList: boolean;
  public selectedSchool: number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public modalEdit: ModalInit,
    @Optional() public dialogRef: MatDialogRef<ModalInitComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    public userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private adminService: AdminService,
    private configService: ConfigServiceService,
  ) { }

  ngOnInit() {
    this.getSchools();
    this.editMode = false;
    this.showList = false;

    this.createModalForm = this.formBuilder.group({
      id_modal: [],
      schoolId: [],
      small_desc: [''],
      title: [''],
      iframeLink: [''],
      isVimeo: [''],
      buttonName: [''],
      buttonLink: [''],
      buttonLinkNewTab: [''],
      dismissName: [''],
      dismissAuto: [''],
      active: [true],
      colorModal: [''],
      colorText: [''],
      openAfterLogin:[''],
      notification:['']
    })

    if(this.modalEdit){
      this.createModalForm.patchValue(this.modalEdit);
      this.editMode = true;
      if(this.modalEdit.isVimeo == 1){
        this.createModalForm.get('isVimeo').setValue("1");
      }
      else{
        this.createModalForm.get('isVimeo').setValue("0");
      }
    }

    this.iconRegistry.addSvgIcon(
      'vimeo',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/vimeo.svg'));
    this.iconRegistry.addSvgIcon(
      'youtube',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/youtube.svg'));
    /* this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init()); */
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  Teste(){
    console.log(this.createModalForm.get('notification').value);
  }

  createModal() {

    if(this.createModalForm.get('isVimeo').value){

    this.modal = this.createModalForm.getRawValue() as ModalInit;

    if (!this.modal.dismissName) {
      this.modal.dismissName = 'Não tenho interesse';
    }
    if (!this.modal.buttonName) {
      this.modal.buttonName = 'QUERO CONFERIR!';
    }

    if(this.createModalForm.get('isVimeo').value == 1){
      this.modal.iframeLink = this.modal.iframeLink.replace('vimeo.com/','player.vimeo.com/video/');
    }
    else if(this.createModalForm.get('isVimeo').value == 0){
     this.modal.iframeLink = this.modal.iframeLink.replace('watch?v=','embed/');
    }

    if(this.createModalForm.get('notification').value == true){
      this.modal.openAfterLogin = 1;
    }

    this.configService.createModal(this.modal)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Modal cadastrado com sucesso!');
          this.createModalForm.reset();
        }
        else {
          this.alertService.error(response.error);
        }
      })
    }
    else{
      this.alertService.error('Selecione o tipo do video se é Vimeo ou Youtube!');
    }
  }

  getModals() {
    this.modals = [];
    this.configService.getModals()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.modals = response.data as ModalInit[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getModalsBySchool(schoolId : number) {
    this.configService.getModalsBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.modals = response.data as ModalInit[];
        }
        else {
          this.alertService.error(response.error);
          this.modals = [];
        }
      })
  }

  openList() {
    this.editMode = false;
    this.showList = true;
    this.getModals();
  }

  backBtn() {
    this.showList = false;
    this.editMode = false;
  }

  deleteModal(modalId: number) {
    if (confirm('Deseja excluir este modal?')) {
      this.configService.deleteModal(modalId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Modal excluido com sucesso!');
            if(this.selectedSchool){
              this.getModalsBySchool(this.userService.getUserSchool());
            }
            else{
              this.getModals();
            }
          }
          else {
            this.alertService.error(response.error);
          }
        })
    }
  }

  openEditMode(modal: ModalInit){
    const dialogRef = this.dialog.open(ModalInitComponent, {
      data: modal
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getModals();
        this.showList = true;
        this.editMode = false;
      }
    });
  }

  updateModal(){
    this.modalEdit = this.createModalForm.getRawValue() as ModalInit

    if(this.createModalForm.get('isVimeo').value == 1){
      this.modalEdit.iframeLink = this.modalEdit.iframeLink.replace('vimeo.com/','player.vimeo.com/video/');
    }
    else if(this.createModalForm.get('isVimeo').value == 0){
     this.modalEdit.iframeLink = this.modalEdit.iframeLink.replace('watch?v=','embed/');
    }

    if(this.createModalForm.get('notification').value == true){
      this.modalEdit.openAfterLogin = 1;
    }

    this.configService.updateModal(this.modalEdit)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Modal editado com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

}
