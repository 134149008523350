import { StudyPlan } from './../../admin/study-plan/study-plan';
import { ElementRef, EventEmitter, HostListener } from '@angular/core';
// tslint:disable: no-unused-expression
import {
    Component,
    OnInit,
    ViewChild,
    ViewChildren,
    OnDestroy,
    Optional,
    Inject,
    Input,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

import { Exercise } from '../../admin/exercise/exercise';
import { Annex } from '../../admin/video/annex/annex';
import { UserService } from '../../user/user.service';
import { Video } from '../video';
import { VideoService } from '../video.service';
import { ForumComment } from '../../forum/comment/forum-comment';
import { ForumService } from '../../forum/forum.service';
import { Embed } from 'src/app/shared/interfaces/embed';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { Schedule } from '../../admin/study-plan/schedule';
import { Subject, Subscription } from 'rxjs';
import { CalendarService } from '../../calendar/calendar.service';
import { Calendar } from '../../admin/calendar/calendar';
import { takeUntil } from 'rxjs/operators';
import { SlideFile } from '../../slide-file/slide-file';
import { SlideFileService } from '../../slide-file/slide-file.service';
import { DisciplineService } from '../../admin/discipline/discipline.service';

import { disciplineTag } from '../../admin/discipline-tag/discipline-tag-create/discipline-tag';
import { MatTableDataSource } from '@angular/material/table';
import { Discipline } from '../../admin/discipline/discipline';

import { TutorMundiAnswerComponent } from '../../tutor-mundi/tutor-mundi-answer/tutor-mundi-answer.component';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { partitionApplyMerge } from '@angular-devkit/schematics';
import { mapToMapExpression } from '@angular/compiler/src/render3/util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StarRatingComponent } from '../../star-rating/star-rating.component';
import { ReportErrorComponent } from '../../report-error/report-error.component';
import { VideoModule } from '../video.module';
//import * as Player from '@vimeo/player/dist/player.js';
import Player from '@vimeo/player';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { stringify } from 'querystring';
import { OpenFileModalComponent } from '../../open-file-modal/open-file-modal.component';
import { detectIncognito } from 'detect-incognito';
import { ModalVideoYoutubeComponent } from '../modal-video-youtube/modal-video-youtube.component';
import { ModalVideoYoutubeMobileComponent } from '../modal-video-youtube-mobile/modal-video-youtube-mobile.component';
import { indexOf } from 'lodash';
import { Plan } from '../../payment/plan';

interface ReturnDisciplinePlan {
    planId: number;
    disciplineId: number;
    planName: string;
}

@Component({
    selector: 'app-watch-video',
    templateUrl: './watch-video.component.html',
    styleUrls: ['./watch-video.component.scss'],
})
export class WatchVideoComponent implements OnInit, OnDestroy {
    @Input() version: any;

    public filteredDisciplineTags: disciplineTag[];
    public filteredVideos: Video[];
    public videos: Video[];
    public disciplineVideos: any;
    public videoDataSource: MatTableDataSource<Video>;
    public loadingDisciplines: boolean;
    public studyPlans: StudyPlan[];
    public planIdVideosNavigation: number;
    public planSeted: StudyPlan;
    public previousVideoId: number;
    public nextVideoId: number;
    public rateVideo: string;
    public valueRate: number;
    private API = environment.ApiUrl;
    public showConfig: boolean;
    public allQualitys = [];
    public valueQuality : string;

    public video: Video;
    public exercises: Exercise[];
    public exercisesCorrect: {
        correct: string;
        comment: string;
        embed: Embed;
    }[];
    public exercisesResponse: string[];
    public exercisesSaved: boolean[];
    public annex: Annex[];
    public loading = true;
    public index: number;
    public player: Player;
    public saving: boolean;
    public dayVideos: Video[];
    public videoId: number;
    public description: string;
    public comments: ForumComment[] = [];
    public videoUrl = environment.VideoUrl;
    public lastDaySchedule: number;
    public scheduleDay: number;
    public schedule: any;
    public topicId: number;
    public calendar: Calendar;
    public showTutor: boolean;
    public schoolTutor: number;
    public duration: string;
    public videoDescription: string;
    public showDescription: boolean;
    public embedCodeChat: String | SafeUrl;
    public embedCodeVideo: String | SafeUrl;
    public transmissao: boolean;
    public urlActual: string;
    public planId: number;
    public setou: boolean;
    public rating: number;
    public starCount: number;
    public rattingArr: boolean[];
    private scheduleSubscription: Subscription;
    private calendarSubscription: Subscription;
    public domain = window.location.hostname;
    public thereIsRate: boolean;
    protected onDestroy = new Subject<void>();
    public videoWatched: boolean;
    public planVersion: number;
    public returnBack: ReturnDisciplinePlan;
    public discipline: Discipline;
    public plan: StudyPlan;
    public disciplineId: number;
    public isRecord: Boolean;
    public urlRecord: string | SafeUrl;
    public liked: boolean;
    public desliked: boolean;
    public totalLikes: number;
    public checkVideo: Video;
    public done: boolean;
    public playerYoutube: any;
    public playYoutube: boolean;
    public isRecordYoutube: boolean;
    public youtubeLiveId: string;
    public youtubeVolume: number = 100;
    public controlYoutube: number;
    public showMask: boolean;
    public isTwo: boolean;
    public isIncognito: boolean;
    public isfromCalendar: boolean;
    public showPlay: boolean;
    public youtubeTime: any;
    public youtubeDuration: any;
    public routerClassroom: boolean;
    public planClassroom: number;
    public arrVideos: Video[];
    public videosOfDay: any[];
    public linkPdf: any;
    public youtubeTimeShow: any;
    public youtubeDurationShow: any;

    public iframe: any;
    public iframeLink: String | SafeUrl;
    public isTeacherVideoWatch: boolean = false;

    @ViewChildren('player_container') playerContainer;
    channelId: number;

    constructor(
        @Optional()
        public dialogRef: MatDialogRef<
            TutorMundiAnswerComponent,
            StarRatingComponent
        >,
        @Optional()
        public dialogRef2: MatDialogRef<
            ModalVideoYoutubeComponent,
            ModalVideoYoutubeMobileComponent
        >,
        private dialog: MatDialog,
        public videoService: VideoService,
        public slideFileService: SlideFileService,
        private alertService: AlertService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private forumService: ForumService,
        private studyPlanService: StudyPlanService,
        private calendarService: CalendarService,
        private disciplineService: DisciplineService,
        public layoutService: LayoutService,
        public deviceService: DeviceDetectorService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.showConfig = false;
        this.rateVideo = 'X1';
        this.valueRate = 1;
        this.filteredDisciplineTags = [];

        this.showPlay = true;
        this.isfromCalendar = false;
        this.isIncognito = false;
        this.isTwo = false;
        this.showMask = false;
        this.controlYoutube = 1;
        this.isRecordYoutube = false;
        this.playYoutube = true;
        this.done = false;
        this.setou = false;
        this.planVersion = 0;
        this.isRecord = false;
        this.thereIsRate = false;
        this.rating = 5;
        this.starCount = 5;
        this.rattingArr = [];
        this.rattingArr = Array(this.starCount).fill(false);
        this.showDescription = false;
        this.showTutor = false;
        this.transmissao = false;
        this.rating = 5;
        this.filterTutorMButton();

        this.scheduleDay = Number(
            this.route.snapshot.paramMap.get('scheduleDay')
        ); // not used

        this.index = Number(this.route.snapshot.paramMap.get('i'));
        this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        

        if ((this.planId == 0 || this.planId == undefined) && this.plan) {
            this.planId = this.plan.id;
        }
        this.videoId = Number(this.route.snapshot.paramMap.get('videoId'));
        this.description = this.route.snapshot.paramMap.get('description');
        if (this.route.snapshot.paramMap.get('topicId') == 'true') {
            this.isfromCalendar = true;
        } else {
            this.topicId = Number(this.route.snapshot.paramMap.get('topicId'));
        }
        if (this.route.snapshot.paramMap.get('channelId')) {
            this.channelId = Number(
                this.route.snapshot.paramMap.get('channelId')
            );
        } else if (this.route.snapshot.queryParams.channelId) {
            this.channelId = Number(this.route.snapshot.queryParams.channelId);
        }

        this.disciplineId = Number(
            this.route.snapshot.paramMap.get('disciplineId')
        );

        if (this.router.url.indexOf('/video/list/') !== -1) {
           
            this.urlActual = 'dashboard';
            this.getSelectedSchedule();
            this.scheduleDay &&
                this.planId &&
                !this.videoId &&
                this.getCalendarV1DayVideos(
                    this.planId,
                    this.userService.getUserId(),
                    this.scheduleDay
                );
            this.videoId = this.video.id;
        } else {
            if (this.router.url.indexOf('/video/day/') !== -1) {
                
                this.urlActual = 'dashboard';
                this.getDayVideos();
                //this.videoId = this.video.id;
            } else if (this.router.url.indexOf('/video/day/next/') !== -1) {
               
                this.urlActual = 'dashboard';
                this.getDayVideos();
                this.videoId = this.video.id;
            } else {
                
                this.urlActual = 'oneVideo';
                this.videoId && this.getVideoOnly(this.videoId);

                this.disciplineService
                    .getSelectedPlanIdOnDisciplinesVideosSubject()
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe((planId) => (this.planId = planId));

                let plano = Object.values(this.route.snapshot.queryParams);
                if (plano[0]) {
                    this.planId = plano[0];
                    this.routerClassroom = true;
                    this.planClassroom = plano[0];
                }
            }
        }
        this.getIncognito();
        this.getPlanVersion(this.planId);
        this.getVideoChat(this.videoId);
        this.getVideoDescription(this.videoId);
        this.getUserRating(this.videoId);
        this.getVideoIsRecord(this.videoId);
        this.getIframeVideo(this.videoId);
        this.getVideoYTMask(this.videoId);
        this.getUserLikeDeslike(this.userService.getUserId(), this.videoId);
        if (this.router.url.indexOf('/teacher') !== -1) {
            this.isTeacherVideoWatch = true;

        }
        this.valueQuality = 'auto';

        //Videos Navigation
        this.getVideosNavigation(this.userService.getUserId());
        this.planIdVideosNavigation = Number(
            this.route.snapshot.paramMap.get('planId')
        );
        //set previous and next video

        if (this.slideFileService.instanceOfSlideFile(this.video)) {

            this.linkPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.API + '/flip-book/' + this.video.file
            );
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    //@HostListener('window:keydown', ['$event'])


    getIncognito() {
        detectIncognito().then((result) => {
            if (result.isPrivate) {
                this.isIncognito = true;
            } else {
                this.isIncognito = false;
            }
        });
    }

    goBack() {
        this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        this.disciplineId = Number(
            this.route.snapshot.paramMap.get('disciplineId')
        );
        if (!this.disciplineId && !this.routerClassroom) {
            this.router.navigateByUrl('/plan/disciplines/' + this.planId);
        } else if (this.disciplineId) {
            this.router.navigateByUrl(
                '/plan/disciplines' +
                    '/' +
                    this.planId +
                    '/' +
                    this.disciplineId
            );
        } else {
            this.router.navigateByUrl(
                '/classrom/generallist/' + this.planClassroom
            );
        }
    }

    goBackClassroom() {
        this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        this.disciplineId = Number(
            this.route.snapshot.paramMap.get('disciplineId')
        );
        if (!this.disciplineId) {
            this.router.navigateByUrl('/plan/disciplines/' + this.planId);
        } else if (this.disciplineId) {
            this.router.navigateByUrl(
                '/plan/disciplines' +
                    '/' +
                    this.planId +
                    '/' +
                    this.disciplineId
            );
        }
    }

    goBackChannel() {
        if (Number(this.route.snapshot.paramMap.get('planId')) > 0) {
            this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        } else {
            this.planId = Number(this.route.snapshot.queryParams.planId);
        }
        if (this.channelId && this.disciplineId) {
            this.router.navigateByUrl(
                '/plan/channel/' +
                    this.channelId +
                    '/disciplines/' +
                    this.planId
            );
        } else if (this.channelId && !this.disciplineId) {

            this.router.navigateByUrl(
                '/classroom/channel/' + this.channelId + '/' + this.planId
            );
        }
    }

    openConfig() {
        this.showConfig = !this.showConfig;

        let qualitylevels = this.playerYoutube.getAvailableQualityLevels();

        qualitylevels.forEach(element => {
            if(element != "medium" && element != "high" && element != "tiny"){
                this.allQualitys.push(element);
            }
        });

    }

    goBackTeacherView() {
        this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        this.disciplineId = Number(
            this.route.snapshot.paramMap.get('disciplineId')
        );
        this.router.navigateByUrl(
            '/teacher/teacher_view/' + this.planId + '/' + this.disciplineId
        );
    }

    onPlayerReady(event) {
        event.target.playVideo();
        event.target.playVideoYT();
    }



    stopVideo() {
        this.playerYoutube.pauseVideo();
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        this.getTimeShow(this.youtubeTime);
    }

    getUserLikeDeslike(userId: number, videoId: number) {
        this.videoService
            .getUserLikeDeslike(userId, videoId)
            .subscribe((res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    if (response.data['like'] == 1) {
                        this.liked = true;
                        this.desliked = false;
                    } else {
                        this.liked = false;
                    }
                    if (response.data['deslike'] == 1) {
                        this.desliked = true;
                        this.liked = false;
                    } else {
                        this.desliked = false;
                    }
                } else {
                    this.liked = false;
                    this.desliked = false;
                }
            });
    }

    initializeExerciseArrays(exercisesNumber: number) {
        this.exercisesResponse = [];
        this.exercisesSaved = [];
        this.exercisesCorrect = [];

        for (let i = 0; i < exercisesNumber; i++) {
            this.exercisesResponse.push(null);
            this.exercisesSaved.push(false);
            this.exercisesCorrect.push({
                correct: null,
                comment: null,
                embed: null,
            });
        }
    }

    getVideoYTMask(videoId: number) {
        this.videoService.getVideoYTMask(videoId).subscribe((res) => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                let isMask = response.data['youtubeMask'] as number;

                if (isMask == 1) {
                    this.showMask = true;
                    this.controlYoutube = 0;
                } else {
                    this.showMask = false;
                    this.controlYoutube = 1;
                }
            }
        });
    }

    private verifyResponseExercises() {
        const studentId = this.userService.getUserId();

        for (let i = 0; i < this.exercises.length; i++) {
            this.verifyResponseExercise(this.exercises[i].id, studentId, i);
        }
    }

    returnStar(i: number) {
        if (this.rating >= i + 1) {
            return 'star';
        } else {
            return 'star_border';
        }
    }

    playVideoYT() {
        if (!this.layoutService.isMobile) {
            this.showPlay = false;
            this.playYoutube = true;
        } else {
            this.playYoutube = true;
        }

        this.playerYoutube.playVideo();
        this.playerYoutube.setVolume(this.youtubeVolume);
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        this.getTimeShow(this.youtubeTime);

        let qualitylevels = this.playerYoutube.getAvailableQualityLevels();
        qualitylevels.forEach(element => {
            if(element != "medium" && element != "high" && element != "tiny"){
                this.allQualitys.push(element);
            }
        });
    }

    setVolumeYoutube() {
        this.playerYoutube.setVolume(this.youtubeVolume);
    }

    setForward() {
        this.playerYoutube.seekTo(this.playerYoutube.getCurrentTime() + 30);
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        this.getTimeShow(this.youtubeTime);
    }

    rewindVideo() {
        this.playerYoutube.seekTo(this.playerYoutube.getCurrentTime() - 30);
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        this.getTimeShow(this.youtubeTime);
    }

    openFullScreen() {
        if (!this.deviceService.isMobile() && !this.deviceService.isTablet()) {
          console.log('nao e mobile irbao!');
            this.playerYoutube.pauseVideo();
            let video = this.video as Video;

            video.currentTime = this.playerYoutube.getCurrentTime();
            let width = window.innerWidth.toString() + 'px';
            let height = window.innerHeight.toString() + 'px';

            const dialogRef = this.dialog.open(ModalVideoYoutubeComponent, {
                panelClass: 'fundoBlack',
                maxWidth: '100vw',
                height: height,
                width: '100%',
                data: video,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.playerYoutube.seekTo(result);
                    this.showPlay = true;
                }
            });
        } else {
          console.log('e mobile irbao!');
            this.playerYoutube.pauseVideo();
            let video = this.video as Video;

            video.currentTime = this.playerYoutube.getCurrentTime();

            const dialogRef = this.dialog.open(
                ModalVideoYoutubeMobileComponent,
                {
                    panelClass: 'fundoBlack',
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    height: '100%',
                    width: '100%',
                    data: video,
                }
            );
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.playerYoutube.seekTo(result);
                    this.showPlay = true;
                }
            });
        }
    }

    openFullLive() {
        if (!this.layoutService.isMobile) {
            this.playerYoutube.pauseVideo();
            let video = this.video as Video;

            let width = window.innerWidth.toString() + 'px';
            let height = window.innerHeight.toString() + 'px';

            video.currentTime = this.playerYoutube.getCurrentTime();
            video.embed.code = this.youtubeLiveId;

            const dialogRef = this.dialog.open(ModalVideoYoutubeComponent, {
                panelClass: 'fundoBlack',
                maxWidth: '100vw',
                height: height,
                width: '100%',
                data: video,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.playerYoutube.seekTo(result);
                    this.showPlay = true;
                }
            });
        } else {
            this.playerYoutube.pauseVideo();
            let video = this.video as Video;

            video.currentTime = this.playerYoutube.getCurrentTime();
            video.embed.code = this.youtubeLiveId;

            const dialogRef = this.dialog.open(
                ModalVideoYoutubeMobileComponent,
                {
                    panelClass: 'fundoBlack',
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    height: '100%',
                    width: '100%',
                    data: video,
                }
            );
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.playerYoutube.seekTo(result);
                    this.showPlay = true;
                }
            });
        }
    }

    setVideoRate() {
        switch (this.valueRate) {
            case 1:
                this.valueRate = 1.25;
                this.playerYoutube.setPlaybackRate(1.25);
                this.rateVideo = 'X1.25';
                break;
            case 1.25:
                this.valueRate = 1.5;
                this.playerYoutube.setPlaybackRate(1.5);
                this.rateVideo = 'X1.50';
                break;
            case 1.5:
                this.valueRate = 2;
                this.playerYoutube.setPlaybackRate(2);
                this.rateVideo = 'X2';
                break;
            case 2:
                this.valueRate = 1;
                this.playerYoutube.setPlaybackRate(1);
                this.rateVideo = 'X1';
                break;
        }
    }

    changeQuality(){
      let index = this.allQualitys.indexOf(this.valueQuality);

      /* if(index+1 > this.allQualitys.length){
        this.index = 0;
      } */

      this.valueQuality = this.allQualitys[index +1];

      if(!this.valueQuality){
        this.valueQuality = this.allQualitys[0];
      }

      this.playerYoutube.setPlaybackQuality(this.valueQuality);
    }

    openMask() {
        if (!this.layoutService.isMobile) {
            this.playYoutube = false;
            this.showPlay = true;
        } else {
            this.playYoutube = false;
        }
        this.playerYoutube.pauseVideo();
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        this.getTimeShow(this.youtubeTime);
    }

    openMaskHover() {
        if (!this.layoutService.isMobile) {
            this.playYoutube = false;
            this.youtubeTime = this.playerYoutube.getCurrentTime();

            this.getTimeShow(this.youtubeTime);
        }
    }

    getTimeShow(timestamp: any){
        const date = new Date(timestamp * 1000);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        this.youtubeTimeShow = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    }

    closeMaskOver() {
        if (!this.layoutService.isMobile) {
            this.playYoutube = true;
        }
        this.youtubeTime = this.playerYoutube.getCurrentTime();
        this.getTimeShow(this.youtubeTime);
    }

    slideMove(event){
      const timestamp = event.value;

      this.getTimeShow(timestamp);
    }

    getVideoChat(videoId: number) {
        this.videoService.getVideoChat(videoId).subscribe((res) => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.embedCodeChat =
                    this.sanitizer.bypassSecurityTrustResourceUrl(
                        response.data['embed_chat'] as string
                    );
                let stringType = response.data['embed_chat'] as string;
                let resultSearch = response.data['link_video'].search('http');

                if (this.embedCodeChat) {
                    if (stringType.includes('youtube')) {
                        stringType = stringType.replace(
                            'embed_domain=',
                            'embed_domain=' + this.domain
                        );
                        this.embedCodeChat =
                            this.sanitizer.bypassSecurityTrustResourceUrl(
                                stringType
                            );
                        this.isRecordYoutube = true;
                        let linkvideo = response.data['link_video'] as string;
                        let idvideo = linkvideo.substring(
                            linkvideo.search('ed/') + 3,
                            linkvideo.length
                        );
                        this.youtubeLiveId = idvideo;
                    }
                }

                if (resultSearch >= 0) {
                    this.embedCodeVideo =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            response.data['link_video'] as string
                        );
                } else {
                    this.embedCodeVideo =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            ('https://' +
                                this.domain +
                                response.data['link_video']) as string
                        );
                }
            }

            if (
                response.data['transmissao'] == 1 ||
                response.data['transmissao'] == '1'
            ) {
                this.transmissao = true;
            } else {
                this.transmissao = false;
            }
        });
    }

    savePlayer(player) {
        this.playerYoutube = player;
        this.playerYoutube.setVolume(this.youtubeVolume);
        this.youtubeDuration = this.playerYoutube.getDuration();

        const timestamp = this.youtubeDuration;

        const date = new Date(timestamp * 1000);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        this.youtubeDurationShow = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    }

    goYoutubeTime() {
        this.playerYoutube.seekTo(this.youtubeTime);

        this.getTimeShow(this.youtubeTime);
    }

    updateTime() {
        setTimeout(() => {
            this.youtubeTime = this.playerYoutube.getCurrentTime();
            this.updateTime();

            this.getTimeShow(this.youtubeTime);
        }, 5000);
    }

    getVideoIsRecord(videoId: number) {
        this.videoService.getVideoIsRecord(videoId).subscribe((res) => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                if (response['isRecorded'] == 1) {
                    this.isRecord = true;
                    this.urlRecord =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            response.data['link_video'] as string
                        );
                } else {
                    this.isRecord = false;
                }
            } else {
                this.alertService.error(response.error);
            }
        });
    }

    getIframeVideo(videoId: number) {
        if (this.disciplineId && this.disciplineId != 0) {
            this.videoService
                .getIframeVideoByDiscipline(this.disciplineId, videoId)
                .subscribe((res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.iframeLink =
                            this.sanitizer.bypassSecurityTrustResourceUrl(
                                response.data['iframe'] as string
                            );
                    }
                });
        } else {
            this.videoService
                .getIframeVideoBySchool(
                    this.userService.getUserSchool(),
                    videoId
                )
                .subscribe((res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.iframeLink =
                            this.sanitizer.bypassSecurityTrustResourceUrl(
                                response.data['iframe'] as string
                            );
                    }
                });
        }
    }

    getPlanVersion(planId: number) {
        this.videoService.getPlanVersion(planId).subscribe((res) => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.planVersion = response.data['versao'] as number;
            } else {
                this.planVersion = 0;
            }
        });
    }

    openRating() {
        const width = this.layoutService.isMobile ? '100vw' : '50vw';

        const dialogRef = this.dialog.open(StarRatingComponent, {
            width: width,
            data: this.video,
            backdropClass: 'backdropBackground',
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.thereIsRate = true;
            }
        });
    }
    openFileModal(file: string) {
        const width = '100vw';

        const dialogRef = this.dialog.open(OpenFileModalComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'full-screen-modal',
            data: file,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.thereIsRate = true;
            }
        });
    }

    getUserRating(videoId: number) {
        this.videoService
            .getUserRate(videoId, this.userService.getUserId())
            .subscribe((res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.thereIsRate = true;
                    this.rating = response.data['starScore'];
                } else {
                    this.thereIsRate = false;
                }
            });
    }

    filterTutorMButton() {
        this.schoolTutor = Number(this.userService.getUserSchool());

        switch (this.schoolTutor) {
            case 122:
                this.showTutor = true;
                break;
            default:
                this.showTutor = false;
        }
    }

    verifyResponseExercise(
        exerciseId: number,
        studentId: number,
        exerciseIndex: number
    ) {
        this.videoService
            .verifyResponseExercise(exerciseId, studentId)
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        const resp = response.data as string;

                        if (resp) {
                            this.exercisesSaved[exerciseIndex] = true;
                            this.exercisesResponse[exerciseIndex] = resp;
                            this.getExerciseCorrectAlternative(
                                exerciseId,
                                exerciseIndex
                            );
                        } else {
                            this.exercisesSaved[exerciseIndex] = false;
                        }
                    } else {
                        this.alertService.error(response.error);
                    }

                    if (this.exercises.length - 1 === exerciseIndex) {
                        this.loading = false;
                    }
                },
                (err) =>
                    this.alertService.error(
                        'Houve um erro ao verificar o exercício. Verifique a conexão e tente novamente'
                    )
            );
    }

    getVideoDescription(videoId: number) {
        this.videoService.getVideoDescription(videoId).subscribe((res) => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.videoDescription = response.data['desc'] as string;
                if (this.videoDescription && this.videoDescription != '') {
                    this.showDescription = true;
                }
            }
        });
    }

    getExerciseCorrectAlternative(exerciseId: number, exerciseIndex: number) {
        this.videoService.getExerciseCorrectAlternative(exerciseId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.exercisesCorrect[exerciseIndex] = response.data as {
                        correct;
                        comment;
                        embed;
                    };
                    this.exercisesSaved[exerciseIndex] = true;
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar o gabarito. Verifique a conexão e tente novamente'
                )
        );
    }

    getDayVideos() {
        this.iframeLink = null;
        this.calendarService
            .getCurrentDayVideosSubject()
            .pipe(takeUntil(this.onDestroy))
            .subscribe((videos) => {
                if (!videos) {
                    this.router.navigateByUrl('/');
                }
                this.dayVideos = videos as Video[];
                this.video = this.dayVideos[this.index];
                this.videoId = this.dayVideos[0].id;

                /* if is video */
                if (this.videoService.instanceOfVideo(this.video)) {
                    this.getVideoDescription(this.video.id);
                    this.getUserRating(this.video.id);
                    this.getVideoIsRecord(this.video.id);
                    this.getIframeVideo(this.video.id);
                    this.getPlanVersion(this.planId);
                    this.getVideoExercises(this.dayVideos[this.index].id);
                    this.getVideoAnnex(this.dayVideos[this.index].id);
                    this.getCommentsVideo(this.video.id);
                    this.getVideoChat(this.video.id);
                    this.getUserLikeDeslike(
                        this.userService.getUserId(),
                        this.video.id
                    );
                } else {
                    // if is file
                    this.loading = false;
                }
            });
    }

    openReportError() {
        const width = this.layoutService.isMobile ? '100vw' : '50vw';
        const dialogRef = this.dialog.open(ReportErrorComponent, {
            width: width,
            data: { id: this.videoId, route: this.urlActual, type: 'video' },
            backdropClass: 'backdropBackground',
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    getCalendarV1DayVideos(
        planId: number,
        studentId: number,
        scheduleDay: number
    ) {
        this.iframeLink = null;
        this.getScheduleLastDay(studentId, planId);

        if (this.schedule) {
            this.dayVideos = this.schedule.days[this.scheduleDay - 1].videos;
            this.video = this.dayVideos[this.index];

            this.getVideoDescription(this.video.id);
            this.getUserRating(this.video.id);
            this.getVideoIsRecord(this.video.id);
            this.getIframeVideo(this.video.id);
            this.getPlanVersion(this.planId);
            this.getVideoExercises(this.dayVideos[this.index].id);
            this.getVideoAnnex(this.dayVideos[this.index].id);
            this.getCommentsVideo(this.video.id);
            this.getVideoChat(this.video.id);
            this.getUserLikeDeslike(
                this.userService.getUserId(),
                this.video.id
            );
        } else {
            this.getRemoteDayVideos(planId, studentId, scheduleDay);
        }
    }

    getScheduleLastDay(studentId: number, planId: number) {
        if (this.schedule) {
            this.lastDaySchedule = this.schedule.days.length;
        } else {
            this.getRemoteSchedule(studentId, planId);
        }
    }

    getRemoteDayVideos(planId: number, studentId: number, scheduleDay: number) {
        this.iframeLink = null;
        this.videoService
            .getDayVideos(planId, studentId, scheduleDay)
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.dayVideos = response.data as Video[];
                        this.video = this.dayVideos[this.index];

                        this.getVideoDescription(this.video.id);
                        this.getUserRating(this.video.id);
                        this.getVideoIsRecord(this.video.id);
                        this.getIframeVideo(this.video.id);
                        this.getPlanVersion(this.planId);
                        this.getVideoExercises(this.dayVideos[this.index].id);
                        this.getVideoAnnex(this.dayVideos[this.index].id);
                        this.getCommentsVideo(this.video.id);
                        this.getVideoChat(this.video.id);
                        this.getUserLikeDeslike(
                            this.userService.getUserId(),
                            this.video.id
                        );
                    } else {
                        this.alertService.error(response.error);
                        this.router.navigate(['dashboard']);
                    }
                },
                (err) =>
                    this.alertService.error(
                        'Houve um erro ao carregar os videos. Verifique a conexão e tente novamente'
                    )
            );
    }

    getVideoOnly(videoId: number) {
        let aux = 0;
        if (this.showMask == true) {
            this.showMask = false;
            aux = 1;
        }
        this.iframeLink = null;
        this.videoService
            .getVideo(videoId, this.userService.getUserId())
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.video = response.data as Video;
                        
                        this.disciplineService
                            .getSelectedPlanIdOnDisciplinesVideosSubject()
                            .pipe(takeUntil(this.onDestroy))
                            .subscribe((planId) => (this.planId = planId));

                        this.getVideoDescription(videoId);
                        this.getUserRating(videoId);
                        this.getVideoIsRecord(videoId);
                        this.getIframeVideo(videoId);
                        this.getPlanVersion(this.planId);
                        this.getVideoExercises(videoId);
                        this.getVideoAnnex(videoId);
                        this.getCommentsVideo(videoId);
                        this.getVideoChat(videoId);
                        this.getUserLikeDeslike(
                            this.userService.getUserId(),
                            videoId
                        );
                        this.setPreviousNextVideo();
                        if (aux == 1) {
                            this.showMask = true;
                        }
                    } else {
                        this.alertService.error(response.error);
                        this.router.navigate(['dashboard']);
                    }
                },
                (err) =>
                    this.alertService.error(
                        'Houve um erro ao carregar o vídeo. Verifique a conexão e tente novamente'
                    )
            );
    }

    getVideo(content: Video | SlideFile) {
        this.iframeLink = null;
        this.dayVideos.map((v, i) => {
            if (v.id === content.id) {
                this.video = this.dayVideos[i];

                if (this.videoService.instanceOfVideo(content)) {
                    this.getVideoDescription(content.id);
                    this.getUserRating(content.id);
                    this.getVideoIsRecord(content.id);
                    this.getIframeVideo(content.id);
                    this.getPlanVersion(this.planId);
                    this.getVideoExercises(content.id);
                    this.getVideoAnnex(content.id);
                    this.getCommentsVideo(content.id);
                    this.getVideoChat(content.id);
                    this.getUserLikeDeslike(
                        this.userService.getUserId(),
                        content.id
                    );
                    this.getVideoYTMask(content.id);
                } else {
                    this.loading = false;
                }
            }
        });
        let vTeste = this.video as Video;
        this.playerYoutube.loadVideoById(vTeste.embed.code);
    }

    private resetData() {
        this.exercises = null;
        this.exercisesCorrect = null;
        this.exercisesResponse = null;
        this.exercisesSaved = null;
        this.annex = null;
        this.loading = true;
    }

    getVideoExercises(videoId: number) {
        this.annex = null;
        this.exercises = null;

        this.videoService.getVideoExercises(videoId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.exercises = response.data as Exercise[];
                    this.initializeExerciseArrays(this.exercises.length);
                    this.verifyResponseExercises();
                } else {
                    this.alertService.error(response.error);
                }

                if (this.exercises.length === 0) {
                    this.loading = false;
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar os exercícios. Verifique a conexão e tente novamente'
                )
        );
    }

    getVideoAnnex(videoId: number) {
        this.videoService.getVideoAnnex(videoId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.annex = response.data as Annex[];
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao carregar os anexos do vídeo. Verifique a conexão e tente novamente'
                )
        );
    }

    sendExerciseResponse(
        exerciseId: number,
        alternative: string,
        exerciseIndex: number
    ) {
        this.videoService
            .sendExerciseResponse(
                exerciseId,
                alternative,
                this.userService.getUserId()
            )
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.getExerciseCorrectAlternative(
                            exerciseId,
                            exerciseIndex
                        );
                    } else {
                        this.alertService.error(response.error);
                    }
                },
                (err) =>
                    this.alertService.error(
                        'Houve um erro ao salvar a resposta. Verifique a conexão e tente novamente'
                    )
            );
    }

    selectVideo(content: Video | SlideFile) {
        this.resetData();
        this.getVideo(content);
    }

    openAnnex(annexUrl: string) {
        window.open(annexUrl, '_blank');
    }

    setWatched() {
        this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        if (this.duration) {
            this.saving = true;
            this.videoService
                .addWatchedVideo(
                    this.video.id,
                    this.userService.getUserId(),
                    this.planId,
                    this.duration
                )
                .subscribe(
                    (res) => {
                        const response = res.body as ResponseApi;
                        if (!response.error) {
                            this.videoWatched = (this.video as Video).watched =
                                true;
                            (this.video as Video).watched = true;

                            // case have list of day videos (calendar access)
                            !this.videoId &&
                                this.dayVideos.map((v, i) => {
                                    if (v.id === this.video.id) {
                                        this.videoWatched = (
                                            this.dayVideos[i] as Video
                                        ).watched = true;
                                        (this.dayVideos[i] as Video).watched =
                                            true;
                                    }
                                });
                        }
                        this.saving = false;
                    },
                    (err) => (this.saving = false)
                );
        } else {
            this.saving = true;
            this.videoService
                .addWatchedVideo(
                    this.video.id,
                    this.userService.getUserId(),
                    this.planId
                )
                .subscribe(
                    (res) => {
                        const response = res.body as ResponseApi;
                        if (!response.error) {
                            this.videoWatched = (this.video as Video).watched =
                                true;
                            (this.video as Video).watched = true;

                            // case have list of day videos (calendar access)
                            !this.videoId &&
                                this.dayVideos.map((v, i) => {
                                    if (v.id === this.video.id) {
                                        this.videoWatched = (
                                            this.dayVideos[i] as Video
                                        ).watched = true;
                                        (this.dayVideos[i] as Video).watched =
                                            true;
                                    }
                                });
                        }
                        this.saving = false;
                    },
                    (err) => (this.saving = false)
                );
        }
    }

    setUnwatched() {
        this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        this.saving = true;
        this.videoService
            .setUnwatchedVideo(
                this.video.id,
                this.userService.getUserId(),
                this.planId
            )
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.videoWatched = (this.video as Video).watched =
                            false;
                        (this.video as Video).watched = false;
                        // case have list of day videos (calendar access)
                        !this.videoId &&
                            this.dayVideos.map((v, i) => {
                                if (v.id === this.video.id) {
                                    this.videoWatched = (
                                        this.dayVideos[i] as Video
                                    ).watched = false;
                                    (this.dayVideos[i] as Video).watched =
                                        false;
                                }
                            });
                    }
                    this.saving = false;
                },
                (err) => (this.saving = false)
            );
    }
    tutorHelp() {
        const dialogRef = this.dialog.open(TutorMundiAnswerComponent, {
            minWidth: '60vw',
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    getCommentsVideo(videoId: number) {
        this.forumService.getCommentsVideo(videoId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.comments = response.data as ForumComment[];
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao carregar os comentários. Verifique a conexão e tente novamente'
                )
        );
    }

    getRemoteSchedule(studentId: number, planId: number) {
        this.studyPlanService
            .getSchedule(studentId, planId)
            .subscribe((res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schedule = response.data as Schedule;
                    this.lastDaySchedule =
                        this.schedule.days[this.schedule.days.length - 1].day;
                } else {
                    this.alertService.error(response.error);
                }
            });
    }

    nextDay() {
        let posarray = 0;

        this.calendarService
            .getCalendarDayVideos()
            .pipe(takeUntil(this.onDestroy))
            .subscribe((videos) => {
                this.videosOfDay = videos;
            });

        if (this.planVersion == 1) {
            this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() =>
                    this.router.navigate([
                        '/video/list',
                        this.planId,
                        this.scheduleDay + 1,
                        0,
                    ])
                );
        } else if (this.planVersion == 2) {
            /*       this.videosOfDay.forEach(day => {
                day.videos.forEach(video => {
                    if(video.id == this.videoId){

                    }
                });
            }); */

            for (let i = 0; i < this.videosOfDay.length; i++) {
                for (let j = 0; j < this.videosOfDay[i].videos.length; j++) {
                    if (this.videosOfDay[i].videos[j].id == this.videoId) {
                        posarray = i;
                    }
                }
            }
            this.openDayVideos(this.videosOfDay[posarray + 1].videos, posarray);
        }
    }

    openDayVideos(videos: Video[], index: number) {
        this.calendarService.setCurrentDayVideos(videos);
        /* this.router.navigate(['/video/list', this.planId, this.dayIndex, videoIndex]); */
        this.router.navigate(['/video/day/', this.planId, 0]);
    }

    commentEvent(comment: ForumComment) {
        this.comments.unshift(comment);
    }

    deleteComment(index: number) {
        this.comments.splice(index, 1);
    }

    getSelectedSchedule() {
        this.scheduleSubscription = this.studyPlanService
            .getSelectedCheduleSubject()
            .subscribe((schedule) => (this.schedule = schedule));
    }

    getTotalLikes(videoId: number) {
        this.videoService.getTotalLikes(videoId).subscribe((res) => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.totalLikes = response.data as number;
            } else {
                this.totalLikes = 0;
            }
        });
    }

    likeMethod(videoId: number) {
        this.liked = !this.liked;
        this.desliked = false;

        this.videoService
            .setLike(this.userService.getUserId(), videoId, this.liked)
            .subscribe((res) => {
                const response = res.body as ResponseApi;

                if (response.error) {
                    this.alertService.error(response.error);
                }
            });
    }

    deslikeMethod(videoId: number) {
        this.desliked = !this.desliked;
        this.liked = false;

        this.videoService
            .setDeslike(this.userService.getUserId(), videoId, this.desliked)
            .subscribe((res) => {
                const response = res.body as ResponseApi;

                if (response.error) {
                    this.alertService.error(response.error);
                }
            });
    }

    //
    getVideosNavigationV1(disciplineId: number) {
        this.filteredDisciplineTags = [];
        const order = this.version == 2 ? true : false;
        let idSchool = Number(this.userService.getUserSchool());
        this.disciplineService
            .getDisciplineVideosV1(
                disciplineId,
                order,
                idSchool,
                this.userService.getUserId()
            )
            .subscribe(
                (res) => {
                    const response = res.body as any;
                    if (!response.error) {
                        let data = response.data;
                        this.filteredVideos = data.videos as Video[];
                        this.videos = data.videos as Video[];
                        this.filteredDisciplineTags =
                            data.tags as disciplineTag[];
                        this.videoDataSource = new MatTableDataSource([
                            ...this.videos,
                        ]);
                        this.setPreviousNextVideo();
                    } else {
                        this.alertService.error(response.error);
                    }
                    this.loadingDisciplines = false;
                },
                (err) =>
                    this.alertService.error(
                        'Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'
                    )
            );
    }

    getVideosNavigationByPlanV2ByVideos(planId: number, disciplineId: number) {
        this.filteredDisciplineTags = [];
        this.videos = [];
        let idSchool = Number(this.userService.getUserSchool());
        // this.disciplineService.getDisciplineVideosByPlanV2(videos , planId , idSchool , this.userService.getUserId())
        this.disciplineService
            .getDisciplineVideosByPlanV2(disciplineId, planId, idSchool)
            .subscribe(
                (res) => {
                    const response = res.body as any;
                    if (!response.error) {
                        let data = response.data;
                        this.videos = data.videos as Video[];
                       
                        this.filteredVideos = this.videos;
                        this.filteredDisciplineTags =
                            data.tags as disciplineTag[];
                        this.videoDataSource = new MatTableDataSource([
                            ...this.videos,
                        ]);
                        this.setPreviousNextVideo();
                    } else {
                        if (!this.disciplineId) {
                            this.alertService.error(response.error);
                        }
                    }
                    this.loadingDisciplines = false;
                },
                (err) =>
                    this.alertService.error(
                        'Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'
                    )
            );
    }

    getVideosNavigation(studentId: number) {
        this.studyPlanService.getStudentPlansByDiscipline(studentId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.studyPlans = response.data as StudyPlan[];
                    if (
                        this.planIdVideosNavigation &&
                        this.planIdVideosNavigation > 0
                    ) {
                        this.studyPlans.forEach((element) => {
                            if (element.id == this.planIdVideosNavigation) {
                                this.planSeted = element;
                                this.getVideosNavigationByVersion();
                            }
                        });
                    }
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) => {
                this.alertService.error(
                    'Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'
                );
            }
        );
    }
    getVideosNavigationByVersion() {
        this.disciplineId =
            this.disciplineId == 0 ? this.discipline.id : this.disciplineId;

        if (
            this.planSeted &&
            this.planSeted.version == 1 &&
            this.planIdVideosNavigation
        ) {
            if (this.disciplineId && this.disciplineId != 0) {
                this.getVideosNavigationV1(this.disciplineId);
            } else {
                this.getVideosNavigationV1(this.discipline.id);
            }
        } else if (
            this.planSeted &&
            this.planSeted.version == 2 &&
            this.planIdVideosNavigation
        ) {
            if (this.disciplineId && this.disciplineId != 0) {
                this.getVideosNavigationByPlanV2ByVideos(
                    this.planIdVideosNavigation,
                    this.disciplineId
                );
            }
        }
    }
    setPreviousNextVideo() {
        this.videoId = Number(this.route.snapshot.paramMap.get('videoId'));
        let videoIndex: number;

        this.filteredVideos.forEach((video, index) => {
            if (video.id == this.videoId) {
                videoIndex = index;
            }
        });
        if (this.filteredVideos.length === 1) {
            this.previousVideoId = null;
            this.nextVideoId = null;
        } else if (this.filteredVideos.length >= 1) {
            if (
                videoIndex != 0 &&
                videoIndex != this.filteredVideos.length - 1
            ) {
                this.previousVideoId = this.filteredVideos[videoIndex - 1].id;
                this.nextVideoId = this.filteredVideos[videoIndex + 1].id;
            } else if (videoIndex == this.filteredVideos.length - 1) {
                this.previousVideoId = this.filteredVideos[videoIndex - 1].id;
                this.nextVideoId = null;
            } else {
                this.nextVideoId = this.filteredVideos[videoIndex + 1].id;
                this.previousVideoId = null;
            }
        }
    }
    goToNextVideo() {
       
        this.loading = true;
        this.router.navigateByUrl(
            '/video/watch/' +
                this.planIdVideosNavigation +
                '/' +
                this.disciplineId +
                '/' +
                this.nextVideoId
        );
        this.getVideoOnly(this.nextVideoId);
        this.loading = false;
    }
    goToPreviousVideo() {
        this.loading = true;
        this.router.navigateByUrl(
            '/video/watch/' +
                this.planIdVideosNavigation +
                '/' +
                this.disciplineId +
                '/' +
                this.previousVideoId
        );
        this.getVideoOnly(this.previousVideoId);
        this.loading = false;
    }
}
