import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NbAccordionModule, NbButtonModule, NbCardModule, NbIconModule, NbListModule } from '@nebular/theme';
import {MatExpansionModule} from '@angular/material/expansion';


import { ForumAddCommentComponent } from './comment/add/forum-add-comment.component';
import { ForumCommentComponent } from './comment/forum-comment.component';
import { ForumListComponent } from './list/forum-list.component';
import { ForumModeratedComponent } from './moderated/forum-moderated/forum-moderated.component';
import { VideoLikesControlComponent } from './video-likes-control/video-likes-control/video-likes-control.component';

@NgModule({
    declarations: [
        ForumCommentComponent,
        ForumAddCommentComponent,
        ForumListComponent,
        ForumModeratedComponent,
        VideoLikesControlComponent
    ],
    imports: [
        CommonModule,
        NbCardModule,
        MatButtonModule,
        NbIconModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCardModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        NbAccordionModule,
        NbListModule,
        NbButtonModule,
        MatSelectModule,
        FormsModule,
        MatTooltipModule,
        MatPaginatorModule,
        NbAccordionModule,
        MatExpansionModule,
        MatCardModule
    ],
    exports: [
        ForumCommentComponent,
        ForumAddCommentComponent
    ],
    providers: [],
})
export class ForumModule { }
