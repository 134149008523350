import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SignInComponent } from './signin/signin.component';
import { CommonModule } from '@angular/common';
import { NbAlertModule } from '@nebular/theme';
import { AutoSigninComponent } from './auto-signin/auto-signin.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; 
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        SignInComponent,
        AutoSigninComponent,
        RecoveryComponent 
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        NbAlertModule,
        MatProgressSpinnerModule,
        MatIconModule
    ],
    exports: [],
    providers: []
})
export class AuthModule {}
