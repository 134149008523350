import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StudyPlan } from './study-plan';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Schedule } from './schedule';
import { tap } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Mock } from '../../mock/mock';
import { Student } from '../student/student';

@Injectable({ providedIn: 'root' })
export class StudyPlanService {

    private API = environment.ApiUrl;
    private planSelected = new BehaviorSubject<StudyPlan>(null);
    private schedulePlan = new BehaviorSubject<Schedule>(null);

    constructor(private http: HttpClient) { }

    /* SELECT */
    selectPlan(studentId: number, plan: StudyPlan) {
        this.setSelectedPlan(plan);
        return this.http.post(this.API + '/study-plan/selectPlan', { studentId, planId: plan.id }, { observe: 'response' });
    }

    /* SAVE */
    registerPlan(studyPlan: StudyPlan) {
        return this.http.post(this.API + '/study-plan/registerPlan', studyPlan, { observe: 'response' });
    }
    createNewPlan(studyPlan: StudyPlan) {
      return this.http.post(this.API + '/study-plan/createNewPlan', studyPlan, { observe: 'response' });
    }
    savePlanChanges(plan: StudyPlan) {
        return this.http.post(this.API + '/study-plan/savePlanChanges', plan, { observe: 'response' });
    }

    /* GETTERS */
    getPlans(schoolId?: number) {
        return this.http.post(this.API + '/study-plan/getPlans', { schoolId }, { observe: 'response' });
    }

    getPlanEditOne(planId: number) {
        return this.http.post(this.API + '/study-plan/getPlanEditOne', { planId }, { observe: 'response' });
    }

    getPlansBySchool(schoolId?: number) {
        return this.http.post(this.API + '/study-plan/getPlansBySchool', { schoolId }, { observe: 'response' });
    }
    /* GETTERS */
    getUnitPlans(unitId?: number) {
        return this.http.post(this.API + '/study-plan/getUnitPlans', { unitId }, { observe: 'response' });
    }

    getPlansByVersion(version : number, schoolId?: number) {
        return this.http.post(this.API + '/study-plan/getPlansByVersion', {version ,schoolId}, { observe: 'response' });
    }
    getPlansByUnit(unitId?: number) {
        return this.http.post(this.API + '/study-plan/getUnitPlans', { unitId }, { observe: 'response' });
    }
    getClassPlans(classId: number, studentId?: number, calendarHide?: boolean) {
        return this.http.post(this.API + '/study-plan/getClassPlans', { classId, studentId, calendarHide }, { observe: 'response' });
    }
    getClassPlansFiles(classId: number, studentId?: number, calendarHide?: boolean) {
        return this.http.post(this.API + '/study-plan/getClassPlansFiles', { classId, studentId, calendarHide }, { observe: 'response' });
    }
    getClassPlansAll(classId: number, studentId?: number, calendarHide?: boolean) {
        return this.http.post(this.API + '/study-plan/getClassPlansAdmin', { classId, studentId, calendarHide }, { observe: 'response' });
    }
    deleteClass(classId: number) {
        return this.http.post(this.API + '/study-plan/deleteClass', { classId }, { observe: 'response' });
    }

    saveStudentsPlans(mocks: Mock[] , students : Student[]) {
        return this.http.post(this.API + '/study-plan/saveStudentsPlans', { mocks , students }, { observe: 'response' });
    }

    UpdatedStudentMocksAssociated(mocks: Mock[] , studentId : Number) {
        return this.http.post(this.API + '/study-plan/UpdatedStudentMocksAssociated', { mocks , studentId }, { observe: 'response' });
    }

    getSelectPlans(schoolId: number) {
        return this.http.post(this.API + '/study-plan/getSelectPlans', { schoolId }, { observe: 'response' });
    }
    getPlansBySchoolAndTeacher(schoolId: number, teacherId:number) {
        return this.http.post(this.API + '/study-plan/getPlansBySchoolAndTeacher', { schoolId, teacherId }, { observe: 'response' });
      }

    getSelectedPlansBySchool(schoolId: number) {
    return this.http.post(this.API + '/study-plan/getSelectedPlansBySchool', { schoolId }, { observe: 'response' });
    }
    getSelectPlansBySchool(schoolId: number) {
      return this.http.post(this.API + '/study-plan/getSelectPlansBySchool', { schoolId }, { observe: 'response' });
    }
    getSelectPaymentPlans(schoolId?: number) {
        return this.http.post(this.API + '/pagamento/getPlansSchool', { schoolId }, { observe: 'response' });
    }
    getStudentSelectedPlan(studentId: number) {
        return this.http.post(this.API + '/study-plan/getStudentSelectedPlan', { studentId }, { observe: 'response' });
    }
    getMessageUserPlansEdu(studentId: number) {
        return this.http.post(this.API + '/study-plan/getMessageUserPlansEdu', { studentId }, { observe: 'response' });
    }
    getSchedule(studentId: number, planId: number) {

        return this.http.post(this.API + '/study-plan/getSchedule', { studentId, planId }, { observe: 'response' })
            .pipe(tap(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.setSelectedSchedule(response.data as Schedule);
                }
            }));
    }
    getScheduleV1(studentId: number, planId: number) {

        return this.http.post(this.API + '/study-plan/getSchedule', { studentId, planId }, { observe: 'response' })
            .pipe(tap(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.setSelectedSchedule(response.data as Schedule);
                }
            }));
    }
    getPlanSubject() {
        return this.planSelected.asObservable();
    }
    getSelectedCheduleSubject() {
        return this.schedulePlan.asObservable();
    }
    // novos métodos 01-09-2019
    getPlanDisciplines(planId: number) {
        return this.http.post(this.API + '/study-plan/getPlanDisciplines', { planId }, { observe: 'response' });
    }
    // novos metodos 25-05-2021
    getPlanDisciplinesPage(planId: number) {
        return this.http.post(this.API + '/study-plan/getPlanDisciplinesPage', { planId }, { observe: 'response' });
    }
    // novos métodos 30-07-2020
    getSchoolDisciplines(schoolId: number) {
        return this.http.post(this.API + '/study-plan/getSchoolDisciplines', { schoolId }, { observe: 'response' });
    }
    // novos métodos 01-09-2019
    getPlanDisciplinesVideos(planId: number , userId?:number) {
        return this.http.post(this.API + '/study-plan/getPlanDisciplinesVideos', { planId , userId }, { observe: 'response' });
    }

    // Somente disciplinas plano v2
    getPlanDisciplinesV2(planId: number , userId?:number , schoolId?: number) {
        return this.http.post(this.API + '/study-plan/getPlanDisciplinesV2', { planId , userId , schoolId }, { observe: 'response' });
    }

    getPlanOneDisciplineVideosV2(planId: number , disciplineId: number , userId?:number , schoolId?: number) {
        return this.http.post(this.API + '/study-plan/getPlanOneDisciplineVideosV2', { planId , userId , schoolId , disciplineId}, { observe: 'response' });
    }

    getPlanDisciplinesV2Teacher(planId: number , userId?:number) {
        return this.http.post(this.API + '/study-plan/getPlanDisciplinesV2Teacher', { planId , userId}, { observe: 'response' });
    }
    // Somente disciplinas plano v1
    getPlanDisciplinesV1(planId: number , userId?:number) {
        return this.http.post(this.API + '/study-plan/getPlanDisciplinesV1', { planId,userId }, { observe: 'response' });
    }
    getClassromPlans(planId: number) {
        return this.http.post(this.API + '/study-plan/getClassRoomsPlansList', { planId }, { observe: 'response' });
    }
    getPlanSimulateds(planId: number) {
        return this.http.post(this.API + '/study-plan/getPlanSimulateds', { planId }, { observe: 'response' });
    }
    getStudentPlans(studentId: number) {
        return this.http.post(this.API + '/study-plan/getStudentPlansAdmin', { studentId }, { observe: 'response' });
    }
    getAllSelectedStudentPlans(studentId: number) {
        return this.http.post(this.API + '/study-plan/getAllSelectedStudentPlans', { studentId }, { observe: 'response' });
    }
    getStudentPlansWithEbooks(studentId: number) {
      return this.http.post(this.API + '/study-plan/getStudentPlansWithEbooks', { studentId }, { observe: 'response' });
    }
    getStudentPlansByDiscipline(studentId: number) {
        return this.http.post(this.API + '/study-plan/getStudentPlansByDiscipline', { studentId }, { observe: 'response' });
    }
    getNotHiddenStudentPlansByDiscipline(studentId: number) {
      return this.http.post(this.API + '/study-plan/getNotHiddenStudentPlansByDiscipline', { studentId }, { observe: 'response' });
    }
    getTeacherViewPlans(teacherId: number) {
        return this.http.post(this.API + '/study-plan/getTeacherViewPlans', { teacherId }, { observe: 'response' });
    }
    getPlansClassrom(studentId: number) {
        return this.http.post(this.API + '/study-plan/getPlansClassroomList', { studentId }, { observe: 'response' });
    }
    getHolidaysPlan(planId: number) {
        return this.http.post(this.API + '/study-plan/getHolyDays', { planId }, { observe: 'response' });
    }
    getPlanDisciplinesVideosV1(planId: number , userId?:number) {
        return this.http.post(this.API + '/study-plan/getPlanDisciplinesVideosV1', { planId , userId }, { observe: 'response' });
    }
    getPlanVideosV2(planId: number , userId?:number) {
        return this.http.post(this.API + '/study-plan/getPlanVideosV2', { planId , userId }, { observe: 'response' });
    }
    getPlanDisciplinesByCalendar(planId: number) {
        return this.http.post(this.API + '/study-plan/getPlanCalendar', {planId}, { observe: 'response' });
    }

    checkLiveClassNow(studentId: number, schoolId:number, classId:number) {
        return this.http.get(this.API + '/notifications/live?schoolId='+schoolId+'&studentId='+studentId+'&classId='+classId, { observe: 'response' });
        //return this.http.post(this.API + '/study-plan/checkLiveClassNow', { studentId, schoolId, classId }, { observe: 'response' });
    }

    /* SETTERS */
    setSelectedPlan(studyPlan: StudyPlan) {
        if (!studyPlan) {
            this.setSelectedSchedule(null);
        }
        this.planSelected.next(studyPlan);
    }
    setSelectedSchedule(schedule: Schedule) {
        this.schedulePlan.next(schedule);
    }


    /* DELETES */
    removePlan(planId: number) {
        return this.http.post(this.API + '/study-plan/removePlan', { planId }, { observe: 'response' });
    }


    /* OTHERS */
    clearSelectedPlanSubject() {
        this.planSelected.next(null);
    }

    getChannelPlansFiles(channelId: number, studentId?: number) {
        return this.http.get(this.API + '/channels/repository?channelId='+channelId+'&studentId='+studentId, { observe: 'response' });
    }

    getChannelStudyPlans(channelId: number) {
        return this.http.get(this.API + '/channels/classrooms?channelId='+channelId, { observe: 'response' });
    }

    getChannelPlansWithEbooks(channelId: number) {
        return this.http.get(this.API + '/channels/flip-book?channelId='+channelId, { observe: 'response' });
    }

    getChannelPlansByDiscipline(channelId: number, userId?: number) {
        return this.http.get(this.API + '/channels/plans/discipline?channelId='+channelId+'&userId='+userId, { observe: 'response' });
    }

    // 15/02/2023
    getDisciplinesByName( disciplineName: string){
      return this.http.post(this.API + '/study-plan/getDisciplinesByName', { disciplineName }, { observe: 'response' });
    }
}
