import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../admin.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service'; 
import { SyncService } from '../sync.service';

@Component({
selector: 'app-class-sync',
templateUrl: './class-sync.component.html',
styleUrls: ['./class-sync.component.scss']
})
export class ClassSyncComponent implements OnInit, OnDestroy {

public createClassForm: FormGroup;
public removeClassForm: FormGroup;
public showClassesList: boolean;
public schools: School[];
public units: any;
public classes: any;
public plans: StudyPlan[];
public selectedPlans: StudyPlan[];
public editMode: boolean = false;
public unitId: number;
public loading: boolean = false;actualUnitId: number;
;
private admUpdate$: Subscription;
private admUpdateRemove$: Subscription;
public deleting: boolean;
public remove: boolean = false; 
public synchronized: boolean = false;
public unsynchronized: boolean = false; 
public showUnitsList;
public syncStudents: any;
public synClasse: any;
public syncProgress: any;
public students: any;
public removeMode: boolean = false;
public totalTime: number;
public classSync: any[];
public classUnSync: any[];
public actualclassId: number;

constructor( 
    @Optional() @Inject(MAT_DIALOG_DATA) public classe: Class,       
    @Optional() public dialogRef: MatDialogRef<ClassSyncComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formbBuilder: FormBuilder,
    private studyPlanService: StudyPlanService,
    private adminService: AdminService,
    public userService: UserService,
    public syncService: SyncService) { }

    ngOnInit(): void { 
        this.selectedPlans = [];
        this.createClassForm = this.formbBuilder.group({
            id: [''],
            name: ['', Validators.required],
            unit: ['', Validators.required],
            school: ['', Validators.required],
            plans: ['']
        });

        if (this.userService.isAdmin()) {
            this.getSchools(); 
        } else if (this.userService.isGeneralCoordinator()) {
            this.coordinatorInit(); 
        } 
        
    }

    ngOnDestroy(): void {}
    
    
    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            this.createClassForm.get('school').setValue(this.userService.getUserSchool());
            this.getUnits(this.userService.getUserSchool());
        }
    }

    addPlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index < 0) {
            this.selectedPlans.push(plan);
            this.createClassForm.get('plans').reset();
        }
    }

    removePlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index >= 0) {
            this.selectedPlans.splice(index, 1);
        }
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }


    getClasses(unitId: number){

        this.loading = true;
        this.unitId = unitId;
        this.actualUnitId = unitId;
        this.syncService.getApiClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data; 
                    this.classSync=[];
                    this.classUnSync =[];

                    this.classes.forEach(element => {
                        if(element.studentSync > 0){
                            this.classSync.push(element);
                        } 
                        if(element.studentNotSync > 0){
                            this.classUnSync.push(element);
                        } 
                    });
                } else {
                    this.classes = []; 
                    this.classSync=[];
                    this.classUnSync =[];
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }
    

    getUnits(schoolId: number)
    {
        
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[]; 
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getStudentsSync(classe: any){

        this.loading = true;
         
        this.syncService.getApiStudents(classe.id )
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.students = response.data; 
                    this.syncMode(classe);
                } else {
                    this.students =[];
                    this.classes = []; 
                    this.classSync=[];
                    this.classUnSync =[];
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }
    getStudentsUnSync(classe: any){

        this.loading = true;
         
        this.syncService.getApiStudents(classe.id )
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.students = response.data; 
                    this.unSyncMode(classe);
                } else {
                    this.students =[];
                    this.classes = []; 
                    this.classSync=[];
                    this.classUnSync =[];
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }
    syncMode(classe:any){ 
        this.syncStudents = []; 
        this.synClasse = classe; 
        this.actualclassId = classe.id;
        this.editMode = true;
        this.removeMode = false;
        this.syncProgress = { text: "Parado 0%", progress: 0};
 
        this.students.forEach(element => {
            if(element.sync == 0){  
                let student = {
                        name: element.name, 
                        unit_id: classe.unitId, 
                        external_id: element.id,
                        class_id: classe.external_classId,
                        classId: classe.id,
                        status: 0
                    };  
                this.syncStudents.push(student);
            }
        }); 
    }

    unSyncMode(classe:any){
   
        this.syncStudents = []; 
        this.synClasse = classe; 
        this.removeMode = true; 
        this.editMode = false;
        this.syncProgress = { text: "Parado 0%", progress: 0};
        this.students.forEach(element => {
            if(element.sync == 1){  
                let student = {
                    name: element.name, 
                    external_id: element.id,
                    class_id: classe.external_classId,
                    unitId: classe.unitId, 
                    status: 0
                };  
                this.syncStudents.push(student);
            }
        });
        
    }
 
    syncClass(students:any){
        
        let timer = 500;
        this.totalTime = (students.lenght * timer)/1000;
        let total = 0;
        let slice = 100/students.length;
        let index = 0;
        students.forEach(element => { 

            setTimeout(() => {
                this.syncService.syncStudent(element)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
    
                    if (!response.error) {
                        total+=slice;
                        this.syncProgressUpdateOne(Math.floor(total));
                        const index =  this.syncStudents.indexOf(element);

                        if (index >= 0) {
                            this.syncStudents.splice(index, 1);
                        } 
                        
                    } else {
                        this.alertService.error(response.error);
                    }
    
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            }, timer+=300);

            index++;

            if(index == 5){
                timer+=300; 
                index = 0;
            }
            
        });
    }
    unSyncClass(students:any){
    
        let timer = 500;
        this.totalTime = (students.lenght * timer)/1000;
        let total = 0;
        let slice = 100/students.length;
        let index = 0;
        students.forEach(element => { 
            setTimeout(() => { 
            
                this.syncService.unSyncStudent(element.external_id)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        total+=slice;
                        this.syncProgressUpdateOne(Math.floor(total));
                        const index =  this.syncStudents.indexOf(element);

                        if (index >= 0) {
                            this.syncStudents.splice(index, 1);
                        } 
                        
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            
            }, timer+=500 );

            
        }); 
    }
    
    async syncProgressUpdate(size:number){
        let timer = 500;
        let total = 0;
        let slice = 100/size;
        for (let index = 0; index < size; index++) { 
            setTimeout(() => {
                if(index < size){
                    this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
                }else{
                    this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
                }  
                total+=slice;
            }, timer+=500);
        }
         
    }
    async syncProgressUpdateOne(total:number){
    
        if(total < 100){
            this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
        }else{
            this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
        }   
         
    }
}