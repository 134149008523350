// tslint:disable: no-unused-expression
import { MediaMatcher } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Optional,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Console } from 'console';
import { Observable } from 'rxjs';
import { Discipline } from 'src/app/modules/admin/discipline/discipline';
import { DisciplineService } from 'src/app/modules/admin/discipline/discipline.service';
import { ConfigServiceService } from 'src/app/modules/admin/my-configuration/config-service.service';
import { ModalInit } from 'src/app/modules/admin/my-configuration/modal-init/modal-init';
import { Notifications } from 'src/app/modules/admin/notifications/notifications';
import { NotificationsComponent } from 'src/app/modules/dashboard/student/notifications/notifications.component';
import { FileRepository } from 'src/app/modules/repository/file/file-repository';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { VideoRepository } from 'src/app/modules/repository/video/video-repository';
import { ProfileComponent } from 'src/app/modules/user/profile/profile.component';
import { User } from 'src/app/modules/user/user';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { IntegrationPortalComponent } from '../components/integration-portal/integration-portal.component';
import { Integrations } from '../components/integration-portal/integrations';
import { ModalVideoLayoutComponent } from '../components/modals/modal-video-layout/modal-video-layout.component';

import { ResponseApi } from '../models/response-api';
import { OctadeskService } from '../services/octadesk.service';
import { Theme } from '../themes/theme';
import { ThemeService } from '../themes/theme.service';
import { HeaderService } from './header.service';
import { IntegrationsService } from 'src/app/modules/admin/integrations/integrations.service';
import { IntegrationExternal } from 'src/app/modules/admin/integrations/create-integration/integrationexternal';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy, OnInit {
    @HostBinding('class')
    public activeThemeCssClass: string;
    public mobileQuery: MediaQueryList;
    public user$: Observable<User>;
    public API = environment.ApiUrl;
    public activeTheme: string;
    public theme: Theme;
    public disciplines: Discipline[];
    public schoolName: string;
    public showRepoFiles: boolean;
    public showRepoVideos: boolean;
    public rdoLink: string;
    public uniLink: string;
    public convergindoLink: string;
    public serenLink: string;
    public modal: ModalInit;
    public domain = window.location.hostname;
    public hideInicio: boolean = true;
    private mobileQueryListener: () => void;
    public active: boolean;

    public notify: Notifications[];
    public loading: boolean;
    public integrations: Integrations[] = [];
    public showPortal: boolean;
    public integrationExternal: IntegrationExternal[];

    constructor(
        //@Optional() @Inject(MAT_DIALOG_DATA) public exercise: Exercise,
        @Optional() public dialogRef: MatDialogRef<NotificationsComponent>,
        private dialog: MatDialog,

        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        public userService: UserService,
        public router: Router,
        private overlayContainer: OverlayContainer,
        private themeService: ThemeService,
        private disciplineService: DisciplineService,
        private alertService: AlertService,
        private headerService: HeaderService,
        private repositoryService: RepositoryService,
        public titleService: Title,
        private bottomSheet: MatBottomSheet,
        private authService: AuthService,
        public layoutService: LayoutService,
        public dialogVideoNewLayout: MatDialog,
        public configurationService: ConfigServiceService,
        private octadeskService: OctadeskService,
        private integrationService:IntegrationsService,

        @Inject(DOCUMENT) private document: HTMLDocument
    ) {
        this.themeService.getThemeObservable().subscribe((theme) => {
            this.theme = theme;
            if (this.theme && this.theme.theme) {
                this.setTheme(this.theme.theme);
                this.titleService.setTitle(this.theme.name);
                if (this.theme.favicon) {
                    this.document
                        .getElementById('appFavicon')
                        .setAttribute('href', this.theme.favicon);
                }
                this.themeService.emitThemeIsLoaded.emit('done');
            }
        });

        this.schoolName = this.userService.getUserSchoolName();
        this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
        this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this.mobileQueryListener);
        this.user$ = this.userService.getUser();
    }

    ngOnInit(): void {
      this.showPortal = false;
        if(this.userService.getUserSchool() == 1 || this.userService.getUserSchool() == 58 || this.userService.getUserSchool() == 40
        || this.userService.getUserSchool() == 30 || this.userService.getUserSchool() == 186 || this.userService.getUserSchool() == 37){
          this.showPortal = true;
        }
        this.repositoryService.getFileList(this.userService.getUserClass());
        if (this.userService.getUserId() !== undefined) {
            this.getDisciplines(this.userService.getUserId());
        }
        if (
            this.userService.getUserId() &&
            this.userService.getUserProfileId()
        ) {
            //this.getRdoLink();
            this.getIntegrations(this.userService.getUserSchool() , this.userService.getUserClass());
            this.getUniLink();
            this.getConvergindoLink();
            this.getSerenLink();
            this.getInfUser();
        }
        this.authService.emitLoginIsDone.subscribe((data) => {
            setTimeout(() => {
                //this.getRdoLink();
                this.getIntegrations(this.userService.getUserSchool() , this.userService.getUserClass());
                this.getUniLink();
                this.getConvergindoLink();
                this.getSerenLink();
                this.getInfUser();
            }, 1000);
        });

        this.authService.emitPortal.subscribe((data) => {
            if (data) {
                this.openIntegrationPortal();
            }
        });

        //TESTE DE MODAL OFFLINE
        /* this.themeService.emitThemeIsLoaded.subscribe((data)=>{
      setTimeout(() => {
        if(!window.localStorage.getItem('closeModal')){
        this.getUserModal(this.domain)
        }
      }, 1000);
    }); */
        //this.getUserModal('sistema.notamaxima.com.br');
    }

    openIntegrations() {
        //this.getRdoLink();
        this.getIntegrations(this.userService.getUserSchool() , this.userService.getUserClass());
        this.getUniLink();
        this.getConvergindoLink();
        this.getSerenLink();
        this.getInfUser();
    }

    getInfUser() {
        this.headerService.emitCheckIntegrations.emit([
            {
                link: this.userService.getUserDomain(),
                name: this.userService.getUserSchoolName(),
                image: this.theme.logo,
                title: this.theme.title
            },
        ]);
    }

    getIntegrations(schoolId:number , classId:number){
      this.integrationService.getStudentExternal(schoolId , classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          this.integrationExternal = response.data as IntegrationExternal[];

          this.integrationExternal.forEach(integration => {
            this.headerService.emitCheckIntegrations.emit([
              {
                  link: integration.link,
                  name: integration.name,
                  image: integration.icon,
                  title: integration.title
              },
          ]);
          });
        }
        else{
          //this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Não foi possível buscar os dados, verifique a conexão e tente novamente!');
      })
    }

    openIntegrationPortal() {
        this.loading = true;
        const dialogRef = this.dialog.open(IntegrationPortalComponent, {
            panelClass: 'dialog-integration-portal',
        });
    }

    disciplinesLoadOnClick() {
        this.getDisciplines(this.userService.getUserId());
    }

    verifyHaveRepository() {
        // get file repository
        this.repositoryService
            .getFileList(this.userService.getUserClass())
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        const files = response.data as FileRepository[];

                        this.showRepoFiles = files.length > 0 ? true : false;
                    } else {
                        this.alertService.error(response.error);
                    }
                },
                (err) =>
                    this.alertService.error(
                        'Houve um erro ao carregar o repositório. Verifique a conexão e tente novamente'
                    )
            );

        // get video repository
        let classId = this.userService.getUserClass();
        let schoolId = this.userService.getUserSchool();
        this.repositoryService.getVideoList(classId, schoolId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    const videos = response.data as VideoRepository[];

                    this.showRepoVideos = videos.length > 0 ? true : false;
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao carregar o repositório. Verifique a conexão e tente novamente'
                )
        );
    }

    setTheme(theme: string) {
        this.activeTheme = theme;

        const cssClass = theme;

        const classList = this.overlayContainer.getContainerElement().classList;
        if (classList.contains(this.activeThemeCssClass)) {
            classList.replace(this.activeThemeCssClass, cssClass);
        } else {
            classList.add(cssClass);
        }

        this.activeThemeCssClass = cssClass;
    }

    openDisciplineVideos(discipline: Discipline) {
        this.disciplineService.setDiscipline(discipline);
        this.router.navigate(['/discipline/videos']);
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this.mobileQueryListener);
    }

    logout() {
        this.userService.logout();
        this.router.navigate(['']);
    }

    link(link: string, openSelf?: boolean) {
        if (openSelf) {
            window.open(link, '_self');
        } else {
            window.open(link, '_blank');
        }
    }

    goToVersion(link: string, openSelf?: boolean) {
        this.document.location.href = window.location.protocol + '//' + link;
    }

    getTypeProfile(profileId: number) {
        switch (profileId) {
            case 1:
                return 'Aluno';
            case 2:
                return 'Professor';
            case 3:
                return 'Monitor';
            case 4:
                return 'Coordenação';
            default:
                return '';
        }
    }

    getDisciplines(studentId: number) {
        this.disciplineService.getStudentDisciplines(studentId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.disciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'
                )
        );
    }

    getHeaderBackgroundImg() {
        if (this.theme.showHeaderImg && this.theme.headerImg) {
            return this.theme.headerImg;
        } else if (this.theme.showHeaderImg && !this.theme.headerImg) {
            return `https://sistema.notamaxima.com.br/assets/images/header_t.png`;
        } else {
            return null;
        }
    }

    openEditProfile() {
        this.bottomSheet.open(ProfileComponent, { data: true });
    }

    getRdoLink() {
        this.headerService
            .getRdoLink(
                this.userService.getUserId(),
                this.userService.getUserProfileId()
            )
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.rdoLink = response.data as string;
                        if (this.rdoLink != '') {
                            this.headerService.emitCheckIntegrations.emit([
                                {
                                    link: this.rdoLink,
                                    name: 'Redação Online',
                                    image: '/assets/images/redacao-online.png',
                                    title: 'link RDO'
                                },
                            ]);
                        }
                    }
                },
                (err) => {
                    this.alertService.error(
                        'Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'
                    );
                }
            );
    }
    getUniLink() {
        this.headerService
            .getUniLink(
                this.userService.getUserId(),
                this.userService.getUserProfileId()
            )
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.uniLink = response.data as string;
                        if (this.uniLink != '') {
                            this.headerService.emitCheckIntegrations.emit([
                                {
                                    link: this.uniLink,
                                    name: 'Unimestre',
                                    image: '/assets/images/unimestre.png',
                                    title: 'Link Unimestre'
                                },
                            ]);
                        }
                    } else {
                        //this.alertService.error(response.error);
                    }
                },
                (err) => {
                    //console.log('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
                }
            );
    }
    getConvergindoLink() {
        this.headerService
            .getConvergindoLink(
                this.userService.getUserId(),
                this.userService.getUserProfileId()
            )
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.convergindoLink = response.data as string;
                        if (this.convergindoLink != '') {
                            this.headerService.emitCheckIntegrations.emit([
                                {
                                    link: this.convergindoLink,
                                    name: 'Convergindo',
                                    image: '/assets/images/convergindo.svg',
                                    title: 'Link Convergindo'
                                },
                            ]);
                        }
                    } else {
                        //this.alertService.error(response.error);
                    }
                },
                (err) => {
                    console.log(
                        'Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'
                    );
                }
            );
    }

    getSerenLink() {
        const seren: string = 'seren';
        this.headerService
            .getSerenLink(
                Number(this.userService.getUserId()),
                Number(this.userService.getUserClass()),
                seren,
                this.userService.getUserEmail()
            )
            .subscribe(
                (res) => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.serenLink = response.data as string;
                        if (this.serenLink != '') {
                            this.headerService.emitCheckIntegrations.emit([
                                {
                                    link: this.serenLink,
                                    name: 'Seren',
                                    image: '/assets/images/seren.png',
                                    title: 'Link Seren'
                                },
                            ]);
                        }
                    } else {
                        //this.alertService.error(response.error);
                    }
                },
                (err) => {
                    //console.log('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
                }
            );
    }

    openDialogVideoNewLayout(modal: ModalInit) {
        let width = 550;
        let height = width * 0.56;

        if (window.outerWidth > 2000) {
            width = 650;
            height = width * 0.56;
        }
        if (window.outerWidth < 1200 && window.outerWidth >= 850) {
            width = 550;
            height = width * 0.56;
        } else if (window.outerWidth < 850) {
            width = window.outerWidth * 0.7;
            height = width * 0.56;
        }

        const dialogRef = this.dialogVideoNewLayout.open(
            ModalVideoLayoutComponent,
            {
                width: '0px',
                height: '0px',
                panelClass: 'custom-dialog-container',
                data: {
                    modal,
                    isMobile: this.mobileQuery,
                    width: width + 'px',
                    height: height + 'px',
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            //window.localStorage.setItem('modalVideo', 'false');
        });
    }

    getUserModal(userDomain: string) {
        this.configurationService.getUserModal(userDomain).subscribe((res) => {
            const response = res.body as ResponseApi;
            if (!response.error) {
                this.modal = response.data as ModalInit;
                if (this.modal.openAfterLogin == 0) {
                    this.openDialogVideoNewLayout(this.modal);
                }
            } else {
                this.dialogVideoNewLayout.closeAll();
            }
        });
    }

    goToPortal() {
        let userId = this.userService.getUserId();

        this.authService.authenticateFather(userId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.octadeskService.startOctadesk();

                    const route = response.data as any;

                    if (route.defaultRoute && route.defaultRoute !== '') {
                        this.router.navigate(['integrations-portal']);
                    } else {
                        this.router.navigate(['integrations-portal']);
                    }
                } else {
                    this.alertService.error(response.error);
                    // nono animation
                }
            },
            (err) => {
                this.alertService.error(
                    'Os dados não puderam ser verificados, tente novamente ou contate o suporte!'
                );
            }
        );
    }
}
