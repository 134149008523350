import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Certificate } from './certificate';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  
  getAllCertificate(schoolId?: number){
    return this.http.get(this.API + '/certificate?schoolId='+schoolId,{ observe: 'response' });
  }
  getStudentCertificates(userId:number, schoolId: number){
    return this.http.get(this.API + '/certificate/by-user?userId='+userId+'&schoolId='+schoolId,{ observe: 'response' });
  }
  createCertificate(certificate: Certificate){
    return this.http.post(this.API + '/certificate', certificate, { observe: 'response' });
  }
  saveChanges(certificate: Certificate){
    return this.http.put(this.API + '/certificate/update', certificate , { observe: 'response' });
  }

  delete(id: number){
    return this.http.delete(this.API + '/certificate'+id , { observe: 'response' });
  }
}