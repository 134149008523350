import { Component, ElementRef,Renderer2, Inject, Input, OnInit, Optional, ViewChild, AfterViewInit } from '@angular/core';
import { FileCertificate, StudentCertificate } from '../../admin/certificate/certificate';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CertificateService } from '../../admin/certificate/certificate.service';
import { UserService } from '../../user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DOCUMENT } from '@angular/common';
import jsPDF from 'jspdf';
const printJS = require("print-js");
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  @ViewChild('pdfTable', { static: true }) printableDiv: ElementRef;
  //public data: StudentCertificate;
  public viewCertificate: boolean;
  public typeC: number;
  public num: number;
  public percent: string;
  public resultStudent: number;
  public total:number;
  public loading: boolean;
  private elementDiv :any;
  
  @Input() data: FileCertificate;
  
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public certificate: FileCertificate,
    @Optional() public dialogRef: MatDialogRef<FileComponent>,
    private dialog: MatDialog,
    private certificateService: CertificateService,
    private userService: UserService,
    public alertService: AlertService,
    @Inject(DOCUMENT) private document: HTMLDocument, 
    private renderer: Renderer2
 
  ) { }

  ngOnInit() {
    this.loading = true;
    setTimeout(() => {
      this.print();
    }, 3000);
  }

  ngAfterViewInit(){
    this.typeC = this.certificate.type;
    var totalPercentage = 100;

    if(this.typeC == 1){
      this.num = (totalPercentage * this.certificate.correct) / this.certificate.totalQuestions;
      this.percent = this.num.toFixed(2);
    }
    if(this.typeC == 2){
      this.num = (totalPercentage * this.certificate.totalWatched) / this.certificate.totalVideo;
      this.percent = this.num.toFixed(2);
    }
    if(this.typeC == 3){
      this.resultStudent = this.certificate.correct + this.certificate.totalWatched;
      this.total = this.certificate.totalQuestions + this.certificate.totalVideo;
      this.num = (totalPercentage * this.resultStudent) / this.total;
      this.percent = this.num.toFixed(2);
    }
    this.loading = false;
  }

  print(): void {
    const element = this.document.getElementById('pdfTable'); 
    setTimeout(() => {
      html2canvas(element).then((canvas) => {
        canvas.getContext('2d')
        const link = this.document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.target = '_blank';
        link.download = 'Certificado ' + this.certificate.planName + '.png';
        link.click();
      });
    }, 5000);
  }
}
