import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';
import { ThemeService } from '../themes/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public logo: string;
  public links: {label: string, link: string}[];
  public show: boolean;
  private schoolsToShow: number[];

  constructor(public themeService: ThemeService, private userService: UserService) { }

  ngOnInit() {
    this.schoolsToShow = [1, 6, 52];
    this.showFooter();
    this.themeService.getThemeObservable()
      .subscribe(theme => this.logo = theme.logo);

      this.links = [
        {label: 'Políticas de privacidade', link: 'https://www.notamaxima.com.br/politica-de-privacidade'},
        {label: 'Termos de uso', link: 'https://www.notamaxima.com.br/termos-de-uso'},
        {label: 'Central de ajuda', link: '#'},
        {label: 'Enviar feedback', link: 'https://forms.gle/tt9XjQugwojUHASA8'},
      ];
  }

  open(link: string) {
    window.open(link, '_blank');
  }

  showFooter() {
    this.show = this.schoolsToShow.find(id => id == this.userService.getUserSchool()) ? true : false;
  }

}
