import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Class, ClassSelect } from '../../school/class';
import { School, SchoolSelect } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { UnitSelect } from '../../school/unit';

@Component({
  selector: 'app-select-class',
  templateUrl: './select-class.component.html',
  styleUrls: ['./select-class.component.scss']
})

export class SelectClassComponent implements OnInit {
  public loading: boolean;
  public selectedClasses: ClassSelect[] = [];
  public schools: SchoolSelect[];
  public units: UnitSelect[];
  public classes: ClassSelect[];

  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<SelectClassComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    //this.getSchoolSelection(Number(this.userService.getUserSchool()));

    if(this.userService.isAdmin()){

      this.getSchoolSelection();

    } else if(this.userService.isGeneralCoordinator()){

      this.getUnitsSelection(Number(this.userService.getUserSchool()));

    } else if(this.userService.isUnitCoordinator()){

      this.getClasses(Number(this.userService.getUserUnit()));

    } else if(this.userService.isTeacher){
      this.getTeacherClasses(Number(this.userService.getUserId()));
    }
  }

  getSchoolSelection(schoolId?: number) {
    this.schoolService.getSchoolSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.schools = response.data as SchoolSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnitsSelection(schoolId: number) {
    this.schoolService.getUnitsSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.units = response.data as UnitSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }


  getClasses(unitId?: number) {
    this.schoolService.getClassesSelection(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.classes = response.data as ClassSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente'));
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  addClassChip(classes: ClassSelect) {
    let classSelected = {id:classes.id, name:classes.name};
    const index = this.selectedClasses.indexOf(classSelected);
    if (index < 0) {
      this.selectedClasses.push(classSelected);
    }
  }

  removeClassChip(classes: ClassSelect) {
    const index = this.selectedClasses.indexOf(classes);
    if (index >= 0) {
      this.selectedClasses.splice(index, 1);
    }
  }

  add(){
    this.dialogRef.close(this.selectedClasses);
  }

}
