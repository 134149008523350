import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Profile } from 'src/app/core/models/profile';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../admin.service';
import { Manager } from '../../school/manager';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { SyncService } from '../sync.service';
 

@Component({
  selector: 'app-coord-sync',
  templateUrl: './coord-sync.component.html',
  styleUrls: ['./coord-sync.component.scss']
})
export class CoordSyncComponent implements OnInit {

  public createManagerForm: FormGroup;
    public profiles: Profile[];
    public schools: School[];
    public units: Unit[];
    public managers: Manager[];
    public showManagersList: boolean;
    public editMode: boolean= false;
    public loading: boolean;
    private admUpdate$: Subscription;
    public selectedSchoolId: number;
    public synchronized: boolean = false;
    public unsynchronized: boolean = false; 
    public showUnitsList: any;
    public coords: any;
    public syncProgress: { text: string; progress: number; };
    public removeMode: boolean= false;
    public syncStudents: any[];
    public students: any;
    public synClasse: any;
    syncCoords: any[];
    synCoord: any;
    syncUnitsCoords: any;
    unSyncUnitsCoords: any[];
    actualUnit: number;
    
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public manager: Manager,
        @Optional() public dialogRef: MatDialogRef<CoordSyncComponent>,
        private dialog: MatDialog,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        public userService: UserService,
        private adminService: AdminService,
        private syncSerivce: SyncService,
        public syncService: SyncService) { }

    ngOnInit(): void {
        if (!this.userService.isAdmin()) {
            this.selectedSchoolId = this.userService.getUserSchool();
        } 

        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        this.schools = []; 
        this.managers = null; 

        if (this.userService.isAdmin()) {
            this.getSchools();

        } else if (this.userService.isGeneralCoordinator()) {
            this.createManagerForm.get('school').setValue(this.userService.getUserSchool());
            this.getUnits(this.userService.getUserSchool());
        } 
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }
 
    getManagersByNameOrEmail(name?: string, email?: string) {
        this.loading = true;
        this.schoolService.getManagersByNameOrEmail(this.userService.getUserProfileId(), this.selectedSchoolId, name, email)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.managers = response.data as Manager[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar os coordenadores. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getCoords(unitId: number) {

        this.actualUnit = unitId;
        this.syncSerivce.getApiCoords(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.coords = response.data ;
               
                } else {
                    this.coords = [];
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }
    getCoordUnits(coordId: number) {
        this.syncSerivce.getApiCoords(coordId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.coords = response.data ;
                   
                } else {
                    this.coords =[];
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getCoordUnitSync(coordId: number) {
        this.syncSerivce.getApiCoordUnitSync(coordId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.synCoord.unitsSync = response.data ;
               
                } else {
                    this.synCoord.unitsSync =[];
                    this.synCoord.unitsUnSync = [];
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }
    getCoordUnitUnSync(coordId: number) {
        this.syncSerivce.getApiCoordUnitUnSync(coordId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.synCoord.unitsUnSync = response.data ; 
                } else {
                    this.synCoord.unitsSync =[];
                    this.synCoord.unitsUnSync = [];
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    syncMode(coord:any){
        this.editMode = true;
        this.removeMode = false;
        this.syncUnitsCoords = [];
        this.synCoord = coord;

        this.syncProgress = { text: "Parado 0%", progress: 0};

        this.synCoord.unSyncUnits.forEach(element => {
            let coorD = {  
                id:         this.synCoord.id, 
                schoolId:   element.schoolId,
                name:       this.synCoord.name, 
                unitName:   element.name, 
                email:      this.synCoord.email,  
                unit_id:    element.unitExternal_id,
                unitId: element.unitId,
                external_id: element.userId, 
                status: 0
            };  
            this.syncUnitsCoords.push(coorD);
        });

        console.info(this.syncUnitsCoords);
       
    }

    unSyncMode(coord:any){
        this.removeMode = true;
        this.editMode = false;
        this.unSyncUnitsCoords = [];
        this.synCoord = coord;

        this.syncProgress = { text: "Parado 0%", progress: 0};

        this.synCoord.syncUnits.forEach(element => {
            let coorD = {  
                id:this.synCoord.id,
                schoolId: element.schoolId,
                name: this.synCoord.name, 
                unitName: element.name, 
                email: this.synCoord.email,  
                unit_id: element.unitExternal_id,
                unitId: element.id,
                external_id: element.userId, 
                status: 0
            };  
            this.unSyncUnitsCoords.push(coorD);
        });

        console.info(this.unSyncUnitsCoords);
         
    } 
   
    syncCoord(coord:any){
        
        let timer = 500;
    
        let total = 0;
        let slice = 100/coord.length;
        let index = 0;

        coord.forEach(element => { 

            setTimeout(() => {
                this.syncService.syncCoord(element)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
    
                    if (!response.error) {
                        total+=slice;
                        this.syncProgressUpdateOne(Math.floor(total));
                        const index =  this.syncUnitsCoords.indexOf(element);

                        if (index >= 0) {
                            this.syncUnitsCoords.splice(index, 1);
                        }  
                        
                    } else {
                        this.alertService.error(response.error);
                    }
    
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            }, timer+=300);

            index++;

            if(index == 5){
                timer+=300; 
                index = 0;
            }
            
        });
    }
    unSyncCoordUnits(coord:any){
    
        let timer = 500; 
        let total = 0;
        let slice = 100/coord.length;
        let index = 0;
        coord.forEach(element => { 
            setTimeout(() => { 
            
                this.syncService.unSyncCoord(element.external_id)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        total+=slice;
                        this.syncProgressUpdateOne(Math.floor(total));
                        const index =  this.unSyncUnitsCoords.indexOf(element);

                        if (index >= 0) {
                            this.unSyncUnitsCoords.splice(index, 1);
                        } 
                        
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            
            }, timer+=500 );

            
        }); 
    }
    unSyncCoord(coord:any){
    
        let timer = 500; 
        let total = 0;
        let slice = 100/coord.length;
        let index = 0;
        coord.forEach(element => { 
            setTimeout(() => { 
            
                this.syncService.unSyncCoord(element.external_id)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        total+=slice;
                        this.syncProgressUpdateOne(Math.floor(total));
                        /* const index =  students.indexOf(element);

                        if (index >= 0) {
                            students.splice(index, 1);
                        }  */
                        
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            
            }, timer+=500 );

            
        }); 
    }
    
      
    async syncProgressUpdate(size:number){
        let timer = 500;
        let total = 0;
        let slice = 100/size;
    
        for (let index = 0; index < size; index++) { 
            setTimeout(() => {
                if(index < size){
                    this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
                }else{
                    this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
                }  
                total+=slice;
            }, timer+=500);
        }
         
    }
    async syncProgressUpdateOne(total:number){
    
        if(total < 100){
            this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
        }else{
            this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
        }   
         
    }
  

}
