import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
    templateUrl: './manage-repository.component.html'
})
export class ManageRepositoryComponent implements OnInit {
    constructor(public userService: UserService) { }

    ngOnInit(): void { }
}
