import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { SchoolService } from '../school.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { School } from '../school';
import { Unit } from '../unit';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Teacher } from './teacher';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Class } from '../class';
import { UserService } from 'src/app/modules/user/user.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { Folder } from '../../repository/folder/folder';
import { takeUntil } from 'rxjs/operators';
import { Permission } from 'src/app/modules/repository/permission';
import { Discipline } from '../../discipline/discipline';
import { StudyPlan } from '../../study-plan/study-plan';
import { DisciplineService } from '../../discipline/discipline.service';
import { StudyPlanService } from '../../study-plan/study-plan.service';

@Component({
  selector: 'app-school-create-teacher',
  templateUrl: './school-create-teacher.component.html'
})
export class SchoolCreateTeacherComponent implements OnInit, OnDestroy {

  public createTeacherForm: FormGroup;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public teachers: Teacher[];
  public showTeachersList: boolean;
  public editMode: boolean;
  public selectedClasses: Class[];
  public loading: boolean;
  public folders: Folder[];
  public filtering: boolean;
  public selectedFolders: Folder[];
  public foldersModel: number[];
  public folderFilter = new FormControl();
  public filteredFolders: BehaviorSubject<Folder[]> = new BehaviorSubject<Folder[]>(null);
  public disciplines: Discipline[];
  public selectedDisciplines: Discipline[];
  public disciplinesModel: number[];
  public disciplineFilter = new FormControl();
  public filteredDisciplines: BehaviorSubject<Discipline[]> = new BehaviorSubject<Discipline[]>(null);
  public plans: StudyPlan[];
  public selectedPlans: StudyPlan[];
  public plansModel: number[];
  public planFilter = new FormControl();
  public filteredPlans: BehaviorSubject<StudyPlan[]> = new BehaviorSubject<StudyPlan[]>(null);
  public plansView : StudyPlan[];
  public selectedPlansView: StudyPlan[];
  public plansModelView : number [];
  public planFilterView = new FormControl();
  public filteredPlansView: BehaviorSubject<StudyPlan[]> = new BehaviorSubject<StudyPlan[]>(null);
  public permissions: Permission[];
  protected onDestroy = new Subject<void>();
  private admUpdate$: Subscription;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public teacher: Teacher,
    @Optional() public dialogRef: MatDialogRef<SchoolCreateTeacherComponent>,
    private dialog: MatDialog,
    private adminService: AdminService,
    private schoolService: SchoolService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private repositoryService: RepositoryService,
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService,
    public userService: UserService) { }

  ngOnInit(): void {
    this.selectedDisciplines = [];
    this.selectedPlans = [];
    this.selectedPlansView = [];
    this.folderFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterFolders();
      });

    this.disciplineFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterDisciplines();
      });

    this.planFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterPlans();
      });

      this.planFilterView.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.functionPlanViewFilter();
      });

    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  init() {
    this.schools = [];
    this.selectedClasses = [];
    this.selectedFolders = [];
    this.getSchools();
    this.getPermissions();
    this.createTeacherForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.required],
      schoolId: ['', Validators.required],
      unitId: ['', Validators.required],
      classId: ['', Validators.required],
      permissionId: ['', Validators.required]
    });
    this.coordinationInit();

    if (this.teacher) { this.initializeEditMode(); }
  }

  coordinationInit(editMode?: boolean) {

    if (this.userService.isGeneralCoordinator()) {
      this.createTeacherForm.get('schoolId').setValue(this.userService.getUserSchool());
      this.getUnits(this.userService.getUserSchool());
      this.getDisciplines(this.userService.getUserSchool());
      this.getPlans(this.userService.getUserSchool());
      editMode && this.getTeachers(this.userService.getUserSchool());
    }

    if (this.userService.isUnitCoordinator()) {
      this.createTeacherForm.get('schoolId').setValue(this.userService.getUserSchool());
      this.createTeacherForm.get('unitId').setValue(this.userService.getUserUnit());
      this.getClasses(this.userService.getUserUnit());
      this.getUnitDisciplines(this.userService.getUserUnit());
      this.getUnitPlans(this.userService.getUserUnit());
      editMode && this.getTeachers(this.userService.getUserSchool(), this.userService.getUserUnit());
    }
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId: number) {
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente'));
  }

  getTeachers(schoolId: number, unitId?: number) {
    this.loading = true;
    this.schoolService.getTeachers(schoolId, unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.teachers = response.data as Teacher[];
          this.teachers = this.teachers.map(t => {
            t.id = Number(t.id);
            t.schoolId = Number(t.schoolId);
            t.unitId = Number(t.unitId);
            return t;
          })
        } else {
          this.alertService.error(response.error);
          this.teachers = [];
        }

        this.loading = false;
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente');
      });
  }

  getTeacherByEmail(email: string) {
    this.loading = true;
    this.schoolService.getTeacherByEmail(email)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              this.teachers = response.data as Teacher[];
            } else {
                this.alertService.error(response.error);
            }

            this.loading = false;
        }, err => {
            this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente');
            this.loading = false;
        });
}

  openList() {

    if (this.userService.isGeneralCoordinator()) {
      this.getTeachers(this.userService.getUserSchool());
    }

    if (this.userService.isUnitCoordinator()) {
      this.getTeachers(this.userService.getUserSchool(), this.userService.getUserUnit());
    }

    this.teachers = [];
    this.showTeachersList = true;
  }

  create() {
    const classId = [];
    this.selectedClasses.forEach(c => classId.push(c.id));
    this.createTeacherForm.get('classId').setValue(classId);
    const permissionId: number = this.createTeacherForm.get('permissionId').value;

    if (this.createTeacherForm.valid) {

      this.loading = true;
      const teacher = this.createTeacherForm.getRawValue() as Teacher;
      this.schoolService.createTeacher(teacher)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.setTeacherFolders(response.data as number, [...this.selectedFolders.map(f => f.id)]);
            this.serTeacherPermissionLevel(response.data as number, permissionId);
            this.setTeacherDisciplines(response.data as number, [...this.selectedDisciplines.map(d => d.id)]);
            this.setTeacherPlans(response.data as number, [...this.selectedPlans.map(p => p.id)]);
            this.setTeacherViewPlans(response.data as number, [...this.selectedPlansView.map(p => p.id)]);
            this.alertService.success('Professor cadastrado', 3);
            this.createTeacherForm.reset();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao cadastrar o professor. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.warning('Preenche todos os campos corretamente');
    }
  }

  edit(teacher: Teacher) {
    const dialogRef = this.dialog.open(SchoolCreateTeacherComponent, {
      minWidth: '60vw',
      data: teacher
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.adminService.updateChields();
        this.getTeachers(result);
      }
    });
  }

  remove(teacherId: number) {
    if (confirm('Deseja realmente remover o Professor?')) {
      this.schoolService.removeTeacher(teacherId , this.userService.getUserProfileId())
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Professor removido', 3);
            this.getTeachers(this.teacher.schoolId, this.teacher.unitId);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o professor. Verifique a conexão e tente novamente'));
    }
  }

  getTeacherClasses(teacherId: number) {
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedClasses = response.data as Class[];

          if (this.selectedClasses?.length) {
            this.getClassFolders(this.selectedClasses[this.selectedClasses.length - 1].id);
          }
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  // edit methods

  initializeEditMode() {

    this.editMode = true;
    this.teacher.unitId = Number(this.teacher.unitId);

    this.createTeacherForm.patchValue(this.teacher);
    this.getUnits(this.teacher.schoolId as number);
    this.getClasses(this.teacher.unitId);
    this.getTeacherClasses(this.teacher.id);
    this.getTeacherFolders(this.teacher.id);
    this.getTeacherDisciplines(this.teacher.id);
    this.getTeacherPlans(this.teacher.id);
    this.getTeacherViewPlans(this.teacher.id);
    this.getDisciplines(this.teacher.schoolId);
    this.getPlans(this.teacher.schoolId);
    this.createTeacherForm.get('password').setValidators([]);
  }

  saveChanges() {
    const classId = [];
    const permissionId: number = this.createTeacherForm.get('permissionId').value;

    this.selectedClasses.forEach(c => classId.push(c.id));
    this.createTeacherForm.get('classId').setValue(classId);

    if (this.createTeacherForm.valid) {

      this.loading = true;

      this.createTeacherForm.get('id').setValue(this.teacher.id);
      const teacher = this.createTeacherForm.getRawValue() as Teacher;
      this.schoolService.saveTeacherChanges(teacher)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.setTeacherFolders(this.teacher.id, [...this.selectedFolders.map(f => f.id)]);
            this.serTeacherPermissionLevel(this.teacher.id, permissionId);
            this.setTeacherDisciplines(teacher.id, [...this.selectedDisciplines.map(d => d.id)]);
            this.setTeacherPlans(teacher.id, [...this.selectedPlans.map(p => p.id)]);
            this.updateTeacherViewPlans(response.data as number, [...this.selectedPlansView.map(p => p.id)]);
            this.alertService.success('Professor editado com sucesso', 3);
            this.dialogRef.close(this.teacher.schoolId);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar as alterações do professor. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addClassChip(c: Class) {
    const index = this.selectedClasses.findIndex(cl => cl.id == c.id);
    if (index < 0) {
      this.selectedClasses.push(c);
      this.createTeacherForm.get('classId').reset();
    }
  }

  removeClassChip(c: Class) {
    const index = this.selectedClasses.indexOf(c);

    if (index >= 0) {
      this.selectedClasses.splice(index, 1);
    }
  }

  getClassFolders(classId: number) {
    this.loading = true;
    this.repositoryService.getClassFolders(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as Folder[];
          this.folders = this.folders.map(f => {
            f.id = Number(f.id);
            f.schoolId = Number(f.schoolId);
            return f;
          });
          this.filteredFolders.next([...this.folders]);
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  addFolderChip(folder: Folder) {
    const index = this.selectedFolders.findIndex(t => t.id == folder.id);

    if (!index) {
      this.selectedFolders.push(folder);
    }
  }

  removeFolderChip(folder: Folder) {
    const index = this.selectedFolders.findIndex(t => t.id == folder.id);

    if (index >= 0) {
      this.selectedFolders.splice(index, 1);
      this.foldersModel = [...this.selectedFolders.map(d => d.id)];
    }
  }

  folderSelectionChange(selectedFoldersId: number[]) {
    selectedFoldersId.forEach(folderId => {
      for (const folder of this.folders) {
        if (folder.id == folderId) {
          if (!this.selectedFolders.find(sf => sf.id == folder.id)) {
            this.selectedFolders.push(folder);
            break;
          }
        }
      }
    })
  }

  protected filterFolders() {
    if (!this.folders) {
      return;
    }
    let search = this.folderFilter.value;
    if (!search) {
      this.filteredFolders.next(this.folders.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtering = true;
    // filter folders
    this.filteredFolders.next(
      this.folders.filter(folder => folder.name.toLowerCase().indexOf(search) > -1)
    );

    this.filtering = false;
  }

  private setTeacherFolders(teacherId: number, folderId: number[]) {
    if (folderId?.length) {
      this.repositoryService.setTeacherFolder([Number(teacherId)], folderId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.selectedFolders = [];
            this.foldersModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao adicionar as pastas do professor. Verifique a conexão e tente novamente'));
    }
  }

  private getTeacherFolders(teacherId: number) {
    this.repositoryService.getAssociatedTeacherFolders(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedFolders = [...response.data as Folder[]];
          this.foldersModel = [...this.selectedFolders.map(f => Number(f.id))];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as pastas do professor. Verifique a conexão e tente novamente'));
  }

  private getPermissions() {
    this.repositoryService.getPermissionsTeacher()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.permissions = response.data as Permission[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as permissões. Verifique a conexão e tente novamente'));
  }

  private serTeacherPermissionLevel(teacherId: number, permissionId: number) {
    this.repositoryService.setPermissionTeacher(teacherId, permissionId, this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao definir as permissões do professor. Verifique a conexão e tente novamente'));
  }

  getDisciplines(schoolId: number) {
    this.disciplineService.getSchoolDisciplines(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.filteredDisciplines.next([...this.disciplines]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  addDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.findIndex(d => d.id == discipline.id);

    if (!index) {
      this.selectedDisciplines.push(discipline);
    }
  }

  removeDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.findIndex(d => d.id == discipline.id);

    if (index >= 0) {
      this.selectedDisciplines.splice(index, 1);
      this.disciplinesModel = [...this.selectedDisciplines.map(d => d.id)];
    }
  }

  disciplineSelectionChange(selectedDisciplinesId: number[]) {
    this.selectedDisciplines = [];
    selectedDisciplinesId.forEach(disciplineId => {
      for (const discipline of this.disciplines) {
        if (discipline.id == disciplineId) {
          this.selectedDisciplines.push(discipline);
          break;
        }
      }
    });
  }

  protected filterDisciplines() {
    if (!this.disciplines) {
      return;
    }
    let search = this.disciplineFilter.value;
    if (!search) {
      this.filteredDisciplines.next(this.disciplines.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtering = true;
    // filter disciplines
    this.filteredDisciplines.next(
      this.disciplines.filter(discipline => discipline.name.toLowerCase().indexOf(search) > -1)
    );

    this.filtering = false;
  }

  getPlans(schoolId: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.plansView = response.data as StudyPlan[]
          this.filteredPlans.next([...this.plans]);
          this.filteredPlansView.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  addPlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.findIndex(p => p.id == plan.id);

    if (!index) {
      this.selectedPlans.push(plan);
    }
  }

  removePlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.findIndex(p => p.id == plan.id);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
      this.plansModel = [...this.selectedPlans.map(p => p.id)];
    }
  }

  planSelectionChange(selectedPlansId: number[]) {
    this.selectedPlans = [];
    selectedPlansId.forEach(planId => {
      for (const plan of this.plans) {
        if (plan.id == planId) {
          this.selectedPlans.push(plan);
          break;
        }
      }
    });
  }

  planViewSelectionChange(selectedPlansId: number[]) {
    this.selectedPlansView = [];
    selectedPlansId.forEach(planId => {
      for (const plan of this.plansView) {
        if (plan.id == planId) {
          this.selectedPlansView.push(plan);
          break;
        }
      }
    });
  }

  addViewPlanChip(plan: StudyPlan) {
    const index = this.selectedPlansView.findIndex(p => p.id == plan.id);

    if (!index) {
      this.selectedPlansView.push(plan);
    }
  }

  removeViewPlanChip(plan: StudyPlan) {
    const index = this.selectedPlansView.findIndex(p => p.id == plan.id);

    if (index >= 0) {
      this.selectedPlansView.splice(index, 1);
      this.plansModelView = [...this.selectedPlansView.map(p => p.id)];
    }
  }

  protected filterPlans() {
    if (!this.plans) {
      return;
    }
    let search = this.planFilter.value;
    if (!search) {
      this.filteredPlans.next(this.plans.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtering = true;
    this.filteredPlans.next(
      this.plans.filter(plan => plan.name.toLowerCase().indexOf(search) > -1)
    );

    this.filtering = false;
  }

  protected functionPlanViewFilter() {
    if (!this.plansView) {
      return;
    }
    let search = this.planFilterView.value;
    if (!search) {
      this.filteredPlansView.next(this.plansView.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtering = true;
    this.filteredPlansView.next(
      this.plansView.filter(plan => plan.name.toLowerCase().indexOf(search) > -1)
    );

    this.filtering = false;
  }

  private setTeacherPlans(teacherId: number, planId: number[]) {
    if (planId?.length) {
      this.schoolService.setTeacherPlans(teacherId, planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.selectedPlans = [];
            this.plansModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao atualizar os planos do professor. verifique a conexão e tente mais tarde'));
    }
  }

  private setTeacherViewPlans(teacherId: number, planId: number[]) {
    if (planId?.length) {
      this.schoolService.setTeacherViewPlans(teacherId, planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.selectedPlans = [];
            this.plansModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao atualizar os planos do professor. verifique a conexão e tente mais tarde'));
    }
  }
  private updateTeacherViewPlans(teacherId: number, planId: number[]) {
    if (planId?.length) {
      this.schoolService.updateTeacherViewPlans(teacherId, planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.selectedPlans = [];
            this.plansModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao atualizar os planos do professor. verifique a conexão e tente mais tarde'));
    }
  }

  private setTeacherDisciplines(teacherId: number, disciplineId: number[]) {
    if (disciplineId?.length) {
      this.schoolService.setTeacherDisciplines(teacherId, disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.selectedDisciplines = [];
            this.disciplinesModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao atualizar as disciplinas do professor. Verifique a conexão e tente mais tarde'));
    }
  }

  private getTeacherPlans(teacherId: number) {
    this.schoolService.getTeacherPlans(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedPlans = [...response.data as StudyPlan[]];
          this.plansModel = [...this.selectedPlans.map(p => p.id)];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos do professor. Verifique a conexão e tente mais tarde'));
  }

  private getTeacherViewPlans(teacherId: number) {
    this.schoolService.getTeacherViewPlans(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedPlansView = [...response.data as StudyPlan[]];
          this.plansModelView = [...this.selectedPlansView.map(p => p.id)];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos do professor. Verifique a conexão e tente mais tarde'));
  }

  private getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedDisciplines = [...response.data as Discipline[]];
          this.disciplinesModel = [...this.selectedDisciplines.map(d => d.id)];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde'));
  }

  private getUnitPlans(unitId: number) {
    this.schoolService.getUnitPlans(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos da unidade. Verifique a conexão e tente novamente'));
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.filteredDisciplines.next([...this.disciplines]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
  }

}
