import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { TopicDisciplineService } from '../../topic-discipline.service';

@Component({
  selector: 'app-video-topic',
  templateUrl: './video-topic.component.html',
  styleUrls: ['./video-topic.component.scss']
})
export class VideoTopicComponent implements OnInit {
  
  public videos: any;
  public loading: boolean;
  public planId: number;
  public disciplineId: number;
  public topicId: number;
  public planName: string;
  public disciplineName: string;
  public topicName: string;
  public classFilter: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutService,
    public userService: UserService,
    public topicDisciplineService: TopicDisciplineService,
    public alertService: AlertService,
  ) { }

  ngOnInit() {
    let n = Object(this.route.snapshot.queryParams);
    this.planName = n.planName;
    this.disciplineName = n.disciplineName;
    this.topicName = n.topicName;

    this.planId = Number(this.route.snapshot.paramMap.get('planId'));
    this.disciplineId = Number(this.route.snapshot.paramMap.get('disciplineId'));
    this.topicId = Number(this.route.snapshot.paramMap.get('topicId'));
    
    this.getTopicVideos(Number(this.userService.getUserId()), this.topicId);
  }

  backPage(){
    this.router.navigateByUrl('topic/plan/' + this.planId +'/discipline/' + this.disciplineId + '/topics' + '?planName=' + this.planName + '&disciplineName=' + this.disciplineName + '&topicName=' + this.topicName);
  }

  selectVideo(videoId: number){
    this.router.navigateByUrl('video/watch/' + this.planId +'/' + this.disciplineId + '/' + videoId);
  }

  getTopicVideos(userId: number, topicId:number) {
    this.loading = true;
    this.topicDisciplineService.getTopicVideos(userId, topicId)
      .subscribe(res => {
        const response = res.body as any;
        if (!response.error) { this.videos= response.data as any[];
        } else { this.alertService.error(response.error); }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar as tags. Verifique a conexão e tente novamente'));
  }

  applyFilter() {

    if (this.classFilter.length) {
        this.videos = [...this.videos].filter(video =>

            video.name.toLocaleLowerCase().indexOf(this.classFilter.toLowerCase()) > -1);

            //this.videoDataSource = new MatTableDataSource([...this.videos]);
    } else {
        this.videos = [...this.videos];
        //this.videoDataSource = new MatTableDataSource([...this.videos]);
    }
  }
}
