<div *ngIf="!showList">

    <form *ngIf="!editMode" [formGroup]="fileFormInsert">

        <!-- Filter Class Folders -->
        <div class="row" *ngIf="userService.isAdmin()">
            <div class="col-md-8">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="selectScholl($event.value)" placeholder="Selecione a escola para carregar as Disciplinas">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-slide-toggle (change)="checktoogleTag($event.checked)" formControlName="schoolOnly" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="As disciplinas so sao cadastradas por escolas">
                        help
                    </mat-icon>
                    Tema por escola
                </mat-slide-toggle>
            </div>
        </div>
        <div class="row" *ngIf="userService.isAdmin()">
            <div class="col-md-6" *ngIf="!fileFormInsert.get('schoolOnly').value">
                <mat-form-field class="w-100">
                    <mat-select placeholder="Selecione os planos" (selectionChange)="getDisciplinesByPlan($event.value)">
                        <mat-option *ngFor="let plan of plansTag" [value]="plan.id">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="!fileFormInsert.get('schoolOnly').value">
                <mat-form-field class="w-100">
                    <mat-select (selectionChange)="getDisciplinesTagsByDiscipline($event.value)" placeholder="Selecione as disciplinas">
                        <mat-option *ngFor="let discipline of disciplinesTagsTags" [value]="discipline.id">
                            {{discipline.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="userService.isAdmin()">
            <div class="col-md-4" *ngIf="disciplinesTags.length > 0">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Temas de disciplinas" formControlName="disciplinesTag">
                        <mat-option (click)="addDisciplineTagChip(tag)" *ngFor="let tag of disciplinesTags" [value]="tag.id">
                            <span [title]="tag.tag_name">{{tag.tag_name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 mt-1">
                <mat-chip-list *ngIf="selectedDisciplinesTags">
                    <mat-chip *ngFor="let tagChip of selectedDisciplinesTags" selectable="true" removable="true" (removed)="removeDisciplineTagChip(tagChip)">
                        {{tagChip.tag_name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getUnits($event.value)" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" >
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getClasses($event.value)" placeholder="Unidade">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="userService.teacherHavePermission(3)" [ngClass]="{'col-md-12': userService.isTeacher, 'col-md-4': userService.isAdmin() || userService.isGeneralCoordinator()}">
                <mat-form-field class="width-100" *ngIf="!userService.isTeacher()">
                    <mat-select placeholder="Turmas" (selectionChange)="selectFolders($event.value)">
                        <mat-option [value]="0">Todas</mat-option>
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- Select Folders -->
        <div class="row">
            <div class="col-md-12">


                <ng-container *ngIf="userService.isTeacher() && userService.teacherHavePermission(3); else teacherLvl2">
                    <mat-form-field class="width-100">
                        <mat-select formControlName="folder" placeholder="Pasta" (selectionChange)="folderSelectionChange($event.value)" multiple>
                            <mat-optgroup *ngFor="let group of teacherSelectGroup" [label]="group.name">
                                <mat-option *ngFor="let folder of group.options" [value]="folder.id">
                                    <span [title]="folder.name">{{folder.name}}</span>
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </ng-container>



                <ng-template #teacherLvl2>
                    <mat-form-field class="width-100">
                        <mat-select #folderSelect2 placeholder="Pastas" class="folder" (selectionChange)="folderSelectionChange($event.value)" formControlName="folder" multiple>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar pasta" noEntriesFoundLabel="Pasta não encontrada" [searching]="filteringFolders">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let folder of filteredFolders | async" [value]="folder.id">
                                <span [title]="folder.name">{{folder.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-template>



                <div *ngIf="selectedFolders?.length > 0" class="mb-3">
                    <mat-label class="mb-2">Pastas selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedFolders">
                        <mat-chip *ngFor="let folderChip of selectedFolders" selectable="true" removable="true" (removed)="removeFolderChip(folderChip)">
                            {{folderChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>


            </div>
        </div>

        <!--   <div class="dropzone" appDnd (fileDropped)="handleFileInput($event);">

            <h5>Arraste os arquivos aqui</h5>
            <h5> ou </h5>
            <input type="file" #fileDropRef id="fileDropRef" title="Escolha os arquivos" multiple (change)="handleFileInput($event.target.files);" />
        </div> -->

        <div class="row">
            <label for="upload" class="d-block width-100">
                <div class="img-file center mt-4 padding-60" [ngClass]="{'height-250': !layoutService.isMobile, 'dropzone-mobile': layoutService.isMobile }" >
                    <span class="send-file-icon">
                        <mat-icon color="accent">upload</mat-icon>
                    </span>
                    <div class="mb-3 width-100 dropzone" appDnd (fileDropped)="handleFileInput($event);">
                        <h6>Arraste os arquivos aqui</h6>
                        <p class="nothing-file" *ngIf="!filesArray">Nenhum arquivo selecionado</p>
                        <input type="file" #fileDropRef id="fileDropRef" title="Escolha os arquivos" multiple (change)="handleFileInput($event.target.files);">
                        <button (click)="fileDropRef.click()">Selecionar Arquivos</button>
                    </div>
                </div>
            </label>
        </div>

        <div class="row" *ngFor="let file of filesArray">
            <div class="col-md-10 col-sm-10 col-lg-10 col-xl-10 col-10 width-100">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" [value]="file.name" (change)="updateNameFilesArray(file.id, $event.target.value, file.name)">
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-2 width-100">
                <button color="warn" mat-icon-button (click)="removeFileArray(file.id)" class="hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Excluir">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

    </form>
    <form *ngIf="editMode" [formGroup]="fileForm">

        <h4 class="mt-3 mb-3">Editar Arquivo</h4>

        <div class="row">
            <div class="col-12 mb-20">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select #folderSelect formControlName="folder" placeholder="Pastas" (selectionChange)="folderSelectionChange($event.value)" multiple>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar pasta" noEntriesFoundLabel="Pasta não encontrada" [formControl]="folderFilter" [searching]="filteringFolders">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let folder of filteredFolders | async" [value]="folder.id">
                                <span [title]="folder.name">{{folder.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedFolders?.length > 0">
                    <mat-label class="mb-2">Pastas selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedFolders">
                        <mat-chip *ngFor="let folderChip of selectedFolders" selectable="true" removable="true" (removed)="removeFolderChip(folderChip)">
                            {{folderChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="col-12 mb-20 annex">
                <input class="width-100" (change)="handleFileInputUpdate($event.target.files);" accept="" fullWidth status="primary" type="file" nbInput placeholder="Anexar Arquivo">
            </div>
        </div>
        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-12 mt-20">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <!-- <div class="col-md-12">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>  -->
        </div>
    </form>

    <div class="row mt-5">
        <div class=" col-6 width-100">
            <button *ngIf="!editMode" (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Arquivos
                Cadastrados</button>
        </div>
        <div *ngIf="!editMode" class=" col-6 text-right width-100">
            <button (click)="add()" class="right bg-color-primary border-primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning" nbButton size="small" status="primary">Cadastrar</button>
        </div>
        <div *ngIf="editMode" class=" col-6 text-right width-100">
            <button (click)="saveChanges()" class="right" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning" nbButton size="small" status="primary">Salvar</button>
        </div>
    </div>
</div>

<div *ngIf="showList" class="">

    <nb-card size="medium" class="cardbigger">
        <nb-card-header>
            <!-- Filter Class Folders -->
            <div class="row">
                <div class="col-md-4" *ngIf="userService.isAdmin()">
                    <mat-form-field class="width-100">
                        <mat-select (selectionChange)="getUnits($event.value)" placeholder="Escola">
                            <mat-option *ngFor="let school of schools" [value]="school.id">
                                <span [title]="school.name">{{school.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.teacherHavePermission(4)">
                    <mat-form-field class="width-100" *ngIf="!userService.isTeacher()" >
                        <mat-select (selectionChange)="getClasses($event.value)" placeholder="Unidade">
                            <mat-option *ngFor="let unit of units" [value]="unit.id">
                                <span [title]="unit.name">{{unit.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="userService.teacherHavePermission(3)"  [ngClass]="{'col-md-12': userService.isTeacher, 'col-md-4': userService.isAdmin() || userService.isGeneralCoordinator()}">
                    <mat-form-field class="width-100" *ngIf="!userService.isTeacher()" >
                        <mat-select placeholder="Turmas" (selectionChange)="getClassFolders($event.value)">
                            <mat-option *ngFor="let class of classes" [value]="class.id">
                                <span [title]="class.name">{{class.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- folder listo to load files -->
            <div class="row">
                <div class="col-md-12">
                    <ng-container *ngIf="userService.isTeacher() && userService.teacherHavePermission(3); else teacherLvl2">
                        <mat-form-field class="width-100" appearance="outline">
                            <mat-select [(ngModel)]="filterFolder" placeholder="Pasta" (selectionChange)="getFolderFiles($event.value)">
                                <mat-optgroup *ngFor="let group of teacherSelectGroup" [label]="group.name">
                                    <mat-option *ngFor="let folder of group.options" [value]="folder.id">
                                        <span [title]="folder.name">{{folder.name}}</span>
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-template #teacherLvl2>
                        <mat-form-field class="width-100" appearance="outline">
                            <mat-select [(ngModel)]="filterFolder" placeholder="Pasta" (selectionChange)="getFolderFiles($event.value)">
                                <mat-option *ngFor="let folder of folders" [value]="folder.id">
                                    <span [title]="folder.name">{{folder.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                </div>
                <div class="col-md-12 mt-2">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Buscar Arquivos por nome" [(ngModel)]="getFileName">
                        <mat-icon (click)="getFileByNameFilter(getFileName)" class="mr-1" matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <mat-list *ngIf="!loading" class="width-100 mt-3 center">

                <div mat-list-item *ngFor="let f of files" class="row" style="min-height: 100px">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8 pt-2">
                        {{f.name}}
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 text-right">
                        <button color="primary" mat-icon-button (click)="preview(f.link)" class="mr-3 hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Download">
                            <mat-icon>get_app</mat-icon>
                        </button>
                        <button color="primary" mat-icon-button (click)="edit(f)" class="mr-3 hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Editar">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button color="warn" mat-icon-button (click)="remove(f.id)" class="hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Excluir">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-list>

            <div *ngIf="empty">Nenhum resultado</div>
            <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
        </nb-card-body>
    </nb-card>

    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>
