import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Folder } from '../admin/repository/folder/folder';
import { Material } from '../admin/repository/material/material';
import { fileClass, FileRepository } from './file/file-repository';
import { VideoRepository } from './video/video-repository';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { throwError } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Class } from '../admin/school/class';
import { StudyPlan } from '../admin/study-plan/study-plan';
import { disciplineTag } from '../admin/discipline-tag/discipline-tag-create/discipline-tag';

interface PlansId {
    id: number;
}
  

@Injectable({ providedIn: 'root' })
export class RepositoryService {

    private API = environment.ApiUrl;

    constructor(
        private http: HttpClient,
        private alertService: AlertService) { }

    getFiles(schoolId: number) {
        return this.http.post(this.API + '/share/getFiles', { schoolId }, { observe: 'response' });
    }

    getFileList(classId: number , schoolId? : number, planId? : any) {
        return this.http.post(this.API + '/share/getFileList', { classId , schoolId , planId}, { observe: 'response' });
    }

    addFile(file: any , disciplineTags: disciplineTag[]) {
        return this.http.post(this.API + '/share/setFile', {file , disciplineTags}, { observe: 'response' });
    }

    saveFileChanges(file: FileRepository) {
        return this.http.post(this.API + '/share/updateFile', file, { observe: 'response' });
    }

    removeFile(fileId: number, folderId?:number) {
        return this.http.post(this.API + '/share/removeFile', { fileId, folderId }, { observe: 'response' });
    }

    getVideos(schoolId: number) {
        return this.http.post(this.API + '/share/getVideos', { schoolId }, { observe: 'response' });
    }

    getVideoList(classId: number, schoolId:number) {
        return this.http.post(this.API + '/share/getVideoList', { classId, schoolId }, { observe: 'response' });
    }

    getFolderVideos(folderId: number) {
        return this.http.post(this.API + '/share/getFolderVideos', { folderId }, { observe: 'response' });
    }
    getFolderFiles(folderId: number) {
        return this.http.post(this.API + '/share/getFolderFiles', { folderId }, { observe: 'response' });
    }

    addVideo(video: VideoRepository) {
        return this.http.post(this.API + '/share/setVideo', video, { observe: 'response' });
    }

    removeVideo(videoId: number) {
        return this.http.post(this.API + '/share/removeVideo', { videoId }, { observe: 'response' });
    }

    saveVideoChanges(video: VideoRepository) {
        return this.http.post(this.API + '/share/updateVideo', video, { observe: 'response' });
    }

    getFolders(schoolId: number, unitId?: number) {
        return this.http.post(this.API + '/share/getFolders', { schoolId, unitId }, { observe: 'response' });
    }

    getFolder(schoolId: number) {
        return this.http.post(this.API + '/share/getFolder', { schoolId }, { observe: 'response' });
    }

    addFolder(folder: Folder) {
        return this.http.post(this.API + '/share/setFolder', folder, { observe: 'response' });
    }

    getFolderPlans(folderId: number) {
        return this.http.post(this.API + '/share/getFolderPlans', {folderId}, { observe: 'response' });
    }

    removeFolder(folderId: number) {
        return this.http.post(this.API + '/share/removeFolder', { folderId }, { observe: 'response' });
    }

    getUnits(schoolId?: number) {
        return this.http.post(this.API + '/school/getUnits', { schoolId }, { observe: 'response' });
    }

    getAllClasses(unitId?: number) {
        return this.http.post(this.API + '/school/getClasses', { unitId }, { observe: 'response' });
    }

    addMaterial(material: Material) {
        return this.http.post(this.API + '/share/addMaterial', material, { observe: 'response' });
    }

    getTeacherFolders(teacherId: number, materialFolder?: number) {
        return this.http.post(this.API + '/share/getTeacherFolders', { teacherId, materialFolder }, { observe: 'response' });
    }

    getMaterials(userId: number, admin?: boolean) {
        return this.http.post(this.API + '/share/getMaterials', { userId, admin }, { observe: 'response' });
    }

    getMaterialsEdit(userId: number, admin?: boolean) {
        return this.http.post(this.API + '/share/getMaterialsEdit', { userId, admin }, { observe: 'response' });
    }

    removeMaterial(materialId: number) {
        return this.http.post(this.API + '/share/deleteMaterial', { materialId }, { observe: 'response' });
    }

    saveMaterialChanges(material: Material) {
        return this.http.post(this.API + '/share/updateMaterials', material, { observe: 'response' });
    }

    getClassFolders(classId: number) {
        return this.http.post(this.API + '/share/getClassFolders', { classId }, { observe: 'response' });
    }

    getPlansFolders(planId: number) {
        return this.http.post(this.API + '/share/getPlansFolders', { planId }, { observe: 'response' });
    }
    
    getPlanFolders(planId: number) {
      return this.http.post(this.API + '/share/getPlanFolders', { planId }, { observe: 'response' });
    }
    getAllFolders(schoolId?: number) {
        return this.http.post(this.API + '/share/getAllFolders', { schoolId }, { observe: 'response' });
      }

    getFolderClasses(folderId: number) {
        return this.http.post(this.API + '/share/getFolderClasses', { folderId }, { observe: 'response' });
    }

    saveFolderChanges(folder: Folder) {
        return this.http.post(this.API + '/share/updateFolder', folder, { observe: 'response' });
    }

    getUnitMaterials(unitId: number, userId?: number, admin?: boolean, exam?: number) {
        return this.http.post(this.API + '/share/getUnitMaterials', { unitId, userId, admin, exam })
            .pipe(
                map((res: ResponseApi) => res?.error ? this.alertService.error(res.error) : res.data as Material[]),
                catchError(err => {
                    this.alertService.error('Houve um erro ao carregar os materiais');
                    return throwError(err);
                })
            );
    }

    getExamMaterials(userId: number) {
        return this.http.post(this.API + '/share/getExamMaterials', { userId })
            .pipe(
                map((res: ResponseApi) => res?.error ? this.alertService.error(res.error) : res.data as Material[]),
                catchError(err => {
                    this.alertService.error('Houve um erro ao carregar as avaliações');
                    return throwError(err);
                })
            );
    }

    getUnitExamsMaterials(unitId: number, userId?: number) {
        return this.http.post(this.API + '/share/getUnitExamsMaterials', { unitId, userId })
            .pipe(
                map((res: ResponseApi) => res?.error ? this.alertService.error(res.error) : res.data as Material[]),
                catchError(err => {
                    this.alertService.error('Houve um erro ao carregar as avaliações');
                    return throwError(err);
                })
            );
    }

    getMaterialClasses(materialId: number) {
        return this.http.post(this.API + '/share/getMaterialClasses', { materialId })
            .pipe(
                map((res: ResponseApi) => res?.error ? this.alertService.error(res.error) : res.data as Class[]),
                catchError(err => {
                    this.alertService.error('Houve um erro ao carregar as turmas do material');
                    return throwError(err);
                })
            );
    }

    getClassAllMaterials(classId: number) {
        return this.http.post(this.API + '/share/getClassAllMaterialsToEdit', { classId }, { observe: 'response' });
    }
    setFolderTeachers(folderId: number[], teacherId: number[]) {
        return this.http.post(this.API + '/share/addTeacherFolder', { teacherId, folderId }, { observe: 'response' });
    }

    setTeacherFolder(teacherId: number[], folderId: number[]) {
        return this.http.post(this.API + '/share/addFolderTeacher', { teacherId, folderId }, { observe: 'response' });
    }

    setTeacherFolderOne(folderId: number[], teacherId: number[]) {
        return this.http.post(this.API + '/share/addFolderTeacherOne', { teacherId, folderId }, { observe: 'response' });
    }

    getFolderTeachers(folderId: number) {
        return this.http.post(this.API + '/share/getAssociatedFolderTeachers', { folderId }, { observe: 'response' });
    }

    getAssociatedTeacherFolders(teacherId: number) {
        return this.http.post(this.API + '/share/getAssociatedTeacherFolders', { teacherId }, { observe: 'response' });
    }

    getAssociatedTeacherClassFolders(teacherId: number, classId: number) {
        return this.http.post(this.API + '/share/getAssociatedTeacherClassFolders', { teacherId, classId }, { observe: 'response' });
    }

    getPermissionsTeacher() {
        return this.http.post(this.API + '/permissions/getPermissionTeacher', {}, { observe: 'response' });
    }

    setPermissionTeacher(teacherId: number, permissionId: number, userId: number) {
        return this.http.post(this.API + '/permissions/setPermissionTeacher', { teacherId, permissionId, userId }, { observe: 'response' });
    }
    getStudentPlans(studentId: number) {
        return this.http.post(this.API + '/share/getStudentPlans', { studentId }, { observe: 'response' });
    }
    getFolderByStudentPlans(studentId: number, plansIds: PlansId[]) {
        return this.http.post(this.API + '/share/getFolderByStudentPlans', { studentId, plansIds }, { observe: 'response' });
    }
    getFolderByStudentClass(classId: number) {
        return this.http.post(this.API + '/share/getFolderByStudentClass', { classId }, { observe: 'response' });
    }
    getFilesFolder(folderId: number, isClassOrPlan: number, classIdOrPlanId: number){
        return this.http.post(this.API + '/share/getFilesFolder', { folderId, isClassOrPlan, classIdOrPlanId }, { observe: 'response' });
    }
    getShareFileByName(fileName: string){
        return this.http.get(this.API + '/sharefiles/getShareFileByName/'+fileName, { observe: 'response' });
    }
    getShareFileByNameCoord(fileName: string , schoolId:number){
        return this.http.get(this.API + '/sharefiles/getShareFileByNameCoord/'+fileName+'/'+schoolId, { observe: 'response' });
    }
    getDisciplineFileTags(fileRepo: number[]) {
        return this.http.post(this.API + '/share/getDisciplineFileTags', fileRepo , { observe: 'response' });
    }
    getFilesFromDisciplineTags(tagId: number) {
        return this.http.post(this.API + '/share/getFilesFromDisciplineTags', {tagId} , { observe: 'response' });
    }

    getChannelFileList(schoolId? : number, planId? : any) {
        return this.http.post(this.API + '/channels/share', { schoolId , planId }, { observe: 'response' });
    }

    getChannelFolderList(schoolId? : number, plansIds? : any, channelId? : number) {
        return this.http.get(this.API + '/channels/folders/list?schoolId='+schoolId+'&plansIds='+plansIds+'&channelId='+channelId, { observe: 'response' });
    }
}