import { Component, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RatingComponent } from 'ng-starrating/lib/rating.component';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { ReportError } from '../../report-error/report-error';
import { ReportErrorComponent } from '../../report-error/report-error.component';
import { StarRatingComponent } from '../../star-rating/star-rating.component';
import { VideoService } from '../../video/video.service';

interface errorReport {
  id: number,
  userId: number,
  elementId: number,
  rota: string,
  description: string,
  videoName: string,
  userName: string,
  created_at: string
}

interface ratingReport {
  id: number,
  userId: number,
  userName: string,
  elementName: string,
  elementId: number,
  starScore: number,
  description: string,
}

@Component({
  selector: 'app-suport',
  templateUrl: './suport.component.html',
  styleUrls: ['./suport.component.scss']
})
export class SuportComponent implements OnInit {

  public errorReports: errorReport[];
  public ratingReports: ratingReport[];
  public filterRating = '';
  public filterReport = '';
  public totalPageReport: number;
  public totalPageRating: number;
  public pageNoRating: number;
  public pageNoReport: number;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ReportErrorComponent , StarRatingComponent>,
    private dialog: MatDialog,
    private alertService: AlertService,
    private videoService: VideoService,
    public deviceService: LayoutService
  ) { }

  ngOnInit() {
    this.getReportsAll(0, 20);
  }

  getReportsAll(offset: number, limit: number) {
    this.videoService.getReportsAll(offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalpage = res.body['totalPage'];

        if (!response.error) {
          this.errorReports = response.data as errorReport[];
          this.totalPageReport = totalpage as number;
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getReportsByName(filtername: string, offset: number, limit: number) {
    this.videoService.getReportsByName(filtername, offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalpage = res.body['totalPage'];

        if (!response.error) {
          this.errorReports = response.data as errorReport[];
          this.totalPageReport = totalpage as number;
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  deleteReport(reportId: number) {
    if (confirm('Deseja realmente excluir esse erro?')) {
      this.videoService.deleteReport(reportId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Erro excluido com sucesso!');
            this.getReportsAll(0, 20);
          }
          else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao conectar . Verifique a conexão e tente novamente'))
    }
  }

  editReport(report : errorReport){
    const dialogRef = this.dialog.open(ReportErrorComponent, {
      minWidth: '60vw',
      data: {report , edit: true}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getReportsAll(0,20);
      }
    });
  }

  editRating(rating : ratingReport){
    const dialogRef = this.dialog.open(StarRatingComponent, {
      minWidth: '60vw',
      data: {rating , edit: true}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getRatingAll(0,20);
      }
    });
  }

  getRatingAll(offset: number, limit: number) {
    this.videoService.getRatingAll(offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalpage = res.body['totalPage'];

        if (!response.error) {
          this.ratingReports = response.data as ratingReport[];
          this.totalPageRating = totalpage as number;
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getRatingAllByName(filtername: string, offset: number, limit: number) {
    this.videoService.getRatingAllByName(filtername, offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalpage = res.body['totalPage'];

        if (!response.error) {
          this.ratingReports = response.data as ratingReport[];
          this.totalPageRating = totalpage as number;
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  deleteRating(ratingId: number) {
    if (confirm('Deseja realmente excluir essa avaliação?')) {
      this.videoService.deleteRating(ratingId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Avaliação excluida com sucesso!');
            this.getRatingAll(0, 20);
          }
          else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao conectar . Verifique a conexão e tente novamente'))
    }
  }

  pageEventsReport(event: any) {
    const offset = event.pageIndex * event.pageSize;
    //console.log(offset, event.pageSize, 'report');

    if (!this.filterReport && this.filterReport != "") {
      this.getReportsByName(this.filterReport, offset, event.pageSize);
    }
    else {
      this.getReportsAll(offset, event.pageSize);
    }
  }

  pageEventsRating(event: any) {
    const offset = event.pageIndex * event.pageSize;
    //console.log(offset, event.pageSize, 'rating');


    if (!this.filterRating && this.filterRating != "") {
      this.getRatingAllByName(this.filterReport, offset, event.pageSize);
    }
    else {
      this.getRatingAll(offset, event.pageSize);
    }
  }

}
