<div class="page-container p-pg page-container-mobile discipline font-poppins">
    <div class="row m-0">
        <div class="mt-2" [ngClass]="{'col-md-6':!layoutService.isMobile, 'col-md-12':layoutService.isMobile}">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'left dash-container':!layoutService.isMobile, 'text-center': layoutService.isMobile, 'text-center width-80': layoutService.isMobile}">
                    <span class="page-title">Aulas / {{planName}} / {{disciplineName}} / {{topicName}}</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile" class="text-color-accent">{{topicName}}</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile" class="text-color-accent">{{topicName}}</h6>
                        <p class="fs-13 pb-0">Escolha a aula que você deseja assistir</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="mt-2 text-right" [ngClass]="{'col-md-5':!layoutService.isMobile, 'col-md-12 p-0':layoutService.isMobile}">
            <mat-form-field class="width-100 search-form">
                <mat-icon class="search-icon" color="accent" matPrefix>search</mat-icon>
                <input class="search-input" matInput placeholder="Procurar aula" [(ngModel)]="classFilter" (keyup)="applyFilter()">
            </mat-form-field>
        </div>
        <div class="mt-2 text-right" [ngClass]="{'col-md-1':!layoutService.isMobile, 'col-md-12 p-0':layoutService.isMobile}">
            <button (click)="backPage()" mat-icon-button class="btn-back" [ngClass]="{'w-100px':!layoutService.isMobile, ' w-100':layoutService.isMobile}">
                <mat-icon class="icon-back">west</mat-icon> Voltar
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pt-2" *ngFor="let video of videos" >
            <button (click)="selectVideo(video.id)" class="decoration-none p-0 w-100 text-left">
                <mat-card class="border-topic">
                    <mat-card-content class="d-flex">
                        <mat-icon color="accent" *ngIf="video.assistido">visibility</mat-icon>
                        <mat-icon *ngIf="!video.assistido">visibility_off</mat-icon>
                        <span class="pl-2 pt-1 f-title"><b>{{video.name}}</b></span>
                        <button (click)="selectVideo(video.id)" class="btn-next" nbButton size="small"><mat-icon class="mr--2">play_arrow</mat-icon></button>
                    </mat-card-content>
                </mat-card>
            </button>
        </div>
    </div>
</div>