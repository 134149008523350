import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { StudentService } from '../student.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { CSVStudents } from '../csv-students';
import { AdminService } from '../../admin.service';
import { UserService } from 'src/app/modules/user/user.service';
import { StudentAddCSVLog } from './student-add-csv-log';
import { MaskService } from 'src/app/shared/services/mask.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-students-add-csv',
    templateUrl: './students-add-csv.component.html'
})
export class StudentsAddCSVComponent implements OnInit, OnDestroy {

    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public addStudentsForm: FormGroup;
    public file;
    public loading: boolean;
    public addLog: StudentAddCSVLog[];
    private admUpdate$: Subscription;

    constructor(
        private studentService: StudentService,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        public userService: UserService,
        public maskService: MaskService) { }

    ngOnInit(): void {
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        // multiples students in file
        this.addStudentsForm = this.formBuilder.group({
            school: ['', Validators.required],
            unit: ['', Validators.required],
            class: ['', Validators.required],
            file: ['', Validators.required],
            expireDays: [{ value: 7, disabled: true }, Validators.maxLength(5)],
            expire: [false]
        });

        this.getSchools();
        this.coordinatorInit();
    }

    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            this.addStudentsForm.get('school').setValue(this.userService.getUserSchool());
            this.getUnits(this.userService.getUserSchool());
        }

        if (this.userService.isUnitCoordinator()) {
            this.addStudentsForm.get('school').setValue(this.userService.getUserSchool());
            this.addStudentsForm.get('unit').setValue(this.userService.getUserUnit());
            this.getClasses(this.userService.getUserUnit());
        }
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    handleFileInput(files: FileList): void {
        if (files && files[0]) {

            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => this.file = reader.result;
        }
    }

    addStudents() {
        this.addStudentsForm.get('file').setValue(this.file);

        if (!this.addStudentsForm.valid) {
            this.alertService.error('Preencha os campos corretamente');
            return;
        }

        this.loading = true;

        const students = this.addStudentsForm.getRawValue() as CSVStudents;

        this.studentService.addStudents(students)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Estudantes adicionados', 3);
                    this.addStudentsForm.reset();
                    this.adminService.updateChields();
                    this.addLog = response.data as StudentAddCSVLog[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao adicionar os estudantes. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    expireToggle(event: MatSlideToggleChange) {

        if (!event.checked) {
            this.addStudentsForm.get('expireDays').disable();
            this.addStudentsForm.get('expire').setValue(false);
        } else {
            this.addStudentsForm.get('expireDays').enable();
            this.addStudentsForm.get('expire').setValue(true);
        }
    }
}
