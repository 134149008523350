<div class="container pt-5 mb-5">
    <form [formGroup]="buyForm" *ngIf="plan && !loading && !bought">
        <mat-card class="width-80 center">

            <div class="row">
                <div class="col-md-6">
                    <h5 class="mb-3">Método de pagamento</h5>
                    <div class="row">
                        <mat-radio-group (change)="changeMethodType()" class="ml-3" formControlName="paymentMethod">
                            <mat-radio-button value="bo" class="mr-4">Boleto</mat-radio-button>
                            <mat-radio-button value="cc">Cartão de Crédito</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="border: solid 1px #e0e0e0" class="p-3 text-color-primary">
                        <div class="row" style="margin-top: 5px">
                            <div class="col-md-6">
                                <div class="sub-title">{{plan.name}}</div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub-title text-right ng-binding">
                                    <font size="2"> &nbsp;</font> {{plan.installments}}x {{plan.price | currency: 'BRL'}}
                                </div>
                            </div>
                        </div>
                        <div style="font-size: small; margin-bottom: 5px;">Acesso por {{plan.months}} Meses</div>
                    </div>
                </div>
            </div>

            <h5 class="mt-4">Dados do comprador</h5>

            <div class="row">
                <div class="col-md-7">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nome" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="CPF" [textMask]="{mask: maskService.cpf}" formControlName="cpf">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Nascimento" [textMask]="{mask: maskService.date}"
                            formControlName="birth">
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Celular" [textMask]="{mask: maskService.phone}"
                            formControlName="phone">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="CEP" [textMask]="{mask: maskService.cep}"
                            formControlName="postalCode">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Endereço" formControlName="address">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Número" [textMask]="{mask: maskService.number}"
                            formControlName="number">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Bairro" formControlName="district">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Cidade" formControlName="city">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="UF" formControlName="uf">
                            <mat-option *ngFor="let uf of ufs" [value]="uf">
                                {{uf}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <ng-container #card *ngIf="buyForm.get('paymentMethod').value == 'cc'">

                <h5 class="mt-4">Dados do cartão</h5>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Nome impresso no cartão" formControlName="cardHolderName">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Número do cartão" [textMask]="{mask: maskService.creditCard}"
                                formControlName="cardNumber">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Validade" [textMask]="{mask: maskService.expiration}"
                                formControlName="cardExpiration">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="CVV" [textMask]="{mask: maskService.cvv}"
                                formControlName="cardCvv">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field class="full-width">
                            <mat-select placeholder="Parcelas" formControlName="installments">
                                <mat-option *ngFor="let parcel of parcels" [value]="parcel">
                                    {{parcel}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

            </ng-container>

            <div class="row pt-3 mr-3">
                <button mat-raised-button color="accent" class="right" (click)="submit()"
                    [disabled]="!buyForm.valid">Finalizar Compra</button>
            </div>

        </mat-card>
    </form>
    <mat-spinner *ngIf="!plan || loading" class="center mt-5"></mat-spinner>
    <app-payment-thanks *ngIf="bought" [boCode]="boCode" [boLink]="boUrl" [method]="paymentMethod"></app-payment-thanks>
</div>
