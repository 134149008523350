
  interface AnnexVideoInterface  {
        id:number,
        videoId:number,
        name:string ,
        link:string  
}
export class AnnexVideoModel { 

    constructor(
        private annex:AnnexVideoInterface 
    ){}

    public getObejct(){
        return this.annex;
    }


}