import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { CalendarService } from 'src/app/modules/calendar/calendar.service';
import { SlideFile } from 'src/app/modules/slide-file/slide-file';
import { SlideFileService } from 'src/app/modules/slide-file/slide-file.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Video } from 'src/app/modules/video/video';
import { VideoService } from 'src/app/modules/video/video.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { Discipline } from '../../../discipline/discipline';
import { DisciplineService } from '../../../discipline/discipline.service';
import { School } from '../../../school/school';
import { SchoolService } from '../../../school/school.service';
import { StudyPlanService } from '../../../study-plan/study-plan.service';

@Component({
  selector: 'app-add-lesson-dialog',
  templateUrl: './add-lesson-dialog.component.html',
  styleUrls: ['./add-lesson-dialog.component.scss']
})
export class AddLessonDialogComponent implements OnInit {

  public form: FormGroup;
  public loading: boolean;
  public saving: boolean;
  public disableSave = true;
  public disciplines: Discipline[];
  public videos: Video[];
  public loadingDisciplines: boolean;
  public disciplineId: number;
  public dayContent: (Video | SlideFile)[];
  public contentType: string;
  public slideFiles: SlideFile[];
  public schools: School[];
  public schoolId: number;
  public typeSlideFile: boolean;
  public typeVideo: boolean;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dayContent: (Video | SlideFile)[], dayvideos:any },
    public dialogRef: MatDialogRef<AddLessonDialogComponent>,
    private alertService: AlertService,
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService,
    private schoolService: SchoolService,
    public userService: UserService,
    public videoService: VideoService,
    public slideFileService: SlideFileService
  ) { }

  ngOnInit(): void {
    
    this.dayContent = [...this.data.dayContent]; 
    this.getDisciplines();
  }

  drop(event: CdkDragDrop<Video[]>) {
    moveItemInArray(this.dayContent, event.previousIndex, event.currentIndex);
    this.disableSave = false;
  }

  selectedContentType(value: string){
    if(value == 'video'){
      this.typeVideo = true;
    }else if(value == 'slideFile'){
      this.typeSlideFile = true;
    }
    this.getDisciplines();
  }

  remove(index: number) {
    this.dayContent.splice(index, 1);
    this.disableSave = false;
  }

  getDisciplines(): void {
    if (this.userService.isAdmin()) {
      this.getSchools();
    } else if (this.userService.isTeacher()) {
      this.getTeacherDisciplines(this.userService.getUserId());
    } else if (this.userService.isGeneralCoordinator()) {
      this.getSchoolDisciplines(this.userService.getUserSchool());
    } else if (this.userService.isUnitCoordinator()) {
      this.getUnitDisciplines(this.userService.getUserUnit());
    }
  }

  getAllDisciplines() {
    this.loadingDisciplines = true;
    this.disciplineService.getDisciplines()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingDisciplines = false;

      }, err => {
        this.alertService.error('Houve um erro ao buscar as disciplínas. Verifique a conexão e tente novamente');
        this.loadingDisciplines = false;
      });
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getSchoolDisciplines(schoolId: number) {
    this.loadingDisciplines = true;
    this.disciplineId = null;
    this.studyPlanService.getSchoolDisciplines(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingDisciplines = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique sua conexão');
        this.loadingDisciplines = false;
      });
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
  }

  private getTeacherDisciplines(teacherId: number) {
    this.loadingDisciplines = true;
    this.disciplineId = null;
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        }
        this.loadingDisciplines = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde');
        this.loadingDisciplines = false;
      });
  }

  getDisciplineSlideFiles(disciplineId: number) {
    this.loading = true;
    this.slideFiles = [];
    this.slideFileService.getDisciplineSlideFiles(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.slideFiles = response.data as SlideFile[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os arquivos de aula. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplineVideos(disciplineId: number) {
    // tslint:disable-next-line: triple-equals
    if (disciplineId != 0) {
      this.loading = true;
      this.videoService.getDisciplineVideos(disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.videos = response.data as Video[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
  }

  filterVideoByName(name: string , schoolId: number) {

    if(!this.userService.isAdmin()){
      schoolId = this.userService.getUserSchool();
    }

    if (name.length > 3) {
      this.loading = true;
      this.videoService.getVideosByNameCalendar(name , schoolId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.videos = response.data as Video[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar os videos. Verifique a sua conexão');
          this.loading = false;
        });
    }
  }

  verifyFilter(value: string, filter: string): boolean {
    if (value && filter) {
      return value.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    }
    return true;
  }

  contentAlreadySelected(content: Video | SlideFile) {
    let selected = false; 
    this.dayContent.forEach(c => {
      if (
        (this.videoService.instanceOfVideo(c) && this.videoService.instanceOfVideo(content)) ||
        (this.slideFileService.instanceOfSlideFile(c) && this.slideFileService.instanceOfSlideFile(content))) {
        c.id == content.id && (selected = true)
      }
    });
    return selected;
  }

  addContent(content: Video | SlideFile) {
    if (!this.contentAlreadySelected(content)) {
      this.dayContent.push(content);
      this.disableSave = false;
    }
  }

  finalize() {
    this.data.dayContent = [...this.dayContent];
    this.dialogRef.close(this.data.dayContent);
  }

  public teacherOwnerDiscipline(content: Video | SlideFile) {
    if (this.userService.isTeacher()) {
      this.disciplines.find(d => {
        if (this.videoService.instanceOfVideo(content)) {
          return d.id == (content as Video).discipline;
        } else {
          return d.id == (content as SlideFile)?.disciplineId;
        }
      });
    } else {
      return true;
    }
  }

}
