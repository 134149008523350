import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommunityAnswer, CommunityTopic } from './community/communityComment';
import { StudyPlan } from '../admin/study-plan/study-plan';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  createCommunityTopic(comment: CommunityTopic) {
    return this.http.post(this.API + '/community/createCommunityTopic', comment , { observe: 'response' });
   }

   getTopics(planId: number ,offset: number , limit: number) {
    return this.http.post(this.API + '/community/getTopics', {planId , offset , limit} , { observe: 'response' });
   }

   getTopicsBySchool(schoolId: number ,offset: number , limit: number) {
    return this.http.post(this.API + '/community/getTopicsBySchool', {schoolId , offset , limit} , { observe: 'response' });
   }

   getAllTopics(plans: StudyPlan[] ,offset: number , limit: number) {
    return this.http.post(this.API + '/community/getAllTopics', {plans , offset , limit} , { observe: 'response' });
   }

   getTopicById(topicId: number) {
    return this.http.post(this.API + '/community/getTopicById', {topicId} , { observe: 'response' });
   }

   createAnswer(comment: CommunityAnswer) {
    return this.http.post(this.API + '/community/createAnswer', comment , { observe: 'response' });
   }

   getTopicAnswers(topicId: number) {
    return this.http.post(this.API + '/community/getTopicAnswers', {topicId} , { observe: 'response' });
   }

   getMyTopics(userId: number , offset: number , limit: number) {
    return this.http.post(this.API + '/community/getMyTopics', {userId , offset , limit} , { observe: 'response' });
   }

   getAnsweredTopics(userId: number , offset: number , limit: number) {
    return this.http.post(this.API + '/community/getAnsweredTopics', {userId , offset , limit} , { observe: 'response' });
   }

   getDisciplineTopics(disciplineId: number) {
    return this.http.post(this.API + '/community/getDisciplineTopics', {disciplineId} , { observe: 'response' });
   }

   getUserActiviesBySchool(schoolId: number) {
    return this.http.post(this.API + '/community/getUserActiviesBySchool', {schoolId} , { observe: 'response' });
   }

   deleteTopic(topicId: number) {
    return this.http.post(this.API + '/community/deleteTopic', {topicId} , { observe: 'response' });
   }

   deleteAnswer(answerId: number) {
    return this.http.post(this.API + '/community/deleteAnswer', {answerId} , { observe: 'response' });
   }
}
