import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { Theme } from './theme';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ThemeService {

    private themeDataKey = Md5.hashStr('themeDataViaulon') as string;
    private theme: Theme = {logo: null, theme: null, name: '', favicon: '' , title:''};
    private themeSubject = new BehaviorSubject<Theme>(null);
    private API = environment.ApiUrl;
    public emitThemeIsLoaded = new EventEmitter<string>();

    constructor(private http: HttpClient) {
        this.haveTheme() && this.decodeAndNotify();
    }

    getTheme(domain: string) {
        return this.http.post(this.API + '/theme/getThemeData', {domain}, {observe: 'response'});
    }

    getThemeObservable() {
        return this.themeSubject.asObservable();
    }

    setThemeData(theme: Theme) {
        const themeDataString = JSON.stringify(theme);
        window.localStorage.setItem(this.themeDataKey, btoa(themeDataString));
        this.decodeAndNotify();
    }

    getThemeData() {
        return window.localStorage.getItem(this.themeDataKey);
    }

    private decodeData(data: string): Theme {
        return JSON.parse(atob(data));
    }

    private decodeAndNotify() {
        const themeRawData = this.getThemeData();
        const theme = this.decodeData(themeRawData);
        this.theme.logo = theme.logo;
        this.theme.theme = theme.theme;
        this.themeSubject.next(theme);
    }

    haveTheme() {
        return !!this.getThemeData();
    }
}
