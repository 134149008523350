<div *ngIf="!showVideosList" class="content">
    <span *ngIf="newVideoId">
      <mat-icon class="icon-align" matPrefix matTooltipClass="custom-tooltip" matTooltip="URL para incorporação do video!">
        check_circle
      </mat-icon> <b class="link-video-live" > Último video cadastrado / URL:</b>
        <i class="link-video-live-i">  {{domain}}/video/watch/{{newVideoId}} </i>
    </span>

    <div mat-dialog-content class="edit-video-content">
        <form [formGroup]="addVideoForm">

            <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Video</h4>


            <!-- Name, TAG -->
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Nome" formControlName="title">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Tag" formControlName="tag" maxlength="6">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-slide-toggle formControlName="transmissao" color="primary" class="mt-3" (click)="checkLive()">
                        <div class="d-flex align-items-center">
                            <mat-icon matTooltipClass="custom-tooltip" matTooltip="Define se vai ser uma Transmissão ao vivo com chat">
                                help
                            </mat-icon>
                            <span class="text-wrap text-center">Transmissão ao vivo</span>
                        </div>
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row  align-items-center mt-1">
                <div class="col-md-6 radio-group-container">
                    <mat-radio-group aria-label="Selecione o servidor do video" formControlName="embedType">
                        <mat-radio-button class="radio-item button-vimeo" value="vimeo" class="mr-4" matTooltipClass="custom-tooltip" matTooltip="Vimeo">
                            <mat-icon svgIcon="vimeo" alt="Vimeo"></mat-icon>
                        </mat-radio-button>
                        <mat-radio-button class="radio-item button-youtube" value="youtube" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="YouTube">
                            <mat-icon svgIcon="youtube" alt="Youtube"></mat-icon>
                        </mat-radio-button>
                        <mat-radio-button *ngIf="!addVideoForm.get('transmissao').value" class="radio-item button-youtube ml-4" value="panda" matTooltipClass="custom-tooltip" matTooltip="panda">
                          <mat-icon svgIcon="panda" alt="Panda"></mat-icon>
                      </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Código do vídeo" formControlName="embedCode">
                    </mat-form-field>
                </div>
                <!--  <div class="col-md-6" *ngIf="live">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Código do Chat" formControlName="embed_chat">
                    </mat-form-field>
                </div> -->
                <div class="col-md-4 d-flex align-items-center" *ngIf="addVideoForm.get('embedType').value == 'youtube'">
                    <mat-slide-toggle formControlName="youtubeMask" color="primary">
                        <div class="d-flex align-items-center">
                            <mat-icon matTooltipClass="custom-tooltip" matTooltip="Define se tera a mascara do Youtube">
                                help
                            </mat-icon>
                            <span class="text-wrap text-center">Mascara do Youtube!</span>
                        </div>
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <mat-form-field class="width-100">
                        <mat-label>Filtrar disciplinas</mat-label>
                        <input matInput (keyup)="filterDisciplines($event.target.value)">
                    </mat-form-field>
                </div>
                <div class="col-md">
                    <mat-form-field class="width-100">
                        <mat-icon class="icon-align" matPrefix matTooltipClass="custom-tooltip" matTooltip="Neste campo você selecionará a disciplina que o vídeo será associado.">
                            info
                        </mat-icon>
                        <mat-select formControlName="discipline" placeholder="Disciplina">
                            <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                <span [title]="discipline.name">{{discipline.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row border-primary-thin ml-2 mr-3 mt-3"></div>
            <span class="ml-2">Seleção de Temas</span>
            <div class="row mt-2 mb-2">
                <div class="col-md-8" *ngIf="userService.isAdmin()">
                    <mat-form-field class="width-100">
                        <mat-select placeholder="Selecione a escola para carregar os temas" formControlName="selectedSchoolForTag" (selectionChange)="selectScholl($event.value)">
                            <mat-option *ngFor="let school of schoolsForTags" [value]="school.id">
                                <span [title]="school.name">   {{school.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-slide-toggle (change)="checktoogleTag($event.checked)" formControlName="schoolOnly" color="primary" class="mt-3">
                        <div class="d-flex align-items-center">
                            <mat-icon matTooltipClass="custom-tooltip" matTooltip="As disciplinas so sao cadastradas por escolas">
                                help
                            </mat-icon>
                            <span class="text-wrap text-center">Tema por escola</span>
                        </div>
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6" *ngIf="!addVideoForm.get('schoolOnly').value">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="Selecione os planos" (selectionChange)="getDisciplinesByPlan($event.value)">
                            <mat-option *ngFor="let plan of plansTag" [value]="plan.id">
                                {{plan.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6" *ngIf="!addVideoForm.get('schoolOnly').value">
                    <mat-form-field class="w-100">
                        <mat-select (selectionChange)="getDisciplinesTagsByDiscipline($event.value)" placeholder="Selecione as disciplinas">
                            <mat-option *ngFor="let discipline of disciplinesTagsTags" [value]="discipline.id">
                                {{discipline.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2 mb-2">
                <div class="col-md-6" *ngIf="disciplinesTags.length > 0">
                    <mat-form-field class="width-100">
                        <mat-select placeholder="Temas de disciplinas" formControlName="disciplinesTag">
                            <mat-option (click)="addDisciplineTagChip(tag)" *ngFor="let tag of disciplinesTags" [value]="tag.id">
                                <span [title]="tag.tag_name">{{tag.tag_name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-chip-list *ngIf="selectedDisciplinesTags">
                        <mat-chip *ngFor="let tagChip of selectedDisciplinesTags" selectable="true" removable="true" (removed)="removeDisciplineTagChip(tagChip)">
                            {{tagChip.tag_name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="row border-primary-thin ml-2 mr-3 mt-3"></div>
            <span class="ml-2" *ngIf="!live">Seleção de Exercícios</span>
            <div class="row" *ngIf="!live">
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-icon class="icon-align" matPrefix matTooltipClass="custom-tooltip" matTooltip="Esta área é destinada apenas a busca de exercícios para associar o vídeo.">
                            info
                        </mat-icon>
                        <mat-select matTooltipClass="custom-tooltip" matTooltipClass="custom-tooltip" matTooltip="Filtrar exercícios por disciplina" (selectionChange)="getExercisesByDiscipline($event.value)">
                            <mat-option value="library">
                                Exercicios da biblioteca
                            </mat-option>
                            <mat-option *ngFor="let discipline of disciplinesExercise" [value]="discipline.id">
                                <span [title]="discipline.name">{{discipline.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="!live">
                <div class="col-12">
                    <mat-form-field *ngIf="!loadingExercises" class="width-100">
                        <mat-select formControlName="exercises" placeholder="Exercícios">
                            <mat-option (click)="addExerciseChip(exercise)" *ngFor="let exercise of exercises" [value]="exercise.id">
                                <span [title]="exercise.name">{{exercise.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="loadingExercises">
                    <mat-spinner [diameter]="25" class="center"></mat-spinner>
                </div>
            </div>
            <div class="row">
                <mat-chip-list *ngIf="selectedExercises">
                    <mat-chip *ngFor="let exerciseChip of selectedExercises" selectable="true" removable="true" (removed)="removeExerciseChip(exerciseChip)">
                        {{exerciseChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div class="col-md" *ngIf="!live">
                <mat-form-field *ngIf="libQuestions" class="width-100">
                    <mat-select formControlName="libQuestions" placeholder="Exercícios da biblioteca">
                        <mat-option (click)="addlibQuestionChip(question)" *ngFor="let question of libQuestions" [value]="question.id">
                            <span [title]="question.name">{{question.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="loadingExercises">
                    <mat-spinner [diameter]="25" class="center"></mat-spinner>
                </div>
                <mat-chip-list *ngIf="selectedExercises">
                    <mat-chip *ngFor="let questionChip of selectedQuestions" selectable="true" removable="true" (removed)="removelibQuestionChip(questionChip)">
                        {{questionChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div class="row border-primary-thin ml-2 mr-3 mt-4" *ngIf="!live"></div>
            <span class="ml-2" *ngIf="!live">Descrição do Vídeo</span>
            <div class="row">
                <div class="col-md-12 mt-1">
                    <mat-form-field class="width-100" appearance="outline">
                        <textarea formControlName="description" [maxlength]="maxChars" class="boxAnswer" matInput placeholder="Descrição do video(opcional)"></textarea>
                    </mat-form-field>
                    <label class="nopadding clrlbl"> Caracteres Restantes: {{role.length}}/{{maxChars}}</label>
                </div>
            </div>
        </form>
    </div>


    <!-- video ao vivo -->
    <div *ngIf="live && !editMode">
        <div class="row">
            <div class="col-md-12" *ngIf="addVideoForm.get('embedType').value != 'youtube'">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Codigo da aula gravada" [(ngModel)]="recordedLink">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-label>Início</mat-label>
                    <input matInput placeholder="Início" [(ngModel)]="initDate" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-label>Término</mat-label>
                    <input matInput placeholder="Término" [(ngModel)]="endDate" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
        </div>
        <!-- Exercícios,  -->
        <div class="row">
            <div class="ml-3">
                <mat-form-field>
                    <mat-select placeholder="selecione a versão" [(ngModel)]="versionId" (selectionChange)="getSchoolPlansByVersion($event.value ,schoolId)">
                        <mat-option *ngFor="let v of version" [value]="v.value">
                            <span [title]="v.option">{{v.option}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mt-4 ml-2">
                <span>Selecione a versão</span>
            </div>
        </div>

        <div class="row">
            <div class="col" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" [(ngModel)]="schoolId" (selectionChange)="getSchoolPlansByVersion(versionId ,$event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" [(ngModel)]="planId" (selectionChange)="addStudyPlanId($event.value)">
                        <ng-container *ngFor="let plan of plans">
                            <mat-option [value]="plan.id">
                                <ng-container> <span [title]="plan.name">{{plan.name}}</span></ng-container>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md">
                <div>
                    <mat-label class="mb-2">Plano de estudos selecionados</mat-label>
                    <mat-chip-list>
                        <mat-chip *ngFor="let studyPlan of studyPlanArray" selectable="true" removable="true" (removed)="removeSelectedStudyPlan(studyPlan.id)">
                            {{studyPlan.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin"
            >Ver vídeos cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100" *ngIf="!live">
            <button (click)="addVideo()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
        <div class=" col-6 text-right width-100" *ngIf="live">
            <button (click)="addVideoLive()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode && !live" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
    <div *ngIf="editMode && live" class="row mt-5 text-right">
        <button (click)="saveChangesLive()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>

<div *ngIf="showVideosList" class="">
    <div class="row">
        <div class="col mb-2">
            <mat-slide-toggle [(ngModel)]="editLive" color="primary" class="mt-3">
                <mat-icon matTooltipClass="custom-tooltip" matTooltip="Pesquise as aulas ao vivo cadastradas!">
                    help
                </mat-icon>
                Aulas ao vivo
            </mat-slide-toggle>
        </div>
    </div>
    <div *ngIf="!editLive">
        <div class="row">
            <div *ngIf="userService.isAdmin()" class="text-right col-md-6">
                <mat-form-field class="width-100">
                    <mat-label>Escola</mat-label>
                    <mat-select (selectionChange)="getSchoolDisciplines($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="text-right col-md-6" [ngClass]="{'col-md-12': !userService.isAdmin()}">
                <mat-form-field *ngIf="!loadingDisciplines" class="width-100">
                    <mat-label>Disciplina</mat-label>
                    <mat-select [(ngModel)]="disciplineId" (selectionChange)="getDisciplineVideos($event.value)">
                        <mat-option value="0">Buscar video pelo nome</mat-option>
                        <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                            <span [title]="discipline.name">{{discipline.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="loadingDisciplines">
                    <mat-spinner [diameter]="25" class="center"></mat-spinner>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="disciplineId == 0">
            <mat-form-field appearance="outline" class="width-60 mt-3 col-md-12" ngDefaultControl>
                <input #nameVideo matInput placeholder="Buscar video" class="aling-right" [(ngModel)]="filterClass" (keyup.enter)="filterVideoByName(nameVideo.value)">
                <mat-icon matSuffix class="corpadraolup search" (click)="filterVideoByName(nameVideo.value)">search</mat-icon>
            </mat-form-field>
                    <!-- <mat-form-field class="width-100">
                        <input #nameVideo matInput placeholder="Buscar video">
                    </mat-form-field> -->
                <!-- <div class="col">
                    <button (click)="filterVideoByName(nameVideo.value)" class="right" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar">
                    <mat-icon class="search" >search</mat-icon>
                </button> -->
                <!-- </div> -->
        </div>
        <nb-card size="medium" *ngIf="videos && !loading">
            <nb-card-header *ngIf="disciplineId != 0">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="width-100">
                            <input matInput placeholder="Buscar" [(ngModel)]="filter">
                        </mat-form-field>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <mat-list class="width-90 mt-3 center">
                    <ng-container *ngFor="let video of videos">
                        <div mat-list-item class="row" style="min-height: 100px" *ngIf="verifyFilter(video.title, video.id)">
                            <div class="col-4 align-middle">
                                {{video.title}}
                            </div>
                            <div class="col-4 align-middle">
                                criado em {{video.registerDate | date:'dd/MM/yyyy'}}
                            </div>
                            <div class="col-2 text-right align-middle">
                                <button class="bg-color-accent-300 border-accent" (click)="editVideo(video)" nbButton size="tiny" outline status="primary">Editar</button>
                            </div>
                            <div class="col-2 text-right align-middle">
                                <button size="tiny" (click)="removeVideo(video.id)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                            </div>
                        </div>
                    </ng-container>
                </mat-list>
            </nb-card-body>
        </nb-card>
    </div>

    <div *ngIf="editLive">
        <div class="row">
            <mat-form-field appearance="outline" class="width-60 mt-3 col-md-12" ngDefaultControl>
                <input #nameVideo matInput placeholder="Buscar por nome" class="aling-right" [(ngModel)]="filterClass" (keyup.enter)="getClassByName(filterClass)">
                <mat-icon matSuffix class="corpadraolup search" (click)="getClassByName(filterClass)">search</mat-icon>
            </mat-form-field>
            <div class="col" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" [(ngModel)]="schoolIdEditLive" (selectionChange)="getSchoolPlans($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" [(ngModel)]="filterPlanIdLive" (selectionChange)="getClassrooms($event.value)">
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            <span [title]="plan.name">{{plan.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <nb-card-body>
            <mat-list class="width-90 mt-3 center">
                <ng-container *ngFor="let classroom of classrooms">
                    <div mat-list-item class="row" style="min-height: 100px">
                        <div class="col-4 align-middle">
                            {{classroom.name}}
                        </div>
                        <div class="col-4 align-middle">
                            Início <i>{{classroom.initDate}}</i><br> Fim <i>{{classroom.endDate}}</i>
                        </div>
                        <div class="col-2 text-right align-middle">
                            <button (click)="editClassroom(classroom, classroom.schoolId)" nbButton size="tiny" outline status="primary">
                                <nb-icon icon="edit"></nb-icon>
                            </button>
                        </div>
                        <div class="col-2 text-right align-middle">
                            <button size="tiny" (click)="removeClassroom(classroom)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </mat-list>
        </nb-card-body>
    </div>

    <div class="text-right width-100">
        <button (click)="showVideosList = false;" class="right bg-color-primary border-primary" nbButton size="small" status="primary">voltar</button>
    </div>
    <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>

</div>
