
export interface IframeVideoInterface{
    id:number,
    name:string,
    videoId:number,
    iframe:string ,
    schoolId:number ,
    planId :number, 
    disciplineId:number
}

export class IframeVideoModel {
    
    constructor(
        private Iframe:IframeVideoInterface 
    ){}

    public getObejct(){
        return this.Iframe;
    }
}