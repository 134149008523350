<div class="modal-container ">
    <div [ngClass]="{'iframe-mobile mr-5 verticalCenter': layoutService.isMobile , 'iframe-web verticalCenter' : !layoutService.isMobile}" [ngStyle]="{'background': modal.colorModal}">
        <div class="mb-2">
            <a (click)="closeDialog()" class="closeBtn" mat-dialog-close>X</a>
        </div>
        <p><span class="p1-title" [ngStyle]="{'color': modal.colorText}">{{modal.small_desc}}</span></p>
        <p><span class="p2-title" [ngStyle]="{'color': modal.colorText}">{{modal.title}}</span> </p>
        <iframe [src]="iframeLink" [width]="data.width" [height]="data.height" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
    </iframe>
        <br>
        <a (click)="openBtnLink()" class="a1-btn">
      {{modal.buttonName}}
    </a>
        <br>
        <a class="a2-btn-sair" (click)="closeDialog()" mat-dialog-close>{{modal.dismissName}}</a>
        <br>
    </div>
    <div class="emptyDiv verticalCenter"></div>
</div>