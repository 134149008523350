import { ExercisesVideoModel } from "./ExercisesVideo.model";
import { VideoDisciplineModel } from "./VideoDiscipline.model";

interface DisciplineInterface{
    id:number, 
    name:string ,
    tag:string ,
    justExercise:boolean ,
    active:boolean , 
    videos?:  VideoDisciplineModel[] ,
    exercises?:  ExercisesVideoModel[]  
}

export class DisciplineModel { 

    private config: {
        exercises: false,
        videoExercises: false,
        videoIframes: false,
        videoAnnex: false,
        videos: true
    }

    constructor(private discipline: DisciplineInterface){
        this.discipline.videos = [];
        this.discipline.exercises = [];
        
    } 

    public countVideos(){
        return this.discipline.videos.length;
    } 

    public getConfig(){
        return this.config;
    } 
    public setConfig(){
        this.config 
    } 
    public getId(){
        return this.discipline.id;
    } 
    public getVideos(){
        return this.discipline.videos;
    } 
     
    public setVideos(arr: any){
        this.discipline.videos = arr;
    } 
    public getName(){
        return this.discipline.name;
    }
    public getVideoExercise(){
        return this.discipline.videos;
    }
    public getObejct(){
        return this.discipline;
    }

    public addVideos(  videos:any[]){
        this.discipline.videos =  videos.map(v=>{
            return new VideoDisciplineModel({...v, ...{ disciplineId :this.discipline.id} } );
        });
    }

    public removeVideo(id:number){
        this.discipline.videos =  this.discipline.videos.filter(v=> v.getId() !== id );
    }
    public addVideosExercises(  exercises:any[]){
        
        this.discipline.videos.map(v=>{
            let e  = exercises.filter((e)=> e.videoId ===v.getId() )
            v.addExercises(e);
            return v ;
        });
    }
    public addVideosIframes(  iframes:any[]){
        
        this.discipline.videos.map(v=>{
            let i  = iframes.filter((i)=> i.videoId ===v.getId() )
            v.addIframes(i);
            return v ;
        });
    }
    public addVideosAnnex(  annex:any[]){
        
        this.discipline.videos.map(v=>{
            let a  = annex.filter((a)=> a.videoId ===v.getId() )
            v.addAnnex(a);
            return v ;
        });
    }
    public addExercises(videoId:number, exercises:any[]){
        this.discipline.exercises =  exercises.map(e=>{
            return new ExercisesVideoModel({...e,videoId});
        });
    }
    
    public setName(name:string){
        this.discipline.name = name ;
    }
}