<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-5 mt-2">
            <ng-container *ngIf="!layoutService.isMobile || !disciplineSelected">
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">Lista de <span *ngIf="layoutService.isMobile">Exercícios</span></span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Exercícios</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Selecione uma disciplina</h6>
                        <p *ngIf="!layoutService.isMobile">Escolha uma disciplina para carregar os exercícios</p>
                        <p class="fs-13 pb-0" *ngIf="layoutService.isMobile">Escolha uma disciplina para carregar os exercícios</p>
                    </div>
                </div>
                <!-- SELECT DISCIPLINE LISTA DESKTOP -->
                <div class="plan-list" *ngIf="!layoutService.isMobile">
                    <mat-list *ngIf="!loadingDisciplines">
                        <mat-list-item *ngFor="let discipline of disciplines" (click)="selectDiscipline(discipline)">
                            <div class="plan-item mb-3" matRipple [ngClass]="{'bg-color-accent': disciplineSelected == discipline?.discipline}">
                                <div class="plan-icon">
                                    <mat-icon [color]="(disciplineSelected == discipline?.discipline) ? null : 'accent'">
                                        task_alt</mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ discipline?.discipline }}</strong>
                                    <span class="plan-expire"></span>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loadingDisciplines" class="center table-spinner" color="accent"></mat-spinner>
                </div>
                <!-- SELECT DISCIPLINE LISTA MOBILE -->
                <div *ngIf="layoutService.isMobile">
                    <mat-form-field class="width-100 m-0 p-0 form-field" appearance="fill" *ngIf="!loadingDisciplines">
                        <mat-select class="p-0 m-0 form-select" [(ngModel)]="firstDiscipline" (selectionChange)="selectDiscipline($event.value)" placeholder="Selecione uma disciplina">
                            <mat-option selected disabled>Selecione uma disciplina</mat-option>
                            <mat-option [value]="discipline" *ngFor="let discipline of disciplines">
                                {{discipline.discipline}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-spinner *ngIf="loadingDisciplines" class="center table-spinner" color="accent"></mat-spinner>
                </div>
                <!-- //SELECT PLANO LISTA -->
            </ng-container>
            <ng-container *ngIf="layoutService.isMobile && disciplineSelected">
                <div class="selected-plan">
                    <div class="page-title-container mt-3 pb-0 pt-0" [ngClass]="{'text-center': layoutService.isMobile}">
                        <div class="page-title">Disciplina selecionada</div>
                        <h3 class="mb-3">{{disciplineSelected}}</h3>
                    </div>
                    <div class="lesson-back">
                        <button (click)="disciplineSelected = null" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-7 mt-2 height-100" [ngClass]="{'margin-de-cima': !layoutService.isMobile}">

            <div class="col-md-6" *ngIf="!layoutService.isMobile && disciplineSelected">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Buscar pelo nome" [(ngModel)]="filterExercise" (keyup)="applyFilter()">
                </mat-form-field>
            </div>
            <!-- exercise list -->
            <ng-container>
                <div *ngIf="exerciseDataSource?.data?.length && !loadingExercises && (disciplineSelected || firstDiscipline)" class="mt-2 table-container table-container-discipline height-100" [ngClass]="{' position-r height-100': !layoutService.isMobile}">
                    <table mat-table [dataSource]="exerciseDataSource" class="table-discipline mb-3 height-100">
                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef [ngClass]="{'brr-5': layoutService.isMobile}" class="border-bottom-none disciplina brl-5"> NOME </th>
                            <td mat-cell *matCellDef="let exercise; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                <span class="mobileDiscipline" *ngIf="!layoutService.isMobile">{{exercise.name}}</span>
                                <button class="mobileDisciplineLink table-mobile-link mr-10px" mat-button color="accent" (click)="open(exercise.link)">
                                <span class="">{{exercise.name}}</span>
                                <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent">Acessar</span>
                            </button>
                            </td>
                        </ng-container>
                        <!-- Link Column -->
                        <ng-container matColumnDef="link" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef class="border-radius-table-right brr-5 text-center"> LINK </th>
                            <td mat-cell *matCellDef="let exercise; let i = index" class="text-center" [ngClass]="{'table-line-contrast': i % 2}">
                                <!-- open exercise -->
                                <button mat-button color="accent" (click)="open(exercise.link)">
                                    <strong>Acessar</strong>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- select a folder -->
                <div *ngIf="!disciplineSelected && !loadingExercises" class="plan-not-selected" [ngClass]="{'height-100vh': !layoutService.isMobile, 'height-30vh text-center': layoutService.isMobile }">
                    <h4 *ngIf="!layoutService.isMobile">Selecione uma disciplina</h4>
                    <h6 class="mb-0" *ngIf="layoutService.isMobile">Nenhuma disciplina selecionada</h6>
                    <br />
                    <p *ngIf="!layoutService.isMobile">Escolha uma disciplina para carregar os exercícios</p>
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
                <!-- empty folder -->
                <div *ngIf="exerciseDataSource && !exerciseDataSource?.data?.length" class="plan-not-selected">
                    <h4 *ngIf="!layoutService.isMobile">Nenhum exercício</h4>
                    <h6 class="mb-0" *ngIf="layoutService.isMobile">Nenhum exercício</h6>
                    <br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>

                <mat-spinner *ngIf="loadingExercises" class="center table-spinner" color="accent"></mat-spinner>
            </ng-container>
        </div>

    </div>
