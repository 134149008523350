import { Component, OnInit } from '@angular/core';
import { PodcastService } from './podecast.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Podcast } from './podcast';
import { UserService } from '../user/user.service';
import { ThemeService } from 'src/app/core/themes/theme.service';
import { Theme } from 'src/app/core/themes/theme';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
    selector: 'app-podcast',
    templateUrl: './podcast.component.html',
    styleUrls: ['./podcast.component.scss']
})
export class PodcastComponent implements OnInit {

    public podcasts: Podcast[];
    public theme: Theme;
    public loading: boolean;

    constructor(
        private podcastService: PodcastService,
        private alertService: AlertService,
        private userService: UserService,
        private themeService: ThemeService,
        public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.themeService.getThemeObservable()
            .subscribe(theme => this.theme = theme);
        this.getPodcasts(this.userService.getUserSchool());
    }

    getPodcasts(schoolId: number) {
        this.loading = true;
        this.podcastService.getPodcasts(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.loading = false;
                    this.podcasts = response.data as Podcast[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as podcasts. Verifique a conexão e tente novamente'));
    }
}
