import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { runInThisContext } from 'vm';
import { UserService } from '../../user/user.service';
import { AdminService } from '../admin.service';
import { SchoolSelect } from '../school/school';
import { SchoolService } from '../school/school.service';
import { UnitSelect } from '../school/unit';
import { Channel } from './channel';
import { ChannelService } from './channel.service';
import { ManageChannelController } from './Channels/Controller/ManageChannel.controller';
import { ManageChannelFactory } from './Channels/Factories/ManageChannel.factory';
import { ChannelModel } from './Channels/Models/Channel.model';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit {


  public formChannel: FormGroup;
  public openChannelList: boolean = false;
  public channelEdit: ChannelModel= null;
  public channels: Channel[] = [];
  public expandPanel: number;
  public loading: boolean;
  public channel: Channel;
  public list: string = 'list';
  public channelController=null;
  public schools: SchoolSelect[];
  schoolId: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private adminService: AdminService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public channelService: ChannelService,
    public schoolService: SchoolService

    ) { }

  ngOnInit() {


    if (this.userService.isAdmin()) {
      this.formChannel = this.formBuilder.group({
        id: [],
        name: ['', Validators.required],
        schoolId: ['', Validators.required]
      });
    } else {
      this.formChannel = this.formBuilder.group({
        id: [],
        name: ['', Validators.required],
        schoolId: [this.userService.getUserSchool()]
      });
    }

    if (this.userService.isAdmin()) 
    {
      this.getSchoolSelection();
    } 
    else if (this.userService.isGeneralCoordinator()) 
    {
      this.getSchoolSelection(this.userService.getUserSchool());
    }
    if(this.route.snapshot.url[2] && this.list == this.route.snapshot.url[2].path){ this.openList(); }

    this.expandExpansionPanel();

    this.channelService.emitChannels.subscribe((data)=>{

      if(data.name === "channel")   this.channel = data.values; 
      
    })
    
  }

  createChannels(){
    const channel = this.formChannel.getRawValue() as Channel; 
    ManageChannelFactory.newCreateChannel(this.channelService,this.alertService, channel).execute();
    this.formChannel.reset();
  }

  listChannels(schoolId:number){
    const res = ManageChannelFactory.newListChannel(this.channelService,this.alertService, schoolId).execute();
  }

  openList(){
    this.openChannelList = true;
    if(this.userService.isGeneralCoordinator()){
      this.getAllChannelsBySchool(this.userService.getUserSchool())
    } else if(this.userService.isUnitCoordinator()){
      this.getAllChannelsByUnit(this.userService.getUserUnit(), this.userService.getUserSchool());
    }
  }

  getAllChannelsBySchool(schoolId: number){
    this.channelService.getAllChannelsBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error){
          let channels = response.data as Channel[];
          this.channelController = ManageChannelFactory.newManageChannelController(this.channelService, this.alertService,  channels );
          this.channels = this.channelController.getAllChannelsBySchool();
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));
  }

  getAllChannelsByUnit(unitId: number, schoolId: number){
    this.channelService.getAllChannelsByUnit(unitId, schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error){
          let channels = response.data as Channel[];
          console.log(channels);
          this.channelController = ManageChannelFactory.newManageChannelController(this.channelService, this.alertService,  channels );
          this.channels = this.channelController.getAllChannelsByUnit();
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));
  }

  // Input envia o dado
  editChannel(channel: ChannelModel){ 
    this.channelEdit = channel ;
    this.openChannelList =false;
  }

  // Output que recebe a alteração
  channelSave(channelEdit:ChannelModel){
    this.channelController.updateChannel(channelEdit);
    this.channelEdit = null;
    this.openChannelList =true;
  }

  // Output que recebe a alteração
  backListChannels(event:any){
    this.channelEdit = null;
    this.openChannelList =true;
  }

  expandExpansionPanel() {
    setInterval(() => { 
      this.expandPanel = 1
    }, 90);
  }

  public removeChanel(channelId:number){
    if (confirm('Deseja realmente excluir o canal?')) {
      this.channelService.removeChannel(channelId).subscribe(res => {

          const response = res.body as ResponseApi;

          if (!response.error) {
              this.channels = this.channelController.removeChannel(channelId);
              this.alertService.success("Plano removido com sucesso!");
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao remover o canal. Verifique a conexão e tente novamente'));
    }
  } 
  
  getSchoolSelection(schoolId?:number)
  {
    this.schoolService.getSchoolSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.schools = response.data as SchoolSelect[];
        else {
          this.schools = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente!'));
  }
}
