<div class="container pt-5 mb-5 text-center">

    <div *ngIf="method == 'cc'">
        <mat-icon color="accent" style="width: 150px; height: 150px; transform: rotate(-90deg);" svgIcon="pay-card">
        </mat-icon>
        <h1 class="mb-4 mt-3 text-color-primary">OBRIGADO!</h1>
        <h4>Seu pedido está sendo processado.</h4>

        <p class="mt-2">As transações feitas com cartão de crédito levam apenas alguns instantes para serem liberadas.
            <br /> Você será notificado quando o seu plano for liberado.</p>

        <button mat-raised-button color="accent" class="mt-4">Voltar ao inicio</button>
    </div>

    <div *ngIf="method == 'bo'">
        <mat-icon color="accent" style="width: 150px; height: 150px; transform: rotate(-90deg);" svgIcon="pay-money">
        </mat-icon>
        <h1 class="mb-4 mt-3 text-color-primary">OBRIGADO!</h1>
        <h4>Seu pedido está sendo processado.</h4>

        <p class="mt-2">A liberação do seu plano pode levar até 3 dias úteis após o pagamento do boleto. <br /> Você
            será notificado quando o seu plano for liberado. </p>

        <div class="mt-5">
            <font class="text-color-primary" weight="bold">CÓDIGO DE BARRAS: </font>
            <font class="text-color-accent" size="4">{{boCode}}</font>
        </div>

        <div>
            <button mat-raised-button color="accent" class="mt-3" (click)="dowloadBoleto()">DOWNLOAD DO BOLETO
                (PDF)</button>
        </div>
        <div class="mt-4">
            <a class="text-color-primary">Voltar ao inicio</a>
        </div>
    </div>

    <div *ngIf="!showPayments">
        <mat-icon color="accent" style="width: 150px; height: 150px; transform: rotate(-90deg);" svgIcon="pay-money">
        </mat-icon>
        <h1 class="mb-4 mt-3 text-color-primary">OBRIGADO!</h1>
        <h4>Seu pedido está sendo processado.</h4>

        <p class="mt-2">A liberação do seu plano pode levar até 3 dias úteis após o pagamento. <br /> Você será
            notificado quando o seu plano for liberado. </p>

        <div>
            <button mat-raised-button color="accent" class="mt-3" (click)="showPayments = true">Minhas compras</button>
        </div>

    </div>

    <div *ngIf="showPayments">
        <app-payment-payments></app-payment-payments>
    </div>

</div>