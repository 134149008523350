import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Mock } from '../../mock/mock';
import { MockService } from '../../mock/mock.service';
import { UserService } from '../../user/user.service';
import { Class } from '../school/class';
import { School } from '../school/school';
import { SchoolService } from '../school/school.service';
import { Unit } from '../school/unit';
import { Student } from '../student/student';
import { StudentService } from '../student/student.service';
import { StudyPlan } from '../study-plan/study-plan';
import { StudyPlanService } from '../study-plan/study-plan.service';
import { EditMockStudentComponent } from './edit-mock-student/edit-mock-student.component';

@Component({
  selector: 'app-mock-student',
  templateUrl: './mock-student.component.html',
  styleUrls: ['./mock-student.component.scss']
})
export class MockStudentComponent implements OnInit {


  public schools: School[];
  public loading: boolean;
  public plans: StudyPlan[];
  public mocks: Mock[];
  public selectedMocks: Mock[];
  public units: Unit[];
  public classes: Class[];
  public selectedStudents: Student[];
  public students: Student[]
  public addMockForm: FormGroup;
  public saving: boolean;
  public editMode: boolean;
  public classId: number;
  public isUnitCoordinator: boolean

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public student: Student,
  @Optional() public dialogRef: MatDialogRef<EditMockStudentComponent>,
    private schoolService: SchoolService,
    private alertService: AlertService,
    public userService: UserService,
    private dialog: MatDialog,
    private studyPlanService: StudyPlanService,
    private mockService: MockService,
    private formBuilder: FormBuilder,
    private studentService: StudentService) { }

  ngOnInit() {
    this.editMode = false;
    this.loading = false;
    this.selectedMocks = [];
    this.selectedStudents = [];
    this.schools = [];
    this.isUnitCoordinator = false;
    this.addMockForm = this.formBuilder.group({
      schoolId: [''],
      planId: [''],
      mockId: [''],
      schoolIdStudent: [''],
      unitId: [''],
      classId: [''],
      studentsId: ['']
    })
    if(this.userService.isAdmin){
      this.getSchools();
    }
    if(this.userService.isGeneralCoordinator()){
      this.getPlans(this.userService.getUserSchool());
      this.getUnits(this.userService.getUserSchool());
    }
    if(this.userService.isUnitCoordinator()){
      this.isUnitCoordinator = true;
      this.getPlans(this.userService.getUserSchool());
      this.getClasses(this.userService.getUserUnit());
    }
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getPlans(schoolId?: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlanMocks(planId: number) {
    this.loading = true;
    this.mockService.getPlanMocks(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  addMockChip(mock: Mock) {
    const index = this.selectedMocks.indexOf(mock);

    if (index < 0) {
      this.selectedMocks.push(mock);
      this.addMockForm.get('mockId').reset();
    }
  }

  removeMockChip(mock: Mock) {
    const index = this.selectedMocks.indexOf(mock);

    if (index >= 0) {
      this.selectedMocks.splice(index, 1);
    }
  }

  addStudentChip(student: Student) {
    const index = this.selectedStudents.indexOf(student);

    if (index < 0) {
      this.selectedStudents.push(student);
      this.addMockForm.get('studentsId').reset();
    }
  }

  removeStudentChip(student: Student) {
    const index = this.selectedStudents.indexOf(student);

    if (index >= 0) {
      this.selectedStudents.splice(index, 1);
    }
  }

  getUnits(schoolId: number) {

    this.loading = true;
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente')
      });
  }

  openList(){
    this.students = [];
    this.addMockForm.reset();
    this.editMode = true;
  }

  getClasses(unitId: number) {
    this.loading = true;
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente')
      });
  }

  getStudents(classId: number) {
    this.loading = true;
    this.studentService.getStudents(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.students = response.data as Student[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getStudentsMocksByClass(classId: number) {
    this.loading = true;
    this.studentService.getStudentsMocksViewByClass(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.students = response.data as Student[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  saveStudentsPlans() {
    if (this.selectedMocks[0] && this.selectedStudents[0]) {
      this.loading = true
      this.studyPlanService.saveStudentsPlans(this.selectedMocks, this.selectedStudents)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Dados cadastrados com sucesso');
            this.addMockForm.reset();
            this.selectedMocks = [];
            this.selectedStudents = [];
            this.loading = false;
          }
          else {
            this.alertService.error(response.error);
            this.loading = false;
          }
        }, err => {
          this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
          this.loading = false
        })
    }
    else{
      this.alertService.error('selecione ao menos um simulado e um estudante');
    }
  }

  buttonBack(){
    this.editMode = false;
  }

  editStudent(student: Student) {
    const dialogRef = this.dialog.open(EditMockStudentComponent, {
      minWidth: '60vw',
      data: student
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getStudentsMocksByClass(this.classId);
      }
    });
  }

}