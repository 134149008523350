import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { ForumComment } from '../../comment/forum-comment';
import { ForumService } from '../../forum.service';

@Component({
  selector: 'app-forum-moderated',
  templateUrl: './forum-moderated.component.html',
  styleUrls: ['./forum-moderated.component.scss']
})
export class ForumModeratedComponent implements OnInit {

  public API = environment.ApiUrl;
    public BaseUrl = environment.baseUrl;
    public reply: boolean;
    public loading: boolean;
    public totalBlockedComments: number;

    // tslint:disable-next-line: max-line-length
    @Input() comment: ForumComment;
    @Input() isReply = false;
    @Input() isForumManagement = false;
    @Output() addEvent = new EventEmitter();

    constructor(
        private forumService: ForumService,
        public userService: UserService,
        private alertService: AlertService) { }

    ngOnInit() {
        this.comment.image = !this.comment.image
            ? this.API + '/store/students/avatar.png'
            : this.comment.image;

        this.comment.name = !this.comment.name
            ? 'Anônimo'
            : this.comment.name;
    }

    like() {

        if (this.comment.liked) {
            return;
        } else
            if (this.comment.disliked) {
                this.comment.dislike--;
            }

        this.comment.disliked = false;
        this.comment.liked = true;
        this.comment.like++;

        this.forumService.like(this.comment.id, this.userService.getUserId())
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (response.error) {
                    this.comment.liked = false;
                    this.comment.like--;
                }
            }, err => this.comment.liked = false);
    }

    dislike() {

        if (this.comment.disliked) {
            return;
        } else
            if (this.comment.liked) {
                this.comment.like--;
            }

        this.comment.liked = false;
        this.comment.disliked = true;
        this.comment.dislike++;

        this.forumService.dislike(this.comment.id, this.userService.getUserId())
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (response.error) {
                    this.comment.disliked = false;
                    this.comment.dislike--;
                }
            }, err => this.comment.disliked = false);
    }

    isMyComment() {
        // tslint:disable-next-line: triple-equals
        return this.comment.userId == this.userService.getUserId() ? true : false;
    }

    enableComment(commentId: number) {
        this.loading = true;
        this.forumService.enableComment(commentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.comment.active = true;
                }

                this.loading = false;
            });
    }

    disableComment(commentId: number) {
        this.loading = true;
        this.forumService.disableComment(commentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.comment.active = false;
                }

                this.loading = false;
            });
    }

    getTotalBlockedComments(){
        this.forumService.getTotalBlockedComments()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.totalBlockedComments = response.data as number;
            }
            else{
                this.alertService.error(response.error);
            }
        })
    }

    replyEvent(comment: ForumComment) {
        this.reply = false;
        this.comment.comments.push(comment);
    }

    deleteEventCallback(index: number) {
        this.comment.comments.splice(index, 1);
    }

    openVideo(videoId: number) {
        window.open(`${this.BaseUrl}/video/watch/${videoId}`, '_blank');

    }

    aproveComment(commentId : number){
      this.forumService.aproveComment(commentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          this.alertService.success('comentário aprovado');
          this.addEvent.emit();
        }
        else{
          this.alertService.error(response.error);
        }
      })
    }

    deleteComment(commentId: number){
      this.forumService.delete(commentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          this.alertService.success('Comentário excluido com sucesso!');
          this.addEvent.emit();
        }
        else{
          this.alertService.error(response.error);
        }
      })
    }
}
