import { SchoolReport } from './../external-integration/interfaces';
import { Component, OnInit } from '@angular/core';
import { ExportToCSV } from '@molteni/export-csv';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { Student } from '../../student/student';
import { StudentService } from '../../student/student.service';
import { IntegrationsService } from '../integrations.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { forEach } from '@angular-devkit/schematics';

@Component({
  selector: 'app-school-report',
  templateUrl: './school-report.component.html',
  styleUrls: ['./school-report.component.scss']
})
export class SchoolReportComponent implements OnInit {
  

  public loading: boolean;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public students: Student[];
  private admUpdate$: Subscription;
  public objectToExport: any[];
  public showButton: boolean;
  public report: SchoolReport[];
  public school: number;
  
  constructor(private integrationsService: IntegrationsService,
    private schoolService: SchoolService,
    public userService: UserService,
    private alertService: AlertService,
    private adminService: AdminService,
    private studentService: StudentService,
    private exportTXT:ExportToCSV) { }

  ngOnInit() {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }
  init(){
    this.getSchools();

  }
  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
              this.schools = response.data as School[];
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  open(){
    this.showButton = true;
  }

  downloadSchoolReport(){
    this.integrationsService.getSchoolReport(this.school)
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
              this.report = response.data as SchoolReport[];
              this.generateExcel();
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao buscar o relatório. Verifique a conexão e tente novamente'));
  }

  generateExcel(){
    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet("Employee Data");

    worksheet.addRow(["Relatório Código Externo por Escola"]);

    worksheet.addRow([" "]);

    let header = ["Escola", "Unidade", "Turma", "Aluno", "Código"]
    worksheet.addRow(header);
    
    let body =  this.report as any[];

    body.forEach(element => {
        element.forEach(repo => {

          let row = Object.values(repo);
          worksheet.addRow(row);
        });
    });

    let fname = "Relatório Código Externo por Escola"

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
    this.loading = false;
  }
}
