import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
 
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
 
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
 
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
 
import { UserService } from 'src/app/modules/user/user.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { Folder } from '../../repository/folder/folder';
import { takeUntil } from 'rxjs/operators';
import { Permission } from 'src/app/modules/repository/permission';
import { School } from '../../school/school';
import { Unit } from '../../school/unit';
import { Class } from '../../school/class';
import { Teacher } from '../../school/create-teacher/teacher';
import { SchoolService } from '../../school/school.service';
import { SyncService } from '../sync.service';
@Component({
  selector: 'app-teacher-sync',
  templateUrl: './teacher-sync.component.html',
  styleUrls: ['./teacher-sync.component.scss']
})
export class TeacherSyncComponent implements OnInit, OnDestroy {

  public createTeacherForm: FormGroup;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public teachers: any;
  public showTeachersList: boolean;
  public editMode: boolean;
  public selectedClasses: Class[];
  public loading: boolean;
  public folders: Folder[];
  public filtering: boolean;
  public selectedFolders: Folder[];
  public foldersModel: number[];
  public folderFilter = new FormControl();
  public filteredFolders: BehaviorSubject<Folder[]> = new BehaviorSubject<Folder[]>(null);
  public permissions: Permission[];
  protected onDestroy = new Subject<void>();
  private admUpdate$: Subscription;
  public synchronized: boolean = false;
  public unsynchronized: boolean = false; 
  public showUnitsList;
  syncTeachers: any[];
  synteacher: any;
  syncProgress: { text: string; progress: number; };
  removeMode: boolean; 
  synTeacher: any; 
  actualClassId: number;
  syncClassesTeacher: any[];
  unSyncClassesTeacher: any[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public teacher: Teacher,
    @Optional() public dialogRef: MatDialogRef<TeacherSyncComponent>,
    private dialog: MatDialog,
    private adminService: AdminService,
    private schoolService: SchoolService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private repositoryService: RepositoryService,
    public userService: UserService,
    public syncService: SyncService ) { }

  ngOnInit(): void {
   

    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.schools = [];
    this.selectedClasses = [];
    this.selectedFolders = [];
    this.getSchools();
    this.createTeacherForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.required],
      schoolId: ['', Validators.required],
      unitId: ['', Validators.required],
      classId: ['', Validators.required],
      permissionId: ['', Validators.required]
    });
    this.coordinationInit();
  
 
  }

  coordinationInit(editMode?: boolean) {

    if (this.userService.isGeneralCoordinator()) {
      this.createTeacherForm.get('schoolId').setValue(this.userService.getUserSchool());
      this.getUnits(this.userService.getUserSchool());
      editMode && this.getTeachers(this.userService.getUserSchool());
    }

    if (this.userService.isUnitCoordinator()) {
      this.createTeacherForm.get('schoolId').setValue(this.userService.getUserSchool());
      this.createTeacherForm.get('unitId').setValue(this.userService.getUserUnit());
      this.getClasses(this.userService.getUserUnit()); 
    }
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId: number) {
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente'));
  }

  getTeachers(classId: number) {
    this.actualClassId = classId;
    this.loading = true;
    this.syncService.getApiTeachers(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.teachers = response.data  ;
         //console.log(this.teachers);
        } else { 
          this.teachers = [];
          this.alertService.error(response.error);
         
        }

        this.loading = false;
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente');
      });
  }  

  remove(teacherId: number) {
    if (confirm('Deseja realmente remover o Professor?')) {
      this.schoolService.removeTeacher(teacherId , this.userService.getUserProfileId())
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Professor removido', 3);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o professor. Verifique a conexão e tente novamente'));
    }
  }

  getTeacherClasses(teacherId: number) {
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedClasses = response.data as Class[];
 
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  } 

  addClassChip(c: Class) {
    const index = this.selectedClasses.findIndex(cl => cl.id == c.id);
    if (index < 0) {
      this.selectedClasses.push(c);
      this.createTeacherForm.get('classId').reset();
    }
  }

  removeClassChip(c: Class) {
    const index = this.selectedClasses.indexOf(c);

    if (index >= 0) {
      this.selectedClasses.splice(index, 1);
    }
  }
 

  addFolderChip(folder: Folder) {
    const index = this.selectedFolders.findIndex(t => t.id == folder.id);

    if (!index) {
      this.selectedFolders.push(folder);
    }
  }

  removeFolderChip(folder: Folder) {
    const index = this.selectedFolders.findIndex(t => t.id == folder.id);

    if (index >= 0) {
      this.selectedFolders.splice(index, 1);
      this.foldersModel = [...this.selectedFolders.map(d => d.id)];
    }
  }
 
  syncMode(teacher:any){
    this.editMode = true;
    this.removeMode = false;
    this.syncClassesTeacher = []; 
    this.synTeacher = teacher;
    //console.log(teacher);
    this.syncProgress = { text: "Parado 0%", progress: 0}; 
    
    this.synTeacher.unSyncClasses.forEach(element => {
      let teacheR = {  
          id:       this.synTeacher.id, 
          name:     this.synTeacher.name, 
          email:    this.synTeacher.email,  
          external_id: this.synTeacher.id, 
          external_classId: element.classExternal_id,  
          className: element.name,  
          unitId:   element.unitId, 
          external_unitId:element.unitIdExternal_id, 
          classId:   element.classId, 
          status: 0
      };  
      this.syncClassesTeacher.push(teacheR);
  });

    console.info(this.syncClassesTeacher);
   
  }

  unSyncMode(coord:any){
      this.removeMode = true;
      this.editMode = false;
      this.unSyncClassesTeacher = [];
 
      this.synTeacher = coord;

      this.syncProgress = { text: "Parado 0%", progress: 0};

      this.synTeacher.syncClasses.forEach(element => {
          let teacheR = {  
              id:       this.synTeacher.id, 
              name:     this.synTeacher.name, 
              email:    this.synTeacher.email,  
              external_id: this.synTeacher.id, 
              external_classId: element.classExternal_id, 
              className: element.name,  
              unitId:   element.unitId, 
              external_unitId:element.unitIdExternal_id, 
              classId:   element.classId, 
              status: 0
          };  
          this.unSyncClassesTeacher.push(teacheR);
      });  
  } 

  syncTeacher(teacher:any){
      
      let timer = 500;

      let total = 0;
      let slice = 100/teacher.length;
      let index = 0;

      teacher.forEach(element => { 

          setTimeout(() => {
              this.syncService.syncTeacher(element)
              .subscribe(res => {
                  const response = res.body as ResponseApi;

                  if (!response.error) {
                      total+=slice;
                      this.syncProgressUpdateOne(Math.floor(total));
                      const index =  this.syncClassesTeacher.indexOf(element);

                      if (index >= 0) {
                        this.syncClassesTeacher.splice(index, 1);
                      }  
                      
                  } else {
                      this.alertService.error(response.error);
                  }

                  this.loading = false;
              }, err => {
                  this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                  this.loading = false;
              });
          }, timer+=700);
  
          
      });
  }
  
  unSyncTeacher(teacher:any){

      let timer = 500; 
      let total = 0;
      let slice = 100/teacher.length;
      let index = 0;
  
          
          this.syncService.unSyncTeacher(teacher[0].external_id)
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                  total+=slice;
                  this.syncProgressUpdateOne(100);
                  this.unSyncClassesTeacher=[];

                  
                  
              } else {
                  this.alertService.error(response.error);
              }

              this.loading = false;
          }, err => {
              this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
              this.loading = false;
          }); 
 
  }
  unSyncTeacherClasses(teacher:any){

    let timer = 500; 
    let total = 0;
    let slice = 100/teacher.length;
    let index = 0;
    teacher.forEach(element => { 
        setTimeout(() => { 
        
            this.syncService.unSyncCoord(element.external_id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    total+=slice;
                    this.syncProgressUpdateOne(Math.floor(total));
                    /* const index =  students.indexOf(element);

                    if (index >= 0) {
                        students.splice(index, 1);
                    }  */
                    
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                this.loading = false;
            });
        
        }, timer+=500 );

        
    }); 
  }

    
  async syncProgressUpdate(size:number){
      let timer = 500;
      let total = 0;
      let slice = 100/size;

      for (let index = 0; index < size; index++) { 
          setTimeout(() => {
              if(index < size){
                  this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
              }else{
                  this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
              }  
              total+=slice;
          }, timer+=500);
      }
      
  }
  async syncProgressUpdateOne(total:number){

      if(total < 100){
          this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
      }else{
          this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
      }   
      
  }

}
