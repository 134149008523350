import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExportToCSV } from '@molteni/export-csv';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Simulated } from 'src/app/core/models/simulated';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { GenerateReport, MockDisciplines, Reports } from '../../integrations/external-integration/interfaces';
import { IntegrationsService } from '../../integrations/integrations.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { Student } from '../../student/student';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  
  public formGenerateReport: FormGroup;
  public classId: number;
  private admUpdate$: Subscription;
  public students: Student[];
  public loading: boolean;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public simulated: Simulated[];
  public disciplines: Discipline[];
  public plans: StudyPlan[];
  public objectToExport: any[];
  public mockDisciplines: MockDisciplines;
  public reports: Reports[];
  public nameDiscipline: any;
  public nameSchool: any;
  public externalCode: any;
  public show: boolean;
  public workBook: Workbook;

  constructor(private integrationsService: IntegrationsService,
    private schoolService: SchoolService,
    private alertService: AlertService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService,
    private exportTXT:ExportToCSV) { }

    exportToTXT = new ExportToCSV();

  ngOnInit() {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  init(){

    this.formGenerateReport = this.formBuilder.group({
        school: ['', Validators.required],
        plan: ['', Validators.required],
        simulated: ['', Validators.required],
        discipline: ['', Validators.required]
    });
    this.profileInit();
    this.show = false; 
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.getSchools();
    } else if (this.userService.isGeneralCoordinator()) {
        const schoolId = this.userService.getUserSchool();
        this.formGenerateReport.get('school').setValue(schoolId);
        this.getPlans(this.userService.getUserSchool());
    } 
  }

  downloadReport(){ 
    
    const name = this.nameDiscipline.name;
    
    var txt = ".txt";

    this.objectToExport = this.reports;
    
    const rows = this.objectToExport;
    
    
    let csvContent = "data:text/csv;charset=utf-8,";
    
    rows.forEach(function(rowArray) {
    
      let arr = Object.values(rowArray);
    
      let row = arr.join(" ");
      csvContent += row + "\r\n";  
    });

    /* for (let i = 0; i < rows.length; i++) {
      let row = rows[i].join(",");
      csvContent += row + "\r\n";
    } */
    
    var encodedUri = encodeURI(csvContent); 
    var a = document.createElement('a'); 
    a.href = encodedUri;
    a.download = name + txt;
    a.click(); 
    a.remove();
    this.loading = false;
  }

  generateXls() {
      let workbook = new Workbook();

      let worksheet = workbook.addWorksheet("Employee Data");

      worksheet.addRow(["Nome da escola :", this.nameSchool.name]);

      worksheet.addRow([" "]);


      let header = [ "Código do aluno", "Total Corretas"]
      let headerRow = worksheet.addRow(header);

      for (let x1 of this.reports) {
        let x2 = Object.keys(x1);
        let temp = [];
        for (let y of x2) {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
      }

      let fname = "Relatório Simulado (Somatória)"

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
  }

  getNameDiscipline(idDiscipline:number){        
    this.integrationsService.getNameDiscipline(idDiscipline)
    .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
            this.nameDiscipline = response.data;

        } else {
            this.alertService.error(response.error);
        }
    }, err => {
        this.alertService.error('Houve um erro ao buscar a disciplina. Verifique a conexão e tente novamente');
    });
  }
  getNameSchool(schoolId:number){        
    this.schoolService.getSchoolOne(schoolId)
    .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
            this.nameSchool = response.data;

        } else {
            this.alertService.error(response.error);
        }
    }, err => {
        this.alertService.error('Houve um erro ao buscar a escola. Verifique a conexão e tente novamente');
    });
  }
  async getReports(){
    const general = this.formGenerateReport.getRawValue() as GenerateReport;
    const idDiscipline = general.discipline;
    const schoolId = general.school;
    await this.getNameDiscipline(idDiscipline);    
    this.getNameSchool(schoolId);
    this.integrationsService.getReports(general)
    .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
            this.reports = response.data as Reports[];
        } else {
            this.alertService.error(response.error);
        }
    }, err => {
        this.alertService.error('Houve um erro ao buscar código e nota. Verifique a conexão e tente novamente');
    });
  }
  getPlans(schoolId?: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getDisciplinesSimulated(simulatedId: number) {
      this.integrationsService.getDisciplinesSimulated(simulatedId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
              this.mockDisciplines = response.data as MockDisciplines;
          } else {
            this.alertService.error(response.error);
          }    
        }, err => {
          this.alertService.error('Houve um erro ao buscar as disciplínas. Verifique a conexão e tente novamente');
        });
  }
  getSimulated(planId:number){
      this.integrationsService.getSimulated(planId)
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
              this.simulated = response.data as Simulated[];
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao buscar os simulados. Verifique a conexão e tente novamente'));
  }
  getSchools() {
  this.schoolService.getSchools()
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
              this.schools = response.data as School[];
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

}
