<div *ngIf="!showList" mat-dialog-content>
    <h6 *ngIf="editMode" class="mt-3 mb-3">Editar Materiais</h6>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-label>Nome</mat-label>
                <input matInput [(ngModel)]="materialName">
            </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="[1,2,3].indexOf(materialType) !== -1">
            <mat-form-field class="width-100">
                <mat-label>Data de Aplicação</mat-label>
                <input matInput [(ngModel)]="applyDate" [textMask]="{mask: maskService.date}">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-label>Tipo de material</mat-label>
                <mat-select [(ngModel)]="materialType">
                    <mat-optgroup *ngFor="let group of typesGroup" [label]="group.name">
                        <mat-option *ngFor="let type of group.types" [value]="type.id">
                            {{type.type}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="[1,2,3].indexOf(materialType) !== -1">
            <mat-form-field class="width-100">
                <mat-label>Data de Expiração</mat-label>
                <input matInput [(ngModel)]="expireDate" [textMask]="{mask: maskService.date}">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-label>Escola</mat-label>
                <mat-select [(ngModel)]="school" (selectionChange)="getFolders($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-label>Observação</mat-label>
                <input matInput [(ngModel)]="materialObservation">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-select [(ngModel)]="folder" placeholder="Pasta">
                    <mat-option *ngIf="!school">Selecione uma escola</mat-option>
                    <mat-option *ngFor="let folder of folders" [value]="folder.id">
                        <span [title]="folder.name">{{folder.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6 mt-5 text-center annex">
            <input class="center width-100" (change)="handleFileInput($event.target.files);" accept="" fullWidth status="primary"
                type="file" nbInput placeholder="Anexar Arquivo">
        </div>
    </div>

    <div class="text-center mt-5">
        <a *ngIf="material" class="center" mat-raised-button color="accent" [href]="material.file" target="_blank">
            Abrir arquivo <mat-icon>open_in_new</mat-icon>
        </a>
    </div>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary">Ver Materiais
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving"
                [disabled]="saving" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving"
            [disabled]="saving" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>
<div *ngIf="showList">

    <div class="row">
        <div class="col-md" *ngIf="userService.isAdmin()">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Escola</mat-label>
                <mat-select placeholder="Escola" (selectionChange)="getUnits($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md" *ngIf="(userService.isAdmin() || userService.isGeneralCoordinator())">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Unidade</mat-label>
                <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                        <span [title]="unit.name">{{unit.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md" *ngIf="!userService.isStudent()">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Turma</mat-label>
                <mat-select placeholder="Turma" (selectionChange)="getClassAllMaterials($event.value)"
                    [(ngModel)]="classId">
                    <mat-option *ngFor="let class of classes" [value]="class.id">
                        <span [title]="class.name">{{class.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <mat-list *ngIf="!loading" class="width-90 mt-3 center">

        <div mat-list-item class="row" *ngFor="let material of materials" style="min-height: 100px">
            <div class="col-4">
                {{material.name}}
            </div>
            <div class="col-4">
                {{material.teacher}}
            </div>
            <div class="col-2 text-right">
                <button (click)="edit(material)" nbButton size="tiny" outline status="primary">Editar</button>
            </div>
            <div class="col-2 text-right">
                <button size="tiny" (click)="remove(material.id)" status="danger" nbButton ghost>
                    <nb-icon icon="trash"></nb-icon>
                </button>
            </div>
        </div>

    </mat-list>
    <mat-spinner *ngIf="loading" class="center"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>