import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { TopicDisciplineService } from '../../topic-discipline.service';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  public loading: boolean;
  public planId: number;
  public disciplineId: number;
  public topics: any[];
  public disciplineName: string;
  public planName: string;
  public topicName: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutService,
    public topicDisciplineService: TopicDisciplineService,
    public alertService: AlertService,
    public userService: UserService,
  ) { }

  ngOnInit() {
    let n = Object(this.route.snapshot.queryParams);
    this.planName = n.planName;
    this.disciplineName = n.disciplineName;
    this.topicName = n.topicName;

    this.planId = Number(this.route.snapshot.paramMap.get('planId'));
    this.disciplineId = Number(this.route.snapshot.paramMap.get('disciplineId'));
    this.getDisciplineTags(Number(this.userService.getUserId()), this.disciplineId);
  }

  backPage(){
    this.router.navigateByUrl('topic/plan/' + this.planId +'/disciplines' + '?planName=' + this.planName + '&disciplineName=' + this.disciplineName);
  }

  selectTopic(topicId: number, name:string){
    this.router.navigateByUrl('topic/plan/' + this.planId +'/discipline/' + this.disciplineId + '/topic/' + topicId + '/videos' + '?planName=' + this.planName + '&disciplineName=' + this.disciplineName + '&topicName=' + name);
  }
  
  getDisciplineTags(userId: number, disciplineId:number) {
    this.loading = true;
    this.topicDisciplineService.getDisciplineTags(userId, disciplineId)
      .subscribe(res => {
        const response = res.body as any;
        if (!response.error) { this.topics= response.data as any[];
        } else { this.alertService.error(response.error); }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar as tags. Verifique a conexão e tente novamente'));
  }

}
