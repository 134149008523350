<div class="container mt-3 mb-3" *ngIf="comment.blockComment == 1">
    <div class="row">
        <div class="comment-img">
            <div [ngStyle]="{'background-image': 'url(' + comment?.image + ')'}" class="comment-box-header-image" [ngClass]="{'comment-img-reply': isReply}">
            </div>
        </div>
        <div class="comment-content" style="width: 80%">
            <span class="col-md-12 comment-videoName">{{comment?.videoName}}</span>
            <br>
            <span class="col-md-12 comment-name">{{comment?.name}}</span>
            <span class="col-md-12 comment-date" *ngIf="comment?.date">{{comment?.date | date : 'dd LLL, y '}}</span>
            <div class="col-md-12 comment-text">{{comment?.text}}</div>
            <div class="comment-buttons pl-1">
                <button class="hvr-grow comment-button" (click)="like()" mat-icon-button aria-label="Like">
                    <mat-icon *ngIf="!comment?.liked" class="comment-button material-icons-outlined">thumb_up</mat-icon>
                    <mat-icon *ngIf="comment?.liked" class="comment-button">thumb_up</mat-icon>
                </button>
                <span class="comment-like-numbers">{{comment?.like}}</span>
                <button class="hvr-grow comment-button" (click)="dislike()" mat-icon-button aria-label="Dislike">
                    <mat-icon *ngIf="!comment?.disliked" class="comment-button material-icons-outlined">thumb_down</mat-icon>
                    <mat-icon *ngIf="comment?.disliked" class="comment-button">thumb_down</mat-icon>
                </button>
                <button *ngIf="!isReply" class="mb-2" mat-flat-button (click)="reply = true">Responder</button>
            </div>
        </div>
        <!-- my comment -->
        <div class="content-options text-right" style="width: 10%" *ngIf="isMyComment()">
            <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="menu" class="right">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="delete()">
                        <mat-icon color="warn">delete</mat-icon>
                        <span>Excluir</span>
                    </button>
                </mat-menu>
            </ng-container>
        </div>
        <!-- Manage comment -->
        <div *ngIf="!isMyComment() && (!userService.isStudent())" class="content-options text-right" style="width: 10%">
            <button (click)="aproveComment(comment.id)" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Aprovar comentário">
                <mat-icon>done</mat-icon>
            </button>
            <mat-spinner class="right mr-1" *ngIf="loading" diameter="20"></mat-spinner>
            <!-- open comment video in forum management -->
            <button *ngIf="isForumManagement && !loading" (click)="deleteComment(comment.id)" class="excludeColor" mat-icon-button matTooltipClass="custom-tooltip" matTooltip="Excluir comentário">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <app-forum-add-comment *ngIf="reply" [commentToResponse]="comment.id" [videoId]="comment.videoId" (addEvent)="replyEvent($event)" style="margin-left: 6%; margin-top: -15px;"></app-forum-add-comment>
    </div>
    <div class="comment-replies">
        <app-forum-comment *ngFor="let replie of comment.comments; let i = index" [comment]="replie" [isReply]="true" (deleteEvent)="deleteEventCallback(i)">
        </app-forum-comment>
    </div>
</div>