<div *ngIf="!editMode">
    <form [formGroup]="addMockForm">
        <h5>Adicionar simulado do aluno</h5>
        <div class="row">

            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-label>Escola (obrigatório)</mat-label>
                    <mat-select (selectionChange)="getPlans($event.value)" formControlName="schoolId">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" #filterPlan (selectionChange)="getPlanMocks($event.value)" formControlName="planId">
                        <mat-option value="0">Todas</mat-option>
                        <mat-option *ngFor="let plan of plans " [value]="plan.id ">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Simulado" formControlName="mockId">
                        <mat-option *ngFor="let mock of mocks" [value]="mock.id" (click)="addMockChip(mock)">
                            {{mock.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-chip-list *ngIf="selectedMocks">
                    <mat-chip *ngFor="let mock of selectedMocks" selectable="true" removable="true" (removed)="removeMockChip(mock)">
                        {{mock.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <h5>Selecionar Alunos</h5>
        <div class="row">

            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-label>Escola (obrigatório)</mat-label>
                    <mat-select (selectionChange)="getUnits($event.value)" formControlName="schoolIdStudent">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="!isUnitCoordinator">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Unidade" formControlName="unitId" (selectionChange)="getClasses($event.value)">
                        <mat-option value="0">Todas</mat-option>
                        <mat-option *ngFor="let unit of units " [value]="unit.id ">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" formControlName="classId" (selectionChange)="getStudents($event.value)">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            {{class.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Alunos" formControlName="studentsId">
                        <mat-option *ngFor="let student of students" [value]="student.id" (click)="addStudentChip(student)">
                            {{student.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-chip-list *ngIf="selectedStudents">
                    <mat-chip *ngFor="let student of selectedStudents" selectable="true" removable="true" (removed)="removeStudentChip(student)">
                        {{student.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <div class="row mt-5">

            <div class=" col-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver estudantes
                    cadastrados</button>
            </div>

            <div class=" col-6 text-right width-100">
                <button (click)="saveStudentsPlans()" [nbSpinner]="loading" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning">Cadastrar</button>
            </div>

        </div>
    </form>
</div>

<div *ngIf="editMode">
    <h4>Ver Alunos</h4>
    <div class="row">

        <div class="col-md-4" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-label>Escola (obrigatório)</mat-label>
                <mat-select (selectionChange)="getUnits($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="!isUnitCoordinator">
            <mat-form-field class="width-100">
                <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                    <mat-option value="0">Todas</mat-option>
                    <mat-option *ngFor="let unit of units " [value]="unit.id ">
                        {{unit.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="width-100">
                <mat-select placeholder="Turma" [(ngModel)]="classId" (selectionChange)="getStudentsMocksByClass($event.value)">
                    <mat-option *ngFor="let class of classes" [value]="class.id">
                        {{class.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <nb-list>
        <nb-list-item *ngFor="let student of students">
            <div class="row">
                <div class="col-md-10">{{student.name}}</div>
                <div class="col-md-2">
                    <button (click)="editStudent(student)" nbButton size="tiny" outline status="primary">
                        Editar
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="text-right width-100">
        <button (click)="buttonBack()" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>