import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Student } from './student';
import { CSVStudents } from './csv-students';
import { ResponseApi } from 'src/app/core/models/response-api';
import { map, catchError } from 'rxjs/operators';
import { Payer } from '../payment/paying/payer';
import { AlertService } from 'src/app/shared/services/alert.service';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StudentService {

    private API = environment.ApiUrl;

    constructor(
        private http: HttpClient,
        private alertService: AlertService) { }

    createStudent(student: Student) {
        return this.http.post(this.API + '/student/createStudent', student, { observe: 'response' });
    }

    addStudents(students: CSVStudents) {
        return this.http.post(this.API + '/student/addStudentsCsv', students, { observe: 'response' });
    }

    getStudents(classId?: number,) {
        return this.http.post(this.API + '/student/getStudents', { classId }, { observe: 'response' });
    }

    getStudentsMocksViewByClass(classId?: number,) {
        return this.http.post(this.API + '/student/getStudentsMocksViewByClass', { classId }, { observe: 'response' });
    }

    getMocksStudentAssociated(userId?: number,) {
        return this.http.post(this.API + '/student/getMocksStudentAssociated', { userId }, { observe: 'response' });
    }

    getStudentsByNameOrEmail(name?: string, email?: string) {
        return this.http.post(this.API + '/student/getStudent', { name, email }, { observe: 'response' });
    }

    getLostStudent(name?: string, email?: string, schoolId?: number, unitId?: number) {
        return this.http.post(this.API + '/student/getLostStudent', { name, email, schoolId, unitId }, { observe: 'response' });
    }

    removeStudent(studentId: number , userProfile: number) {
        return this.http.post(this.API + '/student/removeStudent', { studentId , userProfile }, { observe: 'response' });
    }

    saveStudentChanges(student: Student) {
        return this.http.post(this.API + '/student/saveStudentChanges', student, { observe: 'response' });
    }

    disableStudent(studentId: number) {
        return this.http.post(this.API + '/student/disableStudent', { studentId }, { observe: 'response' });
    }
    enableStudent(studentId: number) {
        return this.http.post(this.API + '/student/enableStudent', { studentId }, { observe: 'response' });
    }

    getStudentsByNameOrEmailV2(name?: string, email?: string) {
        return this.http.post(this.API + '/student/getStudent', { name, email })
            .pipe(
                map((res: ResponseApi) => {
                    if (!res.error) {
                        return res.data as Payer[];
                    } else {
                        this.alertService.error(res.error);
                    }
                }),
                catchError(err => {
                    this.alertService.error('Houve um erro ao carregar os estudantes');
                    return throwError(err);
                })
            );
    }

}
