import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ebook, eBookPlans} from '../admin/flip-book-admin/flipBook';
import { StudyPlansSelect } from '../admin/study-plan/study-plan';

@Injectable({
  providedIn: 'root'
})
export class FlipBookService {

  private API = environment.ApiUrl;
  private planName = new BehaviorSubject<String>(null);

  constructor(private http: HttpClient) { }
  // trocar flipbook para ebook
  CreateEBook(book: Ebook, plans: StudyPlansSelect[]) {
    return this.http.post(this.API + '/ebook', {book, plans}, { observe: 'response' });
  }

  setNamePlan(planName: string){
    this.planName.next(planName);
  }

  getNamePlan() {
    return this.planName.asObservable();
}

  getEbooks(planId : number) {
    return this.http.post(this.API + '/ebook/getebookList' , {planId}, { observe: 'response' });
  }

  getEbookOne(ebookId : number) {
    return this.http.get(this.API + '/ebook/getEbookOne/'+ebookId , { observe: 'response' });
  }

  DeleteEbook(ebookId : number) {
    return this.http.delete(this.API + '/ebook/'+ebookId, { observe: 'response' });
  }

  getEbookPlans(ebookId : number) {
    return this.http.get(this.API + '/ebook/plans/getEbookPlans/'+ebookId , { observe: 'response' });
  }

  updateEbook(ebook: Ebook, plans: StudyPlansSelect[]) {
    return this.http.post(this.API + '/ebook/update', {ebook, plans}, { observe: 'response' });
  }

  setLike(userId: number , ebookId: number , like:boolean) {
    return this.http.post(this.API + '/ebook/setLike' , {userId , ebookId , like}, { observe: 'response' });
  }

  setDeslike(userId: number , ebookId: number , deslike:boolean) {
    return this.http.post(this.API + '/ebook/setDeslike' , {userId , ebookId , deslike}, { observe: 'response' });
  }

  getUserLikeDeslike(userId: number , ebookId: number) {
    return this.http.get(this.API + '/ebook/getUserLikeDeslike/'+userId+'/'+ebookId, { observe: 'response' });
  }

  setEbookComment(userId: number , ebookId: number , comment: string) {
    return this.http.post(this.API + '/ebook/setEbookComment' , {userId , ebookId , comment}, { observe: 'response' });
  }

  getEbookComments(ebookId: number) {
    return this.http.get(this.API + '/ebook/getEbookComments/'+ebookId , { observe: 'response' });
  }

  deleteEbookComment(commentId: number) {
    return this.http.delete(this.API + '/ebook/deleteEbookComment/'+commentId , { observe: 'response' });
  }

  getChannelEbook(channelId : number) {
    return this.http.get(this.API + '/channel/ebook?ebookId='+channelId , { observe: 'response' });
  }
}
