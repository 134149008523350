import { Optional } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbDialogService } from '@nebular/theme';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AdminService } from '../../admin/admin.service';
import { TaskStudentService } from '../task-student.service';
import { UserService } from '../../user/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { AnimationOptions } from 'ngx-lottie';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { RedactionService } from '../../redaction/redaction.service';
import { WriteTaskDialogComponent } from '../write-task-dialog/write-task-dialog.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

/*interface Task {
  id: number;
  classId: number;
  name: string;
  date: string;
  status: string;
}*/

interface Task {
  taskId: number,
  classId: number,
  taskName: string,
  taskDate: string,
  usuario_nome: string,
  teacherId: number,
  className: string,
  finalDate: string,
  taskDescription: string,
  taskLink: string
}


@Component({
  selector: 'app-pending-tasks',
  templateUrl: './pending-tasks.component.html',
  styleUrls: ['./pending-tasks.component.scss']
})
export class PendingTasksComponent implements OnInit {

  public tasks: Task[];
  public editMode: boolean;
  public showButton: boolean;
  public openOneTask: boolean;
  public taskText: string;
  public channelId: number;
  public taskTextParagraphs: string[];

  public formTask: FormGroup;
  public loading: boolean;
  public file: any;
  public fileName: string;
  public extension: string;
  public taskName: string

  public displayedColumns: string[];
  public taskDataSource: MatTableDataSource<Task>;
  public lottieOptions: AnimationOptions;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public task: Task,
    @Optional() public dialogRef: MatDialogRef<PendingTasksComponent>,
    private dialog: MatDialog,
    public taskStudentService: TaskStudentService,
    public matInputModule: MatInputModule,
    public userService: UserService,
    public matFormFieldModule: MatFormFieldModule,
    public maskService: MaskService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private adminService: AdminService,
    public layoutService: LayoutService,
    private nbDialogService: NbDialogService,
    private redactionService: RedactionService,) { }

  ngOnInit() {
    this.getChannelId();
    this.emptyTasksLottieOptionsConfig();
    this.displayedColumns = [
      'name',
      'teacher',
      'date',
      'link',
    ];
    this.formTask = this.formBuilder.group({
      name: [''],
      extension: [''],
      file: [''],
    });

    this.taskStudentService.emitSentTask.subscribe((data) => {
      setTimeout(() => {
        this.getChannelId();
      }, 2000);

    });

    if (this.task) { this.initializeEditMode(); }
  }

  getChannelId(): void {
    this.channelId = Number(this.route.snapshot.paramMap.get('channelId'));
    if(this.channelId && this.channelId != undefined){
      this.getPendingChannel(this.channelId);
    }else{
      this.getPendingTasksStudent();
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const  urlParsed = event.urlAfterRedirects.split("/");
        const name = (urlParsed[2] == 'channel') ? 'channel' : null;
        const channel = (urlParsed && urlParsed[3])? Number(urlParsed[3]) : 0 ;
        if(urlParsed[1] == 'task-student'){
          if(channel > 0  && name == 'channel'){
            this.channelId = channel;
            this.getPendingChannel(this.channelId);
          }else{
            this.getPendingTasksStudent();
          }
        }
      }
    });
  }


  getPendingChannel(channelId: number) {
    this.loading = true;
    const studentId = this.userService.getUserId();
    const classId = this.userService.getUserClass();
    this.taskStudentService.getPendingChannel(channelId, studentId, classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.tasks = response.data as Task[];
          this.taskDataSource = new MatTableDataSource([...this.tasks]);
        } else {
          this.taskDataSource = new MatTableDataSource(null);
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar as tarefas pendentes do canal. Verifique a conexão e tente novamente'));

  }

  getPendingTasksStudent() {
    this.loading = true;
    const studentId = this.userService.getUserId();
    const classId = this.userService.getUserClass();
    this.taskStudentService.getPendingTasksStudent(studentId, classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.tasks = response.data as Task[];
          this.taskDataSource = new MatTableDataSource([...this.tasks]);
        } else {
          this.taskDataSource = new MatTableDataSource(null);
        }
        this.loading = false;

      }, err => this.alertService.error('Houve um erro ao carregar as tarefas pendentes. Verifique a conexão e tente novamente'));

  }

  handleFileInput(files: FileList): void {
    if (files && files[0]) {

      const file = files[0];
      this.extension = file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.file = reader.result;
        this.fileName = file.name;
      };
    }
  }

  clearUploadedFile() {
    this.file = null;
    this.fileName = null;
  }

  submitTask(taskId: number, taskName: string, studentName: string) {

    if (this.file && !this.taskText ) {
      this.loading = true;
      this.formTask.get('file').setValue(this.file);
      this.formTask.get('extension').setValue(this.extension);
      this.formTask.get('name').setValue(taskName);

      const studentId = this.userService.getUserId();
      const sendTasks = this.formTask.getRawValue();
      this.taskStudentService.submitTask(taskId, studentId, studentName, sendTasks)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.formTask.reset();
            this.alertService.success('Tarefa enviada!');
            this.dialogRef.close();            
            this.adminService.updateChields();
            this.taskStudentService.emitSentTask.emit('done');
          } else {
            this.alertService.error(response.error);
          }
          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao enviar a tarefa. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }

    if (!this.file && this.taskText && this.taskText.length > 0) {

      this.loading = true;
      const studentId = this.userService.getUserId();
      const sendTaskText = this.taskText;
      this.taskStudentService.submitTaskText(taskId, studentId, studentName, sendTaskText)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.formTask.reset();
            this.alertService.success('Tarefa enviada!');
            this.dialogRef.close();            
            this.adminService.updateChields();
            this.taskStudentService.emitSentTask.emit('done');
      
          } else {
            this.alertService.error(response.error);
          }
          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao enviar a tarefa. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }

    if (!this.file && !this.taskText ) {
      this.alertService.warning('Você precisa anexar um arquivo ou usar o editor para enviar a tarefa!');
    }
  }

  initializeEditMode() {
    this.editMode = true;
  }

  openFile(url: string) {
    window.open(url, '_blank');
  }

  openTask(task: Task) {
    this.dialog.open(PendingTasksComponent, {
      minWidth: '60vw',
      minHeight: '80vh',
      data: task,
      panelClass: 'task-dialog-bg'
    });
  }

  /* removeTaskSent(id: number){
    var indice = this.tasks.findIndex(item => item.taskId === id);
    let news = this.tasks.splice(indice, 1);
    this.taskDataSource = new MatTableDataSource([...news]);
  } */

  emptyTasksLottieOptionsConfig() {
    this.lottieOptions = {
      path: 'assets/lotties/empty.json',
      loop: true,
      autoplay: true
    };
  }

  openWriteTask() {

    const breakLine = "\n";

    this.nbDialogService.open(WriteTaskDialogComponent, { closeOnBackdropClick: false })
        .onClose.subscribe((textSplited: string[]) => {
            if(textSplited && textSplited.length > 0) {
              this.taskTextParagraphs = textSplited;
              this.taskText = "";
              textSplited.forEach(paragraph => {
                this.taskText = this.taskText + paragraph + breakLine;
              });
            }
        });
  }

  editTaskText(taskText : string) {

    const breakLine = "\n";

    this.nbDialogService.open(WriteTaskDialogComponent, { closeOnBackdropClick: false, context: { taskText: taskText } })
        .onClose.subscribe((textSplited: string[]) => {
          if(textSplited && textSplited.length > 0) {
            this.taskTextParagraphs = textSplited;
            this.taskText = "";
            textSplited.forEach(paragraph => {
              this.taskText = this.taskText + paragraph + breakLine;
            });
          }
        });
  }

}
