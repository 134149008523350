import { UserSelect } from "../../../notifications/users";
import { PlanSelect } from "../../../rdo-manager/create-theme/theme";
import { ClassSelect } from "../../../school/class";
import { Channel, ClassRoomSelect, EbookSelect, TaskSelect, FolderSelect } from "../../channel";

 

export class ChannelModel{
    
    constructor(private   channel:Channel){
    
    }   


    /* Manage  of channel */
    public static newChannelModel(channel:Channel){
        return new ChannelModel(channel);
    }
    public getChannel(){
        return this.channel;
    }
    public setChannel(channel:Channel){
       this.channel = channel;
    }
    public setChannelName(channelName: string) {
        this.channel.name = channelName;
    }


    /* Manage Plans of channel */
    public setChannelPlans(plans:any){
         this.channel.plans = plans;
    }
    public addChannelPlans(plan:PlanSelect){
        if(typeof(this.channel.plans)=='number'){
            this.channel.plans = []
        }
        if(this.channel.plans.filter((p)=> p.id == plan.id).length == 0) {
            this.channel.plans.push(plan);
        }
    }
    public removeChannelPlans(plan:PlanSelect){
        this.channel.plans = this.channel.plans.filter((p)=> p.id !== plan.id) 
    } 
    public getChannelPlans(){
        return this.channel.plans || [];
    } 

    /* Manage Folders of channel */
    public setChannelFolders(folders:FolderSelect[]){
        this.channel.folders = folders;
    }
    public addChannelFolders(folder:FolderSelect){
        if(typeof(this.channel.folders)=='number'){
            this.channel.folders = []
        }
        if(this.channel.folders.filter((f)=> f.id == folder.id).length == 0) {
            this.channel.folders.push(folder);
        }
    }
    public removeChannelFolders(folder:FolderSelect){
        this.channel.folders = this.channel.folders.filter((p)=> p.id !== folder.id);
    } 
    public getChannelFolders(){
        return this.channel.folders  || [];
    }

    /* Manage Ebooks of channel */
    public setChannelEbooks(ebooks:EbookSelect[]){
        this.channel.ebooks = ebooks;
    }
    public addChannelEbooks(ebook:EbookSelect){
        if(typeof(this.channel.ebooks)=='number'){
            this.channel.ebooks = []
        }
        if(this.channel.ebooks.filter((e)=> e.id == ebook.id).length == 0) {
            this.channel.ebooks.push(ebook);
        }
    }
    public removeChannelEbook(ebook:EbookSelect){
        this.channel.ebooks = this.channel.ebooks.filter((p)=> p.id !== ebook.id);
    } 
    public getChannelEbooks(){
        return this.channel.ebooks  || [];
    }

    /* Manage Tasks of channel */
    public setChannelTasks(tasks:TaskSelect[]){
        this.channel.tasks = tasks;
    }
    public addChannelTasks(task:TaskSelect){
        if(typeof(this.channel.tasks)=='number' || this.channel.tasks == undefined){
            this.channel.tasks = [];
        }

        if(this.channel.tasks.filter((e)=> e.id == task.id).length == 0) {
            this.channel.tasks.push(task);
            console.log(this.channel.tasks);
        }
    }
    public removeChannelTask(task:TaskSelect){
        this.channel.tasks = this.channel.tasks.filter((p)=> p.id !== task.id);
    } 
    public getChannelTasks(){
        return this.channel.tasks  || [];
    }
    

    /* Manage Classrooms of channel */
    public setChannelClassrooms(classrooms: ClassRoomSelect[]){
         this.channel.classrooms = classrooms ;
    }
    public addChannelClassRooms(classroom:ClassRoomSelect){
        if(typeof(this.channel.classrooms)=='number'){
            this.channel.classrooms = []
        }
        if(this.channel.classrooms.filter((c)=> c.id == classroom.id).length == 0) {
            this.channel.classrooms.push(classroom);
        }
    }
    public removeChannelClassrooms(classroom:ClassRoomSelect){
        this.channel.classrooms = this.channel.classrooms.filter((p)=> p.id !== classroom.id);
    } 
    public getChannelClassrooms(){
        return this.channel.classrooms  || [];
    }

    /* Manage Class of channel */
    public setChannelClasses(classes: ClassSelect[]){
        this.channel.classes = classes;
    }
    public addChannelClass(classe: ClassSelect){
        console.log(this.channel)
        if(typeof(this.channel.classes)=='number'){
            this.channel.classes = []
        }
        if(this.channel.classes.filter((c)=> c.id == classe.id).length == 0) {
            this.channel.classes.push(classe);
        }
        //console.log(this.channel)

    }
    public removeChannelClass(classe:ClassSelect){
        this.channel.classes = this.channel.classes.filter((p)=> p.id !== classe.id);
    } 
    public getChannelClasses(){
        return this.channel.classes  || [];
    }

    /* Manage Users of channel */
    public setChannelUsers(user: UserSelect[]){
        this.channel.users = user ;
    }
    public addChannelUser(user: UserSelect){
        if(typeof(this.channel.users)=='number'){
            this.channel.users = []
        }
        if(this.channel.users.filter((c)=> c.id == user.id).length == 0) {
            this.channel.users.push(user);
        }
    }
    public removeChannelUser(user:UserSelect){
        this.channel.users = this.channel.users.filter((p)=> p.id !== user.id);
    } 
    public getChannelUsers(){
        return this.channel.users  || [];
    }

    public setChannelRoutePlan(routePlan){
       this.channel.routePlan = routePlan;
    }
    public setChannelRouteFolder(routeFolder){
        this.channel.routeFolder = routeFolder;
    }
    public setChannelRouteLive(routeLive){
        this.channel.routeLive = routeLive;
    }
    public setChannelRouteEbook(routeEbook){
        this.channel.routeEbook = routeEbook;
    }

    public setChannelRouteTask(routeTask){
        this.channel.routeTask = routeTask;
    }

    public getChannelRoutes(){
        return this.channel.routes || [];
    }

    public getChannelRoutePlan(){
        return this.channel.routePlan || [];
    }
    public getChannelRouteFolder(){
        return this.channel.routeFolder || [];
    }
    public getChannelRouteLive(){
        return this.channel.routeLive || [];
    }
    public getChannelRouteEbook(){
        return this.channel.routeEbook || [];
    }
    public getChannelRouteTask(){
        return this.channel.routeTask || [];
    }
}