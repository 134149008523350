import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { from, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { AdminService } from '../../admin.service';
import { PlanSelect } from '../../rdo-manager/create-theme/theme';
import { ClassSelect } from '../../school/class';
import { SchoolSelect } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { UnitSelect } from '../../school/unit';
import { Certificate } from '../certificate';
import { CertificateService } from '../certificate.service';
import { Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import * as imageConversion from 'image-conversion';
import { MockService } from 'src/app/modules/mock/mock.service';
import { Mock } from 'src/app/modules/mock/mock';
import { AnimationOptions } from 'ngx-lottie';
import { Exercise } from '../../exercise/exercise';
import { VideoService } from 'src/app/modules/video/video.service';
import { Video } from 'src/app/modules/video/video';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';

@Component({
    selector: 'app-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
    public certificates: Certificate[];
    public displayedColumns: string[];
    public schools: SchoolSelect[];
    public units: UnitSelect[];
    public classes: ClassSelect[];
    public plans: PlanSelect[];
    public showform: boolean;
    public showList: boolean;
    public editMode: boolean;
    public choiceOption: boolean;
    public formMock: boolean;
    public formWatched: boolean;
    public formAll: boolean;
    public form: FormGroup;
    private admUpdate$: Subscription;
    public logo: any;
    public signature: any;
    public loading: boolean;
    public fileTempSignature;
    public fileTemp;
    public imgSrc;
    public mocks: Mock[];
    public planName: string;
    public planId: number;
    public totalMockQuestions: number;
    public totalVideosPlan: number;

    public lottieOptions: AnimationOptions;
    
    public inputs = [1];

    constructor(
        private certificateService: CertificateService,
        public layoutService: LayoutService,
        public userService: UserService,
        private alertService: AlertService,
        private schoolService: SchoolService,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private mockService: MockService,
        private videoService: VideoService,
        private disciplineService: DisciplineService
    ) {
       
    }

    ngOnInit(): void {
        this.admUpdate$ = this.adminService
            .getUpdateSubject()
            .subscribe(() => this.init());
    }

    init() {
        this.emptyLottieOptionsConfig();
        this.choiceOption = true;
        this.plans = [];
        this.checkWhoItIs();
        this.form = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            schoolId: [''],
            unitId: [''],
            classId: [''],
            planId: ['', Validators.required],
            planName: [''],
            mockId: [''],
            logo: ['', Validators.required],
            signature: ['', Validators.required],
            //time: ['', Validators.required],
            dados0: [''],
            dados1: [''],
            dados2: [''],
            type: [''],
            userIdCreated: [''],
            minimumMock: [''],
            minimumVideo: [''],
        });
    }

    moreLine() {
        if(this.inputs.length < 3){
            this.inputs.push(1);
        }
    }
    
    checkWhoItIs() {
        if (this.userService.isAdmin()) this.getSchoolSelection();

        if (this.userService.isGeneralCoordinator()){
            this.getUnitsSelection(this.userService.getUserSchool());
            this.getPlansSelection(this.userService.getUserSchool());
        }
        if (this.userService.isUnitCoordinator()){
            this.getSchoolSelection(this.userService.getUserSchool());
            this.getPlansSelection(this.userService.getUserSchool()); 
        }
    }

    getSchoolSelection(schoolId?: number) {
        this.schoolService.getSchoolSelection(schoolId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error)
                    this.schools = response.data as SchoolSelect[];
                else this.alertService.error(response.error);
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente!'
                )
        );
    }

    getUnitsSelection(schoolId?: number) {
        this.schoolService.getUnitsSelection(schoolId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error) this.units = response.data as UnitSelect[];
                else this.alertService.error(response.error);
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente!'
                )
        );
    }

    getPlansSelection(schoolId?: number) {
        this.schoolService.getPlansSelection(schoolId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error) this.plans = response.data as PlanSelect[];
                else this.alertService.error(response.error);
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'
                )
        );
    }

    getClassesSelection(unitId?: number) {
        this.schoolService.getClassesSelection(unitId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error)
                    this.classes = response.data as ClassSelect[];
                else this.alertService.error(response.error);
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!'
                )
        );
    }

    getMockByPlan(planId: number) {
        this.planId = planId;
        this.mockService.getMockByPlan(planId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error) this.mocks = response.data as Mock[];
                else this.alertService.error(response.error);
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar os simulados do plano. Verifique a conexão e tente novamente!'
                )
        );
    }

    choiceForm(choice: string) {
        // Formulário de aulas assistidas
        if (choice == 'formWatched') {
            this.formWatched = true;
            this.form.get('type').setValue(2);
        }
        // Formulário de simulado
        if (choice == 'formMock') {
            this.formMock = true;
            this.form.get('type').setValue(1);
        }
        // Formulário de simulado e aulas assistidas
        if (choice == 'formAll') {
            this.formAll = true;
            this.form.get('type').setValue(3);
        }
        this.showform = true;
        this.choiceOption = false;
        this.showList = false;
    }

    openRegistered() {
        this.showform = false;
        this.choiceOption = false;
        this.showList = true;
    }

    create() {
        this.plans.forEach((element) => {
            if (element.id == this.planId) {
                this.planName = element.name;
                console.log(element.name);
            }
        });

        if (this.userService.isGeneralCoordinator()) this.form.get('schoolId').setValue(this.userService.getUserSchool());
        if (this.userService.isUnitCoordinator()) this.form.get('schoolId').setValue(this.userService.getUserSchool());


        this.form.get('planName').setValue(this.planName);
        this.form.get('signature').setValue(this.fileTempSignature);
        this.form.get('logo').setValue(this.fileTemp);
        this.form.get('userIdCreated').setValue(Number(this.userService.getUserId()));

        if (!this.form.valid || !this.fileTemp || !this.fileTempSignature || this.plans.length == 0) {
            console.log(this.form);
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }
        this.loading = true;
        
        let certificate = this.form.getRawValue() as Certificate;
        this.certificateService.createCertificate(certificate).subscribe(
            (res) => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.alertService.success('Certificado cadastrado', 3);
                    this.cancelOrClear();
                    this.adminService.updateChields();
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            },
            (err) => {
                this.alertService.error(
                    'Houve um erro ao criar o certificado. Verifique a conexão e tente novamente'
                );
                this.loading = false;
            }
        );
    }

    cancelOrClear() {
        this.formMock = false;
        this.formWatched = false;
        this.formAll = false;
        this.showList = false;
        this.showform = false;
        this.choiceOption = true;
        this.form.reset();
        this.logo = null;
        this.signature = null;
    }

    cancelSignature() {
        this.signature = null;
    }

    cancelLogo() {
        this.logo = null;
    }

    getMockMaxQuestions(mockId: number) {
        let exercises: Exercise[];
        this.mockService.getMockExercises(mockId).subscribe((res) => {
            const response = res.body as ResponseApi;
            console.log(response);
            if (!response.error) {
                exercises = response.data as Exercise[];
                this.totalMockQuestions = exercises.length;
            }
            else this.totalMockQuestions = 0;
        });
    }

    getDisciplinesforVideos(planId : number){
        let disciplines : Discipline[];
        this.disciplineService.getDisciplinesSelectedByPlan(planId)
        .subscribe(res => {
            const response = res.body as ResponseApi;
            if(!response.error){
                disciplines = response.data as Discipline[];
                this.getTotalVideos(disciplines);
            }
        })
    }

    getTotalVideos(disciplines: Discipline[]) {
        let videos : Video[];
        this.videoService.getDisciplinesAllVideos(disciplines).subscribe((res) => {
            const response = res.body as ResponseApi;
            if (!response.error) {
                videos = response.data as Video[];
                this.totalVideosPlan = videos.length;
            }
            else this.totalVideosPlan = 0;
       });
    }

    /* setMaxValue() {
        let minimumMock = this.form.get('minimumMock').value;
        let minimumVideo = this.form.get('minimumVideo').value;
        if (this.totalMockQuestions > 0) {
            if (minimumMock > this.totalMockQuestions) this.form.get('minimumMock').setValue(this.totalMockQuestions);
        }
        if(this.totalVideosPlan > 0) this.form.get('minimumVideo').setValue(this.totalVideosPlan);
    } */

    backButton() {
        this.router.navigate(['admin/certificates']);
    }

    handleFileInputSignature(files: FileList): void {
        if (files && files[0]) {
            const fileSizeInMb = files[0].size / 1024 / 1024;
            if (fileSizeInMb > 2) {
                imageConversion
                    .compressAccurately(files[0], 1500)
                    .then((res) => {
                        this.signature = res;
                        const reader = new FileReader();
                        reader.readAsDataURL(this.signature);
                        reader.onload = (e) => {
                            this.fileTempSignature = reader.result;
                        };
                    });
            } else {
                this.signature = files[0];
                const reader = new FileReader();
                reader.readAsDataURL(this.signature);
                reader.onload = (e) => {
                    this.fileTempSignature = reader.result;
                };
            }
        }
    }

    handleFileInputLogo(files: FileList): void {
        if (files && files[0]) {
            const fileSizeInMb = files[0].size / 1024 / 1024;
            if (fileSizeInMb > 2) {
                imageConversion
                    .compressAccurately(files[0], 1500)
                    .then((res) => {
                        this.logo = res;
                        const reader = new FileReader();
                        reader.readAsDataURL(this.logo);
                        reader.onload = (e) => {
                            this.fileTemp = reader.result;
                            //this.showButton = true;
                        };
                    });
            } else {
                this.logo = files[0];
                const reader = new FileReader();
                reader.readAsDataURL(this.logo);
                reader.onload = (e) => {
                    this.fileTemp = reader.result;
                    //this.showButton = true;
                };
            }
        }
    }

    emptyLottieOptionsConfig() {
        this.lottieOptions = {
            path: 'assets/lotties/empty.json',
            loop: true,
            autoplay: true,
        };
    }
}
