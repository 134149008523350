<div class="container pt-5 pb-5 min-height-500">
    <mat-expansion-panel class="width-70 center" [expanded]="editMode">
        <mat-expansion-panel-header *ngIf="!editMode" >
            <span class="text-color-accent">Criar Plano</span>
        </mat-expansion-panel-header>
        <mat-expansion-panel-header *ngIf="editMode" class="text-color-accent">
            <span class="text-color-accent">Editar Plano</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <mat-dialog-content>
                <form [formGroup]="createStudyPlanForm">

                    <div class="dados-gerais mb-3"> <hr NOSHADE><span >Dados Gerais</span><hr NOSHADE> </div>


                    <!-- Name, TAG -->
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Nome" formControlName="name">
                            </mat-form-field>
                        </div>

                        <div class="col-md">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Tag" formControlName="tag">
                            </mat-form-field>
                        </div>
                        <div class="col-md">
                            <mat-form-field class="width-100">
                                <mat-label>Versão do calendário</mat-label>
                                <mat-select formControlName="version">
                                    <mat-option [value]="1">Versão 1</mat-option>
                                    <mat-option [value]="2">Versão 2</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="userService.isAdmin()">
                        <div class="col-md-6">
                            <mat-form-field class="width-100">
                                <mat-select formControlName="schools" placeholder="Selecione as escolas para esse plano" matTooltipClass="custom-tooltip" matTooltip="Selecione as escolas para esse plano">
                                    <mat-option (click)="addSchoolChip(school)" *ngFor="let school of schools" [value]="school.id">
                                        <span [title]="school.name">   {{school.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 right">
                            <div *ngIf="selectedSchools?.length > 0">
                                <mat-label class="mb-2">Escolas selecionadas</mat-label>
                                <mat-chip-list *ngIf="selectedSchools">
                                    <mat-chip *ngFor="let schoolChip of selectedSchools" selectable="true" removable="true" (removed)="removeSchoolChip(schoolChip)">
                                        {{schoolChip.name}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>

                    <!--<div class="buscar-disciplina-pelo-nome mb-3 mt-4"> <hr NOSHADE> <span >Buscar Disciplina pelo nome</span> <hr  NOSHADE> </div>
                     Buscar Disciplina pelo nome

                    <div class="row">
                      <div class="col-md-6">
                          <mat-form-field class="width-100">
                            <input #filterDisciplineNameRef formControlName="disciplineSearch" matInput placeholder="Buscar Disciplina pelo nome">
                            <mat-icon class="pointer" matSuffix (click)=" getDisciplinesByName(filterDisciplineNameRef.value )">search</mat-icon>
                          </mat-form-field>
                      </div>
                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="width-100">
                                    <mat-select placeholder="Disciplinas carregadas" (selectionChange)="addDisciplineChip($event.value)" multiple>
                                        <mat-option  *ngFor="let discipline of disciplinesByName " [value]="discipline.id">
                                            <span [title]="discipline.name">{{discipline.name}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>-->
                    <div class="planos-e-disciplinas mb-3 mt-4"> <hr NOSHADE> <span>Planos e Disciplinas</span> <hr NOSHADE> </div>
                    <div class="row" *ngIf="userService.isAdmin()">
                        <div class="col-md-12">
                            <mat-form-field class="width-100">
                                <mat-select (selectionChange)="getPlans($event.value)" placeholder="Selecione a escola para carregar os planos">
                                    <mat-option *ngFor="let school of schools" [value]="school.id">
                                        <span [title]="school.name">   {{school.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="width-100">
                                <mat-label>Selecione o plano para carregar as disciplinas</mat-label>
                                <mat-select (selectionChange)="getPlanDisciplinesFilter($event.value)">
                                    <mat-option [value]="0">
                                        Todas as disciplinas
                                    </mat-option>
                                    <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                        <span [title]="plan.name">   {{plan.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="width-100">
                                    <mat-select #folderSelect2 (selectionChange)="addDisciplineChip($event.value)" formControlName="disciplines" placeholder="Disciplinas" multiple>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Buscar disciplina" noEntriesFoundLabel="Disciplina não encontrada" [formControl]="disciplineFilter" [searching]="filteringMocks">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let discipline of filteredDisciplines | async" [value]="discipline">
                                            <span [title]="discipline.name">  {{discipline.name}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="selectedDisciplines?.length > 0">
                                <mat-label class="mb-2">Disciplinas selecionadas</mat-label>
                                <mat-chip-list *ngIf="selectedDisciplines">
                                    <mat-chip class="mat-standard-chip-style" *ngFor="let disciplineChip of selectedDisciplines" selectable="true" removable="true" (removed)="removeDisciplineChip(disciplineChip)">
                                        {{disciplineChip.name}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>


                    <div class="avaliacao-e-simulados mb-3 mt-4"> <hr NOSHADE> <span >Avaliação e Simulados</span> <hr NOSHADE> </div>
                    <!-- Disciplina, simulado -->
                    <div class="row mt-3" *ngIf="userService.isAdmin()">
                        <div class="col-md">
                            <div>
                                <mat-form-field class="width-100">
                                    <mat-select formControlName="filterschools" placeholder="Selecione as escolas para carregar suas avaliações e simulados">
                                        <mat-option (click)="getMocks()" [value]="0">
                                            Todos os simulados
                                        </mat-option>
                                        <mat-option (click)="getMocks(school.id)" *ngFor="let school of schools" [value]="school.id">
                                            <span [title]="school.name">  {{school.name}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                        <div class="col-md">
                            <div>
                                <nb-card [size]="'tiny'">
                                    <nb-card-body>
                                        <mat-list>
                                            <mat-list-item *ngFor="let mock of filteredMocks | async">
                                                <div class="row width-100">
                                                    <div class="col pt-2">
                                                        <span class="custom-chip bg-color-accent" *ngIf="simulated?.isTest">Avaliação
                                                    </span> {{mock.name}}
                                                    </div>
                                                    <div class="col-2 text-right">
                                                        <button (click)="selectMock(mock)" mat-icon-button color="primary">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                        </mat-list>
                                        <mat-spinner *ngIf="loading || searchingName" class="center mt-5"></mat-spinner>

                                    </nb-card-body>
                                </nb-card>
                                <i class="warnning-disciplines" *ngIf="!scholselected">
                                Aviso: Selecione
                                uma escola
                                para carregar lista de simulados da mesma
                            </i>
                            </div>
                            <div *ngIf="selectedSimulated?.length > 0">
                                <mat-label class="mb-2">Simulados e avaliações selecionados</mat-label>
                                <mat-chip-list *ngIf="selectedSimulated">
                                    <mat-chip *ngFor="let simulatedChip of selectedSimulated; let i = index" selectable="true" removable="true" (removed)="removeMockChip(i)">
                                        {{simulatedChip.name}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>

                    <!-- Início - Término - Vencimento -->
                    <div class="row mt-4">
                        <div class="col-md-2">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Data de início" [textMask]="{mask: maskService.date}" formControlName="beginDate">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-slide-toggle (change)="expireToggle($event)" [(ngModel)]="expireFixed" [ngModelOptions]="{standalone: true}" color="primary" class="mt-3">
                                <mat-icon matTooltip="Se selecionada esta opção, o vencimento será uma data fixa. Caso contrário será definido um número de meses">
                                    help
                                </mat-icon>
                                Vencimento fixo
                            </mat-slide-toggle>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Vencimento (data)" [textMask]="{mask: maskService.date}" formControlName="expireDate">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Vencimento (meses)" [textMask]="{mask: maskService.number}" formControlName="expireMonths">
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Numero de redações -->
                    <div class="row">
                        <div class="col-md">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Redações" [textMask]="{mask: maskService.number}" formControlName="redactions">
                                <button class="hvr-grow" (click)="addRedaction()" matSuffix mat-icon-button color="accent">
                                <nb-icon icon="plus-circle-outline" class="icon-mobile-plus-circle"></nb-icon>
                            </button>
                            </mat-form-field>
                        </div>
                        <div class="col-md">
                            <mat-form-field>
                                <mat-select placeholder="Tipos de redação" formControlName="redactionTypes" (selectionChange)="selectRedactionType($event.value)">
                                    <mat-option *ngFor="let redType of redactionTypes" [value]="redType">
                                        <span [title]="redType.name">  {{redType.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md">
                            <div *ngIf="selectedRedactionTypes?.length > 0">
                                <mat-label class="mb-2">Tipos de redação selecionados</mat-label>
                                <mat-chip-list>
                                    <mat-chip *ngFor="let redChip of selectedRedactionTypes; let i = index" selectable="true" removable="true" (removed)="removeSelectedRedactionType(i)">
                                        {{redChip.name}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>

                    <!-- Calendário -->
                    <div class="row">

                        <div class="col">
                            <mat-slide-toggle formControlName="calendarHide" color="primary" class="mt-3">
                                <mat-icon matTooltip="Define se o plano deve ser ocultado no dashboard">
                                    help
                                </mat-icon>
                                Ocultar do dashboard
                            </mat-slide-toggle>
                        </div>
                        <div class="col">
                          <mat-slide-toggle formControlName="disciplinesHide" color="primary" class="mt-3">
                              <mat-icon matTooltip="Define se o plano deve ser ocultado no menu 'Disciplinas'">
                                  help
                              </mat-icon>
                              Ocultar do menu Disciplinas
                          </mat-slide-toggle>
                        </div>
                        <div class="col">
                          <mat-slide-toggle formControlName="mockHide" color="primary" class="mt-3">
                              <mat-icon matTooltip="Define se o plano deve ser ocultado no menu 'Simulados'">
                                  help
                              </mat-icon>
                              Ocultar do menu Simulados
                          </mat-slide-toggle>
                        </div>
                        <div class="col">
                          <mat-slide-toggle formControlName="releaseCalendarVideosUpToday" color="primary" class="mt-3">
                            <mat-icon
                              matTooltip="Ao selecionar essa opção, vai liberar para o aluno,
                               os vídeos do calendário do início até o dia corrente">
                               help
                            </mat-icon>
                            Libera vídeos do calendário até dia atual
                          </mat-slide-toggle>
                        </div>

                    </div>

                    <!-- dias da semana -->
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Vídeos por dia" [textMask]="{mask: maskService.number}" formControlName="maxDayVideos">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="width-100">
                                    <mat-select formControlName="weekDays" placeholder="Dias da semana">
                                        <mat-option (click)="addWeekDaysChip(day)" *ngFor="let day of weekDays" [value]="day.id">
                                            {{day.day}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="selectedWeekDays?.length > 0">
                                <mat-label class="mb-2">Dias da semana selecionados</mat-label>
                                <mat-chip-list *ngIf="selectedWeekDays">
                                    <mat-chip *ngFor="let dayChip of selectedWeekDays" selectable="true" removable="true" (removed)="removeWeekDaysChip(dayChip)">
                                        {{dayChip.day}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>

                    </div>

                </form>

                <div *ngIf="!editMode" class="text-right width-100 mt-5">
                    <button class="hvr-grow" (click)="registerPlan()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving">Cadastrar</button>
                </div>

                <div *ngIf="editMode" class="row mt-5">
                    <div *ngIf="editMode" class="text-left col-6">
                        <button (click)="duplicatePlan()" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving">Duplicar</button>
                    </div>

                    <div *ngIf="editMode" class="text-right col-6">
                        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving">Salvar</button>
                    </div>
                </div>
            </mat-dialog-content>
        </ng-template>
    </mat-expansion-panel>

    <mat-accordion *ngIf="!editMode" class="center mt-5 mb-5">
        <mat-expansion-panel class="width-70 mt-4 center" [expanded]="editMode">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Planos</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>

                <div class="row">

                    <div class="col-md-12" *ngIf="userService.isAdmin()">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Escola *" (selectionChange)="getPlans($event.value)" [(ngModel)]="SelectedSchool">
                                <mat-option *ngFor="let school of schools" [value]=" school.id">
                                    <span [title]="school.name">  {{school.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <ng-container *ngIf="!loading">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Filtrar por nome" [(ngModel)]="filterName" (keyup)="applyFilter()">
                    </mat-form-field>

                    <nb-list class="width-90 mt-3 center">
                        <nb-list-item *ngFor="let plan of filteredPlans">
                            <div class="row">
                                <div class="col-8">
                                    {{plan.name}}
                                </div>
                                <div class="col-2 text-right">
                                    <button class="hvr-grow" (click)="editPlan(plan)" nbButton size="tiny" outline status="primary">Editar</button>
                                </div>
                                <div class="col-2 text-right">
                                    <button class="hvr-buzz-out" (click)="removePlan(plan)" color="warn" mat-icon-button>
                                        <nb-icon icon="trash"></nb-icon>
                                    </button>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </ng-container>
                <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>

</div>
