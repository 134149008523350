<div class="page-container p-pg page-container-mobile discipline font-poppins">
    <div class="row m-0">
        <div class="col-md-5 mt-2">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'left dash-container':!layoutService.isMobile, 'text-center': layoutService.isMobile, 'text-center width-80': layoutService.isMobile}">
                    <span class="page-title">DISCIPLINAS</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Selecione o plano</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Selecione o plano</h6>
                        <p class="fs-13 pb-0">Escolha o curso que você deseja estudar</p>
                    </div>
                </div>
                <!-- SELECT PLANO LISTA DESKTOP -->
                <div class="plan-list" *ngIf="!layoutService.isMobile">
                    <mat-list *ngIf="!loadingPlans">
                        <mat-list-item *ngFor="let plan of studyPlans" (click)="getEbooks(plan)">
                            <div class="plan-item mb-3" matRipple [ngClass]="{'bg-color-accent': planSelectedName == plan?.name}">
                                <div class="plan-icon">
                                    <mat-icon [color]="(planSelectedName == plan?.name) ? null : 'accent'">class</mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ plan?.name }}</strong>
                                </div>
                            </div>
                            <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                        </mat-list-item>
                    </mat-list>
                </div>
                <!-- SELECT PLANO LISTA MOBILE -->
                <div *ngIf="layoutService.isMobile" id="filter">
                    <mat-form-field class="width-100 m-0 p-0 form-field" *ngIf="!loadingPlans && !ebook">
                        <mat-select class="p-0 m-0 form-select" [(ngModel)]="firstPlanId" (selectionChange)="getEbooks($event.value)">
                            <mat-option value="0" disabled>Nenhum selecionado</mat-option>
                            <mat-option [value]="plan" *ngFor="let plan of studyPlans" >
                                {{plan.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                </div>

            </ng-container>
        </div>

        <div class="col-md-7 mt-2 height-100" *ngIf="ebooksDataSource?.data?.length && !loadingEbooks && planSelected || layoutService.isMobile && ebooksDataSource?.data?.length" [ngClass] = "{headerlist: !layoutService.isMobile}">
            <table mat-table [dataSource]="ebooksDataSource" class="table-discipline mb-3 mt-3 w-100" >
                <ng-container matColumnDef="name">
                    <th class="border-bottom-none disciplina brl-5" [ngClass]="{'brr-5': layoutService.isMobile}" mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let ebook; let i = index" [ngClass]="{'table-line-contrast': i % 2, 'p-0': layoutService.isMobile}">
                        <span class="mobileDiscipline">{{ebook.name}}</span>
                        <button class="mobileDisciplineLink table-mobile-link mr-10px" mat-button (click)="openFlipBook(ebook.id , planName)" color="accent">
                            <span class="">{{ebook.name}}</span>
                            <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent">Acessar</span>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="link" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef [ngClass]="{'brr-5 text-right': !layoutService.isMobile}" class="pr-5">LINK</th>
                    <td mat-cell *matCellDef="let ebook; let i = index" [ngClass]="{'table-line-contrast': i % 2,'text-right': !layoutService.isMobile}">
                        <button mat-button color="accent" class="p-0" (click)="openFlipBook(ebook.id , planName)">
                            <strong class="text-color-accent">Acessar</strong>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
        <!-- select a plan -->
        <div *ngIf="!planSelected && !loadingEbooks && !layoutService.isMobile" class="plan-not-selected">
            <h4>Selecione um plano</h4>
            <br />
            <p>Escolha um plano para carregar os Ebooks</p>
        </div>
        <!-- empty plan -->
        <div *ngIf="ebooksDataSource && !ebooksDataSource?.data?.length && !loadingEbooks" class="plan-not-selected">
            <h4>Nenhum Ebook</h4>
            <br />
            <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
        </div>
        <mat-spinner *ngIf="loadingEbooks" class="center table-spinner" color="accent"></mat-spinner>
    </div>
</div>
