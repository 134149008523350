import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private API = environment.ApiUrl;
  private updateSubject = new BehaviorSubject<Date>(new Date());
  private schoolId = new BehaviorSubject<number>(null);
  private starDate = new BehaviorSubject<string>(null);
  private endDate = new BehaviorSubject<string>(null);
  private updateDashboardStudent  = new BehaviorSubject<string>(null);
  private updateDashboardMock  = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) { }

    emitEventDashboardStudent(eventData:any){
      this.updateDashboardStudent.next(eventData);
    }

    onEventDashboardStudent(){
      return this.updateDashboardStudent.asObservable();
    }

    emitEventDashboardMock(eventData:any){
      this.updateDashboardStudent.next(eventData);
    }

    onEventDashboardMock(){
      return this.updateDashboardStudent.asObservable();
    }
  updateChields() {
    this.updateSubject.next(new Date());
  }

  getSchoolId() {
    return this.schoolId.asObservable();
  }

  setSchoolId(schoolId: number) {
    this.schoolId.next(schoolId);
  }

  getStarDate() {
    return this.starDate.asObservable();
  }

  setStarDate(starDate: string) {
    this.starDate.next(starDate);
  }

  getEndDate() {
    return this.endDate.asObservable();
  }

  setEndDate(endDate: string) {
    this.endDate.next(endDate);
  }

  getRoutes() {
    return this.http.get(this.API + '/routes/getRoutes', { observe: 'response' });
  }

  getMockBySchool(schoolId?: number) {
    return this.http.post(this.API + "/mock/getMocks", { schoolId }, { observe: 'response' });
  }

  getMockSummationBySchool(schoolId?: number) {
    return this.http.post(this.API + "/mock/getMocksSummation", { schoolId }, { observe: 'response' });
  }

  getMockByUser(userId?: number) {
    return this.http.post(this.API + "/student/getMocksStudentAssociated", { userId }, { observe: 'response' });
  }

  getMockSummationByUser(userId?: number) {
    return this.http.post(this.API + "/student/getMocksSummationStudentAssociated", { userId }, { observe: 'response' });
  }

  getAllStatistics(schoolId?: number) {
    return this.http.get(this.API + "/dashboard/all/statistics?schoolId=" + schoolId, { observe: 'response' });
  }

  getLastEngagement(schoolId: number) {
    return this.http.get(this.API + '/dashboard/engagement?schoolId=' + schoolId, { observe: 'response' });
  }

  getLastEngagementWithDate(schoolId: number , startDate:string , endDate: string) {
    return this.http.post(this.API + '/dashboard/engagementwithdate?schoolId=' +schoolId , {startDate , endDate} , { observe: 'response' });
  }

  getMocksLast(schoolId: number) {
    return this.http.get(this.API + '/dashboard/mocks?schoolId=' + schoolId, { observe: 'response' });
  }

  getMocksLastWithDate(schoolId: number , startDate:string , endDate:string) {
    return this.http.post(this.API + '/dashboard/mockswithdate?schoolId=' +schoolId , {startDate,endDate}, { observe: 'response' });
  }

  getStatistics(schoolId: number, planId: number, startDate: string, endDate: string) {
    return this.http.post(this.API + "/dashboard/statistics?schoolId=" + schoolId + "&planId=" + planId, { startDate, endDate }, { observe: 'response' });
  }

  getPerformanceOne(schoolId: number, unitId: number, classId: number, planId: number, userId: number, startDate: string, endDate: string) {
    return this.http.post(this.API + "/dashboard/report/performance/single?schoolId=" + schoolId + "&unitId=" + unitId + "&classId=" + classId + "&planId=" + planId + "&userId=" + userId, { startDate, endDate }, { observe: 'response' });
  }

  getGeneralReport(schoolId: number, unitId: number, classId: number, userId: number, startDate: string, endDate: string) {
    return this.http.post(this.API + "/dashboard/report/performance/general?schoolId=" + schoolId + "&unitId=" + unitId + "&classId=" + classId + "&userId=" + userId, { startDate, endDate }, { observe: 'response' });
  }

  getGeneralReportPlan(schoolId: number, unitId: number, classId: number, planId: number, userId: number, startDate: string, endDate: string , isAdmin:number) {
    return this.http.post(this.API + "/dashboard/report/performance/by-plan?schoolId=" + schoolId + "&unitId=" + unitId + "&classId=" + classId + "&planId=" + planId + "&userId=" + userId+"&isAdmin=" + isAdmin, { startDate, endDate }, { observe: 'response' });
  }

  getFrequency(schoolId: number, unitId: number, classId: number, startDate: string, endDate: string) {
    return this.http.post(this.API + "/dashboard/report/frequency?schoolId=" + schoolId + "&unitId=" + unitId + "&classId=" + classId, { startDate, endDate }, { observe: 'response' });
  }

  getMocks(startDate: string, endDate: string, schoolId: number, unitId: number, mockId: number, classId?: number, userId?: number) {
    return this.http.post(this.API + "/dashboard/report/mock?schoolId=" + schoolId + "&unitId=" + unitId + "&mockId=" + mockId + "&classId=" + classId + "&userId=" + userId, { startDate, endDate }, { observe: 'response' });
  }

  getMocksSummation(startDate: string, endDate: string, schoolId: number, unitId: number, mockId: number, classId?: number, userId?: number) {
    return this.http.post(this.API + "/dashboard/report/summation?schoolId=" + schoolId + "&unitId=" + unitId + "&mockId=" + mockId + "&classId=" + classId + "&userId=" + userId, { startDate, endDate }, { observe: 'response' });
  }


  getGeneralData(schoolId: number, startDate: string, endDate: string) {
    return this.http.post(this.API + "/dashboard/report/dados/general?schoolId=" + schoolId, { startDate, endDate }, { observe: 'response' });
  }

  getRedactionData(schoolId: number, startDate: string, endDate: string) {
    return this.http.post(this.API + "/dashboard/report/redaction?schoolId=" + schoolId, { startDate, endDate }, { observe: 'response' });
  }

  getPlansByClass(classId:number) {
    return this.http.get(this.API + "/dashboard/getplansbyclass?classId=" + classId, { observe: 'response' });
  }


}
