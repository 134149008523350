import { Component, OnInit } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Discipline } from 'src/app/modules/admin/discipline/discipline';
import { DisciplineService } from 'src/app/modules/admin/discipline/discipline.service';
import { School } from 'src/app/modules/admin/school/school';
import { SchoolService } from 'src/app/modules/admin/school/school.service';
import { StudyPlan } from 'src/app/modules/admin/study-plan/study-plan';
import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';
import { Video } from 'src/app/modules/video/video';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core'; 
import { UserService } from 'src/app/modules/user/user.service';
@Component({
  selector: 'app-video-likes-control',
  templateUrl: './video-likes-control.component.html',
  styleUrls: ['./video-likes-control.component.scss']
})
export class VideoLikesControlComponent implements OnInit {

  public schools : School[];
  public selectedSchool: number;
  public plans: StudyPlan[];
  public selectedPlan: number;
  public disciplines: Discipline[];
  public selectedDiscipline: number;
  public videos: Video[];
  public videoUrl = environment.VideoUrl;
  public embedCodeVideo: string | SafeUrl;  
  public videoName: string;

  constructor(
    private sanitizer: DomSanitizer,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private studyplanService: StudyPlanService,
    private disciplineService: DisciplineService,
    public userService: UserService
  ) { }

  ngOnInit() {
    if(this.userService.isAdmin()){
      this.getSchools();
    }
    else{
      this.getPlans(this.userService.getUserSchool());
    }
  }

  getSchools(){
    this.schoolService.getSchools()
    .subscribe(res => {
      const response  = res.body as ResponseApi;

      if(!response.error){
        this.schools = response.data as School[];
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  getPlans(schoolId: number){
    this.studyplanService.getSelectPlans(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plans = response.data as StudyPlan[]
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  getDisciplines(planId: number){
    this.studyplanService.getPlanDisciplines(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.disciplines = response.data as Discipline[];
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  getVideos(disciplineId: number){
    this.disciplineService.getDisciplineVideosWithLikeAndDeslikes(disciplineId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.videos = response.data as Video[]
        this.videos = this.setFullUrl(this.videos);
        this.embedCodeVideo =  response.data['link_video']  as string 
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  getVideosByName(disciplineId: number , videoName: string){
    this.disciplineService.getDisciplineVideosWithLikeAndDeslikesByName(disciplineId , videoName)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.videos = response.data as Video[]
        this.videos = this.setFullUrl(this.videos);
        this.embedCodeVideo =  response.data['link_video']  as string 
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  setFullUrl(videosArr:Video[]) {

    for (let index = 0; index < videosArr.length; index++) {
      if(videosArr[index]?.embed.type.toLowerCase() == 'vimeo' && !videosArr[index].transmissao)
      videosArr[index].fullUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl.vimeo +  videosArr[index].embed.code) as string;
      
      else if(videosArr[index]?.embed.type.toLowerCase() == 'youtube' && !videosArr[index].transmissao){
        videosArr[index].fullUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl.youtube +  videosArr[index].embed.code) as string;
      }
    }
    return videosArr;
  }

}
