import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { HeaderService } from '../../header/header.service';
import { Integrations } from './integrations';

@Component({
  selector: 'app-integration-portal',
  templateUrl: './integration-portal.component.html',
  styleUrls: ['./integration-portal.component.scss']
})
export class IntegrationPortalComponent implements OnInit {

  public logo: string;
  public nameSchool: string;
  public integrations: Integrations[] = [];
  public loading: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<IntegrationPortalComponent>,
    public layoutService: LayoutService,
    public userService: UserService,
    public headerService: HeaderService,
    public router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit():void {
    //this.loading = true;
    this.headerService.emitCheckIntegrations.subscribe((data)=>{
      if(data != undefined){
        this.loading = false;
        data.forEach(element => {
          this.integrations.push(element);
        });
        if(this.integrations[0]){
          this.logo = this.integrations[0].image;
          this.nameSchool = this.integrations[0].name;
        }
      }else{
        this.dialogRef.close();
      }
    });
    setTimeout(() => {
      this.afterViewInit();
    }, 5000);
  }

  afterViewInit(){
    if(this.integrations.length === 0){
      this.dialogRef.close();
    }
  }

  close(){
    this.dialogRef.close();
  }
}
