import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Console } from 'console';
import { Notifications } from 'src/app/modules/admin/notifications/notifications';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { NotificationsComponent } from '../notifications.component';
import { NotificationsService } from '../notifications.service';
import { NotificationStore } from '../notifications.store';

@Component({
  selector: 'app-modal-live',
  templateUrl: './modal-live.component.html',
  styleUrls: ['./modal-live.component.scss']
})
export class ModalLiveComponent implements OnInit {
  public notifications: Notifications[] = [];
  
  constructor(  
    @Optional() @Inject(MAT_DIALOG_DATA) public data: NotificationStore,
    @Optional() public dialogRef: MatDialogRef<ModalLiveComponent>,
    public notificationsService: NotificationsService,
    public layoutService: LayoutService,
    public userService: UserService,
    public router: Router,
    private alertService: AlertService
  ){}

  ngOnInit() {
    //console.log('ao vivo');
    this.notifications = this.data.getAll();
  }
  /* markAsRead(notification: Notifications){
    const userId = Number(this.userService.getUserId());
    notification.read = notification.read ? false : true;
    this.notificationsService.markAsRead(notification, userId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao salvar a notificação. Verifique a conexão e tente novamente'));
  } */

  openLive() {    
    this.notifications.forEach(element => {
        console.log('LINK ... ', this.notifications);
        window.open(element.link, '_blank');
    });
    
  }

  closeDialog(){
    this.dialogRef.close();
  }


}
