<div class="page-container p-pg page-container-mobile discipline">  
    <div class="row m-0">
        <div class="col-md-8 mt-2" [ngClass]="{'col-12': layoutService.isMobile}">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">Tópicos da</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Comunidade</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Comunidade</h6>
                        <p [ngClass]="{'fs-13 pb-0': layoutService.isMobile}">Crie seu tópico, deixe sua dúvida e colabore com seus colegas</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!write" class="row mw-100 m-0" [ngClass] = "{'pt-0': !layoutService.isMobile, 'pt-4 pb-4': layoutService.isMobile}">
        <div *ngIf="userService.isAdmin()" class="col-md-6 ml-4">
            <mat-form-field class="width-100">
                <mat-select class="removespaceSelect" placeholder="Selecione a escola" [(ngModel)]="selectedScholl">
                    <mat-option *ngFor="let school of scholls" (click)="getTopicsBySchool(school.id , 0 , 20)" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-8 mt-4 p-0" [ngClass] = "{'pr-4 mb-4': !layoutService.isMobile}">
            <div *ngIf="!write" class="pt-4 divconte">
                <div class="row mt-2 ml-5 mr-5" *ngIf="!showHiddenDiv">
                    <div class="col-10 pl-0" [ngClass] = "{'col-lg-8': !layoutService.isMobile && !layoutService.isTablet}">
                        <mat-form-field appearance="">
                            <mat-select class="float-left removespaceSelect pb-2" appearance="" placeholder="Mais recentes/antigos" [(ngModel)]="selectedValue" (selectionChange)="getOrder($event.value)">
                                <mat-option value="0" class="div4">
                                    <label class="m-1 mat-option-orderBy">Mais Recentes</label>
                                </mat-option>
                                <mat-option value="1">
                                    <label class="m-1 mat-option-orderBy">Mais Antigos</label>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="layoutService.isMobile || layoutService.isTablet" class="col-2 mr-0 p-0 button-add">
                        <button mat-mini-fab (click)="setWrite(true)" color="accent"><mat-icon>add</mat-icon></button>
                    </div>
                    <div *ngIf="!layoutService.isMobile && !layoutService.isTablet" class="button-add col-4 mr-0 pr-0" [ngClass] = "{'mt-4': layoutService.isMobile}">
                        <button class="float-right" mat-raised-button (click)="setWrite(true)" color="accent">Novo Tópico</button>
                    </div>
                </div>
                <div class="mt-4 ml-5 mr-5 mb-5" *ngIf="!showHiddenDiv">
                    <div>
                        <div *ngFor="let topic of communityTopics | orderBy: topic?.created_at:reverse;" style="background: #EBEBEB!important; border-radius: 10px;
                        cursor: pointer;" (click)="selectTopic(topic)" class="mt-2 mb-2">
                            <div>
                                <button class="btndeletetopic" *ngIf="userService.isAdmin() || userService.getUserId() == topic?.userId" (click)="deleteTopic(topic.id)" size="medium" status="danger" nbButton ghost>
                                    <nb-icon icon="trash"></nb-icon>
                                </button>
                            </div>
                            <div class="comment-div">
                                <div class="pt-2 p-3">
                                    <div class="row ml-1">
                                        <span class="comment-date">{{topic?.created_at | date : 'dd LLL, y '}}</span>
                                    </div>

                                    <div class="row mw-100 d-flex justify-content-between m-0">
                                        <div class="comment-title mt-2" [ngClass]="{'col': layoutService.isMobile , 'col': !layoutService.isMobile }">{{topic?.title}}</div>
                                        <span [ngClass]="{'mt-2 mb-2 divDisciplineMobile': layoutService.isMobile || layoutService.isTablet, 'divDiscipline': !layoutService.isMobile }">{{topic?.disciplineName}}</span>
                                    </div>
                                    <div div class="comment-img" [ngClass]="{'mt-2': layoutService.isMobile}">
                                        <img class="comment-box-header-image" [ngClass]="{'comment-img-reply': isReply}" [src]="topic?.ImagenUsu" alt="">
                                        <span class="col-md-12 ml-2 comment-name forcepadding" [ngClass]="{'mt-2': userService.isMobile}">{{topic?.userName}}</span>
                                        <span *ngIf="!topic?.totalAnswers" class="totaleAnswerss text-color-accent" [ngClass]="{'row ml-3 pl-1 mt-2': layoutService.isMobile}">Nenhuma Resposta</span>
                                        <span *ngIf="topic?.totalAnswers" class="totaleAnswerss text-color-accent" [ngClass]="{'row ml-3 pl-1 mt-2': layoutService.isMobile}">{{topic?.totalAnswers}} Respostas</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pb-2" *ngIf="!showHiddenDiv">
                    <mat-paginator [pageSize]="20" [length]="totalPageTopics" [pageIndex]="pageNoTopics" (page)="pageEventsTopics($event)"></mat-paginator>
                </div>
                <div *ngIf="showHiddenDiv" class="classNotopic p-4">
                    <div class="fontnotopics2">
                        <mat-icon class="iconForum mat-accent">forum</mat-icon>
                        <p class="mt-2 fontp1">Olá, seja bem-vindo!</p>
                        <p class="fontp2">Não existem tópicos criados.</p>
                        <p class="fontp3">Você pode começar criando um tópico ou selecionando um plano para carregar os tópicos</p>
                        <button style="width: 267px!important;" mat-raised-button (click)="setWrite(true)" color="accent">Novo Tópico</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-4 p-0" *ngIf="!write">
            <div class="divlat pt-4 pl-4 pr-4 pb-0" style="background: #FFFFFF;">
                <label class="block-title mb-3" style>Filtrar por assunto ou matéria</label>
                <mat-form-field class="selectedThemetopic width-100">
                    <mat-select class="removespaceSelect" placeholder="Planos" (selectionChange)="getTopics($event.value , 0 , 20)" [(ngModel)]="selectedPlanId">
                        <mat-option [value]="0">
                            <label>Todos</label>
                        </mat-option>
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            <label>{{plan.name}}</label>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="mt-3 width-100 text-center check-list">
                    <div class="d-flex text-center mr-3">
                        <span class="row p-1 d-flex justify-content-center mr-2 mb-2">
                            <mat-checkbox [checked]="myTopics" class=" col-1  checkmytopics1 float-start" (change)="getMyTopics($event.checked , 0 , 20)"></mat-checkbox>
                            <span class="col-12 checkmytopics1 float-end text-left" style="position: relative;">Mostrar apenas os meus tópicos</span>
                        </span>

                    </div>
                    <div class="  d-flex text-center mr-3">
                        <span class="row  p-1 d-flex justify-content-center mr-2 mb-4">
                            <mat-checkbox [checked]="answeredTopics" class=" col-1 checkmytopics2" (change)="getansweredTopics($event.checked , 0 , 20)"></mat-checkbox>
                            <span class="col-12  checkmytopics2 text-left " style="position: relative;">Mostrar apenas os topicos que eu respondi</span>
                        </span>

                    </div>

                </div>
            </div>
            <div class="divlat mt-4 mb-4" style="background: #FFFFFF;">
                <label class="block-title mt-4" *ngIf="userActivies">Alunos mais ativos</label>
                <div class="pb-4" *ngIf="userActivies">
                    <div *ngIf="userActivies[0]" class="mt-4 ml-4 row">
                        <label class="ranking">1°</label>
                        <img class="firstImg" [ngClass]="{'comment-img-reply': isReply}" [src]="userActivies[0].imagen" alt="">
                        <div class="row">
                            <div class="col ml-1">
                                <label class="rankinngNames row text-color-accent">{{userActivies[0].name}}</label>
                                <p class="row pstudenttnames">{{userActivies[0].totalAnswers}} Respostas</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="userActivies[1]" class="mt-4 ml-4 row">
                        <label class="ranking">2°</label>
                        <img class="secondImg" [ngClass]="{'comment-img-reply': isReply}" [src]="userActivies[1].imagen" alt="">
                        <div class="row">
                            <div class="col ml-1">
                                <label class="rankingName row text-color-accent">{{userActivies[1].name}}</label>
                                <p class="row pstudentname">{{userActivies[1].totalAnswers}} Respostas</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="userActivies[2]" class="mt-4 ml-4 row">
                        <label class="ranking">3°</label>
                        <img class="thirdyImg" [ngClass]="{'comment-img-reply': isReply}" [src]="userActivies[2].imagen" alt="">
                        <div class="row">
                            <div class="col ml-1">
                                <label class="row rankingName text-color-accent">{{userActivies[2].name}}</label>
                                <p class="row pstudentname">{{userActivies[2].totalAnswers}} Respostas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- CRIAR TÓPICO -->
    <div *ngIf="write">
        <form [formGroup]="createCommunityTopicForm" class="d-block">
            <div class="row mw-100 p-4 m-0" >
                <div class="col-12 p-0 m-0" [ngClass] = "{'pr-4 col-lg-8': !layoutService.isMobile && !layoutService.isTablet}">
                    <div class="p-4"style="background: #FFFFFF; border-radius: 10px;">
                        <div class="col-md-12 leftBack">
                            <button (click)="setWrite(false)" color="accent" mat-button class="hvr-grow">
                                <mat-icon>reply</mat-icon> <span class="back">Retornar</span>
                            </button>
                        </div>
                        <h5 class="col-md-12 mt-2 ml-2">Crie seu tópico ou deixe sua dúvida</h5>
                        <div class="col-md-12 mt-3">
                            <mat-form-field class="width-80">
                                <input matInput placeholder=" Titulo " formControlName="title" style="position: relative;">
                            </mat-form-field>
                        </div>
                        <div class="mt-4 col-md-12 mb-4 ">
                            <ckeditor [editor]="questionEditor " formControlName="communityComment" [config]="editorConfig">
                            </ckeditor>
                        </div>
                    </div>
                </div>
                <div class="fit-content-lat col-12 p-4" [ngClass] = "{'m-0 col-lg-4': !layoutService.isMobile && !layoutService.isTablet,  'mt-4': layoutService.isMobile || layoutService.isTablet}" style="background: #FFFFFF; border-radius: 10px;">
                    <div class="divlatcreate d-block">
                        <h5 *ngIf="userService.isAdmin()" class="mb-4">Selecione a escola, plano e uma disciplina</h5>
                        <h5 *ngIf="!userService.isAdmin()" class="mb-4">Selecione um plano e uma disciplina</h5>
                        <mat-form-field *ngIf="userService.isAdmin()">
                            <mat-select class="removespaceSelect" placeholder="Escola" formControlName="schoolIdAdmin">
                                <mat-option (click)="getPlansBySchool($event.value)" *ngFor="let school of scholls" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select class="removespaceSelect" placeholder="Plano" formControlName="planId" (selectionChange)="getDisciplinesByPlan($event.value)" style="position: relative;">
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                    {{plan.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="mt-2">
                            <mat-select class="removespaceSelect" placeholder="Disciplina" formControlName="disciplineId">
                                <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                    {{discipline.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="mt-3 width-100">
                            <button style="border-radius: 20px;" nbButton size="medium" (click)="createTopic()" status="primary " class="btnPost width-90 bg-color-accent">Postar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
