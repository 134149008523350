import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { RecordedClassroom } from '../../admin/classroom/record-link';
import { ClassroomService } from '../classroom.service';

@Component({
  selector: 'app-recorded-classroom-dialog',
  templateUrl: './recorded-classroom-dialog.component.html',
  styleUrls: ['./recorded-classroom-dialog.component.scss']
})
export class RecordedClassroomDialogComponent implements OnInit {
  public loading: boolean = true;
  public url : string | SafeUrl;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public recordedClassrooms: RecordedClassroom[],
  private sanitizer: DomSanitizer,
  private route: ActivatedRoute,
  private router: Router) { }

  ngOnInit() {
    this.url = window.location.hostname;
   //(this.recordedClassrooms);
  }

  

  openLink(recorded: any): void {


    if(recorded.live_vimeo_id){
      this.router.navigate(['video/watch/'+recorded.live_vimeo_id , true]);
    }else{
      recorded.recordLink && window.open(recorded.recordLink, '_blank');
    }
    //let finalLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.url+'/video/watch/'+link as string);
    //let finalLink = this.url+'/video/watch/'+link;
   
   
  }

  getRecordedLink(){}



}
