<div class="container">
    <h6>Adicionar Unidade</h6>
    <div class="row mb-3">
        <div class="col-6" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Escola" (selectionChange)="getUnits($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="width-100">
                <mat-select placeholder="Unidade" #filterUnit>
                    <mat-option >Selecione uma unidade</mat-option>
                    <mat-option *ngFor="let unit of units" [value]="unit.id" (click)="addUnitChip(unit)">
                        <span [title]="unit.name">{{unit.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-2 text-right">
        <div class="col width-100">
            <button (click)="add()" class="right" nbButton size="small" status="primary">Cadastrar</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-chip-list *ngIf="selectedUnits">
                <mat-chip *ngFor="let unitChip of selectedUnits" selectable="true" removable="true" (removed)="removeUnitChip(unitChip)">
                    {{unitChip.name}} <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
</div>