import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Calendar } from '../../admin/calendar/calendar';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { CalendarService } from '../../calendar/calendar.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-study-plan-card',
  templateUrl: './study-plan-card.component.html',
  styleUrls: ['./study-plan-card.component.scss']
})
export class StudyPlanCardComponent implements OnInit {

  @Input() studyPlan: StudyPlan;
  @Output() selectedPlan = new EventEmitter<StudyPlan>();
  public lottieOptions: AnimationOptions;
  public planId: number;
  calendar: Calendar;
  loading: boolean = false;


  constructor(
    public userService: UserService,  
    private alertService: AlertService,
    private router: Router,
    public calendarService: CalendarService) { }

  ngOnInit() {
    this.lottieOptionsConfig();
  }

  lottieOptionsConfig() {
    this.lottieOptions = {
      path: '/assets/lotties/plan.json',
      loop: !this.studyPlan.expired,
      autoplay: !this.studyPlan.expired
    };
  }

  selectPlan(plan: StudyPlan) {
    this.selectedPlan.emit(plan);
  }
  syncPlan(plan: StudyPlan) {

    if(confirm("Você quer realmente Atualizar este Calendário?")){
      this.loading = true;
      this.calendarService.syncCalendar(plan.id)
      .subscribe(res => {
        const response = res.body as ResponseApi;
  
        if (!response.error) {
          this.calendar = response.data as Calendar;
          console.info(this.calendar);
          this.alertService.success("Plano atualizado!");
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar o cronograma. Verifique a conexão e tente novamente');
        this.loading = false;
      });
    }
     
    
  }

  viewCalendar(plan : StudyPlan){
    console.log(plan)
    let viewAsStudent = true;
    this.router.navigateByUrl('calendar/v2/'+ plan.id + '/' + viewAsStudent);
  }
}
