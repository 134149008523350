import { Component, Inject, OnInit, Optional, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Notifications, NotificationUpdateGeral } from 'src/app/modules/admin/notifications/notifications';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { ModalCentralComponent } from '../modal-central/modal-central.component';
import { NotificationsComponent } from '../notifications.component';
import { NotificationsService } from '../notifications.service';
import { NotificationStore } from '../notifications.store';

@Component({
  selector: 'app-modal-update',
  templateUrl: './modal-update.component.html',
  styleUrls: ['./modal-update.component.scss']
})
export class ModalUpdateComponent implements OnInit {

  public read: boolean;
  public limited: boolean;
  public notifications: Notifications[] = [];
  public domain = window.location.hostname;
  private link_video: SafeUrl;
  @ViewChildren('player_container') playerContainer;
  
  constructor(  
    @Optional() @Inject(MAT_DIALOG_DATA) public data: NotificationStore,
    @Optional() public dialogRef: MatDialogRef<ModalUpdateComponent>,
    private dialog: MatDialog,
    public notificationsService: NotificationsService,
    public userService: UserService,
    public layoutService: LayoutService,
    private alertService: AlertService,
    public deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.notifications = this.data.getAll();
    this.notifications.forEach(element => {
      if(element.video){
        element.linkSanityzed = this.sanitizer.bypassSecurityTrustResourceUrl(element.video);
      }
    });
  }
  
  removeNotification(id:number){
    this.data.remove(id);
    this.notifications = this.data.getAll();
    
  }

  markAsRead(notification: Notifications){
    const userId = Number(this.userService.getUserId());
    notification.read = notification.read ? false : true;
    this.notificationsService.markAsRead(notification, userId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        setTimeout(() => {
          this.removeNotification(notification.id);
        }, 1000); 
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao salvar a notificação. Verifique a conexão e tente novamente'));
  }

  
  viewMore(notification: NotificationUpdateGeral){
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ModalCentralComponent, {
      data: notification,
      maxWidth: '800px',
      maxHeight: '600px',
    }); 

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      //this.animal = result;
    }); 
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
