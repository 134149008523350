import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ExerciseAlternative } from 'src/app/modules/video/alternative';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { CreateDisciplineLibComponent } from '../create-discipline-lib/create-discipline-lib.component';
import { CreateThemeLibComponent } from '../create-theme-lib/create-theme-lib.component';
import { LibDisciplines, libInstitution, libQuestions, libQuestionType, libSubTheme, libTheme } from '../lib-interfaces';
import { MyExerciseLibraryService } from '../my-exercise-library.service';




@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss']
})
export class CreateQuestionComponent implements OnInit {

  public createFormQuestion: FormGroup;
  public questionEditor = ClassicEditor;
  public aEditor = ClassicEditor;
  public bEditor = ClassicEditor;
  public cEditor = ClassicEditor;
  public loading: boolean;
  public dEditor = ClassicEditor;
  public eEditor = ClassicEditor;
  public commentEditor = ClassicEditor;
  public question: string;
  public comment: string;
  public editorConfig;
  public filter = '';
  private apiUrl = environment.ApiUrl;
  public questionTypes: libQuestionType[];
  public selectedQuestionType: number;
  public disciplines: LibDisciplines[];
  public selectedDiscipline: number;
  public themes: libTheme[];
  public selectedTheme: number;
  public subThemes: libSubTheme[];
  public questions: libQuestions[];
  public questionCreate: libQuestions;
  public selectedSubTheme: number;
  public institutions: libInstitution[];
  public selectedInstitution: number;
  public year: string;
  public questionName: string;
  public exerciseText: string;
  public answer_comment: string;
  public comingFromLibrary;
  public isSummation: boolean;

  public alt1: ExerciseAlternative;
  public alt2: ExerciseAlternative;
  public alt4: ExerciseAlternative;
  public alt8: ExerciseAlternative;
  public alt16: ExerciseAlternative;
  public alt32: ExerciseAlternative;
  public alt64: ExerciseAlternative;

  public editor1 = ClassicEditor;
  public editor2 = ClassicEditor;
  public editor4 = ClassicEditor;
  public editor8 = ClassicEditor;
  public editor16 = ClassicEditor;
  public editor32 = ClassicEditor;
  public editor64 = ClassicEditor;

  public correctAlternative: string;
  public alternatives: ExerciseAlternative[];
  public summationAlternatives: ExerciseAlternative[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public questionConfig: any,
    @Optional() public dialogRef: MatDialogRef<CreateQuestionComponent>,
    private dialog: MatDialog,
    private libraryService: MyExerciseLibraryService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.isSummation = false;
    this.exerciseText = '';
    this.answer_comment = '';

    if (!this.questionConfig) {
      this.comingFromLibrary = false
    }
    else {
      this.comingFromLibrary = true;
    }
    this.editorConfig = {
      ckfinder: {
        // tslint:disable-next-line: max-line-length
        uploadUrl: this.apiUrl + '/upload/exerciseImg',
      },
      image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

        styles: [
          // This option is equal to a situation where no style is applied.
          'full',

          // This represents an image aligned to the left.
          'alignLeft',

          // This represents an image aligned to the right.
          'alignRight'
        ]
      }
    };

    this.alternatives = [
      { label: 'A', option: 'A', value: '' },
      { label: 'B', option: 'B', value: '' },
      { label: 'C', option: 'C', value: '' },
      { label: 'D', option: 'D', value: '' },
      { label: 'E', option: 'E', value: '' }
    ];

    this.summationAlternatives = [
      { option: '1', value: '' },
      { option: '2', value: '' },
      { option: '4', value: '' },
      { option: '8', value: '' },
      { option: '16', value: '' },
      { option: '32', value: '' },
      { option: '64', value: '' }
    ];

    this.question = '';
    this.comment = '';

    this.createFormQuestion = this.formBuilder.group({
      id: [],
      code: [],
      question_type: [],
      discipline_id: [],
      theme_id: [],
      institution_id: [],
      sub_theme: [],
      name: [],
      text: [],
      answer: [],
      answerSummation: [],
      answer_comment: [],
      year: [],
      created_at: [],
      updated_at: [],
      alternatives: [],
      deleted: [],
      disciplineName: [],
      themeName: [],
      institutionName: [],
      subThemeName: [],
      questionTypeName: []
    });

    this.getQuestionTypes();
    this.getDisciplines();
    this.getThemes();
    this.getSubThemes();
    this.getInstitutions();
    if (this.questionConfig.edit) {
      this.getAlternatives(this.questionConfig.question.id);
      this.initEditMode();
    }
  }

  addAlternative() {
    this.alternatives.push({ label: 'E', option: 'a', value: '' });
  }
  removeAlternative() {
    this.alternatives.pop();/* 
    let index = this.alternatives.indexOf(obj)
    if (index > -1) {
      this.alternatives.splice(index, 1);
    }  */
  }

  getAlternatives(questionId: number) {
    this.libraryService.getAlternatives(questionId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          if (this.questionConfig.question.questionTypeName == "SOMATÓRIA") {
            this.summationAlternatives = response.data as ExerciseAlternative[];
          }
          else {
            this.alternatives = response.data as ExerciseAlternative[];
          }
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  public setTypeQuestion(typeName: string) {
    if (typeName == "SOMATÓRIA") {
      this.isSummation = true;
    }
    else {
      this.isSummation = false;
    }
  }

  initEditMode() {

    if (this.questionConfig.edit) {
      if (this.questionConfig.question.questionTypeName == "SOMATÓRIA") {
        this.isSummation = true;
      }
      else {
        this.isSummation = false;
      }
      if (this.questionConfig.question.text) {
        this.exerciseText = this.questionConfig.question.text;
      }

      this.createFormQuestion.patchValue(this.questionConfig.question);
      const ano = (String(this.questionConfig.question.year));
      this.createFormQuestion.get('year').patchValue(ano);
    }
  }

  getQuestionTypes() {
    this.libraryService.getQuestionType()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questionTypes = response.data as libQuestionType[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getDisciplines() {
    this.libraryService.getDisciplines(0,9999999999999)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as LibDisciplines[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  createDiscipline() {
    const dialogRef = this.dialog.open(CreateDisciplineLibComponent, {
      data: { edit: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getDisciplines();
      }
    });
  }

  getThemes() {
    this.libraryService.getThemes(0,99999999999999)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.themes = response.data as libTheme[]
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  createTheme() {
    const dialogRef = this.dialog.open(CreateThemeLibComponent, {
      data: { edit: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getThemes();
        this.getSubThemes();
      }
    });
  }

  getSubThemes() {
    this.libraryService.getSubTheme(0,99999999999999)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.subThemes = response.data as libSubTheme[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getInstitutions() {
    this.libraryService.getInstitution(0,99999999999999)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.institutions = response.data as libInstitution[]
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  createQuestion(questionName) {

    const actualyear = (Number(new Date().getFullYear()));

    if (this.createFormQuestion.get('year').value > 1979 && this.createFormQuestion.get('year').value < actualyear) {
      let questioncreate = this.createFormQuestion.getRawValue() as libQuestions;


      if (this.isSummation) {
        questioncreate.alternatives = this.summationAlternatives;
      }
      else {
        questioncreate.alternatives = this.alternatives;
      }

      questioncreate.text = this.exerciseText;

      if (this.isSummation) {
        this.libraryService.createQuestionSummation(questioncreate)
          .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              this.alertService.success('Questão cadastrada com sucesso!');
              this.dialogRef.close(true);
            }
            else {
              this.alertService.error(response.error);
            }
          })
      }
      else {
        this.libraryService.createQuestion(questioncreate)
          .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              this.alertService.success('Questão cadastrada com sucesso!');
              this.dialogRef.close(true);
            }
            else {
              this.alertService.error(response.error);
            }
          })
      }
    }
    else {
      this.alertService.error('O ano precisa estar entre 1979 e o ano atual!');
    }
  }

  updateQuestion() {

    const actualyear = (Number(new Date().getFullYear()));

    if (this.createFormQuestion.get('year').value > 1979 && this.createFormQuestion.get('year').value < actualyear) {

      this.questionConfig.question = this.createFormQuestion.getRawValue() as libQuestions;

      if (this.isSummation) {
        this.questionConfig.question.alternatives = this.summationAlternatives;
      }
      else {
        this.questionConfig.question.alternatives = this.alternatives;
      }
      this.questionConfig.question.name = this.questionName;
      this.questionConfig.question.text = this.exerciseText;

      if(this.isSummation){
        this.libraryService.updateQuestionSummation(this.questionConfig.question)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Questão atualizada com sucesso!');
            this.dialogRef.close(true);
          }
          else {
            this.alertService.error(response.error);
          }
        })
      }
      else{
      this.libraryService.updateQuestion(this.questionConfig.question)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Questão atualizada com sucesso!');
            this.dialogRef.close(true);
          }
          else {
            this.alertService.error(response.error);
          }
        })
      }
    }
    else{
      this.alertService.error('O ano precisa estar entre 1979 e o ano atual!');
    }
  }
}
