import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { environment } from 'src/environments/environment';
import { Ebook } from '../../admin/flip-book-admin/flipBook';
import { FlipBookService } from '../../flip-book/flip-book.service';
import { UserService } from '../../user/user.service';
import { ebookComments } from '../ebookComments';

@Component({
  selector: 'app-flip-book-view',
  templateUrl: './flip-book-view.component.html',
  styleUrls: ['./flip-book-view.component.scss']
})
export class FlipBookViewComponent implements OnInit {


  public liked: boolean;
  public desliked: boolean;
  public ebook: Ebook;
  private ebookId: number;
  public comment: string;
  public ebookComments: ebookComments[];
  public fileBookUrl:any;
  public planName: string;
  private API = environment.ApiUrl;

  constructor(
    public layoutService: LayoutService,
    private flipBookService: FlipBookService,
    private userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.ebookId = Number(this.route.snapshot.paramMap.get('ebookId'));
    this.planName = String(this.route.snapshot.paramMap.get('planName'));
    this.getEbook();
    this.getUserLikeDeslike(this.userService.getUserId() , this.ebookId);
    this.getEbookComments(this.ebookId);
    this.getPlanName();
  }

  getEbook(){
    this.flipBookService.getEbookOne(this.ebookId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      //lutring
      if(!response.error){
        this.ebook = response.data as Ebook;
        const linkbook = btoa(this.ebook.book);
        console.log(linkbook)
        this.fileBookUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.API +"/flip-book/"+linkbook);
      }
      else{
        console.log(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possível buscar os dados verifique a conexâo e tente novamente!');
    })
  }

  likeMethod(){
    this.liked = !this.liked;
    this.desliked = false;

    this.flipBookService.setLike(this.userService.getUserId() , this.ebookId , this.liked)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success(response.data.toString());
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel salvar os dados verifique a conexão e tente novamente!');
    })
}

deslikeMethod(){
    this.desliked = !this.desliked
    this.liked = false;

    this.flipBookService.setDeslike(this.userService.getUserId() , this.ebookId , this.desliked)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success(response.data.toString());
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel salvar os dados verifique a conexão e tente novamente!');
    })
}

getUserLikeDeslike(userId : number , videoId: number){
  this.flipBookService.getUserLikeDeslike(userId , videoId)
  .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
          if(response.data['like'] == 1){
              this.liked = true;
              this.desliked = false;
          }
          else{
              this.liked = false;
          }
          if(response.data['deslike'] == 1){
              this.desliked = true;
              this.liked = false;
          }
          else{
              this.desliked = false;
          }
      }
      else{
          this.liked = false;
          this.desliked = false;
      }
  })
}

setEbookComment(comment: string){
if(comment){
this.flipBookService.setEbookComment(this.userService.getUserId() , this.ebookId , comment)
.subscribe(res => {
  const response = res.body as ResponseApi;

  if(!response.error){
    this.alertService.success(response.data.toString());
    this.comment = "";
    this.getEbookComments(this.ebookId);
  }
  else{
    this.alertService.error(response.error);
  }
},err => {
  this.alertService.error("Não foi possivel salvar os dados verifique a conexão e tente novamente!");
})
}
else{
  this.alertService.error('você precisa escrever um comentário para salvar!');
}
}

getEbookComments(ebookId: number){
  this.flipBookService.getEbookComments(ebookId)
  .subscribe(res => {
    const response = res.body as ResponseApi;

    if(!response.error){
      this.ebookComments = response.data as ebookComments[];
      this.ebookComments.forEach(element => {
        if(!element.imagenUsu){
          element.imagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png'
        }
      });
    }
    else{
      console.log(response.error);
    }
  },err => {
    console.log(Response.error);
  })
}

deleteEbookComment(commentId: number){
  if(confirm('Deseja remover este comentário?')){
  this.flipBookService.deleteEbookComment(commentId)
  .subscribe(res => {
    const response = res.body as ResponseApi;

    if(!response.error){
      this.alertService.success(response.data.toString());
      this.getEbookComments(this.ebookId);
    }
    else{
      this.alertService.error(response.error);
    }
  }, err => {
    this.alertService.error("Não foi possível excluir os dados verifique a conexão e tente novamente!");
  })
}
}

getPlanName(){
  this.flipBookService.getNamePlan()
  .subscribe(res => {
    this.planName = res as string;
  })
}

}
