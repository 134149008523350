import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Edital } from './create-edital/edital';
import { SelectedPlansResp } from './create-theme/theme';
/* import { School } from './school';
import { Unit } from './unit';
import { Class } from './class';
import { Manager } from './manager';
import { Teacher } from './create-teacher/teacher'; */

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }


  getApiSchools() {
    return this.http.get(this.API + '/syncApi/schools', { observe: 'response' });
  }
  getApiUnits(schoolId: number) {
    return this.http.get(this.API + '/syncApi/units/'+schoolId, { observe: 'response' });
  }
  getApischoolUnits(schoolId: number) {
    return this.http.get(this.API + '/syncApi/schoolUnits/'+schoolId, { observe: 'response' });
  }
  getUnits(schoolId: number) {
    return this.http.get(this.API + '/sync/units/'+schoolId, { observe: 'response' });
  }
  getThemeTypes() {
    return this.http.post(this.API + '/essays/getThemeTypes', {} , { observe: 'response' });
  }
  associateThemeToPlan(themeId: number, selectedPlans: SelectedPlansResp[], schoolId?: number) {
    return this.http.post(this.API + '/essays/associateThemeToPlan', {themeId, selectedPlans, schoolId} , { observe: 'response' });
  }
  updateAssociateThemeToPlan(themeId: number, selectedPlans: SelectedPlansResp[], schoolId?: number) {
    return this.http.post(this.API + '/essays/updateAssociateThemeToPlan', {themeId, selectedPlans, schoolId} , { observe: 'response' });
  }
  getEditals(index:number , type_id:number) {
    return this.http.post(this.API + '/essays/getEditals', {index , type_id} , { observe: 'response' });
  }
  createEdital(edital : Edital) {
    return this.http.post(this.API + '/essays/createEdital', edital , { observe: 'response' });
  }
  updateEdital(edital : Edital) {
    return this.http.post(this.API + '/essays/updateEdital', edital , { observe: 'response' });
  }
  deleteEdital(editalId : number) {
    return this.http.post(this.API + '/essays/deleteEdital', {editalId} , { observe: 'response' });
  }
  getDbEditals() {
    return this.http.post(this.API + '/essays/getDbEditals', {} , { observe: 'response' });
  }
  getApiClasses(unitId: number) {
    return this.http.get(this.API + '/syncApi/classes/'+unitId, { observe: 'response' });
  }
  getApiClass(unitId: number) {
    return this.http.get(this.API + '/syncApi/class/'+unitId, { observe: 'response' });
  }

  getApiCoords(unitId: number) {
    return this.http.get(this.API + '/syncApi/coords/'+unitId, { observe: 'response' });
  }
  getApiCoordUnitSync(coordId: number) {
    return this.http.get(this.API + '/syncApi/coords/unitsync/'+coordId, { observe: 'response' });
  }
  getApiCoordUnitUnSync(coordId: number) {
    return this.http.get(this.API + '/syncApi/coords/unitunsync/'+coordId, { observe: 'response' });
  }
  getApiTeachers(classId: number) {
    return this.http.get(this.API + '/syncApi/teachers/'+classId, { observe: 'response' });
  }
  getApiStudents(classId: number) {
    return this.http.get(this.API + '/syncApi/students/'+classId, { observe: 'response' });
  }

  syncStudent(student: any) {
    return this.http.post(this.API + '/syncApi/student', student, { observe: 'response' });
  }
  syncClass(classe: any) {
    return this.http.post(this.API + '/syncApi/class', classe, { observe: 'response' });
  }
  syncUnit(unit: any) {
    return this.http.post(this.API + '/syncApi/unit', unit, { observe: 'response' });
  }
  syncCoord(coord: any) {
    return this.http.post(this.API + '/syncApi/coord', coord, { observe: 'response' });
  }
  syncTeacher(teacher: any) {
    return this.http.post(this.API + '/syncApi/teacher', teacher, { observe: 'response' });
  }


  unSyncStudent(studentId: number) {
    return this.http.delete(this.API + '/syncApi/remove/student/'+studentId,  { observe: 'response' });
  }
  unSyncClass(classeId: number) {
    return this.http.delete(this.API + '/syncApi/remove/class/'+classeId,  { observe: 'response' });
  }
  unSyncUnit(unitId: number) {
    return this.http.delete(this.API + '/syncApi/remove/unit/'+unitId,  { observe: 'response' });
  }
  unSyncCoord(coordId: number) {
    return this.http.delete(this.API + '/syncApi/remove/coord/'+coordId,   { observe: 'response' });
  }
  unSyncCoordUnits(coordId: number) {
    return this.http.delete(this.API + '/syncApi/remove/coord/units/'+coordId,   { observe: 'response' });
  }
  unSyncTeacher(teacherId: number) {
    return this.http.delete(this.API + '/syncApi/remove/teacher/'+teacherId,  { observe: 'response' });
  }

  createSchool(unit: any) {
    return this.http.post(this.API + '/syncApi/student', unit, { observe: 'response' });
  }
  getSchoolss() {
      return this.http.get(this.API + '/school/getSchools', { observe: 'response' });
  }
  updateSchools() {
    return this.http.put(this.API + '/school/getSchools', { observe: 'response' });
  }
  deleteSchools() {
    return this.http.delete(this.API + '/school/getSchools', { observe: 'response' });
  }
}
