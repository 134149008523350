<div>
    <form [formGroup]="createThemeForm">
        <div class="row">
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Id externo" formControlName="externalId" type="number" required>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Ordem" formControlName='order' type="number" max="100">
                </mat-form-field>
            </div>

            <div class="col-md-1.5">
                <button (click)="getThemeText()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Atualizar</button>
            </div>
            <div class="col-md-2 report-warnning" *ngIf="alertExternal == 1">
                <span>Id não encontrado nos temas!</span>
            </div>
            <div class="col-md-2 report-sucess" *ngIf="alertExternal == 2">
                <span>Dados atualizados!</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name" required>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <input maxlength="5" matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Tipo*" formControlName="type" name="item">
                        <mat-option *ngFor="let type of typeTheme" [value]="type.type">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-9">
            <mat-form-field class="width-100">
                <mat-select placeholder="Tipo*" formControlName="editalId">
                    <mat-option *ngFor="let edital of editals" [value]="edital.id">
                        {{edital.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-3">
                <button class="m-3" (click)="openSelectPlans()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Associar ao plano</button>
            </div>
            <div *ngIf="selectedPlans?.length > 0" class="col-md-9">
                <mat-label class="m-1" status="primary">Planos selecionados</mat-label>
                <mat-chip-list class="pt-3">
                    <mat-chip *ngFor="let plan of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(plan)">
                        {{plan.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
              </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12 text-right width-100">
                <button (click)="updateTheme()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
            </div>
        </div>
    </form>
</div>
