<div *ngIf="!showList">
  <form [formGroup]="form">

    <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Monitoria</h4>

    <div class="row">
      <div class="col">
        <mat-form-field class="width-100">
          <mat-label>Início</mat-label>
          <input matInput placeholder="Início" formControlName="initDate" [textMask]="{mask: maskService.dateTime}">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="width-100">
          <mat-label>Término</mat-label>
          <input matInput placeholder="Término" formControlName="endDate" [textMask]="{mask: maskService.dateTime}">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <mat-form-field class="width-100">
          <mat-select placeholder="Turma" (selectionChange)="getStudents($event.value)" [(ngModel)]="filterClassId"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let class of classes" [value]="class.id">
              {{class.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md">
        <mat-form-field class="width-100">
          <mat-select placeholder="Aluno" formControlName="studentId">
            <mat-option *ngFor="let student of students" [value]="student.id">
              {{student.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field class="width-100">
          <mat-label>Link</mat-label>
          <input matInput placeholder="Link" formControlName="link">
        </mat-form-field>
      </div>
    </div>

  </form>

  <div *ngIf="!editMode" class="row mt-5">
    <div class="col-6 width-100">
      <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver monitorias
        cadastradas</button>
    </div>
    <div class="col-6 text-right width-100">
      <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading"
        [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
    </div>
  </div>

  <div *ngIf="editMode" class="row mt-5 text-right">
    <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading"
      [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
  </div>
</div>

<div *ngIf="showList" class="">

  <!--  <div class="row">
    <div class="col">
      <mat-form-field class="width-100">
        <mat-select placeholder="Turma" [(ngModel)]="filterClassId"
          (selectionChange)="getClassMonitoring($event.value)">
          <mat-option [value]="0">
            Selecione uma Turma
          </mat-option>
          <mat-option *ngFor="let class of classes" [value]="class.id">
            {{class.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div> -->

  <nb-list *ngIf="!loading" class="width-90 mt-3 center">
    <nb-list-item *ngFor="let monitory of monitoring">
      <div class="row">
        <div class="col-6">
          {{monitory.studentName}}
        </div>
        <div class="col-2">
          {{monitory.initDate}}
        </div>
        <div class="col-3 text-right">
          <button (click)="edit(monitory)" nbButton size="tiny" outline status="primary">Editar</button>
        </div>
        <div class="col-1 text-right">
          <button size="tiny" (click)="remove(monitory.id)" status="danger" nbButton ghost>
            <nb-icon icon="trash"></nb-icon>
          </button>
        </div>
      </div>
    </nb-list-item>
  </nb-list>

  <mat-spinner *ngIf="loading" class="center mt-3" color="accent"></mat-spinner>
  <div class="text-right width-100">
    <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
  </div>
</div>