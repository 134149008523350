<div class="row ml-5 mt-3">
    <div class="col-md-10">
        <h3>Relatórios Suporte</h3>
    </div>
</div>

<div>
    <nb-card>
        <nb-card-body>
            <div class="row ml-2">
                <mat-tab-group class="col-md-12">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span (click)="getReportsAll(0 , 20)">Reportes de erros</span>
                        </ng-template>

                        <div class="searchtxt">
                            <mat-form-field appearance="outline" class="width-90 mt-3" ngDefaultControl>
                                <input matInput placeholder="Buscar pelo nome do video" [(ngModel)]="filterReport">
                                <mat-icon (click)="getReportsByName(filterReport , 0 , 20)" matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>

                        <nb-list class="width-90 mt-2">
                            <nb-list-item class="corpadrao list-header" style="color: white ">
                                <div class="row">
                                    <div class="col-md-3">Nome do Video</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile">Rota</div>
                                    <div class="col-md-4">Erro</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile">Aluno</div>
                                    <div class="col-md-1" *ngIf="!deviceService.isMobile">Ações</div>
                                </div>
                            </nb-list-item>

                            <nb-list-item *ngFor="let erro of errorReports">
                                <div class="row" *ngIf="errorReports">
                                    <div class="col-md-3 fontstext">
                                        {{erro.videoName}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile" class="col-md-2 fontstext">
                                        {{erro.rota}}
                                    </div>
                                    <div class="col-md-4 fontstext">
                                        {{erro.description}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile" class="col-md-2 fontstext">
                                        {{erro.userName}}
                                    </div>
                                    <div class="col-md-1 text-right ">
                                        <a nbButton size="tiny " outline status="primary " (click)="editReport(erro)"><img src="assets/icons/editBtn.png "></a>
                                        <a size="tiny" status="danger" (click)="deleteReport(erro.id)" nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png ">
                                        </a>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <mat-paginator [pageSize]="20" [length]="totalPageReport" [pageIndex]="pageNoReport" (page)="pageEventsReport($event)">

                        </mat-paginator>
                    </mat-tab>
                    <mat-tab class="col-md-3">
                        <ng-template mat-tab-label>
                            <span (click)="getRatingAll(0 , 20)">Avaliações</span>
                        </ng-template>

                        <div class="searchtxt">
                            <mat-form-field appearance="outline" class="width-90 mt-3" ngDefaultControl>
                                <input matInput placeholder="Buscar pelo nome do video" [(ngModel)]="filterRating">
                                <mat-icon (click)="getRatingAllByName(filterRating , 0 , 20)" matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>

                        <nb-list class="width-90 mt-2">
                            <nb-list-item class="corpadrao list-header" style="color: white ">
                                <div class="row">
                                    <div class="col-md-3">Nome do Video</div>
                                    <div class="col-md-5" *ngIf="!deviceService.isMobile">Descrição</div>
                                    <div class="col-md-1">Nota</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile">Aluno</div>
                                    <div class="col-md-1" *ngIf="!deviceService.isMobile">Ações</div>

                                </div>
                            </nb-list-item>

                            <nb-list-item *ngFor="let rating of ratingReports">
                                <div class="row" *ngIf="errorReports">
                                    <div class="col-md-3 fontstext">
                                        {{rating.elementName}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile" class="col-md-5 fontstext">
                                        {{rating.description}}
                                    </div>
                                    <div class="col-md-1 fontstext">
                                        {{rating.starScore}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile" class="col-md-2 fontstext">
                                        {{rating.userName}}
                                    </div>
                                    <div class="col-md-1 text-right ">
                                        <a nbButton size="tiny " outline status="primary " (click)="editRating(rating)"><img src="assets/icons/editBtn.png "></a>
                                        <a size="tiny" status="danger" (click)="deleteRating(rating.id)" nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png ">
                                        </a>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <mat-paginator [pageSize]="20" [length]="totalPageRating" [pageIndex]="pageNoRating" (page)="pageEventsRating($event)">

                        </mat-paginator>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </nb-card-body>
    </nb-card>
</div>