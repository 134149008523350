import 'hammerjs';
import * as Sentry from "@sentry/angular";

import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER,ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';


import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnimationOptions } from 'ngx-lottie';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbLayoutModule, NbSpinnerModule, NbThemeModule } from '@nebular/theme';
import { IntercomModule } from 'ng-intercom';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { AuthModule } from './core/auth/auth.module';
import { NetworkModule } from './core/components/network/network.module';
import { HeaderModule } from './core/header/header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from './core/themes/theme.module';
import { AdminModule } from './modules/admin/admin.module';
import { CalendarModule } from './modules/calendar/calendar.module';
import { ClassroomModule } from './modules/classroom/classroom.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { DisciplineModule } from './modules/discipline/discipline.module';
import { ExercisesListModule } from './modules/exercises-list/exercises-list.module';
import { ExperimentModule } from './modules/experiment/experiment.module';
import { ForumModule } from './modules/forum/forum.module';
import { MockModule } from './modules/mock/mock.module';
import { PaymentModule } from './modules/payment/payment.module';
import { PodcastModule } from './modules/podcast/podcast.module';
import { RedactionModule } from './modules/redaction/redaction.module';

import { TaskStudentModule } from './modules/task-student/task-student.module';

import { ReportModule } from './modules/report/report.module';
import { RepositoryModule } from './modules/repository/repository.module';
import { StudyPlanModule } from './modules/study-plan/study-plan.module';
import { UserModule } from './modules/user/user.module';
import { VideoModule } from './modules/video/video.module';
import { CustomDateAdapter } from './shared/CustomDateAdapter';
import { SharedModule } from './shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    NbAccordionModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbListModule,
} from '@nebular/theme';
import { TextMaskModule } from 'angular2-text-mask';
import { MatListModule } from '@angular/material/list';
import { LottieModule } from 'ngx-lottie';
import { TutorMundiModule } from './modules/tutor-mundi/tutor-mundi.module';
import { TeacherViewPlansModule } from './modules/teacher-view-plans/teacher-view-plans.module';
import { Router } from '@angular/router';
import { FooterModule } from './core/footer/footer.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReportErrorModule } from './modules/report-error/report-error.module';
import {StarRatingModule} from './modules/star-rating/star-rating.module';
import { ModalVideoLayoutComponent } from './core/components/modals/modal-video-layout/modal-video-layout.component';
import { CommunityModule } from './modules/community/community/community.module';
import { OpenFileModalModule } from './modules/open-file-modal/open-file-modal.module';
import { NotificationsModule } from './core/components/notifications/notifications.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenuNotificationComponent } from './modules/menu-notification/menu-notification.component';
import { IntegrationPortalComponent } from './core/components/integration-portal/integration-portal.component';
import { CertificatesComponent } from './modules/certificates/certificates.component';
import { DashboardComponent } from './modules/admin/dashboard/dashboard.component';
import { SlideFileModule } from './modules/slide-file/slide-file.module';
import { StudentsComponent } from './modules/admin/dashboard/reports/students/students.component';
import { MockComponent } from './modules/admin/dashboard/reports/mock/mock.component';

export function playerFactory() {
    return import('lottie-web');
}

registerLocaleData(localePt, 'pt');

@NgModule({
    declarations: [AppComponent, ModalVideoLayoutComponent, MenuNotificationComponent, CertificatesComponent, IntegrationPortalComponent, DashboardComponent, StudentsComponent, MockComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HeaderModule,
        AuthModule,
        DashboardModule,
        VideoModule,
        RedactionModule,
        TaskStudentModule,
        NbThemeModule.forRoot({ name: 'strong-blue' }),
        NbLayoutModule,
        NbEvaIconsModule,
        UserModule,
        AdminModule,
        ThemeModule.forRoot(),
        SharedModule,
        NbSpinnerModule,
        MatProgressSpinnerModule,
        LayoutModule,
        PodcastModule,
        ExercisesListModule,
        DisciplineModule,
        ForumModule,
        MockModule,
        PaymentModule,
        RepositoryModule,
        ReportModule,
        ExperimentModule,
        ClassroomModule,
        CalendarModule,
        StudyPlanModule,
        NetworkModule,
        NbCardModule,
        NbButtonModule,
        NbAccordionModule,
        MatFormFieldModule,
        MatTabsModule,
        MatListModule,
        MatTableModule,
        MatNativeDateModule,
        NbIconModule,
        NbListModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        TextMaskModule,
        FormsModule,
        MatDialogModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTooltipModule,
        LottieModule.forRoot({ player: playerFactory }),
        TutorMundiModule,
        TeacherViewPlansModule,
        FooterModule,
        NgxPaginationModule,
        ReportErrorModule,
        StarRatingModule,
        CommunityModule,
        OpenFileModalModule,
        NotificationsModule,
        MatToolbarModule,
        SlideFileModule,
        CommonModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: LOCALE_ID, useValue: 'pt' },
        Title,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },{
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
