<div *ngIf="!showClassesList">
    <form [formGroup]="createClassForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Turma</h4>
        <div *ngIf="remove">
            <h4 class="mt-3 mb-3">Excluir Turma </h4>
            <h6 class="mt-3 mb-3">Total de alunos: {{ total }}</h6>
            <p class="mt-3 mb-3"><b>AVISO: </b> <span class="">Turma vazia, você já pode excluir!</span></p>
            <p class="mt-3 mb-3"><b>AVISO: </b> <span class="danger">Esta turma não está vazia, você precisa migrar os alunos para poder limpar a turma e excluir ela.</span></p>
        </div>
        <!-- Name, TAG -->
        <div class="row">
            <div *ngIf="!remove" [ngClass]="{'col-md-6': !userService.isUnitCoordinator(), 'col-md-12': userService.isUnitCoordinator()}">                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" (selectionChange)="getUnits($event.value)" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-6" *ngIf="!userService.isUnitCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="unit" placeholder="Unidade">
                        <mat-option *ngFor="let unit of units" [value]="unit.id.toString()">
                            <div class="row">
                                <div class="col-10">
                                    {{unit.name}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="!remove" [ngClass]="{'col-md-6': !userService.isUnitCoordinator(), 'col-md-12': userService.isUnitCoordinator()}">
                <mat-form-field class="width-100">
                    <mat-select formControlName="plans" placeholder="Planos">
                        <mat-option (click)="addPlanChip(plan)" *ngFor="let plan of plans" [value]="plan.id">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-chip-list *ngIf="selectedPlans">
                    <mat-chip *ngFor="let planChip of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(planChip)">
                        {{planChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row" *ngIf="remove">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="name" placeholder="Turma" (selectionChange)="getClassesSelect()">
                        <mat-option *ngFor="let class of classes">
                            <div class="row">
                                <div class="col-10">
                                    {{class.name}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row" *ngIf="remove">
        <div class="col-12 width-100 text-right">
            <button (click)="migrate()" nbButton size="small" outline status="primary">Migrar Turma</button>
        </div>
        <div class="col-12 width-100 text-center">
            <button nbButton size="small" outline status="primary">Excluir Turma</button>
        </div>
    </div>

    <div class="row" *ngIf="editMode">
        <div class="col-md-6" *ngIf="classe.active == 1">
            <button mat-raised-button (click)="disableClass(classe.id)" color="warn">Desativar Turma <mat-icon>
                    visibility_off</mat-icon></button>
        </div>
        <div class="col-md-6" *ngIf="classe.active == 0">
            <button mat-raised-button (click)="enableClass(classe.id)" color="accent">Ativar Turma <mat-icon>
                    visibility</mat-icon></button>
        </div>
    </div>

    <div *ngIf="!editMode && !remove" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Turmas
                cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createClass()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>
    <div *ngIf="editMode" class="row mt-5">
        <div *ngIf="editMode" class="text-left col-6">
            <button (click)="duplicateClass()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Duplicar</button>
        </div>

        <div *ngIf="editMode" class="text-right col-6">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </div>
</div>

<div *ngIf="showClassesList" class="">
    <nb-card size="medium">
        <nb-card-header>
            <div class="row mt-3">
                <div class="col-md-6" *ngIf="userService.isAdmin()">
                    <mat-form-field class="width-100" appearance="outline">
                        <mat-label>Escola</mat-label>
                        <mat-select (selectionChange)="getUnits($event.value)" placeholder="Escola">
                            <mat-option *ngFor="let school of schools" [value]="school.id">
                                {{school.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6" *ngIf="!userService.isUnitCoordinator()">
                    <mat-form-field class="width-100" appearance="outline">
                        <mat-select (selectionChange)="getClasses($event.value)" placeholder="Unidade">
                            <mat-option *ngFor="let unit of units" [value]="unit.id">
                                <div class="row">
                                    <div class="col-10">
                                        {{unit.name}}
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body class="no-p">
            <nb-list *ngIf="!loading" class="width-100 mt-3 center">
                <nb-list-item *ngFor="let class of classes">
                    <div class="row">
                        <div class="col-4">
                            {{class.name}}
                        </div>
                        <div class="col-4">
                            criado em {{class.createDate | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="col-2 text-right">
                            <button (click)="editClass(class)" nbButton size="tiny" outline status="primary">Editar</button>
                        </div>

                        <div class="col-2 text-right">
                            <button size="small" (click)="deleteClass(class)" status="danger" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Excluir turma">
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>

                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
        <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
    </nb-card>
    <div class="text-right width-100">
        <button (click)="showClassesList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>