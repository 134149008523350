<div>
    <form [formGroup]="editMockForm">
        <div class="row">
            <h3 class="col-md-6">Editar</h3>
        </div>
        <div class="row">
            <h6 class="col-md-6">Nome : {{student.name}}</h6>
        </div>

        <div class="row">

            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-label>Escola (obrigatório)</mat-label>
                    <mat-select (selectionChange)="getPlans($event.value)" formControlName="schoolId">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" #filterPlan (selectionChange)="getPlanMocks($event.value)" formControlName="planId">
                        <mat-option value="0">Todas</mat-option>
                        <mat-option *ngFor="let plan of plans " [value]="plan.id ">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Simulado" formControlName="mockId">
                        <mat-option *ngFor="let mock of mocks" [value]="mock.id" (click)="addMockChip(mock)">
                            {{mock.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-chip-list *ngIf="selectedMocks">
                    <mat-chip *ngFor="let mock of selectedMocks" selectable="true" removable="true" (removed)="removeMockChip(mock)">
                        {{mock.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </form>
    <div class="row mt-5 text-right">
        <button (click)="updateMocksStudentsAssociated()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" nbSpinnerStatus="warning" [disabled]="loading">Salvar</button>
    </div>
</div>