<mat-spinner *ngIf="loading" class="center" color="accent"></mat-spinner>
<div *ngIf="schedule && !loading" class="full-width">
    <mat-tab-group style="margin-top: 26px" [selectedIndex]="tabIndex" backgroundColor="primary" color="accent" (selectedIndexChange)="handleTabIndexChange($event)">
        <mat-tab *ngFor="let scheduleMonth of months; let i= index" [label]="scheduleMonth.month | uppercase">
            <div class="row full-width header center">
                <div class="col cel-header cel-first bg-color-accent col-mobile-week">DOM</div>
                <div class="col cel-header bg-color-primary col-mobile-week">SEG</div>
                <div class="col cel-header bg-color-primary col-mobile-week">TER</div>
                <div class="col cel-header bg-color-primary col-mobile-week">QUA</div>
                <div class="col cel-header bg-color-primary col-mobile-week">QUI</div>
                <div class="col cel-header bg-color-primary col-mobile-week">SEX</div>
                <div class="col cel-header cel-last bg-color-accent col-mobile-week">SAB</div>
            </div>
            <div *ngFor="let row of scheduleMonthsGroup[i]" class="row full-width center">

              <div *ngFor="let day of row; let i = index" class="col cel" [ngClass]="{
                    'today': day?.date == today,
                    'bg-color-accent': (i % 7 == 0 || i % 6 === 0),
                    'cel-first': i % 7 == 0,
                    'cel-last': i % 6 == 0
                    }">

                    <div class="mb-2 day">{{day?.day}} </div>

                    <ng-container *ngIf="releaseCalendarVideosUpToday">
                      <ng-container *ngIf="monthsOfYear[scheduleMonth.month.toLowerCase()] < actualMonth ||
                        (monthsOfYear[scheduleMonth.month] === actualMonth && day?.day <= dayOfToday)">

                          <div *ngIf="day?.remaining > 0; else complete" class="remaining center text-center mb-3" [ngClass]="{'hidden-mobile': layoutService.isMobile}">
                            <!--  {{day?.remaining}} não assistidos -->
                            <span class="remanning-count font-size-20 foin">{{day?.remaining}}</span>
                            <span class="material-icons-outlined font-size-20" title="{{day?.remaining}} não assistidos">
                                visibility_off
                            </span>
                          </div>
                          <div *ngIf="day?.remaining == 0; else complete" class="remaining center text-center mb-3" [ngClass]="{'hidden-mobile': layoutService.isMobile}">
                              <!--  Dia assistidos -->
                              <span class="material-icons-outlined color-green font-size-40" title="Dia assistido">
                                  task_alt
                                </span>
                          </div>
                          <mat-chip-list *ngIf="!mobileQuery.matches || !layoutService.isMobile">
                              <mat-chip *ngFor="let tag of day?.disciplineTags; let i = index" class="hvr-grow mr-2" (click)="openDaySchedule(day, i)" color="primary" selected>
                                  {{tag}}
                              </mat-chip>
                              <ng-template #complete>
                                  <mat-chip *ngIf="day?.remaining == 0" class="hvr-grow mr-2 mb-2 remaining" color="accent" selected>
                                      Dia assistido
                                  </mat-chip>
                              </ng-template>
                          </mat-chip-list>

                          <!-- classrooms -->
                          <ng-container *ngIf="!mobileQuery.matches && isToday(day?.date) && classrooms?.length" class="text-center">
                              <div class="live mt-3" *ngIf="isToday(day?.date)">
                                  <mat-icon color="warn" class="mr-1">podcasts</mat-icon>
                                  <span class="remaining mb-2 mt-2" [hidden]="layoutService.isMobile">Ao vivo</span>
                              </div>
                              <!--comment-->
                              <mat-chip-list *ngIf="isToday(day?.date)">
                                  <mat-chip *ngFor="let classroom of classrooms" class="hover-grow mr-2" color="warn" selected (click)="openLink(classroom.link)">
                                      {{classroom.tag}}
                                  </mat-chip>
                              </mat-chip-list>
                          </ng-container>

                          <!-- recorded classrooms -->
                          <ng-container *ngIf="!mobileQuery.matches && day?.haveRecordLink">
                              <mat-divider class="mt-2 mb-2"></mat-divider>
                              <button class="full-width hvr-grow recorded-btn small-btn mt-3" mat-raised-button (click)="openRecordedClassroomsDay(day?.date)" color="accent" matTooltipClass="custom-tooltip" matTooltip="Clique para abrir a lista com os links das aulas ao vivo que foram gravadas">Transmissões gravadas</button>
                          </ng-container>

                          <!-- mobile calendar -->
                          <mat-chip-list *ngIf="mobileQuery.matches" class="align-center">
                              <mat-chip *ngIf="day?.remaining > 0; else complete" matTooltipClass="custom-tooltip" matTooltip="Videos não assistidos" class="hvr-grow mr-2" (click)="openDaySchedule(day, 0)" color="primary" selected>
                                  {{day?.remaining}}
                              </mat-chip>
                              <span *ngIf="day?.haveRecordLink" (click)="openRecordedClassroomsDay(day?.date)" class="material-icons-outlined pl-2" color="primary" selected>
                                  live_tv
                                  </span>
                              <ng-template #complete>
                                  <mat-chip *ngIf="day?.remaining == 0" matTooltipClass="custom-tooltip" matTooltip="Todos os vídeos assistidos" class="hvr-grow mr-2" (click)="openDaySchedule(day, 0)" color="accent" selected>
                                      <nb-icon icon="checkmark-circle-outline"></nb-icon>
                                  </mat-chip>
                              </ng-template>
                          </mat-chip-list>

                      </ng-container>
                    </ng-container>


                    <ng-container *ngIf="!releaseCalendarVideosUpToday">

                      <div *ngIf="day?.remaining > 0; else complete" class="remaining center text-center mb-3" [ngClass]="{'hidden-mobile': layoutService.isMobile}">
                        <!--  {{day?.remaining}} não assistidos -->
                        <span class="remanning-count font-size-20 foin">{{day?.remaining}}</span>
                        <span class="material-icons-outlined font-size-20" title="{{day?.remaining}} não assistidos">
                            visibility_off
                        </span>
                      </div>
                      <div *ngIf="day?.remaining == 0; else complete" class="remaining center text-center mb-3" [ngClass]="{'hidden-mobile': layoutService.isMobile}">
                          <!--  Dia assistidos -->
                          <span class="material-icons-outlined color-green font-size-40" title="Dia assistido">
                              task_alt
                            </span>
                      </div>
                      <mat-chip-list *ngIf="!mobileQuery.matches || !layoutService.isMobile">
                          <mat-chip *ngFor="let tag of day?.disciplineTags; let i = index" class="hvr-grow mr-2" (click)="openDaySchedule(day, i)" color="primary" selected>
                              {{tag}}
                          </mat-chip>
                          <ng-template #complete>
                              <mat-chip *ngIf="day?.remaining == 0" class="hvr-grow mr-2 mb-2 remaining" color="accent" selected>
                                  Dia assistido
                              </mat-chip>
                          </ng-template>
                      </mat-chip-list>

                      <!-- classrooms -->
                      <ng-container *ngIf="!mobileQuery.matches && isToday(day?.date) && classrooms?.length" class="text-center">
                          <div class="live mt-3" *ngIf="isToday(day?.date)">
                              <mat-icon color="warn" class="mr-1">podcasts</mat-icon>
                              <span class="remaining mb-2 mt-2" [hidden]="layoutService.isMobile">Ao vivo</span>
                          </div>
                          <!--comment-->
                          <mat-chip-list *ngIf="isToday(day?.date)">
                              <mat-chip *ngFor="let classroom of classrooms" class="hover-grow mr-2" color="warn" selected (click)="openLink(classroom.link)">
                                  {{classroom.tag}}
                              </mat-chip>
                          </mat-chip-list>
                      </ng-container>

                      <!-- recorded classrooms -->
                      <ng-container *ngIf="!mobileQuery.matches && day?.haveRecordLink">
                          <mat-divider class="mt-2 mb-2"></mat-divider>
                          <button class="full-width hvr-grow recorded-btn small-btn mt-3" mat-raised-button (click)="openRecordedClassroomsDay(day?.date)" color="accent" matTooltipClass="custom-tooltip" matTooltip="Clique para abrir a lista com os links das aulas ao vivo que foram gravadas">Transmissões gravadas</button>
                      </ng-container>

                      <!-- mobile calendar -->
                      <mat-chip-list *ngIf="mobileQuery.matches" class="align-center">
                          <mat-chip *ngIf="day?.remaining > 0; else complete" matTooltipClass="custom-tooltip" matTooltip="Videos não assistidos" class="hvr-grow mr-2" (click)="openDaySchedule(day, 0)" color="primary" selected>
                              {{day?.remaining}}
                          </mat-chip>
                          <span *ngIf="day?.haveRecordLink" (click)="openRecordedClassroomsDay(day?.date)" class="material-icons-outlined pl-2" color="primary" selected>
                              live_tv
                              </span>
                          <ng-template #complete>
                              <mat-chip *ngIf="day?.remaining == 0" matTooltipClass="custom-tooltip" matTooltip="Todos os vídeos assistidos" class="hvr-grow mr-2" (click)="openDaySchedule(day, 0)" color="accent" selected>
                                  <nb-icon icon="checkmark-circle-outline"></nb-icon>
                              </mat-chip>
                          </ng-template>
                      </mat-chip-list>

                    </ng-container>

              </div>

            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<span class="small" style="float: right;">v1</span>
