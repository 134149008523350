import { AlertService } from "src/app/shared/services/alert.service";
import { Channel } from "../../channel";
import { ChannelService } from "../../channel.service";
import { ManageChannelController } from "../Controller/ManageChannel.controller";
import { CreateChannel } from "../CreateChannel";
import { ListChannel } from "../ListChannels";

export class ManageChannelFactory{

    public static newManageChannelController(  
        channelService: ChannelService,
        alertService:AlertService,
        channel:Channel[]) {

        return new ManageChannelController(channelService,alertService,channel);
    }

    public static newCreateChannel(  
        channelService:ChannelService,
        alertService:AlertService,
        channel:Channel) {

        return new CreateChannel(channelService,alertService,channel);
    }

    public static newListChannel(  
        channelService:ChannelService,
        alertService:AlertService,
        schoolId:number) {

        return new ListChannel(channelService,alertService,schoolId);
        
    } 

    // public static removeChannel(  
    //     channelService:ChannelService,
    //     alertService:AlertService,
    //     channelId:number) {
    //     return new CreateChannel(channelService,alertService,channelId);
    // }

}