<div>      
    <div *ngIf=" !removeMode  && !editMode && !loading">
        <nb-card size="medium">
            <nb-card-header *ngIf="userService.isAdmin()">
                <h5 class="m-2" >
                    Professores 
                    
                </h5>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Escola"  (selectionChange)="getUnits($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100">
                                <mat-select  placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                                    <mat-option *ngFor="let unit of units" [value]="unit.id.toString()">
                                        <div class="row">
                                            <div class="col-10">
                                                {{unit.name}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100">
                                <mat-select   placeholder="Turma" 
                                (selectionChange)="getTeachers($event.value)">
                                    <mat-option *ngFor="let class of classes" [value]="class.id">
                                        <div class="row">
                                            <div class="col-10">
                                                {{class.name}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="teachers" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let teacher of teachers">
                        <div class="row">
                                <div class="col-8">
                                    {{teacher.name}} - {{teacher.email}}
                                </div> 
                                <div class="col-2">  
                                    <button size="tiny"  
                                            (click)="unSyncMode(teacher)"  nbButton ghost>
                                        <span class="material-icons color-status-success">
                                            alarm_on 
                                        </span> 
                                    </button>
                                </div>
                                <div class="col-2">  
                                    <button size="tiny"   
                                            (click)="syncMode(teacher)"   nbButton ghost> 
                                        <span class="material-icons color-status-danger" >
                                            alarm  
                                        </span>
                                    </button>
                                </div>
                        </div>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>  
    <div *ngIf="editMode && !loading && !removeMode" class="">
        <nb-card size="large">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Sincronizar Professores: {{synTeacher.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui os <b>PROF.</b>  da unidade serão  <b>SINCRONIZADOS</b>  ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <h5>Professores</h5>
                <div class="col-6 text-left width-100 m-0">
                    <span class="color-status-primary  mb-2"  >Status: 
                        <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                        </span> 
                        <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                    </span>
                    
                </div> 
            
                <div class="text-right width-100">
                    <button (click)="syncTeacher(syncClassesTeacher)" class="right mr-2" 
                            nbButton size="small" status="primary">Sincronizar</button>
                    <button (click)="editMode = false; getTeachers(actualClassId);" class="right" 
                    nbButton size="small" status="primary">voltar</button>
                </div>
                <nb-list *ngIf="syncClassesTeacher" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let teacher of syncClassesTeacher">
                        <div class="row">
                            <div class="col-6">
                            {{teacher.className}}
                            </div> 
                            <div class="col-6" *ngIf="teacher.status == 1">
                                <span class="material-icons color-status-success"  status="success">
                                    done_all
                                </span>
                            
                            </div>
                            <div class="col-6" *ngIf="teacher.status == 0">
                                <span class="material-icons color-status-danger"  status="success">
                                    clear
                                </span>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list> 
                
            </nb-card-body>
        </nb-card>

        <div class="text-right width-100">
            <button (click)="editMode = false; getTeachers(actualClassId);" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
    <div *ngIf="removeMode && !loading && !editMode" class="">
        <nb-card size="large">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Remover Professores: {{synTeacher.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui os <b>PROF.</b>  da unidade serão <b>REMOVIDOS</b> ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <h5>Professores</h5>
                <div class="col-6 text-left width-100 m-0">
                    <span class="color-status-primary  mb-2"  >Status: 
                        <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                        </span> 
                        <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                    </span>
                    
                </div>  
                <div class="text-right width-100"> 
                    <button (click)="unSyncTeacher(unSyncClassesTeacher)" class="right mr-2" 
                            nbButton size="small" status="primary">Remover</button>
                           
                    <button (click)="removeMode = false;getTeachers(actualClassId);" class="right" 
                            nbButton size="small" status="primary">voltar</button>
                           
                </div>
                <nb-list *ngIf="unSyncClassesTeacher" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let teacher of unSyncClassesTeacher">
                        <div class="row">
                            <div class="col-6">
                            {{teacher.className}}
                            </div> 
                            <div class="col-6" *ngIf="teacher.status == 1">
                                <span class="material-icons color-status-success"  status="success">
                                    done_all
                                </span>
                            
                            </div>
                            <div class="col-6" *ngIf="teacher.status == 0">
                                <span class="material-icons color-status-danger"  status="success">
                                    clear
                                </span>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
                
            </nb-card-body>
        </nb-card>

        <div class="text-right width-100">
            <button (click)="removeMode = false;getTeachers(actualClassId);" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
</div>
 