import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Experiment } from '../admin/experiment/experiment';
import { ExperimentUser } from '../admin/experiment/user/experiment-user';
import { tap } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from '../user/user.service';
import { SystemVersion } from 'src/app/core/models/system-version';

@Injectable({ providedIn: 'root' })
export class ExperimentService {

    private API = environment.ApiUrl;

    constructor(
        private http: HttpClient,
        private userService: UserService) { }

    signup(name: string, email: string, password: string, planId: number) {
        return this.http.post(this.API + '/experiment/signup', { name, email, password, planId }, { observe: 'response' });
    }

    setVersionSystem() {
        this.http.get(this.API + '/system/version', { observe: 'response' })
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {

                    const version = response.data as SystemVersion;
                    window.localStorage.setItem(btoa('System Version'), btoa(JSON.stringify(version)));
                }
            });
    }

    create(experiment: Experiment) {
        return this.http.post(this.API + '/experiment/create', experiment, { observe: 'response' });
    }

    saveChanges(experiment: Experiment) {
        return this.http.post(this.API + '/experiment/saveChanges', experiment, { observe: 'response' });
    }

    getExperiments() {
        return this.http.post(this.API + '/experiment/getExperiments', {}, { observe: 'response' });
    }

    removeExperiments(experimentId: number) {
        return this.http.post(this.API + '/experiment/remove', { experimentId }, { observe: 'response' });
    }

    getExperimentUsers(planId?: number) {
        return this.http.post(this.API + '/experiment/getExperimentUsers', { planId }, { observe: 'response' });
    }

    removeExperimentUser(experimentUserId: number) {
        return this.http.post(this.API + '/experiment/removeExperimentUser', { experimentUserId }, { observe: 'response' });
    }

    createExperimentUser(experimentUser: ExperimentUser) {
        return this.http.post(this.API + '/experiment/createExperimentUser', experimentUser, { observe: 'response' });
    }

    getExperimentHash(experimentHash: string) {
        return this.http.post(this.API + '/experiment/getExperimentHash', { experimentHash }, { observe: 'response' });
    }
}
