// 1 - voltar para list channels
// 

import { Component, OnInit, Optional, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, Form } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { consoleSandbox } from '@sentry/utils';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Classroom } from 'src/app/modules/classroom/classroom';
import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { FlipBookService } from 'src/app/modules/flip-book/flip-book.service';
import { Plan } from 'src/app/modules/payment/plan';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../../user/user.service';
import { AdminService } from '../../admin.service';
import { Folder } from '../../repository/folder/folder';
import { Class, ClassSelect } from '../../school/class';
import { School, SchoolSelect } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit, UnitSelect } from '../../school/unit';
import { StudyPlan, StudyPlansSelect } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { Channel, ChannelClassrooms, ChannelEbooks, ChannelFolders, ChannelPlans, ClassRoomSelect, EbookSelect, FolderSelect } from '../channel';
import { Ebook } from '../../flip-book-admin/flipBook';
import { MatTableDataSource } from '@angular/material/table';
import { PlanSelect } from '../../rdo-manager/create-theme/theme';

import { RepositoryFolderAddComponent } from '../../repository/folder/repository-folder-add.component';
import { MaterialAddComponent } from '../../repository/material/material-add.component';
import { ClassroomCreateComponent } from '../../classroom/create/classroom-create.component';
import { ClassroomCreateByClassComponent } from '../../classroom/create-by-class/classroom-create-by-class.component';
import { FlipBookAdminComponent } from '../../flip-book-admin/flip-book-admin.component';
import { ChannelService } from '../channel.service';
import { runInThisContext } from 'vm';
import { ManageChannelFactory } from '../Channels/Factories/ManageChannel.factory';
import { ChannelModel } from '../Channels/Models/Channel.model';
import { ManageChannelController } from '../Channels/Controller/ManageChannel.controller';
import { CreateClassComponent } from '../../school/create-class/create-class.component';
import { UserSelect } from '../../notifications/users';
import { StudentService } from '../../student/student.service';
import { CreateStudentComponent } from '../../student/create/create-student.component';
import { TaskManagerService } from '../../task-manager/task-manager.service';
import { SubmitTaskComponent } from '../../task-manager/submit-task/submit-task.component';


@Component({
  selector: 'app-edit-channel',
  templateUrl: './edit-channel.component.html',
  styleUrls: ['./edit-channel.component.scss'],
})
export class EditChannelComponent implements OnInit {

  public admUpdate$: any;
  public channelController: ManageChannelController;
  public expandPanel: number;

  public screen:string = 'formChannel';
  public channelId: number;
  public schools: SchoolSelect[];
  public units: UnitSelect[];
  public selectedSchool: number;
  public plans: PlanSelect[];
  public classes: ClassSelect[];
  public ebooks: Ebook[];
  public tasks: Task[];
  public classrooms: ClassRoomSelect[];
  public folders: FolderSelect[];
  public users: UserSelect[];
  public showRoutePlan: any;

  public formChannel: FormGroup; 
  public formPlan: FormGroup;
  public formFolder: FormGroup;
  public formLive: FormGroup;
  public formEbook: FormGroup;
  public formTask: FormGroup;
  studentService: any;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private adminService: AdminService,
    private channelService: ChannelService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private studyPlanService: StudyPlanService,
    private repositoryService: RepositoryService,
    private classroomService: ClassroomService,
    private ebookService: FlipBookService,
    private taskManagerService: TaskManagerService,
  ) { }
  
  @Input()  channelEdit: ChannelModel;
  @Output() channelEdited = new EventEmitter();
  @Output() backListChannels = new EventEmitter();

  ngOnInit() {

    this.admUpdate$ = this.adminService.getUpdateSubject()
    .subscribe(() => this.init()); 

    this.expandExpansionPanel();

    //this.getSchoolSelection();
    this.gettersByUser();
    
  }

  init() {

    this.loadChannelData();

    this.formChannel = this.formBuilder.group({
      id: [this.channelEdit.getChannel().id],
      name: [this.channelEdit.getChannel().name] 
    });
     
    this.formPlan = this.formBuilder.group({
      id: [],
      routePlanName: [''] 
    });
    this.formFolder = this.formBuilder.group({
      id: [],
      routeFolderName: [''] 
    });
    this.formLive = this.formBuilder.group({
      id: [],
      routeLivesName: [''] 
    });
    this.formEbook = this.formBuilder.group({
      id: [],
      routeEbooksName: [''] 
    });
    this.formTask = this.formBuilder.group({
      id: [],
      routeTasksName: [''] 
    });
  }

  loadChannelData(){
    this.channelService.getChannel(this.channelEdit.getChannel().id)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error){
        let channel = response.data as Channel;
        this.channelEdit.setChannelClassrooms(channel.classrooms);
        this.channelEdit.setChannelEbooks(channel.ebooks);
        this.channelEdit.setChannelTasks(channel.tasks);
        this.channelEdit.setChannelFolders(channel.folders);
        this.channelEdit.setChannelPlans(channel.plans);
        this.channelEdit.setChannelClasses(channel.classes);
        this.channelEdit.setChannelUsers(channel.users);
        this.channelEdit.setChannelRoutePlan(channel.routePlan);
        this.channelEdit.setChannelRouteFolder(channel.routeFolder);
        this.channelEdit.setChannelRouteLive(channel.routeLive);
        this.channelEdit.setChannelRouteEbook(channel.routeEbook);
        this.channelEdit.setChannelRouteTask(channel.routeTask);
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));

  }

  gettersByUser(){
    if (this.userService.isAdmin())  {
      this.getSchoolSelection();
    } else if (this.userService.isGeneralCoordinator()) {
      this.getSchoolSelection(this.userService.getUserSchool()); 
      this.getUnitsSelection(this.userService.getUserSchool()); 
      this.getPlans(this.userService.getUserSchool())
    } else if (this.userService.isUnitCoordinator()) {
      this.getClassesSelection(this.userService.getUserUnit());
      this.getUnitPlans(this.userService.getUserUnit());
      this.getPlans(this.userService.getUserSchool())
    }
  }
  //Screen
  changeScreen(screen:string){
    this.screen = screen;
  }
  openFolderModal() {
    this.dialog.open(RepositoryFolderAddComponent, {
      minWidth: '60vw',
    });
  }
  openFolderMaterialModal(){
    this.dialog.open(MaterialAddComponent, {
      minWidth: '60vw',
      data: null
    });
  }
  openEbookModal(){
    this.dialog.open(FlipBookAdminComponent, {
      minWidth: '60vw',
      data: null
    });
  }
  openTaskModal(){
    this.dialog.open(SubmitTaskComponent, {
      minWidth: '60vw',
      data: null
    });
  }
  openClassroomPlanModal(){
    this.dialog.open(ClassroomCreateComponent, {
      minWidth: '60vw',
      data: null
    });
  }
  openClassroomClassModal(){
    this.dialog.open(ClassroomCreateByClassComponent, {
      minWidth: '60vw',
      data: null
    });
  }
  openClassModal(){
    this.dialog.open(CreateClassComponent, {
      minWidth: '60vw',
      data: null
    });
  }
  openUserModal(){
    this.dialog.open(CreateStudentComponent, {
      minWidth: '60vw',
      data: null
    });
  }

  expandExpansionPanel() {
    setInterval(() => { 
      this.expandPanel = 1
    }, 90);
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  } 



  /* Get selects INIT  */
  getSchoolSelection(schoolId?:number)
  {
    this.schoolService.getSchoolSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.schools = response.data as SchoolSelect[];
        else {
          this.schools = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente!'));
  }
  getUnitsSelection(schoolId?:number)
  {
    this.schoolService.getUnitsSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.units = response.data as UnitSelect[];
        else {
          this.units = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente!'));
  }

  getPlansSelection(schoolId?:number)
  {
      if (this.selectedSchool == 0) {
        this.schoolService.getPlansSelection()
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) this.plans = response.data as PlanSelect[];
          else {
            this.plans = [];
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'));
      } else {
        this.schoolService.getPlansSelection(schoolId)
          .subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) this.plans = response.data as PlanSelect[];
            else {
              this.plans = [];
              this.alertService.error(response.error);
            }
          }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'));
      }
  }

  getPlans(schoolId: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlansSelect[];
        }
        else {
          this.alertService.error('não existem planos cadastrados nessa escola!');
        }
      }, err => {
        this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
      })
  }

  getClassesSelection(unitId?:number)
  {
    this.schoolService.getClassesSelection(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classes = response.data as ClassSelect[];
        else {
          this.classes = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!'));
  }
  getClassPlans(classId: number)
  {
    this.studyPlanService.getClassPlans(classId)
    .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
            this.plans = response.data as PlanSelect[];
        } else {
            this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao carregar os planos da turma. Verifique a conexão e tente novamente'));
  }
  getEbooks(planId) 
  {
    this.ebookService.getEbooks(planId)
    .subscribe(res => {
        const response = res.body as any;
        if (!response.error) {
            this.ebooks = response as Ebook[];
        } else {
            this.ebooks = [];
            this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao carregar os e-books. Verifique a conexão e tente novamente'));
  }

  getTasksByClass(classId:number){
    this.channelService.getTasksByClass(classId)
    .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.tasks = response.data as Task[];
        else {
          this.tasks = [];
          this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente'));
  }

  getClassRoomsAdmin(planId, selectedSchool) {
    this.classroomService.getClassRoomsAdmin(planId, selectedSchool? selectedSchool : this.userService.getUserSchool())
    .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
            this.classrooms = response.data as ClassRoomSelect[];
        } else {
            this.classrooms = [];
            this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
  }

  getClassFolders(classId: number){
    this.repositoryService.getClassFolders(classId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.folders = response.data as Folder[];
        this.folders = this.folders.map(f => {
          f.id = Number(f.id);
          return f;
        })
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
  }

  getPlansFolders(planId: number) {
    // this.loading = true;
    this.repositoryService.getPlanFolders(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as FolderSelect[];
          this.folders = this.folders.map(f => {
            f.id = Number(f.id);
            return f;
          })
        } else {
          this.folders = [];
          this.alertService.error(response.error);
        }
        // this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        // this.loading = false;
      });
  }
  getUnitPlans(unitId: number) {
    if(unitId == 0) {
      this.gettersByUser()
      // this.getPlansSelection(this.selectedSchool)
    } else {
      this.studyPlanService.getUnitPlans(unitId)
        .subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) {
              this.plans = response.data as PlanSelect[];
            } else {
              this.plans = [];
              this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os planos das unidades. Verifique a conexão e tente novamente'));
    }
  }

  getStudents(classId: number) {
    // classId = this.userService.isGeneralCoordinator() ? this.userService.getUserSchool() : undefined;
    // this.loading = true;
    // this.classId = classId;
    this.studentService.getStudents(classId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.users = response.data as UserSelect[];
            } else {
                this.users = [];
                this.alertService.error(response.error);
            }
        }, err => {
            this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
        });
  }

  getStudentsByNameOrEmail(name?: string, email?: string) {

    if (!name && !email) {
        this.alertService.error('os campos devem ser prenchidos');
    }
    else {
        if (name && name.length < 3) {
            this.alertService.error('precisa-se no mínimo de 3 caracteres')
        }
        else if(name) {
            this.studentService.getStudentsByNameOrEmail(name, email)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.users = response.data as UserSelect[];
                    } else {
                        this.alertService.error(response.error);
                    }

                }, err => {
                    this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente');
                });
        }
        if (email && email.length < 3) {
            this.alertService.error('precisa-se no mínimo de 3 caracteres')
        }
        else if(email){
            this.studentService.getStudentsByNameOrEmail(name, email)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.users = response.data as UserSelect[];
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => {
                    this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente');
                });
        }
    }

  }

  /* CRUD OF CHANNEL */
  public saveChanelName(name){
    this.channelEdit.setChannelName(name)

    this.channelService.updateChannel( this.channelEdit.getChannel() ).subscribe(res => {

      const response = res.body as ResponseApi;

      if (!response.error) {
          this.channelService.emitChannels.emit({name:"channel", values:response.data})  
          this.alertService.success("Canal alterado com sucesso!");
      } else {
          this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao salvar o canal. Verifique a conexão e tente novamente'));
  }
 
  public saveChannelPlans(){  
    let channelPlans =  this.channelEdit.getChannelPlans();
    this.channelService.updateChannelPlans( this.channelEdit.getChannel().id,channelPlans ).subscribe(res => {

        const response = res.body as ResponseApi;

        if (!response.error) {
            this.channelService.emitChannels.emit({name:"channel", values:response.data})  
            this.alertService.success("Planos alterados com sucesso!");
        } else {
            this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao salvar os planos do canal. Verifique a conexão e tente novamente'));
  }



  public saveChannelFolders( ){
 
      let channelFolders = this.channelEdit.getChannelFolders();
      
      this.channelService.updateChannelFolders(this.channelEdit.getChannel().id,channelFolders).subscribe(res => {

          const response = res.body as ResponseApi;

          if (!response.error) {
            
              this.channelService.emitChannels.emit({name:"channel", values:response.data})  
              this.alertService.success("Pastas alteradas com sucesso!");
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao salvar as pastas do canal. Verifique a conexão e tente novamente'));
  }

  public saveChannelClassrooms(channelId:number){

      
      let channelClassrooms =  this.channelEdit.getChannelClassrooms(); 
      
      this.channelService.updateChannelClassrooms( this.channelEdit.getChannel().id,channelClassrooms ).subscribe(res => {

          const response = res.body as ResponseApi;

          if (!response.error) {
            
              this.channelService.emitChannels.emit({name:"channel", values:response.data})  
              this.alertService.success("Aulas ao vivo alteradas com sucesso!");
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao salvar as aulas ao vivo do canal. Verifique a conexão e tente novamente'));
  }

  public saveChannelEbooks(){
 
 
      let channelEbooks =   this.channelEdit.getChannelEbooks();
      
      this.channelService.updateChannelEbooks( this.channelEdit.getChannel().id,channelEbooks ).subscribe(res => {

          const response = res.body as ResponseApi;

          if (!response.error) {
            
              this.channelService.emitChannels.emit({name:"channel", values:response.data})  
              this.alertService.success("E-books alterados com sucesso!");
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao carregar os e-books do canal. Verifique a conexão e tente novamente'));
  } 

  public saveChannelClasses(){
    let channelClasses =   this.channelEdit.getChannelClasses();
    
    this.channelService.updateChannelClasses( this.channelEdit.getChannel().id,channelClasses ).subscribe(res => {

        const response = res.body as ResponseApi;

        if (!response.error) {
          
            this.channelService.emitChannels.emit({name:"channel", values:response.data})  
            this.alertService.success("Turmas associadas com sucesso!");
        } else {
            this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao carregar os e-books do canal. Verifique a conexão e tente novamente'));
  }

  public saveChannelTasks(){
    let channelTasks =   this.channelEdit.getChannelTasks();
    this.channelService.updateChannelTasks(this.channelEdit.getChannel().id, channelTasks ).subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {          
          this.channelService.emitChannels.emit({name:"channel", values:response.data})  
          this.alertService.success("Tarefas alteradas com sucesso!");
      } else this.alertService.error(response.error);
    }, err => this.alertService.error('Houve um erro ao carregar as tarefas do canal. Verifique a conexão e tente novamente'));
} 

  // ROUTES

  public savePlansRoute(){
    const routePlanName = this.formPlan.get('routePlanName').value;
    this.channelService.saveRoutePlans( this.channelEdit.getChannel().id, routePlanName ).subscribe(res => {
    const response = res.body as ResponseApi;
      if (!response.error) {
        this.channelService.emitChannels.emit({name:"channel", values:response.data})  
        this.alertService.success("Rota cadastrada com sucesso!");
        this.channelService.getChannel(this.channelEdit.getChannel().id)
          .subscribe(res => { 
            const respo = res.body as ResponseApi;
            if (!respo.error){
              let channel = respo.data as Channel;
              this.channelEdit.setChannelRoutePlan(channel.routePlan);
            } else {
              this.alertService.error(respo.error);
            }
          }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));
      }
      if (!response.error){
      } else { this.alertService.error(response.error); }
    }, err => this.alertService.error('Houve um erro ao salvar a rota dos planos do canal. Verifique a conexão e tente novamente'));
  }

  public saveFoldersRoute(){
    const routeFolderName = this.formFolder.get('routeFolderName').value;
    this.channelService.saveFoldersRoute( this.channelEdit.getChannel().id, routeFolderName ).subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.channelService.emitChannels.emit({name:"channel", values:response.data})  
        this.alertService.success("Rota cadastrada com sucesso!");
        this.channelService.getChannel(this.channelEdit.getChannel().id)
          .subscribe(res => { 
            const respo = res.body as ResponseApi;
            if (!respo.error){
              let channel = respo.data as Channel;
              this.channelEdit.setChannelRouteFolder(channel.routeFolder);
            } else {
              this.alertService.error(respo.error);
            }
          }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));
      } else { this.alertService.error(response.error); }
    }, err => this.alertService.error('Houve um erro ao salvar a rota das pastas do canal. Verifique a conexão e tente novamente'));
  }

  public saveLivesRoute(){
    const routeLivesName = this.formLive.get('routeLivesName').value;
    this.channelService.saveLivesRoute( this.channelEdit.getChannel().id, routeLivesName ).subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.channelService.emitChannels.emit({name:"channel", values:response.data})  
        this.alertService.success("Rota cadastrada com sucesso!");
        this.channelService.getChannel(this.channelEdit.getChannel().id)
          .subscribe(res => { 
            const respo = res.body as ResponseApi;
            if (!respo.error){
              let channel = respo.data as Channel;
              this.channelEdit.setChannelRouteLive(channel.routeLive);
            } else {
              this.alertService.error(respo.error);
            }
          }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));
      } else { this.alertService.error(response.error); }
    }, err => this.alertService.error('Houve um erro ao salvar a rota das aulas ao vivo do canal. Verifique a conexão e tente novamente'));
  }

  public saveEbooksRoute(){
    const routeEbooksName = this.formEbook.get('routeEbooksName').value;
    this.channelService.saveEbooksRoute( this.channelEdit.getChannel().id, routeEbooksName ).subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.channelService.emitChannels.emit({name:"channel", values:response.data})  
        this.alertService.success("Rota cadastrada com sucesso!");
        this.channelService.getChannel(this.channelEdit.getChannel().id)
          .subscribe(res => { 
            const respo = res.body as ResponseApi;
            if (!respo.error){
              let channel = respo.data as Channel;
              this.channelEdit.setChannelRouteEbook(channel.routeEbook);
            } else {
              this.alertService.error(respo.error);
            }
          }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));
      } else { this.alertService.error(response.error); }
    }, err => this.alertService.error('Houve um erro ao salvar a rota dos e-books do canal. Verifique a conexão e tente novamente'));
  }

  public saveTasksRoute(){
    const routeTasksName = this.formTask.get('routeTasksName').value;
    this.channelService.saveTasksRoute( this.channelEdit.getChannel().id, routeTasksName ).subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.channelService.emitChannels.emit({name:"channel", values:response.data})  
        this.alertService.success("Rota cadastrada com sucesso!");
        this.channelService.getChannel(this.channelEdit.getChannel().id)
          .subscribe(res => { 
            const respo = res.body as ResponseApi;
            if (!respo.error){
              let channel = respo.data as Channel;
              this.channelEdit.setChannelRouteTask(channel.routeEbook);
            } else {
              this.alertService.error(respo.error);
            }
          }, err => this.alertService.error('Houve um erro ao buscar o canal. Verifique a conexão e tente novamente!'));
      } else { this.alertService.error(response.error); }
    }, err => this.alertService.error('Houve um erro ao salvar a rota das tarefas do canal. Verifique a conexão e tente novamente'));
  }

  removeRoute(rota) {
    console.log(rota)
    if (confirm('Ao desativar a rota o nome será perdido, deseja realmente excluir a rota?')) {
      this.channelService.removeRoute(rota.rota_id)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.alertService.success('Rota removida!', 3);
            this.adminService.updateChields();
            
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a rota. Verifique a conexão e tente novamente'));
    }
  }

  
}