import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MyExerciseLibraryService } from '../my-exercise-library.service';

@Component({
  selector: 'app-create-question-type-lib',
  templateUrl: './create-question-type-lib.component.html',
  styleUrls: ['./create-question-type-lib.component.scss']
})
export class CreateQuestionTypeLibComponent implements OnInit {

  public questionName: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public questionTypeConfig: any,
    @Optional() public dialogRef: MatDialogRef<CreateQuestionTypeLibComponent>,
    private libraryService: MyExerciseLibraryService,
    private alertService: AlertService,
    public deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.initEditMode();
  }

  createTypeQuestion(name:string){
    this.libraryService.createQuestionType(name)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Tipo de questão cadastrado com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  updateQuestionType(name:string){
    this.questionTypeConfig.questionType.name = name;

    this.libraryService.updateQuestionType(this.questionTypeConfig.questionType)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Tipo de questão atualizado com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  initEditMode(){
    if(this.questionTypeConfig.edit){
      this.questionName = this.questionTypeConfig.questionType.name;
    }
  }

}
