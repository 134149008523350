import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Father } from './father';

@Injectable({
  providedIn: 'root'
})
export class FatherService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  createFather(father: Father) {
    return this.http.post(this.API + '/father/create', father , { observe: 'response' });
  }

  deleteFather(fatherId: number) {
    return this.http.delete(this.API + '/father/delete/'+ fatherId, { observe: 'response' });
  }

  updateFather(father: Father) {
    return this.http.post(this.API + '/father/update', father , { observe: 'response' });
  }

  getAllFathers() {
    return this.http.get(this.API + '/father/getAllFathers', { observe: 'response' });
  }

  getAllFathersByName(fatherName: string) {
    return this.http.post(this.API + '/father/getAllFathersByName', {fatherName} ,  { observe: 'response' });
  }
}
