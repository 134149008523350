<div class="page-container">

    <div class="page-title-container"  [ngClass]="{'text-center': layoutService.isMobile}">
        <span class="page-title">PAGAMENTOS</span>
        <div class="mt-3 description-title">
            <h4 *ngIf="!layoutService.isMobile">Minhas compras</h4>
            <h6 class="mb-0" *ngIf="layoutService.isMobile">Minhas compras</h6>
            <p class="fs-13 pb-0">Acompanhe o seu histórico de compras</p>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="full-width">

        <ng-container matColumnDef="register">
            <th mat-header-cell *matHeaderCellDef class="brl-6 border-radius-left-5"> REGISTRO </th>
            <td mat-cell *matCellDef="let payment"> {{payment.register | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="planName">
            <th mat-header-cell *matHeaderCellDef> PLANO </th>
            <td mat-cell *matCellDef="let payment"> {{payment.planName}} </td>
        </ng-container>


        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> STATUS </th>
            <td mat-cell *matCellDef="let payment"> {{payment.status}} </td>
        </ng-container>


        <ng-container matColumnDef="expire">
            <th mat-header-cell *matHeaderCellDef class="brr-5"> VENCIMENTO </th>
            <td mat-cell *matCellDef="let payment">
                <ng-container *ngIf="payment.expire">
                    {{payment.expire | date:'dd/MM/yyyy'}}
                </ng-container>
                <ng-container *ngIf="!payment.expire">
                    ----------------
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>