<div class="container pb-5">
    <!-- Painel principal -->
    <mat-expansion-panel  class="width-70 center mt-4" [expanded]="expandPanel" *ngIf="channelEdit && screen == 'formChannel'">
        <mat-expansion-panel-header>
            <span class="text-color-accent" >Editar Canal</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div  *ngIf="screen == 'formChannel'">
                
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="width-100">
                            <input matInput placeholder="Nome (obrigatório)" required [value]="channelEdit.getChannel().name" #inputName>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12 text-right d-flex align-items-center mt-2">
                        <button (click)="saveChanelName(inputName.value)" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin pl-2"  style="height: 28px;" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
                    </div>
                </div>
                <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Associar turma(s) </span><hr NOSHADE> </div>
                <div class="row">
                    <div [ngClass]="{'col-6': userService.isAdmin()}" *ngIf="userService.isAdmin()">
                        <mat-form-field class="w-100">
                            <mat-label>Escola</mat-label>
                            <mat-select  placeholder="Escolas" (selectionChange)="selectedSchool = $event.value; getUnitsSelection($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                        <mat-form-field class="w-100" >
                            <mat-label>Unidade</mat-label>
                            <mat-select placeholder="Unidades" (selectionChange)="getClassesSelection($event.value)">
                                <mat-option [value]="0" *ngIf="units?.length > 0" > Todas </mat-option>
                                <mat-option *ngFor="let unit of units" [value]="unit.id"> {{unit.name}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div [ngClass]="{'col-12': userService.isAdmin() || userService.isUnitCoordinator(), 'col-6': userService.isGeneralCoordinator()}">
                        <mat-form-field class="w-100">
                            <mat-label>Turmas</mat-label>
                            <mat-select   placeholder="Turmas" (selectionChange)="channelEdit.addChannelClass($event.value)" >
                                <mat-option *ngFor="let class of classes" [value]="class">
                                    <span>{{class.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-2" *ngIf="channelEdit.getChannelClasses().length > 0">
                        <mat-label><span>Turmas Selecionadas</span></mat-label>
                        <mat-chip-list>
                            <mat-chip *ngFor="let classChip of channelEdit.getChannelClasses()" selectable="true" removable="true" (removed)="channelEdit.removeChannelClass(classChip)">
                                {{classChip.name}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12 text-right d-flex align-items-center mt-2">
                        <button (click)="saveChannelClasses()" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin pl-2"  style="height: 28px;" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
                    </div>
                </div>
                <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Configurações </span><hr NOSHADE> </div>
                <!-- Cards -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 mt-2 mb-2" (click)="changeScreen('planForm')"> 
                        <!-- <div class="row m-0 option option-bg-color-accent-200-hover text-center" [ngClass]="{'bg-color-primary': channel.plans?}"> -->
                        <div class="row m-0 option option-bg-color-accent-200-hover text-center">
                            <div class="col-12">
                                <mat-icon class="material-symbols-outlined icon" color="accent">note_add</mat-icon>
                            </div>
                            <div class="col-12 flex-wrap h-100">
                                <span class="text-center description">{{channelEdit.getChannelPlans().length}}</span><span class="text-center description"> Planos</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-2 mb-2" (click)="changeScreen('folderForm')">
                        <!-- <div class="row m-0 option option-bg-color-accent-200-hover text-center" [ngClass]="{'bg-color-primary': channel.folders?}"> -->
                        <div class="row m-0 option option-bg-color-accent-200-hover text-center">
                            <div class="col-12">
                                <mat-icon class="material-symbols-outlined icon" color="accent">create_new_folder</mat-icon>
                            </div>
                            <div class="col-12 flex-wrap h-100">
                                <span class="text-center description">{{channelEdit.getChannelFolders().length}}</span><span class="text-center description"> Pastas</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-2 mb-2" (click)="changeScreen('liveForm')">
                        <!-- <div class="row m-0 option option-bg-color-accent-200-hover text-center" [ngClass]="{'bg-color-primary': channel.classrooms?}"> -->
                        <div class="row m-0 option option-bg-color-accent-200-hover text-center">
                            <div class="col-12">
                                <mat-icon class="material-symbols-outlined icon" color="accent">video_library</mat-icon>
                            </div>
                            <div class="col-12 flex-wrap h-100">
                                <span class="text-center description">{{channelEdit.getChannelClassrooms().length}}</span><span class="text-center description"> Lives</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-2 mb-2" (click)="changeScreen('flipbookForm')">
                        <!-- <div class="row m-0 option option-bg-color-accent-200-hover text-center" [ngClass]="{'bg-color-primary': channel.ebooks}"> -->
                        <div class="row m-0 option option-bg-color-accent-200-hover text-center">
                            <div class="col-12">
                                <mat-icon class="material-symbols-outlined icon" color="accent">import_contacts</mat-icon>
                            </div>
                            <div class="col-12 flex-wrap h-100">
                                <span class="text-center description">{{channelEdit.getChannelEbooks().length}}</span><span class="text-center description"> E-books</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-2 mb-2" (click)="changeScreen('taskForm')">
                        <div class="row m-0 option option-bg-color-accent-200-hover text-center">
                            <div class="col-12">
                                <mat-icon class="material-symbols-outlined icon" color="accent">task</mat-icon>
                            </div>
                            <div class="col-12 flex-wrap h-100">
                                <span class="text-center description">{{channelEdit.getChannelTasks().length}}</span><span class="text-center description">Tarefas</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col">
                        <button (click)="backListChannels.emit(true)" class="right" nbButton size="small" status="primary">voltar</button>
                    </div>
                    <div class="col text-right width-100">
                        <button type="submit" (click)="backListChannels.emit(true)" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Finalizar</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <!-- Painel editar planos -->
    <mat-expansion-panel  class="width-70 center" [expanded]="expandPanel" *ngIf="screen == 'planForm'">
        <mat-expansion-panel-header>
            <div class="row">
                <span class="text-color-accent col-12" >Editar Planos do Canal</span>
            </div>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div>
                <!-- configurar rota -->
                <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Configurar Rota </span><hr NOSHADE> </div>
                <form [formGroup]="formPlan">
                    <div class="row w-100">    
                        <div class="col-10 pr-0">
                            <mat-form-field class="width-100">
                                <input matInput placeholder="Nome da Rota"  formControlName="routePlanName" [(ngModel)]="channelEdit.getChannelRoutePlan().name" [readonly]="channelEdit.getChannelRoutePlan().name? true : false" [ngClass]="{'disabled': channelEdit.getChannelRoutePlan().name}">
                            </mat-form-field>
                        </div>
                        <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="!channelEdit.getChannelRoutePlan().name">
                            <button (click)="savePlansRoute()" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin h-50 ativar-salvar" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Ativar</button>
                        </div>
                        <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="channelEdit.getChannelRoutePlan().name">
                            <button (click)="removeRoute(channelEdit.getChannelRoutePlan())" class="right  h-50 lb-warn" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Desativar</button>
                        </div>
                    </div>
                </form>
                <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Associar Planos </span><hr NOSHADE> </div>
                <div class="row" *ngIf="!userService.isUnitCoordinator()">
                    <div [ngClass]="{'col-6': !userService.isUnitCoordinator()}" *ngIf="userService.isAdmin()">
                        <mat-form-field class="w-100">
                            <mat-label>Escolas</mat-label>
                            <mat-select placeholder="Escolas" (selectionChange)="selectedSchool = $event.value; getUnitsSelection($event.value); getPlansSelection($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id"> {{school.name}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div [ngClass]="{'col-6': userService.isAdmin(), 'col-12': !userService.isAdmin()}" >
                        <mat-form-field class="w-100">
                            <mat-label>Unidade
                            </mat-label>
                            <mat-select placeholder="Unidades" (selectionChange)="getUnitPlans($event.value)">
                                <mat-option [value]="0" *ngIf="units?.length > 0" > Todas </mat-option>
                                <mat-option *ngFor="let unit of units" [value]="unit.id"> {{unit.name}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100">
                            <mat-label>Plano </mat-label>
                            <mat-select  placeholder="Planos" (selectionChange)="channelEdit.addChannelPlans($event.value)">
                                <mat-option *ngFor="let plan of plans" [value]="plan"> {{plan.name}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="row border-primary-thin ml-2 mr-3 mt-3" *ngIf="channelEdit.getChannelPlans().length > 0"></div> -->
                <div class="row">
                    <div class="col-12 mt-2 ml-2" *ngIf="channelEdit.getChannelPlans().length > 0">
                        <mat-label><span>Planos Selecionados</span></mat-label>
                        <mat-chip-list>
                            <mat-chip *ngFor="let planChip of channelEdit.getChannelPlans()" selectable="true" removable="true" (removed)="channelEdit.removeChannelPlans(planChip)">
                                {{planChip.name}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-4 back-button">
                        <button (click)="changeScreen('formChannel')" class="right" nbButton size="small" status="primary">voltar</button>
                    </div>
                    <div class="col mt-4 text-right width-100">
                        <button (click)="saveChannelPlans(); changeScreen('formChannel')" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
                    </div>
                </div>
                
            </div>
        </ng-template>
    </mat-expansion-panel>
    <!-- Painel editar pastas -->
    <mat-expansion-panel  class="width-70 center" [expanded]="expandPanel" *ngIf="screen == 'folderForm'">
        <mat-expansion-panel-header>
            <span class="text-color-accent" >Editar Pastas do Canal</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="row justify-content-center mb-3 mt-4">
                <div class="col-10">
                    <div class="row justify-content-between">
                        <div class="col-6 w-100 pl-0">
                            <button (click)="openFolderModal()" class="text-color-accent bg-color-primary-300 border-primary-thin appearance-outline size-small status-primary shape-rectangle transitions w-100" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Criar Pasta</button>
                        </div>
                        <div class="col-6 w-100 pr-0">
                            <button (click)="openFolderMaterialModal()" class="text-color-accent bg-color-primary-300 border-primary-thin appearance-outline size-small status-primary shape-rectangle transitions w-100" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Adicionar Material</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row border-primary-thin ml-3 mr-3 mt-3"></div> -->

            <div>
                <div class="justify-content-center mt-3">
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Configurar Rota </span><hr NOSHADE> </div>
                    <form [formGroup]="formFolder">
                        <div class="row w-100">    
                            <div class="col-10 pr-0">
                                <mat-form-field class="width-100">
                                    <input matInput placeholder="Nome da Rota"  formControlName="routeFolderName" [(ngModel)]="channelEdit.getChannelRouteFolder().name" [readonly]="channelEdit.getChannelRouteFolder().name? true : false" [ngClass]="{'disabled': channelEdit.getChannelRouteFolder().name}">
                                </mat-form-field>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="!channelEdit.getChannelRouteFolder().name">
                                <button (click)="saveFoldersRoute()" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin h-50 ativar-salvar" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Ativar</button>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="channelEdit.getChannelRouteFolder().name">
                                <button (click)="removeRoute(channelEdit.getChannelRouteFolder())" class="right  h-50 lb-warn" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Desativar</button>
                            </div>
                        </div>
                    </form>
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Adicionar Pastas </span><hr NOSHADE> </div>
                    <div class="row" *ngIf="!userService.isUnitCoordinator()">
                        <div class="col-6" *ngIf="userService.isAdmin()">
                            <mat-form-field class="w-100">
                                <mat-label>Escola
                                </mat-label>
                                <mat-select  placeholder="Escolas" (selectionChange)="selectedSchool = $event.value; getUnitsSelection($event.value); getPlansSelection($event.value)">
                                    <!-- <mat-option [value]="0" *ngIf="schools?.length > 0"> Nenhum </mat-option> -->
                                    <mat-option *ngFor="let school of schools" [value]="school.id"> {{school.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div [ngClass]="{'col-6': userService.isAdmin(), 'col-12': !userService.isAdmin()}" >
                            <mat-form-field class="w-100">
                                <mat-label>Unidade </mat-label>
                                <mat-select   placeholder="Unidades" (selectionChange)="getUnitPlans($event.value); getClassesSelection($event.value)">
                                    <mat-option [value]="0" *ngIf="units?.length > 0"> Todos </mat-option>
                                    <mat-option *ngFor="let unit of units" [value]="unit.id"> {{unit.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Turma </mat-label>
                                <mat-select   placeholder="Turmas" (selectionChange)="getClassPlans($event.value); getClassFolders($event.value)">
                                    <mat-option [value]="0" *ngIf="classes?.length > 0"> Todos </mat-option>
                                    <mat-option *ngFor="let class of classes" [value]="class.id"> {{class.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Plano </mat-label>
                                <mat-select   placeholder="Planos" (selectionChange)="getPlansFolders($event.value)">
                                    <!-- <mat-option [value]="0" *ngIf="plans?.length > 0"> Nenhum </mat-option> -->
                                    <mat-option *ngFor="let plan of plans" [value]="plan.id"> {{plan.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Pastas</mat-label>
                                <mat-select   placeholder="Pastas" (selectionChange)="channelEdit.addChannelFolders($event.value)">
                                    <!-- <mat-option [value]="0" *ngIf="folders?.length > 0"> Nenhum </mat-option> -->
                                    <mat-option *ngFor="let folder of folders" [value]="folder"> {{folder.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="row border-primary-thin ml-3 mr-3 mt-3" *ngIf="channelEdit.getChannelFolders().length > 0"></div> -->
                    <div class="row">
                        <div class="col-12 mt-4" *ngIf="channelEdit.getChannelFolders().length > 0">
                            <mat-label class="mb-2">Pastas Selecionadas</mat-label>
                            <mat-chip-list>
                                <mat-chip *ngFor="let folderChip of channelEdit.getChannelFolders()" selectable="true" removable="true" (removed)="channelEdit.removeChannelFolders(folderChip)">
                                    {{folderChip.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row border-primary-thin ml-3 mr-3 mt-3" *ngIf="channelEdit.getChannelFolders().length > 0"></div> -->
            <div class="row">
                <div class="col mt-4 back-button">
                    <button (click)="changeScreen('formChannel')" class="right" nbButton size="small" status="primary">voltar</button>
                </div>
                <div class="mt-4 text-right">
                    <button (click)="saveChannelFolders();changeScreen('formChannel')" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <!-- Painel editar Aulas ao Vivo -->
    <mat-expansion-panel  class="width-70 center" [expanded]="expandPanel" *ngIf="screen == 'liveForm'">
        <mat-expansion-panel-header>
            <span class="text-color-accent">Editar Aulas ao Vivo do Canal</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <mat-expansion-panel class="width-90 center">
                <mat-expansion-panel-header>
                    <!-- <div class="col-10 mb-2"> -->
                        <!-- <mat-label> -->
                            <span class="sub-tittle ">Criar Salas de Aula Ao Vivo</span>
                        <!-- </mat-label> -->
                    <!-- </div> -->
                </mat-expansion-panel-header>
                <div class="row justify-content-center mb-4">
                    <div class="col-10">
                        <div class="row justify-content-between">
                            <div class="col-6 w-100 pl-0">
                                <button (click)="openClassroomPlanModal()" class="text-color-accent bg-color-primary-300 border-primary-thin appearance-outline size-small status-primary shape-rectangle transitions w-100" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Por Plano</button>
                            </div>
                            <div class="col-6 w-100 pl-0">
                                <button (click)="openClassroomClassModal()" class="text-color-accent bg-color-primary-300 border-primary-thin appearance-outline size-small status-primary shape-rectangle transitions w-100" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Por Turma</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <div>
                <div class="justify-content-center mt-3 ml-0 mr-0">
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Configurar Rota </span><hr NOSHADE> </div>
                    <form [formGroup]="formLive">
                        <div class="row">    
                            <div class="col-10 pr-0">
                                <mat-form-field class="width-100">
                                    <input matInput placeholder="Nome da Rota"  formControlName="routeLivesName" [(ngModel)]="channelEdit.getChannelRouteLive().name" [readonly]="channelEdit.getChannelRouteLive().name? true : false" [ngClass]="{'disabled': channelEdit.getChannelRouteLive().name}">
                                </mat-form-field>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="!channelEdit.getChannelRouteLive().name">
                                <button (click)="saveLivesRoute()" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin h-50 ativar-salvar" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Ativar</button>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="channelEdit.getChannelRouteLive().name">
                                <button (click)="removeRoute(channelEdit.getChannelRouteLive())" class="right  h-50 lb-warn" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Desativar</button>
                            </div>
                        </div>
                    </form>
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Adicionar Aulas ao Vivo </span><hr NOSHADE> </div>

                    <div class="row"> 
                        <div class="col-6" *ngIf="userService.isAdmin()">
                            <mat-form-field class="w-100">
                                <mat-label>Escola
                                </mat-label>
                                <mat-select   placeholder="Escolas" (selectionChange)="selectedSchool = $event.value; getPlansSelection($event.value)">
                                    <!-- <mat-option [value]="0" *ngIf="schools?.length > 0">
                                        Nenhum
                                    </mat-option> -->
                                    <mat-option *ngFor="let school of schools" [value]="school.id">
                                        {{school.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6"  *ngIf="!userService.isUnitCoordinator()">
                            <mat-form-field class="w-100">
                                <mat-label>Unidade
                                </mat-label>
                                <mat-select placeholder="Unidades" (selectionChange)="getUnitPlans($event.value)">
                                    <mat-option [value]="0" *ngIf="units?.length > 0" > Todas </mat-option>
                                    <mat-option *ngFor="let unit of units" [value]="unit.id"> {{unit.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div [ngClass]="{'col-6': userService.isGeneralCoordinator(), 'col-12': !userService.isGeneralCoordinator()}">
                            <mat-form-field class="w-100">
                                <mat-label>Plano
                                </mat-label>
                                <mat-select   placeholder="Planos" (selectionChange)="getClassRoomsAdmin($event.value, selectedSchool)">
                                    <!-- <mat-option [value]="0" *ngIf="plans?.length > 0"> Todos </mat-option> -->
                                    <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                        {{plan.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Salas de aula
                                </mat-label>
                                <mat-select   placeholder="Salas de Aula" (selectionChange)="channelEdit.addChannelClassRooms($event.value)">
                                    <mat-option *ngFor="let classroom of classrooms" [value]="classroom">
                                        <span>{{classroom.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="row border-primary-thin ml-3 mr-3 mt-3" *ngIf="channelEdit.getChannelClassrooms().length > 0"></div> -->
                    <div class="row">
                        <div class="col-12 mt-4" *ngIf="channelEdit.getChannelClassrooms().length > 0">
                            <mat-label class="mb-2">Aulas ao Vivo Selecionadas</mat-label>
                            <mat-chip-list>
                                <mat-chip *ngFor="let classroomChip of channelEdit.getChannelClassrooms()" selectable="true" removable="true" (removed)="channelEdit.removeChannelClassrooms(classroomChip)">
                                    {{classroomChip.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row border-primary-thin ml-3 mr-3 mt-3" *ngIf="channelEdit.getChannelClassrooms().length > 0"></div> -->
            <div class="row">
                <div class="col mt-4 back-button">
                    <button (click)="changeScreen('formChannel')" class="right" nbButton size="small" status="primary">voltar</button>
                </div>
                <div class="mt-4 text-right">
                    <button (click)="saveChannelClassrooms(); changeScreen('formChannel')" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <!-- Painel editar Ebooks -->
    <mat-expansion-panel  class="width-70 center" [expanded]="expandPanel" *ngIf="screen == 'flipbookForm'">
        <mat-expansion-panel-header>
            <span class="text-color-accent" >Editar E-books do Canal</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="row justify-content-center mb-3 mt-4">
                <div class="col-10">
                    <div class="row justify-content-between">
                        <div class="col-6 w-100 pl-0">
                            <button (click)="openEbookModal()" class="text-color-accent bg-color-primary-300 border-primary-thin appearance-outline size-small status-primary shape-rectangle transitions w-100" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Criar E-book</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row border-primary-thin ml-3 mr-3"></div> -->
            <div>
                <div class="justify-content-center mt-3 ml-0 mr-0">
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Configurar Rota </span><hr NOSHADE> </div>
                    <form [formGroup]="formEbook">
                        <div class="row w-100">    
                            <div class="col-10 pr-0">
                                <mat-form-field class="width-100">
                                    <input matInput placeholder="Nome da Rota"  formControlName="routeEbooksName" [(ngModel)]="channelEdit.getChannelRouteEbook().name" [readonly]="channelEdit.getChannelRouteEbook().name? true : false" [ngClass]="{'disabled': channelEdit.getChannelRouteEbook().name}">
                                </mat-form-field>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="!channelEdit.getChannelRouteEbook().name">
                                <button (click)="saveEbooksRoute()" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin h-50 ativar-salvar" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Ativar</button>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="channelEdit.getChannelRouteEbook().name">
                                <button (click)="removeRoute(channelEdit.getChannelRouteEbook())" class="right  h-50 lb-warn" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Desativar</button>
                            </div>
                        </div>
                    </form>
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Adicionar E-books </span><hr NOSHADE> </div>
                    <div class="row">
                        <div class="col-6" *ngIf="userService.isAdmin()">
                            <mat-form-field class="w-100">
                                <mat-label>Escola
                                </mat-label>
                                <mat-select  placeholder="Escolas" (selectionChange)="selectedSchool = $event.value; getPlansSelection($event.value)">
                                    <!--  <mat-option [value]="0" *ngIf="schools?.length > 0">
                                        Nenhum
                                    </mat-option> -->
                                    <mat-option *ngFor="let school of schools" [value]="school.id">
                                        {{school.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf="!userService.isUnitCoordinator()">
                            <mat-form-field class="w-100">
                                <mat-label>Unidade
                                </mat-label>
                                <mat-select placeholder="Unidades" (selectionChange)="getUnitPlans($event.value)">
                                    <mat-option [value]="0" *ngIf="units?.length > 0" > Todas </mat-option>
                                    <mat-option *ngFor="let unit of units" [value]="unit.id"> {{unit.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div [ngClass]="{'col-6': userService.isGeneralCoordinator(), 'col-12': !userService.isGeneralCoordinator()}">
                            <mat-form-field class="w-100">
                                <mat-label>Plano
                                </mat-label>
                                <mat-select   placeholder="Planos" (selectionChange)="getEbooks($event.value)">
                                    <!-- <mat-option [value]="0" *ngIf="plans?.length > 0">
                                        Nenhum
                                    </mat-option> -->
                                    <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                        {{plan.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>E-books
                                </mat-label>
                                <mat-select   placeholder="E-books" (selectionChange)="channelEdit.addChannelEbooks($event.value)" >
                                    <mat-option *ngFor="let ebook of ebooks" [value]="ebook">
                                        <span>{{ebook.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="row border-primary-thin ml-2 mr-3 mt-3" *ngIf="channelEdit.getChannelEbooks().length > 0"></div> -->
                    <div class="row">
                        <div class="col-12 mt-2 ml-2" *ngIf="channelEdit.getChannelEbooks().length > 0">
                            <mat-label><span>E-books Selecionados</span></mat-label>
                            <mat-chip-list>
                                <mat-chip *ngFor="let ebookChip of channelEdit.getChannelEbooks()" selectable="true" removable="true" (removed)="channelEdit.removeChannelEbook(ebookChip)">
                                    {{ebookChip.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col mt-4 back-button">
                    <button (click)="changeScreen('formChannel')" class="right" nbButton size="small" status="primary">voltar</button>
                </div>
                <div class="mt-4 text-right">
                    <button (click)="saveChannelEbooks();changeScreen('formChannel')" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>

     <!-- Painel editar Tasks -->
     <mat-expansion-panel  class="width-70 center" [expanded]="expandPanel" *ngIf="screen == 'taskForm'">
        <mat-expansion-panel-header>
            <span class="text-color-accent" >Editar Tarefas do Canal</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="row justify-content-center mb-3 mt-4">
                <div class="col-10">
                    <div class="row justify-content-between">
                        <div class="col-6 w-100 pl-0">
                            <button (click)="openTaskModal()" class="text-color-accent bg-color-primary-300 border-primary-thin appearance-outline size-small status-primary shape-rectangle transitions w-100" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Criar Tarefas</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="justify-content-center mt-3 ml-0 mr-0">
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Configurar Rota </span><hr NOSHADE> </div>
                    <form [formGroup]="formTask">
                        <div class="row w-100">    
                            <div class="col-10 pr-0">
                                <mat-form-field class="width-100">
                                    <input matInput placeholder="Nome da Rota"  formControlName="routeTasksName" [(ngModel)]="channelEdit.getChannelRouteTask().name" [readonly]="channelEdit.getChannelRouteTask().name? true : false" [ngClass]="{'disabled': channelEdit.getChannelRouteTask().name}">
                                </mat-form-field>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="!channelEdit.getChannelRouteTask().name">
                                <button (click)="saveTasksRoute()" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin h-50 ativar-salvar" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Ativar</button>
                            </div>
                            <div class="col-2 text-right width-100 d-flex align-items-center" *ngIf="channelEdit.getChannelRouteTask().name">
                                <button (click)="removeRoute(channelEdit.getChannelRouteTask())" class="right  h-50 lb-warn" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Desativar</button>
                            </div>
                        </div>
                    </form>
                    <div class="dados-gerais mb-3 mt-3"> <hr NOSHADE><span > Adicionar Tarefas </span><hr NOSHADE> </div>
                    <div class="row">
                        <div class="col-6" *ngIf="userService.isAdmin()">
                            <mat-form-field class="w-100">
                                <mat-label>Escola</mat-label>
                                <mat-select  placeholder="Escolas" (selectionChange)="selectedSchool = $event.value; getUnitsSelection($event.value);">
                                    <mat-option *ngFor="let school of schools" [value]="school.id">
                                        {{school.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf="!userService.isUnitCoordinator()">
                            <mat-form-field class="w-100">
                                <mat-label>Unidade</mat-label>
                                <mat-select placeholder="Unidades" (selectionChange)="getClassesSelection($event.value)">
                                    <mat-option [value]="0" *ngIf="units?.length > 0" > Todas </mat-option>
                                    <mat-option *ngFor="let unit of units" [value]="unit.id"> {{unit.name}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div [ngClass]="{'col-6': userService.isGeneralCoordinator(), 'col-12': !userService.isGeneralCoordinator()}">
                            <mat-form-field class="w-100">
                                <mat-label>Turma</mat-label>
                                <mat-select placeholder="Selecione as turmas" (selectionChange)="getTasksByClass($event.value)">
                                    <mat-option *ngFor="let class of classes" [value]="class.id">{{class.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Tarefas</mat-label>
                                <mat-select placeholder="Tasks" (selectionChange)="channelEdit.addChannelTasks($event.value)" >
                                    <mat-option *ngFor="let task of tasks" [value]="task"><span>{{task.taskName}}</span></mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-2 ml-2" *ngIf="channelEdit.getChannelTasks().length > 0">
                            <mat-label><span>Tarefas Selecionadas</span></mat-label>
                            <mat-chip-list>
                                <mat-chip *ngFor="let taskChip of channelEdit.getChannelTasks()" selectable="true" removable="true" (removed)="channelEdit.removeChannelTask(taskChip)">
                                    {{taskChip.taskName}} <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col mt-4 back-button">
                    <button (click)="changeScreen('formChannel')" class="right" nbButton size="small" status="primary">voltar</button>
                </div>
                <div class="mt-4 text-right">
                    <button (click)="saveChannelTasks(); changeScreen('formChannel')" class="right bg-color-accent-200 text-color-primary-300 border-primary-thin" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>


    <!-- Painel editar Turmas -->
    <mat-expansion-panel  class="width-70 center" [expanded]="expandPanel" *ngIf="screen == 'classForm'">
        <mat-expansion-panel-header>
            <span class="text-color-accent" >Editar Turmas do Canal</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            
        </ng-template>
    </mat-expansion-panel>
</div>
