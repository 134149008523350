<nb-card size="large" class="background-layout redaction-card">
    <nb-card-header>
        <h5 class="mb-3 mt-2 text-center">Escreva seu texto</h5>
    </nb-card-header>
    <nb-card-body>
        <div class="mb-3 width-100">
            <textarea rows="25" [(ngModel)]="text" nbInput fullWidth placeholder="Mãos à obra :)" class="textarea-text" matTextareaAutosize></textarea>
        </div>
    </nb-card-body>
    <nb-card-footer class="row m-0">
        <div class="pt-10 pb-10" [ngClass]="{'col-6': !layoutService.isMobile, 'col-12 p-0': layoutService.isMobile}">
            <button class="button-comment small-btn cancel-redaction width-100" (click)="close()" nbTooltip="Cancelar" mat-raised-button color="error">
                Cancelar
            </button>
        </div>
        <div class="pt-10 pb-10" [ngClass]="{'col-6': !layoutService.isMobile, 'col-12 p-0 mt-2': layoutService.isMobile}">
            <button class="button-comment small-btn width-100" (click)="save()" [disabled]="!text" mat-raised-button color="accent" nbSpinnerStatus="warning">
                Salvar
            </button>
        </div>
        <!-- <button mat-button class="mt-1 mb-1 hvr-grow text-center width-100" (click)="close()">
            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
        </button> -->
    </nb-card-footer>
</nb-card>