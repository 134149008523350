import { Injectable } from '@angular/core';
import { ScriptStore, Scripts } from '../models/script.store';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts = {};

  constructor() {
    ScriptStore.forEach((script: Scripts) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }


  public load(...scripts: string[]) {
    let promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.async = this.scripts[name].async;
        //Others
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });

        if (this.scripts[name].appendOnBody) {
          document.getElementsByTagName("body")[0].appendChild(script);
        } else {
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    });
  }
}