import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';

import { ResponseApi } from '../../models/response-api';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { ThemeService } from '../../themes/theme.service';
import { Theme } from '../../themes/theme';

@Component({
    selector: 'app-recovery',
    templateUrl: './recovery.component.html'
})
export class RecoveryComponent implements OnInit {
    theme: Theme;
    recoveryForm: FormGroup;
    sended: boolean;
    sending: boolean;

    constructor(
        private authService: AuthService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private themeService: ThemeService,
        private router: Router) { }

    ngOnInit(): void {
        this.getTheme();
        this.recoveryForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    getTheme() {
        this.themeService.getThemeObservable()
            .subscribe(theme => this.theme = theme as Theme);
    }

    recovery() {
        this.sending = true;
        const email = this.recoveryForm.get('email').value;

        this.authService.recovery(email)
            .subscribe(
                res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.sended = true;
                    } else {
                        this.alertService.error(response.error);
                    }
                    this.sending = false;
                },
                err => {
                    this.alertService.error('Falha de comunicação com a API!');
                    this.sending = false;
                }
            );
    }

    goToLogin() {
        this.router.navigate(['/signin']);
    }
}
