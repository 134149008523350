import { UserService } from 'src/app/modules/user/user.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { Discipline } from '../../admin/discipline/discipline';
import { DisciplineService } from '../../admin/discipline/discipline.service';
import { Video } from '../../video/video';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { CommonModule } from '@angular/common';
import { VideoService } from '../../video/video.service';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { disciplineTag } from '../../admin/discipline-tag/discipline-tag-create/discipline-tag';
import { CalendarService } from '../../calendar/calendar.service';
import { ifStmt, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';

@Component({
    selector: 'app-discipline-videos',
    templateUrl: './discipline-videos.component.html',
    styleUrls: ['./discipline-videos.component.scss']

})

export class DisciplineVideosComponent implements OnInit {

    @Input() discipline: Discipline;
    @Input() plan: StudyPlan;
    @Input() version: any;
    public videos: Video[];
    public filter: string;
    public videoDataSource: MatTableDataSource<Video>;
    public displayedColumns: string[];
    public disciplineName: string;
    public nextPageActive = false;
    public loadingDisciplines: boolean;
    public size: number;
    public pageIndex = 0;
    public totalRowsSize: Video[];
    public totalRows: number;
    public planId: number;
    public disciplineId: number;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public planSelectedName: string;
    public disciplineTags: disciplineTag[];
    public selectTagId: number;
    public tags: boolean;
    public selectedDisciplineId: number;
    public filterTag: string;
    public filteredVideos: Video[];
    public filterDisciplineTags: disciplineTag[];
    public filteredDisciplineTags: disciplineTag[];
    public idVideos: Video[];

    constructor(
        private alertService: AlertService,
        private disciplineService: DisciplineService,
        private route: ActivatedRoute,
        public deviceService: DeviceDetectorService,
        public layoutService: LayoutService,
        private router: Router,
        private studyPlanService: StudyPlanService,
        public videoService: VideoService,
        public userService: UserService,
        public calendarService: CalendarService) { }

    ngOnInit(): void {
        
        this.filteredDisciplineTags = [];
        this.filterDisciplineTags = [];
        this.videos = [];
        this.planId = this.plan.id;

        if (this.discipline.id) {
            this.disciplineId = this.discipline.id;
        }
        if (!this.layoutService.isMobile) {
            this.displayedColumns = ['watched','video','link'];
        } else {
            this.displayedColumns = [ 'watched','video'];
        }
        this.disciplineTags = [];
        this.getDisciplinesTagsBySchool(this.userService.getUserSchool());
    }

    ngAfterViewInit() {

        this.planId = Number(this.route.snapshot.paramMap.get('planId'));
        this.disciplineId = Number(this.route.snapshot.paramMap.get('disciplineId'));
        if((this.planId == 0 ||  this.planId == undefined) && this.plan){
            this.planId = this.plan.id;
        }
        if (this.discipline.id != 0 && this.discipline.id != undefined) {
            this.disciplineId = this.discipline.id;
        }
        this.disciplineId = this.disciplineId == 0 ? this.discipline.id : this.disciplineId;
        if (this.plan && this.plan.version == 1 && this.planId) {
            //console.log('v1');
            if (this.disciplineId && this.disciplineId != 0) {
                this.getDisciplineVideosV1(this.disciplineId);
            } else {
                this.getDisciplineVideosV1(this.discipline.id);
            }
        } else if (this.plan && this.plan.version == 2 && this.planId) {
            //console.log('v2');
            if (!this.disciplineId) {
                this.getDisciplineVideosByPlanV2ByVideos(this.discipline.videos , this.planId);
            }
        }

       if(this.plan.version == 2 && this.disciplineId){
        this.getDisciplineVideosByPlanV2ByVideos(this.discipline.videos , this.planId);
        //this.getPlanOneDisciplinesVideosV2(this.planId , this.disciplineId)
       }
    }
    paginate(event: any) {
        this.pageIndex = event;
        this.totalRowsSize = this.videos.slice(event * this.size - this.size, event * this.size);
        this.totalRows = this.totalRowsSize.length;
    }

    setDisciplinePlan(videoId: number) {
        if(this.route.snapshot.paramMap.get('channelId')){
            var channelId = this.route.snapshot.paramMap.get('channelId')
            this.router.navigateByUrl('/video/watch/' + this.plan.id + '/' + this.discipline.id + '/' + videoId + '/' + channelId);
        } else {
            if (this.discipline.id && this.discipline.id != 0) {
                this.router.navigateByUrl('/video/watch/' + this.plan.id + '/' + this.discipline.id + '/' + videoId);
            } else if (this.disciplineId && this.disciplineId != 0) {
                this.router.navigateByUrl('/video/watch/' + this.plan.id + '/' + this.disciplineId + '/' + videoId);
            }
        }
    }

    getDisciplineVideosV1(disciplineId: number) {
        //this.videos = [];
        this.filteredDisciplineTags = [];
        const order = (this.version == 2) ? true : false;
        let idSchool = Number(this.userService.getUserSchool());
        this.disciplineService.getDisciplineVideosV1(disciplineId, order, idSchool , this.userService.getUserId())
            .subscribe(res => {
                const response = res.body as any;
                if (!response.error) {
                    let data = response.data;
                    this.filteredVideos = data.videos as Video[];
                    this.videos  = data.videos as Video[];
                    this.filteredDisciplineTags = data.tags as disciplineTag[];
                    this.videoDataSource = new MatTableDataSource([...this.videos]);
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingDisciplines = false;
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getDisciplineVideosV1ByTag(disciplineId: number, tagId: number) {
        //this.videos = [];
        const order = (this.version == 2) ? true : false;
        let idSchool = Number(this.userService.getUserSchool());
        this.disciplineService.getDisciplineVideosV1ByTag(disciplineId, order, idSchool, tagId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    let data = response.data;
                    this.videos = data as Video[];
                    this.filteredVideos = data as Video[];
                    this.videoDataSource = new MatTableDataSource([...this.videos]);
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingDisciplines = false;
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getDisciplineVideosByPlanV2(disciplineId: number, planId: number) {
        this.filteredDisciplineTags = [];
        this.videos = [];
        let idSchool = Number(this.userService.getUserSchool());
        this.disciplineService.getDisciplineVideosByPlanV2(Number(disciplineId), planId, idSchool)
            .subscribe(res => {
                const response = res.body as any;
                if (!response.error) {
                    let data = response.data;
                    this.videos = data.videos as Video[];
                    this.filteredVideos = data.videos as Video[];
                    this.filteredDisciplineTags = data.tags as disciplineTag[];
                    this.videoDataSource = new MatTableDataSource([...this.videos]);
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingDisciplines = false;
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getDisciplineVideosByPlanV2ByVideos(videos: Video[] , planId: number) {
        this.filteredDisciplineTags = [];
        this.videos = [];
        let idSchool = Number(this.userService.getUserSchool());
        this.disciplineService.getDisciplineVideosByPlanV2ByVideos(videos , planId , idSchool , this.userService.getUserId() , this.disciplineId)
            .subscribe(res => {
                const response = res.body as any;
                if (!response.error) {
                    let data = response.data;
                    this.videos = data.videos as Video[];
                    this.videos.sort((a,b) => {
                        if(a.title > b.title){
                            return 1;
                          }
                          if(a.title < b.title){
                            return -1
                          }
                          return 0;
                    });
                    this.filteredVideos = data.videos as Video[];
                    this.filteredDisciplineTags = data.tags as disciplineTag[];
                    this.videoDataSource = new MatTableDataSource([...this.videos]);
                } else {
                    if(!this.disciplineId){
                    this.alertService.error(response.error);
                    }
                }
                this.loadingDisciplines = false;
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getPlanOneDisciplinesVideosV2(planId: number , disciplineId: number) {
        let userId = Number(this.userService.getUserId());
        this.studyPlanService.getPlanOneDisciplineVideosV2(planId , disciplineId , userId , this.userService.getUserSchool())
          .subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) {
                this.idVideos = response.data as Video[];

                this.getDisciplineVideosByPlanV2ByVideos(this.idVideos , planId);
            } else{
              this.alertService.error('Não foram encontrados Videos');
            }
          }, err => {
            this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
          });
      }

    getDisciplineVideosByPlanV2ByTag(disciplineId: number, planId: number, tagId: number) {
        this.videos = [];
        let idSchool = Number(this.userService.getUserSchool());
        this.disciplineService.getDisciplineVideosByPlanV2ByTag(Number(disciplineId), planId, idSchool, tagId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    let data = response.data;
                    this.videos = data as Video[];
                    this.filteredVideos = data as Video[];
                    this.videoDataSource = new MatTableDataSource([...this.videos]);
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingDisciplines = false;
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getDisciplineTagVideos(tagId: number) {
        const order = (this.version == 2) ? true : false;
        this.disciplineService.getDisciplineTagVideos(this.selectedDisciplineId, order, tagId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    let data = response.data;
                    this.videos = data as Video[];
                    this.filteredVideos = data as Video[];
                    this.videoDataSource = new MatTableDataSource([...this.videos]);
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getTagSelected(idTag: number) {
        if (this.plan.version == 1) {
            if (this.disciplineId && this.disciplineId != 0) {
                this.getDisciplineVideosV1ByTag(this.disciplineId, idTag);
            } else {
                this.getDisciplineVideosV1ByTag(this.discipline.id, idTag);
            }
        }
        if (this.plan.version == 2) {
            if (this.disciplineId && this.disciplineId != 0) {
                this.getDisciplineVideosByPlanV2ByTag(this.disciplineId, this.plan.id, idTag);
            }
            else {
                this.getDisciplineVideosByPlanV2ByTag(this.discipline.id, this.plan.id, idTag);
            }
        }
    }

    getDisciplinesTagsBySchool(idSchool: number){
        this.disciplineService.getDisciplinesTagsBySchoolAndDiscipline(idSchool , this.disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.disciplineTags = response.data as disciplineTag[];
          }
          /* else{
            this.alertService.error(response.error);
          } */
        })
      }

    applyFilter() {

        if (this.filterTag.length) {
            this.filteredVideos = [...this.videos].filter(video =>

                video.title.toLocaleLowerCase().indexOf(this.filterTag.toLowerCase()) > -1);

                this.videoDataSource = new MatTableDataSource([...this.filteredVideos]);
        } else {
            this.filteredVideos = [...this.videos];
            this.videoDataSource = new MatTableDataSource([...this.videos]);
        }
    }
}
