import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { Notifications } from '../admin/notifications/notifications';
import { NotificationsService } from '../admin/notifications/notifications.service';
import { UserService } from '../user/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-menu-notification',
  templateUrl: './menu-notification.component.html',
  styleUrls: ['./menu-notification.component.scss']
})
export class MenuNotificationComponent implements OnInit {
  public schoolId: number;
  public unitId: number;
  public classId: number;
  public userId: number;
  public profileId: number;
  public userImage: string;
  public userName: string;
  public read: boolean;
  public unRead: boolean;
  public loading: boolean;
  public notifications: Notifications[];
  public size: number;
  public pageIndex = 0;
  public totalRows: number;
  public totalRowsSize: Notifications[];
  public offset: number = 0;
  public totalPage: number;
  public pageNo: number;
  public currentPage: number = 1;
  @ViewChildren('player_container') playerContainer;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public maskService: MaskService,
    public layoutService: LayoutService,
    public notificationsService: NotificationsService,
    public deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.schoolId = Number(this.userService.getUserSchool());
    this.unitId = Number(this.userService.getUserUnit());
    this.classId = Number(this.userService.getUserClass());
    this.profileId = Number(this.userService.getUserProfileId());
    this.userId = Number(this.userService.getUserId());
    this.userName = this.userService.getUserName();
    this.userImage = this.userService.getUserImage();
    this.getAllReadAndUnreadNotifications(this.schoolId, this.unitId, this.classId, this.userId, this.profileId);
  }

  paginate(event: any) {
    this.currentPage = event;
    this.pageIndex=event;
    this.totalRowsSize = this.notifications.slice(event * this.size - this.size, event * this.size);
    this.totalRows = this.totalRowsSize.length;
  }

  getAllReadAndUnreadNotifications(schoolId:number, userId:number, profileId:number, offset?: number, limit?: number){
      this.userService.getAllReadAndUnreadNotifications(schoolId, userId, profileId, offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
            this.notifications = response.data as Notifications[];
            //console.log("this.notifications: ", this.notifications);
            this.notifications.forEach(element => {

              if(element.image){
                element.linkSanityzed =this.sanitizer.bypassSecurityTrustResourceUrl(element.image);
              } else if(element.video){
                element.linkSanityzed =this.sanitizer.bypassSecurityTrustResourceUrl(element.video);
              }

            });
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente');
      });
  }

  markAsReadNotification(notification: Notifications){
    const userId = this.userService.getUserId();
    this.notificationsService.markAsRead(notification, userId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if(!response.error){
          this.alertService.success('Mensagem lida!', 3);
          //this.notifications = response.data as Notifications[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente'));
  }
}
