import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';

import { Podcast } from '../../podcast/podcast';
import { PodcastService } from '../../podcast/podecast.service';
import { UserService } from '../../user/user.service';
import { School } from '../school/school';
import { SchoolService } from '../school/school.service';

@Component({
    selector: 'app-create-podcast',
    templateUrl: './create-podcast.component.html'
})
export class CreatePodcastComponent implements OnInit {

    public podcasts: Podcast[];
    public podcast: Podcast;
    public editMode: boolean;
    public createPodcastForm: FormGroup;
    public showPodcastList: boolean;
    public deleting: boolean;
    public loading: boolean;
    public schools: School[];
    public filterSchool: number;

    constructor(
        private formBuilder: FormBuilder,
        private podcastService: PodcastService,
        private alertService: AlertService,
        private schoolService: SchoolService,
        public userService: UserService) { }

    ngOnInit(): void {
        this.createPodcastForm = this.formBuilder.group({
            id: [],
            title: ['', Validators.required],
            link: ['', Validators.required],
            schoolId: ['', Validators.required]
        });
        this.profileInit();
    }

    profileInit() {

        if (this.userService.isAdmin()) {
            this.getSchools();
        } else
            if (this.userService.isGeneralCoordinator() || this.userService.isUnitCoordinator()) {
                this.filterSchool = this.userService.getUserSchool();
                this.createPodcastForm.get('schoolId').setValue(this.userService.getUserSchool());
            }
    }

    openList() {
        if (this.userService.isAdmin()) {
            this.getSchools();
        } else {
            this.getPodcasts(this.filterSchool);
        }
        this.showPodcastList = true;
    }

    getPodcasts(schoolId: number) {
        this.loading = true;
        this.podcastService.getPodcasts(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.podcasts = response.data as Podcast[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao carregar os podcasts. Verifique a conexão e tente novamente');
            });
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
    }

    createPodcast() {

        if (this.userService.isGeneralCoordinator()) {
            this.filterSchool = this.userService.getUserSchool();
            this.createPodcastForm.get('schoolId').setValue(this.userService.getUserSchool());
        }
        const podcast = this.createPodcastForm.getRawValue() as Podcast;

        if (this.createPodcastForm.errors) {
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }

        this.loading = true;

        this.podcastService.setPodcasts(podcast)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Podcast adicionada', 3);
                    this.createPodcastForm.reset();
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar adicionar a podcast. Verifique a conexão e tente novamente');
                this.loading = false;
            });

    }

    removePodcast(id: number) {
        this.deleting = true;
        this.podcastService.removePodcast(id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Podcast removido!');
                    this.getPodcasts(this.filterSchool);
                } else {
                    this.alertService.error(response.error);
                }

                this.deleting = false;
            }, err => {
                this.alertService.error('Houve um erro ao excluir o podcast. Verifique a conexão e tente novamente');
                this.deleting = false;
            });
    }
}

