import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { AdminService } from '../../admin/admin.service';
import { EssayCredit } from '../../admin/essay/essay-credit';
import { EssayCreditService } from '../../admin/essay/essay-credit.service';
import { UserService } from '../../user/user.service';
import { Redaction } from '../redaction';

@Component({
  selector: 'app-buy-credit',
  templateUrl: './buy-credit.component.html',
  styleUrls: ['./buy-credit.component.scss']
})
export class BuyCreditComponent implements OnInit {

  public redactions: Redaction[];
  private admUpdate$: Subscription;
  public buyMoreCredits: boolean;
  public showCredit: boolean;
  public iframeCredit: EssayCredit[];
  public link: string;

  
  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private adminService: AdminService,
    private userService: UserService,
    public layoutService: LayoutService,
    public essayCreditService: EssayCreditService
    
  ) { }

  init(){
    this.getEssayCredits(Number(this.userService.getUserSchool()));
  }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }


  getEssayCredits(schoolId: number){
    this.essayCreditService.getEssayCredits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.iframeCredit = response.data as EssayCredit[];
          this.iframeCredit.forEach(element => {
            if(element.link){
              this.link = element.link;
            }
          });
        } else {
         // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os créditos das escolas. Verifique a conexão e tente novamente!'));
  }

}
