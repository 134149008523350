import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notifications } from './notifications';
import { TypesNotifications } from './types-notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  getTypesNotifications() {
    return this.http.get(this.API + '/notifications/types', { observe: 'response' });
  }
  createNotifications(notify: Notifications){
    return this.http.post(this.API + '/notifications', notify , { observe: 'response' });
  }
  removeNotification(notificationId: number){
    return this.http.delete(this.API + '/notifications'+notificationId, { observe: 'response' });
  }
  updateNotifications(notify: Notifications){
    return this.http.put(this.API + '/notifications/update', notify , { observe: 'response' });
  }
  getAllNotificationsCreated(){
    return this.http.get(this.API + '/notifications/all/created', { observe: 'response' });
  }
  getAllNotificationsCreatedBySchool(schoolId: number){
    return this.http.get(this.API + '/notifications/created/school/'+schoolId, { observe: 'response' });
  }

  getNotificationsCreatedToUnit(unitId: number){
    return this.http.get(this.API + '/notifications/created/unit/'+unitId, { observe: 'response' });
  }

  getNotificationsCreatedToClass(classId: number){
    return this.http.get(this.API + '/notifications/created/class/'+classId, { observe: 'response' });
  }

  getAllNotificationsCreatedByPlan(planId: number){
    return this.http.get(this.API + '/notifications/created/plan/'+planId, { observe: 'response' });
  }
  getNotifyByTitle(title: string){

    return this.http.post(this.API + '/notifications/title', { title }, { observe: 'response' });
    //return this.http.get(this.API + '/notifications/title/'+title, { observe: 'response' });
  }
  getNotificationsByProfile(profile: number){
    return this.http.get(this.API + '/notifications/profile/'+profile, { observe: 'response' });
  }
  getNotificationsByType(type: string){
    return this.http.get(this.API + '/notifications/type/'+type, { observe: 'response' });
  }
  /* markAsRead(notificationId: number, userId:number){
    return this.http.post(this.API + '/notifications/read', {notificationId, userId}, { observe: 'response' });
  } */

  markAsRead(notification: Notifications, userId:number){
    return this.http.post(this.API + '/notifications/read', {notification, userId}, { observe: 'response' });
  }

  getProfilesNotifications(){
    return this.http.get(this.API + '/notifications/profiles', { observe: 'response' });
  }

  getNotificationProfiles(notificationId: number){
    return this.http.get(this.API + '/notifications/profiles/'+notificationId, { observe: 'response' });
  }


  getSchoolNotificationsByProfile( school: number, profile: number){
    return this.http.get(this.API + '/notifications/school/profile/'+school+'/'+profile, { observe: 'response' });
  }

  getUsersByName(name: string) {
    return this.http.get(this.API + '/notifications/search?name='+name, { observe: 'response' });
  }

  getUsersByEmail(email: string) {
    return this.http.get(this.API + '/notifications/search?email='+email, { observe: 'response' });
  }
  /* ALL NOTIFICATIONS */
  getAllNotifications(schoolId:number, userId:number, profile: number) {
    return this.http.post(this.API + '/notifications/all', {schoolId, userId, profile}, { observe: 'response' });
  }
}
