<div class="container pt-5 pb-5">

    <mat-expansion-panel class="width-70 center" *ngIf="!userService.isTeacher()">
        <mat-expansion-panel-header>
            <span class="text-color-accent">Adicionar Pasta</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <app-repository-folder-add></app-repository-folder-add>
        </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="width-70 center mt-4">
      <mat-expansion-panel-header>
          <span class="text-color-accent">Adicionar Arquivo (Repositório)</span>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
          <app-repository-file-add></app-repository-file-add>
      </ng-template>
  </mat-expansion-panel>

    <mat-expansion-panel class="width-70 center  mt-4">
        <mat-expansion-panel-header>
            <span class="text-color-accent">Adicionar Vídeo (Repositório)</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <app-repository-video-add></app-repository-video-add>
        </ng-template>
    </mat-expansion-panel>

    <!--     <mat-expansion-panel class="width-70 center mt-4">
        <mat-expansion-panel-header>
            Adicionar Material
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <app-material-add></app-material-add>
        </ng-template>
    </mat-expansion-panel> -->

</div>
