import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'; 
 

@Injectable({
  providedIn: 'root'
})
export class DuplicateDisciplineService {
  private API = environment.ApiUrl;

  public emitDuplicateDiscipline = new EventEmitter<any>();

  constructor(private http: HttpClient  ) { }

  getDiscipline(disciplineId: any) {
    return this.http.get(this.API + '/duplicate/discipline/'+disciplineId,   { observe: 'response' });
  }  
  getDisciplineExercises(disciplineId: any) {
    return this.http.get(this.API + '/duplicate/discipline/'+disciplineId+'/exercises',   { observe: 'response' });
  } 
  getDisciplineVideos(disciplineId: any) {
    return this.http.get(this.API + '/duplicate/discipline/'+disciplineId+'/videos',   { observe: 'response' });
  }

  getDisciplineVideoExercises(videos: any) {
    return this.http.post(this.API + '/duplicate/discipline/videos/exercises', videos,  { observe: 'response' });
  }
  getDisciplineVideoIframes(videos: any) {
    return this.http.post(this.API + '/duplicate/discipline/videos/iframes', videos,   { observe: 'response' });
  }
  getDisciplineVideoAnnex(videos: any) {
    return this.http.post(this.API + '/duplicate/discipline/videos/annex', videos,   { observe: 'response' });
  }

  saveDiscilpline(schoolId:number, discipline: any) {
    return this.http.post(this.API + '/duplicate/discipline/'+schoolId, discipline,   { observe: 'response' });
  }
}
