import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
 
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { Student } from '../../student/student';
import { IntegrationsService } from '../integrations.service';

@Component({
  selector: 'app-integration-convergindo',
  templateUrl: './integration-convergindo.component.html',
  styleUrls: ['./integration-convergindo.component.scss']
})
export class IntegrationConvergindoComponent implements OnInit {

  public loading:boolean = false;
  public editMode:boolean = false;
  public formConvergindo: FormGroup;
  public students:any;
  public studentsList:any; 
  public studentsSelected: number;
  public totalPage:number = 0;
  public pageSize:number  = 0;
  public schools:School[];
  public units:Unit[];
  public classes:Class[]; 
  public classId:number;
  public classIdList:number; 
  public openList:boolean = false;
  public allStudentsSelected:boolean = false;

  constructor(  public userService: UserService, 
                private integrationService: IntegrationsService,
                private formBuilder: FormBuilder,
                private alertService: AlertService,
                private schoolService: SchoolService,) { }

  ngOnInit() {
    
    this.formConvergindo = this.formBuilder.group({
        id: [''],
        studentId: ['', Validators.required],
        name: ['', Validators.required],
        checked: [false, Validators.required],
        class: ['', Validators.required],
        studentsSelected:['', Validators.required],
      });
    this.profileInit();
    this.show();

  }
  logme(data){
    console.log(data);
  }
  profileInit() { 
    if (this.userService.isAdmin()) {
      this.getSchools(); 
    } else if (this.userService.isGeneralCoordinator()) {
        const schoolId = this.userService.getUserSchool(); 
        this.getUnits(schoolId); 
    } 
  }
  show(offset?:number,pages?:number){
    this.integrationService.getUnitStudents(offset, pages)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      const totalpage = res.body['totalPage'];

      if (!response.error) {
        this.students = response.data ;
        this.totalPage = totalpage as number;
      }
      else {
        this.alertService.error(response.error);
      }
    })
  }
  selectAllStudents($event){
    this.allStudentsSelected = $event; 
  }
  create(){

      let usersId = [this.studentsSelected];

      if(this.allStudentsSelected){

        usersId = this.students.map(students =>{
          return students.id;
        });
      }
      
      this.integrationService.create(usersId)
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
          
              //this.students = response.data as Student[];
              this.alertService.success("Criado com sucesso!");
             
          } else {
              this.alertService.error(response.error);
          }
          this.loading = false;
      }, err => {
          this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
          this.loading = false;
      });  
  }

  delete(id:number){

    if(confirm("Deseja realmente excluir?")){ 
     
      this.integrationService.delete(id)
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
          
            this.studentsList =  this.studentsList.filter(students =>{return students.id !=id; })
            this.alertService.success("Removido com sucesso!"); 
          } else {
              this.alertService.error(response.error);
          }
          this.loading = false;
      }, err => {
          this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
          this.loading = false;
      });
    }
  } 

  getSchools() {
    this.schoolService.getSchoolsSelection()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.schools = response.data as School[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }
  getUnits(schoolId: number) {
      this.schoolService.getUnitsSelection(schoolId)
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                  this.units = response.data as Unit[];
              } else {
                  this.alertService.error(response.error);
              }
          }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
  }
  getClasses(unitId: number) {
      this.schoolService.getClassesSelection(unitId)
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                  this.classes = response.data as Class[];
              } else {
                  this.alertService.error(response.error);
              }
          }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getStudents(classId: number) {
      // classId = this.userService.isGeneralCoordinator() ? this.userService.getUserSchool() : undefined;
      this.loading = true;
      this.classId = classId; 
      this.students =[];
      this.schoolService.getClassStudentsSelect(classId)
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
              
                  this.students = response.data['students'] as Student[];

                  console.log(this.students); 
              } else {
                  this.alertService.error(response.error);
              }
              this.loading = false;
          }, err => {
              this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
              this.loading = false;
          });
  }
  pageEvent(event: any) {
    const offset = event.pageIndex * event.pageSize;
    this.getStudentsList(this.classIdList,offset, event.pageSize);
  }

  getStudentsList(classId: number, offset?:number, pag?:number) {
    
    this.loading = true;

    offset = offset  || 0;
    pag    = pag  || 200;
    this.classIdList = classId;
    this.studentsList = [];
    this.integrationService.getConvStudents(classId,offset,pag)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
            
                this.studentsList = response.data['students'] as Student[];
                //this.totalPage    = Math.ceil(response.data['totalPage'] as number/ this.pageSize);
                this.totalPage =    response.data['totalPage'];
                this.pageSize  = 200;
            } else { 
              this.alertService.error(response.error);
            }
            this.loading = false;
        }, err => {
            this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
            this.loading = false;
        });
  }
   
} 
