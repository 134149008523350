<div class="container pt-5 pb-5">
    <mat-accordion>
        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-70 center">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Plano</span> 
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-payment-create-plan></app-payment-create-plan>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Cupom</span> 
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-payment-coupon></app-payment-coupon>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Experimente</span> 
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-experiment-create></app-experiment-create>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>

</div>