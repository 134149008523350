<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <!-- <app-integration-portal></app-integration-portal> -->
    <mat-toolbar *ngIf="user$ | async as user" class="mat-toolbar not-print mat-elevation-z6 box-shadow-none" [ngClass]="{'header-default': theme.showHeaderImg && !theme.headerImg}">
        <button mat-icon-button (click)="snav.toggle()" class="hvr-grow">
            <mat-icon color="primary">menu</mat-icon>
        </button>
        <span class="header-spacer"></span>
        <!-- mobile logo -->
        <img routerLink="dashboard" class="mobile-logo" [src]="theme?.logo">
        <span class="header-spacer"></span>
        <!-- <button mat-button class="btn-groupwork" (click)="openIntegrations()" *ngIf="rdoLink != '' || uniLink != '' || convergindoLink != '' || serenLink != ''">
            <mat-icon>group_work</mat-icon>
        </button> -->
        <app-notifications id="notifications"></app-notifications>
        <div class="text-right user-menu white" *ngIf="user$ | async as user">
            <div class="thumbnail ml-1 pointer" [matMenuTriggerFor]="menu" [style.background-image]="user.image ? 'url('+user.image+')' : 'url('+API+'/store/students/avatar.png)'">
            </div>
            <button *ngIf="!layoutService.isMobile" mat-button [matMenuTriggerFor]="menu">
                <b>{{user.name}}</b>
                <mat-icon color="accent">keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item routerLink="user/profile">Editar perfil</button> -->
                <button *ngIf="userService.isStudent() && showPortal" mat-menu-item (click)="goToPortal()">Voltar ao portal</button>
                <button mat-menu-item (click)="logout(); snav.close()">Sair</button>
            </mat-menu>
        </div>
    </mat-toolbar>
    <mat-sidenav-container class="example-sidenav-container">
        <mat-sidenav [opened]="(user$ | async) && !mobileQuery.matches" class="not-print sidenav-color" #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="true" fixedTopGap="0">

            <div class="sidenav-top p-3">
                <div class="logo-menu">
                    <a class="logo-background no-logo center" routerLink="dashboard">
                        <img style="width: 80%;" [src]="theme?.logo">
                    </a>

                    <button *ngIf="user$ | async as user" mat-icon-button (click)="snav.toggle()" class="hvr-grow white">
                        <mat-icon color="primary">menu</mat-icon>
                    </button>
                </div>
                <mat-nav-list *ngIf="user$ | async as user" class="mt-2" [ngClass]="{'mt-4': layoutService.isMobile}">
                    <div *ngFor="let screen of user.screens; let i = index">
                        <a *ngIf="screen.description === 'Início'" mat-list-item [routerLink]="'dashboard'" routerLinkActive="active-route" class="menu-item-label">
                            <mat-icon color="accent" class="mr-3">home</mat-icon> Início
                        </a>
                    </div>
                </mat-nav-list>
                <!--  <mat-nav-list class="mt-2" *ngIf="rdoLink != '' && uniLink != '' && convergindoLink != '' && serenLink != ''">
                    <a mat-list-item (click)="openIntegrations()" class="menu-item-label">
                        <mat-icon color="accent" class="mr-3">group_work</mat-icon> Portal
                    </a>
                </mat-nav-list> -->
                <mat-nav-list class="d-none">
                    <a mat-list-item [routerLink]="'dashboard'" *ngIf="userService.getBeta() && userService.oldVersion == domain" (click)="goToVersion(userService.newVersion, true)" routerLinkActive="active-route" class="menu-item-label">
                        <mat-icon color="accent" class="mr-3">launch</mat-icon> Acessar Nova Versão
                    </a>
                    <a mat-list-item [routerLink]="'dashboard'" *ngIf="userService.getBeta() && userService.newVersion == domain" (click)="goToVersion(userService.oldVersion, true)" routerLinkActive="active-route" class="menu-item-label">
                        <mat-icon color="accent" class="mr-3">launch</mat-icon> Versão Antiga
                    </a>
                </mat-nav-list>
                <div class="small text-left mt-2 ml-3">
                    PLANOS DE ESTUDO
                </div>
            </div>
            <mat-nav-list class="pr-3 pl-3 pt-0">
                <div *ngIf="userService.isStudent() && showPortal">
                    <ng-container>
                        <a mat-list-item (click)="goToPortal()" class="menu-item-label mb-2">
                            <mat-icon class="mr-4 mat-accent">arrow_circle_left</mat-icon> Voltar ao portal
                        </a>
                    </ng-container>
                </div>
                <div *ngIf="user$ | async as user">
                    <ng-container *ngIf="uniLink">
                        <a mat-list-item (click)="link(uniLink)" class="menu-item-label mb-2" style="position: unset !important">
                            <mat-icon class="mr-4">leaderboard</mat-icon>
                            <span>Acesso às notas </span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="convergindoLink">
                        <a mat-list-item (click)="link(convergindoLink)" class="menu-item-label mb-2" style="position: unset !important">
                            <mat-icon class="mr-4 mat-accent">leaderboard</mat-icon>
                            <span>Estudo Personalizado</span>
                        </a>
                    </ng-container>
                    <ng-container *ngFor="let screen of (user.screens | orderBy:'order')">
                        <div *ngIf="screen.description !== 'Início' ">
                            <a *ngIf="!screen.external" mat-list-item [routerLink]="[screen.link]" (click)="layoutService.isMobile && snav.close()" routerLinkActive="active-route" class="menu-item-label mb-2">
                                <mat-icon color="accent" class="mr-4 ">{{screen.icon}}</mat-icon>
                                {{ screen.description }}
                            </a>
                            <a *ngIf="screen.external" mat-list-item href="#" (click)="link(screen.link)" class="menu-item-label mb-2">
                                <mat-icon color="accent" class="mr-4 ">{{screen.icon}}</mat-icon>
                                {{ screen.description }}
                            </a>
                        </div>
                    </ng-container>
                    <a mat-list-item href="#" (click)="logout(); snav.close()" class="menu-item-label">
                        <mat-icon color="accent" class="mr-3 ">exit_to_app</mat-icon>
                        Sair
                    </a>
                </div>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="main-container">
                <ng-content></ng-content>
            </div>
            <app-footer></app-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>