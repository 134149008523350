<div class="width-100 mt-2 mb-3">
    <div class="comment-content width-100">
        <form [formGroup]="commentForm">
            <mat-form-field appearance="outline" class="width-100 mt-form-textarea">
                <textarea class="textarea-comment" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="4" formControlName="text" matInput
                    placeholder="Adicionar um comentário"></textarea>
            </mat-form-field>
            <div [ngClass]="{'text-center': layoutService.isMobile, 'text-right': !layoutService.isMobile}">
                <button class="button-comment" *ngIf="!saving && !commentToResponse" [disabled]="commentForm.invalid" (click)="save()"
                    mat-raised-button color="accent" class="small-btn">
                    Comentar
                </button>
                <button *ngIf="!saving && commentToResponse" [disabled]="commentForm.invalid"
                    (click)="save(commentToResponse)" mat-stroked-button color="accent" class="small-btn">
                    Responder
                </button>
                <mat-spinner *ngIf="saving" color="accent" [diameter]="35" class="right"></mat-spinner>
            </div>
        </form>
    </div>
</div>