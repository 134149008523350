<div class="container pt-5 pb-5">
    <mat-accordion [multi]="true">
        <mat-expansion-panel *ngIf="channelEdit === null" class="width-70 center mt-4" [expanded]="expandPanel">
            <mat-expansion-panel-header *ngIf="!openChannelList">
                <span class="text-color-accent" >Criar Canal</span>
            </mat-expansion-panel-header>
            <mat-expansion-panel-header  *ngIf="openChannelList">
                <span class="text-color-accent" >Lista de Canais</span>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <div *ngIf="!openChannelList">
                    <form [formGroup]="formChannel">
                        <!-- Name, TAG -->
                        <div class="row" *ngIf="userService.isAdmin()">
                            <div class="col-md-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Escola</mat-label>
                                    <mat-select  placeholder="Escolas" formControlName="schoolId" >
                                        <mat-option *ngFor="let school of schools" [value]="school.id">
                                            {{school.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="width-100">
                                    <input matInput placeholder="Nome (obrigatório)" formControlName="name">
                                </mat-form-field>
                            </div>
                        </div>
                    </form>                
                    <div *ngIf="channelEdit === null" class="row mt-5">
                        <div class=" col-6 width-100">
                            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver canais cadastrados</button>
                        </div>
                        <div class=" col-6 text-right width-100">
                            <button (click)="createChannels();" class="right bg-color-primary border-primary-thin" 
                                nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="!formChannel.valid"
                                [ngClass]="{'inactive-button': !formChannel.valid}">Cadastrar
                            </button>
                        </div>
                    </div>
                </div>    
                <div *ngIf="openChannelList">
                    <div class="row">
                        <div class="col back-button d-flex flex-row-reverse">
                            <div class="col-3 d-flex justify-content-center">
                                <button (click)="openChannelList = false" class="right" nbButton size="small" status="primary">voltar</button>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="userService.isAdmin()">
                        <div class="col-md-12">
                            <mat-form-field class="w-100">
                                <mat-label>Escola</mat-label>
                                <mat-select  placeholder="Escolas" formControlName="school" (selectionChange)="selectedSchool = $event.value; getAllChannelsBySchool($event.value)">
                                    <mat-option *ngFor="let school of schools" [value]="school.id">
                                        {{school.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <nb-list class="col-12 mt-3 center">
                            <nb-list-item  *ngFor="let chan  of channels" class="w-100 pr-0">
                                <div class="row">
                                    <div class="col-9">
                                        {{chan.getChannel().name}}
                                    </div>
                                    <div class="col-3 text-right p-0">
                                        <button (click)="editChannel(chan)" nbButton size="tiny" outline status="primary" class="bg-color-accent-200 border-accent">
                                            <nb-icon icon="edit" class="height-75 text-color-pr"></nb-icon>
                                        </button>
                                        <button size="tiny" (click)="removeChanel(chan.getChannel().id)" status="danger" nbButton ghost>
                                            <nb-icon icon="trash"></nb-icon>
                                        </button>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                    </div>
                </div>    
            </ng-template>
        </mat-expansion-panel>

        <app-edit-channel *ngIf="channelEdit"
            [channelEdit]="channelEdit" 
            (channelEdited)="channelSave($event)"
            (backListChannels)="backListChannels($event)" >
        </app-edit-channel>


    </mat-accordion>
</div>