import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Video } from './video';
import { Annex } from '../admin/video/annex/annex';
import { ReportError } from '../report-error/report-error';
import { Iframe } from '../admin/video-iframe/iframe';
import { Discipline } from '../admin/discipline/discipline';

@Injectable({ providedIn: 'root' })
export class VideoService {

    private API = environment.ApiUrl;

    public emitBackIsDone = new EventEmitter<any>();

    constructor(private http: HttpClient) { }

    setBackEvent(data:any){
        this.emitBackIsDone.emit(data);
    }
    getVideo(videoId: number , userId? : number) {
        if(!userId){
            userId = 0;
        }
        return this.http.post(this.API + '/video/getVideo', { videoId , userId }, { observe: 'response' });
    }

    getVideoExercises(videoId: number) {
        return this.http.post(this.API + '/video/getExercises', { videoId }, { observe: 'response' });
    }

    sendExerciseResponse(exerciseId: number, alternative: string, studentId: number) {
        return this.http.post(this.API + '/video/saveExerciseResponse', { exerciseId, alternative, studentId }, { observe: 'response' });
    }

    getExerciseCorrectAlternative(exerciseId: number) {
        return this.http.post(this.API + '/video/getExerciseCorrectAlternative', { exerciseId }, { observe: 'response' });
    }

    verifyResponseExercise(exerciseId: number, studentId: number) {
        return this.http.post(this.API + '/video/verifyResponseExercise', { exerciseId, studentId }, { observe: 'response' });
    }

    getVideos() {
        return this.http.get(this.API + '/video/getAllVideos', { observe: 'response' });
    }

    getVideosByPlan(planId: number) {
        return this.http.post(this.API + '/video/getVideosByPlan', {planId} , { observe: 'response' });
    }

    getTotalLikes(videoId: number) {
        return this.http.post(this.API + '/video/getTotalLikes', {videoId} , { observe: 'response' });
    }

    getUserLikeDeslike(userId : number , videoId) {
        return this.http.post(this.API + '/video/getUserLikeDeslike', {userId,videoId} , { observe: 'response' });
    }

    setLike(userId : number , videoId : number , like : boolean) {
        return this.http.post(this.API + '/video/setLike', {userId , videoId , like} , { observe: 'response' });
    }

    setDeslike(userId : number , videoId : number , deslike : boolean) {
        return this.http.post(this.API + '/video/setDeslike', {userId , videoId , deslike} , { observe: 'response' });
    }

    createIframe(iframe: Iframe) {
        return this.http.post(this.API + '/video/createIframe', {iframe} , { observe: 'response' });
    }

    updateIframe(iframe: Iframe) {
        return this.http.post(this.API + '/video/updateIframe', iframe , { observe: 'response' });
    }

    getIframes() {
        return this.http.post(this.API + '/video/getIframes', {} , { observe: 'response' });
    }

    getIframesBySchool(schoolId: number) {
        return this.http.post(this.API + '/video/getIframesBySchool', {schoolId} , { observe: 'response' });
    }

    getIframeVideoByDiscipline(disciplineId: number , videoId: number) {
        return this.http.post(this.API + '/video/getIframeVideo', {disciplineId , videoId} , { observe: 'response' });
    }

    getIframeVideoBySchool(schoolId: number , videoId: number) {
        return this.http.post(this.API + '/video/getIframeVideo', {schoolId , videoId} , { observe: 'response' });
    }

    getIframeByName(name: string) {
        return this.http.post(this.API + '/video/getIframeByName', {name} , { observe: 'response' });
    }

    getIframeByNameAndSchool(name: string , schoolId : number) {
        return this.http.post(this.API + '/video/getIframeByNameAndSchool', {name , schoolId} , { observe: 'response' });
    }

    deleteIframe(planId : number , videoId: number) {
        return this.http.post(this.API + '/video/deleteIframe', {planId , videoId} , { observe: 'response' });
    }

    getDisciplineVideos(disciplineId: number) {
        return this.http.post(this.API + '/video/getDisciplineVideos', { disciplineId }, { observe: 'response' });
    }

    getDisciplinesAllVideos(discipline: Discipline[]) {
      return this.http.post(this.API + '/video/getDisciplinesAllVideos', { discipline }, { observe: 'response' });
  }

    getVideoDescription(videoId: number) {
        return this.http.post(this.API + '/video/getVideoDescription', { videoId }, { observe: 'response' });
    }

    getVideoChat(videoId: number) {
        return this.http.post(this.API + '/video/getVideoChat', { videoId }, { observe: 'response' });
    }

    getVideoYTMask(videoId: number) {
        return this.http.post(this.API + '/video/getVideoYTMask', { videoId }, { observe: 'response' });
    }

    getVideoIsRecord(videoId: number) {
        return this.http.post(this.API + '/video/getVideoIsRecord', { videoId }, { observe: 'response' });
    }

    getReportsAll(offset: number , limit: number) {
        return this.http.post(this.API + '/video/getReportsAll', {offset , limit} , { observe: 'response' });
    }

    getReportsByName(filterName: string ,offset: number , limit: number) {
        return this.http.post(this.API + '/video/getReportsByName', {filterName ,offset , limit} , { observe: 'response' });
    }

    deleteReport(reportId: number) {
        return this.http.post(this.API + '/video/deleteReport', {reportId} , { observe: 'response' });
    }

    updateReport(report: ReportError) {
        return this.http.post(this.API + '/video/updateReport', report , { observe: 'response' });
    }

    getRatingAll(offset: number , limit: number) {
        return this.http.post(this.API + '/video/getRatingAll', {offset , limit} , { observe: 'response' });
    }

    getRatingAllByName(filterName: string ,offset: number , limit: number) {
        return this.http.post(this.API + '/video/getRatingAllByName', {filterName ,offset , limit} , { observe: 'response' });
    }

    deleteRating(ratingId: number) {
        return this.http.post(this.API + '/video/deleteRating', {ratingId} , { observe: 'response' });
    }

    updateRating(ratingId: number , starRating: number , description: string) {
        return this.http.post(this.API + '/video/updateRating', {ratingId , starRating , description} , { observe: 'response' });
    }

    getAllVideos() {
        return this.http.get(this.API + '/video/getAllVideos', { observe: 'response' });
    }

    getPlanVersion(planId: number) {
        return this.http.post(this.API + '/video/getPlanVersion', {planId} , { observe: 'response' });
    }

    addVideo(video: Video) {
        return this.http.post(this.API + '/video/addVideo', video, { observe: 'response' });
    }

    addVideoLive(video: Video) {
        return this.http.post(this.API + '/video/addVideoLive', video, { observe: 'response' });
    }

    ratingVideo(videoId : number , rating: number , description: string , userId: number , videoName: string) {
        return this.http.post(this.API + '/video/ratingVideo', {videoId , rating , description , userId , videoName}, { observe: 'response' });
    }

    getUserRate(videoId : number ,  userId: number ) {
        return this.http.post(this.API + '/video/getUserRate', {videoId ,userId}, { observe: 'response' });
    }

    addReportError(data:ReportError) {
        return this.http.post(this.API + '/video/addReportError', data, { observe: 'response' });
    }

    removeAnnex(annexId: any) {
        return this.http.post(this.API + '/video/removeAnnex', annexId, { observe: 'response' });
    }

    addAnnex(annex: Annex) {
        return this.http.post(this.API + '/video/addAnnex', annex, { observe: 'response' });
    }

    removeVideo(videoId: number) {
        return this.http.post(this.API + '/video/removeVideo', { videoId }, { observe: 'response' });
    }

    getAllAnnex() {
        return this.http.get(this.API + '/video/getAllAnnex', { observe: 'response' });
    }

    markAsWatched(videoId: number, studentId: number) {
        return this.http.post(this.API + '/video/markAsWatched', { videoId, studentId }, { observe: 'response' });
    }

    getDayVideos(planId: number, studentId: number, scheduleDay: number) {
        return this.http.post(this.API + '/calendar/getDayVideos', { planId, studentId, scheduleDay }, { observe: 'response' });
    }

    getVideoAnnex(videoId: number) {
        return this.http.post(this.API + '/video/getVideoAnnex', { videoId }, { observe: 'response' });
    }

    addWatchedVideo(videoId: number, studentId: number, planId: number , duration?:string) {
        return this.http.post(this.API + '/video/addWatchedVideo', { videoId, studentId, planId , duration }, { observe: 'response' });
    }

    setUnwatchedVideo(videoId: number, studentId: number, planId: number) {
        return this.http.post(this.API + '/video/setUnwatchedVideo', { videoId, studentId, planId }, { observe: 'response' });
    }

    saveVideoChanges(video: Video) {
        return this.http.post(this.API + '/video/saveVideoChanges', video, { observe: 'response' });
    }

    saveVideoChangesLive(video: Video) {
        return this.http.post(this.API + '/video/saveVideoChangesLive', video, { observe: 'response' });
    }

    getVideosByName(name: string) {
        return this.http.post(this.API + '/video/getVideosByName', { name }, { observe: 'response' });
    }

    getVideosByNameCoord(name: string , schoolId : number) {
        return this.http.post(this.API + '/video/getVideosByNameCoord', { name , schoolId }, { observe: 'response' });
    }

    getVideosByNameCalendar(name: string , schoolId: number) {
        return this.http.post(this.API + '/video/getVideosByNameCalendar', { name , schoolId }, { observe: 'response' });
    }

    instanceOfVideo(object: any): object is Video {
            return 'title' in object;
    }
}
