<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-5 mt-2">
            <ng-container *ngIf="!layoutService.isMobile || !planSelected">
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">Salas de aula</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Ao Vivo</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Ao Vivo</h6>
                        <p class="fs-13 pb-0">Escolha o plano que você deseja estudar</p>
                    </div>
                </div>
                <!-- SELECT PLANO LISTA DESKTOP -->
                <div class="plan-list" *ngIf="!layoutService.isMobile">
                    <mat-list *ngIf="!loadingPlans">
                        <mat-list-item *ngFor="let plan of studyPlans" (click)="selectPlan(plan)">
                            <div class="plan-item mb-3" matRipple [ngClass]="{'bg-color-accent': planSelected == plan?.name}">
                                <div class="plan-icon">
                                    <mat-icon [color]="(planSelected == plan?.name) ? null : 'accent'">task_alt</mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ plan?.name }}</strong>
                                    <!-- <span class="plan-expire"> Válido até {{ plan?.expireDate | date:'dd/MM/y'}}</span> -->
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                </div>
                <!-- SELECT PLANO LISTA MOBILE -->
                <ng-container *ngIf="layoutService.isMobile">
                    <div id="filter" class="mobile">
                        <mat-form-field class="width-100 m-0 p-0 form-field" appearance="fill" *ngIf="!loadingPlans">
                            <mat-select placeholder="Selecione um plano" class="p-0 m-0 form-select fs-12" (selectionChange)="selectPlan($event.value)">
                                <mat-option [value]="plan" *ngFor="let plan of studyPlans">
                                    {{plan.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                    </div>
                </ng-container>
            </ng-container>
            <!-- mobile only -->
            <ng-container *ngIf="layoutService.isMobile && planSelected">
                <div class="selected-plan text-center mt-2 pt-10">
                    <div class="lesson-title">
                        <div class="page-title">Plano selecionado</div>
                        <h3 class="mb-3">{{planSelected}}</h3>
                    </div>
                    <div class="lesson-back">
                        <button (click)="planSelected= null" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-7 height-100" [ngClass]="{'margin-de-cima': !layoutService.isMobile}">
            <div class="col-md-6" *ngIf="!layoutService.isMobile && planSelected">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Buscar pelo nome" [(ngModel)]="filterClass" (keyup)="applyFilter()">
                </mat-form-field>
            </div>
            <!-- exercise list -->
            <ng-container>
                <div *ngIf="classRoomDataSource?.data?.length && !loadingClassRooms && planSelected || layoutService.isMobile && classRoomDataSource?.data?.length && planSelected" class="table-container table-container-discipline height-100 mt-2">
                    <nb-list class="width-100 mb-3">
                        <nb-list-item class="bg-color-accent list-header brl-5 brr-5 fs-13">
                            <div class="row m-0">
                                <div [ngClass]="{'col-md-5': !layoutService.isMobile , 'col-10' : layoutService.isMobile}">Nome</div>
                                <div class="col-md-5 c-pointer" (click)="getOrder('class.initDate')" [hidden]="layoutService.isMobile">
                                    <span class="d-inline-flex">
                                        <span class="mr-2">Horário </span>
                                    <mat-icon class="material-icons line-height-07" *ngIf="!reverse">arrow_drop_down</mat-icon>
                                    <mat-icon class="material-icons line-height-07" *ngIf="reverse">arrow_drop_up</mat-icon>
                                    </span>
                                </div>
                                <div [ngClass]="{'col-md-2': !layoutService.isMobile , 'col-2' : layoutService.isMobile}">Link</div>
                            </div>
                        </nb-list-item>
                        <nb-list-item *ngFor="let class of classroomsFilter | orderBy: order:reverse; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                            <div class="row m-0">
                                <div [ngClass]="{'col-md-5': !layoutService.isMobile , 'col-9' : layoutService.isMobile}">
                                    {{class.name}}
                                </div>
                                <div class="col-md-5" [hidden]="layoutService.isMobile">
                                    {{class.initDate | date:'dd/M/yyyy'}} - {{class.initDate | date:'HH:mm'}} às {{class.endDate | date:'HH:mm'}}
                                </div>
                                <div [ngClass]="{'col-md-2': !layoutService.isMobile , 'col-2' : layoutService.isMobile}">
                                    <button mat-button color="accent" (click)="openClassLink(class.link, class.planId)">
                                        <strong>Acessar</strong>
                                    </button>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>
                <!-- select a folder -->
                <div *ngIf="!planSelected && !loadingClassRooms && !layoutService.isMobile" class="plan-not-selected">
                    <h4>Selecione um plano</h4>
                    <br />
                    <p>Escolha um plano para carregar as salas de aula</p>
                </div>
                <!-- empty folder -->
                <div *ngIf="classRoomDataSource && !classRoomDataSource?.data?.length && !loadingClassRooms" class="plan-not-selected">
                    <h4>Nenhuma sala de aula</h4>
                    <br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
                <mat-spinner *ngIf="loadingClassRooms" class="center table-spinner" color="accent"></mat-spinner>
            </ng-container>
        </div>
    </div>
</div>
