import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ClassroomService } from '../classroom.service';
import { Classroom } from '../classroom';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../user/user.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-classroom-list',
    templateUrl: 'classroom-list.component.html',
    styleUrls: ['classroom-list.component.scss']
})

export class ClassroomListComponent implements OnInit {

    public classrooms: Classroom[];
    @Output() haveClassrooms = new EventEmitter();
    @Input() planId: number;
    public lottieOptions: AnimationOptions;
    public loading: boolean;

    public currentPagina: number = 1;

    constructor(
        private classroomService: ClassroomService,
        private alertService: AlertService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        public layoutService: LayoutService) { }

    ngOnInit() {
        this.iconRegistry.addSvgIcon(
            'live',
            this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/yt-live.svg'));
            this.getClassClassrooms(this.userService.getUserId() , this.userService.getUserClass());
        this.emptyDisciplinesLottieOptionsConfig();
    }

    getPlanClassrooms(planId: number, schoolId:number) {
        this.loading = true;
        //this.classroomService.getFuturePlanClassrooms(planId, schoolId)
        this.classroomService.getFuturePlanClassrooms(planId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.classrooms = response.data as Classroom[];
                    if (this.classrooms?.length > 0) {
                        this.haveClassrooms.emit(true);
                    } else {
                        this.haveClassrooms.emit(false);
                    }
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));

    }

    getClassClassrooms(userId: number , classId : number) {
        this.loading = true;
        this.classroomService.getClassromsByClass(userId , classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classrooms = response.data as Classroom[];
                    if (this.classrooms?.length > 0) {
                        this.haveClassrooms.emit(true);
                    } else {
                        this.haveClassrooms.emit(false);
                    }
                } else {

                }
                this.loading = false;
            }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));

    }

    openClassroom(link: string) {
        window.open(link, '_blank');
    }

    emptyDisciplinesLottieOptionsConfig() {
        this.lottieOptions = {
            path: 'assets/lotties/empty.json',
            loop: true,
            autoplay: true
        };
    }

    paginate(event: any) {
      //console.log("event in pagination aulas vivo: ", event);
      this.currentPagina = event;
    }
}
