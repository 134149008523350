<nb-list class="width-80 center">
  <nb-list-item class="bg-color-primary">
    <div class="row">
      <div class="col">
        <span class="">
          <ng-container *ngIf="userService.isStudent(); else teacher">
            Teacher
          </ng-container>
          <ng-template #teacher>
            Aluno
          </ng-template>
        </span>
      </div>
      <div class="col">
        <span class="">Início</span>
      </div>
      <div class="col">
        <span class="">Término</span>
      </div>
      <div class="col text-right">
      </div>
    </div>
  </nb-list-item>
  <nb-list-item *ngFor="let monitory of monitoring">
    <div class="row">
      <div class="col monitory-name">
        <span class="text-color-primary">
          <ng-container *ngIf="userService.isStudent(); else teacher2">
            {{monitory.teacherName}}
          </ng-container>
          <ng-template #teacher2>
            {{monitory.studentName}}
          </ng-template>
        </span>
      </div>
      <div class="col monitory-name">
        <span class="text-color-primary">{{monitory.initDate}}</span>
      </div>
      <div class="col monitory-name">
        <span class="text-color-primary">{{monitory.endDate}}</span>
      </div>
      <div class="col pt-2 text-right">
        <button color="accent" mat-mini-fab class="hvr-grow" (click)="openMonitory(monitory.link)">
          <mat-icon>launch</mat-icon>
        </button>
      </div>
    </div>
  </nb-list-item>
</nb-list>