import { Task } from './task';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskManagerService {

  private API = environment.ApiUrl;
  public emitSentTask = new EventEmitter<string>();

  constructor(
    private http: HttpClient,
    private alertService: AlertService) { }


  createTask(task: any, teacherName:string) {
      return this.http.post(this.API + '/task/createTask',  {task, teacherName} , { observe: 'response' });
  }
  getTasks(classId: number, userId:number, type:string) {
    return this.http.post(this.API + '/task/getTasks', { classId, userId, type }, { observe: 'response' });
  }
  getClassTasks(classId: number) {
    return this.http.post(this.API + '/task/getClassTasks', { classId}, { observe: 'response' });
  }
  getPlanTasks(planId: number) {
    return this.http.post(this.API + '/task/getPlanTasks', { planId }, { observe: 'response' });
  }
  getTasksSubmittedByPlan(planId: number, teacherId:number) {
    return this.http.post(this.API + '/task/getTasksSubmittedByPlan', { planId, teacherId }, { observe: 'response' });
  }
  getClassTasksTeacher(classId: number , userId:number) {
    return this.http.post(this.API + '/task/getClassTasksTeacher', { classId , userId}, { observe: 'response' });
  }
  getPlanTasksTeacher(planId: number , userId:number) {
    return this.http.post(this.API + '/task/getPlanTasksTeacher', { planId , userId}, { observe: 'response' });
  }
  getTasksAll(classId: number, teacherId?:number) {
    return this.http.post(this.API + '/task/getTasksAll', { classId, teacherId }, { observe: 'response' });
  }
  getTasksCoord(userId:number) {
    return this.http.post(this.API + '/task/getTasksCoord', { userId }, { observe: 'response' });
  }

  getTasksReceivedCoord(userId:number) {
    return this.http.post(this.API + '/task/getTasksReceivedCoord', { userId }, { observe: 'response' });
  }
  getTask(taskId : number) {
    return this.http.post(this.API + '/task/getTask', { taskId }, { observe: 'response' });
  }
  updateTask(task : Task) {
    return this.http.post(this.API + '/task/updateTask', task , { observe: 'response' });
  }
  getTasksReceived(teacherId:number) {
    return this.http.post(this.API + '/task/getTasksReceived', { teacherId }, { observe: 'response' });
  }
  getTaskSent(id: number) {
    return this.http.post(this.API + '/task/getTaskSent', { id } , { observe: 'response' });
  }
  getStudents(classId: number) {
    return this.http.post(this.API + '/task/getStudents', { classId }, { observe: 'response' });
  }
  getStudentsByPlan(planId: number) {
    return this.http.post(this.API + '/task/getStudentsByPlan', { planId }, { observe: 'response' });
  }
  getAllStudentsByTeacher(teacherId: number) {
    return this.http.post(this.API + '/task/getAllStudentsByTeacher', { teacherId }, { observe: 'response' });
  }
  getTeacherPlans(teacherId: number) {
    return this.http.post(this.API + '/study-plan/getTeacherPlans', { teacherId }, { observe: 'response' });
  }
  getPlansByClass(classId: number) {
    return this.http.post(this.API + '/study-plan/getPlansByClass', { classId }, { observe: 'response' });
  }
  getAllStudentsByCoordinator(coordId: number, profile:number) {
    return this.http.post(this.API + '/task/getAllStudentsByCoordinator', { coordId, profile }, { observe: 'response' });
  }
  removeTask(id: number) {
    return this.http.post(this.API + '/task/removeTask', { id }, { observe: 'response' });
  }
  getAllTasksStudents(studentId: number) {
    return this.http.post(this.API + '/task/getAllTasksStudents', { studentId } , { observe: 'response' });
  }
  getTaskPlans(taskId: number) {
    return this.http.post(this.API + '/task/getTaskPlans', { taskId } , { observe: 'response' });
  }
  getAllTasksStudentsForTeacher(studentId: number , userId:number) {
    return this.http.post(this.API + '/task/getAllTasksStudentsForTeacher', { studentId , userId } , { observe: 'response' });
  }
  getAllTasksStudentsForCoord(studentId: number) {
    return this.http.post(this.API + '/task/getAllTasksStudentsForCoord', { studentId } , { observe: 'response' });
  }
  getAllTeachers(schoolId:number) {
    return this.http.post(this.API + '/task/getAllTeachers', { schoolId } , { observe: 'response' });
  }
  getTeacher(teacherId: number) {
    return this.http.post(this.API + '/task/getTeacher', { teacherId } , { observe: 'response' });
  }
  sendCorrection(studentId:number, taskId:number, sendTask: Task){
    return this.http.post(this.API + '/task/sendCorrection',  {studentId, taskId, sendTask } , { observe: 'response' });
  }
  getTeacherUnit(unitId: number) {
    return this.http.post(this.API + '/task/getTeacherUnit', { unitId } , { observe: 'response' });
  }
  getAllTasksReceived() {
    return this.http.get(this.API + '/task/getAllTasksReceived', { observe: 'response' });
  }
  getAllTasksReceivedByGeneralCoordinator(schoolId:number) {
    return this.http.post(this.API + '/task/getAllTasksReceivedByGeneralCoordinator', { schoolId } , { observe: 'response' });
  }
  getAllTasksReceivedByUnitCoordinator(unitId:number) {
    return this.http.post(this.API + '/task/getAllTasksReceivedByUnitCoordinator', { unitId } , { observe: 'response' });
  }
  getClassesByTeacher(teacherId:number) {
    return this.http.post(this.API + '/task/getClassesByTeacher', { teacherId } , { observe: 'response' });
  }

  getClassName(classId:number){
    return this.http.post(this.API + '/task/getClassName', { classId } , { observe: 'response' });
  }
}
