<div *ngIf="!showList" mat-dialog-content>
    <form [formGroup]="folderForm">
        <h6 *ngIf="editMode" class="mt-3 mb-3">Editar Pastas</h6>
        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>
        </div>
        <!-- Videos -->
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getUnits($event.value); getSchoolTeachers($event.value);" formControlName="schoolId" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select [(ngModel)]="teachersModel" [ngModelOptions]="{standalone: true}" placeholder="Professores" (selectionChange)="teacherSelectionChange($event.value)" multiple [disabled]="!folderForm.get('schoolId').value">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar professor"  matTooltipClass="custom-tooltip" noEntriesFoundLabel="Professor não encontrado" [formControl]="teacherFilter" [searching]="filtering">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let teacher of filteredTeachers | async" [value]="teacher.id">
                                <span [title]="teacher.name">{{teacher.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedTeachers?.length > 0">
                    <mat-label class="mb-2">Professores selecionados</mat-label>
                    <mat-chip-list *ngIf="selectedTeachers">
                        <mat-chip *ngFor="let teacherChip of selectedTeachers" selectable="true" removable="true" (removed)="removeTeacherChip(teacherChip)">
                            {{teacherChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div class="row">
          <div class="col-md-12">
             <button (click)=" openSelectPlans()" class="left m-3 bg-color-accent-200 border-accent-thin" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning" nbButton size="small" status="primary">Adicionar planos</button>
          </div>
          <div class="col-md-12">
              <mat-checkbox formControlName="isTeacher"><span class="white-space">Arquivos Materiais (não aparece no menu 'Repositório')</span>
              </mat-checkbox>
          </div>

            <div *ngIf="selectedPlans?.length > 0" class="col-md-12">
              <mat-label class="m-1" status="primary">Planos selecionados</mat-label>
              <mat-chip-list class="pt-3">
                  <mat-chip *ngFor="let plan of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(plan)">
                    <span [title]="plan.name">{{plan.name}}</span> 
                      <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
              </mat-chip-list>
            </div>
        </div>
    </form>
    <div class="row">
        <div *ngFor="let unit of units" class="col-md-6" [ngClass]="{'col-md-12': userService.isTeacher(), 'col-md-6': !userService.isTeacher()}">
            <nb-flip-card (click)="getUnitsClasses(unit)">
                <nb-card-front>
                    <nb-card class="size-card">
                        <nb-card-body class="pt-5 text-center">
                            <h4 *ngIf="userService.isTeacher()" class="center">Turmas</h4>
                            <h4 *ngIf="!userService.isTeacher()" class="center">{{unit.name}}</h4>
                        </nb-card-body>
                    </nb-card>
                </nb-card-front>
                <nb-card-back>
                    <nb-card class="size-card">
                        <nb-card-header>
                            {{unit.name}}
                        </nb-card-header>
                        <nb-card-body>
                            <mat-list>
                                <mat-list-item *ngFor="let class of unit.classes">
                                    <div class="row width-100">
                                        <div class="col pt-2">
                                            {{class.name}}
                                        </div>
                                        <div class="col-2 text-right">
                                            <button (click)="selectClass(class)" mat-icon-button color="primary">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </nb-card-body>
                    </nb-card>
                </nb-card-back>
            </nb-flip-card>
        </div>
    </div>

    <!-- selecionado, pra quem não é professor -->
    <div class="row" *ngIf="!userService.isTeacher() && (selectedClasses?.length || editMode)">
        <div class="col">
            <nb-card size="small">
                <nb-card-header>
                    <h5>Selecionados</h5>
                </nb-card-header>
                <nb-card-body>
                    <mat-list>
                        <mat-list-item>
                            <div class="row width-100">
                                <div class="col pt-2">
                                    <b>Turma</b>
                                </div>
                                <div class="col pt-2">
                                    <b>Unidade</b>
                                </div>
                                <div class="col pt-2">
                                    <b>Escola</b>
                                </div>
                                <div class="col-2 text-right">

                                </div>
                            </div>
                        </mat-list-item>
                        <mat-list-item *ngFor="let class of selectedClasses; let i = index">
                            <div class="row width-100">
                                <div class="col pt-2">
                                    {{class.name}}
                                </div>
                                <div class="col pt-2">
                                    {{class.unit.name}}
                                </div>
                                <div class="col pt-2">
                                    {{class.school.name}}
                                </div>
                                <div class="col-2 text-right">
                                    <button (click)="removeSelectedClass(i)" mat-icon-button color="warn">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
    <!-- selecionadas professor -->
    <div class="row" *ngIf="userService.isTeacher() && (selectedClasses || editMode)">
        <div class="col">
            <nb-card size="small">
                <nb-card-header>
                    <h5>Selecionados</h5>
                </nb-card-header>
                <nb-card-body>
                    <mat-list>
                        <mat-list-item *ngFor="let class of selectedClasses; let i = index">
                            <div class="row width-100">
                                <div class="col pt-2">
                                    {{class.name}}
                                </div>
                                <div class="col-2 text-right">
                                    <button (click)="removeSelectedClass(i)" mat-icon-button color="warn">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </nb-card-body>
            </nb-card>
        </div>
    </div>

    <div class="row mt-5" *ngIf="!editMode">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver pastas Cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="add()" class="right bg-color-primary border-primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning" nbButton size="small" status="primary">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>

<div *ngIf="showList" class="">
    <div class="row">
        <div class="col-md-4" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getUnits($event.value)" placeholder="Escola">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getClasses($event.value)" placeholder="Unidade">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                        <span [title]="unit.name">{{unit.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="!userService.isTeacher()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Turmas" [(ngModel)]="filterClassId" (selectionChange)="getFolders($event.value)">
                    <mat-option *ngFor="let class of classes" [value]="class.id">
                        <span [title]="class.name">{{class.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
          <mat-form-field class="width-100">
              <mat-select placeholder="Planos" [(ngModel)]="filterPlansId" (selectionChange)="getFoldersPlan($event.value)">
                  <mat-option *ngFor="let plan of plans" [value]="plan.id">
                    <span [title]="plan.name">{{plan.name}}</span> 
                  </mat-option>
              </mat-select>
          </mat-form-field>
        </div>
    </div>

    <mat-list class="width-90 mt-3 center" *ngIf="!loading">
        <div mat-list-item *ngFor="let folder of folders" class="row" style="min-height: 100px">
            <div class="col-8">
                {{folder.name}}
            </div>
            <div class="col-2 text-right">
                <button (click)="edit(folder)" nbButton size="tiny" outline status="primary">Editar</button>
            </div>
            <div class="col-2 text-right">
                <button size="tiny" (click)="remove(folder.id)" status="danger" nbButton ghost>
                    <nb-icon icon="trash"></nb-icon>
                </button>
            </div>
        </div>
    </mat-list>

    <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>
