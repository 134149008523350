import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { disciplineTag } from 'src/app/modules/admin/discipline-tag/discipline-tag-create/discipline-tag';
import { Folder } from 'src/app/modules/admin/repository/folder/folder';
import { StudyPlan } from 'src/app/modules/admin/study-plan/study-plan';
import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { RepositoryService } from '../../repository.service';
import { fileClass, FileRepository } from '../file-repository';

@Component({
  selector: 'app-repository-file-list',
  templateUrl: './repository-file-list.component.html',
  styleUrls: ['./repository-file-list.component.scss']
})
export class RepositoryFileListComponent implements OnInit {

  public folders: Folder[];
  public files: fileClass[];
  public selectedFiles: FileRepository[];
  public loadingFolders: boolean;
  public loadingFiles: boolean;
  public fileDataSource: MatTableDataSource<FileRepository>;
  public displayedColumns: string[];
  public folderSelected: string | number;
  public firstFolder: Folder;
  public plans: StudyPlan[];
  public plansIds: Number[];
  public filesRepository: FileRepository[];
  public filterFiles: string;
  public filteredFiles: FileRepository[];
  public disciplineTag: disciplineTag[];
  public channelId: number;
  public order: string = 'name';
  public reverse: boolean = false;
  public orderMapping = {
    'noOrder': 'asc',
    'asc': 'desc',
    'desc': 'asc'
  };
  public nameDirection: 'asc' | 'desc' | 'noOrder' | string = 'asc';
  public dateDirection: 'asc' | 'desc' | 'noOrder' | string = 'noOrder';


  constructor(
    private repositoryService: RepositoryService,
    private alertService: AlertService,
    private userService: UserService,
    private studyPlanService: StudyPlanService,
    public deviceService: DeviceDetectorService,
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.displayedColumns = ['name', 'created_at' ,'link'];
    this.getChannelId()
  }

  getChannelId(): void {
    this.channelId = Number(this.route.snapshot.paramMap.get('channelId'));
    
    
    if(this.channelId && this.channelId != undefined){
      this.getChannelPlansFiles(this.channelId, Number(this.userService.getUserId()));
    }else{
      this.getClassPlansFiles(this.userService.getUserClass(), Number(this.userService.getUserId()));
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.channelId = Number(this.route.snapshot.paramMap.get('channelId'));
        //alert(this.channelId);
        if(this.channelId && this.channelId != undefined){          
          this.getChannelPlansFiles(this.channelId, Number(this.userService.getUserId()));
        }else{
          this.getClassPlansFiles(this.userService.getUserClass(), Number(this.userService.getUserId()));
        }
      } 
    })    
  }

  getChannelPlansFiles(channelId: number, studentId: number) {
    this.studyPlanService.getChannelPlansFiles(this.channelId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.plansIds = [];
          this.plans.forEach(element => {
            this.plansIds.push(element.id);
          });
        } else {
          this.alertService.error(response.error);
        }
        this.getChannelFolderList();
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');

      });
  }

  getClassPlansFiles(classId: number, studentId: number) {
    this.studyPlanService.getClassPlansFiles(classId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
        this.getFiles(this.userService.getUserClass());
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');

      });
  }

  getChannelFolderList() {
    let auxFiles = [];
    let auxIds = [];
    const schoolId = (this.userService.getUserSchool());
    this.loadingFolders = true;
    this.repositoryService.getChannelFolderList(schoolId, this.plansIds, this.channelId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.filesRepository = response.data as FileRepository[];
          this.files = response.data as fileClass[];
          this.files.forEach(f => {
            auxFiles.push(f.files);
          });

          //console.log(auxFiles);
          auxFiles.forEach(au => {
            au.forEach(element => {
              auxIds.push(element.id);
            });
          });
          this.getDisciplineFileTags(auxIds);
        } else {
          this.alertService.error(response.error);
        }
        this.loadingFolders = false;
      }, err => this.alertService.error('Houve um erro ao carregar os arquivos. Verifique a conexão e tente novamente'));
  }

  getFiles(classId: number) {
    const schoolId = (this.userService.getUserSchool());
    this.loadingFolders = true;
    this.repositoryService.getFileList(classId , schoolId, this.plans)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.filesRepository = response.data as FileRepository[];
          //this.firstFolder = this.Folder[0];

          this.files = response.data as fileClass[];
          let auxFiles = [];
          let auxIds = [];

          this.files.forEach(f => {
            auxFiles.push(f.files);
          });

          auxFiles.forEach(au => {
            au.forEach(element => {
              auxIds.push(element.id);
            });
          });

          this.getDisciplineFileTags(auxIds);
        } else {
          this.alertService.error(response.error);
        }
        this.loadingFolders = false;
      }, err => this.alertService.error('Houve um erro ao carregar os arquivos. Verifique a conexão e tente novamente'));
  }

  open(url: string) {
    window.open(url, '_blank');
  }

  selectFolder(files: FileRepository[], folderName: string) {
    this.folderSelected = folderName;
    this.selectedFiles = files;
    this.filteredFiles = files;
    this.fileDataSource = new MatTableDataSource([...files]);
  }

  selectFolderByFile(files: FileRepository[], filesNew: any){
    let name: any = Object.values(this.firstFolder);
    this.folderSelected = name[0];
    this.fileDataSource = new MatTableDataSource([...files]);
  }

  applyFilter() {
    if (this.filterFiles.length) {
      this.filteredFiles = [...this.selectedFiles].filter(file =>
      file.name.toLocaleLowerCase().indexOf(this.filterFiles.toLowerCase()) > -1);
      this.fileDataSource = new MatTableDataSource([...this.filteredFiles]);
    } else {
      this.filteredFiles = [...this.selectedFiles];
      this.fileDataSource = new MatTableDataSource([...this.selectedFiles]);
    }
  }

  getDisciplineFileTags(fileRepo: number[]){
    this.repositoryService.getDisciplineFileTags(fileRepo)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.disciplineTag = response.data as disciplineTag[];
      }
    }, err => {
      //this.alertService.error("Não foi possivel buscar os dados verifique a conexâo e tente Novamente!");
    })
  }

  getFilesFromDisciplineTags($tagId: number){
    this.repositoryService.getFilesFromDisciplineTags($tagId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        let files = response.data as FileRepository[];
        this.folderSelected = files[0].folder;
        this.fileDataSource = new MatTableDataSource([...files]);
      }
    },err => {
      //this.alertService.error("Não foi possível buscar os dados verifique a conexão e tente novamente!");
    })
  }

  toggleOrderDirection(dataType: string) {
    if(dataType == 'name') {
      this.dateDirection = 'noOrder';
      this.nameDirection = this.orderMapping[this.nameDirection];

      this.filteredFiles.sort((a, b) => this.compareValues(a.name, b.name, this.nameDirection));
    } else if (dataType == 'date') {
      this.nameDirection = 'noOrder'
      this.dateDirection = this.orderMapping[this.dateDirection];

      this.filteredFiles.sort((a, b) => this.compareValues(a.created_at, b.created_at, this.dateDirection));
    }
  }

  compareValues(a, b, direction) {
    if (typeof a === 'string' && typeof b === 'string') {
      const valueA = a.toLowerCase();
      const valueB = b.toLowerCase();

      if (valueA < valueB) {
        return direction === 'desc' ? 1 : -1;
      } else if (valueA > valueB) {
        return direction === 'desc' ? -1 : 1;
      } else {
        return 0;
      }
    } else if (a instanceof Date && b instanceof Date) {
      if (a < b) {
        return direction === 'desc' ? 1 : -1;
      } else if (a > b) {
        return direction === 'desc' ? -1 : 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
}













/* import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Folder } from 'src/app/modules/admin/repository/folder/folder';
import { StudyPlan } from 'src/app/modules/admin/study-plan/study-plan';
import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

import { RepositoryService } from '../../repository.service';
import { FileRepository } from '../file-repository';

interface PlansId {
  id: number;
}

@Component({
  selector: 'app-repository-file-list',
  templateUrl: './repository-file-list.component.html',
  styleUrls: ['./repository-file-list.component.scss']
})

export class RepositoryFileListComponent implements OnInit {

  public folders: Folder[];
  public files: {files: FileRepository[] }[];
  public foldersPlans: { folder: string, files: FileRepository[] }[];
  public foldersClass: { folder: string, files: FileRepository[] }[];
  public selectedFiles: FileRepository[];
  public loadingFolders: boolean;
  public loadingFoldersPlans: boolean;
  public loadingFoldersClass: boolean;
  public loadingFiles: boolean;
  public fileDataSource: MatTableDataSource<FileRepository>;
  public displayedColumns: string[];
  public folderSelected: string | number;
  public firstFolder: Folder;
  public plans: StudyPlan[];
  public filesRepository: FileRepository[];
  public loadingInit: boolean = false;
  public allFolders: any;

  constructor(
    private repositoryService: RepositoryService,
    private alertService: AlertService,
    private userService: UserService,
    private studyPlanService: StudyPlanService,
    public deviceService: DeviceDetectorService,
    public layoutService: LayoutService) { }

  ngOnInit(): void {
    let classId = this.userService.getUserClass();
    let userId = this.userService.getUserId();

    this.getFolderByStudentClass(classId);
    this.getStudentPlans(userId);

    this.displayedColumns = ['name', 'created_at' ,'link'];
  }



  getStudentPlans(studentId: number) {
    this.repositoryService.getStudentPlans(studentId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.plans = response.data as StudyPlan[];
        let plansIds = [];
          this.plans.forEach(element => {
            plansIds.push(element.id);
          });
          this.getFolderByStudentPlans(plansIds);
      } else {
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Houve um erro ao carregar os planos de estudo. Verifique a conexão e tente novamente')
    });
  }

  getFolderByStudentClass(classId: number) {
    this.loadingFoldersClass = true;
    this.repositoryService.getFolderByStudentClass(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        //console.log(response);
        if (!response.error) {
          this.filesRepository = response.data as FileRepository[];
          //this.firstFolder = this.Folder[0];
          //this.files = response.data as { folder: string, files: FileRepository[] }[];
          this.foldersClass = response.data as { folder: string, files: FileRepository[] }[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingFoldersClass = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os arquivos da turma. Verifique a conexão e tente novamente');

      });
  }


  getFolderByStudentPlans(plansIds: PlansId[]) {
    const studentId = Number(this.userService.getUserId());
    this.loadingFoldersPlans = true;
    this.repositoryService.getFolderByStudentPlans(studentId, plansIds)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.filesRepository = response.data as FileRepository[];
          this.foldersPlans = response.data as { folder: string, files: FileRepository[] }[];
        } else {
          this.alertService.error(response.error);
        }

        this.loadingFoldersPlans = false;

      }, err => this.alertService.error('Houve um erro ao carregar os arquivos do plano. Verifique a conexão e tente novamente'));
  }

  open(url: string) {
    window.open(url, '_blank');
  }

  PASTA SELECIONADA
  selectedFolder(folderId: number, folderName: string, isClassOrPlan: number, classIdOrPlanId: number){
    this.folderSelected = folderName;
    this.getFilesFolder(folderId, isClassOrPlan, classIdOrPlanId);
  }

  OBTÉM O ARQUIVO DA PASTA SELECIONADA - INDEPENDENTEMENTE SE FOR TURMA OU PLANO
  getFilesFolder(folder: number, isClassOrPlan: number, classIdOrPlanId:number) {
    let file = [];
    const schoolId = (this.userService.getUserSchool());
    this.loadingFiles = true;
    this.repositoryService.getFilesFolder(folder, isClassOrPlan, classIdOrPlanId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.filesRepository = response.data as FileRepository[];
          file = response.data as {files: FileRepository[] }[];
          this.fileDataSource = new MatTableDataSource([...this.filesRepository]);
          if(file != []){
            this.files = response.data as {files: FileRepository[] }[];
          }else{
            this.files = [];
          }
        } else {
          this.alertService.error(response.error);
        }
        this.loadingFiles = false;
      }, err => this.alertService.error('Houve um erro ao carregar os arquivos. Verifique a conexão e tente novamente'));
  }
}
 */
