import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { UserService } from '../user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { User } from '../user';
import { ResponseApi } from 'src/app/core/models/response-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaskService } from 'src/app/shared/services/mask.service';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { Notifications } from '../../admin/notifications/notifications';
import { NotificationsService } from '../../admin/notifications/notifications.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    public user: User;
    public profileForm: FormGroup;
    public cardPhotoOpen: boolean;
    public photoPreview: string;
    public imgSrc;
    public fileData: File;
    public saving: boolean;
    private API = environment.ApiUrl;
    public fatherList: User[];
    public fatherName: string;
    public selectedFathers: User[];
    /* public read: boolean;
    public unRead: boolean;
    public loading: boolean;
    public notify: Notifications[];

    public totalPage: number;
    public pageNo: number; 

    @ViewChildren('player_container') playerContainer;*/

    constructor(
        public userService: UserService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        public maskService: MaskService,
        public layoutService: LayoutService,
        public notificationsService: NotificationsService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public isBottomSheet?: boolean) { }

    ngOnInit(): void {
        this.profileForm = this.formBuilder.group({
            id: [this.userService.getUserId()],
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            cpf: ['', Validators.required],
            pass: [''],
            image: ['']
        });

        this.getUserDataToEdit();
        //this.getVinculatedFathers(this.userService.getUserId());
    }

    getUserDataToEdit() {
        this.userService.getUserDataToEdit(this.userService.getUserId())
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.user = response.data as User;
                    this.updatePhoto();
                    this.profileForm.patchValue(response.data as User);
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao atualizar os dados do perfil. Verifique a conexão e tente novamente'));
    }

    updatePhoto() {
        if (this.user.photo) {
            this.userService.setUserImage(this.user.photo);
            this.photoPreview = this.user.photo;
        } else if(this.user.image){
            this.userService.setUserImage(this.user.photo);
            this.photoPreview = this.user.photo;
        } else{
            this.photoPreview = this.API +'/store/students/avatar.png';
        }
    }

    handleFileInput(files: FileList): void {
        if (files && files[0]) {

            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.imgSrc = reader.result;
                this.photoPreview = this.imgSrc;
            }
        }
    }

    updateUserData() {
        this.saving = true;

        this.profileForm.get('image').setValue(this.imgSrc);
        const user = this.profileForm.getRawValue() as User;

        this.userService.updateUserData(user)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Dados Atualizados', 3);
                    this.getUserDataToEdit();
                } else {
                    this.alertService.error(response.error);
                }

                this.saving = false;
            }, err => {
                this.alertService.error('Houve um erro ao atualizar os dados do perfil. Verifique a conexão e tente novamente');
                this.saving = false;
            });
    }

    removePhotoProfile(){
        let imgUser: string;
        if(this.photoPreview)        {imgUser = this.photoPreview}
        else if(this.user.photo)     {imgUser = this.user.photo}
        else if(this.imgSrc)         {imgUser = this.imgSrc}

        if (confirm('Deseja realmente remover a imagem?')) {
            this.userService.removePhotoProfile(this.userService.getUserId(), imgUser)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.alertService.success('Imagem deletada!');
                        this.photoPreview = this.API +'/store/students/avatar.png';
                        //console.log('aqui');
                        //console.log(response);
                        //this.getUserDataToEdit();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => {
                    this.alertService.error('Houve um erro ao excluir a imagem. Falha na comunicação com a API');
            });
        }
    }

    searchFatherByName(name: string){
        this.userService.searchFatherByName(name , this.userService.getUserId())
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.fatherList = response.data as User[];
            }
            else{
                this.alertService.error(response.error);
            }
        }, err => {
            this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!')
        })
    }

    vinculateFather(fatherId : number){
        if(confirm('Deseja associar este Responsavel por você?')){
        this.userService.vinculateFather(fatherId , this.userService.getUserId())
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.alertService.success(response.data.toString());
                if(this.fatherName){
                    this.searchFatherByName(this.fatherName);
                }
                else{
                    this.fatherList = [];
                    this.getVinculatedFathers(this.userService.getUserId());
                }
            }
            else{
                this.alertService.error(response.error);
            }
        }, err => {
            this.alertService.error('Não foi possivel cadastrar os dados verifique a conexão e tente novamente');
        })
    }
    }

    desvinculateFather(fatherId : number){
        if(confirm('Deseja Dessacociar este Responsavel por você?')){
            this.userService.desvinculateFather(fatherId , this.userService.getUserId())
            .subscribe(res => {
                const response = res.body as ResponseApi;
    
                if(!response.error){
                    this.alertService.success(response.data.toString());
                    if(this.fatherName){
                        this.searchFatherByName(this.fatherName);
                    }
                    else{
                        this.fatherList = [];
                        this.getVinculatedFathers(this.userService.getUserId());
                    }
                }
                else{
                    this.alertService.error(response.error);
                }
            }, err => {
                this.alertService.error('Não foi possivel cadastrar os dados verifique a conexão e tente novamente');
            })
        }
    }

    getVinculatedFathers(userId : number){
        this.userService.getVinculatedFathers(userId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.fatherList = response.data as User[];
            }
            else{
                console.log(response.error);
            }
        })
    }
}
