<div>
    <form [formGroup]="formTasks">
        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="taskName">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Disciplina/Assunto" formControlName="subjects">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{'col-md-6': userService.isAdmin()}" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-label>Escola</mat-label>
                    <mat-select placeholder="Selecione uma escola" (selectionChange)="getUnitSelection($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" [ngClass]="{'col-md-6': userService.isAdmin(), 'col-md-12': userService.isGeneralCoordinator()}">
                <mat-form-field class="width-100">
                    <mat-label>Unidade</mat-label>
                    <mat-select placeholder="Selecione uma unidade" (selectionChange)="getClassesSelection($event.value)">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" formControlName="classId">
                        <mat-option>Selecione uma turma</mat-option>
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Data de entrega" formControlName="finalDate"
                    [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="width-100">
                    <mat-label>Observação</mat-label>
                    <textarea rows="15" matInput formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="width-100">
              <mat-select placeholder="Planos" formControlName="plans">
                  <mat-option>Selecione um plano</mat-option>
                  <mat-option (click)="addPlanChip(plan)" *ngFor="let plan of plans" [value]="plan.id">
                      <span [title]="plan.name">{{plan.name}}</span>
                  </mat-option>
              </mat-select>
          </mat-form-field>
          </div>
          <div class="col-md-6 right">
            <div *ngIf="selectedPlans?.length > 0">
                <mat-label class="mb-2">Escolas selecionadas</mat-label>
                <mat-chip-list *ngIf="selectedPlans">
                    <mat-chip *ngFor="let plan of selectedPlans" selectable="true" removable="true" (removed)="removeSchoolChip(plan)">
                        {{plan.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
      </div>

        <div class="row" *ngIf="!file">
            <label for="upload" class="d-block width-100 c-pointer" (click)="fileDropRef.click()" (change)="handleFileInput($event.target.files);">
                <div class="img-file center mt-4 " >
                    <span class="send-file-icon">
                        <mat-icon color="accent">upload</mat-icon>
                    </span>
                    <div class="mb-1 mt-0 width-100 dropzone" appDnd (fileDropped)="handleFileInput($event);">
                        <h6>Adicione um arquivo aqui</h6>
                        <input type="file" #fileDropRef id="fileDropRef" title="Escolha o arquivo">
                        <span>Arraste seu arquivo ou clique para selecionar</span>
                    </div>
                </div>
            </label>
        </div>

        <div class="row" *ngIf="file">
            <div class="col-md-10 col-sm-10 col-lg-10 col-xl-10 col-10 width-150">
                <mat-form-field class="width-100">
                    <input matInput disabled [value]="fileName">
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-2 width-90 mt-3" >
                <button color="warn" mat-icon-button (click)="deleteFile(file)" class="hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Excluir">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

    </form>
    <div  class="row text-right">
        <button (click)="submit()" class="right" nbButton size="small" status="primary">Enviar</button>
    </div>
</div>
