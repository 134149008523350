import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StudyPlan } from '../admin/study-plan/study-plan';

import { ForumComment } from './comment/forum-comment';

@Injectable({ providedIn: 'root' })
export class ForumService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  addComment(comment: ForumComment) {
    return this.http.post(this.API + '/forum/addComment', comment, { observe: 'response' });
  }

  getBlockedComments() {
    return this.http.post(this.API + '/forum/getBlockedComments',{}, { observe: 'response' });
  }

  getBlockedCommentsBySchool(schoolId: number) {
    return this.http.post(this.API + '/forum/getBlockedCommentsBySchool',{schoolId}, { observe: 'response' });
  }

  getBlockedCommentsByUnit(unitId: number) {
    return this.http.post(this.API + '/forum/getBlockedComments',{unitId}, { observe: 'response' });
  }

  getCommentsVideo(videoId: number) {
    return this.http.post(this.API + '/forum/getCommentsVideo', { videoId }, { observe: 'response' });
  }

  aproveComment(commentId: number) {
    return this.http.post(this.API + '/forum/aproveComment', { commentId }, { observe: 'response' });
  }

  like(commentId: number, userId: number) {
    return this.http.post(this.API + '/forum/like', { commentId, userId }, { observe: 'response' });
  }

  dislike(commentId: number, userId: number) {
    return this.http.post(this.API + '/forum/dislike', { commentId, userId }, { observe: 'response' });
  }

  delete(commentId: number) {
    return this.http.post(this.API + '/forum/removeComment', { commentId }, { observe: 'response' });
  }

  getSchoolTopics(schoolId: number) {
    return this.http.post(this.API + '/forum/getSchoolTopics', { schoolId }, { observe: 'response' });
  }

  disableComment(commentId: number) {
    return this.http.post(this.API + '/forum/disableComment', { commentId }, { observe: 'response' });
  }

  enableComment(commentId: number) {
    return this.http.post(this.API + '/forum/enableComment', { commentId }, { observe: 'response' });
  }

  getTotalBlockedComments() {
    return this.http.post(this.API + '/forum/getTotalBlockedComments', {}, { observe: 'response' });
  }

  getTotalBlockedCommentsBySchool(schoolId: number) {
    return this.http.post(this.API + '/forum/getTotalBlockedCommentsBySchool', {schoolId}, { observe: 'response' });
  }

  getTotalBlockedCommentsByUnit(unitId: number) {
    return this.http.post(this.API + '/forum/getTotalBlockedComments', {unitId}, { observe: 'response' });
  }

  getDisciplineTopics(disciplineId: number) {
    return this.http.post(this.API + '/forum/getDisciplineTopics', { disciplineId }, { observe: 'response' });
  }

  getLastComments(page: number, planId?: number, schoolId?: number) {
    return this.http.post(this.API + '/forum/getLastComments', { page, planId, schoolId }, { observe: 'response' });
  }
  getLastCommentsByCoordinator(page: number, plansId?: any[], schoolId?: number) {
    return this.http.post(this.API + '/forum/getLastCommentsByCoordinator', { page, plansId, schoolId }, { observe: 'response' });
  }
  getLastCommentsBySchool(page: number, schoolId: number) {
    return this.http.post(this.API + '/forum/getLastCommentsBySchool', { page, schoolId }, { observe: 'response' });
  }
}
