import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Class } from 'tinymce';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { IntegrationsService } from '../integrations.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {

  public loading: boolean = false;
  public editMode: boolean = false;
  public form: FormGroup;
  public classesList: any; 
  public classesSelected: number;
  public schools: School[];
  public units: Unit[];
  public classes: Class[]; 
  public classId: number;
  public classIdList: number; 
  public openList: boolean = false;
  public allClassesSelected: any[];

  constructor(
    public userService: UserService, 
    private integrationService: IntegrationsService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {    
    this.form = this.formBuilder.group({
      id: [''],
      selectedClass: ['', Validators.required]
    });
    this.profileInit();
    this.classes= [];
    this.classesList = [];
    this.allClassesSelected = [];
  }

  profileInit() { 
    if (this.userService.isAdmin()) this.getSchools(); 
    if (this.userService.isGeneralCoordinator()) this.getUnits(Number(this.userService.getUserSchool())); 
  }
  
  selectedClasses(event:any){
    let classId = event.target.defaultValue;
    if(event.target.checked){
      this.allClassesSelected.push(classId);
    }
    if(!event.target.checked){
      this.allClassesSelected.filter(classes =>{return classes.id != classId; })
    }
  }

  create(){
    if(!this.form.valid) return 'Selecione as turmas para cadastrar.';
    this.integrationService.createPortal(this.allClassesSelected)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error){
        this.alertService.success("Criado com sucesso!");
        this.form.reset();
        this.classes = [];
      } else this.alertService.error(response.error);
      this.loading = false;
    }, err => {
      this.alertService.error('Houve um erro ao cadastrar a(s) turma(s) no portal integração. Verifique a conexão e tente novamente');
      this.loading = false;
    });  
  }

  delete(id:number){
    if(confirm("Deseja realmente excluir?")){ 
      this.integrationService.deletePortal(id)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.classesList =  this.classesList.filter(classes =>{return classes.id !=id; })
          this.alertService.success("Turma removida com sucesso!"); 
        } else this.alertService.error(response.error);
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao deletar a turma do portal integração. Verifique a conexão e tente novamente');
        this.loading = false;
      });
    }
  } 

  getSchools() {
    this.schoolService.getSchoolsSelection()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error)this.schools = response.data as School[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnitsSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.units = response.data as Unit[];
        else  this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
  }

  getUncreatedClasses(unitId?: number) {
    this.integrationService.getUncreatedClassesPortal(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classes = response.data as Class[];
        else {
          this.alertService.error(response.error);
          this.classes = [];
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getClassesList(unitId: number) {
    this.loading = true;
    this.integrationService.openListPortal(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classesList = response.data as Class[];
        else {
          this.alertService.error(response.error);
          this.classesList = [];
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as turmas cadastradas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

}
