import { ResponseApi } from "src/app/core/models/response-api"; 

export   class ListChannel{

    constructor(
        private channelService:any,
        private alertService:any,
        private schoolId:any){}

    public async execute(){


        const validation = this.validate();

        if(!validation.status) return false;

        this.channelService.getChannels().subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) {
                this.channelService.emitChannels.emit({name:"channels", values:response.data})  
                 
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os canais. Verifique a conexão e tente novamente'));

        return  true; 

    }

    public validate(){
        return {status:true, data:[], message:""};
    }
}