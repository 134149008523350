<div class="container">
    <h6>Adicionar Turma</h6>
    <div class="row mb-3">
        <div class="col-4" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Escola" (selectionChange)="getUnitsSelection($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4" *ngIf="userService.isAdmin() || this.userService.isGeneralCoordinator()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                        <span [title]="unit.name">{{unit.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="width-100">
                <mat-select placeholder="Turma">
                    <mat-option >Selecione uma turma</mat-option>
                    <mat-option *ngFor="let class of classes" [value]="class.id" (click)="addClassChip(class)">
                        <span [title]="class.name">{{class.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-2 text-right">
        <div class="col width-100">
            <button (click)="add()" class="right" nbButton size="small" status="primary">Cadastrar</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-chip-list *ngIf="selectedClasses">
                <mat-chip *ngFor="let classChip of selectedClasses" selectable="true" removable="true" (removed)="removeClassChip(classChip)">
                    {{classChip.name}} <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
</div>
