<div class="mb-2" *ngIf="!openList">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select  (selectionChange)="getUnits($event.value)" placeholder="Selecione a Escola">
                        <mat-option>Selecione</mat-option>
                        <mat-option *ngFor="let school of schools" [value]="school.id">{{school.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getUncreatedClasses($event.value)" placeholder="Selecione a Unidade"> 
                        <mat-option>Selecione</mat-option>
                        <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="classes.length">
            <ng-container>
                <nb-list class="width-100 col-12 mt-3">
                    <nb-list-item class="bg-color-accent list-header tableborder">
                        <div class="row">
                            <div class="col-md-10">Nome</div>
                            <div class="col-md-2 text-right">Ações</div>
                        </div>
                    </nb-list-item>
                    <nb-list-item class="itenstable" *ngFor="let class of classes">
                        <div class="row">
                            <div class="col-md-10">{{class.name}}</div>
                            <div class="col-md-2 text-right">
                                <input type="checkbox" formControlName="selectedClass" (click)="selectedClasses($event)" [value]="class.id" /> 
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
            </ng-container>
        </div>
        <p class="w-100 text-center m-3" *ngIf="!classes.length">Nenhum resultado!</p>
        <div class="width-100" *ngIf="loading"><mat-spinner class="center mt-5"></mat-spinner></div>
        <!-- buttons -->
        <div class="row mt-5 mb-3">
            <div class=" col-6 width-100 ">
                <button (click)="openList=true" nbButton size="small" outline status="primary"  class="text-color-primary border-primary-thin">Ver Cadastrados</button>
            </div>
            <div class=" col-6 width-100 right text-right"  >
                <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" nbSpinnerStatus="warning">Cadastrar</button>
            </div>
        </div>
    </form>
</div>
<div class="row mt-2" *ngIf="openList" >
    <div class="col-md-6" *ngIf="userService.isAdmin()">
        <mat-form-field class="width-100">
            <mat-select (selectionChange)="getUnits($event.value)" placeholder="Selecione a escola">
                <mat-option >Selecione</mat-option>
                <mat-option *ngFor="let school of schools" [value]="school.id"> {{school.name}} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
        <mat-form-field class="width-100">
            <mat-select (selectionChange)="getClassesList($event.value)" placeholder="Selecione a unidade">
                <mat-option *ngFor="let unit of units" [value]="unit.id">
                    {{unit.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <ng-container *ngIf="classesList.length">
        <nb-list class="width-100 col-12 mt-3">
            <nb-list-item class="bg-color-accent list-header tableborder">
                <div class="row">
                    <div class="col-md-10">Nome</div>
                    <div class="col-md-2 text-right">Ações</div>
                </div>
            </nb-list-item>
            <nb-list-item class="itenstable" *ngFor="let class of classesList">
                <div class="row">
                    <div class="col-md-10">{{class.name}}</div>
                    <div class="col-md-2 text-right">
                        <button size="tiny" (click)="delete(class.id)" status="danger" nbButton ghost>
                            <nb-icon icon="trash"></nb-icon>
                        </button>
                    </div>
                </div>
            </nb-list-item>
        </nb-list> 
    </ng-container>
    <p class="w-100 text-center m-3" *ngIf="!classesList.length">Nenhum resultado!</p>
    <div class="col-12 width-100 text-right mt-2">
        <button (click)="openList=false" nbButton size="small" class="smallfont" status="primary">Voltar</button>
    </div>
</div>