import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbCardModule, NbIconModule, NbListModule } from '@nebular/theme';
import { SharedModule } from 'src/app/shared/shared.module';

import { RepositoryFileListComponent } from './file/list/repository-file-list.component';
import { RepositoryVideoListComponent } from './video/list/repository-video-list.component';
import { RepositoryVideoWatchComponent } from './video/watch/repository-video-watch.component';
import { MaterialListComponent } from './material/list/material-list.component';
import { FormsModule } from '@angular/forms';
import { MaterialAddComponent } from '../admin/repository/material/material-add.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { LottieModule } from 'ngx-lottie';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { OrderModule } from 'ngx-order-pipe';

export function playerFactory() {
    return import('lottie-web');
}
@NgModule({
    declarations: [
        RepositoryVideoListComponent,
        RepositoryFileListComponent,
        RepositoryVideoWatchComponent,
        MaterialListComponent,
    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatButtonModule,
        MatTabsModule,
        MatTableModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        MatFormFieldModule,
        MatRippleModule,
        MatListModule,
        MatDialogModule,
        NbCardModule,
        NbListModule,
        NbButtonModule,
        NbIconModule,
        RouterModule,
        SharedModule,
        FormsModule,
        LottieModule.forRoot({ player: playerFactory }),
        OrderModule
    ],
    entryComponents: [MaterialAddComponent],
    exports: [],
    providers: [CurrencyPipe],
})
export class RepositoryModule { }
