<div *ngIf="!showList">
    <form [formGroup]="experimentForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar - Experimente</h4>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" formControlName="planId">
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <input matInput placeholder="Dias" [textMask]="{mask: maskService.number}"
                        formControlName="expireDays">
                </mat-form-field>
                <button class="hvr-grow" (click)="addExpireDays()" mat-icon-button color="accent">
                    <nb-icon icon="plus-circle-outline"></nb-icon>
                </button>
            </div>
        </div>


    </form>

    <div class="row" *ngIf="editMode">
        <div class="col">
            <mat-form-field class="width-100">
                <input matInput placeholder="Link" [ngModel]="experimentLink" readonly>
                <button mat-icon-button matSuffix [cdkCopyToClipboard]="experimentLink" matTooltipClass="custom-tooltip"
                    matTooltip="Copiar para a área de transferência" (click)="copyClipboard()">
                    <mat-icon>{{copyIcon}}</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver planos
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading"
                [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading"
            [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>

<div *ngIf="showList" class="">
    <nb-list class="width-90 mt-3 center">
        <nb-list-item *ngFor="let experiment of experiments">
            <div class="row">
                <div class="col-5">
                    {{experiment.name}}
                </div>
                <div class="col-4">
                    {{experiment.planName}}
                </div>
                <!-- <div class="col-2">
                    criado em {{discipline.createDate | date:'dd/MM/yyyy'}}
                </div> -->
                <div class="col-2 text-right">
                    <button (click)="edit(experiment)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-1 text-right">
                    <button size="tiny" (click)="remove(experiment.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>