import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { AdminService } from '../../admin.service';
import { PlanSelect } from '../../rdo-manager/create-theme/theme';
import { ClassSelect } from '../../school/class';
import { SchoolSelect } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { UnitSelect } from '../../school/unit';
import { Certificate } from '../certificate';
import { CertificateService } from '../certificate.service';
import * as imageConversion from 'image-conversion';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mock } from 'src/app/modules/mock/mock';
import { MockService } from 'src/app/modules/mock/mock.service';
import { Exercise } from '../../exercise/exercise';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { VideoService } from 'src/app/modules/video/video.service';
import { Video } from 'src/app/modules/video/video';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public certificates: Certificate[];
  public displayedColumns: string[];
  public schools: SchoolSelect[];
  public units: UnitSelect[];
  public classes: ClassSelect[];
  public plans: PlanSelect[];
  public showform: boolean;
  public showList: boolean;
  public editMode: boolean;
  public choiceOption: boolean;
  public formMock: boolean;
  public formWatched: boolean;
  public formAll: boolean;
  public formEdit: FormGroup;
  private admUpdate$: Subscription;
  public logo: any;
  public signature: any;
  public loading: boolean;
  public fileTempSignature;
  public fileTemp;
  public imgSrc;
  public mocks: Mock[];
  public planName: string;
  public planId: number;
  public totalMockQuestions: number;
  public totalVideosPlan: number;

  public inputs = [1];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public  data: Certificate,
    public dialogRef: MatDialogRef<EditComponent>,
    private certificateService: CertificateService,
    public layoutService: LayoutService,
    public userService: UserService,
    private alertService: AlertService,
    private schoolService: SchoolService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private router: Router,
    private mockService: MockService,
    private disciplineService: DisciplineService,
    private videoService: VideoService

  ) { }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
      
    if(this.data.type == 1 || this.data.type ==3){
      this.getMockMaxQuestions(this.data.mockId);
    } else if(this.data.type == 2 || this.data.type == 3){
      this.getDisciplinesforVideos(this.data.planId);
    }
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init(){
    this.formEdit = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      schoolId: [''],
      unitId: [''],
      classId: [''],
      planId: ['', Validators.required],
      planName: [''],
      mockId:[''],
      logo: ['', Validators.required],
      signature: ['', Validators.required],
      dados0: [''],
      dados1: [''],
      dados2: [''],
      type: [''],
      userIdCreated: [''],
      attended_classes: [''],
      minimumMock: [''],
      minimumVideo: [''],
    });
    this.getSchoolSelection();
    if (this.data) { this.initializeEditMode(); }
  }

  initializeEditMode(){
    this.editMode = true;
    this.formEdit.patchValue(this.data);
    this.getUnitsSelection(this.data.schoolId);    
    this.getClassesSelection(this.data.unitId);
    this.getPlansSelection(this.data.schoolId);
    this.getMockByPlan(this.data.planId);

    if(this.data.type == 1) this.formMock = true;
    if(this.data.type == 2) this.formWatched = true;
    if(this.data.type == 3) this.formAll = true;

    this.fileTemp = this.data.logo;
    this.fileTempSignature = this.data.signature;
    this.logo = this.data.logo;
    this.signature = this.data.signature;
    this.formEdit.get('name').setValue(this.data.name);
    this.formEdit.get('signature').setValue(this.fileTempSignature);
    this.formEdit.get('logo').setValue(this.fileTemp);
  }

  getMockMaxQuestions(mockId : number){
    let exercises: Exercise[];
    this.mockService.getMockExercises(mockId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        exercises = response.data as Exercise[];
        this.totalMockQuestions = exercises.length;
      }
      else{
        this.totalMockQuestions = 0;
      }
    })
  }

  /* setMaxValue(){
    let value = this.formEdit.get('minimumCorrect').value;

    if(value > this.totalMockQuestions){
      this.formEdit.get('minimumCorrect').setValue(this.totalMockQuestions);
    }
  } */

  getSchoolSelection(schoolId?:number){
    this.schoolService.getSchoolSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.schools = response.data as SchoolSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente!'));
  }

  getUnitsSelection(unit?:number){
    this.schoolService.getUnitsSelection(unit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.units = response.data as UnitSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente!'));
  }

  getPlansSelection(schoolId?:number){
    this.schoolService.getPlansSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.plans = response.data as PlanSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'));
  }

  getClassesSelection(unitId?:number){
    this.schoolService.getClassesSelection(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classes = response.data as ClassSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!'));
  }

  getMockByPlan(planId: number){
    this.planId = planId;
    this.mockService.getMockByPlan(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.mocks = response.data as Mock[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar os simulados do plano. Verifique a conexão e tente novamente!'));
  }

  saveChanges(){
    this.loading = true;
    this.planId = this.formEdit.get('planId').value;
    this.plans.forEach(element => {
      if(element.id == this.planId){
        this.planName = element.name;
      }
    });
    this.formEdit.get('planName').setValue(this.planName);
    this.formEdit.get('signature').setValue(this.fileTempSignature);
    this.formEdit.get('logo').setValue(this.fileTemp);

    if (!this.formEdit.valid) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }
    this.formEdit.get('userIdCreated').setValue(Number(this.userService.getUserId()));
    let certificate = this.formEdit.getRawValue() as Certificate;
    console.log(certificate);
    this.certificateService.saveChanges(certificate)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.alertService.success('Certificado atualizado', 3);
          this.dialogRef.close(true);
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao editar o certificado. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  moreLine() {
    if(this.inputs.length < 3){
        this.inputs.push(1);
    }
  }

  cancelOrClear() {
    this.formMock = false;
    this.formWatched = false;
    this.formAll = false;
    this.showList = false;
    this.showform = false;
    this.choiceOption = true;
    this.formEdit.reset();
    this.logo = null;
    this.signature = null;
  }

  cancelSignature() {
    this.signature = null;
  }

  cancelLogo() {
    this.logo = null;
  }

  backButton() {
    this.router.navigate(['admin/certificates']);
  }

  handleFileInputSignature(files: FileList): void {
    if (files && files[0]) {
      const fileSizeInMb = files[0].size / 1024 / 1024;
      if (fileSizeInMb > 2) {
        imageConversion.compressAccurately(files[0],1500).then(res=>{
          this.signature = res
          const reader = new FileReader();
          reader.readAsDataURL(this.signature);
          reader.onload = e => {
            this.fileTempSignature = reader.result;
          }
        });
      } else {
        this.signature = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.signature);
        reader.onload = e => {
          this.fileTempSignature = reader.result;
        };
      }
    }
  }

  handleFileInputLogo(files: FileList): void {
    if (files && files[0]) {
      const fileSizeInMb = files[0].size / 1024 / 1024;
      if (fileSizeInMb > 2) {
        imageConversion.compressAccurately(files[0],1500).then(res=>{
          this.logo = res
          const reader = new FileReader();
          reader.readAsDataURL(this.logo);
          reader.onload = e => {
            this.fileTemp = reader.result;
            //this.showButton = true;
          }
        });
      } else {
        this.logo = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.logo);
        reader.onload = e => {
          this.fileTemp = reader.result;
          //this.showButton = true;
        };
      }
    }
  }

  getDisciplinesforVideos(planId : number){
    let disciplines : Discipline[];
    this.disciplineService.getDisciplinesSelectedByPlan(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        disciplines = response.data as Discipline[];
        this.getTotalVideos(disciplines);
      }
    })
  }

  getTotalVideos(disciplines: Discipline[]) {
    let videos : Video[];
    this.videoService.getDisciplinesAllVideos(disciplines).subscribe((res) => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        videos = response.data as Video[];
        this.totalVideosPlan = videos.length;
      } else this.totalVideosPlan = 0;
    });
}

  closeDialog(){
    this.dialogRef.close();
  }
}
