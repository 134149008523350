import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbInputModule, NbIconModule, NbUserModule, NbSpinnerModule, NbListModule } from '@nebular/theme';
import { TextMaskModule } from 'angular2-text-mask';
import { ProfileComponent } from './profile/profile.component';
import { UserService } from './user.service';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
    declarations: [
        ProfileComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        TextMaskModule,
        RouterModule,
        MatIconModule,
        MatTabsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        CommonModule,
        FormsModule,
        NbListModule,
        NbButtonModule
    ],
    providers: [UserService, { provide: MAT_BOTTOM_SHEET_DATA, useValue: false }],
})
export class UserModule { }
