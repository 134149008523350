
export interface AlternativeInterface{
    id:number,
    exerciseId:number,
    option:string,
    text:string,
    value:string
}
export interface ExercisesVideoInterface{
    id:number,
    videoId:number,
    name:string ,
    correct:string ,
    comment:string ,
    tag:string ,
    text:string ,
    sum:boolean ,
    active:boolean  
    alternatives:AlternativeInterface[];
}

export class ExercisesVideoModel {
    constructor(
        private exercise:ExercisesVideoInterface 
    ){}

    public getObejct(){
        return this.exercise;
    }
}