<div class="pt-5 pb-5 ">
  <h3 class="mb-5 ml-5">Relatório geral - Simulados</h3>

  <nb-card>
    <nb-card-body>
      <div class="row">
        <div class="col-md" *ngIf="userService.isAdmin()">
          <mat-form-field appearance="outline" class="width-100">
            <mat-select placeholder="Escola" (selectionChange)="getSchoolPlans($event.value)">
              <mat-option *ngFor="let school of schools" [value]="school.id">
                {{school.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field appearance="outline" class="width-100">
            <mat-select placeholder="Plano" (selectionChange)="getPlanMocks($event.value)">
              <mat-option *ngFor="let plan of studyPlans" [value]="plan.id">
                {{plan.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field appearance="outline" class="width-100">
            <mat-select placeholder="Simulado" (selectionChange)="getMockGeneralReport($event.value)">
              <mat-option *ngFor="let mock of mocks" [value]="mock.id">
                {{mock.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2 mt-2">
          <mat-spinner *ngIf="loading" class="center" [diameter]="35"></mat-spinner>
          <button *ngIf="!loading && mockReport?.url" (click)="openFile()" mat-button class="width-100" color="accent"
            mat-raised-button>Baixar
            relatório</button>
        </div>
      </div>

    </nb-card-body>
  </nb-card>
</div>