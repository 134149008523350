<div *ngIf="!showDisciplinesList">
    <form [formGroup]="createDisciplineForm">
        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Disciplina</h4>
        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome (obrigatório)" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tag (obrigatório)" formControlName="tag">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100 text-left">
                    <mat-select #iconSelected formControlName="icon" placeholder="Ícone">
                        <mat-select-trigger>
                            <mat-icon color="accent" class="icon">{{iconSelected?.value}}</mat-icon>
                        </mat-select-trigger>
                        <mat-option *ngFor="let icon of icons" [value]="icon.name">
                            <mat-icon color="accent" class="icon">{{icon.name}}</mat-icon>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="userService.isAdmin()">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-label>Escola(s) (obrigatório)
                        <!-- <span class="required" matTooltipClass="custom-tooltip" matTooltip="obrigatório">*</span>
                        <span  matTooltipClass="custom-tooltip" matTooltip="Selecione a(s) escola(s)">Escola(s)</span> -->
                    </mat-label>
                    <mat-select formControlName="schools">
                        <mat-option (click)="addSchoolChip(school)" *ngFor="let school of schools" [title]="school.name" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 right" *ngIf="selectedSchools?.length > 0">
                <mat-label class="mb-2">Escola(s) selecionada(s)</mat-label>
                <mat-chip-list *ngIf="selectedSchools">
                    <mat-chip *ngFor="let schoolChip of selectedSchools" selectable="true" [title]="schoolChip.name" removable="true" (removed)="removeSchoolChip(schoolChip)">
                        <span [title]="schoolChip.name">{{schoolChip.name}}</span>
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" (selectionChange)="getDisciplines($event.value)">
                        <!-- <mat-option value="0">Todos</mat-option> -->
                        <mat-option *ngFor="let plan of plans" [value]="plan.id" [title]="plan.name">
                            <span [title]="plan.name"> {{plan.name}}</span>

                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Disciplinas" (selectionChange)="getDisciplineVideos($event.value, true)">
                        <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id" [title]="discipline.name">
                            <span [title]="discipline.name">   {{discipline.name}}</span>

                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4 pt-3">
                <mat-checkbox formControlName="justExercises">Somente exercícios</mat-checkbox>
            </div>
        </div>
        <!-- Videos -->
        <div class="row" *ngIf="videos || (selectedVideos && editMode)">
            <div class="col-md">
                <mat-form-field class="width-100">
                    <input #filterVideoName matInput class="m-0 p-0" placeholder="Buscar vídeos por nome">
                    <mat-icon class="mr-15 c-pointer" matSuffix *ngIf="!searching" (click)="getVideosByName(filterVideoName.value)" size="medium">search</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md">
                <mat-form-field class="width-100">
                    <mat-select formControlName="videos" placeholder="Vídeos">
                        <mat-option (click)="addVideoChip(video)" *ngFor="let video of videos" [value]="video.id">
                            {{video.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="videos || (selectedVideos && editMode)">
            <div class="col-md" *ngIf="selectedVideos?.length > 0">
                <mat-label class="mb-2">Vídeos selecionados</mat-label>
                <mat-chip-list>
                    <mat-chip *ngFor="let videoChip of selectedVideos" selectable="true" removable="true" (removed)="removeVideoChip(videoChip)">
                        {{videoChip.title}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </form>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver disciplinas cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createDiscipline()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Cadastrar</button>
        </div>
    </div>
    <div *ngIf="editMode" class="row mt-5">
        <!-- <div *ngIf="editMode" class="text-left col-6">
            <button (click)="duplicateDiscipline()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Duplicar</button>
        </div> -->
        <div *ngIf="editMode" class="text-right col-12">
            <button (click)="saveChanges()" class="right " nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Salvar</button>
        </div>
    </div>
</div>
<div *ngIf="showDisciplinesList" class="">
    <nb-card size="medium" *ngIf="!loading">
        <nb-card-header>
            <div class="row">
                <div class="col-md-6" *ngIf="userService.isAdmin()">
                    <mat-form-field class="width-100">
                        <mat-label>Escola</mat-label>
                        <mat-select [(ngModel)]="selectedSchool" (selectionChange)="getPlans($event.value)">
                            <mat-option *ngFor="let school of schools" [value]="school.id" [title]="school.name">
                                <span [title]="school.name">   {{school.name}}</span>

                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field class="width-100">
                        <mat-select placeholder="Plano" [(ngModel)]="filterPlan" (selectionChange)="getDisciplines()">
                            <!-- <mat-option value="0">Todos</mat-option> -->
                            <mat-option *ngFor="let plan of plans" [value]="plan.id" [title]="plan.name">
                                <span [title]="plan.name">   {{plan.name}}</span>

                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-10 col-md-10 col-lg-4 col-xl-4">
                    <mat-form-field class="width-100">
                        <input #nameDiscipline (click)="teste(disciplineName)" matInput [(ngModel)]="disciplineName" placeholder="Buscar disciplina por nome">
                    </mat-form-field>
                </div>
                <div class="col-2 col-md-2 col-xl-2 col-lg-2">
                    <button (click)="getNameDiscipline(disciplineName)" class="right" mat-icon-button color="accent" matTooltip="Buscar">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-spinner class="mt-1 center" *ngIf="searching" [diameter]="20"></mat-spinner>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-list class="width-90 mt-3 center">
                <nb-list-item *ngFor="let discipline of disciplines">
                    <div class="row">
                        <div class="col-5">
                            {{discipline.name}}
                        </div>
                        <div class="col-4">
                            {{discipline.tag}}
                        </div>
                        <!-- <div class="col-2">
                    criado em {{discipline.createDate | date:'dd/MM/yyyy'}}
                </div> -->
                        <div class="col-2 text-right">
                            <button (click)="editDiscipline(discipline)" nbButton size="tiny" outline status="primary">Editar</button>
                        </div>
                        <div class="col-1 text-right">
                            <button size="tiny" (click)="removeDiscipline(discipline.id)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>
    <mat-paginator [hidden]="loading || nextPageActive" [length]="totalPage" [pageSize]="pageSize" [pageIndex]="pageNo" [pageSizeOptions]="[10, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500]" (page)="pageEvents($event)" showFirstLastButtons="false">
    </mat-paginator>

    <mat-spinner *ngIf="loading" class="center mt-4"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showDisciplinesList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>


</div>
