import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { TopicDisciplineService } from '../../topic-discipline.service';

@Component({
  selector: 'app-plans-topic',
  templateUrl: './plans-topic.component.html',
  styleUrls: ['./plans-topic.component.scss']
})
export class PlansTopicComponent implements OnInit {

  public plans: any[];
  public loading: boolean;

  constructor(
    private router: Router,
    public layoutService: LayoutService,
    public userService: UserService,
    public topicDisciplineService: TopicDisciplineService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    let userId = Number(this.userService.getUserId());
    let classId = Number(this.userService.getUserClass());
    this.getPlansByUser(userId, classId);
  }

  selectPlan(id: number, name:string){
    this.router.navigateByUrl('topic/plan/' + id +'/disciplines'+'?planName='+name);
  }
  
  getPlansByUser(userId: number, classId:number) {
    this.loading = true;
    this.topicDisciplineService.getPlansByUser(userId, classId)
      .subscribe(res => {
        const response = res.body as any;
        if (!response.error) { this.plans= response.data as any[];
        } else { this.alertService.error(response.error); }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

}
