import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MyExerciseLibraryService } from '../my-exercise-library.service';

@Component({
  selector: 'app-deletelib',
  templateUrl: './deletelib.component.html',
  styleUrls: ['./deletelib.component.scss']
})
export class DeletelibComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public deleteConf: any,
    @Optional() public dialogRef: MatDialogRef<DeletelibComponent>,
    private dialog: MatDialog,
    private libraryService: MyExerciseLibraryService,
    private alertService: AlertService,
    public deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
  }

  modalClose(){
    this.dialog.closeAll();
  }

  deleteSelect(){

    //console.log('entrou no metodo');
    switch(this.deleteConf.value){
      case 2:
        this.libraryService.deleteDiscipline(this.deleteConf.id)
        .subscribe(res => {
          const response  = res.body as ResponseApi;
          
          if(!response.error){
            this.alertService.success('Dados deletados com sucesso!');
            this.dialogRef.close(true);
          }
          else{
            this.alertService.error(response.error);
          }
        })
        break;
      case 1:
        this.libraryService.deleteQuestion(this.deleteConf.id)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.alertService.success('Questao deletada com sucesso');
            this.dialogRef.close(true);
          }
          else{
            this.alertService.error(response.error);
          }
        })  
        break;
      case 3:
        this.libraryService.deleteTheme(this.deleteConf.id)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.alertService.success('Tema deletado com sucesso');
            this.dialogRef.close(true);
          }
          else{
            this.alertService.error(response.error);
          }
        })
        break;
      case 4:
        this.libraryService.deleteSubTheme(this.deleteConf.id)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.alertService.success('Sub-tema deletado com sucesso!');
            this.dialogRef.close(true);
          }
          else{
            this.alertService.error(response.error);
          }
        })
        break;
      case 5:
        this.libraryService.deleteInstitution(this.deleteConf.id)
        .subscribe(res =>{
          const response = res.body as ResponseApi;

          if(!response.error){
            this.alertService.success('Instituição deletada com sucesso!');
            this.dialogRef.close(true);
          }
          else{
            this.alertService.error(response.error);
          }
        }) 
        break; 
        case 6:
          this.libraryService.deleteQuestionType(this.deleteConf.id)
          .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
              this.alertService.success('Tipo de questão deletado com sucesso');
              this.dialogRef.close(true);
            }
            else{
              this.alertService.error(response.error);
            }
          })
          break;
    }
  }

}
