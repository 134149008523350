<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-12 mt-2">
            <div class="page-title-container mt-2 pb-0 pt-0" [ngClass]="{'text-center': layoutService.isMobile}">
                <span class="page-title">MATERIAIS</span>
                <div class="mt-3 description-title">
                    <h4 *ngIf="!layoutService.isMobile">Mural da escola</h4>
                    <p *ngIf="!layoutService.isMobile && userService.isStudent()">Escolha o material que você deseja fazer o download</p>
                    <h6 class="mb-0" *ngIf="layoutService.isMobile">Mural da escola</h6>
                    <p class="fs-13 pb-0" *ngIf="layoutService.isMobile && userService.isStudent()">Selecione o plano que você deseja estudar</p>
                </div>
            </div>
        </div>
    </div>    
    <!-- SELECTS -->
    <div class="row m-0">
        <div class="col-md" *ngIf="!userService.isStudent() && !userService.isTeacher()" id="filter">
            <mat-form-field appearance="outline" class="width-100 m-0 p-0 form-field">
                <mat-select class="p-0 m-0 form-select" placeholder="Tipo de material" [(ngModel)]="materialTypeAdm" (selectionChange)="materialTypeChange()">
                    <mat-option [value]="false">Alunos</mat-option>
                    <mat-option [value]="true">Coordenação</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md" *ngIf="(userService.isAdmin() || userService.isGeneralCoordinator() || userService.isTeacher())">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Escola</mat-label>
                <mat-select placeholder="Escola" (selectionChange)="getUnits($event.value)" [(ngModel)]="schoolId">
                    <mat-option *ngFor="let school of schools" [value]="school.id">{{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md" *ngIf="(userService.isAdmin() || userService.isGeneralCoordinator() || userService.isTeacher())">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Unidade</mat-label>
                <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)" [(ngModel)]="unitId">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md" *ngIf="!userService.isStudent()">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Turma (opcional)</mat-label>
                <mat-select placeholder="Turma" [(ngModel)]="classId" (selectionChange)="applyFilter($event.value)">
                    <mat-option [value]="1">Selecione</mat-option>
                    <mat-option *ngFor="let class of classes" [value]="class.id">{{class.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- ajustado -->
        <div class="col-12 col-md-3 col-lg-3 col-xl-3 right" *ngIf="!materialTypeAdm">
            <div  id="filter">
                <mat-form-field class="width-100 m-0 p-0 form-field" appearance="fill">
                    <mat-select placeholder="Tipo de material do aluno" class="p-0 m-0 form-select" (selectionChange)="getMaterials($event.value, 0)" [(ngModel)]="examType">
                        <mat-option selected [value]="false">Materiais</mat-option>
                        <mat-option *ngIf="hasExamMaterials" [value]="true">Avaliações</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
           <!--  <mat-form-field class="width-100 fs-13" appearance="outline">
                <mat-label>Tipo de material do aluno</mat-label>
                <mat-select placeholder="Selecione o tipo de material" (selectionChange)="getMaterials($event.value, 0)" [(ngModel)]="examType">
                    <mat-option selected [value]="false">Materiais</mat-option>
                    <mat-option *ngIf="hasExamMaterials" [value]="true">Avaliações</mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>
    </div>
    <div class="row m-0">
        <div class="col-12 mt-2">
            <ng-container>
                <div class="table-container table-container-discipline height-100 p-0">
                    <table *ngIf="!loading && dataSource?.data?.length" mat-table color="accent" class="table-discipline mb-3">
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef [hidden]="layoutService.isMobile" class="brl-5"> DATA </th>
                            <td mat-cell *matCellDef="let material; let i = index" [ngClass]="{'table-line-contrast': i % 2}" [hidden]="layoutService.isMobile">
                                {{material.createDate | date:'dd/MM/yy hh:mm'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="teacher">
                            <th mat-header-cell *matHeaderCellDef [ngClass]="{'brl-5': layoutService.isMobile}"> PROFESSOR / NOME </th>
                            <td mat-cell *matCellDef="let material; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                {{material.teacher}} {{material.name}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="observation">
                            <th mat-header-cell *matHeaderCellDef [hidden]="layoutService.isMobile"> OBSERVAÇÃO </th>
                            <td [hidden]="layoutService.isMobile" mat-cell *matCellDef="let material; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                {{material.observation}} </td>
                        </ng-container>
                        <ng-container matColumnDef="applyDate">
                            <th mat-header-cell *matHeaderCellDef [hidden]="!materialTypeAdm"> DATA APLICAÇÃO </th>
                            <td mat-cell *matCellDef="let material; let i = index" [ngClass]="{'table-line-contrast': i % 2}" [hidden]="!materialTypeAdm">
                                {{material.applyDate}} </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef [hidden]="!materialTypeAdm"> TIPO </th>
                            <td mat-cell *matCellDef="let material; let i = index" [ngClass]="{'table-line-contrast': i % 2}" [hidden]="!materialTypeAdm"> {{material.type}} </td>
                        </ng-container>
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="file" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef class="brr-5 text-center"> DOWNLOAD </th>
                            <td mat-cell *matCellDef="let material; let i = index" class="text-center" [ngClass]="{'table-line-contrast': i % 2}">
                                <button mat-icon-button color="accent" (click)="openMaterial(material.file)">
                                    <mat-icon>get_app</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </ng-container>
        </div>
        <div class="col-12">
            <ng-container>
                <!-- empty materials -->
                <div *ngIf="examType == undefined" class="text-center" [ngClass]="{'width-50': !layoutService.isMobile, 'mt-2': layoutService.isMobile}">
                    <h6 class="mb-2">Nenhum material selecionado</h6><br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
                <!-- empty materials -->
                <div class="text-center" [ngClass]="{'width-50': !layoutService.isMobile}" *ngIf="examType != undefined && !dataSource?.data?.length && !loading && !materialTypeAdm">
                    <h4>Nenhum material</h4>
                    <br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
            </ng-container>
        </div>       
    </div>    
    <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
</div>