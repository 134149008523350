<div class="container pt-5 pb-5">
    <mat-accordion>
        <mat-expansion-panel *ngIf="userService.isTeacher() || userService.isUnitCoordinator() || userService.isGeneralCoordinator() || userService.isAdmin()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Notificação</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-notification></app-create-notification>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>