<div class="container">
    <mat-card>
      <ng-container *ngIf="question && !loading && !finished">
  

        
  <div class="container">
      <mat-card>
          <ng-container *ngIf="question && !loading && !finished">
  
      
          <!-- actions  -->
          <div *ngIf="!saving" class="row actions mt-1 mb-2">
            <div class="col">
              <div *ngIf="mock.navigateQuestions && !firstQuestion">
                <button mat-stroked-button color="accent" (click)="prev()" matTooltipClass="custom-tooltip" matTooltip="Questão anterior">
                  &lt;&lt; Questão anterior
                </button>
              </div>
            </div>
            <div class="col text-right">
              <div *ngIf="(mock.navigateQuestions || questionsSaved[i]) && !lastQuestion">
                <button mat-stroked-button color="accent" (click)="next()" matTooltipClass="custom-tooltip" matTooltip="Próxima questão">
                  Próxima questão >>
                </button>
              </div>
              <div *ngIf="lastQuestion">
                <button *ngIf="questionsSaved[i] && (numReplied === questions.length)" mat-raised-button color="accent"
                  class="small-btn" (click)="end()">Terminar</button>
                <button *ngIf="(numReplied < questions.length)" mat-stroked-button color="warn">Você possui questões não
                  respondidas</button>
              </div>
            </div>
          </div>
    
          <!-- enunciado -->
          <mat-divider></mat-divider>
          <div class="question mt-3" [innerHTML]="question?.question"></div>
          <mat-divider></mat-divider>
    
          <!-- alternativas -->
              <div class="alternatives mt-4 mb-4 pb-3">
  
                <div class="list-options mat-elevation-z4" *ngFor="let alt of question?.alternatives; let j = index" [ngClass]=" 
                {
                  'selected-alternative': questionsResponse[i]['opt'+alt.option], 
                  'correct-alternative': (questionsResponse[i]['opt'+alt.option]) && verifyIsCorrectAlternative(i, alt.option), 
                  'incorrect-alternative': (questionsResponse[i]['opt'+alt.option]) && verifyIsIncorrectAlternative(i, alt.option),
                  'unselected-correct-alternative': (!questionsResponse[i]['opt'+alt.option]) && verifyIsCorrectAlternative(i, alt.option)
                }">
                <!--    ***************** NÃO REMOVER O NGIF ABAIXO - VALIDA SE OPÇÃO NÃO FOR NULA *****************    -->
                    <b class="alternative"  *ngIf="alt.value">{{alt.option}})</b>
                    <mat-checkbox  *ngIf="alt.value" [disabled]="questionsSaved[i]" [(ngModel)]="questionsResponse[i]['opt'+alt.option]"
                      class="radio-button" [value]="alt.option" [disableRipple]="true">
                      <div style="height: 70%; width: 100% !important; white-space: pre-line" [innerHTML]="alt.value">
                      </div>
                    </mat-checkbox>
                    <mat-icon *ngIf="(questionsResponse[i]['opt'+alt.option]) && verifyIsCorrectAlternative(i, alt.option)"
                      class="correct-icon" matTooltipClass="custom-tooltip" matTooltip="Parabéns! Você selecionou esta alternativa correta">check_circle
                    </mat-icon>
                    <mat-icon *ngIf="(questionsResponse[i]['opt'+alt.option]) && verifyIsIncorrectAlternative(i, alt.option)"
                      class="incorrect-icon" matTooltipClass="custom-tooltip" matTooltip="Ops! Esta alternativa que você selecionou é incorreta.">cancel
                    </mat-icon>
                    <mat-icon *ngIf="(!questionsResponse[i]['opt'+alt.option]) && verifyIsCorrectAlternative(i, alt.option)"
                      class="unselected-icon" matTooltipClass="custom-tooltip" matTooltip="Você não selecionou esta alternativa correta.">error</mat-icon>
                  </div>
          
                  <div style="display: inline-block;">
                    <button *ngIf="!saving" class="hvr-grow ml-1"
                      (click)="sendQuestionResponse(question.id, questionsResponse[i])"
                      [disabled]="disableSaveResponse(questionsResponse[i])" mat-raised-button class="right small-btn"
                      color="accent" [hidden]="questionsSaved[i]">
                      Salvar resposta <mat-icon>save</mat-icon>
                    </button>
                    <mat-spinner *ngIf="saving" [diameter]="30" class="ml-3"></mat-spinner>
                  </div>
                </div>
        
        <!-- resultado -->
        <div class="response-msg p-3 m-3" *ngIf="questionsResponse[i] && questionsCorrect[i] && !mock.contest">
          <div class="mb-3"><b>Legenda</b></div>
          <div class="label-item">
            <mat-icon class="correct-icon relative mr-2">check_circle</mat-icon>
            A alternativa selecionada é correta.
          </div>
          <div class="label-item">
            <mat-icon class="incorrect-icon relative mr-2">cancel</mat-icon>
            A alternativa selecionada é incorreta.
          </div>
          <div class="label-item">
            <mat-icon class="unselected-icon relative mr-2">error</mat-icon>
            A alternativa é correta mas não foi selecionada.
          </div>
  
        </div>
  
        <!-- actions  -->
        <div *ngIf="!saving" class="row actions mt-3">
          <div class="col">
            <div *ngIf="mock.navigateQuestions && !firstQuestion">
              <button mat-stroked-button color="accent" (click)="prev()" matTooltipClass="custom-tooltip" matTooltip="Questão anterior">
                &lt;&lt; Questão anterior
              </button>
            </div>
          </div>
          <div class="col text-right">
            <div *ngIf="(mock.navigateQuestions || questionsSaved[i]) && !lastQuestion">
              <button mat-stroked-button color="accent" (click)="next()" matTooltipClass="custom-tooltip" matTooltip="Próxima questão">
                Próxima questão >>
              </button>
            </div>
            <div *ngIf="lastQuestion">
              <button *ngIf="questionsSaved[i] && (numReplied === questions.length)" mat-raised-button color="accent"
                class="small-btn" (click)="end()">Terminar</button>
              <button *ngIf="(numReplied < questions.length)" mat-stroked-button color="warn">Você possui questões não
                respondidas</button>
            </div>
          </div>
        </div>
  
      </ng-container>
      <ng-container *ngIf="finished">
        <div class="end-mock text-center">
          <div class="end-mock-title">
            Obrigado por sua participação!
          </div>
          <div class="end-mock-content mt-5">
            O resultado estará disponível a partir do dia {{this.resultDate | date:'dd/MM/y'}} às
            {{this.resultDate | date:'HH:mm'}} horário de Brasília.
          </div>
          <button mat-raised-button color="accent" class="mt-3" routerLink="/dashboard">Voltar ao início</button>
        </div>
      </ng-container>
      <div class="text-center" *ngIf="!question || loading">
        <mat-spinner class="center" color='accent'></mat-spinner>
      </div>
    </mat-card>
  </div>