import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Image } from 'exceljs';
import { Subscription } from 'rxjs';
import { Color } from 'src/app/core/models/color';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../admin.service';
import { RouteMain } from '../create-routes/edit-route/route-main';
import { School } from '../school';
import { SchoolService } from '../school.service';

@Component({
  selector: 'app-create-school',
  templateUrl: './create-school.component.html',
  styleUrls: ['./create-school.component.scss']
})
export class CreateSchoolComponent implements OnInit, OnDestroy {

  public createSchoolForm: FormGroup;
  public showSchoolList: boolean;
  public schools: School[];
  public colors: Color[];
  public logo;
  public loginImg;
  public headerImg;
  public faviconIcon;
  public selectedRoutes: { id: number, route: string }[];
  public routes: RouteMain[];
  public editMode: boolean;
  public totalStudents: number;
  public loading: boolean;
  public schoolselected:any;
  private router : ActivatedRoute;
  private route : RouteMain;
  private admUpdate$: Subscription;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public school: School,
    @Optional() public dialogRef: MatDialogRef<CreateSchoolComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private adminService: AdminService) { }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.totalStudents = 0;
    this.getColors();
    this.getSchools();
    this.getRoutes();
    this.createSchoolForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      tag: ['', Validators.required],
      color: ['', Validators.required],
      logo: [''],
      domain: [''],
      blockRoutes: [''],
      headerImg: [''],
      showHeaderImg: [true],
      restrict_domain: [],
      loginImg: [''],
      faviconIcon: [''],
      defaultRouteId: ['1'],
      restrict_domain_old: [],
      ocultMocksDashboard: [false],
      ocultLiveClassroom: [false]
    });
    this.routes = [];
    this.selectedRoutes = [];

    if (this.school) { 
      this.initializeEditMode(); }
  }

  addRoutesChip(route: { id: number, route: string }) {
    const index = this.selectedRoutes.indexOf(route);

    if (index < 0) {
      this.selectedRoutes.push(route);
      this.createSchoolForm.get('blockRoutes').reset();
    }
  }

  removeRoutesChip(route: { id: number, route: string }) {
    const index = this.selectedRoutes.indexOf(route);

    if (index >= 0) {
      this.selectedRoutes.splice(index, 1);
    }
  }

  getAllStudentsSchool(schoolId: number){
    this.schoolService.getAllStudentsSchool2(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.totalStudents = response.data['total'] as number;
      }
      else{
        this.totalStudents = 0;
      }
    });
  }

  getBlockRoutes(schoolId: number) {
    this.schoolService.getBlockRoutes(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedRoutes = response.data as { id: number, route: string }[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  getRoutes() {
    this.adminService.getRoutes().subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.routes = response.data as RouteMain[];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar as rotas. Verifique a conexão e tente novamente'));
  }


  handleFileInput(files: FileList, from: string): void {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);     
    if (files && files[0]) {
      reader.onload = e => {
        switch (from) {
          case 'logo':
            this.logo = reader.result;
            break;
          case 'header':
            this.headerImg = reader.result;
            break;
          case 'login':
            this.loginImg = reader.result;
            break;
          case 'favicon':
            this.faviconIcon = reader.result;
            break;
        }
      };   
    }
  }


  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

 /*  getSchoolOne(schoolId:number){
    this.schoolService.getSchoolOne(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.school = response.data as School;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao contar os alunos. Verifique a conexão e tente novamente'));
  } */

  getColors() {
    this.schoolService.getColors()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.colors = response.data as Color[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as cores. Verifique a conexão e tente novamente'));
  }

  openList() {
    this.getSchools();
    this.showSchoolList = true;
  }

  createSchool() {
    const routesIds = [];
    this.selectedRoutes.map(r => routesIds.push(r.id));
    this.createSchoolForm.get('blockRoutes').setValue(routesIds);
    this.createSchoolForm.get('logo').setValue(this.logo);
    this.createSchoolForm.get('headerImg').setValue(this.headerImg);
    this.createSchoolForm.get('loginImg').setValue(this.loginImg);
    this.createSchoolForm.get('faviconIcon').setValue(this.faviconIcon);

    if (!this.createSchoolForm.valid) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    const school = this.createSchoolForm.getRawValue() as School;
    
    this.schoolService.createSchool(school)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Escola cadastrada', 3);
          this.createSchoolForm.reset();
          this.adminService.updateChields();
        } else {
          this.loading = false;
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao criar a escola. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  //commit
  duplicateSchool() {

    const routesIds = [];
    this.selectedRoutes.map(r => routesIds.push(r.id));
    this.createSchoolForm.get('blockRoutes').setValue(routesIds);
    this.createSchoolForm.get('defaultRouteId').setValue(1);
    //this.createSchoolForm.get('logo').setValue(this.logo);
    //this.createSchoolForm.get('headerImg').setValue(this.headerImg);
    //this.createSchoolForm.get('loginImg').setValue(this.loginImg);
    //this.createSchoolForm.get('faviconIcon').setValue(this.faviconIcon);

    if (!this.createSchoolForm.valid) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    const school = this.createSchoolForm.getRawValue() as School;
    school.name = school.name + " (Duplicado)";

    this.schoolService.createSchool(school)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Escola Duplicada', 3);
          this.createSchoolForm.reset();
          this.dialogRef.close(true);
          this.getSchools();
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao Duplicar a escola. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getAllSchool(id: number) {
    this.schoolService.getAllSchool(id)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.school = response.data as School;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar a escola. Verifique a conexão e tente novamente'));
  }


  editSchool(school: School) {

    //console.log(school);
    this.editMode = true;
    this.schoolselected = school;
    this.createSchoolForm.get('id').setValue(this.schoolselected.id);
    this.getAllSchool(school.id);
    const dialogRef = this.dialog.open(CreateSchoolComponent, {
      minWidth: '60vw',
      data: {
          'name': school.name,
          'id': school.id,
          'tag': school.tag,
          'domain': school.domain,
          'color': school.color,
          'logo': school.logo,
          'favicon':school.faviconIcon,
          'defaultRouteId': school.defaultRouteId,
          'restrict_domain': school.restrict_domain,
          'ocultMocksDashboard':school.ocultMocksDashboard,
          'ocultLiveClassroom' :school.ocultLiveClassroom
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSchools();
      }
    });
  }

  removeSchool(schoolId: number) {
    if (confirm('Deseja realmente remover a Escola?')) {
      this.schoolService.removeSchool(schoolId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Escola removida', 3);
            this.getSchools();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a escola. Verifique a conexão e tente novamente'));
    }
  }

  // only edit mode methods

  initializeEditMode() {
    //this.school.defaultRouteId += '';
    this.getBlockRoutes(this.school.id);
    this.createSchoolForm.get('logo').setValidators([]);
    this.editMode = true;
    this.createSchoolForm.patchValue(this.school);
    //this.getSchoolOne(this.school.id);
    this.createSchoolForm.get('ocultMocksDashboard').setValue(this.school.ocultMocksDashboard);
    this.createSchoolForm.get('ocultLiveClassroom').setValue(this.school.ocultLiveClassroom);

    this.createSchoolForm.get('restrict_domain').setValue(this.school.restrict_domain);
    let routeId = String(this.school.defaultRouteId);
    this.createSchoolForm.get('defaultRouteId').setValue(routeId);
    this.getAllStudentsSchool(this.school.id);
  }

  saveChanges() {

    if (this.createSchoolForm.valid) {

      this.loading = true;
      let routesIds = [];
      //this.createSchoolForm.get('defaultRouteId').setValue(routesIds);

      let auxDomain = this.school.domain as string;
      let auxRestricted = this.school.restrict_domain;
      this.selectedRoutes.map(r => routesIds.push(r.id));
      this.createSchoolForm.get('blockRoutes').setValue(routesIds);
      this.createSchoolForm.get('logo').setValue(this.logo);
      this.createSchoolForm.get('headerImg').setValue(this.headerImg);
      this.createSchoolForm.get('loginImg').setValue(this.loginImg);
      this.createSchoolForm.get('id').setValue(this.school.id);
      this.createSchoolForm.get('faviconIcon').setValue(this.faviconIcon);

      let school = this.createSchoolForm.getRawValue() as School;

      school.domainOld = auxDomain;
      school.restrict_domain_old = auxRestricted;
      this.schoolService.saveSchoolChanges(school)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Escola editada com sucesso', 3);
            this.dialogRef.close(true);
            this.getSchools();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar a edição. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }

  backBtn(){
    this.showSchoolList = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
