import { Component, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Payment } from 'src/app/modules/payment/payment';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { Plan } from 'src/app/modules/payment/plan';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { School } from '../../school/school';
import { StudentService } from '../../student/student.service';
import { Payer, PlaymentPlans, StudentPayment } from '../paying/payer';

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {
  public plans$: Observable<Plan[]>;
  public payers$: Observable<Payer[]>;
  public users$: Observable<Payer[]>;
  public payment$: Observable<StudentPayment[]>;
  public paymentsPlans$: Observable<PlaymentPlans[]>;
  public currentPayerId: number;
  public userPayments$: Observable<Payment[]>;
  public loading: boolean;
  public deleting: boolean;
  public expandedElement: any;
  public filterEmail = '';
  public filterName = '';
  public filterPlanId: number;
  public planId: number;
  public schools: School[];
  public schoolId: number;
  public blockView:boolean= true;
  public planName: string;
  public user: any;
  public planSelected: PlaymentPlans;

  constructor(
      private paymentService: PaymentService,
      @Optional() public dialogRef: MatDialogRef<CreatePaymentComponent>,
      public dialog: MatDialog,
      private studentService: StudentService,
      public userService: UserService,
      private alertService: AlertService) { }

  ngOnInit() {        
    if (this.userService.isAdmin()) {
        this.getSchools();
    } else if (this.userService.isGeneralCoordinator()) {
      this.schoolId = Number(this.userService.getUserSchool());
        this.getPaymentPlans(this.schoolId);
    }else{
        this.blockView = false;
    }  
  }
  getSchools() {
    this.paymentService.getSchools()
        .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
            this.schools = response.data as School[];
        } else {
            this.alertService.error(response.error);
        }
        }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getPaymentPlans(school: number){
    this.schoolId = school;
    this.paymentsPlans$ = this.paymentService.getPaymentPlans(school);
  }

  getPayers(planId: number) {
    this.payers$ = this.paymentService.getPlanPayments(planId);
  }

  getUserByName(name?: string ) {
    if ((!name  )) {
      this.filterEmail = '' ; this.filterName = '';
      this.filterPlanId = null;
      this.alertService.warning('A busca precisa conter 3 ou mais caracteres');
      return;
    }
    this.filterPlanId
    this.loading = true;
    this.user = this.paymentService.getUserByName(this.schoolId, name);


    this.paymentsPlans$.forEach(elemento => {
      elemento.forEach(element => {
        if(element.id == this.filterPlanId){
          this.planSelected.paymentName = element.name;
        }
      });
    });
  }

  getUserByEmail( email?: string) {
    if (( !email)) {
      this.filterPlanId = null;
      this.filterEmail = '' ; this.filterName = '';
      this.alertService.warning('A busca precisa conter 3 ou mais caracteres');
      return;
    }    
    this.loading = true;
    this.users$ = this.paymentService.getPlanPaymentsEmail(this.schoolId, email);

    this.paymentsPlans$.forEach(elemento => {
      elemento.forEach(element => {
        if(element.paymentPlanId == this.filterPlanId){
          element.paymentPlanId
          this.planName = element.name;
        }
      });
    });
  }

  createPayment(user: any) {
    let releasePayment = { 
      id: 0,
      name: user.name,
      email: user.email,
      plan: user.plan,
      classId: user.classId,
      status: "paid",
      planId: user.planId,
      paymentName: user.paymentName,
      studentId: user.studentId
    }
    if (confirm('Deseja realmente liberar o pagamento?')) {      
      this.paymentService.createUserPayment(releasePayment).subscribe(res =>{         
        const response = res.body as ResponseApi;
        if(!response.error) {
          const data = response.data as number;
          releasePayment.id = Number(data);
          this.releasePayment(releasePayment);
          this.alertService.success("Salvo com sucesso!");
        } else {
          this.alertService.error(response.error);
        }
      }, err =>{this.alertService.error('Houve um erro ao adicionar o pagamento');});
    } 
  }

  releasePayment(payer: StudentPayment){
    if (confirm('Deseja realmente liberar o pagamento?')) {
      payer.status = "paid";
      this.paymentService.releasePayment(payer).subscribe(res =>{
        const response = res.body as ResponseApi;
        if (!response.error) {            
          this.alertService.success("Salvo com sucesso!");
          this.dialogRef.close();
        } else {
          this.alertService.error(response.error);
        }
      }, err =>{this.alertService.error('Houve um erro ao adicionar o pagamento');});
    }
  }

  blockPayment(payer: StudentPayment){
    if (confirm('Deseja realmente liberar o pagamento?')) {      
      this.paymentService.blockPayment(payer.id).subscribe(res =>{        
        const response = res.body as ResponseApi;
        if (!response.error) {
          payer.status = "processing";
          this.alertService.success("Salvo com sucesso!");
        } else {
          this.alertService.error(response.error);
        }
      }, err =>{this.alertService.error('Houve um erro ao adicionar o pagamento');});
    }
  }
}












