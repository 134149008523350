<!--Moderar Comentarios-->
<div class="container pt-5">
    <nb-accordion class="width-70 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Avaliação dos vídeos</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-video-likes-control></app-video-likes-control>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>


<div class="container pt-5">
    <nb-accordion class="width-70 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Moderar Comentários</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <nb-card-header><b>Comentários Total : </b> {{totalBlockedComments}} <br></nb-card-header>
                <div class="mt-4" *ngIf="bloquedComments.length > 0">
                    <app-forum-moderated *ngFor="let comment of bloquedComments" [comment]="comment" [isForumManagement]="true" (addEvent)="rechargeComments()"></app-forum-moderated>
                </div>
                <div *ngIf="bloquedComments.length <= 0" class="center mt-5 ml-5">
                    <span>Não existem comentários para serem aprovados!</span>
                </div>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>



<div class="container pt-5">
    <nb-accordion class="width-70 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Fórum - Tópicos</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>

                <nb-card size="large" *ngIf="!initializing && !loading">
                    <nb-card-header>
                        <div class="row">
                            <div class="col-md-12" *ngIf="userService.isAdmin()">
                                <mat-form-field appearance="outline" class="width-100">
                                    <mat-select placeholder="Escola *" (selectionChange)="getPlans($event.value)">
                                        <mat-option *ngFor="let school of schools" [value]=" school.id">{{school.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="col">
                                <mat-form-field appearance="outline" class="width-100">
                                    <mat-select placeholder="Plano" [(ngModel)]="filterPlanId" (selectionChange)="getPlanDisciplines($event.value)">
                                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                            {{plan.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="outline" class="width-100">
                                    <mat-select placeholder="Disciplina" [(ngModel)]="filterDisciplineId" (selectionChange)="getDisciplineVideos($event.value)">
                                        <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                            {{discipline.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="outline" class="width-100">
                                    <mat-select placeholder="Tópico" [(ngModel)]="filterVideoId" (selectionChange)="getTopicComments($event.value)">
                                        <mat-option *ngFor="let video of videos" [value]="video.id">
                                            {{video.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <nb-card-header>Comentários<br></nb-card-header>
                        <app-forum-comment *ngFor="let comment of comments" [comment]="comment" [isForumManagement]="true" (deleteEvent)="getTopicComments(filterVideoId)"></app-forum-comment>
                    </nb-card-body>
                </nb-card>
                <!-- <mat-paginator *ngIf="!filterVideoId" [hidden]="loading" length="1000" pageSize="20" [pageSizeOptions]="[20]"
          (page)="getLastComments($event.pageIndex + 1)">
        </mat-paginator> -->
                <mat-spinner *ngIf="initializing || loading" class="center mt-5"></mat-spinner>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>