<mat-chip-list *ngIf="!layoutService.isMobile">

    <mat-chip *ngFor="let video of day?.videos; let i = index" class="hvr-grow mr-2 mat-elevation-z6" (click)="openDayVideos(day.videos, i)" [color]="video.watched ? 'accent' : 'primary'" [selected]="teacherOwnerDiscipline(video.discipline)" [matTooltip]="video.title">
        {{video.tag}}
    </mat-chip>

</mat-chip-list>

<mat-chip-list *ngIf="layoutService.isMobile && userService.isStudent()" class="mt-1"  >
    <mat-chip *ngIf="day?.remaining > 0" class="hvr-grow mr-2 mb-2 mat-elevation-z6" color="accent" (click)="openDayVideos(day.videos, 0)" >
        {{day.remaining}} 
    </mat-chip>  
    <mat-chip *ngIf="day?.remaining <= 0" matTooltipClass="custom-tooltip" matTooltip="Todos os vídeos assistidos" (click)="openDayVideos(day.videos, 0)"  class="hvr-grow mr-2 mat-elevation-z6" color="accent">
        <nb-icon icon="checkmark-circle-outline"></nb-icon>
    </mat-chip>
</mat-chip-list>