<div class="mt-3 mr-2 ml-2" *ngIf="userService.isTeacher()">
    <nb-accordion class="mt-3 mb-5 center">
        <nb-accordion-item expanded="true">
            <nb-accordion-item-header>
                <!-- Exibir módulos para escola EX FITNESS -->
                <h4 *ngIf="userService.getUserSchool() == 88; else disciplinesTitle">Módulos</h4>
                <ng-template #disciplinesTitle>
                    <h4>Planos Para Visualização!</h4>
                </ng-template>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <!-- live classes -->
                <div class="row mb-3" [hidden]="!(userService.isStudent() && showClassrooms)">
                    <div class="col-md-12">
                        <nb-accordion>
                            <nb-accordion-item expanded="true">
                                <nb-accordion-item-header>
                                    Aulas ao vivo
                                </nb-accordion-item-header>
                                <nb-accordion-item-body class="m-3">
                                    <app-classroom-list *ngIf="planSelected?.id" (haveClassrooms)="setShowClassrooms($event)" [planId]="planSelected?.id">
                                    </app-classroom-list>
                                </nb-accordion-item-body>
                            </nb-accordion-item>
                        </nb-accordion>
                    </div>
                </div>

                <!-- Chose plan -->
                <div *ngIf="!planSelected && studyPlans && !loading">
                    <h5 class="ml-4">Selecione o plano</h5>
                    <nb-list class="width-80 center">
                        <nb-list-item href="#" *ngFor="let studyPlan of studyPlans">
                            <div class="row">
                                <div class="col plan-name">
                                    <mat-icon class="mt-2 mr-3" color="accent">event</mat-icon>
                                    <span class="text-color-primary">{{studyPlan.name}}</span>
                                </div>
                                <div class="col text-right">
                                    <button *ngIf="!studyPlan.expired" color="accent" mat-mini-fab class="hvr-grow" [matTooltip]="studyPlan.name" (click)="selectPlan(studyPlan)">
                                        <mat-icon>arrow_right_alt</mat-icon>
                                    </button>
                                    <!-- expired -->
                                    <span *ngIf="studyPlan.expired" matTooltipClass="custom-tooltip" matTooltip="Plano expirado">
                                        <button mat-mini-fab disabled>
                                            <mat-icon>hourglass_disabled</mat-icon>
                                        </button>
                                    </span>

                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>
                <!-- END Chose plan -->
                <!-- Chose plan -->
                <div *ngIf="planSelected && !videos && !loading">
                    <a color="accent border-none" class="mt-1 mb-1 back-button" (click)="disciplines=null;planSelected=null;">
                        <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                    </a>
                    <h5 class="ml-4">Selecione a disciplina</h5>
                    <nb-list *ngIf="planSelected && disciplines && !loading" class=" width-80 center">
                        <nb-list-item href="#" *ngFor="let discipline of disciplines">
                            <div class="row">
                                <div class="col plan-name">
                                    <mat-icon class="mt-2 mr-3" color="accent">school</mat-icon>
                                    <span class="text-color-primary">{{discipline.name}}</span>
                                </div>
                                <div class="col text-right">
                                    <button color="accent" mat-mini-fab class="hvr-grow" [matTooltip]="discipline.name" (click)="openDisciplineVideos(discipline)">
                                        <mat-icon>arrow_right_alt</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>
                <div *ngIf="videos && !loading">
                    <a color="accent border-none" class="mt-1 mb-1 back-button" (click)="videos=null;">
                        <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                    </a>
                    <h5 class="ml-4">Selecione o video</h5>
                    <nb-list *ngIf="planSelected && disciplines && !loading" class=" width-80 center">
                        <nb-list-item href="#" *ngFor="let video of videos">
                            <div class="row">
                                <div class="col plan-name">
                                    <mat-icon class="mt-2 mr-3" color="accent">school</mat-icon>
                                    <span class="text-color-primary">{{video.title}}</span>
                                </div>
                                <div class="col text-right">
                                    <button color="accent" mat-mini-fab class="hvr-grow" [matTooltip]="video.name" (click)="openVideo(video.id)">
                                        <mat-icon>arrow_right_alt</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>
                <!-- END Chose plan -->

                <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>