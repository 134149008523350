<div class="center dialog" >
  <div *ngIf="!loading" class="row">
      <div class="col-md-6 pb-3 bg_color_white">
          <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
              <span class="page-title mt-3">CORRIGIR TAREFA</span>
              <div class="mt-3 mb-3 description-title">
                  <h4 *ngIf="!layoutService.isMobile">{{task.taskName}}</h4>
                  <h6 class="mb-0" *ngIf="layoutService.isMobile">{{task.taskName}}</h6>
              </div>
          </div>

          <div  class="mr-3 ml-3"><strong>Nome:</strong> {{task.studentName}}</div>
          <div  class="mr-3 ml-3"><strong>Turma:</strong> {{task.className}}</div>
          <div  class="mr-3 ml-3"><strong>Data de entrega:</strong>  {{task.finalDate | date: 'dd/MM/yyyy'}}</div>
          <div *ngIf="isTaskAfile" class="mt-3 mb-3">
              <a mat-button (click)="openFile(task.taskDoneLink)" color="accent">
                  <mat-icon>attach_file</mat-icon> Abrir Tarefa
              </a>
          </div>


          <div class="mr-3 ml-3">
            <mat-form-field class="width-100">
                <input matInput placeholder="Nota" [(ngModel)]="score" [value]="task.score">
            </mat-form-field>
            <mat-form-field class="width-100">
                <input matInput [placeholder]="task.feedback || 'Feedback'"  [(ngModel)]="feedb" [value]="task.feedback">
            </mat-form-field>
          </div>

          <button (click)="save(task.id, task.studentId, task.studentName)" mat-raised-button color="accent" class="small-btn width-100 mt-3 mr-3 ml-3">
              Enviar correção
          </button>
      </div>
      <div *ngIf="!isTaskAfile" class="col-md-6 word-break">
          <div class="student-task-text p-3">
              <h5 class="mb-2">Texto do Aluno</h5>
              <br />
              <p>{{task.taskDoneText}}</p>
          </div>
      </div>
  </div>
  <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
</div>
