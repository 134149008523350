<ng-container>
  <div [hidden]="!offline" class="network mat-elevation-z6">
    <div class="dino">
      <img src="../../../../assets/images/dino.svg" width="50px">
    </div>
    <div class="net-content">
      <div class="net-title">Sem conexão</div>
      <div class="net-description">Verifique a sua conexão com a internet</div>
    </div>
    <div class="net-icon text-color-accent">
      <mat-icon color="accent">wifi_off</mat-icon>
    </div>
  </div>
</ng-container>