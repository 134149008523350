import { Component, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile, delay } from 'rxjs/operators';
import * as echarts from 'echarts';

@Component({
    selector: 'app-chart-donut',
    templateUrl: './chart-donut.component.html',
    styleUrls: ['./chart-donut.component.scss']
})
export class ChartDonutComponent implements OnInit, AfterViewInit, OnDestroy {

    private alive = true;

    @Input() value: number[];
    @Input() legends: string[];
    option: any = {};
    chartLegend: { iconColor: string; title: string }[];
    echartsIntance: any;

    constructor(private theme: NbThemeService) { }

    ngOnInit(): void { }

    ngAfterViewInit() {
        this.theme.getJsTheme()
            .pipe(
                takeWhile(() => this.alive),
                delay(1)
            )
            .subscribe(config => {

                const variables = config.variables;
                const pieLegend = config.variables.visitorsPieLegend;

                this.setOptions(variables);
                this.setLegendItems(pieLegend);
            });
    }

    setLegendItems(pieLegend) {
        this.chartLegend = [
            {
                iconColor: pieLegend.firstSection,
                title: this.legends[0],
            },
            {
                iconColor: pieLegend.secondSection,
                title: this.legends[1],
            },
        ];
    }

    setOptions(variables) {
        const visitorsPie = variables.visitorsPie;

        this.option = {
            tooltip: {
                trigger: 'item',
                formatter: '',
            },
            series: [
                {
                    name: ' ',
                    clockWise: true,
                    hoverAnimation: true,
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: visitorsPie.firstPieRadius,
                    data: [
                        {
                            value: this.value[0],
                            name: ' ',
                            label: {
                                normal: {
                                    position: 'center',
                                    formatter: '',
                                    textStyle: {
                                        fontSize: '22',
                                        fontFamily: variables.fontSecondary,
                                        fontWeight: '600',
                                        color: variables.fgHeading,
                                    },
                                },
                            },
                            tooltip: {
                                show: false,
                            },
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: visitorsPie.firstPieGradientLeft,
                                        },
                                        {
                                            offset: 1,
                                            color: visitorsPie.firstPieGradientRight,
                                        },
                                    ]),
                                    shadowColor: visitorsPie.firstPieShadowColor,
                                    shadowBlur: 0,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 3,
                                },
                            },
                            hoverAnimation: true,
                        },
                        {
                            value: this.value[1],
                            name: ' ',
                            tooltip: {
                                show: false,
                            },
                            label: {
                                normal: {
                                    position: 'inner',
                                },
                            },
                            itemStyle: {
                                normal: {
                                    color: variables.layoutBg,
                                },
                            },
                        },
                    ],
                },
                {
                    name: ' ',
                    clockWise: true,
                    hoverAnimation: true,
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: visitorsPie.secondPieRadius,
                    data: [
                        {
                            value: this.value[0],
                            name: ' ',
                            label: {
                                normal: {
                                    position: 'center',
                                    formatter: '',
                                    textStyle: {
                                        fontSize: '22',
                                        fontFamily: variables.fontSecondary,
                                        fontWeight: '600',
                                        color: variables.fgHeading,
                                    },
                                },
                            },
                            tooltip: {
                                show: false,
                            },
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1),
                                },
                            },
                            hoverAnimation: true,
                        },
                        {
                            value: this.value[1],
                            name: ' ',
                            tooltip: {
                                show: false,
                            },
                            label: {
                                normal: {
                                    position: 'inner',
                                },
                            },
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: visitorsPie.secondPieGradientLeft,
                                        },
                                        {
                                            offset: 1,
                                            color: visitorsPie.secondPieGradientRight,
                                        },
                                    ]),
                                    shadowColor: visitorsPie.secondPieShadowColor,
                                    shadowBlur: 0,
                                    shadowOffsetX: visitorsPie.shadowOffsetX,
                                    shadowOffsetY: visitorsPie.shadowOffsetY,
                                },
                            },
                        },
                    ],
                },
            ],
        };
    }

    onChartInit(echart) {
        this.echartsIntance = echart;
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
