import { Component, OnInit } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';

@Component({
    templateUrl: './manage-discipline.component.html'
})
export class ManageDisciplineComponent implements OnInit {

  public selectedExercicies: string;

    constructor(
        public userService: UserService,
        private alertService: AlertService,
        private adminService: AdminService
        ) {

    }
    ngOnInit(): void { 
        
    }
    openAllListExercises(){
      if (!this.selectedExercicies) {
        this.alertService.error('Selecione o tipo de exercício');
        return;       
      }
    }
}
