<div *ngIf="!institutionConfig.edit" class="minhadiv">
    <label class="title ml-2">Criar instituição </label>

    <div class="mt-4">
        <p><input placeholder="Nome da instituição" [(ngModel)]="institutionName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
    </div>
    <div class="mt-2">
        <button (click)="createInstitution(institutionName)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
    </div>
</div>

<div *ngIf="institutionConfig.edit" class="minhadiv">
    <label class="title ml-2">Editar Instituição : {{institutionConfig.institution.name}} </label>

    <div class="mt-4">
        <p><input placeholder="Nome da instituição" [(ngModel)]="institutionName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
    </div>
    <div class="mt-2">
        <button (click)="updateInstitution(institutionName)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
    </div>
</div>