<div class="page-container p-pg page-container-mobile mock-list" [ngClass]="{'mt-120':layoutService.isMobile}">
    <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
        <span class="page-title">RESULTADO</span>
        <div class="mt-3 description-title pr-15 pl-15">
            <h6 class="mb-0" *ngIf="layoutService.isMobile">Confira o seu desempenho</h6>
            <h4 *ngIf="!layoutService.isMobile">Confira o seu desempenho</h4>
            <p *ngIf="haveResolution" [ngClass]="{'fs-13 pb-0 mb-0': layoutService.isMobile}">Você pode visualizar a resolução clicando sobre a questão</p>
            <p *ngIf="!haveResolution" [ngClass]="{'fs-13 pb-0 mb-0': layoutService.isMobile}">Você pode visualizar a questão clicando sobre ela</p>
        </div>
    </div>
    <div class="lesson-back" *ngIf="isReport">
        <button routerLink="/performance/report" [ngClass]="{'width-50 text-left back-button': !layoutService.isMobile}" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
          <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
        </button>
    </div>
    <div class="lesson-back" *ngIf="!isReport">
        <button routerLink="../../list" [ngClass]="{'width-50 text-left back-button': !layoutService.isMobile}" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
          <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
        </button>
    </div>
    <div class="p-pg" *ngIf="!loading">
        <div class="mt-1 mb-3 pr-15 pl-15 text-color-primary">
            <div class="mat-stroked-button result-text" color="accent" *ngIf="userService.isStudent()">
                Você acertou <b>{{hits}}</b> de <b>{{questions.length}}</b>
            </div>
            <div class="mat-stroked-button result-text" color="accent" *ngIf="userService.isAdmin()">
                Acertos do aluno: <b>{{hits}}</b> de <b>{{questions.length}}</b>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" matTableResponsive multiTemplateDataRows class="width-100  pr-15 pl-15 background-none table-result">

            <ng-container matColumnDef="questionNumber">
                <th mat-header-cell *matHeaderCellDef class="brl-5 border-radius-table-left text-center pl-0"> Questão </th>
                <td mat-cell *matCellDef="let question; let i = index" class="bbn text-center" [ngClass]="{'table-line-contrast': i % 2}"><b>{{question.index + 1}}</b></td>
            </ng-container>

            <ng-container matColumnDef="studentResponse" class="text-center">
                <th mat-header-cell *matHeaderCellDef class="bbn text-center"> Selecionada </th>
                <td mat-cell *matCellDef="let question; let i = index" class="bbn text-center" [ngClass]="{'table-line-contrast': i % 2}">
                    <b title="ref: {{question.id}}" *ngIf="question.studentResponse">{{question.studentResponse | uppercase}}</b>
                    <b *ngIf="!question.studentResponse"> <span class="material-icons-outlined null">block</span></b>
                </td>
            </ng-container>

            <ng-container matColumnDef="correctResponse">
                <th mat-header-cell *matHeaderCellDef class="bbn text-center"> Correta </th>
                <td title="ref: {{question.questionId}}" mat-cell *matCellDef="let question; let i = index" class="bbn text-center" [ngClass]="{'table-line-contrast': i % 2}"><b>{{question.correctAlternative | uppercase}}</b></td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="brr-5 border-radius-table-right text-center pr-0"> Status </th>
                <td mat-cell *matCellDef="let question; let i = index" class="text-center bbn pr-0" [ngClass]="{'table-line-contrast': i % 2}">
                    <mat-icon class="correct" *ngIf="question.correctAlternative == question.studentResponse">
                        check_circle_outline
                    </mat-icon>
                    <mat-icon class="incorrect" *ngIf="question.correctAlternative != question.studentResponse">
                        highlight_off
                    </mat-icon>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let question; let i = index" [attr.colspan]="columnsToDisplay.length" class="bbn" [ngClass]="{'table-line-contrast': i % 2}">
                    <div class="example-element-detail" [@detailExpand]="question == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="m-4">
                            <h4 class="mb-3">Questão:</h4>

                            <div class="mb-2 question-html break-line-paragraph" [ngClass]="{'break-line-paragraph-mobile': layoutService.isMobile}" [innerHTML]="question.question"></div>

                            <div class="d-flex flex-row flex-nowrap" *ngFor="let alt of question.alternatives">
                                <!--    ***************** NÃO REMOVER O NGIF ABAIXO - VALIDA SE OPÇÃO NÃO FOR NULA *****************    -->
                                <div class="d-flex flex-row" *ngIf="alt.value">
                                    <div class="pr-3"><b class="alternative">{{alt.option}})</b></div>
                                    <div class="flex-grow-1" style="white-space: pre-line" [innerHTML]="alt.value"></div>
                                </div>
                            </div>
                            <ng-container *ngIf="question.comment">
                                <mat-divider></mat-divider>
                                <h4 class="mb-3 mt-4">Resolução:</h4>
                                <div class="mt-4 break-line-paragraph" [innerHTML]="question.comment"></div>
                            </ng-container>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [ngClass]="{'table-line-contrast': i % 2}" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>
    <div *ngIf="loading" class="text-center">
        <mat-spinner color="accent" class="center"></mat-spinner>
    </div>
</div>