import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { UserService } from '../../user/user.service';
import { Redaction } from '../redaction';
import { BuyCreditComponent } from '../buy-credit/buy-credit.component';
import { RedactionService } from '../redaction.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-sent-redactions',
    templateUrl: './sent-redactions.component.html',
    styleUrls: ['./sent-redactions.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0px', display: 'none'})),
            state('expanded', style({height: '*', minHeight: '30px'})),
            transition('collapsed => expanded', [
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ]),
            transition('expanded => collapsed', [
                style ({display: 'none'}),
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ]),
        ]),
    ]
})
export class SentRedactionsComponent implements OnInit, OnDestroy {

    redactions: Redaction[];
    redactionToView: Redaction;
    deleting: boolean;
    correction: any;
    mobileQuery: MediaQueryList;
    public showRedactions: boolean;
    private dialog: MatDialog;
    private mobileQueryListener: () => void;

    public loading: boolean;
    public dataSource: MatTableDataSource<Redaction>;
    public displayedColumnsMobile: string[];
    public displayedColumnsDesk: string[];
    public displayedColumns: string[];
    public lottieOptions: AnimationOptions;

    public API = environment.ApiUrl;
    public BaseUrl = environment.baseUrl;
    public duplicatedEssay: boolean;
    public removeDuplicatedEssay: number;

    constructor(
        private route: ActivatedRoute,
        private redactionService: RedactionService,
        private alertService: AlertService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        private domSanitizer: DomSanitizer,
        public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.showRedactions = true;
        //this.displayedColumnsMobile = ['STATUS', 'CANCEL'];
        //this.displayedColumnsDesk =['status', 'score', 'sent', 'open', 'cancel'];
        if(this.layoutService.isMobile){
            this.displayedColumns = ['status', 'cancel'];
        }else if(!this.layoutService.isMobile){
            this.displayedColumns =['status', 'score', 'sent', 'open', 'cancel'];
        }
        const idRedaction: number = +this.route.snapshot.queryParamMap.get('id');
        this.getSentRedactions(this.userService.getUserId(), idRedaction);
        this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
        this.mobileQueryListener = () =>
            this.changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this.mobileQueryListener);
        this.emptyRedactionsLottieOptionsConfig();
    }


    selectRedaction(redaction: Redaction) {
        window.open(`${this.API}/essays/rdo?html=${redaction.id}`, '_blank');
        //http://localhost:8000/essays/rdo?html=123456
    }

    getRedadationById(redactionId: number) {
        return this.redactions.filter((redaction) => {
            return +redaction.id === redactionId;
        });
    }

    getSentRedactions(studentId: number, redactionId?: number) {
        this.loading = true;
        this.redactionService.getSentRedactions(studentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.redactions = response.data as Redaction[];
                    this.dataSource = new MatTableDataSource([...this.redactions]);

                    console.log(this.dataSource)
                    if (!!redactionId) {
                        const redaction = this.getRedadationById(redactionId)[0];
                        if (!!redaction) {
                            this.selectRedaction(redaction);
                        }
                    }
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => this.alertService.error('Houve um erro ao carregar as redações. Verifique a conexão e tente novamente'));
    }

    remove(redactionId: number) {
        if (confirm("Deseja realmente excluir esta redação?")) {
            this.deleting = true;
            this.redactionService.delSentRedactions(this.userService.getUserId(), redactionId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.redactions = [] ;
                        this.dataSource = {} as MatTableDataSource<Redaction>  ;
                        this.getSentRedactions(this.userService.getUserId());
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.deleting = false;

                }, err => {
                    this.alertService.error('Houve um erro ao excluir a redação. Verifique a conexão e tente novamente');
                    this.deleting = false;
                });
        }
    }

    get isMobile() {
        return this.mobileQuery.matches;
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this.mobileQueryListener);
    }

    innerHTMLBypass(value: any) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    }
    emptyRedactionsLottieOptionsConfig() {
        this.lottieOptions = {
          path: 'assets/lotties/empty.json',
          loop: true,
          autoplay: true
        };
    }

}
