import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { VideoRepository } from 'src/app/modules/repository/video/video-repository';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

 
import { Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from '../admin.service';
import { SchoolService } from '../school/school.service';
import { School } from '../school/school';
import { Unit } from '../school/unit';
import { Class } from '../school/class';
 
 
@Component({
  selector: 'app-rdo-manager',
  templateUrl: './rdo-manager.component.html',
  styleUrls: ['./rdo-manager.component.scss']
})
export class RdoManagerComponent implements OnInit {

  public videoForm: FormGroup;  
  public showList: boolean;
  public teacherSelectGroup: any;
  public videos: VideoRepository[];
  public saving: boolean;
  public filterFolder: number;
  public loading: boolean;
  public editMode: boolean; 

  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();
  schools: any[];
  units: Unit[];
  classes: any[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public video: VideoRepository,
    //@Optional() public dialogRef: MatDialogRef<RepositoryVideoAddComponent>,
    private dialog: MatDialog, 
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public userService: UserService,
    private adminService: AdminService,
    private schoolService: SchoolService
    ){ }

    ngOnInit(): void {
      this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
    }
  
    ngOnDestroy(): void {
      this.admUpdate$.unsubscribe();
    } 

    init() {
      this.teacherSelectGroup = [
        { name: 'Pastas do professor', options: [] },
        { name: 'Pastas da turma', options: [] }
      ];
      /* this.getFolders(this.userService.getUserSchool()); */
      this.profileInit();
      this.videoForm = this.formBuilder.group({
        id: [],
        name: ['', Validators.required],
        folder: ['', Validators.required],
        link: ['', Validators.required],
        tag: ['', Validators.required],
        external: ['', Validators.required]
      });
      if (this.video) { this.initializeEditMode(); }
    }
    profileInit() {

      if (this.userService.isAdmin()) {
        this.getSchools();
  
      } else if (this.userService.isGeneralCoordinator()) {
        this.getUnits(this.userService.getUserSchool());
  
      } else if (this.userService.isUnitCoordinator()) {
        this.getClasses(this.userService.getUserUnit());
  
      } else if (this.userService.isTeacher()) {
        //this.getTeacherFolders(this.userService.getUserId());
  
        if (this.userService.teacherHavePermission(4)) {
          this.getUnits(this.userService.getUserSchool());
  
        } else if (this.userService.teacherHavePermission(3)) {
          this.getClasses(this.userService.getUserUnit());
        }
      }
    }
  
    initializeEditMode() {
      this.editMode = true;
      this.videoForm.patchValue(this.video);
    }
  
  

    getSchools() {
      this.schoolService.getSchools()
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.schools = response.data as School[];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }
  
    getUnits(schoolId: number) {
      this.schoolService.getUnits(schoolId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.units = response.data as Unit[];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
    }
  
    getClasses(unitId: number) {
      this.schoolService.getClasses(unitId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.classes = response.data as Class[];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

}
