import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { UnitSelect } from '../../school/unit';

@Component({
  selector: 'app-select-unit',
  templateUrl: './select-unit.component.html',
  styleUrls: ['./select-unit.component.scss']
})

export class SelectUnitComponent implements OnInit {
  public loading: boolean;
  public selectedUnits: UnitSelect[] = [];
  public schools: School[];
  public units: UnitSelect[];

  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<SelectUnitComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    this.getUnits(Number(this.userService.getUserSchool()));
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId?: number) {
    this.schoolService.getUnitsSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.units = response.data as UnitSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
  }

  addUnitChip(unit: UnitSelect) {
    let unitSelected = {id:unit.id, name:unit.name};
    const index = this.selectedUnits.indexOf(unitSelected);
    if (index < 0) {
      this.selectedUnits.push(unitSelected);
    }
  }

  removeUnitChip(unit: UnitSelect) {
    const index = this.selectedUnits.indexOf(unit);
    if (index >= 0) {
      this.selectedUnits.splice(index, 1);
    }
  }

  add(){
    this.dialogRef.close(this.selectedUnits);
  }

}