import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  private icons: { name: string, file: string }[] = [
    { name: 'question', file: 'question.svg' },
    { name: 'protest', file: 'protest.svg' },
    { name: 'books', file: 'books.svg' },
    { name: 'statistics', file: 'statistics.svg' },
    { name: 'desktop', file: 'desktop.svg' },
    { name: 'checklist', file: 'checklist.svg' },
    { name: 'archive', file: 'archive.svg' },
    { name: 'book_reader', file: 'book_reader.svg' },
    { name: 'book', file: 'book.svg' },
    { name: 'check', file: 'check.svg' },
    { name: 'clipboard', file: 'clipboard.svg' },
    { name: 'headphones', file: 'headphones.svg' },
    { name: 'library', file: 'library.svg' },
    { name: 'pen', file: 'pen.svg' },
    { name: 'task', file: 'task.svg' },
    { name: 'calendar', file: 'calendar.svg' },
    { name: 'checked', file: 'checked.svg' },
    { name: 'unchecked', file: 'unchecked.svg' },
    { name: 'opened_folder', file: 'opened-folder.svg' },
    { name: 'average', file: 'average.svg' },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.icons.forEach(icon => this.registerIcon(icon));
  }

  registerIcon(icon: { name: string, file: string }) {
    this.matIconRegistry.addSvgIcon(
      icon.name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon.file}`)
    );
  }
}