<div mat-dialog-title class="title center text-center mb-5 mat-dialog-title">Adicionar Aula</div>
<div mat-dialog-content class="pt-3 pb-5 add-lesson-dialog">

    <div class="row">
        <div class="col-md-6">

            <!-- select content lesson type -->
            <div class="row">
                <div class="col-md">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-label>Tipo de conteúdo da aula</mat-label>
                        <mat-select [(ngModel)]="contentType" (selectionChange)="selectedContentType($event.value)">
                            <mat-option value="video">Vídeo</mat-option>
                            <mat-option value="slideFile">Arquivo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- search videos to add -->
            <ng-container *ngIf="typeVideo">
                <div class="row" *ngIf="!loadingDisciplines">
                    <div *ngIf="userService.isAdmin()" class="col-md-6">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-label>Escola</mat-label>
                            <mat-select [(ngModel)]="schoolId" (selectionChange)="getSchoolDisciplines($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="disciplines">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-label>Disciplina</mat-label>
                            <mat-select [(ngModel)]="disciplineId" (selectionChange)="getDisciplineVideos($event.value)">
                                <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                    {{discipline.name}}
                                </mat-option>
                                <mat-optgroup *ngIf="!disciplines">Nenhuma Disciplina</mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="disciplines">
                        <mat-form-field appearance="outline" class="width-100" *ngIf="!userService.isTeacher()">
                            <input #filterVideoName matInput placeholder="Buscar video">
                            <button (click)="filterVideoByName(filterVideoName.value , schoolId)" class="right" mat-icon-button matSuffix color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar">
                <mat-icon>search</mat-icon>
              </button>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="loadingDisciplines" class="text-center">
                    <mat-spinner [diameter]="30" class="center"></mat-spinner>
                </div>
                <nb-card size="medium" *ngIf="videos && !loading">
                    <nb-card-header *ngIf="disciplineId != 0">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field class="width-100">
                                    <input matInput placeholder="filtrar" #filter>
                                </mat-form-field>
                            </div>
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <mat-list class="width-90 mt-3 center">
                            <ng-container *ngFor="let video of videos">
                                <div mat-list-item class="row" style="min-height: 60px" *ngIf="verifyFilter(video.title, filter?.value)">
                                    <div class="col align-middle">
                                        {{video.title}}
                                    </div>
                                    <div class="col-2 text-right align-middle">
                                                        <button mat-icon-button (click)="addContent(video)" color="accent">
                                      <mat-icon>add</mat-icon>
                                    </button>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-list>
                    </nb-card-body>
                </nb-card>
            </ng-container>

            <!-- search files to add -->
            <ng-container *ngIf="typeSlideFile">
                <div class="row" *ngIf="!loadingDisciplines">
                    <div *ngIf="userService.isAdmin()" class="col-md-6">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-label>Escola</mat-label>
                            <mat-select [(ngModel)]="schoolId" (selectionChange)="getSchoolDisciplines($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-label>Disciplina</mat-label>
                            <mat-select [(ngModel)]="disciplineId" (selectionChange)="getDisciplineSlideFiles($event.value)">
                                <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                    {{discipline.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="loadingDisciplines" class="text-center">
                    <mat-spinner [diameter]="30" class="center"></mat-spinner>
                </div>
                <nb-card size="medium" *ngIf="slideFiles && !loading">
                    <nb-card-header>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field class="width-100">
                                    <input matInput placeholder="filtrar" #filter>
                                </mat-form-field>
                            </div>
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <mat-list class="width-90 mt-3 center">
                            <ng-container *ngFor="let slideFile of slideFiles">
                                <div mat-list-item class="row" style="min-height: 60px" *ngIf="verifyFilter(slideFile.title, filter?.value)">
                                    <div class="col align-middle">
                                        {{slideFile.name}}
                                    </div>
                                    <div class="col-2 text-right align-middle">
                                        <button mat-icon-button (click)="addContent(slideFile)" color="accent">
                                      <mat-icon>add</mat-icon>
                                    </button>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-list>
                    </nb-card-body>
                </nb-card>
            </ng-container>

            <div *ngIf="loading">
                <mat-spinner class="center"></mat-spinner>
            </div>

        </div>
        <div class="col-md-6" *ngIf="!loadingDisciplines">
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" [ngClass]="{'slideFile bg-color-accent': slideFileService.instanceOfSlideFile(content)}" *ngFor="let content of dayContent; let i = index" cdkDrag>
                    <span>{{i + 1}}º -
            <mat-icon *ngIf="videoService.instanceOfVideo(content)" color="primary">play_circle_outline</mat-icon>
            <mat-icon *ngIf="slideFileService.instanceOfSlideFile(content)" color="primary">picture_as_pdf</mat-icon>
            {{content?.title}} {{content.name}}
          </span>
                    <span class="delete">
            <button mat-icon-button (click)="remove(i)" color="warn" *ngIf="teacherOwnerDiscipline(content)">
              <mat-icon>delete</mat-icon>
            </button>
          </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="text-center">
    <mat-divider class="mt-3 mb-3"></mat-divider>
    <button *ngIf="!saving" (click)="finalize()" class="center" mat-fab color="accent" [disabled]="disableSave">
    <mat-icon>save</mat-icon>
  </button>
    <mat-spinner *ngIf="saving" [diameter]="35" class="center">
    </mat-spinner>
</div>