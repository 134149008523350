// tslint:disable: triple-equals
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../repository.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Material } from 'src/app/modules/admin/repository/material/material';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { School } from 'src/app/modules/admin/school/school';
import { Unit } from 'src/app/modules/admin/school/unit';
import { Class } from 'src/app/modules/admin/school/class';
import { SchoolService } from 'src/app/modules/admin/school/school.service';
import { AnimationOptions } from 'ngx-lottie';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
    selector: 'app-material-list',
    templateUrl: './material-list.component.html',
    styleUrls: ['./material-list.component.scss']
})
export class MaterialListComponent implements OnInit {

    public materialTypeAdm = false;
    public materials: Material[];
    public displayedColumns: string[];
    public dataSource: MatTableDataSource<Material>;

    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public schoolId: number;
    public unitId: number;
    public classId: number;
    public examType: boolean;
    public loading: boolean;
    public hasExamMaterials: boolean;

    public lottieOptions: AnimationOptions;


    constructor(
        private repositoryService: RepositoryService,
        public userService: UserService,
        private alertService: AlertService,
        private schoolService: SchoolService,
        public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.emptyLottieOptionsConfig();
        this.displayedColumns = [
            'date',
            'teacher',
            'observation',
            'applyDate',
            'type',
            'file'
        ];
        this.profileInit();
    }

    profileInit() {

        if (this.userService.isAdmin()) {
            this.getSchools();
        }

        if (this.userService.isGeneralCoordinator()) {
            this.getUnits(this.userService.getUserSchool());
        }

        if (this.userService.isUnitCoordinator()) {
            this.getClasses(this.userService.getUserUnit());
            this.unitId = this.userService.getUserUnit();
        }

        if (this.userService.isTeacher() || this.userService.isStudent()) {
            if (this.userService.isTeacher()) {

                this.getTeacherClasses(this.userService.getUserId());
                //this.getUnits(this.userService.getUserSchool());
                this.getSchoolsProfile();
            }
            this.getUserMaterials(this.userService.getUserId(), this.materialTypeAdm);
            this.setHasExamMaterials(this.userService.getUserId());
        }
    }

    getMaterialsAdmin() {
        if (this.materialTypeAdm) {
            this.getUserMaterials(this.userService.getUserId(), true);
        }
    }

    getMaterials(examType: boolean, isUnit?: number) {
        if (!examType && this.userService.isStudent()) {
            this.getUserMaterials(this.userService.getUserId(), this.materialTypeAdm);
        } else
            if (examType && this.userService.isStudent()) {
                this.getExamMaterials(this.userService.getUserId());
            } else
                if (examType && !this.userService.isStudent()) {

                    this.getUnitExamsMaterials(this.unitId);
                } else {
                    if (this.userService.isTeacher()) {

                        this.getUnitMaterials(this.unitId, true, isUnit);
                    } else {
                        this.getUnitMaterials(this.unitId, this.materialTypeAdm, 0);
                    }

                }
    }

    getUserMaterials(userId: number, admin: boolean) {
        this.loading = true;
        this.repositoryService.getMaterials(userId, admin)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.materials = response.data as Material[];
                    this.dataSource = new MatTableDataSource(this.materials);
                    this.dataSource.filterPredicate = (data, filter: string): boolean => {
                        return (data.classId.toString() === filter.toString());
                    };
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar os materiais. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getSchoolsProfile() {
        let data = {
            userId: this.userService.getUserId()
        };
        this.schoolService.getSchoolsProfile(data)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getUnitsProfile() {
        let data = {
            userId: this.userService.getUserId()
        };
        this.schoolService.getUnitsProfile(data)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getClassesProfile() {
        let data = {
            userId: this.userService.getUserId()
        };
        this.schoolService.getClassesProfile(data)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];

                    this.getMaterials(this.examType);
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];

                    this.getMaterials(this.examType, 1);
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getTeacherClasses(teacherId: number) {

        this.schoolService.getTeacherClasses(teacherId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
    }

    openMaterial(url: string) {
        window.open(url, '_blank');
    }

    applyFilter(classId: string) {
        this.dataSource.filter = classId;
    }

    setHasExamMaterials(userId: number) {
        this.loading = true;
        this.repositoryService.getExamMaterials(userId)
            .subscribe((exams: Material[]) => {
                if (!!exams && exams.length >= 1) {
                    this.hasExamMaterials = true;
                } else {
                    this.hasExamMaterials = false;
                }
                this.loading = false;
            }, err => this.loading = false);
    }

    getExamMaterials(userId: number) {
        this.loading = true;
        this.repositoryService.getExamMaterials(userId)
            .subscribe((exams: Material[]) => {
                this.materials = exams;
                this.dataSource = new MatTableDataSource(this.materials);
                this.dataSource.filterPredicate = (data, filter: string): boolean => {
                    return (data.classId.toString() === filter.toString());
                };
                this.loading = false;
            }, err => this.loading = false);
    }

    getUnitMaterials(unitId: number, admin: boolean, exam?: number) {
        this.loading = true;
        let userId = this.userService.getUserId();
        this.repositoryService.getUnitMaterials(unitId, userId, admin, exam)
            .subscribe((exams: Material[]) => {
                this.materials = exams;
                this.dataSource = new MatTableDataSource(this.materials);
                this.dataSource.filterPredicate = (data, filter: string): boolean => {
                    return (data.classId.toString() === filter.toString());
                };
                this.loading = false;
            }, err => this.loading = false);
    }

    getUnitExamsMaterials(unitId: number) {
        this.loading = true;
        let userId = this.userService.getUserId();
        this.repositoryService.getUnitExamsMaterials(unitId, userId)
            .subscribe((exams: Material[]) => {
                this.materials = exams;
                this.dataSource = new MatTableDataSource(this.materials);
                this.dataSource.filterPredicate = (data, filter: string): boolean => {
                    return (data.classId.toString() === filter.toString());
                };
                this.loading = false;
            }, err => this.loading = false);
    }

    materialTypeChange() {
        if (this.unitId) {
            this.getMaterials(this.examType);
        }
    }

    emptyLottieOptionsConfig() {
        this.lottieOptions = {
            path: 'assets/lotties/empty.json',
            loop: true,
            autoplay: true
        };
    }

}
