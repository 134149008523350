<!--<h4 *ngIf="!editMode && !showList">Criar Tema de disciplina</h4>-->
<h4 *ngIf="editMode && !showList">Editar Tema de disciplina</h4>

<!-- selects -->
<div class="row"  *ngIf="userService.isAdmin()">
    <div class="col-7">
        <mat-form-field class="w-100">
            <mat-select placeholder="Selecione a escola" [(ngModel)]="selectedSchool" (selectionChange)="selectScholl()">
                <mat-option *ngFor="let school of schools" [value]="school.id">
                    {{school.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-5 d-flex align-items-center">
        <div class="row d-flex align-items-center w-100">
            <mat-slide-toggle [(ngModel)]="schoolOnly" color="primary" class="h-100 col-2 p-0 mt-1"></mat-slide-toggle>
            <mat-icon matTooltipClass="custom-tooltip" matTooltip="As disciplinas so sao cadastradas por escolas" class="col-2 p-0">
                help
            </mat-icon>
            <span class="col-7 text-wrap p-0">Tema por escola</span>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-6" *ngIf="!schoolOnly">
        <mat-form-field class="w-100">
            <mat-select placeholder="Selecione os planos" [(ngModel)]="selectedPlan" (selectionChange)="getDisciplinesByPlan($event.value)">
                <mat-option [value]="0">
                    Nenhum
                </mat-option>
                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                    {{plan.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-6" *ngIf="!schoolOnly">
        <mat-form-field class="w-100">
            <mat-select (selectionChange)="selectDiscipline($event.value)" [(ngModel)]="selectedDiscipline" placeholder="Selecione as disciplinas">
                <mat-option [value]="0">
                    Nenhum
                </mat-option>
                <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                    {{discipline.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<!-- input name -->
<div class="row" *ngIf="!showList">
    <div class="mt-3 w-100">
        <div class="col-md-12">
            <mat-form-field class="w-100">
                <input matInput placeholder="Nome Tema" [(ngModel)]="tagName">
            </mat-form-field>
        </div>
    </div>
</div>

<!-- botões -->
<div *ngIf="!showList && !editMode" class="row mt-5">
    <div class=" col-6 width-100">
        <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver temas
        cadastrados</button>
    </div>
    <div class=" col-6 text-right width-100">
        <button (click)="checkCreate()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Cadastrar</button>
    </div>
</div>
<div *ngIf="!showList && editMode" class="row mt-5">
    <div class=" col-md-12 text-right width-100">
        <button (click)="checkDisciplineUpdate(tagName)" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Atualizar</button>
    </div>
</div>

<!-- tags list -->
<div *ngIf="showList" class="mt-3">
    <nb-list>
        <nb-list-item *ngFor="let tag of disciplinesTags">
            <div class="row">
                <div class="col-md-9">
                    {{tag.tag_name}}
                </div>
                <div class="col-md-1 text-right ">
                    <button (click)="openEditMode(tag)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-1 ml-4 text-right">
                    <button (click)="deleteDisciplineTag(tag.id)" size="tiny" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>

    <div class="text-right mt-4 width-100">
        <button (click)="backButton()" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>