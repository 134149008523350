<div class="mt-3 mb-3" >
    <form [formGroup]="formGenerateReport">
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" (selectionChange)="getPlans($event.value)"
                        placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="plan" placeholder="Plano" 
                        (selectionChange)="getSimulated($event.value)">
                        <!-- <mat-option value="0">Todas</mat-option> -->
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select  formControlName="simulated" placeholder="Simulado"
                        (selectionChange)="getDisciplinesSimulated($event.value)" >
                        <mat-option *ngFor="let simulate of simulated" [value]="simulate.id">
                        {{simulate.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select  formControlName="discipline" (selectionChange)="getReports()"  placeholder="Disciplinas">
                        <mat-option *ngFor="let discipline of mockDisciplines" [value]="discipline.idDiscipline">
                        {{discipline.nome_disciplina}} 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <button (click)="downloadReport()" class="right" nbButton size="small"
                    status="primary" nbSpinnerStatus="warning" class="right bg-color-primary border-primary">Gerar Relatório</button>
            </div>
        </div>
    </form>
</div>