<div class="col-12 w-100 mt-2 text-left" *ngIf="dataSource?.data?.length">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
        <!-- # Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> # </th>
          <td mat-cell *matCellDef="let student; let i = index"> {{i+1}} </td>
        </ng-container>
        <!-- Student Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Aluno </th>
            <td mat-cell *matCellDef="let student; let i = index" class="limit-text"> {{student.name}} </td>
          </ng-container>

        <!-- Engagement Column -->
        <ng-container matColumnDef="engagement">
        <th mat-header-cell *matHeaderCellDef> Engajamento </th>
        <td mat-cell *matCellDef="let student; let i = index">
            {{student.engagement | number}} acessos
        </td>
        </ng-container>

        <!-- Data Column -->
        <ng-container matColumnDef="lastAcess" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> Último acesso </th>
            <td mat-cell *matCellDef="let student; let i = index">
              {{student.lastAcess | date:'dd/MM/yyyy'}} - {{student.lastAcess | date:'HH:mm'}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<!-- <mat-spinner *ngIf="!dataSource?.data?.length" class="center  mb-5" color="accent"></mat-spinner> -->
