import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
 
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 
import { ResponseApi } from 'src/app/core/models/response-api';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../admin.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Subscription } from 'rxjs';
import { School } from '../../school/school';
import { Unit } from '../../school/unit';
import { SchoolService } from '../../school/school.service';
import { SyncService } from '../sync.service';
@Component({
  selector: 'app-unit-sync',
  templateUrl: './unit-sync.component.html',
  styleUrls: ['./unit-sync.component.scss']
})

 
export class UnitSyncComponent implements OnInit {

 
    public createUnitForm: FormGroup;
    public showUnitsList: boolean;
    public schools: any;
    public units: any;
    public editMode: boolean = false;
    public loading: boolean;
    public filterSchools: School[];
    public filterSchoolId: number;
    private admUpdate$: Subscription;
    public synchronized: boolean = true;
    public unsynchronized: boolean = false; 
    public  syncUnit: any;  
    public  syncClasses: any;
    public  classes: any;
    public  removeMode: boolean = false;
    public syncProgress: any;
    public unitsSync: any = [];
    public unitsUnSync : any = [];
    public actualSchoolId : number;
    public totalTime : number  = 0;
  
  
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public unit: Unit,
        @Optional() public dialogRef: MatDialogRef<UnitSyncComponent>,
        private dialog: MatDialog,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formbBuilder: FormBuilder,
        private adminService: AdminService,
        public userService: UserService,
        public syncService: SyncService) { }
  
    ngOnInit(): void {
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
            this.syncProgress = { text: "Concluído 100%", progress: 0, total:0} ;
    }
  
    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }
  
    init() {
        this.createUnitForm = this.formbBuilder.group({
            id: [],
            name: ['', Validators.required],
            tag: ['', Validators.required],
            school: ['', Validators.required]
        });
        this.getSchools();
        if (this.unit) { this.initializeEditMode(); }
        this.coordinatorInit();
    }
  
    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            this.createUnitForm.get('school').setValue(this.userService.getUserSchool());
        }
    }
  
    getSchools() {
        this.loading = true;
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;
  
                if (!response.error) {
                    this.schools = response.data as School[];
                    this.filterSchools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
  
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }
  
    getUnits() {
        this.loading = true;
        const schoolId = this.userService.isGeneralCoordinator() ? this.userService.getUserSchool() : undefined;
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
  
                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
  
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente');
            });
    }
  
    getUnitOne(unitId: number){
        this.schoolService.getUnitOne(unitId)
          .subscribe(res => {
            const response = res.body as ResponseApi;
    
            if (!response.error) {
              this.unit = response.data as Unit;
              this.editUnit(this.unit);
            } else {
              this.alertService.error(response.error);
            }
          }, err => this.alertService.error('Houve um erro ao contar os alunos. Verifique a conexão e tente novamente'));
    }
  
    openList() {
        if (!this.userService.isAdmin()) {
            this.getUnits();
        }
        this.showUnitsList = true;
    }
  
    createUnit() {
        if (this.createUnitForm.valid) {
            this.loading = true;
            const unit = this.createUnitForm.getRawValue() as Unit;
  
            this.schoolService.creteUnit(unit)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
  
                    if (!response.error) {
                        this.alertService.success('Unidade cadastrada', 3);
                        this.createUnitForm.reset();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
  
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao criar a unidade. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente');
        }
    }
  
    duplicateUnit() {
        if (this.createUnitForm.valid) {
            this.loading = true;
            const unit = this.createUnitForm.getRawValue() as Unit;
            unit.name = unit.name + " (duplicado)";
            this.schoolService.creteUnit(unit)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
  
                    if (!response.error) {
                        this.alertService.success('Unidade duplicada', 3);
                        this.createUnitForm.reset();
                        this.dialogRef.close(true);
                        this.getUnits();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
  
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao duplicar a unidade. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente');
        }
    }
  
    editUnit(unit: Unit) {
        const dialogRef = this.dialog.open(UnitSyncComponent, {
            minWidth: '60vw',
            data: unit
        });
  
        dialogRef.afterClosed().subscribe(result => {
  
            if (result) {
                this.getUnits();
            }
        });
    }
  
    removeUnit(unitId: number) {
        if (confirm('Deseja realmente remover a Unidade?')) {
            this.schoolService.removeUnit(unitId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
  
                    if (!response.error) {
                        this.alertService.success('Unidade removida', 3);
                        if (this.userService.isAdmin()) {
                            this.getSchoolUnits(this.filterSchoolId);
                        } else {
                            this.getUnits();
                        }
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover a unidade. Verifique a conexão e tente novamente'));
        }
    }
  
    // only edit mode methods
  
    initializeEditMode() {
        this.editMode = true;
        this.createUnitForm.patchValue(this.unit);
        this.createUnitForm.get('school').setValue(this.unit.school);
    }
  
    saveChanges() {
        if (this.createUnitForm.valid) {
  
            this.loading = true;
            const unit = this.createUnitForm.getRawValue() as Unit;
            this.schoolService.saveUnitChanges(unit)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
  
                    if (!response.error) {
                        this.alertService.success('Unidade editada com sucesso', 3);
                        this.dialogRef.close(true);
                        this.getUnits();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
  
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao salvar as alterações da unidade. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente!');
        }
    }
  
    onNoClick(): void {
        this.dialogRef.close();
    }
  
    getSchoolUnits(schoolId: number) {
        this.loading = true;
        this.actualSchoolId = schoolId;
        this.syncService.getApiUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
  
                if (!response.error) {
                    this.units = response.data ;
                    this.unitsSync=[];
                    this.unitsUnSync =[];

                    this.units.forEach(element => {
                        if(element.classSync > 0){
                            this.unitsSync.push(element);
                        }
                        if(element.classNotSync > 0){
                            this.unitsUnSync.push(element);
                        }
                    });
                  
                } else {
                    this.units = [];
                    this.unitsSync=[];
                    this.unitsUnSync =[];
                    this.alertService.error(response.error);
                }
  
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    } 

    getClassesSync(unitId: any){

        this.loading = true; 
        this.syncService.getApiClass(unitId.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data; 
                    
                    this.syncMode(unitId);
                } else {
                    this.classes = [];
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }
    getClassesUnSync(unitId: any){

        this.loading = true; 
        this.syncService.getApiClass(unitId.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data; 
                    
                    this.unSyncMode(unitId);
                } else {
               
                    this.classes = [];
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    syncMode(unit:any){
        this.editMode = true;
        this.syncUnit = unit;
        this.syncClasses = [];
        this.syncProgress = { text: "Parado 0%", progress: 0};
        this.totalTime = (this.classes.lenght * 300)/1000;
        
        this.classes.forEach(element => {
            if(element.sync == 0){  
                let syncClass = {
                    name: element.name,
                    unit_id: unit.externalUnitId ,
                    external_id: element.id,
                    unitId: element.unitId,
                    status: 0
                };
                this.syncClasses.push(syncClass);
            }
          
       }); 
        
    }

    unSyncMode(unit:any){
        this.removeMode = true;
        this.syncUnit = unit;
        this.syncClasses = [];
        this.syncProgress = { text: "Parado 0%", progress: 0};
        this.totalTime = (this.classes.lenght * 300)/1000;
        this.classes.forEach(element => {
            if(element.sync == 1){  
                let syncClass = {
                        name: element.name,
                        unit_id: unit.externalUnitId ,
                        external_id: element.id,
                        unitId: element.unitId,
                        status: 0
                    };
                this.syncClasses.push(syncClass);
            }
          
       }); 
    }
  
    syncClass(syncClasses:any){
    
        let timer = 300;
        let total = 0;
        let slice = 100/syncClasses.length;
        this.totalTime = (syncClasses.lenght * timer)/1000;
        syncClasses.forEach(element => { 

            setTimeout(() => {
                this.syncService.syncClass(element)
                .subscribe(res => {
                    const response = res.body as any;
    
                    if (!response.error) {
                        /* if(response.data.status == 200 || response.data.status == 204){
                            this.alertService.success('Sincronizado');
                        }else{
                            this.alertService.error('Já foi Sincronizado!');
                        } */
                        total+=slice;
                        this.syncProgressUpdateOne(Math.floor(total));
                        const index =  this.syncClasses.indexOf(element);

                        if (index >= 0) {
                            this.syncClasses.splice(index, 1);
                        }
                        
                    } else {
                        this.alertService.error(response.error);
                    }
    
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            }, timer+=300);
            
        });
    }
    unSyncClass(syncClasses:any){
        
        let timer = 300;
        let total = 0;
        let slice = 100/syncClasses.length;
        this.totalTime = (syncClasses.lenght * timer)/1000;  
        syncClasses.forEach(element => { 
            setTimeout(() => { 
            
                    this.syncService.unSyncClass(element.external_id)
                    .subscribe(res => {
                        const response = res.body  as any;

                        if (!response.error) {
                            /* if(response.data.status == 200 || response.data.status == 204){
                                this.alertService.success('Removido');
                            }else{
                                this.alertService.error('Já foi removido!');
                            } */
                          
                            total+=slice;
                            this.syncProgressUpdateOne(Math.floor(total));
                            const index =  this.syncClasses.indexOf(element);

                            if (index >= 0) {
                                this.syncClasses.splice(index, 1);
                            }
                        } else {
                            this.alertService.error(response.error);
                        }

                        this.loading = false;
                    }, err => {
                        this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                        this.loading = false;
                    });
            
            }, timer+=300 );
        });
    }
    
    async syncProgressUpdate(size:number){
        let timer = 400;
        let total = 0;
        let slice = 100/size;
        for (let index = 0; index < size; index++) { 
            setTimeout(() => {
                if(index < size){
                    this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
                }else{
                    this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
                }  
                total+=slice;
            }, timer+=400);
        }
         
    }
    async syncProgressUpdateOne(total:number){
    
        if(total < 100){
            this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
        }else{
            this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
        }   
         
    }

}
