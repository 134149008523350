<div class="container">
  <h6>Adicionar Planos</h6>
  <div class="row mb-3">
    <div class="col-md-4" *ngIf="userService.isAdmin()">
        <mat-form-field class="width-100">
            <mat-select (selectionChange)="getPlans($event.value)" placeholder="Escola">
                <mat-option *ngFor="let school of schools" [value]="school.id">
                  <span [title]="school.name">{{school.name}}</span> 
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="width-100">
          <mat-select  placeholder="Plano" #filterPlan  >
              <mat-option >Selecione um plano</mat-option>
              <mat-option *ngFor="let plan of plans" [value]="plan.id"  (click)="addPlanChip(plan)">
                <span [title]="plan.name">{{plan.name}}</span> 
              </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <div class=" col-6 text-right width-100">
        <button (click)="add()" class="right"   nbButton size="small" status="primary">Cadastrar</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
        <mat-chip-list *ngIf="selectedPlans">
            <mat-chip *ngFor="let planChip of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(planChip)">
                {{planChip.name}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
  </div>

</div>
