<div class="row">
    <div class="col-md-4" *ngIf="userService.isAdmin()">
        <mat-form-field class="width-100">
            <mat-select placeholder="Escola" [(ngModel)]="selectedSchool" (selectionChange)="getPlans($event.value)">
                <mat-option *ngFor="let school of schools" [value]="school.id">
                    {{school.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-4">
        <mat-form-field class="width-100">
            <mat-select placeholder="Planos" [(ngModel)]="selectedPlan" (selectionChange)="getDisciplines($event.value)">
                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                    {{plan.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-4">
        <mat-form-field class="width-100">
            <mat-select placeholder="Disciplina" [(ngModel)]="selectedDiscipline" (selectionChange)="getVideos($event.value)">
                <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                    {{discipline.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="selectedDiscipline" class="col-md-12 mt-2">
        <mat-form-field class="width-100">
            <input matInput placeholder="Buscar por nome" [(ngModel)]="videoName">
            <mat-icon matSuffix (click)="getVideosByName(selectedDiscipline , videoName)">search</mat-icon>
        </mat-form-field>
    </div>
</div>

<div class="mt-3 width-100">
    <nb-list *ngIf="videos">
        <nb-list-item>
            <div class="row">
                <div class="col-md-7">Nome</div>
                <div class="col-md-2">Likes</div>
                <div class="col-md-1">Deslikes</div>
            </div>
        </nb-list-item>
        <nb-list-item>
            <div class="row width-100">
                <div class="col-md-12">
                    <mat-expansion-panel *ngFor="let video of videos">
                        <mat-expansion-panel-header>
                            <div class="col-md-7">
                                <mat-icon>video_library</mat-icon>
                                <span>{{video.title}}</span>
                            </div>
                            <div class="col-md-2">
                                <span class="titles">{{video.likes}}</span>
                                <mat-icon class="likes" color="accent">thumb_up</mat-icon>
                            </div>
                            <div class="col-md-1">
                                <span class="titles">{{video.deslikes}}</span>
                                <mat-icon class="likes">thumb_down</mat-icon>
                            </div>
                        </mat-expansion-panel-header>
                        <div>
                            <iframe *ngIf="video?.embed.type.toLowerCase() == 'vimeo' && !transmissao" #player_container [src]="video.fullUrl" width="100%" height="460" frameborder="0" title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            <!-- <iframe *ngIf="video?.embed.type.toLowerCase() == 'youtube' && !transmissao" width="100%" height="460" [src]="videoUrl.youtube + video?.embed.code | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe> -->
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
</div>