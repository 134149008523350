import { StudentReport, Reports, ReportStudent } from './../reportStudent';
import { Student } from './../../student/student';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExportToCSV } from '@molteni/export-csv';
import { Workbook } from 'exceljs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { DisciplineService } from '../../discipline/discipline.service';
import { IntegrationsService } from '../../integrations/integrations.service';
import { SchoolService } from '../../school/school.service';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import * as fs from 'file-saver';
import { School } from '../../school/school';
import { Subscription } from 'rxjs';
import { StudyPlan } from '../../study-plan/study-plan';
import { Simulated } from 'src/app/core/models/simulated';
import { SummationReportService } from '../summation-report.service';
import { Class } from '../../school/class';
import { Unit } from '../../school/unit';
import { StudentService } from '../../student/student.service';

@Component({
  selector: 'app-summation-students',
  templateUrl: './summation-students.component.html',
  styleUrls: ['./summation-students.component.scss']
})
export class SummationStudentsComponent implements OnInit {

  public form: FormGroup;
  public workBook: Workbook;
  public reports: Reports[];
  public loading: boolean;
  public objectToExport: any[];
  public nameSchool: any;
  public nameClass: string;
  public schools: School[];
  public plans: StudyPlan[];
  private admUpdate$: Subscription;
  public simulated: Simulated[];
  public students: Student[];
  public school: number;
  public planId: number;
  public class: number;
  public studentsReport: StudentReport[];
  public units: Unit[];
  public classes: Class[];
  public studentName: string;


  constructor(private integrationsService: IntegrationsService,
    private schoolService: SchoolService,
    private alertService: AlertService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService,
    private exportTXT:ExportToCSV,
    private summationReportService: SummationReportService,
    private studentService: StudentService) { }

    ngOnInit() {
      this.admUpdate$ = this.adminService.getUpdateSubject()
        .subscribe(() => this.init());
    }
  
    init(){  
      this.form = this.formBuilder.group({
          school: ['', Validators.required],
          plan: ['', Validators.required],
          simulated: ['', Validators.required],
          unit: ['', Validators.required],
          class: ['', Validators.required],
          student: [''],
      });
      this.profileInit();
    }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.getSchools();
    } else if (this.userService.isGeneralCoordinator()) {
        //this.getUnits(this.userService.getUserSchool());
        const schoolId = this.userService.getUserSchool();
        this.school = this.userService.getUserSchool();
        this.form.get('school').setValue(schoolId);
        this.getPlans(this.userService.getUserSchool());
        this.getUnits(this.userService.getUserSchool());
    } else if (this.userService.isUnitCoordinator()) {
      this.getPlans(this.userService.getUserSchool());
      this.getClasses(this.userService.getUserUnit());
    }
  }

  downloadReport(){ 
    
    //const name = this.nameDiscipline.name;
    
    var txt = ".txt";

    //this.objectToExport = this.reports;
    
    const rows = this.objectToExport;
    
    
    let csvContent = "data:text/csv;charset=utf-8,";
    
    rows.forEach(function(rowArray) {
    
      let arr = Object.values(rowArray);
      let row = arr.join(" ");
      csvContent += row + "\r\n";  
    });
    var encodedUri = encodeURI(csvContent); 
    var a = document.createElement('a'); 
    a.href = encodedUri;
    a.download = name + txt;
    a.click(); 
    a.remove();
    this.loading = false;
  }

  generateExcelAll() {
      let workbook = new Workbook();

      let worksheet = workbook.addWorksheet("Employee Data");

      worksheet.addRow(["Relatório Simulado (Somatória)"]);

      worksheet.addRow([" "]);


      //let header =  this.reports.pop();
      let body =  this.reports as any[];

      //worksheet.addRow(header);
      body.forEach(element => {
          element.forEach(repo => {
            let row = Object.values(repo);
                 
            worksheet.addRow(row);
          });
          worksheet.addRow([" "]);
      });
     

      const rows = [];
      let fname = "Relatório Simulado (Somatória)"

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
      this.loading = false;
  }

  generateExcel() {
    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet("Employee Data");

    worksheet.addRow(["Relatório Simulado (Somatória)"]);

    worksheet.addRow([" "]);


    //let header =  this.reports.pop();

   // worksheet.addRow(header);
    this.reports.forEach(element => {
      let row = Object.values(element);
               
      worksheet.addRow(row);
    });
   

    const rows = [];
    let fname = "Relatório Simulado (Somatória)"

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
    this.loading = false;
  
  }
  generateExcelStudent(simulated:number, student:number) {
    this.summationReportService.getReportMockSomatoria(simulated, student)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.reports = response.data as Reports[];
          
          this.generateExcel();
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
          this.alertService.error('Houve um erro ao buscar o relatório. Verifique a conexão e tente novamente');
      });
  }

  generateExcelAllStudents(simulated:number, classId:number) {
    this.summationReportService.getReportMockClassUnicSomatoria(simulated, classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.reports = response.data as Reports[];
          this.generateExcelAll();
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
          this.alertService.error('Houve um erro ao buscar o relatório. Verifique a conexão e tente novamente');
      });
  }
  
  generateReportExcel(){
    const reportStudent = this.form.getRawValue() as ReportStudent;
    const studentId = reportStudent.student;
    if(studentId){
      this.generateExcelStudent(reportStudent.simulated, reportStudent.student);
    }else{
      const classId = reportStudent.class;
      this.generateExcelAllStudents(reportStudent.simulated, classId);
    }
  }
  
  
  getNameSchool(schoolId:number){        
    this.schoolService.getSchoolOne(schoolId)
    .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
            this.nameSchool = response.data;

        } else {
            this.alertService.error(response.error);
        }
    }, err => {
        this.alertService.error('Houve um erro ao buscar a escola. Verifique a conexão e tente novamente');
    });
  }
  getPlans(schoolId?: number) {
    this.school = schoolId;
    this.loading = true;
    this.getUnits(schoolId);
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
              this.units = response.data as Unit[];
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId: number) {
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
              this.classes = response.data as Class[];
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getStudents(classId: number) {
    this.studentService.getStudents(classId)
      .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
              this.students = response.data as Student[];
          } else {
              this.alertService.error(response.error);
          }
      }, err => this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente'));
  }

  getSimulated(planId:number){
    this.planId = planId;
    this.integrationsService.getSimulated(planId)
    .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
            this.simulated = response.data as Simulated[];
        } else {
            this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao buscar os simulados. Verifique a conexão e tente novamente'));
  }

  getSchools() {
    this.schoolService.getSchools()
        .subscribe(res => {
            const response = res.body as ResponseApi;
  
            if (!response.error) {
                this.schools = response.data as School[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

}
