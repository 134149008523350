<div *ngIf="!editMode" class="table-container table-container-discipline height-100">
  <table *ngIf="!loading && taskDataSource?.data?.length" class="table-discipline mb-3 height-100" mat-table [dataSource]="taskDataSource">
      <!-- Name -->
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="bg-color-orange background-orange border-bottom-none disciplina brl-5 pl-3"> ATIVIDADE </th>
          <td mat-cell *matCellDef="let task; let i = index" class="font-table font-gray text-left pl-3 mr-10px" [ngClass]="{'table-line-contrast': i % 2}">
              <span class="d-flex"><mat-icon class="fs-30 mt--3px" [hidden]="!layoutService.isMobile">arrow_right</mat-icon>{{task.taskName}}</span>
          </td>
      </ng-container>
      <!-- Teacher -->
      <ng-container matColumnDef="teacher">
          <th mat-header-cell *matHeaderCellDef class="bg-color-orange background-orange text-center"> PROFESSOR </th>
          <td mat-cell *matCellDef="let task; let i = index" class="text-center" [ngClass]="{'table-line-contrast': i % 2}">{{task.usuario_nome}} </td>
      </ng-container>
      <!-- Date -->
      <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="bg-color-orange background-orange text-center"> DATA </th>
          <td mat-cell *matCellDef="let task; let i = index" class="text-center" [ngClass]="{'table-line-contrast': i % 2}"> {{task.finalDate | date: 'dd/MM/yyyy'}} </td>
      </ng-container>
      <!-- Action -->
      <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef class="bg-color-orange background-orange border-none brr-5 d-block text-center min-w-95px bt-none" [ngClass]="{'text-center': !layoutService.isMobile}"> AÇÃO
          </th>
          <td mat-cell *matCellDef="let task; let i = index" class="d-block font-table min-w-95px" [ngClass]="{'table-line-contrast': i % 2}">
              <button mat-button class="vertical-align-baseline" color="accent" (click)="openTask(task)">
                  <strong class="font-table" color="accent">Enviar</strong>
              </button>
          </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="!tasks" class="empty-tasks text-center mt-5">
      <h6 class="mb-2">Nenhuma tarefa pendente!</h6>
      <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
  </div>
</div>

<div class="center dialog" *ngIf="editMode">
  <div *ngIf="!loading" class="row">
      <div class="col-md-6 pb-3">
          <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
              <span class="page-title">Enviar tarefa</span>
              <div class="mt-3 mb-3 description-title">
                  <h4 *ngIf="!layoutService.isMobile">{{task.taskName}}</h4>
                  <h6 class="mb-0" *ngIf="layoutService.isMobile">{{task.taskName}}</h6>
              </div>
          </div>

          <div>Prof. {{task.usuario_nome}}</div>
          <div>Turma {{task.className}}</div>
          <div> Data de entrega: {{task.finalDate | date: 'dd/MM/yyyy'}}</div>
          <div class="mt-3" *ngIf="task.taskLink">
              <a  mat-button (click)="openFile(task.taskLink)" color="accent">
                  <mat-icon>attach_file</mat-icon> Baixar arquivo do professor
              </a>
          </div>

          <form [formGroup]="formTask">
              <input #inputTask class="invisible" (change)="handleFileInput($event.target.files);" type="file">
          </form>

          <div *ngIf="!file && !taskText" class="send-task pointer text-center p-4" mat-ripple (click)="inputTask.click()">
              <mat-icon color="accent">file_upload</mat-icon> <br />
              <div class="strong">Envie sua tarefa</div>
              <p class="small">Atenção! Envie apenas arquivos PDF ou DOC.</p>
          </div>

          <div *ngIf="file && !taskText" class="send-task text-center text-color-accent p-4 d-flex justify-content-center">
              {{fileName}}
              <mat-icon class="hvr-buzz pointer" color="warn" (click)="clearUploadedFile(); inputTask.value = null">
                  delete</mat-icon>
          </div>

          <div *ngIf="!file && !taskText" class="text-center mt-3">
            <span class="fs-13">Ou se preferir utilize nosso </span>
            <a (click)="openWriteTask()" class="fs-13 text-color-accent fontweight_600 c-pointer">editor de redação
            </a>
          </div>

          <!-- Show Task Text after Student submits the task Text  -->
          <div class="text-center mt-2">
            <div *ngIf="!file && taskText" class="taskText">
                <span class="mt-1"><strong>{{task.taskName}}</strong></span>
                <br><br>
                <p class="mt-1" *ngFor="let paragraph of taskTextParagraphs">&emsp;{{paragraph}}</p>
            </div>
          </div>

          <!-- END Show Task Text after Student submits the task Text  -->

          <button *ngIf="!file && taskText"  (click)="editTaskText(taskText)" mat-raised-button color="accent" class="small-btn width-100 mt-3">
            Editar Tarefa
          </button>

          <button (click)="submitTask(task.taskId, task.taskName, task.usuario_nome, task.teacherId)" mat-raised-button color="accent" class="small-btn width-100 mt-3">
              Enviar
          </button>
      </div>
      <div class="col-md-6 word-break">
          <div class="teacher-observable p-3">
              <h5 class="mb-2">Observação do professor</h5>
              <br />
              <p>{{task.taskDescription}}</p>
          </div>
      </div>
  </div>
  <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
</div>
