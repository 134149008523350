<div class="page-container" [ngClass]="{'mat-card-15': layoutService.isMobile}">
    <div class="mb-3" [ngClass]="{'text-center width-80':layoutService.isMobile, 'width-100': !layoutService.isMobile}">
        <mat-card class="mb-3" *ngIf="activeMocks?.length">
            Simulados ativos
            <!-- Chose plan -->
            <nb-list class="width-80 center">
                <nb-list-item href="#" *ngFor="let activeMock of activeMocks">
                    <div class="row">
                        <div class="col plan-name" [ngClass]="{'col-10 text-left': layoutService.isMobile}">
                            <mat-icon class="mt-2 mr-3" color="accent">ballot</mat-icon>
                            <span class="text-color-primary">{{activeMock.name}}</span>
                        </div>
                        <div class="col text-right">
                            <button color="accent" mat-mini-fab class="hvr-grow" [matTooltip]="activeMock.name" (click)="selectAvailableMockToReply(activeMock)">
                                <mat-icon>arrow_right_alt</mat-icon>
                            </button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </mat-card>
        <!-- novo calendário -->
        <mat-card class="mb-3" *ngIf="planSelected">
            <div class="page-title-container" [ngClass]="{'width-90 dash-container pr-4 pl-4':!layoutService.isMobile, 'width-80 text-center': layoutService.isMobile}">
                <span class="page-title">Plano de estudo inteligente</span>
                <div class="mt-3 description-title" *ngIf="!layoutService.isMobile">
                    <h4 class="font-text">Olá, {{userService.getUserName() | shortName}}!</h4>
                    <h4 *ngIf="userService.isStudent()" class="text-color-accent">O que vamos estudar hoje?</h4>
                </div>
                <div class="mt-3 description-title" *ngIf="layoutService.isMobile">
                    <h6 class="student-name">Olá, {{userService.getUserName() | shortName}}!</h6>
                    <h6 *ngIf="userService.isStudent()" class="text-color-accent">O que vamos estudar hoje?</h6>
                </div>
            </div>
            <button color="accent" mat-mini-fab class="mt-1 mb-1 hvr-grow" (click)="clearSelectedPlan()" [ngClass]="{'back-btn': !planSelected.expireMonths}">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <!-- (Calendar v2) -->
            <ng-container *ngIf="planSelected.version == 2">
                <!-- editor de validade fixa-->
                <app-calendar *ngIf="userService.isStudent() || (!userService.isStudent() && !planSelected.expireMonths)"
                  [planId]="planSelected.id" [releaseCalendarVideosUpToday]="planSelected.releaseCalendarVideosUpToday" class="mt-5"></app-calendar>
                <!-- editor de dias validade por meses-->
                <app-add-sequence-lesson *ngIf="!userService.isStudent() && planSelected.expireMonths" [planId]="planSelected.id">
                </app-add-sequence-lesson>
            </ng-container>
            <!-- (Calendar v1) -->
            <app-study-plan-calendar *ngIf="planSelected.version == 1 && schedule" [schedule]="schedule" [planId]="planSelected.id" [releaseCalendarVideosUpToday]="planSelected.releaseCalendarVideosUpToday">
            </app-study-plan-calendar>
        </mat-card>
        <!-- planos -->
        <mat-card class="mb-3" *ngIf="!planSelected">
            <div class="page-title-container" [ngClass]="{'dash-container pr-4 pl-4':!layoutService.isMobile, 'width-80 text-center': layoutService.isMobile}">
                <span class="page-title">Plano de estudo inteligente</span>
                <div class="mt-3 description-title" *ngIf="!layoutService.isMobile">
                    <h4 class="font-text">Olá, {{userService.getUserName() | shortName}}!</h4>
                    <h4 *ngIf="userService.isStudent()" class="text-color-accent">O que vamos estudar hoje?</h4>
                </div>
                <div class="mt-3 description-title" *ngIf="layoutService.isMobile">
                    <h6 class="student-name">Olá, {{userService.getUserName() | shortName}}!</h6>
                    <h6 *ngIf="userService.isStudent()" class="text-color-accent">O que vamos estudar hoje?</h6>
                </div>
            </div>
            <ng-container *ngIf="!userService.isStudent()" #filters>
                <mat-form-field appearance="outline" *ngIf="userService.isAdmin()" class="width-100 mat-form-plan-list" [ngClass]="{'mr-3': !layoutService.isMobile}">
                    <mat-label>Escola</mat-label>
                    <mat-select (selectionChange)="getUnits($event.value) ; getPlansBySchool($event.value)" class="plan-list">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="mr-3">
                    <mat-label>Unidade</mat-label>
                    <mat-select (selectionChange)="getUnitPlans($event.value)" class="plan-list">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Selecione o tipo de exibição</mat-label>
                    <mat-select [(ngModel)]="viewType">
                      <mat-option value="carousel">Carrossel</mat-option>
                      <mat-option value="list">Listagem</mat-option>
                    </mat-select>
                  </mat-form-field>
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Filtrar planos</mat-label>
                    <input matInput placeholder="Digite o nome do plano" type="text" id="inputFilterPlan">
                </mat-form-field> -->
            </ng-container>
            <!-- listagem em carousel -->
            <!-- <carousel *ngIf="!userService.isStudent() && plans && !loadingPlans && filteredPlans" [height]="350" [cellWidth]="220" [margin]="0" arrowsTheme="accent">
                <ng-container *ngFor="let plan of filteredPlans | async">
                    <div class="carousel-cell p-2">
                        <app-study-plan-card [studyPlan]="plan" (selectedPlan)="selectPlan($event)">
                        </app-study-plan-card>
                    </div>
                </ng-container>
            </carousel> -->
            <!-- <div class="row" *ngIf="!userService.isStudent() && plans && !loadingPlans && filteredPlans">
                <div class="col-md-4 mb-3" *ngFor="let plan of filteredPlans | async">
                  <app-study-plan-card [studyPlan]="plan" (selectedPlan)="selectPlan($event)">
                  </app-study-plan-card>
                </div>
              </div> -->


              
              <!-- Exibe em carrossel se a opção for 'carousel' -->
              <div *ngIf="viewType === 'carousel'">
                <carousel *ngIf="!userService.isStudent() && plans && !loadingPlans && filteredPlans" [height]="350" [cellWidth]="220" [margin]="0" arrowsTheme="accent">
                  <ng-container *ngFor="let plan of filteredPlans | async">
                    <div class="carousel-cell p-2">
                      <app-study-plan-card [studyPlan]="plan" (selectedPlan)="selectPlan($event)">
                      </app-study-plan-card>
                    </div>
                  </ng-container>
                </carousel>
              </div>
              
              <!-- Exibe em listagem normal se a opção for 'list' -->
              <div *ngIf="viewType === 'list'" class="row">
                <div class="col-md-4 mb-3" *ngFor="let plan of filteredPlans | async">
                  <app-study-plan-card [studyPlan]="plan" (selectedPlan)="selectPlan($event)">
                  </app-study-plan-card>
                </div>
              </div>

            <!-- Chose plan -->
            <nb-list *ngIf="userService.isStudent() && !planSelected" class="width-80 center mt-3">
              <nb-list-item href="#" *ngFor="let studyPlan of plans" [ngClass]="{'no-padding': !studyPlan.disciplines.length && studyPlan.version == 1}">
                  <ng-container *ngIf="studyPlan.disciplines.length > 0 && studyPlan.showStudent || studyPlan.version == 2 && studyPlan.showStudent">
                      <div class="row">
                          <div class="col-7 plan-name" [ngClass]="{'col-9 text-left': layoutService.isMobile}">
                              <mat-icon *ngIf="!studyPlan.expired && !layoutService.isMobile" class="mr-3" color="accent">event
                              </mat-icon>
                              <mat-icon *ngIf="studyPlan.expired && !layoutService.isMobile" class="mr-3">event_busy</mat-icon>
                              <span>{{studyPlan.name}}</span>
                          </div>
                          <div class="col-5">
                            <div class="row">
                              <div class="col-8 plan-expire" *ngIf="!layoutService.isMobile">
                                  <ng-container *ngIf="studyPlan.expireDate">
                                      Válido até {{studyPlan.expireDate | date:'dd/MM/y'}}
                                  </ng-container>
                              </div>
                              <div class="text-right col-4" [ngClass]="{'col-3': layoutService.isMobile}">
                                <!-- expired -->
                                <button *ngIf="studyPlan.annex && !studyPlan.expired" color="accent" mat-mini-fab class="hvr-grow download-button" [matTooltip]="Download" (click)="downloadAnnex(studyPlan)">
                                    <mat-icon class="icon-download">cloud_download</mat-icon>
                                </button>
                                <span *ngIf="studyPlan.expired" matTooltipClass="custom-tooltip" matTooltip="Plano expirado">
                                    <button mat-mini-fab disabled class="disabled-button">
                                        <mat-icon class="icon-disabled">hourglass_disabled</mat-icon>
                                    </button>
                                </span>
                                <button *ngIf="!studyPlan.expired" color="accent" mat-mini-fab class="hvr-grow next-button" [matTooltip]="studyPlan.name" (click)="selectPlan(studyPlan)">
                                    <mat-icon class="icon-next">arrow_right_alt</mat-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        <!--   <div *ngIf="studyPlan.status != 3" class="col-5 payment-refused">
                            <span >
                              Acesso expirado
                            </span>
                          </div> -->
                      </div>
                  </ng-container>
              </nb-list-item>
            </nb-list>
            <!-- END Chose plan -->

            <mat-spinner *ngIf="loadingPlans" class="center"></mat-spinner>
        </mat-card>
        <!-- Classroom -->
        <mat-card *ngIf="!ocultLiveClassroom" class="classroom-height mb-3 mt-4" [hidden]="!userService.isStudent()">
            <div class="page-title-container" [ngClass]="{'dash-container pl-4 pr-4':!layoutService.isMobile, 'width-80 text-center': layoutService.isMobile}">
                <span class="page-title"></span>
                <div class="mt-3 description-title">
                    <h4 *ngIf="!layoutService.isMobile">Salas de aula ao vivo</h4>
                    <label class="mt-2 mb-2" style="color: rgba(55, 68, 65, 0.6);">OBS: aulas são realizadas no fuso horário de Brasília!</label>
                    <h6 class="mb-0" *ngIf="layoutService.isMobile">Salas de aula ao vivo</h6>
                    <!-- <p class="fs-13">Ao vivo</p> -->
                </div>
            </div>
            <mat-card-content class="mb-0" [ngClass]="{'pl-4 pr-4': !layoutService.isMobile, 'pl-1 pr-1': layoutService.isMobile}">
                <!-- for v1 plans -->
                <app-classroom-list *ngIf="planSelected?.id && planSelected?.version == 1" (haveClassrooms)="setShowClassrooms($event)" [planId]="planSelected?.id">
                </app-classroom-list>
                <!-- for class classrooms -->
                <app-classroom-list *ngIf="!planSelected?.id" (haveClassrooms)=" setShowClassrooms($event) ">
                </app-classroom-list>
            </mat-card-content>
        </mat-card>
        <!-- Monitory -->
        <mat-card class="row mb-3 mt-4 " [hidden]="!((userService.isStudent() || userService.isTeacher()) && showMonitory) ">
            <div class="page-title-container " [ngClass]="{ 'text-center': layoutService.isMobile} ">
                <span class="page-title ">Acompanhe suas</span>
                <div class="mt-3 description-title ">
                    <h4>Monitorias</h4>
                    <h4>&nbsp;</h4>
                </div>
            </div>
            <div class="col-md-12 ">
                <app-monitory-list (haveMonitory)="setShowMonitory($event) "></app-monitory-list>
            </div>
        </mat-card>
        <!-- STUDENT AVERAGE CHART  -->
        <mat-card *ngIf="userService.isStudent() && !ocultMock">
            <mat-card-content class="mb-0 " [ngClass]="{ 'pl-4 pr-4': !layoutService.isMobile, 'pl-1 pr-1': layoutService.isMobile} ">
                <app-average-chart [studentId]="userService.getUserId() " (planName)="setAveragePlan($event) ">
                </app-average-chart>
            </mat-card-content>

            <mat-card-content [ngClass]="{ 'pl-3 pr-0': !layoutService.isMobile, 'pl-1 pr-1': layoutService.isMobile} ">

                <div class="row m-0 " *ngIf="userService.isStudent() ">
                    <!-- hidden -->

                    <div class=" " [ngClass]="{ 'pt-4 col-12 pl-1 pr-1': layoutService.isMobile, 'col-md-6': !layoutService.isMobile} ">

                        <app-progress-bar *ngIf="watchedVideos " class="mr-0 " title="Aulas assistidas " [label]="labelWatchedVideos" [percent]="watchedVideos " customIcon="desktop">
                        </app-progress-bar>
                        <div class="w-100 text-center">
                            <span><b>Atenção:</b> Este campo é filtrado apenas pelo <b>plano</b>, <b>não é afetado</b> pelo período!</span>
                        </div>
                    </div>
                    <div class=" " [ngClass]="{ 'pt-4 col-12 pl-1 pr-1': layoutService.isMobile, 'col-md-6': !layoutService.isMobile} ">

                        <app-progress-bar *ngIf="exercisePerf " class="ml-0 " title="Acertos e erros " [label]="labelExercisePerf" [percent]="exercisePerf " customIcon="checklist">
                        </app-progress-bar>
                        <div class="w-100 text-center">
                            <span><b>Atenção:</b> Este campo <b>não é afetado</b> pelo período e nem pelo <b>plano</b>!</span>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 text-center ">
                        <a href="https://t.me/joinchat/9ymyfhdMHWI5MWUx " target="_blank ">
                            <img class="telegram mt-5 width-100 " src="/assets/images/telegram.png " alt="telegram " />
                        </a>
                    </div> -->

                <!--IMAGEM DO PODCAST-->
                <!--  <div class="row " *ngIf="layoutService.isMobile ">
                    <div class="col-12 text-center ">
                        <img routerLink="../podcast " target="_blank " class="podcast mt-2 width-100 " src="/assets/images/podcast.png " alt="podcast " />
                    </div>
                </div> -->
            </mat-card-content>
        </mat-card>
        <nb-card showToggleButton="false " *ngIf="userService.isStudent() && performance?.disciplines.length> 0">
            <nb-card-header>
                Desempenho por disciplina
            </nb-card-header>
            <nb-card-body>
                <!-- disciplina report -->
                <div class="row">
                    <div class="col-md-3 p-4 mb-4" *ngFor="let discipline of performance.disciplines">
                        <app-dashboard-discipline-card-report [disciplineReport]="discipline">
                        </app-dashboard-discipline-card-report>
                    </div>
                </div>
            </nb-card-body>

        </nb-card>
    </div>
</div>
