<div *ngIf="!showList">
  <h4 *ngIf="editMode" class="mt-3 mb-3">Editar arquivo de aula</h4>
  <form [formGroup]="form">



    <!-- Nome  -->
    <div class="row">
      <div class="col-md">
        <mat-form-field class="width-100">
          <input matInput placeholder="Nome" formControlName="name">
        </mat-form-field>
      </div>
    </div>

    <!-- Escola, Disciplina -->
    <div class="row">
      <div *ngIf="userService.isAdmin()" class="text-right col-md">
        <mat-form-field class="width-100">
          <mat-label>Escola</mat-label>
          <mat-select (selectionChange)="getSchoolDisciplines($event.value)" formControlName="schoolId">
            <mat-option *ngFor="let school of schools" [value]="school.id">
              <span [title]="school.name">{{school.name}}</span> 
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md">
        <mat-form-field *ngIf="!loadingDisciplines" class="width-100">
          <mat-icon class="icon-align" matPrefix matTooltipClass="custom-tooltip"
            matTooltip="Neste campo você selecionará a disciplina que o arquivo será associado.">
            info
          </mat-icon>
          <mat-select formControlName="disciplineId" placeholder="Disciplina">
            <mat-option *ngIf="!disciplines">Selecione uma escola primeiro</mat-option>
            <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
              <span [title]="discipline.name">{{discipline.name}}</span> 
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="loadingDisciplines">
          <mat-spinner [diameter]="25" class="center"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 annex">
        <label>Arquivo (PDF)</label>
        <input class="width-100" (change)="handleFileInput($event.target.files);" accept="application/pdf" fullWidth status="primary" type="file"
          nbInput placeholder="Anexar Arquivo">
      </div>
    </div>
  </form>

  <div *ngIf="!editMode" class="row mt-5">
    <div class=" col-6 width-100">
      <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver arquivos cadastrados</button>
    </div>
    <div class=" col-6 text-right width-100">
      <button (click)="addSlideFile()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading"
        [disabled]="loading" nbSpinnerStatus="warning" class="right bg-color-primary border-primary">Cadastrar</button>
    </div>
  </div>

  <div *ngIf="editMode" class="row mt-5 text-right">
    <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading"
      [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
  </div>
</div>

<div *ngIf="showList" class="">
  <div class="row">
    <div *ngIf="userService.isAdmin()" class="text-right col-md-6">
      <mat-form-field class="width-100">
        <mat-label>Escola</mat-label>
        <mat-select (selectionChange)="getSchoolDisciplines($event.value)">
          <mat-option *ngFor="let school of schools" [value]="school.id">
            <span [title]="school.name">{{school.name}}</span> 
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="text-right col-md-6">
      <mat-form-field *ngIf="!loadingDisciplines" class="width-100">
        <mat-label>Disciplina</mat-label>
        <mat-select [(ngModel)]="disciplineId" (selectionChange)="getDisciplineSlideFiles($event.value)">
          <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
           <span [title]="discipline.name">{{discipline.name}}</span> 
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="loadingDisciplines">
        <mat-spinner [diameter]="25" class="center"></mat-spinner>
      </div>
    </div>

  </div>
  <nb-card size="medium" *ngIf="slideFiles && !loading">
    <nb-card-header>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="width-100">
            <input matInput placeholder="Buscar" [(ngModel)]="filter">
          </mat-form-field>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <mat-list class="width-90 mt-3 center" *ngIf="slideFiles">
        <ng-container *ngFor="let slideFile of slideFiles">
          <div mat-list-item class="row" *ngIf="verifyFilter(slideFile.name)">
            <div class="col-md-6 col-sm-4 col-xs-4 align-middle">
              {{slideFile.name}}
            </div>
            <div class="col-md-3 col-xs-2 align-middle">
              {{slideFile.createDate | date:'dd/MM/yyyy'}}
            </div>
            <div class="col-md-1 col-xs-2 text-right ">
              <button (click)="editSlideFile(slideFile)" mat-icon-button color="primary">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
            <div class="col-md-1 col-sm-2 col-xs-2 text-right">
              <button (click)="openFile(slideFile.file)" mat-icon-button color="accent">
                <mat-icon>open_in_new</mat-icon>
              </button>
            </div>
            <div class="col-md-1 col-sm-2 col-xs-2 text-right">
              <button size="tiny" (click)="removeSlideFile(slideFile.id)" status="danger" mat-icon-button color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </mat-list>
      <div *ngIf="empty">Nenhum resultado</div>
      
    </nb-card-body>
  </nb-card>
  <div class="text-right width-100">
    <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
  </div>
  <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>

</div>