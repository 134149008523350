import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AdminService } from '../../../admin.service';
import { SelectedPlansResp } from '../../../rdo-manager/create-theme/theme';
import { SchoolSelect } from '../../../school/school';
import { SelectPlanComponent } from '../../../shared-components/select-plan/select-plan.component';
import { SelectSchoolComponent } from '../../../shared-components/select-school/select-school.component';
import { Notifications } from '../../notifications';
import { NotificationsService } from '../../notifications.service';
import { TypesNotifications } from '../../types-notifications';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { SchoolService } from '../../../school/school.service';
import { UnitSelect } from '../../../school/unit';
import { ClassSelect } from '../../../school/class';
import { animate, state, style, transition, trigger, keyframes } from '@angular/animations';
import { EditNotificationsComponent } from '../../edit/edit-notifications/edit-notifications.component';
import { SelectUnitComponent } from '../../../shared-components/select-unit/select-unit.component';
import { SelectClassComponent } from '../../../shared-components/select-class/select-class.component';
import { SelectUsersComponent } from '../../../shared-components/select-users/select-users.component';
import { Component, OnInit } from '@angular/core';
import { Profiles } from '../../profiles';
import { StudentService } from '../../../student/student.service';
import { Student } from '../../../student/student';
import { UserSelect } from '../../users';
import { StudyPlanService } from '../../../study-plan/study-plan.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-create-notification',
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.scss'],
 /*  animations: [     //mousemove
   trigger('mousemove', [
      transition(':leave', [
        style({
          transform: 'rotateY(0deg) translateY(0px)'
        }),
        animate('0.5s ease')
      ])
    ])
  ] */
})

export class CreateNotificationComponent implements OnInit {


  public formBySchool: boolean;
  public formPerPlan: boolean;
  public formPerUnit: boolean;
  public formByClass: boolean;
  public formPerUser: boolean;
  public formByProfile: boolean;
  public choiceOption: boolean;
  public showButton: boolean;
  public form: FormGroup;
  public showform: boolean;
  public notify: Notifications[];
  public editMode: boolean;
  public showList: boolean;
  public showEdit: boolean = false;
  public loading: boolean;
  public showLink: boolean;
  public optionLink: string;
  public image: string;
  public video: string;
  private apiUrl = environment.ApiUrl;
  public editorConfig;
  public questionEditor = ClassicEditor;
  public exerciseText: string = '';
  public types: TypesNotifications[];
  public profiles: Profiles[];
  public filterName: string;
  public filterEmail: string;
  public selectedUnits: UnitSelect[];
  public selectUnits: UnitSelect[];
  public filterUnitId: number;
  public classes: ClassSelect[];

  public selectClasses: ClassSelect[];
  public selectedClasses: ClassSelect[];
  public selectPlans: SelectedPlansResp[];
  public selectedPlans: SelectedPlansResp[];
  public selectSchools: SchoolSelect[];
  public selectedSchools: SchoolSelect[];
  public selectedProfiles: Profiles[];
  public selectedUsers: UserSelect[];
  public selectUsers: UserSelect[];

  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();
  public hidden: boolean;
  public users: UserSelect[];
  public filteredUsers: UserSelect[];

  constructor(
    private alertService: AlertService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public maskService: MaskService,
    public userService: UserService,
    public notificationsService: NotificationsService,
    public schoolService: SchoolService,
    public layoutService: LayoutService,
    public studentService: StudentService,
    public studyPlanService: StudyPlanService,

  ) { }

  ngOnInit() {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  init(){
    this.choiceOption = true;
    this.form = this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      schools: [''],
      plans: [''],
      units: [''],
      classes: [''],
      users: [''],
      profiles: ['', Validators.required],
      type: ['', Validators.required],
      text: ['', Validators.required],
      optionLink: [''],
      link: [''],
      init: ['', Validators.required],
      end: ['', Validators.required]
    });

    this.selectedPlans = [];
    this.selectedSchools = [];
    this.selectedClasses = [];
    this.selectedUnits = [];
    this.selectedProfiles = [];
    this.selectedUsers = [];
    this.checkWhoItIs();
    this.getTypesNotifications();
    this.getProfilesNotifications();
    this.editorConfig = {
      ckfinder: {
        uploadUrl: this.apiUrl + '/upload/exerciseImg',
      },
      image: {
        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
        styles: ['full', 'alignLeft', 'alignRight']
      }
    };

    this.showEdit = false;
  }

  choiceForm(choice: string){
    if(choice == 'formBySchool') this.formBySchool = true;
    if(choice == 'formPerPlan') this.formPerPlan = true;
    if(choice == 'formPerUnit') this.formPerUnit = true;
    if(choice == 'formByClass') this.formByClass = true;
    if(choice == 'formByProfile') this.formByProfile = true;
    if(choice == 'formPerUser') this.formPerUser = true;
    this.showform = true;
    this.choiceOption = false;
  }

  noShowLink(type: string){
    if(type == 'Aula ao Vivo'){ //Sala de aula ao vivo
      this.hidden = true;
    }else{
      this.hidden = false;
    }
  }

  backButton(){
    this.formBySchool = false;
    this.formPerPlan = false;
    this.formPerUnit = false;
    this.formByClass = false;
    this.formPerUser = false;
    this.formByProfile = false;
    this.showform = false;
    this.showList = false;
    this.editMode = false;
    this.choiceOption = true;
  }

  checkWhoItIs(){
    if(this.userService.isAdmin()){
      this.getSchoolsSelection();
    }
    if(this.userService.isGeneralCoordinator()){
      this.getUnitSelection(Number(this.userService.getUserSchool()));
      this.getSchoolSelection(this.userService.getUserSchool());
    }

    if(this.userService.isUnitCoordinator()){
      this.getClasses(Number(this.userService.getUserUnit()));
      this.getSchoolSelection(this.userService.getUserSchool());
    }
    if (this.userService.isTeacher()) {
      this.getSchoolSelection(this.userService.getUserSchool());
      this.getSchoolSelection(this.userService.getUserSchool());
    }

  }

  getTypesNotifications(){
    this.notificationsService.getTypesNotifications()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.types = response.data as TypesNotifications[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os tipos de notificações. Verifique a conexão e tente novamente'));
  }


  getNotificationsCreatedToClass(classId: number){
    this.notificationsService.getNotificationsCreatedToClass(classId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por turma. Verifique a conexão e tente novamente'));

  }

  getNotificationsCreatedToUnit(unitId: number){
    this.notificationsService.getNotificationsCreatedToUnit(unitId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por unidade. Verifique a conexão e tente novamente'));

  }

  getProfilesNotifications(){
    this.notificationsService.getProfilesNotifications()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.profiles = response.data as Profiles[];
          if(this.profiles.length > 0) this.checkProfiles();
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os tipos de notificações. Verifique a conexão e tente novamente'));
  }

  checkProfiles(){
    this.profiles.forEach(profile => {
      if(profile.id == 5 && this.userService.isTeacher()){
        return 'd-none';
      }
      if(profile.id == 4 && !this.userService.isAdmin()){
        return 'd-none';
      }
    });
  }

  getUnitPlans(unitId: number) {
    this.schoolService.getUnitPlans(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectPlans = response.data as SelectedPlansResp[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos da unidade. Verifique a conexão e tente novamente'));
  }

  getTeacherPlans(teacherId: number) {
    this.schoolService.getTeacherPlans(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectPlans = response.data as SelectedPlansResp[];
        } else {
           this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos do professor. Verifique a conexão e tente mais tarde'));
  }

  getSchoolSelection(schoolId: number) {
    this.schoolService.getSchoolSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          //this.selectSchools = response.data as SchoolSelect[];
          this.selectedSchools = response.data as SchoolSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getSchoolsSelection() {
    this.schoolService.getSchoolsSelection()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.selectSchools = response.data as SchoolSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId?: number) {
    this.schoolService.getClassesSelection(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.classes = response.data as ClassSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente'));
  }

  addSchoolChip(school: SchoolSelect) {
    const index = this.selectedSchools.indexOf(school);
    if (index < 0) {
      this.selectedSchools.push(school);
      this.form.get('schools').reset();
    }
  }

  removeSchoolChip(school: SchoolSelect) {
    const index = this.selectedSchools.indexOf(school);
    if (index >= 0) {
      this.selectedSchools.splice(index, 1);
    }
  }

  addProfilesChip(profile: Profiles) {

    const index = this.selectedProfiles.find(obj => obj.id === profile.id);

    if (!index) {
      this.selectedProfiles.push(profile);
    }

  }

  removeProfileChip(profile: Profiles) {
    const index = this.selectedProfiles.indexOf(profile);
    if (index >= 0) {
      this.selectedProfiles.splice(index, 1);
    }
  }

  checkChoice(link: string){
    if(link === 'nenhum'){
      this.showLink = false;
      this.optionLink = 'nenhum';
    }else{
      this.showLink = true;
      if(link === 'image'){
        this.optionLink = 'image';
      }else{
        this.optionLink = 'video';
      }
    }
  }

  create() {
    this.loading = true;


    let notify = this.form.getRawValue() as Notifications;

    notify.isNotificationBySchool = false;
    notify.optionLink = this.optionLink;
    notify.schools = [];
    notify.plans = [];
    notify.units = [];
    notify.classes = [];
    notify.users = [];
    notify.profiles = [];
    notify.create_user = this.userService.getUserId();

    if(this.formBySchool && this.selectedSchools.length > 0){

      notify.isNotificationBySchool = true;

      this.selectedSchools.forEach(element => {
        notify.schools.push(element.id);
      });
    }else if(this.formPerPlan && this.selectedPlans.length > 0){
      this.selectedPlans.forEach(element => {
        notify.plans.push(element.id);
      });
    }else if(this.formPerUnit && this.selectedUnits.length > 0){
      this.selectedUnits.forEach(element => {
        notify.units.push(element.id);
      });
    }else if(this.formByClass && this.selectedClasses.length > 0){
      this.selectedClasses.forEach(element => {
        notify.classes.push(element.id);
      });
    }else if(this.formPerUser && this.selectedUsers.length > 0){

      this.selectedUsers.forEach(element => {
        notify.users.push(element.id);
      });

    }else if(this.formByProfile && this.selectedProfiles.length > 0){

      this.selectedProfiles.forEach(element => {
        notify.profiles.push(element.id);
      });

      if(this.selectedSchools.length > 0 && this.userService.isAdmin()){
        notify.schools = [];
        this.selectedSchools.forEach(element => {
          notify.schools.push(element.id);
        });
      }

      if (!this.userService.isAdmin()) {
        notify.schools.push(this.userService.getUserSchool() ) ;
      }

    }

    if(!this.formByProfile && this.selectedProfiles.length > 0){
      this.selectedProfiles.forEach(element => {
        notify.profiles.push(element.id);
      });
    }

    if( notify.schools.length === 0
        && notify.plans.length === 0
        && notify.units.length === 0
        && notify.classes.length === 0
        && notify.users.length === 0
        && notify.profiles.length === 0)
    {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }
    this.notificationsService.createNotifications(notify)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.adminService.updateChields();
          this.alertService.success('Notificação cadastrada com sucesso!', 3);
          this.form.reset();
          this.filteredUsers = [];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => { this.alertService.error('Houve um erro ao criar a notificação. Verifique a conexão e tente novamente'); });
  }

  edit(notify: Notifications) {
    const dialogRef = this.dialog.open(EditNotificationsComponent, {
      data: notify
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showform = false;
        this.editMode = false;
      }
      this.openList();
    });
  }

  remove(notificationId: number) {
    if (confirm('Deseja realmente excluir a notificação?')) {
      this.notificationsService.removeNotification(notificationId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.alertService.success('Notificação removida!', 3);
            this.openList();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a notificação. Verifique a conexão e tente novamente'));
    }
  }

  getUnitSelection(schoolId?: number){
    this.schoolService.getUnitsSelection(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.selectUnits = response.data as UnitSelect[];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
  }

  openSelectUnits() {
    const dialogRef = this.dialog.open(SelectUnitComponent, {
      minWidth: '60vw',
      data: []
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.selectedUnits.indexOf(result);
        if (index < 0) {
          result.forEach(element => {
            let unitSelected = {id:element.id, name:element.name};
            this.selectedUnits.push(unitSelected);
          });
        }
      }
    });
  }

  addUnitChip(unit: { id: number, name: string }) {
    const index = this.selectedUnits.indexOf(unit);
    if (index < 0) {
      this.selectedUnits.push(unit);
      //this.form.get('plans').reset();
    }
  }

  removeUnitChip(unit: UnitSelect) {
    const index = this.selectedUnits.indexOf(unit);
    if (index >= 0) {
      this.selectedUnits.splice(index, 1);
    }
  }

  openSelectClasses() {
    const dialogRef = this.dialog.open(SelectClassComponent, {
      minWidth: '60vw',
      data: []
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.selectedClasses.indexOf(result);
        if (index < 0) {
          result.forEach(element => {
            let classSelected = {id:element.id, name:element.name};
            this.selectedClasses.push(classSelected);
          });
        }
      }
    });
  }

  addClassChip(classes: { id: number, name: string }) {
    const index = this.selectedClasses.indexOf(classes);
    if (index < 0) {
      this.selectedClasses.push(classes);
      this.form.get('classes').reset();
    }
  }

  removeClassChip(classes: ClassSelect) {
    const index = this.selectedClasses.indexOf(classes);
    if (index >= 0) {
      this.selectedClasses.splice(index, 1);
    }
  }

  preview(){

  }

  getNotifyByTitle(title: string){
    this.notificationsService.getNotifyByTitle(title)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por plano. Verifique a conexão e tente novamente'));
  }

  openSelectUsers() {
    const dialogRef = this.dialog.open(SelectUsersComponent, {
      minWidth: '60vw',
      data: []
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedUsers.push(result);
      }
    });
  }

  openSelectPlans() {
    const dialogRef = this.dialog.open(SelectPlanComponent, {
      minWidth: '60vw',
      data: []
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.selectedPlans.indexOf(result);
        if (index < 0) {
          result.forEach(element => {
            let planSelected = {id:element.id, name:element.name};
              this.selectedPlans.push(planSelected);
          });
        }
      }
    });
  }

  openList(){

    this.showList = true;
    this.showform = false;
    this.checkWhoItIs();
    this.getSchoolsSelection();
    this.getTypesNotifications();
    if(this.userService.isAdmin()){
      this.getAllNotificationsCreated();
    }

  }

  openListBeforeCreated(){

    //this.choiceOption = false;

    this.showform = false;
    this.showList = true;

    this.selectSchools = [];
    this.selectUnits = [];
    this.classes = [];

    if(this.userService.isAdmin()) {
      this.getSchoolsSelection();
      this.getAllNotificationsCreated();
    }

    if(this.userService.isGeneralCoordinator()) {
      this.getUnitSelection(Number(this.userService.getUserSchool()));
      this.getAllNotificationsCreatedBySchool(Number(this.userService.getUserSchool()));
      this.getPlansBySchool(Number(this.userService.getUserSchool()));
    }

    if(this.userService.isUnitCoordinator()) {
      this.getClasses(Number(this.userService.getUserUnit()));
      this.getUnitPlans(Number(this.userService.getUserUnit()));
    }
    if(this.userService.isTeacher()) {
      this.getTeacherPlans(Number(this.userService.getUserId()));
    }

  }

  getAllNotificationsCreated(){
    this.notificationsService.getAllNotificationsCreated()
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente'));
  }

  getAllNotificationsCreatedBySchool(schoolId: number){
    this.notificationsService.getAllNotificationsCreatedBySchool(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por escola. Verifique a conexão e tente novamente'));
  }

  getPlansBySchool(schoolId : number) {
    this.studyPlanService.getPlansBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.selectPlans = response.data as SelectedPlansResp[]
        }
      }, err => this.alertService.error('Não foi possivel buscar os planos verifique a conexão e tente novamente'))
  }

  getAllNotificationsCreatedByPlan(planId: number){
    this.notificationsService.getAllNotificationsCreatedByPlan(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por plano. Verifique a conexão e tente novamente'));
  }

  getNotificationsByProfile(profile: number){
    this.notificationsService.getNotificationsByProfile(profile)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por perfil. Verifique a conexão e tente novamente'));
  }

  getNotificationsByType(type: string){
    this.notificationsService.getNotificationsByType(type)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por tipo. Verifique a conexão e tente novamente'));
  }

  getNotificationsByTitle(title: string){
    this.notificationsService.getNotifyByTitle(title)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.notify = response.data as Notifications[];
      } else {
        this.notify = null;
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar as notificações por título. Verifique a conexão e tente novamente'));
  }

  getUsersByName(name: string) {
    if (!name) {
      this.alertService.error('os campos devem ser prenchidos');
    } else {
      if (name && name.length < 3) {
        this.alertService.error('precisa-se no mínimo de 3 caracteres')
      }
      else if(name) {
        this.loading = true;
        this.notificationsService.getUsersByName(name)
          .subscribe(res => {
            const response = res.body as ResponseApi;
              if (!response.error) {
                this.users = response.data as UserSelect[];
                this.filteredUsers = response.data as UserSelect[];
              } else {
                this.alertService.error(response.error);
              }
              this.loading = false;
          }, err => {
            this.alertService.error('Houve um erro ao carregar os usuários. Verifique a conexão e tente novamente');
            this.loading = false;
          });
      }
    }
  }

  getUsersByEmail(email: string) {
    if (!email) {
      this.alertService.error('os campos devem ser prenchidos');
    }else{
      if (email && email.length < 3) {
        this.alertService.error('precisa-se no mínimo de 3 caracteres')
      } else if(email){
        this.loading = true;
        this.notificationsService.getUsersByEmail(email)
          .subscribe(res => {
            const response = res.body as ResponseApi;
              if (!response.error) {
                this.users = response.data as UserSelect[];
                this.filteredUsers = response.data as UserSelect[];
              } else {
                this.alertService.error(response.error);
              }
              this.loading = false;
          }, err => {
            this.alertService.error('Houve um erro ao carregar os usuários. Verifique a conexão e tente novamente');
            this.loading = false;
          });
      }
    }
  }

  filterByName() {
    if (this.filterName.length) {
        this.filterEmail = '';
        this.filteredUsers = [...this.users].filter(s => s.name.toLowerCase().indexOf(this.filterName.toLowerCase()) > -1);
    } else {
        this.filteredUsers = [...this.users];
    }
  }

  filterByEmail() {
    if (this.filterEmail.length) {
        this.filterName = '';
        this.filteredUsers = [...this.users].filter(s => s.email.toLowerCase().indexOf(this.filterEmail.toLowerCase()) > -1);
    } else {
        this.filteredUsers = [...this.users];
    }
  }

  addUser(user: { id: number, name: string }) {
    console.log("user: ", [user, this.formPerUser])
    const index = this.selectedUsers.indexOf(user);
    if (index < 0) {
      this.selectedUsers.push(user);
      //this.form.get('plans').reset();
    }
  }

  removeUserChip(users: UserSelect) {
    const index = this.selectedUsers.indexOf(users);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }

}
