import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User } from '@sentry/angular';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { environment } from 'src/environments/environment';
import { disciplineTag } from '../../admin/discipline-tag/discipline-tag-create/discipline-tag';
import { Discipline } from '../../admin/discipline/discipline';
import { DisciplineService } from '../../admin/discipline/discipline.service';
import { School } from '../../admin/school/school';
import { SchoolService } from '../../admin/school/school.service';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { Plan } from '../../payment/plan';
import { UserService } from '../../user/user.service';
import { CommunityService } from '../community.service';
import { CommunityTopic } from './communityComment';

interface StudentActivies {
  id: number,
  name: string,
  imagem: string,
  totalAnswers: number
}

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {

  public items: string[];
  public selectedValue: string;
  public write: boolean;
  public questionEditor = ClassicEditor;
  public editorConfig;
  private apiUrl = environment.ApiUrl;
  public disciplines: Discipline[];
  public plans: StudyPlan[];
  public createCommunityTopicForm: FormGroup;
  public communityTopic: CommunityTopic;
  public communityTopics: CommunityTopic[];
  public stringTxt: string;
  public myTopics: boolean;
  public answeredTopics: boolean;
  public userActivies: User;
  public totalPageTopics: number;
  public pageNoTopics: number;
  public selectedPlanId: number;
  public studentActivies: StudentActivies[];
  public reverse: boolean = false;
  public order: string = 'topic.created_at';
  public showHiddenDiv: boolean;
  public firstCall: boolean;
  public allPlans: boolean;
  public scholls : School[];
  public selectedScholl : number;
  public btnDelete: boolean;

  constructor(
    private disciplineService: DisciplineService,
    public userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private communityService: CommunityService,
    private router: Router,
    public layoutService: LayoutService,
    private planService: StudyPlanService,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    this.btnDelete = false;
    if(this.userService.isAdmin()){
      this.getAllSchools();
    }
    this.firstCall = true;
    this.write = false;
    this.answeredTopics = false;

    this.editorConfig = {
      ckfinder: {
        // tslint:disable-next-line: max-line-length
        uploadUrl: this.apiUrl + '/upload/exerciseImg',
      },
      image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

        styles: [
          // This option is equal to a situation where no style is applied.
          'full',

          // This represents an image aligned to the left.
          'alignLeft',

          // This represents an image aligned to the right.
          'alignRight'
        ]
      }
    };

    this.createCommunityTopicForm = this.formBuilder.group({
      id: [],
      title: [''],
      communityComment: [''],
      disciplineName: [''],
      schoolId: [],
      userName: [''],
      userId: [''],
      planId: [],
      disciplineId: [],
      created_at: [''],
      updated_at: [''],
      schoolIdAdmin:['']
    })

    if(this.userService.isStudent()){
      this.getPlans();
    }
    if(!this.userService.isAdmin() && !this.userService.isStudent()){
      this.getTopicsBySchool(this.userService.getUserSchool() , 0 , 20);
    }
    this.getUserActiviesBySchool(this.userService.getUserSchool())

    if(!this.userService.isAdmin() && !this.userService.isStudent()){
      if(this.userService.isTeacher()){
        this.getTeacherPlans(this.userService.getUserId());
      }
      else{
      this.getPlansBySchool(this.userService.getUserSchool());
      }
    }
  }

  setWrite(val: boolean) {
    this.write = val;
  }

  getAllSchools(){
    this.schoolService.getSchools()
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.scholls = response.data as School[];
      }
    }, err => {
      this.alertService.error('Não foi possivel encontrar as escolas verifique a conexão e tente novamente');
    })
  }

  createTopic() {

    if(this.createCommunityTopicForm.get('title').value){

    this.communityTopic = this.createCommunityTopicForm.getRawValue() as CommunityTopic;

    this.communityTopic.userId = this.userService.getUserId();
    this.communityTopic.userName = this.userService.getUserName();
    if(this.userService.isAdmin()){
      this.communityTopic.schoolId = this.createCommunityTopicForm.get('schoolIdAdmin').value;
    }
    else{
      this.communityTopic.schoolId = this.userService.getUserSchool();
    }

    this.communityService.createCommunityTopic(this.communityTopic)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Tópico criado com sucesso!');
          this.createCommunityTopicForm.reset();
          this.getMyTopics(true, 0, 20);
        }
        else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao criar o tópico. Verifique a conexão e tente novamente'))
    }
    else{
      this.alertService.error('O tópico precisa ter um título');
    }
  }

  getTeacherPlans(userId : number){
    this.planService.getTeacherViewPlans(userId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plans = response.data as StudyPlan[]
      }
      else{
        console.log(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!')
    })
  }

  getPlans() {
    this.planService.getAllSelectedStudentPlans(this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[]

          if (this.firstCall) {
            this.getAllTopics(this.plans, 0, 20);
          }
        }
        else {
          //console.log(response.error);
        }
      }, err => this.alertService.error('Não foi possivel buscar os planos verifique a conexão e tente novamente'))
  }

  getPlansBySchool(schoolId : number) {
    this.planService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[]
        }
        else {
          console.log(response.error);
        }
      }, err => this.alertService.error('Não foi possivel buscar os planos verifique a conexão e tente novamente'))
  }

  getDisciplinesByPlan(planId: number) {
    this.disciplineService.getDisciplinesNotExerciciesSelectedByPlan(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        }
        else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Não foi possivel buscar as disciplinas verifique a conexão e tente novamente'))
  }

  getTopics(planId: number, offset: number, limit: number) {

    if (planId == 0) {
      this.getAllTopics(this.plans, 0, 20);
      this.allPlans = true;
    }
    else {
      this.communityTopics = [];
      this.myTopics = false;
      this.answeredTopics = false;
      this.communityService.getTopics(planId, offset, limit)
        .subscribe(res => {
          const response = res.body as ResponseApi
          const totalTopic = res.body['totalPage'];

          if (!response.error) {
            this.communityTopics = response.data as CommunityTopic[];
            this.totalPageTopics = totalTopic as number;
            this.communityTopics.forEach(el => {
              if (!el.ImagenUsu) {
                el.ImagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png';
              }
              this.showHiddenDiv = false;
              this.allPlans = false;
            });
          }
          else {
            this.alertService.error(response.error);
            this.showHiddenDiv = true;
          }
        }, err => this.alertService.error('Não foi possivel buscar os tópicos verifique a conexâo e tente novamente'))
    }
  }

  getAllTopics(plans: StudyPlan[], offset: number, limit: number) {

    this.communityService.getAllTopics(plans, offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        const totalTopic = res.body['totalPage'];

        if (!response.error) {
          this.communityTopics = response.data as CommunityTopic[];
          this.totalPageTopics = totalTopic as number;
          this.communityTopics.forEach(el => {
            if (!el.ImagenUsu) {
              el.ImagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png';
            }
            this.showHiddenDiv = false;
            this.firstCall = false;
          });
        }
        else {
          this.alertService.error(response.error);
          this.showHiddenDiv = true;
          this.firstCall = false;
        }

      }, err => this.alertService.error('Não foi possivel buscar os tópicos verifique a conexâo e tente novamente'))
  }

  selectTopic(topic: CommunityTopic) {

    if(!this.btnDelete){
    this.router.navigate(['topic-answer/', topic.id])
    }
  }

  getMyTopics(event: boolean, offset: number, limit: number) {
    this.communityTopics = [];
    if (event) {
      this.answeredTopics = false;
      this.myTopics = true;
      this.communityService.getMyTopics(this.userService.getUserId(), offset, limit)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          const totalTopic = res.body['totalPage'];

          if (!response.error) {
            this.communityTopics = response.data as CommunityTopic[];
            this.totalPageTopics = totalTopic as number;
            this.communityTopics.forEach(el => {
              if (!el.ImagenUsu) {
                el.ImagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png';
              }
              this.showHiddenDiv = false;
              this.allPlans = false;
            });
          }
          else {
            this.showHiddenDiv = true;
          }
        }, err => this.alertService.error('Não foi possivel buscar os tópicos verifique a conexâo e tente novamente'))
    }
    else {
      if (this.plans) {
        this.getAllTopics(this.plans, 0, 20);
      } else {
        this.showHiddenDiv = true;
      }
    }
  }

  getansweredTopics(event: boolean, offset: number, limit: number) {
    this.communityTopics = [];

    if (event) {
      this.myTopics = false;
      this.answeredTopics = true;
      this.communityService.getAnsweredTopics(this.userService.getUserId(), offset, limit)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          const totalTopic = res.body['totalPage'];

          if (!response.error) {
            this.communityTopics = response.data as CommunityTopic[];
            this.totalPageTopics = totalTopic as number;
            this.communityTopics.forEach(el => {
              if (!el.ImagenUsu) {
                el.ImagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png';
              }
              this.showHiddenDiv = false;
              this.allPlans = false;
            });
          }
          else {
            this.showHiddenDiv = true;
          }
        }, err => this.alertService.error('Não foi possivel buscar os tópicos verifique a conexâo e tente novamente'))
    }
    else {
      if (this.plans) {
        this.getAllTopics(this.plans, 0, 20);
      } else {
        this.showHiddenDiv = true;
      }
    }
  }

  getUserActiviesBySchool(schoolId: number) {
    this.communityService.getUserActiviesBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.userActivies = response.data as StudentActivies[]
          this.userActivies.forEach(element => {
            element.name = element.name.split(' ').slice(0, 3).join(' ');
            if (!element.imagen) {
              element.imagen = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png'
            }
          });
        }
        else {
          //console.log(response.error);
        }
      }, err => this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente'))
  }

  pageEventsTopics(event: any) {
    const offset = event.pageIndex * event.pageSize;

    if(this.userService.isAdmin()){
      if (this.myTopics) {
        this.getMyTopics(true, offset, event.pageSize);
      }
      else if (this.answeredTopics) {
        this.getansweredTopics(true, offset, event.pageSize);
      }
      else if(this.allPlans){
        this.getAllTopics(this.plans , offset , event.pageSize);
      }
      else  if (this.selectedPlanId) {
        this.getTopics(this.selectedPlanId, offset, event.pageSize);
      }
      else{
        this.getTopicsBySchool(this.selectedScholl , offset , event.pageSize);
      }
    }
    else if (!this.userService.isAdmin() && !this.userService.isStudent()){
      if (this.myTopics) {
        this.getMyTopics(true, offset, event.pageSize);
      }
      else if (this.answeredTopics) {
        this.getansweredTopics(true, offset, event.pageSize);
      }
      else if(this.allPlans){
        this.getAllTopics(this.plans , offset , event.pageSize);
      }
      else  if (this.selectedPlanId) {
        this.getTopics(this.selectedPlanId, offset, event.pageSize);
      }
      else{
        this.getTopicsBySchool(this.userService.getUserSchool() , offset , event.pageSize);
      }
    }
    else{
      if (this.myTopics) {
        this.getMyTopics(true, offset, event.pageSize);
      }
      else if (this.answeredTopics) {
        this.getansweredTopics(true, offset, event.pageSize);
      }
      else if(this.allPlans){
        this.getAllTopics(this.plans , offset , event.pageSize);
      }
      else {
        if (this.selectedPlanId) {
          this.getTopics(this.selectedPlanId, offset, event.pageSize);
        }
      }
    }
  }

  getTopicsBySchool(schoolId : number , offset: number , limit: number){
    this.communityService.getTopicsBySchool(schoolId , offset , limit)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      const totalTopic = res.body['totalPage'];

      if(!response.error){
        this.communityTopics = response.data as CommunityTopic[];
        this.totalPageTopics = totalTopic as number;

        this.communityTopics.forEach(el => {
          if (!el.ImagenUsu) {
            el.ImagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png';
          }
          this.showHiddenDiv = false;
          this.allPlans = false;
        });

        this.getPlansBySchool(schoolId);
      }
      else{
        if(this.userService.isAdmin){
          this.alertService.error('Não existem tópicos criados nessa escola');
        }
      }
    }, err => {
      this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
    })
  }

  getOrder(val: number) {

    if (val == 0) {
      this.reverse = false
    }
    else {
      this.reverse = true;
    }
  }

  deleteTopic(topicId : number){
    this.btnDelete = true;
    if(confirm('Você deseja excluir esse tópico')){
      this.communityService.deleteTopic(topicId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          if(this.userService.isAdmin()){
            this.getTopicsBySchool(this.selectedScholl , 0 , 20);
            this.btnDelete = false;
          }
          else{
            this.getMyTopics(true , 0 , 20);
            this.btnDelete = false;
          }
        }
      })
    }
    else{
      setTimeout(() => {
        this.btnDelete = false;
      }, 2000);
    }
  }
}
