import { Component, TemplateRef, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { MainTheme, MotivacionalText } from 'src/app/modules/admin/rdo-manager/create-theme/theme';

@Component({
  selector: 'app-read-theme',
  templateUrl: './read-theme.component.html',
  styleUrls: ['./read-theme.component.scss']
})
export class ReadThemeComponent {
  public text:any;
  public title:any;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public theme: MainTheme,
    public dialogRef: NbDialogRef<ReadThemeComponent>) {
  }
 

  ngOnInit(): void {

  }

  close() {
      this.dialogRef.close();
  }

  save() {
      this.dialogRef.close(this.text);
  }
}
