import jsPDF from 'jspdf';

export default class PdfFactory {

    private header:any;
    private headerSaved:any;
    private body:any;
    private bodyKeys:any; 
    private documentPDF:jsPDF;
    private sizeTemp:number;
    private lineSpace:number;
    private pageWidth:number;
    private pageHeight:number;
    private pdfConfig:any;
    public sizeOfBox : number;
    public multiplyFactor = 1;
 

    constructor(header:any, body:any,bodyKeys:any, config:any) { 
        
        // load Env
        this.header = header;
        this.body = body;
        this.bodyKeys = bodyKeys;
        this.pdfConfig =config;
        this.headerSaved = header;

        // Start build table
        this.start(); 
    }

    private async start()
    {   
        /* Calculate Sizes of:
            Cells
            Page A4| A3 | A2
            LineSpace between cells
        */
       
        await this.getHeaderStrSizes();
       
        // Create a instance of PDF LIB
        this.documentPDF =  await new jsPDF(   
                                this.pdfConfig.orientation, 
                                this.pdfConfig.unit, 
                                this.pdfConfig.format
                            );
        await this.setHeaderInfo();
        // Create the header of Table
        this.setHeader(45);

        // Create the Body of Table 
        await this.setBody();
 
        if(this.pdfConfig.download){
            // Force the download of a PDF
            this.download(this.pdfConfig.name); 
        }else{
             // Open In a New window to download or print
            this.openPrintWindow(); 
        }
     
    }
    
    private async setHeader(Ypos = 0)
    {   
        
        // Empty square Color
        this.documentPDF.setDrawColor(23,71,87); 
        this.documentPDF.setFillColor(23,71,87);

        // Empty square X, Y, W, H  
        this.documentPDF.rect(10, 10 + Ypos, this.sizeTemp , 10, "FD");

         // Text Font Size
        this.documentPDF.setFontSize(this.pdfConfig.fontSize);

        // Text Color
        this.documentPDF.setTextColor(247,247,247);

     
        // Print Header
        await this.header.forEach( element => {
            this.documentPDF.cell(
                element.posX ,
                element.posY + Ypos, 
                element.width + this.lineSpace,
                element.height, 
                element.text , 
                element.lineNumber , 
                element.align
            ); 
        });  
      
    } 
    private async setHeaderInfo(Ypos = 0)
    {   
        
        // Empty square Color
        //this.documentPDF.setDrawColor(23,71,87); 
        //this.documentPDF.setFillColor(23,71,87);

        // Empty square X, Y, W, H  
        //this.documentPDF.rect(10, 10 , this.sizeTemp , 10, "FD");

         // Text Font Size
        //this.documentPDF.setFontSize(this.pdfConfig.fontSize);

        // Text Color
        //this.documentPDF.setTextColor(34,43,69);

        let  line = 1;
        let posInfoY =10;
        let secondLIne = 1;
        let posInfoY1 = 10;

        this.documentPDF.setDrawColor(23,71,87); 
            this.documentPDF.setFillColor(23,71,87);

            this.documentPDF.rect(10, 10 , 60 , 14.4, "FD");
            this.documentPDF.rect(10, 25 , 60 , 14.4, "FD");
            this.documentPDF.rect(10, 40 , 60 , 14.4, "FD");

            this.documentPDF.rect(130, 10 , 60 , 14.4, "FD");
            this.documentPDF.rect(130, 25 , 60 , 14.4, "FD");
            this.documentPDF.rect(130, 40 , 60 , 14.4, "FD");

            this.documentPDF.setFontSize(this.pdfConfig.fontSize);
            
        // Print Header
        await this.pdfConfig.headerInfoData.forEach( element => {

            this.documentPDF.setTextColor("white");
            this.documentPDF.cell(
                10 ,
                posInfoY, 
                60,
                15, 
                element.name , 
                line , 
               "center"
            ); 
            this.documentPDF.setTextColor("black");
            this.documentPDF.cell(
                10 ,
                posInfoY, 
                60,
                15, 
                element.value , 
                line , 
               "center"
            );
            secondLIne ++;
            if(secondLIne > 2){
                secondLIne=1;
                line++;
            }
           
            posInfoY +=10;
        });  
      
    }
    private async setBody()
    { 
        // Define the number of the line that will be printed
        var line = 0; 
      
        var actualCellsHeight = 0;
        // Count total lines by page
        var totalLines = 0;
        
        //Start to build the body
        await this.body.forEach( element => {

            // gets mod between str lenght and col size
            this.getMultiplyFactor(element); 
            
            let heightCells = (this.header[0].height * this.multiplyFactor);
            actualCellsHeight += heightCells; 

            // Create a new page if needed
            if((this.pageHeight - heightCells) < actualCellsHeight){
                totalLines = 0;
                actualCellsHeight = 0;
                this.documentPDF.cellAddPage();
               
                if(this.pdfConfig.hasHeader){ 
                    this.setHeader();   
                }
            }  

            var col = 0;
            // Text Font Size
            this.documentPDF.setFontSize(this.pdfConfig.fontSize);

            // Text Color
            this.documentPDF.setTextColor(34,43,69);

            this.bodyKeys.forEach(key  => {

                if (Object.prototype.hasOwnProperty.call(element, key)) {
                        
                    const el = element[key]; 

                    this.documentPDF.cell(  
                        this.header[col].posX ,
                        this.header[col].posY , 
                        this.header[col].width + this.lineSpace, 
                        (this.header[col].height * this.multiplyFactor), 
                            `${el}` , 
                            line , 
                        this.header[col].align 
                    ); 
                    col +=1;
                }
            }); 
            
            line +=1;
            totalLines += 1;
             
        }); 
 
        
    }
  
    // Build an object configured to fill all header data
    private async getHeaderStrSizes(posY = 0)
    { 

        let head = [] ;
        var size = 0;   

        await this.headerSaved.forEach(   element => {
            
            let width = this.getLength(element); 
            size += width; 
            let el = {posX:10, posY:10 + posY, width: width, height: 10, text:element, lineNumber:1, align:'justify'};  
           
            head.push(el);
        });
      
        this.header = head; 

        this.getCreateConfig(size , head.length, posY);
        
    } 

    private getMultiplyFactor(element){

        this.multiplyFactor = 1;

        this.bodyKeys.forEach(key  => {

            let el = element[key];
            var sizeElText = this.getLength(el)/2;

            if( sizeElText > 12 ){
   
                let multiplyFactor = Math.ceil(sizeElText/12);
                if(multiplyFactor > this.multiplyFactor){
                    this.multiplyFactor = multiplyFactor;
                } 
            }

        });

    }
    // Define what tipe of page is (A4,A3,A2) and if is horizontal or vertical
    private getCreateConfig(size:number, cols:number, posYfactor = 0)
    {

        let conf = {ori:"p",format:'a4'};

        switch (true) {

            // A4 vertical
            case size <= 190:
                 this.lineSpace = Math.ceil((190 - size)/ cols);
                 this.pdfConfig.orientation = 'p';  
                 this.pdfConfig.format ='a4';
                 this.pageWidth = 190;
                 this.pageHeight = (297 - posYfactor); 
                 this.sizeTemp = size + (cols * this.lineSpace);
               

            break;

            // A4 horizontal
            case size > 190 && size <= 277:
                this.lineSpace = Math.ceil((277 - size)/ cols);
                this.pdfConfig.orientation = 'l';  
                this.pdfConfig.format ='a4';
                this.pageWidth = 277;
                this.pageHeight = 210 - posYfactor;
                this.sizeTemp = size + (cols * this.lineSpace);

            break; 

            // A3 horizontal
            case size > 277 && size <= 400:
                this.lineSpace = Math.ceil((400 - size)/ cols);
                this.pdfConfig.orientation = 'l';  
                this.pdfConfig.format ='a3';
                this.pageWidth = 400;
                this.pageHeight = 297 - posYfactor;
                this.sizeTemp = size + (cols * this.lineSpace);

            break;  

            // A2 horizontal
            default:
                this.lineSpace = Math.ceil((574 - size)/ cols);
                this.pdfConfig.orientation = 'l';  
                this.pdfConfig.format ='a2';
                this.pageWidth = 574 ;
                this.pageHeight = 420 - posYfactor;
                this.sizeTemp = size + (cols * this.lineSpace);
            break;
        }

        return conf;

    } 
    // Get the size string
    private getLength(el)
    { 
        return (el.length * 2) + 10;
    }  
    // Force download of File
    private download(name:string)
    {
        this.documentPDF.save(name+".pdf"); 
    }
    // Open a new window
    private openPrintWindow()
    {
        this.documentPDF.output("dataurlnewwindow");
    } 
}