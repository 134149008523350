<div *ngIf="!showPodcastList" mat-dialog-content>
    <form [formGroup]="createPodcastForm" class="pt-5">
        <h4 *ngIf="editMode" mat-dialog-title class="mt-3 mb-3">Editar Podcast</h4>

        <!-- Name, TAG -->
        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Título" formControlName="title">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Iframe" formControlName="link">
                </mat-form-field>
            </div>
            <div class="col"*ngIf="userService.isAdmin()">
                <mat-form-field>
                    <mat-select  placeholder="Escola" formControlName="schoolId">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
        </div>

    </form>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver podcasts
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createPodcast()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading"
                [disabled]="loading" nbSpinnerStatus="warning" class="right bg-color-primary border-primary">Cadastrar</button>
        </div>
    </div>

    <!-- <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary">Salvar</button> 
    </div> -->
</div>

<div *ngIf="showPodcastList" class="">
    <mat-form-field *ngIf="userService.isAdmin()">
        <mat-select  placeholder="Escola" [(ngModel)]="filterSchool"
            (selectionChange)="getPodcasts($event.value)">
            <mat-option *ngFor="let school of schools" [value]="school.id">
                {{school.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <nb-list *ngIf="!loading" class="width-90 mt-3 center">
        <nb-list-item *ngFor="let pod of podcasts">
            <div class="row">
                <div class="col-10">
                    <nb-card class="mt-2">
                        <nb-card-body [innerHTML]="pod?.link | safe:'true'">

                        </nb-card-body>
                    </nb-card>
                </div>
                <!-- <div class="col-4">
                    criado em {{exercise.createDate | date:'dd/MM/yyyy'}}
                </div> -->
                <!-- <div class="col-2 text-right">
                    <button (click)="editExercise(exercise)" nbButton size="tiny" outline
                        status="primary">Editar</button>
                </div> -->
                <div class="col-2 text-right">
                    <button size="tiny" (click)="removePodcast(pod.id)" status="danger" nbButton ghost
                        [nbSpinner]="deleting" nbSpinnerStatus="danger" nbSpinnerSize="tiny">
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <mat-spinner *ngIf="loading" class="center"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showPodcastList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>