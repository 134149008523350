import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IntegrationsService } from '../integrations.service';
import { uniStudent } from './uniStudent';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-integration-unimestre',
  templateUrl: './integration-unimestre.component.html',
  styleUrls: ['./integration-unimestre.component.scss']
})
export class IntegrationUnimestreComponent implements OnInit {

  public studentMail: string;
  public formUnimestre: FormGroup;
  public studentUni: uniStudent;
  public editMode: boolean;
  public unitStudents: uniStudent[];
  public pageIndex: number;
  public admUpdate$: Subscription;
  public pageSize: number;
  public pageNo: number;
  public studenNameUni: string;
  public totalPage: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public student: any,
    @Optional() public dialogRef: MatDialogRef<IntegrationUnimestreComponent>,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private userService: UserService,
    private integrationService: IntegrationsService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {


    this.editMode = false;
  
    this.unitStudents = [];

    this.pageSize = 20;
    this.pageNo = 0;

    this.formUnimestre = this.formBuilder.group({
      id: [''],
      userId: ['', Validators.required],
      nameUni: ['', Validators.required],
      emailUni: ['', Validators.required],
      school: ['', Validators.required],
      classUni: ['', Validators.required],
      courseUni: ['', Validators.required],
      externalCode: [''],
      birthDate: ['', Validators.required],
    })

    if(this.student){
      this.editMode = this.student.aux;
      this.formUnimestre.patchValue(this.student.studentUni);
      let birthdate = this.datepipe.transform(this.student.studentUni.birthDate , 'dd-MM-yyyy');
      this.formUnimestre.get('birthDate').setValue(birthdate);
    }

    this.getUnitStudents(0,20);
  }

  getStudentByEmail(email: string) {
    this.integrationService.getStudentByEmail(email)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.studentUni = response.data as uniStudent;
          this.formUnimestre.patchValue(this.studentUni);
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  createStudent() {
    this.studentUni = this.formUnimestre.getRawValue();

    this.integrationService.SaveUniStudent(this.studentUni)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Estudante cadastrado com sucesso!');
          this.studentUni = null;
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getUnitStudents(offset: number , pagesize:number) { 
    this.integrationService.getUnitStudents(offset, pagesize)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalpage = res.body['totalPage'];

        if (!response.error) {
          this.unitStudents = response.data as uniStudent[];
          this.totalPage = totalpage as number;
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  deleteUniStudent(uniId: number) {
    if (confirm('Deseja realmente excluir o aluno ?')) {
      this.integrationService.deleteUniStudent(uniId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('estudante deletado com sucesso!');
            this.getUnitStudents(0,20);
          }
        })
    }
  }

  getUniStudentsByName(name: string){
    if(name){
    this.integrationService.getUniStudentsByName(name)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.unitStudents = response.data as uniStudent[];
      }
      else{
        this.alertService.error(response.error);
      }
    })
    }
    else{
      this.alertService.error('Você precisa prencher o campo de nome antes de fazer a busca!');
    }
  }

  edituni(studentUni: uniStudent) {
    this.editMode = true;
    const aux = true;
    const dialogRef = this.dialog.open(IntegrationUnimestreComponent, {
      minWidth: '60vw',
      data: {studentUni , aux }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        //console.log('fechou o modal');
        this.editMode = false;
        this.getUnitStudents(0,20);
      }
    });

    dialogRef.backdropClick().subscribe(() => {
      this.editMode = false;
      this.updateList();
    })
  }

  updateList(){
    this.editMode = false;
    this.getUnitStudents(0,20);
  }

  updateStudent(){
    this.studentUni = this.formUnimestre.getRawValue();

    this.integrationService.updateUniStudent(this.studentUni)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Estudante editado com sucesso!');
          this.studentUni = null;
          this.dialogRef.close(true);
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  pageEvent(event: any) {
    const offset = event.pageIndex * event.pageSize;

      this.getUnitStudents(offset, event.pageSize);
  }

}
