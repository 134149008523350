<div class="maindiv">
    <div *ngIf="!modalConf.edit">
        <label class="title ml-2">Criar Nova Disciplina </label>

        <div class="mt-4">
            <label class="center mylabel ml-2">Nome da disciplina</label>
            <p><input [(ngModel)]="disciplineName" placeholder="Nome" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
        </div>
        <div class="mt-4">
            <label class="center mylabel ml-2">Tag</label>
            <p><input maxlength="5" [(ngModel)]="disciplineTag" placeholder="Digite uma tag" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
        </div>
        <div class="mt-2">
            <button (click)="createDiscipline(disciplineName , disciplineTag)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
        </div>
    </div>

    <div *ngIf="modalConf.edit">
        <label class="title ml-2">Editar Disciplina</label>
        <p><label class="subtitle ml-2">Código da disciplina : {{modalConf.discipline.code}}</label></p>

        <div class="mt-4">
            <label class="center mylabel ml-2">Nome da disciplina</label>
            <p><input [(ngModel)]="disciplineName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
        </div>
        <div class="mt-4">
            <label class="center mylabel ml-2">Tag</label>
            <p><input [(ngModel)]="disciplineTag" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
        </div>
        <div class="mt-2">
            <button (click)="updateDiscipline(disciplineName , disciplineTag)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
        </div>
    </div>
</div>