<div class="mb-3" [ngClass]="{'text-center width-80':layoutService.isMobile, 'width-90': !layoutService.isMobile}">
    <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
        <span class="page-title"></span>
        <div class="mt-3 description-title">
            <h4 *ngIf="!layoutService.isMobile">Avaliação</h4>
            <h6 class="mb-0" *ngIf="layoutService.isMobile">Avaliação</h6>
            <p class="fs-13 font-color-default evaluation">Confira como está a sua média nos cursos cadastrados</p>
        </div>
    </div>
</div>
<div class="row align-items-center">
    <div class="col-md" id="filter-plan">
        <mat-form-field class="width-100 m-0 p-0 form-field">
            <mat-select class="p-0 m-0 form-select" placeholder="Plano de estudos" (selectionChange)="planChange($event.value)" [(ngModel)]="planId">
                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                    {{plan.name}}
                </mat-option>
            </mat-select> 
        </mat-form-field>
    </div>
    <div class="col-md" id="filter">
        <mat-form-field class="width-100 m-0 p-0 form-field" (click)="pickerBegin.open()">
            <mat-label>Início</mat-label>
            <input class="pointer" matInput [matDatepicker]="pickerBegin" [(ngModel)]="dateBegin" readonly>
            <mat-datepicker-toggle [ngClass]="{'adjust-mr': !layoutService.isMobile}" #pickerBeginToggle matSuffix [for]="pickerBegin"></mat-datepicker-toggle>
            <mat-datepicker #pickerBegin></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md" id="filter">
        <mat-form-field class="width-100 m-0 p-0 form-field" (click)="pickerEnd.open()">
            <mat-label>Fim</mat-label>
            <input class="pointer" matInput [matDatepicker]="pickerEnd" [(ngModel)]="dateEnd" readonly>
            <mat-datepicker-toggle [ngClass]="{'adjust-mr': !layoutService.isMobile}" #pickerEndToggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <button class="width-100" mat-raised-button color="accent" (click)="getAverageData()">Filtrar</button>
    </div>
</div>
<mat-spinner *ngIf="loading" class="center" color="accent"></mat-spinner>
<div class="row mt-4 mb-2" *ngIf="average && !loading ">
    <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
        <div class="average-card p-3 m-2">
            <div class="average-card-header">
                <mat-icon class="star-icon" svgIcon="statistics"></mat-icon>
                <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="É o total de exercícios resolvidos no Período / pelo total de questões disponíveis"
                    matTooltipHideDelay="2000">
                </mat-icon>
            </div>
            <div class="average-card-title mt-3">
                <div class="title">Desempenho</div>
                <div class="small">{{dateBegin | date:'dd/MM'}} - {{dateEnd | date:'dd/MM'}}</div>
            </div>
            <div class="average-card-botton">
                <div class="value mt-3">{{average.performance}}%</div>
            </div>
        </div>
    </div>
    <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
        <div class="average-card p-3 m-2">
            <div class="average-card-header">
                <mat-icon class="star-icon" svgIcon="protest"></mat-icon>
                <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="Porcentagem entre o número de dias que o aluno fez login na plataforma no período selecionado e a quantidade de dias letivos deste mesmo período"
                    matTooltipHideDelay="2000">
                </mat-icon>
            </div>
            <div class="average-card-title mt-3">
                <div class="title">Participação</div>
                <div class="small">{{dateBegin | date:'dd/MM'}} - {{dateEnd | date:'dd/MM'}}</div>
            </div>
            <div class="average-card-botton">
                <div class="value mt-3">{{average.participation}}%</div>
            </div>
        </div>
    </div>
    <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
        <div class="average-card p-3 m-2">
            <div class="average-card-header">
                <mat-icon class="star-icon" svgIcon="books"></mat-icon>
                <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="Mostra o progresso no seu plano de estudo! Videos visualizados e exercícios feitos"
                    matTooltipHideDelay="2000">
                </mat-icon>
            </div>
            <div class="average-card-title mt-3">
                <div class="title">Plano de estudos</div>
                <div class="small">{{dateBegin | date:'dd/MM'}} - {{dateEnd | date:'dd/MM'}}</div>
            </div>
            <div class="average-card-botton">
                <div class="value mt-3">{{average.studyPlan}}%</div>
            </div>
        </div>
    </div>
    <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
        <div class="average-card p-3 m-2">
            <div class="average-card-header">
                <mat-icon class="star-icon" svgIcon="average"></mat-icon>
                <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="Esta média é a a porcentagem de nota dos acertos e erros dos exercícios"
                    matTooltipHideDelay="2000">
                </mat-icon>
            </div>
            <div class="average-card-title mt-3">
                <div class="title">Média</div>
                <div class="small">{{dateBegin | date:'dd/MM'}} - {{dateEnd | date:'dd/MM'}}</div>
            </div>
            <div class="average-card-botton pt-3">
                <mat-progress-bar class="mt-1" mode="buffer" color="accent" [value]="70" [bufferValue]="100">
                </mat-progress-bar>
            </div>
        </div>
    </div>
</div>
<mat-spinner *ngIf="!average" color="primary" class="center mt-4"></mat-spinner>