import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { Ebook} from '../admin/flip-book-admin/flipBook';
import { StudyPlan, StudyPlansSelect } from '../admin/study-plan/study-plan';
import { StudyPlanService } from '../admin/study-plan/study-plan.service';
import { FlipBookService } from '../flip-book/flip-book.service';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-flip-book-student',
  templateUrl: './flip-book-student.component.html',
  styleUrls: ['./flip-book-student.component.scss']
})
export class FlipBookStudentComponent implements OnInit {

  public studyPlans: StudyPlansSelect[];
  public ebooks: Ebook[];
  public planName: string;
  public none: boolean;
  public displayedColumns = [];
  public ebooksDataSource: MatTableDataSource<Ebook>;
  public firstPlanId: StudyPlansSelect;
  public planSelected: StudyPlansSelect;
  public loadingEbooks: boolean;

  public channelId: number;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private studyPlanService: StudyPlanService,
    public layoutService: LayoutService,
    private flipBookService: FlipBookService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.none = true;
    this.displayedColumns = this.layoutService.isMobile ? ['name'] : ['name', 'link'];

    //path:  ebook/channel/:channelId', path: 'student/flip-book/channel/:channelId'

    this.channelId = Number(this.route.snapshot.paramMap.get('channelId'));
    console.log(this.channelId);
    if(!this.channelId || this.channelId == undefined){ 
      this.getPlans();
    }else{
      this.getChannelPlans();
    }
  }

  getChannelPlans(){
    this.studyPlanService.getChannelPlansWithEbooks(this.channelId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.studyPlans = response.data as StudyPlansSelect[];
        //if(this.layoutService.isMobile) {
          this.firstPlanId = this.studyPlans[0];
          this.getEbooks(this.firstPlanId);
          this.studyPlans.forEach(element => {
            if(element.id == this.firstPlanId.id){
              this.planName = element.name;
              this.planSelected = element;
              this.firstPlanId = element;
            }
          });
        //}
      }
    },err => {
      this.alertService.error('NÃ£o foi possivel buscar os dados verifique a conexÃ¢o e tente novamente');
    })
  }

 
  getPlans(){
    this.studyPlanService.getStudentPlansWithEbooks(this.userService.getUserId())
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.studyPlans = response.data as StudyPlansSelect[];
        // if(this.layoutService.isMobile) {
          this.firstPlanId = this.studyPlans[0];
          this.getEbooks(this.firstPlanId);
          this.studyPlans.forEach(element => {
            if(element.id == this.firstPlanId.id){
              this.planName = element.name;
              this.planSelected = element;
              this.firstPlanId = element;
            }
          });
        // }
      }
    },err => {
      this.alertService.error('NÃ£o foi possivel buscar os dados verifique a conexÃ¢o e tente novamente');
    })
  }

  getEbooks(plan: StudyPlansSelect){
    this.loadingEbooks = true;
    this.none = true;
    this.planName = plan.name;
    this.flipBookService.getEbooks(plan.id)
    .subscribe(res => {
      const response = res.body as any;
      if (!response.error) {
        this.ebooks = response as Ebook[];
        this.ebooksDataSource = new MatTableDataSource(this.ebooks);
      }
      else {
        this.ebooks = [];
        this.alertService.error(response.error);
      }
      this.loadingEbooks = false;
    }, err => {
      this.alertService.error('NÃ£o foi possivel buscar os dados verifique a conexÃ£o e tente novamente!');
    })
  }

  openFlipBook(bookId: number , planName:string){
    this.flipBookService.setNamePlan(planName);
    this.router.navigateByUrl('view/flip-book/'+bookId);
  }
}
