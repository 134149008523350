<div class="page-container p-pg page-container-mobile">
    <div class="row m-0">
        <div class="mt-2" [ngClass]="{'col-12 text-center': layoutService.isMobile, 'col-md-6': !layoutService.isMobile}">
            <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                <span class="page-title">Redações</span>
                <div class="mt-3 description-title">
                    <h4 *ngIf="!layoutService.isMobile">Minhas redações</h4>
                    <h6 class="mb-0" *ngIf="layoutService.isMobile">Minhas redações</h6>
                    <p class="fs-13 pb-0">Envie e acompanhe as suas redações</p>
                </div>
            </div>
        </div>
        <div class="mt-2 align-self-end text-right" [ngClass]="{'col-12 text-center': layoutService.isMobile, 'col-md-6': !layoutService.isMobile}">
            <button class="button-send btn-large" routerLink="../send" mat-raised-button color="accent">
                Nova redação
            </button>
        </div>
        <div class="col-12 mt-3" *ngIf="showRedactions">
            <ng-container>
                <table *ngIf="!loading && dataSource?.data?.length && !redactionToView" mat-table color="accent" [dataSource]="dataSource" class="width-100 center height-100" multiTemplateDataRows>
                    <ng-container matColumnDef="status">
                        <th class="border-bottom-none disciplina brl-5" [ngClass]="{'text-left pl-15px font-th': layoutService.isMobile}" mat-header-cell *matHeaderCellDef>STATUS</th>
                        <td mat-cell *matCellDef="let redaction; let i = index" class="border-none border-bottom-none" [ngClass]="{'table-line-contrast': i % 2, 'pl-15px': !layoutService.isMobile, 'pl-0': layoutService.isMobile}">
                            <div class="align-itens-center border-none border-bottom-none">
                                <ng-container *ngIf="redaction.essayError != 1 && redaction.score == null">
                                    <span class="d-flex">
                                        <mat-icon class="fs-30 mt--3px color-back" [hidden]="!layoutService.isMobile">arrow_right</mat-icon>
                                        <mat-icon class="waiting-correction mr-2" [ngClass]="{'mr-0 fs-20': layoutService.isMobile}">schedule</mat-icon>
                                        <span class="fs-status mt-1 d-flex">Aguardando correção - Id: <b>&nbsp;{{redaction.id}}</b></span>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="redaction.essayError != 1 && redaction.score >= 0 && redaction.score != null">
                                    <span class="d-flex">
                                        <mat-icon class="fs-30 mt--3px color-back" [hidden]="!layoutService.isMobile">arrow_right</mat-icon>
                                        <mat-icon class="mr-2" color="accent" [ngClass]="{'mr-0 fs-20': layoutService.isMobile}">check_circle_outline</mat-icon>
                                        <span class="fs-status mt-1 d-flex">Corrigida - Id: <b>&nbsp;{{redaction.id}}</b></span>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="redaction.essayError == 1">
                                    <span class="d-flex">
                                        <mat-icon class="fs-30 mt--3px color-back" [hidden]="!layoutService.isMobile">arrow_right</mat-icon>
                                        <mat-icon class="mr-2 color-orange" [ngClass]="{'mr-0 fs-20': layoutService.isMobile}">block</mat-icon>
                                        <span class="fs-status mt-1 d-flex">Inválida - Id: <b>&nbsp;{{redaction.id}}</b></span>
                                    </span>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="score">
                        <th mat-header-cell *matHeaderCellDef> NOTA </th>
                        <td mat-cell *matCellDef="let redaction; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                            <ng-container *ngIf="redaction.score >= 0; else pendingScore">
                                {{redaction.score | number: '2.2-2'}}
                            </ng-container>
                            <ng-template #pendingScore>
                                ----
                            </ng-template>
                    </ng-container>
                    <ng-container matColumnDef="sent">
                        <th mat-header-cell *matHeaderCellDef> ENVIO </th>
                        <td mat-cell *matCellDef="let redaction; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                            {{redaction.sendDate | date: !layoutService.isMobile ? 'dd/MM/yyyy' : 'dd/MM'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="open">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> VER </th>
                        <td mat-cell *matCellDef="let redaction; let i = index" class="text-center" color="accent" [ngClass]="{'table-line-contrast': i % 2}">
                            <button mat-button color="accent" (click)="selectRedaction(redaction)">Ver Redação</button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cancel">
                        <th mat-header-cell *matHeaderCellDef class="brr-5 text-center pr-15px min-w-70px" [ngClass]="{'font-th': layoutService.isMobile}"> CANCELAR </th>
                        <td mat-cell *matCellDef="let redaction; let i = index" class="text-center pr-15px redaction-row min-w-70px border-none" [ngClass]="{'table-line-contrast': i % 2}">
                            <div class="align-itens-center redaction-row text-center pr-15px">
                                <ng-container>
                                    <mat-icon *ngIf="!redaction.score || redaction.essayError == 1" class="c-pointer incorrect pr-5px" (click)="remove(redaction.id)">
                                        delete_forever
                                    </mat-icon>
                                </ng-container>
                                <ng-container>
                                    <mat-icon *ngIf="redaction.essayError != 1 && redaction.score" class="c-pointer incorrect pr-5px" (click)="remove(redaction.id)">
                                        highlight_off
                                    </mat-icon>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                    <!-- Expanded Content Column -->
                    <ng-container matColumnDef="expandedDetail" [ngClass]="{'d-block': layoutService.isMobile}">
                        <td mat-cell *matCellDef="let redaction" [attr.colspan]="displayedColumns.length">
                            <div class="redaction-detail" [@detailExpand]="redaction == expandedRedaction ? 'expanded' : 'collapsed'">
                                <div class="redaction-description width-100">
                                    <div class="width-100">
                                        <ng-container *ngIf="redaction.score >= 0; else pendingScore">
                                            <b>Nota: </b>{{redaction.score | number: '2.2-2'}}
                                        </ng-container>
                                        <ng-template #pendingScore>
                                            <b>Nota:</b> Pendente
                                        </ng-template>
                                    </div>
                                    <div class="width-100"> <b>Data envio:</b> {{redaction.sendDate | date: 'dd/MM'}}</div>
                                    <div class="width-100">
                                        <button mat-button color="accent" (click)="selectRedaction(redaction)">Ver</button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <!-- MOBILE -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" [hidden]="!layoutService.isMobile"></tr>
                    <tr mat-row *matRowDef="let redaction; columns: displayedColumns;" [hidden]="!layoutService.isMobile" [class.redaction-expanded-row]="expandedRedaction == redaction" (click)="expandedRedaction = expandedRedaction == redaction ? null : redaction" class="border-bottom-none">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="redaction-detail-row" [hidden]="!layoutService.isMobile"></tr>
                    <!-- DESKTOP -->
                    <tr mat-header-row class="brl-5 brr-5" *matHeaderRowDef="displayedColumns" [hidden]="layoutService.isMobile"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="layoutService.isMobile"></tr>
                </table>
            </ng-container>
        </div>
        <div class="col-12">
            <!-- empty essays -->
            <div *ngIf="!dataSource?.data?.length && !loading" class="p-pg" [ngClass]="{'center text-center': layoutService.isMobile, 'width-70': !layoutService.isMobile}">
                <h4>Nenhuma redação</h4>
                <br />
                <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
            </div>
            <!-- view redaction -->
            <div *ngIf="redactionToView" class="width-90 center mt-3 mb-5">
                <nb-card *ngIf="redactionToView.score">
                    <nb-card-body>
                        <div class="row">
                            <div class="col-md-4">
                                <!-- <div>Corretor: {{redactionToView.corrector}}</div> -->
                                <div style="font-size: 20px; font-weight: bold;">
                                    Nota:
                                    <span style="font-size: 25px;" class="text-color-primary">
                                        {{redactionToView.score | number: '2.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </nb-card-body>
                </nb-card>
                <button class="mt-3 mb-3 hvr-grow" (click)="redactionToView = null" nbButton shape="round" outline status="primary" size="large">
                    <nb-icon icon="arrow-back-outline"></nb-icon>
                </button>
                <div class="redaction-online" *ngIf="redactionToView.correctionUrl == 'Online'">
                    <div class="height-100" [innerHTML]="redactionToView.correctionText"></div>
                </div>
                <div class="redaction-online" *ngIf="redactionToView.correctionBase64">
                    <div>
                        <iframe width="100%" height="100%" class="p-3 back-white" [src]="innerHTMLBypass(redactionToView.correctionBase64)" frameborder="0"></iframe>
                    </div>
                </div>
                <div *ngIf="redactionToView.correctionUrl != 'Online' && !redactionToView.correctionBase64">
                    <img class="width-100" [src]="redactionToView.correctionUrl">
                </div>
                <div class="redaction-online" *ngIf="redactionToView.correctionBase64 && redactionToView.themeName == 'Enem' ">
                    <nb-card>
                        <nb-card-body>
                            <div class=" competence competence_1_comment">
                                <h4>Competência 1</h4>
                                <div class="commnet w-100"> {{redactionToView.correction.competence_1_comment}}
                                </div>
                                <h4 class="m-2">Nota</h4>
                                <div class="score w-100"> {{redactionToView.correction.competence_1_rate}}</div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <nb-card>
                        <nb-card-body>
                            <div class=" competence competence_2_comment ">
                                <h4>Competência 2</h4>
                                <div class="commnet w-100"> {{redactionToView.correction.competence_2_comment}}
                                </div>
                                <h4 class="m-2">Nota</h4>
                                <div class="score w-100"> {{redactionToView.correction.competence_2_rate}}</div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <nb-card>
                        <nb-card-body>
                            <div class=" competence competence_3_comment">
                                <h4>Competência 3</h4>
                                <div class="commnet w-100"> {{redactionToView.correction.competence_3_comment}}
                                </div>
                                <h4 class="m-2">Nota</h4>
                                <div class="score w-100"> {{redactionToView.correction.competence_3_rate}}</div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <nb-card>
                        <nb-card-body>
                            <div class=" competence competence_4_comment">
                                <h4 class="m-2">Competência 4</h4>
                                <div class="commnet w-100"> {{redactionToView.correction.competence_4_comment}}
                                </div>
                                <h4 class="m-2">Nota</h4>
                                <div class="score w-100"> {{redactionToView.correction.competence_4_rate}}</div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <nb-card>
                        <nb-card-body>
                            <div class=" competence competence_5_comment">
                                <h4>Competência 5</h4>
                                <div class="commnet w-100"> {{redactionToView.correction.competence_5_comment}}
                                </div>
                                <h4 class="m-2">Nota</h4>
                                <div class="score w-100"> {{redactionToView.correction.competence_5_rate}}</div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <nb-card *ngIf="redactionToView.correction.general_comment">
                        <nb-card-body>
                            <div class="general_comment">
                                <h4>Considerações Finais</h4>
                                <div class="w-100"> {{redactionToView.correction.general_comment}}</div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                </div>
                <div class="redaction-online" *ngIf="redactionToView.correctionBase64 && redactionToView.themeName == 'Vestibular' ">
                    <nb-card *ngIf="redactionToView.correction.general_comment">
                        <nb-card-body>
                            <div class="general_comment">
                                <h4>Comentário Geral</h4>
                                <div class="w-100"> {{redactionToView.correction.general_comment}}</div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
            <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
        </div>
    </div>
</div>
