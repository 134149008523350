<div class="row" *ngIf="userService.isAdmin()">
    <div class="col-md-12">
        <span *ngIf="!schoolId" class="text-small" style="color:red;">Selecione uma Escola para poder pesquisar por EMAIL OU NOME!</span>
    </div>
</div>
<div class="row">
    <div class="col-md-12" *ngIf="userService.isAdmin()">
        <mat-form-field appearance="outline" class="width-100">
            <mat-label>Escolas</mat-label>
            <mat-select placeholder="Selecione uma escola" (selectionChange)="getPaymentPlans($event.value)" >
                <mat-option *ngFor="let school of schools " [value]="school.id">
                    {{school.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <span *ngIf="!filterPlanId && schoolId" class="text-small" style="color:red;">Selecione um Plano para poder pesquisar por EMAIL OU NOME!</span>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <mat-form-field appearance="outline" class="width-100">
            <mat-label>Planos / [Produto]</mat-label>
            <mat-select placeholder="Plano" (selectionChange)="getPayers($event.value)"
                [(ngModel)]="filterPlanId">
                <mat-option *ngFor="let plan of paymentsPlans$ | async" [value]="plan.paymentPlanId">
                    PLANO: {{plan.name}} /  [PRODUTO: {{plan.paymentName}}]
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row" >
    <div class="col-md-6">
        <mat-form-field class="width-100">
            <input [(ngModel)]="filterName" matInput placeholder="Buscar aluno por nome" [disabled]="!filterPlanId">
            <button (click)="getUserByName(filterName)" class="ml-2" mat-icon-button
                color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="col-md-6">
        <mat-form-field class="width-100">
            <input [(ngModel)]="filterEmail" matInput placeholder="Buscar aluno por e-mail" [disabled]="!filterPlanId">
            <button (click)="getUserByEmail(filterEmail)" class="ml-2"
                mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </div>                    
</div>
<div class="row mt-5">
    <nb-list class="width-100 mt-2">
        <nb-list-item *ngIf="users$">
            <div class="row">
                <div class="col-md-6"><strong>Aluno</strong></div>
                <div class="col-md-4"><strong>Plano Selecionado</strong></div>
                <div class="col-md-2"><strong>&nbsp;</strong></div>                
            </div>
        </nb-list-item>
        <nb-list-item *ngFor="let user of users$ | async">
            <div class="row">
                <div class="col-md-6">
                    <span class="text-small">{{user.name}} - {{user.email}}</span>
                </div>
                <div class="col-md-4">
                    <span class="text-small">{{planName}}</span>
                </div>
                <div class="col-md-2">
                    <button size="tiny" (click)="createPayment(user)" status="success" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Criar pagamento">
                        Criar pagamento
                    </button>
                </div>
            </div>
        </nb-list-item>                        
    </nb-list>
    <mat-spinner *ngIf="users$ && !(users$ | async)" class="center mt-5 mb-3"></mat-spinner>
</div>