import { ModalLiveComponent } from './modal-live/modal-live.component';
import { Component, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Notifications, NotificationUpdateGeral } from 'src/app/modules/admin/notifications/notifications';
import { NotificationBell, NotificationLiveClass } from 'src/app/modules/admin/notifications/notifications';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ResponseApi } from '../../models/response-api';
import { ModalBellComponent } from './modal-bell/modal-bell.component';
import { ModalUpdateComponent } from './modal-update/modal-update.component';
import { NotificationsService } from './notifications.service';
import { AuthService } from '../../auth/auth.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { Subscription } from 'rxjs';
import { NoticationFactory } from './notification.factory';
import { NotificationStore } from './notifications.store';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public notificationsBell: NotificationStore = new NotificationStore();
  public notificationsUpdate: NotificationStore = new NotificationStore();
  public notificationsLive: NotificationStore = new NotificationStore();
  public notifications: Notifications[];
  public loading: boolean;
  public active: boolean;
  public notifyBell: boolean;
  public notifyLiveClass: boolean;
  public notifyUpdate: boolean;
  public totalNotificationsBell: number;
  private admUpdate$: Subscription;
  private lastNtfId:number; 
  public schoolId: number;
  public unitId: number;
  public classId: number;
  public profileId: number;
  public userId: number;
  
  constructor(
    @Optional() public dialogRef: MatDialogRef<NotificationsComponent>,
    private dialog: MatDialog,
    public userService: UserService,
    public notificationsService: NotificationsService,
    private alertService: AlertService, 
    public layoutService: LayoutService,
    private authService: AuthService,
    public adminService: AdminService,
    private ntffactory: NoticationFactory
  ) { }

  ngOnInit(): void {     
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init()); 
  }
  

  init(){
    this.totalNotificationsBell = 0;
    this.userId = Number(this.userService.getUserId());
    this.profileId = Number(this.userService.getUserProfileId());
    this.schoolId = Number(this.userService.getUserSchool());
    this.unitId = Number(this.userService.getUserUnit());
    this.classId = Number(this.userService.getUserClass());
    this.notifications = [];
    this.getAllNotificationsByUser(this.schoolId, this.unitId, this.classId, this.userId, this.profileId);

    setInterval(() => {
      this.getReloadNotificationsByUser();
      //this.totalNotificationsBell = this.ntffactory.create('Sininho', this.notifications).count();
    }, 300000);//A CADA MINUTO ***OLD 180000 A CADA 3 MINUTOS***

    this.notificationsService.emitCheckNotifications.subscribe((data)=>{
     
      this.totalNotificationsBell = data;
    });
  }
  
  openModalNotificationsBell(){
    if(this.notificationsBell != undefined){
      this.openNotificationsBell(this.notificationsBell);
    }else{
    
      this.openNotificationsBell(null);
    }
    
  }
  openNotificationsBell(notifications:NotificationStore): void {
    const dialogRef = this.dialog.open(ModalBellComponent, {
      data: notifications,
      maxWidth: '400px',
      maxHeight: '600px',
      panelClass: 'custom-dialog-container',
      position: {
        top: '80px',
        right: '30px'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }
  openNotificationsUpdate(notifications:NotificationStore): void {
    if (notifications.getAll().length != 0){
      const dialogRef = this.dialog.open(ModalUpdateComponent, {
        data: notifications,
        maxWidth: '350px',
        maxHeight: '600px',
        panelClass: 'custom-dialog-container',
        position: {
          top: '80px',
          right: '30px'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        notifications.getAllIds();
        //Marcar como lido todas UPDATE
        let resp = notifications.getAllIds();
        this.markAsReadNotifications(resp);
        this.notificationsUpdate.clear();
      });
    }
  }
  openNotificationsLive(notifications:NotificationStore): void {
    if (notifications.getAll().length != 0){
      const dialogRef = this.dialog.open(ModalLiveComponent, {
        data: notifications,
        maxWidth: '40vw',
        width: '400px'
      });

      dialogRef.afterClosed().subscribe(result => {
        notifications.getAllIds(); 
         //Marcar como lido todas LIVE
         let resp = notifications.getAllIds();
        this.markAsReadNotifications(resp);
        this.notificationsLive.clear();
      });
    }
  }

  getAllNotificationsByUser(schoolId:number, unitId:number, classId:number, userId:number, profile: number){
    
    this.notificationsService.getAllNotificationsByUser(schoolId, unitId, classId, userId, profile)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error ) {     
        this.notifications = response.data as Notifications[];
        this.updateNotificationChildrens(this.notifications);
      }
      this.loading = false;
    }, err => { 
      //this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente');
    });
  }
  getReloadNotificationsByUser(){
    this.lastNtfId = this.lastNtfId ? this.lastNtfId : 0;
    this.notificationsService.getReloadNotificationsByUser(this.schoolId, this.userId, this.profileId, this.lastNtfId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {        
        this.notifications = response.data as Notifications[];
        this.newUpdateNotificationChildrens(this.notifications);
      }
      this.loading = false;
    }, err => { 
      //this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente');
    });
  }
  
  updateNotificationChildrens(notifications: Notifications[]){
    this.notificationsBell   = this.ntffactory.create('Sininho',      notifications); 
    this.notificationsLive   = this.ntffactory.create('Aula ao Vivo', notifications); 
    this.notificationsUpdate = this.ntffactory.create('Atualização',  notifications); 
    this.totalNotificationsBell = this.notificationsBell.count();
 
    this.openNotificationsUpdate(this.notificationsUpdate);
    this.openNotificationsLive(this.notificationsLive);

    //TOTAL NOTIFICAÇÕES
    let ntf = notifications;
    this.lastNtfId = ntf.pop().id;
  }
  newUpdateNotificationChildrens(notifications: Notifications[]){
    this.notificationsBell.add( this.ntffactory.create('Sininho', notifications).getAll()); 
    this.notificationsLive.add( this.ntffactory.create('Aula ao Vivo', notifications).getAll()); 
    this.notificationsUpdate.add( this.ntffactory.create('Atualização', notifications).getAll());
    this.totalNotificationsBell = this.notificationsBell.count();
    this.openNotificationsUpdate(this.notificationsUpdate);
    this.openNotificationsLive(this.notificationsLive);

    let ntf = notifications;
    this.lastNtfId = ntf.pop().id;

      //TOTAL NOTIFICAÇÕES
  }

  markAsReadNotifications(notificationsIds: Number[]){
    const userId = Number(this.userService.getUserId());
    this.notificationsService.markAsReadNotifications(notificationsIds, userId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao salvar a notificação. Verifique a conexão e tente novamente'));
  }
/*   checkIfMessageIsRead(notifications: Notifications[]){
    let notificationsUnRead: Notifications[] = [];
    notifications.forEach(element => {
      if(!element.read){
        notificationsUnRead.push(element);
      }
    });
    notifications = notificationsUnRead;
    return notifications;
  } */


 /*  removeNotification(id:number){
    this.notifications.forEach(element => {
      element.remove(id)
    });
    this.notifications = this.data.getAll();
    this.notificationsService.emitCheckNotifications.emit(this.notifications.length);
  } */
}
