<div class="background-layout">
    <div class="row m-0">
        <h5 class="mb-5 mt-2 text-center width-100"> Reporte um erro</h5>
        <form class="w-100" [formGroup]="reportForm">
            <div class="col-md-12 marginboxtop mb-3">
                <textarea formControlName="description" width="100" maxlength="200" class="boxAnswer" matInput placeholder="Descrição do problema aqui .."></textarea>
            </div>
            <div class="col-12">
                <button class="button-comment small-btn width-100" *ngIf="!report.edit" 
                (click)="createReport()" [nbSpinner]="loading" 
                [ngClass]="{'salvar-mobile': layoutService.isMobile}" mat-raised-button color="accent" 
                [disabled]="!reportForm.valid || loading" nbSpinnerStatus="warning">
                    Enviar
                </button>
                <button class="button-comment small-btn width-100"  *ngIf="report.edit" (click)="updateReport()"
                nbButton size="large" status="primary" [ngClass]="{'salvar-mobile': layoutService.isMobile}"
                [nbSpinner]="loading" [disabled]="!reportForm.valid || loading" nbSpinnerStatus="warning">
                    Atualizar
                </button>
            </div>
        </form>
        <button mat-button class="mt-1 mb-1 hvr-grow text-center ml-auto mr-auto" color="accent" (click)="closeDialogRef()">
            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
        </button>
    </div>
</div>