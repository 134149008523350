<div *ngIf="!questionTypeConfig.edit" class="minhadiv">
    <label class="title ml-2">Criar Tipo de questão </label>

    <div class="mt-4">
        <p><input placeholder="Tipo da questão" [(ngModel)]="questionName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
    </div>
    <div class="mt-2">
        <button (click)="createTypeQuestion(questionName)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
    </div>
</div>

<div *ngIf="questionTypeConfig.edit" class="minhadiv">
    <label class="title ml-2">Editar Tipo de questão : {{questionTypeConfig.questionType.name}} </label>

    <div class="mt-4">
        <p><input placeholder="Tipo da questão" [(ngModel)]="questionName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
    </div>
    <div class="mt-2">
        <button (click)="updateQuestionType(questionName)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
    </div>
</div>