
/* 
export interface Channel {
    id: number;
    name: string;
    icon?: string;
    image?: string;
    tag?: string;
    active: boolean;
    totalPlans: number;
    totalFolders: number;
    totalEbooks: number;
    totalLives: number;

    plans: PlanSelect[];
    folders: FolderSelect[];
    ebooks: EbookSelect[];
    classrooms: ClassRoomSelect[];

    created_at: string | Date;
    updated_at: string | Date;
    deleted_at: string | Date;
} */

import { ResponseApi } from "src/app/core/models/response-api";
import { Channel } from "../../channel";
import { ChannelService } from "../../channel.service";
import { ChannelModel } from "../Models/Channel.model";

export class ManageChannelController{
    public  channels:ChannelModel[]=[];

    constructor(
        private channelService: ChannelService,
        private alertService:any,
        channels: Channel[] ){

        channels.forEach(chan => {
            this.channels.push(ChannelModel.newChannelModel(chan))
        });
         
    }
  
      
   
    public getOneChannel(channelId:number){
        return this.channels;
    }

    public getAllChannelsBySchool(){
        return this.channels;
    }  

    public getAllChannelsByUnit(){
        return this.channels;
    }  

    public updateChannel(channel:ChannelModel){

        this.channels =  this.channels.map((c)=> {

            if(c.getChannel().id === channel.getChannel().id){
                c.setChannel(channel.getChannel());
            }
            return c;
        })
    }

    public removeChannel(channelId:number){
        this.channels =  this.channels.filter((c)=> c.getChannel().id !== channelId )
        return this.channels;
    } 
}