import { Component, OnInit } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { School } from '../../admin/school/school';
import { UserService } from '../../user/user.service';
import { VideoService } from '../../video/video.service';

@Component({
  selector: 'app-tutor-mundi-answer',
  templateUrl: './tutor-mundi-answer.component.html',
  styleUrls: ['./tutor-mundi-answer.component.scss']
})
export class TutorMundiAnswerComponent implements OnInit {


  public lessons: string[];
  public lessonText: string;
  public answer: string;
  public ptLessons: string[];
  constructor(
    private userService: UserService,
    private videoService: VideoService
  ) { }

  ngOnInit() {
    this.lessons = [];
    this.ptLessons = [];
    this.setLessons();

  }

  setLessons() {
    this.lessons =
      ["Mathematics",
        "Writing",
        "Physics",
        "Chemistry",
        "Portuguese",
        "Biology",
        "History",
        "English",
        "Geography",
        "Spanish",
        "Sociology",
        "Philosophy"];

    this.ptLessons =
      ["Matemática",
        "Escrita",
        "Física",
        "Química",
        "Português",
        "Biologia",
        "História",
        "Inglês",
        "Geografia",
        "Espanhol",
        "Sociologia",
        "Filosofia"];
  }

  sendTuTorMundi(lesson: string, answer: string) {

    let strLesson = "";

    for(let i = 0 ; i < this.ptLessons.length ; i++){
      if(this.ptLessons[i] == lesson){
       strLesson = this.lessons[i];
      }
    }


    const baseUrl = 'https://app.tutormundi.com';
    const endpoint = 'api/post/search';

    const uriEncodedQuestion = window.encodeURIComponent(answer);

    const url = `${baseUrl}/${endpoint}/${strLesson}/${uriEncodedQuestion}`;

    window.open(url);
  }

}
