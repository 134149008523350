import { Component, OnInit, Input, Optional, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ForumComment } from './forum-comment';
import { ForumService } from '../forum.service';
import { UserService } from '../../user/user.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-forum-comment',
    templateUrl: './forum-comment.component.html',
    styleUrls: ['./forum-comment.component.scss']
})
export class ForumCommentComponent implements OnInit {

    public API = environment.ApiUrl;
    public BaseUrl = environment.baseUrl;
    public reply: boolean;
    public loading: boolean;

    // tslint:disable-next-line: max-line-length
    @Input() comment: ForumComment;
    @Input() isReply = false;
    @Input() isForumManagement = false;
    @Output() deleteEvent = new EventEmitter();

    constructor(
        private forumService: ForumService,
        public userService: UserService,
        private router: Router) { }

    ngOnInit() {
        this.comment.image = !this.comment.image
            ? this.API + '/store/students/avatar.png'
            : this.comment.image;

        this.comment.name = !this.comment.name
            ? 'Anônimo'
            : this.comment.name;

    }

    like() {

        if (this.comment.liked) {
            return;
        } else
            if (this.comment.disliked) {
                this.comment.dislike--;
            }

        this.comment.disliked = false;
        this.comment.liked = true;
        this.comment.like++;

        this.forumService.like(this.comment.id, this.userService.getUserId())
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (response.error) {
                    this.comment.liked = false;
                    this.comment.like--;
                }
            }, err => this.comment.liked = false);
    }

    dislike() {

        if (this.comment.disliked) {
            return;
        } else
            if (this.comment.liked) {
                this.comment.like--;
            }

        this.comment.liked = false;
        this.comment.disliked = true;
        this.comment.dislike++;

        this.forumService.dislike(this.comment.id, this.userService.getUserId())
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (response.error) {
                    this.comment.disliked = false;
                    this.comment.dislike--;
                }
            }, err => this.comment.disliked = false);
    }

    isMyComment() {
        // tslint:disable-next-line: triple-equals
        return this.comment.userId == this.userService.getUserId() ? true : false;
    }

    delete() {
        this.forumService.delete(this.comment.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.deleteEvent.emit(true);
                }
            });
    }

    enableComment(commentId: number) {
        this.loading = true;
        this.forumService.enableComment(commentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.comment.active = true;
                }

                this.loading = false;
            });
    }

    disableComment(commentId: number) {
        this.loading = true;
        this.forumService.disableComment(commentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.comment.active = false;
                }

                this.loading = false;
            });
    }

    replyEvent(comment: ForumComment) {
        this.reply = false;
        this.comment.comments.push(comment);
    }

    deleteEventCallback(index: number) {
        this.comment.comments.splice(index, 1);
    }

    openVideo(videoId: number) {
        window.open(window.location.origin + `/video/watch/`+ null +`/` + null + `/` + videoId, '_blank');
    }
}