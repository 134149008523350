<div *ngIf="!showUnitsList && !loading">
    <form [formGroup]="createUnitForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Unidade</h4>
        <h6 *ngIf="editMode" class="mt-3 mb-3">Total alunos: {{unit.total}}</h6>
        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>
        </div>

        <!-- Color, Logo -->
        <div class="row" *ngIf="userService.isAdmin()">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" placeholder="Escola" (selectionChange)="getDisciplines($event.value); getPlans($event.value);">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <div class="row">
                                <div class="col-10">
                                    <span [title]="school.name">{{school.name}}</span> 
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- disciplines, plans-->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select #disciplineSelect [(ngModel)]="disciplinesModel" [ngModelOptions]="{standalone: true}" placeholder="Disciplinas" (selectionChange)="disciplineSelectionChange($event.value)" multiple [disabled]="!createUnitForm.get('school').value">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar disciplina" noEntriesFoundLabel="Disciplina não encontrada" [formControl]="disciplineFilter" [searching]="filtering">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let discipline of filteredDisciplines | async" [value]="discipline.id">
                                <span [title]="discipline.name">{{discipline.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedDisciplines?.length > 0">
                    <mat-label class="mb-2">Disciplinas selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedDisciplines">
                        <mat-chip *ngFor="let disciplineChip of selectedDisciplines" selectable="true" removable="true" (removed)="removeDisciplineChip(disciplineChip)">
                            {{disciplineChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select [(ngModel)]="plansModel" [ngModelOptions]="{standalone: true}" placeholder="Planos" (selectionChange)="planSelectionChange($event.value)" multiple [disabled]="!createUnitForm.get('school').value">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar plano" noEntriesFoundLabel="Plano não encontrado" [formControl]="planFilter" [searching]="filtering">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let plan of filteredPlans | async" [value]="plan.id">
                                <span [title]="plan.name">{{plan.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedPlans?.length > 0">
                    <mat-label class="mb-2">Planos selecionadas</mat-label>
                    <mat-chip-list *ngIf="selectedPlans">
                        <mat-chip *ngFor="let planChip of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(planChip)">
                            {{planChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </form>


    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver unidades
        cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createUnit()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5">
        <div *ngIf="editMode" class="text-left col-6">
            <button (click)="duplicateUnit()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Duplicar</button>
        </div>

        <div *ngIf="editMode" class="text-right col-6">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </div>
</div>

<div *ngIf="showUnitsList && !loading" class="">
    <nb-card size="small">
        <nb-card-header *ngIf="userService.isAdmin()">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Escola" [(ngModel)]="filterSchoolId" (selectionChange)="getSchoolUnits($event.value); getDisciplines($event.value); getPlans($event.value);">
                            <mat-option *ngFor="let school of filterSchools" [value]="school.id">
                                <span [title]="school.name">{{school.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-list *ngIf="units" class="width-90 mt-3 center">
                <nb-list-item *ngFor="let unit of units">
                    <div class="row">
                        <div class="col-4">
                            <!-- {{unit.school.name}} -->- {{unit.name}}
                        </div>
                        <div class="col-4">
                            criado em {{unit.createDate | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="col-2 text-right">
                            <button (click)="getUnitOne(unit.id)" nbButton size="tiny" outline status="primary">Editar</button>
                        </div>
                        <div class="col-2 text-right">
                            <button size="tiny" (click)="removeUnit(unit.id)" status="danger" nbButton ghost>
                <nb-icon icon="trash"></nb-icon>
              </button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>

    <div class="text-right width-100">
        <button (click)="showUnitsList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>

<div class="">
    <mat-spinner class="center" *ngIf="loading"></mat-spinner>
</div>