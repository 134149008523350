<div class="mb-4 my-full-class" (mousemove)="openMaskHover()" (mousestop)="closeMaskOver()">
  <!--  <h4>titulo 1</h4> -->
  <div *ngIf="showMask" style="z-index: -1000!important;">
    <youtube-player width="100%" [height]="heightscreen" [videoId]="video?.embed.code" (ready)="savePlayer($event)"
      [playerVars]="{'controls': 0 ,'rel' : 0}">
    </youtube-player>
  </div>

  <div *ngIf="!showMask" style="z-index: -1000!important;">
    <youtube-player width="100%" [height]="heightscreen" [videoId]="video?.embed.code" (ready)="savePlayer($event)">
    </youtube-player>
  </div>

  <!--DIV POR CIMA DO IFRAME [ngClass]="{'colorMaskTransparent': playYoutube , 'colorMaskOverflow' : !playYoutube}"-->
  <div *ngIf="showMask" style="z-index: 1000!important;" class="overlayYoutube" (click)="openMask()"
    [ngClass]="{'maskOff': playYoutube , 'maskOn' : !playYoutube}">
    <!--  *ngIf="!playYoutube" -->

  </div>

  <div class="div-overflow" [ngClass]="{'row ml-4': layoutService.isMobile}" style="z-index: 1001!important;"
    *ngIf="!playYoutube && showMask && !layoutService.isMobile">
    <div class="row testnewMask">
      <div class="col-md-2">
        <button *ngIf="showPlay" (click)="playVideoYT()" [ngClass]="{'col d-flex': layoutService.isMobile}"
          class="btcircle circle-play2" mat-button><mat-icon>play_circle</mat-icon></button>
        <button *ngIf="!showPlay" (click)="pauseVideo()" [ngClass]="{'col d-flex': layoutService.isMobile}"
          class="btcircle circle-play2" mat-button><mat-icon>pause_circle</mat-icon></button>
      </div>
      <div class="col-md-6" *ngIf="!layoutService.isMobile">
        <div class="row">
          <mat-slider [title]="youtubeTimeShow" (change)="goYoutubeTime()" (input)="slideMove($event)" [(value)]="youtubeTime" class="slider-sound width-70"
            horizontal min="0" [max]="youtubeDuration" step="1"></mat-slider>
            <div class="mt-3 ml-2" style="color:white;">
              <label>{{youtubeTimeShow}} / {{youtubeDurationShow}}</label>
            </div>
          </div>
      </div>
      <div class="col-md-2">
        <div class="row">
          <mat-icon *ngIf="youtubeVolume > 0" class="sound-icon_new mt-2">volume_up</mat-icon>
          <mat-icon *ngIf="youtubeVolume == 0" class="sound-icon_new mt-2">volume_off</mat-icon>
          <mat-slider class="slider-sound_new" (change)="setVolumeYoutube()" horizontal style="color: white;" min="0"
            max="100" step="1" [(value)]="youtubeVolume"></mat-slider>
        </div>
      </div>
      <div class="col-md-2 mt-2">
        <div class="row">
          <div [ngClass]="{'divconfigmain' : showConfig}">
            <div *ngIf="showConfig" class="divconfig">
              <div class="ml-1 mt-3 row"><label>Velocidade: </label> <mat-icon (click)="setVideoRate()"
                [ngClass]="{'btnrateNew': !layoutService.isMobile , 'rateBtnMobile' : layoutService.isMobile}">slow_motion_video<label>{{rateVideo}}</label></mat-icon></div>
                <div class="ml-1 mt-3 row"><label>Qualidade: </label> <mat-icon (click)="changeQuality()"
                  [ngClass]="{'btnrateNew': !layoutService.isMobile , 'rateBtnMobile' : layoutService.isMobile}"><label>{{valueQuality}}</label></mat-icon>
              </div>
            </div>
            <mat-icon (click)="openConfig()" class="iconClass">settings</mat-icon>
          </div>
        <mat-icon (click)="exitFullScreen()" class="ml-3" [ngClass]="{'fullscrenbtnnew': !layoutService.isMobile , 'fullscrenbtnmobile': layoutService.isMobile , 'fullleftscreenconfig' : showConfig}">fullscreen_exit</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="div-overflow" [ngClass]="{'row ml-4': layoutService.isMobile}" style="z-index: 1001!important;"
    *ngIf="!playYoutube && showMask && layoutService.isMobile">
    <div class="divVolume ml-2">
      <mat-slider class="slider-sound" (change)="setVolumeYoutube()" vertical style="color: white;" min="0" max="100"
        step="1" [(value)]="youtubeVolume"></mat-slider>
      <mat-icon *ngIf="youtubeVolume > 0" class="sound-icon">volume_up</mat-icon>
      <mat-icon *ngIf="youtubeVolume == 0" class="sound-icon">volume_off</mat-icon>
    </div>
    <div [ngClass]="{'row': layoutService.isMobile}" class="upButtonsMask">
      <button (click)="rewindVideo()"
        [ngClass]="{'col d-flex': layoutService.isMobile , 'btn-rewind' : !layoutService.isMobile}" class="btcircle"
        mat-button><mat-icon
          [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">fast_rewind</mat-icon></button>
      <button *ngIf="showPlay" (click)="playVideoYT()" [ngClass]="{'col d-flex': layoutService.isMobile}"
        class="btcircle" mat-button><mat-icon
          [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">play_circle</mat-icon></button>
      <button *ngIf="!showPlay" (click)="pauseVideo()" [ngClass]="{'col d-flex': layoutService.isMobile}"
        class="btcircle" mat-button><mat-icon
          [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">pause_circle</mat-icon></button>
      <button (click)="setForward()"
        [ngClass]="{'col d-flex': layoutService.isMobile , 'btn-next' : !layoutService.isMobile}"
        class="btcircle btn-next" mat-button><mat-icon
          [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">fast_forward</mat-icon></button>
    </div>
    <div>
      <mat-icon (click)="setVideoRate()"
        [ngClass]="{'btnrate': !layoutService.isMobile , 'rateBtnMobile' : layoutService.isMobile}">slow_motion_video<label>{{rateVideo}}</label></mat-icon>
      <mat-icon (click)="exitFullScreen()" class="mr-5"
        [ngClass]="{'fullscrenbtn': !layoutService.isMobile , 'fullscrenbtnmobile': layoutService.isMobile}">fullscreen_exit</mat-icon>
    </div>
    <div class="ml-5 row marginslidertime">
      <mat-slider style="background-color: white;" class="classteste" (change)="goYoutubeTime()" [(value)]="youtubeTime"
        class="slider-sound width-90" horizontal min="0" [max]="youtubeDuration" step="1"></mat-slider>
    </div>
    <div class="row marginslidertime width-100" *ngIf="layoutService.isMobile">
      <mat-slider style="background-color: white;" class="classteste" (change)="goYoutubeTime()" [(value)]="youtubeTime"
        class="slider-sound width-90" horizontal min="0" [max]="youtubeDuration" step="1"></mat-slider>
    </div>
  </div>
</div>
