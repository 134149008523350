<div class="row">
    <div class="col-12 text-right">  
        <a class="btn btn-success modal-close m-3 pr-3" [href]="file" download="Arquivo.pdf">Download</a>      
        <a  class="btn btn-primary modal-close m-3 " (click)="dialogRef.close()">Fechar</a>
    </div>
</div>
<div class="row">
    <div class="col-12 min-height">
        <iframe [src]="link"  width="100%" height="100%"></iframe>  
    </div>
</div>