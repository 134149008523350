import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summation-report',
  templateUrl: './summation-report.component.html',
  styleUrls: ['./summation-report.component.scss']
})
export class SummationReportComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  }


}
