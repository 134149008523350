import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Payment } from 'src/app/modules/payment/payment';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { Plan } from 'src/app/modules/payment/plan';

import { PaymentEditComponent } from '../../edit/payment-edit.component';
import { Payer, PlaymentPlans, StudentPayment } from '../payer';
import { StudentService } from '../../../student/student.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from 'src/app/modules/user/user.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { School } from '../../../school/school';
import { CreatePaymentComponent } from '../../create-payment/create-payment.component';

 
@Component({
    selector: 'app-paying-list',
    templateUrl: './paying-list.component.html',
    styleUrls: ['./paying-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class PayingListComponent implements OnInit {

    public plans$: Observable<Plan[]>;
    public payers$: Observable<Payer[]>;
    public payment$: Observable<StudentPayment[]>;
    public paymentsPlans$: Observable<PlaymentPlans[]>;
    public currentPayerId: number;
    public userPayments$: Observable<Payment[]>;
    public loading: boolean;
    public deleting: boolean;
    public expandedElement: any;
    public filterEmail = '';
    public filterName = '';
    public filterPlanId: number;
    public schools: School[];
    public schoolId: number;
    public blockView:boolean= true;

    constructor(
        private paymentService: PaymentService,
        public dialog: MatDialog,
        private studentService: StudentService,
        public userService: UserService,
        private alertService: AlertService) { }

    ngOnInit() {        
        if (this.userService.isAdmin()) {
            this.getSchools();
        } else if (this.userService.isGeneralCoordinator()) {

            this.schoolId = Number(this.userService.getUserSchool());
            this.getPaymentPlans(this.schoolId);
        }else{
            this.blockView = false;
        }  
    }

    getSchools() {
        this.paymentService.getSchools()
            .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.schools = response.data as School[];
            } else {
                this.alertService.error(response.error);
            }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getPaymentPlans(school: number){
        this.schoolId =school;
        this.paymentsPlans$ = this.paymentService.getPaymentPlans(school);
    }

    getPlans() {
        this.plans$ = this.paymentService.getPlansObservable();
    }

    getPayers(planId: number) {
        this.payers$ = this.paymentService.getPlanPayments(planId);
    }

    getUserPayments(userId: number) {
        this.currentPayerId = userId;
        this.userPayments$ = this.paymentService.getUserPayments(userId);
    }

    editPayment(payment: Payment) {
        const dialogRef = this.dialog.open(PaymentEditComponent, {
            minWidth: '80vw',
            data: { payment, isAddPayment: false, studentId: this.currentPayerId }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getUserPayments(this.currentPayerId);
            }
        });
    }

    addStudentPayment(studentId: number) {
        const dialogRef = this.dialog.open(PaymentEditComponent, {
            minWidth: '60vw',
            data: { payment: null, isAddPayment: true, studentId }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getUserPayments(this.currentPayerId);
            }
        });
    }

    removePayment(payment: Payment) {
        if (confirm('Deseja realmente remover o pagamento')) {
            this.deleting = true;
            this.paymentService.removePayment(payment, this.currentPayerId)
                .subscribe(
                    res => {
                        this.deleting = false;
                        this.getUserPayments(this.currentPayerId);
                    },
                    err => this.deleting = false
                );
        }
    }

    getPaymentsByName(name?: string ) {
        

        if ((!name  )) {
            this.alertService.warning('A busca precisa conter 3 ou mais caracteres');
            return;
        }
        this.filterEmail = '' ; this.filterName = '';
        this.filterPlanId = null;
        this.loading = true;
        this.payers$ = this.paymentService.getPlanPaymentsName(this.schoolId, name);
         
    }
    getPaymentsByEmail( email?: string) {
     

        if (( !email)) {
            this.alertService.warning('A busca precisa conter 3 ou mais caracteres');
            return;
        } 
        this.filterEmail = '' ; this.filterName = '';

        this.filterPlanId = null;

        this.loading = true;
        this.payers$ = this.paymentService.getPlanPaymentsEmail(this.schoolId, email);
        //this.payers$.subscribe(p => this.loading = false, err => this.loading = false);
    }

  

    releasePayment(payer: StudentPayment){
        if (confirm('Deseja realmente liberar o pagamento?')) {
            payer.status = "paid";         
            this.paymentService.releasePayment(payer).subscribe(res =>{             
                const response = res.body as ResponseApi;
                if (!response.error) {                
                    this.alertService.success("Salvo com sucesso!");
                } else {
                    this.alertService.error(response.error);
                } 
                this.loading = true;
            }, err =>{this.alertService.error('Houve um erro ao adicionar o pagamento');});
        }
    }
    
    blockPayment(payer: StudentPayment){

        if (confirm('Deseja realmente liberar o pagamento?')) {
          
            this.paymentService.blockPayment(payer.id).subscribe(res =>{
             
                const response = res.body as ResponseApi;

                if (!response.error) {
                    payer.status = "processing";
                    this.alertService.success("Salvo com sucesso!");
                } else {
                    this.alertService.error(response.error);
                } 
              
            }, err =>{this.alertService.error('Houve um erro ao adicionar o pagamento');});
        }
    }

    openModalcreatePayment(){
        const dialogRef = this.dialog.open(CreatePaymentComponent, {
            minWidth: '60vw',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //this.getUserPayments(this.currentPayerId);
            }
        });
    }
}
