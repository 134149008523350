import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReportPerformanceComponent } from './performance/report-performance.component'; 
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; 
import { AverageChartComponent } from './charts/average/average-chart.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon'; 
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips'; 
import {
    NbAccordionModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbListModule,
    NbProgressBarModule,
    NbSpinnerModule 
} from '@nebular/theme';

@NgModule({
    declarations: [
        ReportPerformanceComponent,
        AverageChartComponent
       
    ],
    imports: [
        CommonModule,
        NbAccordionModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule, 
        MatProgressSpinnerModule,
        FormsModule,  
        MatIconModule,
        MatTabsModule,
        MatChipsModule,
        NbAccordionModule,
        NbAlertModule,
        NbButtonModule,
        NbCardModule,
        NbIconModule,
        NbListModule,
        NbProgressBarModule,
        NbSpinnerModule ,
        MatTooltipModule

    ],
    exports: [],
    providers: [],
})
export class ReportModule { }
