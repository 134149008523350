import { Theme } from 'src/app/core/themes/theme';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, Optional, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../user/user.service';
import { Redaction } from '../redaction';
import { RedactionPlan } from '../redaction-plan';
import { MatSelect } from '@angular/material/select';
import { RedactionTheme } from '../redaction-theme';
import { RedactionService } from '../redaction.service';
import { RedactionWriteComponent } from './write-dialog/redaction-write-dialog.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { ReadThemeComponent } from '../sent/read-theme/read-theme.component';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { AnimationOptions } from 'ngx-lottie';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { stringify } from '@angular/compiler/src/util';
import { MainTheme, MotivacionalText } from '../../admin/rdo-manager/create-theme/theme';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { MatPaginator } from '@angular/material/paginator';
import { AdminService } from '../../admin/admin.service';
import { nullSafeIsEquivalent, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as imageConversion from 'image-conversion';


@Component({
  selector: 'app-send-redaction',
  templateUrl: './send-redaction.component.html',
  styleUrls: ['./send-redaction.component.scss']
})
export class SendRedactionComponent implements OnInit, OnDestroy {

  private mobileQueryListener: () => void;
  public plans: RedactionPlan[];
  public themes: MainTheme[] = [];
  public themeFree: MainTheme[];
  public selectedPlan: RedactionPlan;
  public selectedPlanId: number;
  public firstPlanId: number;
  public freeThemes: MainTheme[] = [];
  public AllThemes: MainTheme[] = [];
  public selectedTheme: MainTheme;
  public selectedThemeId: number;
  public noShow: boolean = true;
  public loading: boolean;
  public loadingPlans: boolean;
  public redactionText: string;
  public imgSrc;
  public fileTemp;
  public sending: boolean;
  public endPlans: boolean;
  public freeTheme: string;
  public mobileQuery: MediaQueryList;
  public lottieOptions: AnimationOptions;
  public showMessageEdu: boolean;
  public isInPlansEdu: boolean
  public nextPage: number = 1;
  public previousPage: number = 0;
  public totalPages: number = 1;
  public actualPage: number = 1;
  public planSelected: number;
  public firstTheme: number = 0;
  public showButton: boolean;
  public themeSelected: MainTheme;
  public temaLivreEnem: MainTheme;
  public temaLivreVestibular: MainTheme;
  public temaVestibularId: number;
  public temaEnemId: number;
  public openRedaction: boolean;
  public motivacionalTextEnem: string;
  public motivacionalTextVestibular: string;
  public size: number;
  public creditsAvailable: any;
  /* public typeTheme: { name: string, type: number }[] = [{ name: 'Enem', type: 1 }, { name: 'Vestibular', type: 2 }, { name: 'Concurso', type: 3 }, { name: 'UFSC', type: 4 },
  { name: 'UFPR', type: 5 }, { name: 'UFRGS', type: 6 }, { name: 'UDESC', type: 7 }, { name: 'ACAFE', type: 8 }, { name: 'FUVEST', type: 9 }, { name: 'UNICAMP', type: 10 }]; */
  //public pageIndex = 0;
  public totalRowsSize: MainTheme[];
  public totalRows: number;
  public noShowTheme: boolean = false;
  public typeTheme: MainTheme[];
  @ViewChild(MatPaginator) paginator: MatPaginator;


  public pageIndex: number = 1;
  public pageSize: number = 10;
  public length: number;

  public showMotivationTheme: MainTheme;

  constructor(

    private redactionService: RedactionService,
    private alertService: AlertService,
    private userService: UserService,
    private nbDialogService: NbDialogService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private studyPlanService: StudyPlanService,
    private domSanitizer: DomSanitizer,
    private dialogService: NbDialogService,
    public layoutService: LayoutService,
    public adminService: AdminService) { }

  ngOnInit(): void {
    this.checkCredit(Number(this.userService.getUserId()));
  }

  init() {
    this.totalRows = 0;
    this.showMessageEdu = false;
    this.isInPlansEdu = false;
    this.checkSchoolEdu();
    this.checkMessagePlansEdu();
    this.getStudentPlans(this.userService.getUserId());

    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () =>
      this.changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.lottieOptionsConfig();

    this.temaLivreVestibular = ({
      id: 1304,
      name: 'Tema livre Vestibular',
      tag: '',
      externalId: null,
      type: null,
      typeName: 'Vestibular',
      description: null,
      motivacionalText: ([{
        id: 1304,
        theme_id: 1304,
        title: 'Tema livre Vestibular',
        motivacional_text: this.motivacionalTextVestibular
      }]),
      order: null
    });
    this.temaLivreEnem = ({
      id: 1305,
      name: 'Tema livre Enem',
      tag: '',
      externalId: null,
      type: null,
      typeName: 'Enem',
      description: null,
      motivacionalText: ([{
        id: 1305,
        theme_id: 1305,
        title: 'Tema livre Enem',
        motivacional_text: this.motivacionalTextEnem
      }]),
      order: null
    });

    this.temaEnemId = this.temaLivreEnem.id;
    this.temaVestibularId = this.temaLivreVestibular.id;
  }



  checkCredit(studentId: number) {
    this.loading = true;
    this.redactionService.checkEssaysAvailable(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.creditsAvailable = response;
        console.log("this.creditsAvailable: ", this.creditsAvailable)
        if (!response.error) {
          this.init();
        } else {
          this.router.navigateByUrl('/redaction/buy-credit');
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar as redações. Verifique a conexão e tente novamente'));
  }

  getStudentPlans(studentId: number) {
    this.noShow = true;
    this.loadingPlans = true;
    this.redactionService.getStudentPlansWithRedactions(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as RedactionPlan[];
          this.firstPlanId = 0;
          this.endPlans = this.plans.length > 0 ? false : true;
          if (!this.endPlans) {
            this.plans.map(p => {
              p.redactions = Number(p.redactions);
              p.sendRedactions = Number(p.sendRedactions);
              return p;
            });
          }
          this.loadingPlans = false;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os planos disponíveis. Verifique a conexão e tente novamente'));
  }

  checkSchoolEdu() {
    const id = Number(this.userService.getUserSchool());
    if (id == 122) {
      this.showMessageEdu = true;
    }
    else {
      this.showMessageEdu = false;
    }
  }

  checkMessagePlansEdu() {

    this.studyPlanService.getMessageUserPlansEdu(this.userService.getUserId()).subscribe(res => {

      const response = res.body as ResponseApi

      if (response.data == true) {
        this.isInPlansEdu = true
      }
      else {
        this.isInPlansEdu = false;
      }
    })
  }

  getPlanNewThemes(planId: number, page?: number) {
    this.noShowTheme = false;
    this.firstTheme = 0;
    this.loading = true;
    let currentPage = page;

    this.redactionService.getPlanNewThemes(planId, page)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let data = response.data;
          this.themes = data as MainTheme[];
          if (!data) {
            this.noShowTheme = true;
          }
          this.firstTheme = 0;
        } else {
          this.alertService.success(response.error);
          this.noShowTheme = true;
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os temas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getThisPlanThemes(planId:number){
    this.redactionService.getThisPlanThemes(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.typeTheme = response.data as MainTheme[];
      }
      else{
        this.typeTheme = [];
      }
    })
  }

  getPlanSelectedTheme(themeId:number) {
    this.noShowTheme = false;
    this.firstTheme = 0;
    this.loading = true;

    this.redactionService.getPlanSelectedTheme(this.selectedPlanId ,themeId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let data = response.data;
          this.themes = data as MainTheme[];
          if (!data) {
          }
          this.firstTheme = 0;
        } else {
          this.alertService.success(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os temas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlanFreeThemes(planId: number) {
    this.firstTheme = 0;
    this.loading = true;

    this.redactionService.getPlanFreeThemes(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.firstTheme = 0;
          const themesTemp = response.data as MainTheme[];
          themesTemp.forEach(element => {
            this.themes.push(element);
          });
        } else {
          this.alertService.success(response.error);

        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os temas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  paginate(event: any) {
    this.pageIndex = event;
    this.totalRowsSize = this.themes.slice(event * this.length - this.themes.length, event * this.themes.length);
    this.totalRows = this.totalRowsSize.length;
  }

  /* getNextThemePage(){
      this.themes = [];
      this.actualPage = this.nextPage + 1;
      this.getPlanNewThemes(this.selectedPlanId, this.nextPage);


      this.nextPage++;
      this.previousPage--;
  }

  getPreviousThemePage(){
      this.themes = [];
      this.previousPage--;
      this.nextPage--;

      this.actualPage = (this.previousPage >= 0) ? this.previousPage + 1 : 0;
      this.getPlanNewThemes(this.selectedPlanId, this.previousPage);

  } */

  selectPlan(planId: number) {
    this.totalRows = 0;
    this.pageIndex = 1;
    this.themes = [];

    this.selectedPlanId = planId;
    //this.themes = [];
    this.getPlanNewThemes(planId);
    this.getThisPlanThemes(planId);
    //this.getPlanFreeThemes(planId);
    this.noShow = false;
  }

  selectTheme(theme: MainTheme) {
    console.log('aaaaa' , theme);
    this.selectedTheme = theme;
    if (theme.motivacionalText) {
      this.showMotivationTheme = theme;
      this.selectedThemeId = theme.motivacionalText[0].id;
    }
    else {
      this.showMotivationTheme = null;
      this.selectedThemeId = 1304
    }

    //alert(this.selectedTheme +""+ this.selectedThemeId);
  }

  backButton() {
    this.selectTheme = null;
    this.themeSelected = null;
  }

  openWriteRedaction() {
    this.showButton = true;
    this.openRedaction = true;
    this.nbDialogService.open(RedactionWriteComponent, { closeOnBackdropClick: false })
      .onClose.subscribe(text => {
        if (this.isFreeTheme()) {
          this.redactionText = this.freeTheme + '\n\n' + text;
          this.showButton = true;
        } else if (text) {
          this.redactionText = text;
          this.showButton = true;
        } else {
          this.showButton = false;
        }

      });
  }

  handleFileInput(files: FileList): void {
    this.showButton = false;
    // tratar o tamanho para ver se a imagem tem menos de 2mb
    // colocar um aviso caso tenha mais
    // quando carregar colocar o base64 gerado em this.fileTemp logo abaixo para uma pré visualização

    if (files && files[0]) {

      console.log(files)

      const fileSizeInMb = files[0].size / 1024 / 1024;

      console.log(fileSizeInMb)

      if (fileSizeInMb > 2) {
        imageConversion.compressAccurately(files[0], 1500).then(res => {
          this.imgSrc = res
          const reader = new FileReader();
          reader.readAsDataURL(this.imgSrc);
          reader.onload = e => {
            this.fileTemp = reader.result;
            this.showButton = true;
          }

          console.log(this.fileTemp)
        });
      }
      else {
        this.imgSrc = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.imgSrc);
        reader.onload = e => {
          this.fileTemp = reader.result;
          this.showButton = true;
        };

        console.log(this.fileTemp)
      }
    }
  }

  cancelRedaction() {
    this.firstPlanId = 0;
    this.selectedPlanId = null;
    this.imgSrc = null;
    this.redactionText = null;
    this.showButton = false;
    this.selectedTheme = null;
  }

  isFreeTheme() {
    return (this.selectedThemeId === 3173 || this.selectedThemeId === 3174) ? true : false;
  }

  sendRedaction() {


    this.showButton = true;
    this.sending = true;
    const formData = new FormData();

    if (this.imgSrc !== undefined) {
      if (this.selectedThemeId) {
        //this.selectedTheme.id = this.selectedThemeId;
        if (this.selectedTheme.motivacionalText) {
          this.selectedTheme.motivacionalText[0].id = this.selectedThemeId;
          formData.append('themeId', this.selectedTheme.motivacionalText[0].id + "");
        }

        else {
          if(this.selectedTheme.type == 1){
            formData.append('themeId', 1305 + "");
          }
          else{
            formData.append('themeId', 1304 + "");
          }
        }

        formData.append('file', this.imgSrc);
        formData.append('planId', this.selectedPlanId + "");
        if (this.selectedTheme.typeName == 'UFSC' || this.selectedTheme.typeName == 'UFPR' || this.selectedTheme.typeName == 'UFRGS' || this.selectedTheme.typeName == 'UDESC'
          || this.selectedTheme.typeName == 'ACAFE' || this.selectedTheme.typeName == 'FUVEST' || this.selectedTheme.typeName == 'UNICAMP') {
          this.selectedTheme.typeName = 'vestibular'
        }
        if (this.selectedTheme.type == 4 || this.selectedTheme.type == 5 || this.selectedTheme.type == 6 || this.selectedTheme.type == 7
          || this.selectedTheme.type == 8 || this.selectedTheme.type == 9 || this.selectedTheme.type == 10) {
          this.selectedTheme.type = 2
        }
        formData.append('theme_type', (String)(this.selectedTheme.typeName));
        formData.append('studentId', this.userService.getUserId() + "");
        formData.append('test_id', (String)(this.selectedTheme.editalId));
        formData.append('selectedThemeId', (String)(this.selectedTheme.id));

        this.sendRedactionRequest(formData);
      }
      else {
        this.alertService.error('Você precisa selecionar um tema');
      }
    }
    else {
      if (this.selectedThemeId) {
        //this.selectedTheme.id = this.selectedThemeId;
        if (this.selectedTheme.motivacionalText) {
          this.selectedTheme.motivacionalText[0].id = this.selectedThemeId;
          formData.append('themeId', this.selectedTheme.motivacionalText[0].id + "");
        }

        else {
            if(this.selectedTheme.type == 1){
              formData.append('themeId', 1305 + "");
            }
            else{
              formData.append('themeId', 1304 + "");
            }
        }

        formData.append('text', this.redactionText);
        formData.append('planId', this.selectedPlanId + "");
        if (this.selectedTheme.typeName == 'UFSC' || this.selectedTheme.typeName == 'UFPR' || this.selectedTheme.typeName == 'UFRGS' || this.selectedTheme.typeName == 'UDESC'
          || this.selectedTheme.typeName == 'ACAFE' || this.selectedTheme.typeName == 'FUVEST' || this.selectedTheme.typeName == 'UNICAMP') {
          this.selectedTheme.typeName = 'vestibular'
        }
        if (this.selectedTheme.type == 4 || this.selectedTheme.type == 5 || this.selectedTheme.type == 6 || this.selectedTheme.type == 7
          || this.selectedTheme.type == 8 || this.selectedTheme.type == 9 || this.selectedTheme.type == 10) {
          this.selectedTheme.type = 2
        }
        formData.append('theme_type', (String)(this.selectedTheme.typeName));
        formData.append('test_id', (String)(this.selectedTheme.editalId));
        formData.append('studentId', this.userService.getUserId() + "");
        formData.append('selectedThemeId', (String)(this.selectedTheme.id));

        this.sendRedactionRequest(formData);
      }
      else {
        this.alertService.error('Você precisa selecionar um tema!');
      }
    }

  }

  sendRedactionRequest(formData: FormData) {

    console.log(formData);
    this.redactionService.sendRedaction(formData)
      .subscribe(res => {
        const response = res.body as any;

        if (!response.error) {
          this.alertService.success('Redação Enviada!');

          const idRedaction = response.data.idredacao;

          this.router.navigateByUrl('redaction/sent?id=' + idRedaction);
        } else {
          this.alertService.error(response.error);
        }

        this.sending = false;
      }, err => {
        this.alertService.error('Houve um erro ao enviar sua redação. Verifique a conexão e tente novamente');
        this.sending = false;
      });
  }
  //this.alertService.error('Você precisa selecionar o plano e o tema ou escrever um texto.');

  get isMobile() {
    return this.mobileQuery.matches;
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    //this.mobileQuery.removeListener(this.mobileQueryListener);
  }
  innerHTMLBypass(value: any) {

    return this.domSanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(value));
  }

  openThemeText(theme: MainTheme) {
    this.redactionService.getTextMotivationalOne(theme.id)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

          theme.motivacionalText[0].motivacional_text = response.data as string;
          this.dialogService.open(ReadThemeComponent, {
            context: {
              title: 'Texto Motivador',
              theme
            },
          });
        }

        else {
          this.alertService.error('erro ao buscar os dados');
        }
      })
  }

  lottieOptionsConfig() {
    this.lottieOptions = {
      path: 'assets/lotties/empty.json'
    };
  }
  openLink() {
    window.open(`https://notamaxima-bucket.s3.us-east-2.amazonaws.com/files/FOLHA+DE+REDA%C3%87%C3%83O+NOTA+M%C3%81XIMA+2021+(1).pdf`, '_blank');
  }

}
