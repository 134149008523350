import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoryListComponent } from './monitory-list/monitory-list.component';
import { NbButtonModule, NbListModule } from '@nebular/theme';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [MonitoryListComponent],
  imports: [
    CommonModule,
    NbListModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    MonitoryListComponent
  ]
})
export class MonitoryModule { }
