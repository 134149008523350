import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../user/user.service';
import { Monitory } from '../monitory';
import { MonitoryService } from '../monitory.service';

@Component({
  selector: 'app-monitory-list',
  templateUrl: './monitory-list.component.html',
  styleUrls: ['./monitory-list.component.scss']
})
export class MonitoryListComponent implements OnInit {

  monitoring: Monitory[];
  @Output() haveMonitory = new EventEmitter();

  constructor(
    private monitoryService: MonitoryService,
    public userService: UserService,
    private alertService: AlertService) { }

  ngOnInit() {
    if (this.userService.isTeacher()) {
      this.getTeacherMonitoring(this.userService.getUserId());
      //console.log('Teacher');
    } else
      if (this.userService.isStudent()) {
        this.getStudentMonitoring(this.userService.getUserId());
        //console.log('Student');
      } else {
        //console.log('Other profile');
      }
  }

  getStudentMonitoring(studentId: number) {
    this.monitoryService.getStudentMonitoring(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.monitoring = response.data as Monitory[];
          if (this.monitoring?.length > 0) {
            this.haveMonitory.emit(true);
          } else {
            this.haveMonitory.emit(false);
          }
        }
      }, err => this.alertService.error('Houve um erro ao carregar as monitorias. Verifique a conexão e tente novamente'));
  }

  getTeacherMonitoring(teacherId: number) {
    this.monitoryService.getTeacherMonitoring(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.monitoring = response.data as Monitory[];
          if (this.monitoring?.length > 0) {
            this.haveMonitory.emit(true);
          } else {
            this.haveMonitory.emit(false);
          }
        }
      }, err => this.alertService.error('Houve um erro ao carregar as monitorias. Verifique a conexão e tente novamente'));
  }

  openMonitory(link: string) {
    window.open(link, '_blank');
  }

}
