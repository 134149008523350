import { MatCardModule } from '@angular/material/card';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
    NbAccordionModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbListModule,
    NbSpinnerModule,
} from '@nebular/theme';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderModule } from 'ngx-order-pipe';


import { routes } from './admin.routing';
import { ClassroomCreateComponent } from './classroom/create/classroom-create.component';
import { ClassroomCreateByClassComponent } from './classroom/create-by-class/classroom-create-by-class.component';
import { CreateDisciplneComponent } from './discipline/create/create-discipline.component';
import { ManageDisciplineComponent } from './discipline/manage/manage-discipline.component';
import { CreateExerciseComponent } from './exercise/create/create-exercise.component';
import { CreateExerciseListComponent } from './exercises-list/create-exercise-list.component';
import { ExperimentCreateComponent } from './experiment/create/experiment-create.component';
import { ExperimentManageComponent } from './experiment/manage/experiment-manage.component';
import { ExperimentUserAddComponent } from './experiment/user/add/experiment-user-add.component';
import { MiscellaneousComponent } from './miscellaneous/miscellaneous.component';
import { MockCreateComponent } from './mock/create/mock-create.component';
import { MockReportComponent } from './mock/report/mock-report.component';
import { PaymentCouponComponent } from './payment/coupon/payment-coupon.component';
import { PaymentEditComponent } from './payment/edit/payment-edit.component';
import { PaymentManageComponent } from './payment/manage/payment-manage.component';
import { PayingListComponent } from './payment/paying/list/paying-list.component';
import { PaymentCreatePlanComponent } from './payment/plan/payment-create-plan.component';
import { CreatePodcastComponent } from './podcast/create-podcast.component';
import { RepositoryFileAddComponent } from './repository/file/add/repository-file-add.component';
import { RepositoryFolderAddComponent } from './repository/folder/repository-folder-add.component';
import { ManageRepositoryComponent } from './repository/manage/manage-repository.component';
import { MaterialAddComponent } from './repository/material/material-add.component';
import { RepositoryVideoAddComponent } from './repository/video/add/repository-video-add.component';
import { CreateClassComponent } from './school/create-class/create-class.component';
import { CreateManagerComponent } from './school/create-manager/create-manager.component';
import { SchoolCreateTeacherComponent } from './school/create-teacher/school-create-teacher.component';
import { CreateUnitComponent } from './school/create-unit/create-unit.component';
import { CreateSchoolComponent } from './school/create/create-school.component';
import { ManageSchoolComponent } from './school/manage/manage-school.component';
import { CreateStudentComponent } from './student/create/create-student.component';
import { StudentsAddCSVComponent } from './student/csv-add/students-add-csv.component';
import { ManageStudentComponent } from './student/manage/manage-student.component';
import { CreateStudyPlanComponent } from './study-plan/create/create-study-plan.component';
import { AddVideoComponent } from './video/add/add-video.component';
import { AddVideoAnnexComponent } from './video/annex/add-video-annex.component';
import { MonitoryCreateComponent } from './monitory/create/monitory-create.component';
import { HolydaysComponent } from './holydays/holydays.component';
import { CreateRoutesComponent } from './school/create-routes/create-routes.component';
import { EditRouteComponent } from './school/create-routes/edit-route/edit-route.component';
import { EditSchoolRoutesComponent } from './school/create-routes/edit-school-routes/edit-school-routes.component';
import { MockGeneralReportComponent } from './mock/report/mock-general-report/mock-general-report.component';
import { EditClassComponent } from './school/create-class/edit-class/edit-class.component';
import { RdoManagerComponent } from './rdo-manager/rdo-manager.component';
import { SchoolSyncComponent } from './rdo-manager/school-sync/school-sync.component';
import { UnitSyncComponent } from './rdo-manager/unit-sync/unit-sync.component';
import { ClassSyncComponent } from './rdo-manager/class-sync/class-sync.component';
import { CoordSyncComponent } from './rdo-manager/coord-sync/coord-sync.component';
import { TeacherSyncComponent } from './rdo-manager/teacher-sync/teacher-sync.component';
import { TaskManagerComponent } from './task-manager/task-manager.component';
import { TaskReceivedComponent } from './task-manager/task-received/task-received.component';
import { TaskSentComponent } from './task-manager/task-sent/task-sent.component';
import { SubmitTaskComponent } from './task-manager/submit-task/submit-task.component';

import { PerformanceReportComponent } from './performance-report/performance-report.component';
import { MoksReportComponent } from './performance-report/moks-report/moks-report.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ExternalIntegrationComponent } from './integrations/external-integration/external-integration.component';
import { ExportToCSV } from '@molteni/export-csv';
import { SearchReportComponent } from './integrations/search-report/search-report.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ReportsComponent } from './reports/reports.component';
import { SummationCreateComponent } from './summation/summation-create/summation-create.component';
import { SlideFileCreateComponent } from './slide-file/slide-file-create/slide-file-create.component';
import { EditClassesComponent } from './classroom/edit-classes/edit-classes.component';
import { EditClassesByClassComponent } from './classroom/edit-classes-by-class/edit-classes-by-class.component';
import { SummationReportComponent } from './summation-report/summation-report.component';
import { TaskEditComponent } from './task-manager/task-edit/task-edit.component';
import { ReportTastingComponent } from './performance-report/report-tasting/report-tasting.component';
import { SearchComponent } from './summation-report/search/search.component';
import { SummationStudentsComponent } from './summation-report/summation-students/summation-students.component';
import { CreateAdminComponent } from './school/create-admin/create-admin.component';
import { SchoolReportComponent } from './integrations/school-report/school-report.component';
import { MockStudentComponent } from './mock-student/mock-student.component';
import { EditMockStudentComponent } from './mock-student/edit-mock-student/edit-mock-student.component';
import { MyExerciseLibraryComponent } from './my-exercise-library/my-exercise-library.component';
import { CreateThemeComponent } from './rdo-manager/create-theme/create-theme.component';
import { EditThemeComponent } from './rdo-manager/create-theme/edit-theme/edit-theme.component';
import { DndDirective } from './repository/file/add/dnd.directive';

import { CreateQuestionComponent } from './my-exercise-library/create-question/create-question.component';
import { CreateDisciplineLibComponent } from './my-exercise-library/create-discipline-lib/create-discipline-lib.component';
import { DeletelibComponent } from './my-exercise-library/deletelib/deletelib.component';
import { CreateThemeLibComponent } from './my-exercise-library/create-theme-lib/create-theme-lib.component';
import { CreateInstitutionLibComponent } from './my-exercise-library/create-institution-lib/create-institution-lib.component';
import { CreateQuestionTypeLibComponent } from './my-exercise-library/create-question-type-lib/create-question-type-lib.component';
import { SuportComponent } from './suport/suport.component';
import { SelectSchoolComponent } from './shared-components/select-school/select-school.component';
import { SelectPlanComponent } from './shared-components/select-plan/select-plan.component';
import { IntegrationUnimestreComponent } from './integrations/integration-unimestre/integration-unimestre.component';
import { CreateEditalComponent } from './rdo-manager/create-edital/create-edital.component';
import { VideoIframeComponent } from './video-iframe/video-iframe.component';
import { DisciplineTagCreateComponent } from './discipline-tag/discipline-tag-create/discipline-tag-create.component';
import { ModalInitComponent } from './my-configuration/modal-init/modal-init.component';
import { ManageConfiComponent } from './my-configuration/manage-confi/manage-confi.component';
import { CreateEssayCreditComponent } from './essay/create-essay-credit/create-essay-credit.component';
import { CreateLandingPagesComponent } from './my-configuration/create-landing-pages/create-landing-pages.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CreateNotificationComponent } from './notifications/create/create-notification/create-notification.component';
import { EditNotificationsComponent } from './notifications/edit/edit-notifications/edit-notifications.component';
import { NotificationsModule } from 'src/app/core/components/notifications/notifications.module';
import { IntegrationConvergindoComponent } from './integrations/integration-convergindo/integration-convergindo.component';
import { CreatePaymentComponent } from './payment/create-payment/create-payment.component';
import { SelectUnitComponent } from './shared-components/select-unit/select-unit.component';
import { SelectClassComponent } from './shared-components/select-class/select-class.component';
import { SelectUsersComponent } from './shared-components/select-users/select-users.component';
import { CreateFatherComponent } from './student/create-father/create-father.component';
import { IntegrationSerenComponent } from './integrations/integration-seren/integration-seren.component';
import { CertificateComponent } from './certificate/certificate.component';
import { CreateComponent } from './certificate/create/create.component';
import { EditComponent } from './certificate/edit/edit.component';
import { FlipBookAdminComponent } from './flip-book-admin/flip-book-admin.component';
import { PortalComponent } from './integrations/portal/portal.component';
import { ChannelComponent } from './channel/channel.component';
import { EditChannelComponent } from './channel/edit-channel/edit-channel.component';
import { DuplicateDisciplineComponent } from './discipline/duplicate-discipline/duplicate-discipline.component';
import { CorrectTaskComponent } from './task-manager/correct-task/correct-task.component';
import { CreateIntegrationComponent } from './integrations/create-integration/create-integration.component';

@NgModule({
    declarations: [
        CreateStudyPlanComponent,
        CreateDisciplneComponent,
        CreateExerciseComponent,
        ManageDisciplineComponent,
        AddVideoComponent,
        AddVideoAnnexComponent,
        ManageSchoolComponent,
        CreateSchoolComponent,
        CreateUnitComponent,
        CreateClassComponent,
        CreateManagerComponent,
        CreateStudentComponent,
        StudentsAddCSVComponent,
        ManageStudentComponent,
        CreatePodcastComponent,
        CreateExerciseListComponent,
        MockCreateComponent,
        PaymentCreatePlanComponent,
        PaymentManageComponent,
        RepositoryFileAddComponent,
        RepositoryVideoAddComponent,
        ManageRepositoryComponent,
        RepositoryFolderAddComponent,
        PaymentCouponComponent,
        SchoolCreateTeacherComponent,
        MiscellaneousComponent,
        MaterialAddComponent,
        MockReportComponent,
        ExperimentCreateComponent,
        ExperimentUserAddComponent,
        ExperimentManageComponent,
        ClassroomCreateComponent,
        ClassroomCreateByClassComponent,
        PayingListComponent,
        PaymentEditComponent,
        MonitoryCreateComponent,
        TaskReceivedComponent,
        TaskSentComponent,
        SubmitTaskComponent,
        HolydaysComponent,
        CreateRoutesComponent,
        EditRouteComponent,
        EditSchoolRoutesComponent,
        MockGeneralReportComponent,
        EditClassComponent,
        RdoManagerComponent,
        SchoolSyncComponent,
        UnitSyncComponent,
        ClassSyncComponent,
        CoordSyncComponent,
        TeacherSyncComponent,
        TaskManagerComponent,
        TaskReceivedComponent,
        TaskSentComponent,
        SubmitTaskComponent,
        PerformanceReportComponent,
        MoksReportComponent,
        ExternalIntegrationComponent,
        SearchReportComponent,
        IntegrationsComponent,
        ReportsComponent,
        SummationCreateComponent,
        SlideFileCreateComponent,
        EditClassesComponent,
        EditClassesByClassComponent,
        TaskEditComponent,
        ReportTastingComponent,
        SearchComponent,
        SummationReportComponent,
        SummationStudentsComponent,
        CreateAdminComponent,
        SchoolReportComponent,
        MockStudentComponent,
        EditMockStudentComponent,
        MyExerciseLibraryComponent,
        CreateThemeComponent,
        EditThemeComponent,
        DndDirective,
        CreateQuestionComponent,
        CreateDisciplineLibComponent,
        DeletelibComponent,
        CreateThemeLibComponent,
        CreateInstitutionLibComponent,
        CreateQuestionTypeLibComponent,
        SuportComponent,
        SelectSchoolComponent,
        SelectPlanComponent,
        IntegrationUnimestreComponent,
        CreateEditalComponent,
        VideoIframeComponent,
        DisciplineTagCreateComponent,
        ModalInitComponent,
        ManageConfiComponent,
        CreateEssayCreditComponent,
        CreateLandingPagesComponent,
        IntegrationConvergindoComponent,
        NotificationsComponent,
        CreateNotificationComponent,
        EditNotificationsComponent, 
        FlipBookAdminComponent,
        CreatePaymentComponent,
        SelectUnitComponent,
        SelectClassComponent,
        SelectUsersComponent,
        IntegrationSerenComponent ,
        CertificateComponent,
        CreateComponent,
        EditComponent,
        SelectUsersComponent,
        PortalComponent,
        ChannelComponent,
        EditChannelComponent,
        CreateFatherComponent,
        DuplicateDisciplineComponent,
        CorrectTaskComponent,
        CreateIntegrationComponent  
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NbCardModule,
        NbButtonModule,
        NbAccordionModule,
        NbIconModule,
        NbListModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        TextMaskModule,
        CKEditorModule,
        FormsModule,
        MatTabsModule,
        MatDialogModule,
        NbSpinnerModule,
        MatProgressSpinnerModule,
        SharedModule,
        MatListModule,
        MatDividerModule,
        MatTableModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTooltipModule,
        NgxMatSelectSearchModule,
        ClipboardModule,
        MatProgressBarModule,
        MatCardModule,
        OrderModule,
        DragDropModule,
        MatPaginatorModule,
        MatFormFieldModule,
        NotificationsModule
    ],
    entryComponents: [
        CreateSchoolComponent,
        CreateUnitComponent,
        CreateClassComponent,
        CreateManagerComponent,
        CreateDisciplneComponent,
        AddVideoComponent,
        CreateExerciseComponent,
        AddVideoAnnexComponent,
        CreateStudentComponent,
        PaymentCreatePlanComponent,
        MockCreateComponent,
        SchoolCreateTeacherComponent
    ],
    exports: [SelectPlanComponent],
    providers: [CurrencyPipe,
        ExportToCSV]
})
export class AdminModule { }
