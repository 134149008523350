import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Mock } from '../../mock/mock';
import { MockService } from '../../mock/mock.service';
import { UserService } from '../../user/user.service';
import { Video } from '../../video/video';
import { VideoService } from '../../video/video.service';
import { AdminService } from '../admin.service';
import { CreateDisciplneComponent } from '../discipline/create/create-discipline.component';
import { Discipline } from '../discipline/discipline';
import { DisciplineService } from '../discipline/discipline.service';
import { CreateExerciseComponent } from '../exercise/create/create-exercise.component';
import { Exercise } from '../exercise/exercise';
import { ExerciseService } from '../exercise/exercise.service';
import { MockCreateComponent } from '../mock/create/mock-create.component';
import { CreateThemeComponent } from '../rdo-manager/create-theme/create-theme.component';
import { EditThemeComponent } from '../rdo-manager/create-theme/edit-theme/edit-theme.component';
import { MainTheme, MotivacionalText } from '../rdo-manager/create-theme/theme';
import { SchoolService } from '../school/school.service';
import { CreateDisciplineLibComponent } from './create-discipline-lib/create-discipline-lib.component';
import { CreateInstitutionLibComponent } from './create-institution-lib/create-institution-lib.component';
import { CreateQuestionTypeLibComponent } from './create-question-type-lib/create-question-type-lib.component';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { CreateThemeLibComponent } from './create-theme-lib/create-theme-lib.component';
import { DeletelibComponent } from './deletelib/deletelib.component';
import { LibDisciplines, libInstitution, libQuestions, libQuestionType, libSubTheme, libTheme } from './lib-interfaces';
import { MyExerciseLibraryService } from './my-exercise-library.service';
import { DeviceDetectorService } from 'ngx-device-detector';

interface question {
  id: string,
  codigo: string
}

@Component({
  selector: 'app-my-exercise-library',
  templateUrl: './my-exercise-library.component.html',
  styleUrls: ['./my-exercise-library.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyExerciseLibraryComponent implements OnInit {

  public mocks: Mock[];
  public disciplines: LibDisciplines[];
  public loading: boolean;
  public filterDisciplineType = 0;
  public filterNameExercise: string;
  public searching: boolean;
  public exercises: Exercise[];
  public loadingInit = true;
  public disciplineName: string;
  public totalPage: any;
  public exerciseDisciplines: Discipline[];
  public filterDisciplineId: number;
  public filterVideoId: number;
  public currentVideoEdit: number;
  public filterVideos: Video[];
  public themes: libTheme[];
  public subThemes: libSubTheme[];
  public institutions: libInstitution[];
  public questionTypes: libQuestionType[];
  public filter = '';
  public testeQuestions: question[]
  public filterMock = '';
  public filterQuestion = '';
  public filterDiscipline = '';
  public filterTheme = '';
  public filterSubTheme = '';
  public filterInstitution = '';
  public filterQuestionType = '';
  public questions: libQuestions[];
  public isMocks: boolean;
  public isQuestions : boolean;
  public isDisciplines: boolean;
  public isThemes: boolean;
  public isSubThemes: boolean;
  public isInstitution: boolean;
  public isQuestionTypes: boolean;
  public totalPageMock: number;
  public pageNoMock: number;
  public totalPageDisciplines: number;
  public pageNoDisciplines: number;
  public totalPageQuestions: number;
  public pageNoQuestions: number;
  public totalPageThemes: number;
  public pageNoThemes: number;
  public totalPageSubThemes: number;
  public pageNoSubThemes: number;
  public totalPageInstitutions: number;
  public pageNoInstitutions: number;

  constructor(

    @Optional() public dialogRef: MatDialogRef<MockCreateComponent, CreateDisciplineLibComponent>,
    @Optional() public dialogRef2: MatDialogRef<CreateExerciseComponent, CreateThemeLibComponent>,
    @Optional() public dialogRef3: MatDialogRef<CreateInstitutionLibComponent, CreateQuestionComponent>,
    @Optional() public dialogRef4: MatDialogRef<DeletelibComponent, CreateQuestionTypeLibComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    public userService: UserService,
    private videoService: VideoService,
    private mockService: MockService,
    public deviceService: DeviceDetectorService,
    private libraryService: MyExerciseLibraryService,
    private alertService: AlertService,
    private disciplineService: DisciplineService,
    private exerciseService: ExerciseService,
    private adminService: AdminService
  ) { }

  ngOnInit() {

    this.isMocks = true
    this.isQuestions = true;
    this.isDisciplines = true;
    this.isThemes = true;
    this.isInstitution = true;
    this.isSubThemes = true;
    this.isQuestionTypes = true;
    this.exercises = [];
    this.filterDisciplineId = 0;
    this.getMocks(0,20);
    //this.getExercicies();
  }


  getMocks(offset: number , limit: number) {

    this.mockService.getMocks();

    this.libraryService.getMocksLib(offset , limit)
      .subscribe(res => {

        const response = res.body as ResponseApi;
        const totalpagemock = res.body['totalPage'];

        if (!response.error) {
          this.mocks = response.data as Mock[];
          this.isMocks = true;
          this.totalPageMock = totalpagemock as number;
        }
        else {
          this.alertService.error(response.error);
          this.isMocks = false;
        }
      
      })

  }

  getMocksByName(name: string, offset: number , limit: number) {

    this.libraryService.getMocksLibByName( name ,offset , limit)
      .subscribe(res => {

        const response = res.body as ResponseApi;
        const totalpagemock = res.body['totalPage'];

        if (!response.error) {
          this.mocks = response.data as Mock[];
          this.isMocks = true;
          this.totalPageMock = totalpagemock as number;
        }
        else {
          this.alertService.error(response.error);
          this.isMocks = false;
        }
      })
  }

  pageEventsMock(event: any) {
    const offset = event.pageIndex * event.pageSize;

    if (this.filterMock && this.filterMock != "") {
      this.getMocksByName(this.filterMock, offset, event.pageSize);
    }
    else {
      this.getMocks(offset, event.pageSize);
    }
  }

  getQuestions(offset: number , limit : number) {
    this.libraryService.getQuestions(offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalpagequestions = res.body['totalPage'] as number;

        if (!response.error) {
          this.questions = response.data as libQuestions[];
          this.totalPageQuestions = totalpagequestions;
          this.isQuestions = true;
        }
        else {
          this.alertService.error(response.error);
          this.isQuestions = false;
        }
      })
  }

  getQuestionsByName(name: string ,offset: number , limit : number) {
    this.libraryService.getQuestionsByName(name ,offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalpagequestions = res.body['totalPage'] as number;

        if (!response.error) {
          this.questions = response.data as libQuestions[];
          this.totalPageQuestions = totalpagequestions;
          this.isQuestions = true;
        }
        else {
          this.alertService.error(response.error);
          this.isQuestions = false;
        }
      })
  }

  pageEventsQuestions(event: any) {
    const offset = event.pageIndex * event.pageSize;

    if (this.filterQuestion && this.filterQuestion != "") {
      this.getQuestionsByName(this.filterQuestion, offset, event.pageSize);
    }
    else {
      this.getQuestions(offset, event.pageSize);
    }
  }

  getDisciplines(offset: number , limit: number) {
    this.libraryService.getDisciplines(offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totaldiscipline = res.body['totalPage'];

        if (!response.error) {
          this.disciplines = response.data as LibDisciplines[];
          this.totalPageDisciplines = totaldiscipline as number;
          this.isDisciplines = true
        }
        else {
          this.alertService.error(response.error);
          this.isDisciplines = false;
        }
      })
  }

  getDisciplinesByName(name: string, offset: number , limit: number) {
    this.libraryService.getDisciplinesByName(name, offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totaldiscipline = res.body['totalPage'];

        if (!response.error) {
          this.disciplines = response.data as LibDisciplines[];
          this.totalPageDisciplines = totaldiscipline as number;
          this.isDisciplines = true
        }
        else {
          this.alertService.error(response.error);
          this.isDisciplines = false;
        }
      })
  }

  pageEventsDisciplines(event: any) {
    const offset = event.pageIndex * event.pageSize;

    if (this.filterDiscipline && this.filterDiscipline != "") {
      this.getDisciplinesByName(this.filterDiscipline, offset, event.pageSize);
    }
    else {
      this.getDisciplines(offset, event.pageSize);
    }
  }

  getThemes(offset: number , limit: number) {
    this.libraryService.getThemes(offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const themessize = res.body['totalPage'] as number;

        if (!response.error) {
          this.themes = response.data as libTheme[]
          this.totalPageThemes = themessize;
          this.isThemes = true;
        }
        else {
          this.alertService.error(response.error);
          this.isThemes = false;
        }
      })
  }

  getThemesByName(name: string, offset: number , limit: number) {
    this.libraryService.getThemesByName( name,offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const themessize = res.body['totalPage'] as number;

        if (!response.error) {
          this.themes = response.data as libTheme[]
          this.totalPageThemes = themessize;
          this.isThemes = true;
        }
        else {
          this.alertService.error(response.error);
          this.isThemes = false;
        }
      })
  }

  pageEventsThemes(event: any) {
    const offset = event.pageIndex * event.pageSize;
    //console.log(offset, event.pageSize, 'report');

    if (this.filterTheme && this.filterTheme != "") {
      this.getThemesByName(this.filterTheme, offset, event.pageSize);
    }
    else {
      this.getThemes(offset, event.pageSize);
    }
  }

  getSubThemes(offset: number , limit: number) {
    this.libraryService.getSubTheme(offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalsub = res.body['totalPage'] as number;

        if (!response.error) {
          this.subThemes = response.data as libSubTheme[];
          this.totalPageSubThemes = totalsub;
          this.isSubThemes = true;
        }
        else {
          this.alertService.error(response.error);
          this.isSubThemes = false;
        }
      })
  }

  getSubThemesByName(name:string, offset: number , limit: number) {
    this.libraryService.getSubThemeByName(name ,offset , limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalsub = res.body['totalPage'] as number;

        if (!response.error) {
          this.subThemes = response.data as libSubTheme[];
          this.totalPageSubThemes = totalsub;
          this.isSubThemes = true;
        }
        else {
          this.alertService.error(response.error);
          this.isSubThemes = false;
        }
      })
  }

  pageEventsSubThemes(event: any) {
    const offset = event.pageIndex * event.pageSize;
    //console.log(offset, event.pageSize, 'report');

    if (this.filterSubTheme && this.filterSubTheme != "") {
      this.getSubThemesByName(this.filterSubTheme, offset, event.pageSize);
    }
    else {
      this.getSubThemes(offset, event.pageSize);
    }
  }


  getInstitutions(offset:number , limit: number) {
    this.libraryService.getInstitution(offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalInstitution = res.body['totalPage'] as number;

        if (!response.error) {
          this.institutions = response.data as libInstitution[];
          this.totalPageInstitutions = totalInstitution;
          this.isInstitution = true;
        }
        else {
          this.alertService.error(response.error);
          this.isInstitution = false;
        }
      })
  }

  getInstitutionsByName(name: string ,offset:number , limit: number) {
    this.libraryService.getInstitutionByName(name ,offset, limit)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        const totalInstitution = res.body['totalPage'] as number;

        if (!response.error) {
          this.institutions = response.data as libInstitution[];
          this.totalPageInstitutions = totalInstitution;
          this.isInstitution = true;
        }
        else {
          this.alertService.error(response.error);
          this.isInstitution = false;
        }
      })
  }

  pageEventsInstitutions(event: any) {
    const offset = event.pageIndex * event.pageSize;
    //console.log(offset, event.pageSize, 'report');

    if (this.filterSubTheme && this.filterSubTheme != "") {
      this.getInstitutionsByName(this.filterSubTheme, offset, event.pageSize);
    }
    else {
      this.getInstitutions(offset, event.pageSize);
    }
  }

  /*  getExercicies() {
     for (let i = 0; i < 20; i++) {
       this.testeQuestions[i].codigo = "085417", i;
       console.info(this.testeQuestions[i])
     }
   } */



  getExercisesByName() {

    if (this.filterNameExercise.length > 3) {
      this.loading = true;
      this.exerciseService.getExercisesByName(this.filterNameExercise)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.exercises = response.data as Exercise[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
  }


  getQuestionTypes() {
    this.libraryService.getQuestionType()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questionTypes = response.data as libQuestionType[];
          this.isQuestionTypes = true;
        }
        else {
          this.alertService.error(response.error);
          this.isQuestionTypes = false;
        }
      })
  }


  createMock() {
    if (this.deviceService.isMobile()) {

      const dialogRef = this.dialog.open(MockCreateComponent, {
        minWidth: '80vw',
        height: '100vw',
        data: { edit: false }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getMocks(0,20);
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(MockCreateComponent, {
        minWidth: '80vw',
        maxHeight: '40vw',
        data: { edit: false }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getMocks(0,20);
        }
      });
    }
  }
  Deletelib(val: number, thisId: number) {
    const dialogRef = this.dialog.open(DeletelibComponent, {
      data: { value: val, id: thisId }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        //console.log(val);
        switch (val) {
          case 0:
            this.getMocks(0,20);
            break;
          case 1:
            this.getQuestions(0,20);
            break;
          case 2:
            this.getDisciplines(0,20);
            break;
          case 5:
            this.getInstitutions(0,20);
            break;
          case 4:
            this.getSubThemes(0,20);
            break;
          case 3:
            this.getThemes(0,20);
            break;
          case 6:
            this.getQuestionTypes();
            break;
        }
      }
    });
  }

  createInstitution() {
    const dialogRef = this.dialog.open(CreateInstitutionLibComponent, {
      data: { edit: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getInstitutions(0,20);
      }
    });
  }

  createQuestionType() {
    const dialogRef = this.dialog.open(CreateQuestionTypeLibComponent, {
      data: { edit: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getQuestionTypes();
      }
    });
  }

  createExercicie() {
    const dialogRef = this.dialog.open(CreateExerciseComponent, {
      minWidth: '80vw',
      maxHeight: '40vw',
      data: { edit: true }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getMocks(0,20);
      }
    });
  }

  createQuestion(edit: boolean) {

    if (this.deviceService.isMobile()) {
      const dialogRef = this.dialog.open(CreateQuestionComponent, {
        minWidth: '80vw',
        height: '110vw',
        data: { edit }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getQuestions(0,20);
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(CreateQuestionComponent, {
        minWidth: '80vw',
        maxHeight: '40vw',
        data: { edit }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getQuestions(0,20);
        }
      });
    }
  }

  updateQuestion(edit: boolean, question: libQuestions) {

    question.answerSummation = [];

    if(question.questionTypeName == "SOMATÓRIA"){
      question.answerSummation = question.answer.split('+');
      question.answer = "";
    }
    if (this.deviceService.isMobile()) {
      const dialogRef = this.dialog.open(CreateQuestionComponent, {
        minWidth: '80vw',
        height: '110vw',
        data: { edit, question }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getQuestions(0,20);
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(CreateQuestionComponent, {
        minWidth: '80vw',
        maxHeight: '40vw',
        data: { edit, question }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getQuestions(0,20);
        }
      });
    }
  }

  updateExercicie(exercise: Exercise) {
    const dialogRef = this.dialog.open(CreateExerciseComponent, {
      minWidth: '80vw',
      maxHeight: '40vw',
      data: exercise
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getMocks(0,20);
      }
    });
  }

  createTheme() {
    if (this.deviceService.isMobile()) {
      const dialogRef = this.dialog.open(CreateThemeLibComponent, {
        panelClass: 'maindiv',
        minWidth: '80vw',
        data: { edit: false }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getThemes(0,20);
          this.getSubThemes(0,20);
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(CreateThemeLibComponent, {
        panelClass: 'maindiv',
        data: { edit: false }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getThemes(0,20);
          this.getSubThemes(0,20);
        }
      });
    }
  }

  editTheme(theme: libTheme) {
    const dialogRef = this.dialog.open(CreateThemeLibComponent, {
      panelClass: 'maindiv',
      data: { edit: true, theme, isSub: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getThemes(0,20);
      }
    });
  }

  editQuestionType(questionType: libQuestionType) {
    const dialogRef = this.dialog.open(CreateQuestionTypeLibComponent, {
      panelClass: 'maindiv',
      data: { edit: true, questionType }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getQuestionTypes();
      }
    });
  }

  editInstitution(institution: libInstitution) {
    const dialogRef = this.dialog.open(CreateInstitutionLibComponent, {
      panelClass: 'maindiv',
      data: { edit: true, institution }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getInstitutions(0,20);
      }
    });
  }

  editSubTheme(subTheme: libSubTheme) {
    const dialogRef = this.dialog.open(CreateThemeLibComponent, {
      panelClass: 'maindiv',
      data: { edit: true, subTheme, isSub: true }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getSubThemes(0,20);
      }
    });
  }

  updateMock(mock: Mock) {

    if (this.deviceService.isMobile) {
      const dialogRef = this.dialog.open(MockCreateComponent, {
        panelClass: 'maindiv',
        minWidth: '80vw',
        height: '100vw',
        data: { edit: false }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getMocks(0,20);
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(MockCreateComponent, {
        minWidth: '80vw',
        maxHeight: '40vw',
        data: mock
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getMocks(0,20);
        }
      });
    }
  }


  createDiscipline() {
    if (this.deviceService.isMobile()) {
      const dialogRef = this.dialog.open(CreateDisciplineLibComponent, {
        minWidth: '60vw',
        height: '100vw',
        panelClass: 'maindiv',
        data: { edit: false }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getDisciplines(0,20);
        }
      });

    } else {
      const dialogRef = this.dialog.open(CreateDisciplineLibComponent, {
        data: { edit: false }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.getDisciplines(0,20);
        }
      });
    }
  }

  updateDiscipline(discipline: Discipline) {

    const dialogRef = this.dialog.open(CreateDisciplineLibComponent, {
      panelClass: 'maindiv',
      data: { edit: true, discipline }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getDisciplines(0,20);
      }
    });
  }


  verifyFilter(value: string) {
    if (value) {
      return value.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 || !this.filter;
    } else {
      return true;
    }
  }

  removeMock(mockId: number) {
    if (confirm('Deseja realmente remover o Simulado?')) {
      this.mockService.removeMock(mockId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Simulado removido!', 3);
            this.getMocks(0,20);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o simulado. Verifique a conexão e tente novamente'));
    }
  }

  removeDiscipline(disciplineId: number) {

    if (confirm('Deseja realmente apagar a disciplina?')) {
      this.disciplineService.removeDiscipline(disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Disciplina removida', 3);
            this.getDisciplines(0,20);
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }
  }

  getNameDiscipline(nameDiscipline: string) {
    if (!this.userService.isAdmin()) {
      this.disciplineService.getNameDisciplineCoord(nameDiscipline, this.userService.getUserSchool())
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            let data = response.data;
            this.disciplines = data as LibDisciplines[];
            if (this.totalPage) {
              this.totalPage = data[0].totalPage;
            } else {
              this.totalPage = 0;
            }
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao buscar a disciplina. Verifique a conexão e tente novamente'));
    }
    else {
      this.disciplineService.getNameDiscipline(nameDiscipline)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            let data = response.data;
            this.disciplines = data as LibDisciplines[];
            if (this.totalPage) {
              this.totalPage = data[0].totalPage;
            } else {
              this.totalPage = 0;
            }
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao buscar a disciplina. Verifique a conexão e tente novamente'));
    }
  }

}
