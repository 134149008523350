import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { PaymentBuyComponent } from './buy/payment-buy.component';
import { PaymentThanksComponent } from './thanks/payment-thanks.component';
import { PaymentPaymentsComponent } from './payments/payment-payments.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { PaymentExpiredComponent } from './expired/payment-expired.component';

@NgModule({
    declarations: [
        PaymentBuyComponent,
        PaymentThanksComponent,
        PaymentPaymentsComponent,
        PaymentExpiredComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        TextMaskModule,
        MatSelectModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTableModule
    ],
    exports: [],
    providers: [],
})
export class PaymentModule { }
