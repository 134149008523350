<div class="container pt-5 pb-5">

    <mat-accordion>
        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Modal</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-modal-init></app-modal-init>
            </ng-template>
        </mat-expansion-panel>
        
        <mat-expansion-panel  class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Landing Pages</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-landing-pages></app-create-landing-pages>
            </ng-template>
        </mat-expansion-panel>
        
    </mat-accordion>
    
</div>