import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

import { Coupon } from '../admin/payment/coupon/coupon';
import { Payer, Payments, PlaymentPlans, StudentPayment } from '../admin/payment/paying/payer';
import { Billing } from './billing';
import { Customer } from './customer';
import { Payment } from './payment';
import { Plan } from './plan';

@Injectable({ providedIn: 'root' })
export class PaymentService {

    private API = environment.ApiUrl;

    constructor(
        private http: HttpClient,
        private alertService: AlertService) { }

    getSchools() {
        return this.http.get(this.API + '/school/getSchools', { observe: 'response' });
    }

    getPaymentPlans(schoolId: number) {
        return this.http.get(this.API + '/manage/payments/school/'+schoolId)
            .pipe(map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as PlaymentPlans[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    getPlanPaymentsName(school: number, name:string) {
        return this.http.get(this.API + '/manage/payments/school/name?name='+name+'&school='+school)
            .pipe(map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as Payments[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    getPlanPaymentsEmail(school: number, email:string) {
        return this.http.get(this.API + '/manage/payments/school/email?email='+email+'&school='+school)
            .pipe(map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as Payments[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    createUserPayment(payment: any) {
        return this.http.post(this.API + "/manage/payments/user", payment)
            .pipe(map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as any;
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    getUserByName(school: number, name:string) {
        return this.http.get(this.API + '/manage/payments/user/name?name='+name+'&school='+school)
            .pipe(map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as any[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    getUserByEmail(school: number, email:string) {
        return this.http.get(this.API + '/manage/payments/user/email?email='+email+'&school='+school)
            .pipe(map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as any[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    getPlanPayments(planId: number) {
        return this.http.get(this.API + '/manage/payments/plan/'+planId)
            .pipe(map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as Payments[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    blockPayment(payer: number) {
        return this.http.put(this.API + '/manage/payments/block/payment/'+payer,{}, { observe: 'response' });
    }
    releasePayment(payer: StudentPayment) {
        console.log(this.API + '/alepay/notification');
        return this.http.post(this.API + '/alepay/notification', payer, { observe: 'response' });
    }

    getPlan(planId: string) {
        return this.http.post(this.API + '/pagamento/getPlan', { planId }, { observe: 'response' });
    }

    getPlanHash(planHash: string) {
        return this.http.post(this.API + '/pagamento/getPlanHash', { planHash }, { observe: 'response' });
    }

    buyCreditCard(customer: Customer, billing: Billing, cardHash: string, planId: number) {
        return this.http.post(this.API + '/pagamento/buyCreditCard', { customer, billing, cardHash, planId }, { observe: 'response' });
    }

    buyBoleto(customer: Customer, planId: number) {
        return this.http.post(this.API + '/pagamento/buyBoleto', { customer, planId }, { observe: 'response' });
    }

    createPlan(plan: Plan) {
        return this.http.post(this.API + '/pagamento/createPlan', plan, { observe: 'response' });
    }

    getPlans() {
        return this.http.post(this.API + '/pagamento/getPlans', {}, { observe: 'response' });
    }

    savePlanChanges(plan: Plan) {
        return this.http.post(this.API + '/pagamento/savePlanChange', plan, { observe: 'response' });
    }

    removePlan(planId: number) {
        return this.http.post(this.API + '/pagamento/removePlan', { planId }, { observe: 'response' });
    }

     isPaid(userId: number) {
        return this.http.post(this.API + '/experiment/isPaid', { userId }, { observe: 'response' });
    } 
    /*isPaid(userId: number) {
        return this.http.post(this.API + '/pagamento/isPaid', { userId }, { observe: 'response' });
    }*/

    getPayments(userId: number) {
        return this.http.post(this.API + '/pagamento/getPayments', { userId }, { observe: 'response' });
    }

    getCoupons() {
        return this.http.post(this.API + '/pagamento/getCupom', {}, { observe: 'response' });
    }

    createCoupon(coupon: Coupon) {
        return this.http.post(this.API + '/pagamento/createCupom', { cupom: coupon.cupom, percent: coupon.valor }, { observe: 'response' });
    }

    removeCoupon(id: number) {
        return this.http.post(this.API + '/pagamento/removeCupom', { id }, { observe: 'response' });
    }

    getPlansObservable() {
        return this.http.post(this.API + '/pagamento/getPlans', {})
            .pipe(
                map((response: ResponseApi) => {
                    if (!response.error) {
                        return response.data as Plan[];
                    } else {
                        this.alertService.warning('Nenhum resultado!');
                        return [];
                    }
                }),
                catchError(err => {
                    this.alertService.error('Houve um erro ao carregar os planos');
                    return throwError(err);
                })
            );
    }

    getPlanPayers(planId: number) {
        return this.http.post(this.API + '/pagamento/getPlanPayers', { planId })
            .pipe(
                map((response: ResponseApi) => {
                if (!response?.error) {
                    return response?.data as Payer[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    getUserPayments(userId: number) {
        return this.http.post(this.API + '/pagamento/getPayments', { userId })
            .pipe(map((response: ResponseApi) => {
                if (!response.error) {
                    return response.data as Payment[];
                } else {
                    this.alertService.warning('Nenhum resultado!');
                    return [];
                }
            }));
    }

    savePaymentChanges(payment: Payment) {
        return this.http.post(this.API + '/pagamento/savePaymentChanges', payment)
            .pipe(
                map((response: ResponseApi) => {
                    if (response?.error) {
                        this.alertService.error(response.error);
                    }
                }),
                catchError(err => {
                    this.alertService.error('Houve um erro ao atualizar o pagamento');
                    return throwError(err);
                })
            );
    }

    removePayment(payment: Payment, studentId: number) {
        delay(5000);
        return this.http.post(this.API + '/pagamento/removePayment', { payment, studentId })
            .pipe(
                map((response: ResponseApi) => {
                    response?.error ? this.alertService.error(response.error) : this.alertService.success('Pagamento removido!');
                }),
                catchError(err => {
                    this.alertService.error('Houve um erro ao remover o pagamento');
                    return throwError(err);
                })
            );
    }

    addStudentPayment(payment: Payment) {
        return this.http.post(this.API + '/pagamento/addStudentPayment', payment)
            .pipe(
                map((response: ResponseApi) => {
                    response?.error ? this.alertService.error(response.error) : this.alertService.success('Pagamento Adicionado!');
                }),
                catchError(err => {
                    this.alertService.error('Houve um erro ao adicionar o pagamento');
                    return throwError(err);
                })
            );
    }


}
