import { Component, OnInit } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { StudyPlanService } from '../admin/study-plan/study-plan.service';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-task-student',
  templateUrl: './task-student.component.html',
  styleUrls: ['./task-student.component.scss']
})
export class TaskStudentComponent implements OnInit {

  public showMessageEdu: boolean
  public isInPlansEdu: boolean;

  constructor(
    private userService: UserService,
    private studyPlanService: StudyPlanService,
    public layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.showMessageEdu = false;
    this.isInPlansEdu = false;
    this.checkSchoolEdu();
    this.checkMessagePlansEdu();
  }


  checkSchoolEdu() {
    const id = Number(this.userService.getUserSchool());
    if (id == 122) {
      this.showMessageEdu = true;
    }
    else {
      this.showMessageEdu = false;
    }
  }

  checkMessagePlansEdu(){

    this.studyPlanService.getMessageUserPlansEdu(this.userService.getUserId()).subscribe(res =>{

      const response = res.body as ResponseApi

      if(response.data == true){
        this.isInPlansEdu = true
      }
      else{
       this.isInPlansEdu = false;
      }
    })
  }
}
