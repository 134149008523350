import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ExperimentService } from 'src/app/modules/experiment/experiment.service';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { Plan } from 'src/app/modules/payment/plan';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../../admin.service';
import { Class } from '../../../school/class';
import { School } from '../../../school/school';
import { SchoolService } from '../../../school/school.service';
import { Unit } from '../../../school/unit';
import { Student } from '../../../student/student';
import { StudentService } from '../../../student/student.service';
import { Experiment } from '../../experiment';
import { ExperimentUser } from '../experiment-user';

@Component({
    selector: 'app-experiment-user-add',
    templateUrl: './experiment-user-add.component.html'
})
export class ExperimentUserAddComponent implements OnInit, OnDestroy {

    public experimentUserForm: FormGroup;
    public experiments: Experiment[];
    public experimentUsers: ExperimentUser[];
    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public students: Student[];
    public loading: boolean;
    public showList: boolean;
    public filterPlan: number;
    public plans: Plan[];
    private admUpdate$: Subscription;

    constructor(
        private experimentService: ExperimentService,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private studentService: StudentService,
        private paymentService: PaymentService,
        private adminService: AdminService) { }

    ngOnInit(): void {
        this.experimentUserForm = this.formBuilder.group({
            studentId: ['', Validators.required],
            experimentId: [1, Validators.required]
        });

        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        this.profileInit();
        this.getExperiments();
    }

    profileInit() {
        if (this.userService.isAdmin()) {
            this.getSchools();
        } else
            if (this.userService.isGeneralCoordinator()) {
                this.getUnits(this.userService.getUserSchool());

            } else
                if (this.userService.isUnitCoordinator()) {
                    this.getClasses(this.userService.getUserUnit());
                }
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
    }

    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    getUsers(classId: number) {
        this.studentService.getStudents(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.students = response.data as Student[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente'));
    }

    getExperiments() {
        this.experimentService.getExperiments()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.experiments = response.data as Experiment[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos experimente. Verifique a conexão e tente novamente'));
    }

    getPlans() {
        this.paymentService.getPlans()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plans = response.data as Plan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }

    getExperimentUsers(planId?: number) {
        this.experimentService.getExperimentUsers(planId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.experimentUsers = response.data as ExperimentUser[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os vinculos de degustação. Verifique a conexão e tente novamente'));
    }

    openList() {
        this.getPlans();
        this.showList = true;
    }

    create() {
        if (!this.experimentUserForm.valid) {
            this.alertService.error('Preencha todos os campos corretamente!');
            return;
        }
        this.loading = true;
        const expUser = this.experimentUserForm.getRawValue() as ExperimentUser;

        this.experimentService.createExperimentUser(expUser)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.experimentUserForm.reset();
                    this.alertService.success('Vínculo registrado!');
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao criar o vínculo. Verifique a conexão e tente novamente');
            });

        this.loading = true;
    }

    remove(experimentUserId: number) {
        if (confirm('Deseja realmente remover a degustação do aluno?')) {
            this.experimentService.removeExperimentUser(experimentUserId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.getExperimentUsers(this.filterPlan);
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao remover o vínculo. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        }
    }

}
