<ng-container *ngIf="!loadingInit">
    <div *ngIf="!showExercisesList" mat-dialog-content>
        <form [formGroup]="createExerciseForm">

            <h4 *ngIf="editMode" mat-dialog-title class="mt-3 mb-3">Editar Exercício</h4>

            <!-- Name, TAG -->
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Nome" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Tag" formControlName="tag">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <mat-select formControlName="disciplineId" placeholder="Disciplinas apenas de exercícios">
                            <mat-option (click)="addDisciplineChip(discipline)" *ngFor="let discipline of exerciseDisciplines" [value]="discipline.id">
                                <span [title]="discipline.name">{{discipline.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-chip-list *ngIf="selectedDisciplines">
                        <mat-chip *ngFor="let disciplineChip of selectedDisciplines" selectable="true" removable="true" (removed)="removeDisciplineChip(disciplineChip)">
                            <span [title]="disciplineChip.name">{{disciplineChip.name}}</span>
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </form>

        <!-- Enunciado e alternativas -->
        <div class="row mt-5">
            <mat-tab-group class="width-90 center" backgroundColor="accent">
                <mat-tab label="Enunciado">
                    <ckeditor [editor]="questionEditor" [config]="editorConfig" [(ngModel)]="question" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="1">
                    <ckeditor [editor]="editor1" [config]="editorConfig" [(ngModel)]="alt1.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="2">
                    <ckeditor [editor]="editor2" [config]="editorConfig" [(ngModel)]="alt2.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="4">
                    <ckeditor [editor]="editor4" [config]="editorConfig" [(ngModel)]="alt4.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="8">
                    <ckeditor [editor]="editor8" [config]="editorConfig" [(ngModel)]="alt8.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="16">
                    <ckeditor [editor]="editor16" [config]="editorConfig" [(ngModel)]="alt16.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="32">
                    <ckeditor [editor]="editor32" [config]="editorConfig" [(ngModel)]="alt32.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="64">
                    <ckeditor [editor]="editor64" [config]="editorConfig" [(ngModel)]="alt64.value" class="width-100">
                    </ckeditor>
                </mat-tab>
            </mat-tab-group>
        </div>

        <!-- Selecionar gabarito -->
        <div class="row mt-3">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select [(ngModel)]="correctAlternatives" multiple placeholder="Gabarito">
                        <mat-option value="1">1</mat-option>
                        <mat-option value="2">2</mat-option>
                        <mat-option value="4">4</mat-option>
                        <mat-option value="8">8</mat-option>
                        <mat-option value="16">16</mat-option>
                        <mat-option value="32">32</mat-option>
                        <mat-option value="64">64</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>



        <div *ngIf="!editMode" class="row mt-5">
            <div class=" col-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver exercícios
            cadastrados</button>
            </div>
            <div class=" col-6 text-right width-100">
                <button (click)="createExercise()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
            </div>
        </div>

        <div *ngIf="editMode" class="row mt-5 text-right">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </div>

    <div *ngIf="showExercisesList" mat-dialog-content class="">


        <div class="row">
            <div class="col-md">
                <!-- disciplines exercises only -->
                <mat-form-field class="width-100">
                    <mat-select placeholder="Disciplina de exercício" [(ngModel)]="filterDisciplineId" (selectionChange)="getDisciplineExercises($event.value)">
                        <mat-option *ngFor="let discipline of exerciseDisciplines" [value]="discipline.id">
                            <span [title]="discipline.name">{{discipline.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <input matInput placeholder="Filtrar por nome" [(ngModel)]="filterName" (keyup)="applyFilter()">
            </mat-form-field>
        </div>

        <nb-card *ngIf="!loading">
            <nb-card-body>
                <mat-list class="width-90 mt-3 center">
                    <ng-container *ngFor="let exercise of filteredExercises">
                        <div mat-list-item class="row" style="min-height: 100px">
                            <div class="col-4">
                                {{exercise.name}}
                            </div>
                            <div class="col-4">
                                criado em {{exercise.createDate | date:'dd/MM/yyyy'}}
                            </div>
                            <div class="col-2 text-right">
                                <button (click)="editExercise(exercise)" nbButton size="tiny" outline status="primary">Editar</button>
                            </div>
                            <div class="col-2 text-right">
                                <button size="tiny" (click)="removeExercise(exercise.id)" status="danger" nbButton ghost>
                    <nb-icon icon="trash"></nb-icon>
                  </button>
                            </div>
                        </div>
                    </ng-container>
                </mat-list>
            </nb-card-body>
        </nb-card>

        <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>

        <div class="text-right width-100">
            <button (click)="showExercisesList = false;" class="right" nbButton size="small" status="primary">voltar</button>
        </div>

    </div>
</ng-container>
<mat-spinner *ngIf="loadingInit" class="center mt-5 mb-3"></mat-spinner>
