<div class="width-100" *ngIf="!list" >
    <div class="row">
        <div class="col-md-6" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Selecione a escola" (selectionChange)=" duplicateDisclipline.requestPlans($event.value)">
                    <mat-option *ngFor="let school of  duplicateDisclipline.getSchools()" [title]="school.name" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div [ngClass]="{'col-6': userService.isAdmin(), 'col-12': !userService.isAdmin()}">
            <mat-form-field class="width-100">
                <mat-select placeholder="Selecione o plano" (selectionChange)="duplicateDisclipline.requestPlanDisciplines($event.value)">
                    <mat-option *ngFor="let plan of duplicateDisclipline.getPlans()" [value]="plan.id" [title]="plan.name">
                        <span [title]="plan.name"> {{plan.name}}</span>

                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <mat-form-field appearance="fill" class="width-100">
                <mat-label>Buscar disciplina por nome</mat-label>
                <input matInput type="text" [(ngModel)]="disciplineName">
                <button *ngIf="disciplineName" matSuffix mat-icon-button aria-label="Search" (click)="duplicateDisclipline.requestNameDiscipline(disciplineName)">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
            <mat-spinner class="mt-1 center" *ngIf="searching" [diameter]="20"></mat-spinner>
        </div>
    </div>
    <div class="row width-100 mt-1 mb-1" *ngIf="disciplines"><hr></div>
    <div class="row" *ngIf="disciplines">
        <div class="col-md-12">
            <h6>Disciplinas encontradas</h6>
        </div>
        <div class="col-md-12 text-right">
            <button (click)="clear()" nbButton size="tiny" outline status="primary">Limpar</button>
        </div>
    </div>
    <div class="row" *ngIf="duplicateDisclipline.getPlanDisciplines().length > 0">
        <nb-list class="col-12 mt-3 max-list">
            <nb-list-item class="bg-color-accent list-header tableborder">
                <div class="row">
                    <div class="col-md-10">Nome</div>
                    <div class="col-md-2 text-right">Ações</div>
                </div>
            </nb-list-item>
            <nb-list-item class="itenstable" *ngFor="let discipline of duplicateDisclipline.getPlanDisciplines()">
                <div class="row">
                    <div class="col-md-10">{{discipline.name}}</div>
                    <div class="col-md-2 text-right">
                        <button right mat-icon-button color="accent" (click)="duplicateDisclipline.selectedDisciplines(discipline)" >
                            <mat-icon>add_box</mat-icon>
                        </button>
                    </div>
                </div>
            </nb-list-item>
        </nb-list>
    </div>
    <div class="row width-100 mt-1 mb-1" *ngIf="duplicateDisclipline.get().length > 0"><hr></div>
    <div class="row" *ngIf="duplicateDisclipline.get().length > 0">
        <div class="col-md-12">
            <h6>Disciplinas selecionadas</h6>
        </div>
        <div class="col-md-6 text-left">
            <button (click)="duplicateDisclipline.clear()" nbButton size="tiny" outline status="primary">Limpar</button>
        </div>
        <div class="col-md-6 text-right">
           <!--  <button (click)="duplicateDisclipline.duplicate()" nbButton size="tiny" outline status="primary">Duplicar</button> -->
        </div>
    </div>
    <div class="row" *ngIf="duplicateDisclipline.get().length > 0">
        <nb-list class="col-md-12 mt-3">
            <nb-list-item class="bg-color-primary list-header">
                <div class="row">
                    <div class="col-md-10">Disciplina</div>
                    <div class="col-md-2 text-right">Ações</div>
                </div>
            </nb-list-item>
            <nb-list-item class="itenstable row pt-0" *ngFor="let discipline of duplicateDisclipline.get()">
                <div class="width-100">
                    <div class="col-md-12 p-0">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header class="p-panel-header">
                                <span class="text-color-accent d-flex">
                                    <div *ngIf="duplicateDisclipline.loadingDuplicate"  class="text-center">
                                        <mat-spinner [diameter]="30" class="center"></mat-spinner>
                                    </div>
                                    <button *ngIf="!duplicateDisclipline.loadingDuplicate" (click)="duplicateDisclipline.duplicate(discipline)" nbButton 
                                    size="tiny" outline status="primary" ghost>Duplicar</button>

                                    <button size="tiny" (click)="duplicateDisclipline.remove(discipline.getId())" status="danger" nbButton ghost>
                                        <nb-icon icon="trash"></nb-icon>
                                    </button>
                                    {{discipline.getDisciplineName()}}
                                    
                                </span>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <div class="width-100 mb-3 mt-2">
                                    <b>Mudar nome da discilplina: </b><input class="min-width-400" (keyup)="discipline.setDisciplineName($event.target.value)" type="text" value="{{discipline.getDisciplineName()}}"/>
                                </div>
                                <div class="width-100">
                                    <!-- <div class="col">
                                        <mat-checkbox type="checkbox" (change)="discipline.setConfigVideoExercises($event.checked)" >Duplicar exercícios dos videos</mat-checkbox>
                                    </div>
                                    <div class="col">
                                        <mat-checkbox (change)="discipline.setConfigVideoIframes($event.checked)">Duplicar iframes dos videos</mat-checkbox>
                                    </div>
                                    <div class="col">
                                        <mat-checkbox   (change)="discipline.setConfigVideoAnnex($event.checked)">Duplicar anexo dos videos</mat-checkbox>
                                    </div> -->
                                </div>
                                <h6 class="mt-3 mb-3 pl-0">Vídeos da Disciplina</h6>
                                <nb-list-item class="itenstable" *ngFor="let video of  discipline.getVideos()">
                                    <div class="row width-100">
                                        <div class="col-md-12 col-2 d-flex">
                                            <button size="tiny" (click)="discipline.removeVideo(video.getId())" status="danger" nbButton ghost>
                                                <nb-icon icon="trash"></nb-icon>
                                            </button>
                                            <input class="width-100 remove-border-input" type="text" (change)="discipline.setVideoName(video.getId(), $event.target.value)" [value]="video.getName()"/>
                                        </div>
                                    </div>
                                </nb-list-item>
                            </ng-template>
                        </mat-expansion-panel>
                    </div>
                </div>
            </nb-list-item>
        </nb-list>
    </div>
</div>