<div class="container">
    <form [formGroup]="recoveryForm" class="form mt-4 text-center">

        <div class="row text-center pt-3">
            <div class="col-md-5 center">
                <mat-card class="no-padding">
                    <mat-card-header class="bg-color-primary pt-3 text-center">
                        <mat-card-title class="text-big text-center"><img class="mt-4 mb-3 center" [src]="theme?.logo">
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="pt-3">

                        <div class="form-group" *ngIf="sended">
                            <nb-alert class="mr-4 ml-4" accent="warning">Você receberá um email com uma nova senha. Você
                                poderá alterar a sua senha acessando o item <b>"Perfil"</b> no menu lateral.</nb-alert>
                        </div>

                        <h5 *ngIf="!sended" class="text-center text-color-primary">Recuperar senha</h5>

                        <div class="form-group" *ngIf="!sended">
                            <i class="fa fa-user-circle fa-lg mr-2"></i>
                            <mat-form-field class="width-90">
                                <input formControlName="email" matInput placeholder="E-mail">
                                <mat-error *ngIf="recoveryForm?.get('email').errors?.email">
                                    Email inválido!
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-card-content>

                    <mat-card-actions class="pb-3">
                        <div class="form-group" *ngIf="!sended">
                            <button (click)="recovery()" mat-raised-button mat-button color="accent" class="width-90"
                                [disabled]="recoveryForm.invalid || sending" type="submit">
                                Receber nova senha
                            </button>
                        </div>
                        <div class="form-group"  >
                            <button (click)="goToLogin()" mat-raised-button mat-button color="accent" class="width-90">
                                Voltar a tela de Login
                            </button>
                        </div>

                        <div class="form-group pt-2">
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>


    </form>
</div>