<div *ngIf="!showList">
    <form [formGroup]="form">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Sala de aula</h4>

        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Início</mat-label>
                    <input matInput placeholder="Início" formControlName="initDate" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Término</mat-label>
                    <input matInput placeholder="Término" formControlName="endDate" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" formControlName="schoolId" (selectionChange)="getUnits($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col" *ngIf="userService.isAdmin() || this.userService.isGeneralCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Unidade" formControlName="unitId" (selectionChange)="getClasses($event.value)">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" formControlName="classId">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <ng-container *ngIf="!userService.isTeacher()">{{class.name}}</ng-container>
                            <ng-container *ngIf="userService.isTeacher()">
                                {{class.schoolName}} - {{class.unitName}} - {{class.name}}
                            </ng-container>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Link</mat-label>
                    <input matInput placeholder="Link" formControlName="link">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Link da aula gravada</mat-label>
                    <input matInput placeholder="https://dominio.com" formControlName="recordLink">
                </mat-form-field>
            </div>
        </div>

    </form>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver salas
        cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChangeByClass(class)" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>

<div *ngIf="showList" class="">

    <div class="row">
        <mat-form-field appearance="outline" class="width-60 mt-3 ml-2 col-md-12" ngDefaultControl>
            <input matInput placeholder="Buscar por nome" class="aling-right" [(ngModel)]="filterClass">
            <mat-icon matSuffix class="corpadraolup" (click)="getClassByName(filterClass)">search</mat-icon>
        </mat-form-field>
        <div class="col" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Escola" [(ngModel)]="selectedSchoolId" (selectionChange)="getUnits($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col" *ngIf="userService.isAdmin() || this.userService.isGeneralCoordinator()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Unidade" [(ngModel)]="selectedUnitId" (selectionChange)="getClasses($event.value)">
                    <mat-option [value]="0">
                        Selecione uma Unidade
                    </mat-option>
                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                        {{unit.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="width-100">
                <mat-select placeholder="Turma" [(ngModel)]="filterClassId" (selectionChange)="getClassrooms($event.value)">
                    <mat-option [value]="0">
                        Selecione uma Turma
                    </mat-option>
                    <mat-option *ngFor="let class of classes" [value]="class.id">
                        {{class.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col" *ngIf="showButton">
            <button (click)="editClassrooms()" nbButton size="small" outline status="primary">
          Editar Salas de Aula
      </button>
        </div>
    </div>

    <nb-list class="width-90 mt-3 center">
        <nb-list-item *ngFor="let classroom of classrooms">
            <div class="row">
                <div class="col-8">
                    {{classroom.name}}
                </div>
                <!-- <div class="col-2">
                  criado em {{discipline.createDate | date:'dd/MM/yyyy'}}
              </div> -->
                <div class="col-3 text-right">
                    <button (click)="edit(classroom)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-1 text-right">
                    <button size="tiny" (click)="remove(classroom)" status="danger" nbButton ghost>
            <nb-icon icon="trash"></nb-icon>
          </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>