import { School } from './../../school';
import { routes } from '../../../admin.routing';
import { NativeDateAdapter } from '@angular/material/core';

import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { SchoolService } from '../../school.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Unit } from '../../unit';
import { ResponseApi } from 'src/app/core/models/response-api';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../../admin.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Subscription } from 'rxjs';
import { Routes } from '@angular/router';
import { Route } from '@angular/compiler/src/core';

interface RouteMain {
  id: number;
  descr: string;
  icon: string;
  link: string;
  order: number;
  schools: { id: number, name: string }[];
}
interface SchoolRoute {
  Routes: { id: number, descr: string }[];
}

@Component({
  selector: 'app-edit-school-routes',
  templateUrl: './edit-school-routes.component.html',
  styleUrls: ['./edit-school-routes.component.scss']
})
export class EditSchoolRoutesComponent implements OnInit, OnDestroy {

  public icons: string[];
  public orderroots: number[];
  public editSchoolRouteForm: FormGroup;
  public showSchoolList: boolean;
  public schools: School[];
  public selectedRoutes: { id: number, descr: string }[];
  public schoolchip: { id: number, route: string }[];
  public routes: { id?: number, route?: string, icon?: string, link?: string, order?: number }[];
  public routeget: SchoolRoute;
  public teste: string[] = [];
  public editMode: boolean;
  public loading: boolean;
  public route: RouteMain;
  private admUpdate$: Subscription;


  constructor(
    @Inject(MAT_DIALOG_DATA) public School: School,
    @Optional() public dialogRef: MatDialogRef<EditSchoolRoutesComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private adminService: AdminService) { }

  ngOnInit() {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.editSchoolRouteForm = this.formBuilder.group({
      id: this.School.id,
      Routes: [''],
    });
    this.schoolService.getSchoolRoute(this.School.id).subscribe(res => {
      const response = res.body as ResponseApi;
      this.routeget = response.data as SchoolRoute;
      this.editSchoolRouteForm.patchValue(this.routeget);

      this.routeget?.Routes?.forEach((route) => this.selectedRoutes.push(route));


      /* for(let i = 0 ; i < this.routeget.schools.length ; i++){
        this.selectedschools.push(this.routeget.schools[i]);
      } */

      if (!response.error) {
        //console.log(response + " resposta da api");
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar as rotas. Verifique a conexão e tente novamente'));

    this.routes = [];
    this.schools = [];
    this.schoolchip = [];
    this.icons = [];
    this.orderroots = [];
    this.selectedRoutes = [];
    this.getRoutes()
  }
  addRouteChip(rot: { id: number, descr: string }) {
    const index = this.selectedRoutes.indexOf(rot);

    if (index < 0) {
      this.selectedRoutes.push(rot);
      this.editSchoolRouteForm.get('Routes').reset();
    }
  }

  removeSChoollChip(rot: { id: number, descr: string }) {
    const index = this.selectedRoutes.indexOf(rot);

    if (index >= 0) {
      this.selectedRoutes.splice(index, 1);
    }
  }
  getRoutes(id?: number) {
    this.adminService.getRoutes().subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.routes = response.data as RouteMain[];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar as rotas. Verifique a conexão e tente novamente'));
  }

  saveSchoolRouteChanges(id?: number) {
    if (this.editSchoolRouteForm.valid) {

      this.loading = true;
      const routeid = [];
      this.selectedRoutes.map(r => routeid.push(r.id));
      this.editSchoolRouteForm.get('Routes').setValue(routeid);
      const schooledit = this.editSchoolRouteForm.getRawValue();
      this.schoolService.saveSchoolRouteChanges(schooledit)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('escola editada com sucesso', 3);
            this.adminService.updateChields();
            this.dialogRef.close(true);
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar a edição. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }
}
