<div *ngIf="!showList">
    <form [formGroup]="createIframeForm">
        <div class="row width-100">
            <div class="col-md-4" *ngIf="userService.isAdmin()">
                <mat-form-field>
                    <mat-label>Escola</mat-label>
                    <mat-select formControlName="schoolId" placeholder="Selecione a escola" (selectionChange)="getPlans($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>   
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Planos</mat-label>
                    <mat-select formControlName="planId" placeholder="Selecione o plano" (selectionChange)="getDisciplines($event.value)">
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            <span [title]="plan.name"> {{plan.name}}</span>   
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select formControlName="disciplineId" placeholder="Disciplinas" (selectionChange)="getDisciplineVideos($event.value, true)">
                        <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                            <span [title]="discipline.name">  {{discipline.name}}</span>  
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Videos</mat-label>
                    <mat-select formControlName="videoId" placeholder="Selecione o Video">
                        <mat-option *ngFor="let video of videos" [value]="video.id">
                            <span [title]="video.title">{{video.title}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row width-100 mt-2">
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Nome" value="value" formControlName="nome">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Iframe" value="value" formControlName="iframe">
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!editMode" class="row mt-4">
            <div class=" col-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Iframes
                    cadastrados</button>
            </div>
            <div class=" col-6 text-right width-100">
                <button class="right" nbButton size="small" status="primary" (click)="createIframe()" class="right bg-color-primary border-primary">Cadastrar</button>
            </div>

        </div>

        <div *ngIf="editMode" class="row mt-4">
            <div class="col-md-12 text-right width-100">
                <button class="right" nbButton size="small" status="primary" (click)="updateIframe()">Atualizar</button>
            </div>

        </div>
    </form>
</div>


<div *ngIf="showList">
    <div class="col-md-4">
        <mat-form-field appearance="outline" class="width-90 mt-3" ngDefaultControl>
            <input matInput placeholder="Buscar" class="aling-right" [(ngModel)]="nameIframe">
            <mat-icon matSuffix class="corpadraolup" (click)="checkGetName(nameIframe)">search</mat-icon>
        </mat-form-field>
    </div>
    <nb-list class="width-100 mt-1">
        <nb-list-item class="corpadrao list-header" style="color: white ">
            <div class="row ">
                <div class="col-md-10 ">Nome</div>
                <div class="col-md-2">Ações</div>
            </div>
        </nb-list-item>

        <nb-list-item *ngFor="let i of iframes">
            <div class="row">
                <div class="col-md-10">{{i.nome}}</div>
                <div class="col-md-1">
                    <button (click)="editIframe(i)" nbButton size="tiny" outline status="primary">
                        <nb-icon icon="edit"></nb-icon>
                    </button>
                </div>
                <div class="col-md-1">
                    <button (click)="deleteiframe(i.planId , i.videoId)" size="tiny" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
</div>

<div class="row" *ngIf="showList">
    <div class="text-right width-100">
        <button class="right" nbButton size="small" status="primary" (click)="backButton()">Voltar</button>
    </div>
</div>