<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-5 mt-2">
            <!-- plan not selected on mobile -->
            <ng-container *ngIf="!layoutService.isMobile || !folderSelected">
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">Biblioteca de</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Arquivos</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Arquivos</h6>
                        <p class="fs-13 pb-0">Escolha uma pasta para carregar os arquivos</p>
                    </div>
                </div>

                <!-- SELECT PLANO LISTA DESKTOP -->
                <div class="plan-list">
                    <mat-list *ngIf="!loadingFolders">
                        <mat-list-item *ngFor="let file of files" (click)="selectFolder(file.files, file.folder)">
                            <div class="plan-item mb-3" matRipple [ngClass]="{'bg-color-accent': folderSelected == file?.folder}">
                                <div class="plan-icon">
                                    <mat-icon [color]=" (folderSelected == file?.folder) ? null : 'accent' ">folder</mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ file?.folder }}</strong>
                                    <span class="plan-expire"></span>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loadingFolders" class="center table-spinner" color="accent"></mat-spinner>
                </div>
                <!-- SELECT PLANO LISTA MOBILE
                <div class="plan-list" *ngIf="layoutService.isMobile">
                    <mat-form-field class="width-100 mat-form-plan-list" appearance="fill" *ngIf="!loadingFolders">
                        <mat-select placeholder="Selecione a pasta" class="plan-list" [(ngModel)]="firstFolder" (selectionChange)="selectFolderByFile($event.value, files)">
                            <mat-option [value]="file.files" *ngFor="let file of files">
                                {{file.folder}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-spinner *ngIf="loadingFolders" class="center table-spinner" color="accent"></mat-spinner>
                </div>
 -->
                <!--  <div class="plan-list">
                    <mat-list *ngIf="!loadingFolders">
                        <mat-list-item *ngFor="let file of files" (click)="selectFolder(file.files, file.folder)">
                            <div class="plan-item mb-3" matRipple [ngClass]="{'bg-color-yellow': folderSelected == file?.folder}">
                                <div class="plan-icon">
                                    <mat-icon color="accent" svgIcon="opened_folder"></mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ file?.folder }}</strong>
                                    <span class="plan-expire"></span>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loadingFolders" class="center table-spinner" color="accent"></mat-spinner>
                </div> -->
            </ng-container>
            <ng-container *ngIf="layoutService.isMobile && folderSelected">
                <div class="selected-plan page-title-container mt-2 pt-10">
                    <div class="lesson-title">
                        <div class="page-title">Pasta selecionada</div>
                        <h6 class="mb-0">{{folderSelected}}</h6>
                        <p class="fs-13 pb-0">Escolha uma pasta para carregar os arquivos</p>
                    </div>
                    <div class="lesson-back">
                        <button (click)="folderSelected = null" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- File list -->
        <div class="col-md-7 mt-2 height-100" [ngClass]="{'margin-de-cima': !layoutService.isMobile}">

            <!-- Menu de Busca -->
            <div class="row">
                <div class="col-md-6" *ngIf="!layoutService.isMobile && folderSelected">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Buscar pelo nome" [(ngModel)]="filterFiles" (keyup)="applyFilter()">
                    </mat-form-field>
                </div>
                <div class="col-md-4" *ngIf="!layoutService.isMobile && folderSelected">
                    <mat-form-field>
                        <mat-select placeholder="Tags">
                            <mat-option (click)="getFilesFromDisciplineTags(tag.id)" *ngFor="let tag of disciplineTag" [value]="tag.id">
                                {{tag.tag_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- Lista de arquivos -->
            <ng-container>
                <div *ngIf="fileDataSource?.data?.length && !loadingFiles && folderSelected" class="mt-2 table-container table-container-discipline height-100" [ngClass]="{'position-r': !layoutService.isMobile}">
                    <nb-list class="width-100 mb-3">
                        <nb-list-item class="bg-color-accent list-header brl-5 brr-5 fs-13">
                            <div class="row m-0">
                                <div class="col-md-6 c-pointer" (click)="toggleOrderDirection('name')" [hidden]="layoutService.isMobile">
                                    <span class="d-inline-flex">
                                        <span class="mr-2">Nome</span>
                                        <mat-icon class="material-icons line-height-07" *ngIf="nameDirection === 'desc'">arrow_drop_down</mat-icon>
                                        <mat-icon class="material-icons line-height-07" *ngIf="nameDirection === 'asc'">arrow_drop_up</mat-icon>
                                        <mat-icon class="material-icons line-height-05" *ngIf="nameDirection === 'noOrder'">filter_list</mat-icon>
                                    </span>
                                </div>
                                <div class="c-pointer" [ngClass]="{'col-md-4': !layoutService.isMobile , 'col-10' : layoutService.isMobile}" (click)="toggleOrderDirection('date')">
                                    <span class="d-inline-flex">
                                        <span class="mr-2">CRIADO EM</span>
                                        <mat-icon class="material-icons line-height-07" *ngIf="dateDirection === 'desc'">arrow_drop_down</mat-icon>
                                        <mat-icon class="material-icons line-height-07" *ngIf="dateDirection === 'asc'">arrow_drop_up</mat-icon>
                                        <mat-icon class="material-icons line-height-02" *ngIf="dateDirection === 'noOrder'">filter_list</mat-icon>
                                    </span>
                                </div>
                                <!-- <div [ngClass]="{'col-md-4': !layoutService.isMobile , 'col-10' : layoutService.isMobile}">CRIADO EM</div> -->
                                <div [ngClass]="{'col-md-2': !layoutService.isMobile , 'col-2' : layoutService.isMobile}">Link</div>
                            </div>
                        </nb-list-item>
                        <nb-list-item *ngFor="let file of filteredFiles; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                            <div class="row m-0">
                                <div [ngClass]="{'col-md-5': !layoutService.isMobile , 'col-9' : layoutService.isMobile}">
                                    <span class="mobileFileList">{{file.name}}</span>
                                    <button class="mobileDisciplineLink table-mobile-link mr-10px" color="accent" *ngIf="layoutService.isMobile" mat-button (click)="open(file.file)">
                                        <span class="white-space-breack">{{file.name}} </span>
                                        <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent" >Acessar</span>
                                    </button>
                                </div>
                                <div class="col-md-5" [hidden]="layoutService.isMobile">
                                    {{file.created_at | date:'dd/M/yyyy HH:mm'}}
                                </div>
                                <div [ngClass]="{'col-md-2': !layoutService.isMobile , 'col-2' : layoutService.isMobile}">
                                    <button mat-button color="accent" (click)="open(file.file)">
                                        <strong>Acessar</strong>
                                    </button>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>
                <!-- select a folder -->
                <div *ngIf="!folderSelected && !loadingFiles" class="plan-not-selected">
                    <h4>Selecione uma pasta</h4>
                    <br />
                    <p>Escolha uma pasta para carregar os arquivos</p>
                </div>
                <!-- empty folder -->
                <div *ngIf="fileDataSource && !fileDataSource?.data?.length && !loadingFiles" class="plan-not-selected">
                    <h4>Nenhum arquivo</h4>
                    <br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
                <mat-spinner *ngIf="loadingFiles" class="center table-spinner" color="accent"></mat-spinner>
            </ng-container>
        </div>
    </div>
</div>






























<!-- CÓDIGO ANTERIOR - COM OS ERROS DAS PASTAS NÃO SENDO EXIBIDAS -->

<!-- <div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-5 mt-2">
            <ng-container *ngIf="!layoutService.isMobile || !folderSelected">
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">Biblioteca de</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Arquivos</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Arquivos</h6>
                        <p class="fs-13 pb-0">Escolha uma pasta para carregar os arquivos</p>
                    </div>
                </div>
                <div class="plan-list">
                    <mat-list *ngIf="!loadingFoldersClass && !loadingFoldersPlans">
                        <mat-list-item *ngFor="let folder of foldersClass" (click)="selectedFolder(folder.id, folder.name, 0, folder.id_turma)">
                            <div class="plan-item mb-3" matRipple
                                [ngClass]="{'bg-color-accent': folderSelected == folder?.name}">
                                <div class="plan-icon">
                                    <mat-icon color="accent" svgIcon="opened_folder"></mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ folder?.name }}</strong>
                                    <span class="plan-expire"></span>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-list-item *ngFor="let folder of foldersPlans" (click)="selectedFolder(folder.id, folder.name, 1, folder.planId)">
                            <div class="plan-item mb-3" matRipple
                                [ngClass]="{'bg-color-accent': folderSelected == folder?.name}">
                                <div class="plan-icon">
                                    <mat-icon color="accent" svgIcon="opened_folder"></mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ folder?.name }}</strong>
                                    <span class="plan-expire"></span>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loadingFoldersClass && loadingFoldersPlans" class="center table-spinner" color="accent"></mat-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="layoutService.isMobile && folderSelected">
                <div class="selected-plan page-title-container mt-2 pt-10">
                    <div class="lesson-title">
                        <div class="page-title">Pasta selecionada</div>
                        <h6 class="mb-0">{{folderSelected}}</h6>
                        <p class="fs-13 pb-0">Escolha uma pasta para carregar os arquivos</p>
                    </div>
                    <div class="lesson-back">
                        <button (click)="folderSelected = null" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-7 mt-2 height-100">
            <ng-container>
                <div *ngIf="fileDataSource?.data?.length && !loadingFiles && folderSelected"
                    class="table-container table-container-discipline height-100" [ngClass]="{'position-r top-130': !layoutService.isMobile}">
                    <table class="table-discipline mb-3 height-100" mat-table [dataSource]="fileDataSource">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef class="brl-5"> NOME </th>
                            <td mat-cell *matCellDef="let file; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                <span class="mobileFileList">{{file.name}}</span>
                                <button class="mobileLink" *ngIf="layoutService.isMobile" mat-button (click)="open(file.file)">
                                    <span class="white-space-breack">{{file.name}} </span>
                                    <strong>Acessar</strong>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="created_at">
                            <th mat-header-cell *matHeaderCellDef [hidden]="layoutService.isMobile"> CRIADO EM</th>
                            <td mat-cell *matCellDef="let file; let i = index" [hidden]="layoutService.isMobile" [ngClass]="{'table-line-contrast': i % 2}">
                                {{file.created_at}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="link">
                            <th mat-header-cell *matHeaderCellDef class="brr-5 text-center" [hidden]="layoutService.isMobile"> LINK </th>
                            <td mat-cell *matCellDef="let file; let i = index" class="text-center" [hidden]="layoutService.isMobile" [ngClass]="{'table-line-contrast': i % 2}">
                                <button mat-button color="accent" (click)="open(file.file)">
                                    <strong>Acessar</strong>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <div *ngIf="!folderSelected && !loadingFiles" class="plan-not-selected">
                    <h4>Selecione uma pasta</h4>
                    <br />
                    <p>Escolha uma pasta para carregar os arquivos</p>
                </div>
                <div *ngIf="fileDataSource && !fileDataSource?.data?.length && !loadingFiles" class="plan-not-selected">
                    <h4>Nenhum arquivo</h4>
                    <br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
                <mat-spinner *ngIf="loadingFiles" class="center table-spinner" color="accent"></mat-spinner>
            </ng-container>
        </div>
    </div>
</div> -->
