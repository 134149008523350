<div class="mb-2" *ngIf="!editMode">
    <form [formGroup]="addExternalIntegration">
        <!-- School, Unit, Class -->
        <div class="row">
            <div class="col-md-4" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="schoolId" (selectionChange)="getUnits($event.value)"
                        placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getClasses($event.value)"
                        placeholder="Unidade">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getStudents($event.value)"
                     placeholder="Turma">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            {{class.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- Students -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="studentId"
                        placeholder="Aluno">
                        <mat-option *ngFor="let student of students" [value]="student.id">
                            {{student.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="!editCodeList">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Código externo" formControlName="code">
                </mat-form-field>
            </div> 
        </div>
        <!-- buttons -->
        <div class="row mb-3">
            <!--  <button (click)="openList()" nbButton size="small" class="smallfont" outline status="primary">Ver códigos externos</button> -->
            <div class=" col-6 width-100" *ngIf="!editCodeList">
               <button (click)="edit()" nbButton size="small" class="smallfont" outline status="primary" class="text-color-primary border-primary-thin">Editar</button>
            </div> 
            <!-- <div class="col-12 text-right right" *ngIf="show">
                <button (click)="saveCodeChanges()" class="right" nbButton size="small" status="primary" nbSpinnerStatus="warning">Salvar</button>
            </div> -->
            <div class=" col-6 width-100" *ngIf="editCodeList">
                <button (click)="editCodeList = false;" class="bg-color-primary border-primary" nbButton size="small" status="primary" nbSpinnerStatus="warning">Voltar</button>
             </div> 
            <div class=" col-6 width-100 right text-right" *ngIf="editCodeList">
                <button (click)="openEditCode()" nbButton size="small" outline status="primary" class="smallfont text-color-primary border-primary-thin">Buscar</button>
             </div> 
            <div class=" col-6 width-100 right text-right" *ngIf="!editCodeList">
                <button (click)="saveStudentCode()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" nbSpinnerStatus="warning">Cadastrar</button>
            </div>
        </div>
        
    </form>
</div>
<div class="mt-3 mb-3" *ngIf="!editMode">
    <div class="width-100">
        <div class="row">
            <div class=" col-12 width-100 left text-left mt-3 mb-3">
                <button (click)="getLastSavedCodes()" class="right bg-color-accent-300 border-accent" nbButton size="small" status="primary" nbSpinnerStatus="warning">Atualizar Lista</button>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3" >Nome</div>
            <div class="col-md-3" >Código</div>
            <div class="col-md-3" >Criado em</div>
            <div class="col-md-2" ></div>
            <div class="col-md-1" ></div>
        </div>
    </div>
    <div class="width-100"> 
        <div class="row" *ngFor="let last of lastStudentCodes">
            <div class="col-md-3" >{{last.name}}</div>
            <div class="col-md-3" >{{last.code}}</div>
            <div class="col-md-3" >{{last.date}}</div>
            <div class="col-md-2" >
                <button (click)="editCode(last)" nbButton size="tiny" outline
                  status="primary" class="right bg-color-accent-100 border-accent">Editar</button>
            </div>
            <div class="col-md-1" >
                <button size="tiny" status="danger" nbButton ghost 
                    (click)="removeCode(last.id, last.schoolId)"
                    nbSpinnerStatus="danger" nbSpinnerSize="tiny">
                    <nb-icon icon="trash"></nb-icon>
                </button>
            </div>
            <div *ngIf="lastStudentCodes== ''" class="col-md-12"> Nenhum resultado. </div>
        </div>
    </div>
</div>
<div class="mt-3 no-p" *ngIf="editMode">
    <div class="row"><div class="col-12"><h4 class="mt-3 mb-3">Editar Código Externo</h4></div></div>
    <div class="row">
        <div class="col-md-4">Nome</div>
        <div class="col-md-4">Código</div>
        <div class="col-md-4">Criado em</div>
    </div>
    <div class="row" >
        <div class="col-md-4">
            {{last.name}}
        </div>
        <div class="col-md-4">
            <mat-form-field class="width-100">
                <form [formGroup]="editForm">
                    <input matInput placeholder="Código" formControlName="code">
                </form>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            {{last.date}}
        </div>
        <div class="col-12 text-right right"><button (click)="saveChanges(last.id, last.schoolId)" class="right" nbButton size="small" status="primary" nbSpinnerStatus="warning">Salvar</button></div>
    </div>
</div>
<div class="width-100" *ngIf="loading"><mat-spinner class="center mt-5"></mat-spinner></div>
