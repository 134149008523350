import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ExerciseService } from '../../admin/exercise/exercise.service';
import { UserService } from '../../user/user.service';
import { Mock } from '../mock';
import { MockSummationQuestion } from '../mock-question';
import { MockService } from '../mock.service';
import * as moment from 'moment';
import { ResponseApi } from 'src/app/core/models/response-api';
import { SummationOptions } from 'src/app/modules/mock/summation-reply/SummationOptions';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-summation-reply',
  templateUrl: './summation-reply.component.html',
  styleUrls: ['./summation-reply.component.scss']
})
export class SummationReplyComponent implements OnInit {

  @Input() mock: Mock;
  @Input() finalize: boolean;
  @Input() timeLeft: number;
  @Output() startTimer = new EventEmitter();
  @Output() clearMock = new EventEmitter();

  public questions: MockSummationQuestion[];
  public loading: boolean;
  public question: MockSummationQuestion;
  public questionsResponse: SummationOptions[];
  public questionsSaved: boolean[];
  public questionsCorrect: SummationOptions[];
  public lastQuestion: boolean;
  public firstQuestion: boolean;
  public questionsNotReplied: number[];
  public alreadyStarted: boolean;
  public initDate: Date;
  public resultDate: Date;
  public numReplied = 0;
  public allReplied = false;
  public finished: boolean;
  public saving: boolean;
  public replies = 0;
  public i = 0;
  public loadedQuestionsId: number[] = [];


  constructor(
    private userService: UserService,
    private mockService: MockService,
    public layoutService: LayoutService,
    private alertService: AlertService,
    private exerciseService: ExerciseService,
    private router: Router,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.startDelay();
    this.firstQuestion = true;
  }

  initialize() {
    this.initializeQuestionsArrays(this.mock.numQuestions);
    this.getMockQuestion([], 0);
    this.verifyIsValidToInit();
    setTimeout(() => {
      this.startTimer.emit();
    }, 1000);
  }

  verifyInitAndResultDate() {
    if (this.mock.initDate) {
      this.initDate = moment(this.mock.initDate).toDate();
    }
    if (this.mock.resultDate) {
      this.resultDate = moment(this.mock.resultDate, 'DD/MM/YYYY hh:mm:ss').toDate();
    }
    this.verifyInitDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    !!changes.finalize && this.finalizeMock(changes.finalize.currentValue);
  }

  initializeQuestionsArrays(numQuestions: number) {
    this.questionsResponse = [];
    this.questionsSaved = [];
    this.questionsCorrect = [];
    this.questionsNotReplied = [];
    this.questions = [];

    for (let i = 0; i < numQuestions; i++) {
      let opt: SummationOptions = { opt1: false, opt2: false, opt4: false, opt8: false, opt16: false, opt32: false, opt64: false };
      this.questions.push(null);
      this.questionsResponse.push(opt);
      this.questionsSaved.push(false);
      this.questionsCorrect.push(null);
    }
  }

  getMockQuestion(ignoreQuestions: number[], questionIndex: number) {
    this.loading = true;
    this.mockService.getMockNextQuestion(this.mock.id, ignoreQuestions, this.userService.getUserId(), this.mock.random)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          const question = response.data as MockSummationQuestion;
          question.alternatives.sort((a, b) => Number(a.option) < Number(b.option) ? -1 : Number(a.option) < Number(b.option) ? 1 : 0);
          this.questions[questionIndex] = question;
          this.loadedQuestionsId.push(question.id);

          // replied
          if (question.studentResponse) {
            this.numReplied++;
            this.alreadyStarted = true;
            this.questionsSaved[questionIndex] = true;
            this.setResponseAlternativesQuestion(questionIndex, question.studentResponse as string);
            if (!this.mock.contest) {
              this.getQuestionCorrectAlternative(question.id, questionIndex);
            }
          } else {
            this.questionsSaved[questionIndex] = false;
          }

          this.question = question;

          console.info(question);
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar a questão. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  sendQuestionResponse(questionId: number, options: SummationOptions) {

    this.saving = true;
    const optStr = this.selectedResponseToString(options);

    this.mockService.sendQuestionResponse(questionId, optStr, this.mock.id, this.userService.getUserId(), this.timeLeft)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          if (!this.mock.contest) {
            this.getQuestionCorrectAlternative(questionId, this.i);
          } else {
            this.questionsSaved[this.i] = true;
          }
          this.numReplied++;
        } else {
          this.alertService.error(response.error);
        }

        this.saving = false;
      }, err => {
        this.alertService.error('Houve um erro ao salvar a resposta. Verifique a conexão e tente novamente');
        this.saving = false;
      });
  }

  getQuestionCorrectAlternative(questionId: number, exerciseIndex: number) {
    this.exerciseService.getExerciseCorrectAlternative(questionId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        const correctOptions: SummationOptions = { opt1: false, opt2: false, opt4: false, opt8: false, opt16: false, opt32: false, opt64: false };
        const arrayCorrect = (response.data as { correct: string }).correct.split('+');

        arrayCorrect.forEach(option => {
          correctOptions['opt' + option] = true;
        });

        if (!response.error) {
          this.questionsCorrect[exerciseIndex] = correctOptions;
          this.questionsSaved[exerciseIndex] = true;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar o gabarito. Verifique a conexão e tente novamente'));
  }

  next() {
    this.loading = true;
    this.firstQuestion = false;
    this.question = this.questions[this.i + 1];

    if (!this.questions[this.i + 1]) { // load next question
      this.getMockQuestion([...this.loadedQuestionsId], this.i + 1);
    } else { // already loaded
      this.loading = false;
    }

    this.i++;
    if (this.i === this.questions.length - 1) {
      this.lastQuestion = true;
    }
  }

  prev() {
    this.loading = true;
    this.lastQuestion = false;
    this.question = this.questions[this.i - 1];
    this.i--;
    this.loading = false;

    if (this.i === 0) {
      this.firstQuestion = true;
    }
  }

  end(endTime: boolean = false) {
    this.mockService.setSelectedMockToReply(null);

    if (endTime) {
      this.finalizeEndTime();
    } else {
      this.verifyShowResult();
    }
  }

  finalizeMock(finalize: boolean) {
    finalize && this.end(true);
  }

  private finalizeEndTime() {
    this.loading = true;
    this.mockService.finalizeMockTimeEnd(this.userService.getUserId(), this.mock.id)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.verifyShowResult();
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao finalizar o simulado. Verifique a conexão e tente novamente'));
  }

  verifyIsValidToInit() {

    if (this.numReplied > 0 && this.numReplied === this.questions.length) {
      this.verifyShowResult();
      return;
    }

    this.loading = false;
  }

  verifyShowResult() {
    if (this.mock.resultDate) {
      this.loading = true;
      this.mockService.verifyMockResultIsReleased(this.mock.id)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            const released = response.data as boolean;

            if (released) {
              if (this.mock.summation) {
                this.router.navigate(['/summation/result/' + this.mock.id]);
              } else {
                this.router.navigate(['/mock/result/' + this.mock.id]);
              }
            } else {
              this.finished = true;
            }

          } else {
            this.finished = true;
          }

          this.loading = false;

        }, err => {
          this.finished = true;
          this.loading = false;
        });
    } else {
      if (this.mock.summation) {
        this.router.navigate(['/summation/result/' + this.mock.id]);
      } else {
        this.router.navigate(['/mock/result/' + this.mock.id]);
      }
    }
  }

  verifyInitDate() {
    this.mock && this.initialize();
  }

  private startDelay() {
    const awaitTime = (Math.floor(Math.random() * 6) + 1) * 1000; // 0 to 7 seconds;
    setTimeout(() => {
      this.verifyInitAndResultDate();
    }, awaitTime);
  }

  verifyIsCorrectAlternative(i: number, alternative: string) {
    if (this.questionsResponse[i] && this.questionsCorrect[i] && !this.mock.contest) {
      if (this.questionsCorrect[i]['opt' + alternative]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  verifyIsIncorrectAlternative(i: number, alternative: string) {
    if (this.questionsResponse[i] && this.questionsCorrect[i] && !this.mock.contest) {

      if (!this.questionsCorrect[i]['opt' + alternative]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  selectedResponseToString(options: SummationOptions) {
    let optionsStr = '';

    if (options.opt1) {
      optionsStr += '1';
    }
    if (options.opt2) {
      optionsStr += '+2';
    }
    if (options.opt4) {
      optionsStr += '+4';
    }
    if (options.opt8) {
      optionsStr += '+8';
    }
    if (options.opt16) {
      optionsStr += '+16';
    }
    if (options.opt32) {
      optionsStr += '+32';
    }
    if (options.opt64) {
      optionsStr += '+64';
    }

    if (optionsStr.charAt(0) == '+') {
      optionsStr = optionsStr.substring(1);
    }

    return optionsStr;

  }

  disableSaveResponse(options: SummationOptions) {
    let disabled = true;

    if (options.opt1 || options.opt2 || options.opt4 || options.opt8 || options.opt16 || options.opt32 || options.opt64) {
      disabled = false;
    }

    return disabled;
  }

  setResponseAlternativesQuestion(questionIndex: number, optionsStr: string) {
    const options = optionsStr.split('+');
    options.map(opt => {
      switch (opt) {
        case '1':
          this.questionsResponse[questionIndex].opt1 = true;
          break;
        case '2':
          this.questionsResponse[questionIndex].opt2 = true;
          break;
        case '4':
          this.questionsResponse[questionIndex].opt4 = true;
          break;
        case '8':
          this.questionsResponse[questionIndex].opt8 = true;
          break;
        case '16':
          this.questionsResponse[questionIndex].opt16 = true;
          break;
        case '32':
          this.questionsResponse[questionIndex].opt32 = true;
          break;
        case '64':
          this.questionsResponse[questionIndex].opt64 = true;
          break;
        default:
          break;
      }
    })
  }

}