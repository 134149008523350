<div  >    
    
     <div *ngIf="!synchronized || !unsynchronized" class="row mt-2">
        <div class=" col-6 width-100" >
            <button class="width-100" (click)="synchronized = true;  unsynchronized = false;" 
                    nbButton size="small" outline status="primary">
                Ver Sincronizadas
            </button>
        </div>
        <div class=" col-6 width-100">
            <button class="width-100"  (click)="unsynchronized = true; synchronized = false;" 
                    nbButton size="small" outline status="danger"> 
                Ver não Sincronizadas</button>
        </div>
        
    </div>
    
    <div *ngIf="synchronized && !removeMode && !editMode">
        <nb-card size="small">
            <nb-card-header *ngIf="userService.isAdmin()">
                <h5 class="m-2" >
                   Escolas Sincronizadas <span class="material-icons info-font"> info </span>  <span class="info" > (Clique em <b>VER NÃO SINCRONIZADAS</b> para ver as que estão pendentes de sincronização! ) </span>
                     
                </h5>
              
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="schools" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let school of schoolsSync">
                       
                        <div class="row"  >
                            <div class="col-4">
                                {{school.name}}
                            </div>
                            <div class="col-4">
                                <span class="color-status-success">{{school.unitSync}} </span> 
                                
                                <span class="color-status-primary" >/{{school.total}} </span>
                                
                            </div> 
                            <div class="col-2 text-right">
                                <button size="tiny" 
                                        (click)="getSchoolUnitsUnSync(school)" 
                                        *ngIf="school.unitSync > 0 ;" 
                                        nbButton ghost>
                                    <span class="material-icons color-status-success">
                                        alarm_on
                                    </span>
                                </button> 
                                <button size="tiny" 
                                        *ngIf="school.unitSync == 0 ;"
                                        nbButton ghost>
                                    <span class="material-icons ">
                                        alarm_off
                                    </span>
                                </button> 
                               
                            </div>
                        </div>
                    </nb-list-item>
                    
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="unsynchronized && !removeMode && !editMode">
        <nb-card size="small">
            <nb-card-header *ngIf="userService.isAdmin()">
                <h5 class="m-2">Escolas Não Sincronizadas <span class="material-icons info-font"> info </span>  <span class="info" > (Clique em <b>VER SINCRONIZADAS</b> para ver as que estão pendentes de sincronização! ) </span>

                </h5>
              
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="schools" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let school of schoolsUnSync">
                        <div class="row"  >
                            <div class="col-4">
                                {{school.name}}
                            </div> 
                            <div class="col-4">
                                <span class="color-status-danger">
                                    {{school.unitNotSync}} 
                                </span> 
                                
                                <span class="color-status-primary" >
                                    /{{school.total}} 
                                </span>
                                
                            </div> 
                            <div class="col-2 text-right"> 
                                <button size="tiny" 
                                        (click)="getSchoolUnitsSync(school)"
                                        *ngIf="school.unitNotSync > 0 ;"  
                                        nbButton ghost>
                                    <span class="material-icons color-status-danger">
                                        alarm
                                    </span>
                                </button>
                                <button size="tiny" 
                                        *ngIf="school.unitNotSync == 0 ;"
                                        nbButton ghost>
                                    <span class="material-icons ">
                                        alarm_off
                                    </span>
                                </button>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="editMode && !loading && !removeMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Sincronizar Unidades: {{syncSchool.name}}  <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui as <b>UNIDADES</b>  da escola serão  <b>SINCRONIZADAS</b>  ! ) </span>
            </nb-card-header>
            <nb-card-body> 
                <div class="row">
                    <div class="col-6 text-left width-100 m-0">
                        <span class="color-status-primary  mb-2"  >Status: 
                            <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                            </span> 
                            <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                        </span>
                        
                    </div>
                    <div class="col-6 text-right width-50  m-0">
                        <button (click)="syncUnits(syncUnit)" class="right" 
                                nbButton size="small" status="primary">Sincronizar</button>
                    </div>
                </div>
                <h6>Unidades</h6>
                <nb-list *ngIf="syncUnit" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let unit of syncUnit">
                        <div class="row">
                            <div class="col-6">
                                {{unit.name}}
                            </div> 
                            
                        </div>
                    </nb-list-item>
                </nb-list> 
                
            </nb-card-body>
        </nb-card>
    
        <div class="text-right width-100">
            <button (click)="editMode = false;getSchools()" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
    <div *ngIf="removeMode && !loading && !editMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Remover Unidades: {{syncSchool.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui as <b>UNIDADES</b>  da escola serão <b>REMOVIDAS</b> ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <h5>Unidades</h5>
                <div class="col-6 text-left width-100 m-0">
                    <span class="color-status-primary  mb-2"  >Status: 
                        <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                        </span> 
                        <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                    </span>
                    
                </div>
             
                
                <div class="text-right width-100">
                    <button (click)="unSyncUnits(syncUnit)" class="right" 
                            nbButton size="small" status="primary">Remover</button>
                </div>
                <nb-list *ngIf="syncUnit" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let unit of syncUnit">
                        <div class="row">
                            <div class="col-6">
                               {{unit.name}}
                            </div> 
                           
                        </div>
                    </nb-list-item>
                </nb-list> 
                 
            </nb-card-body>
        </nb-card>
    
        <div class="text-right width-100">
            <button (click)="removeMode = false;getSchools()" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>

  
</div>

 

<div class="">
    <mat-spinner class="center" *ngIf="loading"></mat-spinner>
</div>