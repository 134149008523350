import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunityComponent } from './community.component';
import { RouterModule } from '@angular/router';
import { routes } from '../../admin/admin.routing';
import { NbAccordionModule, NbButtonModule, NbCardModule, NbIconModule, NbListModule, NbSpinnerModule } from '@nebular/theme';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { OrderModule } from 'ngx-order-pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AnswerTopicComponent } from './answer-topic/answer-topic.component';

@NgModule({
  declarations: [CommunityComponent, AnswerTopicComponent],
  imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NbCardModule,
        NbButtonModule,
        NbAccordionModule,
        NbIconModule,
        NbListModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        TextMaskModule,
        CKEditorModule,
        FormsModule,
        MatTabsModule,
        MatDialogModule,
        NbSpinnerModule,
        MatProgressSpinnerModule,
        SharedModule,
        MatListModule,
        MatDividerModule,
        MatTableModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        NgxMatSelectSearchModule,
        ClipboardModule,
        MatProgressBarModule,
        MatCardModule,
        OrderModule,
        DragDropModule,
        MatPaginatorModule,
        MatFormFieldModule
  ]
})
export class CommunityModule { }
