import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/modules/user/user.service';
import { School } from '../../school/school';
import { Unit } from '../../school/unit';
import { Class } from 'tinymce';
import { SchoolService } from '../../school/school.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IntegrationsService } from '../integrations.service';
import { IntegrationExternal } from './integrationexternal';

@Component({
    selector: 'app-create-integration',
    templateUrl: './create-integration.component.html',
    styleUrls: ['./create-integration.component.scss'],
})
export class CreateIntegrationComponent implements OnInit {
    public editMode: boolean;
    public addIntegrationForm: FormGroup;
    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public showList: boolean;
    public file: any;
    public fileMetaData: any;
    public integration: IntegrationExternal;
    public integrations : IntegrationExternal[];
    public selectedSchool:number;

    constructor(
        private formBuilder: FormBuilder,
        public userService: UserService,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private integrationService: IntegrationsService
    ) {}

    ngOnInit() {
        this.editMode = false;
        this.showList = false;

        this.addIntegrationForm = this.formBuilder.group({
            name: [''],
            link: [''],
            title:[''],
            schoolOnly: [''],
            schoolId: [Validators.required],
            classId: [],
            icon: [''],
        });

        this.getSchools();
    }

    getSchools() {
        this.schoolService.getSchools().subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'
                )
        );
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'
                )
        );
    }

    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId).subscribe(
            (res) => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            },
            (err) =>
                this.alertService.error(
                    'Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'
                )
        );
    }

    openList() {
        this.showList = true;
    }

    backBtn() {
        this.showList = false;
    }

    handleFileInput(files: FileList): void {
        if (files && files[0]) {
            const fileSizeInMb = files[0].size / 1024 / 1024;

            if (fileSizeInMb > 5) {
                this.alertService.error('Tamanho máximo 5 MB!');
            } else {
                const file = files[0];
                this.fileMetaData = {
                    name: files[0].name,
                    type: files[0].type,
                    size: files[0].size,
                };

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    this.file = reader.result;
                };
            }
        }
    }

    createIntegration() {
        this.integration =
            this.addIntegrationForm.getRawValue() as IntegrationExternal;

        if (this.file) {
            this.integration.icon = this.file;
        } else {
            this.integration.icon = '';
        }

        this.integrationService.createIntegration(this.integration)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.alertService.success('Integração cadastrada com sucesso!');
            this.addIntegrationForm.reset();
          }
          else{
            this.alertService.error(response.error);
          }
        } , err => {
          this.alertService.error("Não foi possível cadastrar os dados verifique a conexão e tente novamente!");
        })
    }

    getIntegrations(schoolId:number){
      this.integrationService.getIntegrations(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          this.integrations = response.data as IntegrationExternal[];
        }
        else{
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Não foi possível buscar os dados, verifique a conexão e tente novamente!');
      })
    }

    deleteIntegration(integrationId){
      console.log(this.selectedSchool);

      if(confirm('deseja deletar essa integração?')){
        this.integrationService.deleteIntegration(integrationId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if(!response.error){
            this.alertService.success('Integração deletada com sucesso');
            this.getIntegrations(this.selectedSchool);
          }
          else{
            this.alertService.error(response.error);
          }
        } , err => {
          this.alertService.error('Não foi possivel deletar os dados, verifique a conexão e tente novamente!');
        })
      }
    }
}
