import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
 
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 
import { ResponseApi } from 'src/app/core/models/response-api';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../admin.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Subscription, timer } from 'rxjs';
import { School } from '../../school/school';
import { Unit } from '../../school/unit';
import { SchoolService } from '../../school/school.service';
import { SyncService } from '../sync.service';
 
@Component({
  selector: 'app-school-sync',
  templateUrl: './school-sync.component.html',
  styleUrls: ['./school-sync.component.scss']
})
export class SchoolSyncComponent implements OnInit, OnDestroy {

    public createUnitForm: FormGroup;
    public showUnitsList: boolean;
    public schools: any;
    public schoolsSync: any = [];
    public schoolsUnSync: any = [];
    public units: any;
    public editMode: boolean =false;
    public loading: boolean;
    public filterSchools: School[];
    public filterSchoolId: any;
    private admUpdate$: Subscription;
    public synchronized: boolean = true;
    public unsynchronized: boolean = false; 
    public syncUnit: any;
    public removeMode: boolean =false;
    public syncProgress:any;
    syncSchool: any;
    totalTime: number;


    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public unit: Unit,
        @Optional() public dialogRef: MatDialogRef<SchoolSyncComponent>,
        private dialog: MatDialog,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formbBuilder: FormBuilder,
        private adminService: AdminService,
        public userService: UserService,
        public syncService: SyncService) { }

    ngOnInit(): void {
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
            this.syncProgress = { text: "Parado 0%", progress: 0};
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        this.createUnitForm = this.formbBuilder.group({
            id: [],
            name: ['', Validators.required],
            tag: ['', Validators.required],
            school: ['', Validators.required]
        });
        this.getSchools();
        if (this.unit) { this.initializeEditMode(); }
        this.coordinatorInit();
    }

    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            this.createUnitForm.get('school').setValue(this.userService.getUserSchool());
        }
    }

    getSchools() {
        this.loading = true;
        this.syncService.getApiSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data  ;
                    this.schoolsSync=[];
                    this.schoolsUnSync =[];
                    this.schools.forEach(element => {
                        if(element.unitSync > 0){
                            this.schoolsSync.push(element);
                        } 
                        if(element.unitNotSync > 0){
                            this.schoolsUnSync.push(element);
                        }
                    });
                    //console.log(this.schoolsSync);
                    //console.log(this.schoolsUnSync);
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getUnits() {
        this.loading = true;
        const schoolId = this.userService.isGeneralCoordinator() ? this.userService.getUserSchool() : undefined;
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente');
            });
    }

    getUnitOne(unitId: number){
        this.schoolService.getUnitOne(unitId)
            .subscribe(res => {
            const response = res.body as ResponseApi;
    
            if (!response.error) {
                this.unit = response.data as Unit;
                this.editUnit(this.unit);
            } else {
                this.alertService.error(response.error);
            }
            }, err => this.alertService.error('Houve um erro ao contar os alunos. Verifique a conexão e tente novamente'));
    }

    openList() {
        if (!this.userService.isAdmin()) {
            this.getUnits();
        }
        this.showUnitsList = true;
    }

    createUnit() {
        if (this.createUnitForm.valid) {
            this.loading = true;
            const unit = this.createUnitForm.getRawValue() as Unit;

            this.schoolService.creteUnit(unit)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Unidade cadastrada', 3);
                        this.createUnitForm.reset();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao criar a unidade. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente');
        }
    }

    duplicateUnit() {
        if (this.createUnitForm.valid) {
            this.loading = true;
            const unit = this.createUnitForm.getRawValue() as Unit;
            unit.name = unit.name + " (duplicado)";
            this.schoolService.creteUnit(unit)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Unidade duplicada', 3);
                        this.createUnitForm.reset();
                        this.dialogRef.close(true);
                        this.getUnits();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao duplicar a unidade. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente');
        }
    }

    editUnit(unit: Unit) {
        const dialogRef = this.dialog.open(SchoolSyncComponent, {
            minWidth: '60vw',
            data: unit
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.getUnits();
            }
        });
    }

    removeUnit(unitId: number) {
        if (confirm('Deseja realmente remover a Unidade?')) {
            this.schoolService.removeUnit(unitId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Unidade removida', 3);
                        if (this.userService.isAdmin()) {
                            this.getSchoolUnits(this.filterSchoolId);
                        } else {
                            this.getUnits();
                        }
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover a unidade. Verifique a conexão e tente novamente'));
        }
    }

    // only edit mode methods

    initializeEditMode() {
        this.editMode = true;
        this.createUnitForm.patchValue(this.unit);
        this.createUnitForm.get('school').setValue(this.unit.school);
    }

    saveChanges() {
        if (this.createUnitForm.valid) {

            this.loading = true;
            const unit = this.createUnitForm.getRawValue() as Unit;
            this.schoolService.saveUnitChanges(unit)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Unidade editada com sucesso', 3);
                        this.dialogRef.close(true);
                        this.getUnits();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao salvar as alterações da unidade. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente!');
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getSchoolUnits(schoolId: number) {
        this.loading = true;
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }  
    getSchoolUnitsSync(school: any) {
        this.loading = true;
        this.syncService.getApiUnits(school.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data ;
                    this.syncMode(school);
                } else {
                    this.syncUnit =[];
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    } 
    getSchoolUnitsUnSync(school: any) {
        this.loading = true;
        this.syncService.getApischoolUnits(school.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data  ;
                    this.unSyncMode(school);
                } else {
                    this.syncUnit =[];
                    this.alertService.error(response.error);
                } 
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    } 

    syncMode(school:any){
        this.editMode = true;
        this.syncUnit = []; 
        this.syncSchool = school;
        this.syncProgress = { text: "Parado 0%", progress: 0};
        this.units.forEach(element => {
            let unit = {
                name: element.name, 
                schoolId: school.id,
                external_id: element.id,
                status: 0
            };  
            this.syncUnit.push(unit);
        }); 
    }

    unSyncMode(school:any){
      
        this.removeMode = true;
        this.syncUnit = [];
        this.syncSchool = school;
        this.syncProgress = { text: "Parado 0%", progress: 0};
        this.units.forEach(element => {
            let unit = {
                name: element.name, 
                schoolId: school.id,
                external_id: element.id,
                status: 0
            };  
            this.syncUnit.push(unit);
        });  
        
    }

    syncUnits(syncUnit:any){
     
        let timer = 300;
        this.totalTime = (syncUnit.length * timer)/1000;
   
        let total = 0;
        let slice = 100/syncUnit.length;
      
        syncUnit.forEach(element => {
            setTimeout(() => {
                this.syncService.syncUnit(element)
                .subscribe(res => {
                    const response = res.body as any;

                    if (!response.error) { 
                        /* if(response.data.status == 200 || response.data.status == 204){
                            this.alertService.success('Sincronizado');
                        }else{
                            this.alertService.error('Já foi Sincronizado!');
                        } */
                        total+=slice; 
                        this.syncProgressUpdateOne(Math.floor(total));
                        const index =  this.syncUnit.indexOf(element);

                        if (index >= 0) {
                            this.syncUnit.splice(index, 1);
                        }
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            }, timer+=300);
        }); 
    }

    unSyncUnits(syncUnit:any){ 
        
        let timer = 300;
        this.totalTime = (syncUnit.length * timer)/1000;
     
        let total = 0;
        let slice = 100/syncUnit.length;
     
        syncUnit.forEach(element => {
            setTimeout(() => {
                this.syncService.unSyncUnit(element.external_id)
                .subscribe(res => {
                    const response = res.body as any;

                    if (!response.error) {
                        /* if(response.data.status == 200 || response.data.status == 204){
                            this.alertService.success('Sincronizado');
                        }else{
                            this.alertService.error('Já foi Sincronizado!');
                        } */
                        total+=slice; 
                        this.syncProgressUpdateOne(Math.floor(total));
                        const index =  this.syncUnit.indexOf(element);

                        if (index >= 0) {
                            this.syncUnit.splice(index, 1);
                        }
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            }, timer+=300);
        }); 
        
    }

    async updateSyncUnits(id:number){
        let syncUnit = this.syncUnit;
        syncUnit.forEach(element => { 
            if(element.id == id){
                element = []
            }
        });
        this.syncUnit = syncUnit;
    }
    
    async syncProgressUpdate(size:number){
        let timer = 400;
        let total = 0;
        let slice = 100/size;
        for (let index = 0; index < size; index++) { 
            setTimeout(() => {
                if(index < size){
                    this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
                }else{
                    this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
                }  
                total+=slice;

            }, timer+=400);
        }
         
    }
    async syncProgressUpdateOne(total:number){
    
        if(total < 100){
            this.syncProgress ={ text: "Carregando "+ total+"%", progress: total};
        }else{
            this.syncProgress ={ text: "Concluído 100%", progress: 100}; 
        }   
         
    }


}
