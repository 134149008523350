import { ThemeService } from './../../../core/themes/theme.service';
import { PerformanceReportService } from './performance-report.service';
import { MockReport } from './../mock/report/mock-report';
import { MoksReportComponent } from './moks-report/moks-report.component';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Class } from 'src/app/modules/admin/school/class';
import { School } from 'src/app/modules/admin/school/school';
import { SchoolService } from 'src/app/modules/admin/school/school.service';
import { Unit } from 'src/app/modules/admin/school/unit';
import { Mock } from 'src/app/modules/mock/mock';
import { MockService } from 'src/app/modules/mock/mock.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Workbook } from 'exceljs';
import jsPDF from 'jspdf';
import PdfFactory from './class/pdfFactory';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as fs from 'file-saver';

import { Student } from '../student/student';
import { NbTreeGridService } from '@nebular/theme';
import { stringify } from '@angular/compiler/src/util';
import { fstatSync, fsync } from 'fs';
import { style } from '@angular/animations';

interface XlsReport {
  NameAluno: string;
  Totalresps: string;
  TotalCorrect: string;
  Percent: string;
}

interface getFront {
  typeSim: string,
  school: string,
  unit: string,
  class: string,
  plan: string,
  nameTest: string,
  TypeRelat: string
}

interface PerformanceReport {
  studentId: number,
  idsimulado: number;
  timeLeft: string;
  dateTest: string;
  studentName: string;
  totalexercicies: number;
  correct: number;
  errors: number;
  porcent: number;
  typeSim: string,
  school: string,
  unit: string,
  class: string,
  nameTest: string,
  TypeRelat: string,
}

interface PerformanceReportdiscipline {
  studentId: number;
  idsimulado: number;
  timeLeft: string;
  dateTest: string;
  studentName: string;
  disciplineName: string;
  totalexercicies: number;
  correct: number;
  errors: number;
  points: number;
  typeSim: string,
  school: string,
  unit: string,
  class: string,
  nameTest: string,
  TypeRelat: string,
}

interface PerformanceReportNote {
  studentId: number;
  idsimulado: number;
  timeLeft: string;
  dateTest: string;
  studentName: string;
  totalexercicies: number;
  totalDone: number;
  note: number;
  typeSim: string,
  school: string,
  unit: string,
  class: string,
  nameTest: string,
  TypeRelat: string,
}


@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.scss']
})
export class PerformanceReportComponent implements OnInit {

  public displayedColumns: string[];
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public schoolId: number;
  public unit: number;
  public unitteacher: Unit;
  public reportPerfresp: PerformanceReport[];
  public reportPerfrespdisc: PerformanceReportdiscipline[];
  public reportPerfrespNote: PerformanceReportNote[];
  public classId: number;
  public mockId: number;
  public mocks: Mock[];
  public loading: boolean;
  public linkDownload: any[];
  public workBook: Workbook;
  public unitTemp: number;
  public studentsToFilter: string[];
  public mocksToFilter: string[];
  public mockReport: MockReport[];
  public filterMock: string;
  public filterStudent: string;
  public typePerformance: string;
  public order: string = 'perf.studentName';
  public reverse: boolean = false;
  public pdfFile: any;
  public xlsFile: any;
  public dadosPg: XlsReport[];
  private posy: number;
  private sizePaper: number;
  private pdfFactory: PdfFactory;
  public actualData: any;
  public btnAtualizar: boolean;

  constructor(
    private mockService: MockService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private schoolService: SchoolService,
    private performanceService: PerformanceReportService,
    private router: Router) { }


  ngOnInit() {
    this.profileInit();

    this.actualData = {
      school: {},
      unit: {},
      class: {},
      nameTest: {},
      typeSim: [''],
      TypeRelat: ['']
    }
  }

  profileInit() {

    this.btnAtualizar = false;
    this.posy = 10;
    this.sizePaper = 10;
    this.typePerformance = "";
    if (this.userService.isAdmin()) {
      this.getSchools();
    }

    if (this.userService.isGeneralCoordinator()) {
      this.schoolId = this.userService.getUserSchool();
      //this.getMocks(this.userService.getUserSchool());
      this.getUnits(this.userService.getUserSchool());
    }

    if (this.userService.isUnitCoordinator()) {
      this.schoolId = this.userService.getUserSchool();
      //this.getMocks(this.userService.getUserSchool());
      this.getClasses(this.userService.getUserUnit());
    }

    if (this.userService.isTeacher()) {
      this.schoolId = this.userService.getUserSchool();
      this.getClasses(this.userService.getUserUnit());
      //this.getMocks(this.userService.getUserSchool());
      this.getTeacherClasses(this.userService.getUserId());
    }
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente')
      });
  }

  getClasses(unitId: number) {
    this.unitTemp = unitId;
    this.loading = true;
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente')
      });
  }

  getUnits(schoolId: number) {

    /*  if (this.userService.isAdmin()) {
       this.getMocks(schoolId);
     } */
    this.loading = true;
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente')
      });
  }

  getTeacherClasses(teacherId: number) {

    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getMocks(classid: number) {
    this.mockService.getMocksClass(classid)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente'));
  }

  getMockGeneralReport(mockId: number) {
    this.loading = true;
    this.mockService.getMockGeneralReport(mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mockReport = response.data as MockReport[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar o relatório geral. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getSelectedReport(value: any) {
    if (value == "Performance geral") {
      this.typePerformance = "PG";
      this.btnAtualizar = false
    }
    if (value == "Performance disciplina") {
      this.typePerformance = "PD";
      this.btnAtualizar = false;
    }
    if (value == "Nota") {
      this.typePerformance = "NT";
      this.btnAtualizar = false;
    }
  }

  getSelectedReportBtn(classId?: number, mockId?: number) {
    if (classId == null || mockId == null) {
      alert("Selecione todos os campos")
    }
    else {

      if (this.typePerformance == "PG") {
        this.getReportPerformance(classId, mockId);
        this.btnAtualizar = true;
      }
      if (this.typePerformance == "PD") {
        this.getReportPerformanceDiscipline(mockId, classId);
        this.btnAtualizar = true;
      }
      if (this.typePerformance == "NT") {
        this.getReportPerformanceNote(classId, mockId);
        this.btnAtualizar = true;
      }
    }
  }

  getReportPerformance(classId: number, mockId: number) {
    this.performanceService.performanceReport(classId, mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.reportPerfresp = response.data as PerformanceReport[];
          if(this.reportPerfresp[0]){
            this.btnAtualizar = true;
          }
          else{
            this.btnAtualizar = false;
            this.alertService.error('não existem dados para esse plano');
          }
        }
        else {
          this.btnAtualizar = false;
          this.alertService.error(response.error)
        }
      }, err => this.alertService.error("houve um erro ao carregar verifique a conexao e tente novamente"))
  }

  getReportPerformanceDiscipline(mockId: number, classId: number) {
    this.performanceService.performanceReportDiscipline(mockId, classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.reportPerfrespdisc = response.data as PerformanceReportdiscipline[];
          if(this.reportPerfrespdisc[0]){
            this.btnAtualizar = true;
          }
          else{
            this.btnAtualizar = false;
            this.alertService.error('não existem dados para esse plano');
          }
        }
        else {
          this.btnAtualizar = false;
          this.alertService.error(response.error)
        }
      }, err => this.alertService.error("houve um erro ao carregar verifique a conexao e tente novamente"))
  }

  getReportPerformanceNote(classId: number, mockid: number) {
    this.performanceService.performanceReportNote(classId, mockid)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.reportPerfrespNote = response.data as PerformanceReportNote[];
          if(this.reportPerfrespNote[0]){
            this.btnAtualizar = true;
          }
          else{
            this.btnAtualizar = false;
            this.alertService.error('não existem dados para esse plano');
          }
        }
        else {
          this.btnAtualizar = false;
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error("houve um erro ao carregar verifique a conexao e tente novamente"))
  }

  openMock(studentId: number, mockId: number, classeid: number) {
    let isReport = true;
    this.mockService.setSelectedClassMockReportSubject(classeid);
    this.router.navigate(['/mock/result/', mockId, studentId , isReport]);
  }

  getOrder(el: string) {

    if (this.order === el) {
      this.reverse = !this.reverse;
    }
    this.order = el;
  }

  orderTable(val: string) {
    if (val == "Nome aluno") {
      this.getOrder('perf.studentName');
    }
    if (val == "Ranking") {
      this.getOrder('perf.correct');
    }
    if (val == "Tempo de temino") {
      this.getOrder('perf.timeLeft');
    }
  }
  orderTableNote(val: string) {
    if (val == "Nome aluno") {
      this.getOrder('perf.studentName');
    }
    if (val == "Ranking") {
      this.getOrder('perf.note');
    }
    if (val == "Tempo de temino") {
      this.getOrder('perf.timeLeft');
    }
  }


  generateXls() {
    if (this.typePerformance == "PG") {

      /* this.reportPerfresp.forEach(element => {
        element.school = this.actualData.school.name;
        element.typeSim = this.actualData.typeSim
        element.unit = this.actualData.unit.name;
        element.class = this.actualData.class.name;
        element.nameTest = this.actualData.nameTest.name;
        element.TypeRelat = this.actualData.TypeRelat;
      }); */

      /* let headerHead = ["Nome da escola" , "Tipo de avaliação" , "Unidade", "Turma" , "Nome da prova" , "Tipo de relatório"];
      
      let temp2 = [];
      temp2.push(this.actualData.school.name , this.actualData.typeSim , this.actualData.unit.name , this.actualData.class.name , this.actualData.nameTest.name,
        this.actualData.TypeRelat); */

      let workbook = new Workbook();

      let worksheet = workbook.addWorksheet("Employee Data");

      if (!this.userService.isAdmin()) {
        worksheet.addRow(["Nome da escola :", this.userService.getUserSchoolName()]);
      }
      else {
        worksheet.addRow(["Nome da escola :", this.actualData.school.name]);
      }
      if(this.userService.isTeacher()){
        this.schoolService.getUnitOne(this.userService.getUserUnit())
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.unitteacher = response.data as Unit;
          }
        })
        worksheet.addRow(["Unidade :" , this.unitteacher.name])
      }
      else{
        worksheet.addRow(["Unidade :", this.actualData.unit.name]);
      }
      worksheet.addRow(["Tipo de avaliação :", this.actualData.typeSim]);
      worksheet.addRow(["Turma :", this.actualData.class.name]);
      worksheet.addRow(["Nome da prova :", this.actualData.nameTest.name]);
      worksheet.addRow(["tipo de relatorio :", this.actualData.TypeRelat]);
      worksheet.addRow([" "]);


      let header = ["Nome", "id turma", "id aluno", "id simulado", "tempo restante", "Data da prova", "acertos", "erros", "porcentagem" , "total de exercicios"]
      let headerRow = worksheet.addRow(header);

      for (let x1 of this.reportPerfresp) {
        let x2 = Object.keys(x1);
        let temp = [];
        for (let y of x2) {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
      }

      let fname = "Performance geral"

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
    }

    if (this.typePerformance == "PD") {

      /* this.reportPerfrespdisc.forEach(element => {
        element.school = this.actualData.school.name;
        element.typeSim = this.actualData.typeSim
        element.unit = this.actualData.unit.name;
        element.class = this.actualData.class.name;
        element.nameTest = this.actualData.nameTest.name;
        element.TypeRelat = this.actualData.TypeRelat;
      }); */


      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Employee Data");

      if (!this.userService.isAdmin()) {
        worksheet.addRow(["Nome da escola :", this.userService.getUserSchoolName()]);
      }
      else {
        worksheet.addRow(["Nome da escola :", this.actualData.school.name]);
      }
      if(this.userService.isTeacher()){
        this.schoolService.getUnitOne(this.userService.getUserUnit())
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.unitteacher = response.data as Unit;
          }
        })
        worksheet.addRow(["Unidade :" , this.unitteacher.name])
      }
      else{
        worksheet.addRow(["Unidade :", this.actualData.unit.name]);
      }
      worksheet.addRow(["Tipo de avaliação :", this.actualData.typeSim]);
      worksheet.addRow(["Turma :", this.actualData.class.name]);
      worksheet.addRow(["Nome da prova :", this.actualData.nameTest.name]);
      worksheet.addRow(["tipo de relatorio :", this.actualData.TypeRelat]);
      worksheet.addRow([" "]);

      let header = ["Id simulado", "id aluno", "Nome Aluno", "Nome disciplina", "Acertos", "Erros", "Tempo restante", "Data da prova", "Total de exercicios", "Pontuação"]
      let headerRow = worksheet.addRow(header);

      for (let x1 of this.reportPerfrespdisc) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
      }

      let fname = "Performance Disciplina"

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
    }

    if (this.typePerformance == "NT") {

      /* this.reportPerfrespNote.forEach(element => {
        element.school = this.actualData.school.name;
        element.typeSim = this.actualData.typeSim
        element.unit = this.actualData.unit.name;
        element.class = this.actualData.class.name;
        element.nameTest = this.actualData.nameTest.name;
        element.TypeRelat = this.actualData.TypeRelat;
      }); */

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Employee Data");

      if (!this.userService.isAdmin()) {
        worksheet.addRow(["Nome da escola :", this.userService.getUserSchoolName()]);
      }
      else {
        worksheet.addRow(["Nome da escola :", this.actualData.school.name]);
      }
      if(this.userService.isTeacher()){
        this.schoolService.getUnitOne(this.userService.getUserUnit())
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.unitteacher = response.data as Unit;
          }
        })
        worksheet.addRow(["Unidade :" , this.unitteacher.name])
      }
      else{
        worksheet.addRow(["Unidade :", this.actualData.unit.name]);
      }
      worksheet.addRow(["Tipo de avaliação :", this.actualData.typeSim]);
      worksheet.addRow(["Turma :", this.actualData.class.name]);
      worksheet.addRow(["Nome da prova :", this.actualData.nameTest.name]);
      worksheet.addRow(["tipo de relatorio :", this.actualData.TypeRelat]);
      worksheet.addRow([" "]);

      let header = ["Nome Aluno", "id Turma", "id Aluno", "Id Simulado", "Data da prova", "Acertos", "Erros", "Tempo restante", "Total exercicios", "Nota", "Total Feito"]
      let headerRow = worksheet.addRow(header);

      for (let x1 of this.reportPerfrespNote) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
      }

      let fname = "Performance Nota"

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
    }
  }
  /* async generatePDFs() {



    let reportPerfres = [];
    for (let index = 0; index < 100; index++) {

      reportPerfres.push(
        {
          studentId: index,
          idsimulado: 9,
          timeLeft: "04:59:57",
          studentName: "Joao Alberto Cunha da costa " + index,
          totalexercicies: "Joao Alberto Cunha da costa oao Alberto Cunha da costa  ",
          correct: 8,
          correcst: "Joao Alberto Cunha da costa oao Alberto Cunha da costa  ",
          correcsts: "Joao Alberto Cunha da costa oao Alberto Cunha da costa  ",
          errors: 70,
          porcent: 5,
        });

    }
    const headerInfos = [

      { name : "Tipo de Avaliação :", value: "Prova"},			
      { name : "Escola :", value: "COC Lages"},	
      { name : "Unidade :", value: "Pré-vestibular"},	
      { name : "Turma :", value: "3º Ano 2021"},	
      { name : "Plano :", value: "3º Ano 2021 "},	
      { name : "Nome da prova :", value: "Simulado AV 1º Bimestre"},			
      { name : "Tipo de relatório :", value: "por Disciplinas"},	
      { name : "Data da prova :", value: "3/26/2021	"} 
    ];
    const config = {
      name: "Performance_Disciplina",
      orientation: "p",
      unit: "mm",
      format: "a4",
      fontSize: 12,
      hasHeader: true,// TRUE insert the header in every page / False Just once
      download: false, // if true force download if False open new window
      headerInfo: true,
      headerInfoData: headerInfos
    };
    const header = [
      "Nome", "Total Questões", "Acertos",
      "Erros", "Erros novos Erros novos Erros novos Erros novos Erros novos Erros novos", "correcsts correcsts", "Porcentagem"
    ];
    const body = reportPerfres;
    const bodyKeys = [
      "studentName", "totalexercicies",
      "correct",
      "correcst",
      "correcsts",
      "errors",
      "porcent",
    ];
    const sizeOfBox = 0;
    this.pdfFactory = new PdfFactory(header, body, bodyKeys, config);
  } */
  async generatePDF() {

    if (this.typePerformance == "PG") {
      /* this.reportPerfresp.forEach(element => {
         element.school = this.actualData.school.name;
         element.typeSim = this.actualData.typeSim
         element.unit = this.actualData.unit.name;
         element.class = this.actualData.class.name;
         element.nameTest = this.actualData.nameTest.name;
         element.TypeRelat = this.actualData.TypeRelat;
       });
*/
      const headerInfos = [

        { name: "Tipo de Avaliação", value: this.actualData.typeSim },
        { name: "Escola", value: this.actualData.school.name },
        { name: "Unidade", value: this.actualData.unit.name },
        { name: "Turma", value: this.actualData.class.name },
        { name: "Nome da prova", value: this.actualData.nameTest.name },
        { name: "Tipo de relatório", value: this.actualData.TypeRelat },
      ];
      if (!this.userService.isAdmin()) {
        headerInfos[1].value = this.userService.getUserSchoolName();
      }
      if(this.userService.isTeacher()){
        this.schoolService.getUnitOne(this.userService.getUserUnit())
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.unitteacher = response.data as Unit;
          }
        })
        headerInfos[2].value = this.unitteacher.name;
      }
      const config = {
        name: "Performance_Geral",
        orientation: "p",
        unit: "mm",
        format: "a4",
        fontSize: 12,
        hasHeader: true,// TRUE insert the header in every page / False Just once
        download: true, // if true force download if False open new window
        headerInfo: true,
        headerInfoData: headerInfos
      };
      /* const headerhead = ["Nome da escola", "Tipo de avaliação", "Unidade", "Turma", "Nome da prova", "Tipo de relatório"];
      const bodyhead = this.actualData;
      const bodykeyshead = [this.actualData.school.name, this.actualData.typeSim, this.actualData.unit.name, this.actualData.class.name, */
      //this.actualData.nameTest.name, this.actualData.TypeRelat];
      const header = [
        "Nome", "Total Questões", "Acertos",
        "Erros", "Porcentagem", "Tempo Restante", "Data da prova"];
      const body = this.reportPerfresp;
      const bodyKeys = [
        "studentName", "totalexercicies",
        "correct", "errors",
        "porcent", "timeLeft", "dateTest"
      ];

      this.pdfFactory = new PdfFactory(header, body, bodyKeys, config);
    }

    if (this.typePerformance == "PD") {
      /* this.reportPerfrespdisc.forEach(element => {
         element.school = this.actualData.school.name;
         element.typeSim = this.actualData.typeSim
         element.unit = this.actualData.unit.name;
         element.class = this.actualData.class.name;
         element.nameTest = this.actualData.nameTest.name;
         element.TypeRelat = this.actualData.TypeRelat;
       }); */

      const headerInfos = [

        { name: "Tipo de Avaliação", value: this.actualData.typeSim },
        { name: "Escola", value: this.actualData.school.name },
        { name: "Unidade", value: this.actualData.unit.name },
        { name: "Turma", value: this.actualData.class.name },
        { name: "Nome da prova", value: this.actualData.nameTest.name },
        { name: "Tipo de relatório", value: this.actualData.TypeRelat },
      ];

      if (!this.userService.isAdmin()) {
        headerInfos[1].value = this.userService.getUserSchoolName();
      }

      if(this.userService.isTeacher()){
        this.schoolService.getUnitOne(this.userService.getUserUnit())
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.unitteacher = response.data as Unit;
          }
        })
        headerInfos[2].value = this.unitteacher.name;
      }

      const config = {
        name: "Performance_Disciplina",
        orientation: "p",
        unit: "mm",
        format: "a4",
        fontSize: 12,
        hasHeader: true,// TRUE insert the header in every page / False Just once
        download: true, // if true force download if False open new window
        headerInfo: true,
        headerInfoData: headerInfos
      };
      const header = [
        "Nome", "Disciplinas", "Questoes",
        "Corretas", "Incorretas", "Pontuação"
        , "Tempo Restante", "Data da prova"
      ];
      const body = this.reportPerfrespdisc;
      const bodyKeys = [
        "studentName", "disciplineName",
        "totalexercicies", "correct",
        "errors", "points", "timeLeft", "dateTest"
      ];

      this.pdfFactory = new PdfFactory(header, body, bodyKeys, config);
    }

    if (this.typePerformance == "NT") {
      /* this.reportPerfrespNote.forEach(element => {
        element.school = this.actualData.school.name;
        element.typeSim = this.actualData.typeSim
        element.unit = this.actualData.unit.name;
        element.class = this.actualData.class.name;
        element.nameTest = this.actualData.nameTest.name;
        element.TypeRelat = this.actualData.TypeRelat;
      }); */

      const headerInfos = [

        { name: "Tipo de Avaliação", value: this.actualData.typeSim },
        { name: "Escola", value: this.actualData.school.name },
        { name: "Unidade", value: this.actualData.unit.name },
        { name: "Turma", value: this.actualData.class.name },
        { name: "Nome da prova", value: this.actualData.nameTest.name },
        { name: "Tipo de relatório", value: this.actualData.TypeRelat },
      ];

      if (!this.userService.isAdmin()) {
        headerInfos[1].value = this.userService.getUserSchoolName();
      }

      if(this.userService.isTeacher()){
        this.schoolService.getUnitOne(this.userService.getUserUnit())
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.unitteacher = response.data as Unit;
          }
        })
        headerInfos[2].value = this.unitteacher.name;
      }

      const config = {
        name: "Performance_Nota",
        orientation: "p",
        unit: "mm",
        format: "a4",
        fontSize: 12,
        hasHeader: true,// TRUE insert the header in every page / False Just once
        download: true, // if true force download if False open new window
        headerInfo: true,
        headerInfoData: headerInfos
      };
      const header = [
        "Nome", "Total Respostas", "Nota"
        , "Tempo Restante", "Data da prova"
      ];
      const body = this.reportPerfrespNote;
      const bodyKeys = [
        "studentName", "totalDone",
        "note", "timeLeft", "dateTest"
      ];

      this.pdfFactory = new PdfFactory(header, body, bodyKeys, config);
    }
  }
}
