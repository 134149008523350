<div>
    <form [formGroup]="addStudentsForm">

        <!-- School -->
        <div class="row">
            <div [ngClass]="{'col-12': userService.isAdmin()}" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" (selectionChange)="getUnits($event.value)"
                        placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- Unit, Class -->
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="unit" (selectionChange)="getClasses($event.value)"
                        placeholder="Unidade">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="class" placeholder="Turma">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-2">
                <input class="width-100" (change)="handleFileInput($event.target.files);" accept=".csv" fullWidth status="primary" type="file" nbInput placeholder="Arquivo"><br>
                <label color="accent"><small>Insira até mil alunos por vez.</small></label>
            </div>
        </div>

        <!-- Test users? -->
        <div class="row">
            <div class="col-md-3">
                <mat-slide-toggle (change)="expireToggle($event)" color="primary" class="mt-3">Usuários teste
                </mat-slide-toggle>
            </div>
            <div class="col-md-3">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Dias de teste" formControlName="expireDays"
                        [textMask]="{mask: maskService.number}">
                </mat-form-field>
            </div>

        </div>
    </form>

    <nb-card size="medium" *ngIf="addLog">
        <nb-card-body>
            <nb-card-header>
                <h4 class="mt-3 mb-1">Resultado</h4>
            </nb-card-header>
            <nb-list>
                <nb-list-item *ngFor="let log of addLog">
                    <div class="row">
                        <div class="col">
                            {{log.email}}
                        </div>
                        <div class="col-2">
                            <mat-icon *ngIf="log.status" color="primary" matTooltipClass="custom-tooltip" matTooltip="Adicionado">check_circle</mat-icon>
                            <mat-icon *ngIf="!log.status" color="warn" matTooltipClass="custom-tooltip" matTooltip="Não adicionado">error</mat-icon>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>

    <div class="row mt-5 text-right">
        <button (click)="addStudents()" class="right bg-color-primary border-primary" nbButton size="small" status="primary"
        [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>