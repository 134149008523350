import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Summation } from './summation';

@Injectable({
  providedIn: 'root'
})

export class SummationService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  createSummationExercise(summation: Summation) {
    return this.http.post(this.API + '/summation/createSummationExercise', summation, { observe: 'response' });
  }

  removeSummationExercise(summationExerciseId: number) {
    return this.http.post(this.API + '/summation/removeSummationExercise', { summationExerciseId }, { observe: 'response' });
  }

  saveSummationExerciseChanges(summation: Summation) {
    return this.http.post(this.API + '/summation/saveSummationExerciseChanges', summation, { observe: 'response' });
  }

  getSummationExercisesByDiscipline(disciplineId: number) {
    return this.http.post(this.API + '/summation/getSummationExercisesByDiscipline', { disciplineId }, { observe: 'response' });
  }

  getSummationExerciseDisciplines(summationExerciseId: number) {
    return this.http.post(this.API + '/summation/getSummationExerciseDisciplines', { summationExerciseId }, { observe: 'response' });
  }
}
