import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NbButtonModule, NbCardModule, NbIconModule, NbListModule, NbSpinnerModule } from '@nebular/theme';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ForumModule } from '../forum/forum.module';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SlideFileModule } from '../slide-file/slide-file.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReportErrorComponent } from './report-error.component';

@NgModule({
  declarations: [ReportErrorComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NbListModule,
    MatButtonModule,
    NbIconModule,
    NbCardModule,
    NbButtonModule,
    MatTooltipModule,
    RouterModule,
    NbSpinnerModule,
    ForumModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    SlideFileModule,
    MatChipsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NbSpinnerModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatToolbarModule ,
    MatMenuModule
  ]
})
export class ReportErrorModule { }
