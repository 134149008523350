<div class="row ml-5 mt-3">
    <div class="ml-5 col-md-0.9 pt-4">
        <mat-icon class="corpadraoicon text-color-accent">local_library</mat-icon>
    </div>
    <div class="col-md-10">
        <h3>Biblioteca</h3>
        <p><label class="fontsub">Edite e gerencie sua biblioteca de exercícios!</label></p>
    </div>
</div>

<div>
    <nb-card>
        <nb-card-body>
            <div class="row" [ngClass]="{'ml-5': !deviceService.isMobile() , 'ml-1' : deviceService.isMobile()}">
                <mat-tab-group dynamicHeight class="col-md-12">
                    <mat-tab class="col-md-3 ">
                        <ng-template mat-tab-label>
                            <span (click)="getMocks(0,20)">Avaliações</span>
                        </ng-template>
                        <div class="row">
                            <div class="col-md-7 mt-3">
                                <button (click)="createMock()" nbButton size="medium" status="primary " class="corpadraoBtn mt-3 bg-color-accent-200 border-accent" [ngClass]="{'ml-2': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Criar Avaliação</button>
                            </div>
                            <div class="col-md-4" [ngClass]="{'ml-3': !deviceService.isMobile() , 'ml-2' : deviceService.isMobile()}">
                                <mat-form-field appearance="outline" class="width-90 mt-3" ngDefaultControl>
                                    <input matInput placeholder="Buscar" [(ngModel)]="filterMock">
                                    <mat-icon matSuffix class="corpadraolup text-color-accent" (click)="getMocksByName(filterMock , 0 , 20)">search</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <nb-list *ngIf="isMocks && mocks" class="width-90 mt-1">
                            <nb-list-item class="corpadrao list-header bg-color-accent" style="color: white ">
                                <div class="row">
                                    <div class="col" [ngClass]="{'col-md-4 ': deviceService.isMobile() , 'col-md-4' : !deviceService.isMobile()}">Nome</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile()">Plano</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile()">Questões</div>
                                    <div class="col-md-3" *ngIf="!deviceService.isMobile()">Data</div>
                                    <div class="col" [ngClass]="{'col-md-3 ml-auto float-right': deviceService.isMobile(), 'col-md-1' : !deviceService.isMobile()}">Ações</div>
                                </div>
                            </nb-list-item>

                            <nb-list-item *ngFor="let mock of mocks">
                                <div class="row">
                                    <div class="col-md-4 fontstext">
                                        {{mock.name}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile()" class="col-md-2 fontstext">
                                        {{mock.nomePlano}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile()" class="col-md-2 fontstext">
                                        {{mock.qtdQuestoes}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile()" class="col-md-2 fontstext">
                                        {{mock.dataCriacao}}
                                    </div>
                                    <div class="col-md-2 text-right">
                                        <button class="hvr-grow edit-button" (click)="updateMock(mock)" nbButton size="tiny" outline status="primary">Editar</button>
                                        <!-- <a (click)="updateMock(mock)" class="editicon" nbButton size="tiny" outline status="primary"><img src="assets/icons/editBtn.png" ></a> -->
                                        <!--  <a size="tiny" (click)="Deletelib(0 , mock.id)" status="danger" nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png">
                                        </a> -->
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <mat-paginator [pageSize]="20" [length]="totalPageMock" [pageIndex]="pageNoMock" (page)="pageEventsMock($event)">

                        </mat-paginator>
                        <div *ngIf="!isMocks" class="divnoitens">
                            <span class="txtnoitens">Não há avalições cadastradas</span>
                        </div>
                    </mat-tab>
                    <mat-tab class="col-md-3 ">
                        <ng-template mat-tab-label>
                            <span (click)="getQuestions(0,20)">Questoes</span>
                        </ng-template>
                        <nb-card>
                            <nb-card-body>
                                <div class="row">
                                    <div class="col-md-7 mt-3">
                                        <button (click)="createQuestion(false)" nbButton size="medium" status="primary " class="corpadraoBtn mt-3 bg-color-accent-200 border-accent" [ngClass]="{'ml-2': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Criar Questão</button>
                                    </div>
                                    <div class="col-md-4" [ngClass]="{'ml-3': !deviceService.isMobile() , 'ml-2' : deviceService.isMobile()}">
                                        <mat-form-field appearance="outline" class="width-90 mt-3" ngDefaultControl>
                                            <input matInput placeholder="Buscar" class="aling-right" [(ngModel)]="filterQuestion">
                                            <mat-icon matSuffix class="corpadraolup text-color-accent" (click)="getQuestionsByName(filterQuestion , 0 ,20)">search</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <mat-list *ngIf="isQuestions && questions" class="width-90 mt-1">
                                    <nb-list-item class="corpadrao bg-color-accent" style="color: white ">
                                        <div class="row ">
                                            <div class="col" [ngClass]="{'col-md-10': deviceService.isMobile() , 'col-md-3' : !deviceService.isMobile()}">Código</div>
                                            <div class="col-md-2" *ngIf="!deviceService.isMobile()">Ano</div>
                                            <div class="col-md-1" *ngIf="!deviceService.isMobile()">Instituição</div>
                                            <div class="col-md-2" *ngIf="!deviceService.isMobile()">Disciplina</div>
                                            <div class="col-md-3" *ngIf="!deviceService.isMobile()">Tema</div>
                                            <div class="col" [ngClass]="{'col-md-2 ml-auto float-right': deviceService.isMobile() , 'col-md-1' : !deviceService.isMobile()}">Ações</div>
                                        </div>
                                    </nb-list-item>

                                    <nb-list-item *ngFor=" let question of questions ">
                                        <ng-container>
                                            <div mat-list-item class="row mt-3 ">
                                                <div class="col-md-3 fontstext ">
                                                    {{question.code}}
                                                </div>
                                                <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                                    {{question.year}}
                                                </div>
                                                <div *ngIf="!deviceService.isMobile() " class="col-md-1 fontstext ">
                                                    {{question.institutionName}}
                                                </div>
                                                <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                                    {{question.disciplineName}}
                                                </div>
                                                <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                                    {{question.themeName}}
                                                </div>
                                                <div class="col-md-2 text-right ">
                                                    <button class="hvr-grow edit-button" (click)="updateQuestion(true , question)" nbButton size="tiny" outline status="primary">Editar</button>
                                                    <a size="tiny " status="danger " class="ml-1" (click)="Deletelib(1 , question.id) " nbButton ghost>
                                                        <img src="assets/icons/deleteBtn.png ">
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </nb-list-item>
                                </mat-list>
                                <mat-paginator [pageSize]="20" [length]="totalPageQuestions" [pageIndex]="pageNoQuestions" (page)="pageEventsQuestions($event)">

                                </mat-paginator>
                                <div *ngIf="!isQuestions" class="divnoitens">
                                    <span class="txtnoitens">Não há Questões cadastradas</span>
                                </div>
                            </nb-card-body>
                        </nb-card>
                    </mat-tab>
                    <mat-tab class="col-md-9 ">
                        <ng-template mat-tab-label>
                            <span (click)="getDisciplines(0,20) ">Disciplinas</span>
                        </ng-template>
                        <div class="row ">
                            <div class="col-md-7 mt-3 ">
                                <button (click)="createDiscipline()" nbButton size="medium" status="primary " class="corpadraoBtn mt-3 bg-color-accent-200 border-accent" [ngClass]="{'ml-2': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Criar Disciplina</button>
                            </div>
                            <div class="col-md-4" [ngClass]="{'ml-3': !deviceService.isMobile() , 'ml-2' : deviceService.isMobile()}">
                                <mat-form-field appearance="outline" class="width-90 mt-3 " ngDefaultControl>
                                    <input matInput placeholder="Buscar " [(ngModel)]="filterDiscipline">
                                    <mat-icon matSuffix class="corpadraolup text-color-accent" (click)="getDisciplinesByName(filterDiscipline , 0 , 20)">search</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <nb-list *ngIf="isDisciplines && disciplines" class="width-90 mt-1">
                            <nb-list-item class="corpadrao list-header bg-color-accent" style="color: white ">
                                <div class="row ">
                                    <div class="col" [ngClass]="{'col-md-10': deviceService.isMobile() , 'col-md-5' : !deviceService.isMobile()}">Nome Disciplina</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile()">Código</div>
                                    <div class="col-md-4" *ngIf="!deviceService.isMobile()">Data</div>
                                    <div class="col" [ngClass]="{'col-md-10': deviceService.isMobile() , 'col-md-1' : !deviceService.isMobile()}">Ações</div>
                                </div>
                            </nb-list-item>
                            <nb-list-item *ngFor="let discipline of disciplines ">
                                <div class="row ">
                                    <div class="col-md-5 fontstext ">
                                        {{discipline.name}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                        {{discipline.code}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-3 fontstext ">
                                        criado em {{discipline.created_at}}
                                    </div>
                                    <div class="col-md-2 text-right ">
                                        <button class="hvr-grow edit-button" (click)="updateDiscipline(discipline)" nbButton size="tiny" outline status="primary">Editar</button>
                                        <a class="ml-1" size="tiny " (click)="Deletelib(2 , discipline.id) " status="danger " nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png ">
                                        </a>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <mat-paginator [pageSize]="20" [length]="totalPageDisciplines" [pageIndex]="pageNoDisciplines" (page)="pageEventsDisciplines($event)">

                        </mat-paginator>
                        <div *ngIf="!isDisciplines" class="divnoitens">
                            <span class="txtnoitens">Não há disciplinas cadastradas</span>
                        </div>
                    </mat-tab>
                    <mat-tab class="col-md-9 ">
                        <ng-template mat-tab-label>
                            <span (click)="getThemes(0,20) ">Temas</span>
                        </ng-template>
                        <div class="row ">
                            <div class="col-md-7 mt-3 ">
                                <button (click)="createTheme()" nbButton size="medium" status="primary " class="corpadraoBtn mt-3 bg-color-accent-200 border-accent" [ngClass]="{'ml-2': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Criar Tema</button>
                            </div>
                            <div class="col-md-4" [ngClass]="{'ml-3': !deviceService.isMobile() , 'ml-2' : deviceService.isMobile()}">
                                <mat-form-field appearance="outline" class="width-90 mt-3 " ngDefaultControl>
                                    <input matInput placeholder="Buscar " [(ngModel)]="filterTheme">
                                    <mat-icon matSuffix class="corpadraolup text-color-accent" (click)="getThemesByName(filterTheme , 0 , 20)">search</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <nb-list *ngIf="isThemes && themes" class="width-90 mt-1">
                            <nb-list-item class="corpadrao list-header bg-color-accent" style="color: white ">
                                <div class="row ">
                                    <div class="col" [ngClass]="{'col-md-10': deviceService.isMobile() , 'col-md-5' : !deviceService.isMobile()}">Nome Tema</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile()">Disciplina</div>
                                    <div class="col-md-4" *ngIf="!deviceService.isMobile()">Cadastrado</div>
                                    <div class="col" [ngClass]="{'col-md-2': deviceService.isMobile() , 'col-md-1' : !deviceService.isMobile()}">Ações</div>
                                </div>
                            </nb-list-item>
                            <nb-list-item *ngFor="let theme of themes ">
                                <div class="row ">
                                    <div class="col-md-5 fontstext ">
                                        {{theme.name}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                        {{theme.disciplineName}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-3 fontstext ">
                                        {{theme.created_at}}
                                    </div>
                                    <div class="col-md-2 text-right ">
                                        <button class="hvr-grow edit-button" (click)="editTheme(theme)" nbButton size="tiny" outline status="primary">Editar</button>
                                        <a size="tiny " (click)="Deletelib(3 , theme.id) " class="ml-1" status="danger " nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png ">
                                        </a>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <mat-paginator [pageSize]="20" [length]="totalPageThemes" [pageIndex]="pageNoThemes" (page)="pageEventsThemes($event)">

                        </mat-paginator>
                        <div *ngIf="!isThemes" class="divnoitens">
                            <span class="txtnoitens">Não há temas cadastrados</span>
                        </div>
                    </mat-tab>
                    <mat-tab class="col-md-9 ">
                        <ng-template mat-tab-label>
                            <span (click)="getSubThemes(0,20) ">SubTemas</span>
                        </ng-template>
                        <div class="row ">
                            <div class="col-md-7 mt-3 ">
                                <button (click)="createTheme()" nbButton size="medium" status="primary " class="corpadraoBtn mt-3 bg-color-accent-200 border-accent" [ngClass]="{'ml-2': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Criar Subtema</button>
                            </div>
                            <div class="col-md-4" [ngClass]="{'ml-3': !deviceService.isMobile() , 'ml-2' : deviceService.isMobile()}">
                                <mat-form-field appearance="outline" class="width-90 mt-3 " ngDefaultControl>
                                    <input matInput placeholder="Buscar " [(ngModel)]="filterSubTheme">
                                    <mat-icon matSuffix class="corpadraolup text-color-accent" (click)="getSubThemesByName(filterSubTheme , 0 ,20)">search</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <nb-list *ngIf="isSubThemes && subThemes" class="width-90 mt-1">
                            <nb-list-item class="corpadrao list-header bg-color-accent" style="color: white ">
                                <div class="row ">
                                    <div class="col" [ngClass]="{'col-md-10': deviceService.isMobile() , 'col-md-4' : !deviceService.isMobile()}">Nome subTema</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile()">Disciplina</div>
                                    <div class="col-md-2" *ngIf="!deviceService.isMobile()">Tema</div>
                                    <div class="col-md-3" *ngIf="!deviceService.isMobile()">Cadastrado</div>
                                    <div class="col" [ngClass]="{'col-md-2': deviceService.isMobile() , 'col-md-1' : !deviceService.isMobile()}">Ações</div>
                                </div>
                            </nb-list-item>
                            <nb-list-item *ngFor="let subTheme of subThemes ">
                                <div class="row ">
                                    <div class="col-md-4 fontstext ">
                                        {{subTheme.name}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                        {{subTheme.disciplineName}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                        {{subTheme.themeName}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-2 fontstext ">
                                        {{subTheme.created_at}}
                                    </div>
                                    <div class="col-md-2 text-right ">
                                        <button class="hvr-grow edit-button" (click)="editSubTheme(subTheme)" nbButton size="tiny" outline status="primary">Editar</button>
                                        <a size="tiny " (click)="Deletelib(4 , subTheme.id) " class="ml-1" status="danger " nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png ">
                                        </a>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <mat-paginator [pageSize]="20" [length]="totalPageSubThemes" [pageIndex]="pageNoSubThemes" (page)="pageEventsSubThemes($event)">

                        </mat-paginator>
                        <div *ngIf="!isSubThemes" class="divnoitens">
                            <span class="txtnoitens">Não há sub-temas cadastrados</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span (click)="getInstitutions(0,20) ">Instituições</span>
                        </ng-template>
                        <div class="row ">
                            <div class="col-md-7 mt-3 ">
                                <button (click)="createInstitution()" nbButton size="medium" status="primary " class="corpadraoBtn mt-3 bg-color-accent-200 border-accent" [ngClass]="{'ml-2': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Criar Instituição</button>
                            </div>
                            <div class="col-md-4" [ngClass]="{'ml-3': !deviceService.isMobile() , 'ml-2' : deviceService.isMobile()}">
                                <mat-form-field appearance="outline" class="width-90 mt-3 " ngDefaultControl>
                                    <input matInput placeholder="Buscar " [(ngModel)]="filterInstitution">
                                    <mat-icon matSuffix class="corpadraolup text-color-accent" (click)="getInstitutionsByName(filterInstitution , 0 ,20)">search</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <nb-list *ngIf="isInstitution && institutions" class="width-90 mt-1">
                            <nb-list-item class="corpadrao list-header bg-color-accent" style="color: white ">
                                <div class="row ">
                                    <div class="col" [ngClass]="{'col-md-10': deviceService.isMobile() , 'col-md-7' : !deviceService.isMobile()}">Nome</div>
                                    <div class="col-md-4" *ngIf="!deviceService.isMobile()">Criado em:</div>
                                    <div class="col" [ngClass]="{'col-md-2': deviceService.isMobile() , 'col-md-1' : !deviceService.isMobile()}">Ações</div>
                                </div>
                            </nb-list-item>
                            <nb-list-item *ngFor="let institution of institutions ">
                                <div class="row ">
                                    <div class="col-md-7 fontstext ">
                                        {{institution.name}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-3 fontstext ">
                                        {{institution.created_at}}
                                    </div>
                                    <div class="col-md-2 text-right ">
                                        <button class="hvr-grow edit-button" (click)="editInstitution(institution)" nbButton size="tiny" outline status="primary">Editar</button>
                                        <a size="tiny " (click)="Deletelib(5 , institution.id) " class="ml-1" status="danger " nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png ">
                                        </a>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <mat-paginator [pageSize]="20" [length]="totalPageInstitutions" [pageIndex]="pageNoInstitutions" (page)="pageEventsInstitutions($event)">

                        </mat-paginator>
                        <div *ngIf="!isInstitution" class="divnoitens">
                            <span class="txtnoitens">Não há instituições cadastradas</span>
                        </div>
                    </mat-tab>
                    <!--   <mat-tab>
                        <ng-template mat-tab-label>
                            <span (click)="getQuestionTypes() ">Tipos de Questões</span>
                        </ng-template>
                        <div class="row ">
                            <div class="col-md-7 mt-3 ">
                                <button (click)="createQuestionType()" nbButton size="medium" status="primary " class="corpadraoBtn mt-3" [ngClass]="{'ml-2': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Criar Tipos de questões</button>
                            </div>
                            <div class="col-md-4" [ngClass]="{'ml-3': !deviceService.isMobile() , 'ml-2' : deviceService.isMobile()}">
                                <mat-form-field appearance="outline" class="width-90 mt-3 " ngDefaultControl>
                                    <input matInput placeholder="Buscar " [(ngModel)]="filterQuestionType">
                                    <mat-icon matSuffix class="corpadraolup">search</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <nb-list *ngIf="isQuestionTypes && questionTypes" class="width-90 mt-1">
                            <nb-list-item class="corpadrao list-header " style="color: white ">
                                <div class="row ">
                                    <div class="col" [ngClass]="{'col-md-10': deviceService.isMobile() , 'col-md-7' : !deviceService.isMobile()}">Nome</div>
                                    <div class="col-md-4" *ngIf="!deviceService.isMobile() ">Criado em:</div>
                                    <div class="col" [ngClass]="{'col-md-2': deviceService.isMobile() , 'col-md-1' : !deviceService.isMobile()}">Ações</div>
                                </div>
                            </nb-list-item>
                            <nb-list-item *ngFor="let questionType of questionTypes ">
                                <div class="row ">
                                    <div class="col-md-7 fontstext ">
                                        {{questionType.name}}
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() " class="col-md-3 fontstext ">
                                        {{questionType.created_at}}
                                    </div>
                                    <div class="col-md-2 text-right ">
                                        <a (click)="editQuestionType(questionType) " nbButton size="tiny " class="editicon" outline status="primary "><img src="assets/icons/editBtn.png "></a>
                                        <a size="tiny " (click)="Deletelib(6 , questionType.id) " class="ml-1" status="danger " nbButton ghost>
                                            <img src="assets/icons/deleteBtn.png ">
                                        </a>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                        <div *ngIf="!isQuestionTypes" class="divnoitens">
                            <span class="txtnoitens">Não há tipos de questões cadastrados</span>
                        </div>
                    </mat-tab> -->
                </mat-tab-group>
            </div>
        </nb-card-body>
    </nb-card>
</div>