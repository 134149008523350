<div class="page-container p-pg page-container-mobile">
    <div class="row m-0">
        <div class="mt-2" [ngClass]="{'col-12 text-center': layoutService.isMobile, 'col-md-6': !layoutService.isMobile}">
            <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                <span class="page-title">Redações</span>
                <div class="mt-3 description-title">
                    <h4 *ngIf="!layoutService.isMobile">Minhas redações</h4>
                    <h6 class="mb-0" *ngIf="layoutService.isMobile">Minhas redações</h6>
                    <p class="fs-13 pb-0">Envie e acompanhe as suas redações</p>
                </div>
            </div>
        </div>
        <div class="mt-2 align-self-end text-right" [ngClass]="{'col-12 text-center': layoutService.isMobile, 'col-md-6': !layoutService.isMobile}">
            <button class="button-send btn-large" disabled mat-raised-button color="accent">
                Nova redação
            </button>
        </div>
        <div class="row m-5">
            <div class="container p-5">
                <div class="row m-0">
                    <div class="col-md-12 pb-4 bt-4 text-center">
                        <mat-icon color="accent" class="material-symbols-outlined f-100">description</mat-icon>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-md-12 text-center">
                        <h4>Ops! Você não possui disponibilidade para enviar a sua redação para correção.</h4>
                        <p class="mt-3"  *ngIf="link"> Caso queira adquirir créditos para enviar novas produções textuais, clique no botão abaixo.</p>
                    </div>
                </div>
                <div class="row m-0" *ngIf="link">
                    <div class="col-md-12 mt-3 text-center">
                        <a href="{{link}}" target="_blank" class="text-white">
                            <button class="button-send btn-large pr-5 pl-5" mat-raised-button color="accent">
                                Adquirir mais créditos
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>