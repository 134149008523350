import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { environment } from 'src/environments/environment';
import { SlideFile } from '../slide-file';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-slide-file-player',
  templateUrl: './slide-file-player.component.html',
  styleUrls: ['./slide-file-player.component.scss']
})
export class SlideFilePlayerComponent implements OnInit {

  public linkfilePdf: any;
  private API = environment.ApiUrl;

  @Input() slideFile: SlideFile;

  constructor(
    private sanitizer: DomSanitizer,
    public deviceService: DeviceDetectorService,
    public layoutService: LayoutService
  ) { }

  ngOnInit() {
    //console.log('slide-file-player work!')

   /*  if(this.slideFile.file){
      this.slideFile.file.replace("aaaa" , "")
    } */

    /*console.log("device info - layoutService.isMobile - layoutService.isTablet ",
     [this.deviceService.getDeviceInfo().deviceType, this.layoutService.isMobile, this.layoutService.isTablet]); */


    this.linkfilePdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.API +"/flip-book/"+this.slideFile.file);
  }

  downloadFile() {
    window.open(this.slideFile.file, '_blank');
  }

}
