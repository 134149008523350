
 import { DisciplineModel } from "./Discipline.model";
import {VideoDisciplineModel} from "./VideoDiscipline.model";

interface ConfigDuplicate{

    discilplines:boolean,
    disciplineExercises:boolean,
    videosExercises:boolean,
    videosAnnex:boolean,
    videosIframes:boolean
}

interface DuplicateDisciplineInterface{
    id:number,
    discipline:any, 
    config?:any
}
export class DuplicateDisciplineModel { 

    private duplicateDiscipline:DuplicateDisciplineInterface;

    constructor(id:number,  discipline:any,  videos:any[]){
       
        let d  = new DisciplineModel(discipline);
        d.addVideos(videos); 
     
        this.duplicateDiscipline = {
            id:id,
            discipline:  d, 
            config: {
                exercises: false,
                videoExercises: false,
                videoIframes: false,
                videoAnnex: false,
                videos: true
            }
        }
    }

    public static newDuplicateDisciplineModel( id:number,  discipline:any,     videos:any){
        return new DuplicateDisciplineModel( id, discipline  ,   videos );
    }

    public getObj(){
        return this.duplicateDiscipline;
    }
    public getVideos(){
        return  this.duplicateDiscipline.discipline.getVideos();
    }
    public getDiscipline(){

        return this.duplicateDiscipline.discipline ;
    }
    public setDiscipline(obj:any){

        this.duplicateDiscipline.discipline = obj;
    }
    public setVideos(obj:any){
        this.duplicateDiscipline.discipline.setVideos(obj) ;
    }
    public removeVideo(id:number){
        
        this.duplicateDiscipline.discipline.removeVideo(id) ;
    }
    
    public setVideoName(id:number, name:string){
        this.duplicateDiscipline.discipline.setVideos(
            this.duplicateDiscipline.discipline.getVideos().map((v)=>{
                if(v.getId() == id){
                    v.setName(name);
                }
                return v;
            })
        );
    } 

    public setVideoExercises(obj:any){
        this.duplicateDiscipline.discipline.addVideosExercises(obj) ;
    }
    public setVideoIframes(obj:any){
        this.duplicateDiscipline.discipline.addVideosIframes(obj) ;
    }
    public setVideoAnnex(obj:any){
        this.duplicateDiscipline.discipline.addVideosAnnex(obj) ;
    }
    public getId(){
        return this.duplicateDiscipline.id;
    }
    public getDisciplineId(){
        return this.duplicateDiscipline.discipline.getId();
    }
    public getDisciplineName(){
        return this.duplicateDiscipline.discipline.getName();
    }
    public setDisciplineName(value:string){
        return this.duplicateDiscipline.discipline.setName(value);
    }
   
    public async  getConfig(){
        return this.duplicateDiscipline.config ;
    }
    public setConfigExercises(condition:boolean){
        this.duplicateDiscipline.config.exercises = condition;
    }
    public setConfigVideoExercises(condition:boolean){
        this.duplicateDiscipline.config.videoExercises = condition;
    }
    public setConfigVideoIframes(condition:boolean){
        this.duplicateDiscipline.config.videoIframes = condition;
    }
    public setConfigVideoAnnex(condition:boolean){
        this.duplicateDiscipline.config.videoAnnex = condition;
    }
    public setConfigVideos(condition:boolean){
        this.duplicateDiscipline.config.videos = condition;
    }

} 
