import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { resolveTxt } from 'dns';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @HostBinding('class.fileover') fileover: boolean;
  files: any[];
  @Output() fileDropped = new EventEmitter<any>();
  constructor() { }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    //console.log('drag over');
  }

  OnfileDropped($event) {
    for (const item of $event) {
      this.files.push(item);
    }
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;

    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
