import { ReportTastingComponent } from './performance-report/report-tasting/report-tasting.component';
import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

import { ManageDisciplineComponent } from './discipline/manage/manage-discipline.component';
import { ExperimentManageComponent } from './experiment/manage/experiment-manage.component';
import { MiscellaneousComponent } from './miscellaneous/miscellaneous.component';
import { MockReportComponent } from './mock/report/mock-report.component';
import { PaymentManageComponent } from './payment/manage/payment-manage.component';
import { CreatePodcastComponent } from './podcast/create-podcast.component';
import { ManageRepositoryComponent } from './repository/manage/manage-repository.component';
import { ManageSchoolComponent } from './school/manage/manage-school.component';
import { ManageStudentComponent } from './student/manage/manage-student.component';
import { CreateStudyPlanComponent } from './study-plan/create/create-study-plan.component';
import { PayingListComponent } from './payment/paying/list/paying-list.component';
import { RdoManagerComponent } from './rdo-manager/rdo-manager.component';
import { TaskManagerComponent } from './task-manager/task-manager.component';
import { PerformanceReportComponent } from './performance-report/performance-report.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ReportsComponent } from './reports/reports.component';
import { SummationReportComponent } from './summation-report/summation-report.component';
import { SearchComponent } from './summation-report/search/search.component';
import { ClassroomGeneralListComponent } from '../classroom/classroom-general-list/classroom-general-list.component';
import { TutorMundiAnswerComponent } from '../tutor-mundi/tutor-mundi-answer/tutor-mundi-answer.component';
import { TeacherViewPlansComponent } from '../teacher-view-plans/teacher-view-plans/teacher-view-plans.component';
import { MyExerciseLibraryComponent } from './my-exercise-library/my-exercise-library.component';
import { CreateQuestionComponent } from './my-exercise-library/create-question/create-question.component';
import { patch } from '@nebular/theme';
import { Component } from '@angular/core';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { SuportComponent } from './suport/suport.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CertificateComponent } from './certificate/certificate.component';
import { CreateComponent } from './certificate/create/create.component';
import { EditComponent } from './certificate/edit/edit.component';
import { ChannelComponent } from './channel/channel.component';
import { EditChannelComponent } from './channel/edit-channel/edit-channel.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
    {
        path: 'study-plan/create',
        component: CreateStudyPlanComponent,
        canActivate: [AuthGuard]
    },
    {
    path: 'star/rating',
    component: StarRatingComponent,
    canActivate: [AuthGuard]
    },
    {
        path: 'teacher/teacher_view',
        component: TeacherViewPlansComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'teacher/teacher_view/:planId/:disciplineId',
        component: TeacherViewPlansComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'create-question',
        component: CreateQuestionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'suport',
        component: SuportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'report/tasting',
        component : ReportTastingComponent,
        canActivate : [AuthGuard]
    },
    /* {
        path: 'report/testetutor',
        component : TutorMundiAnswerComponent,
        canActivate : [AuthGuard]
    }, */
    {
        path: 'rdo-manager/sync',
        component: RdoManagerComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'exercise-library',
        component: MyExerciseLibraryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'discipline/manage',
        component: ManageDisciplineComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'miscellaneous',
        component: MiscellaneousComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'school/manage',
        component: ManageSchoolComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'student/manage',
        component: ManageStudentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payment/manage',
        component: PaymentManageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'addpod',
        component: CreatePodcastComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'repository/manage',
        component: ManageRepositoryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'mock/report',
        component: MockReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'performance/report',
        component: PerformanceReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'experiment',
        component: ExperimentManageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payment/paying/list',
        component: PayingListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'task-manager',
        component: TaskManagerComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'integrations',
        component: IntegrationsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'general-reports',
        component: ReportsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'summation/report',
        component: SummationReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/certificates',
        component: CertificateComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/certificates/create',
        component: CreateComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/certificates/edit',
        component: EditComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/channel',
        component: ChannelComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/channel/edit/:channelId',
        component: EditChannelComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/channel/list',
        component: ChannelComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
];

