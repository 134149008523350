<ng-container *ngIf="!loadingInit">
    <div *ngIf="!showExercisesList" mat-dialog-content>
        <form [formGroup]="createExerciseForm">

            <h4 *ngIf="editMode" mat-dialog-title class="mt-3 mb-3">Editar Exercício</h4>

            <!-- Name, TAG -->
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Nome" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Tag" formControlName="tag">
                    </mat-form-field>
                </div>
            </div>

            <!-- Videos -->
            <div class="row">
                <div class="col-md">
                    <mat-form-field class="width-100">
                        <mat-label>Filtrar disciplinas de vídeos</mat-label>
                        <input matInput (keyup)="filterDisciplinesRegister($event.target.value)">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <mat-select placeholder="Disciplinas apenas de vídeos" (selectionChange)="getDisciplineVideos($event.value)">
                            <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                {{discipline.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <mat-select formControlName="videos" placeholder="Vídeos">
                            <mat-option (click)="addVideoChip(video)" *ngFor="let video of videos" [value]="video.id">
                                {{video.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-chip-list *ngIf="selectedVideos">
                        <mat-chip *ngFor="let videoChip of selectedVideos" selectable="true" removable="true" (removed)="removeVideoChip(videoChip)">
                            {{videoChip.title}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <mat-select formControlName="disciplineId" placeholder="Disciplinas apenas de exercícios">
                            <mat-option (click)="addDisciplineChip(discipline)" *ngFor="let discipline of exerciseDisciplines" [value]="discipline.id">
                                {{discipline.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-chip-list *ngIf="selectedDisciplines">
                        <mat-chip *ngFor="let disciplineChip of selectedDisciplines" selectable="true" removable="true" (removed)="removeDisciplineChip(disciplineChip)">
                            {{disciplineChip.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>


            <!-- video do enunciado -->
            <label class="mt-4">Vídeo do enunciado (opcional)</label>
            <div class="row">
                <div class="col-md-4 radio-group-container">
                    <mat-radio-group aria-label="Selecione o servidor do video" formControlName="embedType">
                        <mat-radio-button class="radio-item button-vimeo" value="Vimeo" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="Vimeo">
                            <mat-icon svgIcon="vimeo"></mat-icon>
                        </mat-radio-button>
                        <mat-radio-button class="radio-item" value="youtube" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="YouTube">
                            <mat-icon svgIcon="youtube"></mat-icon>
                        </mat-radio-button>
                        <mat-radio-button class="radio-item" value="0" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="Nenhum vídeo">
                            <mat-icon svgIcon="novideo"></mat-icon>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Código do vídeo" formControlName="embedCode">
                    </mat-form-field>
                </div>
            </div>

            <!-- resolução video -->
            <label class="mt-4">Vídeo da resolução (opcional)</label>
            <div class="row">
                <div class="col-md-4 radio-group-container">
                    <mat-radio-group aria-label="Selecione o servidor do video" formControlName="commentEmbedType">
                        <mat-radio-button class="radio-item" value="Vimeo" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="Vimeo">
                            <mat-icon svgIcon="vimeo"></mat-icon>
                        </mat-radio-button>
                        <mat-radio-button class="radio-item" value="youtube" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="Youtube">
                            <mat-icon svgIcon="youtube"></mat-icon>
                        </mat-radio-button>
                        <mat-radio-button class="radio-item" value="0" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="Nenhum vídeo">
                            <mat-icon svgIcon="novideo"></mat-icon>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Código do vídeo" formControlName="commentEmbedCode">
                    </mat-form-field>
                </div>
            </div>

        </form>

        <!-- Enunciado e alternativas -->
        <div class="row mt-5">
            <mat-tab-group class="width-90 center" backgroundColor="accent">
                <mat-tab label="Enunciado">
                    <ckeditor [editor]="questionEditor" [config]="editorConfig" [(ngModel)]="question" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="A">
                    <ckeditor [editor]="aEditor" [config]="editorConfig" [(ngModel)]="A.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="B">
                    <ckeditor [editor]="bEditor" [config]="editorConfig" [(ngModel)]="B.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="C">
                    <ckeditor [editor]="cEditor" [config]="editorConfig" [(ngModel)]="C.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="D">
                    <ckeditor [editor]="dEditor" [config]="editorConfig" [(ngModel)]="D.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="E">
                    <ckeditor [editor]="eEditor" [config]="editorConfig" [(ngModel)]="E.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="Resolução">
                    <ckeditor [editor]="commentEditor" [config]="editorConfig" [(ngModel)]="comment" class="width-100">
                    </ckeditor>
                </mat-tab>
            </mat-tab-group>
        </div>

        <!-- Selecionar gabarito -->
        <div class="row mt-3">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select [(ngModel)]="correctAlternative" placeholder="Gabarito">
                        <mat-option value="a">A</mat-option>
                        <mat-option value="b">B</mat-option>
                        <mat-option value="c">C</mat-option>
                        <mat-option value="d">D</mat-option>
                        <mat-option value="e">E</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>



        <div *ngIf="!editMode" class="row mt-5">
            <div class=" col-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver exercícios
                    cadastrados</button>
            </div>
            <div class=" col-6 text-right width-100">
                <button (click)="createExercise()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
            </div>
        </div>

        <div *ngIf="editMode" class="row mt-5 text-right">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </div>

    <div *ngIf="showExercisesList" mat-dialog-content class="">

        <mat-radio-group [(ngModel)]="filterDisciplineType" (change)="getDisciplines($event.value)">
            <div class="row">
                <div class="col-md">
                    <mat-radio-button [value]="0">Disciplinas de vídeos</mat-radio-button>
                </div>
                <div class="col-md">
                    <mat-radio-button [value]="1">Disciplinas de Execício</mat-radio-button>
                </div>
            </div>
            <div class="row">
                <div *ngIf="!userService.isTeacher()" class="col-md">
                    <mat-radio-button [value]="2">Buscar exercício por nome</mat-radio-button>
                </div>
                <div *ngIf="!userService.isTeacher()" class="col-md">
                    <mat-radio-button [value]="3">Buscar exercício por TAG</mat-radio-button>
                </div>
            </div>
        </mat-radio-group>

        <div class="row">
            <div class="col-md" *ngIf="filterDisciplineType != 2">
                <!-- disciplines vídeos -->
                <mat-form-field class="width-100" *ngIf="filterDisciplineType == 0">
                    <mat-select placeholder="Disciplina de vídeo" [(ngModel)]="filterDisciplineId" (selectionChange)="getVideos($event.value)">
                        <mat-option *ngFor="let discipline of filterDisciplines" [value]="discipline.id">
                            {{discipline.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- disciplines exercises only -->
                <mat-form-field class="width-100" *ngIf="filterDisciplineType == 1">
                    <mat-select placeholder="Disciplina de exercício" [(ngModel)]="filterDisciplineId" (selectionChange)="getDisciplineExercises($event.value)">
                        <mat-option *ngFor="let discipline of filterDisciplines" [value]="discipline.id">
                            {{discipline.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md" *ngIf="filterDisciplineType == 0">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Vídeos" [(ngModel)]="filterVideoId" (selectionChange)="getVideoExercises($event.value)">
                        <mat-option *ngFor="let video of filterVideos" [value]="video.id">
                            {{video.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md" *ngIf="filterDisciplineType == 2">
                <div class="col">
                    <mat-form-field class="width-100">
                        <input [(ngModel)]="filterName" matInput placeholder="Buscar exercício">
                        <button (click)="getExercisesByName()" class="ml-2" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md" *ngIf="filterDisciplineType == 3">
                <div class="col">
                    <mat-form-field class="width-100">
                        <input [(ngModel)]="filterTag" matInput placeholder="Buscar exercício por TAG">
                        <button (click)="getExercisesByTag()" class="ml-2" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <nb-card>
            <nb-card-header *ngIf="filterDisciplineId != 0">
                <div class="row" *ngIf="exercises.length">
                    <div class="col-md-6">
                        <mat-form-field class="width-100">
                            <input matInput placeholder="Buscar" [(ngModel)]="filter">
                        </mat-form-field>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <mat-list *ngIf="!loading" class="width-90 mt-3 center">
                    <ng-container *ngFor="let exercise of exercises">
                        <div mat-list-item class="row" style="min-height: 100px" *ngIf="verifyFilter(exercise.name)">
                            <div class="col-4">
                                {{exercise.name}}
                            </div>
                            <div class="col-4">
                                criado em {{exercise.createDate | date:'dd/MM/yyyy'}}
                            </div>
                            <div class="col-2 text-right">
                                <button (click)="editExercise(exercise)" nbButton size="tiny" outline status="primary">Editar</button>
                            </div>
                            <div class="col-2 text-right">
                                <button size="tiny" (click)="removeExercise(exercise.id)" status="danger" nbButton ghost>
                                    <nb-icon icon="trash"></nb-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </mat-list>
            </nb-card-body>
        </nb-card>
        <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
        <div class="text-right width-100">
            <button (click)="showExercisesList = false;" class="right" nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
</ng-container>
<mat-spinner *ngIf="loadingInit" class="center mt-5 mb-3"></mat-spinner>