import { CommonModule , CurrencyPipe} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NbListModule } from '@nebular/theme';
import { LottieModule } from 'ngx-lottie';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';


import { ClassroomGeneralListComponent } from './classroom-general-list/classroom-general-list.component';
import { ClassroomListComponent } from './list/classroom-list.component';
import { RecordedClassroomDialogComponent } from './recorded-classroom-dialog/recorded-classroom-dialog.component';
export function playerFactory() {
    return import('lottie-web');
}
@NgModule({
    declarations: [
        ClassroomListComponent,
        RecordedClassroomDialogComponent,
        ClassroomGeneralListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatCardModule,
        NbListModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSelectModule,
        MatFormFieldModule,
        OrderModule,
        NgxPaginationModule,
        MatInputModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
    exports: [
        ClassroomListComponent
    ],
    providers: [CurrencyPipe],
})
export class ClassroomModule { }
