<div class="container">
    <form *ngIf="theme" [formGroup]="signupForm" (submit)="signup()" class="form mt-4 text-center">

        <div class="row text-center pt-3">
            <div class="col-md-5 center">
                <mat-card class="no-padding">
                    <mat-card-header class="bg-color-primary pt-3 text-center">
                        <mat-card-title class="text-big text-center"><img class="mt-4 mb-3 center" [src]="theme?.logo">
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="pt-3">

                        <div class="form-group" *ngIf="experiment?.id">
                            <nb-alert class="mr-4 ml-4" accent="warning">{{experiment.name}}</nb-alert>
                        </div>

                        <div class="form-group">
                            <i class="fa fa-user-circle fa-lg mr-2"></i>
                            <mat-form-field class="width-90">
                                <input formControlName="name" matInput placeholder="Nome">
                                <mat-error *ngIf="signupForm?.get('name').errors">
                                    Insira o seu nome!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <i class="fa fa-user-circle fa-lg mr-2"></i>
                            <mat-form-field class="width-90">
                                <input formControlName="email" matInput placeholder="E-mail">
                                <mat-error *ngIf="signupForm?.get('email').errors?.email">
                                    Email inválido!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <i class="fa fa-unlock-alt fa-lg mr-2"></i>
                            <mat-form-field class="width-90">
                                <input type="password" formControlName="password" matInput placeholder="Senha">
                            </mat-form-field>
                        </div>
                    </mat-card-content>

                    <mat-card-actions class="pb-3">
                        <div class="form-group" *ngIf="!loading">
                            <button mat-raised-button mat-button color="accent" class="width-90"
                                [disabled]="signupForm.invalid || block" type="submit">
                                Cadastrar
                            </button>
                        </div>
                        <mat-spinner *ngIf="loading" class="center" diameter="20"></mat-spinner>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>


    </form>
    <mat-spinner *ngIf="!theme" class="center mt-5"></mat-spinner>
</div>