import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';

import { AdminService } from '../../admin.service';
import { Coupon } from './coupon';

@Component({
    selector: 'app-payment-coupon',
    templateUrl: './payment-coupon.component.html'
})
export class PaymentCouponComponent implements OnInit {

    public couponForm: FormGroup;
    public loading: boolean;
    public coupons: Coupon[];
    public showCouponList: boolean;
    public editMode: boolean;
    public filterName: string;
    public filteredCoupons: Coupon[];

    constructor(
        private paymentService: PaymentService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        public maskService: MaskService) { }

    ngOnInit(): void {
        this.couponForm = this.formBuilder.group({
            id: [],
            cupom: ['', Validators.required],
            valor: ['', [Validators.required, Validators.maxLength(2)]]
        });

    }

    openList() {
        this.getCoupons();
        this.showCouponList = true;
    }

    getCoupons() {

        this.loading = true;
        this.paymentService.getCoupons()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.coupons = response.data as Coupon[];
                    this.filteredCoupons = response.data as Coupon[];
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar os cupons. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    createCoupon() {
        if (this.couponForm.valid) {

            this.loading = true;
            const coupon = this.couponForm.getRawValue() as Coupon;

            this.paymentService.createCoupon(coupon)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Cupom criado!');
                        this.couponForm.reset();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao criar o coupon. Verifique a conexão e tente novamente!');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha os campos corretamente!');
        }
    }

    removeCoupon(couponId: number) {
        if (confirm('Deseja realmente remover o cupom?')) {
            this.paymentService.removeCoupon(couponId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Cupom removido', 3);
                        this.getCoupons();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover o cupom. Verifique a conexão e tente novamente'));
        }
    }

    applyFilter() {

        if (this.filterName.length) {
            this.filteredCoupons = [...this.coupons].filter(c => c.cupom.toLowerCase().indexOf(this.filterName.toLowerCase()) > -1);
        } else {
            this.filteredCoupons = [...this.coupons];
        }
    }
}
