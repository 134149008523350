import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { NbAlertModule } from '@nebular/theme';

import { ExperimentSignupComponent } from './signup/experiment-signup.component';

@NgModule({
    declarations: [
        ExperimentSignupComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatCardModule,
        MatProgressSpinnerModule,
        NbAlertModule,
        RouterModule
    ],
    exports: [],
    providers: [],
})
export class ExperimentModule { }
