<div class="ml-5 mt-2">
    <div>
        <label class="labeltitle">{{planName}}</label><br>
        <label class="title-book mt-2 text-color-accent">{{ebook.name}}</label>
    </div>
    <div class="flip-container m-1">
        <ng-container>
            <div #flipBook>
                <iframe [src]="fileBookUrl" width="100%" height="100%" [ngClass]="{'dimMobile': layoutService.isMobile, 'dimTablet': layoutService.isTablet && !layoutService.isMobile, 'dimDesktop': !layoutService.isMobile && !layoutService.isTablet}" frameborder="0"></iframe>
            </div>
        </ng-container>
    </div>
    <div class="ml-2 row width-100">
        <div class="col-md-9">
            <span>Avalie essa aula</span>
            <button *ngIf="!liked" class="borderNone ml-2" (click)="likeMethod()">
                <mat-icon color="accent">thumb_up_off_alt</mat-icon>
            </button>
            <button *ngIf="liked" class="borderNone ml-2" (click)="likeMethod()">
                <mat-icon color="accent">thumb_up</mat-icon>
            </button>
            <button *ngIf="!desliked" class="borderNone ml-2" (click)="deslikeMethod()">
                <mat-icon>thumb_down_off_alt</mat-icon>
            </button>
            <button *ngIf="desliked" class="borderNone ml-2" (click)="deslikeMethod()">
                <mat-icon>thumb_down</mat-icon>
            </button>
        </div>
        <div class="col mt-2">
            <button class="btn-error borderNone">
                <span> Reporte um Problema</span><span
                    class="ml-2 margin-icons material-icons pest-control">pest_control</span>
            </button>
        </div>
    </div>

    <div class="width-100 div-comment mt-5">
        <h6 class="title-comments ">Comentários</h6>
        <textarea [(ngModel)]="comment" class="txt-area-comment" cols="30" rows="10"></textarea>
        <div class="width-100 mt-2">
            <button (click)="setEbookComment(comment)" class="butn-answers bg-color-accent">Responder</button>
        </div>
    </div>

    <div style="background: #FFFFFF;" class="m-5">
        <label class="titleanswer ml-4 text-color-accent">Comentarios</label>
        <div *ngFor="let comment of ebookComments" class="row mt-4" style="background: #f5f6f8; border-radius: 10px;">
            <div class="comment-content mt-4 ml-4">
                <div class="p-1">
                    <span class="col-md-12 comment-date">{{comment?.created_at | date : 'dd LLL, y '}}</span>
                    <div class="col-md-12">
                        <div class="comment-img">
                            <p class="mt-2">
                                <img class="comment-box-header-image" [ngClass]="{'comment-img-reply': isReply}" [src]="comment?.imagenUsu" alt="">
                                <span class="nameanswers"> {{comment?.userName}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 ml-3 fontText d-flex flex-column justify-content-center" [innerHtml]="comment?.comment"></div>
                </div>
            </div>
            <div class="divdeleteanswer">
                <button *ngIf="userService.isAdmin() || userService.getUserId() == comment?.userId" size="large" status="danger" nbButton ghost>
                    <nb-icon (click)="deleteEbookComment(comment.id_comment)" icon="trash"></nb-icon>
                </button>
            </div>
        </div>
    </div>
</div>
