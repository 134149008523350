<div>
    <form [formGroup]="formEditByClass" >
        <h4 class="mt-3 mb-3">Editar Salas de aula</h4>
        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Salas de aula" formControlName="classroomId"
                        (selectionChange)="addClasseId($event.value)">
                        <mat-option *ngFor="let classroom of classes" [value]="classroom.id"
                        [title]="classroom.name">
                            <ng-container>{{classroom.name}}</ng-container>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div class="col">
                <mat-label class="mb-2">Salas de aula selecionadas</mat-label>
                <mat-chip-list>
                    <mat-chip *ngFor="let class of classroomsArray" selectable="true" removable="true"
                        (removed)="removeSelectedClass(class.id)">
                            {{class.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Início</mat-label>
                    <input matInput placeholder="Início" formControlName="initDate"
                        [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Término</mat-label>
                    <input matInput placeholder="Término" formControlName="endDate"
                        [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10">
                <mat-form-field class="width-100">
                    <mat-label>Link</mat-label>
                    <input matInput placeholder="Link" formControlName="link">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10">
                <mat-form-field class="width-100">
                    <mat-label>Link da aula gravada</mat-label>
                    <input matInput placeholder="https://dominio.com" formControlName="recordLink">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col">
            <button (click)="closeDialogRef()" class="right" nbButton size="small" status="primary">voltar</button>
        </div>
        <div  class="col text-right">
            <button (click)="updateClassrooms()" class="right" nbButton size="small"
                status="primary" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </div>
    
</div>