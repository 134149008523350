import { Component, OnInit , Inject , Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Student } from '../student';
import { Father } from './father';
import { FatherService } from './father.service';

@Component({
  selector: 'app-create-father',
  templateUrl: './create-father.component.html',
  styleUrls: ['./create-father.component.scss']
})
export class CreateFatherComponent implements OnInit {

  public editMode: boolean;
  public showList: boolean;
  public createFatherForm: FormGroup;
  public fathercreate: Father;
  public fatherList: Father[];
  public fatherName : string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public father: Father,
    @Optional() public dialogRef: MatDialogRef<CreateFatherComponent>,
    private dialog: MatDialog,
    private fatherService: FatherService,
    public userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.editMode = false;
    this.showList = false;

    this.createFatherForm = this.formBuilder.group({
      id: [],
      name: [''],
      email:[''],
      password:['']
    })

    if(this.father){
      this.initializeEditMode();
    }
  }

  createFather(){
    this.fathercreate = this.createFatherForm.getRawValue() as Father;
    this.fathercreate.profile = 6

    this.fatherService.createFather(this.fathercreate)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success(response.data.toString());
        this.createFatherForm.reset();
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error("Não foi possível cadastrar os dados verifique a conexâo e tente novamente!");
    })
  }

  initializeEditMode(){
    this.editMode = true;
    this.createFatherForm.patchValue(this.father);
  }

  getAllFathers(){
    this.fatherService.getAllFathers()
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.fatherList = response.data as Father[];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error("Não foi possível buscar os dados verifique a conexão e tente novamente!");
    })
  }

  openList(){
    this.showList = true;
    this.getAllFathers();
  }

  editFather(father:Father){
    const dialogRef = this.dialog.open(CreateFatherComponent, {
      minWidth: '60vw',
      data: father
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getAllFathers();
        this.editMode = false;
      }
    });
  }

  updateFather(){
    this.fathercreate = this.createFatherForm.getRawValue() as Father;
    this.fatherService.updateFather(this.fathercreate)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success(response.data.toString())
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error("Não foi possível atualizar os dados verifique a conexão e tente novamente!");
    })
  }

  backBtn(){
    this.showList = false;
    this.editMode = false;
  }

  deleteFather(fatherId){
    if(confirm('Deseja realmente excluir esse pai?')){
    this.fatherService.deleteFather(fatherId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success(response.data.toString());
        this.getAllFathers();
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error("Não foi possível excluir os dados verifique a conexão e tente novamente");
    })
  }
  }

  searchAllFatherByName(fatherName: string){
    this.fatherService.getAllFathersByName(fatherName)
    .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
            this.fatherList = response.data as Father[];
        }
        else{
            this.alertService.error(response.error);
        }
    }, err => {
        this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!')
    })
}

}
