<div class="contaier">
    <button class="mt-3 mb-3" (click)="clearDay()" nbButton shape="round" outline status="primary" size="small">
        <nb-icon icon="arrow-back-outline"></nb-icon>
    </button>
    <nb-list class="width-60 center">
        <nb-list-item  *ngFor="let video of scheduleDay.videos">
            <div class="row">
                <div class="col-6">
                    {{video.title}}
                </div>
                <div class="col-4">
                    {{video.duration}}
                </div>
                <div class="col-2 text-right">
                   <!--  <button nbButton outline size="tiny" status="primary">Assistir</button> -->
                </div>
            </div>
        </nb-list-item>
    </nb-list>

    <div class="row text-center">
        <button [routerLink]="[ '/video/list', scheduleDay.day ]" class="center hvr-grow mt-3 mb-3" shape="round" nbButton outline status="primary">
            Assistir
        </button>
    </div>
</div>