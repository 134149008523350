<div *ngIf="!showHolyDaysList" mat-dialog-content> 
    <form [formGroup]="holydaysForm" class="pt-5">
        <h4 *ngIf="editMode" mat-dialog-title class="mt-3 mb-3">Editar Feriado</h4>

        <!-- Name, date -->

        <div class="row">
            <div class="col-md-12">
                <mat-form-field *ngIf="userService.isAdmin()">
                    <mat-select  placeholder="Escola" 
                        (selectionChange)="getStudyPlans($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano de estudo" formControlName="studyPlan"
                    (selectionChange)="addStudyPlanId($event.value)">
                        <mat-option [value]="studyPlan.id" *ngFor="let studyPlan of studyPlans">
                            {{studyPlan.name}} 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input #dateInput matInput placeholder="Data"
                    [textMask]="{mask: maskService.date}" formControlName="date">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <button (click)="addDate(dateInput.value)" class="smallfont" nbButton size="small" outline status="primary">+</button>
            </div>
            <div class="col-md">
                <div *ngIf="addStudyPlanId.length < 3">
                    <mat-label class="mb-2">Plano de estudos selecionados</mat-label>
                    <mat-chip-list>
                        <mat-chip *ngFor="let studyPlan of studyPlanArray"
                            selectable="true" removable="true"
                            (removed)="removeSelectedStudyPlan(studyPlan.id)">
                            {{studyPlan.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>                            
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="col-md">
                <div >
                    <mat-label class="mb-2">Datas selecionadas</mat-label>
                    <mat-chip-list>
                        <mat-chip *ngFor="let date of dateArray"
                            selectable="true" removable="true"
                            (removed)="removeSelectedData(date.date)">
                            {{date.date}}
                            <mat-icon matChipRemove>cancel</mat-icon>                            
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </form>
    <div class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" class="smallfont" outline status="primary" class="text-color-primary border-primary-thin">Ver feriados
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createHolyDays()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading"
                [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>
</div>
<div *ngIf="showHolyDaysList"  class="">
    <mat-form-field *ngIf="userService.isAdmin()" class="width-100">
        <mat-select  placeholder="Escola" 
            (selectionChange)="getStudyPlans($event.value)">
            <mat-option *ngFor="let school of schools" [value]="school.id">
                {{school.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100">
        <mat-select placeholder="Plano de estudo"  [(ngModel)]="holyDaysFilter"
         (selectionChange)="getHolyDays($event.value)">
            <mat-option [value]="studyPlan.id" *ngFor="let studyPlan of studyPlans">
                {{studyPlan.name}} 
            </mat-option>
        </mat-select>
    </mat-form-field>
    <nb-list  class="width-100 mt-3 center"> 
            
        <nb-list-item *ngFor="let holyDay of holyDays ">
            <div class="row">
                <div class="col-6">                    
                    {{holyDay.name}}
                </div>
                <div class="col-4">                    
                    {{holyDay.date}} <!-- | date:'dd/MM/yyyy' -->
                </div>
                <div class="col-2 text-right">
                    <button size="tiny" status="danger" nbButton ghost 
                    (click)="removeHolyDays(holyDay.id)"
                        nbSpinnerStatus="danger" nbSpinnerSize="tiny">
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>  
        <nb-list-item *ngIf="!holyDays">
            <div class="row">
                <div class="col-12">                    
                    Nenhum resultado!
                </div>
                
            </div>
        </nb-list-item>  
    </nb-list>
    <mat-spinner *ngIf="loading" class="center"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showHolyDaysList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>