<h2 class="mb-2">Transmissões gravadas:</h2>
<mat-nav-list *ngIf="recordedClassrooms?.length">
    <a [ngClass]="{'disabled': !recorded?.recordLink}" *ngFor="let recorded of recordedClassrooms" mat-list-item (click)="openLink(recorded); $event.preventDefault()">
        <span mat-line>{{recorded.name}}</span>
        <span class="small" mat-line *ngIf="recorded?.recordLink; else noLink">
        <span *ngIf="recorded.live_vimeo_id">{{url}}/video/watch/</span>{{recorded?.recordLink}}
        </span>
        <ng-template #noLink>
            <span mat-line>Link indisponível. A gravação será disponibilizada em breve.</span>
        </ng-template>
    </a>
</mat-nav-list>
<!-- <div class="text-center" *ngIf="loading">
  <mat-spinner class="center" color="accent"></mat-spinner>
</div> -->