import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnimationOptions } from 'ngx-lottie';

import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

import { UserService } from '../user/user.service';
import { DisciplinesExercises, exerciseDiscipline, ListDisciplineExercises } from './disciplines-exercises';
import { ExercisesListService } from './exercises-list.service';

@Component({
    selector: 'app-exercises-list',
    templateUrl: './exercises-list.component.html',
    styleUrls: ['./exercises-list.component.scss']
})
export class ExercisesListComponent implements OnInit {

    public disciplines: DisciplinesExercises[];
    public loadingDisciplines: boolean;
    public loadingExercises: boolean;
    public exerciseDataSource: MatTableDataSource<{ id: number, name: string, link: string }>;
    public displayedColumns: string[];
    public disciplineSelected: string;
    public firstDiscipline: number;
    public lottieOptions: AnimationOptions;
    public filterExercise: string;
    public filteredExercises: exerciseDiscipline[];
    public exercises: exerciseDiscipline[];

    constructor(
        private exercisesListService: ExercisesListService,
        private alertService: AlertService,
        private userService: UserService,
        public deviceService: DeviceDetectorService,
        public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.displayedColumns = ['name', 'link'];
        this.getDisciplinesExercises();        
    }

    open(link: string) {
        window.open(link, '_blank');
    }

    getDisciplinesExercises() {
        this.firstDiscipline = 0;
        this.loadingDisciplines = true;
        const schoolId = this.userService.getUserSchool();
        const userId = this.userService.getUserId();
        const profileId = this.userService.getUserProfileId();
        this.exercisesListService.getListExercises(schoolId, userId, profileId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.disciplines = response.data as DisciplinesExercises[];
                    //this.getDisciplineByStudent(userId);
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingDisciplines = false;
            }, err => this.alertService.error('Houve um erro ao carregar as listas de exercício. Verifique a conexão e tente novamente'));
    }

    getDisciplineByStudent(userId: number){
        this.exercisesListService.getListExercises(this.userService.getUserSchool(), userId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    let disciplines = response.data as DisciplinesExercises[];
                    disciplines.forEach(element => {
                        this.disciplines.push(element);    
                    });
                    
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingDisciplines = false;
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente')); 
    }

    selectDiscipline(discipline: DisciplinesExercises) {
        this.disciplineSelected = discipline.discipline;
        this.filteredExercises = discipline.list;
        this.exercises = discipline.list;
        this.exerciseDataSource = new MatTableDataSource([...discipline.list]);
    }
    emptyDisciplinesLottieOptionsConfig() {
        this.lottieOptions = {
            path: 'assets/lotties/empty.json',
            loop: true,
            autoplay: true
        };
        }

        applyFilter() {
    
            if (this.filterExercise.length) {
                this.filteredExercises = [...this.exercises].filter(exercise => 
    
                    exercise.name.toLocaleLowerCase().indexOf(this.filterExercise.toLowerCase()) > -1);
    
                    this.exerciseDataSource = new MatTableDataSource([...this.filteredExercises]);
            } else {
                this.filteredExercises = [...this.exercises];
                this.exerciseDataSource = new MatTableDataSource([...this.exercises]);
            }
        }
}
