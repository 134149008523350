import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
    selector: 'app-payment-manage',
    templateUrl: './payment-manage.component.html'
})
export class PaymentManageComponent implements OnInit {

    constructor(public userService: UserService) { }

    ngOnInit(): void { }
}
