import { Injectable } from '@angular/core';
import * as textMaskAddOn from 'text-mask-addons';

@Injectable({ providedIn: 'root' })
export class MaskService {

    private maskCpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    private maskPhone = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    private maskCEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    private maskBirth = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    private maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    private maskDateTime = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];
    private maskDateTimeMock = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ':' , /\d/,/\d/];
    private maskTime = [/\d/, 'h', ':', /\d/, /\d/, 'm'];
    private maskExpiring = [/\d/, /\d/, '/', /\d/, /\d/];
    private maskNumber = textMaskAddOn.createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: false,
        requireDecimal: false
    });
    private maskReal = textMaskAddOn.createNumberMask({
        prefix: 'R$ ',
        suffix: '',
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        integerLimit: 5
    });

    private maskCardNumber = userInput => {
        const numbers = userInput.match(/\d/g);
        let numbersLength;
        if (numbers) { numbersLength = numbers.length; }

        if (numbersLength > 15) {
            return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ',
                /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
        } else {
            return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ',
                /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
        }
    }
    private maskCvv = userInput => {
        const numbers = userInput.match(/\d/g);
        let numbersLength;
        if (numbers) { numbersLength = numbers.length; }

        if (numbersLength > 3) {
            return [/\d/, /\d/, /\d/, /\d/];
        } else {
            return [/\d/, /\d/, /\d/];
        }
    }

    constructor() { }

    get cpf() {
        return this.maskCpf;
    }

    get phone() {
        return this.maskPhone;
    }

    get cep() {
        return this.maskCEP;
    }

    get birth() {
        return this.maskBirth;
    }

    get number() {
        return this.maskNumber;
    }

    get real() {
        return this.maskReal;
    }

    get date() {
        return this.maskDate;
    }

    get creditCard() {
        return this.maskCardNumber;
    }

    get expiration() {
        return this.maskExpiring;
    }

    get cvv() {
        return this.maskCvv;
    }

    get time() {
        return this.maskTime;
    }

    get dateTime() {
        return this.maskDateTime;
    }

    get dateTimeMock() {
        return this.maskDateTimeMock;
    }
}
