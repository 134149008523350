import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SchoolService } from '../../../school/school.service';
import { MockDashboard } from '../../dashboard';
import { DashboardService } from '../../dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

export interface ReportMock {
  student: string;
  mock: string;
  questions: number;
  date: string;
}
/* const ELEMENT_DATA: ReportMock[] = [
  {student: 'Thais Braga', mock: 'Simulado ACAFE', questions: 15, date: '30/09/2023 - 18:09'},
  {student: 'Pedro Soares', mock: 'Simulado UFSC', questions: 55, date: '21/09/2023 - 10:10'},
  {student: 'Maria Marizete', mock: 'Simulado ENEM', questions: 20, date: '29/09/2023 - 15:39'},
  {student: 'Deide André', mock: 'Simulado UFPR', questions: 5, date: '10/09/2023 - 11:15'},
  {student: 'Aline Soares', mock: 'Simulado Geral', questions: 10, date: '01/09/2022 - 14:21'},
]; */

@Component({
  selector: 'app-mock',
  templateUrl: './mock.component.html',
  styleUrls: ['./mock.component.scss']
})
export class MockComponent implements OnInit {

  displayedColumns: string[];
  dataSource: MatTableDataSource<MockDashboard>;
  public mockDash: MockDashboard[];
  public selectedSchool: any;
  public startDate: string;
  public endDate:string;
  private eventSubscription: Subscription;
  protected onDestroy = new Subject<void>();

  constructor(
    public userService: UserService,
    public alertService: AlertService,
    public layoutService: LayoutService,
    public schoolService: SchoolService,
    public dashboardService: DashboardService,
  ) { }

  ngOnInit() {
    this.displayedColumns = ['name', 'mock', 'questions', 'realized'];
    if(this.userService.isAdmin()){
      this.getMocksLast(0);
    }
    else{
      this.getMocksLast(this.userService.getUserSchool())
    }

    //coment
    //comment 2

    this.eventSubscription = this.dashboardService.onEventDashboardMock().subscribe(eventData => {
      if(this.userService.isAdmin()){
        this.getMocks();
      }
    });
  }


  getMocksLast(schoolId : number){
    this.dashboardService.getMocksLast(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error){
          this.mockDash = response.data as MockDashboard[];
          this.dataSource = new MatTableDataSource(this.mockDash);
        }
        else{
          this.mockDash = [];
          this.alertService.error(response.error);
          this.dataSource = new MatTableDataSource(this.mockDash);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os simulados e avaliações. Verifique a conexão e tente novamente!'));
  }

  getMocksLastSelected(schoolId : number){


    this.dashboardService.getMocksLast(schoolId)
      .subscribe(res => {
        //teste de mock
        const response = res.body as ResponseApi;
        if (!response.error){
          this.mockDash = response.data as MockDashboard[];
          this.dataSource = new MatTableDataSource(this.mockDash);
        }
        else{
          this.mockDash = [];
          this.alertService.error(response.error);
          this.dataSource = new MatTableDataSource(this.mockDash);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os simulados e avaliações. Verifique a conexão e tente novamente!'));
  }

  getMocksLastSelectedWithDate(schoolId : number , starDate:string , endDate:string){


    this.dashboardService.getMocksLastWithDate(schoolId , starDate ,endDate)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error){
          this.mockDash = response.data as MockDashboard[];
          this.dataSource = new MatTableDataSource(this.mockDash);
        }
        else{
          this.mockDash = [];
          this.alertService.error(response.error);
          this.dataSource = new MatTableDataSource(this.mockDash);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os simulados e avaliações. Verifique a conexão e tente novamente!'));
  }

  getMocks(){
    this.dashboardService
    .getSchoolId()
    .pipe(takeUntil(this.onDestroy))
    .subscribe((schoolId) => {
        this.selectedSchool = schoolId;
    });

    this.dashboardService
      .getStarDate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((start) => {
        this.startDate = start;
      });

    this.dashboardService
      .getEndDate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((end) => {
        this.endDate = end;
      });

    if (this.startDate && this.endDate) {
      this.getMocksLastSelectedWithDate(this.selectedSchool, this.startDate ,this.endDate);
    }
    else {
      this.getMocksLastSelected(this.selectedSchool);
    }
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

}
