<div class="minhadiv">
    <label *ngIf="deleteConf.value == 0" [ngClass]="{'labelini': !deviceService.isMobile() , 'labeliniMobile' : deviceService.isMobile()}">Você tem certeza que deseja excluir essa avaliação?</label>
    <label *ngIf="deleteConf.value == 1" [ngClass]="{'labelini': !deviceService.isMobile() , 'labeliniMobile' : deviceService.isMobile()}">Você tem certeza que deseja excluir essa questão?</label>
    <label *ngIf="deleteConf.value == 2" [ngClass]="{'labelini': !deviceService.isMobile() , 'labeliniMobile' : deviceService.isMobile()}">Você tem certeza que deseja excluir essa disciplina?</label>
    <label *ngIf="deleteConf.value == 3" [ngClass]="{'labelini': !deviceService.isMobile() , 'labeliniMobile' : deviceService.isMobile()}">Você tem certeza que deseja excluir esse tema?</label>
    <label *ngIf="deleteConf.value == 4" [ngClass]="{'labelini': !deviceService.isMobile() , 'labeliniMobile' : deviceService.isMobile()}">Você tem certeza que deseja excluir esse Sub-tema?</label>
    <label *ngIf="deleteConf.value == 5" [ngClass]="{'labelini': !deviceService.isMobile() , 'labeliniMobile' : deviceService.isMobile()}">Você tem certeza que deseja excluir essa Instituição?</label>
    <label *ngIf="deleteConf.value == 6" [ngClass]="{'labelini': !deviceService.isMobile() , 'labeliniMobile' : deviceService.isMobile()}">Você tem certeza que deseja excluir esse tipo de questão?</label>
    <button (click)="deleteSelect()" [ngClass]="{'buttonini': !deviceService.isMobile() , 'buttoniniMobile' : deviceService.isMobile()}">Sim excluir!</button>
    <a class="testeclass" (click)="modalClose()">Cancelar</a>
</div>