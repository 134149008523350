<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-12 mt-2">
            <ng-container>
                <div class="page-title-container"  [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">CERTIFICADOS</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Meus certificados</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Meus certificados</h6>
                        <!-- <p class="fs-13 pb-0">&nbsp;</p> -->
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-md-12 p-0 text-right">
            <button (click)="create()" nbButton class="bg-accent bg-color-accent-300 border-accent" nbSpinnerStatus="warning" size="small">Criar certificado</button>
        </div>
    </div>
    <div class="page-container p-0 pt-4" *ngIf="dataSource?.data?.length">
        <table mat-table [dataSource]="dataSource" class="full-width">
            <ng-container matColumnDef="certificate">
                <th mat-header-cell *matHeaderCellDef class="brl-5"> CERTIFICADO </th>
                <td mat-cell *matCellDef="let certificate; let i = index">
                    {{certificate.mockName}} - {{certificate.planName}} - {{certificate.schoolName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="edit" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="text-right pl-2 brr-5"> AÇÕES </th>
                <td mat-cell *matCellDef="let certificate; let i = index" class="text-right">
                    <button (click)="edit(certificate)" nbButton size="tiny" outline status="primary" class="bg-color-accent-100 border-accent">
                        <nb-icon icon="edit"></nb-icon>
                    </button>
                    <button class="ml-1" size="tiny" (click)="delete(certificate.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="no-certificates" *ngIf="!dataSource?.data?.length">
        <h4>Nenhum certificado disponível</h4>
        <br />
        <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
    </div>
    <div class="text-center" [ngClass]="{'width-50': !layoutService.isMobile}" *ngIf="loading">
        <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
    </div>
</div>
