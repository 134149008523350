import { Component, OnInit, Optional, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { Classroom } from 'src/app/modules/classroom/classroom';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MaskService } from 'src/app/shared/services/mask.service';
import { School } from '../../school/school';
import { Class } from '../../school/class';
import { SchoolService } from '../../school/school.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Unit } from '../../school/unit';
import { UserService } from 'src/app/modules/user/user.service';
import { Observable, Subscription } from 'rxjs';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { EditClassesComponent } from '../edit-classes/edit-classes.component';

interface StudyPlans {
    name: string;
    id: number;
}

interface Classroom {

    id: number;
    name: string;
    classId: number;
    planId?: any;
    schools: [],
    schoolId: number,
    unitId: number;
    link: string;
    initDate: string | Date;
    endDate: string | Date;
    tag: string;
    recordLink?: string;
    planName?: string;
    versionId: number;
    live_vimeo_id?: number;
    isVimeo?: boolean;
    liveVideo?: number;
}

@Component({
    selector: 'app-classroom-create',
    templateUrl: 'classroom-create.component.html',
    styleUrls: ['./classroom-create.component.scss']
})

export class ClassroomCreateComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public classrooms: Classroom[];
    public editMode: boolean;
    public showList: boolean;
    public showEdit: boolean = false;
    public loading: boolean;
    public filterPlanId: number;
    public selectedSchoolId: number;
    public schools: School[];
    public plans: StudyPlan[];
    private admUpdate$: Subscription;
    public studyPlanArray: StudyPlans[] = [];
    public showButton: boolean;
    public schoolIdPlan: number;
    public version: any;
    public filterClass: string;
    public showLink: boolean;
    //@ViewChild('schoolIdSelected') schoolIdSelected: ElementRef;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public classroom: Classroom,
        @Optional() public dialogRef: MatDialogRef<ClassroomCreateComponent>,
        private classroomService: ClassroomService,
        private alertService: AlertService,
        private adminService: AdminService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        public maskService: MaskService,
        public userService: UserService,
        private studyPlanService: StudyPlanService,
        private schoolService: SchoolService,) { }

    ngOnInit(): void {

        this.showLink = true;

        if (this.classroom) {
            if (this.classroom.liveVideo == 1) {
                this.showLink = false;
            }
            else {
                this.showLink = true;
            }
        }
        this.version = [{ option: 'Calendário modelo antigo', value: 1 }, { option: 'Calendário modelo novo', value: 2 }];

        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());

       
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        this.form = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            planId: ['', Validators.required],
            schoolId: [''],
            endDate: ['', Validators.required],
            initDate: ['', Validators.required],
            link: ['', Validators.required],
            recordLink: [''],
            versionId: [''],
            tag: ['']
        });
        /*  this.profileInit(); */
        this.showEdit = false;
        if (this.classroom) { this.initializeEditMode(); }

        this.profileInit();
        if (!this.editMode) {
            this.form.get('versionId').setValue(1);
        }
    } 
    profileInit() {
        if (this.userService.isAdmin()) {
            !this.showList && this.getSchools();
        } else if (this.userService.isGeneralCoordinator()) {
            //console.log('é cordenador de geral');
            this.getSchoolPlans(this.userService.getUserSchool());
        }else if(this.userService.isUnitCoordinator()){
            //console.log('é cordenador de unidade');
            this.getSchoolPlans(this.userService.getUserSchool());
        } else if(this.userService.isTeacher){
            //console.log('é professor');
            this.getSchoolPlans(this.userService.getUserSchool());
        }
    } 
    removeSelectedStudyPlan(id: number) {
        this.studyPlanArray.forEach(element => {
            if (element.id == id) {
                let index = this.studyPlanArray.indexOf(element);
                this.studyPlanArray.splice(index, 1);
            }

        });
    }
    addStudyPlanId(id: number) {

        if (!this.editMode) {
            let max = 15;

            if (this.editMode) {
                max = 1;
            }

            if (this.studyPlanArray.length < max) {
                this.plans.forEach(element => {
                    if (element.id == id) {
                        this.studyPlanArray.push({ id: element.id, name: element.name });
                    }

                });
            } else {
                this.alertService.error("Você pode selecionar no máximo " + max + " planos de estudos.");
            }
        }
    }
    getSchoolPlans(schoolId: number) {
        
        // this.schoolIdPlan = schoolId;
        // this.studyPlanService.getPlans(this.schoolIdPlan)
        //     .subscribe(res => {
        //         const response = res.body as ResponseApi;

        //         if (!response.error) {
        //             this.plans = response.data as StudyPlan[];
        //             if (this.editMode) {
        //                 setTimeout(() => {
        //                     this.addStudyPlanId(this.classroom.planId);

        //                 }, 1000);
        //             }
        //         } else {
        //             this.alertService.error(response.error);
        //         }
        //     }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));

            this.loading = true;
            this.schoolIdPlan = schoolId;
            this.studyPlanService.getSelectPlans(this.schoolIdPlan)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                this.plans = response.data as StudyPlan[];
                if (this.editMode) {
                    setTimeout(() => {
                        this.addStudyPlanId(this.classroom.planId);

                    }, 1000);
                }
                } else {
                this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getSchoolPlansByVersion(version: number, schoolId: number) {

        if (schoolId) {
            this.plans = [];
            this.schoolIdPlan = schoolId;
            this.studyPlanService.getPlansByVersion(version, schoolId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.plans = response.data as StudyPlan[];
                        if (this.editMode) {
                            setTimeout(() => {
                                this.addStudyPlanId(this.classroom.planId);

                            }, 1000);
                        }
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
        }
        else if (this.schoolIdPlan) {
            this.studyPlanService.getPlansByVersion(version, this.schoolIdPlan)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.plans = response.data as StudyPlan[];
                        if (this.editMode) {
                            setTimeout(() => {
                                this.addStudyPlanId(this.classroom.planId);

                            }, 1000);
                        }
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
        }
    }

    initializeEditMode() {
        this.editMode = true;
        this.form.patchValue(this.classroom);
        if (this.classroom.tag == "AULA") {
            this.form.get("tag").setValue("");
        }
        if (this.userService.isAdmin()) {
            this.form.get('schoolId').setValue(this.classroom.schoolId);
        }
        this.form.get('planId').setValue(this.classroom.planId);
        this.form.get('versionId').setValue(this.classroom.versionId);
        /*  this.getSchoolPlans(this.schoolIdPlan); */
        this.getSchoolPlansByVersion(this.classroom.versionId, this.classroom.schoolId);
    }

    edit(classroom: Classroom, schoolId: number) {
        this.selectedSchoolId = schoolId;
        const dialogRef = this.dialog.open(ClassroomCreateComponent, {
            minWidth: '60vw',
            data: classroom
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getClassrooms(this.filterPlanId);
            }
        });
    }

    editClassrooms() {
        this.showEdit = true;
        this.showList = false;
        const classroom = this.classrooms;
        const dialogRef = this.dialog.open(EditClassesComponent, {
            minWidth: '60vw',
            data: classroom
        });
        dialogRef.afterClosed().subscribe(result => {
            this.showList = true;
            this.getClassrooms(this.filterPlanId);
        });
    }

    openList() {
        this.showList = true;
    }

    getClassrooms(planId: number) {
        this.loading = true
        this.classroomService.getClassRoomsAdmin(planId, this.schoolIdPlan)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classrooms = response.data as Classroom[];
                    this.showButton = true;
                    this.loading = false;
                } else {
                    this.alertService.error(response.error);
                    this.classrooms = [];
                    this.showButton = false;
                    this.loading = false;
                }
            }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
    }

    getSchools() {
        this.plans = [];
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));

    }

    create() {
        if (this.form.errors) {
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }
        this.loading = true;

        const classroom = this.form.getRawValue() as Classroom;

        if (this.userService.isAdmin()) {
            classroom.schoolId = Number(this.form.get('schoolId').value);

        } else if (this.userService.isGeneralCoordinator()) {
            classroom.schoolId = Number(this.userService.getUserSchool());

        } else if (this.userService.isUnitCoordinator()) {
            classroom.schoolId = Number(this.userService.getUserSchool());

        } else if (this.userService.isTeacher) {
            classroom.schoolId = Number(this.userService.getUserSchool());
        }

        let studyPlansId: number[] = [];
        this.studyPlanArray.forEach(element => {
            studyPlansId.push(element.id);
        });

        var reports = [];

        classroom.isVimeo = false;

        studyPlansId.forEach(elementId => {

            setTimeout(() => {
                classroom.planId = elementId;
                this.classroomService.createClassroom(classroom)
                    .subscribe(res => {
                        const response = res.body as ResponseApi;

                        if (!response.error) {
                            reports.push({ status: "success", alert: 'Sala criada!' });
                            this.studyPlanArray = [];
                            this.adminService.updateChields();
                            this.form.reset();
                        } else {
                            reports.push({ status: "error", alert: response.error });

                            //this.alertService.error();
                        }

                        //  this.loading = false;

                    }, err => {
                        reports.push({ status: "error", alert: 'Houve um erro ao criar a sala. Verifique a conexão e tente novamente' });
                        //reports.push('Houve um erro ao criar a sala. Verifique a conexão e tente novamente');
                        //this.alertService.error('Houve um erro ao criar a sala. Verifique a conexão e tente novamente');
                        //  this.loading = false;
                    });
            }, 500);//ms
        });

        setTimeout(() => {
            this.loading = false;
            let alert = "";
            reports.forEach(element => {
                if (element.status == "error") {
                    alert = element.alert;
                }
            });
            if (alert != "") {
                this.alertService.error(alert);
            } else {
                this.alertService.success("Dados Salvos com sucesso!");
            }


        }, 6000);

    }

    saveChanges() {
        if (!this.form.valid) {
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }

        this.loading = true;

        let classroom = this.form.getRawValue() as Classroom;
        classroom.live_vimeo_id = this.classroom.live_vimeo_id;
        if (!this.classroom.isVimeo) {
            classroom.isVimeo = false;
        }
        else {
            classroom.isVimeo = this.classroom.isVimeo;
        }

        this.classroomService.saveClassroomChanges(classroom)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Sala editada!');
                    this.adminService.updateChields();
                    this.form.reset();
                    this.dialogRef.close(true);
                    this.studyPlanArray = [];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;

            }, err => {
                this.alertService.error('Houve um erro ao salvar as alterações da sala. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getClassByName(name: string) {

        if (name) {

            this.classroomService.getClassByName(false, name)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.classrooms = response.data as Classroom[];
                    }

                    else {
                        this.alertService.error(response.error);
                    }
                })
        } else {
            this.alertService.error('O campo nome não pode estar vazio para fazer a busca!');
        }
    }

    remove(classroomId: Classroom) {
        if (confirm('Deseja realmente excluir a sala?')) {
            this.classroomService.removeClassroom(classroomId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Removido!');
                        this.adminService.updateChields();
                        this.getClassrooms(this.filterPlanId);
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover a sala. Verifique a conexão e tente novamente'));
        }
    }
}
