import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EssayCredit } from './essay-credit';

@Injectable({
  providedIn: 'root'
})
export class EssayCreditService {
  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  getEssayCredits(schoolId?: number) {
    return this.http.post(this.API + '/essays/getEssayCredits', { schoolId }, { observe: 'response' });
  }
  
  createEssayCredit(iframe: EssayCredit[]) {
    return this.http.post(this.API + '/essays/createEssayCredit', iframe, { observe: 'response' });
  }
  removeIframe(idIframe: number) {
    return this.http.post(this.API + '/essays/removeIframe', { idIframe }, { observe: 'response' });
  }
  
}
