<div class="container pt-5 pb-5">
    <mat-accordion>
        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-70 center">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Escola</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-school></app-create-school>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Unidade</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-unit></app-create-unit>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Turma</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-class></app-create-class>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Coordenador</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-manager></app-create-manager>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Professor</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-school-create-teacher></app-school-create-teacher>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Administrador</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-admin></app-create-admin>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Rotas</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-routes></app-create-routes>
            </ng-template>
        </mat-expansion-panel>

    </mat-accordion>

</div>