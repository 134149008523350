import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { UserService } from '../user/user.service';
import { VideoService } from '../video/video.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportError } from './report-error';

@Component({
  selector: 'app-report-error',
  templateUrl: './report-error.component.html',
  styleUrls: ['./report-error.component.scss']
})
export class ReportErrorComponent implements OnInit {

  public descriptionError = '';
  public loading : boolean;
  public reportForm: FormGroup;

  constructor( @Optional() @Inject(MAT_DIALOG_DATA) public report: any,
  @Optional() public dialogRef: MatDialogRef<ReportErrorComponent>,
  private userService: UserService,
  private videoService: VideoService,
  private alertService: AlertService,
   private formBuilder: FormBuilder,
  public layoutService : LayoutService) { }

  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      id:          [this.report.id],
      userId:      [this.userService.getUserId()],
      url:         [this.report.rota],
      type:        [this.report.type],
      description: ['', Validators.required]
    });

    if(this.report){
      this.reportForm.patchValue(this.report.report);
      this.reportForm.get('userId').setValue(this.report.report.userId);
    }
  }

  createReport(){
    const report = this.reportForm.getRawValue() as ReportError;
    this.videoService.addReportError(report)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Seu problema foi reportado com sucesso! Estaremos analisando e entraremos em contato.');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  updateReport(){
    const report = this.reportForm.getRawValue() as ReportError;
    this.videoService.updateReport(report)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Erro atualizado com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }
  
  closeDialogRef(){
    this.dialogRef.close(true);
  }

}
