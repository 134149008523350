import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CommunityService } from '../../community.service';
import { CommunityAnswer, CommunityTopic } from '../communityComment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/modules/user/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/shared/services/layout.service';


@Component({
  selector: 'app-answer-topic',
  templateUrl: './answer-topic.component.html',
  styleUrls: ['./answer-topic.component.scss']
})
export class AnswerTopicComponent implements OnInit {

  public topic: CommunityTopic;
  public topicId: number;
  public questionEditor = ClassicEditor;
  public editorConfig;
  public answerComment: string;
  private apiUrl = environment.ApiUrl;
  public showAnswer: boolean;
  public comunityAnswer: CommunityAnswer;
  public createAnswerTopicForm: FormGroup;
  public topicAnswers: CommunityAnswer[];

  constructor(
    private route: ActivatedRoute,
    private communityService: CommunityService,
    private alertService: AlertService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    public layoutService: LayoutService
  ) { }

  ngOnInit() {

    this.createAnswerTopicForm = this.formBuilder.group({
      id_answer: [],
    topicId: [],
    answerText: [''],
    schoolId: [],
    userName: [''],
    userId: [],
    created_at: [],
    updated_at: []
    })

    this.showAnswer = false;
    this.answerComment = "";
    this.topicId = Number(this.route.snapshot.paramMap.get('topicId'));

    this.getTopicById(this.topicId);
    this.getTopicAnswers(this.topicId);

    this.editorConfig = {
      ckfinder: {
        // tslint:disable-next-line: max-line-length
        uploadUrl: this.apiUrl + '/upload/exerciseImg',
      },
      image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

        styles: [
          // This option is equal to a situation where no style is applied.
          'full',

          // This represents an image aligned to the left.
          'alignLeft',

          // This represents an image aligned to the right.
          'alignRight'
        ]
      }
    };
  }

  getTopicById(topicId : number){
    this.communityService.getTopicById(topicId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.topic = response.data as CommunityTopic;
        if(!this.topic.ImagenUsu){
          this.topic.ImagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png';
        }
      }
    },err => this.alertService.error('Não foi possivel buscar os dados verifique a conexao e tente novamente'));
  }

  openAnswerBox(){
    this.showAnswer = !this.showAnswer;
  }

  createAnswer(comment: string){

    this.comunityAnswer = this.createAnswerTopicForm.getRawValue() as CommunityAnswer;


    this.comunityAnswer.answerText = comment;
    this.comunityAnswer.topicId = this.topicId;
    this.comunityAnswer.schoolId = this.userService.getUserSchool();
    this.comunityAnswer.userName = this.userService.getUserName();
    this.comunityAnswer.userId = this.userService.getUserId();

    //console.log(this.comunityAnswer);

    this.communityService.createAnswer(this.comunityAnswer)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Comentário Respondido');
        this.showAnswer = false;
        this.getTopicAnswers(this.topicId);
      }
      else{
        this.alertService.error(response.error);
      }
    },err => this.alertService.error('Não foi possivel cadastrar o comentário verifique a conexão e tente novamente'))
  }

  getTopicAnswers(topicId: number){
    this.communityService.getTopicAnswers(topicId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.topicAnswers = response.data as CommunityAnswer[];
        this.topicAnswers.forEach(element => {
          if(!element.ImagenUsu){
            element.ImagenUsu = 'https://api-sistema.notamaxima.com.br/store/students/avatar.png'
          }
        });
      }
      else{
        this.topicAnswers = [];
      }
    }, err => this.alertService.error('não foi possivel buscar os dados verifique a conexâo e tente novamente'))
  }

  backBtn(){
    this.router.navigate(['community'])
  }

  deleteAnswer(answerId : number){
    if(confirm('Deseja remover essa resposta?')){
    this.communityService.deleteAnswer(answerId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.getTopicAnswers(this.topicId);
        this.alertService.success('Resposta deletada com sucesso!');
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Houve um erro ao deletar a resposta verifique a conexão e tente novamente!')
    })
  }
}

}
