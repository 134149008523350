import { School } from './school/school';
import { RouteMain } from './school/create-routes/edit-route/route-main';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StudyPlan } from './study-plan/study-plan';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { Podcast } from '../podcast/podcast';
import { MainTheme } from './rdo-manager/create-theme/theme';

@Injectable({providedIn: 'root'})
export class AdminService {

    private API = environment.ApiUrl;
    private updateSubject = new BehaviorSubject<Date>(new Date());

    constructor(private http: HttpClient) {}

    updateChields() {
        this.updateSubject.next(new Date());
    }

    getUpdateSubject() {
        return this.updateSubject.asObservable();
    }

    getRoutes() {
        return this.http.get(this.API + '/routes/getRoutes', {observe: 'response'});
    }

    getHolyDays(studyPlanId: number) {
        return this.http.post(this.API + "/miscellaneous/getHolyDays", { studyPlanId }, { observe: 'response' });

    }
    getStudyPlanSchool(schoolId: number) {
        return this.http.post(this.API + "/miscellaneous/getStudyPlanSchool", { schoolId }, { observe: 'response' });
    }
    createHolyDays(dates: any[], studyPlansId: any[]) {
        return this.http.post(this.API + "/miscellaneous/createHolyDays", { dates, studyPlansId }, { observe: 'response' });

    }
    removeHolyDays(holyDayId: number) {
        return this.http.post(this.API + "/miscellaneous/removeHolyDays", { holyDayId }, { observe: 'response' });

    }
    saveHolyDays(data: string, studyPlanId: number) {
        return this.http.post(this.API + "/miscellaneous/saveHolyDays", { data, studyPlanId }, { observe: 'response' });
    }
    saveChanges(podcast: Podcast) {
        return this.http.post(this.API + '/miscellaneous/savePodcasts', podcast, { observe: 'response' });
    }
    createTheme(theme: MainTheme) {
        return this.http.post(this.API + '/essays/createTheme', theme , { observe: 'response' });
    }
    getDbThemeText(themeId: number) {
        return this.http.post(this.API + '/essays/getDbThemeText' , {themeId} , { observe: 'response' });
    }
    getThemes() {
        return this.http.post(this.API + '/essays/getThemes' , 0 , { observe: 'response' });
    }
    deleteTheme(themeId: number) {
        return this.http.post(this.API + '/essays/deleteTheme', {themeId} , { observe: 'response' });
    }
    updateTheme(theme: MainTheme) {
        return this.http.post(this.API + '/essays/updateTheme', theme , { observe: 'response' });
    }
    getThemeText(externalId: number) {
        return this.http.post(this.API + '/essays/getTextTheme', {externalId} , { observe: 'response' });
    }

    getIcons() {
        return this.http.get(this.API + '/icons', {observe: 'response'});
    }
}
