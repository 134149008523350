import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FatherService {

  private API = environment.ApiUrl;

  constructor(
    private router: Router,
    private http: HttpClient) { }

    getStudentsFather(userId: number) {
      return this.http.get(this.API + '/father/getStudentsFather/'+userId, { observe: 'response' });
  }
}
