import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { LottieModule } from 'ngx-lottie';

import {
  NbAccordionModule,
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbSpinnerModule,
  NbTooltipModule,
} from '@nebular/theme';

import { PendingTasksComponent } from './pending-tasks/pending-tasks.component';
import { SentTasksComponent } from './sent-tasks/sent-tasks.component';
import { TaskStudentComponent } from './task-student.component';
import { WriteTaskDialogComponent } from './write-task-dialog/write-task-dialog.component';
import { FormsModule } from '@angular/forms';
import {  ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TaskStudentComponent,
    SentTasksComponent,
    PendingTasksComponent,
    WriteTaskDialogComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRippleModule,
    LottieModule.forRoot({ player: () => import('lottie-web')}),

    NbListModule,
    NbCardModule,
    NbButtonModule,
    NbSpinnerModule,
    NbInputModule,
    NbDialogModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    NbIconModule,
    NbTooltipModule,
    NbAccordionModule,
    NbAlertModule,

  ]
})
export class TaskStudentModule { }
