import { TaskManagerService } from '../task-manager.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MaskService } from 'src/app/shared/services/mask.service';
import { UserService } from 'src/app/modules/user/user.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Class, ClassSelect } from '../../school/class';
import { SchoolService } from '../../school/school.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { Task } from '../task';
import { SchoolSelect } from '../../school/school';
import { UnitSelect } from '../../school/unit';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';


@Component({
  selector: 'app-submit-task',
  templateUrl: './submit-task.component.html',
  styleUrls: ['./submit-task.component.scss']
})

export class SubmitTaskComponent implements OnInit {

  public formTasks: FormGroup;
  public tasks:Task[];
  public classes: ClassSelect[];
  public loading: boolean;
  public file: any;
  public fileName: string;
  public extension: string;
  public materialObservation: string;
  public schools: SchoolSelect[];
  public units: UnitSelect[];
  public plans : StudyPlan[];
  public selectedPlans : StudyPlan[];

  constructor(
    public taskManagerService: TaskManagerService,
    public maskService: MaskService,
    public userService: UserService,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private studyPlanService : StudyPlanService,) {  }


  ngOnInit() {
    this.formTasks = this.formBuilder.group({
      id:[],
      taskName:['', Validators.required],
      classId:[''],
      finalDate:['', Validators.required],
      subjects:[''],
      extension: [''],
      file: [''],
      description: [''],
      teacherId: [],
      className: [''],
      plans:[]
    });

    this.selectedPlans = [];

    if(this.userService.isTeacher()){
      this.getTeacherPlans(this.userService.getUserId());
    }
    else{
      this.getPlansBySchool(this.userService.getUserSchool());
    }
    this.checkWhoIs();
  }

  checkWhoIs(){
    if(this.userService.isGeneralCoordinator()){
      this.getSchoolSelection(this.userService.getUserSchool());
      this.getUnitSelection(this.userService.getUserSchool());
    }
    if(this.userService.isUnitCoordinator()){
      this.getSchoolSelection(this.userService.getUserSchool());
      this.getUnitSelection(this.userService.getUserSchool());
      this.getClassesSelection(this.userService.getUserUnit());
    }

    if(this.userService.isTeacher()) this.getTeacherClasses(this.userService.getUserId());

    if(this.userService.isAdmin()) this.getSchoolSelection();
  }

  getClassesSelection(unitId?: number) {
    this.schoolService.getClassesSelection(unitId).subscribe(
      (res) => {
        const response = res.body as ResponseApi;
        if(!response.error) this.classes = response.data as ClassSelect[];
        else {
          this.classes = [];
          this.alertService.error(response.error);
        }
      },(err) => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!')
    );
  }

  getClassesBySchool(schoolId?:number){
    this.schoolService.getClassesBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classes = response.data as ClassSelect[];
        else {
          this.classes = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!'));
  }

  getTeacherPlans(teacherId){
    this.taskManagerService.getTeacherPlans(teacherId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plans = response.data as StudyPlan[];
      }
    }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'))
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classes = response.data as Class[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  getClasses() {
    this.schoolService.getClasses()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  submit(){
    if (this.formTasks.valid) {
      this.loading = true;
      this.formTasks.get('file').setValue(this.file);
      this.formTasks.get('extension').setValue(this.extension);
      this.formTasks.get('teacherId').setValue(this.userService.getUserId());
      this.formTasks.get('id').setValue(this.userService.getUserId());

      if(this.selectedPlans.length > 0){
        let plansIds = [];
      this.selectedPlans.forEach(select => {
        plansIds.push(select.id);
      });
      this.formTasks.get('plans').setValue(plansIds);
      }

      const tasks: any = this.formTasks.getRawValue() as Task;
      const teacherName = this.userService.getUserName()
      this.taskManagerService.createTask(tasks, teacherName)
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                  this.alertService.success('Tarefa criada!');
                  this.formTasks.reset();
                  this.adminService.updateChields();
              } else {
                  this.alertService.error(response.error);
              }

              this.loading = false;
          }, err => {
              this.alertService.error('Houve um erro ao criar a tarefa. Verifique a conexão e tente novamente');
              this.loading = false;
          });
    } else {
        this.alertService.error('Preencha os campos corretamente');
    }
  }

  handleFileInput(files: FileList): void {
    if (files && files[0]) {

        const file = files[0];

        this.fileName = file.name;
        this.extension = file.name.split('.').pop();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            this.file = reader.result;
        };
    }
  }
  getSchoolSelection(schoolId?: number) {
    this.schoolService.getSchoolSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.schools = response.data as SchoolSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnitSelection(schoolId?: number){
    this.schoolService.getUnitsSelection(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error) this.units = response.data as UnitSelect[];
      else this.alertService.error(response.error);
    }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
  }

  deleteFile(file: FileList): void {
    if (confirm('Deseja remover o arquivo?')) {
      this.file = "";
    }
  }

  addPlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.indexOf(plan);

    if (index < 0) {
      this.selectedPlans.push(plan);
      this.formTasks.get('plans').reset();
    }
  }
  removePlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.indexOf(plan);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }

  getclassPlans(classId){
    if(!this.userService.isTeacher()){
      this.taskManagerService.getPlansByClass(classId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plans = response.data as StudyPlan[];
      }
    }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'))
    }
  }

  getPlansBySchool(schoolId: number) {
    this.loading = true;
    this.studyPlanService.getSelectedPlansBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
}
