<div class="minhadiv" *ngIf="!themeConfig.edit">
    <label class="title ml-2">Criar tema </label>

    <mat-tab-group dynamicHeight class="mb-4">
        <mat-tab label="Temas">
            <div class="mt-4">
                <mat-select class="ml-2" placeholder="Selecione a disciplina" [(ngModel)]="selectDiscipline">
                    <mat-option *ngFor="let disciple of disciplines" [value]="disciple.id">
                        {{disciple.name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="mt-4">
                <p><input placeholder="Nome do tema" [(ngModel)]="themeName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
            </div>
            <div class="mt-2">
                <button (click)="createTheme(themeName , selectDiscipline)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
            </div>
        </mat-tab>
        <mat-tab label="Subtemas">
            <div class="mt-4 width-80">
                <mat-select class="ml-2" placeholder="Selecione a disciplina" [(ngModel)]="selectDiscipline">
                    <mat-option *ngFor="let disciple of disciplines" [value]="disciple.id">
                        {{disciple.name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="mt-4">
                <mat-select class="ml-2" placeholder="Selecione o tema" [(ngModel)]="selectTheme">
                    <mat-option *ngFor="let theme of themes" [value]="theme.id">
                        {{theme.name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="mt-4">
                <p><input placeholder="Nome do subtema" [(ngModel)]="subThemeName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
            </div>
            <div class="mt-2">
                <button (click)="createSubTheme(subThemeName , selectDiscipline , selectTheme)" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="minhadiv" *ngIf="themeConfig.edit && !themeConfig.isSub">
    <label class="title ml-2">Editar tema: {{themeConfig.theme.name}}</label>

    <div class="mt-4 width-80">
        <mat-select class="ml-2" placeholder="Selecione a disciplina" [(ngModel)]="selectDiscipline">
            <mat-option *ngFor="let disciple of disciplines" [value]="disciple.id">
                {{disciple.name}}
            </mat-option>
        </mat-select>
    </div>
    <div class="mt-4">
        <p><input placeholder="Nome do tema" [(ngModel)]="themeName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
    </div>
    <div class="mt-2">
        <button (click)="updateTheme()" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
    </div>
</div>

<div class="minhadiv" *ngIf="themeConfig.edit && themeConfig.isSub">
    <label class="title ml-2">Editar Subtema: {{themeConfig.subTheme.name}}</label>
    <div class="mt-4 width-80">
        <mat-select class="ml-2" placeholder="Selecione a disciplina" [(ngModel)]="selectDiscipline">
            <mat-option *ngFor="let disciple of disciplines" [value]="disciple.id">
                {{disciple.name}}
            </mat-option>
        </mat-select>
    </div>
    <div class="mt-4 width-80">
        <mat-select class="ml-2" placeholder="Selecione o tema" [(ngModel)]="selectTheme">
            <mat-option *ngFor="let theme of themes" [value]="theme.id">
                {{theme.name}}
            </mat-option>
        </mat-select>
    </div>
    <div class="mt-4">
        <p><input placeholder="Nome do subtema" [(ngModel)]="subThemeName" class="center ml-2" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}"></p>
    </div>
    <div class="mt-2">
        <button (click)="updateSubTheme()" class="ml-2" [ngClass]="{'btnsalvar': !deviceService.isMobile() , 'btnsalvarMobile' : deviceService.isMobile()}">Salvar</button>
    </div>
</div>