import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';

@Injectable({providedIn: 'root'})
export class DashboardService {

    private API = environment.ApiUrl;
    public emitLoginIsDone = new EventEmitter<string>();
    constructor(private http: HttpClient) {}

    getWatchedVideosChartData(studentId: number,planId?: number) {
        return this.http.post(this.API + '/dashboard/getWatchedVideosChartData', {studentId,planId}, {observe: 'response'});
    }

    getExerciseChartDate(studentId: number) {
        return this.http.post(this.API + '/dashboard/getExerciseChartDate', {studentId}, {observe: 'response'});
    }

    getRedactionsChartData(studentId: number) {
        return this.http.post(this.API + '/dashboard/getRedactionsChartData', {studentId}, {observe: 'response'});
    }

    getAverageData(studentId: number) {
        return this.http.post(this.API + '/dashboard/getAverageData', {studentId}, {observe: 'response'});
    }
    getAverageDataByPlanPeriod(data: any) {
        return this.http.post(this.API + '/dashboard/getAverageDataPlanPeriod', data, {observe: 'response'});
    }
     getAverageDataByPlanPeriodAdm(data: any) {
        return this.http.post(this.API + '/dashboard/getAverageDataPlanPeriodAdmin', data, {observe: 'response'});
    }
    downloadAnnex(annexId: number) {
        return this.http.post(this.API + '/dashboard/downloadAnnex', { annexId } , {observe: 'response'});
    }
}
