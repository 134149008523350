<div class="page-container p-pg page-container-mobile">
    <div class="row m-0">
        <div class="col-12 mt-2">
            <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                <span class="page-title">Podcast</span>
                <div class="mt-3 description-title">
                    <h4 *ngIf="!layoutService.isMobile">Nota Máxima</h4>
                    <h6 *ngIf="layoutService.isMobile" class="mb-0">Nota Máxima</h6>
                    <p class="fs-13 pb-0">Ouça seus podcasts no Spotfy</p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="podcast-header pb-3 d-md-flex mb-5">
                <div class="podcast-card bg-color-accent mat-elevation-z8 mr-md-5" [ngClass]="{'m-auto': layoutService.isMobile}">
                    <mat-icon>podcasts</mat-icon>
                    <div class="img-overlay text-center mat-elevation-z6">
                        <img class="center" [src]="theme?.logo | safe" [alt]="theme?.name">
                    </div>
                </div>
                <div class="podcast-title-container" *ngIf="layoutService.isMobile">
                    <span class="page-title">Podcast</span>
                    <div class="mt-1 description-title">
                        <h2>{{theme?.name}}</h2>
                    </div>
                </div>
                <div class="podcast-title-container">
                    <div class="spotify-card" *ngIf="!layoutService.isMobile">
                        <small class="strong">OUÇA NO</small> <img src="/assets/images/spotify.png" alt="Spotify">
                    </div>
                </div>
                <div class="spotify-card m-auto" *ngIf="layoutService.isMobile">
                    <small class="strong">OUÇA NO</small> <img src="/assets/images/spotify.png" alt="Spotify">
                </div>
            </div>
            <div class="container">
                <h5 class="mb-3"  [ngClass]="{'text-center': layoutService.isMobile}"><span [ngClass]="{'font-weight-700':layoutService.isMobile}">Lista de </span>episódios</h5>
                <ng-container>
                    <div class="mt-3" *ngFor="let podcast of podcasts">
                        <div [innerHTML]="podcast?.link | safe:'true'"></div>
                    </div>
                    <div class="mt-3" *ngIf="!podcasts">
                        <div>Nenhum resultado!</div>
                    </div>
                </ng-container>
                <ng-template *ngIf="loading">
                    <mat-spinner class="center"></mat-spinner>
                </ng-template>
            </div>
        </div>
    </div>   
</div>