import { Component, OnInit } from '@angular/core';

import { UserService } from 'src/app/modules/user/user.service';
@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.scss']
})
export class MiscellaneousComponent implements OnInit {

  constructor(public userService: UserService) { }

  ngOnInit() {
  }

}
