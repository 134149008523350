<div class="container pt-5 pb-5" *ngIf="blockView">
    <mat-accordion>
        <mat-expansion-panel class="width-90 center">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Pagamentos</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <button (click)="openModalcreatePayment()" class="right bg-color-primary border-primary" [nbSpinner]="saving" [disabled]="saving"
                            nbSpinnerStatus="warning" nbButton size="small" status="primary">Criar pagamento</button>
                    </div>
                </div>
                <div class="row" *ngIf="userService.isAdmin()">
                    <div class="col-md-12">
                        <span *ngIf="!schoolId" class="text-small" style="color:red;">Selecione uma Escola para poder pesquisar por EMAIL OU NOME!</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" *ngIf="userService.isAdmin()">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-label>Escolas</mat-label>
                            <mat-select placeholder="Selecione uma escola" (selectionChange)="getPaymentPlans($event.value)" >
                                <mat-option *ngFor="let school of schools " [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <span *ngIf="!filterPlanId && schoolId" class="text-small" style="color:red;">Selecione um Plano para poder pesquisar por EMAIL OU NOME!</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-label>Planos / [Produto]</mat-label>
                            <mat-select placeholder="Plano" (selectionChange)="getPayers($event.value)"
                                [(ngModel)]="filterPlanId">
                                <mat-option *ngFor="let plan of paymentsPlans$ | async" [value]="plan.paymentPlanId">
                                    PLANO: {{plan.name}} /  [PRODUTO: {{plan.paymentName}}]
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="width-100">
                            <input [(ngModel)]="filterName" matInput placeholder="Buscar aluno por nome" [disabled]="!filterPlanId">
                            <button *ngIf="schoolId" (click)="getPaymentsByName(filterName)" class="ml-2" mat-icon-button
                                color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="width-100">
                            <input [(ngModel)]="filterEmail" matInput placeholder="Buscar aluno por e-mail" [disabled]="!filterPlanId">
                            <button  *ngIf="schoolId" (click)="getPaymentsByEmail(filterEmail)" class="ml-2"
                                mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>                    
                </div>  
                <div class="row mt-5">
                    <nb-list class="width-100 mt-2">
                        <nb-list-item *ngIf="payers$ && !(payers$ | async)">
                            <div class="row">
                                <div class="col-md-6"><strong>Aluno</strong></div>
                                <div class="col-md-3"><strong>Status</strong></div>
                                <div class="col-md-3"><strong>&nbsp;</strong></div>
                            </div>
                        </nb-list-item>
                        <!-- <nb-list-item *ngFor="let payer of payers$ | async" (opened)="getUserPayments(payer.id)"> -->
                        <nb-list-item *ngFor="let payer of payers$ | async">
                            <div class="row">
                                <div class="col-md-6">
                                    <span class="text-small">{{payer.name}} - {{payer.email}}</span>
                                </div>
                                <div class="col-md-3">
                                    <span class="text-small" *ngIf='payer.status == "paid"'>Pago</span>
                                    <span class="text-small" *ngIf='payer.status == "waiting_payment"'>Esperando pagamento</span>
                                    <span class="text-small" *ngIf='payer.status == "refunded"'>Reembolsado</span>
                                    <span class="text-small" *ngIf='payer.status == "refused"'>Recusado</span>
                                    <span class="text-small" *ngIf='payer.status == "pending"'>Pendente</span>
                                    <span class="text-small" *ngIf='payer.status == "processing"'>Processando</span>
                                    <!-- <span class="text-small" *ngIf='payer.status'>{{payer.status}}</span> -->
                                </div>
                                <div class="col-md-3">
                                    <button size="tiny"  *ngIf='payer.status != "paid"' status="success" (click)="releasePayment(payer)" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Liberar pagamento">
                                        Liberar pagamento
                                    </button>
                                    <button size="tiny"  *ngIf='payer.status == "paid"' (click)="blockPayment(payer)" nbButton ghost matTooltipClass="custom-tooltip" matTooltip="Bloquear pagamento">
                                        Bloquear pagamento
                                    </button> 
                                </div>
                            </div>
                        </nb-list-item>                        
                    </nb-list>
                    <mat-spinner *ngIf="payers$ && !(payers$ | async)" class="center mt-5 mb-3"></mat-spinner>
                    <!-- <div *ngFor="let payer of payers$ | async" (opened)="getUserPayments(payer.id)">            
                        <ng-template>
                            <nb-list *ngIf="(userPayments$ | async)">
                                <nb-list-item *ngFor="let payment of userPayments$ | async">
                                    <div class="row">
                                        <div class="col pt-2">{{payment.planName}}</div>
                                        <div class="col-2 pt-2">{{payment.status}}</div>
                                        <div class="col-2 pt-2 d-none d-lg-block d-xl-block">
                                            <ng-container *ngIf="payment.expire">
                                                {{payment.expire | date:'dd/MM/yyyy'}}
                                            </ng-container>
                                            <ng-container *ngIf="!payment.expire">
                                                ---------
                                            </ng-container>
                                        </div>
                                        <div class="col-2 text-center">
                                        <ng-container *ngIf="payment.cupomName">
                                            {{payment.cupomName + ' ' + payment.percentCupom + '%'}}
                                        </ng-container>
                                        <ng-container *ngIf="!payment.cupomName">
                                            ---------
                                        </ng-container>
                                        </div>
                                        <div class="col-1 text-right">
                                            <button *ngIf="!deleting" color="accent" matTooltipClass="custom-tooltip"
                                                (click)="editPayment(payment)" mat-icon-button
                                                matTooltip="Editar pagamento">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </div>
                                        <div class="col-1 text-right">
                                            <button *ngIf="!deleting" color="warn" matTooltipClass="custom-tooltip"
                                                (click)="removePayment(payment)" mat-icon-button
                                                matTooltip="Remover pagamento">
                                                <mat-icon>delete_forever</mat-icon>
                                            </button>
                                            <mat-spinner *ngIf="deleting" diameter="25" class="right mt-2">
                                            </mat-spinner>
                                        </div>
                                    </div>
                                </nb-list-item>
                                <nb-list-item>
                                    <div class="row">
                                        <div class="col pt-2">
                                            <b><i>Adicionar pagamento</i></b>
                                        </div>
                                        <div class="col-2 text-right">
                                            <button class="right hvr-grow-out" color="primary" mat-icon-button
                                                (click)="addStudentPayment(payer.id)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </nb-list-item>
                            </nb-list>
                            <mat-spinner *ngIf="!(userPayments$ | async)" class="center mt-2" diameter="30">
                            </mat-spinner>
                        </ng-template>
                    </div> -->
                </div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>