import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Notifications } from 'src/app/modules/admin/notifications/notifications';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-modal-central',
  templateUrl: './modal-central.component.html',
  styleUrls: ['./modal-central.component.scss']
})
export class ModalCentralComponent implements OnInit {

  public read: boolean;
  public limited: boolean;

  constructor( 
    @Optional() @Inject(MAT_DIALOG_DATA) public notification: Notifications,
    @Optional() public dialogRef: MatDialogRef<ModalCentralComponent>,
    private dialog: MatDialog,
    public notificationsService: NotificationsService,
    public userService: UserService,
    public layoutService: LayoutService,
    private alertService: AlertService,
    public deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
      if(this.notification.video){
        this.notification.linkSanityzed = this.sanitizer.bypassSecurityTrustResourceUrl(this.notification.video);
      }
  }
  
  markAsRead(notification: Notifications){
    const userId = Number(this.userService.getUserId());
    this.read = this.read ? false : true;
    this.notificationsService.markAsRead(notification, userId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      console.log(response);
      if (!response.error) {
        setTimeout(() => {
          this.dialogRef.close();
        }, 5000); 
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao salvar a notificação. Verifique a conexão e tente novamente'));
  }

  removeNotification(notification: Notifications){
    const userId = Number(this.userService.getUserId());
    this.read = this.read ? false : true;
    this.notificationsService.markAsRead(notification, userId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      console.log(response);
      if (!response.error) {
        setTimeout(() => {
          this.closeDialog();
        }, 1000); 
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao salvar a notificação. Verifique a conexão e tente novamente'));
  }

  closeDialog(){
    this.dialogRef.close();
  }
}