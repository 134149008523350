import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Exercise } from './exercise';

@Injectable({ providedIn: 'root' })
export class ExerciseService {

    private API = environment.ApiUrl;

    constructor(private http: HttpClient) { }

    getExercises() {
        return this.http.post(this.API + '/exercise/getExercises', {}, { observe: 'response' });
    }

    removeExercise(exerciseId: number) {
        return this.http.post(this.API + '/exercise/removeExercise', { exerciseId }, { observe: 'response' });
    }

    createExercise(exercise: Exercise) {
        return this.http.post(this.API + '/exercise/createExercise', exercise, { observe: 'response' });
    }

    getExerciseVideos(exerciseId: number) {
        return this.http.post(this.API + '/exercise/getExerciseVideos', { exerciseId }, { observe: 'response' });
    }

    saveExerciseChanges(exercise: Exercise) {
        return this.http.post(this.API + '/exercise/saveExerciseChanges', exercise, { observe: 'response' });
    }

    getExerciseCorrectAlternative(exerciseId: number) {
        return this.http.post(this.API + '/video/getExerciseCorrectAlternative', { exerciseId }, { observe: 'response' });
    }

    getExerciseCorrectAlternativeNew(exerciseId: number[]) {
        return this.http.post(this.API + '/video/getExerciseCorrectAlternativeNew', { exerciseId }, { observe: 'response' });
    }

    getExercisesByName(name: string) {
        return this.http.post(this.API + '/exercise/getExercisesByName', { name }, { observe: 'response' });
    }
    
    getExercisesByTag(tag: string) {
        return this.http.post(this.API + '/exercise/getExercisesByTag', { tag }, { observe: 'response' });
    }
    

    getExercisesByNameAndSchool(name: string , schoolId : number) {
        return this.http.post(this.API + '/exercise/getExercisesByNameAndSchool', { name , schoolId }, { observe: 'response' });
    }

    getExercisesByNameSummation(name: string) {
        return this.http.post(this.API + '/exercise/getExercisesByNameSummation', { name }, { observe: 'response' });
    }

    getExercisesByNameSummationAndSchool(name: string , schoolId: number) {
        return this.http.post(this.API + '/exercise/getExercisesByNameSummationAndSchool', { name , schoolId }, { observe: 'response' });
    }

    getExerciseDisciplines(exerciseId: number) {
        return this.http.post(this.API + '/exercise/getExerciseDisciplines', { exerciseId }, { observe: 'response' });
    }

    getExercisesByDiscipline(disciplineId: number) {
        return this.http.post(this.API + '/exercise/getExercisesByDiscipline', { disciplineId }, { observe: 'response' });
    }
    getExercisesByDisciplineForSelect(disciplineId: number) {
        return this.http.post(this.API + '/exercise/getExercisesByDisciplineForSelect', { disciplineId }, { observe: 'response' });
    }
}
