import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Console } from 'console';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { FileRepository } from 'src/app/modules/repository/file/file-repository';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { UserService } from 'src/app/modules/user/user.service';
import { SelectGroup } from 'src/app/shared/interfaces/select-group';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { Tuple } from 'src/app/shared/types/tuple';

import { AdminService } from '../../../admin.service';
import { disciplineTag } from '../../../discipline-tag/discipline-tag-create/discipline-tag';
import { Discipline } from '../../../discipline/discipline';
import { DisciplineService } from '../../../discipline/discipline.service';
import { Class } from '../../../school/class';
import { School } from '../../../school/school';
import { SchoolService } from '../../../school/school.service';
import { Unit } from '../../../school/unit';
import { StudyPlan } from '../../../study-plan/study-plan';
import { StudyPlanService } from '../../../study-plan/study-plan.service';
import { Folder } from '../../folder/folder';

interface filesArrayModel {
  id?: number;
  name: string;
  folder: [];
  file: "";
  tag?: string;
  extension: string;
}

@Component({
  selector: 'app-repository-file-add',
  templateUrl: './repository-file-add.component.html',
  styleUrls: ['./repository-file-add.component.scss']
})
export class RepositoryFileAddComponent implements OnInit, OnDestroy {

  @ViewChild('fileDropRef') fileDropRef: ElementRef;
  public fileForm: FormGroup;
  public fileFormInsert: FormGroup;
  public filesArray: any = [];
  public originalFilesArray: any[] = [];
  public folders: Folder[];
  public showList: boolean;
  public files: FileRepository[];
  public file: any;
  public fileParsed: any[] = [];
  public extension: string;
  public saving: boolean;
  public filterFolder: number;
  public loading: boolean;
  public folderFilter = new FormControl();
  public filteredFolders: BehaviorSubject<Folder[]> = new BehaviorSubject<Folder[]>(null);
  public filteringFolders: boolean;
  public selectedFolders: Folder[] = [];
  public editMode: boolean;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public teacherSelectGroup: Tuple<SelectGroup<Folder>>;
  public isUnitCord : boolean;
  public empty: boolean;
  public nothingFile: boolean = true;
  public getFileName : string;
  public schoolsforTag: School[];
  public disciplinesTags: disciplineTag[];
  public selectedDisciplinesTags: disciplineTag[];
  public plansTag: StudyPlan[];
  public disciplinesTagsTags: Discipline[];

  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();


  constructor(

    @Optional() @Inject(MAT_DIALOG_DATA) public fileEdit: FileRepository,
    @Optional() public dialogRef: MatDialogRef<RepositoryFileAddComponent>,
    public userService: UserService,
    private dialog: MatDialog,
    private repositoryService: RepositoryService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private schoolService: SchoolService,
    private adminService: AdminService,
    public layoutService : LayoutService,
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService) {

    }

  ngOnInit(): void {
    this.disciplinesTags = [];
    this.selectedDisciplinesTags = [];
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());

    this.folderFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterFolders();
      });
      //this.fileDropRef.nativeElement.textContent = "A"

      if(!this.userService.isAdmin()){
        this.getDisciplinesTagsBySchool(this.userService.getUserSchool());
      }

      if(!this.userService.isAdmin()){
        this.getPlansBySchoolTag(this.userService.getUserSchool())
      }
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();

  }



  init() {
    this.teacherSelectGroup = [
      { name: 'Pastas do professor', options: [] },
      { name: 'Pastas da turma', options: [] }
    ];

    this.folderFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterFolders();
      });

    this.profileInit();
    this.fileForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      folder: ['', Validators.required],
      file: ['', Validators.required],
      tag: [''],
      extension: [''],
      disciplinesTag:[''],
      schoolOnly:[false]
    });

    this.fileFormInsert = this.formBuilder.group({
      folder: ['', Validators.required],
      file: ['', Validators.required],
      schoolOnly:[false],
      disciplinesTag:['']
    });


    if (this.fileEdit) { this.initializeEditMode() }
  }

  profileInit() {
    this.isUnitCord = false;

    if (this.userService.isAdmin()) {
      this.getSchools();

    } else if (this.userService.isGeneralCoordinator()) {
      this.getUnits(this.userService.getUserSchool());
      this.getFolders(this.userService.getUserSchool());

    } else if (this.userService.isUnitCoordinator()) {
      this.isUnitCord = true;
      this.getClasses(this.userService.getUserUnit());

    } else if (this.userService.isTeacher()) {
      this.getTeacherFolders(this.userService.getUserId());
      this.getTeacherClasses(this.userService.getUserId());

      // if (this.userService.teacherHavePermission(4)) {
      //   this.getClasses(this.userService.getUserUnit());
      //   //this.getUnits(this.userService.getUserSchool());

      // } else if (this.userService.teacherHavePermission(3)) {
      //   this.getClasses(this.userService.getUserUnit());
      // }
    }
  }

  initializeEditMode() {
    this.fileForm.get('file').clearValidators();
    this.fileForm.get('file').updateValueAndValidity();

    this.editMode = true;
    this.fileForm.patchValue(this.fileEdit);
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
          this.schoolsforTag = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId: number) {
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  getFolders(schoolId: number) {
    this.repositoryService.getFolders(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.folders = response.data as Folder[];

          this.filteredFolders.next(this.folders.slice());

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
  }

  getClassFolders(classId: number) {
    this.repositoryService.getClassFolders(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.folders = response.data as Folder[];
          this.folders = this.folders.map(f => {
            f.id = Number(f.id);
            return f;
          })

          this.teacherSelectGroup[1].options = [...this.folders];
          this.filteredFolders.next(this.folders.slice());
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
  }

  getTeacherFolders(teacherId: number) {
    this.repositoryService.getAssociatedTeacherFolders(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.folders = response.data as Folder[];
          if (this.userService.teacherHavePermission(3)) {
            this.teacherSelectGroup[0].options = response.data as Folder[];
          } else {
            this.folders = response.data as Folder[];
            this.filteredFolders.next([...this.folders]);
          }

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
  }

  getFolderFiles(folderId: number) {
    this.loading = true;
    this.repositoryService.getFolderFiles(folderId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.files = response.data as FileRepository[];
          this.empty = false;
          if(response.data == ''){
            this.empty = true;
          }

        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os arquivos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  loadFilesToFilesArray() {


    this.filesArray.forEach(file => {
      this.fileParsed.forEach(fileParsed => {
        if (file.id == fileParsed.id) {
          file.file = fileParsed.file;
        }
      });
      file.folder = this.getFolderSIds();
    });
  }

  updateNameFilesArray(fileId: number, newName: string, newTag: string) {
    if (this.filesArray.length > 0) {
      // percorre filesArray
      this.filesArray.forEach(file => {
        if (file.id == fileId) {
          file.name = newName;
          file.tag = newTag;
        }
      });
    }
    if (this.fileParsed.length > 0) {
      this.fileParsed.forEach(file => {
        if (file.id == fileId) {
          file.name = newName;
          file.tag = newTag;
        }
      });
    }
  }
  handleFileInputUpdate(files: FileList): void {
    if (files && files[0]) {
      this.originalFilesArray = [files];
      this.nothingFile = false;
      const file = files[0];
      this.extension = file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.file = reader.result;
      };
    }
  }
  // bloquear caso a pessoa tente colocar mais de 5 arquivos
  handleFileInput(files: FileList) {

    if (files && files.length < 6 && this.fileParsed.length < 6) {
      this.originalFilesArray.push(files);
      for (let index = 0; index < files.length; index++) {
        var cont = index;
        const file = files[index];
        let name = file.name.split('.');

        var fileX = {
          extension: file.name.split('.').pop(),
          id: this.filesArray.length + 1,
          name: name[0],
          folder: "",
          file: [],
          tag: ""
        };
        this.filesArray.push(fileX);
        this.readFile(file, fileX.id); //1s

      }

    } else if (files.length > 5) {
      this.alertService.error('Você pode subir no máximo 5 arquivos!');
      this.clearfilesArray();
    } else {
      this.alertService.error('Você precisa selecionar ao menos um arquivo!');
      this.clearfilesArray();
    }
  }

  readFile(file: any, id: number) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.fileParsed.push({ id: id, file: reader.result });
    };
  }

  getFiles(schoolId: number) {
    this.loading = true;
    this.repositoryService.getFiles(schoolId)
      .subscribe(res => {

        const response = res.body as ResponseApi;

        if (!response.error) {
          this.files = response.data as FileRepository[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os arquivos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  openList() {
    // this.getFiles(this.userService.getUserSchool());
    this.showList = true;
  }

  getFolderSIds() {
    let arr = [];
    if (this.selectedFolders.length > 0) {
      this.selectedFolders.forEach(element => {
        arr.push(element.id);
      });
    } else {
      this.alertService.error('Selecione uma pasta para enviar!');
    }

    return arr;
  }

  checkEmpty() {

    let success = true;
    //Verifica o array caso esteja vazio retorna false
    if (this.filesArray.length == 0) {

      this.alertService.error('Selecione uma pasta e um arquivo para enviar!');

      success = false;

    } else {
      //Percorre o array para buscar folder ou file vazio

      this.filesArray.forEach(element => {
        //Testa se o campo folder está vazio/ caso positivo retorna false
        if (element.folder.length == 0) {

          this.alertService.error('Selecione uma pasta!');
          success = false;

          //Testa se o campo file está vazio/ se positivo retorna false
        } else if (element.file == "") {

          this.alertService.error('Selecione um arquivo para enviar!');

          success = false;
        }

      });
    }

    // caso nenhum erro seja encontrado retorna true
    return success;

  }

  add() {

    // Atualiza o filesArray
    // com os arquivos upados e carregados no fileParsed
    this.loadFilesToFilesArray();


    // verifica os campos do array antes de enviar para a api
    if (this.checkEmpty()) {

      this.saving = true;

      this.filesArray.forEach(element => {

        const file = element as FileRepository; 
        this.repositoryService.addFile(file , this.selectedDisciplinesTags)
          .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              this.alertService.success('Arquivo adicionado', 3);
              this.fileForm.reset();// update
              this.fileFormInsert.reset();// Create
              this.schools = [];
              this.clearfilesArray(); // Limpa os arrays do upload
              this.adminService.updateChields();// Serviço para atualizar os selects das janelas
              this.selectedDisciplinesTags = [];
            } else {
              this.alertService.error(response.error);
            }

            this.saving = false;

          }, err => {
            this.alertService.error('Houve um erro ao adicionar o arquivo. Verifique a conexão e tente novamente');
            this.saving = false;
          });
      });
    }
  }

  remove(fileId: number) {
    console.log('fileId ', fileId, 'folderId ', this.filterFolder);
    if (confirm('Deseja realmente remover o arquivo?')) {
      this.repositoryService.removeFile(Number(fileId), this.filterFolder)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Arquivo removido!', 3);
            this.getFolderFiles(this.filterFolder);
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o arquivo. Verifique a conexão e tente novamente'));
    }
  }

  removeFileArray(fileId: number) {

    if (confirm('Deseja realmente remover o arquivo?')) {

      for (let index = 0; index < this.filesArray.length; index++) {
        const element = this.filesArray[index];

        if (element.id == fileId) {
          this.filesArray.splice(index, 1);
        }

      }
      for (let index = 0; index < this.fileParsed.length; index++) {
        const element = this.fileParsed[index];

        if (element.id == fileId) {
          this.fileParsed.splice(index, 1);
        }

      }

    }

  }

  clearfilesArray() {
    this.filesArray = [];
    this.fileParsed = [];
    this.selectedFolders = [];
    this.schools = [];
    this.units = [];
    this.classes = [];
  }

  removeFolderChip(folder: Folder) {

    const index = this.selectedFolders.findIndex(t => t.id == folder.id);

    if (index >= 0) {
      this.selectedFolders.splice(index, 1);
      this.fileFormInsert.get('folder').setValue([...this.selectedFolders.map(f => f.id)]);
    }
  }

  folderSelectionChange(selectedFolderId: number[]) {

    if (selectedFolderId.length === 0 ) {
      this.selectedFolders = [];
    } else {

      this.selectedFolders = [];

      selectedFolderId.forEach(folderId => {
        for (const folder of this.folders) {
          if (folder.id == folderId) {

              this.selectedFolders.push(folder);

            }
          }
      });
    }

    let chipsToRemove = this.folders.filter( sf => !this.selectedFolders.includes(sf) );

    if(chipsToRemove.length > 0) {
      chipsToRemove.forEach( (folder) => this.removeFolderChip(folder) );
    };

  }

  edit(file: FileRepository) {
    const dialogRef = this.dialog.open(RepositoryFileAddComponent, {
      minWidth: '60vw',
      data: file
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getFolderFiles(this.filterFolder);
      }
    });
  }

  protected filterFolders() {
    if (!this.folders) {
      return;
    }
    let search = this.folderFilter.value;
    if (!search) {
      this.filteredFolders.next(this.folders.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteringFolders = true;
    // filter folders
    this.filteredFolders.next(
      this.folders.filter(folder => folder?.name?.toLowerCase().indexOf(search) > -1)
    );

    this.filteringFolders = false;
  }

  preview(fileLink: string) {
    window.open(fileLink, '_blank');
  }

  saveChanges() {
    this.fileForm.get('file').setValue(this.file);
    this.fileForm.get('tag').setValue("vazio");
    this.fileForm.get('extension').setValue(this.extension);

    if (this.fileForm.valid) {
      this.saving = true;
      const file = this.fileForm.getRawValue() as FileRepository;

      this.repositoryService.saveFileChanges(file)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Arquivo Alterado', 3);
            this.dialogRef.close(true);
            this.fileForm.reset();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.saving = false;

        }, err => {
          this.alertService.error('Houve um erro ao editar o arquivo. Verifique a conexão e tente novamente');
          this.saving = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente');
    }
  }

  getFileByNameFilter(fileName: string){
    if(this.userService.isAdmin()){
      this.getShareFileByName(fileName);
    }
    else{
      this.getShareFileByNameCoord(fileName);
    }
  }

  getShareFileByName(fileName: string){
    this.repositoryService.getShareFileByName(fileName)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.files = response.data as FileRepository[];
      }
      else{
        this.alertService.error(response.error);
      }
    },err => {
      this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
    })
  }

  getShareFileByNameCoord(fileName: string){
    this.repositoryService.getShareFileByNameCoord(fileName , this.userService.getUserSchool())
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.files = response.data as FileRepository[];
      }
      else{
        this.alertService.error(response.error);
      }
    },err => {
      this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
    })
  }

  getDisciplinesTagsBySchool(idSchool: number){
    this.disciplineService.getDisciplinesTagsBySchool(idSchool)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.disciplinesTags = response.data as disciplineTag[];
      }
      else{
        console.log(response.error);
        //this.alertService.error(response.error);
      }
    })
  }

  addDisciplineTagChip(tag: disciplineTag) {
    if(this.selectedDisciplinesTags.length <= 2){
    const index = this.selectedDisciplinesTags.indexOf(tag);

    if (index < 0) {
      this.selectedDisciplinesTags.push(tag);
      this.fileForm.get('disciplinesTag').reset();
    }
  }
  else{
    this.alertService.error('Um vídeo pode ter no maximo 3 tags!');
  }
  }

  removeDisciplineTagChip(tag: disciplineTag) {
    const index = this.selectedDisciplinesTags.indexOf(tag);

    if (index >= 0) {
      this.selectedDisciplinesTags.splice(index, 1);
    }
  }

  selectScholl(schoolId : number){
    if(!this.fileFormInsert.get('schoolOnly').value){
      this.getPlansBySchoolTag(schoolId);
    }
    else{
      this.getDisciplinesTagsBySchool(schoolId);
    }
  }

  getPlansBySchoolTag(schoolId : number){
    this.studyPlanService.getSelectPlans(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plansTag = response.data as StudyPlan[];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error("Não foi possivel buscar os dados verifique a conexão e tente novamente!");
    })
  }

  getDisciplinesByPlan(planId: number){
    this.studyPlanService.getPlanDisciplines(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.disciplinesTagsTags = response.data as Discipline[];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!');
    })
  }

  getDisciplinesTagsByDiscipline( disciplineId : number){
    this.disciplineService.getDisciplinesTagsByDiscipline(disciplineId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.disciplinesTags = response.data as disciplineTag[];
      }
      else{
        this.alertService.error('Não existem temas de disciplinas nessa Disciplina!');
      }
    })
  }

  checktoogleTag(evt: boolean){

    if(evt && !this.userService.isAdmin()){
      this.getDisciplinesTagsBySchool(this.userService.getUserSchool());
    }
  }

  getAssociatedTeacherClassFolders(teacherId, classId){
    this.repositoryService.getAssociatedTeacherClassFolders(teacherId, classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.folders = response.data as Folder[];
          //console.log(this.folders)
          if (this.userService.teacherHavePermission(3)) {
            console.log("havePermission")
            this.teacherSelectGroup[0].options = response.data as Folder[];
          } else {
            console.log("Don't havePermission")

            this.folders = response.data as Folder[];
            this.filteredFolders.next([...this.folders]);
          }

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
  }

  selectFolders(classId){
    if(this.userService.isTeacher()) {
      if(classId == 0) {
        console.log("todas")
        this.getTeacherFolders(this.userService.getUserId())
      } else {
        this.getAssociatedTeacherClassFolders(this.userService.getUserId(), classId)
        //pegar id da classe + id do professor e trazer as pastas
      }
    } else {
      this.getClassFolders(classId)
    }
  }

}
