import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ReportService {

    private API = environment.ApiUrl;

    constructor(private http: HttpClient) { }
     
    getStudentReportPerformance(studentId: number, classId: number, planId?: number,date_begin?: string,date_end?: string) {
        return this.http.post(this.API + '/report/studentPerformance', { studentId, classId, planId, date_begin, date_end  }, { observe: 'response' });
    }
    getRelatoriosCSV(userData: any) {
        return this.http.post(this.API + '/report/reportCsvNovo', userData, { observe: 'response' });
    }
    getTiposRelatorios() {
        var userData: any;
        return this.http.post(this.API + '/report/reports',  userData,  { observe: 'response' });
    }
    getAverageDataByPlanPeriod(data: any) {
        return this.http.post(this.API + '/dashboard/getAverageDataPlanPeriodAdmin', data, {observe: 'response'});
    }
}
