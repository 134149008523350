import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../user/user.service';
import { Video } from '../../video/video';
import { VideoService } from '../../video/video.service';
import { Discipline } from '../discipline/discipline';
import { DisciplineService } from '../discipline/discipline.service';
import { School } from '../school/school';
import { SchoolService } from '../school/school.service';
import { StudyPlan } from '../study-plan/study-plan';
import { StudyPlanService } from '../study-plan/study-plan.service';
import { Iframe } from './iframe';

@Component({
  selector: 'app-video-iframe',
  templateUrl: './video-iframe.component.html',
  styleUrls: ['./video-iframe.component.scss']
})
export class VideoIframeComponent implements OnInit {

  public schools: School[];
  public createIframeForm: FormGroup;
  public plans: StudyPlan[];
  public videos: Video[];
  public iframe: Iframe;
  public iframes: Iframe[];
  public showList: boolean;
  public editMode: boolean;
  public nameIframe: string;
  public disciplines: Discipline[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public iframeEdit: Iframe,
    @Optional() public dialogRef: MatDialogRef<VideoIframeComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private studyPlanService: StudyPlanService,
    private videoService: VideoService,
    private disciplineService: DisciplineService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.editMode = false;
    this.showList = false;
    this.createIframeForm = this.formBuilder.group({
      schoolId: [],
      id: [''],
      planId: [, Validators.required],
      videoId: [, Validators.required],
      nome: ['', Validators.required],
      iframe: ['', Validators.required],
      disciplineId: []
    })
    this.getSchools();

    if (this.iframeEdit) {
      this.editMode = true;
      if (!this.userService.isAdmin()) {
        this.getPlans(this.userService.getUserSchool());
        this.getDisciplines(this.iframeEdit.planId);
        this.getDisciplineVideos(this.iframeEdit.disciplineId);

        this.createIframeForm.patchValue(this.iframeEdit);

        let aux = (Number)(this.iframeEdit.disciplineId);
        this.createIframeForm.get('disciplineId').setValue(aux);
        this.createIframeForm.get('planId').setValue((Number)(this.iframeEdit.planId));
      }
      else {
        this.getPlans(this.iframeEdit.schoolId);
        this.getDisciplines(this.iframeEdit.planId);
        this.getDisciplineVideos(this.iframeEdit.disciplineId);
        this.createIframeForm.patchValue(this.iframeEdit);

        let aux = (Number)(this.iframeEdit.disciplineId);
        this.createIframeForm.get('disciplineId').setValue(aux);
        this.createIframeForm.get('schoolId').setValue((Number)(this.iframeEdit.schoolId));
        this.createIframeForm.get('planId').setValue((Number)(this.iframeEdit.planId));
      }
    }

    if (!this.userService.isAdmin()) {
      this.getPlans(this.userService.getUserSchool());
    }
  }

  getSchools(id?: number) {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  getPlans(schoolId?: number) {
    this.studyPlanService.getPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
      });
  }

  getVideos(planId: number) {
    this.videoService.getVideosByPlan(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getDisciplineVideos(disciplineId: number) {
    this.disciplineService.getDisciplineVideos(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  createIframe() {

    if (this.createIframeForm.valid) {
      this.iframe = this.createIframeForm.getRawValue() as Iframe;

      if(!this.userService.isAdmin()){
        this.iframe.schoolId = this.userService.getUserSchool();
      }

      this.videoService.createIframe(this.iframe)
        .subscribe(res => {
          const response = res.body as ResponseApi

          if (!response.error) {
            this.alertService.success('Iframe cadastrado com sucesso!');
            this.createIframeForm.reset();
          }
          else {
            this.alertService.error(response.error);
          }
        })
    }

    else {
      this.alertService.error('você precisa prencher todos os campos e selecionar plano e vídeo!');
    }
  }

  openList() {
    this.showList = true;
    if(this.userService.isAdmin()){
      this.getIframes();
    }
    else{
      this.getIframesBySchool(this.userService.getUserSchool());
    }
  }

  getIframes() {
    this.videoService.getIframes()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.iframes = response.data as Iframe[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  getIframesBySchool(schoolId : number) {
    this.videoService.getIframesBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.iframes = response.data as Iframe[];
        }
        else {
          this.alertService.error(response.error);
        }
      })
  }

  backButton() {
    this.showList = false;
    this.editMode = false;
  }

  deleteiframe(planId: number, videoId: number) {

    if (confirm('Deseja excluir este iframe')) {
      this.videoService.deleteIframe(planId, videoId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Iframe deletado com sucesso!');

            if(this.userService.isAdmin()){
              this.getIframes();
            }
            else{
              this.getIframesBySchool(this.userService.getUserSchool());
            }
          }
          else {
            this.alertService.error(response.error);
          }
        })
    }
  }

  editIframe(i: Iframe) {
    //console.log(i);
    const dialogRef = this.dialog.open(VideoIframeComponent, {
      data: i
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        if(this.userService.isAdmin()){
          this.getIframes();
        }
        else{
          this.getIframesBySchool(this.userService.getUserSchool());
        }
      }
    });
  }

  updateIframe() {
    if (this.createIframeForm.valid) {
      const auxPlanId = this.iframeEdit.planId;
      this.iframe = this.createIframeForm.getRawValue() as Iframe;

      if(!this.userService.isAdmin()){
        this.iframe.schoolId = this.userService.getUserSchool();
      }

      if (!this.iframe.planId) {
        this.iframe.planId = auxPlanId;
      }

      this.videoService.updateIframe(this.iframe)
        .subscribe(res => {
          const response = res.body as ResponseApi

          if (!response.error) {
            this.alertService.success('Iframe atualizado com sucesso!');
            this.dialogRef.close(true);
          }
          else {
            this.alertService.error(response.error);
          }
        })
    }
    else {
      this.alertService.error('você precisa prencher todos os campos e selecionar plano e vídeo!')
    }
  }

  checkGetName(name: string){
    if(this.userService.isAdmin()){
      this.getIframeByName(name);
    }
    else{
      this.getIframeByNameAndSchool(name);
    }
  }

  getIframeByName(name: string) {

    if (this.nameIframe) {
      this.videoService.getIframeByName(name)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.iframes = response.data as Iframe[];
          }
          else {
            this.alertService.error('Não existem iframes cadastrados com esse nome!');
          }
        })
    }
    else {
      this.getIframes();
    }
  }

  getIframeByNameAndSchool(name: string) {

    if (this.nameIframe) {
      this.videoService.getIframeByNameAndSchool(name , this.userService.getUserSchool())
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.iframes = response.data as Iframe[];
          }
          else {
            this.alertService.error('Não existem iframes cadastrados com esse nome!');
          }
        })
    }
    else {
      this.getIframesBySchool(this.userService.getUserSchool());
    }
  }

  getDisciplines(planId: number) {
    this.studyPlanService.getPlanDisciplines(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
      });
  }

}
