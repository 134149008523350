<div *ngIf="!showList">
    <form [formGroup]="createModalForm">
        <div class="row">
            <mat-form-field class="col-md-4  witdh-100">
                <mat-select placeholder="Escolas" formControlName="schoolId">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="col-md-4">
                <mat-slide-toggle formControlName="buttonLinkNewTab" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Define se o link do botão será aberto em uma nova aba">
                        help
                    </mat-icon>
                    Nova aba
                </mat-slide-toggle>
            </div>
            <div class="col-md-4">
                <mat-slide-toggle formControlName="dismissAuto" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="O modal so será aberto a primeira vez que o usuario acessar!">
                        help
                    </mat-icon>
                    Modal abrir uma unica vez
                </mat-slide-toggle>
            </div>
        </div>
        <div class="row">
            <mat-form-field class="col-md-6  witdh-100">
                <input formControlName="small_desc" matInput placeholder="Titulo menor(descrição)">
            </mat-form-field>
            <mat-form-field class="col-md-6 witdh-100">
                <input formControlName="title" matInput placeholder="Titulo">
            </mat-form-field>
        </div>
        <div class="row mt-2">
            <div class="col-md-6 radio-group-container">
                <mat-radio-group aria-label="Selecione o servidor do video" formControlName="isVimeo">
                    <mat-radio-button class="radio-item button-vimeo" value="1" class="mr-4" matTooltipClass="custom-tooltip" matTooltip="Vimeo">
                        <mat-icon svgIcon="vimeo"></mat-icon>
                    </mat-radio-button>
                    <mat-radio-button class="radio-item button-youtube" value="0" class="mr-1" matTooltipClass="custom-tooltip" matTooltip="YouTube">
                        <mat-icon svgIcon="youtube"></mat-icon>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-form-field class="col-md-6 witdh-100">
                <input formControlName="iframeLink" matInput placeholder="Link do vídeo">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="witdh-100 col-md-4">
                <input formControlName="buttonName" matInput placeholder="Nome do botão(opcional)">
            </mat-form-field>
            <mat-form-field class="witdh-100 col-md-4">
                <input formControlName="buttonLink" matInput placeholder="Link de acesso">
            </mat-form-field>
            <mat-form-field class="witdh-100 col-md-4">
                <input formControlName="dismissName" matInput placeholder="Nome do botão de saida(opcional)">
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-slide-toggle formControlName="active" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Define se o modal fica ativo para escola, cada escola só pode ter um modal ativo!">
                        help
                    </mat-icon>
                    Ativo
                </mat-slide-toggle>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <label>Selecione a cor do modal</label>
                <input formControlName="colorModal" matInput placeholder="cor do modal" type="color">
            </div>
            <div class="col-md-6">
                <label>Selecione a cor dos Textos</label>
                <input formControlName="colorText" matInput placeholder="cor do modal" type="color">
            </div>
        </div>

        <div class="row">
            <div *ngIf="createModalForm.get('notification').value != 1" class="col-md-4 mt-2">
                <mat-slide-toggle formControlName="openAfterLogin" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Diz se o modal será aberto antes ou depois do usuario entrar">
                        help
                    </mat-icon>
                    Abrir após logado
                </mat-slide-toggle>
            </div>
            <div class="col-md-4 mt-2">
                <mat-slide-toggle formControlName="notification" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="O modal se comporta como uma Notificação Sendo aberto toda vez que o Aluno logar na plataforma!">
                        help
                    </mat-icon>
                    Notificação
                </mat-slide-toggle>
            </div>
        </div>
    </form>
</div>

<div *ngIf="!editMode && !showList" class="row mt-5">
    <div class=" col-6 width-100">
        <button nbButton size="small" outline status="primary" (click)="openList()" class="text-color-primary border-primary-thin">Ver Modals cadastrados</button>
    </div>
    <div class=" col-6 text-right width-100" *ngIf="!live">
        <button (click)="createModal()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
    </div>
</div>

<div *ngIf="editMode && !showList" class="row mt-5">
    <div class="col-md-12 text-right width-100" *ngIf="!live">
        <button (click)="updateModal()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Atualizar</button>
    </div>
</div>

<div *ngIf="showList">
    <div class="row">
        <mat-form-field class="col-md-12  witdh-100">
            <mat-select placeholder="Escolas" (selectionChange)="getModalsBySchool($event.value)" [(ngModel)]="selectedSchool">
                <mat-option *ngFor="let school of schools" [value]="school.id">
                    {{school.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <nb-list class="width-100 mt-2">
        <nb-list-item *ngFor="let modal of modals">
            <div class="row">
                <div class="col-md-9">
                    {{modal.title}}
                </div>
                <div class="col-md-1 text-right ">
                    <button nbButton size="tiny" outline status="primary" (click)="openEditMode(modal)">Editar</button>
                </div>
                <div class="col-1 ml-4 text-right">
                    <button size="tiny" status="danger" nbButton ghost>
                        <nb-icon icon="trash" (click)="deleteModal(modal.id_modal)"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>

    <div class="row">
        <div class=" col-6 width-100">
            <button nbButton size="small" outline status="primary" (click)="backBtn()">Voltar</button>
        </div>
    </div>
</div>
