import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../admin.service';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { School } from '../school';
import { SchoolService } from '../school.service';
import { Unit } from '../unit';

@Component({
  selector: 'app-create-unit',
  templateUrl: './create-unit.component.html'
})
export class CreateUnitComponent implements OnInit, OnDestroy {

  public createUnitForm: FormGroup;
  public showUnitsList: boolean;
  public schools: School[];
  public units: Unit[];
  public editMode: boolean;
  public loading: boolean;
  public filterSchools: School[];
  public filterSchoolId: number;
  public disciplines: Discipline[];
  public selectedDisciplines: Discipline[];
  public disciplinesModel: number[];
  public disciplineFilter = new FormControl();
  public filteredDisciplines: BehaviorSubject<Discipline[]> = new BehaviorSubject<Discipline[]>(null);
  public plans: StudyPlan[];
  public selectedPlans: StudyPlan[];
  public plansModel: number[];
  public planFilter = new FormControl();
  public filteredPlans: BehaviorSubject<StudyPlan[]> = new BehaviorSubject<StudyPlan[]>(null);
  public filtering: boolean;
  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public unit: Unit,
    @Optional() public dialogRef: MatDialogRef<CreateUnitComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formbBuilder: FormBuilder,
    private adminService: AdminService,
    private studyPlanService: StudyPlanService,
    private disciplineService: DisciplineService,
    public userService: UserService) { }

  ngOnInit(): void {
    this.selectedDisciplines = [];
    this.selectedPlans = [];
    this.disciplineFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterDisciplines();
      });

    this.planFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterPlans();
      });
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  init() {
    this.createUnitForm = this.formbBuilder.group({
      id: [],
      name: ['', Validators.required],
      tag: [''],
      school: ['', Validators.required]
    });
    this.getSchools();

    if (this.unit) { this.initializeEditMode(); }
    this.coordinatorInit();
  }

  coordinatorInit() {
    if (this.userService.isGeneralCoordinator()) {
      this.createUnitForm.get('school').setValue(this.userService.getUserSchool());
      this.getDisciplines(this.userService.getUserSchool());
      this.getPlans(this.userService.getUserSchool());
    }
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
          this.filterSchools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getUnits() {
    this.loading = true;
    const schoolId = this.userService.isGeneralCoordinator() ? this.userService.getUserSchool() : undefined;
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente');
      });
  }

  getUnitOne(unitId: number) {
    this.schoolService.getUnitOne(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.unit = response.data as Unit;
          this.editUnit(this.unit);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao contar os alunos. Verifique a conexão e tente novamente'));
  }

  openList() {
    if (!this.userService.isAdmin()) {
      this.getUnits();
      this.getDisciplines(this.userService.getUserSchool());
      this.getPlans(this.userService.getUserSchool());
    }
    this.showUnitsList = true;
  }

  createUnit() {
    if (this.createUnitForm.valid) {
      this.loading = true;
      const unit = this.createUnitForm.getRawValue() as Unit;

      this.schoolService.creteUnit(unit)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Unidade cadastrada', 3);
            this.setUnitPlans(response.data as number, [...this.selectedPlans?.map(p => p.id)]);
            this.setUnitDisciplines(response.data as number, [...this.selectedDisciplines?.map(d => d.id)]);
            this.createUnitForm.reset();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao criar a unidade. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente');
    }
  }

  duplicateUnit() {
    if (this.createUnitForm.valid) {
      this.loading = true;
      const unit = this.createUnitForm.getRawValue() as Unit;
      unit.name = unit.name + " (duplicado)";
      this.schoolService.creteUnit(unit)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Unidade duplicada', 3);
            this.createUnitForm.reset();
            this.dialogRef.close(true);
            this.getUnits();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao duplicar a unidade. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente');
    }
  }


  editUnit(unit: Unit) {
    const dialogRef = this.dialog.open(CreateUnitComponent, {
      minWidth: '60vw',
      data: unit
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getUnits();
      }
    });
  }

  removeUnit(unitId: number) {
    if (confirm('Deseja realmente remover a Unidade?')) {
      this.schoolService.removeUnit(unitId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Unidade removida', 3);
            if (this.userService.isAdmin()) {
              this.getSchoolUnits(this.filterSchoolId);
            } else {
              this.getUnits();
            }
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a unidade. Verifique a conexão e tente novamente'));
    }
  }

  initializeEditMode() {
    this.editMode = true;
    this.createUnitForm.patchValue(this.unit);
    this.createUnitForm.get('school').setValue(this.unit.school);
    this.getPlans(this.unit.school as number);
    this.getDisciplines(this.unit.school as number);
    this.getUnitDisciplines(this.unit.id);
    this.getUnitPlans(this.unit.id);
  }

  saveChanges() {
    if (this.createUnitForm.valid) {

      this.loading = true;
      const unit = this.createUnitForm.getRawValue() as Unit;
      this.schoolService.saveUnitChanges(unit)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Unidade editada com sucesso', 3);
            this.setUnitPlans(this.unit.id, [...this.selectedPlans?.map(p => p.id)]);
            this.setUnitDisciplines(this.unit.id, [...this.selectedDisciplines?.map(d => d.id)]);
            this.dialogRef.close(true);
            this.getUnits();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar as alterações da unidade. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getSchoolUnits(schoolId: number) {
    this.loading = true;
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplines(schoolId: number) {
    this.disciplineService.getSchoolDisciplines(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.filteredDisciplines.next([...this.disciplines]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  addDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.findIndex(d => d.id == discipline.id);

    if (!index) {
      this.selectedDisciplines.push(discipline);
    }
  }

  removeDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.findIndex(d => d.id == discipline.id);

    if (index >= 0) {
      this.selectedDisciplines.splice(index, 1);
      this.disciplinesModel = [...this.selectedDisciplines.map(d => d.id)];
    }
  }

  disciplineSelectionChange(selectedDisciplinesId: number[]) {
    this.selectedDisciplines = [];
    selectedDisciplinesId.forEach(disciplineId => {
      for (const discipline of this.disciplines) {
        if (discipline.id == disciplineId) {
          this.selectedDisciplines.push(discipline);
          break;
        }
      }
    });
  }

  protected filterDisciplines() {
    if (!this.disciplines) {
      return;
    }
    let search = this.disciplineFilter.value;
    if (!search) {
      this.filteredDisciplines.next(this.disciplines.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtering = true;
    // filter disciplines
    this.filteredDisciplines.next(
      this.disciplines.filter(discipline => discipline.name.toLowerCase().indexOf(search) > -1)
    );

    this.filtering = false;
  }

  getPlans(schoolId: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.filteredPlans.next([...this.plans]);
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  addPlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.findIndex(p => p.id == plan.id);

    if (!index) {
      this.selectedPlans.push(plan);
    }
  }

  removePlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.findIndex(p => p.id == plan.id);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
      this.plansModel = [...this.selectedPlans.map(p => p.id)];
    }
  }

  planSelectionChange(selectedPlansId: number[]) {
    this.selectedPlans = [];
    selectedPlansId.forEach(planId => {
      for (const plan of this.plans) {
        if (plan.id == planId) {
          this.selectedPlans.push(plan);
          break;
        }
      }
    });
  }

  protected filterPlans() {
    if (!this.plans) {
      return;
    }
    let search = this.planFilter.value;
    if (!search) {
      this.filteredPlans.next(this.plans.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtering = true;
    this.filteredPlans.next(
      this.plans.filter(plan => plan.name.toLowerCase().indexOf(search) > -1)
    );

    this.filtering = false;
  }

  private setUnitPlans(unitId: number, planId: number[]) {
    if (planId?.length) {
      this.schoolService.setUnitPlans(unitId, planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.selectedPlans = [];
            this.plansModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao adicionar os planos da unidade. Verifique a conexão e tente novamente'));
    }
  }

  private setUnitDisciplines(unitId: number, disciplineId: number[]) {
    if (disciplineId?.length) {
      this.schoolService.setUnitDisciplines(unitId, disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.selectedDisciplines = [];
            this.disciplinesModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao adicionar as disciplinas da unidade. Verifique a conexão e tente novamente'));
    }
  }

  private getUnitPlans(unitId: number) {
    this.schoolService.getUnitPlans(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedPlans = [...response.data as StudyPlan[]];
          this.plansModel = [...this.selectedPlans.map(p => p.id)];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos da unidade. Verifique a conexão e tente novamente'));
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedDisciplines = [...response.data as Discipline[]];
          this.disciplinesModel = [...this.selectedDisciplines.map(d => d.id)];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
  }
}
