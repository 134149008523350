import { Input, AfterViewInit, OnDestroy, Component } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile, delay } from 'rxjs/operators';
import * as echarts from 'echarts';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html'
})

export class LineChartComponent implements AfterViewInit, OnDestroy {

    private alive = true;

    @Input() chartData: {
        innerLine: number[];
        outerLine: {label: string, value: number}[];
    };
    @Input() legend: string;

    chartLegend: { iconColor: string; title: string }[];

    option: any;
    themeSubscription: any;
    echartsIntance: any;

    constructor(private theme: NbThemeService) {}


    ngAfterViewInit(): void {
        this.theme.getJsTheme()
            .pipe(
                delay(1),
                takeWhile(() => this.alive),
            )
            .subscribe(config => {
                const eTheme: any = config.variables.visitors;

                this.setOptions(eTheme);
                this.setLegendItems(config.variables.visitorsLegend);
            });
    }

    setLegendItems(visitorsLegend): void {
        this.chartLegend = [
            {
                iconColor: visitorsLegend.firstIcon,
                title: this.legend,
            },
            {
                iconColor: visitorsLegend.secondIcon,
                title: 'Page Views',
            },
        ];
    }

    setOptions(eTheme) {
        this.option = {
            grid: {
                left: 40,
                top: 20,
                right: 0,
                bottom: 60,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    lineStyle: {
                        color: eTheme.tooltipLineColor,
                        width: eTheme.tooltipLineWidth,
                    },
                },
                textStyle: {
                    color: eTheme.tooltipTextColor,
                    fontSize: 20,
                    fontWeight: eTheme.tooltipFontWeight,
                },
                position: 'top',
                backgroundColor: eTheme.tooltipBg,
                borderColor: eTheme.tooltipBorderColor,
                borderWidth: 1,
                formatter: (params) => {
                    return Math.round(parseInt(params[0].value, 10));
                },
                extraCssText: eTheme.tooltipExtraCss,
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                offset: 25,
                data: this.chartData.outerLine.map(i => i.label),
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    color: eTheme.axisTextColor,
                    fontSize: eTheme.axisFontSize,
                },
                axisLine: {
                    lineStyle: {
                        color: eTheme.axisLineColor,
                        width: '2',
                    },
                },
            },
            yAxis: {
                type: 'value',
                boundaryGap: false,
                axisLine: {
                    lineStyle: {
                        color: eTheme.axisLineColor,
                        width: '1',
                    },
                },
                axisLabel: {
                    color: eTheme.axisTextColor,
                    fontSize: eTheme.axisFontSize,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {

                    lineStyle: {
                        color: eTheme.yAxisSplitLine,
                        width: '1',
                    },
                },
            },
            series: [
                this.getInnerLine(eTheme),
                this.getOuterLine(eTheme),
            ],
        };
    }

    getOuterLine(eTheme) {
        return {
            type: 'line',
            smooth: true,
            symbolSize: 20,
            itemStyle: {
                normal: {
                    opacity: 0,
                },
                emphasis: {
                    color: '#ffffff',
                    borderColor: eTheme.itemBorderColor,
                    borderWidth: 2,
                    opacity: 1,
                },
            },
            lineStyle: {
                normal: {
                    width: eTheme.lineWidth,
                    type: eTheme.lineStyle,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: eTheme.lineGradFrom,
                    }, {
                        offset: 1,
                        color: eTheme.lineGradTo,
                    }]),
                    shadowColor: eTheme.lineShadow,
                    shadowBlur: 6,
                    shadowOffsetY: 12,
                },
            },
            areaStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: eTheme.areaGradFrom,
                    }, {
                        offset: 1,
                        color: eTheme.areaGradTo,
                    }]),
                },
            },
            data: this.chartData.outerLine.map(i => i.value),
        };
    }

    getInnerLine(eTheme) {
        return {
            type: 'line',
            smooth: true,
            symbolSize: 20,
            tooltip: {
                show: false,
                extraCssText: '',
            },
            itemStyle: {
                normal: {
                    opacity: 0,
                },
                emphasis: {
                    opacity: 0,
                },
            },
            lineStyle: {
                normal: {
                    width: eTheme.innerLineWidth,
                    type: eTheme.innerLineStyle,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1),
                },
            },
            areaStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: eTheme.innerAreaGradFrom,
                    }, {
                        offset: 1,
                        color: eTheme.innerAreaGradTo,
                    }]),
                    opacity: 1,
                },
            },
            data: this.chartData.innerLine,
        };
    }

    onChartInit(echart) {
        this.echartsIntance = echart;
    }

    resizeChart() {
        if (this.echartsIntance) {
            this.echartsIntance.resize();
        }
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
