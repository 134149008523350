<div class="page-container p-pg page-container-mobile discipline font-poppins">
    <div class="row m-0">
        <div class="mt-2" [ngClass]="{'col-md-7':!layoutService.isMobile, 'col-md-12':layoutService.isMobile}">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'left dash-container':!layoutService.isMobile, 'text-center': layoutService.isMobile, 'text-center width-80': layoutService.isMobile}">
                    <span class="page-title">AULAS / {{planName}}</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile" class="text-color-accent">Selecione uma disciplina</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile" class="text-color-accent">Selecione uma disciplina</h6>
                        <p class="fs-13 pb-0">Escolha a disciplina que você deseja estudar</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="mt-2 text-right" [ngClass]="{'col-md-5':!layoutService.isMobile, 'col-md-12 p-0':layoutService.isMobile}">
            <button (click)="backPage()" mat-icon-button class="btn-back" [ngClass]="{'w-100px':!layoutService.isMobile, ' w-100':layoutService.isMobile}">
                <mat-icon class="icon-back">west</mat-icon> Voltar
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 mt-3" *ngFor="let discipline of disciplines" >
            <button (click)="selectDiscipline(discipline.id, discipline.name)" class="decoration-none p-0 w-100 text-left" >
                <mat-card class="no-padding br-0">
                    <mat-card-header class="pt-4 pr-4 pl-4 text-left topic">
                        <mat-card-title class="d-flex">
                            <mat-icon class="f-icon" color="accent" *ngIf="!discipline.icone">co_present</mat-icon>
                            <mat-icon class="f-icon" color="accent" *ngIf="discipline.icone">{{discipline.icone}}</mat-icon>
                            <span class="pl-4 pt-2 f-title"><b>{{discipline.name}}</b></span>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="pt-3 pb-4 pr-4 pl-4 text-left">
                        <div class="pb-1 text-description">{{discipline.totalAssistido}}/{{discipline.totalAulas}} aulas assistidas</div>
                        <div class="d-flex">
                            <mat-progress-bar mode="determinate" value="{{discipline.porcentagem}}"></mat-progress-bar>
                            <span class="pl-1">{{discipline.porcentagem}}%</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </button>
        </div>
    </div>
</div>