<div class="container pt-5">
    <h2 class="mb-5 ml-2">Relatórios</h2>
    <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-4 col-sm-12">
            <nb-card class="size-card">
                <nb-card-body class="pt-2 text-center">
                    <ng-container > 
                        <a mat-list-item routerLink="../report/performance" class="menu-item-label pt-2 pb-2 text-left">
                            <nb-icon class="mr-4 " icon="external-link-outline"></nb-icon>
                            <span>Relatório Desempenho</span>
                        </a>
                    </ng-container>
                </nb-card-body>
                <nb-card-body class="pt-2 text-center">
                    <ng-container > 
                        <a mat-list-item routerLink="../mock/report" class="menu-item-label pt-2 pb-2 text-left">
                            <nb-icon class="mr-4 " icon="external-link-outline"></nb-icon>
                            <span>Relatório Simulados</span>
                        </a>
                    </ng-container>
                </nb-card-body>
                <nb-card-body class="pt-2 text-center">
                    <ng-container > 
                        <a mat-list-item routerLink="../performance/report" class="menu-item-label pt-2 pb-2 text-left">
                            <nb-icon class="mr-4 " icon="external-link-outline"></nb-icon>
                            <span>Relatórios de provas e Simulados</span>
                        </a>
                    </ng-container>
                </nb-card-body>
                <nb-card-body class="pt-2 text-center">
                    <ng-container > 
                        <a mat-list-item routerLink="../summation/report" class="menu-item-label pt-2 pb-2 text-left">
                            <nb-icon class="mr-4 " icon="external-link-outline"></nb-icon>
                            <span>Relatório simulado (somatória)</span>
                        </a>
                    </ng-container>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>
<router-outlet></router-outlet>