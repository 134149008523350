import { Component, OnInit, Optional, Input, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DisciplineService } from '../discipline.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Video } from 'src/app/modules/video/video';
import { VideoService } from 'src/app/modules/video/video.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Discipline, Icons } from '../discipline';
import { AdminService } from '../../admin.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-create-discipline',
  templateUrl: './create-discipline.component.html',
  styleUrls: ['./create-discipline.component.scss']
})
export class CreateDisciplneComponent implements OnInit, OnDestroy {

  public createDisciplineForm: FormGroup;
  public videos: Video[];
  public selectedVideos: Video[];
  public showDisciplinesList: boolean;
  public disciplines: Discipline[];
  public disciplinesAll: Discipline[];
  public disciplinesAux: Discipline[];
  public editMode: boolean;
  public loading: boolean;
  public plans: StudyPlan[] = [];
  public filterPlan: number;
  public page: number;
  public schools: School[];
  public admUpdate$: Subscription;
  public searching: boolean;
  public selectedSchool: number;
  public filterName: string;
  public planId: number;
  public pageIndex: number;
  public total: number;
  public pageSize: number;
  public pageNo: number;
  public disciplineName: string;
  public totalPage: any;
  public nextPageActive = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public selectedSchools: School[];
  public selectedSchoolPlans: StudyPlan[];
  public icons: Icons[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public discipline: any,
    @Optional() public dialogRef: MatDialogRef<CreateDisciplneComponent>,
    private dialog: MatDialog,
    private disciplineService: DisciplineService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private videoService: VideoService,
    private adminService: AdminService,
    private studyPlanService: StudyPlanService,
    private schoolService: SchoolService,
    public userService: UserService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.int());

    this.pageSize = 10;
    this.pageNo = 0;
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  int() {

    this.selectedSchools = [];
    this.selectedVideos = [];

    this.createDisciplineForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      tag: ['', Validators.required],
      icon: [''],
      justExercises: [],
      videos: [],
      schools: []
    });

    this.getIcons();

    if (this.userService.isGeneralCoordinator()) {
      this.getPlans(this.userService.getUserSchool());
    }
    this.profileInit();
    if (this.discipline) { this.initializeEditMode(); }
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.getSchools();
    } else {
      this.createDisciplineForm.get('schools').setValue(this.userService.getUserSchool());
    }
  }

  getIcons(){
    this.adminService.getIcons()
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) { this.icons = response.data as Icons[];
      } else { this.alertService.error(response.error);}
    }, err => this.alertService.error('Houve um erro ao buscar os ícones. Verifique a conexão e tente novamente'));
  }

  getNameDiscipline(nameDiscipline: string) {
    if (!this.userService.isAdmin()) {
      this.disciplineService.getNameDisciplineCoord(nameDiscipline, this.userService.getUserSchool())
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            let data = response.data;
            this.disciplines = data as Discipline[];
            this.totalPage = data[0].totalPage;
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao buscar a disciplina. Verifique a conexão e tente novamente'));
    }
    else {
      this.disciplineService.getNameDiscipline(nameDiscipline)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            let data = response.data;
            this.disciplines = data as Discipline[];
            this.totalPage = data[0].totalPage;
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao buscar a disciplina. Verifique a conexão e tente novamente'));
    }
  }

  pageEvents(event: any) {

    if (event.pageIndex >= this.pageNo) {
      // Clicked on next button
      if (!this.userService.isAdmin()) {
        if (this.filterPlan) {

          this.getNextPageCoord(event.pageIndex, event.pageSize, this.planId, this.disciplineName , this.userService.getUserSchool());
        } else {
          this.getNextPageCoord(event.pageIndex, event.pageSize, this.planId, this.disciplineName , this.userService.getUserSchool());
        }
      }
      else {
        if (this.filterPlan) {

          this.getNextPage(event.pageIndex, event.pageSize, this.planId, this.disciplineName);
        } else {
          this.getNextPage(event.pageIndex, event.pageSize, this.planId, this.disciplineName);
        }
      }
    }
  }


  getNextPage(page: number, pageOpntions: number, planId: number, name?: string) {
    this.nextPageActive = true;
    this.disciplineService.getNextPage(page, pageOpntions, planId, name)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }
        this.nextPageActive = false;
      }, err => {
        this.nextPageActive = false;
        this.alertService.error('Houve um erro ao buscar a página. Verifique a conexão e tente novamente')
      });


  }

  getNextPageCoord(page: number, pageOpntions: number, planId: number, name?: string, schoolId?: number) {
    this.nextPageActive = true;
    this.disciplineService.getNextPageCoord(schoolId, page, pageOpntions, planId, name)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }
        this.nextPageActive = false;
      }, err => {
        this.nextPageActive = false;
        this.alertService.error('Houve um erro ao buscar a página. Verifique a conexão e tente novamente')
      });


  }

  getPrevPage(page: number, pageOpntions: number, planId: number, name?: string) {
    this.disciplineService.getPrevPage(page, pageOpntions, planId, name)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar a página. Verifique a conexão e tente novamente'));
  }

  getPrevPageCoord(page: number, pageOpntions: number, planId: number, name?: string, schoolId?: number) {
    this.disciplineService.getPrevPageCoord(schoolId,page, pageOpntions, planId, name)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar a página. Verifique a conexão e tente novamente'));
  }
  getNextPageWithSchool(page: number, pageOpntions: number, planId: number, schoolId: number, disciplineName?: string) {
    this.disciplineService.getNextPageWithSchool(page, pageOpntions, planId, schoolId, disciplineName)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar a página. Verifique a conexão e tente novamente'));
  }
  getPrevPageWithSchool(page: number, pageOpntions: number, planId: number, schoolId: number, disciplineName?: string) {
    this.disciplineService.getPrevPageWithSchool(page, pageOpntions, planId, schoolId, disciplineName)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar a página. Verifique a conexão e tente novamente'));
  }

  getVideos() {
    this.videoService.getVideos()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os vídeos. Verifique a conexão e tente novamente'));
  }

  getAllDisciplines() {
    this.loading = true;
    this.disciplineService.getDisciplines()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
          this.disciplinesAll = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }


  addSchoolChip(school: School) {
    const index = this.selectedSchools.indexOf(school);

    if (index < 0) {
      this.selectedSchools.push(school);
      this.createDisciplineForm.get('schools').reset();
      this.getPlans(school.id);
    }
  }

  removeSchoolChip(school: School) {
    const index = this.selectedSchools.indexOf(school);

    if (index >= 0) {
      this.selectedSchools.splice(index, 1);
    }
  }

  addVideoChip(video: Video) {
    const index = this.selectedVideos.indexOf(video);

    if (index < 0) {
      this.selectedVideos.push(video);
      this.createDisciplineForm.get('videos').reset();
    }
  }

  removeVideoChip(video: Video) {
    const index = this.selectedVideos.indexOf(video);

    if (index >= 0) {
      this.selectedVideos.splice(index, 1);
    }
  }

  createDiscipline() {
    const videosIds = [];
    this.selectedVideos.map(v => videosIds.push(v.id));
    this.createDisciplineForm.get('videos').setValue(videosIds);
    let schoolsid = [];
    if(this.userService.isAdmin()){
      this.selectedSchools.map(s => schoolsid.push(s.id));
      this.createDisciplineForm.get('schools').setValue(schoolsid);
    }else if(this.userService.isGeneralCoordinator() && this.userService.isUnitCoordinator()){
      this.createDisciplineForm.get('schools').setValue(this.userService.getUserSchool());
    }
    const discipline = this.createDisciplineForm.getRawValue() as Discipline;

    if (!this.createDisciplineForm.valid) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    this.disciplineService.createDiscipline(discipline)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Disciplina cadastrada', 3);
          this.createDisciplineForm.reset();
          this.selectedVideos = [];
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao criar a disciplina. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  duplicateDiscipline() {
    const videosIds = [];
    this.selectedVideos.map(v => videosIds.push(v.id));
    this.createDisciplineForm.get('videos').setValue(videosIds);
    const discipline = this.createDisciplineForm.getRawValue() as Discipline;
    discipline.name = discipline.name + " (duplicado)";
    if (!this.createDisciplineForm.valid) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }
    this.loading = true;
    this.disciplineService.createDiscipline(discipline)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.alertService.success('Disciplina Duplicada', 3);
          this.createDisciplineForm.reset();
          this.selectedVideos = [];
          this.selectedSchools = [];
          this.dialogRef.close(true);
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao Duplicar a disciplina. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  editDiscipline(discipline: Discipline) {
    const dialogRef = this.dialog.open(CreateDisciplneComponent, {
      minWidth: '60vw',
      data: discipline,
      maxHeight: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDisciplines();
      }
    });
  }

  openList() {
    if (this.userService.isGeneralCoordinator()) {
      this.getPlans(this.userService.getUserSchool());
    }
    this.showDisciplinesList = true;
  }

  removeDiscipline(disciplineId: number) {
    if (confirm('Deseja realmente apagar a disciplina?')) {
      this.disciplineService.removeDiscipline(disciplineId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.alertService.success('Disciplina removida', 3);
            this.getDisciplines();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }
  }

  // edit mode methods

  initializeEditMode() {

    console.log("this.discipline : ", this.discipline);
    this.editMode = true;
    this.createDisciplineForm.patchValue(this.discipline);

    this.selectedSchool = this.discipline.schoolId ? this.discipline.schoolId : this.discipline.schools;

    this.getDisciplineVideos(this.discipline.id);
    if (this.userService.isAdmin()) {
      this.getSchools();
    } else if (this.userService.isGeneralCoordinator()) {
      this.getPlans(this.userService.getUserSchool());
    }
  }

  getDisciplineVideos(disciplineId: number, searchOnly?: boolean) {
    this.disciplineService.getDisciplineVideos(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          if (!searchOnly) {
            this.selectedVideos = response.data as Video[];
          } else {
            this.videos = response.data as Video[];
          }
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  saveChanges() {
    if (this.createDisciplineForm.valid) {
      this.loading = true;
      const videosIds = [];
      const schoolsIds = [];

      this.selectedVideos.map(v => videosIds.push(v.id));
      this.createDisciplineForm.get('videos').setValue(videosIds);

      this.selectedSchools.map(s => schoolsIds.push(s.id));
      this.createDisciplineForm.get('schools').setValue(schoolsIds);

      this.createDisciplineForm.get('id').setValue(this.discipline.id);

      const c = this.createDisciplineForm.getRawValue() as Discipline;

      console.log("createDisciplineForm : ", c);

      this.disciplineService.saveDisciplineChanges(c)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Disciplina editada com sucesso');
            this.dialogRef.close(true);
            //this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar as alterações da turma. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }

  teste(variavel: string){
    console.log(variavel);
  }

  getPlans(schoolId?: number) {
    this.plans = []; //add para não carregar planos da escola selecionada anteriormente
    this.getSchoolDisciplines(schoolId);
    this.loading = true;
    
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          if(this.plans && this.plans.length > 0){
            let plans = response.data as StudyPlan[];
            plans.forEach(element => {
              this.plans.push(element);
            });
          }else{
            this.plans = response.data as StudyPlan[];
          }
        } else {
          this.alertService.error(response.error);
          this.disciplines = [];
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplines( filter?: number) {

    //console.log("this.filterPlan: ", this.filterPlan);
    //console.log("filter: ", filter);

    if(this.filterPlan || filter ){

      //console.log("exec  this.getPlanDisciplines");

      if (filter) {
        this.getPlanDisciplines(filter);
      }else {
        this.getPlanDisciplines(this.filterPlan);
      }


    } else {
      //console.log("exec  this.getSchoolDisciplines");
      this.getSchoolDisciplines(this.selectedSchool);
    }

  }

  getPrevAllDisciplinesPage(page: number, pageOptions: number, disciplineName?: string) {
    this.loading = true;
    this.disciplineService.getPrevAllDisciplinesPage(page, pageOptions, disciplineName)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getNextAllDisciplinesPage(page: number, pageOptions: number, disciplineName?: string) {
    this.loading = true;
    this.disciplineService.getNextAllDisciplinesPage(page, pageOptions, disciplineName)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlanDisciplines(planId?: number) {
    this.loading = true;
    this.planId = planId;
    this.studyPlanService.getPlanDisciplines(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
          this.disciplineName = '';
        } else {
          this.alertService.error(response.error);
          this.disciplines = [];
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getPlanDisciplinesPage(planId?: number) {
    this.loading = true;
    this.planId = planId;
    this.studyPlanService.getPlanDisciplinesPage(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.disciplinesAll = data as Discipline[];
          this.totalPage = data[0].totalPage;

          this.disciplineName = '';

        } else {
          this.alertService.error(response.error);
          this.disciplines = [];
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }


  getSchools() {
    this.plans = [];
    this.selectedSchoolPlans = [];
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
          if(this.editMode){
            this.selectedSchools = [];
            this.schools.forEach(school => {
              this.discipline.schools.forEach(schoolId => {
                if( schoolId == school.id  && !this.selectedSchools.includes(school) ){
                  this.selectedSchools.push(school);
                }
              });
            });
          }
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getSchoolDisciplines(schoolId: number) {
    this.disciplineService.getSchoolDisciplines(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let data = response.data;
          this.disciplines = data as Discipline[];
          this.totalPage = data[0].totalPage;
        } else {
          this.alertService.error(response.error);

        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  getVideosByName(videoName: string) {
    this.searching = true;
    this.videoService.getVideosByName(videoName)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[];
        } else {
          this.alertService.error(response.error);
        }
        this.searching = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
        this.searching = false;
      });
  }


}
