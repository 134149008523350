export interface Scripts {
  name: string;
  src: string;
  async?: boolean;
  appendOnBody?: boolean;
}
export const ScriptStore: Scripts[] = [
  { name: 'oktadesck', src: 'https://chat.octadesk.services/api/widget/aletech?showButton=true&openOnMessage=true', async: true, appendOnBody: true },
];

/* 
<script>
(function (o, c, t, a, d, e, s, k) {
  o.octadesk = o.octadesk || {};
  s = c.getElementsByTagName("body")[0];
  k = c.createElement("script");
  k.async = 1;
  k.src = t + '/' + a + '?showButton=' + d + '&openOnMessage=' + e;
  s.appendChild(k);
  })(window, document, 'https://chat.octadesk.services/api/widget', 'aletech', true, true);
</script> */