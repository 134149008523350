<div class="mb-3" >
    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" (selectionChange)="getPlans($event.value)"
                        placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="plan" placeholder="Plano" 
                        (selectionChange)="getSimulated($event.value)">
                        <mat-option value="0">Todas</mat-option>
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            <span [title]="plan.name">{{plan.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select  formControlName="simulated" placeholder="Simulado">
                        <mat-option *ngFor="let simulate of simulated" [value]="simulate.id">
                            <span [title]="simulate.name">{{simulate.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-12 col-lg-6 col-xl-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" >
                <mat-form-field class="width-100">
                    <mat-select formControlName="unit" placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12 col-lg-6 col-xl-6">
                <mat-form-field class="width-100">
                    <span matPrefix style="margin-right: 8px;">
                        <mat-icon class="ml10" matTooltipClass="custom-tooltip"
                            matTooltip="Se selecionado retorna relatório por turma">help
                        </mat-icon> 
                    </span>
                    <mat-select formControlName="class" placeholder="Turma" (selectionChange)="getStudents($event.value)">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12 col-lg-6 col-xl-6">
                <mat-form-field class="width-100">
                    <span matPrefix style="margin-right: 8px;">
                        <mat-icon class="ml10" matTooltipClass="custom-tooltip"
                            matTooltip="Se selecionado retorna relatório por aluno">help
                        </mat-icon> 
                    </span>
                    <mat-select formControlName="student" placeholder="Aluno">
                        <mat-option>Todos</mat-option>
                        <mat-option *ngFor="let student of students" [value]="student.id">
                            <span [title]="student.name">{{student.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
           <!--  <div class="col-md-3 col-6 col-lg-3 col-xl-3">
                <button (click)="downloadReportTXT()" class="right" nbButton size="small"
                    status="primary" nbSpinnerStatus="warning">Relatório TXT</button>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-12 col-12 col-lg-12 col-xl-12 text-right mt-3">
                <button (click)="generateReportExcel()" class="right" nbButton size="small"
                    status="primary" nbSpinnerStatus="warning">Relatório Excel</button>
            </div>
        </div>
    </form>
</div>