import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideFilePlayerComponent } from './slide-file-player/slide-file-player.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NbCardModule, NbButtonModule, NbAccordionModule, NbIconModule, NbListModule } from '@nebular/theme';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  declarations: [SlideFilePlayerComponent],
  imports: [
    CommonModule,
    SharedModule,

    NbCardModule,
    NbButtonModule,
    NbAccordionModule,
    MatFormFieldModule,
    MatTabsModule,
    MatListModule,
    MatTableModule,
    MatNativeDateModule,
    NbIconModule,
    NbListModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    TextMaskModule,
    FormsModule,
    MatDialogModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTooltipModule,
  ],
  exports: [
    SlideFilePlayerComponent
  ]
})
export class SlideFileModule { }
