<div class="container pt-5 pb-5">

    <app-mock-general-report></app-mock-general-report>

    <h3 class="mb-5 ml-5">Relatório - Simulados</h3>

    <nb-card>
        <nb-card-body>
            <div class="row">

                <div class="col-md-2" *ngIf="userService.isAdmin()">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Escola" (selectionChange)="getUnits($event.value)" [(ngModel)]="schoolId">
                            <mat-option *ngFor="let school of schools" [value]="school.id">{{school.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="(userService.isAdmin() || userService.isGeneralCoordinator())">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)" [(ngModel)]="unitId">
                            <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Turma" [(ngModel)]="classId">
                            <mat-option *ngFor="let class of classes" [value]="class.id">{{class.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <div class="col-md text-center">
                        <button class="mt-3" mat-raised-button color="primary" (click)="getReport()">
                Ver Simulados
              </button>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="col-md text-center">
                        <button class="mt-3" mat-raised-button color="accent" (click)="generateReport()">
                Baixar Excel
              </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md" *ngIf="mocksToFilter?.length">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Simulado" (selectionChange)="applyFilter($event.value); filterStudent = null" [(ngModel)]="filterMock">
                            <mat-option>Todos</mat-option>
                            <mat-option *ngFor="let mock of mocksToFilter" [value]="mock">{{mock}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md" *ngIf="studentsToFilter?.length">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Estudante" (selectionChange)="applyFilter($event.value); filterMock = null;" [(ngModel)]="filterStudent">
                            <mat-option>Todos</mat-option>
                            <mat-option *ngFor="let student of studentsToFilter" [value]="student">{{student}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </nb-card-body>
    </nb-card>

    <table *ngIf="dataSource && !loading" mat-table color="accent" [dataSource]="dataSource" class="mat-elevation-z8 width-100 center">


        <ng-container matColumnDef="student">
            <th mat-header-cell *matHeaderCellDef> Estudante </th>
            <td mat-cell *matCellDef="let report"> {{report.studentName}} </td>
        </ng-container>


        <ng-container matColumnDef="replied">
            <th mat-header-cell *matHeaderCellDef> Respondidas </th>
            <td mat-cell *matCellDef="let report"> {{report.replied}} </td>
        </ng-container>


        <ng-container matColumnDef="correct">
            <th mat-header-cell *matHeaderCellDef> Acertos </th>
            <td mat-cell *matCellDef="let report"> {{report.correct}} </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Erros </th>
            <td mat-cell *matCellDef="let report"> {{report.erros}} </td>
        </ng-container>

        <ng-container matColumnDef="mockQuestions">
            <th mat-header-cell *matHeaderCellDef> Questões </th>
            <td mat-cell *matCellDef="let report"> {{report.mockQuestions}} </td>
        </ng-container>

        <ng-container matColumnDef="mockDone">
            <th mat-header-cell *matHeaderCellDef> Tempo Aluno </th>
            <td mat-cell *matCellDef="let report"> {{secondsToInputTime(report.mockDone)}} </td>
        </ng-container>

        <ng-container matColumnDef="mockTime">
            <th mat-header-cell *matHeaderCellDef> Tempo Limite </th>
            <td mat-cell *matCellDef="let report"> {{secondsToInputTime(report.mockTime)}} </td>
        </ng-container>

        <ng-container matColumnDef="mockName">
            <th mat-header-cell *matHeaderCellDef> simulado </th>
            <td mat-cell *matCellDef="let report">
                <ng-container *ngIf="report.mockName.length < 25; else cut">
                    {{report.mockName}}
                </ng-container>
                <ng-template #cut>
                    <span [matTooltip]="report.mockName">{{report.mockName | slice:0:25}}...</span>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="viewMock">
            <th mat-header-cell *matHeaderCellDef class="text-right"> Ver Simulado </th>
            <td mat-cell *matCellDef="let report" class="text-right">
                <button mat-mini-fab (click)="openMock(report.studentId, report.id)">
            <mat-icon>open_in_new</mat-icon>
          </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
</div>
<div class="container pt-5 pb-5" *ngIf="linkDownload">
    <div>
        <h3 class="mb-5 ml-5"> Downloads (Excel)</h3>

        <nb-card>
            <nb-card-body>
                <div>
                    <div class="row mt-2">
                        <div class="col-10" *ngFor="let link of linkDownload">
                            <a id="desempenho-geral" href="{{link.url}}" target="_blank" color="primary" download>
                  {{clearName(link.name)}}
                </a>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>

    </div>
</div>