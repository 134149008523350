import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ReportService } from '../report.service';
import { Unit } from '../../admin/school/unit';
import { Class } from '../../admin/school/class';
import { Student } from '../../admin/student/student';
import { SchoolService } from '../../admin/school/school.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../user/user.service';
import { StudentService } from '../../admin/student/student.service';
import { ReportPerformance } from './report-performance';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { School } from '../../admin/school/school';
import Litepicker from 'litepicker';
import { Average } from '../charts/average/average';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

interface Csv {
    aluno: string
    desempenho: string
    email: string
    media: string
    participacao: string
    plano_estudos: string
    turma: string
}

interface UserDataReport {
    schoolId: number,
    classId: number,
    reportId: number,
    unitId: number,
    planId: number,
    studentId: number,
    date_begin: string,
    date_end: string
}



@Component({
    selector: 'app-report-performance',
    templateUrl: './report-performance.component.html',
    styleUrls: ['./report-performance.component.scss']
})
export class ReportPerformanceComponent implements OnInit {

    public tempSchool: number = 0;
    public unit: number;
    public class: number;
    public student: number;

    public studyPlan: number;
    public plans: StudyPlan[];


    public units: Unit[];
    public classes: Class[];
    public students: Student[];

    public tempClassId: number;
    public tempUnit: number;
    public dateBegin: string;
    public dateEnd: string;
    public planId: number;
    public loading: boolean;
    public loadingInit: boolean = false;
    public linkDownload: any = [];
    public reports: any = [];
    public report: number = 0;
    public reportDescription: number = 0;
    public reportcsv: Csv[];
    public typerelat: string;
    public userdata : UserDataReport;


    performance: ReportPerformance = {
        answered: { class: 0, student: 0 },
        correct: { class: 0, student: 0 },
        errors: { class: 0, student: 0 },
        forum: { answers: 0, topics: 0 },
        frequency: { accesses: 0, lastAccess: '0' },
        studyPlan: { exercisesSolved: 0, watchedVideos: 0 },
        disciplines: [
            {
                name: 'Nenhuma', studentError: 0, studentCorrect: 0,
                classError: 0, classCorrect: 0, totalQuestions: 0
            }
        ]
    };
    schools: any[];
    public filterDays: any = {
        performance: 0,
        average: 0,
        frequency: 0,
        participation: 0,
        studyPlan: 0
    };
    public filterDaysModel: any = {
        performance: 0,
        average: 0,
        frequency: 0,
        participation: 0,
        studyPlan: 0
    };
    studentData: Student;


    constructor(
        private reportService: ReportService,
        private schoolService: SchoolService,
        private alertService: AlertService,
        public userService: UserService,
        private studentService: StudentService,
        private studyPlanService: StudyPlanService) { }

    ngOnInit(): void {
        this.reportcsv = [];
        this.coordinatorInit();
        this.profileInit();
        this.getTiposRelatorios();

        const picker: any = new Litepicker({
            element: document.getElementById('date-report-begin'),
            format: 'DD/MM/YYYY',
            lang: 'pt-BR',
            onSelect: this.setDateBegin.bind(this)
        });
        const pickerEnd: any = new Litepicker({
            element: document.getElementById('date-report-fim'),
            format: 'DD/MM/YYYY',
            lang: 'pt-BR',
            onSelect: this.setDateEnd.bind(this)
        });
    }

    profileInit() {
        if (this.userService.isAdmin()) {
            this.getSchools();
        } else if (this.userService.isUnitCoordinator()) {
            this.unit = this.userService.getUserUnit();
            this.tempUnit = this.unit;
            this.getClasses(this.unit);
        } else if (this.userService.isGeneralCoordinator()) {
            this.getUnits(this.userService.getUserSchool());
        } else if (this.userService.isUnitCoordinator()) {
            this.getClasses(this.userService.getUserUnit());
        } else if (this.userService.isTeacher()) {
            this.getTeacherClasses(this.userService.getUserId());
            this.tempSchool = this.userService.getUserSchool();
        }
    }
    coordinatorInit() {
        if (this.userService.isUnitCoordinator()) {
            this.getUnits(this.userService.getUserSchool());
            this.unit = this.userService.getUserUnit();
            this.tempUnit = this.unit;
            this.getClasses(this.unit);
        }
    }
    getSchools() {
        this.units = [];
        this.classes = [];
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
    }
    getUnits(schoolId: number) {
        this.loadingInit = true;
        this.tempSchool = schoolId;
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loadingInit = false;
                if (!response.error) {
                    this.units = response.data as Unit[];

                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente')
            });
    }

    getClasses(unitId: number) {
        this.classes = [];
        this.students = [];
        this.tempUnit = unitId;
        this.loadingInit = true;

        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loadingInit = false;

                if (!response.error) {

                    this.classes = response.data as Class[];

                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente')
            });
    }
    getTeacherClasses(userId: number) {
        this.classes = [];
        this.students = [];
        this.tempUnit = this.userService.getUserUnit();
        this.loadingInit = true;

        this.schoolService.getTeacherClasses(userId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loadingInit = false;

                if (!response.error) {

                    this.classes = response.data as Class[];

                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente')
            });
    }

    getStudents(classId: number) {
        this.students = [];
        this.tempClassId = classId;
        this.getClassPlans(classId);
        this.loadingInit = true;
        this.studentService.getStudents(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loadingInit = false;
                if (!response.error) {
                    this.students = response.data as Student[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente')
            });
    }

    getStudentPlans(studentId: number) {
        this.loadingInit = true;
        this.studyPlanService.getStudentPlans(studentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loadingInit = false;
                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar os planos de estudo. Verifique a conexão e tente novamente')
            });
    }
    getClassPlans(classId: number) {
        this.loadingInit = true;
        this.studyPlanService.getClassPlans(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loadingInit = false;
                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar os planos de estudo. Verifique a conexão e tente novamente')
            });
    }

    getPlanId(planId: number) {
        this.planId = planId;
    }

    getStudentPerformanceReport() {

        if (this.dateBegin == "" || this.dateBegin === undefined
            || this.dateEnd == "" || this.dateEnd === undefined) {
            this.alertService.error("Selecione o INICIO e FIM !");
            return false;
        } else if (this.tempUnit == 0 || this.tempUnit === undefined) {
            this.alertService.error("Você precisa selecionar uma Unidade!");
            return false;
        } else if (this.tempClassId == 0 || this.tempClassId === undefined) {
            this.alertService.error("Você precisa selecionar uma Turma!");
            return false;
        } else if (this.planId == 0 || this.planId === undefined) {
            this.alertService.error("Você precisa selecionar um plano!");
            return false;
        } else {
            this.loadingInit = true;
            this.reportService.getStudentReportPerformance(
                this.student,
                this.tempClassId,
                this.studyPlan,
                this.dateBegin,
                this.dateEnd
            ).subscribe(res => {
                const response = res.body as ResponseApi;
                this.loadingInit = false;
                if (!response.error) {
                    this.performance = response.data as ReportPerformance;
                    this.getAverageDataByPlan();
                    this.getStudent();
                    this.alertService.success("Relatório Gerado com sucesso!");
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar os dados do relatório. Verifique a conexão e tente novamente')
            });
        }
    }

    async generateReport() {

        if (this.dateBegin == "" || this.dateBegin === undefined
            || this.dateEnd == "" || this.dateEnd === undefined) {
            this.alertService.error("Selecione o INICIO e FIM !");
            return false;
        } else if (this.report === undefined || this.report == 0) {
            this.alertService.error("Você precisa selecionar um tipo relatório!");
        } else if (this.tempSchool === undefined || this.tempSchool == 0) {
            this.alertService.error("Você precisa selecionar uma Escola!");
        } else {
            this.loadingInit = true;
            this.userdata = {
                schoolId: this.tempSchool,
                classId: this.tempClassId,
                reportId: this.report,
                unitId: this.tempUnit,
                planId: this.planId,
                studentId: this.student,
                date_begin: this.dateBegin,
                date_end: this.dateEnd
            }
            await this.getReport(this.userdata);

        }

    }

    getReportCSV(userdata: UserDataReport) {

        this.reportService.getRelatoriosCSV(userdata)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    //this.linkDownload = response.data as ReportCsv[];
                    this.loadingInit = false;
                    this.generateArr(response.data);
                    this.alertService.success("Relatório Gerado com sucesso!");
                } else {
                    this.loadingInit = false;
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loadingInit = false;
                this.alertService.error('Houve um erro ao carregar os dados do relatório. . Verifique a conexão e tente novamente')
            });
    }
    getReport(UserDataReport: UserDataReport){
        this.reportService.getRelatoriosCSV(UserDataReport)
        .subscribe(res => {
            const response = res.body as ResponseApi 
            this.reportcsv = response.data as Csv[];
            if(this.reportcsv[0]){
            this.generateXls(this.reportcsv);
            this.loadingInit = false;
            }
            else{
                this.alertService.error('Não ha dados registrados , verifique se todos os campos foram prenchidos');
                this.loadingInit = false;
            }
        }) 
    }
    generateXls(csv: Csv[]) {


        const headWrite = [];
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Employee Data");

        let vet = [];
     
        switch (this.typerelat) {
            case "1": {
                let header = ["Turma", "Aluno", "Email", "Desempenho", "Média", "Participacao", "Plano de Estudos"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "2": {
                let header = ["Turma", "Aluno", "Email", "Desempenho", "Média", "Participacao", "Plano de Estudos"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "3": {
                let header = ["id_usuario", "usuario_nome", "usuario_login", "Acertos", "Erros"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "4": {
                let header = ["usuario_nome", "usuario_login", "acessos", "ultimo_acesso"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "5": {
                let header = ["Escola", "Unidade", "Turma", "Nome", "Email", "Data de Cadastro", "Ultimo Acesso"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "6": {
                let header = ["Escola", "Unidade", "Turma", "Nome", "Email", "Data de Cadastro", "Ultimo Acesso"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "7": {
                let header = ["Escola", "Unidade", "Turma", "Nome", "Email", "Data de Cadastro", "Ultimo Acesso"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "8": {
                let header = ["id", "Nome Simulado", "Aluno", "Escola", "Unidade", "Turma", "Total Questões", "Respondidas",
                    "Acertos", "Erros", "Tempo Total (Min)", "Tempo Realizado (Min)", "Id Questões", "Nome Questões", "Acerto Ou Erro"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "9": {
                const reportCvs = this.reportcsv as any;
                let header = ["id", "Nome Simulado", "Aluno", "Escola", "Unidade", "Turma", "Total Questões",
                    "Respondidas", "Acertos", "Erros", "Tempo Total (Min)", "Tempo Realizado (Min)", "Id Questões", "Nome Questões", "Acerto Ou Erro"];
                let headerRow = worksheet.addRow(header);

                let auxReport = [];

                reportCvs.forEach(studentReport => {
                    studentReport.forEach(element => {
                        auxReport.push(element);      
                    });
                });

                console.log(auxReport);

                for (let x1 of auxReport) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "10": {
                let header = ["usuario_nome", "usuario_login", "acessos", "ultimo_acesso"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "11": {
                let header = ["Nome aluno", "E-mail", "Turma", "Acessos", "Último Acesso"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }
                
                let fname = "Relatório - Frequência de acessos unidade";

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "12": {
                let header = ["Escola", "Mes", "Acessos Unicos", "Acessos Gerais"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "13": {
                let header = ["escola", "unidade", "idturma", "turma", "id_usuario", "nome", "email", "nome_simulado"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "14": {
                let header = ["escola", "unidade", "idturma", "turma", "id_usuario", "nome", "email", "nome_simulado"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "15": {
                let header = ["id_usuario", "usuario_nome", "escola", "turma", "acessos_geral", "acessos_dia", "ultimo_acesso", "usuario_criado_em"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "16": {
                let header = ["escola", "coordenador_unidade"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "17": {
                let header = ["escola", "coordenador_geral"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "18": {
                let header = ["id_usuario", "usuario_nome", "escola", "turma", "acessos_geral", "acessos_dia", "ultimo_acesso", "usuario_criado_em"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
            case "19": {
                let header = ["id_usuario", "usuario_nome", "escola", "turma", "acessos_geral", "acessos_dia", "ultimo_acesso", "usuario_criado_em"];
                let headerRow = worksheet.addRow(header);

                for (let x1 of this.reportcsv) {
                    let x2 = Object.keys(x1);
                    let temp = []
                    for (let y of x2) {
                        temp.push(x1[y])
                    }
                    worksheet.addRow(temp)
                }

                let fname = "relatorios performance"

                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                });
            }
                break;
        }

        /* for (let x1 of this.reportcsv) {
            let x2 = Object.keys(x1);
            let temp = []
            for (let y of x2) {
                temp.push(x1[y])
            }
            worksheet.addRow(temp)
        }

        let fname = "relatorios performance"

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        }); */

    }

    getTiposRelatorios() {

        this.reportService.getTiposRelatorios()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.reports = response.data;
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
    }
    setDateBegin(begin: any) {
        this.dateBegin = begin;
    }
    setDateEnd(end: any) {
        this.dateEnd = end;
    }
    generateArr(data) {
        this.linkDownload = [];
        for (let key in data) {
            this.linkDownload.push(data[key]);
        }
    }
    clearName(str: string) {
        let palavra = "";
        let text = str.split("_");
        console.info(text);
        text.forEach(element => {
            palavra += element.charAt(0).toUpperCase() + element.slice(1) + " ";
        });
        return palavra;
    }

    // Desabilitado
    setValuesDownloadReport() {
        document.getElementById('desempenho-geral')
            .setAttribute('href', this.linkDownload[0]);

        document.getElementById('frequencia-acessos')
            .setAttribute('href', this.linkDownload[1]);

        document.getElementById('nota-materia')
            .setAttribute('href', this.linkDownload[2]);

        document.getElementById('desempenho-turma')
            .setAttribute('href', this.linkDownload[3]);

        document.getElementById('video-assistido')
            .setAttribute('href', this.linkDownload[4]);


        document.getElementById('acertos-erros')
            .setAttribute('href', this.linkDownload[5]);

    }

    getReportId(report: number) {
        this.report = report;

        this.reports.forEach(element => {
            if (element.id == report) {
                this.reportDescription = element.description;
            }

        });
    }

    getAverageDataByPlan() {
        let dataReq = {
            studentId: this.student || 0,
            planId: this.planId || 0,
            begin: this.dateBegin || 0,
            end: this.dateEnd || 0
        }
        this.loading = true;
        this.reportService.getAverageDataByPlanPeriod(dataReq)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loading = false;
                if (!response.error) {
                    this.filterDays = response.data as Average;
                    console.info(this.filterDays);
                    if (this.filterDays.length == 0) {
                        this.filterDays = this.filterDaysModel;
                    }
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao carregar sua média. Verifique a conexão e tente novamente')
            });
    }

    getStudent() {
        this.students.forEach(element => {

            if (element.id == this.student) {
                this.studentData = element;
            }
        });

    }

}
