import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../auth.service';
import { ResponseApi } from '../../models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import jwt_decode from "jwt-decode";
import { ThemeService } from '../../themes/theme.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Theme } from '../../themes/theme';

@Component({
    selector: 'app-auto-signin',
    templateUrl: './auto-signin.component.html'
})
export class AutoSigninComponent implements OnInit {

    protected pass: string;
    protected email: string;
    protected signinForm: FormGroup;
    protected theme: Theme;
    @ViewChild('formEl') formEl: ElementRef;
    private token: string;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private alertService: AlertService,
        private themeService: ThemeService,
        public titleService: Title,
        private formBuilder: FormBuilder,
        @Inject(DOCUMENT) private document: HTMLDocument) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParamMap.get("token");
        this.getTheme();
        this.login();
    }
    login() {

        this.authService.authenticateLoginServer(this.token).subscribe(
            res => {
                const response = res.body as ResponseApi;
                console.info(response);
                if (!response.error) {
                    this.router.navigate(['dashboard']);
                } else {
                    this.alertService.error(response.error);
                    // nono animation
                    this.router.navigate(['signin']);
                    this.formEl.nativeElement.classList.add('validate-error');
                    setTimeout(() => {
                        this.formEl.nativeElement.classList.remove('validate-error');
                    }, 1000);
                }
            },
            err => {
                this.alertService.error('Falha de comunicação com a API!');
            }
        );
    }
    getTheme() {
        const domain = window.location.hostname;
        this.themeService.getTheme(domain)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.theme = response.data as Theme;

                    this.themeService.setThemeData(this.theme);
                    this.titleService.setTitle(this.theme.name);
                    if (this.theme.favicon) {
                        this.document.getElementById('appFavicon').setAttribute('href', this.theme.favicon);
                    }
                } else {
                    this.alertService.error(response.error);
                }

            }, err => this.alertService.error('Houve um erro ao carregar o tema. Verifique a conexão e tente novamente'));
    }


    goToRecovery() {
        this.router.navigate(['/signin/recovery']);
    }
}
