import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ConfigServiceService } from 'src/app/modules/admin/my-configuration/config-service.service';
import { ModalInit } from 'src/app/modules/admin/my-configuration/modal-init/modal-init';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
@Component({
  selector: 'app-modal-video-layout',
  templateUrl: './modal-video-layout.component.html',
  styleUrls: ['./modal-video-layout.component.scss']
})
export class ModalVideoLayoutComponent implements OnInit {

  public modal: ModalInit;
  public modalSave: ModalInit;
  public iframeLink: string | SafeUrl

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   public alertService: AlertService,
   public configurationService: ConfigServiceService,
   public layoutService: LayoutService,
   private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if(this.data.modal){
      //console.log(this.data.modal , 'modal de entrada');
      this.modal = this.data.modal as ModalInit;
      this.iframeLink =  this.sanitizer.bypassSecurityTrustResourceUrl(this.modal.iframeLink  as string);

      if(this.modal.dismissAuto){
        window.localStorage.setItem('closeModal', 'true');
      }
    }
  }
  closeDialog(){
      window.localStorage.setItem('closeModal', 'true');
  }

  openBtnLink(){
    if(this.modal.buttonLinkNewTab){
      window.open(this.modal.buttonLink , '_blank');
    }
    else{
      window.open(this.modal.buttonLink , '_self');
    }
  }

  saveModal(){
    this.modalSave.small_desc = "Nota Máxima de cara nova!";
    this.modalSave.title = "Conheça o novo layout da plataforma!";
    this.modalSave.iframeLink = "https://player.vimeo.com/video/601804675?h=cb2ed4338d&color=1abc9c&title=0&byline=0&portrait=0";
    this.modalSave.buttonLink = "https://plataforma.notamaxima.com.br";
    this.modalSave.buttonName = "QUERO CONFERIR AGORA!";
    this.modalSave.dismissName = "Não tenho interesse";
  }

}
