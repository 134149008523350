// tslint:disable: deprecation
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Schedule } from 'src/app/modules/admin/study-plan/schedule';
import { ScheduleDay } from 'src/app/modules/admin/study-plan/schedule-day';
import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';

import { ScheduleMonth } from './schedule-month';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Holidays } from './holidays';
import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { Classroom } from 'src/app/modules/classroom/classroom';
import { UserService } from 'src/app/modules/user/user.service';
import { RecordedClassroom } from 'src/app/modules/admin/classroom/record-link';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RecordedClassroomDialogComponent } from 'src/app/modules/classroom/recorded-classroom-dialog/recorded-classroom-dialog.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
    selector: 'app-study-plan-calendar',
    templateUrl: './study-plan-calendar.component.html',
    styleUrls: ['./study-plan-calendar.component.scss']
})
export class StudyPlanCalendarComponent implements OnInit, OnDestroy {

    mobileQuery: MediaQueryList;
    private mobileQueryListener: () => void;

    @Input() schedule: Schedule;
    @Input() planId: number;
    @Input() releaseCalendarVideosUpToday: boolean = false;
    @Output() scheduleDay: EventEmitter<ScheduleDay> = new EventEmitter();


    public days: any[] = [];
    public startDate: Date;
    public endDate: Date;
    public months: ScheduleMonth[];
    public scheduleMonthsGroup: any[];
    public today = new Date();
    public tabIndex = 0;
    public daysWithVideos: number[] = [];
    public loading: boolean;
    public classrooms: Classroom[];
    public actualMonth: any;
    public dayOfToday: number;

    public colIndex = 0;

    private holidays: Holidays[];

    public monthsOfYear = {
      'janeiro'		: 0,
      'fevereiro'	: 1,
      'março'			: 2,
      'abril'			: 3,
      'maio'			: 4,
      'junho'			: 5,
      'julho'			: 6,
      'agosto'		: 7,
      'setembro'	: 8,
      'outubro'		: 9,
      'novembro'	: 10,
      'dezembro'	: 11
    }

    constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        private studyPlanService: StudyPlanService,
        private userService: UserService,
        private classroomService: ClassroomService,
        private bottomSheet: MatBottomSheet,
        private alertService: AlertService,
        public layoutService: LayoutService) {

        this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
        this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    ngOnInit(): void {

        //console.log("releaseCalendarVideosUpToday: ", this.releaseCalendarVideosUpToday);
        let date = new Date;
        if(date){
          this.actualMonth = date.getMonth();
          this.dayOfToday = date.getDate();
        }
        else{
          this.actualMonth = 0;
          this.dayOfToday = 1;
        }
        this.loading = true;
        this.scheduleMonthsGroup = [];
        this.months = [];
        this.getHolidaysPlan();
        this.getClassrooms(this.planId);

    }

    init() {
        this.initializeStartDay();
        this.endDate = this.getEndDate();
        this.calculeRequiredMonths();
        this.populateMonths();
        this.formatScheduleMonthsGroup();
        this.handleTabIndexChange(this.tabIndex);
        this.loading = false;
    }

    getHolidaysPlan() {
        this.studyPlanService.getHolidaysPlan(this.planId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.holidays = response.data as Holidays[];

                    this.holidays.map(h => {
                        h.month = Number(h.month);
                        h.days = h.days.map(d => Number(d));

                        return h;
                    });
                }

                this.init();
            },
                err => this.init());
    }

    // pega os dias do mês, pra saber se é 30 ou 31 dias
    monthDays(date: Date) {
        const year = date.getFullYear();
        const month = date.getMonth()+1;
        const days = new Date(year, month, 0).getDate();
        return new Array(days);
    }

    groupColumns(scheduleDays: ScheduleDay[]) {
        const newRows = [];

        for (let i = 0; i < scheduleDays.length; i += 7) {
            newRows.push(scheduleDays.slice(i, i + 7));
        }

        return newRows;
    }

    formatScheduleMonthsGroup() {
        this.months.map(m => {
            this.scheduleMonthsGroup.push(this.groupColumns(m.scheduleDays));
        });
    }

    initializeStartDay() {
        const stdt = this.schedule.startDate as string;
        const momentStdt = moment(stdt);
        this.startDate = momentStdt.toDate();

        this.calcCurrentTabIndex(this.startDate.getMonth(), new Date().getMonth());

        while (this.schedule.weekDays.indexOf(this.startDate.getDay()) === -1) {
            this.startDate.setDate(this.startDate.getDate() + 1);
        }
    }

    calcCurrentTabIndex(startMonth: number, currentMonth: number) {
        this.tabIndex = currentMonth - startMonth;
    }

    getEndDate(): Date {

        const momentEndDate = moment(this.startDate.toString());

        const endDate = momentEndDate.toDate();

        this.schedule.days.map(d => {

            while (this.schedule.weekDays.indexOf(endDate.getDay()) === -1) {
                endDate.setDate(endDate.getDate() + 1);
            }

            endDate.setDate(endDate.getDate() + 1);
        });

        // fix temporario para resolver quando a ultima aula cai em um dia primeiro
        endDate.setDate(endDate.getDate() + 10);

        let holidays = 0;

        this.holidays.forEach(h => {
            holidays += h.days.length;
        });

        endDate.setDate(endDate.getDate() + holidays + 1);

        return endDate;
    }

    calculeRequiredMonths() {
        let months: number;
        let years: number;

        years = (this.endDate.getFullYear() - this.startDate.getFullYear());

        if (years === 0) {
            months = this.endDate.getMonth() - this.startDate.getMonth() + 1;
        } else if (years >= 1) {
                months = this.endDate.getMonth() - this.startDate.getMonth() + 1 + (12 * years);
        }

        const date = new Date(this.startDate.toDateString());


        for (let i = 0; i < months; i++) {
            const monthName = date.toLocaleDateString('default', { month: 'long' });
            const month: ScheduleMonth = { month: monthName, scheduleDays: this.monthDays(date) };
            this.months.push(month);

            if(date.getMonth() === 0){
                date.setDate(date.getDate() + 28);
            }else{
                const  ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
                if(ultimoDia === date.getDate()){
                    date.setDate(date.getDate() + 3);
                }else{
                   date.setMonth(date.getMonth() + 1);
                }
            }
        }

        if (months === 0) {
            const monthName = date.toLocaleDateString('default', { month: 'long' });
            const month: ScheduleMonth = { month: monthName, scheduleDays: this.monthDays(date) };
            this.months.push(month);
        }

    }

    private getMonthHolidays(month: number) {
        let holidays: number[] = [];

        this.holidays.forEach(holiday => {
            if (holiday.month === month) {
                holidays = holiday.days;
            }
        });

        return holidays;
    }

    populateMonths() {
        this.schedule.weekDays.map(d => this.daysWithVideos.push(Number(d)));
        const day = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1);
        let prevMonth = day.getMonth();
        let monthIndex = 0;
        let i = day.getDay();

        let monthHolidays = this.getMonthHolidays(this.startDate.getMonth() + 1);

        // populate the empty early days
        while (day.getDate() < this.startDate.getDate()) {
            this.months[monthIndex].scheduleDays[i] = {
                day: day.getDate(), date: new Date(day), remaining: null, videos: null, scheduleDay: null
            };
            day.setDate(day.getDate() + 1);
            i++;
        }

        // populate days with videos
        this.schedule.days.forEach(d => {

            d.day = day.getDate();

            // populate weeked days

            // skip day
            while (this.daysWithVideos.indexOf(day.getDay()) === -1 || monthHolidays.indexOf(day.getDate()) !== -1) {
                this.months[monthIndex]
                    .scheduleDays[i] = {
                    day: day.getDate(),
                    date: new Date(day),
                    remaining: null,
                    videos: null,
                    scheduleDay: null
                };

                day.setDate(day.getDate() + 1);
                i++;
                d.day = day.getDate();

                // verify change month
                if (day.getMonth() !== prevMonth) {
                    prevMonth = day.getMonth();
                    monthIndex++;
                    i = day.getDay();
                    monthHolidays = this.getMonthHolidays(day.getMonth() + 1);
                }
            }

            // add day video
            d.date = new Date(day);
            this.months[monthIndex].scheduleDays[i] = d;
            day.setDate(day.getDate() + 1);
            i++;

            // verify change month
            if (day.getMonth() !== prevMonth) {
                prevMonth = day.getMonth();
                monthIndex++;
                i = day.getDay();
                monthHolidays = this.getMonthHolidays(day.getMonth() + 1);
            }

        });

        // populate (month days - 1) empty early days

        if (monthIndex < this.months.length) {

            while (day.getDate() < this.monthDays(day).length) {
                this.months[monthIndex].scheduleDays[i] = {
                    day: day.getDate(), date: new Date(day), remaining: null, videos: null, scheduleDay: null
                };
                day.setDate(day.getDate() + 1);
                i++;
            }
            // populate last day
            this.months[monthIndex].scheduleDays[i] = {
                day: day.getDate(), date: new Date(day), remaining: null, videos: null, scheduleDay: null
            };
        }
    }

    openDaySchedule(day: ScheduleDay, i: number) {
        /* this.scheduleDay.emit(day); */
        this.router.navigate(['/video/list', this.planId, day.scheduleDay, i]);
    }

    ngOnDestroy() {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this.mobileQueryListener);
    }

    colIndexCalc() {
        if (this.colIndex === 7) {
            this.colIndex = 0;
        }
        return this.colIndex++;
    }

    getClassrooms(planId: number) {
        this.classroomService.getClassroms(planId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classrooms = response.data as Classroom[];
                }
            });
    }

    isToday(day: Date) {
        if (day) {
            return day.getDate() === this.today.getDate() &&
                day.getMonth() === this.today.getMonth() &&
                day.getFullYear() === this.today.getFullYear();
        }
        return false;
    }

    openLink(link: string) {
        window.open(link, '_blank');
    }

    async handleTabIndexChange(index: number) {

        const monthDays = this.months[index].scheduleDays;
        const lastDay = [...monthDays].pop();

        const res = await this.classroomService.getMonthClassroomRecord(this.planId, lastDay.date as Date).toPromise();
        const response = res.body as ResponseApi;

        if (!response.error) {
            const recordLink = response.data as RecordedClassroom[];
            this.classroomRecordMonthOrganizer(recordLink, monthDays);
        }

    }

    classroomRecordMonthOrganizer(recordLink: RecordedClassroom[], monthDays: ScheduleDay[]) {
        monthDays = monthDays.map(day => {
            const record = recordLink.find(rl => {
                const rlDate = moment(rl.initDate, 'DD-MM-YYYY').toDate();
                return rlDate.getDate() == (day.date as Date).getDate();
            });
            day.haveRecordLink = record ? true : false;
            return day;
        });
    }

    openRecordedClassroomsDay(date: Date) {
        this.classroomService.getDayClassroomRecord(this.planId, date)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.bottomSheet.open(RecordedClassroomDialogComponent, { data: response.data as RecordedClassroom[] });
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.alertService.error('Houve um erro ao carregar as aulas gravadas. Verifique a conexão e tente novamente');
            });
    }
}



