import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { environment } from 'src/environments/environment';

import { VideoRepository } from '../video-repository';

@Component({
    selector: 'app-repository-video-watch',
    templateUrl: './repository-video-watch.component.html',
    styleUrls: ['./repository-video-watch.component.scss']
})
export class RepositoryVideoWatchComponent implements OnInit {

    public code: string;
    public name: string;
    public baseVideoUrl = environment.VideoUrl;
    public videoUrl: string;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public video: VideoRepository,
        @Optional() public dialogRef: MatDialogRef<RepositoryVideoWatchComponent>,
        private route: ActivatedRoute,
        public deviceService: DeviceDetectorService,
        public layoutService : LayoutService) { }

    ngOnInit(): void {
        if (!this.video) {
            this.code = this.route.snapshot.paramMap.get('code');
            this.name = this.route.snapshot.paramMap.get('name');
        } else {
            this.code = this.video.link;
            this.name = this.video.name;
        }
        this.videoUrl = !isNaN(Number(this.code)) ? this.baseVideoUrl.vimeo + this.code : this.baseVideoUrl.youtube + this.code;
    }

    close() {
        this.dialogRef.close();
    }


}
