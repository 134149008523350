import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/Safe.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { LayoutService } from './services/layout.service';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertService } from './services/alert.service';

@NgModule({
    declarations: [SafePipe, ShortNamePipe],
    imports: [ CommonModule, LayoutModule, MatSnackBarModule],
    exports: [SafePipe, ShortNamePipe, MatSnackBarModule],
    providers: [LayoutService, AlertService],
})
export class SharedModule {}
