<div class="row" *ngIf="!editMode">
    <div class="col-12 col-md-12 col-lg-6 col-xl-6" *ngIf="userService.isAdmin()">
        <mat-form-field class="width-100">
            <mat-select placeholder="Escola" (selectionChange)="getUnits($event.value)">
                <mat-option>Selecione uma escola</mat-option>
                <mat-option *ngFor="let school of schools" [value]="school.id">
                    <span [title]="school.name">{{school.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-12 col-md-12 col-lg-6 col-xl-6" *ngIf="userService.isAdmin() || this.userService.isGeneralCoordinator()">
        <mat-form-field class="width-100">
            <mat-select placeholder="Unidade" (selectionChange)="getTeacherUnit($event.value)">
                <mat-option>Selecione uma unidade</mat-option>
                <mat-option *ngFor="let unit of units" [value]="unit.id">
                    <span [title]="unit.name">{{unit.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-12 col-md-12 col-lg-6 col-xl-6" *ngIf="!userService.isTeacher()">
        <mat-form-field class="width-100">
            <mat-select placeholder="Professor" (selectionChange)="getClassesByTeacher($event.value)">
                <mat-option>Selecione um professor</mat-option>
                <mat-option *ngFor="let teacher of teachers" [value]="teacher.teacherId">
                    <span [title]="teacher.teacherName">{{teacher.teacherName}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6">
        <mat-form-field class="width-100">
            <mat-select placeholder="Turma" [(ngModule)]="classId" (selectionChange)="checkClass($event.value)">
                <mat-option disabled>Selecione uma turma</mat-option>
                <mat-option [value]="0">Todas</mat-option>
                <mat-option *ngFor="let class of classes" [value]="class.id">
                    <span [title]="class.name">{{class.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6">
        <mat-form-field class="width-100">
            <mat-select placeholder="Plano" [(ngModel)]="planId" (selectionChange)="checkPlans($event.value)">
                <mat-option disabled>Selecione um plano</mat-option>
                <mat-option [value]="0">Todos</mat-option>
                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                    <span [title]="plan.name">{{plan.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-12">
        <mat-form-field class="width-100">
            <mat-select placeholder="Aluno" [(ngModule)]="studentId" (selectionChange)="getStudentsTasks($event.value)">
                <mat-option *ngFor="let student of students" [value]="student.id">
                    <span [title]="student.name">{{student.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6" *ngIf="userService.isAdmin() || this.userService.isGeneralCoordinator()">
        <mat-form-field class="width-100">
            <mat-select placeholder="Tarefas">
                <mat-option>Selecione uma Tarefa</mat-option>
                <mat-option *ngFor="let task of selectedTasks" [value]="task.taskId">
                    <span [title]="task.taskName">{{task.taskName}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6 mt-3" *ngIf="userService.isAdmin() || this.userService.isGeneralCoordinator()">
    <div *ngIf="userService.isUnitCoordinator() || userService.isGeneralCoordinator()">
        <div>
            <button (click)="checkUser()" nbButton size="small" status="primary" [disabled]="loading" nbSpinnerStatus="warning" class="right bg-color-accent-300 border-accent">Ver Minhas Tarefas Cadastradas</button>
        </div>
    </div>
    </div>
</div>


<div class="center" *ngIf="editMode">

    <div *ngIf="!loading" class="row">

    <div class="col-md-6">

        <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
        <span *ngIf="task.done == 0" class="page-title mt-3">CORRIGIR TAREFA</span>
        <span *ngIf="task.done == 1" class="page-title mt-3">VER TAREFA</span>
        <div class="mt-3 mb-3 description-title">
            <h4 *ngIf="!layoutService.isMobile">{{task.taskName}}</h4>
            <h6 class="mb-0" *ngIf="layoutService.isMobile">{{task.taskName}}</h6>
        </div>
        </div>

        <div  class="mr-3"><strong>Nome:</strong> {{task.name}}</div>
        <div  class="mr-3"><strong>Turma:</strong> {{task.className}}</div>
        <div  class="mr-3"><strong>Data de entrega:</strong>  {{task.finalDate | date: 'dd/MM/yyyy'}}</div>
        <div *ngIf="isTaskAfile" class="mt-3">
            <a mat-button (click)="openFile(task.taskDoneLink)" color="accent">
                <mat-icon>attach_file</mat-icon> Abrir tarefa
            </a>
        </div>
    </div>
    <div class="col-md-6">
        <form [formGroup]="formCorrect">
            <div class="mt-3">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nota" formControlName="score" [value]="task.score">
                </mat-form-field>
                <div class="row mt-3">
                    <div class="col-md-12" *ngIf="task.feedback">
                        <span class="page-title title-feed"><b><i>Feedback</i></b><br></span>
                        <div class="feed border-accent">{{task.feedback}}</div>
                    </div>
                </div>
                <mat-form-field class="width-100" *ngIf="!task.feedback">
                    <input matInput [placeholder]="task.feedback || 'Feedback'" formControlName="feed" [value]="task.feedback">
                </mat-form-field>
                <div class="row mt-3">
                    <div class="col-md-12 annex" *ngIf="task.taskLink">
                        <a mat-button (click)="openLink(task.taskLink)" color="accent">
                            <mat-icon>attach_file</mat-icon> Arquivo Anexado
                        </a>
                        <button class="ml-1" size="tiny" (click)="removeLink()" status="danger" nbButton ghost>
                            <nb-icon icon="trash"></nb-icon>
                        </button>
                    </div>
                    <div class="col-md-12 annex" *ngIf="!task.taskLink">
                        <input (change)="handleFileInput($event.target.files);" class="width-100" accept="" fullWidth status="primary" type="file" nbInput placeholder="Anexar Arquivo">
                    </div>
                </div>
            </div>
            <button *ngIf="task.done == 0" (click)="sendCorrection(task.studentId, task.taskId)" mat-raised-button color="accent"
                class="small-btn width-100 mr-3" [ngClass]="{'mt-4': !isTaskAfile, 'mt-5': isTaskAfile}"> Enviar correção
            </button>
            <button *ngIf="task.done == 1" (click)="sendCorrection(task.studentId, task.taskId)"  mat-raised-button color="accent"
                class="small-btn width-100 mr-3" [ngClass]="{'mt-4': !isTaskAfile, 'mt-5': isTaskAfile}"> Alterar correção
            </button>
        </form>
    </div>

    <div *ngIf="!isTaskAfile" class="col-md-6 word-break" [ngClass]="{'mt-4 mb-3': layoutService.isMobile }">
        <div class="student-task-text p-3">
            <h4 class="mb-2">Texto do Aluno</h4>
            <br />
            <p *ngFor="let paragraph of taskTextParagraphs" >&emsp;{{paragraph}}</p>
        </div>
    </div>

    </div>

</div>


<div class="mt-5" *ngIf="!loading && !editMode && tasks.length > 0">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-4 col-xl-4 n-mobile"><b>Tarefa</b></div>
        <div class="col-12 col-md-12 col-lg-3 col-xl-3 n-mobile"><b>Aluno</b></div>
        <div class="col-8 col-md-8 col-lg-3 col-xl-3 n-mobile"><b>Data de entrega</b></div>
        <div class="col-4 col-md-4 col-lg-2 col-xl-2 n-mobile text-center"><b>&nbsp;</b></div>
    </div>
    <div>
        <div *ngFor="let task of tasks" class="row" style="min-height: 100px">
            <div class="col-6 col-md-6 col-xl-3 col-lg-3">
                {{task.taskName}}
            </div>
            <div class="col-6 col-lg-4 col-md-6 col-xl-4">
                {{task.name}}
            </div>
            <div class="col-6 col-md-6 col-xl-2 col-lg-2">
                {{task.finalDate | date: 'dd/MM/yyyy'}}
            </div>
            <div *ngIf="task.score" class="col-6 col-md-6 col-lg-3 col-xl-3 text-right">
                <button class="right status-done" (click)="correcTask(task)" nbButton size="small">Ver</button>
            </div>
            <div *ngIf="!task.score" class="col-6 col-md-6 col-lg-3 col-xl-3 text-right">
                <button class="right status-waiting" (click)="correcTask(task)" nbButton size="small" status="primary">Corrigir</button>
            </div>
        </div>
    </div>

</div>