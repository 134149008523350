<div *ngIf="!comingFromLibrary">
    <div class="col-md-9 ml-4"><label class="title">Nova Questão</label></div>
    <div class="col-md-1">
        <button (click)="createQuestion(questionName)" nbButton size="medium" status="primary " class="corpadraoBtn bg-color-primary border-primary" [ngClass]="{'ml-4': deviceService.isMobile()}" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>


<div class="row mt-4" *ngIf="comingFromLibrary">
    <div *ngIf="!questionConfig.edit" class="col-md-8 ml-4"><label class="title">Nova Questão</label></div>
    <div *ngIf="questionConfig.edit" class="col-md-8 ml-4"><label class="title">Editar Questão</label>
        <p><label class="subtitleTeste">Codigo da questao : {{questionConfig.question.code}}</label></p>
    </div>
    <div class="col-md-1" [ngClass]="{'ml-4 col-md-12': deviceService.isMobile()}" *ngIf="!questionConfig.edit">
        <button (click)="createQuestion(questionName)" nbButton size="medium" status="primary " class="corpadraoBtn" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
    <div class="col-md-1" [ngClass]="{'ml-4 col-md-12': deviceService.isMobile()}" *ngIf="questionConfig.edit">
        <button (click)="updateQuestion()" nbButton size="medium" status="primary " class="corpadraoBtn" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
    <div class="col-md-1 ml-4">
        <button class="btncancel" nbButton size="medium" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cancelar</button>
    </div>
</div>

<!-- <div class="row">
    <div class="mt-3 ml-4 col-md-6">
        <input placeholder="Nome da Questao" [(ngModel)]="questionName" [ngClass]="{'textbox': !deviceService.isMobile() , 'textboxMobile' : deviceService.isMobile()}">
    </div>
</div> -->

<div class="row mt-5">
    <div class="col-md-6">
        <mat-tab-group dynamicHeight class="width-90 center" backgroundColor="accent">
            <mat-tab label="Enunciado">
                <ckeditor [editor]="questionEditor" [config]="editorConfig" [(ngModel)]="exerciseText" class="width-100">
                </ckeditor>
            </mat-tab>

            <!--      <mat-tab *ngFor="let alternative of alternatives" [label]="alternative.label">
                <ckeditor [editor]="aEditor" [config]="editorConfig" [(ngModel)]="alternative.value" class="width-100">
                </ckeditor>
            </mat-tab> -->

            <!--   <mat-tab label="A" ngFor>
                    <ckeditor [editor]="aEditor" [config]="editorConfig" [(ngModel)]="A.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="B">
                    <ckeditor [editor]="bEditor" [config]="editorConfig" [(ngModel)]="B.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="C">
                    <ckeditor [editor]="cEditor" [config]="editorConfig" [(ngModel)]="C.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="D">
                    <ckeditor [editor]="dEditor" [config]="editorConfig" [(ngModel)]="D.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="E">
                    <ckeditor [editor]="eEditor" [config]="editorConfig" [(ngModel)]="E.value" class="width-100">
                    </ckeditor>
                </mat-tab> -->
            <mat-tab label="Resolução">
                <ckeditor [editor]="commentEditor" [config]="editorConfig" [(ngModel)]="answer_comment" class="width-100">
                </ckeditor>
            </mat-tab>
        </mat-tab-group>
    </div>


    <div class="col-md-6">
        <form [formGroup]="createFormQuestion">
            <div>
                <div>
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Selecione o tipo da questão" formControlName="question_type">
                            <mat-option *ngFor="let questionType of questionTypes" (click)="setTypeQuestion(questionType.name)" [value]="questionType.id">
                                {{questionType.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="!isSummation">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select formControlName="answer" placeholder="Gabarito">
                            <mat-option value="a">A</mat-option>
                            <mat-option value="b">B</mat-option>
                            <mat-option value="c">C</mat-option>
                            <mat-option value="d">D</mat-option>
                            <mat-option value="e">E</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isSummation">
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select formControlName="answerSummation" multiple placeholder="Gabarito">
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="4">4</mat-option>
                            <mat-option value="8">8</mat-option>
                            <mat-option value="16">16</mat-option>
                            <mat-option value="32">32</mat-option>
                            <mat-option value="64">64</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="width-80">
                        <mat-select placeholder="Selecione a disciplina" formControlName="discipline_id">
                            <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                {{discipline.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <a><img class="ml-2" (click)="createDiscipline()" src="assets/icons/addBtn.png"></a>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="width-80">
                        <mat-select placeholder="Selecione o tema" formControlName="theme_id">
                            <mat-option *ngFor="let theme of themes" [value]="theme.id">
                                {{theme.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <a><img class="ml-2" (click)="createTheme()" src="assets/icons/addBtn.png"></a>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="width-80">
                        <mat-select placeholder="Selecione o subtema" formControlName="sub_theme">
                            <mat-option *ngFor="let subTheme of subThemes" [value]="subTheme.id">
                                {{subTheme.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <a><img class="ml-2" (click)="createTheme()" src="assets/icons/addBtn.png"></a>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="width-100">
                        <mat-select placeholder="Selecione a instituição" formControlName="institution_id">
                            <mat-option *ngFor="let institution of institutions" [value]="institution.id">
                                {{institution.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="width-100">
                        <input matInput placeholder="Digite o ano" formControlName="year" type="number">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div class="col-md-6" *ngIf="!isSummation">
        <!--    <a (click)="addAlternative()" class="ml-5"><img src="assets/icons/addBtn.png"></a>
        <a size="tiny" status="danger" (click)="removeAlternative()" nbButton ghost>
            <img src="assets/icons/deleteBtn.png">
        </a> -->
        <mat-tab-group dynamicHeight class="width-90 center mt-3" backgroundColor="accent">

            <mat-tab *ngFor="let alternative of alternatives" [label]="alternative.option">
                <ckeditor [editor]="aEditor" [config]="editorConfig" [(ngModel)]="alternative.value" class="width-100">
                </ckeditor>
            </mat-tab>

            <!--   <mat-tab label="A" ngFor>
                    <ckeditor [editor]="aEditor" [config]="editorConfig" [(ngModel)]="A.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="B">
                    <ckeditor [editor]="bEditor" [config]="editorConfig" [(ngModel)]="B.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="C">
                    <ckeditor [editor]="cEditor" [config]="editorConfig" [(ngModel)]="C.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="D">
                    <ckeditor [editor]="dEditor" [config]="editorConfig" [(ngModel)]="D.value" class="width-100">
                    </ckeditor>
                </mat-tab>
                <mat-tab label="E">
                    <ckeditor [editor]="eEditor" [config]="editorConfig" [(ngModel)]="E.value" class="width-100">
                    </ckeditor>
                </mat-tab> -->
        </mat-tab-group>
    </div>

    <div class="col-md-6" *ngIf="isSummation">
        <mat-tab-group dynamicHeight class="width-90 center" backgroundColor="accent">
            <mat-tab *ngFor="let alternative of summationAlternatives" [label]="alternative.option">
                <ckeditor [editor]="editor1" [config]="editorConfig" [(ngModel)]="alternative.value" class="width-100">
                </ckeditor>
            </mat-tab>
            <!--   <mat-tab label="2">
                <ckeditor [editor]="editor2" [config]="editorConfig" [(ngModel)]="alt2.value" class="width-100">
                </ckeditor>
            </mat-tab>
            <mat-tab label="4">
                <ckeditor [editor]="editor4" [config]="editorConfig" [(ngModel)]="alt4.value" class="width-100">
                </ckeditor>
            </mat-tab>
            <mat-tab label="8">
                <ckeditor [editor]="editor8" [config]="editorConfig" [(ngModel)]="alt8.value" class="width-100">
                </ckeditor>
            </mat-tab>
            <mat-tab label="16">
                <ckeditor [editor]="editor16" [config]="editorConfig" [(ngModel)]="alt16.value" class="width-100">
                </ckeditor>
            </mat-tab>
            <mat-tab label="32">
                <ckeditor [editor]="editor32" [config]="editorConfig" [(ngModel)]="alt32.value" class="width-100">
                </ckeditor>
            </mat-tab>
            <mat-tab label="64">
                <ckeditor [editor]="editor64" [config]="editorConfig" [(ngModel)]="alt64.value" class="width-100">
                </ckeditor>
            </mat-tab> -->
        </mat-tab-group>
    </div>
</div>