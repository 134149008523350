import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../../admin.service';
import { StudyPlan } from '../../../study-plan/study-plan';
import { StudyPlanService } from '../../../study-plan/study-plan.service';
import { Class } from '../../class';
import { School } from '../../school';
import { SchoolService } from '../../school.service';
import { Unit } from '../../unit';

interface DataStructure {
    classe  : any, 
    schools : any,
    units   : any,
    classes :any,
    editMode  : boolean
}

@Component({
  selector: 'app-edit-class',
  templateUrl: './edit-class.component.html',
  styleUrls: ['./edit-class.component.scss']
})

export class EditClassComponent implements OnInit {
    [x: string]: any;
    public createClassForm: FormGroup;
    public removeClassForm: FormGroup;
    public showClassesList: boolean;
    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public plans: StudyPlan[];
    public selectedPlans: StudyPlan[];
    public editMode: boolean =  false;
    public unitId: number;
    public loading: boolean;
    private admUpdate$: Subscription;
    private admUpdateRemove$: Subscription;
    public deleting: boolean;
    public remove: boolean =  false;
    public  classe: Class;
    public  classeOld: Class;
    public success: boolean;
    public close: boolean;
    public classId: number;
    public showMigrationClass: boolean;
    public hideButton: boolean;
    public migrationClass: boolean;
    
    
    constructor( 
     
        @Optional() @Inject(MAT_DIALOG_DATA) public  data: DataStructure, 
        @Optional() public dialogRef: MatDialogRef<EditClassComponent>, 
        private dialog: MatDialog,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formbBuilder: FormBuilder,
        private studyPlanService: StudyPlanService,
        public deviceService: DeviceDetectorService,
        private adminService: AdminService,
        public userService: UserService) { 
            this.classe = this.data.classe;
            this.classeOld = this.data.classe;
            this.schools = this.data.schools;
            this.units = this.data.units;
            this.classes = this.data.classes;        

            if(this.data.editMode){
                this.editMode = this.data.editMode;
            }else{
                this.remove = true;
            }
        }


    ngOnInit(): void { 
        this.selectedPlans = [];
        this.admUpdate$ = this.adminService.getUpdateSubject()
        .subscribe(() => this.initEdit()); 

        if(this.userService.isUnitCoordinator()){
            this.getUnitPlans(this.userService.getUserUnit());
        }
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe(); 
    }

    openList() {
        // this.getClasses();
        this.classes = [];
        this.showClassesList = true;
    }
    

    initEdit(classe?:any) {
        this.createClassForm = this.formbBuilder.group({
            id: this.classe.id,
            name: this.classe.name,
            unit: this.classe.unit.id,
            school: this.classe.school,
            plans: ['']
        });
        this.migrationClassForm = this.formbBuilder.group({
            idClassMigration: this.classe.id,
            nameMigration: [''],
            unitMigration: this.classe.unit.id,
            schoolMigration: this.classe.school,
            plansMigration: ['']
        });
        
        if (this.userService.isAdmin()) {
            this.getSchools();
            this.getPlans(Number(this.classe.school));
            
        } else if (this.userService.isGeneralCoordinator()) {
            this.coordinatorInit();
            this.getPlans(this.userService.getUserSchool());
        }
        
        this.getOneClassTotal(this.classe.id);
        
        if (this.classe ) { this.initializeEditMode(classe); } 
    }

    
    initializeEditMode(classe?:any) {
        const classUnit = this.classe.unit as Unit;
        this.createClassForm.patchValue(this.classe);
        const schoolId = this.classe.school as number;
        this.createClassForm.get('school').setValue(schoolId);
        this.createClassForm.get('unit').setValue(classUnit.id);
        this.getUnits(schoolId);
        this.getClassPlans(this.classe.id); 
        //this.getPlans(this.createClassForm.get('school').value);
    }

    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            this.createClassForm.get('school').setValue(this.userService.getUserSchool());
            this.getUnits(this.userService.getUserSchool());
        }
    }
    
    getOneClassTotal(id:number) {
        this.schoolService.getOneClassTotal(id)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.classe = response.data as Class;
                    //console.log(this.classe , 'classe do metodo');
                    if(this.classe.total == 0){
                        this.success = true;
                    }else{
                        this.success = false;
                    }                
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve o total das turmas. Verifique a conexão e tente novamente'));
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getPlans(schoolId?: number) {
        this.studyPlanService.getSelectPlans(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }

    getClasses(unitId: number) {
         
        this.loading = true;
        this.unitId = unitId; 
        this.schoolService.getClasses(unitId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];

            } else {
                this.alertService.error(response.error);
            }

            this.loading = false;
        }, err => {
            this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente');
            this.loading = false;
        });
    }

    getClassPlans(classId: number) {
        this.classId = classId;
        this.studyPlanService.getClassPlansAll(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.selectedPlans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId?: number) {
        
        
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];

                    // select current unit after load units
                    if (this.editMode) {
                        const unit = this.classe.unit as Unit;
                        this.createClassForm.get('unit').setValue(unit.id);
                    }
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getUnitPlans(unitId: number) {
        this.studyPlanService.getUnitPlans(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                } else {
                    this.plans = [];
                    this.alertService.error('Não existem planos cadastrados em sua unidade.');
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos da sua unidade. Verifique a conexão e tente novamente'));
    }

    getClassesSelect(unitId?: number) {
        this.schoolService.getClassesSelect(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];

                    // select current unit after load units
                    if (this.editMode) {
                        const classe = this.classe as Class;
                        this.createClassForm.get('unit').setValue(classe.id);
                    }
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente'));
    }
    getClasseNew(newId: number){
        const id = newId;
    }

    migration(){
        this.showMigrationClass = true;
        this.migrationClass = true;
        this.hideButton = true;

    }

    updateClass() {
        let newClassId: number;
        this.createClassForm.get('id').setValue(this.classeOld.id);

        if (confirm('Deseja migrar os alunos para essa turma?')) {
            if(this.showMigrationClass){
                newClassId = this.migrationClassForm.get('idClassMigration').value;
            }else{
                newClassId = this.createClassForm.get('id').value;
            }
            if(this.classeOld.id != newClassId){
                this.schoolService.saveClassChanges(this.classeOld.id, newClassId)
                    .subscribe(res => {
                        const response = res.body as ResponseApi;

                        if (!response.error) {
                            this.alertService.success('Alterado com sucesso!');
                            this.success = true;
                            this.classe.total = 0;
                            this.dialog.closeAll();
                        } else {
                            this.alertService.error(response.error);
                        }
                        this.loading = false;

                    }, err => {
                this.alertService.error('Houve um erro ao salvar as alterações. Verifique a conexão e tente novamente');
                
                });
            }else{
                this.alertService.error('Nada foi modificado!');
                this.dialog.closeAll();
            }
            this.loading = false;
        }   
    }

    editClass(classe: Class) {
        
    }
    deleteStudents(classe: Class){
        if (confirm('Deseja excluir os alunos?')) {
            if (confirm('Deseja realmente excluir TODOS os alunos?')) {
                this.schoolService.deleteStudents(this.classe.id)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.classe.total = 0;
                        this.success = true;
                        this.alertService.success('Excluído com sucesso!');

                    } else {
                        this.alertService.error(response.error);
                    }
                    this.loading = false;

                }, err => {
                    this.alertService.error('Houve um erro ao excluir os alunos. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
            } 
        }
    }
    deleteClass(classe: Class) {
        /* this.remove = true;
        this.schoolService.getClasses(classe.id);
        //const $content = this.schoolService.totalClass(classe.id);

        const dialogRef = this.dialog.open(CreateClassComponent, {
            minWidth: '60vw',
            data: classe
        });

        dialogRef.afterOpened().subscribe(result => {
                alert('passo 2');
                this.editMode = false;
                this.remove = true; 
        });

        dialogRef.afterClosed().subscribe(result => {
            this.remove = false;
            if (result) { 
                this.getClasses(this.unitId);
            }
        }); */
    }
    removeClass() {

        if (confirm('Deseja realmente remover a Turma?')) {
            const classId = this.classe.id;
            this.schoolService.removeClass(classId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Turma removida', 3);
                        this.dialog.closeAll();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover a turma. Verifique a conexão e tente novamente'));
        }
    }
    disableClass(classId: number) {
        if (confirm('Deseja DESATIVAR a Turma?')) {
            this.schoolService.disableClass(classId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Turma Desativada', 3);
                        this.getClasses(this.unitId);
                        this.adminService.updateChields();
                        this.classe.active = 0;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao desativar a turma. Verifique a conexão e tente novamente'));
        }
    }

    enableClass(classId: number) {
        if (confirm('Deseja DESATIVAR a Turma?')) {
            this.schoolService.enableClass(classId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Turma Desativada', 3);
                        this.getClasses(this.unitId);
                        this.adminService.updateChields();
                        this.classe.active = 1;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao desativar a turma. Verifique a conexão e tente novamente'));
        }
    }
    removeClassPlans(planId:number, classId:number){
        this.schoolService.removeClassPlans(planId, classId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.alertService.success('Plano excluído');
            } else {
                this.alertService.error(response.error);
            }
            
            this.loading = false;
        }, err => {
            this.alertService.error('Houve um erro ao salvar as alterações da turma. Verifique a conexão e tente novamente');
            this.loading = false;
        });
    }
    saveChanges() {
        
        if (this.createClassForm.valid || this.migrationClassForm.valid) {

            this.loading = true;
            const plansIds = [];            
            let classe: Class;
            this.selectedPlans.map(p => plansIds.push(p.id));
            if(this.showMigrationClass){
                this.migrationClassForm.get('plansMigration').setValue(plansIds);
                classe = this.migrationClassForm.getRawValue() as Class;
                this.updateClass();
            }else{
                this.createClassForm.get('plans').setValue(plansIds);
                this.createClassForm.get('id').setValue(this.classe.id);
                classe = this.createClassForm.getRawValue() as Class;
                this.schoolService.saveChanges(classe)
                    .subscribe(res => {
                        const response = res.body as ResponseApi;

                        if (!response.error) {
                            this.alertService.success('Turma editada com sucesso', 3);
                            this.dialog.closeAll();
                            this.getClasses(this.unitId);
                            this.adminService.updateChields();
                        } else {
                            this.alertService.error(response.error);
                        }

                        this.loading = false;
                    }, err => {
                        this.alertService.error('Houve um erro ao salvar as alterações da turma. Verifique a conexão e tente novamente');
                        this.loading = false;
                });

            }            
        } else {
            this.alertService.error('Preencha todos os campos corretamente!');
        }
    }

    addPlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index < 0) {
            this.selectedPlans.push(plan);
            this.createClassForm.get('plans').reset();
        }
    }
    removePlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index >= 0) {
            this.selectedPlans.splice(index, 1);
            let planId = Number(plan.id);
            this.removeClassPlans(planId, this.classId);
        }
    }
    

}
