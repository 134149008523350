import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenFileModalComponent } from './open-file-modal.component';

@NgModule({
  declarations: [OpenFileModalComponent],
  imports: [
    CommonModule
  ],
  exports: [OpenFileModalComponent],
})
export class OpenFileModalModule { }
