import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { StudyPlan } from 'src/app/modules/admin/study-plan/study-plan';
import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

import { DashboardService } from '../../../dashboard.service';
import { Average } from './average';
import { filterDays } from './filterdays';

@Component({
    selector: 'app-average-chart',
    templateUrl: './average-chart.component.html',
    styleUrls: ['./average-chart.component.scss']
})

export class AverageChartComponent implements OnInit {

    @Input() studentId: number;
    @Output() planName: EventEmitter<string> = new EventEmitter();
    public average: Average = {
        performance: 0,
        average: 0,
        frequency: 0,
        participation: 0,
        studyPlan: 0
    };

    public filterDays = filterDays;

    public plans: StudyPlan[];
    public selectedPlan: any;
    public period: number;
    public planId = 0;
    public dateBegin = '';
    public dateEnd = '';
    public loading: boolean;

    constructor(
        private dashboardService: DashboardService,
        private userService: UserService,
        private alertService: AlertService,
        private studyPlanService: StudyPlanService,
        public layoutService: LayoutService) { }

    ngOnInit(): void {
        this.getClassPlans(this.userService.getUserClass(), this.userService.getUserId(), true);

    }

    getAverageData() {

        if (this.planId == 0) {
            this.alertService.error('Você deve selecionar um plano!');
            return false;
        } else if (this.dateBegin == '' || this.dateEnd == '') {
            this.alertService.error('Você deve selecionar o início e o fim !');
            return false;
        } else {
            this.getAverageDataByPlan();
        }

    }

    getAverageDataByPlans(planId: number, days?: number, firstLoad = false) {
        const dataReq = {
            studentId: this.userService.getUserId(),
            planId,
            period: [days]

        };
        this.loading = true;
        this.dashboardService.getAverageDataByPlanPeriod(dataReq)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loading = false;
                if (!response.error) {
                    this.filterDays.averageResult = response.data as Average[];
                    if (firstLoad) {
                        this.filterDays.average = this.average;
                    } else {
                        this.filterDays.average = this.filterDays.averageResult[days];
                    }
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao carregar sua média. Verifique a conexão e tente novamente');
            });
    }

    getAverageDataByPlan() {
        const dataReq = {
            studentId: this.userService.getUserId() || 0,
            planId: this.selectedPlan.id || 0,
            begin: this.dateBegin || 0,
            end: this.dateEnd || 0
        };
        this.loading = true;
        this.dashboardService.getAverageDataByPlanPeriodAdm(dataReq)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loading = false;
                if (!response.error) {
                    this.average = response.data as Average;

                } else {
                    this.alertService.error(response.error);
                }

                this.dashboardService.emitLoginIsDone.emit(this.selectedPlan.id);

            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao carregar sua média. Verifique a conexão e tente novamente');
            });
    }

    getClassPlans(classId: number, studentId: number, firstLoad = false) {
        this.loading = true;
        this.studyPlanService.getClassPlans(classId, studentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loading = false;
                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                    this.selectedPlan = this.average;
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
            });
    }

    planChange(planId: number) {
        this.planId = planId;
        this.plans.forEach(plan => {
            if (planId == plan.id) {
                this.selectedPlan = plan;
                this.planName.emit(plan.name);
            }
        });
    }

}
