<div class="mt-4">
    <h4 class="font-text">Olá, {{userService.getUserName()}}!</h4>

    <div class="mt-5">
        <div *ngIf="students">
            <ng-container>
                <nb-list class="width-100" *ngIf="haveStudents">
                    <nb-list-item class="corpadrao list-header" style="color: white ">
                        <div class="row">
                            <div class="col-md-9">Nome</div>
                            <div class="col-md-1">Acessar</div>
                        </div>
                    </nb-list-item>
                    <nb-list-item *ngFor="let student of students">
                        <div class="row">
                            <div class="col-md-9">
                                {{student.name}}
                            </div>
                            <div class="col-md-1"> <button (click)="fatherLogin(student.id , student.email , student.class , student.domain , student.schoolName)" class="buttonclass" nbButton size="tiny" outline status="primary "><mat-icon size="small">done</mat-icon></button>
                            </div>
                            <div *ngIf="student.id == selectedStudent" style="margin: auto;" class="d-flex justify-content-center">
                                <div class="col max-w100" *ngFor="let integration of integrations">
                                    <div class="text-center">
                                        <a (click)="verifyLogin(integration.link)" [href]="integration.link" [target]="targetContoller">
                                            <img class="circle" [src]="integration.image" [alt]="integration.name"><br>
                                            <span class="text">{{integration.name}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
            </ng-container>
        </div>
        <div *ngIf="!haveStudents" class="m-5">
            <h2>Não há estudantes associados a este Responsável!</h2>
        </div>
    </div>
</div>