<nb-list class="center" [ngClass]="{'width-80': layoutService.isMobile, 'width-100': !layoutService.isMobile}">
    <nb-list-item class="bg-color-accent brl-5 brr-5">
        <div class="row">
            <div class="col">
                <span [ngClass]="{'text-center': layoutService.isMobile}">TURMA</span>
            </div>
            <div class="col" *ngIf="!layoutService.isMobile">
                <span class="">INÍCIO</span>
            </div>
            <div class="col" *ngIf="!layoutService.isMobile">
                <span class="">TÉRMINO</span>
            </div>
            <div class="col text-right" *ngIf="!layoutService.isMobile">
            </div>
        </div>
    </nb-list-item>
    <nb-list-item *ngFor="let classroom of classrooms | paginate: {itemsPerPage: 10, currentPage: currentPagina }" [ngClass]="{' mb-2 p-0': layoutService.isMobile}">
        <div class="width-100" [ngClass]="{'pl-3 pt-2': layoutService.isMobile}">
            <div class="row" [ngClass]="{'text-left m-0 mt-1': layoutService.isMobile}">
                <div class="col classroom-name" *ngIf="!layoutService.isMobile">
                    <mat-icon class="mr-3" color="accent" svgIcon="live"></mat-icon>
                    <span class="">{{classroom.name}}</span>
                </div>
                <div class="col-12 text-left p-0"  *ngIf="layoutService.isMobile">
                    <span class=" d-inline-flex">
                        <mat-icon class="mr-2" color="accent" svgIcon="live"></mat-icon>
                        <span class="font-weight-bold mt-2px">{{classroom.name}}</span>
                    </span>
                </div>
                <div class="col classroom-name"  [ngClass]="{'pl-0': layoutService.isMobile}">
                    <span class="">
                        <span *ngIf="layoutService.isMobile">Início</span><br>
                        {{classroom.initDate | date:'dd/MM HH:mm'}}
                    </span>
                </div>
                <div class="col classroom-name" [ngClass]="{'pl-0': layoutService.isMobile}">
                    <span class="">
                        <span *ngIf="layoutService.isMobile">Término</span><br>
                        {{classroom.endDate | date:'dd/MM HH:mm'}}
                    </span>
                </div>
                <div class="col pt-2 text-right">
                    <button color="accent" mat-mini-fab class="hvr-grow line-height-button-arrow width-30 height-30" [matTooltip]="classroom.name"
                        (click)="openClassroom(classroom.link)">
                        <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </nb-list-item>

    <!-- empty folder -->
    <nb-list-item *ngIf="!classrooms" [ngClass]="{' mb-2 p-0': layoutService.isMobile}">
        <div class="plan-not-selected" [ngClass]="{'height-auto': !layoutService.isMobile}">
            <h6>Nenhuma aula  ao vivo no momento</h6>
            <br />
            <!-- <ng-lottie class="mt-3 max-height-50" [options]="lottieOptions"></ng-lottie> -->
        </div>
    </nb-list-item>

    <nb-list-item class="text-center center">
      <pagination-controls *ngIf="classrooms" class="text-center" previousLabel="" nextLabel='' (pageChange)="paginate($event)"></pagination-controls>
    </nb-list-item>


</nb-list>


