
<div [ngClass]="{'mb-5': !layoutService.isMobile , 'mb-2': layoutService.isMobile}" class="mt-2 width-100">
  <button mat-raised-button color="accent" *ngIf="mockIsFinished" (click)="verifyShowResult()">Ver Resultado</button>
</div>
<div class="" [ngClass]="{'page-container': layoutService.isMobile}">
    <ng-container *ngIf="question && !loading && !finished">
        <!-- actions  -->
        <div *ngIf="!saving" class="row m-0 actions">
            <div class="col-6">
                <div *ngIf="mock.navigateQuestions && !firstQuestion">
                    <button class="previous-question" color="accent" (click)="prev()" matTooltipClass="custom-tooltip" matTooltip="Questão anterior">
                <span class="text-color-accent"> << </span>Questão anterior
            </button>
                </div>
            </div>
            <div *ngIf="(mock.navigateQuestions || questionsSaved[i]) && !lastQuestion" class="col-6 text-right" [ngClass]="{'col-12': firstQuestion || !mock.navigateQuestions, 'col-6': mock.navigateQuestions}">
                <button color="accent" class="next-question" (click)="next()" matTooltipClass="custom-tooltip" matTooltip="Próxima questão">
                Próxima questão <span class="text-color-accent">>></span>
            </button>
            </div>
            <div *ngIf="lastQuestion" class="col-6 text-right">
                <button class="end-question" color="accent" (click)="end()" matTooltipClass="custom-tooltip" matTooltip="Terminar" *ngIf="questionsSaved[i] && (numReplied === questions.length)">
                <span class="the-end-send">Terminar </span><mat-icon color="accent">send</mat-icon>
            </button>
            </div>
            <div *ngIf="lastQuestion" class="col-12 text-center">
                <div class="mb-2 mt-2 pt-4 pb-4 width-100" *ngIf="(numReplied < questions.length)">
                    <span class="unanswered">
                    Você possui questões não respondidas
                </span>
                </div>
            </div>
        </div>
        <!-- enunciado -->
        <div class="question mt-3 mb-2" [innerHTML]="question?.question"></div>
        <mat-divider></mat-divider>
        <div class="width-100 center" *ngIf="enumUrl">
          <!-- YouTube -->
          <iframe *ngIf="embedEnum.embed_type && embedEnum.embed_type.toLowerCase() == 'youtube'" width="100%" height="460" [src]="enumUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <!-- Vimeo -->
          <iframe *ngIf="embedEnum.embed_type && embedEnum.embed_type.toLowerCase() == 'vimeo'" [src]="enumUrl" width="100%" height="460" frameborder="0" title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
        <!-- alternativas -->
        <div class="alternatives mock mt-2 mb-2 pb-3">
            <mat-radio-group [(ngModel)]="questionsResponse[i]" class="radio-group">
                <div *ngFor="let alt of question?.alternatives" class="question-alternatives" [ngClass]="{'selected-alternative': questionsResponse[i] == alt.option, 'correct-alternative': verifyIsCorrectAlternative(i, alt.option), 'incorrect-alternative': (questionsResponse[i] == alt.option) && verifyIsIncorrectAlternative(i, alt.option)}">
                    <!--    ***************** NÃO REMOVER O NGIF ABAIXO - VALIDA SE OPÇÃO NÃO FOR NULA *****************    -->
                    <div class="row" *ngIf="alt.value" [ngClass]="{'ml-15 mr-15 mt-0 mb-0': !layoutService.isMobile, 'm-0': layoutService.isMobile}">
                        <div class="pt-1 option-size">
                            <span *ngIf="alt.option" class="option">{{alt.option}})</span>
                        </div>
                        <div class="col p-0 ">
                            <div class="row">
                                <mat-radio-button [disabled]="questionsSaved[i]" class="option-size p-0 pl-1 radio-button mat-radio" [value]="alt.option">
                                </mat-radio-button>
                                <span class=" col button-text p-relative pl-0 innerHTML-reply" [innerHTML]="alt.value"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-radio-group>
            <button *ngIf="!saving" class="hvr-grow right btn-save-question" (click)="sendQuestionResponse(question.id, questionsResponse[i])" [disabled]="!questionsResponse[i]" mat-mini-fab class="right" color="accent" [hidden]="questionsSaved[i]">
          <mat-icon>save</mat-icon>
        </button>
            <mat-spinner *ngIf="saving" [diameter]="30" class="ml-3"></mat-spinner>
        </div>
        <!-- resultado -->
        <div class="response-msg" *ngIf="questionsResponse[i] && questionsCorrect[i].correct && !mock.contest">
            <div *ngIf="!mock.contest" class="exercise-comment comment" [innerHTML]="questionsCorrect[i].comment">
            </div>
            <div class="response-msg" *ngIf="questionsResponse[i] && questionsCorrect[i].correct">
                <ng-container *ngIf="questionsResponse[i] == questionsCorrect[i].correct; else error">
                  <div class="alert alert-success" role="alert">
                      <!-- <mat-icon class="mr-2">check</mat-icon> -->
                      <span class="material-icons">done</span>
                      <span> Parabéns, você acertou! :)</span>
                  </div>
                </ng-container>
                <mat-divider></mat-divider>
                <!-- exercise resolution -->
                <div *ngIf="exerciseEmbed.embed_code" class="resolution-embed text-center">
                    <span class="resolution-title text-color-primary">Veja a resolução do exercício
                        no vídeo abaixo:</span>
                    <div class="width-100 center">
                        <!-- YouTube -->
                        <iframe *ngIf="exerciseEmbed.embed_type && exerciseEmbed.embed_type.toLowerCase() == 'youtube'" width="100%" height="460" [src]="embedCodeVideo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <!-- Vimeo -->
                        <iframe *ngIf="exerciseEmbed.embed_type && exerciseEmbed.embed_type.toLowerCase() == 'vimeo'" [src]="embedCodeVideo" width="100%" height="460" frameborder="0" title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
        <!-- actions  -->
        <div *ngIf="!saving" class="row m-0 actions">
            <div class="col-6">
                <div *ngIf="mock.navigateQuestions && !firstQuestion">
                    <button class="previous-question" color="accent" (click)="prev()" matTooltipClass="custom-tooltip" matTooltip="Questão anterior">
                <span class="text-color-accent"><<</span> Questão anterior
            </button>
                </div>
            </div>
            <div *ngIf="(mock.navigateQuestions || questionsSaved[i]) && !lastQuestion" class="col-6 text-right" [ngClass]="{'col-12': firstQuestion || !mock.navigateQuestions, 'col-6': mock.navigateQuestions}">
                <button color="accent" class="next-question" (click)="next()" matTooltipClass="custom-tooltip" matTooltip="Próxima questão">
                Próxima questão <span class="text-color-accent">>></span>
            </button>
            </div>
            <div *ngIf="lastQuestion" class="col-6 text-right">
                <button class="end-question" color="accent" (click)="end()" matTooltipClass="custom-tooltip" matTooltip="Terminar" *ngIf="questionsSaved[i] && (numReplied === questions.length)">
                <span class="the-end-send">Terminar </span><mat-icon color="accent">send</mat-icon>
            </button>
            </div>
            <div *ngIf="lastQuestion" class="col-12 text-center">
                <div class="mb-2 mt-2 pt-4 pb-4 width-100" *ngIf="(numReplied < questions.length)">
                    <span class="unanswered">
                    Você possui questões não respondidas
                </span>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="finished">
        <div class="end-mock text-center">
            <div class="end-mock-title">
                Obrigado por sua participação!
            </div>
            <div class="end-mock-content mt-5">
                O resultado estará disponível a partir do dia {{this.resultDate | date:'dd/MM/y'}} às {{this.resultDate | date:'HH:mm'}} horário de Brasília.
            </div>
            <button mat-raised-button color="accent" class="mt-3" routerLink="/dashboard">Voltar ao início</button>
        </div>
    </ng-container>
    <div class="text-center" *ngIf="!question || loading">
        <mat-spinner class="center" color='accent'></mat-spinner>
    </div>
</div>
