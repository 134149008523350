import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ExperimentService } from 'src/app/modules/experiment/experiment.service';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { Plan } from 'src/app/modules/payment/plan';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { Md5 } from 'ts-md5';

import { AdminService } from '../../admin.service';
import { Experiment } from '../experiment';

@Component({
    selector: 'app-experiment-create',
    templateUrl: './experiment-create.component.html',
})
export class ExperimentCreateComponent implements OnInit, OnDestroy {

    public plans: Plan[];
    public experimentForm: FormGroup;
    public editMode: boolean;
    public experiments: Experiment[];
    public showList: boolean;
    public loading: boolean;
    public experimentLink: string;
    public copyIcon: string = 'content_copy';
    private admUpdate$: Subscription;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public experiment: Experiment,
        @Optional() public dialogRef: MatDialogRef<ExperimentCreateComponent>,
        private experimentService: ExperimentService,
        private paymentService: PaymentService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        private dialog: MatDialog,
        private clipboard: Clipboard,
        public maskService: MaskService) { }

    ngOnInit(): void {
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        this.experimentForm = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            planId: ['', Validators.required],
            expireDays: [15, [Validators.required, Validators.maxLength(3)]]
        });
        this.getPlans();
        if (this.experiment) { this.initializeEditMode(); }
    }

    initializeEditMode() {
        this.editMode = true;
        this.experimentForm.patchValue(this.experiment);
        const hashId = Md5.hashStr(this.experiment.id.toString()) as string;
        this.experimentLink = 'https://sistema.notamaxima.com.br/experiment/' + hashId.substring(0, 8);
        this.getPlans();
    }

    getPlans() {
        this.paymentService.getPlans()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plans = response.data as Plan[];
                    this.plans = this.plans.map(p => {
                        p.id = Number(p.id);
                        return p;
                    });
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }

    getExperiments() {
        this.experimentService.getExperiments()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.experiments = response.data as Experiment[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos experimente. Verifique a conexão e tente novamente'));
    }

    addExpireDays() {

        if (this.experimentForm.get('expireDays').value && this.experimentForm.get('expireDays').value > 0) {
            this.experimentForm.get('expireDays')
                .setValue((this.experimentForm.get('expireDays').value as number) + 1);
        } else {
            this.experimentForm.get('expireDays').setValue(1);
        }
    }

    create() {
        if (this.experimentForm.errors) {
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }

        this.loading = true;

        const experiment = this.experimentForm.getRawValue() as Experiment;

        this.experimentService.create(experiment)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Plano experimente registrado!');
                    this.adminService.updateChields();
                    this.experimentForm.reset();
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;

            }, err => {
                this.alertService.error('Houve um erro ao criar o plano experimente. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    saveChanges() {

        if (this.experimentForm.errors) {
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }

        this.loading = true;

        const experiment = this.experimentForm.getRawValue() as Experiment;
        experiment.id = this.experiment.id;

        this.experimentService.saveChanges(experiment)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Plano experimente registrado!');
                    this.adminService.updateChields();
                    this.experimentForm.reset();
                    this.dialogRef.close(true);
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao editar o plano experimente. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    edit(experiment: Experiment) {
        const dialogRef = this.dialog.open(ExperimentCreateComponent, {
            minWidth: '60vw',
            data: experiment
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.getExperiments();
            }
        });
    }

    openList() {
        this.getExperiments();
        this.showList = true;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    remove(experimentId: number) {
        if (confirm('Deseja realmente excluir a degustação?')) {
            this.experimentService.removeExperiments(experimentId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Removido!');
                        this.adminService.updateChields();
                        this.getExperiments();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
        }
    }

    copyClipboard() {
        this.copyIcon = 'check';
        setTimeout(() => {
            this.copyIcon = 'content_copy';
        }, 3000);
    }

}
