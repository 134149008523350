<div  >    
    
    <div *ngIf="!synchronized || !unsynchronized" class="row mt-2">
        <div class=" col-6 width-100" >
            <button class="width-100" (click)="synchronized = true;  unsynchronized = false;" 
                    nbButton size="small" outline status="primary">
                Ver Sincronizadas
            </button>
        </div>
        <div class=" col-6 width-100">
            <button class="width-100"  (click)="unsynchronized = true; synchronized = false;" 
                    nbButton size="small" outline status="danger"> 
                Ver não Sincronizadas
            </button>
        </div>
        
    </div>

    <div *ngIf="synchronized && !editMode && !removeMode">
        <nb-card size="small">
            <nb-card-header *ngIf="userService.isAdmin()">
                <h5 class="m-2" >
                    Unidades Sincronizadas <span class="material-icons info-font"> info </span>  <span class="info" > (Clique em <b>VER NÃO SINCRONIZADAS</b> para ver as que estão pendentes de sincronização! ) </span>
                </h5>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Escola" [(ngModel)]="filterSchoolId" 
                                        (selectionChange)="getSchoolUnits($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> 
                
                    
                </div>
            
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="schools" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let unit of unitsSync">
                        <div class="row"  >
                            <div class="col-4">
                                {{unit.name}}
                            </div>
                            <div class="col-4">
                                <span class="color-status-success">  {{unit.classSync}} </span> 
                                <span class="color-status-primary" >/{{unit.total}} classes</span>
                            </div> 
                            <div class="col-2 text-right">
                                <button size="tiny" 
                                        (click)="getClassesUnSync(unit)"  
                                        nbButton ghost>
                                    <span class="material-icons color-status-success">
                                        alarm_on
                                    </span>
                                </button>  
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
                <span *ngIf="!schools" >Nenhum resultado</span>
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="unsynchronized && !editMode && !removeMode">
        <nb-card size="medium">
            <nb-card-header *ngIf="userService.isAdmin()">
                    <h5 class="m-2">Unidades Não Sincronizadas <span class="material-icons info-font"> info </span>  <span class="info" > (Clique em <b>VER SINCRONIZADAS</b> para ver as que estão pendentes de sincronização! ) </span>

                    </h5>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100">
                                <mat-select placeholder="Escola" [(ngModel)]="filterSchoolId" 
                                            (selectionChange)="getSchoolUnits($event.value)">
                                    <mat-option *ngFor="let school of filterSchools" [value]="school.id">
                                        {{school.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> 
                    
                    </div>
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="schools" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let unit of unitsUnSync">
                        <div class="row"  >
                            <div class="col-4">
                                {{unit.name}}
                            </div>
                            <div class="col-4">
                                <span class="color-status-danger">{{unit.classNotSync}}</span>
                                <span class="color-status-primary" >/{{unit.total}} classes</span> 
                            </div> 
                            <div class="col-2 text-right">
                                <button size="tiny" 
                                        (click)="getClassesSync(unit)"
                                        *ngIf="unit.classNotSync > 0 ;"  
                                        nbButton ghost>
                                    <span class="material-icons color-status-danger">
                                        alarm
                                    </span>
                                </button> 
                            </div>
                        </div>
                    </nb-list-item>
                    <nb-list-item *ngIf="units.length == 0" >Nenhum resultado</nb-list-item>
                </nb-list>
                <span *ngIf="!schools" >Nenhum resultado</span>
            </nb-card-body>
        </nb-card>
    </div> 
    <div *ngIf="editMode && !loading && !removeMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Sincronizar Unidade: {{syncUnit.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui as <b>TURMAS</b>  da unidade serão  <b>SINCRONIZADAS</b>  ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <div class="row">
                    <div class="col-6 text-left width-100 m-0">
                        <span class="color-status-primary  mb-2"  >Status: 
                            <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                            </span> 
                            <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                        </span>
                        
                    </div>
                    <div class="text-right width-100">
                        <button (click)="syncClass(syncClasses)" class="right" 
                                nbButton size="small" status="primary">Sincronizar</button>
                    </div>
                </div> 
                <h5>Turmas</h5> 
                 
                <nb-list *ngIf="syncClasses" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let class of syncClasses">
                        <div class="row">
                            <div class="col-6">
                               {{class.name}}
                            </div>  
                        </div>
                    </nb-list-item>
                    <nb-list-item *ngIf="units.length == 0" >Nenhum resultado</nb-list-item>
                </nb-list> 
            </nb-card-body>
        </nb-card>
    
        <div class="text-right width-100">
            <button (click)="editMode = false;getSchoolUnits(actualSchoolId);" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
    <div *ngIf="removeMode && !loading && !editMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Remover Unidade: {{syncUnit.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui as <b>TURMAS</b>  da unidade serão <b>REMOVIDAS</b> ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <div class="row">
                    <div class="col-6 text-left width-100 m-0">
                        <span class="color-status-primary  mb-2"  >Status: 
                            <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                            </span> 
                            <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                        </span>
                        
                    </div>
                    <div class="text-right width-100">
                        <button (click)="unSyncClass(syncClasses)" class="right" 
                                nbButton size="small" status="primary">Remover</button>
                    </div>
                </div>
                <h6>Turmas</h6>  
                <nb-list *ngIf="syncClasses" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let class of syncClasses">
                        <div class="row">
                            <div class="col-6">
                               {{class.name}}
                            </div>  
                        </div>
                    </nb-list-item>
                </nb-list>
                
            </nb-card-body>
        </nb-card>
    
        <div class="text-right width-100">
            <button (click)="removeMode = false;getSchoolUnits(actualSchoolId);" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
    
</div> 
<div class="">
<mat-spinner class="center" *ngIf="loading"></mat-spinner>
</div>