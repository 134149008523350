<div *ngIf="!showManagersList">
    <form [formGroup]="createManagerForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Coordenador</h4>

        <!-- Name, profile -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="profile" placeholder="Acesso" #profileSelected>
                        <mat-option *ngFor="let profile of profiles" [value]="profile.id">
                            <span [title]="profile.name">{{profile.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- login, password -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Login" formControlName="login">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Senha" formControlName="password">
                </mat-form-field>
            </div>
        </div>

        <!-- school, unit -->
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getUnits($event.value)" formControlName="school" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="createManagerForm.get('profile').value != '4' && createManagerForm.get('profile').value != '3'">
                <mat-form-field class="width-100">
                    <mat-select formControlName="unit" placeholder="Unidade">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <div class="row">
                                <div class="col-10">
                                    <span [title]="unit.name">{{unit.name}}</span>
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>


    <div *ngIf="!editMode" class="row mt-5 align-items-center">
        <div class="col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin mw-100 d-flex flex-wrap"><span class="mr-1">Ver Coordenadores</span><span>cadastrados</span></button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createManager()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>

<div *ngIf="showManagersList" class="">

    <div class="row mt-3" *ngIf="userService.isAdmin()">
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-label>Escola</mat-label>
                <mat-select (selectionChange)="getManagers($event.value)" [(ngModel)]="selectedSchoolId" placeholder="Escola">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <div class="row">
                <div class="col-9">
                    <mat-form-field class="width-100">
                        <input #filterNameRef matInput placeholder="Buscar por nome">
                    </mat-form-field>
                </div>
                <div class="col">
                    <button (click)="getManagersByNameOrEmail(filterNameRef.value)" class="right" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md">
            <div class="row">
                <div class="col-9">
                    <mat-form-field class="width-100">
                        <input #filterEmailRef matInput placeholder="Buscar por e-mail">
                    </mat-form-field>
                </div>
                <div class="col">
                    <button (click)="getManagersByNameOrEmail(null, filterEmailRef.value)" class="right" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <nb-list class="width-90 mt-3 center" *ngIf="!loading">
        <nb-list-item *ngFor="let manager of managers">
            <div class="row">
                <div class="col-7">
                    {{manager.name}}
                </div>
                <div class="col-3">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip *ngIf="manager.profile == 4" color="warn" selected>Admin</mat-chip>
                        <mat-chip *ngIf="manager.profile == 3" color="primary" selected>Coord geral</mat-chip>
                        <mat-chip *ngIf="manager.profile == 2" color="accent" selected>Coord unidade</mat-chip>
                    </mat-chip-list>
                </div>
                <div class="col-1 text-right">
                    <button (click)="editManager(manager)" class="hvr-buzz-out" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div class="col-1 text-right" >
                    <button (click)="removeManager(manager.id)" class="hvr-buzz-out" color="warn" mat-icon-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <mat-spinner *ngIf="loading" class="center"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showManagersList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>
