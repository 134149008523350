import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule , ReactiveFormsModule  } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NbAccordionModule, NbCardModule, NbIconModule, NbListModule, NbSpinnerModule } from '@nebular/theme';
import { LottieModule } from 'ngx-lottie';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClassroomModule } from '../classroom/classroom.module';
import { DisciplinePlanDisciplinesComponent } from './plan-disciplines/discipline-plan-disciplines.component';
import { DisciplineVideosComponent } from './videos/discipline-videos.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TopicDisciplineComponent } from './topic-discipline/topic-discipline.component';
import { ChannelDisciplineComponent } from './channel-discipline/channel-discipline.component'; 
import { PlansTopicComponent } from './topic-discipline/components/plans-topic/plans-topic.component';
import { DisciplinesTopicComponent } from './topic-discipline/components/disciplines-topic/disciplines-topic.component';
import { TopicsComponent } from './topic-discipline/components/topics/topics.component';
import { VideoTopicComponent } from './topic-discipline/components/video-topic/video-topic.component'; 
import { MatCardModule } from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';


export function playerFactory() {
    return import('lottie-web');
}
@NgModule({
    declarations: [
        DisciplineVideosComponent,
        DisciplinePlanDisciplinesComponent,
        TopicDisciplineComponent,
        ChannelDisciplineComponent, 
        PlansTopicComponent, DisciplinesTopicComponent, TopicsComponent, VideoTopicComponent, 
    ],
    imports: [
        CommonModule,
        MatInputModule,
        NbListModule,
        NbIconModule,
        NbCardModule,
        RouterModule,
        NbSpinnerModule,
        NbAccordionModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatTooltipModule,
        ClassroomModule,
        MatListModule,
        MatRippleModule,
        MatTableModule,
        MatPaginatorModule,
        NgxPaginationModule,
        MatCardModule,
        MatProgressBarModule,
        LottieModule.forRoot({ player: playerFactory }),

    ],
    exports: [],
    providers: [],
})
export class DisciplineModule { }
