<div *ngIf="!showList && !showEdit">
    <form [formGroup]="form">
        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Sala de aula</h4>
        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Início</mat-label>
                    <input matInput placeholder="Início" formControlName="initDate" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>Término</mat-label>
                    <input matInput placeholder="Término" formControlName="endDate" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="ml-3">
                <mat-form-field>
                    <mat-select placeholder="selecione a versão" formControlName="versionId" (selectionChange)="getSchoolPlansByVersion($event.value ,schoolId)">
                        <mat-option *ngFor="let v of version" [value]="v.value">
                            {{v.option}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mt-4 ml-2">
                <span>Selecione a versão</span>
            </div>
        </div>

        <div class="row">
            <div class="col" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" formControlName="schoolId" (selectionChange)="getSchoolPlansByVersion(form.get('versionId').value ,$event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" formControlName="planId" (selectionChange)="addStudyPlanId($event.value)">
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="!editMode" class="col-md">
                <div>
                    <mat-label class="mb-2">Plano de estudos selecionados</mat-label>
                    <mat-chip-list>
                        <mat-chip *ngFor="let studyPlan of studyPlanArray" selectable="true" removable="true" (removed)="removeSelectedStudyPlan(studyPlan.id)">
                            {{studyPlan.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-10" *ngIf="showLink">
                <mat-form-field class="width-100">
                    <mat-label>Link</mat-label>
                    <input matInput placeholder="Link" formControlName="link">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <mat-label>Tag</mat-label>
                    <input #tag matInput placeholder="tag" formControlName="tag" maxlength="4">
                    <mat-hint align="end">{{tag.value?.length || 0}}/4</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field class="width-100">
                    <mat-label>codígo do vimeo da aula gravada</mat-label>
                    <input matInput placeholder="https://dominio.com" formControlName="recordLink">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver salas
                cadastradas</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="create()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>
    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges(class)" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>
<div *ngIf="showList" class="">
    <div class="row">
        <mat-form-field appearance="outline" class="width-60 mt-3 ml-2 col-md-12" ngDefaultControl>
            <input matInput placeholder="Buscar por nome" class="aling-right" [(ngModel)]="filterClass">
            <mat-icon matSuffix class="corpadraolup" (click)="getClassByName(filterClass)">search</mat-icon>
        </mat-form-field>
        <div class="col" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Escola" [(ngModel)]="selectedSchoolId" (selectionChange)="getSchoolPlans($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="width-100">
                <mat-select placeholder="Plano" [(ngModel)]="filterPlanId" (selectionChange)="getClassrooms($event.value)">
                    <mat-option *ngFor="let plan of plans" [value]="plan.id">
                        {{plan.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col" *ngIf="showButton">
            <button (click)="editClassrooms()" nbButton size="small" outline status="primary">
                Editar mais de uma Sala de Aula
            </button>
        </div>
        <!-- <div class="col" *ngIf="!showButton && !classrooms">
            <div class="col-12">
                Nenhum resultado! a
            </div>
        </div> -->
    </div>
    <nb-list *ngIf="!loading" class="width-100 mt-3 center">
        <nb-list-item *ngFor="let classroom of classrooms" [ngClass]="{'hide-element': classroom.liveVideo == 1}">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    {{classroom.name}}
                </div>
                <div class="col-8 col-sm-48 col-md-4 col-lg-4 col-xl-4">
                    Início <i>{{classroom.initDate}}</i><br> Fim <i>{{classroom.endDate}}</i>
                </div>

                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-right">
                    <div class="row w-100">
                        <div class="col-12">
                            <button (click)="edit(classroom, classroom.schoolId)" nbButton size="tiny" outline status="primary">
                                <nb-icon icon="edit"></nb-icon>
                            </button>
                        </div>
                        <div class="col-12">
                            <button size="tiny" (click)="remove(classroom)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </nb-list-item>
        <nb-list-item *ngIf="!classrooms || !showButton">
            <div class="row">
                <div class="col-12">
                    Nenhum resultado!
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <mat-spinner class="mt-5 center" *ngIf="loading">
    </mat-spinner>
    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>