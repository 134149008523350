import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { Video } from '../video';
import { VideoService } from '../video.service';

@Component({
  selector: 'app-modal-video-youtube-mobile',
  templateUrl: './modal-video-youtube-mobile.component.html',
  styleUrls: ['./modal-video-youtube-mobile.component.scss']
})
export class ModalVideoYoutubeMobileComponent implements OnInit {

  public playerYoutube : any;
  public playYoutube: boolean;
  public isRecordYoutube: boolean;
  public youtubeLiveId: string;
  public youtubeVolume: number = 10;
  public controlYoutube: number;
  public showMask: boolean;
  public isTwo: boolean;
  public isIncognito:boolean;
  public elem: any;
  public youtubeTime: any;
  public youtubeDuration: any;
  public showPlay: boolean;
  public controlCloseMask: boolean;
  public myTimeout: any = null
  public MaskOpen: boolean;
  public controlPlay: any;
  public isPortrait :boolean;
  text = "";
  public youtubeTimeShow: any;
  public youtubeDurationShow: any;
  public allQualitys = [];
  public valueQuality : string;
  public showConfig: boolean
  public rateVideo: string;
  public valueRate: number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public video: Video,
    @Optional() public dialogRef: MatDialogRef<ModalVideoYoutubeMobileComponent>,
    @Inject(DOCUMENT) private document : any,
    private videoService: VideoService,
    public layoutService: LayoutService,
    public deviceService: DeviceDetectorService
  ) {
    screen.orientation.addEventListener("change", () => {
      this.checkDisplay();
    });
  }

  ngOnInit() {

  this.checkDisplay();
  this.MaskOpen = true;
  //this.getOppositeOrientation();
  this.controlCloseMask = true;
  this.showPlay = true;
  this.elem = this.document.documentElement;

    if(this.video){
      this.getVideoYTMask(this.video.id);
    }

    this.valueQuality = 'auto';
    this.showConfig = false;

    //this.openFullScreen(this.elem);

    this.rateVideo = 'X1';
    this.valueRate = 1;
  }

  savePlayer(player) {
    this.playerYoutube = player;
    this.playerYoutube.setVolume(this.youtubeVolume);
    this.playerYoutube.seekTo(this.video.currentTime);
    this.youtubeDuration = this.playerYoutube.getDuration();
    this.playerYoutube.pauseVideo();
    //this.goYoutubeTime();

    const timestamp = this.youtubeDuration

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeDurationShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

}

slideMove(event){
  const timestamp = event.value;

  const date = new Date(timestamp * 1000);

  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

openMask(){
  if(this.playYoutube){
    if(!this.layoutService.isMobile){
      this.playYoutube = false;
      this.showPlay = true;
  }
  else{
      this.playYoutube = false;
  }
  this.playerYoutube.pauseVideo();
  this.youtubeTime = this.playerYoutube.getCurrentTime();

  const timestamp = this.youtubeTime

            const date = new Date(timestamp * 1000);

            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}

getOppositeOrientation() {
  const { type } = screen.orientation;
  return type.startsWith("portrait") ? "landscape" : "portrait";
}


checkDisplay() {
  const { type, angle } = screen.orientation;
  this.text = `Orientation type is ${type} & angle is ${angle}.`;
  console.log(this.text);
  if(angle == 90){
    this.isPortrait = false;
  }
  else{
    this.isPortrait = true;
  }
}


setForward(){
  this.playerYoutube.seekTo(this.playerYoutube.getCurrentTime() + 30);
  this.youtubeTime = this.playerYoutube.getCurrentTime();

  const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

rewindVideo(){
  this.playerYoutube.seekTo(this.playerYoutube.getCurrentTime() - 30);
  this.youtubeTime = this.playerYoutube.getCurrentTime();

  const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

getVideoYTMask(videoId: number){
  this.videoService.getVideoYTMask(videoId)
  .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
          let isMask = response.data['youtubeMask'] as number;

          if(isMask == 1){
              this.showMask = true;
              this.controlYoutube = 0;
          }
          else{
              this.showMask = false;
              this.controlYoutube = 1;
          }
      }
  })
}

exitFullScreen(){
  this.dialogRef.close(this.playerYoutube.getCurrentTime());
}

pauseVideo(){
  this.showPlay = true;
  this.playerYoutube.pauseVideo();
  this.youtubeTime = this.playerYoutube.getCurrentTime();
}

openFullScreen(element: any){
  if(element.requestFullScreen()){
    this.elem.requestFullScreen();
  }
}

goYoutubeTime(){
  this.playerYoutube.seekTo(this.youtubeTime);

  const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

openMaskHover(){


  if (this.myTimeout) {
		clearTimeout(this.myTimeout)
	}

  if(!this.layoutService.isMobile){
      this.playYoutube = false;
      this.youtubeTime = this.playerYoutube.getCurrentTime();
  }

  this.myTimeout = setTimeout(() => {
    this.closeMaskOver();
  }, 5000);
}

closeMaskOver(){
  if(!this.layoutService.isMobile){
      this.playYoutube = true;
  }
  this.youtubeTime = this.playerYoutube.getCurrentTime();
}

updateTime(){

  setTimeout(() => {
      this.youtubeTime = this.playerYoutube.getCurrentTime();
      this.updateTime();

      const timestamp = this.youtubeTime

      const date = new Date(timestamp * 1000);

      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, 5000);
}

setVolumeYoutube(){
  this.playerYoutube.setVolume(this.youtubeVolume);
}

playVideoYT(){
  if(!this.layoutService.isMobile){
      this.showPlay = false;
      this.playYoutube = true;
  }
  else{
    this.playYoutube = true;
  }

  this.playerYoutube.playVideo();
  this.playerYoutube.setVolume(this.youtubeVolume);
  this.youtubeTime = this.playerYoutube.getCurrentTime();

  const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

changeQuality(){
  let index = this.allQualitys.indexOf(this.valueQuality);

 /*  if(index+1 > this.allQualitys.length){
    this.index = 0;
  } */

  this.valueQuality = this.allQualitys[index +1];

  if(!this.valueQuality){
    this.valueQuality = this.allQualitys[0];
  }

  this.playerYoutube.setPlaybackQuality(this.valueQuality);
}

openConfig(){
  this.showConfig = !this.showConfig;

  console.log(this.valueQuality);

  let qualitylevels = this.playerYoutube.getAvailableQualityLevels();
    console.log(this.playerYoutube.getAvailableQualityLevels());

    qualitylevels.forEach(element => {
        if(element != "medium" && element != "high" && element != "tiny"){
            this.allQualitys.push(element);
        }
    });

    console.log(this.allQualitys , 'ble bvle');
}

setVideoRate() {
  switch (this.valueRate) {
      case 1:
          this.valueRate = 1.25;
          this.playerYoutube.setPlaybackRate(1.25);
          this.rateVideo = 'X1.25';
          break;
      case 1.25:
          this.valueRate = 1.5;
          this.playerYoutube.setPlaybackRate(1.5);
          this.rateVideo = 'X1.50';
          break;
      case 1.5:
          this.valueRate = 2;
          this.playerYoutube.setPlaybackRate(2);
          this.rateVideo = 'X2';
          break;
      case 2:
          this.valueRate = 1;
          this.playerYoutube.setPlaybackRate(1);
          this.rateVideo = 'X1';
          break;
  }
}

}
