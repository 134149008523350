import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { Plan } from 'src/app/modules/payment/plan';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { Md5 } from 'ts-md5/dist/md5';

import { AdminService } from '../../admin.service';
import { Class } from '../../school/class';
import { SchoolService } from '../../school/school.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { CurrencyPipe } from '@angular/common';
import { UserService } from 'src/app/modules/user/user.service';
import { School } from 'src/app/modules/admin/school/school';
import { Unit } from '../../school/unit';

@Component({
    selector: 'app-payment-create-plan',
    templateUrl: './payment-create-plan.component.html'
})
export class PaymentCreatePlanComponent implements OnInit {

    public planForm: FormGroup;
    public plans: Plan[];
    public studyPlans: StudyPlan[];
    public classes: Class[];
    public months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    public loading: boolean;
    public showPlanList: boolean;
    public editMode: boolean;
    public showStudentList: boolean;
    public filteredPlans: Plan[];
    public schools: School[];
    public filterPlanName: string;
    public units: Unit[]


    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public plan: Plan,
        @Optional() public dialogRef: MatDialogRef<PaymentCreatePlanComponent>,
        private dialog: MatDialog,
        private paymentService: PaymentService,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private adminService: AdminService,
        public maskService: MaskService,
        private schoolService: SchoolService,
        private studyPlanService: StudyPlanService,
        public userService: UserService,
        private currencyPipe: CurrencyPipe) { }

    ngOnInit(): void {
        /* this.getClasses(); */
        /* this.getStudyPlans(); */
        this.getSchools();
        this.planForm = this.formBuilder.group({
            id: [],
            name: ['', Validators.required],
            price: ['', Validators.required],
            installments: ['', Validators.required],
            class: [''],
            studyPlan: ['', Validators.required],
            link: ['']
        });
        if (this.plan) { this.initializeEditMode(); }
    }

    getPlans() {

        this.loading = true;
        this.paymentService.getPlans()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plans = (response.data as Plan[]).map(plan => {
                        plan.price = Number(plan.price);
                        plan.id = Number(plan.id);
                        plan.installments = Number(plan.installments);
                        plan.studyPlan = Number(plan.studyPlan);
                        plan.class = Number(plan.class);
                        return plan;
                    });

                    this.filteredPlans = [...this.plans];
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getStudyPlans(schoolId?: number) {
        if (this.userService.isUnitCoordinator() || this.userService.isTeacher()) {
            const schcord = this.userService.getUserSchool();
            this.studyPlanService.getPlans(schcord)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.studyPlans = response.data as StudyPlan[];
                    } else {
                        this.studyPlans = [];
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao carregar os planos de estudo. Verifique a conexão e tente novamente'));
        }
        else {
            this.studyPlanService.getPlans(schoolId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.studyPlans = response.data as StudyPlan[];
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao carregar os planos de estudo. Verifique a conexão e tente novamente'));
        }
    }

    getStudyPlansByUnit(unitId : number){
      if(!this.userService.isAdmin){
          unitId = this.userService.getUserUnit();
      }
      this.studyPlanService.getPlansByUnit(unitId)
      .subscribe(res =>{
          const response = res.body as ResponseApi;

          if(!response.error){
              this.studyPlans = response.data as StudyPlan[];
          }
          else{
              this.alertService.error(response.error);
              this.studyPlans = [];
          }
      },err => this.alertService.error('Houve um erro ao carregar os planos de estudo. Verifique a conexão e tente novamente'));
    }

    getSchools() {
        this.loading = true;
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;
                this.loading = false;
                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente')
            });
    }

    getClasses(unitId : number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    openList() {
        this.getPlans();
        this.showPlanList = true;
    }

    editPlan(plan: Plan) {

        const dialogRef = this.dialog.open(PaymentCreatePlanComponent, {
            minWidth: '60vw',
            data: plan
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.getPlans();
            }
        });
    }

    initializeEditMode() {
        this.editMode = true;

        if (typeof this.plan.price === 'number') {
            this.plan.price = this.currencyPipe.transform(this.plan.price, 'BRL', '', '1.2');
        }
        this.plan.price = this.plan.price.replace('.', '');
        const hashId = Md5.hashStr(this.plan.id.toString()) as string;
        this.plan.link = 'https://sistema.notamaxima.com.br/pagamento/index.html?plan=' + hashId.substring(0, 8);
        this.planForm.patchValue(this.plan);
    }

    createPlan() {
        if (this.planForm.valid) {
            this.loading = true;
            const plan = this.planForm.getRawValue() as Plan;

            this.paymentService.createPlan(plan)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Plano criado!');
                        this.planForm.reset();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao criar o plano. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha os campos corretamente');
        }
    }

    duplicatePlan() {
        if (this.planForm.valid) {
            this.loading = true;
            const plan = this.planForm.getRawValue() as Plan;
            plan.name = plan.name + " (Duplicado)";
            this.paymentService.createPlan(plan)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Plano Duplicado!');
                        this.planForm.reset();
                        this.dialogRef.close(true);
                        this.getPlans();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao Duplicar o plano. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha os campos corretamente');
        }
    }

    saveChanges() {
        if (this.planForm.valid) {
            this.loading = true;
            this.planForm.get('id').setValue(this.plan.id);
            const plan = this.planForm.getRawValue() as Plan;
            this.paymentService.savePlanChanges(plan)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Plano editado com sucesso!', 3);
                        this.dialogRef.close(true);
                        this.getPlans();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao salvar as alterações. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente!');
        }
    }

    removePlan(planId: number) {
        if (confirm('Deseja realmente remover o plano?')) {
            this.paymentService.removePlan(planId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Plano removido!', 3);
                        this.getPlans();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover o plano. Verifique a conexão e tente novamente'));
        }
    }

    applyFilter() {

        if (this.filterPlanName.length) {
            this.filteredPlans = [...this.plans].filter(p => p.name.toLowerCase().indexOf(this.filterPlanName.toLowerCase()) > -1);
        } else {
            this.filteredPlans = [...this.plans];
        }
    }
}
