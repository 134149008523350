import { Component, OnInit } from '@angular/core';
import { Workbook } from 'exceljs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { ReportService } from '../../report/report.service';
import { UserService } from '../../user/user.service';
import { UserSelect } from '../notifications/users';
import { PlanSelect } from '../rdo-manager/create-theme/theme';
import { ClassSelect } from '../school/class';
import { SchoolSelect } from '../school/school';
import { SchoolService } from '../school/school.service';
import { UnitSelect } from '../school/unit';
import { StudentService } from '../student/student.service';
import { PerformanceGeneral, PerformanceSingle, RedactionsReport, Statistics, UserDataReport } from './dashboard';
import { DashboardService } from './dashboard.service';
import * as fs from 'file-saver';
import { Mock } from '../../mock/mock';
import { StudentsComponent } from './reports/students/students.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  public schoolId: number;
  public unitId: number;
  public classId: number;
  public planId: number;
  public mockId: number;
  public studentId: number;
  public userdata: UserDataReport;
  public schools: SchoolSelect[];
  public plans: PlanSelect[];
  public units: UnitSelect[];
  public classes: ClassSelect[];
  public students: UserSelect[];
  public mocks: Mock[];
  public statistics: Statistics;
  public typerelat: number;
  public endDate = '';
  public startDate = '';
  public schoolName: string;
  public loading: boolean;
  public performance: any;
  public generalReportPlanId: number;
  public studentsComponent: StudentsComponent;
  public stoploading: boolean;


  constructor(
    public userService: UserService,
    public alertService: AlertService,
    public layoutService: LayoutService,
    public schoolService: SchoolService,
    public studentService: StudentService,
    public dashboardService: DashboardService,
    public reportService: ReportService
  ) { }

  ngOnInit() {

    this.stoploading = false;

    this.schoolName = this.userService.getUserSchoolName();

    if (this.userService.isAdmin()) {
      this.getSchools();
      this.getAllStatistics(0);
    }

    if (this.userService.isGeneralCoordinator() || this.userService.isUnitCoordinator()) {
      this.schoolId = this.userService.getUserSchool();
      this.getPlanSelection(this.schoolId);
      this.getUnitsSelection(this.schoolId);
      this.getAllStatistics(this.schoolId);
      this.getMockBySchool(this.userService.getUserSchool());
    }
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchoolSelection()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.schools = response.data as SchoolSelect[];
        else this.alertService.error(response.error);
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlanSelection(schoolId?: number) {
    this.schoolService.getPlansSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.plans = response.data as PlanSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'));
  }

  getPlanSelectionByClass(classId?: number) {
    this.dashboardService.getPlansByClass(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.plans = response.data as PlanSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'));
  }

  getUnitsSelection(schoolId?: number) {
    this.schoolService.getUnitsSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.units = response.data as UnitSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente!'));
  }

  getClassesSelection(unitId?: number) {
    this.schoolService.getClassesSelection(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classes = response.data as ClassSelect[];
        else this.alertService.error(response.error);
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!'));
  }

  getStudents(classId: number) {
    this.studentService.getStudents(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.students = response.data as UserSelect[];
        else {
          this.students = [];
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
      });
  }

  getMockBySchool(schoolId: number) {
    if(schoolId){
    this.dashboardService.getMockBySchool(schoolId)
      .subscribe((res) => {
        const response = res.body as ResponseApi;
        if (!response.error) this.mocks = response.data as Mock[];
        else this.alertService.error(response.error);
      }, err => {
        this.alertService.error('Houve um erro ao buscar os simulados da escola. Verifique a conexão e tente novamente!')
      });
    }
  }

  getMockSummationBySchool(schoolId: number) {
    this.dashboardService.getMockSummationBySchool(schoolId)
      .subscribe((res) => {
        const response = res.body as ResponseApi;
        if (!response.error) this.mocks = response.data as Mock[];
        else this.alertService.error(response.error);
      }, err => {
        this.alertService.error('Houve um erro ao buscar os simulados da escola. Verifique a conexão e tente novamente!')
      });
  }

  getMockByUser(userId: number) {
    this.dashboardService.getMockBySchool(userId)
      .subscribe((res) => {
        const response = res.body as ResponseApi;
        if (!response.error) this.mocks = response.data as Mock[];
        else this.alertService.error(response.error);
      }, err => {
        this.alertService.error('Houve um erro ao buscar os simulados da escola. Verifique a conexão e tente novamente!')
      });
  }

  getMockSummationByUser(userId: number) {
    this.dashboardService.getMockSummationByUser(userId)
      .subscribe((res) => {
        const response = res.body as ResponseApi;
        if (!response.error) this.mocks = response.data as Mock[];
        else this.alertService.error(response.error);
      }, err => {
        this.alertService.error('Houve um erro ao buscar os simulados da escola. Verifique a conexão e tente novamente!')
      });
  }

  getAverageData() {
    this.loading = false;
    if (this.startDate && this.endDate == '') {
      const date = this.startDate + this.endDate;
      this.getStatistics(this.schoolId, this.planId, date);
    }
    else {
      this.getStatistics(this.schoolId, this.planId);
    }

  }

  setSchoolObservable(schoolId: number) {
    this.dashboardService.setSchoolId(schoolId);
    this.dashboardService.emitEventDashboardStudent({});
  }

  controlEmits() {
    this.getengagement();
    this.getMocksSchool();
  }

  getengagement() {

    let school = 0;

    if (!this.userService.isAdmin()) {
      this.dashboardService.setSchoolId(this.userService.getUserSchool());
      school = this.userService.getUserSchool();
    }
    else {
      this.dashboardService.setSchoolId(this.schoolId);
      school = this.schoolId;
    }

    this.dashboardService.setStarDate(this.startDate);
    this.dashboardService.setEndDate(this.endDate);
    this.dashboardService.emitEventDashboardStudent({});
    this.loading = false;

    //this.getMocksSchool(school)
  }

  getMocksSchool() {
    if (!this.userService.isAdmin()) {
      this.dashboardService.setSchoolId(this.userService.getUserSchool());
    }
    else {
      this.dashboardService.setSchoolId(this.schoolId);
    }
    this.dashboardService.setStarDate(this.startDate);
    this.dashboardService.setEndDate(this.endDate);
    this.dashboardService.emitEventDashboardMock({});
    this.loading = false;
  }

  getAllStatistics(schoolId?: number) {
    this.dashboardService.getAllStatistics(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error){
          this.statistics = response.data as Statistics;
          this.stoploading = true;
        }
        else {
          //this.alertService.error(response.error);
          //this.statistics = [];
          this.stoploading = true;
        }
      }, err => this.alertService.error('Houve um erro ao carregar as estatísticas. Verifique a conexão e tente novamente!'));
  }

  getStatistics(schoolId: number, planId: number, date?: string) {
    this.loading = true;
    this.dashboardService.getStatistics(schoolId, planId, this.startDate, this.endDate)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.statistics = response.data as Statistics;
        else this.alertService.error(response.error);
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as estatísticas. Verifique a conexão e tente novamente!');
        this.loading = false;
      });
  }


  clearForm() {
    this.schoolId = null;
    this.unitId = null;
    this.classId = null;
    this.studentId = null;
    this.startDate = null;
    this.endDate = null;
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */
  generateReport(type: number) {
    if (!this.startDate || !this.endDate ||
      this.startDate == "" || this.endDate == "" ||
      this.startDate === undefined || this.endDate === undefined) {

      this.alertService.error("Selecione o Início e Fim !");
      return false;
    } else {
      if (this.userService.isGeneralCoordinator() || this.userService.isUnitCoordinator()) { this.schoolId = this.userService.getUserSchool(); }
      if (type == 1) this.generatePerformanceSingle();
      if (type == 2 && !this.generalReportPlanId) this.generateGeneralPerformance();
      if (type == 2 && this.generalReportPlanId) this.generateGeneralReportPlan();
      if (type == 3) this.generateFrequency();
      if (type == 4) this.generateMocks();
      if (type == 5) this.generateMocksSummation();
      if (type == 6) this.generateGeneralData();
      if (type == 7) this.generateRedactionData();
    }

  }

  generateGeneralPerformance() {
    this.typerelat = 2;
    if (this.generalReportPlanId) {
      this.dashboardService.getGeneralReport(this.schoolId, this.unitId, this.classId, this.studentId, this.startDate, this.endDate)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.performance = response.data as PerformanceGeneral[];
            this.generateXls();
            this.alertService.success("Relatório Gerado com sucesso!");
            this.studentId = null;
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
    }
    else {
      this.alertService.error('você precisa selecionar ao menos um Plano!');
    }
  }

  generateGeneralReportPlan() {
    this.typerelat = 2;

    let isAdmin = 0;
    if(this.userService.isAdmin()){
      isAdmin = 1
    }
    else{
      isAdmin = 0;
    }

    this.dashboardService.getGeneralReportPlan(this.schoolId, this.unitId, this.classId, this.generalReportPlanId, this.studentId, this.startDate, this.endDate , isAdmin)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        console.log(response);
        if (!response.error) {
          this.performance = response.data as PerformanceGeneral[];
          this.generateXls();
          this.alertService.success("Relatório Gerado com sucesso!");
          this.studentId = null;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
  }

  generatePerformanceSingle() {
    this.typerelat = 1;
    if (this.studentId) {
      this.dashboardService.getPerformanceOne(this.schoolId, this.unitId, this.classId, this.planId, this.studentId, this.startDate, this.endDate)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.performance = response.data as PerformanceSingle[];
            this.generateXls();
            this.alertService.success("Relatório Gerado com sucesso!");
            this.studentId = null;
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
    }
    else {
      this.alertService.error('você Precisa selecionar um aluno');
    }
  }

  generateFrequency() {
    this.typerelat = 3;
    if (this.classId) {
      this.dashboardService.getFrequency(this.schoolId, this.unitId, this.classId, this.startDate, this.endDate)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.performance = response.data as PerformanceSingle[];
            this.generateXls();
            this.alertService.success("Relatório Gerado com sucesso!");
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
    }
    else {
      this.alertService.error('Você precisa selecionar uma turma!');
    }
  }

  generateMocks() {
    this.typerelat = 4;
    if (this.mockId) {
      this.dashboardService.getMocks(this.startDate, this.endDate, this.schoolId, this.unitId, this.mockId, this.classId, this.studentId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.performance = response.data as PerformanceSingle[];
            this.generateXls();
            this.alertService.success("Relatório Gerado com sucesso!");
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
    }
    else {
      this.alertService.error('Precisa ser selecionado Um Simulado');
    }
  }

  generateMocksSummation() {
    this.typerelat = 5;
    if (this.mockId) {
      this.dashboardService.getMocksSummation(this.startDate, this.endDate, this.schoolId, this.unitId, this.mockId, this.classId, this.studentId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.performance = response.data as PerformanceSingle[];
            this.generateXls();
            this.alertService.success("Relatório Gerado com sucesso!");
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
    }
    else {
      this.alertService.error('Precisa ser selecionado Um Simulado');
    }
  }

  generateGeneralData() {
    this.typerelat = 6;
    this.dashboardService.getGeneralData(this.schoolId, this.startDate, this.endDate)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.performance = response.data as PerformanceGeneral[];
          this.generateXls();
          this.alertService.success("Relatório Gerado com sucesso!");
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
  }

  generateRedactionData() {
    this.typerelat = 7;
    this.dashboardService.getRedactionData(this.schoolId, this.startDate, this.endDate)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.performance = response.data as RedactionsReport[];
          this.generateXls();
          this.alertService.success("Relatório Gerado com sucesso!");
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao gerar o relatório. Verifique a conexão e tente novamente!'));
  }

  generateXls() {
    const headWrite = [];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Employee Data");
    let vet = [];
    switch (this.typerelat) {
      case 1: {
        let header = ["Nome do Aluno","E_mail", "Nome da turma", "Nome da Disciplina","Total De Videos","Total Assistidos",
        "Nome do video", "Nome do exercício", "Gabarito", "Respondido", "Acertou", "Resposta do Usuário"];
        let headerRow = worksheet.addRow(header);

        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório Individual"
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
      }
        break;

      case 2: {
        let header = ["Escola", "Turma", "Plano", "Aluno", "E_mail", "total Assistido", "total de Vídeos"
          , "porcentagem", "Respostas", "total Exercícios" , "Acertos" , "Erros"];
        let headerRow = worksheet.addRow(header);
        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório de Desempenho Geral"
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
      }
        break;

      case 3: {
        let header = ["id_usuario", "Nome", "E_mail", "Total de acessos", "Ultimo Acesso"];
        let headerRow = worksheet.addRow(header);

        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório Frequência"
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
      }
        break;

      case 4: {

        if(this.studentId){
          let header = ["usuario_nome","Nome Disciplina" , "Nome Exercicio", "Resposta", "Gabarito", "Acertou" , "totalExercicios",
        "Total Acertos" , "Erros" , "Data"];
        let headerRow = worksheet.addRow(header);

        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório Provas e Simulados Individual"

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
        }
        else{
          let header = ["Nome Escola", "nome_turma", "nome_simulado", "usuario_nome", "usuario_login", "Acertos",
          "erros","respondidas"];
        let headerRow = worksheet.addRow(header);

        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório Provas e Simulados"

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
        }
      }
        break;

      case 5: {
        let header = ["Nome Usuario", "Nome Disciplina", "Nome Exercicio", "Resposta", "Gabarito", "pontuação", "total Exercicios",
        "total de acertos", "total erros", "finalizado em"];
        let headerRow = worksheet.addRow(header);
        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório Provas e Simulados - Somatória"
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
      }
        break;

      case 6: {
        let header = ["Nome Escola", "Nome Turma", "Nome Unidade","Nome Aluno", "E_mail", "Criado Em"
          , "Ultimo Acesso", "Ultimo Ip"];
        let headerRow = worksheet.addRow(header);

        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório Dados Geral"
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
      }
        break;
      case 7: {
        let header = ["Nome Da turma", "Nome Do plano", "Nome Usuario", "Id da Redação","Nome Do tema","Tipo Redação", "Enviado em", "Nota", "Corrigido"];
        let headerRow = worksheet.addRow(header);

        for (let x1 of this.performance) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        let fname = "Relatório Redações"
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });
      }
        break;

    }
  }
}
