<div class="mb-2" *ngIf="!openList"  >
    <form [formGroup]="formConvergindo">
        <!-- School, Unit, Class -->
        <div class="row">
            <div class="col-md-4" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select  (selectionChange)="getUnits($event.value)"
                        placeholder="Selecione a Escola">
                        <mat-option   >Selecione</mat-option>
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <mat-form-field    class="width-100">
                   
                    <mat-select (selectionChange)="getClasses($event.value)"
                        placeholder="Selecione a Unidade"> 
                        <mat-option   >Selecione</mat-option>
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
              <!-- classes -->
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getStudents($event.value)"
                     placeholder="Selecione a Turma">
                        <mat-option   >Selecione</mat-option>
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            {{class.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- Students -->
        <div class="row" *ngIf="classId"  >

            <div class="col-12 m-3" style=" font-size:12px;"> 
                <i> <strong> Selecione um ESTUDANTE ou a TURMA toda!</strong> </i> 
            </div>
            <div class="col-md-6  p-4" style="color:green;" *ngIf="turma.checked" >
               
                <mat-icon aria-hidden="false" style="position:relative;top:3px;"> done</mat-icon>
                <i> Toda turma selecionada!</i>
            </div>
            <div class="col-md-6"  *ngIf="!turma.checked"> 
                <mat-form-field class="width-100" >
                    <mat-select formControlName="studentId"
                        (selectionChange)="studentsSelected = $event.value.id"
                        placeholder="Selecione um aluno">
                        <mat-option *ngFor="let student of students" [value]="student">
                            {{student.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 p-4"  > 
                <mat-checkbox #turma   (change)="selectAllStudents(turma.checked)" >Toda turma</mat-checkbox> 
            </div>
        </div>
        <div class="width-100" *ngIf="loading"><mat-spinner class="center mt-5"></mat-spinner></div>
        <!-- buttons -->
        <div class="row mt-5 mb-3">
            <div class=" col-6 width-100 ">
                <button (click)="openList=true" class="left" outline nbButton size="small" class="smallfont" status="primary" class="text-color-primary border-primary-thin">Ver Cadastrados</button>
             </div>
            <div class=" col-6 width-100 right text-right"  >
                <button (click)="create()" class="right" nbButton size="small" status="primary" nbSpinnerStatus="warning" class="right bg-color-primary border-primary">Cadastrar</button>
            </div>
        </div>
        
    </form>
</div>
<div class="row mt-2 ml-2 mr-2" *ngIf="openList" >
    <div class=" col-6 width-100 left text-left p-2">
        <button (click)="openList=false" nbButton size="small" class="smallfont"   status="primary">Voltar</button>
     </div>
    <!-- <div class="row"  >
        <mat-form-field class="col-md-12 right">
            <input matInput placeholder="Buscar por nome" [value]="studenNameUni" [(ngModel)]="studenNameUni">
            <mat-icon matSuffix (click)="getUniStudentsByName(studenNameUni)">search</mat-icon>
        </mat-form-field>
    </div>-->
       <!-- School, Unit, Class -->
    <div class="row p-3 mb-2">
        <div class="col-md-4" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getUnits($event.value)"
                    placeholder="Selecione a escola">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
            <mat-form-field  class="width-100">
                <mat-select (selectionChange)="getClasses($event.value)"
                    placeholder="Selecione a unidade">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                        {{unit.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- classes -->
        <div class="col-md-4">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getStudentsList($event.value)"
                placeholder="Selecione a turma">
                    <mat-option *ngFor="let class of classes" [value]="class.id">
                        {{class.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div >

    </div>
    <div *ngIf="!studentsList" class="row w-100 m-3 center text-center" style=" font-size:12px;"> 
        <span class="center" ><i> <strong> Selecione uma TURMA para carregar os ALUNOS!</strong> </i></span> 
    </div>
    <ng-container *ngIf="studentsList">
        <nb-list class="width-100">
            <nb-list-item class="bg-color-accent list-header tableborder">
                <div class="row">
                    <div class="col-md-5 mt-1">Nome</div>
                    <div class="col-md-5 mt-1">Status</div>
                    <div class="col-md-2 mt-1">Ações</div>
                </div>
            </nb-list-item>


            <nb-list-item class="itenstable" *ngFor="let student of studentsList">
                <div class="row">
                    <div class="col-md-5">{{student.name}}</div>
                    <div class="col-md-5">Cadastrado</div>
                    <!--<div class="col-md-1 text-right">
                        <button nbButton (click)="edituni(student)" size="tiny" outline status="primary">Editar</button>
                    </div>-->
                    <div class="col-md-1 text-right">
                        <button size="tiny" (click)="delete(student.id)" status="danger" nbButton ghost>
                            <nb-icon icon="trash"></nb-icon>
                        </button>
                    </div>
                </div>
            </nb-list-item>
        </nb-list> 
        <p  class="w-100 text-center m-3" *ngIf="studentsList.length === 0">Nenhum resultado!</p>
        <!--<mat-paginator class="w-100 " *ngIf="studentsList"  [length]="totalPage" [pageSize]="pageSize" (page)="pageEvent($event)"></mat-paginator>-->
    </ng-container>
    
</div>
 
