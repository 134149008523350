import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 
import { LinkedQueue } from 'src/app/core/components/data-structures/LinkedQueue.model';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PlanSelect } from '../../rdo-manager/create-theme/theme';
import { SchoolSelect } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { DuplicateDisciplineController } from '../components/duplicate-discipline.controller';
import { Discipline } from '../discipline';
import { DisciplineService } from '../discipline.service';
import { DuplicateDisciplineService } from './duplicate-discipline.service';

@Component({
  selector: 'app-duplicate-discipline',
  templateUrl: './duplicate-discipline.component.html',
  styleUrls: ['./duplicate-discipline.component.scss']
})
export class DuplicateDisciplineComponent implements OnInit {

  private disciplinesDuplicate:any[];
  public disciplineForm: FormGroup;
  public schools: SchoolSelect[];
  public selectedSchools: SchoolSelect[];
  public plans: PlanSelect[];
  public loading: boolean;
  public list: boolean;
  public editMode: boolean;
  public disciplineName: string;
  public disciplines: Discipline[];
  public allDisciplineSelected: any[];  
  public disciplinesSelect: Discipline[];
  public videos: any[];
  private queue:LinkedQueue<any> = new LinkedQueue();
  private duplicateDisclipline:any;
  public discipline : any;
  constructor(
    private alertService: AlertService,
    private studyPlanService: StudyPlanService,
    private schoolService: SchoolService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private disciplineService: DisciplineService,
    private duplicateDisciplineService:DuplicateDisciplineService
  ){}

  ngOnInit() {
    this.allDisciplineSelected = [];
    this.disciplineForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      tag: ['', Validators.required],
      justExercises: [],
      videos: [],
      schools: []
    });

    this.duplicateDisclipline  = new DuplicateDisciplineController(
      this.alertService, 
      this.duplicateDisciplineService,
      this.schoolService,
      this.studyPlanService,
      this.userService,
      this.disciplineService
      );

    if (this.userService.isAdmin())  this.duplicateDisclipline.requestSchools();
    if (this.userService.isGeneralCoordinator()) this.duplicateDisclipline.requestPlans(this.userService.getUserSchool());

    this.init();    
  }

  init(){
    this.duplicateDisciplineService.emitDuplicateDiscipline.subscribe((obj)=>{
      if(obj.name === 'disciplineLoaded'){
        this.allDisciplineSelected = this.duplicateDisclipline.get();
      } 

    });
  } 


  openList(){
    if (this.userService.isGeneralCoordinator()) {
      this.duplicateDisclipline.requestPlans(this.userService.getUserSchool());
    }
    this.list = true;
  }

  clear(){
    this.disciplines = [];
  }

  selectedDisciplines(discipline: Discipline){
    this.duplicateDisclipline.selectedDisciplines(discipline);
  }
}
