import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { Video } from '../video';
import { VideoService } from '../video.service';

@Component({
    selector: 'app-modal-video-youtube',
    templateUrl: './modal-video-youtube.component.html',
    styleUrls: ['./modal-video-youtube.component.scss'],
})
export class ModalVideoYoutubeComponent implements OnInit {
    public playerYoutube: any;
    public playYoutube: boolean;
    public isRecordYoutube: boolean;
    public youtubeLiveId: string;
    public youtubeVolume: number = 100;
    public controlYoutube: number;
    public showMask: boolean;
    public isTwo: boolean;
    public isIncognito: boolean;
    public elem: any;
    public youtubeTime: any;
    public youtubeDuration: any;
    public showPlay: boolean;
    public controlCloseMask: boolean;
    public rateVideo: string;
    public valueRate: number;
    public myTimeout: any = null;
    public showConfig: boolean;
    public heightscreen: string;
    public youtubeTimeShow: any;
    public youtubeDurationShow: any;
    public allQualitys = [];
    public valueQuality : string;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public video: Video,
        @Optional() public dialogRef: MatDialogRef<ModalVideoYoutubeComponent>,
        @Inject(DOCUMENT) private document: any,
        private videoService: VideoService,
        public layoutService: LayoutService
    ) {}

    ngOnInit() {
      if(this.video.embed.code.includes('https://www.youtube.com/embed/')){
        this.video.embed.code = this.video.embed.code.replace('https://www.youtube.com/embed/' , '');
      }

        this.heightscreen = (window.innerHeight - 100).toString();
        this.showConfig = false;
        this.rateVideo = 'X1';
        this.valueRate = 1;

        this.controlCloseMask = true;
        this.showPlay = true;
        this.elem = this.document.documentElement;

        if (this.video) {
            this.getVideoYTMask(this.video.id);
        }

        this.valueQuality = 'auto';

        //this.openFullScreen(this.elem);
    }

    savePlayer(player) {
        this.playerYoutube = player;
        this.playerYoutube.setVolume(this.youtubeVolume);
        this.playerYoutube.seekTo(this.video.currentTime);
        this.youtubeDuration = this.playerYoutube.getDuration();

        const timestamp = this.youtubeDuration

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeDurationShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        //this.goYoutubeTime();
    }

    openMask() {
        if (this.playYoutube) {
            if (!this.layoutService.isMobile) {
                this.playYoutube = false;
                this.showPlay = true;
            } else {
                this.playYoutube = false;
            }
            this.playerYoutube.pauseVideo();
            this.youtubeTime = this.playerYoutube.getCurrentTime();

            const timestamp = this.youtubeTime

            const date = new Date(timestamp * 1000);

            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
    }

    setVideoRate() {
        switch (this.valueRate) {
            case 1:
                this.valueRate = 1.25;
                this.playerYoutube.setPlaybackRate(1.25);
                this.rateVideo = 'X1.25';
                break;
            case 1.25:
                this.valueRate = 1.5;
                this.playerYoutube.setPlaybackRate(1.5);
                this.rateVideo = 'X1.50';
                break;
            case 1.5:
                this.valueRate = 2;
                this.playerYoutube.setPlaybackRate(2);
                this.rateVideo = 'X2';
                break;
            case 2:
                this.valueRate = 1;
                this.playerYoutube.setPlaybackRate(1);
                this.rateVideo = 'X1';
                break;
        }
    }

    setForward() {
        this.playerYoutube.seekTo(this.playerYoutube.getCurrentTime() + 30);
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    rewindVideo() {
        this.playerYoutube.seekTo(this.playerYoutube.getCurrentTime() - 30);
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    openConfig(){
      this.showConfig = !this.showConfig;

      let qualitylevels = this.playerYoutube.getAvailableQualityLevels();
        console.log(this.playerYoutube.getAvailableQualityLevels());

        qualitylevels.forEach(element => {
            if(element != "medium" && element != "high" && element != "tiny"){
                this.allQualitys.push(element);
            }
        });

        console.log(this.allQualitys , 'ble bvle');
    }

    getVideoYTMask(videoId: number) {
        this.videoService.getVideoYTMask(videoId).subscribe((res) => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                let isMask = response.data['youtubeMask'] as number;

                if (isMask == 1) {
                    this.showMask = true;
                    this.controlYoutube = 0;
                } else {
                    this.showMask = false;
                    this.controlYoutube = 1;
                }
            }
        });
    }

    exitFullScreen() {
        this.dialogRef.close(this.playerYoutube.getCurrentTime());
    }

    pauseVideo() {
        this.showPlay = true;
        this.playerYoutube.pauseVideo();
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    openFullScreen(element: any) {
        if (element.requestFullScreen()) {
            this.elem.requestFullScreen();
        }
    }

    goYoutubeTime() {
        this.playerYoutube.seekTo(this.youtubeTime);

        const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    openMaskHover() {
        if (this.myTimeout) {
            clearTimeout(this.myTimeout);
        }

        if (!this.layoutService.isMobile) {
            this.playYoutube = false;
            this.youtubeTime = this.playerYoutube.getCurrentTime();

            const timestamp = this.youtubeTime

            const date = new Date(timestamp * 1000);

            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }

        this.myTimeout = setTimeout(() => {
            this.closeMaskOver();
        }, 2000);
    }

    closeMaskOver() {

        if (!this.layoutService.isMobile) {
            this.playYoutube = true;
        }
        this.youtubeTime = this.playerYoutube.getCurrentTime();

        const timestamp = this.youtubeTime

        const date = new Date(timestamp * 1000);

        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    changeQuality(){
      let index = this.allQualitys.indexOf(this.valueQuality);

     /*  if(index+1 > this.allQualitys.length){
        this.index = 0;
      } */

      this.valueQuality = this.allQualitys[index +1];

      if(!this.valueQuality){
        this.valueQuality = this.allQualitys[0];
      }

      this.playerYoutube.setPlaybackQuality(this.valueQuality);
    }

    updateTime() {
        setTimeout(() => {
            this.youtubeTime = this.playerYoutube.getCurrentTime();
            this.updateTime();

            const timestamp = this.youtubeTime

            const date = new Date(timestamp * 1000);

            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }, 5000);
    }

    setVolumeYoutube() {
        this.playerYoutube.setVolume(this.youtubeVolume);
    }

    slideMove(event){
      const timestamp = event.value;

      const date = new Date(timestamp * 1000);

      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      this.youtubeTimeShow = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    playVideoYT() {
        if (!this.layoutService.isMobile) {
            this.showPlay = false;
            this.playYoutube = true;
        } else {
            this.playYoutube = true;
        }

        this.playerYoutube.playVideo();
        this.playerYoutube.setVolume(this.youtubeVolume);
        this.youtubeTime = this.playerYoutube.getCurrentTime();
    }
}
