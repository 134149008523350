import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Notifications } from 'src/app/modules/admin/notifications/notifications';
import { NotificationsService } from 'src/app/modules/admin/notifications/notifications.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { AllNotifications } from './all-notification';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public allNotifications: AllNotifications[];
  public notification: boolean = true;
  public read: boolean = false;
  public emitCheckNotifications = new EventEmitter<string>();
  public notify: Notifications[];
  
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public notifications: Notifications,
    @Optional() public dialogRef: MatDialogRef<NotificationsComponent>,
    public layoutService: LayoutService,
    public userService: UserService,
    public router: Router,
    private alertService: AlertService,
    public notificationsService: NotificationsService,
    public headerService: HeaderService
  ) { }
 
  ngOnInit() {
/*    //console.log(this.notifications);

    /*  NOTIFICATIONS */
    /* let userId = Number(this.userService.getUserId());
    let profile = Number(this.userService.getUserProfileId());
    let schoolId = Number(this.userService.getUserSchool());
    this.getAllNotifications(schoolId, userId, profile);
    this.headerService.emitCheckNotifications.subscribe((data)=>{
      setInterval(() => {
          this.getAllNotifications(schoolId, userId, profile);
      }, 180000); 

    });
*/
  }
/*
  closeDialog(){
    this.dialogRef.close();
  } */ 

  /* viewMore(id: number){
    //window.localStorage.setItem('closeModal', 'true');
  }

  markAsRead(notificationId: number){
    const userId = this.userService.getUserId();
    let profile = Number(this.userService.getUserProfileId());
    let schoolId = Number(this.userService.getUserSchool());
    this.notificationsService.markAsRead(notificationId, userId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if(!response.error){
          this.alertService.success('Mensagem lida!', 3);
          this.getAllNotifications(schoolId, userId, profile);
          //this.notify = response.data as Notifications[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente'));
  }

  getAllNotifications(schoolId:number, userId:number, profile: number){
    this.notificationsService.getAllNotifications(schoolId, userId, profile)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (response.data && response.data != null) {
        this.notify = response.data as Notifications[];
      }
      //this.loading = false;
    }, err => { 
      this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente');
    });
  }


  removeNotification(notificationId: number){
    this.notificationsService.removeNotification(notificationId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (response.data && response.data != null) {
        this.notify = response.data as Notifications[];
      }
      //this.loading = false;
    }, err => { 
      this.alertService.error('Houve um erro ao buscar as notificações. Verifique a conexão e tente novamente');
    });
  } */
}
