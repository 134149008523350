import { stringify } from '@angular/compiler/src/util';
import { ResponseApi } from './../../../../core/models/response-api';
import { Class, ClassSelect } from './../../school/class';
import { AdminService } from './../../admin.service';
import { AlertService } from './../../../../shared/services/alert.service';
import { SchoolService } from 'src/app/modules/admin/school/school.service';
import { UserService } from './../../../user/user.service';
import { MaskService } from './../../../../shared/services/mask.service';
import { TaskManagerService } from './../task-manager.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { Task } from '../task';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';


@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnInit {


  public formTasks: FormGroup;
  public tasks: Task[];
  public classes: ClassSelect[];
  public loading: boolean;
  public file: any;
  public extension: string;
  public materialObservation: string;
  public className: string;
  public plans : StudyPlan[];
  public selectedPlans : StudyPlan[];
  public taskLink: string;
  public taskDescription: string;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public task: Task,
    @Optional() public dialogRef: MatDialogRef<TaskEditComponent>,
    private dialog: MatDialog,
    public taskManagerService: TaskManagerService,
    public maskService: MaskService,
    public userService: UserService,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private studyPlanService : StudyPlanService,
  ) { }

  ngOnInit() {
    this.taskLink = this.task.taskLink;
    this.formTasks = this.formBuilder.group({
      id: [],
      taskName: ['', Validators.required],
      classId: [''],
      finalDate: ['', Validators.required],
      taskSubject: [''],
      extension: [''],
      file: [''],
      taskDescription: [''],
      teacherId: [],
      className: [''],
      plans:[]
    });

    if(this.userService.isTeacher()){
      this.getTeacherPlans(this.userService.getUserId());
    }
    else{
      this.getPlansBySchool(this.userService.getUserSchool());
    }

    if(this.task.id){
      this.getTask(this.task.id);
      this.getTaskPlans(this.task.id);
    }

    this.selectedPlans = [];

    if(this.userService.isGeneralCoordinator())   this.getClassesBySchool(this.userService.getUserSchool())
    if(this.userService.isUnitCoordinator())      this.getClassesSelection(this.userService.getUserUnit());
    if(this.userService.isTeacher())              this.getTeacherClasses(this.userService.getUserId())
    if(this.userService.isAdmin())                this.getClasses();
  }

  getClassesSelection(unitId?: number) {
    this.schoolService.getClassesSelection(unitId).subscribe(
      (res) => {
        const response = res.body as ResponseApi;
        if(!response.error) this.classes = response.data as ClassSelect[];
        else {
          this.classes = [];
          this.alertService.error(response.error);
        }
      },(err) => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!')
    );
  }

  getClassesBySchool(schoolId?:number){
    this.schoolService.getClassesBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.classes = response.data as ClassSelect[];
        else {
          this.classes = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as turmas. Verifique a conexão e tente novamente!'));
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
          this.classes = this.classes.map(c => {
            c.id = Number(c.id)
            return c;
          })
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  getClasses() {
    this.schoolService.getClasses()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  handleFileInput(files: FileList): void {
    if (files && files[0]) {

      const file = files[0];
      this.extension = file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.file = reader.result;
      };
    }
  }

  getTask(taskId: number) {
    this.loading = true;
    this.taskManagerService.getTask(taskId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.task = response.data as Task;
          this.taskLink = this.task.taskLink;
          this.formTasks.patchValue(this.task);
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente')
      });
  }

  openLink(url: string){
    window.open(url, '_blank');
  }

  removeLink(){
    this.taskLink = null;
  }
  removeDesc(){
    this.taskDescription = null;
  }

  updateTask() {
    if (this.file) {
      this.formTasks.get('file').setValue(this.file);
      this.formTasks.get('extension').setValue(this.extension);
    }

    if(this.selectedPlans.length > 0){
      let plansIds = [];
    this.selectedPlans.forEach(select => {
      plansIds.push(select.id);
    });
    this.formTasks.get('plans').setValue(plansIds);
    }
    this.loading = true;
    const param = this.formTasks.getRawValue();
    this.taskManagerService.updateTask(param)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.alertService.success('Dados Alterados com sucesso');
          this.dialogRef.close()
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente')
      });;
  }

  addPlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.indexOf(plan);

    if (index < 0) {
      this.selectedPlans.push(plan);
      this.formTasks.get('plans').reset();
    }
  }
  removePlanChip(plan: StudyPlan) {
    const index = this.selectedPlans.indexOf(plan);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }

  getTeacherPlans(teacherId){
    this.taskManagerService.getTeacherPlans(teacherId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.plans = response.data as StudyPlan[];
      }
    }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'))
  }

  getTaskPlans(taskId : number){
    this.taskManagerService.getTaskPlans(taskId)
    .subscribe(res=>{
      const response = res.body as ResponseApi;

      if(!response.error){
        let plansIds = response.data as StudyPlan[];

        plansIds.forEach(plan => {
          this.selectedPlans.push(plan);
        });
      }
    })
  }

  getPlansBySchool(schoolId: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlansBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
}

