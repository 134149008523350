import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LayoutService } from 'src/app/shared/services/layout.service';


@Component({
  //selector: 'app-write-task-dialog',
  templateUrl: './write-task-dialog.component.html',
  styleUrls: ['./write-task-dialog.component.scss']
})
export class WriteTaskDialogComponent implements OnInit {

    @Input() public taskText: string = "";
    text: string = "";

    constructor(
        public dialogRef: NbDialogRef<WriteTaskDialogComponent>,
        public layoutService: LayoutService) { }


    ngOnInit(): void {
      if (this.taskText !== "") {
        this.text = this.taskText;
      }
    }

    close() {
        this.dialogRef.close();
    }

    save() {

      const splitEnteredText = this.text.split("\n");
      this.dialogRef.close(splitEnteredText);
    }
}

