import { Exercise } from './../../exercise/exercise';
import { SchoolService } from './../../school/school.service';
import { Video } from './../../../video/video';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { ExerciseAlternative } from 'src/app/modules/video/alternative';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { environment } from 'src/environments/environment';
import { MatIconRegistry } from '@angular/material/icon';

import { DomSanitizer } from '@angular/platform-browser';
import { VideoService } from 'src/app/modules/video/video.service';

import { AdminService } from '../../admin.service';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { ExerciseService } from '../../exercise/exercise.service';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { Summation } from '../summation';
import { SummationService } from '../summation.service';

@Component({
  selector: 'app-summation-create',
  templateUrl: './summation-create.component.html',
  styleUrls: ['./summation-create.component.scss']
})
export class SummationCreateComponent implements OnInit {

  public questionEditor = ClassicEditor;
  public editor1 = ClassicEditor;
  public editor2 = ClassicEditor;
  public editor4 = ClassicEditor;
  public editor8 = ClassicEditor;
  public editor16 = ClassicEditor;
  public editor32 = ClassicEditor;
  public editor64 = ClassicEditor;
  public question: string;
  public editorConfig;
  public filter = '';
  private apiUrl = environment.ApiUrl;

  public alt1: ExerciseAlternative;
  public alt2: ExerciseAlternative;
  public alt4: ExerciseAlternative;
  public alt8: ExerciseAlternative;
  public alt16: ExerciseAlternative;
  public alt32: ExerciseAlternative;
  public alt64: ExerciseAlternative;

  public videos: Video[];
  public selectedVideos: Video[];
  public createExerciseForm: FormGroup;
  public showExercisesList: boolean;
  public exercises: Exercise[];
  public correctAlternatives: string[];
  public editMode: boolean;
  public loading: boolean;
  public filterName: string;
  public loadingInit = true;
  public filterDisciplineId: number;
  public filterDisciplineType = 0;
  public filterVideoId: number;
  public selectedDisciplines: Discipline[];
  public disciplines: Discipline[];
  public disciplinesAux: Discipline[];
  public exerciseDisciplines: Discipline[];
  public filterDisciplines: Discipline[];
  public admUpdate$: Subscription;
  public comment: string;
  public correctAlternative: string;
  public filterVideos: Video[];
  public filteredExercises: Exercise[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public summationExercise: Summation,
    @Optional() public dialogRef: MatDialogRef<SummationCreateComponent>,
    private dialog: MatDialog,
    private alertService: AlertService,
    private formBulder: FormBuilder,
    private disciplineService: DisciplineService,
    private iconRegistry: MatIconRegistry,
    private adminService: AdminService,
    private summationService: SummationService,
    private exerciseService: ExerciseService,
    private sanitizer: DomSanitizer,
    public userService: UserService,
    private schoolService: SchoolService,
    public maskService: MaskService) { }



  ngOnInit(): void {
    this.editorConfig = {
      ckfinder: {
        // tslint:disable-next-line: max-line-length
        uploadUrl: this.apiUrl + '/upload/exerciseImg',
      },
      image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

        styles: [
          // This option is equal to a situation where no style is applied.
          'full',

          // This represents an image aligned to the left.
          'alignLeft',

          // This represents an image aligned to the right.
          'alignRight'
        ]
      }
    };

    this.iconRegistry.addSvgIcon(
      'vimeo',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/vimeo.svg'));
    this.iconRegistry.addSvgIcon(
      'youtube',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/youtube.svg'));
    this.iconRegistry.addSvgIcon(
      'novideo',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/novideo.svg'));
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {

    this.alt1 = { option: '1', value: '' };
    this.alt2 = { option: '2', value: '' };
    this.alt4 = { option: '4', value: '' };
    this.alt8 = { option: '8', value: '' };
    this.alt16 = { option: '16', value: '' };
    this.alt32 = { option: '32', value: '' };
    this.alt64 = { option: '64', value: '' };
    this.question = '';
    this.comment = '';
    this.getDisciplinesInit();

    if (this.userService.isAdmin()) {
      this.getDisciplinesExercisesOnly();
    } else if (this.userService.isGeneralCoordinator()) {
      this.getSchoolDisciplinesExercisesOnly(this.userService.getUserSchool());
    }

    this.createExerciseForm = this.formBulder.group({
      id: [''],
      name: ['', Validators.required],
      tag: ['', Validators.required],
      question: ['', Validators.required],
      videos: [],
      alternatives: ['', Validators.required],
      disciplineId: [],
      correctAlternative: ['', Validators.required],
      comment: ['', Validators.required],
      embedCode: [''],
      embedType: [''],
      commentEmbedCode: [''],
      commentEmbedType: [''],
    });

    this.selectedVideos = [];
    this.selectedDisciplines = [];
    if (this.summationExercise) { this.initializeEditMode(); }
  }

  openList() {
    this.exercises = [];
    this.showExercisesList = true;
    this.filterDisciplineId = null;
    this.filteredExercises = [];
  }

  createExercise() {

    if (this.selectedDisciplines.length === 0) {
      this.alertService.error('Você deve selecionar ao menos uma disciplina!');
      return;
    }

    this.loading = true;
    const videosIds = [];
    const disciplinesIds = [];
    const alternatives = [];

    alternatives.push(this.alt1, this.alt2, this.alt4, this.alt8, this.alt16, this.alt32, this.alt64);
    this.selectedVideos.map(v => videosIds.push(v.id));
    this.selectedDisciplines.map(d => disciplinesIds.push(d.id));
    this.createExerciseForm.get('videos').setValue(videosIds);
    this.createExerciseForm.get('disciplineId').setValue(disciplinesIds);
    this.createExerciseForm.get('correctAlternative').setValue(this.correctAlternatives);
    this.createExerciseForm.get('question').setValue(this.question);
    this.createExerciseForm.get('alternatives').setValue(alternatives);
    this.createExerciseForm.get('comment').setValue(this.comment);

    const exercise = this.createExerciseForm.getRawValue() as Exercise;
    exercise.embed = { code: this.createExerciseForm.get('embedCode').value, type: this.createExerciseForm.get('embedType').value };
    exercise.commentEmbed = {
        code: this.createExerciseForm.get('commentEmbedCode').value,
        type: this.createExerciseForm.get('commentEmbedType').value
    };

    const summation = this.createExerciseForm.getRawValue() as Summation;

    if (this.createExerciseForm.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      this.loading = false;
      return;
    }

    this.summationService.createSummationExercise(summation)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Exercício cadastrado', 3);
          this.createExerciseForm.reset();
          this.correctAlternatives = [];
          this.init();
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao cadastrar exercício. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplinesInit() {
    if (this.userService.isAdmin()) {
      this.getDisciplinesVideosOnly();
      this.getDisciplinesExercisesOnly();
    } else if (this.userService.isGeneralCoordinator()) {

      this.getSchoolDisciplinesVideosOnly(this.userService.getUserSchool());
      this.getSchoolDisciplinesExercisesOnly(this.userService.getUserSchool());

    } else if (this.userService.isTeacher()) {
      this.getTeacherDisciplines(this.userService.getUserId());

    } else if (this.userService.isUnitCoordinator()) {
      this.getUnitDisciplines(this.userService.getUserUnit());
    }
  }

  editExercise(summationExercise: Summation) {
    const dialogRef = this.dialog.open(SummationCreateComponent, {
      minWidth: '60vw',
      data: summationExercise,
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log("this.filterDisciplineId :", this.filterDisciplineId);
      this.getDisciplineExercises(this.filterDisciplineId);
    });
  }

  removeExercise(exerciseId: number) {
    if (confirm('Deseja realmente remover o exercício?')) {
      this.summationService.removeSummationExercise(exerciseId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('exercício removido', 3);
            this.getDisciplineExercises(this.filterDisciplineId);
            //this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o exercício. Verifique a conexão e tente novamente'));
    }
  }

  getDisciplinesVideosOnly() {
    this.disciplineService.getDisciplines(true)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.filterDisciplines = response.data as Discipline[];
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingInit = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loadingInit = false;
      });
  }

  getSchoolDisciplinesVideosOnly(schoolId: number) {
    this.disciplineService.getSchoolDisciplinesVideosOnly(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.filterDisciplines = response.data as Discipline[];
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingInit = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loadingInit = false;
      });
  }

  private getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.filterDisciplines = response.data as Discipline[];
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingInit = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde');
        this.loadingInit = false;
      });
  }

  addVideoChip(video: Video) {
    const index = this.selectedVideos.indexOf(video);

    if (index < 0) {
      this.selectedVideos.push(video);
      this.createExerciseForm.get('videos').reset();
    }
  }

  removeVideoChip(video: Video) {
    const index = this.selectedVideos.indexOf(video);

    if (index >= 0) {
      this.selectedVideos.splice(index, 1);
    }
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.filterDisciplines = response.data as Discipline[];
          this.disciplines = response.data as Discipline[];
          this.disciplinesAux = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingInit = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde');
        this.loadingInit = false;
      });
  }

  // edit mode methods

  initializeEditMode() {
    this.loadingInit = false;

    this.editMode = true;
    this.summationExercise.alternatives.sort((a, b) => Number(a.option) < Number(b.option) ? -1 : Number(a.option) < Number(b.option) ? 1 : 0);
    this.question = this.summationExercise.question;

    this.getSummationExerciseCorrectAlternatives(this.summationExercise.id);
    this.getExerciseDisciplines(this.summationExercise.id);

    this.alt1.value = this.summationExercise.alternatives[0]?.value ? this.summationExercise.alternatives[0]?.value : null;
    this.alt2.value = this.summationExercise.alternatives[1]?.value ? this.summationExercise.alternatives[1]?.value : null;
    this.alt4.value = this.summationExercise.alternatives[2]?.value ? this.summationExercise.alternatives[2]?.value : null;
    this.alt8.value = this.summationExercise.alternatives[3]?.value ? this.summationExercise.alternatives[3]?.value : null;
    this.alt16.value = this.summationExercise.alternatives[4]?.value ? this.summationExercise.alternatives[4]?.value : null;
    this.alt32.value = this.summationExercise.alternatives[5]?.value ? this.summationExercise.alternatives[5]?.value : null;
    this.alt64.value = this.summationExercise.alternatives[6]?.value ? this.summationExercise.alternatives[6]?.value : null;
    this.createExerciseForm.get('name').setValue(this.summationExercise.name);

    this.createExerciseForm.patchValue(this.summationExercise);
  }


  getSummationExerciseCorrectAlternatives(exerciseId: number) {
    this.exerciseService.getExerciseCorrectAlternative(exerciseId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.correctAlternatives = (response.data as { correct: string }).correct.split('+');
          const resp = response.data as { correct: string, comment: string };
          this.correctAlternative = resp.correct;
          this.comment = resp.comment;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar o gabarito. Verifique a conexão e tente novamente'));
  }


  saveChanges() {

    if (this.selectedDisciplines.length === 0) {
      this.alertService.error('Você deve selecionar ao menos uma disciplina!');
      return;
    }

    this.loading = true;
    const videosIds = [];
    const alternatives = [];
    const disciplinesIds = [];

    alternatives.push(this.alt1, this.alt2, this.alt4, this.alt8, this.alt16, this.alt32, this.alt64);
    this.selectedVideos.map(v => videosIds.push(v.id));
    this.selectedDisciplines.map(d => disciplinesIds.push(d.id));
    this.createExerciseForm.get('videos').setValue(videosIds);
    this.createExerciseForm.get('disciplineId').setValue(disciplinesIds);
    this.createExerciseForm.get('correctAlternative').setValue(this.correctAlternatives);
    this.createExerciseForm.get('question').setValue(this.question);
    this.createExerciseForm.get('alternatives').setValue(alternatives);
    this.createExerciseForm.get('comment').setValue(this.comment);
    this.createExerciseForm.get('id').setValue(this.summationExercise.id);

    const summation = this.createExerciseForm.getRawValue() as Summation;

    if (this.createExerciseForm.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      this.loading = false;
      return;
    }

    this.summationService.saveSummationExerciseChanges(summation)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Exercício editado com sucesso', 3);
          this.dialogRef.close();
          //this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao salvar as alterações da turma. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplineVideos(disciplineId: number) {
    this.disciplineService.getDisciplineVideos(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[]
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
  }

  getDisciplines() {
    if (this.userService.isAdmin()) {
      this.getDisciplinesExercisesOnly();
    } else if (this.userService.isGeneralCoordinator()) {
      this.getSchoolDisciplinesExercisesOnly(this.userService.getUserSchool());
    }
  }

  getVideos(disciplineId: number) {
    this.filterVideoId = null;
    this.disciplineService.getDisciplineVideos(disciplineId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.filterVideos = response.data as Video[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
}

  getSchoolDisciplinesExercisesOnly(schoolId: number) {
    this.disciplineService.getSchoolDisciplinesExercisesOnly(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.exerciseDisciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }

        this.loadingInit = false;
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas de exercícios. Verifique a conexão e tente novamente'));
  }

  addDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.indexOf(discipline);

    if (index < 0) {
      this.selectedDisciplines.push(discipline);
      this.createExerciseForm.get('disciplineId').reset();
    }
  }

  removeDisciplineChip(discipline: Discipline) {
    const index = this.selectedDisciplines.indexOf(discipline);

    if (index >= 0) {
      this.selectedDisciplines.splice(index, 1);
    }
  }

  /* getExerciseDisciplines(exerciseId: number) {
    this.summationService.getSummationExerciseDisciplines(exerciseId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedDisciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }
 */
  getExerciseDisciplines(exerciseId: number) {
    this.exerciseService.getExerciseDisciplines(exerciseId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedDisciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  getDisciplinesExercisesOnly() {
    this.disciplineService.getDisciplinesExercisesOnly()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.exerciseDisciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
        this.loadingInit = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas de exercícios. Verifique a conexão e tente novamente')
      });
  }

  public getDisciplineExercises(disciplineId: number) {
    this.loading = true;
    this.summationService.getSummationExercisesByDiscipline(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.exercises = response.data as Exercise[];
          this.filteredExercises = response.data as Exercise[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os exercícios. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  filterDisciplinesRegister(filter: string) {
    this.disciplines = [...this.disciplinesAux].filter(discipline => {
      return discipline.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
  }

  applyFilter() {
    if (this.filterName.length) {
      this.filteredExercises = [...this.exercises].filter(exercise =>
        exercise.name.toLowerCase().indexOf(this.filterName.toLowerCase()) > -1
      );
    } else {
      this.filteredExercises = [...this.exercises];
    }
  }


}
