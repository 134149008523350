<div class="container pt-5 pb-5">
      <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Criar Integração</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-create-integration></app-create-integration>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Portal Integração</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-portal></app-portal>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Integração Unimestre</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-integration-unimestre></app-integration-unimestre>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Integração Convergindo</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-integration-convergindo></app-integration-convergindo>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Integração Seren </span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-integration-seren></app-integration-seren>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Adicionar Identificador Externo</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-external-integration></app-external-integration>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Buscar Relatório</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-search-report></app-search-report>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                <span class="text-color-accent">Relatório Identificador Externo por Escola</span>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-school-report></app-school-report>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>
