<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-8 mt-2" [ngClass]="{'col-12': layoutService.isMobile}">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">Tópicos da</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Comunidade</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Comunidade</h6>
                        <p [ngClass]="{'fs-13 pb-0': layoutService.isMobile}">Crie seu tópico, deixe sua dúvida e colabore com seus colegas</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row mw-100 m-0" [ngClass] = "{'pt-0': !layoutService.isMobile, 'pb-4': layoutService.isMobile}">
        <div class="col-md-8 pb-3" style="background: #FFFFFF; border-radius: 10px;">
            <ng-container>
                <div class="mb-2">
                    <button (click)="backBtn()" color="accent" mat-button class="mt-3 mb-1 hvr-grow  pl-3">
                        <mat-icon>reply</mat-icon> <span class="back">Retornar</span>
                    </button>
                </div>
                <div class="mt-3 p-4" [ngStyle]="{'border-radius': '20px','background-color': '#f5f6f8'}">
                    <div class="comment-content" style="width: 80%">
                        <div class="comment-date pl-0 mt-1">{{topic?.created_at | date : 'dd LLL, y '}}</div>
                        <div class="comment-title pl-0 mt-1">{{topic?.title}}</div>
                        <div class="comment-img mt-1">
                            <p class="ml-0 mt-2">
                                <img class="comment-box-header-image " [src]="topic?.ImagenUsu" alt="">
                                <span class="comment-name ml-2">{{topic?.userName}}</span>
                                <span *ngIf="!topic?.totalAnswers" class="noresultFont ml-2 text-color-accent">Nenhuma Resposta</span>
                                <span *ngIf="topic?.totalAnswers" class="noresultFont ml-2 text-color-accent">{{topic?.totalAnswers}} Resposta</span>
                            </p>

                        </div>
                        <div class="ml-4 mr-2 fontText" [innerHtml]="topic?.communityComment"></div>
                    </div>
                    <div>
                        <div class="row mt-4" *ngIf="showAnswer">
                            <div class="col-md-11">
                                <ckeditor #scroll [editor]="questionEditor" [(ngModel)]="answerComment" [config]="editorConfig">
                                </ckeditor>
                                <div class="mt-3 mr-1 width-100 text-right">
                                    <button class="btnamswer bg-color-accent" size="medium" (click)="createAnswer(answerComment)">Salvar</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <div style="background: #FFFFFF;" class="pl-4">
                <label class="titleanswer mt-4 text-color-accent">Comentários</label>
                <div>
                    <div *ngFor="let answer of topicAnswers" style="background: #f5f6f8; border-radius: 10px;" class="mb-3">
                        <div class="comment-content">
                            <div class="pl-4">
                                <div class="comment-img ml-4" [ngClass]="{'mt-2': layoutService.isMobile}">
                                    <div class="row pl-3 w-100 d-flex justify-content-between">
                                        <div>
                                            <img class="comment-box-header-image" [ngClass]="{'comment-img-reply': isReply}" [src]="answer?.ImagenUsu" alt="">
                                            <span class="nameanswers ml-2"> {{answer?.userName}}</span>
                                        </div>
                                        <div class="divdeleteanswer">
                                            <button class="btndeletetopic" deleteAnswer(answer.id_answer) *ngIf="userService.isAdmin() || userService.getUserId() == answer?.userId" size="large" status="danger" nbButton ghost>
                                                <nb-icon icon="trash"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ml-3 fontText d-flex flex-column justify-content-center" [innerHtml]="answer?.answerText"></div>
                                    <div class="date-row">
                                        <span class="col-md-12 comment-date answer">{{answer?.created_at | date : 'dd LLL, y '}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="width-100 button2 mt-3" *ngIf="!layoutService.isMobile && !showAnswer"  [ngClass]="{'mt-4': !layoutService.isMobile}">
                <button (click)="openAnswerBox()" class="btnamswer bg-color-accent" style="width: 60%;">Responder</button>
            </div>
        </div>
        <div class="col-md-3 divlat width-100" [ngClass]="{'mt-4': layoutService.isMobile, 'ml-4 p-0 ': !layoutService.isMobile}" style="background-color: #ffffff; border-radius: 10px;">
            <div class="mt-4 pb-3">
                <span class="titlelat">Sobre o topico</span><br>
                <div class="divdown">
                    <span class="mt-2 subtitiles">Materia</span><br>
                    <div class="mt-2 margindisc" [ngClass]="{'w-75': !layoutService.isMobile, 'w-50': layoutService.isMobile}">{{topic?.disciplineName}}</div><br>
                    <span class="subtitiles">Interações</span><br>
                    <span class="fontBold">{{topicAnswers?.length}} respostas</span>
                    <div class="mt-2 width-100">
                        <button (click)="openAnswerBox()" class="btnamswer bg-color-accent"><span>Responder</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
