import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';

import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Classroom } from 'src/app/modules/classroom/classroom';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MaskService } from 'src/app/shared/services/mask.service';
import { School } from '../../school/school';
import { Class } from '../../school/class';
import { SchoolService } from '../../school/school.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Unit } from '../../school/unit';
import { UserService } from 'src/app/modules/user/user.service';
import { Observable, Subscription } from 'rxjs';
import { EditClassesByClassComponent } from '../edit-classes-by-class/edit-classes-by-class.component';

@Component({
  selector: 'app-classroom-create-by-class',
  templateUrl: 'classroom-create-by-class.component.html'
})

export class ClassroomCreateByClassComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public classrooms: Classroom[];
  public editMode: boolean;
  public showList: boolean;
  public showEdit: boolean;
  public loading: boolean;
  public filterClassId: number;
  public selectedUnitId: number;
  public schools: School[];
  public classes: Class[];
  public units: Unit[];
  private admUpdate$: Subscription;
  public showButton:boolean;
  public selectedSchoolId: number;
  public filterClass: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public classroom: Classroom,
    @Optional() public dialogRef: MatDialogRef<ClassroomCreateByClassComponent>,
    private classroomService: ClassroomService,
    private alertService: AlertService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public maskService: MaskService,
    public userService: UserService,
    private schoolService: SchoolService) { }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.form = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      classId: ['', Validators.required],
      schoolId: [''],
      unitId: [''],
      endDate: ['', Validators.required],
      initDate: ['', Validators.required],
      link: ['', Validators.required],
      recordLink: [''],
      live_vimeo_id:['']
    });
    this.profileInit();

    if (this.classroom) { this.initializeEditMode(); }
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      !this.showList && this.getSchools();
    } else
      if (this.userService.isGeneralCoordinator()) {
        this.getUnits(this.userService.getUserSchool());
      } else if (this.userService.isUnitCoordinator()) {
        this.getClasses(this.userService.getUserUnit());
      } else if (this.userService.isTeacher()) {
        this.getTeacherClasses(this.userService.getUserId());
      }
  }

  initializeEditMode() {
    this.editMode = true;
    this.form.patchValue(this.classroom);
    this.selectedSchoolId = this.classroom.schoolId;
    this.selectedUnitId = this.classroom.unitId;
    this.getUnits(this.selectedSchoolId);
    this.getClasses(this.classroom.unitId);
  }

  editClassrooms() {
    this.showEdit = true;
    this.showList = false;
    const classroom = this.classrooms;
    const dialogRef = this.dialog.open(EditClassesByClassComponent, {
      minWidth: '60vw',
      data: classroom
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showList = true;
      this.getClassrooms(this.filterClassId);
    });
  }

  edit(classroom: Classroom) {
    const dialogRef = this.dialog.open(ClassroomCreateByClassComponent, {
        minWidth: '60vw',
        data: classroom
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.getClassrooms(this.filterClassId);
      }
    });
  }

  openList() {

    this.showList = true;
  }

  getClassrooms(classId: number) {
    this.classroomService.getClassRoomsAdminByClass(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classrooms = response.data as Classroom[];
          this.showButton = true;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));

  }

  getSchools() {
    this.units = [];
    this.classes = [];
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId: number) {
    this.units = [];
    this.classes = [];
    this.selectedSchoolId = schoolId;
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId: number) {
    this.classes = [];
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  create() {
    if (this.form.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    const classroom = this.form.getRawValue() as Classroom;
    classroom.isVimeo = false

    this.classroomService.createClassroom(classroom)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Sala criada!');
          this.adminService.updateChields();
          this.form.reset();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;

      }, err => {
        this.alertService.error('Houve um erro ao criar a sala. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  saveChanges(c : Classroom) {
    if (this.form.status != "VALID") {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;
    let auxc = c as Classroom;
    const classroom = this.form.getRawValue() as Classroom;
    classroom.live_vimeo_id = auxc.live_vimeo_id;
    if(!c.isVimeo){
      classroom.isVimeo = false;
    }
    else{
      classroom.isVimeo = this.classroom.isVimeo;
    }

    this.classroomService.saveClassroomChanges(classroom)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Sala editada!');
          this.adminService.updateChields();
          this.form.reset();
          this.dialogRef.close(true);
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;

      }, err => {
        this.alertService.error('Houve um erro ao salvar as alterações da sala. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  saveChangeByClass(c : Classroom) {
    if (this.form.status != "VALID") {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;
    let auxc = c as Classroom;
    const classroom = this.form.getRawValue() as Classroom

    this.classroomService.saveClassroomChanges(classroom)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Sala editada!');
          this.adminService.updateChields();
          this.form.reset();
          this.dialogRef.close(true);
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;

      }, err => {
        this.alertService.error('Houve um erro ao salvar as alterações da sala. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  remove(classroomId: Classroom) {
    if (confirm('Deseja realmente excluir a sala?')) {
      this.classroomService.removeClassroom(classroomId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Removido!');
            this.adminService.updateChields();
            this.getClassrooms(this.filterClassId);
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a sala. Verifique a conexão e tente novamente'));
    }
  }

  getClassByName(name: string) {

    if (name) {

      this.classroomService.getClassByName(true, name)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.classrooms = response.data as Classroom[];
          }

          else {
            this.alertService.error(response.error);
          }
        })
    } else {
      this.alertService.error('O campo nome não pode estar vazio para fazer a busca!');
    }
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }
}
