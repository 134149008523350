import { Component, OnInit, Input, Output, Optional, ViewEncapsulation } from '@angular/core';
import { ForumComment } from '../forum-comment';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/modules/user/user.service';
import { environment } from 'src/environments/environment';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ForumService } from '../../forum.service';
import { EventEmitter } from '@angular/core';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
    selector: 'app-forum-add-comment',
    templateUrl: './forum-add-comment.component.html',
    styleUrls: ['./forum-add-comment.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ForumAddCommentComponent implements OnInit {

    API = environment.ApiUrl;
    commentForm: FormGroup;
    @Input() videoId: number;
    @Input() commentToResponse: number;
    @Output() addEvent: EventEmitter<ForumComment> = new EventEmitter();
    userImg = '';
    saving: boolean;


    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private forumService: ForumService,
        public layoutService: LayoutService) { }

    ngOnInit(): void {

        this.userImg = this.userService.getUserImage();
        this.commentForm = this.formBuilder.group({
            userId: [this.userService.getUserId()],
            videoId: [],
            text: ['', Validators.required],
            commentId: [],
            isReply: [false],
            blockComment: []
        });
    }

    save(commentId?: number) {

        if (!commentId) {
            this.commentForm.get('videoId').setValue(this.videoId);
            if (!this.commentForm.valid) {
                this.alertService.error('Comentário inválido!');
                return;
            }
        } else {
            this.commentForm.get('commentId').setValue(this.commentToResponse);
            this.commentForm.get('videoId').setValue(this.videoId);
            this.commentForm.get('isReply').setValue(true);
        }

        this.saving = true;
        const comment = this.commentForm.getRawValue() as ForumComment;

        comment.blockComment = 1;

        this.forumService.addComment(comment)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.addEvent.emit(response.data as ForumComment);
                    this.commentForm.get('text').setValue('');
                    this.alertService.success('Seu comentário foi registrado, e será avaliado por nossos moderadores para publicação, obrigado!');
                } else {
                    this.alertService.error(response.error);
                    this.addEvent.emit(null);
                }

                this.saving = false;
            }, err => {
                this.alertService.error('Houve um erro ao adicionar o seu comentário. Verifique a conexão e tente novamente');
                this.saving = false;
            });
    }
}
