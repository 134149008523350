 
 
 
import { isArray } from 'lodash';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service'; 
import { PlanSelect } from '../../rdo-manager/create-theme/theme';
import { SchoolSelect } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { Discipline } from '../discipline';
import { DisciplineService } from '../discipline.service';
import { DuplicateDisciplineService } from '../duplicate-discipline/duplicate-discipline.service';
import { DisciplineModel } from './models/Discipline.model';
import { DuplicateDisciplineModel } from './models/DuplicateDiscipline.model';
 
 
export class DuplicateDisciplineController {

        
    public loadingDuplicate: boolean = false;   
    public loading: boolean;   
    private duplicateDisciplineList:DuplicateDisciplineModel[] = []; 

    private schools:    SchoolSelect[] = [];
    private plans:      PlanSelect[]   = [];
    private disciplines:Discipline[]   = [];
    private duplicateState = {request:0, duplicate:0};
    public show: boolean;

    constructor(
        private alertService: AlertService,    
        private duplicateDisciplineService: DuplicateDisciplineService,
        private schoolService: SchoolService,
        private studyPlanService: StudyPlanService, 
        private userService: UserService, 
        private disciplineService: DisciplineService
    ){ }
    
    
    /* GETTERS SETTERS */
    public get(): DuplicateDisciplineModel[]{
        return this.duplicateDisciplineList;
    }

    public clear(): DuplicateDisciplineModel[]{
        this.duplicateDisciplineList = [];
        return this.duplicateDisciplineList;
    }

    public getOne(id:number): DuplicateDisciplineModel{
        return this.duplicateDisciplineList.filter((d)=> d.getId() === id)[0] ;
    }

    public add(discipline:any,  videos:any[]  ):void{

       
        let index = this.duplicateDisciplineList.length+1;
        let  d = new DuplicateDisciplineModel(index, discipline,videos  );
    
        this.duplicateDisciplineList.push(d); 
        this.duplicateDisciplineService.emitDuplicateDiscipline.emit({name:"disciplineLoaded",data:[] })
    }

    public  selectedDisciplines(discipline:any){
        this.getDiscipline(discipline.id );
    }

    public update(discilpineId:number, obj:any):void{
        this.remove(discilpineId);
        this.add(obj.discipline  , obj.videosobj ); 
        this.duplicateDisciplineService.emitDuplicateDiscipline.emit({name:"disciplineLoaded",data:[] })
    }

    public remove(disciplineId:number ):void{

        let  d = this.duplicateDisciplineList.filter((d)=> d.getId() !== disciplineId) ;  
        this.duplicateDisciplineList = d; 
        this.duplicateDisciplineService.emitDuplicateDiscipline.emit({name:"disciplineLoaded",data:[] })
    }

    public duplicate(discipline:any){  
        this.loadingDuplicate = true;
        
        let arrVd = discipline.getVideos().map((v)=> {return v.getId();} );
        this.getDisciplineVideosExercises(arrVd, discipline);
        this.getDisciplineVideosIframes(arrVd, discipline);
        this.getDisciplineVideosAnnex(arrVd, discipline);
        
        this.duplicateDisciplineService.emitDuplicateDiscipline.subscribe((data)=>{
            if(data.name === "duplicate" && this.duplicateState.request === 3){
                this.duplicateState.request= 0; 
                this.duplicateState.duplicate--; 
                let f = this.formatDiscipline( data.value.getDiscipline());
                this.createDiscipline(f); 
                this.duplicateDisciplineList = this.duplicateDisciplineList.filter((d)=> d.getId() !== data.value.getId() )
            }
            
        })
        
    } 
    public getSchools(){
        return this.schools;
    } 
    public getPlans(){
        return this.plans;
    }
    
    public getPlanDisciplines(){
        return this.disciplines;
    }
    public getSearchNameDiscipline(){
        return this.disciplines;
    }

    /* Duplciate methods requests */

    public getDiscipline(disciplineId:number){

        this.duplicateDisciplineService.getDiscipline(disciplineId).subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) {                 
                this.getDisciplineAddVideos(response.data, disciplineId);
                 
            }else{ 
                this.alertService.error(response.error); 
            }
          }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));

         
    } 
    public getDisciplineAddVideos(discilpline:any, disciplineId:number){

        this.duplicateDisciplineService.getDisciplineVideos(disciplineId).subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error &&  isArray(response.data)) { 
                this.duplicateDisciplineService.emitDuplicateDiscipline.emit({name:"disciplineLoaded",data:[] })
                this.add( discilpline, response.data); 
            }else{ 
                this.alertService.error(response.error); 
            }
          }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));

        
    }

    public getDisciplineVideosExercises(videos:any,  d:any){
        
  
        this.duplicateDisciplineService.getDisciplineVideoExercises(videos).subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) { 
                let i =   this.duplicateDisciplineList.indexOf(d);
                this.duplicateDisciplineList[i].setVideoExercises(response.data);
            }
            this.duplicateState.request ++;
            this.duplicateDisciplineService.emitDuplicateDiscipline.emit({name:"duplicate",value:d})
          }, err => this.alertService.error('Houve um erro ao carregar os exercícios dos vídeos. Verifique a conexão e tente novamente'));
    }
    public getDisciplineVideosIframes(videos:any,  d:any){
        this.duplicateDisciplineService.getDisciplineVideoIframes(videos).subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) { 
                let i =   this.duplicateDisciplineList.indexOf(d);
                this.duplicateDisciplineList[i].setVideoIframes(response.data);
            }

            this.duplicateState.request ++;
            this.duplicateDisciplineService.emitDuplicateDiscipline.emit({name:"duplicate",value:d})
        }, err => this.alertService.error('Houve um erro ao carregar os iframes dos vídeos. Verifique a conexão e tente novamente'));
    }
    public getDisciplineVideosAnnex(videos:any,  d:any){
        this.duplicateDisciplineService.getDisciplineVideoAnnex(videos).subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) { 
                let i =   this.duplicateDisciplineList.indexOf(d);
                this.duplicateDisciplineList[i].setVideoAnnex(response.data);
            }
            this.duplicateState.request ++;
            this.duplicateDisciplineService.emitDuplicateDiscipline.emit({name:"duplicate",value:d})
        }, err => this.alertService.error('Houve um erro ao carregar os anexo dos vídeos. Verifique a conexão e tente novamente'));
    }


    /* Standard Request Metods  */
    requestSchools() {
        this.schoolService.getSchools()
          .subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) { this.schools = response.data as SchoolSelect[];
            } else{ this.alertService.error(response.error); }
          }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
    } 
    requestPlans(schoolId?:number){
    this.schoolService.getPlansSelection(schoolId)
        .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) this.plans = response.data as PlanSelect[];
        else this.alertService.error(response.error);
        }, err => this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente!'));
    } 
    requestPlanDisciplines(planId?: number) {
    this.loading = true;
    this.studyPlanService.getPlanDisciplines(planId)
        .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
            let data = response.data;
            this.disciplines = data as Discipline[];
        } else {
            this.alertService.error(response.error);
            this.disciplines = [];
        }        
        this.loading = false;
        }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
        });
    }  
    requestNameDiscipline(name: string) {
        if (!this.userService.isAdmin()) {
            this.disciplineService.getDisciplinesSchoolByName(this.userService.getUserSchool(), name)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) { this.disciplines = response.data as Discipline[];
                } else { this.alertService.error(response.error); }
            }, err => this.alertService.error('Houve um erro ao buscar a(s) disciplina(s). Verifique a conexão e tente novamente'));
        }else {
            this.disciplineService.getNameDiscipline(name)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) { this.disciplines = response.data as Discipline[];
                } else { this.alertService.error(response.error); }
            }, err => this.alertService.error('Houve um erro ao buscar a disciplina. Verifique a conexão e tente novamente'));
        }
    }

    public createDiscipline(discipline:any){       
        this.duplicateDisciplineService.saveDiscilpline(this.userService.getUserSchool(), discipline).subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) { 
                this.alertService.success('Disciplina duplicada com sucesso!', 3);
            }
            this.loadingDuplicate = false;
        }, err => this.alertService.error('Houve um erro ao duplicar a disciplina. Verifique a conexão e tente novamente'));
    }
   
    public formatDiscipline( discipline:any){
      
        return   {
            id :    discipline.getObejct().id,
            name :  discipline.getObejct().name, 
            tag :   discipline.getObejct().tag || null, 
            justExercise :discipline.getObejct().justExercise || 0,
            active :    discipline.getObejct().active || 1,
            videos:     discipline.getObejct().videos.map((v)=>{
                return {
                    id:             v.getObejct().id,
                    disciplineId:   v.getObejct().disciplineId,
                    order:          v.getObejct().order,
                    tag:            v.getObejct().tag,
                    name:           v.getObejct().name,
                    desc:           v.getObejct().desc,
                    link:           v.getObejct().link,
                    type:           v.getObejct().type,
                    duration:       v.getObejct().duration,
                    embed_chat :    v.getObejct().embed_chat,
                    streaming :     v.getObejct().streaming,
                    isRecord:       v.getObejct().isRecord,
                    active:         v.getObejct().active,
                    youtubeMask :   v.getObejct().youtubeMask,
                    exercises :  v.getObejct().exercises.map((e)=>{
                        return {
                            id:         e.getObejct().id,
                            videoId:    e.getObejct().videoId,
                            name:       e.getObejct().name,
                            correct:    e.getObejct().correct,
                            comment:    e.getObejct().comment,
                            tag:        e.getObejct().tag,
                            text:       e.getObejct().text,
                            sum:        e.getObejct().sum,
                            active:     e.getObejct().active,
                            alternatives:  e.getObejct().alternatives.map((a)=>{
                                return {
                                    id:         a.id,
                                    exerciseId: a.exerciseId,
                                    option:     a.option,
                                    text:       a.text,
                                    value:      a.value
                                }
                            })
                            
                        }
                    }) || [],
                    iframes :    v.getObejct().iframes.map((i)=>i.getObejct() ) || [],
                    annexs :     v.getObejct().annexs.map((a)=>a.getObejct()) || []
                } 
            }),
            exercises:  discipline.getObejct().exercises.map((e)=>{
                return {
                    id:         e.getObejct().id,
                    videoId:    e.getObejct().videoId,
                    name:       e.getObejct().name,
                    correct:    e.getObejct().correct,
                    comment:    e.getObejct().comment,
                    tag:        e.getObejct().tag,
                    text:       e.getObejct().text,
                    sum:        e.getObejct().sum,
                    active:     e.getObejct().active,
                    alternatives:  e.getObejct().alternatives.map((a)=>{
                        return {
                            id:         a.id || null,
                            exerciseId: a.exerciseId,
                            option:     a.option,
                            text:       a.text,
                            value:      a.value
                        }
                    })
                    
                }
            }) || []
        }
    }

    
}