// tslint:disable: no-unused-expression
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MockService } from '../../mock.service';
import { Mock } from '../../mock';
import { MockQuestion } from '../../mock-question';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ExerciseService } from 'src/app/modules/admin/exercise/exercise.service';
import { Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import * as moment from 'moment';

@Component({
  selector: 'app-mock-contest-reply',
  templateUrl: 'mock-contest-reply.component.html',
  styleUrls: ['mock-contest-reply.component.scss']
})

export class MockContestReplyComponent implements OnInit, OnChanges {

  @Input() mock: Mock;
  @Input() finalize: boolean;
  @Input() timeLeft: number;
  @Output() clearMock = new EventEmitter();
  @Output() startTimer = new EventEmitter();

  public questions: MockQuestion[];
  public loading: boolean;
  public question: MockQuestion;
  public questionsResponse: string[];
  public questionsSaved: boolean[];
  public lastQuestion: boolean;
  public questionsNotReplied: number[];
  public i = 0;
  public alreadyStarted: boolean;
  public initDate: Date;
  public resultDate: Date;
  public finished: boolean;
  public numReplied = 0;


  constructor(
    private userService: UserService,
    private mockService: MockService,
    private alertService: AlertService,
    private exerciseService: ExerciseService,
    private router: Router) { }

  ngOnInit(): void {

    this.initDate = moment(this.mock.initDate).toDate();
    this.resultDate = moment(this.mock.resultDate, 'DD/MM/YYYY hh:mm:ss').toDate();


    this.verifyInitDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    !!changes.finalize && this.finalizeMock(changes.finalize.currentValue);
  }

  initializeQuestionsArrays(exercisesNumber: number) {
    this.questionsResponse = [];
    this.questionsSaved = [];
    this.questionsNotReplied = [];

    for (let i = 0; i < exercisesNumber; i++) {

      this.questionsResponse.push(null);
      this.questionsSaved.push(false);
    }
  }

  getMockQuestions() {
    this.loading = true;
    this.mockService.getMockQuestions(this.mock.id, true)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questions = response.data as MockQuestion[];
          this.initializeQuestionsArrays(this.questions.length);
          this.verifyResponseQuestions();
          this.question = this.questions[0];
        } else {
          this.alertService.error(response.error);
        }

      }, err => {
        this.alertService.error('Houve um erro ao carregar as questões. Verifique a conexão e tente novamente');
      });
  }

  sendQuestionResponse(questionId: number, alternative: string) {

    this.mockService.sendQuestionResponse(questionId, alternative, this.mock.id, this.userService.getUserId(), this.timeLeft)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questionsSaved[this.i] = true;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao salvar a resposta. Verifique a conexão e tente novamente'));
  }


  private verifyResponseQuestions() {

    const studentId = this.userService.getUserId();

    for (let i = 0; i < this.questions.length; i++) {
      this.verifyResponseQuestion(this.questions[i].id, studentId, i);
    }
  }

  verifyResponseQuestion(questionId: number, studentId: number, exerciseIndex: number) {

    this.mockService.verifyResponseQuestion(this.mock.id, questionId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

          const resp = response.data as string;

          if (resp) {
            this.alreadyStarted = true;
            this.questionsSaved[exerciseIndex] = true;
            this.questionsResponse[exerciseIndex] = resp;
            this.numReplied++;
          } else {
            this.questionsSaved[exerciseIndex] = false;
          }

        } else {
          this.alertService.error(response.error);
        }

        if (this.questions.length - 1 === exerciseIndex) {
          this.verifyIsValidToInit();
        }
      }, err => this.alertService.error('Houve um erro ao verificar o exercício. Verifique a conexão e tente novamente'));
  }


  next() {
    this.loading = true;
    this.question = this.questions[this.i + 1];
    this.i++;
    this.loading = false;

    if (this.i === this.questions.length - 1) {
      this.lastQuestion = true;
    }
  }

  arrayNotReplied() {
    const notReplied: number[] = [];
    this.questionsSaved.forEach((replied, i) => {
      if (!replied) {
        notReplied.push(this.questions[i].id);
      }
    });
    return notReplied;
  }

  finalizeEndTime(notReplied: number[]) {
    this.mockService.finalizeMockTimeEnd(this.userService.getUserId(), this.mock.id)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (response.error) {
          this.alertService.error(response.error);
        } else {
          if (this.verifyShowResult()) {
            this.router.navigate(['/mock/result/' + this.mock.id]);
          }
        }
      }, err => this.alertService.error('Houve um erro ao finalizar o simulado. Verifique a conexão e tente novamente'));
  }

  end(endTime: boolean = false) {
    const notReplied = this.arrayNotReplied();

    if (notReplied.length > 0) {
      this.finalizeEndTime(notReplied);
    } else
      if (this.verifyShowResult() && !endTime) {
        this.router.navigate(['/mock/result/' + this.mock.id]);
      }
    this.finished = true;
  }

  finalizeMock(finalize: boolean) {

    finalize && this.end(true);
  }

  verifyIsValidToInit() {
    const now = new Date();
    const toleranceDate = new Date(this.initDate.getTime());
    toleranceDate.setMinutes(toleranceDate.getMinutes() + Number(this.mock.tolerance));

    if (!this.alreadyStarted && now.getTime() > toleranceDate.getTime()) {
      this.alertService.error('O tempo para iniciar esse simulado se esgotou');
      this.clearMock.emit();
      return;
    }

    if (this.numReplied === this.questions.length) {
      if (this.verifyShowResult()) {
        this.router.navigate(['/mock/result/' + this.mock.id]);
      }
      this.finished = true;
      this.loading = false;
      return;
    }

    this.startTimer.emit();

    this.loading = false;
  }

  verifyShowResult() {
    return this.resultDate.getTime() < new Date().getTime();
  }

  verifyInitDate() {
    if (new Date().getTime() < this.initDate.getTime()) {
      this.alertService
        .warning('O simulado estará disponível apartir de '
          + this.initDate.toLocaleDateString() + ' às ' + this.initDate.getHours() + ':' + this.initDate.getMinutes());
      this.clearMock.emit();
    } else {
      this.mock && this.getMockQuestions();
    }
  }

}
