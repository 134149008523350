<div class="background-layout">
    <h5 class="mb-3 mt-2 text-center">Dê sua nota!</h5>
    <form [formGroup]="starForm">
        <div class="mb-3 text-center width-100">
            <a mat-icon-button color="accent" *ngFor="let r of rattingArr; index as i" (click)="clickRating(i)">
                <mat-icon class="star-icon-size">{{returnStar(i)}}</mat-icon>
            </a>
        </div>
        <div class="mb-3 width-100">
            <textarea formControlName="description" placeholder="Adicionar um comentário" class="textarea-star-rating" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="4" matInput></textarea>
        </div>
        <button class="button-comment small-btn width-100"  mat-raised-button color="accent" (click)="createRating()"
            [disabled]=" !starForm.valid || this.rating === 0 || loading" nbSpinnerStatus="warning">
            Salvar
        </button>
        <button class="button-comment small-btn width-100"  *ngIf="isEdit" (click)="updateRating()" nbButton size="large" status="primary" [ngClass]="{'salvar-mobile': layoutService.isMobile}" [nbSpinner]="loading" [disabled]=" !starForm.valid || this.rating === 0 || loading" nbSpinnerStatus="warning">
            Atualizar
        </button>
        <button mat-button class="mt-1 mb-1 width-100 text-center" color="accent" (click)="closeDialogRef()">
            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
        </button>
    </form>
</div>