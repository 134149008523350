<div class="container pt-5 pb-5">

    <mat-accordion>
        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Disciplina</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-discipline></app-create-discipline>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Duplicar Disciplinas</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-duplicate-discipline></app-duplicate-discipline>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Adicionar Vídeo</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-video></app-add-video>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Exercício</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <mat-form-field appearance="outline" class="width-100">
                    <mat-select placeholder="Tipo de avaliação *" [(ngModel)]="selectedExercicies">
                        <mat-option value="Exercicio">
                            Cadastrar Exercicio
                        </mat-option>
                        <mat-option value="Somatoria">
                            Cadastrar Exercicio(Somatoria)
                        </mat-option>
                        <mat-option value="lib">
                            Cadastrar Exercicio da biblioteca
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <app-create-exercise *ngIf="selectedExercicies == 'Exercicio'"></app-create-exercise>
                <app-summation-create *ngIf="selectedExercicies == 'Somatoria'"></app-summation-create>
                <app-create-question *ngIf="selectedExercicies == 'lib'"></app-create-question>
                <button (click)="openAllListExercises()" nbButton size="small" outline status="primary" *ngIf="!selectedExercicies" class="text-color-primary border-primary-thin">Ver exercícios cadastrados</button>
            </ng-template>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                Cadastrar Exercício (Somatória)
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>

            </ng-template>
        </mat-expansion-panel> -->

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Anexo</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-video-annex></app-add-video-annex>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Arquivo de Aula</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-slide-file-create></app-slide-file-create>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Iframe do Vídeo</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-video-iframe></app-video-iframe>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Tema da Disciplina</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-discipline-tag-create></app-discipline-tag-create>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>

</div>