<div>
    <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Responsável</h4>
    <form [formGroup]="createFatherForm" *ngIf="!showList">
        <div class="row">
            <mat-form-field class="col-md-4">
                <input matInput placeholder="Nome" formControlName="name">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <input matInput placeholder="E_mail" type="email" formControlName="email">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <input matInput placeholder="Senha" type="password" formControlName="password">
            </mat-form-field>
        </div>

    </form>

    <div class="row mt-4 width-100" *ngIf="showList">
        <mat-form-field class="col-md-4">
            <input matInput placeholder="Buscar Responsável" [(ngModel)]="fatherName">
        </mat-form-field>
        <button (click)="searchAllFatherByName(fatherName)" class="mt-3 buttonclass" mat-icon-button color="accent" matTooltip="Buscar">
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <div class="mt-4" *ngIf="showList && !editMode">
        <div *ngIf="fatherList">
            <ng-container class="width-80">
                <nb-list>
                    <nb-list-item class="list-header" style="color: white; background-color: #4eb397;
                    height: 10px; ">
                        <div class="row">
                            <div class="col-md-10">Nome</div>
                            <div class="col-md-2">Ações</div>
                        </div>
                    </nb-list-item>
                    <nb-list-item *ngFor="let father of fatherList">
                        <div class="row">
                            <div class="col-md-10">
                                {{father.name}}
                            </div>
                            <div class="col-md-1">
                                <button (click)="editFather(father)" nbButton size="tiny" outline status="primary">Editar</button>
                            </div>
                            <div class="col-md-1">
                                <button (click)="deleteFather(father.id)" size="tiny" status="danger" nbButton ghost>
                                    <nb-icon icon="trash"></nb-icon>
                                </button>
                            </div>
                        </div>
                    </nb-list-item>

                </nb-list>
            </ng-container>
        </div>
    </div>

    <div *ngIf="!editMode" class="row mt-5">
        <div *ngIf="!showList" class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary">Ver Responsáveis
                cadastrados</button>
        </div>
        <div *ngIf="showList" class=" col-6 width-100">
            <button (click)="backBtn()" nbButton size="small" outline status="primary">Voltar</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="createFather()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="updateFather()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>