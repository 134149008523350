<h4 *ngIf="editMode" class="mt-3 mb-3">Editar Estudante</h4>
<div *ngIf="!showStudentList">

    <form [formGroup]="createStudentForm">

        <!-- Name, Email, Password -->
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input matInput placeholder="E-mail" type="email" formControlName="email">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Senha" formControlName="password">
                </mat-form-field>
            </div>
        </div>

        <!-- School, Unit -->
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" (selectionChange)="getUnits($event.value)" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="unit" (selectionChange)="getClasses($event.value)" placeholder="Unidade">

                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="row">

            <!-- Class -->
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="class" placeholder="Turma">

                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- plans only in edit mode -->
            <div *ngIf="student" class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="plans" placeholder="Planos">
                        <mat-option (click)="addPlanChip(plan)" *ngFor="let plan of plans" [value]="plan.id">
                            <span [title]="plan.name">{{plan.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-chip-list *ngIf="selectedPlans && !loadingPlans">
                    <mat-chip *ngFor="let planChip of selectedPlans" [selected]="planChip.classPlan" [removable]="!planChip.classPlan" (removed)="removePlanChip(planChip)" color="warn" matTooltipClass="custom-tooltip" matTooltip="Planos da turma não podem ser removidos"
                        [matTooltipDisabled]="!planChip.classPlan" matTooltipClass="class-plan-tooltip">
                        {{planChip.name}}
                        <mat-icon *ngIf="!planChip.classPlan" matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <ng-container *ngIf="loadingPlans">
                    <mat-spinner [diameter]="30" color="accent" class="center"></mat-spinner>
                </ng-container>
            </div>

        </div>


        <div class="row">
            <div class="col-md-3">
                <mat-slide-toggle formControlName="expire" (change)="expireToggle($event)" color="primary" class="mt-3">
                    Usuário teste
                </mat-slide-toggle>
            </div>
            <div class="col-md-3">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Dias de teste" formControlName="expireDays" [textMask]="{mask: maskService.number}">
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="editMode">
            <div class="col-md-6" *ngIf="student.active == 1">
                <button mat-raised-button (click)="disableStudent(student.id)" color="warn">Desativar Estudante</button>
            </div>
            <div class="col-md-6" *ngIf="student.active == 0">
                <button mat-raised-button (click)="enableStudent(student.id)" color="accent">Ativar Estudante</button>
            </div>
        </div>

        <div class="row mt-4 width-100" *ngIf="editMode && isAdmOrCoord">
            <mat-form-field class="col-md-4">
                <input matInput placeholder="Buscar Responsável" formControlName="fatherName">
            </mat-form-field>
            <button (click)="searchFatherByName()" class="mt-3 buttonclass" mat-icon-button color="accent" matTooltip="Buscar">
                <mat-icon>search</mat-icon>
            </button>
        </div>

        <div class="mt-4" *ngIf="editMode && isAdmOrCoord">
            <div *ngIf="fatherList">
                <ng-container class="width-100">
                    <nb-list>
                        <nb-list-item class="list-header" style="color: white; background-color: #4eb397;
                        height: 10px; ">
                            <div class="row">
                                <div class="col-md-11">Nome</div>
                                <div class="col-md-1">Vincular</div>
                            </div>
                        </nb-list-item>
                        <nb-list-item *ngFor="let father of fatherList">
                            <div class="row">
                                <div class="col-md-11">
                                    {{father.email}}
                                </div>
                                <div *ngIf="father.isFatherAssociated == 0" class="col-md-1"> <button (click)="vinculateFather(father.id)" style="border: none; color: green" class="buttonclass" nbButton size="tiny" outline status="primary "><mat-icon size="small" class="coricon">done</mat-icon></button>
                                </div>
                                <div *ngIf="father.isFatherAssociated == 1" class="col-md-1"> <button (click)="desvinculateFather(father.id)" style="border: none; color:red" class="buttonclass" nbButton size="tiny" outline status="primary "><mat-icon size="small" class="coriconclose">close</mat-icon></button>
                                </div>
                            </div>
                        </nb-list-item>

                    </nb-list>
                </ng-container>
            </div>
        </div>

        <div *ngIf="!editMode" class="row mt-5">
            <div class=" col-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver estudantes
                    cadastrados</button>
            </div>
            <div class=" col-6 text-right width-100">
                <button (click)="createStudent()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning">Cadastrar</button>
            </div>
        </div>

        <div *ngIf="editMode" class="row mt-5 text-right">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </form>
</div>

<div *ngIf="showStudentList" class="">

    <div class="row">
        <div class="col-md-4" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-label>Escola</mat-label>
                <mat-select (selectionChange)="getUnits($event.value)" placeholder="Escola" [(ngModel)]="filterSchoolId">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="(userService.isAdmin() || userService.isGeneralCoordinator())">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getClasses($event.value)" placeholder="Unidade">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                        <div class="row">
                            <div class="col-10">
                                <span [title]="unit.name">{{unit.name}}</span>
                            </div>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getStudents($event.value)" placeholder="Turma">
                    <mat-option *ngFor="let class of classes" [value]="class.id">
                        <div class="row">
                            <div class="col-10">
                                <span [title]="class.name">{{class.name}}</span>
                            </div>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md">
            <div class="row">
                <div class="col-md-9 width-100">
                    <mat-form-field>
                        <input #filterNameRef matInput placeholder="Buscar por nome">
                        <mat-icon matSuffix (click)="getStudentsByNameOrEmail(filterNameRef.value)">search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-md">
            <div class="row">
                <div class="col-md-9 width-100">
                    <mat-form-field>
                        <input #filterEmailRef matInput placeholder="Buscar por e-mail">
                        <mat-icon matSuffix (click)="getStudentsByNameOrEmail(null, filterEmailRef.value)">search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!--     <div class="col-md">
            <div class="row">
                <div class="col-md-9 width-100">
                    <mat-form-field>
                        <input #lotStudent matInput placeholder="Buscar usuário">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <button (click)="getLostStudent(null, lotStudent.value)" class="right" mat-icon-button color="accent" matTooltip="Buscar">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
        </div> -->
    </div>

    <mat-spinner class="center mt-5" *ngIf="loading">
    </mat-spinner>

    <nb-card size="medium" *ngIf="!loading && filteredStudents">
        <nb-card-header>
            <div class="row">
                <div class="col-md">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Filtrar por nome" [(ngModel)]="filterName" (keyup)="filterByName()">
                    </mat-form-field>
                </div>
                <div class="col-md">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="filtrar por email" [(ngModel)]="filterEmail" (keyup)="filterByEmail()">
                    </mat-form-field>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-list class="width-90 mt-3 center">
                <nb-list-item *ngFor="let student of filteredStudents">
                    <div class="row">
                        <div class="col-8">
                            {{student.name}}
                        </div>
                        <div class="col-2 text-right">
                            <button (click)="editStudent(student)" nbButton size="tiny" outline status="primary">Editar</button>
                        </div>
                        <div class="col-2 text-right">
                            <button size="tiny" (click)="removeStudent(student.id)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>
    <div class="text-right width-100">
        <button (click)="showStudentList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>