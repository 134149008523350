<div *ngIf="!showCouponList">

    <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Cupom</h4>
    <form [formGroup]="couponForm">

        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Cupom" formControlName="cupom">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input [textMask]="{mask: maskService.number}" matInput placeholder="Percentual de desconto"
                        formControlName="valor">
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!editMode" class="row mt-5">
            <div class=" col-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver cupons
                    cadastrados</button>
            </div>
            <div class=" col-6 text-right width-100">
                <button (click)="createCoupon()" class="right bg-color-primary border-primary" nbButton size="small" status="primary"
                    [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
            </div>
        </div>

        <!-- <div *ngIf="editMode" class="row mt-5 text-right">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary">Salvar</button>
        </div> -->
    </form>
</div>

<div *ngIf="showCouponList " class="">

    <mat-spinner class="center mt-5" *ngIf="loading">
    </mat-spinner>

    <nb-card size="medium" *ngIf="!loading">
        <nb-card-header>
            <mat-form-field class="ml-3" appearance="outline">
                <mat-label>Filtrar por nome</mat-label>
                <input matInput placeholder="Nome" [(ngModel)]="filterName" (keyup)="applyFilter()">
            </mat-form-field>
        </nb-card-header>
        <nb-card-body>
            <nb-list class="width-90 mt-1 center">
                <nb-list-item *ngFor="let coupon of filteredCoupons">
                    <div class="row">
                        <div class="col-6">
                            {{coupon.cupom}}
                        </div>
                        <div class="col-2">
                            {{coupon.valor}}
                        </div>
                        <div class="col-2 text-right">
                            <!-- <button (click)="editPlan(coupon)" nbButton size="tiny" outline status="primary">Editar</button> -->
                        </div>
                        <div class="col-2 text-right">
                            <button size="tiny" (click)="removeCoupon(coupon.id)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>
    <div class="text-right width-100">
        <button (click)="showCouponList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>