<div class="container pt-5 mb-5 text-center">

    <div>
        <mat-icon color="accent" style="width: 150px; height: 150px; transform: rotate(-90deg);" svgIcon="clock">
        </mat-icon>
        <h2 class="mb-4 mt-3 text-color-primary">SEU TESTE CHEGOU AO FIM</h2>
        <h4>Gostou do plano?</h4>
        <h5>Renove agora mesmo e mantenha o seu progresso!</h5>

        <p class="mt-5">Clique no botão abaixo e você será redirecionado para a página de compra do plano.</p>

        <button mat-raised-button color="accent" class="mt-4" (click)="openPlanBuy()">Renovar Agora!</button>
    </div>



</div>