<div class="page-container p-pg page-container-mobile discipline font-poppins">
    <div class="row">
        <div class="col-md-5 mt-2">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'left dash-container':!layoutService.isMobile, 'text-center': layoutService.isMobile, 'text-center width-80': layoutService.isMobile}">
                    <span class="page-title">AULAS</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile" class="text-color-accent">Selecione um plano</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile" class="text-color-accent">Selecione o plano</h6>
                        <p class="fs-13 pb-0">Escolha o curso que você deseja estudar</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 pt-3"  *ngFor="let plan of plans" >
            <button (click)="selectPlan(plan.id, plan.name)" class="decoration-none p-0 w-100 text-left">
                <mat-card class="no-padding">
                    <mat-card-header class="pt-4 pr-4 pl-4 text-left topic">
                        <mat-card-title class="d-flex">
                            <mat-icon class="f-icon" color="accent">library_books</mat-icon>
                            <span class="pl-4 pt-2 f-title"><b>{{plan.name}}</b></span>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="pt-3 pb-4 pr-4 pl-4 text-left">
                        <div class="pb-1">{{plan.totalDisciplinas}} disciplina(s)</div>
                        <div class="pt-1 text-right"><small>Válido até {{plan.datafinal  | date:'dd/MM/yyyy'}}</small></div>
                        <!-- <div class="d-flex">
                            <mat-progress-bar mode="determinate" value="plan.porcentagem"></mat-progress-bar>
                            <span class="pl-1">{{plan.porcentagem}}%</span>
                        </div> -->
                    </mat-card-content>
                </mat-card>
            </button>
        </div>
    </div>    
</div>