import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Monitory } from './monitory';


@Injectable({
  providedIn: 'root'
})
export class MonitoryService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  getTeacherMonitoring(teacherId: number) {
    return this.http.post(this.API + '/monitory/getTeacherMonitoring', { teacherId }, { observe: 'response' });
  }

  getStudentMonitoring(studentId: number) {
    return this.http.post(this.API + '/monitory/getStudentMonitoring', { studentId }, { observe: 'response' });
  }

  createMonitory(monitory: Monitory) {
    return this.http.post(this.API + '/monitory/create', monitory, { observe: 'response' });
  }

  saveMonitoryChanges(monitory: Monitory) {
    return this.http.post(this.API + '/monitory/update', monitory, { observe: 'response' });
  }

  removeMonitory(monitoryId: number) {
    return this.http.post(this.API + '/monitory/remove', { monitoryId }, { observe: 'response' });
  }


}
