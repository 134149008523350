import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortName' })
export class ShortNamePipe implements PipeTransform {

    transform(name: string, showTwoNames?: boolean) {

        const conn = ['de', 'da', 'do', 'das', 'dos', 'e'];
        const arr = name.split(' ');
        let ret = arr[0];

        if (arr.length >= 2) {
            if (conn.indexOf(arr[1].toLowerCase()) > -1 && arr.length >= 2) {
                if (showTwoNames) {
                    ret += ' ' + arr[1] + ' ' + arr[2];
                    console.warn('two');
                } else {
                    ret += ' ' + arr[0];
                    console.warn('zero');
                }
            } 
        }
        return ret;
    }
}
