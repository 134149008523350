import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

import { Schedule } from '../admin/study-plan/schedule';
import { ScheduleDay } from '../admin/study-plan/schedule-day';
import { Video } from '../video/video';
import { ScheduleMonth } from './schedule-month';
import { Calendar, CalendarDay, CalendarFixedEdit, CalendarMonth } from '../admin/calendar/calendar';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { SlideFile } from '../slide-file/slide-file';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private API = environment.ApiUrl;
  private startDate: Date;
  private endDate: Date;
  private selectedCalendar = new BehaviorSubject<Calendar>(null);
  private dayVideosSubject = new BehaviorSubject<(Video | SlideFile)[]>(null);
  private selectedCalendarDayVideos = new BehaviorSubject<any>(null);
 

  constructor(
    private http: HttpClient,
    private alertService: AlertService) { }

  /*
  * Split in group of 7, (row of calendar)
  */
  groupColumns(scheduleDays: CalendarDay[]) {
    const newRows: CalendarDay[][] = [];

    for (let i = 0; i < scheduleDays.length; i += 7) {
      newRows.push(scheduleDays.slice(i, i + 7));
    }

    return newRows;
  }

  formatScheduleMonthsGroup(months: CalendarMonth[]) {
    const scheduleMonthsGroup = [];
    months.map(m => {
      scheduleMonthsGroup.push(this.groupColumns(m.days));
    });
    return scheduleMonthsGroup;
  }

  calcCurrentTabIndex() {
    const currentMonth = new Date().getMonth();
    return currentMonth - this.startDate.getMonth();
  }

  updatePlanDayVideos(planId: number, videos: Video[]) {
    return this.http.post(this.API + '/schedule/day/update', { planId, videos }, { observe: 'response' });
  }

  getExpireMonthPlanCalendar(planId: number) {
    return this.http.post(this.API + '/calendar/getCalendarPlan', { planId }, { observe: 'response' });
  }

  updateExpireMonthCalendarDays(planId: number, days: CalendarDay[]) {
    return this.http.post(this.API + '/calendar/setDayCalendarPlan', { planId, days }, { observe: 'response' });
  }

  getPlanSchedule(planId: number, studentId?: number) {
    return this.http.post(this.API + '/calendar/getSchedule', { planId, studentId }, { observe: 'response' })
      .pipe(tap(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          let data = response.data;
         
          this.setSelectedCalendar(data as Calendar);
          
        }
      }));
  }
   
  setSelectedCalendar(calendar: Calendar) {
    this.selectedCalendar.next(calendar);
  }

  getSelectedCalendarSubject() {
    return this.selectedCalendar.asObservable();
  }
  setCalendarDayVideos(days: any) {
    this.selectedCalendarDayVideos.next(days);
  }

  getCalendarDayVideos() {
    return this.selectedCalendarDayVideos.asObservable();
  }

  getExpireFixedPlanScheduleEdit(planId: number) {
    return this.http.post(this.API + '/calendar/getScheduleEdit', { planId }, { observe: 'response' })
      .pipe(tap(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.setSelectedCalendar(response.data as Calendar);
        }
      }));
  }

  syncCalendar(planId: number) {
    return this.http.post(this.API + '/calendar/getScheduleSync', {planId}, { observe: 'response' });
  }

  updateExpireFixedCalendarDays(calendarFixedEdit: CalendarFixedEdit) {
    return this.http.post(this.API + '/calendar/setScheduleEdited', calendarFixedEdit, { observe: 'response' });
  }

  setCurrentDayVideos(videos: (Video | SlideFile)[]) {
    this.dayVideosSubject.next(videos);
  }

  getCurrentDayVideosSubject() {
    return this.dayVideosSubject.asObservable();
  }
}
