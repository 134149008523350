import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { ModalBellComponent } from './modal-bell/modal-bell.component';
import { ModalUpdateComponent } from './modal-update/modal-update.component';
import { ModalLiveComponent } from './modal-live/modal-live.component';
import { MatIconModule } from '@angular/material/icon';
import { NbIconModule } from '@nebular/theme';
import { LoadingModule } from '../loading/loading.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { ModalCentralComponent } from './modal-central/modal-central.component';
import { NoticationFactory } from './notification.factory';
import { NotificationStore } from './notifications.store';


@NgModule({
  declarations: [NotificationsComponent, ModalBellComponent, ModalUpdateComponent, ModalLiveComponent, ModalCentralComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatTooltipModule,
    NbIconModule,
    LoadingModule
  ],
  exports: [NotificationsComponent, ModalBellComponent, ModalUpdateComponent, ModalLiveComponent],
  providers: [
    NotificationStore
  ]
})
export class NotificationsModule {

 }
