<div class="container pt-5 pb-5">
    <mat-accordion>
        <mat-expansion-panel  class="width-70 center mt-4">
            <mat-expansion-panel-header>
                Enviar Tarefa
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
            <app-submit-task></app-submit-task>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel  class="width-70 center mt-4">
            <mat-expansion-panel-header>
                Tarefas Enviadas pelo Professor
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-task-sent></app-task-sent>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel class="width-70 center mt-4">
            <mat-expansion-panel-header>
                Tarefas Recebidas dos Alunos
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-task-received></app-task-received>
            </ng-template>
        </mat-expansion-panel> 
    </mat-accordion>
</div>
