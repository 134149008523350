<div class="page-container p-pg page-container-mobile discipline font-poppins">
    <div class="row m-0">
        <div class="col-md-5 mt-2">
            <ng-container *ngIf="!layoutService.isMobile || firstPlanId">
                <div class="page-title-container" [ngClass]="{'left dash-container':!layoutService.isMobile, 'text-center': layoutService.isMobile, 'text-center width-80': layoutService.isMobile, 'hide-mobile' : disciplineSelected && layoutService.isMobile}">
                    <span class="page-title">DISCIPLINAS</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Selecione o plano</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Selecione o plano</h6>
                        <p class="fs-13 pb-0">Escolha o curso que você deseja estudar</p>
                    </div>
                </div>
                <!-- SELECT PLANO LISTA DESKTOP -->
                <div class="plan-list" *ngIf="!layoutService.isMobile">
                    <mat-list *ngIf="!loadingPlans">
                        <mat-list-item *ngFor="let plan of studyPlans" (click)="selectPlan(plan)">
                            <div class="plan-item mb-3" matRipple [ngClass]="{'bg-color-accent': planSelectedName == plan?.name}">
                                <div class="plan-icon">
                                    <mat-icon [color]="(planSelectedName == plan?.name) ? null : 'accent'">class</mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ plan?.name }}</strong>
                                    <span class="plan-expire"> Válido até {{ plan?.expireDate | date:'dd/MM/y'}}</span>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                    </mat-list>
                </div>
                <!-- SELECT PLANO LISTA MOBILE -->
                <div *ngIf="layoutService.isMobile" id="filter">
                    <mat-form-field class="width-100 m-0 p-0 form-field" *ngIf="!loadingPlans && !disciplineSelected">
                        <mat-select class="p-0 m-0 form-select" [(ngModel)]="firstPlanId" (selectionChange)="selectPlan($event.value)">
                            <mat-option value="0">Nenhum selecionado</mat-option>
                            <mat-option [value]="plan" *ngFor="let plan of studyPlans">
                                {{plan.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                </div>
            </ng-container>
            <!-- Mobile only unselect plan -->
            <ng-container *ngIf="layoutService.isMobile && planSelected && !firstPlanId && !disciplineSelected">
                <div class="selected-plan text-center mt-2">
                    <div class="lesson-title">
                        <div class="page-title">Plano selecionado</div>
                        <h6 class="mb-2 mt-2">{{planSelected.name}}</h6>
                        <p class="fs-13">Escolha a disciplina que você deseja estudar</p>
                    </div>
                    <div class="lesson-back" *ngIf="!firstPlanId">
                        <button (click)="reloadPlan(planSelected)" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-7 mt-2 height-100">
            <!-- Discipline list -->
            <ng-container *ngIf="!disciplineSelected">
                <div *ngIf="disciplineDataSource?.data?.length && !loadingDisciplines && planSelected || layoutService.isMobile && disciplineDataSource?.data?.length" class="table-container table-container-discipline height-100" [ngClass]="{' position-r top-136': !layoutService.isMobile}">
                    <table mat-table [dataSource]="disciplineDataSource" class="table-discipline mb-3">
                        <!-- Position Column -->
                        <ng-container matColumnDef="discipline">
                            <th class="border-bottom-none disciplina brl-5" [ngClass]="{'brr-5': layoutService.isMobile}" mat-header-cell *matHeaderCellDef>DISCIPLINA</th>
                            <td mat-cell *matCellDef="let discipline; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                <span class="mobileDiscipline">{{discipline.name}}</span>
                                <button class="mobileDisciplineLink table-mobile-link mr-10px" mat-button (click)="disciplineSelected = discipline; sendDisciplinePlan(planSelected)">
                                    <span class="">{{discipline.name}} </span>
                                    <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent" >Acessar</span>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="total">
                            <th class="border-bottom-none disciplina" mat-header-cell *matHeaderCellDef>ASSISTIDO</th>
                            <td mat-cell *matCellDef="let discipline; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                <span class="mobileDiscipline">{{discipline.videoWatched || 0}} / {{discipline.totalVideos}}</span>
                                <button class="mobileDisciplineLink table-mobile-link mr-10px" mat-button (click)="disciplineSelected = discipline">
                                    <span class="">{{discipline.videoWatched || 0}} / {{discipline.totalVideos}}</span>
                                    <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent">Acessar</span>
                                </button>
                            </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="link" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef [ngClass]="{'brr-5 text-center': !layoutService.isMobile}"> LINK </th>
                            <td mat-cell *matCellDef="let discipline; let i = index" [ngClass]="{'table-line-contrast': i % 2,'text-center': !layoutService.isMobile}">
                                <button mat-button color="accent" (click)="disciplineSelected = discipline">
                                    <strong>Acessar</strong>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns | paginate: {itemsPerPage: 10, currentPage: p, totalItems: totalRows}"></tr>
                    </table>
                    <pagination-controls class="text-center" previousLabel="" nextLabel='' (pageChange)="paginate($event)"></pagination-controls>
                </div>
                <!-- select a plan -->
                <div *ngIf="!planSelected && !loadingDisciplines && !layoutService.isMobile" class="plan-not-selected">
                    <h4>Selecione um plano</h4>
                    <br />
                    <p>Escolha um plano para carregar as disciplinas</p>
                </div>
                <!-- empty plan -->
                <div *ngIf="disciplineDataSource && !disciplineDataSource?.data?.length && !loadingDisciplines" class="plan-not-selected">
                    <h4>Nenhuma disciplina</h4>
                    <br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
                <mat-spinner *ngIf="loadingDisciplines" class="center table-spinner" color="accent"></mat-spinner>
            </ng-container>
            <!-- Vídeo List -->
            <ng-container *ngIf="disciplineSelected">
                <div class="page-title-container mt-1 pt-0 text-center" *ngIf="layoutService.isMobile">
                    <span class="page-title">DISCIPLINAS</span>
                    <div class="mt-3 description-title">
                        <h6 class="mb-0">Selecione a aula</h6>
                        <p class="fs-13 pb-0">Escolha a aula que você deseja estudar</p>
                        <h5 class="mb-3 text-center">{{disciplineSelected.name}}</h5>
                    </div>
                    <div class="lesson-back">
                        <button (click)="checkDiscipline()" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div>
                </div>
                <div class="lesson-back mt-92px" *ngIf="!layoutService.isMobile">
                    <button (click)="checkDiscipline()" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                        <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                    </button>
                </div>
                <app-discipline-videos [discipline]="disciplineSelected" [plan]="planSelected"></app-discipline-videos>
            </ng-container>
        </div>
    </div>
</div>



<div class="mt-3 mr-2 ml-2" *ngIf="false">
    <nb-accordion class="mt-3 mb-5 center">
        <nb-accordion-item expanded="true">
            <nb-accordion-item-header>
                <!-- Exibir módulos para escola EX FITNESS -->
                <h4 *ngIf="userService.getUserSchool() == 88; else disciplinesTitle">Módulos</h4>
                <ng-template #disciplinesTitle>
                    <h4>Disciplinas</h4>
                </ng-template>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <!-- live classes -->
                <div class="row mb-3" [hidden]="!(userService.isStudent() && showClassrooms)">
                    <div class="col-md-12">
                        <nb-accordion>
                            <nb-accordion-item expanded="true">
                                <nb-accordion-item-header>
                                    Aulas ao vivo
                                </nb-accordion-item-header>
                                <nb-accordion-item-body class="m-3">
                                    <app-classroom-list *ngIf="planSelected?.id" (haveClassrooms)="setShowClassrooms($event)" [planId]="planSelected?.id">
                                    </app-classroom-list>
                                </nb-accordion-item-body>
                            </nb-accordion-item>
                        </nb-accordion>
                    </div>
                </div>

                <!-- Chose plan -->
                <div *ngIf="!planSelected && studyPlans && !loading">
                    <h5 class="ml-4">Selecione o plano</h5>
                    <nb-list class="width-80 center">
                        <nb-list-item href="#" *ngFor="let studyPlan of studyPlans">
                            <div class="row">
                                <div class="col plan-name">
                                    <mat-icon class="mt-2 mr-3" color="accent">event</mat-icon>
                                    <span class="text-color-primary">{{studyPlan.name}}</span>
                                </div>
                                <div class="col text-right">
                                    <button *ngIf="!studyPlan.expired" color="accent" mat-mini-fab class="hvr-grow" [matTooltip]="studyPlan.name" (click)="selectPlan(studyPlan)">
                                        <mat-icon>arrow_right_alt</mat-icon>
                                    </button>
                                    <!-- expired -->
                                    <span *ngIf="studyPlan.expired" matTooltipClass="custom-tooltip" matTooltip="Plano expirado">
                                        <button mat-mini-fab disabled>
                                            <mat-icon>hourglass_disabled</mat-icon>
                                        </button>
                                    </span>

                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>
                <!-- END Chose plan -->
                <!-- Chose plan -->
                <div *ngIf="planSelected && disciplines && !loading">
                    <h5 class="ml-4">Selecione a disciplina</h5>
                    <nb-list *ngIf="planSelected && disciplines && !loading" class=" width-80 center">
                        <nb-list-item href="#" *ngFor="let discipline of disciplines">
                            <div class="row">
                                <div class="col plan-name">
                                    <mat-icon class="mt-2 mr-3" color="accent">school</mat-icon>
                                    <span class="text-color-primary">{{discipline.name}}</span>
                                </div>
                                <div class="col text-right">
                                    <button color="accent" mat-mini-fab class="hvr-grow" [matTooltip]="discipline.name" (click)="openDisciplineVideos(discipline)">
                                        <mat-icon>arrow_right_alt</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>
                <!-- END Chose plan -->

                <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>
