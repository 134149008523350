import { Component, OnInit , Inject } from '@angular/core';
import { User } from '@sentry/angular';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { OctadeskService } from 'src/app/core/services/octadesk.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../user/user.service';
import { FatherService } from './fatherservice.service';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { Integrations } from 'src/app/core/components/integration-portal/integrations';
import { Theme } from 'src/app/core/themes/theme';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from 'src/app/core/themes/theme.service';
import { Title } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-father-screen',
  templateUrl: './father-screen.component.html',
  styleUrls: ['./father-screen.component.scss']
})
export class FatherScreenComponent implements OnInit {

  public students : User[];
  public haveStudents: boolean;
  public rdoLink: string;
  public uniLink: string;
  public convergindoLink: string;
  public serenLink: string;
  public integrations: Integrations[] = [];
  public logo: string;
  public nameSchool: string;
  public selectedStudent: number;
  public theme: Theme;
  public activeThemeCssClass: string;
  public activeTheme: string;
  public targetContoller: string;

  constructor(
    public userService: UserService,
    private alertService: AlertService,
    private fatherService: FatherService,
    private authService: AuthService,
    private octadeskService: OctadeskService,
    private headerService: HeaderService,
    private overlayContainer: OverlayContainer,
    private router: Router,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private themeService: ThemeService,
    private titleService: Title
    ) {
      this.themeService.getThemeObservable()
      .subscribe(theme => {
        this.theme = theme;
        if (this.theme && this.theme.theme) {
          this.setTheme(this.theme.theme);
          this.titleService.setTitle(this.theme.name);
          if (this.theme.favicon) {
            this.document.getElementById('appFavicon').setAttribute('href', this.theme.favicon);
          }
          this.themeService.emitThemeIsLoaded.emit('done');
        }
      });
    }

  ngOnInit() {
    this.getStudentsFather(this.userService.getUserId());
    this.haveStudents = false;
  }

  setTheme(theme: string) {

    this.activeTheme = theme;

    const cssClass = theme;

    const classList = this.overlayContainer.getContainerElement().classList;
    if (classList.contains(this.activeThemeCssClass)) {
      classList.replace(this.activeThemeCssClass, cssClass);
    } else {
      classList.add(cssClass);
    }

    this.activeThemeCssClass = cssClass;
  }

  getStudentsFather(fatherId: number){
    this.fatherService.getStudentsFather(fatherId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.students = response.data as User[];
        this.haveStudents = true;
      }
      else{
        console.log(response.error);
        this.haveStudents = false;
      }
    }, err => {
      this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
    })
  }

  fatherLogin(userId : number , userEmail: string , userClass: number , userDomain: string , userSchoolName: string) {
    this.selectedStudent = 0;

    if(userId){
      this.selectedStudent = userId;
    }
    this.integrations = [];
    this.getRdoLink(userId);
    this.getUniLink(userId);
    this.getConvergindoLink(userId);
    this.getSerenLink(userId , userEmail , userClass);
    this.getInfUser(userDomain , userSchoolName);

}

verifyLogin(intLink: string){
  if(intLink == this.integrations[0].link){
    if(this.selectedStudent){
      this.login(this.selectedStudent);
    }
  }
  else{
    this.targetContoller = '_blank'
  }
}

ngAfterViewInit(): void {
  this.headerService.emitCheckIntegrations.subscribe((data)=>{
    if(data != undefined){
      data.forEach(element => {
        this.integrations.push(element);
      });
      if(this.integrations[0]){
        this.logo = this.integrations[0].image;
        this.nameSchool = this.integrations[0].name;
      }
    }
  });
}

getInfUser(userDomain: string , schoolName: string){
  this.headerService.emitCheckIntegrations.emit([{
    link: userDomain, name: schoolName, image: this.theme.logo , title:''
  }]);
}

getRdoLink(userId: number) {

  this.headerService.getRdoLink(userId, 1)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.rdoLink = response.data as string;
        if(this.rdoLink != '') {
          this.headerService.emitCheckIntegrations.emit([{
            link: this.rdoLink, name: "Redação Online", image: "/assets/images/redacao-online.png",title:''
          }]);
        }
      }
    }, err => {
      this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');

    });
}

getUniLink(userId: number) {

  this.headerService.getUniLink(userId , 1)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.uniLink = response.data as string;
        if(this.uniLink != '') {
          this.headerService.emitCheckIntegrations.emit([{
            link: this.uniLink, name: "Unimestre", image: "/assets/images/unimestre.png",title:''
          }]);
        }
      } else {
        //this.alertService.error(response.error);
      }
    }, err => {
      //console.log('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');

    });
}

getConvergindoLink(userId: number) {
  this.headerService.getConvergindoLink(userId , 1)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.convergindoLink = response.data as string;
        if(this.convergindoLink != ''){
          this.headerService.emitCheckIntegrations.emit([{
            link: this.convergindoLink, name: "Convergindo", image: "/assets/images/convergindo.svg",title:''
          }]);
        }
      } else {
        //this.alertService.error(response.error);
      }
    }, err => {
      console.log('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');

    });
}

getSerenLink(userId: number , userEmail:string , userClass: number) {
  const seren: string = 'seren';
  this.headerService.getSerenLink(userId, userClass, seren, userEmail)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.serenLink = response.data as string;
        if(this.serenLink != '') {
          this.headerService.emitCheckIntegrations.emit([{
            link: this.serenLink, name: "Seren", image: "/assets/images/seren.png" , title:'aa'
          }]);
        }
      } else {
        //this.alertService.error(response.error);
      }
    }, err => {
      //console.log('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');

    });
}

login(userId:number) {

  this.authService.authenticateFather(userId).subscribe(
      res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
              this.octadeskService.startOctadesk();

              const route = response.data as any;

              if(route.defaultRoute && route.defaultRoute !== ""){

                  this.targetContoller = '_self';
                  this.router.navigate([route.defaultRoute]);
              }else{

                this.targetContoller = '_self';
                  this.router.navigate(['dashboard']);
              }

          } else {
              this.alertService.error(response.error);
              // nono animation
          }
      },
      err => {
          this.alertService.error('Os dados não puderam ser verificados, tente novamente ou contate o suporte!');
      }
  );
}

}
