// tslint:disable: no-unused-expression
// tslint:disable: triple-equals
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';

import { AdminService } from '../../admin.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Folder } from '../folder/folder';
import { Material } from './material';
import { Unit } from '../../school/unit';
import { Class } from '../../school/class';

@Component({
    selector: 'app-material-add',
    templateUrl: './material-add.component.html',
    styleUrls: ['./material-add.component.scss']
})
export class MaterialAddComponent implements OnInit, OnDestroy {

    private admUpdate$: Subscription;

    public materialForm: FormGroup;
    public schools: School[];
    public folders: Folder[];
    public editMode: boolean;
    public saving: boolean;
    public showList: boolean;
    public materials: Material[];
    public typesGroup = [
        {
            name: 'Escola',
            types: [
                { id: 1, type: 'Prova' },
                { id: 2, type: 'Trabalho' },
                { id: 3, type: 'Material Extra' }
            ]
        },
        {
            name: 'Aluno',
            types: [{ id: 4, type: 'Material de Apoio' }]
        }
    ];

    public materialName: string;
    public applyDate: Date | string;
    public expireDate: Date | string;
    public materialType: number;
    public file: any;
    public extension: string;
    public folder: number;
    public materialObservation: string;
    public school: number;
    public units: Unit[];
    public classes: Class[];
    public loading: boolean;
    public classId: number;


    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public material: Material,
        @Optional() public dialogRef: MatDialogRef<MaterialAddComponent>,
        private dialog: MatDialog,
        private repositoryService: RepositoryService,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private adminService: AdminService,
        public userService: UserService,
        public maskService: MaskService) { }

    ngOnInit(): void {
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        this.materialName = null;
        this.applyDate = null;
        this.expireDate = null;
        this.materialType = null;
        this.file = null;
        this.extension = null;
        this.folder = null;
        this.materialObservation = null;
        this.school = null;
        this.getSchools();
        this.coordinatorInit();
        if (this.material) { this.initializeEditMode(); }
    }

    initializeEditMode() {
        this.material.id = Number(this.material.id);
        this.material.class = Number(this.material.id);
        this.setMaterial(this.material);
        this.userService.isAdmin() && this.getFolders(this.material.schoolId);
        this.editMode = true;
    }

    private setMaterial(material: Material) {
        this.materialName = material.name;
        this.applyDate = material.applyDate;
        this.expireDate = material.expireDate;
        this.materialType = Number(material.type);
        this.file = material.file;
        this.folder = material.folderId;
        this.materialObservation = material.observation;
        this.school = material.schoolId;
    }

    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            !this.editMode && (this.school = this.userService.getUserSchool());
            this.getFolders(this.school);
        }

        if (this.userService.isUnitCoordinator()) {
            !this.editMode && (this.school = this.userService.getUserSchool());
            this.getFolders(this.school, this.userService.getUserUnit());
        }

        if (this.userService.isTeacher()) {
            !this.editMode && (this.school = this.userService.getUserSchool());
            this.getTeacherFolders(this.userService.getUserId(), 1);
        }
    }

    edit(material: Material) {
        const dialogRef = this.dialog.open(MaterialAddComponent, {
            minWidth: '60vw',
            data: material
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.getClassAllMaterials(this.classId);
            }
        });
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
    }

    getFolders(schoolId: number, unit?: number) {

        this.repositoryService.getFolders(schoolId, unit)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.folders = response.data as Folder[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
    }

    getTeacherFolders(teacherId: number, materialFolder?: number) {

        this.repositoryService.getTeacherFolders(teacherId, materialFolder)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.folders = response.data as Folder[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
    }

    handleFileInput(files: FileList): void {
        if (files && files[0]) {

            const file = files[0];
            this.extension = file.name.split('.').pop();
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.file = reader.result;
            };
        }
    }

    create() {

        this.saving = true;
        const material: Material = {
            id: null,
            name: this.materialName,
            applyDate: this.applyDate,
            expireDate: this.expireDate,
            extension: this.extension,
            file: this.file,
            folderId: this.folder,
            observation: this.materialObservation,
            type: this.materialType,
            schoolId: this.school,
            class: [],
            classId: null,
            userId: this.userService.getUserId()
        };

        if (!material.name || !material.file || !material.type || !material.folderId) {
            this.alertService.warning('Preencha os campos corretamente!');
            this.saving = false;
            return;
        }

        this.repositoryService.addMaterial(material)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Arquivo adicionado', 3);
                    this.adminService.updateChields();
                } else {
                    this.alertService.error(response.error);
                }

                this.saving = false;
            }, err => {
                this.alertService.error('Houve um erro ao cadastraro material. Verifique a conexão e tente novamente');
                this.saving = false;
            });
    }

    openList() {

        this.units = [];
        this.classes = [];
        this.materials = [];
        this.classId = null;
        this.showList = true;

        if (this.userService.isGeneralCoordinator()) {
            this.getUnits(this.userService.getUserSchool());
        } else
            if (this.userService.isUnitCoordinator()) {
                this.getClasses(this.userService.getUserUnit());
            } else
                if (this.userService.isTeacher()) {
                    this.getTeacherClasses(this.userService.getUserId());
                }
    }

    remove(materialId: number) {
        if (confirm('Você deseja realmente excluir o material?')) {
            return this.repositoryService.removeMaterial(materialId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.getClassAllMaterials(this.classId);
                        this.alertService.success('Material removido!');
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover o material. Verifique a conexão e tente novamente'));
        }
    }

    saveChanges() {

        this.saving = true;

        const material: Material = {
            id: this.material.id,
            name: this.materialName,
            applyDate: this.applyDate,
            expireDate: this.expireDate,
            extension: this.extension,
            file: this.file,
            folderId: this.folder,
            observation: this.materialObservation,
            type: this.materialType,
            schoolId: this.school,
            class: [],
            classId: null,
            userId: this.userService.getUserId()
        };

        if (!material.name || !material.file || !material.type || !material.folderId || !material.id) {
            this.alertService.warning('Preencha os campos corretamente!');
            this.saving = false;
            return;
        }

        this.repositoryService.saveMaterialChanges(material)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Arquivo alterado', 3);
                    this.dialogRef.close(true);
                    this.adminService.updateChields();
                } else {
                    this.alertService.error(response.error);
                }

                this.saving = false;
            }, err => {
                this.alertService.error('Houve um erro ao salvar as alterações do material. Verifique a conexão e tente novamente');
                this.saving = false;
            });

    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
    }

    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    getClassAllMaterials(classId: number) {
        this.loading = true;
        this.repositoryService.getClassAllMaterials(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.materials = response.data as Material[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.loading = false;
                this.alertService.error('Houve um erro ao carregar os materiais. Verifique a conexão e tente novamente');
            });
    }

    getTeacherClasses(teacherId: number) {
        this.schoolService.getTeacherClasses(teacherId).subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

}
