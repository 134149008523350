import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
    templateUrl: './manage-student.component.html',
})
export class ManageStudentComponent implements OnInit {

    constructor(
        public userService: UserService
    ) { }

    ngOnInit(): void { 
    }
}
