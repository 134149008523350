import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { timeStamp } from 'console';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Discipline } from '../../admin/discipline/discipline';
import { DisciplineService } from '../../admin/discipline/discipline.service';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { CalendarService } from '../../calendar/calendar.service';
import { UserService } from '../../user/user.service';
import { Video } from '../../video/video';

@Component({
  selector: 'app-teacher-view-plans',
  templateUrl: './teacher-view-plans.component.html',
  styleUrls: ['./teacher-view-plans.component.scss']
})
export class TeacherViewPlansComponent implements OnInit {



  public studyPlans: StudyPlan[];
  public disciplines: Discipline[];
  public planSelected: StudyPlan;
  public loading = true;
  public showClassrooms: boolean;
  public videos: Video[];
  public videoIdSearch:number;
  public planIdSearch:number;
  public disciplineIdSearch:number;

  constructor(
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService,
    private alertService: AlertService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute, 
    public calendarService: CalendarService,
  ) { }

  ngOnInit() {
    this.planIdSearch = Number(this.route.snapshot.paramMap.get('planId'));  
    this.disciplineIdSearch = Number(this.route.snapshot.paramMap.get('disciplineId'));
     
    this.getTeacherViewPlans();
  }

   getTeacherViewPlans() {
    this.studyPlanService.getTeacherViewPlans(this.userService.getUserId())
      .subscribe(async res => {
        const response = res.body as ResponseApi;

        if (!response.data[0]) {
          this.alertService.error('Não há planos cadastrados para esse professor!')
        }
        else {
          if (!response.error) {
            this.studyPlans = response.data as StudyPlan[];
            await this.getOnePlan(this.studyPlans,this.planIdSearch); 
            
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  async getOnePlan(plans,id){
      const planSel = await plans.map(plan =>{if(plan.id == id) return plan })
      this.videoIdSearch = null;
      this.videos = null;
      this.planSelected = null;
      this.disciplines = null;
      if(this.planIdSearch && this.planIdSearch > 0 && this.disciplineIdSearch && this.disciplineIdSearch > 0){
        this.planSelected = planSel[0];
        if(planSel[0].version === 1){
      
          this.videoIdSearch = null;
          this.getPlanDisciplinesVideosv1(planSel[0].id);
        }else{
          this.getPlanDisciplinesVideosv2(planSel[0].id);
        }
      }else if(this.planIdSearch && this.planIdSearch > 0){
        this.planSelected = planSel;
        this.selectPlan(planSel);
      }
  }

  selectPlan(studyPlan: StudyPlan) {
    this.planSelected = studyPlan;
    this.disciplineService.setSelectedPlanIdOnDisciplinesVideosSubject(studyPlan.id);

    if(studyPlan.version === 1){
      this.getPlanDisciplinesVideosv1(studyPlan.id);
    }else{
      this.getPlanDisciplinesVideosv2(studyPlan.id);
    }
    
    this.planIdSearch = studyPlan.id;
    this.disciplineIdSearch = null;
    this.videoIdSearch = null;
  }


  getPlanDisciplinesVideosv1(planId: number) {
    this.loading = true;
    this.studyPlanService.getPlanDisciplinesVideos(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.data[0]) {
          this.alertService.error('Não ha disciplinas Cadastradas nesse plano');
        }
        else {

          if (!response.error) {
            this.disciplines = response.data as Discipline[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getPlanDisciplinesVideosv2(planId: number) {
    this.loading = true;
    this.studyPlanService.getPlanDisciplinesV2Teacher(planId, this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi; 

          if (!response.error) {
            this.disciplines = response.data as Discipline[];
            if(this.disciplines.length == 0){
              this.reloadCalendar(planId , this.userService.getUserId() );
            }
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
         
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  reloadCalendar(planId: number, studentId: number){ 
    this.calendarService.getPlanSchedule(planId, studentId)
      .subscribe(res => {
        this.secondTryPlanV2(planId,studentId);
      }, err => {}); 
  }
  secondTryPlanV2(planId: number, studentId: number){
    this.loading = true;
    this.studyPlanService.getPlanDisciplinesV2(planId,studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.data[0]) {
          this.alertService.error('Não ha disciplinas Cadastradas nesse plano');
        }
        else {

          if (!response.error) {
            this.disciplines = response.data as Discipline[];
             
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getDisciplineVideosv2(disciplineId: number, planId: number) {
    this.videos = [];
    this.disciplineIdSearch = disciplineId;
    this.videoIdSearch = null;
    let userId = Number(this.userService.getUserId());
    this.disciplineService.getDisciplineVideosByPlanV2(Number(disciplineId), planId, userId)
        .subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) {
                let data = response.data['videos'];
                this.videos = data as Video[]; 
            } else {
                this.alertService.error(response.error);
            } 
        }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
  }

  getDisciplineVideosv1(disciplineId: number, searchOnly?: boolean) {
    
 
    this.disciplineIdSearch = disciplineId;
    this.videoIdSearch = null;
     
    this.loading = true;
    this.disciplineService.getDisciplineVideos(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
         
            this.videos = response.data as Video[];
           
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente')
      });
  }

  openDisciplineVideos(discipline: Discipline) {
    if(this.planSelected.version === 1){
      this.getDisciplineVideosv1(discipline.id );
    }else{
      this.getDisciplineVideosv2(discipline.id,this.planSelected.id);
    }
  }
  openVideo(videoId:number) { 
    this.videoIdSearch =  videoId; 
    //console.log('/video/watch/' + this.planIdSearch + '/' + this.disciplineIdSearch + '/'+ this.videoIdSearch+'/teacher')
    this.router.navigate(['/video/watch/' + this.planIdSearch + '/' + this.disciplineIdSearch + '/'+ this.videoIdSearch+'/teacher']);
  } 
  setShowClassrooms(show: boolean) {
    this.showClassrooms = show;
  }
}
