<div class="container" *ngIf="loading">
    <div class="row">
        <div class="col-12 text-center mt-10">
            <mat-spinner color="primary" class="center"></mat-spinner>
        </div>
    </div>
</div>
<div class="container" *ngIf="!loading">
    <div class="row">
        <div class="col-12 d-flex">
            <h6 class="close-modal" (click)="close()">X</h6>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12 text-center">
            <img class="logo" [src]="logo" [alt]="nameSchool">
        </div>
    </div>
    <div class="row row-integration d-flex justify-content-center">
        <div class="col max-w100"  *ngFor="let integration of integrations">
            <div class="text-center" [title]="integration.title">
                <a [href]="integration.link" target="_blank">
                    <img class="circle" [src]="integration.image" [alt]="integration.name"><br>
                    <span class="text">{{integration.name}}</span>
                </a>
            </div>
        </div>
    </div>
</div>
