import { Component, OnInit } from '@angular/core';
import { MockService } from '../mock.service';
import { UserService } from '../../user/user.service';
import { ActivatedRoute } from '@angular/router';
import { MockSummationQuestion } from '../mock-question';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ExerciseService } from '../../admin/exercise/exercise.service';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SummationOptions } from '../summation-reply/SummationOptions';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-mock-result',
  templateUrl: './summation-result.component.html',
  styleUrls: ['./summation-result.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SummationResultComponent implements OnInit {

  public questions: MockSummationQuestion[];
  public questionsResponse: SummationOptions[];
  public questionsSaved: boolean[];
  public questionsCorrect: SummationOptions[];
  public mockId: number;
  public loading: boolean;
  public dataSource: MatTableDataSource<MockSummationQuestion>;
  public columnsToDisplay: string[];
  public expandedElement: MockSummationQuestion | null;
  public hits = 0;
  public studentId: number;
  public haveResolution: boolean;

  constructor(
    private mockService: MockService,
    public userService: UserService,
    private route: ActivatedRoute,
    public layoutService: LayoutService,
    private alertService: AlertService,
    private exerciseService: ExerciseService) { }

  ngOnInit(): void {
    this.mockId = Number(this.route.snapshot.paramMap.get('mockId'));
    this.loading = true;
    this.profileInit();
    // tslint:disable-next-line: no-unused-expression
    this.mockId && this.getMockQuestions();
    this.columnsToDisplay = [
      'questionNumber',
      'studentResponse',
      'correctResponse',
      'punctuation'
    ];
  }

  profileInit() {
    if (this.userService.isStudent()) {
      this.studentId = this.userService.getUserId();
    } else {
      this.studentId = Number(this.route.snapshot.paramMap.get('studentId'));
    }
  }

  getMockQuestions() {
    this.loading = true;
    this.mockService.getMockQuestions(this.mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.questions = response.data as MockSummationQuestion[];
          this.questions = this.questions.map(q => {
            q.alternatives = q.alternatives.sort((a, b) => Number(a.option) < Number(b.option) ? -1 : Number(a.option) < Number(b.option) ? 1 : 0);
            return q;
          })
          this.initializeQuestionsArrays(this.questions.length);
          this.verifyResponseQuestions();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as questões. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  initializeQuestionsArrays(exercisesNumber: number) {
    this.questionsResponse = [];
    this.questionsSaved = [];
    this.questionsCorrect = [];

    for (let i = 0; i < exercisesNumber; i++) {
      let opt: SummationOptions = { opt1: false, opt2: false, opt4: false, opt8: false, opt16: false, opt32: false, opt64: false };
      this.questionsResponse.push(opt);
      this.questionsSaved.push(false);
      this.questionsCorrect.push(null);
    }
  }

  concatResponseIntoQuestions() {

    if (this.verifyArrays()) {
      this.questionsCorrect.map((q, i) => {
        const studentResponse = this.summationOptionsToString(this.questionsResponse[i], i, 'student');
        const correctResponse = this.summationOptionsToString(q, i, 'correct');
        this.questions[i].correctAlternative = correctResponse;
        this.questions[i].index = i;
        this.questions[i].studentResponse = studentResponse;
        this.questions[i].punctuation = this.calcSummationPunctuation(studentResponse, correctResponse, this.questions[i].alternatives.length);
      });
      this.dataSource = new MatTableDataSource(this.questions);
      this.loading = false;
    }
  }

  getPunctuation() {
    let hits = 0;
    this.questions.map(q => {
      hits += q.punctuation;
    });
    return hits;
  }

  private verifyArrays(): boolean {

    this.questionsResponse.map(q => {
      if (!q) {
        return false;
      }
    });

    return true;
  }

  private verifyResponseQuestions() {

    for (let i = 0; i < this.questions.length; i++) {
      this.verifyResponseQuestion(this.questions[i].id, this.studentId, i);
    }

  }

  verifyResponseQuestion(questionId: number, studentId: number, exerciseIndex: number) {

    this.mockService.verifyResponseQuestion(this.mockId, questionId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

          const resp = response.data as string;

          this.questionsSaved[exerciseIndex] = true;
          this.setResponseAlternativesQuestion(exerciseIndex, resp);
          this.getQuestionCorrectAlternative(questionId, exerciseIndex);
        } else {
          this.alertService.error(response.error);
        }

        if (this.questions.length - 1 === exerciseIndex) {
          this.loading = false;
        }
      }, err => this.alertService.error('Houve um erro ao verificar o exercício. Verifique a conexão e tente novamente'));
  }

  getQuestionCorrectAlternative(questionId: number, exerciseIndex: number) {
    this.exerciseService.getExerciseCorrectAlternative(questionId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

          const correctOptions: SummationOptions = { opt1: false, opt2: false, opt4: false, opt8: false, opt16: false, opt32: false, opt64: false };
          const arrayCorrect = (response.data as { correct: string }).correct.split('+');

          arrayCorrect.forEach(option => {
            correctOptions['opt' + option] = true;
          });

          this.questionsCorrect[exerciseIndex] = correctOptions;
          this.questionsSaved[exerciseIndex] = true;

          const studentResponse = this.summationOptionsToString(this.questionsResponse[exerciseIndex], exerciseIndex, 'student');
          const correctResponse = this.summationOptionsToString(this.questionsCorrect[exerciseIndex], exerciseIndex, 'correct');
          this.questions[exerciseIndex].correctAlternative = correctResponse;
          this.questions[exerciseIndex].index = exerciseIndex;
          this.questions[exerciseIndex].studentResponse = studentResponse;
          this.questions[exerciseIndex].punctuation = this.calcSummationPunctuation(studentResponse, correctResponse, this.questions[exerciseIndex].alternatives.length);
          this.dataSource = new MatTableDataSource(this.questions);
          //this.concatResponseIntoQuestions();

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar o gabarito. Verifique a conexão e tente novamente'));
  }

  setResponseAlternativesQuestion(questionIndex: number, optionsStr: string) {
    const options = optionsStr.split('+');
    options.map(opt => {
      switch (opt) {
        case '1':
          this.questionsResponse[questionIndex].opt1 = true;
          break;
        case '2':
          this.questionsResponse[questionIndex].opt2 = true;
          break;
        case '4':
          this.questionsResponse[questionIndex].opt4 = true;
          break;
        case '8':
          this.questionsResponse[questionIndex].opt8 = true;
          break;
        case '16':
          this.questionsResponse[questionIndex].opt16 = true;
          break;
        case '32':
          this.questionsResponse[questionIndex].opt32 = true;
          break;
        case '64':
          this.questionsResponse[questionIndex].opt64 = true;
          break;
        default:
          break;
      }
    })
  }

  summationOptionsToString(options: SummationOptions, questionIndex: number, origin) {
    let optionsStr = '';

    if (options) {
      if (options.opt1) {
        optionsStr += '1';
      }
      if (options.opt2) {
        optionsStr += '+2';
      }
      if (options.opt4) {
        optionsStr += '+4';
      }
      if (options.opt8) {
        optionsStr += '+8';
      }
      if (options.opt16) {
        optionsStr += '+16';
      }
      if (options.opt32) {
        optionsStr += '+32';
      }
      if (options.opt64) {
        optionsStr += '+64';
      }

      if (optionsStr.charAt(0) == '+') {
        optionsStr = optionsStr.substring(1);
      }
    }

    return optionsStr;
  }

  getStudentQuestionNPC(studentResponse: string[], correctResponse: string[]) {
    let NPC = 0;
    studentResponse.forEach(resp => {
      if (correctResponse.find(option => option == resp)) {
        NPC++;
      }
    });
    return NPC;
  }

  getStudentQuestionNPI(studentResponse: string[], correctResponse: string[]) {
    let NPI = 0;
    studentResponse.forEach(resp => {
      if (!correctResponse.find(option => option == resp)) {
        NPI++;
      }
    });
    return NPI;
  }

  calcSummationPunctuation(studentResponse: string, correctResponse: string, NP: number) {
    let punctuation = 0.0;
    const studentRespArray = studentResponse.split('+');
    const correctRespArray = correctResponse.split('+');

    const NTPC = correctRespArray.length;
    const NPC = this.getStudentQuestionNPC(studentRespArray, correctRespArray); // student num. prep. corretas
    const NPI = this.getStudentQuestionNPI(studentRespArray, correctRespArray); // student num. prep. incorretas

    //console.log(NPC, NPI);

    console.log(correctRespArray);
    //NPTC = total de corretas
    // NPC = total de acertos

    if (NPC > NPI) {
      punctuation = (NP - (NTPC - (NPC - NPI))) / NP;
    }


    return punctuation;
  }
}
