import { HttpClient } from '@angular/common/http'; 
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlanSelect } from '../rdo-manager/create-theme/theme';
import { ClassSelect } from '../school/class';
import { Channel, ChannelClassrooms, ChannelEbooks, ChannelFolders, ChannelPlans, ClassRoomSelect, EbookSelect, FolderSelect, TaskSelect } from './channel';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  private API = environment.ApiUrl;
  public emitChannels = new EventEmitter<{name:string, values:any}>();
  constructor(private http: HttpClient) { }

  getChannel(channelId?: number){
    return this.http.get(this.API + '/channels?channelId='+channelId,{ observe: 'response' });
  }

  getChannelPlans(channelId: number){
    return this.http.get(this.API + '/channels/adm/plans?channelId='+channelId,{ observe: 'response' });
  }

  getChannelFolders(channelId: number){
    return this.http.get(this.API + '/channels/adm/folders?channelId='+channelId,{ observe: 'response' });
  }

  getChannelLives(channelId: number){
    return this.http.get(this.API + '/channels/adm/lives?channelId='+channelId,{ observe: 'response' });
  }

  getChannelEbooks(channelId: number){
    return this.http.get(this.API + '/channels/adm/ebooks?channelId='+channelId,{ observe: 'response' });
  }

  getChannelTasks(channelId: number){
    return this.http.get(this.API + '/channels/adm/tasks?channelId='+channelId,{ observe: 'response' });
  }

  getAllChannels(){
    return this.http.get(this.API + '/channels/all', { observe: 'response' });
  }

  getAllChannelsBySchool(schoolId: number){
    return this.http.get(this.API + '/channels/channelsbyschool?schoolId='+schoolId, { observe: 'response' });
  }

  getAllChannelsByUnit(unitId: number, schoolId: number){
    return this.http.get(this.API + '/channels/channelsbyunit?unitId='+unitId+'&schoolId='+schoolId, { observe: 'response' });
  }

  createChannel(channel: Channel){
    return this.http.post(this.API + '/channels', channel, { observe: 'response' });
  }

  updateChannel(channel: Channel){
    return this.http.put(this.API + '/channels/update', channel, { observe: 'response' });
  }

  removeChannel(id: number){
    return this.http.delete(this.API + '/channels'+id , { observe: 'response' });
  }

  deleteChannel(id: number){
    return this.http.put(this.API + '/channels', id , { observe: 'response' });
  }

  updateChannelPlans(channelId: number, channelPlans){
    return this.http.post(this.API + '/channels/plans', {channelId, channelPlans}, { observe: 'response' });
  }

  updateChannelFolders(channelId: number, channelFolders: FolderSelect[]){
    return this.http.post(this.API + '/channels/folders', {channelId, channelFolders}, { observe: 'response' });
  }

  updateChannelClassrooms(channelId: number, channelClassrooms: ClassRoomSelect[]){
    return this.http.post(this.API + '/channels/classrooms', {channelId, channelClassrooms}, { observe: 'response' });
  }

  updateChannelEbooks(channelId: number, channelEbooks: EbookSelect[]){
    return this.http.post(this.API + '/channels/ebooks', {channelId, channelEbooks}, { observe: 'response' });
  }

  updateChannelTasks(channelId: number, channelTasks: TaskSelect[]){
    return this.http.post(this.API + '/channels/tasks', {channelId, channelTasks}, { observe: 'response' });
  }

  updateChannelClasses(channelId: number, channelClasses: ClassSelect[]){
    return this.http.post(this.API + '/channels/classes', {channelId, channelClasses}, { observe: 'response' });
  }

  //PROVISÓRIO
  saveRoutePlans(channelId: number, routePlanName: string){
    return this.http.post(this.API + '/channels/route/plans', {channelId, routePlanName}, { observe: 'response' });
  }

  saveFoldersRoute(channelId: number, routeFolderName: string){
    return this.http.post(this.API + '/channels/route/folders', {channelId, routeFolderName}, { observe: 'response' });
  }

  saveLivesRoute(channelId: number, routeLiveName: string){
    return this.http.post(this.API + '/channels/route/lives', {channelId, routeLiveName}, { observe: 'response' });
  }

  saveEbooksRoute(channelId: number, routeEbookName: string){
    return this.http.post(this.API + '/channels/route/ebooks', {channelId, routeEbookName}, { observe: 'response' });
  }
  
  saveTasksRoute(channelId: number, routeTaskName: string){
    return this.http.post(this.API + '/channels/route/tasks', {channelId, routeTaskName}, { observe: 'response' });
  }

  removeRoute(id: number){
    return this.http.delete(this.API + '/channels/route/'+id , { observe: 'response' });
  }

  getTasksByClass(classId: number){
    return this.http.get(this.API + '/channels/tasks?classId='+classId , { observe: 'response' });
  }
}
