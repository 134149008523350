<div class="container">
  <ng-container *ngIf="question && !loading && !finished">
    <mat-divider></mat-divider>
    <div class="question mt-3" [innerHTML]="question?.question"></div>
    <mat-divider></mat-divider>

    <div class="alternatives mt-4 mb-4">
      <mat-radio-group [(ngModel)]="questionsResponse[i]" class="radio-group">
        <div *ngFor="let alt of question?.alternatives">
          <!--    ***************** NÃO REMOVER O NGIF ABAIXO - VALIDA SE OPÇÃO NÃO FOR NULA *****************    -->
          <ng-container *ngIf="alt.value">
            <b class="alternative">{{alt.option}})</b>
            <!-- <mat-radio-button [disabled]="exerciseSaved1" class="radio-button" -->
            <mat-radio-button [disabled]="questionsSaved[i]" class="radio-button" [value]="alt.option">
              <div style="height: 70%; white-space: pre-line" [innerHTML]="alt.value">
              </div>
            </mat-radio-button>
          </ng-container>
        </div>
      </mat-radio-group>
      <button class="hvr-grow" (click)="sendQuestionResponse(question.id, questionsResponse[i])"
        [disabled]="!questionsResponse[i]" mat-mini-fab class="right" color="accent" [hidden]="questionsSaved[i]">
        <mat-icon>send</mat-icon>
      </button>
    </div>

    <div *ngIf="this.questionsSaved[i] && !lastQuestion">
      <button mat-raised-button color="accent" (click)="next()">Próxima questão</button>
    </div>
    <div *ngIf="this.questionsSaved[i] && lastQuestion">
      <button mat-raised-button color="accent" (click)="end()">Terminar</button>
    </div>

  </ng-container>
  <ng-container *ngIf="finished && !verifyShowResult()">
    <div class="end-mock text-center">
      <div class="end-mock-title">
        Obrigado por sua participação!
      </div>
      <div class="end-mock-content mt-5">
        O resultado estará disponível a partir do dia {{this.resultDate | date:'dd/MM/y'}} às
        {{this.resultDate | date:'HH:mm'}} horário de Brasília.
      </div>
      <button mat-raised-button color="accent" class="mt-3" routerLink="/dashboard">Voltar ao início</button>
    </div>
  </ng-container>
  <div class="text-center" *ngIf="!question || loading">
    <mat-spinner class="center" color='accent'></mat-spinner>
  </div>
</div>