<div class="page-container p-pg page-container-mobile discipline " [ngClass]="{ 'p-0': layoutService.isMobile, 'pt-1': !layoutService.isMobile} ">
    <div class="row flex-start m-0 pt-5" [ngClass]="{'d-flex': !layoutService.isMobile}">
        <div class="col-12 ">
            <!-- lesson header -->
            <div class="lesson-header">
                <div class="lesson-title">
                    <span *ngIf="!video" class="page-title">Você está assistindo</span>
                    <span *ngIf="video" class="page-title">Esta é a prévia de</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">{{name}}</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">{{name}}</h6>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div class="lesson-back mb-2 " [ngClass]="{ 'text-center ': layoutService.isMobile} ">
                    <!-- back to repository list -->
                    <button *ngIf="!video" color="accent" mat-button class="mt-1 mb-1" [ngClass]="{ 'width-50 back-button text-left': !layoutService.isMobile} "  routerLink="/repository/video/list">
                        <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                    </button>
                    <!-- close preview -->
                    <button *ngIf="video" color="accent" mat-button class="mt-1 mb-1" [ngClass]="{ 'width-50 back-button text-left': !layoutService.isMobile} "  (click)="close()">
                        <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                    </button>
                </div>
            </div>

            <div>
                <div class="row">
                    <div class="mb-5 col-md-12">
                        <mat-card class="mb-3">
                            <iframe #player_container [src]="videoUrl | safe" width="100%" height="460" [ngClass]="{'videoMobile' : layoutService.isMobile}" frameborder="0" title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>