import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Class } from 'tinymce';
import { AdminService } from '../../admin.service';
import { ClassSelect } from '../../school/class';
import { UnitSelect } from '../../school/unit';
import { SchoolSelect } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { SelectPlanComponent } from '../../shared-components/select-plan/select-plan.component';
import { StudyPlan, StudyPlansSelect } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { ConfigServiceService } from '../config-service.service';
import { LandingPages } from './landingpages';

interface SelectedPlansResp {
  id: number,
  name: string;
}

@Component({
  selector: 'app-create-landing-pages',
  templateUrl: './create-landing-pages.component.html',
  styleUrls: ['./create-landing-pages.component.scss']
})
export class CreateLandingPagesComponent implements OnInit {

  private lps: LandingPages[];
  public form: FormGroup;
  public schoolsSelect: SchoolSelect[];
  public unitsSelect: UnitSelect[];
  public classesSelect: ClassSelect[];
  public plansSelect: StudyPlansSelect[];
  public selectedPlans: SelectedPlansResp[];
  public editMode: boolean;
  public showList: boolean;
  public loading: boolean;
  public selectedSchoolId :number;
  public selectedUnitId :number;
  public selectedClassId :number;
  public selectedPlanId :number;
  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();
  public copy: boolean = false;

  constructor(
    private dialog: MatDialog,
    private adminService: AdminService,
    private schoolService: SchoolService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public userService: UserService,
    private configService: ConfigServiceService,
    public studyPlanService: StudyPlanService
  ) { }

  ngOnInit() {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  init() {
    this.getSchoolsSelection();
    this.selectedPlans = [];
    this.form = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      link: ['', Validators.required],
      schoolId: ['', Validators.required],
      unitId: ['', Validators.required],
      classId: ['', Validators.required],
      plansIds: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getSchoolsSelection() {
    this.schoolService.getSchoolsSelection()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.schoolsSelect = response.data as SchoolSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnitsSelection(schoolId: number) {
    if(this.selectedSchoolId){
      this.getSchoolLandingPages(this.selectedSchoolId);
    }
    this.schoolService.getUnitsSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.unitsSelect = response.data as UnitSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClassesSelection(unitId: number) {
    if(this.selectedUnitId){
      this.getUnitLandingPages(this.selectedUnitId);
    }
    this.schoolService.getClassesSelection(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.classesSelect = response.data as ClassSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getPlansSelection(schoolId?: number) {
    this.schoolService.getPlansSelection(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plansSelect = response.data as StudyPlansSelect[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getSchoolLandingPages(schoolId: number) {
    this.configService.getSchoolLandingPages(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.lps = response.data as LandingPages[];
        } else {
          this.lps = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  } 

  getPlanLandingPages(planId?: number) {
    this.configService.getPlanLandingPages(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.lps = response.data as LandingPages[];
        } else {
          this.lps = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  } 

  getUnitLandingPages(unitId?: number) {
    this.configService.getUnitLandingPages(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.lps = response.data as LandingPages[];
        } else {
          this.lps = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getClassLandingPages(classId: number) {
    this.configService.getClassLandingPages(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.lps = response.data as LandingPages[];
        } else {
          this.lps = [];
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  openSelectPlans() {
    const dialogRef = this.dialog.open(SelectPlanComponent, {
      minWidth: '60vw',
      data: []
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if (result) {
        const index = this.selectedPlans.indexOf(result); 
        if (index < 0) {
          result.forEach(element => {
            let planSelected = {id:element.id, name:element.name};
              this.selectedPlans.push(planSelected);
          });
        }
      }
    });
  }

  removePlanChip(plan: SelectedPlansResp) {
    const index = this.selectedPlans.indexOf(plan);
    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }

  create() {
    this.loading = true;
    const plansIds = [];
    this.selectedPlans.map(e => plansIds.push(e.id));
    this.form.get('plansIds').setValue(plansIds);

    this.lps = this.form.getRawValue() as LandingPages[];
    this.configService.createLandingPages(this.lps)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        //console.log(response);
        this.alertService.success("Landing Pages cadastradas com sucesso!");
        this.form.reset();
      }else{
        this.alertService.error(response.error);
      }
      this.loading = false;
    }, err => {
      this.alertService.error('Houve um erro ao criar as landing pages. Verifique a conexão e tente novamente');
      this.loading = false;
    });
  }

  openList() {
    this.showList = true;    
    if(this.userService.isAdmin()){
      this.getLandingPages();
    }
    this.getSchoolsSelection();
  }

  getLandingPages(schoolId?: number) {
    this.configService.getLandingPages(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.lps = response.data as LandingPages[];
        } else {
          this.alertService.error(response.error);
          this.lps = [];
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }
  
  removeLandingPage(lpId: number){
    if (confirm('Deseja realmente apagar a Landing Page?')) {
      this.configService.removeLandingPage(lpId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.alertService.success('Landing Page removida', 3);
            this.getLandingPages();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao exclur a Landing Page. Verifique a conexão e tente novamente'));
    }
  }

  disableLandingPage(lpId: number, token:string){
    if (confirm('Deseja realmente desativar a Landing Page?')) {
      this.configService.disableLandingPage(lpId, token)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.alertService.success('Landing Page desativada', 3);
            this.getLandingPages();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao desativar a Landing Page. Verifique a conexão e tente novamente'));
    }
  }

  enableLandingPage(lpId: number){
    if (confirm('Deseja realmente ativar a Landing Page?')) {
      this.configService.enableLandingPage(lpId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.alertService.success('Landing Page ativada', 3);
            this.getLandingPages();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao ativar a Landing Page. Verifique a conexão e tente novamente'));
    }
  }

  /* To copy any Text */
copyText(val: string){
  let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyToken(val: string){
    this.copy = true;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}