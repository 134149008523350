import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'; 
import { BehaviorSubject } from 'rxjs';
import { Notifications } from 'src/app/modules/admin/notifications/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private API = environment.ApiUrl; 
  public emitCheckNotifications = new EventEmitter<number>();

  constructor(private http: HttpClient) { }
  
  /* ALL NOTIFICATIONS */
  getAllNotifications(schoolId:number, userId:number, profile: number) {
    return this.http.post(this.API + '/notifications/all', {schoolId, userId, profile}, { observe: 'response' });
  }

  markAsReadNotification(notificationId: number, userId:number){
    return this.http.post(this.API + '/notifications/read', {notificationId, userId}, { observe: 'response' });
  }
  markAsReadNotifications(notificationIds: Number[], userId:number){
    return this.http.post(this.API + '/notifications/read/ids', {notificationIds, userId}, { observe: 'response' });
  }

  getTypesNotifications() {
    return this.http.get(this.API + '/notifications/types/', { observe: 'response' });
  }
  createNotifications(notify: Notifications[]){
    return this.http.post(this.API + '/notifications', notify , { observe: 'response' });
  }
  removeNotification(notificationId: number){
    return this.http.get(this.API + '/notifications/remove/'+notificationId, { observe: 'response' });
   /*  return this.http.get(this.API + '/notifications/remove/', { observe: 'response', 
      params:{
        notificationId:notificationId.toString()
      } 
    }); */
  }
  getAllNotificationsCreated(){
    return this.http.get(this.API + '/notifications/all/created/', { observe: 'response' });
  }
  getAllNotificationsCreatedBySchool(schoolId: number){
    return this.http.get(this.API + '/notifications/created/school/'+schoolId, { observe: 'response' });
    /* return this.http.get(this.API + '/notifications/created/school/', { observe: 'response', 
      params:{
        schoolId:schoolId.toString()
      } 
    }); */
  }
  getAllNotificationsCreatedByPlan(planId: number){
    return this.http.get(this.API + '/notifications/created/plan/'+planId, { observe: 'response'});
  }
  getNotifyByTitle(title: string){
    return this.http.get(this.API + '/notifications/title/'+title, { observe: 'response' });
    /* return this.http.get(this.API + '/notifications/title/', { observe: 'response', 
      params:{
        title:title.toString()
      } 
    }); */
  }
  getNotificationsByProfile(profile: number){
    return this.http.get(this.API + '/notifications/profile/', { observe: 'response', 
      params:{
        profile:profile.toString()
      } 
    });
  }
  getNotificationsByType(type: string){
    return this.http.get(this.API + '/notifications/type/', { observe: 'response', 
      params:{
        type:type.toString()
      } 
    });
  }
  markAsRead(notification: Notifications, userId:number){
    return this.http.post(this.API + '/notifications/read', {notification, userId}, { observe: 'response' });
  }
  getAllNotificationsByUser(schoolId: number, unitId:number, classId:number, userId:number, profileId:number) {
    return this.http.post(this.API + '/notifications/all/by-user', {schoolId, unitId, classId, userId, profileId}, { observe: 'response' });
    //return this.http.post(this.API + '/notifications/user?schoolId='+schoolId+'&userId='+userId+'&profileId='+profileId, { observe: 'response' });
  } 
  getAllNotificationsByProfile(profile:number) {
    return this.http.get(this.API + '/notifications/profile/', { observe: 'response', 
      params:{
        profile:profile.toString()
      } 
    });
  } 
  getAllReadAndUnreadNotifications(schoolId: number, unitId:number, classId:number,userId:number, profileId:number) {
    return this.http.post(this.API + '/notifications/all', { schoolId, unitId, classId, userId, profileId }, { observe: 'response' });
    //return this.http.get(this.API + '/notifications/all?schoolId='+schoolId+'&userId='+userId+'&profileId='+profileId, { observe: 'response' });
  }
  checkLiveClassNow(schoolId:number, studentId: number, classId:number) {
    return this.http.get(this.API + '/notifications/live?schoolId='+schoolId+'&studentId='+studentId+'&classId='+classId, { observe: 'response' });
    //return this.http.post(this.API + '/notifications/live', { studentId, schoolId, classId }, { observe: 'response' });
  }
  asReadNotifications(notifications: Notifications[], userId:number){
    return this.http.post(this.API + '/notifications/read', { notifications, userId }, { observe: 'response' });
  }
  /* AJUSTE ROTAS */
  getReloadNotificationsByUser(schoolId: number, userId: number, profileId: number, lastNtfId: number){
    return this.http.get(this.API + '/notifications/reload?schoolId='+schoolId+'&userId='+userId+'&profileId='+profileId+'&lastNtfId='+lastNtfId, { observe: 'response' });
  }
}
