<div *ngIf="!showClassesList">
    <form [formGroup]="createClassForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Turma</h4>
        <h6> Total de alunos: {{classe.total}}</h6>
        <div *ngIf="remove">
            <h4 class="mt-3 mb-3">Excluir Turma </h4>
            <h6 class="mt-3 mb-3">Total de alunos: {{classe.total}} </h6>
            <p class="mt-3 mb-3" *ngIf="success"><b>AVISO: </b> <span class="">Turma vazia, você já pode excluir!</span></p>
            <p class="mt-3 mb-3" *ngIf="!success"><b>AVISO: </b> <span class="danger">Esta turma não está vazia, você precisa migrar os alunos para poder limpar a turma e excluir ela.</span></p>
        </div>
        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6" *ngIf="!remove">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" (selectionChange)="getUnits($event.value)" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-6" *ngIf="!userService.isUnitCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="unit" placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <div class="row">
                                <div class="col-10">
                                    <span [title]="unit.name">{{unit.name}}</span>
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="!remove">
                <mat-form-field class="width-100">
                    <mat-select formControlName="plans" placeholder="Planos">
                        <mat-option (click)="addPlanChip(plan)" *ngFor="let plan of plans" [value]="plan.id">
                            <span [title]="plan.name">{{plan.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-chip-list *ngIf="selectedPlans">
                    <mat-chip *ngFor="let planChip of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(planChip)">
                        {{planChip.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <div class="row" *ngIf="remove">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="id" placeholder="Turma">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <div class="row">
                                <div class="col-10">
                                    <span [title]="class.name">{{class.name}}</span> 
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <form [formGroup]="migrationClassForm" *ngIf="showMigrationClass">
        <br>
        <hr><br>
        <h4 class="mt-3 mb-3">Migrar Turma</h4>
        <!-- MIGRANDO TURMA -->
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="schoolMigration" (selectionChange)="getUnits($event.value)" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="unitMigration" placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <div class="row">
                                <div class="col-10">
                                    <span [title]="unit.name">{{unit.name}}</span> 
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="idClassMigration" placeholder="Turma">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row" *ngIf="remove">
        <div class="col-6 width-100 text-center" *ngIf="!success && !close">
            <button (click)="deleteStudents(classe)" mat-raised-button size="small" outline color="warn">EXCLUIR OS ALUNOS</button>
        </div>
        <div class="col-6 width-100 text-center" *ngIf="!success && !close">
            <button (click)="updateClass()" nbButton size="small" outline status="primary">Migrar Turma</button>
        </div>

        <div class="col-12 width-100 text-center" *ngIf="success && !close">
            <button mat-raised-button size="small" outline (click)="removeClass()" color="warn">Excluir Turma</button>
        </div>
    </div>

    <div class="row mt-4" *ngIf="editMode">
        <div [ngClass]="{'col-12': !deviceService.isDesktop, 'col-6': deviceService.isDesktop}">
            <div class="row m-0 d-flex " [ngClass]="{'justify-content-center': !deviceService.isDesktop, 'justify-content-between': deviceService.isDesktop}">
                <div class="mt-2" *ngIf="classe.active == 1">
                    <button mat-raised-button (click)="disableClass(classe.id)" color="warn">Desativar Turma <mat-icon> visibility_off</mat-icon></button>
                </div>
                <div class="mt-2" *ngIf="classe.active == 0">
                    <button mat-raised-button (click)="enableClass(classe.id)" color="accent">Ativar Turma <mat-icon>
                            visibility</mat-icon></button>
                </div>
                <div class="mt-2 mr-2">
                    <button mat-raised-button (click)="migration()" class="background-primary" [hidden]="hideButton">Migrar Turma</button>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
    </div>
</div>