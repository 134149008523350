<div  >    
    
        <div *ngIf="!synchronized || !unsynchronized" class="row mt-2">
            <div class=" col-6 width-100" >
                <button class="width-100" (click)="synchronized = true;  unsynchronized = false;" 
                        nbButton size="small" outline status="primary">
                    Ver Sincronizadas
                </button>
            </div>
            <div class=" col-6 width-100">
                <button class="width-100"  (click)="unsynchronized = true; synchronized = false;" 
                        nbButton size="small" outline status="danger"> 
                    Ver não Sincronizadas</button>
            </div>
            
        </div>
    
    <div *ngIf="synchronized && !removeMode && !editMode">
        <nb-card size="small">
            <nb-card-header *ngIf="userService.isAdmin()">
                <h5 class="m-2" >
                    Classes Sincronizadas <span class="material-icons info-font"> info </span>  <span class="info" > (Clique em <b>VER NÃO SINCRONIZADAS</b> para ver as que estão pendentes de sincronização! ) </span>
                    
                </h5>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select  placeholder="Escola"
                                            (selectionChange)="getUnits($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100">
                                <mat-select   placeholder="Unidade" 
                                    (selectionChange)="getClasses($event.value)">
                                    <mat-option *ngFor="let unit of units" [value]="unit.id.toString()">
                                        <div class="row">
                                            <div class="col-10">
                                                {{unit.name}}
                                            </div>
                                        </div>
                                </mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="classes" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let class of classSync">
                        <div class="row">
                            <div class="col-4">
                                {{class.name}}
                            </div> 
                            <div class="col-4">
                                <span class="color-status-success">{{class.studentSync}}</span> 
                                
                                <span class="color-status-primary" >/{{class.total}} students</span>
                                
                                </div> 
                                <div class="col-2 text-right">
                                    <button size="tiny" (click)="getStudentsUnSync(class)" status="danger" nbButton ghost>
                                        <span class="material-icons">
                                            alarm_on
                                        </span>
                                    </button>
                                </div>
                        </div>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="unsynchronized && !removeMode && !editMode">
        <nb-card size="small">
            <nb-card-header *ngIf="userService.isAdmin()">
                    <h5 class="m-2">Classes Não Sincronizadas <span class="material-icons info-font"> info </span>  <span class="info" > (Clique em <b>VER NÃO SINCRONIZADAS</b> para ver as que estão pendentes de sincronização! ) </span>

                    </h5>
                    <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select  placeholder="Escola" 
                                            (selectionChange)="getUnits($event.value)">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    {{school.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> 
                
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100">
                                <mat-select   placeholder="Unidade" 
                                    (selectionChange)="getClasses($event.value)">
                                    <mat-option *ngFor="let unit of units" [value]="unit.id.toString()">
                                        <div class="row">
                                            <div class="col-10">
                                                {{unit.name}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="classes" class="width-90 mt-3 center">
                    <nb-list-item  *ngFor="let class of classUnSync">
                        <div class="row">
                            <div class="col-4">
                                    {{class.name}}
                            </div>
                            
                            <div class="col-4">
                                <span class="color-status-danger">{{class.studentNotSync}}</span> 
                                
                                <span class="color-status-primary" >/{{class.total}} students</span>
                                
                                </div> 
                                <div class="col-2 text-right">
                                    <button size="tiny" (click)="getStudentsSync(class)" status="danger" nbButton ghost>
                                        <span class="material-icons">
                                            alarm_on
                                        </span>
                                    </button>
                                </div>
                        </div>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="editMode && !loading && !removeMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Sincronizar Alunos: {{synClasse.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui os <b>ALUNOS</b>  da turma serão  <b>SINCRONIZADOS</b>  ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <h5>Alunos</h5>
                <div class="col-6 text-left width-100 m-0">
                    <span class="color-status-primary  mb-2"  >Status: 
                        <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                        </span> 
                        <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                    </span>
                    
                </div>
            
                <div class="text-right width-100">
                    <button (click)="syncClass(syncStudents)" class="right" 
                            nbButton size="small" status="primary">Sincronizar</button>
                </div>
                <nb-list *ngIf="syncStudents" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let student of syncStudents">
                        <div class="row">
                            <div class="col-6">
                            {{student.name}}
                            </div> 
                            <div class="col-6" *ngIf="student.status == 1">
                                <span class="material-icons color-status-success"  status="success">
                                    done_all
                                </span>
                            
                            </div>
                            <div class="col-6" *ngIf="student.status == 0">
                                <span class="material-icons color-status-danger"  status="success">
                                    clear
                                </span>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list> 
            </nb-card-body>
        </nb-card>

        <div class="text-right width-100">
            <button (click)="editMode = false;getClasses(actualUnitId)" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
    <div *ngIf="removeMode && !loading && !editMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Remover Alunos: {{synClasse.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui os <b>ALUNOS</b>  da turma serão <b>REMOVIDOS</b> ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <h5>Alunos</h5>
                <div class="col-6 text-left width-100 m-0">
                    <span class="color-status-primary  mb-2"  >Status: 
                        <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                        </span> 
                        <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                    </span>
                    
                </div>
                <div class="text-right width-100">
                    <button (click)="unSyncClass(syncStudents)" class="right" 
                            nbButton size="small" status="primary">Remover</button>
                </div>
                <nb-list *ngIf="syncStudents" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let student of syncStudents">
                        <div class="row">
                            <div class="col-6">
                            {{student.name}}
                            </div> 
                            <div class="col-6" *ngIf="student.status == 1">
                                <span class="material-icons color-status-success"  status="success">
                                    done_all
                                </span>
                            
                            </div>
                            <div class="col-6" *ngIf="student.status == 0">
                                <span class="material-icons color-status-danger"  status="success">
                                    clear
                                </span>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
                
            </nb-card-body>
        </nb-card>

        <div class="text-right width-100">
            <button (click)="removeMode = false; getClasses(actualUnitId)" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>

  
  

   <!-- 
   <div *ngIf="!editMode" class="row mt-5">
       <div class=" col-6 width-100">
           <button (click)="openList()" nbButton size="small" outline status="primary">Ver unidades
               cadastradas</button>
       </div>
       <div class=" col-6 text-right width-100">
           <button (click)="createUnit()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
       </div>
   </div>
   <div *ngIf="editMode" class="row mt-5">
       <div *ngIf="editMode" class="text-left col-6">
           <button (click)="duplicateUnit()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Duplicar</button>
       </div>

       <div *ngIf="editMode" class="text-right col-6">
           <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
       </div>
   </div> -->
</div>
 