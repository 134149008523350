import { Plan } from 'src/app/modules/payment/plan';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchoolService } from '../../school/school.service';
import { School } from '../../school/school';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


interface SelectedPlansResp {
  id: number,
  name: string;
}

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {
  public loading: boolean;
  public selectedPlans: SelectedPlansResp[] = [];
  public schools: School[];
  public plans: SelectedPlansResp[];

  public planForm: FormGroup;

  constructor(  public userService: UserService,
                public dialogRef: MatDialogRef<SelectPlanComponent>,
                private alertService: AlertService,
                private formBuilder: FormBuilder,
                private schoolService: SchoolService,
                private studyPlanService: StudyPlanService) { }

  ngOnInit() {
   /*  this.planForm = this.formBuilder.group({
      plans: []
    }); */
    if (this.userService.isGeneralCoordinator() ) {
      this.getPlans(this.userService.getUserSchool());

    }else if(this.userService.isUnitCoordinator()){
      this.getUnitPlans(this.userService.getUserUnit());
    } else if (this.userService.isTeacher()) {
      this.getTeacherPlans(this.userService.getUserId());
    } else{
      this.getSchools();
    }
  }

  private getTeacherPlans(teacherId: number) {
    this.schoolService.getTeacherPlans(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          //this.selectedPlans = [...response.data as StudyPlan[]];
          //this.plansModel = [...this.selectedPlans.map(p => p.id)];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos do professor. Verifique a conexão e tente mais tarde'));
  }

  /* private getTeacherViewPlans(teacherId: number) {
    this.schoolService.getTeacherViewPlans(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedPlansView = [...response.data as StudyPlan[]];
          this.plansModelView = [...this.selectedPlansView.map(p => p.id)];
        } else {
          // this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos do professor. Verifique a conexão e tente mais tarde'));
  } */

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }
  getPlans(schoolId?: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getUnitPlans(unitId?: number) {
    this.loading = true;
    this.studyPlanService.getUnitPlans(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  addPlanChip(plan: SelectedPlansResp) {
    let planSelected = {id:plan.id, name:plan.name};
    const index = this.selectedPlans.indexOf(planSelected);
    if (index < 0) {
      this.selectedPlans.push(planSelected);
    }
  }
  removePlanChip(plan: SelectedPlansResp) {
    const index = this.selectedPlans.indexOf(plan);
    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }
  add(){
    this.dialogRef.close( this.selectedPlans);
  }

}
