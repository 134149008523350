// tslint:disable: no-unused-expression
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Payment } from 'src/app/modules/payment/payment';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { Plan } from 'src/app/modules/payment/plan';
import { MaskService } from 'src/app/shared/services/mask.service';

@Component({
    selector: 'app-payment-edit',
    templateUrl: './payment-edit.component.html',
})
export class PaymentEditComponent implements OnInit {

    public form: FormGroup;
    public loading: boolean;
    public plans$: Observable<Plan[]>;
    public payment: Payment;
    public isAddPayment: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { payment: Payment, isAddPayment: boolean, studentId: number },
        public dialogRef: MatDialogRef<PaymentEditComponent>,
        private paymentService: PaymentService,
        private formBuilder: FormBuilder,
        public maskService: MaskService) { }

    ngOnInit(): void {
        this.payment = this.data.payment;
        this.isAddPayment = this.data.isAddPayment;
        this.form = this.formBuilder.group({
            id: [''],
            status: ['', Validators.required],
            planName: [''],
            planId: [''],
            studentId: [''],
            expire: ['']
        });
        this.setForm();
        this.isAddPayment && this.getPaymentPlans();
    }

    private setForm() {
        if (!this.isAddPayment) {
            this.form.patchValue(this.payment);
            if (this.payment.expire) {
                this.form.get('expire').setValue(moment(this.payment.expire).format('DD/MM/YYYY'));
            }
            this.form.get('studentId').setValue(this.data.studentId);
            this.form.get('id').setValidators([Validators.required]);
            this.form.get('id').updateValueAndValidity();
        } else {
            this.form.get('studentId').setValue(this.data.studentId);
            this.form.get('planId').setValidators([Validators.required]);
            this.form.get('planId').updateValueAndValidity();
        }
    }

    saveChanges() {
        this.loading = true;
        const payment: Payment = this.form.getRawValue();
        payment.studentId = this.data.studentId;
        this.paymentService.savePaymentChanges(payment)
            .subscribe(() => this.dialogRef.close(true), err => this.loading = false);
    }

    getPaymentPlans() {
        this.plans$ = this.paymentService.getPlansObservable();
    }

    addStudentPayment() {
        this.loading = true;
        const payment = this.form.getRawValue();
        this.paymentService.addStudentPayment(payment)
            .subscribe(() => this.dialogRef.close(true), err => this.loading = false);
    }
}
