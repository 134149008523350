<div class="row" *ngIf="!editMode">
    <mat-form-field class="col-md-7">
        <input matInput placeholder="Buscar por email" [value]="studentMail" [(ngModel)]="studentMail">
    </mat-form-field>
    <div class="col-md-2 width-100 mt-3">
        <button (click)="getStudentByEmail(studentMail)" nbButton size="medium" status="primary" class="bg-color-accent-300 border-accent-thin">Buscar</button>
    </div>
</div>

<mat-divider class="mt-3 mb-2"></mat-divider>
<div *ngIf="studentUni || editMode" class="ml-2">
    <div class="mt-3 ml-2">
        <form [formGroup]="formUnimestre">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Nome" formControlName="nameUni">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="E_mail" formControlName="emailUni">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Escola" formControlName="school">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Turma" formControlName="classUni">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Curso" formControlName="courseUni">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Data de nascimento" formControlName="birthDate" type="date | dd-mm-yyyy">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100 externalcode">
                    <input matInput placeholder="Código externo" formControlName="externalCode">
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="row mt-2" *ngIf="!editMode">
        <div class="col-md-6 width-100 right text-right">
            <button (click)="createStudent()" class="right" nbButton size="small" status="primary" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>
    <div class="row mt-2" *ngIf="editMode">
        <div class="col-md-6 width-100 right text-right">
            <button (click)="updateStudent()" class="right" nbButton size="small" status="primary" nbSpinnerStatus="warning">Atualizar</button>
        </div>
    </div>
</div>

<div class="row mt-2 ml-2 mr-2" *ngIf="!editMode">

    <div class="row" *ngIf="!editMode">
        <mat-form-field class="col-md-12 right">
            <input matInput placeholder="Buscar por nome" [value]="studenNameUni" [(ngModel)]="studenNameUni">
            <mat-icon matSuffix (click)="getUniStudentsByName(studenNameUni)">search</mat-icon>
        </mat-form-field>
    </div>
    <ng-container>
        <nb-list class="width-100">
            <nb-list-item class="bg-color-accent list-header tableborder">
                <div class="row">
                    <div class="col-md-5 mt-1">Nome</div>
                    <div class="col-md-5 mt-1">Data de nascimento</div>
                    <div class="col-md-2 mt-1">Ações</div>
                </div>
            </nb-list-item>

            <nb-list-item class="itenstable" *ngFor="let uni of unitStudents">
                <div class="row">
                    <div class="col-md-5">{{uni.nameUni}}</div>
                    <div class="col-md-5">{{uni.birthDate}}</div>
                    <div class="col-md-1 text-right">
                        <button nbButton (click)="edituni(uni)" size="tiny" outline status="primary" class="bg-color-accent-100 border-accent-thin">Editar</button>
                    </div>
                    <div class="col-md-1 text-right">
                        <button size="tiny" (click)="deleteUniStudent(uni.id)" status="danger" nbButton ghost>
                            <nb-icon icon="trash"></nb-icon>
                        </button>
                    </div>
                </div>
            </nb-list-item>
        </nb-list>

        <mat-paginator *ngIf="!studenNameUni" [length]="totalPage" [pageSize]="pageSize" (page)="pageEvent($event)"></mat-paginator>
    </ng-container>
</div>