<div >   
    <div *ngIf=" !removeMode  && !editMode && !loading" >
        <nb-card size="medium">
                <nb-card-header *ngIf="userService.isAdmin()">
                    <h5 class="m-2" >
                    Coordenadores 
                    
                    </h5>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100">
                                <mat-select placeholder="Escola"  (selectionChange)="getUnits($event.value)">
                                    <mat-option *ngFor="let school of schools" [value]="school.id">
                                        {{school.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100">
                                <mat-select   placeholder="Unidade" 
                                (selectionChange)="getCoords($event.value)">
                                    <mat-option *ngFor="let unit of units"
                                    
                                    [value]="unit.id.toString()">
                                        <div class="row">
                                            <div class="col-10">
                                                {{unit.name}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
            </nb-card-header>
            <nb-card-body>
                <nb-list *ngIf="coords" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let coord of coords">
                        <div class="row">
                                <div class="col-6">
                                    {{coord.name}} - {{coord.email}}
                                </div> 
                               <!--  <div class="col-4">  
                                    <span class="color-status-success" *ngIf="coord.sync == 1;" >
                                        Sincronizado
                                    </span>
                                    <span class="color-status-danger" *ngIf="coord.sync == 0;">
                                        Não Sincronizado
                                    </span> 
                                </div>  -->
                                <div class="col-2">  
                                    <button size="tiny"  
                                            (click)="unSyncMode(coord)"  nbButton ghost>
                                        <span class="material-icons color-status-success">
                                            alarm_on 
                                        </span> 
                                    </button>
                                </div>
                                <div class="col-2">  
                                    <button size="tiny"   
                                            (click)="syncMode(coord)"   nbButton ghost> 
                                        <span class="material-icons color-status-danger" >
                                            alarm  
                                        </span>
                                    </button>
                                </div>
                        
                             <!--    <div class="col-2 text-right">
                                    <button size="tiny"  
                                            (click)="syncMode(coord)"  nbButton ghost>
                                        <span class="material-icons color-status-success">
                                            alarm 
                                        </span> 
                                    </button>
                                    <button size="tiny"   
                                            (click)="unSyncMode(coord)"   nbButton ghost> 
                                        <span class="material-icons color-status-danger" >
                                            alarm_on
                                        </span>
                                    </button>
                                </div> -->
                        </div>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div> 
    <div *ngIf="editMode && !loading && !removeMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Sincronizar Coordenadoress: {{synCoord.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui os <b>COORD.</b>  da unidade serão  <b>SINCRONIZADOS</b>  ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <h5>Coordenadores</h5>
                <div class="col-6 text-left width-100 m-0">
                    <span class="color-status-primary  mb-2"  >Status: 
                        <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                        </span> 
                        <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                    </span>
                    
                </div>
            
                <div class="text-right width-100">
                    <button (click)="syncCoord(syncUnitsCoords)" class="right" 
                            nbButton size="small" status="primary">Sincronizar</button>
                </div>
                <nb-list   *ngIf="syncUnitsCoords" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let unit of syncUnitsCoords">
                        <div class="row">
                            <div class="col-6">
                            {{unit.unitName}}
                            </div> 
                            <div class="col-6" *ngIf="unit.status == 1">
                                <span class="material-icons color-status-success"  status="success">
                                    done_all
                                </span>
                            
                            </div>
                            <div class="col-6" *ngIf="unit.status == 0">
                                <span class="material-icons color-status-danger"  status="success">
                                    clear
                                </span>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
                
            </nb-card-body>
        </nb-card>

        <div class="text-right width-100">
            <button (click)="editMode = false;getCoords(actualUnit);" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
    <div *ngIf="removeMode && !loading && !editMode" class="">
        <nb-card size="small">
            <nb-card-header class="mt-3" *ngIf="userService.isAdmin()">
                Remover Coordenadoress: {{synCoord.name}} <span class="material-icons info-font"> info </span>  <span class="info" > (Aqui os <b>COORD.</b>  da unidade serão <b>REMOVIDOS</b> ! ) </span>
            </nb-card-header>
            <nb-card-body>
                <h5>Coordenadores</h5>
                <div class="col-6 text-left width-100 m-0">
                    <span class="color-status-primary  mb-2"  >Status: 
                        <span class="color-status-success"><b>{{syncProgress.text}}</b> 
                        </span> 
                        <mat-progress-bar mode="determinate" [value]="syncProgress.progress"></mat-progress-bar>
                    </span>
                    
                </div>
            
                <div class="text-right width-100">
                    <button (click)="unSyncCoord(unSyncUnitsCoords)" class="right" 
                            nbButton size="small" status="primary">Remover</button> 
                   <!--  <button (click)="unSyncCoordUnits(unSyncUnitsCoords)" class="right" 
                            nbButton size="small" status="primary">Remover Unidades</button> -->
                </div>
                <nb-list  *ngIf="unSyncUnitsCoords" class="width-90 mt-3 center">
                    <nb-list-item *ngFor="let unit of unSyncUnitsCoords">
                        <div class="row">
                            <div class="col-6">
                            {{unit.unitName}}
                            </div> 
                            <div class="col-6" *ngIf="unit.status == 1">
                                <span class="material-icons color-status-success"  status="success">
                                    done_all
                                </span>
                            
                            </div>
                            <div class="col-6" *ngIf="unit.status == 0">
                                <span class="material-icons color-status-danger"  status="success">
                                    clear
                                </span>
                            </div>
                        </div>
                    </nb-list-item>
                </nb-list>
                
            </nb-card-body>
        </nb-card>

        <div class="text-right width-100">
            <button (click)="removeMode = false;getCoords(actualUnit);" class="right" 
                    nbButton size="small" status="primary">voltar</button>
        </div>
    </div>
</div>
 