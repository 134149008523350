import { StudyPlan } from 'src/app/modules/admin/study-plan/study-plan';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseApi } from 'src/app/core/models/response-api';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../admin.service';
import { Class } from '../../school/class';
import { Teacher } from '../../school/create-teacher/teacher';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { SelectPlanComponent } from '../../shared-components/select-plan/select-plan.component';
import { Folder } from './folder';
import { StudyPlanService } from '../../study-plan/study-plan.service';
interface SelectedPlansResp {
  id: number,
  name: string;
}

@Component({
  selector: 'app-repository-folder-add',
  templateUrl: './repository-folder-add.component.html',
  styleUrls: ['./repository-folder-add.component.scss']
})
export class RepositoryFolderAddComponent implements OnInit, OnDestroy {

  public folderForm: FormGroup;
  public folders: Folder[];
  public showList: boolean;
  public saving: boolean;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public unitClass: Class[]
  public selectedClasses: Class[];
  public editMode: boolean;
  public loading: boolean;
  public filterClassId: number;
  public filterPlansId: number;
  public teachers: Teacher[];
  public filtering: boolean;
  public selectPlans: boolean = false;
  public selectedTeachers: Teacher[];
  public teachersModel: number[];
  public selectedPlans: SelectedPlansResp[];
  public teacherFilter = new FormControl();
  public filteredTeachers: BehaviorSubject<Teacher[]> = new BehaviorSubject<Teacher[]>(null);
  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();
  public plans: StudyPlan[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public folder: Folder,
    @Optional() public dialogRef: MatDialogRef<RepositoryFolderAddComponent>,
    private dialog: MatDialog,
    private repositoryService: RepositoryService,
    public userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private studyPlanService: StudyPlanService,
    private schoolService: SchoolService) { }

  ngOnInit(): void {
    this.teacherFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterTeachers();
      });

    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  init() {
    this.selectedClasses = [];
    this.selectedTeachers = [];
    this.selectedPlans = [];
    this.folderForm = this.formBuilder.group({
      id: [],
      userId: [''],
      name: ['', Validators.required],
      tag: ['', Validators.required],
      classId: ['', Validators.required],
      planId: ['', Validators.required],
      unitId: ['', Validators.required],
      schoolId: [''],
      isTeacher: [false]
    });
    this.getSchools();
    this.coordinationInit();
    if (this.folder) { this.initializeEditMode(); }
  }

  initializeEditMode() {
    this.folderForm.patchValue(this.folder);
    this.getFolderClasses(this.folder.id);
    this.getFolderTeachers(this.folder.id);
    this.getSchoolTeachers(this.folder.schoolId);
    this.getFolderPlans(this.folder.id);
    this.editMode = true;
  }

  coordinationInit() {

    if (this.userService.isGeneralCoordinator()) {
      this.folderForm.get('schoolId').setValue(this.userService.getUserSchool());
      this.getUnits(this.userService.getUserSchool());
      this.getSchoolTeachers(this.userService.getUserSchool());
    }

    if (this.userService.isUnitCoordinator()) {
      this.folderForm.get('schoolId').setValue(this.userService.getUserSchool());
      this.folderForm.get('unitId').setValue(this.userService.getUserUnit());
      this.getUnits(this.userService.getUserUnit());
    }

    if (this.userService.isTeacher()) {
      this.folderForm.get('userId').setValue(this.userService.getUserId());
      this.folderForm.get('schoolId').setValue(this.userService.getUserSchool());
      this.folderForm.get('unitId').setValue(this.userService.getUserUnit());
      this.getUnits(this.userService.getUserSchool(), this.userService.getUserUnit());

      // precisa de um método que retorne as turmas do professor
      // this.getTeacherClasses(this.userService.getUserId());
    }
  }

  getFolderPlans(folderId: number){
    this.repositoryService.getFolderPlans(folderId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.selectedPlans = response.data as SelectedPlansResp[];
      }
    })
  }

  openList() {
    this.getFolders();
    if (this.userService.isUnitCoordinator()) {
      this.getClasses(this.userService.getUserUnit());
    }
    this.showList = true;
  }

  getFolders() {
    /* if(this.userService.isAdmin()){
      this.getAllFolders();
    }else if(this.userService.isGeneralCoordinator()){
      this.getAllFolders(this.userService.getUserSchool());
    } */
    if (!this.userService.isTeacher()) {
      if (this.filterClassId){ this.getClassFolders(this.filterClassId); }
      if (this.filterPlansId){ this.getFoldersPlan (this.filterPlansId); }
    } else {
      this.getTeacherFolders(this.userService.getUserId());
    }
  }

  getAllFolders(schoolId?:number){
    this.loading = true;
    this.repositoryService.getAllFolders(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as Folder[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getFoldersPlan(planId:number){
    this.loading = true;
    this.repositoryService.getPlanFolders(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as Folder[];
          this.folders = this.folders.map(f => {
            f.id = Number(f.id);
            f.schoolId = Number(f.schoolId);
            return f;
          })
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getPlans(schoolId?: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  getClassFolders(classId: number) {
    this.loading = true;
    this.repositoryService.getClassFolders(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as Folder[];
          this.folders = this.folders.map(f => {
            f.id = Number(f.id);
            f.schoolId = Number(f.schoolId);
            return f;
          })
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlansFolders(planId: number) {
    this.loading = true;
    this.repositoryService.getPlanFolders(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as Folder[];
          this.folders = this.folders.map(f => {
            f.id = Number(f.id);
            f.schoolId = Number(f.schoolId);
            return f;
          })
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getTeacherFolders(teacherId: number) {
    this.loading = true;
    this.repositoryService.getAssociatedTeacherFolders(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as Folder[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  /* getTeacherFolders(teacherId: number) {
    this.loading = true;
    this.repositoryService.getTeacherFolders(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.folders = response.data as Folder[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  } */

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId: number, unitId?: number) {
    this.getPlans(schoolId);
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          const units = response.data as Unit[];
          this.InitUnitClassesArray(units);
          if (unitId) {
            units.map(u => {
              if (u.id == this.userService.getUserUnit()) {
                this.units = [u] as Unit[];
              }
            });
          } else {
            this.units = response.data as Unit[];
          }
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getUnitClasses(unit: Unit) {
    /* this.units.map(unit => { */

    this.schoolService.getClasses(unit.id)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          unit.classes = response.data as Class[];
        }
      });
    /*  }); */
  }

  getTeacherClasses(teacherId: number, unit: Unit) {
    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          unit.classes = response.data as Class[];
          // concat school, unit and class name
          unit.classes = unit.classes.map(c => {
            c.name = c.schoolName + ' - ' + c.unitName + ' - ' + c.name;
            return c;
          });
        }
      });
  }

  getUnitsClasses(unit: Unit) {

    this.userService.isTeacher() ? this.getTeacherClasses(this.userService.getUserId(), unit) : this.getUnitClasses(unit);
  }

  addTeacherFolder(teacherId: number[], folderId: number[]) {

    this.repositoryService.setTeacherFolderOne(teacherId, folderId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {

          this.getTeacherFolders(this.userService.getUserId());

        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  async add() {
    this.folderForm.get('userId').setValue(this.userService.getUserId());
    this.folderForm.get('schoolId').setValue(this.userService.getUserSchool());
    const classId = [];
    const planId = [];
    await this.selectedClasses.map(c => classId.push(c.id));  
    await this.selectedPlans.map(c => planId.push(c.id)); 
    this.folderForm.get('classId').setValue(classId);
    this.folderForm.get('planId').setValue(planId);
    if (!this.folderForm.errors) {
      this.saving = true;
      const folder = await this.folderForm.getRawValue() as Folder; 
      this.addFolder(folder);
    } else {
      this.alertService.error('Preencha todos os campos corretamente');
    } 
  }

  addFolder(folder){
    console.log(folder);
    this.repositoryService.addFolder(folder)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        if (this.userService.isTeacher()) {
          this.addTeacherFolder([response.data as number], [this.filterInt(this.userService.getUserId())]);
        }
        if (this.selectedTeachers?.length) {
          this.addTeacherFolder([response.data as number], [...this.selectedTeachers.map(t => t.id)]);
        }
        this.alertService.success('Pasta adicionada', 3);
        this.folderForm.reset();
        this.adminService.updateChields();
      } else {
        this.alertService.error(response.error);
      }

      this.saving = false;

    }, err => {
      this.alertService.error('Houve um erro ao adicionar a pasta. Verifique a conexão e tente novamente');
      this.saving = false;
    });
  }

  filterInt(value: any) {
    if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value))
      return Number(value);
    return NaN;
  }

  remove(folderId: number) {
    if (confirm('Deseja realmente remover a Pasta?')) {
      this.repositoryService.removeFolder(folderId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Pasta removida!', 3);
            this.getFolders();
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover a Pasta. Verifique a conexão e tente novamente'));
    }
  }

  addClassChip(c: Class) {
    const index = this.selectedClasses.indexOf(c);
    if (index < 0) {
      this.selectedClasses.push(c);
      this.folderForm.get('classId').reset();
    }
  }

  removeClassChip(c: Class) {
    const index = this.selectedClasses.indexOf(c);

    if (index >= 0) {
      this.selectedClasses.splice(index, 1);
    }
  }

  selectClass(c: Class) {
   
    if (this.selectedClasses.indexOf(c) === -1) {
      this.selectedClasses.push(c);
    }
  }

  removeSelectedClass(index: number) {
    if (index !== -1) {
      this.selectedClasses.splice(index, 1);
    }
  }

  saveChanges() {
    this.folderForm.get('id').setValue(this.folder.id);

    this.folderForm.get('userId').setValue(this.userService.getUserId());
    this.folderForm.get('schoolId').setValue(this.userService.getUserSchool());
    const classId = [];
    const planId = [];
    this.selectedClasses.map(c => classId.push(c.id));
    this.selectedPlans.map(c => planId.push(c.id));
    this.folderForm.get('classId').setValue(classId);
    this.folderForm.get('planId').setValue(planId);

    if (!this.folderForm.errors) {
      this.saving = true;
      const folder = this.folderForm.getRawValue() as Folder;

      this.repositoryService.saveFolderChanges(folder)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.setFolderTeachers(this.folder.id, [...this.selectedTeachers.map(t => t.id)]);
            this.alertService.success('Pasta Atualizada', 3);
            this.folderForm.reset();
            this.adminService.updateChields();
            this.dialogRef.close(true);
          } else {
            this.alertService.error(response.error);
          }

          this.saving = false;

        }, err => {
          this.alertService.error('Houve um erro ao adicionar a pasta. Verifique a conexão e tente novamente');
          this.saving = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente');
    }
  }

  edit(folder: Folder) {
    const dialogRef = this.dialog.open(RepositoryFolderAddComponent, {
      minWidth: '60vw',
      data: folder
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getFolders();
      }
    });
  }

  openSelectPlans() {
    const dialogRef = this.dialog.open(SelectPlanComponent, {
      minWidth: '60vw',
      data: []
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if (result) {
        const index = this.selectedPlans.indexOf(result); 
        if (index < 0) {
          result.forEach(element => {
            let planSelected = {id:element.id, name:element.name};
              this.selectedPlans.push(planSelected);
          });
        }
      }
    });
  }
  removePlanChip(plan: SelectedPlansResp) {
    const index = this.selectedPlans.indexOf(plan);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }


  getFolderClasses(folderId: number) {
    this.repositoryService.getFolderClasses(folderId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedClasses = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas da pasta. Verifique a conexão e tente novamente'));
  }


  getClasses(unitId: number) {
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getSchoolTeachers(schoolId: number) {
    this.schoolService.getTeachers(schoolId).subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.teachers = response.data as Teacher[];
        this.teachers.map(t => {
          t.id = Number(t.id);
          t.schoolId = Number(t.schoolId);
          t.unitId = Number(t.unitId);
        });
      } else {
        //this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente'));
  }

  addTeacherChip(teacher: Teacher) {
    const index = this.selectedTeachers.findIndex(t => t.id == teacher.id);

    if (!index) {
      this.selectedTeachers.push(teacher);
    }
  }

  removeTeacherChip(teacher: Teacher) {
    const index = this.selectedTeachers.findIndex(t => t.id == teacher.id);

    if (index >= 0) {
      this.selectedTeachers.splice(index, 1);
      this.teachersModel = [...this.selectedTeachers.map(d => d.id)];
    }
  }

  teacherSelectionChange(selectedTeachersId: number[]) {
    this.selectedTeachers = [];
    selectedTeachersId.forEach(teacherId => {
      for (const teacher of this.teachers) {
        if (teacher.id == teacherId) {
          this.selectedTeachers.push(teacher);
          break;
        }
      }
    });
  }

  protected filterTeachers() {
    if (!this.teachers) {
      return;
    }
    let search = this.teacherFilter.value;
    if (!search) {
      this.filteredTeachers.next(this.teachers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtering = true;
    // filter Teachers
    this.filteredTeachers.next(
      this.teachers.filter(teacher => teacher.name.toLowerCase().indexOf(search) > -1)
    );

    this.filtering = false;
  }

  private setFolderTeachers(folderId: number, teacherId: number[]) {
    if (teacherId?.length) {
      this.repositoryService.setFolderTeachers([Number(folderId)], teacherId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.selectedTeachers = [];
            this.teachersModel = [];
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao adicionar os professores da pasta. Verifique a conexão e tente novamente'));
    }
  }

  private getFolderTeachers(folderId: number) {
    this.repositoryService.getFolderTeachers(folderId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedTeachers = [...response.data as Teacher[]];
          this.teachersModel = [...this.selectedTeachers.map(t => Number(t.id))];
        } else {
          //console.log(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os professores da pasta. Verifique a conexão e tente novamente'));
  }

  private InitUnitClassesArray(unities: Unit[]) {
    unities = unities.map(unit => {
      unit.classes = [];
      return unit;
    })

  }

}
