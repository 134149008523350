
<div *ngIf="!saving && calendar" class="text-right">
  <button  mat-stroked-button color="accent" class="hvr-grow mr-3" (click)="syncCalendar()">
      Atualizar calendário <mat-icon class="f20">sync</mat-icon> 
  </button>
  <button  mat-stroked-button class="hvr-grow" color="accent" (click)="saveChanges()">
    Salvar alterações <mat-icon>save</mat-icon>
  </button>
</div>
<div class="row pt-3">
  <div *ngFor="let day of calendar?.days; let i = index" class="col-md-3">
    <nb-flip-card>
      <nb-card-front>
        <nb-card class="center mat-elevation-z8 bg-color-primary">
          <nb-card-body class="day text-center">
            <span>
              <div class="small text-center mb-3">dia</div>
              <div class="text-center">{{day?.day}}</div>
            </span>
          </nb-card-body>
          <button mat-icon-button class="hvr-buzz-out edit" matTooltipClass="custom-tooltip" matTooltip="Editar dia"
            (click)="openDialog(day?.videos, i)">
            <mat-icon>edit</mat-icon>
          </button>
        </nb-card>
      </nb-card-front>
      <nb-card-back>
        <nb-card class="center mat-elevation-z8">
          <nb-card-header>
            Aulas do dia {{day?.day}}
          </nb-card-header>
          <nb-card-body>
            <mat-nav-list>
              <ng-container *ngFor="let video of day?.videos">
                <div mat-list-item class="mb-3">{{ video?.title }}</div>
              </ng-container>
            </mat-nav-list>
          </nb-card-body>
        </nb-card>
      </nb-card-back>
    </nb-flip-card>
  </div>
  <div class="col-md-3 text-center add-day">
    <button mat-fab class="hvr-grow" color="accent" matTooltipClass="custom-tooltip" matTooltip="Adicionar dia" (click)="addNewDay()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <!-- save -->

</div>

<button *ngIf="!saving" mat-fab class="hvr-grow save-changes" color="primary" matTooltipClass="custom-tooltip" matTooltip="Salvar alterações"
  (click)="saveChanges()">
  <mat-icon>save</mat-icon>
</button>

<mat-spinner class="save-changes" *ngIf="saving" [diameter]="35"></mat-spinner>