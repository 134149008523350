<div class="container pt-5 pb-5">

    <mat-accordion>
        <mat-expansion-panel class="width-70 center" [expanded]=false>
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Estudante</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-student></app-create-student>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
        <mat-expansion-panel class="width-70 mt-4 center" [expanded]=false>
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Estudantes (CSV)</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-students-add-csv></app-students-add-csv>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-70 mt-4 center" [expanded]=false>
            <mat-expansion-panel-header>
                Criar Responsável
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-father></app-create-father>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>