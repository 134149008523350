import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5/dist/md5';

import { StudyPlanService } from '../admin/study-plan/study-plan.service';
import { User, Base64 } from './user';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    private API = environment.ApiUrl;
    private userSubject = new BehaviorSubject<User>(null);
    private userDataKey: string = Md5.hashStr('userDataViaulon') as string;
    private user: User;

    constructor(
        private router: Router,
        private http: HttpClient,
        private studyPlanService: StudyPlanService) {

        this.user = {} as User;
        // tslint:disable-next-line:no-unused-expression
        this.isLogged() && this.decodeAndNotify();
    }

    getUser() {
        return this.userSubject.asObservable();
    }

    private decodeAndNotify() {
        const userStringData = this.getUserLoggedData();
        const user = this.decodeData(userStringData);
        this.user = user;
        this.userSubject.next(user);
    }

    loadUserData() {
        const userStringData = this.getUserLoggedData();
        const user = this.decodeData(userStringData);
        this.user = user;
        this.getMetaData();
    }

    logout() {
        this.studyPlanService.clearSelectedPlanSubject();
        window.localStorage.removeItem(this.userDataKey);
        this.userSubject.next(null);
        this.router.navigate(['signin'])
            .then(res => {
                location.reload();
            });
    }

    isLogged() {
        return !!this.getUserLoggedData();
    }

    setUserLoggedData(userData) {
        // console.log(userData)
        // userData.screens = this.orderScreens(userData.screens);
        const userDataString = JSON.stringify(userData);
        window.localStorage.setItem(this.userDataKey, btoa(userDataString));
        this.decodeAndNotify();
    }

    getUserLoggedData() {
        return window.localStorage.getItem(this.userDataKey);
    }

    private decodeData(data: string): User {
        return JSON.parse(atob(data));
    }

    getUserName() {
        return this.user.name;
    }

    getUserId() {
        return this.user.id;
    }
    getUserDomain() {
        return this.user.domain;
    }
    getUserRestrictDomain() {
        return this.user.restrictDomain;
    }  
    getUserUnit() {
        return this.user.unit;
    }

    getUserClass() {
        return this.user.class;
    }

    getUserSchool() {
        return this.user.school;
    }

    getUserSchoolName() {
        return this.user.schoolName;
    }

    getUserEmail() {
        return this.user.email;
    }

    // Integração UNIMESTRE

    getIsUni() {
        return (this.user.call!== "")? true: false;
    }

    getCall() {
        return this.user.call;
    }
    getMenuRedirect() {
        return this.user.menu_redirect;
    }
    getUniLink() {
        return this.user.uniLink;
    }
    getUniCode() {
        let code = btoa(this.user.uniToken);
        let codeArr = code.split(':');
        return codeArr[0] ;
    }
    getUniPass() {
        let code = btoa(this.user.uniToken);
        let codeArr = code.split(':');
        return codeArr[1] ;
    }


    getUserProfileId(): number {
        return this.user.profileId;
    }
    getUserPermissionId(): number {
        return this.user.permissionId;
    }

    getUserImage(): string {
        if (this.user.image) {
            return this.user.image;
        } else {
            return this.API + '/store/students/avatar.png';
            //return 'store/students/avatar.png';
        }
    }

    setUserImage(imgUrl: string) {
        this.loadUserData();
        this.user.image = imgUrl;
        this.setUserLoggedData(this.user);
    }

    getUserDataToEdit(userId: number) {
        return this.http.post(this.API + '/user/getUserDataToEdit', { userId }, { observe: 'response' });
    }

    updateUserData(user: User) {
        return this.http.post(this.API + '/user/updateUserData', user, { observe: 'response' });
    }

    isBuyProfile() {
        return this.user.buyProfile;
    }


    // verify profile methods

    isAdmin(): boolean {
        return Number(this.user.profileId) === 4 ? true : false;
    }

    isGeneralCoordinator(): boolean {
        return Number(this.user.profileId) === 3 ? true : false;
    }

    isUnitCoordinator(): boolean {
        return Number(this.user.profileId) === 2 ? true : false;
    }

    isTeacher(): boolean {
        return Number(this.user.profileId) === 5 ? true : false;
    }

    isStudent(): boolean {
        return Number(this.user.profileId) === 1 ? true : false;
    }

    isFather(): boolean {
        return Number(this.user.profileId) === 6 ? true : false;
    }

    isBeta(): boolean {

      return this.user.isBeta;
    }
    getBeta(): boolean {

      return (this.user.isBeta !== null && this.user.isBeta !== undefined)? true : false;
    }
    hasNewVersion(): boolean {

      return (this.user.newVersion !== null)? true : false ;
    }
    hasOldVersion(): boolean {

      return (this.user.oldVersion !== null)? true : false ;
    }

    teacherHavePermission(permissionRequired: number) {
        if (this.isTeacher()) {
            if (permissionRequired <= this.getUserPermissionId()) {
                return true;
            }
        } else if (!this.isStudent()) {
            return true;
        }

        return false;
    }


    isRDOActive(){

        if(this.user.rdoLink !== "")
            return true;
        else
            return false;
    }

    getProfiles(ignoreAdm?: boolean) {
        return this.http.post(this.API + '/user/getProfiles', { ignoreAdm }, { observe: 'response' });
    }

    getIp() {
        return this.http.get('http://api.ipify.org/?format=json');
    }
    utf8_encode(string)
    {
        var utftext = "";
        string = string.replace(/\r\n/g, "\n");

        for (var n = 0; n < string.length; n++)
        {
            var c = string.charCodeAt(n);

            if (c < 128)
            {
                utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048))
            {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else
            {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        } // Next n

        return utftext;
    }


    utf8_decode(utftext)
    {
        var string = "";
        var i = 0;
        var c, c1, c2, c3;
        c = c1 = c2 = 0;

        while (i < utftext.length)
        {
            c = utftext.charCodeAt(i);

            if (c < 128)
            {
                string += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224))
            {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else
            {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        } // Whend

        return string;
    }

    getMetaData() {
        //Pega a data
        var data = new Date();

        //Pega os dados
        let hora = data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();
        let timesTamp = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate() + ' ' + hora;

        //Pega o tamanho da tela
        let sizeScreen = {
            innerWidth: document.documentElement.clientWidth || document.body.clientWidth,
            innerHeight: document.documentElement.clientHeight || document.body.clientHeight
        };

        //Gera o básico da finger print
        var fingerPrint = {
            browserCodeName: window.navigator.appCodeName,
            browserName: window.navigator.appName,
            browserVersion: window.navigator.appVersion,
            cookiesEnabled: window.navigator.cookieEnabled,
            browserLanguage: window.navigator.language,
            browserOnline: window.navigator.onLine,
            platform: window.navigator.platform,
            userAgentHeader: window.navigator.userAgent,
            sizeScreen: sizeScreen
        };

        let metaData = {
            userId: this.user.id,
            userEmail: this.user.email,
            userProfileId: this.user.profileId,
            userUnit: this.user.unit,
            userSchool: this.user.school,
            userSchoolName: this.user.schoolName,
            userClass: this.user.class,
            userTimesTamp: timesTamp,
            userFingerPrint: fingerPrint,

        }
        window.localStorage.setItem("userData", JSON.stringify(metaData));

        return metaData;
    }
    get expire() {
        return this.user.expire;
    }

    get expireDate() {
        return this.user.expireDate;
    }

    get token() {
        return this.user.token;
    }

    get betaUrl() {
        return this.user.betaUrl;
    }
    get newVersion() {
      return this.user.newVersion;
    }
    get oldVersion() {
      return this.user.oldVersion;
    }
    removePhotoProfile(userId: number, userPhoto: string) {
        return this.http.post(this.API + '/user/removePhotoProfile', { userId, userPhoto }, { observe: 'response' });
    }
    
    getAllReadAndUnreadNotifications(schoolId: number, unitId:number, classId:number, userId: number, profileId: number, offset?: number, limit?: number) {
        return this.http.post(this.API + '/notifications/all', { schoolId, unitId, classId, userId, profileId, offset, limit }, { observe: 'response' });
        //return this.http.get(this.API + '/notifications/all?schoolId='+schoolId+'&userId='+userId+'&profileId='+profileId+'&offset='+offset+'&limit='+limit, { observe: 'response' });
    }
    markAsReadNotification(userId: number, notificationId: number) {
        return this.http.post(this.API + '/notifications/read', {notificationId, userId}, { observe: 'response' });
        /* return this.http.post(this.API + '/notifications/markAsRead', { userId, notificationId }, { observe: 'response' }); */
    }

    searchFatherByName(fatherName: string , userId: number) {
        return this.http.post(this.API + '/user/searchFatherByName', {fatherName , userId} ,  { observe: 'response' });
    }

    vinculateFather(fatherId : number , userId :number){
        return this.http.post(this.API + '/user/vinculateFather', {fatherId , userId} , { observe: 'response' });
    }

    desvinculateFather(fatherId : number , userId :number){
        return this.http.post(this.API + '/user/desvinculateFather', {fatherId , userId} , { observe: 'response' });
    }
    getVinculatedFathers(userId: number) {
        return this.http.get(this.API + '/user/getVinculatedFathers/'+userId, { observe: 'response' });
    }

    getTeacherSchools(userId: number) {
        return this.http.post(this.API + '/user/getTeacherSchools', {userId}, { observe: 'response' })
    }

    // orderScreens(screens) {
    //     let screensOrders = [];
    //     let orderedScreens = [];
    //     let addedIds = [];
    //     screens.forEach(screen => {
    //         screensOrders.push(Number(screen.order))
    //     });
    //     screensOrders = screensOrders.sort((a, b) =>  a - b);
    //     screensOrders.forEach(order => {

    //         screens.forEach(screen => {
    //             if(Number(screen.order) == order && !addedIds.includes(screen.id)){
    //                 orderedScreens.push(screen);
    //                 addedIds.push(screen.id)
    //             }
    //         });
           
    //     });
    //     return orderedScreens;
    // }
}
