import { Component, OnInit, Input } from '@angular/core';
import { PaymentService } from '../payment.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-payment-thanks',
    templateUrl: './payment-thanks.component.html',
    styleUrls: ['./payment-thanks.component.scss']
})
export class PaymentThanksComponent implements OnInit {

    @Input() method: string = null;
    @Input() boLink = 'http://google.com';
    @Input() boCode = '00190.00009 02832.093005 20456.970175 5 80790000002999';
    showPayments: boolean;

    constructor(
        private paymentService: PaymentService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer) { }


    ngOnInit(): void {
        this.iconRegistry.addSvgIcon(
            'pay-card',
            this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/payment-method.svg'));
        this.iconRegistry.addSvgIcon(
            'pay-money',
            this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/money.svg'));
    }

    dowloadBoleto() {
        window.open(this.boLink, '_blank');
    }
}
