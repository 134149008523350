import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PaymentService } from 'src/app/modules/payment/payment.service';
import { Paid } from 'src/app/modules/payment/payments/paid';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AuthService } from '../auth/auth.service';
import { ResponseApi } from '../models/response-api';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router,
        private authService: AuthService,
        private alertService: AlertService,
        private paymentService: PaymentService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        if (!this.userService.isLogged()) {
            this.router.navigate(['login']);
            return false;
        }


        if (this.userService.isBuyProfile()) {

            this.paymentService.isPaid(this.userService.getUserId())
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        const paid = response.data as Paid;
                        if (!paid.paid) {
                            this.router.navigate(['payment/thanks']);
                        } else if (!paid.paid && paid.expired && paid.type === 2) {
                            this.router.navigate(['payment/expired']);
                        }

                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Não foi possível verificar o seu plano'));
        }

        // verify expired test user
        if (this.userService.expire) {
            const expire = moment(this.userService.expireDate).toDate();

            if (expire > new Date()) {
                this.alertService.error('O seu tempo de teste expirou. Entre em contato com a nossa equipe para mais informações');
                this.router.navigate(['dashboard']);
            }
        }
        return true;
    }
}
