<div class="row mb-4 mt-3 width-100">
    <div class="col-md-6" *ngIf="!layoutService.isMobile">
        <mat-form-field class="width-100">
            <input matInput placeholder="Buscar pelo nome" [(ngModel)]="filterTag" (keyup)="applyFilter()">
        </mat-form-field>
    </div>
    <div class="col-md-6" *ngIf="filteredDisciplineTags">
        <mat-form-field *ngIf="filteredDisciplineTags.length > 0" class="width-100">
            <mat-select placeholder="Selecione um tema" [(ngModel)]="selectTagId" (selectionChange)="getTagSelected($event.value)">
                <mat-option *ngFor="let tag of filteredDisciplineTags" [value]="tag.id">
                    {{tag.tag_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="table-container table-videos height-100">
    <table class="width-100 mb-3" *ngIf="videos" mat-table [dataSource]="videoDataSource">
        <!-- Vídeo name -->
        <ng-container matColumnDef="watched" >
            <th mat-header-cell *matHeaderCellDef [ngClass]="{'brl-5': !layoutService.isMobile}"> ASSISTIDO </th>
            <td mat-cell *matCellDef="let video" class="text-center">
                <mat-icon *ngIf="video?.watched" color="accent">visibility</mat-icon>
                <mat-icon *ngIf="!video?.watched">visibility_off</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="video">
            <th class="border-bottom-none" mat-header-cell *matHeaderCellDef> NOME DA AULA </th>
            <td mat-cell *matCellDef="let video">
                <span class="mobileDiscipline" >{{video.title}}</span>
                <button class="mobileDisciplineLink table-mobile-link mr-10px" mat-button (click)="setDisciplinePlan(video.id)">
                    <span class="">{{video.title}}</span>
                    <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent">
                        <mat-icon>play_circle</mat-icon>
                    </span>
                </button>
            </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="link" stickyEnd class="disciplineVideoLink">
            <th mat-header-cell *matHeaderCellDef [ngClass]="{'brr-5': !layoutService.isMobile}"> LINK </th>
            <td mat-cell *matCellDef="let video">
                <button mat-icon-button color="accent" (click)="setDisciplinePlan(video.id)">
                    <mat-icon>play_circle</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns | paginate: {itemsPerPage: 10, currentPage: p, totalItems: totalRows}"></tr>
    </table>
    <pagination-controls class="text-center" previousLabel="" nextLabel='' (pageChange)="paginate($event)"></pagination-controls>
</div>
<mat-spinner *ngIf="!discipline || !videos" class="center mt-5 pt-5"></mat-spinner>