import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Class } from 'src/app/modules/admin/school/class';
import { School } from 'src/app/modules/admin/school/school';
import { SchoolService } from 'src/app/modules/admin/school/school.service';
import { Unit } from 'src/app/modules/admin/school/unit';
import { Mock } from 'src/app/modules/mock/mock';
import { MockService } from 'src/app/modules/mock/mock.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { MockReport } from './mock-report';
import { Student } from '../../student/student';

@Component({
  selector: 'app-mock-report',
  templateUrl: './mock-report.component.html',
  styleUrls: ['./mock-report.component.scss']
})
export class MockReportComponent implements OnInit {

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<MockReport>;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public schoolId: number;
  public unit: number;
  public classId: number;
  public mockId: number;
  public mocks: Mock[];
  public mockReport: MockReport[];
  public loading: boolean;
  public linkDownload: any[];
  public unitTemp: number;
  public studentsToFilter: string[];
  public mocksToFilter: string[];
  public filterMock: string;
  public filterStudent: string;


  constructor(
    private mockService: MockService,
    public userService: UserService,
    private alertService: AlertService,
    private schoolService: SchoolService,
    private router: Router) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'student',
      'replied',
      'correct',
      'errors',
      'mockQuestions',
      'mockDone',
      'mockTime',
      'mockName',
      'viewMock'
    ];
    this.profileInit();
    this.verifySelectedClassMockReport();
  }

  profileInit() {

    if (this.userService.isAdmin()) {
      this.getSchools();
    }

    if (this.userService.isGeneralCoordinator()) {
      this.schoolId = this.userService.getUserSchool();
      this.getMocks(this.userService.getUserSchool());
      this.getUnits(this.userService.getUserSchool());
    }

    if (this.userService.isUnitCoordinator()) {
      this.schoolId = this.userService.getUserSchool();
      this.getMocks(this.userService.getUserSchool());
      this.getClasses(this.userService.getUserUnit());
    }

    if (this.userService.isTeacher()) {
      this.schoolId = this.userService.getUserSchool();
      this.getMocks(this.userService.getUserSchool());
      this.getTeacherClasses(this.userService.getUserId());
    }
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente')
      });
  }

  getClasses(unitId: number) {
    this.unitTemp = unitId;
    this.loading = true;
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente')
      });
  }

  getUnits(schoolId: number) {

    if (this.userService.isAdmin()) {
      this.getMocks(schoolId);
    }
    this.loading = true;
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente')
      });
  }

  getTeacherClasses(teacherId: number) {

    this.schoolService.getTeacherClasses(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getMocks(schoolId: number) {
    this.mockService.getMocks(/* schoolId */)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente'));
  }

  getMockReportClass(mockId: number, classId: number) {
    this.loading = true;
    return this.mockService.getMockReportClass(mockId, classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mockReport = response.data as MockReport[];
          this.generateStudentAndMockArraysToFilter(this.mockReport);
          this.dataSource = new MatTableDataSource(this.mockReport);
          this.dataSource.filterPredicate = (data, filter: string): boolean => {
            if (data.mockName.toString() === filter.toString() || data.studentName === filter.toString()) {
              return true;
            } else {
              return false;
            }
          };
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar o relatório. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getReport() {
    if (this.mockId || this.classId) {
      this.getMockReportClass(this.mockId, this.classId);
    }
  }


  secondsToInputTime(seconds: number) {
    const date = new Date(0);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString().substr(12, 1) + 'h' + date.toISOString().substr(13, 3) + 'm';
  }

  openMock(studentId: number, mockId: number) {
    this.mockService.setSelectedClassMockReportSubject(this.classId);
    this.router.navigate(['/mock/result/', mockId, studentId]);
  }

  verifySelectedClassMockReport() {
    this.mockService.getSelectedClassMockReportSubject()
      .subscribe(classId => {
        if (classId) {
          this.classId = classId;
          this.getReport();
        }
      });
  }

  async generateReport() {

    if (this.schoolId === undefined || this.schoolId == 0) {
      this.alertService.error('Você precisa selecionar uma Escola!');
    } else if (this.unitTemp === undefined || this.unitTemp == 0) {
      this.alertService.error('Você precisa selecionar uma Unidade!');
    } else if (this.classId === undefined || this.classId == 0) {
      this.alertService.error('Você precisa selecionar uma turma!');
    } else {
      this.dataSource = new MatTableDataSource([]);
      let userdata = {
        schoolId: this.schoolId,
        classId: this.classId,
        reportId: 9,
        unitId: this.unitTemp
      }
      await this.getReportCSV(userdata);

    }

  }

  getReportCSV(userdata: any) {
    this.loading = true;
    this.mockService.reportCsvNovoRelSimulados(userdata)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          //this.linkDownload = response.data as ReportCsv[];

          if(response.data){
            this.generateXls(response.data);
            }
          this.alertService.success('Relatório Gerado com sucesso!');
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar os dados do relatório. . Verifique a conexão e tente novamente')
      });
  }


  generateXls(arrRelat : any){
    const headWrite = [];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Employee Data");

    // pega as keys de um array

    let auxHeaderName = [];

  /*   auxHeaderName.push(arrRelat);
    
    let auxKeys = auxHeaderName.flatMap(x => Object.keys(x));

    //console.log(auxKeys); */

    let header = ["id","Id Simulado","Aluno","Nome Simulado","Respondidos","Acertos","Erros","Total Questões","Tempo de prova","Tempo término"];
    let headerRow = worksheet.addRow(header);

    for (let x1 of arrRelat) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
            temp.push(x1[y])
        }
        worksheet.addRow(temp)
    }

    let fname = "simulado_relatorio_geral";

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  }

  clearName(str: string) {
    let palavra = '';
    let text = str.split('_');
    console.info(text);
    text.forEach(element => {
      palavra += element.charAt(0).toUpperCase() + element.slice(1) + ' ';
    });
    return palavra;
  }

  generateStudentAndMockArraysToFilter(mockReport: MockReport[]) {
    this.studentsToFilter = [];
    this.mocksToFilter = [];
    const studentsName = [];
    const mocksName = [];

    mockReport.forEach(report => {
      studentsName.push(report.studentName);
      mocksName.push(report.mockName);
    });

    this.studentsToFilter = [... new Set(studentsName)];
    this.mocksToFilter = [... new Set(mocksName)];
  }

  applyFilter(filter: string) {
    this.dataSource.filter = filter;
  }

}
