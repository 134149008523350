<div class="container pt-3">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="width-100">
                <mat-label>Materia</mat-label>
                <mat-select placeholder="Materia" [(ngModel)]="lessonText">
                    <mat-option *ngFor="let lesson of ptLessons" [value]="lesson">
                        {{lesson}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12 marginboxtop">
            <mat-form-field class="width-100">
                <textarea [(ngModel)]="answer" class="boxAnswer" matInput placeholder="Escreva aqui sua pergunta"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="row mt-1 mr-1">
        <button mat-raised-button color="accent" class="right" (click)="sendTuTorMundi(lessonText , answer)">Enviar Pergunta</button>
    </div>
</div>