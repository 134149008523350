<div  [ngClass] = "{'no-data': !editMode}">
    <div *ngIf="calendar && editMode" class="text-right">
        <button *ngIf="!userService.isStudent()" mat-stroked-button color="accent" class="mr-3" (click)="syncCalendar(planId)">
           Atualizar calendário<mat-icon class="f20">sync</mat-icon>
        </button>
        <button *ngIf="!userService.isStudent()" mat-stroked-button color="accent" class="mr-3" (click)="callAddNewMonth()">
            Adicionar mês <mat-icon>add</mat-icon>
        </button>
        <button *ngIf="!userService.isStudent()" mat-stroked-button color="accent" class="mr-3" (click)="saveCalendarChange()">
            Save <mat-icon>save</mat-icon>
        </button>
    </div>
    <div *ngIf="calendar " class="full-width">
        <div *ngIf="isView">
            <div class="page-title-container" [ngClass]="{'width-90 dash-container pr-4 pl-4':!layoutService.isMobile, 'width-80 text-center': layoutService.isMobile}">
                <span class="page-title">Plano de estudo inteligente</span>
                <div class="mt-3 description-title" *ngIf="!layoutService.isMobile">
                    <h4 class="font-text">Olá, {{userService.getUserName()}}!</h4>
                    <h4 *ngIf="userService.isStudent()" class="text-color-accent">O que vamos estudar hoje?</h4>
                </div>
                <div class="mt-3 description-title" *ngIf="layoutService.isMobile">
                    <h6 class="student-name">Olá, {{userService.getUserName()}}!</h6>
                    <h6 *ngIf="userService.isStudent()" class="text-color-accent">O que vamos estudar hoje?</h6>
                </div>
            </div>
            <button color="accent" mat-mini-fab class="mt-1 mb-1 ml-2 hvr-grow" (click)="btnBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <mat-tab-group #yearTabIndex style="margin-top: 26px" [selectedIndex]="yearTabIndex" backgroundColor="primary" color="primary" (selectedIndexChange)="handleTabIndexYearChange($event)">
            <!-- anos -->
            <div *ngIf="calendar?.agenda">
                <mat-tab *ngFor="let year of calendar?.agenda; let yearIndex = index" [label]="year.year">
                    <mat-tab-group #actualDate [selectedIndex]="actualDate" backgroundColor="primary" color="primary" (selectedIndexChange)="handleTabIndexMonthChange($event)">
                        <mat-tab *ngFor="let month of year.months; let monthIndex = index" [label]="month.monthName | uppercase">
                            <div class="row full-width header center">
                                <div class="col cel-header cel-first bg-color-accent col-mobile-week">DOM</div>
                                <div class="col cel-header bg-color-primary col-mobile-week">SEG</div>
                                <div class="col cel-header bg-color-primary col-mobile-week">TER</div>
                                <div class="col cel-header bg-color-primary col-mobile-week">QUA</div>
                                <div class="col cel-header bg-color-primary col-mobile-week">QUI</div>
                                <div class="col cel-header bg-color-primary col-mobile-week">SEX</div>
                                <div class="col cel-header cel-last bg-color-accent col-mobile-week">SAB</div>
                            </div>


                            <div *ngFor="let weekRow of yearsSchedule[yearIndex][monthIndex]; let weekIndex = index" class="row full-width center">

                                <div *ngFor="let day of weekRow; let dayIndex = index" class="col cel" [ngClass]="{

                                    'bg-color-accent': (dayIndex % 7 == 0 || dayIndex % 6 === 0),
                                    'cel-first': dayIndex % 7 == 0,
                                    'cel-last':  dayIndex % 6 == 0
                                    }">

                                    <div class="mb-3 day">
                                        <span class="day-date">
                                            <span>{{day?.day}}</span>
                                        </span>
                                    </div>


                                    <ng-container *ngIf="(!releaseCalendarVideosUpToday || year.year  < actualYear)">

                                      <div *ngIf="day?.remaining > 0; else complete" class="remaining center text-center mb-3" [ngClass]="{'hidden-mobile': layoutService.isMobile}">
                                        <!--  {{day?.remaining}} não assistidos -->
                                        <mat-chip *ngIf="day?.remaining > 0" class="remaining mobile-calendar-hide" color="primary">
                                            {{day.remaining}}
                                            <mat-icon>visibility_off</mat-icon>
                                        </mat-chip>
                                      </div>

                                      <app-lesson-popover class="mb-2" *ngIf="day?.videos?.length" [day]="day" [dayIndex]="dayIndex" [rowIndex]="weekIndex" [planId]="planId" [teacherDisciplines]="teacherDisciplines">
                                      </app-lesson-popover>

                                      <app-add-lesson class="mb-2" *ngIf="!userService.isStudent() && (day?.date) && editMode" [day]="day?.day" [date]="day?.date" [dayIndex]="dayIndex" [weekIndex]="weekIndex" [monthIndex]="monthIndex" [yearIndex]="yearIndex" [dayVideos]="day?.videos" (updatedVideos)="saveDayChanges($event)">
                                      </app-add-lesson>

                                      <!-- classrooms -->
                                      <ng-container *ngIf="isToday(day?.date) && classrooms?.length" class="text-center">
                                          <div class="live mt-3">
                                              <mat-icon color="warn" class="mr-1" [hidden]="layoutService.isMobile">podcasts</mat-icon>
                                              <span class="remaining mb-4" [ngClass]="{'hidden-mobile': layoutService.isMobile}">Ao vivo</span>
                                          </div>
                                          <mat-chip-list>
                                              <mat-chip *ngFor="let classroom of classrooms" class="hover-grow mr-2" color="warn" selected (click)="openLink(classroom.link)">
                                                  {{classroom.tag}}
                                              </mat-chip>
                                          </mat-chip-list>
                                      </ng-container>

                                      <!-- classrooms -->
                                      <!-- recorded classrooms -->
                                      <ng-container *ngIf="!layoutService.isMobile && day?.recordDay && day?.recordDay.length > 0">
                                          <!--     <mat-divider class="mt-2 mb-2"></mat-divider> -->
                                          <button class="full-width hvr-grow recorded-btn small-btn mt-3" mat-raised-button (click)="openRecordedClassroomsDay(day?.recordDay)" color="accent" matTooltipClass="custom-tooltip" matTooltip="Clique para abrir a lista com os links das aulas ao vivo que foram gravadas">
                                              Transmissões gravadas
                                          </button>
                                      </ng-container>

                                      <div class="day-code" *ngIf="day?.videos?.length">D-{{day?.ref}}</div>
                                      <!-- mobile calendar -->

                                      <mat-chip-list *ngIf="layoutService.isMobile && day?.recordDay && day?.recordDay.length > 0" class="align-center">
                                          <span *ngIf="day?.recordDay" (click)="openRecordedClassroomsDay(day?.recordDay)" class="material-icons-outlined pl-2" color="primary" selected>
                                              live_tv
                                          </span>
                                      </mat-chip-list>

                                    </ng-container>


                                    <ng-container *ngIf="releaseCalendarVideosUpToday && year.year  === actualYear">
                                      <ng-container *ngIf="monthsOfYear[month.monthName.toLowerCase()] < actualMonth ||
                                          (monthsOfYear[month.monthName.toLowerCase()] === actualMonth && day?.day <= dayOfToday)">

                                          <div *ngIf="day?.remaining > 0; else complete" class="remaining center text-center mb-3" [ngClass]="{'hidden-mobile': layoutService.isMobile}">
                                            <!--  {{day?.remaining}} não assistidos -->
                                            <mat-chip *ngIf="day?.remaining > 0" class="remaining mobile-calendar-hide" color="primary">
                                                {{day.remaining}}
                                                <mat-icon>visibility_off</mat-icon>
                                            </mat-chip>
                                          </div>

                                          <app-lesson-popover class="mb-2" *ngIf="day?.videos?.length" [day]="day" [dayIndex]="dayIndex" [rowIndex]="weekIndex" [planId]="planId" [teacherDisciplines]="teacherDisciplines">
                                          </app-lesson-popover>

                                          <app-add-lesson class="mb-2" *ngIf="!userService.isStudent() && (day?.date) && editMode" [day]="day?.day" [date]="day?.date" [dayIndex]="dayIndex" [weekIndex]="weekIndex" [monthIndex]="monthIndex" [yearIndex]="yearIndex" [dayVideos]="day?.videos" (updatedVideos)="saveDayChanges($event)">
                                          </app-add-lesson>

                                          <!-- classrooms -->
                                          <ng-container *ngIf="isToday(day?.date) && classrooms?.length" class="text-center">
                                              <div class="live mt-3">
                                                  <mat-icon color="warn" class="mr-1" [hidden]="layoutService.isMobile">podcasts</mat-icon>
                                                  <span class="remaining mb-4" [ngClass]="{'hidden-mobile': layoutService.isMobile}">Ao vivo</span>
                                              </div>
                                              <mat-chip-list>
                                                  <mat-chip *ngFor="let classroom of classrooms" class="hover-grow mr-2" color="warn" selected (click)="openLink(classroom.link)">
                                                      {{classroom.tag}}
                                                  </mat-chip>
                                              </mat-chip-list>
                                          </ng-container>

                                          <!-- classrooms -->
                                          <!-- recorded classrooms -->
                                          <ng-container *ngIf="!layoutService.isMobile && day?.recordDay && day?.recordDay.length > 0">
                                              <!--     <mat-divider class="mt-2 mb-2"></mat-divider> -->
                                              <button class="full-width hvr-grow recorded-btn small-btn mt-3" mat-raised-button (click)="openRecordedClassroomsDay(day?.recordDay)" color="accent" matTooltipClass="custom-tooltip" matTooltip="Clique para abrir a lista com os links das aulas ao vivo que foram gravadas">
                                                  Transmissões gravadas
                                              </button>
                                          </ng-container>

                                          <div class="day-code" *ngIf="day?.videos?.length">D-{{day?.ref}}</div>
                                          <!-- mobile calendar -->

                                          <mat-chip-list *ngIf="layoutService.isMobile && day?.recordDay && day?.recordDay.length > 0" class="align-center">
                                              <span *ngIf="day?.recordDay" (click)="openRecordedClassroomsDay(day?.recordDay)" class="material-icons-outlined pl-2" color="primary" selected>
                                                  live_tv
                                              </span>
                                          </mat-chip-list>

                                      </ng-container>
                                    </ng-container>

                                </div>

                            </div>

                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
            </div>
        </mat-tab-group>
    </div>
    <span class="small" style="float: right;">v2</span>

    <div *ngIf="!noShow" class="text-center mt-3">
        <span class="ml-3">Não há dados cadastrados</span>
    </div>

</div>
