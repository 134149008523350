import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class PerformanceReportService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  performanceReport(classId : number , mockId : number){
    return this.http.post(this.API + '/mock/getRepoPerformance', {classId , mockId}, { observe: 'response' });
  }

  performanceReportDiscipline(mockId : number , classId : number){
    return this.http.post(this.API + '/mock/getRepoPerformanceDiscipline', {mockId , classId}, { observe: 'response' });
  }
  performanceReportNote(classId : number , mockId :  number){
    return this.http.post(this.API + '/mock/getRepoPerformanceScore', {classId , mockId}, { observe: 'response' });
  }
  performanceReportTasting(planId :  number){
    return this.http.post(this.API + '/mock/performanceReportTasting', {planId}, { observe: 'response' });
  }
}
