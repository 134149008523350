import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Podcast } from './podcast';

@Injectable({ providedIn: 'root' })
export class PodcastService {

    private API = environment.ApiUrl;

    constructor(private http: HttpClient) { }

    getPodcasts(schoolId?: number) {
        return this.http.post(this.API + '/miscellaneous/getPodcasts', { schoolId }, { observe: 'response' });
    }

    setPodcasts(podcast: Podcast) {
        return this.http.post(this.API + '/miscellaneous/setPodcasts', podcast, { observe: 'response' });
    }

    removePodcast(id: number) {
        return this.http.post(this.API + '/miscellaneous/deletePodcasts', { id }, { observe: 'response' });
    }
}
