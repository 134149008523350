import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FlipBookService } from './flip-book.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-flip-book',
  templateUrl: './flip-book.component.html',
  styleUrls: ['./flip-book.component.scss']
})
export class FlipBookComponent  implements OnInit {

  public fileBookUrl:any;
  private API = environment.ApiUrl;
  constructor( private flipBookService: FlipBookService ,private sanitizer: DomSanitizer ) {} 


  ngOnInit(): void {
     this.fileBookUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.API +"/flip-book"); 
  } 


}

