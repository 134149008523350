import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModalInit } from './modal-init/modal-init';
import { LandingPages } from './create-landing-pages/landingpages';

@Injectable({
  providedIn: 'root'
})
export class ConfigServiceService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  /* MODAL */
  createModal(modal: ModalInit) {
    return this.http.post(this.API + '/configuration/createModal', modal , { observe: 'response' });
  }

  getModals() {
    return this.http.post(this.API + '/configuration/getModals', {} , { observe: 'response' });
  }

  getModalsBySchool(schoolId : number) {
    return this.http.post(this.API + '/configuration/getModalsBySchool', {schoolId} , { observe: 'response' });
  }

  deleteModal(id_modal: number) {
    return this.http.post(this.API + '/configuration/deleteModal', {id_modal} , { observe: 'response' });
  }

  updateModal(modal: ModalInit) {
    return this.http.post(this.API + '/configuration/updateModal', modal , { observe: 'response' });
  }

  getUserModal(userDomain: string){
    return this.http.post(this.API + '/configuration/getUserModal', {userDomain} , { observe: 'response' });
  }

  GetModalNotification(userDomain: string){
    return this.http.post(this.API + '/configuration/GetModalNotification', {userDomain} , { observe: 'response' });
  }

  /* LANGIND PAGES */
  createLandingPages(lps: LandingPages[]) {
    return this.http.post(this.API + '/configuration/createLandingPages', lps , { observe: 'response' });
  }
  getLandingPages(schoolId?: number) {
    return this.http.post(this.API + '/configuration/getLandingPages', { schoolId }, { observe: 'response' });
  }
  getSchoolLandingPages(schoolId: number){
    return this.http.post(this.API + '/configuration/getSchoolLandingPages', { schoolId }, { observe: 'response' });
  }
  getUnitLandingPages(unitId: number){
    return this.http.post(this.API + '/configuration/getUnitLandingPages', { unitId }, { observe: 'response' });
  }
  getClassLandingPages(classId: number){
    return this.http.post(this.API + '/configuration/getClassLandingPages', { classId }, { observe: 'response' });
  }
  getPlanLandingPages(planId: number){
    return this.http.post(this.API + '/configuration/getPlanLandingPages', { planId }, { observe: 'response' });
  }
  removeLandingPage(lpId: number) {
    return this.http.post(this.API + '/configuration/removeLandingPage', { lpId }, { observe: 'response' });
  }
  disableLandingPage(lpId: number, token:string) {
    return this.http.post(this.API + '/configuration/disableLandingPage', { lpId, token }, { observe: 'response' });
  }
  enableLandingPage(lpId: number) {
    return this.http.post(this.API + '/configuration/enableLandingPage', { lpId }, { observe: 'response' });
  }
}
