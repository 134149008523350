<div *ngIf="!showList">
    <div class="row width-100">
        <div class="col-md-6" *ngIf="!editMode">
            <mat-select placeholder="Tipo de Redação" (selectionChange)="setValName($event.value)">
                <mat-option *ngFor="let theme of themes" [value]="theme">
                    {{theme.name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-6">
            <label>Tipo De edital selecionado: {{labelName}} </label>
        </div>
    </div>

    <div class="row width-100 mt-2">
        <div class="col-md-12" *ngIf="selectedEdital">
            <h4>Edital Selecionado : {{selectedEdital.name}}</h4>
        </div>
    </div>

    <nb-list class="width-90 mt-3">
        <nb-list-item class="list-header corpadrao bg-color-accent-200">
            <div class="row">
                <div class="col-md-12">Nome Edital</div>
            </div>
        </nb-list-item>

        <nb-list-item *ngFor="let edital of editals">
            <div class="row">
                <div (click)="selectEdital(edital)" class="col-md-12">{{edital.name}}</div>
            </div>
        </nb-list-item>
    </nb-list>
    <mat-paginator [pageSize]="pageSize" [length]="pagelength" [pageIndex]="pageIndex" (page)="pageEvents($event)"></mat-paginator>

    <div class="row mt-5">
        <div class=" col-6 width-100" *ngIf="!editMode">
            <button nbButton size="small" (click)="openList()" outline status="primary" class="text-color-primary border-primary-thin">Ver Editais
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100" *ngIf="!editMode">
            <button class="right" nbButton size="small" (click)="createEdital()" status="primary" class="right bg-color-primary border-primary">Cadastrar</button>
        </div>

        <div class=" col-md-12 text-right" *ngIf="editMode">
            <button class="right" nbButton size="small" (click)="updateEdital()" status="primary" class="right bg-color-primary border-primary">Atualizar</button>
        </div>
    </div>
</div>

<div *ngIf="showList">
    <h4>Editais cadastrados:</h4>
    <nb-list class="width-90 mt-3">
        <nb-list-item class="list-header corpadrao bg-color-accent-200" style="color: white">
            <div class="row">
                <div class="col-md-5">Nome</div>
                <div class="col-md-5">Tipo</div>
                <div class="col-md-2">Ações</div>
            </div>
        </nb-list-item>

        <nb-list-item *ngFor="let e of dbEditals">
            <div class="row">
                <div class="col-md-5">{{e.name}}</div>
                <div class="col-md-5">{{e.typeName}}</div>
                <div class="col-1 text-right">
                    <button nbButton size="tiny" outline status="primary" (click)="openEditMode(e)">Editar</button>
                </div>
                <div class="col-1 text-right">
                    <button size="tiny" status="danger" (click)="deleteEdital(e.id)" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>

    <div class="col-md-12 text-right">
        <button class="right" nbButton size="small" (click)="btnBack()" status="primary" class="right bg-color-primary border-primary">Voltar</button>
    </div>
</div>
