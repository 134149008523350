import { Component, Input } from '@angular/core';

import { NgxLegendItemColor } from './enum.legend-item-color';

@Component({
    selector: 'app-legend-chart',
    styleUrls: ['./legend-chart.component.scss'],
    templateUrl: './legend-chart.component.html',
})
export class LegendChartComponent {

    @Input()
    legendItems: { iconColor: NgxLegendItemColor; title: string }[] = [];
}
