import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Theme } from 'src/app/core/themes/theme';
import { ThemeService } from 'src/app/core/themes/theme.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { ExperimentService } from '../experiment.service';
import { Experiment } from '../../admin/experiment/experiment';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
    selector: 'app-experiment-signup',
    templateUrl: './experiment-signup.component.html',
    styleUrls: ['./experiment-signup.component.scss']
})
export class ExperimentSignupComponent implements OnInit {

    public signupForm: FormGroup;
    public theme: Theme;
    public experimentHash: string;
    public experiment: Experiment;
    private login: { email: string, password: string };
    public loading: boolean;
    public block: boolean;

    constructor(
        private experimentService: ExperimentService,
        private formBuilder: FormBuilder,
        private themeService: ThemeService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        public titleService: Title,
        private authService: AuthService,
        @Inject(DOCUMENT) private document: HTMLDocument
    ) { }

    ngOnInit(): void {
        this.getTheme();
        this.experimentHash = this.route.snapshot.paramMap.get('experimentHash');
        // tslint:disable-next-line: no-unused-expression
        this.experimentHash && this.getExperimentHash();
        this.signupForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    getTheme() {
        const domain = window.location.hostname;
        this.themeService.getTheme(domain)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.theme = response.data as Theme;
                    this.themeService.setThemeData(this.theme);
                    this.titleService.setTitle(this.theme.name);
                    if (this.theme.favicon) {
                        this.document.getElementById('appFavicon').setAttribute('href', this.theme.favicon);
                    }
                } else {
                    this.alertService.error(response.error);
                }

            }, err => this.alertService.error('Houve um erro ao carregar o tema. Verifique a conexão e tente novamente'));
    }

    getExperimentHash() {
        this.experimentService.getExperimentHash(this.experimentHash)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.experiment = response.data as Experiment;
                    if (!this.experiment?.id) {
                        this.alertService
                            .error('Não encontramos o plano que você solicitou. Tente novamente ou entre em contato com o suporte');
                        this.block = true;
                    }
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar o plano. Verifique a conexão e tente novamente'));
    }

    signup() {
        if (this.block) {
            this.alertService.error('Não encontramos o plano que você solicitou. Tente novamente ou entre em contato com o suporte');
            return;
        }
        this.loading = true;
        const email = this.signupForm.get('email').value;
        const name = this.signupForm.get('name').value;
        const password = this.signupForm.get('password').value;

        this.login = { email, password };

        this.experimentService.signup(name, email, password, this.experiment.id).subscribe(
            res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.signin();
                } else {
                    this.alertService.error(response.error);
                    this.loading = false;
                }
            },
            err => {
                this.alertService.error('Falha de comunicação com a API!');
                this.loading = false;
                // this.signupForm.reset();
            }
        );
    }

    signin() {
        const { email, password } = this.login;
        this.authService.authenticate(email, password)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.router.navigate(['dashboard']);
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao realizar a autenticação. Verifique a conexão e tente novamente');
            });
    }
}
