import { Injectable } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';
import { ScriptService } from './script.service';

@Injectable({
  providedIn: 'root'
})
export class OctadeskService {

  constructor(
    private scriptService: ScriptService,
    private userService: UserService) { }

  startOctadesk() {

    window.addEventListener('onOctaChatReady', () => {
      window['octadesk'].chat.login({
        name: this.userService.getUserName(),
        email: this.userService.getUserEmail()
      });
    });

    const domain = window.location.hostname;
    if (domain.indexOf('plataforma.notamaxima.com.br') != -1) {
      setTimeout(() => {
        this.scriptService.load('oktadesck')
          .then(data => {
            //console.log('Script loaded', data);
          })
          .catch(error => (error));
      }, 3000);
    }
  }

}