import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { disciplineTag } from './discipline-tag';

@Component({
  selector: 'app-discipline-tag-create',
  templateUrl: './discipline-tag-create.component.html',
  styleUrls: ['./discipline-tag-create.component.scss']
})
export class DisciplineTagCreateComponent implements OnInit {

  public tagName: string;
  public editMode: boolean;
  public loading: boolean;
  public disciplinesTags: disciplineTag[];
  public showList: boolean;
  public schools: School[];
  public disciplines: Discipline[];
  public selectedSchool: number;
  public plans: StudyPlan[];
  public schoolOnly: boolean;
  public selectedDiscipline: number;
  public selectedPlan: number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public disciplineTag: disciplineTag,
    @Optional() public dialogRef: MatDialogRef<DisciplineTagCreateComponent>,
    private dialog: MatDialog,
    public userService: UserService,
    private disciplineService: DisciplineService,
    private alertService: AlertService,
    private schoolService: SchoolService,
    private studyPlanService: StudyPlanService
  ) { }

  ngOnInit() {

    this.schoolOnly = false;
    this.showList = false;

    if (this.disciplineTag) {
      this.editMode = true;
      this.showList = false;
      this.tagName = this.disciplineTag.tag_name;
      if(this.disciplineTag.idSchool){
        this.schoolOnly = true;
        this.selectedSchool = this.disciplineTag.idSchool;
      }
    }

    if (this.userService.isAdmin()) {
      this.getSchools();
    }

    if (!this.userService.isAdmin()) {
      this.getPlansBySchool(this.userService.getUserSchool())
    }
  }

  createTag() {
      if (this.userService.isAdmin()) {
        console.log("createTag isAdmin");
        if (this.tagName && this.selectedSchool) {
          this.disciplineService.createDisciplineTag(this.tagName, this.selectedSchool)
            .subscribe(res => {
              const response = res.body as ResponseApi

              if (!response.error) {
                this.tagName = '';
                this.alertService.success('Tag cadastrada com sucesso!');
              }
              else {
                this.alertService.error(response.error);
              }
            })
        }
        else {
          this.alertService.error('Você precisa preencher o nome da Tag e selecionar uma escola!');
        }
      }
      else {
        if (this.tagName) {
          this.disciplineService.createDisciplineTag(this.tagName, this.userService.getUserSchool())
            .subscribe(res => {
              const response = res.body as ResponseApi

              if (!response.error) {
                this.tagName = '';
                this.alertService.success('Tag cadastrada com sucesso!');
              }
              else {
                this.alertService.error(response.error);
              }
            })
        }
        else {
          this.alertService.error('Você precisa preencher o nome da Tag!');
        }
      }  
  }

  createTagWithDiscipline() {
    if (this.tagName && this.selectedDiscipline) {
      this.disciplineService.createDisciplineTagWithDiscipline(this.tagName, this.selectedDiscipline)
        .subscribe(res => {
          const response = res.body as ResponseApi

          if (!response.error) {
            this.tagName = '';
            this.alertService.success('Tag cadastrada com sucesso!');
          }
          else {
            this.alertService.error(response.error);
          }
        })
    }
    else {
      this.alertService.error('Você precisa preencher o nome da Tag e selecionar uma escola!');
    }
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  openList() {
    this.selectedSchool = null;
    this.selectedDiscipline = null;
    this.selectedPlan = null;
    this.disciplines = null;
    this.showList = true
    if (this.userService.isAdmin()) {
      this.plans = null;
      this.getDisciplinesTags();
    }
    else {
      this.getDisciplinesTagsBySchool(this.userService.getUserSchool());
    }
    this.selectedSchool = 0;
  }

  backButton() {
    this.selectedSchool = null;
    this.selectedDiscipline = null;
    this.selectedPlan = null;
    if (this.userService.isAdmin()){
      this.plans = null;
    }
    this.disciplines = null;
    this.showList = false;
  }

  getDisciplinesTags() {
    this.disciplineService.getDisciplinesTags()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplinesTags = response.data as disciplineTag[];
        }
        else {
          this.alertService.error('Não existem temas cadastrados');
        }
      })
  }

  getDisciplinesTagsBySchool(idSchool: number) {
    if (this.showList) {
      this.disciplineService.getDisciplinesTagsBySchool(idSchool)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.disciplinesTags = response.data as disciplineTag[];
          }
          else {
            this.alertService.error('Não existem temas de disciplinas nessa escola!');
            this.disciplinesTags = [];
          }
        })
    }
  }

  deleteDisciplineTag(tagId: number) {
    if (confirm('Deseja excluir essa tag?')) {
      this.disciplineService.deleteDisciplineTag(tagId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Tag deletada com sucesso!');
            this.getDisciplinesTags();
          }
          else {
            this.alertService.error(response.error);
          }
        })
    }
  }

  openEditMode(disciplineTag: disciplineTag) {
    const dialogRef = this.dialog.open(DisciplineTagCreateComponent, {
      data: disciplineTag,
      minWidth: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getDisciplinesTags();
        this.showList = true;
        this.editMode = false;
      }
    });

    dialogRef.backdropClick().subscribe(() => {
      this.showList = true;
      this.editMode = false;
    });
  }

  updateDisciplineTag(tagname: string) {

    this.disciplineTag.tag_name = tagname;
    this.disciplineTag.idSchool = this.selectedSchool;

    this.disciplineService.updateDisciplineTag(this.disciplineTag)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Dados atualizados com sucesso!');
          this.dialogRef.close(true);
        }
        else {
          this.alertService.error(response.error);
          this.dialogRef.close(true);
        }
      })
  }

  updateDisciplineTagWithDiscipline(tagname: string) {
    if (this.selectedDiscipline) {

      this.disciplineTag.tag_name = tagname;
      this.disciplineTag.disciplineId = this.selectedDiscipline;

      this.disciplineService.updateDisciplineTagWithDiscipline(this.disciplineTag)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Dados atualizados com sucesso!');
            this.dialogRef.close(true);
          }
          else {
            this.alertService.error(response.error);
            this.dialogRef.close(true);
          }
        })
    }
    else {
      this.alertService.error('Você precisa selecionar uma disciplina se não for somente por escola!');
    }
  }

  getPlansBySchool(schoolId: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        }
        else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error("Não foi possivel buscar os dados verifique a conexão e tente novamente!");
      })
  }

  selectScholl() {
    if (this.showList) {
      this.getDisciplinesTagsBySchool(this.selectedSchool)
    }
    if (!this.schoolOnly) {
      this.getPlansBySchool(this.selectedSchool);
    }
  }

  getDisciplinesByPlan(planId: number) {
    if(planId != 0) {
      this.studyPlanService.getPlanDisciplines(planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.disciplines = response.data as Discipline[];
          }
          else {
            this.alertService.error(response.error);
          }
        }, err => {
          this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!');
        })
    }
  }

  checkCreate() {
    if (this.selectedDiscipline && this.selectedDiscipline != 0) {
      this.createTagWithDiscipline()
    }
    else {
      this.createTag()
    }
  }

  checkDisciplineUpdate(tagname: string) {
    console.log('entrou no check');
    console.log(this.schoolOnly);
    if (this.schoolOnly) {
      this.updateDisciplineTag(tagname);
    }
    else {
      this.updateDisciplineTagWithDiscipline(tagname);
    }
  }

  selectDiscipline(disciplineId: number) {
    if (!this.schoolOnly) {
      if (this.showList) {
        if(disciplineId != 0) {
          this.disciplineService.getDisciplinesTagsByDiscipline(disciplineId)
            .subscribe(res => {
              const response = res.body as ResponseApi;
  
              if (!response.error) {
                this.disciplinesTags = response.data as disciplineTag[];
              }
              else {
                this.disciplinesTags = [];
                this.alertService.error('Não existem temas de disciplinas nessa Disciplina!');
              }
            })
        } else if (disciplineId == 0) {
          this.getDisciplinesTagsBySchool(this.userService.getUserSchool())
        }
      }
    }
  }
}
