<div *ngIf="!showThemeList">
    <form [formGroup]="createThemeForm">
        <div class="row align-items-center">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input matInput #themeExternalId placeholder="Id externo" formControlName="externalId" type="number" required>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Ordem" formControlName='order' type="number" max="100">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <button (click)="getThemeText(themeExternalId.value)" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning" class="right bg-color-accent-300 border-accent">Atualizar</button>
            </div>
            <div class="col-md-2 report-warnning" *ngIf="alertExternal == 1">
                <span>Id não encontrado nos temas!</span>
            </div>
            <div class="col-md-2 report-sucess" *ngIf="alertExternal == 2">
                <span>Dados atualizados!</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name" required>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <input maxlength="5" matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Tipo*" formControlName="type" name="item">
                        <mat-option *ngFor="let type of typeTheme" [value]="type.type">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-9">
            <mat-form-field class="width-100">
                <mat-select placeholder="Tipo de edital" formControlName="editalId">
                    <mat-option *ngFor="let edital of editals" [value]="edital.id">
                        {{edital.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-3">
                <button class="m-3" (click)="openSelectPlans()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning" class="right bg-color-accent-300 border-accent">Associar ao plano</button>
            </div>
            <div *ngIf="selectedPlans?.length > 0" class="col-md-9">
                <mat-label class="m-1" status="primary">Planos selecionados</mat-label>
                <mat-chip-list class="pt-3">
                    <mat-chip *ngFor="let plan of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(plan)">
                        {{plan.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
              </div>
        </div>
        <div class="row mt-5">
            <div class=" col-md-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Temas
                    cadastrados</button>
            </div>
            <div class="col-md-6 text-right width-100">
                <button (click)="createTheme()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning" class="right bg-color-primary border-primary">Cadastrar</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="showThemeList" class="row mt-5">
    <nb-list class="width-90 mt-3 center">
        <nb-list-item *ngFor="let theme of themes">
            <div class="row">
                <div class="col-md-8">
                    {{theme.name}}
                </div>

                <div class="col-md-2">
                    {{theme.typeName}}
                </div>
                <div class="col-md-1 text-right">
                    <button (click)="editTheme(theme)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-md-1 text-right">
                    <button (click)="deleteTheme(theme.id)" size="tiny" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="col-md-12 text-right width-100 mt-2">
        <button (click)="backButton()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Voltar</button>
    </div>
</div>
