import Node from "./Node.model"

export class LinkedQueue<T>{
    head: Node<T>;
    tail: Node<T>;

    constructor(){
        this.head = this.tail = null;
    }

    public printQueue(){
        console.info("head",this.head);
        console.info("tail",this.tail);
    }
    public newQueue(){
        return new LinkedQueue();
    }

    public enqueue(data:T):void{
        const node = new Node(data);

        if(this.isEmpty()){
            this.head = this.tail = null
        }

        this.tail.next = node;
        this.tail= node;
    }


    public dequeue():T{

        if(this.isEmpty()){
            return null;
        }

        const data = this.head.data;

        if(this.tail === this.head){
            this.head = this.tail = null
        }else{
            this.head = this.head.next;
        }

        return data;
    }



    public isEmpty() {
        return this.head === null;
    }
}