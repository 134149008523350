import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudyPlanCardComponent } from './study-plan-card/study-plan-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LottieModule } from 'ngx-lottie';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  NbAccordionModule,
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbListModule,
  NbProgressBarModule,
} from '@nebular/theme';
export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [StudyPlanCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NbAccordionModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbListModule,
    NbProgressBarModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatTooltipModule
  ],
  exports: [
    StudyPlanCardComponent
  ]
})
export class StudyPlanModule { }
