import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { Discipline } from '../../admin/discipline/discipline';
import { SchoolService } from '../../admin/school/school.service';

import { ScheduleDay } from '../../admin/study-plan/schedule-day';
import { UserService } from '../../user/user.service';
import { Video } from '../../video/video';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'app-lesson-popover',
  templateUrl: './lesson-popover.component.html',
  styleUrls: ['./lesson-popover.component.scss']
})
export class LessonPopoverComponent implements OnInit {

  @Input() public planId: number;
  @Input() public day: ScheduleDay;
  @Input() public dayIndex: number;
  @Input() public rowIndex: number;
  @Input() public teacherDisciplines: Discipline[];

  public position: string[] = [];
  public loading: boolean;

  constructor(
    private router: Router,
    private calendarService: CalendarService,
    public userService: UserService,
    public layoutService: LayoutService,
    private schoolService: SchoolService) {
  }

  ngOnInit(): void {

  }

  openDayVideos(videos: Video[], videoIndex: number) {
    this.calendarService.setCurrentDayVideos(videos);
    /* this.router.navigate(['/video/list', this.planId, this.dayIndex, videoIndex]); */
    this.router.navigate(['/video/day', this.planId, videoIndex]);
  }

  public teacherOwnerDiscipline(disciplineId: number) {
    if (this.userService.isTeacher()) {
      return this.teacherDisciplines.find(d => d.id == disciplineId) ? true : false;
    } else {
      return true;
    }
  }
}
