import { filter } from "lodash"; 
import { Injectable } from "@angular/core";
import { NotificationStore } from "./notifications.store";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
})

export class NoticationFactory{

    private API = environment.ApiUrl;
    construct(
    ){}
 
    create(type:string,notifications:any[]){
        const filtered = notifications.filter((ntf)=>{
            return ntf.type === type;
        });

        return new NotificationStore(filtered);
    }


} 