<div class="report-spinner-box" *ngIf="loadingInit">
    <mat-spinner class="report-spinner center mt-2 mb-1"></mat-spinner>
</div>


<div class="container pt-5">

    <div class="container">
    </div>
    <nb-accordion>
        <nb-accordion-item expanded>
            <nb-accordion-item-header>
                Desempenho
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <div class="row mt-2">
                    <div class="col-md text-center m-2">
                        <p>Início</p>
                        <input #inicio placeholder="Início" id="date-report-begin" class="input-group-text d-inline-block" type="text" />
                    </div>
                    <div class="col-md text-center m-2">
                        <p>Fim</p>
                        <input #fim placeholder="Fim" id="date-report-fim" class="input-group-text d-inline-block" type="text" />

                    </div>

                </div>

                <div class="row mb-4 width-90 center">
                    <div class="col-md" *ngIf="userService.isAdmin()">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Escola" id="schoolIdSel" (selectionChange)="getUnits($event.value)">
                                <mat-option [value]="0">
                                    Selecione
                                </mat-option>
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    <span matTooltipClass="custom-tooltip" matTooltip="{{school.name}}" >{{school.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)" [(ngModel)]="unit">
                                <mat-option>
                                    Selecione
                                </mat-option>
                                <mat-option  *ngFor="let unit of units" [value]="unit.id">
                                    <span matTooltipClass="custom-tooltip" matTooltip="{{unit.name}}" >{{unit.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Turma" (selectionChange)="getStudents($event.value)" [(ngModel)]="class">
                                <mat-option>
                                    Selecione
                                </mat-option>
                                <mat-option *ngFor="let class of classes" [value]="class.id">
                                    <span matTooltipClass="custom-tooltip" matTooltip="{{class.name}}" > {{class.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Aluno" (selectionChange)="getStudentPlans($event.value)" [(ngModel)]="student">
                                <mat-option>
                                    Selecione
                                </mat-option>
                                <mat-option *ngFor="let student of students" [value]="student.id">
                                    <span matTooltipClass="custom-tooltip" matTooltip="{{student.name}}" >{{student.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Planos" (selectionChange)="getPlanId($event.value)" [(ngModel)]="studyPlan">
                                <mat-option>
                                    Selecione
                                </mat-option>
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                    <span matTooltipClass="custom-tooltip" matTooltip="{{plan.name}}" >{{plan.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md" *ngIf="userService.isTeacher() || userService.isAdmin() || userService.isGeneralCoordinator() || 
                    userService.isUnitCoordinator()">
                        <mat-form-field appearance="outline" class="width-100">
                            <mat-select placeholder="Relatórios" (selectionChange)="getReportId($event.value); this.typerelat=$event.value">
                                <mat-option [value]="0">
                                    Selecione
                                </mat-option>
                                <mat-option *ngFor="let report of reports" [value]="report.id">
                                    <span matTooltipClass="custom-tooltip" matTooltip="{{report.name}}" >{{report.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md report-warnning" *ngIf="report != 0">
                        <span>{{reportDescription}}</span>
                    </div>
                    <div class="col-md text-center">
                        <style>
                            .text-branco {
                                position: relative !important;
                                top: 5px;
                                color: white !important;
                                cursor: pointer;
                            }
                        </style>
                        <a class="btn btn-primary text-branco m-2 d-inline-block" type="date" (click)="getStudentPerformanceReport()"> Atualizar </a>

                    </div>
                    <div class="col-md text-center">

                        <style>
                            .text-branco {
                                position: relative !important;
                                top: 5px;
                                color: white !important;
                                cursor: pointer;
                            }
                        </style>
                        <a class="btn btn-success text-branco m-2 d-inline-block" type="date" (click)="generateReport()"> Gerar relatórios Excel </a>
                    </div>
                </div>
                <!-- STUDENT AVERAGE CHART  -->
                <div class="row">
                    <div class="col-md-12">
                        <nb-accordion>
                            <nb-accordion-item expanded="true">
                                <nb-accordion-item-header>
                                    Desempenho &nbsp; <span *ngIf="studentData"> do Aluno(a) :
                                        {{studentData.name}}</span>
                                </nb-accordion-item-header>
                                <nb-accordion-item-body class="m-3">
                                    <app-average-chart [averageData]="filterDays"></app-average-chart>
                                </nb-accordion-item-body>
                            </nb-accordion-item>
                        </nb-accordion>
                    </div>
                </div>
                <div *ngIf="performance">

                    <!-- performance -->
                    <nb-list class="width-90 mt-3 center">

                        <!-- List header -->
                        <nb-list-item class="bg-color-primary list-header" style="color: white">
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Questões respondidas</div>
                                <div class="col-2">Acertos</div>
                                <div class="col-2">Erros</div>
                                <!--
                                <div class="col-2">Total questões</div> -->

                            </div>
                        </nb-list-item>

                        <!-- individual -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Individual
                                </div>
                                <div class="col-3">
                                    {{performance.answered.student}}
                                </div>

                                <div class="col-2">
                                    {{performance.correct.student}}
                                </div>

                                <div class="col-2">
                                    {{performance.errors.student}}
                                </div>

                                <div class="col-2">
                                    {{performance.totalPlan}}
                                </div>
                            </div>
                        </nb-list-item>

                        <!-- turma -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Turma (média)
                                </div>
                                <div class="col-3">
                                    {{performance.answered.class}}
                                </div>
                                <div class="col-2">
                                    {{performance.correct.class}}
                                </div>
                                <div class="col-2">
                                    {{performance.errors.class}}
                                </div>
                                <div class="col-2">
                                    {{performance.totalPlan}}
                                </div>
                            </div>
                        </nb-list-item>

                    </nb-list>

                    <!-- por materia  -->
                    <nb-list class="width-90 mt-3 center">

                        <!-- List header -->
                        <nb-list-item class="bg-color-primary list-header" style="color: white">
                            <div class="row">
                                <div class="col-3">Matéria</div>
                                <div class="col-2">Acertos aluno</div>
                                <div class="col-2">Acertos turma</div>
                                <div class="col-2">Erros aluno</div>
                                <div class="col-1">Erros turma</div>
                                <div class="col-2">Total questões</div>
                            </div>
                        </nb-list-item>

                        <!-- disciplina report -->
                        <nb-list-item *ngFor="let discipline of performance.disciplines">
                            <div class="row">
                                <div class="col-3">
                                    {{discipline.name}}
                                </div>
                                <div class="col-2">
                                    {{discipline.studentCorrect}}
                                </div>
                                <div class="col-2">
                                    {{discipline.classCorrect}}
                                </div>
                                <div class="col-2">
                                    {{discipline.studentError}}
                                </div>
                                <div class="col-1">
                                    {{discipline.classError}}
                                </div>
                                <div class="col-2">
                                    {{discipline.totalQuestions}}

                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>

                    <!-- forum -->
                    <nb-list class="width-90 mt-3 center">

                        <!-- List header -->
                        <nb-list-item class="bg-color-primary list-header" style="color: white">
                            <div class="row">
                                <div class="col-3">Engajamento fórum</div>
                                <div class="col-3">Tópicos</div>
                                <div class="col-3">Perguntas</div>
                                <div class="col-3">Total</div>
                            </div>
                        </nb-list-item>

                        <!-- individual -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Individual
                                </div>
                                <div class="col-3">
                                    {{performance.forum.topicsUser}}
                                </div>
                                <div class="col-3">
                                    {{performance.forum.answersUser}}
                                </div>
                                <div class="col-3">
                                    {{performance.forum.topicsClassTotal}}
                                </div>
                            </div>
                        </nb-list-item>

                        <!-- turma -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Turma (média)
                                </div>
                                <div class="col-3">
                                    {{performance.forum.topicsClass}}
                                </div>
                                <div class="col-3">
                                    {{performance.forum.answersClass}}
                                </div>
                                <div class="col-3">
                                    {{performance.forum.answersClassTotal}}
                                </div>
                            </div>
                        </nb-list-item>

                    </nb-list>

                    <!-- frequencia -->
                    <nb-list class="width-90 mt-3 center">

                        <!-- List header -->
                        <nb-list-item class="bg-color-primary list-header" style="color: white">
                            <div class="row">
                                <div class="col-3">Frequência acessos</div>
                                <div class="col-3">Acessos</div>
                                <div class="col-3">Último acesso</div>
                            </div>
                        </nb-list-item>

                        <!-- individual -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Individual
                                </div>
                                <div class="col-3">
                                    {{performance.frequency.accessesUser}}
                                </div>
                                <div class="col-3">
                                    {{performance.frequency.lastAccess }}
                                </div>

                            </div>
                        </nb-list-item>

                        <!-- turma -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Turma (média)
                                </div>
                                <div class="col-3">
                                    {{performance.frequency.accessesClass}}
                                </div>
                                <div class="col-3">
                                    ---
                                </div>
                            </div>
                        </nb-list-item>

                    </nb-list>


                    <!-- plano estudo -->
                    <nb-list class="width-90 mt-3 center">

                        <!-- List header -->
                        <nb-list-item class="bg-color-primary list-header" style="color: white">
                            <div class="row">
                                <div class="col-3">Plano de estudo</div>
                                <div class="col-3">Videos assistidos</div>
                                <div class="col-3">Exercícios respondidos</div>
                                <div class="col-3">Totais</div>
                            </div>
                        </nb-list-item>

                        <!-- individual -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Individual
                                </div>
                                <div class="col-3">
                                    {{performance.studyPlan.watchedVideosUser}}
                                </div>
                                <div class="col-3">
                                    {{performance.studyPlan.exercisesSolvedUser}}
                                </div>
                                <div class="col-3">
                                    {{performance.studyPlan.watchedVideosTotal}}
                                </div>
                            </div>
                        </nb-list-item>

                        <!-- turma -->
                        <nb-list-item>
                            <div class="row">
                                <div class="col-3">
                                    Turma (média)
                                </div>
                                <div class="col-3">
                                    {{performance.studyPlan.watchedVideosClass}}
                                </div>
                                <div class="col-3">
                                    {{performance.studyPlan.exercisesSolvedClass}}
                                </div>
                                <div class="col-3">
                                    {{performance.studyPlan.exercisesTotal}}
                                </div>
                            </div>
                        </nb-list-item>

                    </nb-list>
                </div>

            </nb-accordion-item-body>
        </nb-accordion-item>

        <nb-accordion-item *ngIf="performance">
            <!--  <nb-accordion-item-header>
                Downloads (Excel)
            </nb-accordion-item-header> -->
            <nb-accordion-item-body>
                <div class="row mt-2">
                    <div class="col-10" *ngFor="let link of linkDownload">
                        <a id="desempenho-geral" href="{{link.url}}" target="_blank" color="primary" download>
                            {{clearName(link.name)}}
                        </a>
                    </div>
                </div>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>

    <br><br><br><br><br><br><br><br>
</div>