import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PodcastComponent } from './podcast.component';
import { NbCardModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [ PodcastComponent ],
    imports: [
        CommonModule,
        NbCardModule,
        RouterModule,
        SharedModule,
        MatProgressSpinnerModule,
        MatIconModule
     ],
    exports: [],
    providers: [],
})
export class PodcastModule {}
