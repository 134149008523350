import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbCardModule, NbIconModule, NbListModule, NbSpinnerModule } from '@nebular/theme';
import { SharedModule } from 'src/app/shared/shared.module';

import { ForumModule } from '../forum/forum.module';
import { VideoService } from './video.service';
import { WatchVideoComponent } from './watch-video/watch-video.component';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SlideFileModule } from '../slide-file/slide-file.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import {MatSliderModule} from '@angular/material/slider';
import { MatNativeDateModule } from '@angular/material/core';
import { ModalVideoYoutubeComponent } from './modal-video-youtube/modal-video-youtube.component';
import { ModalVideoYoutubeMobileComponent } from './modal-video-youtube-mobile/modal-video-youtube-mobile.component';

@NgModule({
    declarations: [WatchVideoComponent, ModalVideoYoutubeComponent, ModalVideoYoutubeMobileComponent],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NbListModule,
        MatButtonModule,
        NbIconModule,
        NbCardModule,
        NbButtonModule,
        MatTooltipModule,
        RouterModule,
        NbSpinnerModule,
        ForumModule,
        MatCardModule,
        MatExpansionModule,
        MatIconModule,
        MatDividerModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        SlideFileModule,
        MatChipsModule,
        MatFormFieldModule,
        NgxYoutubePlayerModule.forRoot(),
        MatSliderModule,
        MatNativeDateModule
    ],
    exports: [],
    providers: [VideoService]
})
export class VideoModule {}
