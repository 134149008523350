import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';
import { StudyPlan } from 'src/app/modules/admin/study-plan/study-plan';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Workbook } from 'exceljs';
import { Class } from 'src/app/modules/admin/school/class';
import { Unit } from 'src/app/modules/admin/school/unit';
import { MockService } from 'src/app/modules/mock/mock.service';
import { Router } from '@angular/router';
import { PerformanceReportService } from './../performance-report.service';
import { SchoolService } from 'src/app/modules/admin/school/school.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/modules/user/user.service';
import { Component, OnInit } from '@angular/core';
import { School } from 'src/app/modules/admin/school/school';
import { Mock } from 'src/app/modules/mock/mock';
import PdfFactory from '../class/pdfFactory';
import * as fs from 'file-saver';

interface PerformanceReportTasting {
  id: number,
  studentId: number,
  studentName: string,
  studentEmail: string,
  watchClasses: string
}

interface paymentPlan {
  id: number,
  name: string;
}

@Component({
  selector: 'app-report-tasting',
  templateUrl: './report-tasting.component.html',
  styleUrls: ['./report-tasting.component.scss']
})
export class ReportTastingComponent implements OnInit {

  public displayedColumns: string[];
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public schoolId: number;
  public unit: number;
  public unitteacher: Unit;
  public classId: number;
  public planId: number;
  public mocks: Mock[];
  public loading: boolean;
  public linkDownload: any[];
  public workBook: Workbook;
  public unitTemp: number;
  public studentsToFilter: string[];
  public mocksToFilter: string[];
  public filterMock: string;
  public filterStudent: string;
  public typePerformance: string;
  public order: string = 'perf.studentName';
  public studyPlans: paymentPlan[];
  public reverse: boolean = false;
  public pdfFile: any;
  public reportTasting: PerformanceReportTasting[];
  public xlsFile: any;
  private posy: number;
  private sizePaper: number;
  private pdfFactory: PdfFactory;
  public actualData: any;
  public btnAtualizar: boolean;

  constructor(private mockService: MockService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private schoolService: SchoolService,
    private performanceService: PerformanceReportService,
    private studyPlanService: StudyPlanService,
    private router: Router) { }

  ngOnInit() {
    this.profileInit();

    this.actualData = {
      school: {},
      plan : {}
    }
  }

  profileInit() {

    this.btnAtualizar = false;
    this.posy = 10;
    this.sizePaper = 10;
    this.typePerformance = "";
    if (this.userService.isAdmin()) {
      this.getSchools();
    }
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente')
      });
  }

  getReportTasting(planId: number) {
    if (this.planId) {
      this.performanceService.performanceReportTasting(planId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.reportTasting = response.data as PerformanceReportTasting[];
            if(this.reportTasting[0]){
              this.btnAtualizar = true;
            }
            else{
              this.btnAtualizar = false;
              this.alertService.error('não existem dados para esse plano');
            }
          }
          else {
            this.btnAtualizar = false;
            this.alertService.error(response.error)
          }
        }, err => this.alertService.error("houve um erro ao carregar verifique a conexao e tente novamente"))
    }
    else {
      this.alertService.error('Selecione o plano de estudo antes');
    }
  }
  getPaymentPlans(schoolId?: number) {
    this.studyPlanService.getSelectPaymentPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.studyPlans = response.data as paymentPlan[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos de estudo. Falha na comunicação com a API'));
  }
  generateXls() {
      /* this.reportPerfresp.forEach(element => {
        element.school = this.actualData.school.name;
        element.typeSim = this.actualData.typeSim
        element.unit = this.actualData.unit.name;
        element.class = this.actualData.class.name;
        element.nameTest = this.actualData.nameTest.name;
        element.TypeRelat = this.actualData.TypeRelat;
      }); */

      /* let headerHead = ["Nome da escola" , "Tipo de avaliação" , "Unidade", "Turma" , "Nome da prova" , "Tipo de relatório"];
      
      let temp2 = [];
      temp2.push(this.actualData.school.name , this.actualData.typeSim , this.actualData.unit.name , this.actualData.class.name , this.actualData.nameTest.name,
        this.actualData.TypeRelat); */

      let workbook = new Workbook();

      let worksheet = workbook.addWorksheet("Employee Data");

        worksheet.addRow(["Nome da escola :", this.actualData.school.name]);

    
      worksheet.addRow(["Plano de pagamento :", this.actualData.plan.name]);
      
      worksheet.addRow([" "]);


      let header = [ "Id","Id aluno","Nome", "E_mail" , "Aulas Assistidas"]
      let headerRow = worksheet.addRow(header);

      for (let x1 of this.reportTasting) {
        let x2 = Object.keys(x1);
        let temp = [];
        for (let y of x2) {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
      }

      let fname = "Relatório Degustação"

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      });
    }
}
