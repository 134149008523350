<div *ngIf="!showMocksList">
    <form [formGroup]="addMockForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Simulado</h4>

        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tempo de prova" formControlName="timeLeft" [textMask]="{mask: maskService.time}" (keyup)="inputTimeLeftToSeconds()">
                </mat-form-field>
            </div>

            <div class="col-md" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Escola" formControlName="schoolId" (selectionChange)="getPlans($event.value); getUnits($event.value)">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano" formControlName="planId">
                        <!-- <mat-option value="0" (click)="addAllPlanChip()">Todos</mat-option> -->
                        <mat-option *ngFor="let plan of plans" [value]="plan.id" (click)="addPlanChip(plan)">
                            {{plan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-slide-toggle formControlName="isChecked" color="primary" class="mt-3" (click)="openStudentFields()">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Seleciona os alunos que ficarão associados a este simulado">
                        help
                    </mat-icon>
                    Selecionar Alunos
                </mat-slide-toggle>
            </div>
            <div *ngIf="selectedPlans?.length > 0" class="col-md-6">
                <mat-label class="mb-2">Planos selecionados</mat-label>
                <mat-chip-list>
                    <mat-chip *ngFor="let plan of selectedPlans" selectable="true" removable="true" (removed)="removePlanChip(plan)">
                        {{plan.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row" *ngIf="boolStudents">
            <div class="col-md-6" *ngIf="!userService.isUnitCoordinator()">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Unidade" (selectionChange)="getClasses($event.value)">
                        <!-- <mat-option value="0">Todas</mat-option> -->
                        <mat-option *ngFor="let unit of units " [value]="unit.id ">
                            {{unit.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" (selectionChange)="getStudents($event.value)">
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            {{class.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Alunos">
                        <mat-option *ngFor="let student of students" [value]="student.id" (click)="addStudentChip(student)">
                            {{student.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-chip-list *ngIf="selectedStudents">
                    <mat-chip *ngFor="let student of selectedStudents" selectable="true" removable="true" (removed)="removeStudentChip(student)">
                        {{student.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <mat-slide-toggle formControlName="isReset" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Se selecionada esta opção, o aluno poderá refazer o simulado">
                        help
                    </mat-icon>
                    Refazer Simulado
                </mat-slide-toggle>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <mat-slide-toggle formControlName="private" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Se selecionada esta opção, o simulado será bloqueado e exibido apenas a alunos específicos">
                        help
                    </mat-icon>
                    Privado
                </mat-slide-toggle>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <mat-slide-toggle formControlName="contest" (change)="contestToggle($event)" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Se selecionada esta opção, o usuário terá o resultado de seus acertos e erros apenas ao terminar o simulado. Caso contrário, verá imediatamente a resolução e o gabarito da questão submetida.">
                        help
                    </mat-icon>
                    Resultado no fim
                </mat-slide-toggle>
            </div>
            <div class="col-md-3">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Data de resultado" formControlName="resultDate" [textMask]="{mask: maskService.dateTimeMock}">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <mat-slide-toggle formControlName="notify" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Informa se o usuário verá uma notificação em seu dashboard informando quando esse simulado estiver disponível. IMPORTANTE: adicione uma data de liberação se esta opção estiver marcada">
                        help
                    </mat-icon>
                    Notificar estudante
                </mat-slide-toggle>
            </div>
            <div class="col-md-3">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Data de liberação" formControlName="initDate" [textMask]="{mask: maskService.dateTimeMock}">
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tolerância (minutos)" formControlName="tolerance" [textMask]="{mask: maskService.number}">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <mat-slide-toggle formControlName="navigateQuestions" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Permitirá ao aluno navegar entre as questões do simulado">
                        help
                    </mat-icon>
                    Navegar entre as questões
                </mat-slide-toggle>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md">
                <mat-slide-toggle formControlName="random" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Habilitar questões em ordem aleatória">
                        help
                    </mat-icon>
                    Questões aleatórias
                </mat-slide-toggle>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md">
                <mat-slide-toggle formControlName="isTest" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Se selecionada esta opção, o tipo deixará de ser simulado para ser de avaliação">
                        help
                    </mat-icon>
                    Avaliação
                </mat-slide-toggle>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md">
                <mat-slide-toggle #summationToggle (change)="setSummationFilter($event)" formControlName="summation" color="primary" class="mt-3">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Define se a prova terá questões objetivas ou somatórias">
                        help
                    </mat-icon>
                    Questões somatórias
                </mat-slide-toggle>
            </div>
        </div>

        <!-- objective questions -->
        <div class="row" *ngIf="!summationToggle.checked">
            <nb-card size="medium">
                <nb-card-header>
                    <ng-container>
                        <span class="mb-3"><b>Filtro:</b></span>
                        <mat-radio-group [(ngModel)]="filterDisciplineType" [ngModelOptions]="{standalone: true}" (change)="getDisciplines($event.value)">
                            <div class="row">
                                <div class="col-md">
                                    <mat-radio-button [value]="0">Disciplinas de vídeos</mat-radio-button>
                                </div>
                                <div class="col-md">
                                    <mat-radio-button [value]="1">Disciplinas de execício</mat-radio-button>
                                </div>
                                <div class="col-md">
                                    <mat-radio-button [value]="2">Buscar exercício por nome</mat-radio-button>
                                </div>
                                <div class="col-md">
                                    <mat-radio-button [value]="4">Buscar exercício por TAG</mat-radio-button>
                                </div>
                                <div class="col-md">
                                    <mat-radio-button [value]="3">Buscar exercício da biblioteca</mat-radio-button>
                                </div>
                            </div>
                        </mat-radio-group>
                    </ng-container>
                    <div class="row mt-3">
                        <div class="col-md" *ngIf="filterDisciplineType != 2">
                            <!-- disciplines vídeos -->
                            <mat-form-field class="width-100" *ngIf="filterDisciplineType == 0">
                                <mat-select placeholder="Disciplina de vídeo" (selectionChange)="getVideos($event.value)" [(ngModel)]="filterDisciplineId" [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                        {{discipline.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- disciplines exercises only -->
                            <mat-form-field class="width-100" *ngIf="filterDisciplineType == 1">
                                <mat-select placeholder="Disciplina de exercício" [(ngModel)]="filterDisciplineId" [ngModelOptions]="{standalone: true}" (selectionChange)="getDisciplineExercises($event.value)">
                                    <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                        {{discipline.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md" *ngIf="filterDisciplineType == 0">
                            <mat-form-field class="width-100">
                                <mat-select placeholder="Vídeos" (selectionChange)="getVideoExercises($event.value)" [(ngModel)]="filterVideoId" [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let video of videos" [value]="video.id">
                                        {{video.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md" *ngIf="filterDisciplineType == 2">
                            <div class="col">
                                <mat-form-field class="width-100">
                                    <input [(ngModel)]="filterName" [ngModelOptions]="{standalone: true}" matInput placeholder="Buscar exercício">
                                    <button (click)="getExercisesByName()" class="ml-2" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>

                            
                        </div>
                        <div class="col-md" *ngIf="filterDisciplineType == 4">
                            <div class="col">
                                <mat-form-field class="width-100">
                                    <input [(ngModel)]="filterTag" [ngModelOptions]="{standalone: true}" matInput placeholder="Buscar exercício por TAG">
                                    <button (click)="getExercisesByTag()" class="md-12" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </nb-card-header>
                <nb-card-body>
                    <mat-list *ngIf="!loading && exercises && filterDisciplineType!= 3">
                        <mat-list-item *ngFor="let exercise of exercises">
                            <div class="row width-100">
                                <div class="col pt-2">
                                    {{exercise.name}}
                                </div>
                                <div class="col-2 text-right">
                                    <button (click)="selectExercise(exercise)" mat-icon-button color="primary">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-list *ngIf="!loading && libQuestions && filterDisciplineType == 3">
                        <mat-list-item *ngFor="let question of libQuestions">
                            <div class="row width-100">
                                <div class="col pt-2">
                                    {{question.code}}
                                </div>
                                <div class="col-2 text-right">
                                    <button (click)="selectExerciseLib(question)" mat-icon-button color="primary">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
                </nb-card-body>
            </nb-card>
        </div>

        <!-- summation questions -->
        <div class="row" *ngIf="summationToggle.checked">
            <nb-card size="small" class="width-100">
                <nb-card-header>
                    <div class="row mt-3">
                        <div class="col-md-12" *ngIf="filterDisciplineType != 2">
                            <!-- disciplines exercises only -->
                            <mat-form-field class="width-100">
                                <mat-select placeholder="Disciplina de exercício" [(ngModel)]="filterDisciplineId" [ngModelOptions]="{standalone: true}" (selectionChange)="getDisciplineSummationExercises($event.value)">
                                    <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                                        {{discipline.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 mt-3">
                            <mat-form-field class="width-100">
                                <input [(ngModel)]="filterNameSummation" [ngModelOptions]="{standalone: true}" matInput placeholder="Buscar exercício por nome">
                                <button (click)="getExercisesByNameSummation()" class="ml-2" mat-icon-button color="accent" matTooltipClass="custom-tooltip" matTooltip="Buscar" matSuffix>
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                </nb-card-header>
                <nb-card-body>
                    <mat-list *ngIf="!loading && exercises">
                        <mat-list-item *ngFor="let exercise of exercises">
                            <div class="row width-100">
                                <div class="col pt-2">
                                    {{exercise.name}}
                                </div>
                                <div class="col-2 text-right">
                                    <button (click)="selectExercise(exercise)" mat-icon-button color="primary">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
                </nb-card-body>
            </nb-card>
        </div>

        <div class="row" *ngIf="!loading && exercises || editMode || selectedLibQuestions">
            <div class="col">
                <nb-card size="small">
                    <nb-card-header>
                        <h5>Selecionados</h5>
                    </nb-card-header>
                    <nb-card-body>
                        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let exercise of selectedExercises; let i = index" cdkDrag>
                                <div class="row width-100">
                                    <div class="col pt-2">
                                        {{exercise.name}} / ref: {{exercise.id}}
                                    </div>
                                    <div class="col-2 text-right">
                                        <button (click)="removeExercise(i)" mat-icon-button color="warn">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let question of selectedLibQuestions; let i = index" cdkDrag>
                                <div class="row width-100">
                                    <div class="col pt-2">
                                        {{question.code}}
                                    </div>
                                    <div class="col-2 text-right">
                                        <button (click)="removeLibExercise(i)" mat-icon-button color="warn">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </form>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Simulados
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="addMock()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
        </div>
    </div>
    <div *ngIf="editMode" class="row mt-5">
        <div *ngIf="editMode" class="text-left col-6">
            <button (click)="duplicateMock()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Duplicar</button>
        </div>

        <div *ngIf="editMode" class="text-right col-6">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </div>
</div>

<div *ngIf="showMocksList" class="">
    <div class="row">
        <div class="col-md-12 mb-2">
            <mat-form-field class="width-100">
                <input matInput placeholder="Buscar Simulados por nome" [(ngModel)]="getMockName">
                <mat-icon (click)="getMocksByName(getMockName)" class="mr-1" matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select placeholder="Escola" [(ngModel)]="schoolId" (selectionChange)="getMocks($event.value)">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="width-100" ngDefaultControl>
                <input matInput placeholder="Filtrar Simulados" [(ngModel)]="filter" (keyup)="applyFilter()">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

    </div>
    <mat-list class="width-90 mt-3 center">
        <ng-container *ngFor="let mock of mocks">
            <div mat-list-item class="row" style="min-height: 100px">
                <div class="col-8 align-middle">
                    <span *ngIf="mock.isTest" class="bg-color-accent custom-chip">Avaliação</span> {{mock.name}}
                </div>
                <div class="col-2 text-right align-middle">
                    <button (click)="editMock(mock)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-2 text-right align-middle">
                    <button size="tiny" (click)="removeMock(mock.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </ng-container>
    </mat-list>
    <div class="text-right width-100">
        <button (click)="showMocksList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>