import { Student } from './../student/student';
import { Injectable } from '@angular/core';
import { Code, EditCodes, GenerateReport, MockDisciplines} from "./external-integration/interfaces";
import { StudentCode } from '../integrations/external-identifier';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseApi } from 'src/app/core/models/response-api';
import { map, catchError } from 'rxjs/operators';
import { Payer } from '../payment/paying/payer';
import { AlertService } from 'src/app/shared/services/alert.service';
import { throwError } from 'rxjs';
import { Discipline } from '../discipline/discipline';
import { EventEmitter } from '@angular/core';
import { uniStudent } from './integration-unimestre/uniStudent';
import { IntegrationExternal } from './create-integration/integrationexternal';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  private API = environment.ApiUrl;
  public emitSaveIsDone = new EventEmitter<string>();

  constructor(
      private http: HttpClient,
      private alertService: AlertService) { }

  emitEditIsDone(){
    this.emitSaveIsDone.emit('done');
  }
  getReports(general: GenerateReport){
    return this.http.post(this.API + '/miscellaneous/getReports', general, { observe: 'response' });
  }
  saveChanges(id:number, schoolId:number, codeChange: Code){
    return this.http.post(this.API + '/miscellaneous/saveChanges', { id, schoolId, codeChange }, { observe: 'response' });
  }
  getListCodes(editCode: StudentCode){
    return this.http.post(this.API + '/miscellaneous/getListCodes', { editCode }, { observe: 'response' });
  }
  saveStudentCode(externalCode: StudentCode) {
    return this.http.post(this.API + '/miscellaneous/saveStudentCode', externalCode, { observe: 'response' });
  }
  removeCode(id: string, schoolId:number) {
    return this.http.post(this.API + '/miscellaneous/removeCode', { id, schoolId }, { observe: 'response' });
  }
  getEqualCode(code: string){
    return this.http.post(this.API + '/miscellaneous/getEqualCode', { code }, { observe: 'response' });
  }
  getLastSavedCodes(schoolId?:number) {
    return this.http.post(this.API + '/miscellaneous/getLastSavedCodes', { schoolId }, { observe: 'response' });
  }
  getDisciplinesSimulated(simulatedId: number ) {
    return this.http.post(this.API + '/miscellaneous/getDisciplinesSimulated', { simulatedId }, { observe: 'response' });
  }
  getSimulated(planId:number) {
    return this.http.post(this.API + '/miscellaneous/getSimulated', { planId }, { observe: 'response' });
  }
  getStudents(classId?: number) {
    return this.http.post(this.API + '/student/getStudents', { classId }, { observe: 'response' });
  }

  getUnitStudents(offset: number, pageSize: number) {
    return this.http.post(this.API + '/student/getUnitStudents', {offset, pageSize} , { observe: 'response' });
  }

  getUniStudentsByName(name: string) {
    return this.http.post(this.API + '/student/getUniStudentsByName', {name} , { observe: 'response' });
  }

  SaveUniStudent(uniStudent: uniStudent) {
    return this.http.post(this.API + '/student/SaveUniStudent', uniStudent , { observe: 'response' });
  }

  updateUniStudent(uniStudent: uniStudent) {
    return this.http.post(this.API + '/student/updateUniStudent', uniStudent , { observe: 'response' });
  }

  deleteUniStudent(uniId: number) {
    return this.http.post(this.API + '/student/deleteUniStudent', {uniId} , { observe: 'response' });
  }

  getStudentByEmail(email: string){
    return this.http.post(this.API + '/student/getStudentByEmail', { email }, { observe: 'response' });
  }

  getNameDiscipline(idDiscipline: number){
    return this.http.post(this.API + '/miscellaneous/getNameDiscipline', { idDiscipline }, { observe: 'response' });
  }
  getSchoolReport(schoolId: number){
    return this.http.post(this.API + '/report/getSchoolReport', { schoolId }, { observe: 'response' });
  }



  getConvStudents(classId: number, offset:number, pag:number) {

    return this.http.get(this.API + '/integrations/convergindo/'+classId,  { observe: 'response', params:{offset:offset.toString(),pag:pag.toString()} });
  }

  create(usersId: number[]){
    return this.http.post(this.API + '/integrations/convergindo',{ usersId } , { observe: 'response' });
  }

  update(id:number,user: any){
    return this.http.put(this.API + '/integrations/convergindo/'+id, { user } , { observe: 'response' });
  }

  delete(id: number){
    return this.http.delete(this.API + '/integrations/convergindo/'+id , { observe: 'response' });
  }

  /* INTEGRAÇÃO SEREN */
  createSeren(classesIds: any[]){
    return this.http.post(this.API + '/integrations/seren', { classesIds } , { observe: 'response' });
  }

  deleteSeren(id: number){
    return this.http.delete(this.API + '/integrations/seren'+id , { observe: 'response' });
  }

  openList(unitId?: number){
    return this.http.get(this.API + '/integrations/seren/list?unitId='+unitId, { observe: 'response' });
  }
  getUncreatedClasses(unitId?: number){
    return this.http.get(this.API + '/integrations/seren/uncreated?unitId='+unitId, { observe: 'response' });
  }

  /* PORTAL INTEGRAÇÃO */
  createPortal(classesIds: any[]){
    return this.http.post(this.API + '/integrations/portal', { classesIds } , { observe: 'response' });
  }

  deletePortal(id: number){
    return this.http.delete(this.API + '/integrations/portal'+id , { observe: 'response' });
  }
  openListPortal(unitId?: number){
    return this.http.get(this.API + '/integrations/portal/list?unitId='+unitId, { observe: 'response' });
  }
  getUncreatedClassesPortal(unitId?: number){
    return this.http.get(this.API + '/integrations/portal/uncreated?unitId='+unitId, { observe: 'response' });
  }

  createIntegration(integration: IntegrationExternal){
    return this.http.post(this.API + '/integrations/createexternal', integration , { observe: 'response' });
  }

  getIntegrations(schoolId:number){
    return this.http.get(this.API + '/integrations/getExternal/'+schoolId,{ observe: 'response' });
  }

  deleteIntegration(integrationId:number){
    return this.http.post(this.API + '/integrations/deleteIntegration', {integrationId},{ observe: 'response' });
  }

  getStudentExternal(schoolId:number , classId:number){
    return this.http.get(this.API + '/integrations/getStudentExternal/'+schoolId+'/'+classId,{ observe: 'response' });
  }
}
