import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { TopicDisciplineService } from '../../topic-discipline.service';

@Component({
  selector: 'app-disciplines-topic',
  templateUrl: './disciplines-topic.component.html',
  styleUrls: ['./disciplines-topic.component.scss']
})
export class DisciplinesTopicComponent implements OnInit {


  public planId: number;
  public loading: boolean;
  public disciplines: any[];
  public planName: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutService,
    public userService: UserService,
    public topicDisciplineService: TopicDisciplineService,
    private alertService: AlertService
  ) { }

  ngOnInit() {    
    let p = Object(this.route.snapshot.queryParams);
    this.planName = p.planName;
    this.planId = Number(this.route.snapshot.paramMap.get('planId'));
    this.getDisciplinesByPlan(this.planId, Number(this.userService.getUserId()));    
  }

  backPage(){
    this.router.navigateByUrl('topic/plans');
  }

  selectDiscipline(disciplineId: number, disciplineName:string){
    this.router.navigateByUrl('topic/plan/' + this.planId +'/discipline/' + disciplineId + '/topics'+'?planName='+this.planName+'&disciplineName='+disciplineName);
  }

  getDisciplinesByPlan(planId:number, userId: number) {
    this.loading = true;
    this.topicDisciplineService.getDisciplinesByPlan(userId, planId)
      .subscribe(res => {
        const response = res.body as any;
        if (!response.error) { this.disciplines= response.data as any[];;
        } else { this.alertService.error(response.error); }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }
}