import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { SchoolService } from '../../school/school.service';
import { SelectPlanComponent } from '../../shared-components/select-plan/select-plan.component';
import { SyncService } from '../sync.service';
import { EditThemeComponent } from './edit-theme/edit-theme.component';

import { MainTheme, MotivacionalText, ThemeText, SelectedPlansResp, Edital } from './theme';

@Component({
  selector: 'app-create-theme',
  templateUrl: './create-theme.component.html',
  styleUrls: ['./create-theme.component.scss']
})
export class CreateThemeComponent implements OnInit {

  public createThemeForm: FormGroup;
  public typeTheme: { name: string, type: number }[] = [{ name: 'Enem', type: 1 }, { name: 'Vestibular', type: 2 }, { name: 'Concurso', type: 3 }, { name: 'UFSC', type: 4 },
  { name: 'UFPR', type: 5 }, { name: 'UFRGS', type: 6 }, { name: 'UDESC', type: 7 }, { name: 'ACAFE', type: 8 }, { name: 'FUVEST', type: 9 }, { name: 'UNICAMP', type: 10 }];
  public editals : {id:number , type_id:number , name:string , description:string}[] = [    {
    "id": 69,
    "type_id": 2,
    "name": "UFPR 2021",
    "description": null
},
{
    "id": 256,
    "type_id": 2,
    "name": "FUVEST",
    "description": null
},
{
    "id": 257,
    "type_id": 2,
    "name": "UFSC",
    "description": null
},
{
    "id": 477,
    "type_id": 2,
    "name": "ACAFE",
    "description": null
},
{
    "id": 479,
    "type_id": 2,
    "name": "UDESC",
    "description": null
},
{
    "id": 505,
    "type_id": 2,
    "name": "UFRGS",
    "description": null
},
{
    "id": 747,
    "type_id": 2,
    "name": "UFPR 2013",
    "description": null
},
{
    "id": 800,
    "type_id": 2,
    "name": "UFPR 2019",
    "description": null
},
{
    "id": 833,
    "type_id": 2,
    "name": "Unicamp",
    "description": null
},
{
    "id": 859,
    "type_id": 2,
    "name": "UFPR - RESUMO",
    "description": null
}];
  public loading: boolean;
  public showThemeList: boolean;
  public editMode: boolean;
  public themes: MainTheme[];
  public motivacionalText: string[];
  public alertExternal: number;
  public selectedPlans: SelectedPlansResp[];
  public schoolId: number;

  constructor(
    @Optional() public dialogRef: MatDialogRef<EditThemeComponent>,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private adminService: AdminService,
    private syncService: SyncService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.selectedPlans = [];
    this.alertExternal = 0;
    this.showThemeList = false;
    this.editMode = false;
    this.createThemeForm = this.formBuilder.group({
      id: [''],
      name: [''],
      tag: [''],
      externalId: [''],
      motivacionalText: [''],
      type: [''],
      order: [''],
      editalId:[]
    });
    this.schoolId = Number(this.userService.getUserSchool());

    this.getDbEditals();
  }

  openList() {
    this.getThemes();
    this.showThemeList = true;
  }

  getThemes() {
    this.adminService.getThemes()
      .subscribe(res => {
        const response = res.body as ResponseApi

        if (!response.error) {
          this.themes = response.data as MainTheme[];
          this.themes.forEach(e => {
            switch (e.type) {
              case 1:
                e.typeName = "Enem";
                break;
              case 2:
                e.typeName = "Vestibular";
                break
              case 3:
                e.typeName = "Concurso";
                break;
              case 4:
                e.typeName = "UFSC";
                break;
              case 5:
                e.typeName = "UFPR";
                break;
              case 6:
                e.typeName = "UFRGS";
                break;
              case 7:
                e.typeName = "UDESC";
                break;
              case 8:
                e.typeName = "ACAFE";
                break;
              case 9:
                e.typeName = "FUVEST";
                break;
              case 10:
                e.typeName = "UNICAMP";
                break;
            }
          });
        }
        else {
          this.alertService.error('nao foi possivel carregar os dados');
        }
      }, err => {
        this.alertService.error('Houve um erro ao criar o tema. Verifique a conexão e tente novamente');
      })
  }

  createTheme() {
    if (this.createThemeForm.get('order').value > 100) {
      this.createThemeForm.get('order').setValue(0);
    }

    this.loading = true;
    const theme = this.createThemeForm.getRawValue() as MainTheme;
    //theme.description = this.motivacionalText;
    this.adminService.createTheme(theme)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          const id = response.data as any;
          if (id && id > 0 && this.selectedPlans.length > 0) {
            this.associateThemeToPlan(id, this.selectedPlans);
          }
          this.alertService.success("Dados cadastrados com sucesso!");
          this.createThemeForm.reset();
          this.loading = false;
        } else {
          this.alertService.error(response.error);
          this.loading = false;
        }
      }, err => {
        this.alertService.error('Houve um erro ao criar o tema. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  associateThemeToPlan(themeId: number, selectedPlans: SelectedPlansResp[]) {
    if (this.selectedPlans.length > 0) {
      this.syncService.associateThemeToPlan(themeId, selectedPlans, this.schoolId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            this.selectedPlans = [];
          } else {
            this.alertService.error(response.error);
          }
          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao criar o tema com os planos. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
  }

  editTheme(theme: MainTheme) {
    this.adminService.getDbThemeText(theme.id)
      .subscribe(res => {
        const response = res.body as ResponseApi
        if (!response.error) {
          theme.motivacionalText = response.data as MotivacionalText[];
          const dialogRef = this.dialog.open(EditThemeComponent, {
            minWidth: '60vw',
            data: theme
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.getThemes();
            }
          });
        } else {
          this.alertService.error(response.error);
        }
      });
  }

  getDbEditals(){
    this.syncService.getDbEditals()
    .subscribe(res => {
      const response = res.body as ResponseApi

      if(!response.error){
        let dbedital = response.data as Edital[];

        dbedital.forEach(dbEd => {
          dbEd.id = dbEd.type_id;
          dbEd.type_id = dbEd.essayThemeTypeId;
          this.editals.push(dbEd);
        });

        //console.log(this.editals);
      }
    })
  }

  getThemeText(externalId: number) {
    if (externalId) {
      this.adminService.getThemeText(externalId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            let data = response.data as ThemeText;
            this.createThemeForm.get('name').setValue(data.title);
            this.createThemeForm.get('type').setValue(data.correction_type.id);
            data.motivational_texts.forEach(value => {

              value.motivacional_text = value.motivacional_text.replaceAll("src=\"/themes/", 'src="https://ia.redacaonline.com.br/themes/');

            });
            this.createThemeForm.get('motivacionalText').setValue(data.motivational_texts);
            this.alertExternal = 2;

          }
          else {
            this.alertService.error('erro');
            this.alertExternal = 1;
          }
        })
    }
    else {
      this.alertService.error('precisa ser prenchido o id externo para atualizar!');
    }
  }

  backButton() {
    this.showThemeList = false;
  }

  deleteTheme(themeId: number) {
    this.adminService.deleteTheme(themeId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('dados deletados com sucesso!');
          this.getThemes();
        }
        else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao criar o tema. Verifique a conexão e tente novamente');
      })
  }

  openSelectPlans() {
    const dialogRef = this.dialog.open(SelectPlanComponent, {
      minWidth: '60vw',
      data: []
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
        const index = this.selectedPlans.indexOf(result);
        if (index < 0) {
          result.forEach(element => {
            let planSelected = { id: element.id, name: element.name };
            this.selectedPlans.push(planSelected);
          });
        }
      }
    });
  }
  removePlanChip(plan: SelectedPlansResp) {
    const index = this.selectedPlans.indexOf(plan);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }

}
