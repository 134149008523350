import { AnnexVideoModel } from "./AnnexVideo.model";
import { ExercisesVideoModel } from "./ExercisesVideo.model";
import { IframeVideoModel } from "./IframeVideo.model"; 

interface VideoInterface{
    id:number,
    disciplineId:number,
    order:number,
    tag:string,
    name:string ,
    desc:string,
    link:string ,
    type:string ,
    duration:string ,
    embed_chat :string ,
    streaming :string ,
    isRecord:boolean,
    active:boolean,
    youtubeMask :boolean,
    exercises?:  ExercisesVideoModel[],
    iframes?:     IframeVideoModel[],
    annexs?:      AnnexVideoModel[] 
}

export class VideoDisciplineModel {
    constructor(private video:VideoInterface){
        this.video.exercises = [];
        this.video.iframes = [];
        this.video.annexs = [];
    }

   
    public getObejct(){
        return this.video;
    }

    public addExercises(  exercises:any[]){

        this.video.exercises =  exercises.map(e=>{
            return new ExercisesVideoModel(e);
        });
    }
    public addIframes(iframes:any[]){

        this.video.iframes =  iframes.map(i=>{
            return new IframeVideoModel(i);
        });
    }
    public addAnnex(annexs:any[]){

        this.video.annexs =  annexs.map(a=>{
            return new AnnexVideoModel(a);
        });
    }
    public getName(){
       
        return this.video.name;
    }
 
    public setName(name:string){
        return this.video.name = name;
    }
    public getId(){
        return this.video.id;
    }
}