import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
    templateUrl: './manage-school.component.html',
})
export class ManageSchoolComponent implements OnInit {

    constructor(public userService: UserService) { }

    ngOnInit(): void { }
}
