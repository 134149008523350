<div>
    <mat-card class="center mat-elevation-z8" [ngClass]="{'not-grow': studyPlan.expired}">
        <mat-card-header></mat-card-header>
        <div class="head-card" [ngClass]="{'disabled-plan': studyPlan.expired, 'bg-color-primary': !studyPlan.expired, 'bg-color-accent': studyPlan.version == 1 && !userService.isStudent()}">
            <ng-lottie class="mr-2" [options]="lottieOptions"></ng-lottie>
        </div>
        <mat-card-content class="bg-color-primary pb-4" [ngClass]="{'disabled-plan': studyPlan.expired, 'bg-color-primary': !studyPlan.expired, 'bg-color-accent': studyPlan.version == 1 && !userService.isStudent()}">
            <div class="plan-name mb-2" [matTooltip]="studyPlan.name">
                <div class="m-3 text-center">
                    <span *ngIf="loading" class="center color-spinner">Atualizando...</span>
                </div>

                <ng-container *ngIf="studyPlan.name.length <= 15">
                    {{studyPlan.name}}
                </ng-container>
                <ng-container *ngIf="studyPlan.name.length > 15">
                    {{studyPlan.name | slice:0:16}}...
                </ng-container>
            </div>
            <div class="plan-expire">
                <ng-container *ngIf="userService.isStudent()">
                    <ng-container *ngIf="!studyPlan.expired">
                        Acesso até {{studyPlan.expireDate | date}}
                    </ng-container>
                    <ng-container *ngIf="studyPlan.expired">
                        Expirou em {{studyPlan.expireDate | date}}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!userService.isStudent()">
                    <ng-container *ngIf="!studyPlan.expireMonths">
                        Acesso até {{studyPlan.expireDate | date}}
                    </ng-container>
                    <ng-container *ngIf="studyPlan.expireMonths">
                        Acesso por {{studyPlan.expireMonths}} meses
                    </ng-container>
                </ng-container>
            </div>
            <div>
                <div>
                    <button *ngIf="!studyPlan.expired && (studyPlan.version == 2 || userService.isStudent())" mat-mini-fab class="hvr-grow open-calendar-button" matTooltipClass="custom-tooltip" matTooltip="Visualizar calendário" (click)="viewCalendar(studyPlan)" class="btnview">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </div>
                <div>
                    <button *ngIf="!studyPlan.expired && (studyPlan.version == 2 || userService.isStudent())" mat-mini-fab class="hvr-grow open-calendar-button" matTooltipClass="custom-tooltip" matTooltip="Abrir calendário" (click)="selectPlan(studyPlan)">
                        <mat-icon>event</mat-icon>
                    </button>
                </div>
            </div>
           <!--  <div class="btn-reload-plan">
                <button *ngIf="!studyPlan.expired && (studyPlan.version == 2 || userService.isStudent())" mat-mini-fab class="hvr-grow open-calendar-button " matTooltipClass="custom-tooltip" matTooltip="Atualizar calendário" (click)="syncPlan(studyPlan)">
                    <mat-icon>sync</mat-icon> 
                </button>
            </div> -->
            <button *ngIf="!studyPlan.expired && studyPlan.version == 1 && !userService.isStudent()" mat-mini-fab class="open-calendar-button" matTooltipClass="custom-tooltip" matTooltip="Não é possível editar calendários da versão antiga!">
                <mat-icon>block</mat-icon>
            </button>
            <button *ngIf="studyPlan.expired" mat-mini-fab class="open-calendar-button" matTooltipClass="custom-tooltip" matTooltip="Plano expirado">
                <mat-icon>hourglass_disabled</mat-icon>
            </button>
        </mat-card-content>
    </mat-card>

</div>
<!-- <mat-spinner *ngIf="loading" class="center"></mat-spinner> -->