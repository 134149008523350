import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
    templateUrl: './redaction-write-dialog.component.html',
    styleUrls: ['./redaction-write-dialog.component.scss']
})
export class RedactionWriteComponent implements OnInit {

    constructor(
        public dialogRef: NbDialogRef<RedactionWriteComponent>,
        public layoutService: LayoutService) { }

    text: string;

    ngOnInit(): void { }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(this.text);
    }
}
