import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../admin.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { Class } from '../class';
import { School } from '../school';
import { SchoolService } from '../school.service';
import { Unit } from '../unit';
import { EditClassComponent } from './edit-class/edit-class.component';


@Component({
    selector: 'app-create-class',
    templateUrl: './create-class.component.html',
    styleUrls: ['./create-class.component.scss']
})
export class CreateClassComponent implements OnInit, OnDestroy {

    public createClassForm: FormGroup;
    public removeClassForm: FormGroup;
    public showClassesList: boolean;
    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public plans: StudyPlan[];
    public selectedPlans: StudyPlan[];
    public editMode: boolean = false;
    public unitId: number;
    public loading: boolean;
    private admUpdate$: Subscription;
    private admUpdateRemove$: Subscription;
    public deleting: boolean;
    public remove: boolean = false; 
    public selectedscholl : number;

    constructor( 
        @Optional() @Inject(MAT_DIALOG_DATA) public classe: Class,       
        @Optional() public dialogRef: MatDialogRef<EditClassComponent>,
        private dialog: MatDialog,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formbBuilder: FormBuilder,
        private studyPlanService: StudyPlanService,
        private adminService: AdminService,
        public userService: UserService) { }

    ngOnInit(): void { 
        this.admUpdate$ = this.adminService.getUpdateSubject()
        .subscribe(() => this.initEdit()); 
        if(this.userService.isUnitCoordinator()){
            this.getUnitPlans(this.userService.getUserUnit());
        }
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    initEdit() {
        this.selectedPlans = [];
       
        this.createClassForm = this.formbBuilder.group({
            id: [''],
            name: ['', Validators.required],
            unit: ['', Validators.required],
            school: ['', Validators.required],
            plans: ['']
        });
        
        
        if (this.userService.isAdmin()) {
            this.getSchools();
            
        } else if (this.userService.isGeneralCoordinator()) {
            this.coordinatorInit();
            this.getPlans(this.userService.getUserSchool());
        } 


        if (this.classe ) { this.initializeEditMode(); } 
    }
     
    initializeEditMode() {
        const classUnit = this.classe.unit as Unit;
        
        
        this.remove = true;
        this.createClassForm.patchValue(this.classe);
        const schoolId = this.classe.school as number;
        this.createClassForm.get('school').setValue(schoolId);
        this.createClassForm.get('unit').setValue(classUnit.id);
        this.getUnits(schoolId);
        this.getClassPlans(this.classe.id);
    }
    
    createClass() {
        const plansIds = [];
        this.selectedPlans.map(p => plansIds.push(p.id));
        this.createClassForm.get('plans').setValue(plansIds);

        if (this.userService.isUnitCoordinator()){
            this.createClassForm.get('unit').setValue(this.userService.getUserUnit());
            this.createClassForm.get('school').setValue(this.userService.getUserSchool());
        }

        const c = this.createClassForm.getRawValue() as Class;

        if (plansIds.length < 1 || this.createClassForm.invalid) {
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }

        this.loading = true;

        this.schoolService.createClass(c)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Turma cadastrada', 3);
                    this.createClassForm.reset();
                    this.adminService.updateChields();
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao criar a turma. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    editClass(classe: Class) {
        this.editMode = true;
        const dialogRef = this.dialog.open(EditClassComponent, {
            minWidth: '60vw',
            data: {
                'classe':classe, 
                'schools': this.schools,
                'units': this.units,
                'classes': this.classes,
                'editMode':true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.editMode = false;
            this.getClasses(this.unitId); 
            if (result) {
                this.classe = null;
                
            }
        });
    }
    
    
    deleteClass(classe: Class) {
        this.remove = true;
        this.schoolService.getClasses(classe.id);
        const dialogRef = this.dialog.open(EditClassComponent, {
            minWidth: '60vw',
            data: { 
                'classe':classe, 
                'schools': this.schools,
                'units': this.units,
                'classes': this.classes,
                'editMode':false

            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.remove = false;   
            this.getClasses(this.unitId);
            if (result) { 
             
            }
        });
    } 

    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            this.createClassForm.get('school').setValue(this.userService.getUserSchool());
            this.getUnits(this.userService.getUserSchool());
        }
    }

    addPlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index < 0) {
            this.selectedPlans.push(plan);
            this.createClassForm.get('plans').reset();
        }
    }

    removePlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index >= 0) {
            this.selectedPlans.splice(index, 1);
        }
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }


    getClasses(unitId: number) {
        this.loading = true;
        this.unitId = unitId;
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getPlans(schoolId?: number) {
        this.studyPlanService.getSelectPlans(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId: number) {
        /* if(this.userService.isAdmin()){
            this.getPlans(schoolId);
        } */

        this.selectedscholl = schoolId;

        this.getPlans(schoolId);
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];

                    // select current unit after load units
                    if (this.editMode) {
                        const unit = this.classe.unit as Unit;
                        this.createClassForm.get('unit').setValue(unit.id);
                    }
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getUnitPlans(unitId: number) {
        this.studyPlanService.getUnitPlans(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                } else {
                    this.plans = [];
                    this.alertService.error('Não existem planos cadastrados em sua unidade.');
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos da sua unidade. Verifique a conexão e tente novamente'));
    }

    openList() {
        // this.getClasses();
        if (this.userService.isUnitCoordinator()) {
            this.getClasses(this.userService.getUserUnit());
        } else {
            this.classes = [];
        }
        this.showClassesList = true;
    }

    duplicateClass() {
        const plansIds = [];
        this.selectedPlans.map(p => plansIds.push(p.id));
        this.createClassForm.get('plans').setValue(plansIds);
        const c = this.createClassForm.getRawValue() as Class;
        c.name = c.name + " (Duplicado)";
        if (plansIds.length < 1 || this.createClassForm.invalid) {
            this.alertService.error('Preencha todos os campos corretamente');
            return;
        }

        this.loading = true;

        this.schoolService.createClass(c)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Turma duplicada', 3);
                    this.createClassForm.reset();
                    this.dialogRef.close(true);
                    this.getClasses(this.unitId);
                    this.adminService.updateChields();
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao duplicar a turma. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }
 
    removeClass(classId: number) {
        if (confirm('Deseja realmente remover a Turma?')) {
            this.schoolService.removeClass(classId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Turma removida', 3);
                        this.getClasses(this.unitId);
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover a turma. Verifique a conexão e tente novamente'));
        }
    }

    disableClass(classId: number) {
        if (confirm('Deseja DESATIVAR a Turma?')) {
            this.schoolService.disableClass(classId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Turma Desativada', 3);
                        this.getClasses(this.unitId);
                        this.adminService.updateChields();
                        this.classe.active = 0;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao desativar a turma. Verifique a conexão e tente novamente'));
        }
    }
    enableClass(classId: number) {
        if (confirm('Deseja DESATIVAR a Turma?')) {
            this.schoolService.enableClass(classId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Turma Desativada', 3);
                        this.getClasses(this.unitId);
                        this.adminService.updateChields();
                        this.classe.active = 1;
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao desativar a turma. Verifique a conexão e tente novamente'));
        }
    }

    // only edit mode methods

   
    getClassPlans(classId: number) {
        this.studyPlanService.getClassPlans(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.selectedPlans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }

    saveChanges() {
        if (this.createClassForm.valid) {

            this.loading = true;
            const plansIds = [];
            this.selectedPlans.map(p => plansIds.push(p.id));
            this.createClassForm.get('plans').setValue(plansIds);
            this.createClassForm.get('id').setValue(this.classe.id);
            const classe = this.createClassForm.getRawValue() as Class;
            this.schoolService.saveChanges(classe)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Turma editada com sucesso', 3);
                        this.dialogRef.close(true);
                        this.getClasses(this.unitId);
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao salvar as alterações da turma. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente!');
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    
    
}