import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { ResponseApi } from '../../models/response-api';
import { ThemeService } from '../../themes/theme.service';
import { Theme } from '../../themes/theme';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UserService } from 'src/app/modules/user/user.service';
import { OctadeskService } from '../../services/octadesk.service';
import { ConfigServiceService } from 'src/app/modules/admin/my-configuration/config-service.service';
import { ModalInit } from 'src/app/modules/admin/my-configuration/modal-init/modal-init';

@Component({
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SignInComponent implements OnInit {
    public signinForm: FormGroup;
    public theme: Theme;
    public modal: ModalInit;
    public loading: boolean;
    public hide = true;
    public showFooter: boolean = false;
    public showAcreFooter: boolean = false;
    public showCeilandiaFooter: boolean = false;
    @ViewChild('formEl') formEl: ElementRef;

    constructor(
        private router: Router,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private octadeskService: OctadeskService,
        public userService: UserService,
        private themeService: ThemeService,
        private configurationService: ConfigServiceService,
        public titleService: Title,
        @Inject(DOCUMENT) private document: HTMLDocument) { }

    ngOnInit(): void {
        this.getTheme();
        this.checkSubdomain();
        this.signinForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    checkSubdomain() {
        const hostname = window.location.hostname;
        const subdomain = 'jedis';
        const subdomainAcre = 'acrejedis';
        const subdomainCeilandia = 'ceilandiajedis';


        this.showFooter = hostname.includes(subdomain);
        this.showAcreFooter = hostname.includes(subdomainAcre);
        this.showCeilandiaFooter = hostname.includes(subdomainCeilandia);

    }

    getTheme() {
        const domain = window.location.hostname;
        this.themeService.getTheme(domain)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                  this.theme = response.data as Theme;

                  if(this.theme.forceGoTo !== null && this.theme.forceGoTo !== domain ){
                    this.document.location.href = 'http://'+this.theme.forceGoTo;
                  }

                  this.themeService.setThemeData(this.theme);
                  this.titleService.setTitle(this.theme.name);
                  if (this.theme.favicon) {
                      this.document.getElementById('appFavicon').setAttribute('href', this.theme.favicon);
                  }
              } else {
                  this.alertService.error(response.error);
              }

            }, err => this.alertService.error('Houve um erro ao carregar o tema. Verifique a conexão e tente novamente'));
    }

    login() {
        const email = this.signinForm.get('email').value;
        const password = this.signinForm.get('password').value;

        this.authService.authenticate(email, password).subscribe(
            res => {
                const response = res.body as ResponseApi;
                if (!response.error) {
                    this.loading = false;
                    this.octadeskService.startOctadesk();
                    
                    const route = response.data as any;
              
                    if(route.defaultRoute && route.defaultRoute !== ""){
                   
                        this.router.navigate([route.defaultRoute]);
                    }else if(this.userService.isAdmin() || this.userService.isGeneralCoordinator()){
                        this.router.navigate(['admin/dashboard']);
                    }else{
                 
                        this.router.navigate(['dashboard']);
                    }
                    
                } else {
                    this.alertService.error(response.error);
                    // nono animation
                    this.formEl.nativeElement.classList.add('validate-error');
                    setTimeout(() => {
                        this.formEl.nativeElement.classList.remove('validate-error');
                    }, 1000);
                }
                this.loading = false;
            },
            err => {
                this.alertService.error('Os dados não puderam ser verificados, tente novamente ou contate o suporte!');
                this.loading = false;
            }
        );
    }

    getUserModal(userDomain: string){
        this.configurationService.getUserModal(userDomain)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if(!response.error){
           this.modal = response.data as ModalInit;
           if(this.modal.openAfterLogin){
           this.openDialogVideoNewLayout(this.modal);
           }
          }
        }, err => {
          //console.log(err);
    
        });
      }

    loginDelay() {
        this.loading = true;
        const awaitTime = (Math.floor(Math.random() * 3) + 1) * 1000; // 0 to 7 seconds;
        setTimeout(() => {
            this.login();
        }, awaitTime);
    }

    goToRecovery() {
        this.router.navigate(['/signin/recovery']);
    }

    getHeaderBackgroundImg() {
        console.log(this.theme); 
        if (this.theme.showHeaderImg && this.theme.headerImg) {
            return this.theme.headerImg;
        } else if (this.theme.showHeaderImg && !this.theme.headerImg) {
            return `https://sistema.notamaxima.com.br/assets/images/header_t.png`;
        } else {
            return null;
        }
    }

    openLink(link: string) {
        window.open(link, '_blank');
    }

    openDialogVideoNewLayout(modal: ModalInit) {

        let width = 550;
        let height = (width * 0.56);
    
        if (window.outerWidth > 2000 ) {
          width = 650;
          height = (width * 0.56);
    
        }
        if (window.outerWidth < 1200 && window.outerWidth >= 850 ) {
          width = 550;
          height = (width * 0.56);
    
        }else  if ( window.outerWidth < 850 ) {
          width = (window.outerWidth * 0.70);
          height = (width * 0.56);
        }
    }
}
