import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherViewPlansComponent } from './teacher-view-plans/teacher-view-plans.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NbAccordionModule, NbListModule } from '@nebular/theme';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [TeacherViewPlansComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    NbAccordionModule,
    NbListModule,
    MatIconModule
  ]
})
export class TeacherViewPlansModule { }
