import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { routes } from '../../../admin.routing';
import { AdminService } from '../../../admin.service';
import { School } from '../../school';
import { SchoolService } from '../../school.service';


interface RouteMain {
  id: number;
  descr: string;
  icon: string;
  link: string;
  order: number;
  schools: { id: number, name: string }[];
}


@Component({
  selector: 'app-edit-route',
  templateUrl: './edit-route.component.html',
  styleUrls: ['./edit-route.component.scss']
})
export class EditRouteComponent implements OnInit, OnDestroy {

  public icons: string[];
  public orderroots: number[];
  public editRouteForm: FormGroup;
  public showSchoolList: boolean;
  public schools: School[];
  public selectedschools: { id: number, name: string }[];
  public schoolchip: { id: number, route: string }[];
  public routes: { id?: number, route?: string, icon?: string, link?: string, order?: number, escolas?: [] }[];
  public routeget: RouteMain;
  public teste: string[] = [];
  public editMode: boolean;
  public loading: boolean;
  private admUpdate$: Subscription;
  public converter: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public route: RouteMain,
    @Optional() public dialogRef: MatDialogRef<EditRouteComponent>,
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private alertService: AlertService,
    private userService : UserService,
    private formBuilder: FormBuilder,
    private adminService: AdminService) {

  }

  ngOnInit() {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.editRouteForm = this.formBuilder.group({
      id: [''],
      descr: ['', Validators.required],
      icon: [''],
      order: [''],
      schools: [''],
      link: ['', Validators.required],
    });
    this.schoolService.getRoute(this.route.id).subscribe(res => {
      const response = res.body as ResponseApi;
      this.routeget = response.data as RouteMain;
      this.editRouteForm.patchValue(this.routeget);
      this.converter = this.routeget.icon.toString();
      this.editRouteForm.get('icon').setValue(this.converter);
      this.routeget?.schools?.forEach((school) => this.selectedschools.push(school));

      if (!response.error) {
        //console.log(response + " resposta da api");
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar as rotas. Verifique a conexão e tente novamente'));

    this.routes = [];
    this.schools = [];
    this.schoolchip = [];
    this.icons = [];
    this.orderroots = [];
    this.selectedschools = [];
    this.setOrder();
    this.setIcons();
    this.getSchools();
  }

  profileInit(){

  }

  getRoutes(id?: number) {
    this.adminService.getRoutes().subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
        this.routes = response.data as RouteMain[];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao carregar as rotas. Verifique a conexão e tente novamente'));
  }

  setOrder() {
    for (let i = 0; i < 20; i++) {
      this.orderroots[i] = i;
    }
  }

  setIcons() {
    /* dashboard
    video_library
    edit
    visibility
    add_circle_outline
    calculate
    home
    group_add
    face
    archive
    ondemand_video
    archive
    poll
    payment
    archive
    rule
    archives
    video_library
    ballot
    library_music
    create
    source
    poll
    library_music
    forum
    payments
    shopping_bag
    science
    credit_card
    groups
    help_outline
    help_outline
     */

    this.icons[0] = "dashboard";
    this.icons[1] = "video_library";
    this.icons[2] = "edit";
    this.icons[3] = "visibility"
    this.icons[4] = "add_circle_outline";
    this.icons[5] = "calculate";
    this.icons[6] = "home";
    this.icons[7] = "group_add";
    this.icons[8] = "face";
    this.icons[9] = "archive";
    this.icons[10] = "ondemand_video";
    this.icons[11] = "poll";
    this.icons[12] = "payment";
    this.icons[13] = "rule";
    this.icons[14] = "archives";
    this.icons[15] = "ballot";
    this.icons[16] = "library_music";
    this.icons[17] = "create";
    this.icons[18] = "source";
    this.icons[19] = "forum";
    this.icons[20] = "payments";
    this.icons[21] = "shopping_bag";
    this.icons[22] = "science";
    this.icons[23] = "credit_card";
    this.icons[24] = "groups";
    this.icons[25] = "help_outline";

  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  addSchollChip(schoolId: number[]) {
    if (schoolId.length === 0 ) {
      this.selectedschools = [];
    } else {

      this.selectedschools = [];

      schoolId.forEach(schoolId => {
        for (const school of this.schools) {
          if (school.id == schoolId) {
              this.selectedschools.push(school);

            }
          }
      });

      console.log(this.selectedschools);
    }

    let chipsToRemove = this.schools.filter( sf => !this.selectedschools.includes(sf) );

    if(chipsToRemove.length > 0) {
      chipsToRemove.forEach( (school) => this.removeSChoollChip(school) );
    };
  }

  removeSChoollChip(sch: { id: number, name: string }) {
    const index = this.selectedschools.indexOf(sch);

    if (index >= 0) {
      this.selectedschools.splice(index, 1);
    }
  }

  saveRouteChanges() {
    if (this.editRouteForm.valid) {

      this.loading = true;
      const schoolsid = [];
      this.selectedschools.map(s => schoolsid.push(s.id));
      this.editRouteForm.get('schools').setValue(schoolsid);
      const routeedit = this.editRouteForm.getRawValue();
      this.schoolService.saveRouteChanges(routeedit)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('rota editada com sucesso', 3);
            this.adminService.updateChields();
            this.dialogRef.close(true);
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar a edição. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    } else {
      this.alertService.error('Preencha todos os campos corretamente!');
    }
  }

}
