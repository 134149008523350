<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-12 mt-2">
            <ng-container>
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">PERFIL</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Editar perfil</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Editar perfil</h6>
                        <p class="fs-13 pb-0">Informações Pessoais</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-md-4 mb-3 order-md-1 order-sm-2 order-xs-2">
            <form class="mt-3" [formGroup]="profileForm">
                <h3 *ngIf="isBottomSheet">Editar Perfil</h3>
                <div class="mb-3">
                    <mat-form-field class="width-100">
                        <mat-label>Nome</mat-label>
                        <input #name formControlName="name" type="text" matInput>
                    </mat-form-field>
                </div>
                <div class="mb-3">
                    <mat-form-field class="width-100">
                        <mat-label>E-mail</mat-label>
                        <input formControlName="email" type="email" matInput>
                    </mat-form-field>
                </div>
                <div class="mb-3">
                    <mat-form-field class="width-100">
                        <mat-label>Nova Senha</mat-label>
                        <input formControlName="pass" type="password" matInput>
                    </mat-form-field>
                </div>
                <div class="mb-3">
                    <mat-form-field class="width-100">
                        <mat-label>CPF</mat-label>
                        <input formControlName="cpf" [textMask]="{mask: maskService.cpf}" type="text" matInput>
                    </mat-form-field>
                </div>
                <!-- hide -->
                <div class="invisible">
                    <input #imageUpload (change)="handleFileInput($event.target.files);" accept="image/*" fullWidth type="file" mat-raised-button>
                </div>

                <button class="button-comment small-btn width-100" (click)="updateUserData()" [ngClass]="{'salvar-mobile': layoutService.isMobile}" mat-raised-button color="accent">
                    Salvar
                </button>
            </form>
        </div>
        <div class="col-md-4 pt-md-3 text-center order-md-2 order-sm-1 order-first mb-3">
            <div class="user-img-preview center" [ngStyle]="{'background-image': 'url('+ photoPreview +')'}"></div>
            <button (click)="imageUpload.click()" mat-raised-button color="accent" class="mt-3 pl-5 pr-5">Carregar nova</button>
            <button [disabled]="!photoPreview" (click)="removePhotoProfile()" mat-raised-button class="mt-3 pl-5 pr-5 background-transparent">&nbsp;Remover foto &nbsp;
            </button>
        </div>
    </div>
</div>