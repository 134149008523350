import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PaymentService } from '../payment.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Plan } from '../plan';
import { Billing } from '../billing';
import { Customer } from '../customer';
import { UserService } from '../../user/user.service';

/* const pagarme = require('pagarme/browser'); */


@Component({
    selector: 'app-payment-buy',
    templateUrl: './payment-buy.component.html',
    styleUrls: ['./payment-buy.component.scss']
})
export class PaymentBuyComponent implements OnInit {

    planId: number;
    planIdHash: string;
    plan: Plan;
    buyForm: FormGroup;
    loading: boolean;
    bought: boolean;
    boUrl: string;
    boCode: string;
    paymentMethod: string;
    parcels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    ufs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG',
        'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

    constructor(
        private paymentService: PaymentService,
        private formBuilder: FormBuilder,
        public maskService: MaskService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private userService: UserService) { }

    ngOnInit(): void {
        this.planIdHash = this.route.snapshot.paramMap.get('planHash');

        // tslint:disable-next-line: no-unused-expression
        this.planIdHash && this.getPlan(this.planIdHash);

        this.buyForm = this.formBuilder.group({
            paymentMethod: ['cc', Validators.required],
            name: ['', Validators.required],
            cpf: ['', Validators.required],
            birth: ['', Validators.required],
            phone: ['', Validators.required],
            postalCode: ['', Validators.required],
            uf: ['', Validators.required],
            city: ['', Validators.required],
            address: ['', Validators.required],
            number: ['', Validators.required],
            district: ['', Validators.required],
            cardHolderName: ['', Validators.required],
            cardNumber: ['', Validators.required],
            cardExpiration: ['', Validators.required],
            cardCvv: ['', Validators.required],
            installments: ['', Validators.required]
        });
    }

    submit() {

        if (!this.buyForm.valid) {
            this.alertService.error('Preencha todos os dados corretamente');
            return;
        }

        this.loading = true;
        this.paymentMethod = this.buyForm.get('paymentMethod').value;

        if (this.buyForm.get('paymentMethod').value === 'cc') {
            this.creditCard();
        } else {
            this.boleto();
        }
    }

    getPlan(planHash: string) {

        this.paymentService.getPlanHash(planHash)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plan = response.data as Plan;
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }

    creditCard() {

        let exp: string = this.buyForm.get('cardExpiration').value;
        let cNumber: string = this.buyForm.get('cardNumber').value;
        exp = exp.replace('/', '');
        cNumber = cNumber.replace('.', '');

        const card = {
            card_holder_name: this.buyForm.get('cardHolderName').value,
            card_expiration_date: exp,
            card_number: cNumber,
            card_cvv: this.buyForm.get('cardCvv').value
        };

        /* if (!pagarme.validate(card)) {
            this.alertService.error('Dados do cartão inválidos. Verifique os dados e tente novamente');
            this.loading = false;
            return false;
        } */

        /*  pagarme.client.connect({ encryption_key: 'ek_test_iN2XL590YryiAiAaZUQ08j9yIwWCRr' })
           .then(client => client.security.encrypt(card))
           .then(cardhash => {
             this.creditCardBuy(cardhash);
           }); */
    }

    changeMethodType() {

        if (this.buyForm.get('paymentMethod').value === 'bo') {
            this.buyForm.get('cardHolderName').clearValidators();
            this.buyForm.get('cardNumber').clearValidators();
            this.buyForm.get('cardExpiration').clearValidators();
            this.buyForm.get('cardCvv').clearValidators();
            this.buyForm.get('installments').clearValidators();
        } else {
            this.buyForm.get('cardHolderName').setValidators([Validators.required]);
            this.buyForm.get('cardNumber').setValidators([Validators.required]);
            this.buyForm.get('cardExpiration').setValidators([Validators.required]);
            this.buyForm.get('cardCvv').setValidators([Validators.required]);
            this.buyForm.get('installments').setValidators([Validators.required]);
        }

        this.buyForm.get('cardHolderName').updateValueAndValidity();
        this.buyForm.get('cardNumber').updateValueAndValidity();
        this.buyForm.get('cardExpiration').updateValueAndValidity();
        this.buyForm.get('cardCvv').updateValueAndValidity();
        this.buyForm.get('installments').updateValueAndValidity();
    }

    creditCardBuy(cardHash) {

        let cpf = this.buyForm.get('cpf').value as string;
        let phone = this.buyForm.get('phone').value;
        let postalCode = this.buyForm.get('postalCode').value;

        cpf = cpf.replace('.', '');
        cpf = cpf.replace('-', '');
        phone = phone.replace('(', '');
        phone = phone.replace(')', '');
        phone = phone.replace(' ', '');
        phone = phone.replace('-', '');
        postalCode = postalCode.replace('-', '');

        const customer: Customer = {
            name: this.buyForm.get('name').value,
            cpf,
            phone,
            email: this.userService.getUserEmail(),
            userId: this.userService.getUserId()
        };

        const billing: Billing = {
            name: this.buyForm.get('name').value,
            address: this.buyForm.get('address').value,
            city: this.buyForm.get('city').value,
            district: this.buyForm.get('district').value,
            number: this.buyForm.get('number').value,
            postalCode: Number(postalCode),
            uf: this.buyForm.get('uf').value,
        };

        this.paymentService.buyCreditCard(customer, billing, cardHash, this.plan.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.bought = true;
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao realizar a compra. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    boleto() {

        let cpf = this.buyForm.get('cpf').value as string;
        let phone = this.buyForm.get('phone').value;
        let postalCode = this.buyForm.get('postalCode').value;

        cpf = cpf.replace('.', '');
        cpf = cpf.replace('-', '');
        phone = phone.replace('(', '');
        phone = phone.replace(')', '');
        phone = phone.replace(' ', '');
        phone = phone.replace('-', '');
        postalCode = postalCode.replace('-', '');

        const customer: Customer = {
            name: this.buyForm.get('name').value,
            cpf,
            phone,
            email: this.userService.getUserEmail(),
            userId: this.userService.getUserId()
        };

        this.paymentService.buyBoleto(customer, this.plan.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    const resp = response.data as any;
                    this.boCode = resp.boCode;
                    this.boUrl = resp.boUrl;
                    this.bought = true;
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao realizar a compra. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

}
