import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { Class } from '../../school/class';
import { SchoolService } from '../../school/school.service';
import { TaskManagerService } from '../task-manager.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Student } from '../../student/student';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../../admin.service';
import { UserService } from 'src/app/modules/user/user.service';
import { School } from '../../school/school';
import { Unit } from '../../school/unit';
import { Task } from '../task'
import { CorrectTaskComponent } from '../correct-task/correct-task.component';
import { NbDialogService } from '@nebular/theme';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { StudyPlan } from '../../study-plan/study-plan';
//import { TextField } from '@material-ui/core';
import { SelectedPlansResp } from './../../rdo-manager/create-theme/theme';


@Component({
  selector: 'app-task-received',
  templateUrl: './task-received.component.html',
  styleUrls: ['./task-received.component.scss']
})
export class TaskReceivedComponent implements OnInit {

  public tasks:Task[];
  public taskTextParagraphs: string[];
  public classes: Class[];
  public classId: number;
  public studentId: number;
  public planId:number;

  public loading: boolean;
  public isTaskAfile: boolean = false;
  public formCorrect: FormGroup;
  public student: [];
  public editMode: boolean;
  public students: Student[];
  public score: number;
  public feed: string;
  public teachers: any[] = [];
  public teacherId: number;
  public schools: School[];
  public units: Unit[];
  public selectedClass: number;
  public selectedTasks: Task[];
  public file: any;
  public plans: SelectedPlansResp[];
  public selectedPlan: number;
  public taskLink: string

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public task: Task,
    @Optional() public dialogRef: MatDialogRef<TaskReceivedComponent>,
    private dialog: MatDialog,
    public taskManagerService: TaskManagerService,
    private schoolService: SchoolService,
    public matInputModule: MatInputModule,
    public matFormFieldModule: MatFormFieldModule,
    public maskService: MaskService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public layoutService: LayoutService,
    public studyPlanService: StudyPlanService ) { }

  ngOnInit() {
    this.formCorrect = this.formBuilder.group({
      id: [''],
      score: [''],
      taskId: [''],
      feed: [''],
    });

    this.tasks = [];   

    if(this.userService.isAdmin()){
      this.getSchools();
      this.getAllTasksReceived();
    }

    if(this.userService.isGeneralCoordinator()){
      let schoolId: number = this.userService.getUserSchool();
      this.getUnits(schoolId);
      this.getAllTasksReceivedByGeneralCoordinator();
      this.getPlansBySchool(schoolId);
    }

    if (this.userService.isUnitCoordinator()) {
      this.getTeacherUnit(this.userService.getUserUnit());
      this.getAllTasksReceivedByUnitCoordinator();
    }

    if (this.userService.isTeacher()) {
      let teacherId: number = this.userService.getUserId();
      if(!this.task){
        this.getPlansBySchoolAndTeacher(Number(this.userService.getUserSchool()), teacherId);
        this.getTeacher();
        this.getTeacherClasses(teacherId);
        //this.getAllStudentsByTeacher(teacherId);
      }

    }
    if (this.task) { this.initializeEditMode(); }

    this.taskManagerService.emitSentTask.subscribe((data)=>{
      setTimeout(() => {
          this.getClasses();
      }, 2000);

    });
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
          //this.filterSchools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnits(schoolId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.units = response.data as Unit[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClasses() {
    this.schoolService.getClasses()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getPlansBySchoolAndTeacher(schoolId: number, teacherId:number) {
    this.studyPlanService.getPlansBySchoolAndTeacher(schoolId, teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as SelectedPlansResp[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  getAllTasksReceivedByUnitCoordinator(){
    this.loading = true;
    const unitId = this.userService.getUserUnit();
    this.taskManagerService.getAllTasksReceivedByUnitCoordinator(unitId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.tasks = response.data as [];
                this.loading = false;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar todas as tarefas. Verifique a conexão e tente novamente'));
  }

  getAllTasksReceivedByGeneralCoordinator(){
    this.loading = true;
    const schoolId = this.userService.getUserSchool();
    this.taskManagerService.getAllTasksReceivedByGeneralCoordinator(schoolId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.tasks = response.data as [];
                this.loading = false;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar todas as tarefas. Verifique a conexão e tente novamente'));
  }

  getAllTasksReceived(){
    this.loading = true;
    this.taskManagerService.getAllTasksReceived()
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.tasks = response.data as [];
                this.loading = false;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar todas as tarefas. Verifique a conexão e tente novamente'));
  }

  getTasksReceived(){
    this.loading = true;
    const teacherId = this.userService.getUserId();
    this.taskManagerService.getTasksReceived(teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.tasks = response.data as Task[];
                this.loading = false;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente'));
  }

  getClassTasks(classId:number){
    this.loading = true;
    this.selectedClass = classId;
    this.taskManagerService.getClassTasks(classId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              //  this.selectedTasks = response.data as Task[];
              this.tasks = response.data as Task[];
            } else {
              //this.selectedTasks = [];
              this.tasks = [];
                this.alertService.error(response.error);

            }
            this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente');
          this.loading = false;
      });
  }

  getPlanTasks(planId:number){
    this.loading = true;
    this.selectedPlan = planId;
    this.taskManagerService.getPlanTasks(planId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              //  this.selectedTasks = response.data as Task[];
              this.tasks = response.data as Task[];
            } else {
              //this.selectedTasks = [];
              this.tasks = [];
                this.alertService.error(response.error);

            }
            this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente');
          this.loading = false;
      });
  }

  getClassTasksTeacher(classId:number){
    this.loading = true;
    this.selectedClass = classId;
    this.taskManagerService.getClassTasksTeacher(classId , this.userService.getUserId())
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              //  this.selectedTasks = response.data as Task[];
              this.tasks = response.data as Task[];
            } else {
              //this.selectedTasks = [];
              this.tasks = [];
                this.alertService.error(response.error);

            }
            this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente');
          this.loading = false;
      });
  }

  checkUser(){

    if(this.userService.isGeneralCoordinator()){
      this.getAllTasksReceivedByGeneralCoordinator();
    }
    if(this.userService.isUnitCoordinator()){
      this.getAllTasksReceivedByUnitCoordinator();
    }

    if(this.userService.isAdmin()){
      this.getSchools();
      this.getAllTasksReceived();

    }
    if(this.userService.isTeacher()){
      this.getTasksReceivedCoord();
    }
  }

  getTasksReceivedCoord(){
    this.loading = true;
    this.taskManagerService.getTasksReceivedCoord(this.userService.getUserId())
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.tasks = response.data as [];

            } else {
                this.alertService.error(response.error);
            }
            this.loading = false;
        }, err =>
        { this.loading = false;
          this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente')
        });
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  getTeacher(){
    const teacherId = this.userService.getUserId();
    this.taskManagerService.getTeacher(teacherId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.tasks = response.data as [];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar o professor. Verifique a conexão e tente novamente'));
  }

  handleFileInput(files: FileList): void {
    if (files && files[0]) {

        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            this.file = reader.result;
        };
    }
}

  checkClass(classId: number){
    if(classId > 0){
      this.getStudents(classId);
      if(this.userService.isTeacher()){
        this.getClassTasksTeacher(classId);
      }
      else{
        this.getClassTasks(classId);
      }
    }else{
      //PEGA TODAS AS TAREFAS
      this.getTasks(0);
      if(this.userService.isTeacher()) this.getAllStudentsByTeacher(Number(this.userService.getUserId()));
      if(this.userService.isGeneralCoordinator() || this.userService.isUnitCoordinator())
        this.getAllStudentsByCoordinator(Number(this.userService.getUserId()));
    }
  }

  //Seleção de planos
  getPlansBySchool(schoolId: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as SelectedPlansResp[];
        }else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error("Não foi possivel buscar os dados verifique a conexão e tente novamente!");
      })
  }

  getStudentsByPlan(planId: number){
    this.loading = true;
    this.taskManagerService.getStudentsByPlan(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.students = response.data as [];
        } else {
            this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
          this.alertService.error('Houve um erro ao carregar os alunos. Verifique a conexão e tente novamente');
          this.loading = false;
      });
  }
  getPlanTasksTeacher(planId:number){
    this.loading = true;
    this.selectedPlan = planId;
    this.taskManagerService.getPlanTasksTeacher(planId , this.userService.getUserId())
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              //  this.selectedTasks = response.data as Task[];
              this.tasks = response.data as Task[];
            } else {
              //this.selectedTasks = [];
              this.tasks = [];
                this.alertService.error(response.error);

            }
            this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente');
          this.loading = false;
      });
  }

  checkPlans(planId: number){
    if(planId > 0){
      this.getStudentsByPlan(planId);
      if(this.userService.isTeacher()){
        this.getPlanTasksTeacher(planId);
      }
      else{
        this.getPlanTasks(planId);
      }
    }else{
      //PEGA TODAS AS TAREFAS
      // this.getTasks(0);
      // if(this.userService.isTeacher()) this.getAllStudentsByTeacher(Number(this.userService.getUserId()));
      // if(this.userService.isGeneralCoordinator() || this.userService.isUnitCoordinator())
      //   this.getAllStudentsByCoordinator(Number(this.userService.getUserId()));
    }
  }

  getAllStudentsByTeacher(teacherId: number){
    this.taskManagerService.getAllStudentsByTeacher(teacherId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) this.students = response.data as [];
      else this.alertService.error(response.error);
      this.loading = false;
    }, err => {
      this.alertService.error('Houve um erro ao carregar os alunos. Verifique a conexão e tente novamente');
      this.loading = false;
    });
  }

  getAllStudentsByCoordinator(coordId: number){
    this.taskManagerService.getAllStudentsByCoordinator(coordId, Number(this.userService.getUserProfileId()))
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) this.students = response.data as [];
      else this.alertService.error(response.error);
      this.loading = false;
    }, err => {
      this.alertService.error('Houve um erro ao carregar os alunos. Verifique a conexão e tente novamente');
      this.loading = false;
    });
  }

  getTasks(classId: number){
    this.loading = true;
    this.selectedClass = classId;
    const userId = this.userService.getUserId();
    let type: string;
    if(this.userService.isGeneralCoordinator() || this.userService.isUnitCoordinator()) type = "Coordinator";
    if(this.userService.isTeacher()) type = "Teacher";
    this.taskManagerService.getTasks(classId, userId, type)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.tasks = response.data as Task[];
        } else {
          this.alertService.error(response.error);
          this.tasks = [];
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente');
        this.loading = false;
    });
  }

  getStudents(classId: number){
    this.loading = true;
    this.taskManagerService.getStudents(classId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.students = response.data as [];
            } else {
                this.alertService.error(response.error);
            }
            this.loading = false;
        }, err => {
            this.alertService.error('Houve um erro ao carregar os alunos. Verifique a conexão e tente novamente');
            this.loading = false;
          }
        );
  }

  getStudentsTasks(studentId:number){
    if(this.userService.isTeacher()){
      this.getAllTasksStudentsForTeacher(studentId);
    }
    else{
      this.getAllTasksStudentsForCoord(studentId);
    }
  }

  getAllTasksStudentsForTeacher(studentId: number){
    this.loading = true;
    this.taskManagerService.getAllTasksStudentsForTeacher(studentId , this.userService.getUserId())
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.tasks = response.data as [];
                this.loading = false;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente'));
  }

  getAllTasksStudentsForCoord(studentId: number){
    this.loading = true;
    this.taskManagerService.getAllTasksStudentsForCoord(studentId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.tasks = response.data as [];
                this.loading = false;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente'));
  }

  getAllTeachers(){
    const schoolId = this.userService.getUserSchool();
    this.taskManagerService.getAllTeachers(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.teachers = response.data as [];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar os professores. Verifique a conexão e tente novamente'));
  }

  getClassesByTeacher(teacherId:number) {
    this.teacherId = teacherId;
    this.taskManagerService.getClassesByTeacher(teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as any;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente'));
  }

  getTeacherUnit(unitId:number) {
    this.taskManagerService.getTeacherUnit(unitId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.teachers = response.data as any;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente'));
  }

  initializeEditMode(){
    this.editMode = true;

    this.getClassName(this.task.classId);
    this.taskLink = this.task.taskLink;

    this.isTaskAfile = (this.task.taskDoneLink && this.task.taskDoneLink != "") ?  true  :  false;
    this.formCorrect.patchValue(this.task);

    if(this.task.taskDoneText){
      this.taskTextParagraphs = this.task.taskDoneText.split("\n");
    }
  }
  openFile(url: string){
    window.open(url, '_blank');
  }

  openLink(url: string){
    window.open(url, '_blank');
  }
  removeLink(){
    this.task.taskLink = null;
    this.task.file = null;
  }

  sendCorrection(studentId: number, taskId:number){

    const sendTask: Task = this.formCorrect.getRawValue();
    
    if(!sendTask.file){
      sendTask.file = this.file;
    }
    if (sendTask.feedback == "") {
      this.alertService.warning('Precisa adicionar um feedback!');
    }else if(sendTask.score == null){
      this.alertService.warning('Precisa adicionar uma nota!');
    } else {
      //console.log(sendTask);

      this.loading = true;
      this.taskManagerService.sendCorrection(studentId, taskId, sendTask)
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                this.alertService.success('Correção enviada', 3);
                //this.getAllTasksStudents(studentId);
                this.dialogRef.close();
              } else {
                  this.alertService.error(response.error);
              }
              this.loading = false;
          }, err => this.alertService.error('Houve um erro ao enviar a correção. Verifique a conexão e tente novamente'));

    }


  }

  correct(task: Task){
    const dialogRef = this.dialog.open(TaskReceivedComponent, {
      minWidth: '60vw',
      data: task
    });

    dialogRef.afterClosed().subscribe(result => {
        this.getTasks(this.selectedClass);
    });

  }

  correcTask(task: Task){

    this.isTaskAfile = (task.taskDoneLink && task.taskDoneLink != "") ?  true  :  false;

      const dialogRef = this.dialog.open(TaskReceivedComponent, {
        minWidth: '90vw',
        minHeight: '70vh',
        data: task
      });

      dialogRef.afterClosed().subscribe(result => {
        if(this.studentId){
          this.getStudentsTasks(this.studentId);
        } else if(this.selectedClass){
          this.getTasks(this.selectedClass);
        } else{
          this.getTasks(task.classId);
        }
      });

  }

  public getClassName(classId: number ){

    this.taskManagerService.getClassName(classId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if(!response.error){
        this.task.className = response.data as string;
      }

      else{
        this.task.className = 'Nenhuma turma';
      }
    })
  }
}
