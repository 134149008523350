import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../admin.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { EssayCredit } from '../essay-credit';
import { EssayCreditService } from '../essay-credit.service';

@Component({
  selector: 'app-create-essay-credit',
  templateUrl: './create-essay-credit.component.html',
  styleUrls: ['./create-essay-credit.component.scss']
})
export class CreateEssayCreditComponent implements OnInit {

  public showCreditsList: boolean;
  public addCreditsForm: FormGroup;
  public iframeCredit: EssayCredit[];
  public schools: School[];
  public schoolId: number;
  public loading: boolean;
  public editMode: boolean;
  public filter: string;
  private admUpdate$: Subscription;
  public existCredit: boolean;
  public schoolName: string;

  constructor(
    private essayCreditService: EssayCreditService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private schoolService: SchoolService,
    public userService: UserService) {}

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  init() {
    this.iframeCredit = [];
    this.getSchools();
    this.getEssayCredits();
    this.addCreditsForm = this.formBuilder.group({
      id: [],
      schoolId: ['', Validators.required],
      link: ['', Validators.required]
    });
  }

  create() {
    this.schoolId = this.addCreditsForm.get('schoolId').value;
    this.verifyExistCredit(this.schoolId);

    if (this.addCreditsForm.valid) {
      if(!this.existCredit){
        this.iframeCredit = this.addCreditsForm.getRawValue() as EssayCredit[];
        this.essayCreditService.createEssayCredit(this.iframeCredit)
          .subscribe(res => {
            const response = res.body as ResponseApi
            if (!response.error) {
              this.alertService.success('Iframe cadastrado com sucesso!');
              this.addCreditsForm.reset();
            } else {
              this.alertService.error(response.error);
            }
          })
      }else{
        this.alertService.error('Você só pode cadastrar um link por escola!');
      }
    } else {
      this.alertService.error('Você precisa prencher todos os campos para o cadastro!');
    }
  }

  verifyExistCredit(schoolId: number){
    this.iframeCredit.forEach(element => {
      if(element.schoolId == schoolId){
        this.existCredit = true;
      }
    });    
  }

  openList() {
    this.getEssayCredits(this.schoolId);
    this.showCreditsList = true;
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente!'));
  }

  getEssayCredits(schoolId?: number){
    this.essayCreditService.getEssayCredits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.iframeCredit = response.data as EssayCredit[];
        } else {
          //this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar os créditos das escolas. Verifique a conexão e tente novamente!'));
  }

  
  removeIframe(idIframe: number) {
    if (confirm('Deseja realmente excluir o Iframe?')) {
      this.essayCreditService.removeIframe(idIframe)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Iframe removido!', 3);
            this.getEssayCredits(this.schoolId);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o Iframe. Verifique a conexão e tente novamente'));
    }
  }
}
