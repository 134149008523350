<div *ngIf="!showPlanList">

    <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Plano</h4>
    <form [formGroup]="planForm">


        <!-- Name, Email, Password -->
        <div class="row">

            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field appearance="outline" class="width-100">
                    <mat-select placeholder="Escola *" (selectionChange)="getStudyPlans($event.value) ; getUnits($event.value)">
                        <mat-option *ngFor="let school of schools" [value]=" school.id">{{school.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="(userService.isAdmin() || userService.isGeneralCoordinator())">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="getClasses($event.value) ; getStudyPlansByUnit($event.value)" placeholder="Unidade">
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <div class="row">
                                <div class="col-10">
                                    {{unit.name}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" formControlName="class">
                        <mat-option>Sem turma</mat-option>
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            {{class.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Preço" type="text" formControlName="price" [textMask]="{mask: maskService.real}">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Parcelas" formControlName="installments">
                        <mat-option *ngFor="let month of months" [value]="month">
                            {{month}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Plano de estudo" formControlName="studyPlan">
                        <mat-option *ngFor="let studyPlan of studyPlans" [value]="studyPlan.id">
                            {{studyPlan.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="editMode">
            <div class="col-md-8">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Link" formControlName="link" readonly="true">
                </mat-form-field>
            </div>
        </div>



        <div *ngIf="!editMode" class="row mt-5">
            <div class=" col-6 width-100">
                <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Planos
                    cadastrados</button>
            </div>
            <div class=" col-6 text-right width-100">
                <button (click)="createPlan()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
            </div>
        </div>

        <div *ngIf="editMode" class="row mt-5">
            <div *ngIf="editMode" class="text-left col-6">
                <button (click)="duplicatePlan()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Duplicar</button>
            </div>

            <div *ngIf="editMode" class="text-right col-6">
                <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="showPlanList" class="">

    <mat-spinner class="center mt-5" *ngIf="loading"></mat-spinner>

    <nb-card size="medium" *ngIf="!loading">
        <nb-card-header>
            <mat-form-field class="ml-3" appearance="outline">
                <mat-label>Filtrar por nome</mat-label>
                <input matInput placeholder="Nome" [(ngModel)]="filterPlanName" (keyup)="applyFilter()">
            </mat-form-field>
        </nb-card-header>
        <nb-card-body>
            <nb-list class="width-90 mt-1 center">
                <nb-list-item *ngFor="let pl of filteredPlans">
                    <div class="row">
                        <div class="col-6">
                            {{pl.name}}
                        </div>
                        <div class="col-2">
                            {{pl.price | currency: 'BRL'}}
                        </div>
                        <div class="col-2 text-right">
                            <button (click)="editPlan(pl)" nbButton size="tiny" outline status="primary">Editar</button>
                        </div>
                        <div class="col-2 text-right">
                            <button size="tiny" (click)="removePlan(pl.id)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>
    <div class="text-right width-100">
        <button (click)="showPlanList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>