<div class="page-container p-pg page-container-mobile discipline "
  [ngClass]="{ 'p-0': layoutService.isMobile, 'pt-1': !layoutService.isMobile} ">
  <!-- oncontextmenu="return false " <div class="watch-video-container mt-2 " [ngClass]="{ 'p-md-6': videoId, 'p-md-4': this.urlActual=='dashboard' } " [hidden]="loading "> -->
  <div class="watch-video-container mt-2 "
    [ngClass]="{ 'p-md-4 background-white': this.urlActual=='dashboard' , 'p-md-3': transmissao, 'p-md-2 background-white': !transmissao && videoId} "
    [hidden]="loading ">
    <div class="row flex-start m-0 pt-5 " [ngClass]="{ 'contents-video d-flex': !layoutService.isMobile} ">

      <div class="col-12 ">
        <!-- lesson header -->
        <div class="lesson-header ">
          <div class="lesson-title text-center " *ngIf="!deviceService.isDesktop()">
            <div class="page-title mt-1 ">Conteúdo</div>
            <h6 class="mb-3 mt-2 " *ngIf="videoService?.instanceOfVideo(video) && video?.title ">{{video?.title}}</h6>
            <h6 class="mb-3 " *ngIf="slideFileService.instanceOfSlideFile(video) && video?.name ">{{video?.name}}</h6>
            <label style="color: red; " class="mt-2 " *ngIf="isIncognito && transmissao ">Atenção!,Não é possivel
              visualizar o chat em guia Anônima!</label>
          </div>
          <div class=" lesson-title " *ngIf="deviceService.isDesktop()">
            <div class="page-title ">Conteúdo</div>
            <h4 *ngIf="videoService?.instanceOfVideo(video) && video?.title ">{{video?.title}}</h4>
            <h6 class="mb-3 " *ngIf="slideFileService.instanceOfSlideFile(video) && video?.name ">{{video?.name}}</h6>
            <label style="color: red; " class="mt-2 " *ngIf="isIncognito && transmissao ">Atenção!,Não é possivel
              visualizar o chat em guia Anônima!</label>
          </div>
          <div class="lesson-back mb-2 " [ngClass]="{ 'text-center ': layoutService.isMobile} ">
            <!-- day videos back -->
            <div *ngIf="!isTeacherVideoWatch ">
              <!--Voltar do calendario-->
              <button *ngIf="!disciplineId && videoId && !topicId && !isTeacherVideoWatch && !transmissao && !channelId"
                routerLink="/dashboard" [ngClass]="{ 'width-50 back-button text-left': !layoutService.isMobile} "
                color="accent" mat-button class="mt-1 mb-1 hvr-grow ">
                <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
              </button>
              <button *ngIf="this.urlActual=='dashboard' && !topicId && !transmissao && isfromCalendar && !channelId"
                [ngClass]="{ 'width-50 text-left back-button': !layoutService.isMobile}" color="accent" mat-button
                class="mt-1 mb-1 hvr-grow " routerLink="/dashboard">
                <mat-icon>reply</mat-icon> <span class="back ">Voltar</span>
              </button>
              <!-- 1 video only back -->
              <button *ngIf="videoId && !topicId && this.urlActual=='oneVideo' && transmissao && !channelId"
                [ngClass]="{'width-50 back-button text-left': !layoutService.isMobile}" color="accent" mat-button
                class="mt-1 mb-1 hvr-grow" (click)="goBack()">
                <mat-icon>reply</mat-icon> <span class="back ">Voltar</span>
              </button>
              <button
                *ngIf="videoId && !topicId && this.urlActual=='oneVideo' && !transmissao && !isfromCalendar && !channelId"
                (click)="goBackClassroom()" [ngClass]="{'width-50 back-button text-left': !layoutService.isMobile} "
                color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
              </button>
              <!-- forum back -->
              <button *ngIf="topicId && !channelId" color="accent" mat-button class="mt-1 mb-1 back-button border-none"
                routerLink="/forum/list" [ngClass]="{'width-50 back-button text-left': !layoutService.isMobile}">
                <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
              </button>
              <!-- go back channel -->
              <button *ngIf="channelId" color="accent" mat-button class="mt-1 mb-1 back-button border-none"
                (click)="goBackChannel()" [ngClass]="{'width-50 back-button text-left': !layoutService.isMobile}">
                <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
              </button>
            </div>
            <a *ngIf="isTeacherVideoWatch " color="accent" class="mt-1 mb-1 back-button border-none "
              (click)="goBackTeacherView() " [ngClass]="{ 'width-50 back-button text-left': !layoutService.isMobile} ">
              <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
            </a>
          </div>
        </div>
      </div>

      <div
        [ngClass]="{ 'col-md-8': this.urlActual=='dashboard' , 'col-md-7': transmissao && this.urlActual=='oneVideo' , 'col-md-12': !transmissao && this.urlActual=='oneVideo' , 'center': !layoutService.isMobile} ">
        <!-- TypeOf Video only -->
        <div *ngIf="video && videoService.instanceOfVideo(video) " class="mb-3 ">
          <div *ngIf="showDescription " class="mt-2 mb-2 ">
            <h8 class="descfont ">Descrição </h8><br>
            <h8 class="fs-11 ">{{videoDescription}}</h8>
          </div>
          <div class="videoWrapper video-border d-block"
            [ngClass]="{'videoWrapperYoutube': isRecordYoutube || video.embed.type.toLowerCase() == 'youtube' && showMask}">
            <div>
              <iframe *ngIf="transmissao && !isRecord && !isRecordYoutube " width="100% " height="460 "
                [src]="embedCodeVideo " frameborder="0 "
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture " allowfullscreen
                class="a"></iframe>
              <div (mouseenter)="openMaskHover()" (mouseleave)="closeMaskOver()" class="mb-4 b"
                *ngIf="isRecordYoutube ">
                <div *ngIf="showMask" style="z-index: -1000!important; ">
                  <youtube-player width="100% " height="460" [playerVars]="{ 'controls': 0 , 'rel' : 0} "
                    [videoId]="youtubeLiveId " (ready)="savePlayer($event) ">
                  </youtube-player>
                </div>

                <div *ngIf="!showMask " style="z-index: -1000!important; ">
                  <youtube-player width="100% " height="460 " [videoId]="youtubeLiveId " (ready)="savePlayer($event) ">
                  </youtube-player>
                </div>

                <!--DIV POR CIMA DO IFRAME [ngClass]="{ 'colorMaskTransparent': playYoutube , 'colorMaskOverflow' : !playYoutube} "-->
                <div *ngIf="showMask " style="z-index: 1000!important; " class="overlayYoutubeLive "
                  (click)="openMask() " [ngClass]="{ 'maskOff': playYoutube , 'maskOn' : !playYoutube} ">
                  <!--  *ngIf="!playYoutube " -->

                </div>
                <!--MASCARA AULA AO VIVO-->
                <div *ngIf="!layoutService.isMobile && !playYoutube && showMask" class="div-overflow"
                  [ngClass]="{'row ml-4': layoutService.isMobile}" style="z-index: 1001!important;">

                  <div class="row testnewMask">
                    <div class="col-md-2">
                      <button *ngIf="showPlay" (click)="playVideoYT()"
                        [ngClass]="{'col d-flex': layoutService.isMobile}" class="btcircle circle-play2"
                        mat-button><mat-icon>play_circle</mat-icon></button>
                      <button *ngIf="!showPlay" (click)="openMask()" [ngClass]="{'col d-flex': layoutService.isMobile}"
                        class="btcircle circle-play2" mat-button><mat-icon>pause_circle</mat-icon></button>
                    </div>
                    <div class="col-md-6" *ngIf="!layoutService.isMobile">
                      <!--  <div class="row">
                                 <mat-slider [title]="youtubeTimeShow" (change)="goYoutubeTime()" (input)="slideMove($event)" [(value)]="youtubeTime" class="slider-sound width-70"
                                   horizontal min="0" [max]="youtubeDuration" step="1"></mat-slider>
                                   <div class="mt-3 ml-2" style="color:white;">
                                     <label>{{youtubeTimeShow}} / {{youtubeDurationShow}}</label>
                                   </div>
                                 </div> -->
                    </div>
                    <div class="col-md-2">
                      <div class="row">
                        <mat-icon *ngIf="youtubeVolume > 0" class="sound-icon_new mt-2">volume_up</mat-icon>
                        <mat-icon *ngIf="youtubeVolume == 0" class="sound-icon_new mt-2">volume_off</mat-icon>
                        <mat-slider class="slider-sound_new" (change)="setVolumeYoutube()" horizontal
                          style="color: white;" min="0" max="100" step="1" [(value)]="youtubeVolume"></mat-slider>
                      </div>
                    </div>
                    <div class="col-md-2 mt-2">
                      <div class="row">
                        <div [ngClass]="{'divconfigmain' : showConfig}">
                          <div *ngIf="showConfig" class="divconfig">
                            <div class="ml-1 mt-3 row"><label>Velocidade: </label> <mat-icon (click)="setVideoRate()"
                                [ngClass]="{'btnrateNew': !layoutService.isMobile , 'rateBtnMobile' : layoutService.isMobile}">slow_motion_video<label>{{rateVideo}}</label></mat-icon>
                            </div>
                          </div>
                          <mat-icon (click)="openConfig()" class="iconClass">settings</mat-icon>
                        </div>
                        <mat-icon (click)="openFullScreen()" class="ml-3"
                          [ngClass]="{'fullscrenbtnnew': !layoutService.isMobile , 'fullscrenbtnmobile': layoutService.isMobile , 'fullleftscreen' : deviceService.orientation == 'landscape' , 'fullleftscreenconfig' : showConfig}">fullscreen</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <iframe *ngIf="isRecord" width="100%" height="460" [src]="urlRecord" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                class="c"></iframe>
              <iframe *ngIf="video?.embed.type.toLowerCase() == 'vimeo' && !transmissao" #player_container
                [src]="videoUrl.vimeo + video?.embed.code | safe" width="100%" height="460" frameborder="0"
                title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen class="d"></iframe>
              <iframe *ngIf="video?.embed.type.toLowerCase() == 'panda' && !transmissao" color="white"
                [src]="video?.embed.code | safe" width="100%" height="460" frameborder="0" title="Video"
                style="border:none;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
                allowfullscreen=true fetchpriority="high"></iframe>
            </div>

            <!-- [ngClass]="{'colorMaskTransparent': playYoutube , 'colorMaskOverflow' : !playYoutube}" -->
            <div (mouseenter)="openMaskHover()" (mouseleave)="closeMaskOver()" class="mb-4"
              *ngIf="video?.embed.type.toLowerCase() == 'youtube'">
              <!--  <h4>titulo 1</h4> -->
              <div *ngIf="showMask" style="z-index: -1000!important;">
                <youtube-player *ngIf="video?.embed.type.toLowerCase() == 'youtube' && !transmissao" width="100%"
                  height="500" [videoId]="video?.embed.code" (ready)="savePlayer($event)"
                  [playerVars]="{'controls': 0 ,'rel' : 0}">
                </youtube-player>
              </div>

              <!--   <div *ngIf="!showMask" style="z-index: -1000!important;">
                              <youtube-player *ngIf="video?.embed.type.toLowerCase() == 'youtube' && !transmissao" width="100%" height="500" [videoId]="video?.embed.code" (ready)="savePlayer($event)">
                              </youtube-player>
                          </div> -->

              <iframe *ngIf="video?.embed.type.toLowerCase() == 'youtube' && !transmissao && !showMask" width="100%"
                height="500" [src]="videoUrl.youtube + video?.embed.code | safe" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>

              <!--DIV POR CIMA DO IFRAME [ngClass]="{'colorMaskTransparent': playYoutube , 'colorMaskOverflow' : !playYoutube}"-->
              <div *ngIf="showMask" style="z-index: 1000!important;" class="overlayYoutube" (click)="openMask() "
                [ngClass]="{'maskOff': playYoutube , 'maskOn' : !playYoutube}">
                <!--  *ngIf="!playYoutube" -->

              </div>

              <!--MASCARA NOVA DO VIDEO-->
              <div *ngIf="!layoutService.isMobile && !playYoutube && showMask" class="div-overflow"
                [ngClass]="{'row ml-4': layoutService.isMobile}" style="z-index: 1001!important;">

                <div class="row testnewMask">
                  <div class="col-md-2">
                    <button *ngIf="showPlay" (click)="playVideoYT()" [ngClass]="{'col d-flex': layoutService.isMobile}"
                      class="btcircle circle-play2" mat-button><mat-icon>play_circle</mat-icon></button>
                    <button *ngIf="!showPlay" (click)="openMask()" [ngClass]="{'col d-flex': layoutService.isMobile}"
                      class="btcircle circle-play2" mat-button><mat-icon>pause_circle</mat-icon></button>
                  </div>
                  <div class="col-md-6" *ngIf="!layoutService.isMobile">
                    <div class="row">
                      <mat-slider [title]="youtubeTimeShow" (change)="goYoutubeTime()" (input)="slideMove($event)"
                        [(value)]="youtubeTime" class="slider-sound width-70" horizontal min="0" [max]="youtubeDuration"
                        step="1"></mat-slider>
                      <div class="mt-3 ml-2" style="color:white;">
                        <label>{{youtubeTimeShow}} / {{youtubeDurationShow}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row">
                      <mat-icon *ngIf="youtubeVolume > 0" class="sound-icon_new mt-2">volume_up</mat-icon>
                      <mat-icon *ngIf="youtubeVolume == 0" class="sound-icon_new mt-2">volume_off</mat-icon>
                      <mat-slider class="slider-sound_new" (change)="setVolumeYoutube()" horizontal
                        style="color: white;" min="0" max="100" step="1" [(value)]="youtubeVolume"></mat-slider>
                    </div>
                  </div>
                  <div class="col-md-2 mt-2">
                    <div class="row">
                      <div [ngClass]="{'divconfigmain' : showConfig}">
                        <div *ngIf="showConfig" class="divconfig">
                          <div class="ml-1 mt-3 row"><label>Velocidade: </label> <mat-icon (click)="setVideoRate()"
                              [ngClass]="{'btnrateNew': !layoutService.isMobile , 'rateBtnMobile' : layoutService.isMobile}">slow_motion_video<label>{{rateVideo}}</label></mat-icon>
                          </div>
                          <div class="ml-1 mt-3 row"><label>Qualidade: </label> <mat-icon (click)="changeQuality()"
                              [ngClass]="{'btnrateNew': !layoutService.isMobile , 'rateBtnMobile' : layoutService.isMobile}"><label>{{valueQuality}}</label></mat-icon>
                          </div>
                        </div>
                        <mat-icon (click)="openConfig()" class="iconClass">settings</mat-icon>
                      </div>
                      <mat-icon (click)="openFullScreen()" class="ml-3"
                        [ngClass]="{'fullscrenbtnnew': !layoutService.isMobile , 'fullscrenbtnmobile': layoutService.isMobile , 'fullleftscreen' : deviceService.orientation == 'landscape' , 'fullleftscreenconfig' : showConfig}">fullscreen</mat-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="layoutService.isMobile && !playYoutube && showMask" class="div-overflow"
                [ngClass]="{'row ml-4': layoutService.isMobile}" style="z-index: 1001!important;">
                <div class="divVolume ml-2" [ngClass]="{'organizeYTVolumeMobile': !layoutService.isMobile}">
                  <mat-slider class="slider-sound" (change)="setVolumeYoutube()" vertical style="color: white;" min="0"
                    max="100" step="1" [(value)]="youtubeVolume"></mat-slider>
                  <mat-icon *ngIf="youtubeVolume > 0" class="sound-icon">volume_up</mat-icon>
                  <mat-icon *ngIf="youtubeVolume == 0" class="sound-icon">volume_off</mat-icon>
                </div>
                <div [ngClass]="{'row': layoutService.isMobile}" class="upButtonsMask">
                  <button (click)="rewindVideo()"
                    [ngClass]="{'col d-flex': layoutService.isMobile , 'btn-rewind' : !layoutService.isMobile}"
                    class="btcircle" mat-button><mat-icon
                      [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">fast_rewind</mat-icon></button>
                  <button *ngIf="showPlay" (click)="playVideoYT()" [ngClass]="{'col d-flex': layoutService.isMobile}"
                    class="btcircle" mat-button><mat-icon
                      [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">play_circle</mat-icon></button>
                  <button *ngIf="!showPlay" (click)="openMask()" [ngClass]="{'col d-flex': layoutService.isMobile}"
                    class="btcircle" mat-button><mat-icon
                      [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">pause_circle</mat-icon></button>
                  <button (click)="setForward()"
                    [ngClass]="{'col d-flex': layoutService.isMobile , 'btn-next' : !layoutService.isMobile}"
                    class="btcircle btn-next" mat-button><mat-icon
                      [ngClass]="{'circle-play': !layoutService.isMobile , 'circle-play2' : layoutService.isMobile}">fast_forward</mat-icon></button>
                </div>
                <div>
                  <mat-icon (click)="setVideoRate()"
                    [ngClass]="{'btnrate': !layoutService.isMobile , 'rateBtnMobile' : layoutService.isMobile}">slow_motion_video<label>{{rateVideo}}</label></mat-icon>
                  <mat-icon (click)="openFullScreen()" class="mr-5"
                    [ngClass]="{'fullscrenbtn': !layoutService.isMobile , 'fullscrenbtnmobile': layoutService.isMobile , 'fullleftscreen' : deviceService.orientation == 'landscape'}">fullscreen</mat-icon>
                </div>
                <div class="ml-5 row marginslidertime" *ngIf="!layoutService.isMobile">
                  <mat-slider style="background-color: white;" class="classteste" (change)="goYoutubeTime()"
                    [(value)]="youtubeTime" class="slider-sound width-90" horizontal min="0" [max]="youtubeDuration"
                    step="1"></mat-slider>
                </div>
                <div class="row marginslidertime width-100" *ngIf="layoutService.isMobile">
                  <mat-slider [ngClass]="{'classlandscapefull': deviceService.orientation == 'landscape'}"
                    style="background-color: white;" class="classteste" (change)="goYoutubeTime()"
                    [(value)]="youtubeTime" class="slider-sound width-90" horizontal min="0" [max]="youtubeDuration"
                    step="1"></mat-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Botao video anterior/ proximo video [ngClass]="{'margintopa': showMask}"-->
        <div *ngIf="!slideFileService.instanceOfSlideFile(video)" class="videos-navigation">
          <div class="previous-video">
            <button [ngClass]="{'notMobile': !layoutService.isMobile}" *ngIf="previousVideoId"
              (click)="goToPreviousVideo()">
              <mat-spinner class="m-1" *ngIf="loading" diameter="25"></mat-spinner>
              <div class="inner-button-navigation">
                <mat-icon color="accent" class="first">keyboard_arrow_left</mat-icon>
                <mat-icon color="accent">keyboard_arrow_left</mat-icon>
                <span *ngIf="!loading"> Anterior</span>
              </div>
            </button>
          </div>
          <div class="next-video">
            <button [ngClass]="{'notMobile': !layoutService.isMobile}" *ngIf="nextVideoId" (click)="goToNextVideo()">
              <div class="inner-button-navigation">
                <span *ngIf="!loading"> Próximo</span>
                <mat-icon color="accent">keyboard_arrow_right</mat-icon>
                <mat-icon color="accent" class="first">keyboard_arrow_right</mat-icon>
              </div>
            </button>
          </div>
        </div>
        <!-- AVALIAR AULA -->
        <ng-container *ngIf="video && videoService.instanceOfVideo(video)" class="mb-3">
          <div class="mt-4"
            [ngClass]="{'m-0 mb-2': !layoutService.isMobile , 'marginMobile':layoutService.isMobile && video?.embed.type.toLowerCase() == 'youtube'}">
            <div [ngClass]="{'d-block': layoutService.isMobile, 'd-flex': !layoutService.isMobile}">
              <div [ngClass]="{'text-center mt-0': layoutService.isMobile, 'text-left': !layoutService.isMobile}">
                <span class="font-size-1 font-poppins">O que você achou dessa aula?</span>
                <button *ngIf="!liked" class="borderNone ml-2" (click)="likeMethod(video.id)"><mat-icon
                    color="accent">thumb_up_off_alt</mat-icon></button>
                <button *ngIf="liked" class="borderNone ml-2" (click)="likeMethod(video.id)"><mat-icon
                    color="accent">thumb_up</mat-icon></button>
                <button *ngIf="!desliked" class="borderNone ml-2"
                  (click)="deslikeMethod(video.id)"><mat-icon>thumb_down_off_alt</mat-icon></button>
                <button *ngIf="desliked" class="borderNone ml-2"
                  (click)="deslikeMethod(video.id)"><mat-icon>thumb_down</mat-icon></button>
              </div>
              <div
                [ngClass]="{'mt-3 d-block text-center': layoutService.isMobile, 'd-inline-block right': !layoutService.isMobile}">
                <button class="mt-1 mb-1 btnReport p-auto" (click)="openReportError()"
                  [ngClass]="{'float-right': !layoutService.isMobile}">
                  <span class="material-icons pest-control">pest_control</span><span class="report-txt"> Reporte um
                    erro</span>
                </button>
              </div>
            </div>
          </div>
          <div class="m-0 mb-2 d-block width-100" *ngIf="!transmissao">
            <ng-container>
              <div class="mt-2 ml-1 mr-1 mb-3 border-none text-right">
                <button *ngIf="this.showTutor" (click)="tutorHelp()" mat-raised-button color="accent"
                  class="tutorbutton size-button">Ajuda do tutor</button>
                <button mat-raised-button color="accent" [disabled]="saving" *ngIf="!video?.watched"
                  class="center small-btn size-button" (click)="setWatched()">
                  <span *ngIf="!saving" class="fs-13 margintopa">Marcar como assistido</span>
                  <mat-icon class="ml-2" *ngIf="!saving">visibility</mat-icon>
                  <mat-spinner class="m-1" *ngIf="saving" diameter="25"></mat-spinner>
                </button>
                <button mat-raised-button [disabled]="saving" *ngIf="video?.watched"
                  class="center small-btn size-button" (click)="setUnwatched()">
                  <span *ngIf="!saving" class="fs-13">Marcar não assistido</span>
                  <mat-icon class="ml-2" *ngIf="!saving">visibility_off</mat-icon>
                  <mat-spinner class="m-1" *ngIf="saving" diameter="25"></mat-spinner>
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!-- Type of SlideFile only -->
        <ng-container *ngIf="video && slideFileService.instanceOfSlideFile(video)">
          <app-slide-file-player [slideFile]="video"></app-slide-file-player>
          <!--   <iframe [src]="video?.file | safe" width="100%" height="500" alt="pdf" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe> -->
        </ng-container>
        <!-- annex -->
        <div *ngIf="annex?.length" class="pt-3 pb-2 mb-2">
          <div class="mb-2 font-weight-700 courseware" [ngClass]="{'text-center': layoutService.isMobile}">Material
            didático da aula</div>
          <mat-chip-list class="mb-2 mt-2">
            <mat-chip class="watch-video" [matTooltip]="anx.name" *ngFor="let anx of annex"
              (click)="openFileModal(anx.url)">
              <mat-icon color="accent" class="mr-2">download</mat-icon>
              <span class="anx-name">{{anx.name}}</span>
            </mat-chip>
          </mat-chip-list>
        </div>

        <!--iframe-->
        <div *ngIf="iframeLink" style="position:relative; width:100%; height:800px; overflow:auto;">
          <div
            style="position:relative!important; width:100%!important; height:800px!important; overflow-y: scroll!important;  ">
            <iframe [src]="iframeLink" allowtransparency="true" allowfullscreen="true"
              style="width: 100%!important; height: 100000px!important; border: 0px none; background: transparent;"
              class="iframeVideoScrool" [ngClass]="{'iframeVideo': !layoutService.isMobile}"></iframe>
          </div>
        </div>
        <!-- exercises -->
        <mat-accordion class="exercise mt-5" *ngIf="!transmissao">
          <ng-container *ngIf="exercises">
            <div class="mb-2 font-weight-700 courseware mt-4" *ngIf="!layoutService.isMobile && exercises.length > 0">
              Testes de fixação</div>
            <div class="mb-2 font-weight-700 courseware text-center" *ngIf="layoutService.isMobile && exercises.length">
              Testes de fixação</div>
            <mat-expansion-panel class="mb-2" *ngFor="let exercise of exercises; let i=index" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon *ngIf="exercisesResponse[i]" matTooltipClass="custom-tooltip" matTooltip="Respondido"
                    color="accent" class="mr-2">
                    check_circle_outline</mat-icon>
                  <mat-icon *ngIf="!exercisesResponse[i]" matTooltipClass="custom-tooltip" matTooltip="Não respondido"
                    color="accent" class="mr-2">
                    access_time</mat-icon>
                  Teste de fixação {{i + 1}}
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <ng-container>
                <mat-divider></mat-divider>
                <div class="question text-left mt-3" [innerHTML]="exercise?.question"></div>
                <div *ngIf="exercise?.embed?.code" class="question-embed text-center">
                  <div class="width-100 center">
                    <!-- YouTube -->
                    <iframe *ngIf="exercise?.embed.type && exercise?.embed.type.toLowerCase() == 'youtube'" width="100%"
                      height="460" [src]="videoUrl.youtube + exercise?.embed.code | safe" frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
                    <!-- Vimeo -->
                    <iframe *ngIf="exercise?.embed.type && exercise?.embed.type.toLowerCase() == 'vimeo'"
                      [src]="videoUrl.vimeo + exercise?.embed.code | safe" width="100%" height="460" frameborder="0"
                      title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <!-- exercise alternatives -->
                <div class="alternatives">
                  <mat-radio-group [(ngModel)]="exercisesResponse[i]" class="radio-group">
                    <div *ngFor="let alt of exercise?.alternatives">
                      <!--    ***************** NÃO REMOVER O NGIF ABAIXO - VALIDA SE OPÇÃO NÃO FOR NULA *****************    -->
                      <div class="row" *ngIf="alt.value"
                        [ngClass]="{'ml-15 mr-15': !layoutService.isMobile, 'm-0': layoutService.isMobile}">
                        <div class="pt-1 option-size">
                          <span class="option">{{alt.option}}) </span>
                        </div>
                        <div class="col p-0">
                          <div class="row m-0">
                            <mat-radio-button class="option-size p-0 pl-1 radio-button mat-radio"
                              [disabled]="exercisesSaved[i]" [value]="alt.option">
                            </mat-radio-button>
                            <span class="col button-text p-0" [ngClass]="{'pt-1': !layoutService.isMobile}"
                              [innerHTML]="alt.value"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-radio-group>
                  <button class="hvr-grow float-right"
                    (click)="sendExerciseResponse(exercise.id, exercisesResponse[i], i)"
                    [disabled]="!exercisesResponse[i]" mat-mini-fab color="accent" [hidden]="exercisesSaved[i]">
                    <mat-icon>send</mat-icon>
                  </button>
                </div>
                <!-- exercise result -->
                <div class="response-msg" *ngIf="exercisesResponse[i] && exercisesCorrect[i].correct">
                  <ng-container *ngIf="exercisesResponse[i] == exercisesCorrect[i].correct; else error">
                    <div class="alert alert-success" role="alert">
                      <mat-icon class="mr-2">check</mat-icon>
                      Parabéns, você acertou! :)
                    </div>
                  </ng-container>
                  <ng-template #error>
                    <div class="alert alert-danger" role="alert">
                      <mat-icon class="mr-2">close</mat-icon>
                      Ops! a alternativa correta é a alternativa <b>
                        {{exercisesCorrect[i].correct}} </b>.
                    </div>
                  </ng-template>
                  <div class="exercise-comment comment" [innerHTML]="exercisesCorrect[i].comment">
                  </div>
                  <mat-divider></mat-divider>
                  <!-- exercise resolution -->
                  <div *ngIf="exercisesCorrect[i].embed.code && exercisesResponse[i]"
                    class="resolution-embed text-center">
                    <span class="resolution-title text-color-primary">Veja a resolução do exercício
                      no vídeo abaixo:</span>
                    <div class="width-100 center">
                      <!-- YouTube -->
                      <iframe
                        *ngIf="exercisesCorrect[i].embed.type && exercisesCorrect[i].embed.type.toLowerCase() == 'youtube'"
                        width="100%" height="460" [src]="videoUrl.youtube + exercisesCorrect[i].embed.code | safe"
                        frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                      <!-- Vimeo -->
                      <iframe
                        *ngIf="exercisesCorrect[i].embed.type && exercisesCorrect[i].embed.type.toLowerCase() == 'vimeo'"
                        [src]="videoUrl.vimeo + exercisesCorrect[i].embed.code | safe" width="100%" height="460"
                        frameborder="0" title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </ng-container>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
        <!-- comments -->
        <div *ngIf="video && videoService.instanceOfVideo(video) &&  !transmissao" class="mt-4 comments-add">
          <div class="font-size-1 color-black text-left mt-5" *ngIf='!layoutService.isMobile'><b>Comentários</b></div>
          <div class="font-800 font-size-1 color-black text-center" *ngIf='layoutService.isMobile'>Comentários</div>
          <app-forum-add-comment [videoId]="video?.id" (addEvent)="commentEvent($event)">
          </app-forum-add-comment>
          <ng-container *ngFor="let comment of comments; let i = index">
            <app-forum-comment *ngIf="comment.active && comment.blockComment != 1" [comment]="comment"
              (deleteEvent)="deleteComment(i)">
            </app-forum-comment>
          </ng-container>
        </div>
      </div>
      <!-- right colum with others day videos -->
      <!-- [ngClass]="{'col-md-3': this.urlActual== 'dashboard'  , 'col-md-4':  videoId}"-->
      <div class="col">

        <!--CHAT DE VIDEO AO VIVO-->
        <div [ngClass]="{'chat': !deviceService.isMobile() , 'chatMobile' : deviceService.isMobile()}"
          *ngIf="transmissao && !isIncognito">
          <iframe [src]="embedCodeChat" width="100%" height="100%" frameborder="0"></iframe>
        </div>
        <div *ngIf="!transmissao && this.urlActual !== 'oneVideo'">
          <div class="day-videos-header mb-3 d-flex">
            <mat-icon svgIcon="calendar" class="mr-3"></mat-icon>
            <h5>Agenda Diária</h5>
          </div>
          <mat-list>
            <mat-list-item *ngFor="let v of dayVideos">
              <div class="select-video" (click)="selectVideo(v)">
                <div class="row align-items-center day-video-card mat-elevation-z1 pt-3 pb-3 mb-3"
                  [ngClass]="{'current-video border-accent': video?.id == v.id}">
                  <div class="col col-2">
                    <div *ngIf="v.watched" class="hvr-grow" class="mr-3 watched">
                      <mat-icon *ngIf="videoService.instanceOfVideo(v)" svgIcon="checked"></mat-icon>
                      <mat-icon *ngIf="slideFileService.instanceOfSlideFile(v)">
                        file
                      </mat-icon>
                    </div>
                    <div *ngIf="!v.watched" class="hvr-grow" class="mr-3 unwatched">
                      <mat-icon *ngIf="videoService.instanceOfVideo(v)" svgIcon="unchecked">
                      </mat-icon>
                      <mat-icon *ngIf="slideFileService.instanceOfSlideFile(v)">
                        file
                      </mat-icon>
                    </div>
                  </div>
                  <div class="col" style="white-space: pre-line">
                    {{v?.title}} {{v?.name}}
                  </div>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
          <!-- ocultando por enquanto -->
          <div class="text-right">
            <button (click)="nextDay()" mat-button color="accent" class="mt-1"><span class="back">Próximo Dia </span>>>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="loading" class="width-100 loading-container">
    <mat-spinner class="loading" color="accent"></mat-spinner>
  </ng-container>
</div>
