<div class="col-12  mt-3 mb-3">
    <div>
        <ng-container>
            <div class="page-title-container">
                <span class="page-title" *ngIf="notification.type === 'Atualização'">ATUALIZAÇÃO</span>
                <div class="mt-1 description-title">
                    <h6 *ngIf="layoutService.isMobile">{{notification.title}}</h6>
                    <h3 *ngIf="!layoutService.isMobile">{{notification.title}}</h3>
                    <h6 class="btn-close-notifis" (click)="removeNotification(notification)">X</h6>
                </div>
            </div>
        </ng-container>
        <ng-container>
            <div class="row">
                <div class="col-12"  *ngIf='notification.image'>
                    <img class="width-100 pt-2 pb-3" [src]="notification.image">
                </div>
                <div class="col-12"  *ngIf="notification.vimeo == 1">
                    <iframe class="width-100 pt-2 pb-3" #player_container width="100%" height="250" [src]="notification.linkSanityzed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div class="row" *ngIf="notification.text">
                <div class="col-12">
                    <span class="fs-13 pb-0 word-break" [innerHtml]="notification.text"></span>
                </div>
            </div>
        </ng-container>
        <ng-container>
            <div class="row mt-4">
                <div class="col-6 text-left">
                    <span class="c-pointer read" [ngClass]="{'active': notification.read}">
                        <span class="fs-13"  (click)="markAsRead(notification)" *ngIf="!notification.read">Marcar como lida</span>
                    </span>
                </div>
                <div class="col-6 text-right lesson-back">
                    <button (click)="closeDialog()" class="close-button" mat-raised-button color="accent">Fechar</button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
