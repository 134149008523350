<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <div class="col-md-12 mt-2">
            <ng-container>
                <div class="page-title-container"  [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">CERTIFICADOS</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Meus certificados</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Meus certificados</h6>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    
    <div class="page-container p-0 pt-4" *ngIf="dataSource?.data?.length">
        <table mat-table [dataSource]="dataSource" class="full-width">
            <ng-container matColumnDef="certificate">
                <th mat-header-cell *matHeaderCellDef class="brl-5"> CERTIFICADO </th>
                <td mat-cell *matCellDef="let certificate; let i = index">
                    {{certificate.mockName}} - {{certificate.planName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="text-left pl-1"> DATA DE CONCLUSÃO </th>
                <td mat-cell *matCellDef="let certificate; let i = index" class="text-left">
                    {{certificate.dateCreated | date:'dd/MM/yyyy'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="action" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="brr-5 text-right"> AÇÕES </th>
                <td mat-cell *matCellDef="let certificate; let i = index" class="text-right">
                    <button (click)="download(certificate)" class="btn-share">
                        <img src="assets/images/pdf.png" class="pdf" alt="Download pdf" matTooltipClass="custom-tooltip" matTooltip="Baixar PDF do Certificado">
                    </button>
                    <button (click)="shareLinkedin(certificate)" class="btn-share">
                        <img src="assets/images/linkedin.png" class="linkedin" alt="LinkedIn Add to Profile button" matTooltipClass="custom-tooltip" matTooltip="Compartilhar certificado no Linkedin">
                    </button>
                    <button (click)="shareEmail(certificate)" class="btn-share">
                        <img src="assets/images/email.svg" class="email" alt="Email " matTooltipClass="custom-tooltip" matTooltip="Enviar certificado por e-mail">
                    </button>
                    <button (click)="view(certificate)" class="btn-share">
                        <img src="assets/images/view.svg" class="view" alt="View" matTooltipClass="custom-tooltip" matTooltip="Visualizar certificado">
                    </button>
                </td>
            </ng-container>    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="row m-0" *ngIf="loading">
        <div class="col-12">
            <h5>Carregando certificados disponíveis</h5>
            <br />
            <div class="text-webkit-center"><mat-spinner color="accent"></mat-spinner></div>
        </div>
    </div>
    <div class="row m-0" *ngIf="!dataSource?.data?.length && !loading">
        <div class="col-12">
            <h5>Nenhum certificado disponível</h5>
            <br />
            <ng-lottie class="mt-3 text-center " [options]="lottieOptions"></ng-lottie>
        </div>
    </div>
</div>