import { Component, OnInit, Input } from '@angular/core';
import { PaymentService } from '../payment.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Plan } from '../plan';

@Component({
    selector: 'app-payment-expired',
    templateUrl: './payment-expired.component.html'
})
export class PaymentExpiredComponent implements OnInit {

    private buyLink: string;

    constructor(
        private paymentService: PaymentService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer) { }


    ngOnInit(): void {
        this.iconRegistry.addSvgIcon(
            'clock',
            this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/relogio.svg'));
    }

    openPlanBuy() {
        window.open(this.buyLink, '_blank');
    }

    getPlan(planId: string) {
        this.paymentService.getPlan(planId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                   this.buyLink = (response.data as Plan).link;
                }
            });
    }
}
