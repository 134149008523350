import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

import { Calendar, CalendarDay, CalendarFixedEdit, CalendarMonth, CalendarYear } from '../admin/calendar/calendar';
import { RecordedClassroom } from '../admin/classroom/record-link';
import { Discipline } from '../admin/discipline/discipline';
import { SchoolService } from '../admin/school/school.service';
import { Schedule } from '../admin/study-plan/schedule';
import { ScheduleDay } from '../admin/study-plan/schedule-day';
import { Classroom } from '../classroom/classroom';
import { ClassroomService } from '../classroom/classroom.service';
import { RecordedClassroomDialogComponent } from '../classroom/recorded-classroom-dialog/recorded-classroom-dialog.component';
import { ScheduleMonth } from '../dashboard/student/calendar/schedule-month';
import { UserService } from '../user/user.service';
import { Video } from '../video/video';
import { CalendarService } from './calendar.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    @Input() schedule: Schedule;
    @Input() releaseCalendarVideosUpToday: boolean = false;
    @Output() scheduleDay: EventEmitter<ScheduleDay> = new EventEmitter();
    private mobileQueryListener: () => void;

  public auxPlanId : number;
  @Input() planId: number;
  public calendar: Calendar;
  public yearsSchedule: ScheduleDay[][][][];
  public monthTabIndex: number;
  public loading: boolean;
  public yearTabIndex: number;
  public teacherDisciplines: Discipline[];
  public classrooms: Classroom[];
  public months: ScheduleMonth[];
  public recordedClassrooms: Classroom[];
  public mobileQuery: MediaQueryList;
  public today = new Date();
  public classId: number;
  public isView: boolean;
  public record: boolean;
  public selectedYear: number;
  public noShow: boolean = false;
  public tabIndex: number;
  public editMode: boolean;
  public viewAsStudent: boolean;
  public actualDate: any;
  public actualMonth: any;
  public dayOfToday: number;
  public actualYear: number;

  public monthsOfYear = {
    'janeiro'		: 0,
    'fevereiro'	: 1,
    'março'			: 2,
    'abril'			: 3,
    'maio'			: 4,
    'junho'			: 5,
    'julho'			: 6,
    'agosto'		: 7,
    'setembro'	: 8,
    'outubro'		: 9,
    'novembro'	: 10,
    'dezembro'	: 11
  }

  constructor(
    public userService: UserService,
    public calendarService: CalendarService,
    private alertService: AlertService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    public classroomService: ClassroomService,
    private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher) {
      this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
        this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit(): void {

    console.log("releaseCalendarVideosUpToday: ", this.releaseCalendarVideosUpToday);

    let date = new Date;
    if(date){
      this.actualDate = date.getMonth();
      this.actualMonth = date.getMonth();
      this.dayOfToday = date.getDate();
      this.actualYear = date.getFullYear();
    }
    else{
      this.actualDate = 0;
      this.actualMonth = 0;
      this.dayOfToday = 1;
      this.actualYear = new Date().getFullYear();
    }

    this.isView = false;
    this.auxPlanId = Number(this.route.snapshot.paramMap.get('planId'));
    this.editMode = true;
    this.viewAsStudent = Boolean(this.route.snapshot.paramMap.get('viewAsStudent'));
    this.editMode = this.viewAsStudent ? false : true;


    if(this.auxPlanId && !this.editMode){
      this.planId = this.auxPlanId;
      this.isView = true;
    }
    if (this.userService.isTeacher()) {
      this.getTeacherDisciplines(this.userService.getUserId());

    if(this.isView){
      this.getPlanSchedule(this.planId);
      this.getClassroomsByClass(this.planId);
      this.getRecordedClassroms(this.planId);
    }

    } else {
      this.getPlanSchedule(this.planId);
    }
    this.classId = this.userService.getUserClass();
    this.getClassroomsByClass(this.planId);
    this.getRecordedClassroms(this.planId);
  }

  syncCalendar(planId: number) {

    if(confirm("Você quer realmente Atualizar este Calendário?")){
      this.loading = true;
      this.calendarService.syncCalendar(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.calendar = response.data as Calendar;
          this.alertService.success("Plano atualizado!");
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar o cronograma. Verifique a conexão e tente novamente');
        this.loading = false;
      });
    }


  }

  private calcCurrentYearIndex() {
    const today = new Date();
    let year = today.getFullYear();
    /* if(this.calendar.agenda && this.calendar.agenda.length > 0){
      this.yearTabIndex = this.calendar?.agenda.findIndex(y => y.year == year);
    }else{
      this.yearTabIndex = year;
    } */
    this.yearTabIndex = year;
  }

  private calcCurrentMonthIndex() {
    const today = new Date();
    let month = today.getMonth();

    if(this.calendar.agenda && this.calendar.agenda.length > 0){
      this.monthTabIndex = this.calendar?.agenda[this.yearTabIndex]?.months.findIndex(m => m.month == month);
    }

  }

  private getPlanSchedule(planId: number) {
    if (this.userService.isStudent()) {
      this.getStudentSchedule(planId, this.userService.getUserId());
    }else if(this.isView){
      this.getStudentSchedule(planId , this.userService.getUserId());
    }
     else {
      this.getEditSchedule(planId);
    }
  }

  private getStudentSchedule(planId: number, studentId?: number) {
    this.loading = true;

    this.calendarService.getPlanSchedule(planId, studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.calendar = response.data as Calendar;
          this.updateDateJSAgenda();

          if(this.calendar.agenda && this.calendar.agenda.length > 0){
            this.noShow = true;
            this.selectedYear = this.calendar.agenda[0].year;
          }

          this.handleTabIndexYearChange(this.tabIndex);
          this.handleTabIndexMonthChange(this.monthTabIndex);
          this.calcCurrentYearIndex();
          this.calcCurrentMonthIndex();

          if (!this.calendar || !this.calendar?.agenda) {
            this.initializeEmptyPlan();
            this.noShow = false;

          }
          this.loadCalendarObservable(this.calendar);
          this.formatCalendarToRender();
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar o cronograma. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  private updateDateJSAgenda(){
    for (let indexY = 0; indexY < this.calendar.agenda.length; indexY++) {
      const year = this.calendar.agenda[indexY];

      for (let indexM = 0; indexM < year.months.length; indexM++) {
        const month =  year.months[indexM];

        for (let indexD = 0; indexD < month.days.length; indexD++) {
          const day = month.days[indexD];
          if(day && day.date){
            let  tempDate = new Date(day.date);
            let ar = tempDate.toString().split(' ');
            let strDate =  ar[0]+" "+ ar[1] +" "+ ar[2]+" "+ ar[3]+" "+ ar[4]+" "+ ar[5];
            this.calendar.agenda[indexY].months[indexM].days[indexD].dateJs = ar[2];
          }

        }
      }

    }

  }

  public btnBack(){
    this.router.navigateByUrl('dashboard');
  }

  private getEditSchedule(planId: number) {
    this.loading = true;

    this.calendarService.getExpireFixedPlanScheduleEdit(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.calendar = response.data as Calendar;


          if(this.calendar.agenda && this.calendar.agenda.length > 0){
            this.noShow = true;

            this.selectedYear = this.calendar.agenda[0].year;
          }
          //this.calcCurrentYearIndex();
          //this.calcCurrentMonthIndex();

          if (!this.calendar || !this.calendar?.agenda) {

            this.noShow = false;
            this.initializeEmptyPlan();
          }
          this.formatCalendarToRender();

        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar o cronograma. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }
  loadCalendarObservable(calendar:Calendar){

    let  calendarInMemory = [] ;
    calendar.agenda.forEach(years => {
      years.months.forEach(months => {
        months.days.forEach(day => {

          if(  day && day.videos && day.videos.length > 0){
            calendarInMemory.push(day);
          }
        });
      });
    });

    this.calendarService.setCalendarDayVideos(calendarInMemory);
  }
  private correctDateForCalendar(){
    this.calendar.agenda.forEach(year => {
        year.months.forEach(month => {
          month.days.forEach(day => {
            if(day && day.date){
              day.date = moment(day.date).format( 'YYYY-MM-DD 00:00:00').toString();
            }

          });
      });
    });


  }

  private initializeEmptyPlan() {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();

    this.addNewYear(year, month);
  }

  private addNewYear(year?: number, month?: number) {

    let date = new Date();
    let newYear: number;
    let nowMonth = date.getMonth();



    if (year) {
      newYear = year;
    } else if(this.calendar.agenda && this.calendar.agenda.length > 0){
      newYear = this.calendar.agenda[this.calendar.agenda.length - 1].year + 1;
      month = 1;
    }else{
      let currentYear = Number(moment(new Date(this.calendar.startDate)).format( 'YYYY'));
      newYear = currentYear;
      month =  Number(moment(new Date(this.calendar.startDate)).format( 'MM'));
    }

    date = new Date(newYear, month - 1, 1);

    const y: CalendarYear = {
      year: newYear,
      months: [{
        month,
        monthName: date.toLocaleString('default', { month: 'long' }),
        days: this.generateNewMonthDays(newYear, month)
      }]
    };

    if(this.calendar?.agenda){
      this.calendar.agenda.push(y);
      this.formatCalendarToRender();
    }


  }

  private generateNewMonthDays(year: number, month: number): CalendarDay[] {
    const date = new Date(year, month - 1, 1);
    const monthDays = new Date(year, month, 0).getDate();
    const days: CalendarDay[] = [];

    // populate empty days
    for (let i = 0; i < date.getDay(); i++) {
      days.push(null);
    }

    // populate days
    for (let i = 1; i <= monthDays; i++) {
      const day: CalendarDay = {
        day: i,
        videos: [],
        date:moment(date).format( 'YYYY-MM-DD 00:00:00').toString(),
        ref: Number(this.datePipe.transform(date, 'yyyyMMdd'))
      };
      //date: moment(date, 'YYYY-MM-DD hh:mm:ss').toString(),

      days.push(day);
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  private formatCalendarToRender() {
    this.yearsSchedule = [];
    this.calendar?.agenda.forEach(year => {
      this.yearsSchedule.push(this.calendarService.formatScheduleMonthsGroup(year.months));
    });

    console.log('this.yearsSchedule :', this.yearsSchedule );
  }

  private addNewMonth(year: number, month: number, lastYearRef: CalendarYear) {
    const date = new Date(year, month - 1, 1);
    const newMonth: CalendarMonth = {
      month: month,
      monthName: date.toLocaleString('default', { month: 'long' }),
      days: this.generateNewMonthDays(year, month)
    };
    lastYearRef.months.push(newMonth);
    this.formatCalendarToRender();
  }

  private getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.teacherDisciplines = response.data as Discipline[];
          this.getPlanSchedule(this.planId);
        }
      });
  }

  callAddNewMonth() {
    this.noShow = true;
    let lastYear: any;
    let lastMonth: any;

    let date = new Date();
    let year = date.getFullYear();
    let month = Number(moment(date).format( 'MM'));

    if(this.calendar.agenda.length === 0){
      month = Number(moment(new Date(this.calendar.startDate)).format( 'MM'));
    }


    if(this.calendar.agenda && this.calendar.agenda.length > 0){

      lastYear = this.calendar.agenda[this.calendar.agenda.length - 1];
      if(lastYear.months != null){
        lastMonth = lastYear.months[lastYear.months.length - 1].month;
      }else{
        lastMonth = month;
      }

    }else{
      lastYear = year;
      lastMonth - month;
    }
    if(!this.calendar){
      lastYear = year;
      lastMonth - month;
    }

   if (lastMonth < 12) {
      this.addNewMonth(lastYear.year, lastMonth + 1, lastYear);
    } else {
      this.addNewYear();
    }

    /* const lastYear = this.calendar.agenda[this.calendar.agenda.length - 1];
    const lastMonth = lastYear.months[lastYear.months.length - 1].month;

    if (lastMonth < 12) {
      this.addNewMonth(lastYear.year, lastMonth + 1, lastYear);
    } else {
      this.addNewYear();
    } */
  }
  async saveCalendarChange(){
     await this.correctDateForCalendar();

     const calendarFixedEdit: CalendarFixedEdit = {
      planId: this.planId,
      userId: this.userService.getUserId(),
      schedule: this.calendar
    }
    await this.updateExpireFixedCalendarDays(calendarFixedEdit) ;
  }

  saveDayChanges(update:
    { videos: Video[], dayIndex: number, monthIndex: number, yearIndex: number, day: number, weekIndex: number, code: number }) {

    this.yearsSchedule[update.yearIndex][update.monthIndex][update.weekIndex][update.dayIndex].videos = [...update.videos];

    const calendarFixedEdit: CalendarFixedEdit = {
      planId: this.planId,
      userId: this.userService.getUserId(),
      schedule: this.calendar
    }

    this.updateExpireFixedCalendarDays(calendarFixedEdit) ;
  }
  updateExpireFixedCalendarDays(calendarFixedEdit: CalendarFixedEdit){
    this.calendarService.updateExpireFixedCalendarDays(calendarFixedEdit)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.alertService.success('Calendário editado com sucesso');
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao atualizar o calendário do plano. Verifique a conexão e tente novamente'));
  }

  isToday(day: Date) {
      day = new Date(day) ;
      return day.getDate() === this.today.getDate() &&
        day.getMonth() === this.today.getMonth() &&
        day.getFullYear() === this.today.getFullYear();
  }

  getClassrooms(planId: number) {
    this.classroomService.getClassroms(planId)
    .subscribe(res => {
      const response = res.body as ResponseApi;
      if (!response.error) {
          this.classrooms = response.data as Classroom[];
      }
    });
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }

  getClassroomsByClass(planId: number) {
    this.classroomService.getClassroms(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classrooms = response.data as Classroom[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));

  }

  getRecordedClassroms(planId: number) {
    this.classroomService.getRecordedClassroms(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.recordedClassrooms = response.data as Classroom[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));

  }

  async handleTabIndexYearChange(index: number) {
    const today = new Date();
    const monthDays = this.calendar.agenda[0].months[0].days;
    //const lastDay =  monthDays[monthDays.length];
    const lastDay =  monthDays[monthDays.length - 1];
    const last = lastDay.date as Date;
    this.tabIndex = today.getFullYear();
    this.monthTabIndex = today.getMonth()+1;

    const res = await this.classroomService.getMonthClassroomRecord(this.planId, last).toPromise();
    const response = res.body as ResponseApi;

    if (!response.error) {
      const recordLink = response.data as RecordedClassroom[];
      this.classroomRecordMonthOrganizerYear(recordLink, monthDays, index);
    }
  }

  async handleTabIndexMonthChange(index: number) {
    const today = new Date();
    //const monthDays = this.calendar.agenda[this.selectedYear].months[index].days;
    const monthDays = this.calendar.agenda[0].months[0].days;
    const lastDay =  [...monthDays].pop();
    const last = lastDay.date as Date;
    this.monthTabIndex = today.getMonth();

    const res = await this.classroomService.getMonthClassroomRecord(this.planId, last).toPromise();
    const response = res.body as ResponseApi;

    if (!response.error) {

        const recordLink = response.data as RecordedClassroom[];
        this.classroomRecordMonthOrganizerMonth(recordLink, monthDays,index);
    }
  }

  classroomRecordMonthOrganizerMonth(recordLink: RecordedClassroom[], monthDays: CalendarDay[], index) {

    monthDays =  monthDays.map(day => {

      if(day &&  day.date){
        const record = recordLink.find(rl => {
            const rlDate = moment(rl.initDate, 'DD-MM-YYYY').toDate();
            return rlDate.getDate() == (new Date(day.date)).getDate();
        });
        day.haveRecordLink = record ? true : false;
      }

      return day;
    });
  }

  classroomRecordMonthOrganizerYear(recordLink: RecordedClassroom[], monthDays: CalendarDay[],index) {



    monthDays =  monthDays.map(day => {

      if(day &&  day.date){
        const record = recordLink.find(rl => {
            const rlDate = moment(rl.initDate, 'DD-MM-YYYY').toDate();
            return rlDate.getDate() == (new Date(day.date)).getDate();
        });
        day.haveRecordLink = record ? true : false;
      }

      return day;
    });
  }

  openRecordedClassroomsDay(recordedClassRoom: any) {
    // Aqui entra o objeto da aula gravada no dia
    this.bottomSheet.open(RecordedClassroomDialogComponent, { data: recordedClassRoom as RecordedClassroom[] });
   /*  this.classroomService.getDayClassroomRecord(this.planId, dataArr)
        .subscribe(res => {
            const response = res.body as ResponseApi;
            if (!response.error) {
                this.bottomSheet.open(RecordedClassroomDialogComponent, { data: response.data as RecordedClassroom[] });
            } else {
                this.alertService.error(response.error);
            }
        }, err => {
            this.alertService.error('Houve um erro ao carregar as aulas gravadas. Verifique a conexão e tente novamente');
        }); */
  }
  convert(str: string) {
    var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      date = str.split(" ");

    return [date[3], mnths[date[1]], date[2]].join("-");
  }

  public getDay(str: string){
    if(str){
      let date = str.split("-");
      let day = date[2].split(" ");
      return day[0];
    }else{
      return "";
    }

  }

}
