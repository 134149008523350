import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './experiment-manage.component.html'
})

export class ExperimentManageComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}
