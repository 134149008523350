import { Component, OnInit, Input } from '@angular/core';
import { DisciplineReport } from './discipline-report';

@Component({
    selector: 'app-dashboard-discipline-card-report',
    templateUrl: './dashboard-discipline-card-report.component.html',
    styleUrls: ['./dashboard-discipline-card-report.component.scss']
})
export class DashboardDisciplineCardReportComponent implements OnInit {

    @Input() disciplineReport: DisciplineReport;

    constructor() { }

    ngOnInit(): void { }
}
