<div class="progres-card pt-4 pb-4 pl-3 pr-3 app-progress-bar">
  <mat-icon class="star-icon" *ngIf="customIcon" [svgIcon]="customIcon"></mat-icon>
  <div class="title mb-4 baloo-font mt-3">{{title}}</div>
  <mat-progress-bar class="mt-1" mode="buffer" color="accent" [value]="total" [bufferValue]="100">
  </mat-progress-bar>
  <div class="bar-labels mt-4">
    <div class="label-1 mr-4 baloo-font">
      <span class="dot bg-color-accent mr-2"></span> {{label[0]}}
    </div>
    <div class="label-2 baloo-font">
      <span class="dot bg-color-warn mr-2"></span> {{label[1]}}
    </div>
  </div>
</div>