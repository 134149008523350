import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseApi } from 'src/app/core/models/response-api';
import { RepositoryService } from 'src/app/modules/repository/repository.service';
import { VideoRepository } from 'src/app/modules/repository/video/video-repository';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../../admin.service';
import { Folder } from '../../folder/folder';
import { Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepositoryVideoWatchComponent } from 'src/app/modules/repository/video/watch/repository-video-watch.component';
import { SelectGroup } from 'src/app/shared/interfaces/select-group';
import { Tuple } from 'src/app/shared/types/tuple';
import { SchoolService } from '../../../school/school.service';
import { School } from '../../../school/school';
import { Unit } from '../../../school/unit';
import { Class } from '../../../school/class';

@Component({
  selector: 'app-repository-video-add',
  templateUrl: './repository-video-add.component.html',
  styleUrls: ['./repository-video-add.component.scss']
})
export class RepositoryVideoAddComponent implements OnInit, OnDestroy {

  public videoForm: FormGroup;
  public folders: Folder[];
  public teacherSelectGroup: Tuple<SelectGroup<Folder>>;
  public showList: boolean;
  public videos: VideoRepository[];
  public saving: boolean;
  public filterFolder: number;
  public loading: boolean;
  public editMode: boolean;
  public schools: School[];
  public units: Unit[];
  public classes: Class[];
  public isUnitCoord : boolean;
  public empty: boolean;
  private admUpdate$: Subscription;
  protected onDestroy = new Subject<void>();
  public profileInitialized: boolean;
  public selectedSchool: number;
  public selectedUnit: number;
  public stage: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public video: VideoRepository,
    @Optional() public dialogRef: MatDialogRef<RepositoryVideoAddComponent>,
    private dialog: MatDialog,
    private repositoryService: RepositoryService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public userService: UserService,
    private adminService: AdminService,
    private schoolService: SchoolService) { }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.profileInitialized = false;

    this.teacherSelectGroup = [
      { name: 'Pastas do professor', options: [] },
      { name: 'Pastas da turma', options: [] }
    ];
    /* this.getFolders(this.userService.getUserSchool()); */
    this.profileInit();
    this.videoForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      folder: ['', Validators.required],
      link: ['', Validators.required],
      tag: ['', Validators.required],
      external: ['', Validators.required]
    });
    if (this.video) { this.initializeEditMode(); }
  }

  profileInit() {
    this.isUnitCoord = false; 
    console.log(this.userService.getUserPermissionId());
    console.log(this.userService.getUserId());
    if (this.userService.isAdmin()) {
      this.selectionManager("adm");
    } else if(this.userService.isGeneralCoordinator() || this.userService.getUserPermissionId() == 4) {
      this.selectedSchool = this.userService.getUserSchool();
      console.log(this.userService.getUser());
      this.selectionManager("general");
    } else if (this.userService.isUnitCoordinator() || this.userService.getUserPermissionId() == 3){
      this.selectedSchool = this.userService.getUserSchool();
      this.selectedUnit = this.userService.getUserUnit();
      this.isUnitCoord = true;
      this.selectionManager("unit");
    } else if (this.userService.getUserPermissionId() == 2 || this.userService.getUserPermissionId() == 1) {
      this.selectionManager("teacher12");
    }
    this.profileInitialized = true;
  }

  selectionManager(stage){
    this.stage = stage;
    if (stage == "adm") {
      this.getSchools();
      this.getUnits();
      this.getClasses();
    } else if (stage == "general") {
      this.getUnits(this.selectedSchool);
      this.getClassesBySchool(this.selectedSchool);
      this.getFolders(this.selectedSchool);
    } else if (stage == "unit") {
      if(this.selectedUnit == 0){
        this.getClassesBySchool(this.selectedSchool)
        this.getFolders(this.selectedSchool)
      } else {
        this.getClasses(this.selectedUnit);
        this.getFolders(this.selectedSchool, this.selectedUnit);
      }
    } else if (stage == "teacher12") {
      this.getTeacherFolders(this.userService.getUserId());
      this.getTeacherClasses(this.userService.getUserId());
    }
  }

  initializeEditMode() {
    this.editMode = true;
    this.videoForm.patchValue(this.video);
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }

  getUnits(schoolId?: number) {
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClassesBySchool(schoolId: number) {

    this.schoolService.getClassesBySchool(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId?: number) {
      this.schoolService.getClasses(unitId)
        .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));    
  }

  getFolders(schoolId: number, unitId?: number) {
    if (!this.userService.isTeacher()) {
      this.repositoryService.getFolders(schoolId, unitId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.folders = response.data as Folder[];
            if(this.folders.length > 0) {
              this.folders = this.folders.map(f => {
                f.id = Number(f.id);
                return f;
              })
            } else {
              if(this.profileInitialized) {
                if(this.stage == "general") {
                  this.alertService.error('A escola selecionada não possui pastas vinculadas');
                } else if(this.stage == "unit") {
                  this.alertService.error('A unidade selecionada não possui pastas vinculadas');
                }
              }
            }
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
    } else {
      this.getTeacherFolders(this.userService.getUserId());
    }
  }

  getClassFolders(classId: number) {
    if(classId != 0){
      this.repositoryService.getClassFolders(classId)
        .subscribe(res => {
          const response = res.body as ResponseApi;
  
          if (!response.error) {
            this.folders = response.data as Folder[];
            if(this.folders.length > 0) {
              this.folders = this.folders.map(f => {
                f.id = Number(f.id);
                return f;
              })
              this.teacherSelectGroup[1].options = [...this.folders];
            } else {
              this.alertService.error('A turma selecionada não possui pastas vinculadas');
            }
  
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
    } else {
      if(this.selectedUnit > 0){
        this.getFolders(this.selectedSchool, this.selectedUnit)
      } else {
        this.getFolders(this.selectedSchool)
      }
    }
  }

  getTeacherFolders(teacherId: number) {
    this.repositoryService.getAssociatedTeacherFolders(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          if (this.userService.teacherHavePermission(3)) {
            this.teacherSelectGroup[0].options = response.data as Folder[];
          } else {
            this.folders = response.data as Folder[];
          }
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as pastas. Verifique a conexão e tente novamente'));
  }

  getVideos(schoolId: number) {
    this.loading = true;
    this.repositoryService.getVideos(schoolId)
      .subscribe(res => {

        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as VideoRepository[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  openList() {
    this.showList = true;
  }

  getFolderVideos(folderId: number) {
    this.loading = true;
    this.repositoryService.getFolderVideos(folderId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as VideoRepository[];
          this.empty = false;
          if(response.data == ''){
            this.empty = true;
          }
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getTeacherClasses(teacherId: number) {
    this.classes = [];
    this.schoolService.getTeacherClasses(teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  add() {
    this.saving = true;
    const video = this.videoForm.getRawValue() as VideoRepository;

    this.repositoryService.addVideo(video)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Arquivo adicionado', 3);
          this.videoForm.reset();
          this.clearfilesArray();
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.saving = false;
      }, err => {
        this.alertService.error('Houve um erro ao adicionar o arquivo. Verifique a conexão e tente novamente');
        this.saving = false;
      });
  }

  clearfilesArray() {
    this.schools = [];
    this.units = [];
    this.classes = [];
  }

  remove(videoId: number) {
    if (confirm('Deseja realmente remover o arquivo?')) {
      this.repositoryService.removeVideo(videoId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Arquivo removido!', 3);
            this.getVideos(this.userService.getUserSchool());
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o video. Verifique a conexão e tente novamente'));
    }
  }

  edit(video: VideoRepository) {
    const dialogRef = this.dialog.open(RepositoryVideoAddComponent, {
      minWidth: '60vw',
      data: video
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getFolderVideos(this.filterFolder);
      }
    });
  }

  saveChanges() {

    if (this.videoForm.valid) {
      this.saving = true;
      const video = this.videoForm.getRawValue() as VideoRepository;

      this.repositoryService.saveVideoChanges(video)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Vídeo atualizado', 3);
            this.videoForm.reset();
            this.adminService.updateChields();
            this.dialogRef.close(true);
          } else {
            this.alertService.error(response.error);
          }

          this.saving = false;

        }, err => {
          this.alertService.error('Houve um erro ao adicionar o arquivo. Verifique a conexão e tente novamente');
          this.saving = false;
        });
    } else {
      this.alertService.warning('Preencha todos os campos corretamente');
    }
  }

  preview(video: VideoRepository) {
    this.dialog.open(RepositoryVideoWatchComponent, {
      minWidth: '60vw',
      data: video
    });
  }

  getTeacherSchools() {
    this.userService.getTeacherSchools(this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
  }
}
