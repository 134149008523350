import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-open-file-modal',
  templateUrl: './open-file-modal.component.html',
  styleUrls: ['./open-file-modal.component.scss']
})
export class OpenFileModalComponent implements OnInit {
  public link: SafeResourceUrl;
  constructor( 
    private sanitizer: DomSanitizer, 
    @Optional() @Inject(MAT_DIALOG_DATA) public file: any,
    @Optional() public dialogRef: MatDialogRef<OpenFileModalComponent>
  ) 
  { }

  ngOnInit() { 
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this.file);
  }

}
