<div class="visitors-statistics">
    <div class="statistics-chart">
        <div echarts [options]="option" class="echart" (chartInit)="onChartInit($event)">
        </div>
    </div>
    <div class="statistics-footer">
        <div class="row h5">
            <div class="col-6 text-center">{{value[1]}}</div>
            <div class="col-6 text-center">{{value[0]}}</div>
        </div>
        <app-legend-chart class="visitors-statistics-legend" [legendItems]="chartLegend"></app-legend-chart>
    </div>
</div>