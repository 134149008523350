<div *ngIf="!showList">
    <form [formGroup]="videoForm">

        <h4 *ngIf="editMode" class="mt-3 mb-3">Editar Vídeo</h4>

        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>
        </div>

        <!-- Filter Class Folders -->
        <div class="row">
            <div class="col-md-4" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="selectedSchool=$event.value; selectionManager('general')" placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                            <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" [ngClass]="{'col-md-4': userService.isAdmin(), 'col-md-6': userService.isGeneralCoordinator()}">
                <mat-form-field class="width-100">
                    <mat-select (selectionChange)="selectedUnit=$event.value; selectionManager('unit')" placeholder="Unidade">
                        <mat-option [value]="0">
                            Selecionar Todas
                        </mat-option>
                        <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" [ngClass]="{'col-md-4': userService.isAdmin(), 'col-md-6': userService.isGeneralCoordinator(), 'col-md-12': (userService.isUnitCoordinator() || userService.isTeacher())}">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turmas" (selectionChange)="getClassFolders($event.value)">
                        <mat-option [value]="0">
                            Selecionar Todas
                        </mat-option>
                        <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- Select Folders -->
        <div class="row">
            <div class="col-md-12">
                <ng-container *ngIf="userService.isTeacher() && userService.teacherHavePermission(3); else teacherLvl2">
                    <mat-form-field class="width-100">
                        <mat-select formControlName="folder" placeholder="Pasta" (selectionChange)="getFolderVideos($event.value)">
                            <mat-optgroup *ngFor="let group of teacherSelectGroup" [label]="group.name">
                                <mat-option *ngFor="let folder of group.options" [value]="folder.id">
                                    <span [title]="folder.name">{{folder.name}}</span> 
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-template #teacherLvl2>
                        <mat-form-field class="width-100">
                            <mat-select formControlName="folder" placeholder="Pasta">
                                <mat-option *ngFor="let folder of folders" [value]="folder.id">
                                    <span [title]="folder.name">{{folder.name}}</span> 
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                </ng-template>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-centered-itens">
                <mat-checkbox formControlName="external">
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="Ao marcar essa caixa o link informado será aberto em uma nova aba do navegador.">
                        help
                    </mat-icon> 
                    <span class="text-wrap">
                        Link Externo
                    </span> 
                </mat-checkbox>
            </div>
            <div class="col-md">
                <mat-form-field class="width-100">
                    <input matInput [placeholder]="videoForm.get('external').value
                              ? 'Link do vídeo' 
                              : 'Código do vídeo (Vimeo ou YouTube)'" formControlName="link">
                </mat-form-field>
            </div>
        </div>

    </form>

    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver vídeos Cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="add()" class="right bg-color-primary border-primary" [nbSpinner]="saving" [disabled]="saving" nbSpinnerStatus="warning" nbButton size="small" status="primary">Cadastrar</button>
        </div>
    </div>

    <div *ngIf="editMode" class="row mt-5 text-right">
        <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="saving" [disabled]="saving">Salvar</button>
    </div>
</div>

<div *ngIf="showList" class="">

    <!-- Filter Class Folders -->
    <div class="row">
        <div class="col-md-4" *ngIf="userService.isAdmin()">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getUnits($event.value)" placeholder="Escola">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        <span [title]="school.name">{{school.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.teacherHavePermission(4)">
            <mat-form-field class="width-100">
                <mat-select (selectionChange)="getClasses($event.value)" placeholder="Unidade">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                        <span [title]="unit.name">{{unit.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="userService.teacherHavePermission(3)">
            <mat-form-field class="width-100">
                <mat-select placeholder="Turmas" (selectionChange)="getClassFolders($event.value)">
                    <mat-option *ngFor="let class of classes" [value]="class.id">
                        <span [title]="class.name">{{class.name}}</span> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <ng-container *ngIf="userService.isTeacher() && userService.teacherHavePermission(3); else teacherLvl2">
                <mat-form-field class="width-100" appearance="outline">
                    <mat-select [(ngModel)]="filterFolder" placeholder="Pasta" (selectionChange)="getFolderVideos($event.value)">
                        <mat-optgroup *ngFor="let group of teacherSelectGroup" [label]="group.name">
                            <mat-option *ngFor="let folder of group.options" [value]="folder.id">
                                <span [title]="folder.name">{{folder.name}}</span> 
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-template #teacherLvl2>
                <mat-form-field class="width-100" appearance="outline">
                    <mat-select [(ngModel)]="filterFolder" placeholder="Pasta" (selectionChange)="getFolderVideos($event.value)">
                        <mat-option *ngFor="let folder of folders" [value]="folder.id">
                             <span [title]="folder.name">{{folder.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
        </div>
    </div>

    <mat-list *ngIf="!loading" class="width-90 mt-3 center">

        <div mat-list-item *ngFor="let v of videos" class="row" style="min-height: 100px">
            <div class="col-6 pt-2">
                {{v.name}}
            </div>
            <div class="col-2 pt-2">
                {{v.folder}}
            </div>
            <div class="col-4 text-right">
                <button color="primary" mat-icon-button (click)="preview(v)" class="mr-3 hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Preview">
            <mat-icon>visibility</mat-icon>
          </button>
                <button color="primary" mat-icon-button (click)="edit(v)" class="mr-3 hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Editar">
            <mat-icon>edit</mat-icon>
          </button>
                <button color="warn" mat-icon-button (click)="remove(v.id)" class="hvr-buzz-out" matTooltipClass="custom-tooltip" matTooltip="Excluir">
            <mat-icon>delete</mat-icon>
          </button>
            </div>
        </div>

    </mat-list>
    <div *ngIf="empty">Nenhum resultado</div>
    <mat-spinner *ngIf="loading" class="center mt-3"></mat-spinner>
    <div class="text-right width-100">
        <button (click)="showList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>