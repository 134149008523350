<div class="mb-2" *ngIf="!editMode && !showList">
  <form [formGroup]="addIntegrationForm">
    <!-- School, Unit, Class -->

    <div class="row mt-1">
      <div class="col-md-6" *ngIf="!editCodeList">
        <mat-form-field class="width-90">
          <input matInput placeholder="Nome" formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="!editCodeList">
        <mat-form-field class="width-90">
          <input matInput placeholder="Link" formControlName="link">
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="!editCodeList">
        <mat-form-field class="width-90">
          <input matInput placeholder="Titulo" formControlName="title">
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col d-flex align-items-center">
        <mat-slide-toggle formControlName="schoolOnly" color="primary">
          <div class="d-flex align-items-center">
            <mat-icon matTooltipClass="custom-tooltip" matTooltip="Define se sera ligado somente na escola">
              help
            </mat-icon>
            <span class="text-wrap text-center">Cadastrado na Escola</span>
          </div>
        </mat-slide-toggle>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4" *ngIf="userService.isAdmin()">
        <mat-form-field class="width-100">
          <mat-select formControlName="schoolId" (selectionChange)="getUnits($event.value)" placeholder="Escola">
            <mat-option *ngFor="let school of schools" [value]="school.id">
              {{school.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!addIntegrationForm.get('schoolOnly').value" class="col-md-4">
        <mat-form-field class="width-100">
          <mat-select (selectionChange)="getClasses($event.value)" placeholder="Unidade">
            <mat-option *ngFor="let unit of units" [value]="unit.id">
              {{unit.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div  *ngIf="!addIntegrationForm.get('schoolOnly').value" class="col-md-4">
        <mat-form-field class="width-100">
          <mat-select formControlName="classId" placeholder="Turma">
            <mat-option *ngFor="let class of classes" [value]="class.id">
              {{class.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6 annex">
        <input formControlName="icon" class="width-100" (change)="handleFileInput($event.target.files);" accept="" fullWidth status="primary"
          type="file" nbInput placeholder="Anexar Arquivo">
      </div>
    </div>
    <!-- Students -->
    <!-- buttons -->
  </form>
</div>

<div class="mt-3 mb-3" *ngIf="!editMode && showList">
  <div class="col" *ngIf="userService.isAdmin()">
    <mat-form-field class="width-100">
      <mat-select [(ngModel)]="selectedSchool" (selectionChange)="getIntegrations($event.value)" placeholder="Escola">
        <mat-option *ngFor="let school of schools" [value]="school.id">
          {{school.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-5" *ngIf="integrations">
    <nb-list class="width-90 mt-1">
      <nb-list-item class="corpadrao list-header bg-color-accent" style="color: white ">
          <div class="row">
              <div class="col-md-4">Nome</div>
              <div class="col-md-4">Turma</div>
              <div class="col-md-2">Link</div>
              <div class="col">Ações</div>
          </div>
      </nb-list-item>

      <nb-list-item *ngFor="let int of integrations">
          <div class="row">
              <div class="col-md-4 fontstext">
                  {{int.name}}
              </div>
              <div class="col-md-4 fontstext">
                  {{int.nomeTurma}}
              </div>
              <div class="col-md-2 fontstext">
                <a href="{{int.link}}" target="_blank"><mat-icon>link</mat-icon></a>
            </div>
              <div class="col-md-2 text-right">
                   <a size="tiny" (click)="deleteIntegration(int.integrationId)" status="danger" nbButton ghost>
                      <img src="assets/icons/deleteBtn.png">
                  </a>
              </div>
          </div>
      </nb-list-item>
  </nb-list>
  </div>
</div>


<div class="row mb-3 mt-3">
  <div class=" col-6 width-100" *ngIf="!showList">
    <button (click)="openList()" nbButton size="small" class="smallfont" outline status="primary"
      class="text-color-primary border-primary-thin">Ver cadastrados</button>
  </div>
  <div class=" col-6 width-100" *ngIf="showList">
    <button (click)="backBtn()" class="bg-color-primary border-primary" nbButton size="small" status="primary"
      nbSpinnerStatus="warning">Voltar</button>
  </div>
  <div class=" col-6 width-100 right text-right" *ngIf="!editMode">
    <button (click)="createIntegration()" class="right bg-color-primary border-primary" nbButton size="small"
      status="primary" nbSpinnerStatus="warning">Cadastrar</button>
  </div>
</div>
