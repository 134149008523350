<nb-card class="theme-dialog">
    <nb-card-header>{{title}}</nb-card-header>
    <nb-card-body>
        <div *ngFor="let t of theme.motivacionalText" [innerHtml]="t.motivacional_text">

        </div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="close()">Fechar</button>
    </nb-card-footer>
</nb-card>