import { StudyPlan } from './../../study-plan/study-plan';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Mock } from 'src/app/modules/mock/mock';
import { MockService } from 'src/app/modules/mock/mock.service';
import { UserService } from 'src/app/modules/user/user.service';
import { Video } from 'src/app/modules/video/video';
import { VideoService } from 'src/app/modules/video/video.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';

import { AdminService } from '../../admin.service';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { Exercise } from '../../exercise/exercise';
import { ExerciseService } from '../../exercise/exercise.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { SummationService } from '../../summation/summation.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Unit } from '../../school/unit';
import { Class } from '../../school/class';
import { Student } from '../../student/student';
import { StudentService } from '../../student/student.service';
import { libQuestions } from '../../my-exercise-library/lib-interfaces';
import { MyExerciseLibraryService } from '../../my-exercise-library/my-exercise-library.service';

interface selectedPlansResp {
  id: number,
  name: string;
}

@Component({
  selector: 'app-mock-create',
  templateUrl: './mock-create.component.html',
  styleUrls: ['./mock-create.component.scss']
})
export class MockCreateComponent implements OnInit, OnDestroy {

  public showMocksList: boolean;
  public addMockForm: FormGroup;
  public selectedExercises: Exercise[];
  public selectedLibQuestions: libQuestions[];
  public exercises: Exercise[];
  public mocks: Mock[];
  public mocksAux: Mock[];
  public schools: School[];
  public schoolId: number;
  public plans: StudyPlan[];
  public loading: boolean;
  public editMode: boolean;
  public filter: string;
  public selectedModels: Exercise[] = [];
  public disciplines: Discipline[];
  public videos: Video[];
  public filterDisciplineId: number;
  public filterName: string;
  public filterTag: string;
  public filterVideoId: number;
  public filterDisciplineType = 0;
  public selectedPlans: selectedPlansResp[];
  public boolStudents: boolean;
  public units: Unit[];
  public classes: Class[];
  public students: Student[];
  public selectedStudents: Student[];
  public libQuestions : libQuestions[];
  public filterNameSummation: string;
  public planExist: boolean;
  public sch: {id: number, name: string};
  public getMockName: string;

  private admUpdate$: Subscription;

  // Standard methods
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public mock: Mock,
    @Optional() public dialogRef: MatDialogRef<MockCreateComponent>,
    private dialog: MatDialog,
    private mockService: MockService,
    private adminService: AdminService,
    private studyPlanService: StudyPlanService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private exerciseService: ExerciseService,
    private libraryService: MyExerciseLibraryService,
    private schoolService: SchoolService,
    private studentService: StudentService,
    private disciplineService: DisciplineService,
    private videoService: VideoService,
    public maskService: MaskService,
    private summationService: SummationService,
    public userService: UserService) { }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init() {
    this.selectedStudents = [];
    this.boolStudents = false;
    this.selectedExercises = [];
    this.selectedLibQuestions = [];
    this.selectedPlans = [];
    this.addMockForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      planId: [''],
      exercises: ['', Validators.required],
      libQuestions: [''],
      timeLeft: [''],
      schoolId: ['', Validators.required],
      initDate: [{ value: '', disabled: false }],
      tolerance: [{ value: '', disabled: false }, Validators.maxLength(3)],
      resultDate: [{ value: '', disabled: true }],
      isReset: [false],
      private: [false],
      navigateQuestions: [false],
      notify: [false],
      contest: [false],
      random: [false],
      isTest: [false, Validators.required],
      summation: [false],
      selectedStudents: [''],
      isChecked: [false]
    });

    this.profileInit();
    if (this.mock) { this.initializeEditMode(); }

  }

  initializeEditMode() {
    this.editMode = true;
    this.addMockForm.patchValue(this.mock);
    
    this.addMockForm.get('isReset').setValue(this.mock.isReset);
    this.addMockForm.get('isReset').setValue(this.mock.isReset);
    this.addMockForm.get('timeLeft').setValue(this.secondsToInputTime());
    if (this.mock.contest) {
      this.addMockForm.get('initDate').enable();
      this.addMockForm.get('tolerance').enable();
      this.addMockForm.get('resultDate').enable();
    }
    this.getMockExercises(this.mock.id);
    this.getMockPlans(this.mock.id);
    this.getStudentsMocksAssociated(this.mock.id);
    this.addMockForm.get('summation').disable();
    this.boolStudents = true;
    if(this.addMockForm.get('selectedStudents').value){ this.addMockForm.get('isChecked').setValue(true)};
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.getSchools();
      this.getDisciplines();
    }
    else if (this.userService.isGeneralCoordinator()) {
      this.schoolId = this.userService.getUserSchool();
      this.addMockForm.get('schoolId').setValue(this.schoolId);
      this.getPlans(this.schoolId);
      this.getSchoolDisciplines(this.userService.getUserSchool());
    }
    else if (this.userService.isUnitCoordinator()) {
      this.schoolId = this.userService.getUserSchool();
      this.addMockForm.get('schoolId').setValue(this.schoolId);
      this.getPlans(this.schoolId);
      this.getUnitDisciplines(this.userService.getUserUnit());
    }
    else if (this.userService.isTeacher()) {
      this.schoolId = this.userService.getUserSchool();
      this.addMockForm.get('schoolId').setValue(this.schoolId);
      this.getTeacherDisciplines(this.userService.getUserProfileId());
    }
  }

  openStudentFields() {
    this.boolStudents = !this.boolStudents;
    if (this.userService.isAdmin()) {
      if (this.addMockForm.get('schoolId').value) {
        this.getUnits(this.addMockForm.get('schoolId').value);
      }
      else {
        this.alertService.error('voce precisa selecionar uma escola para carregar os dados!');
      }
    }
    if(this.userService.isGeneralCoordinator()){
      this.getUnits(this.userService.getUserSchool());
    }
    if(this.userService.isUnitCoordinator()){
      this.getClasses(this.userService.getUserUnit());
    }
  }

  getQuestions(){
    this.loading = true;
    this.libraryService.getQuestions(0,999999999)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.libQuestions = response.data as libQuestions[];
        this.loading = false;
      }
      else{
        this.alertService.error(response.error);
        this.loading = false;
      }
    })
  }

  // Auxiliar methods
  selectExercise(exercise: Exercise) {

    if (this.selectedExercises.indexOf(exercise) === -1) {
      this.selectedExercises.push(exercise);
    }
  }

  selectExerciseLib(exercise: libQuestions) {

    if (this.selectedLibQuestions.indexOf(exercise) === -1) {
      this.selectedLibQuestions.push(exercise);
    }
  }

  applyFilter() {
    if (this.schoolId) {
      if (this.filter.length) {
        this.mocks = [...this.mocksAux].filter(mock =>
          mock.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
        );
      } else {
        this.mocks = [...this.mocksAux];
      }
    } else {
      this.alertService.error('Você precisa selecionar uma escola.');
      return
    }
  }

  getUnits(schoolId: number) {

    this.loading = true;
    this.schoolService.getUnits(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.units = response.data as Unit[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente')
      });
  }

  getClasses(unitId: number) {
    this.loading = true;
    this.schoolService.getClasses(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        this.loading = false;
        if (!response.error) {
          this.classes = response.data as Class[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente')
      });
  }

  getStudents(classId: number) {
    this.loading = true;
    this.studentService.getStudents(classId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.students = response.data as Student[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  addStudentChip(student: Student) {
    const index = this.selectedStudents.indexOf(student);

    if (index < 0) {
      this.selectedStudents.push(student);
      this.addMockForm.get('studentsId').reset();
    }
  }

  removeStudentChip(student: Student) {
    const index = this.selectedStudents.indexOf(student);

    if (index >= 0) {
      this.selectedStudents.splice(index, 1);
    }
  }

  openList() {
    if (!this.userService.isAdmin()) {
      this.getMocks(this.schoolId);
    }
    this.showMocksList = true;
  }

  inputTimeLeftToSeconds() {
    let time: string = this.addMockForm.get('timeLeft').value;
    time = time.replace('h', '').replace('m', '');
    const hourMinutes = time.split(':');
    const seconds = (Number(hourMinutes[0]) * 60 * 60) + (Number(hourMinutes[1]) * 60);

    return seconds;
  }

  secondsToInputTime() {
    const date = new Date(0);
    date.setSeconds(this.mock.timeMock); // specify value for SECONDS here
    return date.toISOString().substr(12, 1) + 'h' + date.toISOString().substr(13, 3) + 'm';
  }

  contestToggle(event: MatSlideToggleChange) {

    if (!event.checked) {
      this.addMockForm.get('resultDate').disable();
      this.addMockForm.get('contest').setValue(false);
    } else {
      this.addMockForm.get('resultDate').enable();
      this.addMockForm.get('contest').setValue(true);
    }
  }

  // Remove methods
  removeExercise(index: number) {
    if (index > -1) {
      this.selectedExercises.splice(index, 1);
    }
  }

  removeLibExercise(index: number) {
    if (index > -1) {
      this.selectedLibQuestions.splice(index, 1);
    }
  }

  removeExerciseChip(exercise: Exercise) {
    const index = this.selectedModels.indexOf(exercise);

    if (index >= 0) {
      this.selectedModels.splice(index, 1);
    }
  }

  removeMock(mockId: number) {
    if (confirm('Deseja realmente remover o Simulado?')) {
      this.mockService.removeMock(mockId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Simulado removido!', 3);
            this.getMocks(this.schoolId);
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao remover o simulado. Verifique a conexão e tente novamente'));
    }
  }


  // Add Methods
  addExerciseChip(exercise: Exercise) {
    const index = this.selectedModels.indexOf(exercise);

    if (index < 0) {
      this.selectedModels.push(exercise);
      this.addMockForm.get('exercises').reset();
    }
  }

  addMock() {
    let schoolId = this.addMockForm.get('schoolId');
    if (this.userService.isAdmin() && schoolId.value == '') {
      this.alertService.error('Você precisa selecionar uma escola');
      return
    }
    const exercisesIds = [];
    const plansids = [];
    const selectStudents = [];
    this.selectedExercises.map(e => exercisesIds.push(e.id));
    if (this.selectedStudents[0]) {
      this.selectedStudents.map(s => selectStudents.push(s.id));
      this.addMockForm.get('selectedStudents').setValue(selectStudents);
    }
    this.selectedPlans.map(e => plansids.push(e.id));
    // this.selectedModels.map(e => exercisesIds.push(e.id));
    this.addMockForm.get('planId').setValue(plansids);
    this.addMockForm.get('exercises').setValue(exercisesIds);
    this.addMockForm.get('libQuestions').setValue(this.selectedLibQuestions);


    if (!this.addMockForm.get('timeLeft').value) {
      this.addMockForm.get('timeLeft').setValue(0);
    } else {
      this.addMockForm.get('timeLeft').setValue(this.inputTimeLeftToSeconds());
    }

    const mock = this.addMockForm.getRawValue() as Mock;

    if(this.selectedStudents[0]){
      mock.private = true;
    }


    if (this.addMockForm.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    this.mockService.addMock(mock)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Simulado cadastrado');
          this.addMockForm.reset();
          this.videos = [];
          this.disciplines = [];
          this.plans = [];
          this.selectedExercises = [];
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao cadastrar o Simulado. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }


  duplicateMock() {
    const exercisesIds = [];

    this.selectedExercises.map(e => exercisesIds.push(e.id));
    // this.selectedModels.map(e => exercisesIds.push(e.id));
    this.addMockForm.get('exercises').setValue(exercisesIds);
    this.addMockForm.get('timeLeft').setValue(this.inputTimeLeftToSeconds());

    const mock = this.addMockForm.getRawValue() as Mock;
    mock.name = mock.name + " (Duplicado)";
    if (this.addMockForm.errors) {
      this.alertService.error('Preencha todos os campos corretamente');
      return;
    }

    this.loading = true;

    this.mockService.addMock(mock)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.alertService.success('Simulado Duplicado');
          this.addMockForm.reset();
          // this.selectedModels = [];
          this.selectedExercises = [];
          this.dialogRef.close(true);
          this.getMocks(this.schoolId);
          this.adminService.updateChields();
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao Duplicar o Simulado. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  // Edit Methods
  editMock(mock: Mock) {
    mock.schoolId = Number(mock.schoolId);
    const dialogRef = this.dialog.open(MockCreateComponent, {
      minWidth: '60vw',
      maxHeight: '90vh',
      data: mock
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.getMocks(this.schoolId);
      }
    });
  }

  addAllPlanChip(){
    if(!this.planExist){
      for (var j = 0; j < this.plans.length; j++){
        for (var i = 0; i < this.selectedPlans.length; i++){
          if(this.selectedPlans[i].id == this.plans[j].id){
            j++;
          }
        }
        this.sch = {id: this.plans[j].id, name: this.plans[j].name};
        this.selectedPlans.push(this.sch);
        this.addMockForm.get('planId').reset();
      }
    }
    this.planExist = true;
  }

  addPlanChip(sch: { id: number, name: string }) {
    const index = this.selectedPlans.indexOf(sch);

    if (index < 0 && !this.planExist) {
      this.selectedPlans.push(sch);
      this.addMockForm.get('planId').reset();
    }
  }

  removePlanChip(sch: { id: number, name: string }) {
    const index = this.selectedPlans.indexOf(sch);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }

  saveChanges() {

    if(this.selectedPlans){

    let schoolId = this.addMockForm.get('schoolId');
    if (this.userService.isAdmin() && schoolId.value == '') {
      this.alertService.error('Você precisa selecionar uma escola');
      return
    }
    const exercisesIds = [];
    const plansids = [];
    const selectStudents = [];

    // this.selectedModels.map(e => exercisesIds.push(e.id));
    this.selectedPlans.map(e => plansids.push(e.id));
    this.selectedExercises.map(e => exercisesIds.push(e.id));
    if (this.selectedStudents[0]) {
      this.selectedStudents.map(s => selectStudents.push(s.id));
      this.addMockForm.get('selectedStudents').setValue(selectStudents);
    }
    this.addMockForm.get('planId').setValue(plansids);
    this.addMockForm.get('exercises').setValue(exercisesIds);
    this.addMockForm.get('id').setValue(this.mock.id);
    this.addMockForm.get('libQuestions').setValue(this.selectedLibQuestions);
    this.addMockForm.get('timeLeft').setValue(this.inputTimeLeftToSeconds());

      this.loading = true;
      const m = this.addMockForm.getRawValue() as Mock;

      if(this.selectedStudents[0]){
        m.private = true;
      }


      this.mockService.saveMockChanges(m)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Simulado editado com sucesso', 3);
            this.dialogRef.close(true);
            if(this.getMockName){
              this.getMocksByName(this.getMockName);
            }
            else{
              if(this.userService.isAdmin()){
                this.getMocks(this.schoolId);
              }
              else{
                this.getMocks(this.userService.getUserSchool())
              }
            }
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao salvar as alterações do simulado. Verifique a conexão e tente novamente');
          this.loading = false;
        });
      }
      else{
        this.alertService.error('Você precisa selecionar ao menos um plano para cadastrar o simulado');
      }
  }


  // Get Methods
  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
  }

  getExercises() {
    this.exerciseService.getExercises()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.exercises = response.data as Exercise[];
        } else {
          this.alertService.error(response.error);
        }

      }, err => this.alertService.error('Houve um erro ao carregar as questões. Verifique a conexão e tente novamente'));
  }

  getMocks(schoolId: number) {
    this.mockService.getMocks(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
          this.mocksAux = [...this.mocks];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente'));
  }

  getMockExercises(mockId: number) {
    this.mockService.getMockExercises(mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          // this.selectedModels = response.data as Exercise[];
          this.selectedExercises = response.data as Exercise[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as questões do simulado. Verifique a conexão e tente novamente'));
  }

  getMockPlans(mockId: number) {
    this.mockService.getMockPlans(mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          // this.selectedModels = response.data as Exercise[];
          this.selectedPlans = response.data as selectedPlansResp[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as questões do simulado. Verifique a conexão e tente novamente'));
  }

  getDisciplines() {
    if(!this.userService.isAdmin()){
      this.getSchoolDisciplines(this.userService.getUserSchool())
    }
    else{
      if (this.filterDisciplineType === 0) {
        this.getDisciplinesVideosOnly();
      } else
        if (this.filterDisciplineType === 1) {
          this.getDisciplinesExercisesOnly();
        }
        else  if(this.filterDisciplineType == 3){
          this.getQuestions();
        }
    }
  }

  getDisciplinesVideosOnly() {
    this.disciplineService.getDisciplines()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
      });
  }

  getDisciplinesExercisesOnly() {
    if(!this.userService.isAdmin()){
      this.disciplineService.getSchoolDisciplinesExercisesOnly(this.userService.getUserSchool())
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas de exercícios. Verifique a conexão e tente novamente'));
    }
    else{
      this.disciplineService.getDisciplinesExercisesOnly()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas de exercícios. Verifique a conexão e tente novamente'));
    }
  }

  getSchoolDisciplines(schoolId: number) {
    if (this.filterDisciplineType === 0) {
      this.getSchoolDisciplinesVideosOnly(schoolId);
    } else
      if (this.filterDisciplineType === 1) {
        this.getSchoolDisciplinesExercisesOnly(schoolId);
      }
  }

  setSummationFilter(ev : any){
    if(ev.checked){
      this.selectedExercises = []; 
      this.exercises = [];
      this.filterDisciplineType = 1;
      this.getDisciplines();
    }
    else{
      this.selectedExercises = []; 
      this.exercises = [];
      this.filterDisciplineType = 0;
      this.getDisciplines();
    }
  }

  getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
      });
  }

  getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[]
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
      })
  }

  getSchoolDisciplinesVideosOnly(schoolId: number) {
    this.disciplineService.getSchoolDisciplinesVideosOnly(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
      });
  }

  getSchoolDisciplinesExercisesOnly(schoolId: number) {
    this.disciplineService.getSchoolDisciplinesExercisesOnly(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas de exercícios. Verifique a conexão e tente novamente'));
  }

  getVideos(disciplineId: number) {
    this.filterVideoId = null;
    this.disciplineService.getDisciplineVideos(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
  }

  getVideoExercises(videoId: number) {

    if (this.filterVideoId == 0) {
      this.getDisciplineExercises(this.filterDisciplineId);
    } else {

      this.loading = true;
      this.videoService.getVideoExercises(videoId)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.exercises = response.data as Exercise[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar os exercícios. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
  }


  getExercisesByName() {

    if(!this.userService.isAdmin()){
      if (this.filterName.length > 3) {
        this.loading = true;
        this.exerciseService.getExercisesByNameAndSchool(this.filterName , this.userService.getUserSchool())
          .subscribe(res => {
            const response = res.body as ResponseApi;
  
            if (!response.error) {
              this.exercises = response.data as Exercise[];
            } else {
              this.alertService.error(response.error);
            }
  
            this.loading = false;
          }, err => {
            this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
            this.loading = false;
          });
      }
    }
    else{
      if (this.filterName.length > 3) {
        this.loading = true;
        this.exerciseService.getExercisesByName(this.filterName)
          .subscribe(res => {
            const response = res.body as ResponseApi;
  
            if (!response.error) {
              this.exercises = response.data as Exercise[];
            } else {
              this.alertService.error(response.error);
            }
  
            this.loading = false;
          }, err => {
            this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
            this.loading = false;
          });
      }
    }
  
  }

  
  getExercisesByTag() {

    if (this.filterTag.length > 1) { // verificar se o numero está correto 
        this.loading = true;
        this.exerciseService.getExercisesByTag(this.filterTag)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.exercises = response.data as Exercise[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar os Exercícios. Verifique a conexão e tente novamente');
                this.loading = false;
            });
            
    } else {
        this.alertService.warning('Precisa-se de no mínimo 3 caractéres');
    }
}

  getExercisesByNameSummation() {
    if(!this.userService.isAdmin()){
      if (this.filterNameSummation.length > 3) {
        this.loading = true;
        this.exerciseService.getExercisesByNameSummationAndSchool(this.filterNameSummation , this.userService.getUserSchool())
          .subscribe(res => {
            const response = res.body as ResponseApi;
  
            if (!response.error) {
              this.exercises = response.data as Exercise[];
            } else {
              this.alertService.error(response.error);
            }
  
            this.loading = false;
          }, err => {
            this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
            this.loading = false;
          });
      }
    }
    else{
    if (this.filterNameSummation.length > 3) {
      this.loading = true;
      this.exerciseService.getExercisesByNameSummation(this.filterNameSummation)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.exercises = response.data as Exercise[];
          } else {
            this.alertService.error(response.error);
          }

          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }
  }
  }

  getDisciplineExercises(disciplineId: number) {
    this.loading = true;
    this.exerciseService.getExercisesByDiscipline(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.exercises = response.data as Exercise[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os exercícios. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplineSummationExercises(disciplineId: number) {
    this.loading = true;
    this.summationService.getSummationExercisesByDiscipline(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.exercises = response.data as Exercise[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os exercícios. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  drop(event: CdkDragDrop<Exercise[]>) {
    moveItemInArray(this.selectedExercises, event.previousIndex, event.currentIndex);
  }

  getPlans(schoolId?: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getStudentsMocksAssociated(mockId: number) {
    this.selectedStudents = [];
    this.mockService.getStudentMocksAssociated(mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.selectedStudents = response.data as Student[];
        }
        else {

        }
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
      })
  }

  getMocksByName(mockName: string){
    this.mockService.getMocksByName(mockName)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.mocks = response.data as Mock[];
        this.mocksAux = [...this.mocks];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!')
    })
  }

  getMocksByNameNotAdmin(mockName: string){
    this.mockService.getMocksByNameNotAdmin(mockName , this.userService.getUserSchool())
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.mocks = response.data as Mock[];
        this.mocksAux = [...this.mocks];
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!')
    })
  }

}