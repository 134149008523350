<div *ngIf="deviceService.isDesktop()">
<ng-container>
  <embed [src]="slideFile?.file | safe" width="100%" height="500" alt="pdf">
</ng-container>
</div>

<div *ngIf="  !deviceService.isDesktop() ||

              (layoutService.isMobile && !layoutService.isTablet) ||

              (!layoutService.isMobile && layoutService.isTablet) ||

              (layoutService.isMobile && layoutService.isTablet) ||

              (!deviceService.isDesktop() && !layoutService.isMobile && !layoutService.isTablet) ||

              (!deviceService.isDesktop() && layoutService.isMobile && !layoutService.isTablet ) ||

              (!deviceService.isDesktop() && !layoutService.isMobile && layoutService.isTablet) ||

              (!deviceService.isDesktop() && layoutService.isMobile && layoutService.isTablet)"

  width="100%" height="500" class="mt-3 text-center no-desktop">
  <!-- <p>O seu navegador não possui a extensão adequada para este material.
    Clique <a [href]="slideFile?.file"> aqui para fazer o download.</a></p> -->
    <span class="row d-flex justify-content-center">Clique aqui para baixar o arquivo</span>
    <button mat-raised-button color="accent" (click)="downloadFile()" class="row mt-3 mb-2 w-30 pl-3 pr-3 pt-1 pb-1 small-btn size-button">
      <mat-icon class="mr-2">vertical_align_bottom</mat-icon>
      BAIXAR
    </button>
</div>
