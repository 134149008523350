<div *ngIf="editMode">
    <form [formGroup]="formEdit">
        <div class="page-container p-pg page-container-mobile">
            <div class="row m-0">
                <div class="col-md-12 col-12 col-lg-12">
                    <span class="title"><span><img class="certificate" src="assets/icons/certificate.svg"></span> Criar certificado</span>
                </div>
                <div class="col-md-12 col-12 col-lg-12 text-right">
                    <button (click)="saveChanges()" nbButton class="btn btn-accent bg-accent mr-10" nbSpinnerStatus="warning">Salvar</button>
                    <button (click)="closeDialog()" nbButton class="btn btn-secondary">Cancelar</button>
                </div>
            </div>
            <div class="page-container bg-white pt-4 pb-4 mt-5">
                <div class="row">
                    <div class="col-12"><h6 class="subtitle">Geral</h6></div>
                </div>
                <div class="row">
                    <div class="col-4" *ngIf="userService.isAdmin()">
                        <mat-form-field appearance="outline" class="width-100 mat-height">
                            <mat-select (selectionChange)="getUnitsSelection($event.value); getPlansSelection($event.value)" class="width-100" formControlName="schoolId" placeholder="Selecione a escola" matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                    <span [title]="school.name">{{school.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="userService.isGeneralCoordinator() || userService.isAdmin()">
                        <mat-form-field appearance="outline" class="width-100 mat-height">
                            <mat-select (selectionChange)="getClassesSelection($event.value)" class="width-100" formControlName="unitId" placeholder="Selecione a unidade" matTooltipClass="custom-tooltip" matTooltip="Selecione a unidade">
                                <mat-option *ngFor="let unit of units" [value]="unit.id">
                                    <span [title]="unit.name">{{unit.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="!userService.isTeacher()">
                        <mat-form-field appearance="outline" class="width-100 mat-height">
                            <mat-select class="width-100" formControlName="classId" placeholder="Selecione a turma" matTooltipClass="custom-tooltip" matTooltip="Selecione a turma">
                                <mat-option *ngFor="let class of classes" [value]="class.id">
                                    <span [title]="class.name">{{class.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="formWatched">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <mat-select (selectionChange)="getDisciplinesforVideos($event.value)" class="width-100" formControlName="planId" placeholder="Selecione o plano para criar o certificado" matTooltipClass="custom-tooltip" matTooltip="Selecione o plano para criar o certificado">
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                    <span [title]="plan.name">{{plan.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="formMock">
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <mat-select (selectionChange)="getMockByPlan($event.value);" class="width-100" formControlName="planId" placeholder="Selecione o plano para abrir os simulados" matTooltipClass="custom-tooltip" matTooltip="Selecione o plano para abrir os simulados">
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                    <span [title]="plan.name">{{plan.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <mat-select class="width-100" formControlName="mockId" (selectionChange)="getMockMaxQuestions($event.value)" placeholder="Selecione o simulado que você deseja criar o certificado" matTooltipClass="custom-tooltip" matTooltip="Selecione o simulado que você deseja criar o certificado">
                                <mat-option *ngFor="let mock of mocks" [value]="mock.id">
                                    <span [title]="mock.name">{{mock.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="formAll">
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <mat-select (selectionChange)="getMockByPlan($event.value); getDisciplinesforVideos($event.value)" class="width-100" formControlName="planId" placeholder="Selecione o plano para abrir os simulados" matTooltipClass="custom-tooltip" matTooltip="Selecione o plano para abrir os simulados">
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                    <span [title]="plan.name">{{plan.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <mat-select class="width-100" formControlName="mockId" (selectionChange)="getMockMaxQuestions($event.value)" placeholder="Selecione o simulado que você deseja criar o certificado" matTooltipClass="custom-tooltip" matTooltip="Selecione o simulado que você deseja criar o certificado">
                                <mat-option *ngFor="let mock of mocks" [value]="mock.id">
                                    <span [title]="mock.name">{{mock.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="page-container bg-white pt-4 pb-4 mt-5">
                <div class="row">
                    <div class="col-12"><h6 class="subtitle">Configurações do certificado</h6></div>
                </div>
                <div class="row mt-4">
                    <div class="col-12"><h6 class="description">Informação do certificado</h6></div>
                </div>
                <div class="row mt-3" *ngIf="totalMockQuestions && formMock || formAll">
                    <div class="col-12">
                        <label>Total de questões do simulado: {{totalMockQuestions}}</label>
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <input matInput placeholder="Número mínimo para liberar certificado" type="number" max="20" min="1" formControlName="minimumMock">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="totalVideosPlan && formWatched || formAll">
                    <div class="col-12">
                        <label>Total de Videos do plano: {{totalVideosPlan}}</label>
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <input matInput placeholder="Número mínimo para liberar certificado" type="number" max="20" min="1" formControlName="minimumVideo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12" *ngIf="!logo">
                        <label for="uploadLogo" class="width-100">
                            <div class="center mt-2 custom-input">
                                <span class="d-inline-flex" matTooltipClass="custom-tooltip" matTooltip="Upload de logo">Upload da logo</span>
                                <span class="btn btn-secondary btn-choice">Escolher</span>
                                <input style="display: none;" id="uploadLogo" (change)="handleFileInputLogo($event.target.files);" accept="image/*" fullWidth status="primary" type="file" nbInput placeholder="Upload de logo">
                            </div>
                        </label>
                    </div>
                    <div *ngIf="logo" class="col-12 text-center">
                        <h6>Upload da logo</h6>
                        <img class="redaction-preview mt-1"  [ngClass]="{'width-40': !layoutService.isMobile}" [src]="fileTemp" />
                    </div>
                </div>
                <div class="row mt-2" *ngIf="logo">
                    <div class="col-12 text-center">
                        <button (click)="cancelLogo()" class="btn-cancel" nbTooltip="Cancelar Arquivo" color="error">
                            <mat-icon class="cancel">cancel</mat-icon> <span class="text">Alterar Logo</span>
                        </button>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="width-100 max-height">
                            <input matInput placeholder="Nome de quem assina" formControlName="name">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="width-100 max-height" *ngFor="let more of inputs; let i = index">
                            <input matInput placeholder="Dados da pessoa (Opcional)" formControlName="dados{{i}}" *ngIf="3 > i">
                            <mat-icon (click)="moreLine()" class="bg-color-accent m-0 c-pointer"  matTooltipClass="custom-tooltip" matTooltip="Adicionar mais linhas" matSuffix>add</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="!signature">
                    <div class="col-12">
                        <label for="uploadSignature" class="width-100">
                            <div class="center mt-2 custom-input">
                                <span class="d-inline-flex" matTooltipClass="custom-tooltip" matTooltip="Upload da assinatura">Upload da assinatura</span>
                                <span class="btn btn-secondary float-right d-inline-flex btn-choice">Escolher</span>
                                <input style="display: none;" id="uploadSignature" (change)="handleFileInputSignature($event.target.files);" accept="image/*" fullWidth status="primary" type="file" nbInput placeholder="Upload da assinatura">
                            </div>
                        </label>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="signature">
                    <div class="col-12 text-center">
                        <h6>Upload da Assinatura</h6>
                        <img class="redaction-preview mt-1" [ngClass]="{'width-40': !layoutService.isMobile}" [src]="fileTempSignature" />
                    </div>
                </div>
                <div class="row mt-2 mb-4" *ngIf="signature">
                    <div class="col-12 text-center">
                        <button (click)="cancelSignature()" class="btn-cancel" nbTooltip="Cancelar Arquivo" color="error">
                            <mat-icon class="cancel">cancel</mat-icon> <span class="text">Alterar Assinatura</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
