import { Component, OnInit } from '@angular/core';
import { DisciplineService } from '../../admin/discipline/discipline.service';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { Discipline } from '../../admin/discipline/discipline';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Classroom } from '../classroom';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { AnimationOptions } from 'ngx-lottie';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-classroom-general-list',
  templateUrl: './classroom-general-list.component.html',
  styleUrls: ['./classroom-general-list.component.scss']
})
export class ClassroomGeneralListComponent implements OnInit {

  public studyPlans: StudyPlan[];
  public disciplines: Discipline[];
  public planSelected: string;
  public loadingPlans: boolean;
  public loadingClassRooms: boolean;
  public showClassrooms: boolean;
  public filterClass: string;
  public classrooms: Classroom[];
  public classroomsFilter : Classroom[];
  public classRoomDataSource: MatTableDataSource<Classroom>;
  public displayedColumns: string[];
  public firstPlan: StudyPlan;
  public order: string = 'classRoom.initDate';
  public reverse: boolean = false;
  public planId: number;

  public channelId: number;

  constructor(
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService,
    private alertService: AlertService,
    public userService: UserService,
    private router: Router,
    public layoutService: LayoutService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.classrooms = [];
    this.displayedColumns = ['name', 'date', 'link'];


    this.channelId = Number(this.route.snapshot.paramMap.get('channelId'));
    if(!this.channelId || this.channelId == undefined){ 
      this.getStudyPlans(this.userService.getUserId());
    }else{
      this.getChannelStudyPlans(this.channelId);
    }

    this.planId = Number(this.route.snapshot.paramMap.get('planId'));
  }

  getChannelStudyPlans(channelId: number) {
    this.studyPlanService.getChannelStudyPlans(channelId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.studyPlans = response.data as StudyPlan[];
          if(this.layoutService.isMobile) {
            this.firstPlan = this.studyPlans[0];
            this.getClassPlans(this.firstPlan.id);
          } else {
            this.getClassPlans(this.planId);
          }

          /* Quando retornar da página watch-video */
          if(this.planId && this.planId > 0){
            this.studyPlans.forEach(element => {
              if(element.id == this.planId){
                this.planSelected = element.name;
              }
            });
          }    
        } else {
          this.alertService.error(response.error);
        }

        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }



  getStudyPlans(studentId: number) {
    this.studyPlanService.getPlansClassrom(studentId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.studyPlans = response.data as StudyPlan[];
          if(this.layoutService.isMobile) {
            this.firstPlan = this.studyPlans[0];
            this.getClassPlans(this.firstPlan.id);
          } else {
            this.getClassPlans(this.planId);
          }

          /* Quando retornar da página watch-video */
          if(this.planId && this.planId > 0){
            this.studyPlans.forEach(element => {
              if(element.id == this.planId){
                this.planSelected = element.name;
              }
            });
          }    
        } else {
          this.alertService.error(response.error);
        }

        this.loadingPlans = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loadingPlans = false;
      });
  }

  selectPlan(studyPlan: StudyPlan) {
   /*  if(studyPlan.id){
      this.router.navigateByUrl('/classrom/generallist/' + studyPlan.id);
    } */
    this.planId = studyPlan.id;
    this.planSelected = studyPlan.name;

    this.getClassPlans(studyPlan.id);
    /* if(!this.channelId || this.channelId == undefined){ 
      this.getClassPlans(studyPlan.id);
    }else{
      this.getChannelStudyPlans(this.channelId);
    } */
    
  }

  getClassPlans(planId: number) {
    this.loadingClassRooms = true;
    //gerar os planos
    this.studyPlanService.getClassromPlans(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.classrooms = response.data as Classroom[];
          this.classRoomDataSource = new MatTableDataSource([...this.classrooms]);
          this.classroomsFilter = response.data as Classroom[];
        } else {
          this.alertService.error(response.error);
        }

        this.loadingClassRooms = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as salas. Verifique a conexão e tente novamente');
        this.loadingClassRooms = false;
      });
  }

  getOrder(el: string) {

    if (this.order === el) {
      this.reverse = !this.reverse;
    }
    this.order = el;
  }

  openClassLink(link: string, planId: number) {

    if (link.includes("video/watch/")) {
      if (this.channelId) {
        this.router.navigateByUrl(link +'/?planId='+ planId + '&channelId=' + this.channelId)
      } else {
        const url = link + '/?planId=' + planId;
        this.router.navigateByUrl(link +'/?planId='+ planId);
      }
    }else{
      window.open(link, '_blank');
    }
  }

  applyFilter() {

    if (this.filterClass) {
         this.classroomsFilter = [...this.classrooms].filter(classroom => 

          classroom.name.toLocaleLowerCase().indexOf(this.filterClass.toLowerCase()) > -1);
          this.classroomsFilter = ([...this.classroomsFilter]);

            this.classRoomDataSource = new MatTableDataSource([...this.classroomsFilter]);
    } else {
        this.classroomsFilter = [...this.classrooms];
        this.classRoomDataSource = new MatTableDataSource([...this.classrooms]);
    }
}

}
