import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Mock } from './mock';
import { BehaviorSubject, throwError } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MockService {

    private API = environment.ApiUrl;
    private selectedClassMockReport = new BehaviorSubject<number>(null);
    private selectedMockToReply = new BehaviorSubject<Mock>(null);

    constructor(
        private http: HttpClient,
        private alertService: AlertService) { }

    getPlanMocks(planId: number, contest?: boolean) {
        return this.http.post(this.API + '/mock/getPlanMocks', { planId, contest }, { observe: 'response' });
    }
    getPlanMocksEdit(planId: number, contest?: boolean) {
        return this.http.post(this.API + '/mock/getPlanMocksEdit', { planId, contest }, { observe: 'response' });
    }

    getMockQuestions(mockId: number, random?: boolean) {
        return this.http.post(this.API + '/mock/getMockQuestions', { mockId, random }, { observe: 'response' });
    }

    sendQuestionResponse(questionId: number, alternative: string, mockId: number, studentId: number, timeLeft: number) {
        return this.http.post(this.API + '/mock/saveQuestionResponse', { questionId, alternative, mockId, studentId, timeLeft },
            { observe: 'response' });
    }

    verifyResponseQuestion(mockId: number, questionId: number, studentId: number) {
        return this.http.post(this.API + '/mock/verifyResponseQuestion', { mockId, questionId, studentId }, { observe: 'response' });
    }

    getQuestionAlternativesNew(mockId: number, userId: number) {
        return this.http.post(this.API + '/mock/getQuestionAlternativesNew', { mockId, userId }, { observe: 'response' });
    }

    addMock(mock: Mock) {
        return this.http.post(this.API + '/mock/addMock', mock, { observe: 'response' });
    }

    getStudentMocksAssociated(mockId?: number,) {
        return this.http.post(this.API + '/mock/getStudentMocksAssociated', { mockId }, { observe: 'response' });
    }

    removeMock(mockId: number) {
        return this.http.post(this.API + '/mock/removeMock', { mockId }, { observe: 'response' });
    }

    getMocks(schoolId?: number) {
        return this.http.post(this.API + '/mock/getMocks', { schoolId }, { observe: 'response' });
    }

    getMocksClass(classid?: number) {
        return this.http.post(this.API + '/mock/getMocksClass', { classid }, { observe: 'response' });
    }

    getMockExercises(mockId: number) {
        return this.http.post(this.API + '/mock/getMockExercises', { mockId }, { observe: 'response' });
    }

    getMockPlans(mockId: number) {
        return this.http.post(this.API + '/mock/getMockPlans', { mockId }, { observe: 'response' });
    }


    getMockByPlan(planId: number) {
        return this.http.post(this.API + '/mock/getMockByPlan', { planId }, { observe: 'response' });
    }

    saveMockChanges(mock: Mock) {
        return this.http.post(this.API + '/mock/saveMockChanges', mock, { observe: 'response' });
    }

    finalizeMockTimeEnd(studentId: number, mockId: number) {
        return this.http.post(this.API + '/mock/finalizeMockQuestions', { studentId, mockId }, { observe: 'response' });
    }

    getTimeLeft(studentId: number, mockId: number) {
        return this.http.post(this.API + '/mock/getTimeLeft', { studentId, mockId, }, { observe: 'response' });
    }

    getMockReportClass(mockId: number, classId: number) {
        return this.http.post(this.API + '/mock/getMockReportClass', { mockId, classId }, { observe: 'response' });
    }

    getSelectedClassMockReportSubject() {
        return this.selectedClassMockReport.asObservable();
    }

    setSelectedClassMockReportSubject(classId: number) {
        this.selectedClassMockReport.next(classId);
    }
    getRelatoriosCSV(userData: any) {
        return this.http.post(this.API + '/report/reportCsvNovo', userData, { observe: 'response' });
    }

    reportCsvNovoRelSimulados(userData: any) {
        return this.http.post(this.API + '/report/reportCsvNovoRelSimulados', userData, { observe: 'response' });
    }

    getStudentAvailableMocks(studentId: number) {
        return this.http.post(this.API + '/mock/studentAvailableMocks', { studentId })
            .pipe(
                map((res: ResponseApi) => res?.error ? this.alertService.error(res.error) : res.data as Mock[]),
                catchError(err => this.handleError(err))
            );
    }

    private handleError(err: HttpErrorResponse, description?: string) {
        // tslint:disable-next-line: no-unused-expression
        description && this.alertService.error('Houve um erro ao ' + description);
        return throwError(err);
    }

    getSelectedMockToReply() {
        return this.selectedMockToReply.asObservable();
    }

    setSelectedMockToReply(mock: Mock) {
        this.selectedMockToReply.next(mock);
    }

    getMockNextQuestion(mockId: number, ignoreQuestions: number[], userId: number, random?: boolean) {
        return this.http.post(this.API + '/mock/getNextQuestion', { mockId, ignoreQuestions, userId, random }, { observe: 'response' });
    }

    verifyMockValidToInit(mockId: number, studentId: number) {
        return this.http.post(this.API + '/mock/verifyMockValidToInit', { mockId, studentId }, { observe: 'response' });
    }

    verifyMockResultIsReleased(mockId: number) {
        return this.http.post(this.API + '/mock/verifyMockResultIsReleased', { mockId }, { observe: 'response' });
    }

    verifyAllQuestionsAnswered(mockId: number , userId : number) {
      return this.http.post(this.API + '/mock/verifyAllQuestionsAnswered', { mockId , userId }, { observe: 'response' });
  }

    getPlanJustTests(planId: number) {
        return this.http.post(this.API + '/mock/getPlanJustTests', { planId }, { observe: 'response' });
    }

    getPlanJustMocks(planId: number) {
        return this.http.post(this.API + '/mock/getPlanJustMocks', { planId }, { observe: 'response' });
    }

    getMocksStudentUserAssociation(studentId: number) {
        return this.http.post(this.API + '/mock/getMocksStudentUserAssociation', { studentId }, { observe: 'response' });
    }

    getTestsStudentUserAssociation(studentId: number) {
        return this.http.post(this.API + '/mock/getTestsStudentUserAssociation', { studentId }, { observe: 'response' });
    }

    getMockGeneralReport(mockId: number) {
        const reportId = 13;
        return this.http.post(this.API + '/report/getReportByMockIdNovo', { mockId, reportId }, { observe: 'response' });

    }

    getClassPlansWithMocks(classId: number, studentId: number, test: boolean) {
        return this.http.post(this.API + '/mock/getClassPlansWithMocks', { classId, studentId, test }, { observe: 'response' });
    }

    getNotHiddenClassPlansWithMocks(classId: number, studentId: number, test: boolean) {
      return this.http.post(this.API + '/mock/getNotHiddenClassPlansWithMocks', { classId, studentId, test }, { observe: 'response' });
  }

    getMocksByName(mockName: string) {
        return this.http.get(this.API + '/mock/getMocksByName/'+mockName, { observe: 'response' });
    }

    getMocksByNameNotAdmin(mockName: string , schoolId: number) {
        return this.http.get(this.API + '/mock/getMocksByNameNotAdmin/'+mockName+'/'+schoolId, { observe: 'response' });
    }
    resetMock(mockId: number , userId: number) {
        return this.http.post(this.API + '/mock/resetMock', {mockId , userId} , { observe: 'response' });
    }
    getVideoResponse(exerciseId: number) {
        return this.http.post(this.API + '/mock/getVideoResponse', {exerciseId}, { observe: 'response' });
    }

    getVideoEnum(exerciseId: number) {
      return this.http.post(this.API + '/mock/getVideoEnum', {exerciseId}, { observe: 'response' });
  }
}
