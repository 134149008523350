import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { User } from '@sentry/angular';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';

import { AdminService } from '../../admin.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { Father } from '../create-father/father';
import { Student } from '../student';
import { StudentService } from '../student.service';

@Component({
    selector: 'app-create-student',
    templateUrl: './create-student.component.html',
    styles: ['.class-plan-tooltip {background-color: #b71c1c;}']
})
export class CreateStudentComponent implements OnInit, OnDestroy {

    public schools: School[];
    public units: Unit[];
    public classes: Class[];
    public classId: number;
    public createStudentForm: FormGroup;
    public students: Student[];
    public plans: StudyPlan[];
    public selectedPlans: StudyPlan[];
    public loading: boolean;
    public loadingPlans: boolean;
    public saving: boolean;
    public showStudentList: boolean;
    public editMode: boolean;
    public filterName: string;
    public filterEmail: string;
    public filterSchoolId: number;
    public filteredStudents: Student[];
    private admUpdate$: Subscription;
    public fatherList: Father[];
    public selectedFathers: User[];
    public isAdmOrCoord: boolean;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public student: Student,
        @Optional() public dialogRef: MatDialogRef<CreateStudentComponent>,
        private dialog: MatDialog,
        private studentService: StudentService,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        private studyPlanService: StudyPlanService,
        public userService: UserService,
        public maskService: MaskService) { }

    ngOnInit(): void {
        this.isAdmOrCoord = false;
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        // single student
        this.selectedPlans = [];
        this.createStudentForm = this.formBuilder.group({
            id: [],
            name: ['', Validators.required],
            email: ['', [Validators.email, Validators.required]],
            password: ['', Validators.required],
            school: ['', Validators.required],
            unit: ['', Validators.required],
            class: ['', Validators.required],
            plans: [''],
            active: [''],
            expireDays: [{ value: 7, disabled: true }, Validators.maxLength(5)],
            expire: [false],
            fatherName:['']
        });
        if (this.student) { this.initializeEditMode(); }
        this.getSchools();
        this.coordinatorInit();
    }

    coordinatorInit() {
        if (this.userService.isGeneralCoordinator()) {
            this.createStudentForm.get('school').setValue(this.userService.getUserSchool());
            this.getUnits(this.userService.getUserSchool());
        }

        if (this.userService.isUnitCoordinator()) {
            this.createStudentForm.get('school').setValue(this.userService.getUserSchool());
            this.createStudentForm.get('unit').setValue(this.userService.getUserUnit());
            this.getClasses(this.userService.getUserUnit());
        }
    }

    expireToggle(event: MatSlideToggleChange) {

        if (!event.checked) {
            this.createStudentForm.get('expireDays').disable();
            this.createStudentForm.get('expire').setValue(false);
        } else {
            this.createStudentForm.get('expireDays').enable();
            this.createStudentForm.get('expire').setValue(true);
        }
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getUnits(schoolId: number) {
        this.getPlans(schoolId); 
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getClasses(unitId: number) {
        this.schoolService.getClasses(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.classes = response.data as Class[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
    }

    createStudent() {
        if (this.createStudentForm.valid) {

            this.saving = true;

            const student = this.createStudentForm.getRawValue() as Student;

            this.studentService.createStudent(student)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Estudante adicionado');
                        this.createStudentForm.reset();
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.saving = false;
                }, err => {
                    this.alertService.error('Houve um erro ao cadastrar o estudante. Verifique a conexão e tente novamente');
                    this.saving = false;
                });
        } else {
            this.alertService.error('Preencha os campos corretamente');
        }
    }

    getStudents(classId: number) {
        // classId = this.userService.isGeneralCoordinator() ? this.userService.getUserSchool() : undefined;
        this.loading = true;
        this.classId = classId;
        this.studentService.getStudents(classId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.students = response.data as Student[];
                    this.filteredStudents = [...this.students];
                } else {
                    this.alertService.error(response.error);
                }
                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar os estudantes. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    openList() {
        if (this.userService.isUnitCoordinator()) {
            this.getClasses(this.userService.getUserUnit());
        }

        this.showStudentList = true;
    }

    editStudent(student: Student) {

        const dialogRef = this.dialog.open(CreateStudentComponent, {
            minWidth: '60vw',
            data: student,
            maxHeight: '600px',
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.getStudents(this.classId);
            }
        });
    }

    removeStudent(studentId: number) {
        if (confirm('Deseja realmente remover o Estudante?')) {
            this.studentService.removeStudent(studentId , this.userService.getUserProfileId())
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Estudante removida', 3);
                        this.getStudents(this.classId);
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover o estudante. Verifique a conexão e tente novamente'));
        }
    }

    initializeEditMode() {

        if(this.userService.isAdmin() || this.userService.isGeneralCoordinator){
            this.isAdmOrCoord = true;
        }
        this.getVinculatedFathers(this.student.id);
        this.student.school = Number(this.student.school);
        this.student.class = Number(this.student.class);

        this.editMode = true;
        this.createStudentForm.get('password').setValidators([]);
        this.createStudentForm.patchValue(this.student);
        this.createStudentForm.get('expire').setValue(this.student.expire);
        this.createStudentForm.get('unit').setValue(Number(this.student.unit));
        this.getUnits(this.student.school as number);
        this.getClasses(this.student.unit as number);
        this.getStudentPlans(this.student.id as number);
        this.student.expire ? this.createStudentForm.get('expireDays').enable() : this.createStudentForm.get('expireDays').disable();

        if(this.student.school){
            this.getPlans(this.student.school);
        } else if (this.userService.isAdmin() && !this.student.school) {
            this.getPlans();
        } else if (this.userService.isGeneralCoordinator()) {
            this.getPlans(this.userService.getUserSchool());
        } else if (this.userService.isUnitCoordinator()) {
            this.getUnitPlans(this.userService.getUserUnit());
        }
    }

    saveChanges() {

        if (this.createStudentForm.valid) {

            this.saving = true;
            const plansIds = [];
            this.selectedPlans.map(p => plansIds.push(p.id));
            this.createStudentForm.get('plans').setValue(plansIds);
            this.createStudentForm.get('id').setValue(this.student.id);
            const student = this.createStudentForm.getRawValue() as Student;

            this.studentService.saveStudentChanges(student)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Estudante editado com sucesso', 3);
                        this.dialogRef.close(true);
                        this.getStudents(this.classId);
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.saving = false;
                }, err => {
                    this.alertService.error('Houve um erro ao salvar as alterações. Verifique a conexão e tente novamente');
                    this.saving = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente!');
        }
    }

    getPlans(schoolId?: number) {
        this.studyPlanService.getSelectPlans(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
    }

    addPlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index < 0) {
            this.selectedPlans.push(plan);
            this.createStudentForm.get('plans').reset();
        }
    }

    removePlanChip(plan: StudyPlan) {
        const index = this.selectedPlans.indexOf(plan);

        if (index >= 0) {
            this.selectedPlans.splice(index, 1);
        }
    }

    getStudentPlans(studentId: number) {
        this.loadingPlans = true;
        this.studyPlanService.getStudentPlans(studentId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.selectedPlans = response.data as StudyPlan[];
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingPlans = false;
            }, err => {
                this.loadingPlans = false;
                this.alertService.error('Houve um erro ao carregar os planos do estudante. Verifique a conexão e tente novamente');
            });
    }

    filterByName() {

        if (this.filterName.length) {
            this.filterEmail = '';
            this.filteredStudents = [...this.students].filter(s => s.name.toLowerCase().indexOf(this.filterName.toLowerCase()) > -1);
        } else {
            this.filteredStudents = [...this.students];
        }
    }

    filterByEmail() {

        if (this.filterEmail.length) {
            this.filterName = '';
            this.filteredStudents = [...this.students].filter(s => s.email.toLowerCase().indexOf(this.filterEmail.toLowerCase()) > -1);
        } else {
            this.filteredStudents = [...this.students];
        }
    }

    disableStudent(studentId: number) {
        if (confirm('Deseja DESATIVAR o Estudente?')) {
            this.studentService.disableStudent(studentId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Estudante Desativado', 3);
                        this.getStudents(this.classId);
                        this.adminService.updateChields();
                        this.updateStudant(0);
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao desativar o estudante. Verifique a conexão e tente novamente'));
        }
    }

    enableStudent(studentId: number) {
        if (confirm('Deseja Ativar o Estudante?')) {
            this.studentService.enableStudent(studentId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Estudante Ativado', 3);
                        this.getStudents(this.classId);
                        this.adminService.updateChields();
                        this.updateStudant(1);
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao desativar o estudante. Verifique a conexão e tente novamente'));
        }
    }

    getStudentsByNameOrEmail(name?: string, email?: string) {

        if (!name && !email) {
            this.alertService.error('os campos devem ser prenchidos');
        }
        else {
            if (name && name.length < 3) {
                this.alertService.error('precisa-se no mínimo de 3 caracteres')
            }
            else if(name) {
                this.loading = true;
                this.studentService.getStudentsByNameOrEmail(name, email)
                    .subscribe(res => {
                        const response = res.body as ResponseApi;

                        if (!response.error) {
                            this.students = response.data as Student[];
                            this.filteredStudents = response.data as Student[];
                        } else {
                            this.alertService.error(response.error);
                        }

                        this.loading = false;
                    }, err => {
                        this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente');
                        this.loading = false;
                    });
            }
            if (email && email.length < 3) {
                this.alertService.error('precisa-se no mínimo de 3 caracteres')
            }
            else if(email){
                this.loading = true;
                this.studentService.getStudentsByNameOrEmail(name, email)
                    .subscribe(res => {
                        const response = res.body as ResponseApi;

                        if (!response.error) {
                            this.students = response.data as Student[];
                            this.filteredStudents = response.data as Student[];
                        } else {
                            this.alertService.error(response.error);
                        }

                        this.loading = false;
                    }, err => {
                        this.alertService.error('Houve um erro ao carregar os estudantes. Verifique a conexão e tente novamente');
                        this.loading = false;
                    });
            }
        }

    }

    getLostStudent(name?: string, email?: string) {
        let schoolId: number = null;
        let unitId: number = null;

        if (!name && !email) {
            this.alertService.error('os campos devem ser prenchidos');
            //console.log(name, 'nome variavel' , email , 'email variavel');
        }
        else {
            if (name && name.length < 3) {
                //console.log(name.length, 'tamanho do nome');
                //console.log('passou do if', name);
                this.alertService.error('precisa-se no mínimo de 3 caracteres')
            }
            else if(name) {

        if (this.userService.isGeneralCoordinator()) {
            schoolId = this.userService.getUserSchool();
        } else if (this.userService.isUnitCoordinator()) {
            schoolId = this.userService.getUserSchool();
            unitId = this.userService.getUserUnit();
        }

        this.loading = true;
        this.studentService.getLostStudent(name, email, schoolId, unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.students = response.data as Student[];
                    this.filteredStudents = response.data as Student[];
                    //console.info(response.data);
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar o estudante. Verifique a conexão e tente novamente');
                this.loading = false;
            });
        } if (email && email.length < 3) {
            this.alertService.error('precisa-se no mínimo de 3 caracteres')
        }
        else if(email){
            if (this.userService.isGeneralCoordinator()) {
                schoolId = this.userService.getUserSchool();
            } else if (this.userService.isUnitCoordinator()) {
                schoolId = this.userService.getUserSchool();
                unitId = this.userService.getUserUnit();
            }
    
            this.loading = true;
            this.studentService.getLostStudent(name, email, schoolId, unitId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;
    
                    if (!response.error) {
                        this.students = response.data as Student[];
                        this.filteredStudents = response.data as Student[];
                        //console.info(response.data);
                    } else {
                        this.alertService.error(response.error);
                    }
    
                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar o estudante. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        }
    }
}

    updateStudant(active: number) {
        this.student.active = active;
    }

    private getUnitPlans(unitId: number) {
        this.schoolService.getUnitPlans(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.plans = response.data as StudyPlan[]
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os planos da unidade. Verifique a conexão e tente novamente'));
    }

    searchFatherByName(){
        let name = this.createStudentForm.get('fatherName').value;
        this.userService.searchFatherByName(name , this.student.id)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.fatherList = response.data as Father[];
            }
            else{
                this.alertService.error(response.error);
            }
        }, err => {
            this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!')
        })
    }

    vinculateFather(fatherId : number){
        if(confirm('Deseja associar este Responsavel por você?')){
        this.userService.vinculateFather(fatherId , this.student.id)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.alertService.success(response.data.toString());
                if(this.createStudentForm.get('fatherName').value){
                    this.searchFatherByName();
                }
                else{
                    this.fatherList = [];
                    this.getVinculatedFathers(this.userService.getUserId());
                }
            }
            else{
                this.alertService.error(response.error);
            }
        }, err => {
            this.alertService.error('Não foi possivel cadastrar os dados verifique a conexão e tente novamente');
        })
    }
    }

    desvinculateFather(fatherId : number){
        if(confirm('Deseja Dessacociar este Responsavel por você?')){
            this.userService.desvinculateFather(fatherId , this.student.id)
            .subscribe(res => {
                const response = res.body as ResponseApi;
    
                if(!response.error){
                    this.alertService.success(response.data.toString());
                    if(this.createStudentForm.get('fatherName').value){
                        this.searchFatherByName();
                    }
                    else{
                        this.fatherList = [];
                        this.getVinculatedFathers(this.userService.getUserId());
                    }
                }
                else{
                    this.alertService.error(response.error);
                }
            }, err => {
                this.alertService.error('Não foi possivel cadastrar os dados verifique a conexão e tente novamente');
            })
        }
    }

    getVinculatedFathers(userId : number){
        this.userService.getVinculatedFathers(userId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if(!response.error){
                this.fatherList = response.data as Father[];
            }
            else{
                console.log(response.error);
            }
        })
    }
}
