<div *ngIf="!editMode" class="table-container table-container-discipline height-100">
  <table *ngIf="!loading && taskDataSource?.data?.length" class="table-discipline mb-3" mat-table [dataSource]="taskDataSource">
      <!-- Name -->
      <ng-container matColumnDef="name">
          <th class="mobile-th border-bottom-none disciplina brl-5 pl-3 background-color-accent" mat-header-cell *matHeaderCellDef>ATIVIDADE</th>
          <td mat-cell *matCellDef="let task; let i = index" class="font-table font-gray text-left pl-3 mr-10px" [ngClass]="{'table-line-contrast': i % 2}">
              <span class="d-flex"><mat-icon class="fs-30 mt--3px" [hidden]="!layoutService.isMobile">arrow_right</mat-icon>{{task.taskName}}</span>
          </td>
      </ng-container>
      <!-- Teacher -->
      <ng-container matColumnDef="teacher">
          <th mat-header-cell *matHeaderCellDef class="background-color-accent"> PROFESSOR </th>
          <td mat-cell *matCellDef="let task; let i = index" [ngClass]="{'table-line-contrast': i % 2}">{{task.teacherName}} </td>
      </ng-container>
      <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef class="background-color-accent"> NOTA</th>
          <td mat-cell *matCellDef="let task; let i = index" [ngClass]="{'table-line-contrast': i % 2}">{{task.score}} </td>
      </ng-container>
      <!-- Date -->
      <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="background-color-accent">DATA</th>
          <td mat-cell *matCellDef="let task; let i = index" [ngClass]="{'table-line-contrast': i % 2}"> {{task.dateSent | date: 'dd/MM/yyyy'}} </td>
      </ng-container>
      <!-- Action -->
      <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef class="mobile-th background-color-accent border-none brr-5 d-block min-w-95px bt-none">AÇÃO</th>
          <td mat-cell *matCellDef="let task; let i = index" class="d-block font-table min-w-95px" [ngClass]="{'table-line-contrast': i % 2}">
              <button mat-button class="vertical-align-baseline" color="accent" (click)="openTask(task)">
                  <strong class="font-table" color="accent">{{task.status}}</strong>
              </button>
          </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <!-- empty task -->
  <div *ngIf="!tasks && !loading" class="empty-tasks text-center mt-5">
      <h6 class="mb-2">Nenhuma tarefa enviada!</h6>
      <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
  </div>
  <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
</div>

<div class="center dialog" *ngIf="editMode">

    <div class="modal-header">
        <span class="page-title">Correção da tarefa</span>
        <button mat-icon-button class="close-button" (click)="closeModal()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

  <div *ngIf="!loading" class="row">
    
      <div class="col-md-6 pb-3">
          <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                         
              <div class="mt-3 mb-3 description-title">
                  <h4 *ngIf="!layoutService.isMobile">{{task.taskName}}</h4>
                  <h6 class="mb-0" *ngIf="layoutService.isMobile">{{task.taskName}}</h6>
              </div>
          </div>
          <div>Prof. {{task.teacherName}}</div>
          <div *ngIf="task.score">Nota: {{task.score}}</div>
          <div *ngIf="task.dateSent" > Data de envio: {{task.dateSent | date: 'dd/MM/yyyy'}}</div>
          <div *ngIf="task.dateTeacherDone" > Data de correção: {{task.dateTeacherDone | date: 'dd/MM/yyyy'}}</div>

          <div class="mt-3" *ngIf="isTaskAfile">
            <a mat-button (click)="openFile(task.taskDoneLink)" color="accent">
                <mat-icon>attach_file</mat-icon> Ver a tarefa enviada.
            </a>
          </div>

          <div class="teacher-observable p-3 mt-3">
              <h5 class="mb-2">Observação do professor</h5>
              <br />
              <p>{{task.taskDescription}}</p>
          </div>

          <!-- <div class="teacher-feedback p-3 mt-3" *ngIf="task.feedback || task.score; else waitingCorrection"> -->
          <div class="teacher-feedback p-3 mt-3" *ngIf="task.feedback">
            <h5 class="mb-2">Feedback</h5>
            <br />
            <p>{{task.feedback}}</p>
          </div>

          <div class="mt-3" *ngIf="embedcodeimage">
            <!-- <iframe [src]="embedcodeimage"></iframe> -->
            <button (click)="openImage()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading">Visualizar Imagem De Resolução</button>
            <!-- <img (click)="openImage()" [src]="embedcodeimage"> -->
          </div>
      </div>

      <div class="col-md-6 word-break">

          <!--<ng-template #waitingCorrection> -->
              <div class="text-center" *ngIf="!task.score; ">
                  <h5>Aguardando correção</h5>
              </div>
          <!-- </ng-template> -->

          <div *ngIf="!isTaskAfile" class="word-break" [ngClass]="{'mt-4 mb-3': layoutService.isMobile }">
            <div class="student-task-text p-3">
                <h4 class="mb-2">Texto enviado</h4>
                <br />
                <p *ngFor="let paragraph of taskTextParagraphs" >&emsp;{{paragraph}}</p>
            </div>
          </div>

      </div>
  </div>



  <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
</div>
