import { Component, Inject, OnInit, Optional } from '@angular/core';
import { inject } from '@angular/core/testing';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Discipline } from '../../discipline/discipline';
import { MyExerciseLibraryService } from '../my-exercise-library.service';

interface myVar{
  edit: boolean,
  data:any
}

@Component({
  selector: 'app-create-discipline-lib',
  templateUrl: './create-discipline-lib.component.html',
  styleUrls: ['./create-discipline-lib.component.scss']
})
export class CreateDisciplineLibComponent implements OnInit {

  public disciplineName: string;
  public disciplineTag: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public modalConf: any,
    @Optional() public dialogRef: MatDialogRef<CreateDisciplineLibComponent>,
    private myExerciseLibrary: MyExerciseLibraryService,
    public deviceService: DeviceDetectorService,
    private alertService: AlertService
  ) { }

  ngOnInit() {

    if(this.modalConf.edit){
      this.disciplineName = this.modalConf.discipline.name;
      this.disciplineTag = this.modalConf.discipline.tag;
    }
  }

  createDiscipline(name: string , tag: string){

    this.myExerciseLibrary.createDiscipline(name , tag)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('dados cadastrados com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  updateDiscipline(name: string , tag: string){
    this.myExerciseLibrary.updateDiscipline(this.modalConf.discipline.id , name , tag)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('dados atualizados com sucesso');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

}
