import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FlipBookService } from '../../flip-book/flip-book.service';
import { UserService } from '../../user/user.service';
import { School } from '../school/school';
import { SchoolService } from '../school/school.service';
import { StudyPlan, StudyPlansSelect } from '../study-plan/study-plan';
import { StudyPlanService } from '../study-plan/study-plan.service';
import { Ebook} from './flipBook';

@Component({
  selector: 'app-flip-book-admin',
  templateUrl: './flip-book-admin.component.html',
  styleUrls: ['./flip-book-admin.component.scss']
})
export class FlipBookAdminComponent implements OnInit {

  public schools: School[];
  public plans: StudyPlansSelect[];
  public book: any;
  public studyPlanArray: StudyPlansSelect[];
  public createEbookForm: FormGroup;
  public showList: boolean;
  public ebooks: Ebook[];
  public selectedPlanId: number;
  public editMode: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public ebook: Ebook,
    @Optional() public dialogRef: MatDialogRef<FlipBookAdminComponent>,
    private dialog: MatDialog,
    private userService: UserService,
    private alertService: AlertService,
    private schoolService: SchoolService,
    private studyPlanService: StudyPlanService,
    private formBuilder: FormBuilder,
    private flipBookService: FlipBookService
  ) { }

  ngOnInit() {

    this.editMode = false;
    this.showList = false;

    this.createEbookForm = this.formBuilder.group({
      id: [],
      schoolId: [Validators.required],
      name: ['', Validators.required],
      book: ['', Validators.required],
    });

    this.studyPlanArray = [];
    this.getSchools();

    if(this.ebook){
      this.editMode = true;
      this.createEbookForm.patchValue(this.ebook);
      this.createEbookForm.get('schoolId').setValue((Number)(this.ebook.schoolId));
      this.getEbookPlans(this.ebook.id);
    }

    if(!this.userService.isAdmin()){
      this.getPlans(this.userService.getUserSchool());
    }
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        }
        else {
          this.alertService.error('nenhuma escola encontrada');
        }
      }, err => {
        this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
      })
  }

  getPlans(schoolId: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlansSelect[];
        }
        else {
          this.alertService.error('não existem planos cadastrados nessa escola!');
        }
      }, err => {
        this.alertService.error('Não foi possível buscar os dados verifique a conexão e tente novamente!');
      })
  }

  addStudyPlanChip(planId: number) {
    this.plans.forEach(element => {
      if (element.id == planId) {
        this.studyPlanArray.push({ id: element.id, name: element.name });
      }

    });
  }

  removeSelectedStudyPlan(id: number) {
    this.studyPlanArray.forEach(element => {
      if (element.id == id) {
        let index = this.studyPlanArray.indexOf(element);
        this.studyPlanArray.splice(index, 1);
      }

    });
  }

  handleFileInput(files: FileList): void {
    if (files && files[0]) {

      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.book = reader.result;
      };
    }
  }

  CreateEBook() {
    let ebook = this.createEbookForm.getRawValue() as Ebook;

    //ebook.plans = this.studyPlanArray;

    if(this.userService.isAdmin()){
    ebook.book = this.book;

    ebook.user_id = this.userService.getUserId();

    if (ebook.name && ebook.schoolId && ebook.book && this.studyPlanArray[0]?.name) {
      this.flipBookService.CreateEBook(ebook, this.studyPlanArray)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (response) {
            this.createEbookForm.reset();
            this.studyPlanArray = [];
            this.alertService.success('Ebook criado com sucesso!');
          }
        }, err => {
          this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!');
        })
    }
    else{
      this.alertService.error('Não foi possivel cadastrar o ebook verifique se todos os campos foram prenchidos!');
    }
  }

  else{
    //ebook.plans = this.studyPlanArray;
    ebook.book = this.book;

    ebook.user_id = this.userService.getUserId();

    ebook.schoolId = this.userService.getUserSchool();

    if (ebook.name && ebook.schoolId && ebook.book && this.studyPlanArray[0]?.name) {
      this.flipBookService.CreateEBook(ebook, this.studyPlanArray)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (response) {
            this.createEbookForm.reset();
            this.studyPlanArray = [];
            this.alertService.success('Ebook criado com sucesso!');
          }
        }, err => {
          this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!');
        })
    }
    else{
      this.alertService.error('Não foi possivel cadastrar o ebook verifique se todos os campos foram prenchidos!');
    }
  }
}

  openList() {
    this.createEbookForm.reset();
    this.showList = true;
  }

  btnBack() {
    this.showList = false;
  }

  getEbooks(planId: number) {
    this.flipBookService.getEbooks(planId)
      .subscribe(res => {
        const response = res.body as any;

        if (!response.error) {
          this.ebooks = response as Ebook[];
        }
        else {
          this.ebooks = [];
          this.alertService.error(response.error);
        }
      }, err => {
        this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!');
      })
  }

  deleteEbook(ebookId : number){
    if(confirm('Deseja excluir o ebook?')){
    this.flipBookService.DeleteEbook(ebookId)
    .subscribe(res => {
      const response = res.body as any;

      if(!response.error){
        this.getEbooks(this.selectedPlanId);
        this.alertService.success('Ebook Deletado com sucesso!');
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente!');
    })
  }
  }

  openEditMode(ebook: Ebook){
    const dialogRef = this.dialog.open(FlipBookAdminComponent, {
      minWidth: '60vw',
      data: ebook
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        console.log(this.selectedPlanId);
        this.getEbooks(this.selectedPlanId);
        this.editMode = false;
      }
    });
  }

  getEbookPlans(ebookId: number){
    this.flipBookService.getEbookPlans(ebookId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.studyPlanArray = response.data as StudyPlansSelect[];
      }
    },err => {
      this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente');
    })
  }

  updateEbook(){
    this.ebook = this.createEbookForm.getRawValue() as Ebook;
    this.ebook.user_id = this.userService.getUserId();

    this.flipBookService.updateEbook(this.ebook , this.studyPlanArray)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success(response.data.toString());
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    }, err => {
      this.alertService.error('Não foi possivel buscar os dados verifique a conexão e tente novamente');
    })
  }

}
