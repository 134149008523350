import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
    NbAccordionModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbListModule,
    NbProgressBarModule,
} from '@nebular/theme';
import { NgxEchartsModule } from 'ngx-echarts';

import { StudyPlanCalendarComponent } from './student/calendar/study-plan-calendar.component';
import { ChartDonutComponent } from './student/charts/donut/chart-donut.component';
import { LegendChartComponent } from './student/charts/legend/legend-chart.component';
import { LineChartComponent } from './student/charts/line/line-chart.component';
import { DashboardStudentComponent } from './student/dashboard-student.component';
import { ScheduleDayComponent } from './student/day-videos/schedule-day.component';
import { AverageChartComponent } from './student/charts/average/average-chart.component';
import { DashboardDisciplineCardReportComponent } from './student/discipline-card-report/dashboard-discipline-card-report.component';
import { ClassroomModule } from '../classroom/classroom.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CalendarModule } from '../calendar/calendar.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { MonitoryModule } from '../monitory/monitory.module';
import { StudyPlanModule } from '../study-plan/study-plan.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatCardModule } from '@angular/material/card';
import { ProgressBarComponent } from './student/charts/progress-bar/progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from 'src/app/shared/shared.module';
import { LiveClassComponent } from './student/live-class/live-class.component';
import { NotificationsComponent } from './student/notifications/notifications.component';


@NgModule({
    declarations: [
        DashboardStudentComponent,
        StudyPlanCalendarComponent,
        ScheduleDayComponent,
        ChartDonutComponent,
        LegendChartComponent,
        LineChartComponent,
        AverageChartComponent,
        DashboardDisciplineCardReportComponent,
        ProgressBarComponent,
        LiveClassComponent,
        NotificationsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatListModule,
        MatTooltipModule,
        NbAccordionModule,
        NbListModule,
        NbButtonModule,
        NbIconModule,
        NgxEchartsModule,
        NbCardModule,
        NbAlertModule,
        NbProgressBarModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatChipsModule,
        ClassroomModule,
        MatInputModule,
        MatSelectModule,
        CalendarModule,
        MatDatepickerModule,
        FormsModule,
        MonitoryModule,
        StudyPlanModule,
        IvyCarouselModule,
        MatCardModule,
        MatProgressBarModule,
        SharedModule
    ],
    exports: [],
    providers: []
})
export class DashboardModule { }
