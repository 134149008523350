import { TaskEditComponent } from './../task-edit/task-edit.component';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AdminService } from '../../admin.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { TaskManagerService } from '../task-manager.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Task } from '../task';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';



@Component({
  selector: 'app-task-sent',
  templateUrl: './task-sent.component.html',
  styleUrls: ['./task-sent.component.scss']
})
export class TaskSentComponent implements OnInit {

  public tasks: Task[] = [];
  public sentTasks: any;
  public classes: Class[];
  public plans: StudyPlan[];
  public teachers: any[] = [];
  public loading: boolean =  false;
  public show: boolean;
  public form: FormGroup;
  public teacherId: number;
  public schools: School[];
  public units: Unit[];
  public classId : number;
  public planId : number;
  public planName : string;
  public taskLink: string;



  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public Task : Task,
    @Optional() public dialogRef: MatDialogRef<TaskEditComponent>,
    private dialog: MatDialog,
    public taskManagerService: TaskManagerService,
    private schoolService: SchoolService,
    private studyPlanService: StudyPlanService,
    public matInputModule: MatInputModule,
    public matFormFieldModule: MatFormFieldModule,
    public maskService: MaskService,
    private formBuilder: FormBuilder,
    public userService: UserService,
    private adminService: AdminService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      classId: ['', Validators.required],
      taskId: [''],
      taskDate: [''],
      planId: ['']
    });

    if(this.userService.isAdmin()){
      this.getSchools();

      //this.getAllTeachers();//não deve ser chamado mais aqui
      // 1) Admin Select Escola para buscar as unidades
      // 2) Unidade para puxar os professores
    }else if(this.userService.isGeneralCoordinator()){
      this.getUnits(this.userService.getUserSchool());

      // 1) Coord Geral Ja tem um id de escola cadastrado
      //  this.userService.getUserSchool()
      // 2) Unidade para puxar os professores
      //   buscar as unidades da escola deste coord geral
    } else if (this.userService.isUnitCoordinator()) {
      this.getTeacherUnit(this.userService.getUserUnit());
     }
     if (this.userService.isTeacher()) {
      this.getTeacher();
      this.getTeacherClasses(this.userService.getUserId());
      this.getPlansBySchoolAndTeacher(Number(this.userService.getUserSchool()), Number(this.userService.getUserId()));
    }
  }


  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
          //this.filterSchools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getUnits(schoolId: number) {
    this.schoolService.getUnits(schoolId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.units = response.data as Unit[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as unidades. Verifique a conexão e tente novamente'));
  }

  getPlansBySchoolAndTeacher(schoolId: number, teacherId:number) {
    this.studyPlanService.getPlansBySchoolAndTeacher(schoolId, teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }

  getTasksSubmittedByPlan(planId: number) {
    this.tasks = [];
    this.loading = true;
    this.plans.forEach(element => { if(element.id == planId) this.planName = element.name; });
    this.taskManagerService.getTasksSubmittedByPlan(planId, Number(this.userService.getUserId()))
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.tasks = response.data as Task[];
        } else {
          this.tasks = [];
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente'));
  }


  getAllTeachers(){
    const schoolId = this.userService.getUserSchool();
    this.taskManagerService.getAllTeachers(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.teachers = response.data as [];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar os professores. Verifique a conexão e tente novamente'));
  }

  getTeacher(){
    const teacherId = this.userService.getUserId();
    this.taskManagerService.getTeacher(teacherId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if (!response.error) {
        this.tasks = response.data as [];
      } else {
        this.alertService.error(response.error);
      }
    }, err => this.alertService.error('Houve um erro ao buscar o professor. Verifique a conexão e tente novamente'));
  }

  getClasses(unitId?: number) {
    this.schoolService.getClasses(unitId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente'));
  }

  getClassesByTeacher(teacherId:number) {
    this.teacherId = teacherId;
    this.taskManagerService.getClassesByTeacher(teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as any;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente'));
  }

  getTeacherUnit(unitId:number) {
    this.taskManagerService.getTeacherUnit(unitId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.teachers = response.data as any;
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente'));
  }


  getTeacherClasses(teacherId?: number) {
    this.teacherId = teacherId;
    this.schoolService.getTeacherClasses(teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
                this.classes = response.data as Class[];
            } else {
                this.alertService.error(response.error);
            }
        }, err => this.alertService.error('Houve um erro ao carregar os professores. Verifique a conexão e tente novamente'));
  }

  getTasks(classId: any){
    this.classId = classId;
    this.loading = true;
    this.tasks = [];
    this.taskManagerService.getTasksAll(classId, this.teacherId)
        .subscribe(res => {
            const response = res.body as ResponseApi;

            if (!response.error) {
              this.tasks = response.data as Task[];
              //this.className = className;
            } else {
              this.tasks = [];
              this.alertService.error(response.error);
            }
            this.loading = false;
        }, err =>
        { this.loading = false;
          this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente')
        });
  }

  getTasksCoord(){
    this.loading = true;
    this.taskManagerService.getTasksCoord(this.userService.getUserId())
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.tasks = response.data as Task[];
        } else {
          this.tasks = [];
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err =>
      { this.loading = false;
        this.alertService.error('Houve um erro ao carregar as tarefas. Verifique a conexão e tente novamente')
      });
  }

  getTaskSent(id: number){

    this.loading = true;
    this.taskManagerService.getTaskSent(id)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
            this.sentTasks = response.data as any;

        } else {
            this.sentTasks = [];
            this.alertService.error(response.error);
        }
        this.loading = false;
    }, err => {
        this.loading = false;
        this.alertService.error('Houve um erro ao carregar as turmas. Verifique a conexão e tente novamente')
      });

  }

  editTasks(task? : Task){
    const dialogRef = this.dialog.open(TaskEditComponent, {
      minWidth: '60vw',
      data: task
    });
    dialogRef.afterClosed().subscribe(() => {
        //this.getTasks(this.classId);
        this.form.reset();
    });
  }

  remove(id: number){
    if (confirm('Deseja realmente apagar a tarefa?')) {
      this.taskManagerService.removeTask(id)
        .subscribe(res => {
          const response = res.body as ResponseApi;

          if (!response.error) {
            this.alertService.success('Tarefa removida', 3);
            this.tasks = [];
            this.adminService.updateChields();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao excluir a tarefa. Verifique a conexão e tente novamente'));
    }

  }

  openLink(url: string){
    window.open(url, '_blank');
  }
}
