import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-live-class',
  templateUrl: './live-class.component.html',
  styleUrls: ['./live-class.component.scss']
})
export class LiveClassComponent implements OnInit {


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: string,
    @Optional() public dialogRef: MatDialogRef<LiveClassComponent>,
    public layoutService: LayoutService,
    public userService: UserService,
    public router: Router,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
  }


  openLive() {
    window.open(this.data, '_blank');
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
