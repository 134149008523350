// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'http://localhost:4200',
  //ApiUrl: 'http://localhost:8000',
  ApiUrl: 'https://api-ntm.aletech.com.br',
  //ApiUrl: 'https://api-dev-ntm.aletech.com.br',
  //ApiUrl: 'https://api-homolog.notamaxima.com.br',
  SentryDNS:"https://f17a7e061af043c2bc02a25e4f8b22f4@o947105.ingest.sentry.io/5909253",
  VideoUrl: {
    vimeo: 'https://player.vimeo.com/video/',
    youtube: 'https://www.youtube.com/embed/',
    panda:'https://player-vz-6035ade3-139.tv.pandavideo.com.br/embed/?v='
  },
  agora: {
    appId: '40dfc55f9d794acf8838726b0255c9b0'
  },
  hmr: false
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

