import { Task } from './../task';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NbDialogRef } from '@nebular/theme';
import { ResponseApi } from 'src/app/core/models/response-api';
import { TaskStudentService } from 'src/app/modules/task-student/task-student.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LayoutService } from 'src/app/shared/services/layout.service';



@Component({
  selector: 'app-correct-task',
  templateUrl: './correct-task.component.html',
  styleUrls: ['./correct-task.component.scss']
})

export class CorrectTaskComponent implements OnInit {

  @Input() public task: Task;
  @Input() public dialogClass: string;

  public text: string = "";
  public isTaskAfile: boolean = true;
  public loading: boolean = false;
  public score: string = "";
  public feedb: string = "";
  public form: FormGroup;

  constructor(
    public dialogRef: NbDialogRef<CorrectTaskComponent>,
    public layoutService: LayoutService,
    public alertService: AlertService,
    public taskStudentService: TaskStudentService,
    private formBuilder: FormBuilder,) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      score:[''],
      feed: [''],
    });

    if (this.task && this.task.taskDoneText &&  this.task.taskDoneText !== "") {
      console.log("Task is a text, not a file :", this.task.taskDoneText);
      this.text = this.task.taskDoneText;
      this.isTaskAfile = false;
    }

  }

  close() {
    this.dialogRef.close();
  }

  openFile(url: string){
    window.open(url, '_blank');
  }

  save(taskId: number, studentId:number, studentName:string) {

    this.task.feedback = this.feedb;
    this.task.score = (this.score !== "") ? parseInt(this.score)  : null ;

    if (this.score !== "" && this.task.feedback !== "" ) {
      this.loading = true;

      this.taskStudentService.submitTaskCorrection(taskId, studentId, studentName,  this.task)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {

            this.alertService.success('Correcão enviada!');
            this.dialogRef.close(this.task);

            //this.adminService.updateChields();
            //this.taskStudentService.emitSentTask.emit('done');
          } else {
            this.alertService.error(response.error);
          }
          this.loading = false;
        }, err => {
          this.alertService.error('Houve um erro ao enviar a correção. Verifique a conexão e tente novamente');
          this.loading = false;
        });
    }

    if (this.score == "" || this.task.feedback == "" ) {
      this.alertService.warning('Você precisa adicionar uma nota e um feedback!');
    }

  }

}
