<div *ngIf="!showSchoolList">

    <form [formGroup]="createRouteForm">

        <div class="row">
            <div class="col-md">
                <mat-form-field class="width-100">
                    <input matInput placeholder=Nome formControlName="descr">
                </mat-form-field>
            </div>

            <div class="col-md">
                <mat-form-field class="width-100">
                    <mat-select formControlName="order" placeholder="Ordem(Opcional)">
                        <mat-option *ngFor="let orderroot of orderroots " [value]="orderroot">
                            <div class="row">
                                <div class="col-10">
                                    {{orderroot}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder=Link formControlName="link">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select formControlName="schools" (selectionChange)="addSchollChip($event.value)" placeholder="Escola" multiple>
                            <mat-option  *ngFor="let school of schools" [value]="school.id">
                                {{school.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedschools?.length > 0 && isAdm">
                    <mat-label class="mb-2">Escolas selecionadas</mat-label>
                    <mat-chip-list>
                        <mat-chip *ngFor="let school of selectedschools" selectable="true" removable="true" (removed)="removeSChoollChip(school)">
                            {{school.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <mat-form-field class="width-100">
                    <mat-select #iconSelected formControlName="icon" placeholder="Icone">
                        <mat-select-trigger>
                            <mat-icon>{{iconSelected?.value}}</mat-icon>
                        </mat-select-trigger>
                        <mat-option *ngFor="let icon of icons" [value]="icon">
                            <mat-icon>{{icon}}</mat-icon>{{icon}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>

<div *ngIf="editMode == false" class="row mt-5">
    <div class=" col-6 width-100">
        <button (click)="editMode = true" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver rotas
            cadastradas</button>
    </div>
    <div class=" col-6 text-right width-100">
        <button (click)="createRoute()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Cadastrar</button>
    </div>
</div>

<div *ngIf="editMode == true" class="row mt-5">
    <div class=" col-6 width-100">
        <button (click)="openList()" nbButton size="small" outline status="primary">Filtrar por rotas</button>
    </div>
    <div class=" col-6 text-right width-100">
        <button (click)="openSchoolList()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Filtrar por escolas</button>
    </div>
</div>

<div *ngIf="showSchoolList && filtterRoute == true" class="">
    <nb-list class="width-90 mt-3 center">
        <nb-list-item *ngFor="let route of routes">
            <div class="row">
                <div class="col-8">
                    {{route.descr}}
                </div>
                <div class="col-2 text-right">
                    <button (click)="editRoute(route)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-2 text-right">
                    <button size="tiny" (click)="removeRoute(route.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="text-right width-100">
        <button (click)="btnVoltar()" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>
<div *ngIf="showSchoolList && filterSchool == true" class="">
    <nb-list class="width-90 mt-3 center">
        <nb-list-item *ngFor="let school of schools">
            <div class="row">
                <div class="col-8">
                    {{school.name}}
                </div>
                <div class="col-2 text-right">
                    <button (click)="editSchoolRoutes(school)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="text-right width-100">
        <button (click)="btnVoltar()" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>
