import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { MainTheme } from '../rdo-manager/create-theme/theme';
import { libAlternatives, libInstitution, libQuestions, libQuestionType, libSubTheme, libTheme } from './lib-interfaces';

@Injectable({
  providedIn: 'root'
})
export class MyExerciseLibraryService {

  private API = environment.ApiUrl;
  private updateSubject = new BehaviorSubject<Date>(new Date());

  constructor(private http: HttpClient) { }

  getMocks(schoolId?: number) {
    return this.http.post(this.API + '/library/getMock', {}, { observe: 'response' });
  }

  createDiscipline(name: string, tag?: string) {
    return this.http.post(this.API + '/library/create/discipline', { name, tag }, { observe: 'response' });
  }

  getDisciplines(offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/disciplines', {offset , limit}, { observe: 'response' });
  }

  getDisciplinesByName(name: string, offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/disciplinesByName', {name ,offset , limit}, { observe: 'response' });
  }

  updateDiscipline(id: number, name: string, tag?: string) {
    return this.http.post(this.API + '/library/update/discipline', { id, name, tag }, { observe: 'response' });
  }

  deleteDiscipline(disciplineId: number) {
    return this.http.post(this.API + '/library/remove/discipline', { disciplineId }, { observe: 'response' });
  }

  getQuestions(offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/question', {offset , limit}, { observe: 'response' });
  }

  getQuestionsByName( name:string ,offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/questionByName', {name ,offset , limit}, { observe: 'response' });
  }

  createQuestion(question: libQuestions) {
    return this.http.post(this.API + '/library/create/question', question, { observe: 'response' });
  }

  createQuestionSummation(question: libQuestions) {
    return this.http.post(this.API + '/library/create/questionSummation', question, { observe: 'response' });
  }

  updateQuestion(question: libQuestions) {
    return this.http.post(this.API + '/library/update/question', question, { observe: 'response' });
  }

  updateQuestionSummation(question: libQuestions) {
    return this.http.post(this.API + '/library/update/questionSummation', question, { observe: 'response' });
  }

  deleteQuestion(questionId: number) {
    return this.http.post(this.API + '/library/delete/question', { questionId }, { observe: 'response' });
  }

  getThemes(offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/themes', {offset , limit}, { observe: 'response' });
  }

  getThemesByName(name: string ,offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/themesByName', {name, offset , limit}, { observe: 'response' });
  }

  createTheme(themeName: string, disciplineId: number) {
    return this.http.post(this.API + '/library/create/themes', { themeName, disciplineId }, { observe: 'response' });
  }

  updateTheme(theme: libTheme) {
    return this.http.post(this.API + '/library/update/theme', theme, { observe: 'response' });
  }

  deleteTheme(themeId: number) {
    return this.http.post(this.API + '/library/delete/theme', { themeId }, { observe: 'response' });
  }

  getAlternatives(questionId: number) {
    return this.http.post(this.API + '/library/get/alternatives', { questionId }, { observe: 'response' });
  }

  createAlternatives(alternative: libAlternatives) {
    return this.http.post(this.API + '/library/create/alternatives', alternative, { observe: 'response' });
  }

  updateAlternatives(alternative: libAlternatives) {
    return this.http.post(this.API + '/library/update/alternative', alternative, { observe: 'response' });
  }

  deleteAlternatives(alternativeId: number) {
    return this.http.post(this.API + '/library/delete/theme', { alternativeId }, { observe: 'response' });
  }

  getInstitution(offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/institutions', {offset , limit}, { observe: 'response' });
  }

  getInstitutionByName(name:string ,offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/institutionsByName', {name,offset , limit}, { observe: 'response' });
  }

  createInstitution(institutionName: string) {
    return this.http.post(this.API + '/library/create/institutions', { institutionName }, { observe: 'response' });
  }

  updateInstitution(institution: libInstitution) {
    return this.http.post(this.API + '/library/update/institutions', institution, { observe: 'response' });
  }

  deleteInstitution(institutionId: number) {
    return this.http.post(this.API + '/library/delete/institutions', { institutionId }, { observe: 'response' });
  }

  getMocksLib(offset: number, limit: number, schoolId?: number) {
    return this.http.post(this.API + '/library/get/MocksLib', { offset, limit, schoolId }, { observe: 'response' });
  }

  getMocksLibByName(name: string, offset: number, limit: number , schoolId?: number) {
    return this.http.post(this.API + '/library/get/MocksLibByName', {name , offset, limit , schoolId}, { observe: 'response' });
  }

  getQuestionType() {
    return this.http.post(this.API + '/library/get/questiontypes', {}, { observe: 'response' });
  }

  createQuestionType(questionTypeName: string) {
    return this.http.post(this.API + '/library/create/questiontypes', { questionTypeName }, { observe: 'response' });
  }

  updateQuestionType(questionType: libQuestionType) {
    return this.http.post(this.API + '/library/update/questiontypes', questionType, { observe: 'response' });
  }

  deleteQuestionType(typeId: number) {
    return this.http.post(this.API + '/library/delete/questiontypes', { typeId }, { observe: 'response' });
  }

  getSubTheme(offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/subtheme', {offset , limit}, { observe: 'response' });
  }

  getSubThemeByName(name: string ,offset: number , limit: number) {
    return this.http.post(this.API + '/library/get/subthemeByName', {name, offset , limit}, { observe: 'response' });
  }

  createSubTheme(subThemeName: string, disciplineId: number, themeId: number) {
    return this.http.post(this.API + '/library/create/subtheme', { subThemeName, disciplineId, themeId }, { observe: 'response' });
  }

  updateSubTheme(subTheme: libSubTheme) {
    return this.http.post(this.API + '/library/update/subtheme', subTheme, { observe: 'response' });
  }

  deleteSubTheme(subId: number) {
    return this.http.post(this.API + '/library/delete/subtheme', { subId }, { observe: 'response' });
  }
}
