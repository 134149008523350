import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private API = environment.ApiUrl;
  public emitCheckNotifications = new EventEmitter<string>();
  public emitCheckIntegrations = new EventEmitter<[{ link: string, name: string, image: string , title:string }]>();

  constructor(private http: HttpClient) { }

  getRdoLink(userId:number, userProfileId:number) {
    let token = btoa(userId+':'+userProfileId);
    return this.http.post(this.API + '/rdo/link/', {token}, { observe: 'response' });
  }
  getUniLink(userId:number, userProfileId:number) {
    let token = btoa(userId+':'+userProfileId);
    return this.http.post(this.API + '/uni/link/', {token}, { observe: 'response' });
  }

  getSerenLink(userId:number, classId:number, seren: string, email:string) {
    //let token = btoa(userId+':'+userProfileId);
    return this.http.get(this.API + '/integrations/seren?userId='+userId+'&classId='+classId+'&seren='+seren+'&email='+email, { observe: 'response' });
  }

  /*
  getNotificationsBySchool(schoolId:number, userId:number, profile: number) {
    return this.http.post(this.API + '/notifications/bySchool/', {schoolId, userId, profile}, { observe: 'response' });
  }*/

  /* ALL NOTIFICATIONS
  getAllNotifications(schoolId:number, userId:number, profile: number) {
    return this.http.post(this.API + '/notifications/getAllNotifications', {schoolId, userId, profile}, { observe: 'response' });
  }*/

  getConvergindoLink(userId:number, userProfileId:number) {
    let token = btoa(userId+':'+userProfileId);
    return this.http.get(this.API + '/integrations/convergindo/link',  { observe: 'response',   params : {token} });
  }

  getNotificationsPerPlan(userId:number) {
    return this.http.post(this.API + '/notifications/plan/', {userId}, { observe: 'response' });
  }
  getNotificationsBySchool(schoolId:number, userId:number, profileId: number) {
    return this.http.get(this.API + '/notifications/school?schoolId='+schoolId+'&userId='+userId+'&profileId='+profileId, { observe: 'response' });
  }

  /* ALL NOTIFICATIONS */
  getAllNotifications(schoolId:number, userId:number, profile: number) {
    return this.http.post(this.API + '/notification/getAllNotifications/', {schoolId, userId, profile}, { observe: 'response' });
  }

}
