<div class="page-container p-pg page-container-mobile discipline">
  <div class="row m-0">
      <div class="col-md-12 mt-2">
          <ng-container>
              <div class="page-title-container pb-1"  [ngClass]="{'text-center': layoutService.isMobile}">
                  <span class="page-title">NOTIFICAÇÕES</span>
                  <div class="mt-3 description-title">
                      <h4 *ngIf="!layoutService.isMobile">Minhas notificações</h4>
                      <h6 class="mb-0" *ngIf="layoutService.isMobile">Minhas notificações</h6>
                      <!-- <p class="fs-13 pb-0">&nbsp;</p> -->
                  </div>
              </div>
          </ng-container>
      </div>
  </div>
  <mat-accordion class="m-3 mt-2">
    <mat-expansion-panel *ngFor="let notification of notifications | paginate: {itemsPerPage: 10, currentPage: currentPage, totalItems: totalRows}" class="notification p-2 m-2 mt-2 box-shadow-none" [ngClass]="{'bgRead': notification.read, 'bgUnread': !notification.read}" hideToggle>
        <mat-expansion-panel-header class="bgTransparente size-header">
            <mat-panel-title class="d-block">
                <!-- <span class="img-user" [ngStyle]="{'background-image': 'url('+ userImage +')'}"></span> -->
                <img class="img-user" src="{{notification.create_user_image}}" alt="Imagem do autor da notificação"/>
                <span class="name pr-10">{{userName}}</span>
                <span class="date">{{notification.init}}</span>
                <p class="notifs-title">{{notification.title}}</p>
            </mat-panel-title>
            <div class="text-right p-2" *ngIf="notification.read">
                <button mat-raised-button [ngClass]="{'bgWhite': notification.read}" class="small-btn br-5" disabled>Lida</button>
            </div>
            <div class="text-right p-2" *ngIf="!notification.read">
                <button (click)="markAsReadNotification(notification)" mat-raised-button [ngClass]="{'bgWhite disabled': notification.read}" class="small-btn br-5" color="accent">Não Lida</button>
            </div>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
                <div class="row">
                    <div class="col-12"  *ngIf='notification.image && notification.vimeo == "0" && notification.linkSanityzed'>
                        <img class="width-100 pt-2 pb-3" [src]="notification.linkSanityzed">
                    </div>
                    <div class="col-12"  *ngIf="notification.video && notification.vimeo == '1' && notification.linkSanityzed">
                        <iframe class="width-100 pt-2 pb-3"  #player_container [src]="notification.linkSanityzed" width="100%" height="250" frameborder="0" title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                </div>
                <div class="row" *ngIf="notification.text">
                    <div class="col-12">
                        <span class="fs-13 pb-0" [innerHtml]="notification.text"></span>
                    </div>
                </div>
            <!-- <div class="text-center mb-2" *ngIf="notification.image"><img class="responsive-img" [src]="notification.link"></div>
            <div class="text-center mb-2" *ngIf="notification.video"><iframe #player_container [src]="notification.link" width="100%" height="460" frameborder="0" title="Video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>
            <div class="text-limit" [innerHtml]="notification.text"></div> -->
        </ng-template>
    </mat-expansion-panel>

    <div *ngIf="!notifications">
        <p class="fw-300 p-2 mb-0">Nenhuma notificação</p>
    </div>
    <pagination-controls *ngIf="notifications" class="text-center" previousLabel="" nextLabel='' (pageChange)="paginate($event)"></pagination-controls>
  </mat-accordion>

</div>
