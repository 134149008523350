<!-- liveClass -->
<div class="row m-0">
    <div class="col-12 text-center">        
        <div class="page-title-container">
            <!-- <mat-icon class="material-symbols-outlined alarm-clock">alarm_on</mat-icon> -->
            <div><img src="/assets/images/alarm-clock.png" class="alarm-clock"></div>
            <div class="mt-3 mb-3 description-title hidden">
                <span class="aviso">AVISO</span>
                <h6 class="title-aviso" *ngIf="layoutService.isMobile">Sua <strong>aula ao vivo</strong><br> começa em <strong>5 minutos</strong></h6>
                <h4 class="title-aviso" *ngIf="!layoutService.isMobile">Sua <strong>aula ao vivo</strong><br> começa em <strong>5 minutos</strong></h4>
            </div>
            <div class="mt-3 mb-3 description-title">
                <span class="aviso">AVISO</span>
                <h6 class="title-aviso" *ngIf="layoutService.isMobile">Sua <strong>aula ao vivo</strong><br> começa em <strong>agora</strong></h6>
                <h4 class="title-aviso" *ngIf="!layoutService.isMobile">Sua <strong>aula ao vivo</strong><br> começa em <strong>agora</strong></h4>
            </div>
        </div>
        <div class="row mt-2 text-center">
            <div class="col-6">
                <button (click)="closeDialog()" class="width-100 small-btn btn-close-notification" mat-raised-button>Fechar</button>
            </div>
            <div class="col-6">
                <button (click)="openLive()" class="width-100 small-btn" mat-raised-button color="accent">Assistir aula</button>
            </div>
        </div>
    </div>
</div>
