import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { School } from './school';
import { Unit } from './unit';
import { Class } from './class';
import { Manager } from './manager';
import { Teacher } from './create-teacher/teacher';
import { RouteMain } from './create-routes/edit-route/route-main';

@Injectable({ providedIn: 'root' })
export class SchoolService {

    private API = environment.ApiUrl;

    constructor(private http: HttpClient) { }

    createSchool(school: School) {
        return this.http.post(this.API + '/school/createSchool', school, { observe: 'response' });
    }

    removeSchool(schoolId: number) {
        return this.http.post(this.API + '/school/removeSchool', { schoolId }, { observe: 'response' });
    }
    getAllSchool(schoolId: number) {
        return this.http.post(this.API + '/school/getAllSchool', { schoolId }, { observe: 'response' });
    }

    getSchoolShowMock(schoolId: number) {
        return this.http.post(this.API + '/school/getSchoolShowMock', { schoolId }, { observe: 'response' });
    }

    getSchools() {
        return this.http.get(this.API + '/school/getSchools', { observe: 'response' });
    }

    getSchoolsProfile(data: any) {
        return this.http.post(this.API + '/school/getSchoolsProfile', data, { observe: 'response' });
    }

    getUnitsProfile(data: any) {
        return this.http.post(this.API + '/school/getUnitsProfile', { data }, { observe: 'response' });
    }

    getClassesProfile(data: any) {
        return this.http.post(this.API + '/school/getClassesProfile', { data }, { observe: 'response' });
    }

    getRoute(routeId?: number) {
        return this.http.post(this.API + '/school/getRoute', { routeId }, { observe: 'response' });
    }
    getSchoolRoute(schoolid?: number) {
        return this.http.post(this.API + '/school/getSchoolRoutes', { schoolid }, { observe: 'response' });
    }
    createRoutes(router: RouteMain) {
        return this.http.post(this.API + '/school/createRoute', router, { observe: 'response' });
    }
    saveRouteChanges(router: RouteMain) {
        return this.http.post(this.API + '/school/saveRouteChanges', router, { observe: 'response' });
    }
    saveSchoolRouteChanges(school: School) {
        return this.http.post(this.API + '/school/saveSchoolRouteChanges', school, { observe: 'response' });
    }
    removeRoute(routeId: number) {
        return this.http.post(this.API + '/school/removeRoute',  {routeId} , { observe: 'response' });
    }
    saveChanges(classe: Class) {
        return this.http.post(this.API + '/school/saveChanges', classe, { observe: 'response' });
    }

    getColors() {
        return this.http.get(this.API + '/school/getColors', { observe: 'response' });
    }

    creteUnit(unit: Unit) {
        return this.http.post(this.API + '/school/createUnit', unit, { observe: 'response' });
    }

    removeUnit(unitId: number) {
        return this.http.post(this.API + '/school/removeUnit', { unitId }, { observe: 'response' });
    }

    getUnits(schoolId?: number) {
        return this.http.post(this.API + '/school/getUnits', { schoolId }, { observe: 'response' });
    }

    getClassesBySchool(schoolId: number) {
        return this.http.post(this.API + '/school/getClassesBySchool', { schoolId }, { observe: 'response' });
    }

    removeClass(classId: number) {
        return this.http.post(this.API + '/school/removeClass', { classId }, { observe: 'response' });
    }

    getClasses(unitId?: number) {
        return this.http.post(this.API + '/school/getClasses', { unitId }, { observe: 'response' });
    }
    getOneClass(classId: number) {
        return this.http.post(this.API + '/school/getClassOne', { classId }, { observe: 'response' });
    }
    getOneClassTotal(id: number) {
        return this.http.post(this.API + '/school/getClassOneTotal', { id }, { observe: 'response' });
    }
    getSchoolOne(schoolId: number) {
        return this.http.post(this.API + '/school/getSchoolOne', { schoolId }, { observe: 'response' });
    }
    getUnitOne(unitId: number) {
        return this.http.post(this.API + '/school/getUnitOne', { unitId }, { observe: 'response' });
    }
    updateClass(newId:number, oldId:number){
        return this.http.post(this.API + '/school/updateTurma', { newId, oldId }, { observe: 'response' });
    }
    totalClass(classId: number){
        return this.http.post(this.API + '/school/totalClass', { classId }, { observe: 'response' });
    }
    getClassesSelect (unitId?: number){
        return this.http.post(this.API + '/school/getClassesSelect', { unitId }, { observe: 'response' });
    }
    getClassStudentsSelect(classId: number ){

        return this.http.get(this.API + '/students/class/'+classId, { observe: 'response'}  );
    }
    /* -------------------------- SELECTS NOME E ID -------------------------- */
    getClassesSelection(unitId?: number){
        return this.http.post(this.API + '/school/getClassesSelection', { unitId }, { observe: 'response' });
    }
    getUnitsSelection(schoolId?: number){
        return this.http.post(this.API + '/school/getUnitsSelection', { schoolId }, { observe: 'response' });
    }
    getPlansSelection(schoolId?: number){
        return this.http.post(this.API + '/school/getPlansSelection', { schoolId }, { observe: 'response' });
    }
    getSchoolsSelection(){
        return this.http.get(this.API + '/school/getSchoolsSelection', { observe: 'response' });
    }
    getSchoolSelection(schoolId?: number){
        return this.http.post(this.API + '/school/getSchoolSelection', { schoolId }, { observe: 'response' });
    }
    /* -------------------------- END SELECTS NOME E ID -------------------------- */


    deleteStudents(classId: number){
        return this.http.post(this.API + '/school/deleteStudents', { classId }, { observe: 'response' });
    }

    createClass(c: Class) {
        return this.http.post(this.API + '/school/createClass', c, { observe: 'response' });
    }

    removeManager(managerId: number , userProfile: number) {
        return this.http.post(this.API + '/school/removeManager', { managerId, userProfile }, { observe: 'response' });
    }

    getManagers(schoolId?: number, profileId?: number) {
        return this.http.post(this.API + '/school/getManagers', { schoolId, profileId }, { observe: 'response' });
    }

    getManagersCoord(schoolId: number) {
      return this.http.post(this.API + '/school/getManagersCoord', { schoolId }, { observe: 'response' });
  }

    getAdmins(schoolId?: number, profileId?: number) {
        return this.http.post(this.API + '/school/getAdmins', { schoolId, profileId }, { observe: 'response' });
    }

    getManagersByNameOrEmail(profileId: number, schoolId: number, name?: string, email?: string) {
        return this.http.post(this.API + '/school/getManagersByNameOrEmail', { profileId, schoolId, name, email }, { observe: 'response' });
    }

    getAdminsByNameOrEmail(profileId: number, schoolId: number, name?: string, email?: string) {
        return this.http.post(this.API + '/school/getAdminsByNameOrEmail', { profileId, schoolId, name, email }, { observe: 'response' });
    }

    createManager(manager: Manager) {
        return this.http.post(this.API + '/school/createManager', manager, { observe: 'response' });
    }

    // edit methods
    saveSchoolChanges(school: School) {
        return this.http.post(this.API + '/school/saveSchoolChanges', school, { observe: 'response' });
    }

    saveUnitChanges(unit: Unit) {
        return this.http.post(this.API + '/school/saveUnitChanges', unit, { observe: 'response' });
    }

    saveClassChanges(classOld: Number, classNew: Number) {
        return this.http.post(this.API + '/school/saveClassChanges', {classOld, classNew}, { observe: 'response' });
    }

    saveManagerChanges(manager: Manager) {
        return this.http.post(this.API + '/school/saveManagerChanges', manager, { observe: 'response' });
    }

    getAllClasses() {
        return this.http.get(this.API + '/school/getClasses', { observe: 'response' });
    }

    getBlockRoutes(schoolId: number) {
        return this.http.post(this.API + '/school/getBlockRoutes', { schoolId }, { observe: 'response' });
    }

    getAllStudentsSchool2(schoolId: number) {
        return this.http.post(this.API + '/school/getAllStudentsSchool', { schoolId }, { observe: 'response' });
    }

    removeTeacher(teacherId: number , userProfile: number) {
        return this.http.post(this.API + '/school/removeTeacher', { teacherId , userProfile }, { observe: 'response' });
    }

    getTeachers(schoolId?: number, unitId?: number) {
        return this.http.post(this.API + '/school/getTeachers', { schoolId, unitId }, { observe: 'response' });
    }

    createTeacher(teacher: Teacher) {
        return this.http.post(this.API + '/school/createTeacher', teacher, { observe: 'response' });
    }

    saveTeacherChanges(teacher: Teacher) {
        return this.http.post(this.API + '/school/saveTeacherChanges', teacher, { observe: 'response' });
    }

    getTeacherClasses(teacherId: number) {
        return this.http.post(this.API + '/school/getTeacherClasses', { teacherId }, { observe: 'response' });
    }

    setTeacherPlans(teacherId: number, planId: number[]) {
        return this.http.put(this.API + `/teacher/plans/store/${teacherId}`, { planId }, { observe: 'response' });
    }

    setTeacherViewPlans(teacherId: number, planId: number[]) {
        return this.http.post(this.API + '/study-plan/setTeacherViewPlans' , {teacherId , planId}, { observe: 'response' });
    }

    updateTeacherViewPlans(teacherId: number, planId: number[]) {
        return this.http.post(this.API + '/study-plan/updateTeacherViewPlans' , {teacherId , planId}, { observe: 'response' });
    }

    getTeacherPlans(teacherId: number) {
        return this.http.post(this.API + '/study-plan/getTeacherPlans', { teacherId }, { observe: 'response' });
    }

    getTeacherViewPlans(teacherId: number) {
        return this.http.post(this.API + '/study-plan/getTeacherViewPlans', { teacherId }, { observe: 'response' });
    }

    setTeacherDisciplines(teacherId: number, disciplineId: number[]) {
        return this.http.put(this.API + `/teacher/disciplines/store/${teacherId}`, { disciplineId }, { observe: 'response' });
    }

    getTeacherDisciplines(teacherId: number) {
        return this.http.post(this.API + '/discipline/getTeacherDisciplines', { teacherId }, { observe: 'response' });
    }

    disableClass(classId: number) {
        return this.http.post(this.API + '/school/disableClass', { classId }, { observe: 'response' });
    }

    enableClass(classId: number) {
        return this.http.post(this.API + '/school/enableClass', { classId }, { observe: 'response' });
    }

    setUnitPlans(unitId: number, planId: number[]) {
        return this.http.put(this.API + `/unit/plans/store/${unitId}`, { planId }, { observe: 'response' });
    }

    setUnitDisciplines(unitId: number, disciplineId: number[]) {
        return this.http.put(this.API + `/unit/disciplines/store/${unitId}`, { disciplineId }, { observe: 'response' });
    }

    getUnitPlans(unitId: number) {
        return this.http.post(this.API + `/study-plan/getUnitPlans`, { unitId }, { observe: 'response' });
    }

    getUnitDisciplines(unitId: number) {
        return this.http.post(this.API + `/discipline/getUnitDisciplines`, { unitId }, { observe: 'response' });
    }
    removeClassPlans(planId: number, classId: number) {
        return this.http.post(this.API + `/study-plan/removeClassPlans`, { planId, classId }, { observe: 'response' });
    }
    getTeacherByEmail(email: string) {
        return this.http.post(this.API + `/school/getTeacherByEmail`, { email }, { observe: 'response' });
    }

}
