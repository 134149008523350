<div class="container pt-5 pb-5">

    <mat-accordion>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-100 center mb-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Temas</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-theme></app-create-theme>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-100 center mb-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Criar Edital</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-edital></app-create-edital>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-100 center">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Sincronizar - Escola </span><span class="material-icons ml-2 info-font"> info </span> <span class="info"> (Sincronizar as <b>UNIDADES</b> da escola!) </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-school-sync></app-school-sync>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-100 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Sincronizar - Unidade </span><span class="material-icons ml-2 info-font"> info </span><span class="info"> (Sincronizar as <b>TURMAS</b> da unidade!) </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-unit-sync></app-unit-sync>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf=" userService.isAdmin() || userService.isGeneralCoordinator() 
                    || userService.isUnitCoordinator() " class="width-100 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Sincronizar - Turma </span><span class="material-icons ml-2 info-font"> info </span><span class="info"> (Sincronizar os <b>ALUNOS</b> da turma!) </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-class-sync></app-class-sync>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-100 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Sincronizar - Coordenador </span><span class="material-icons ml-2 info-font"> info </span><span class="info"> (Sincronizar os <b>COORD.</b> da unidade!) </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-coord-sync></app-coord-sync>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator()" class="width-100 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Sincronizar - Professor </span><span class="material-icons ml-2 info-font"> info </span><span class="info"> (Sincronizar os <b>PROF.</b> da unidade!) </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-teacher-sync></app-teacher-sync>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>

</div>