import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MyExerciseLibraryService } from '../my-exercise-library.service';

@Component({
  selector: 'app-create-institution-lib',
  templateUrl: './create-institution-lib.component.html',
  styleUrls: ['./create-institution-lib.component.scss']
})
export class CreateInstitutionLibComponent implements OnInit {

  public institutionName: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public institutionConfig: any,
    @Optional() public dialogRef: MatDialogRef<CreateInstitutionLibComponent>,
    private libraryService: MyExerciseLibraryService,
    private alertService: AlertService,
    public deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.initEditMode()
  }

  createInstitution(name: string){
    this.libraryService.createInstitution(name)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Instituição cadastrada com sucesso!');
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  updateInstitution(name: string){
   this.institutionConfig.institution.name = name;

   this.libraryService.updateInstitution(this.institutionConfig.institution)
   .subscribe(res => {
     const response = res.body as ResponseApi;

     if(!response.error){
       this.alertService.success('Instituição atualizada com sucesso!');
       this.dialogRef.close(true);
     }
     else{
       this.alertService.error(response.error);
     }
   })
  }

  initEditMode(){
    if(this.institutionConfig.edit){
      this.institutionName = this.institutionConfig.institution.name;
    }
  }

}
