import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ScheduleDay } from 'src/app/modules/admin/study-plan/schedule-day';
import { DashboardService } from '../../dashboard.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';
import { SchoolService } from 'src/app/modules/admin/school/school.service';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
    selector: 'app-schedule-day',
    templateUrl: './schedule-day.component.html',
    styleUrls: ['./schedule-day.component.scss']
})
export class ScheduleDayComponent implements OnInit {

    @Input() scheduleDay: ScheduleDay;
    @Output() clearScheduleDay = new EventEmitter();

    constructor(
        private dashboardService: DashboardService,
        private alertService: AlertService,
        private studyPlanService: StudyPlanService,
        private schoolService: SchoolService,
        private userService: UserService) { }

    ngOnInit(): void { }

    clearDay() {
        this.clearScheduleDay.emit(null);
        this.scheduleDay = null;
    }
}
