<div *ngIf="!showAnnexList">
    <form [formGroup]="addVideoAnnexForm">

        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>
        </div>

      <div class="row">
        <!-- Disciplinas -->
        <div class="col-md-6">
          <mat-form-field class="width-100">
            <mat-select formControlName="discipline" placeholder="Disciplina" (selectionChange)="getVideos($event.value)">
              <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id">
                <span [title]="discipline.name">{{discipline.name}}</span> 
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

          <!-- Videos -->
              <div class="col-md-6">
                  <mat-form-field class="width-100">
                      <mat-select formControlName="video" placeholder="Vídeos">
                          <mat-option (click)="addVideoChip(video)" *ngFor="let video of videos" [value]="video.id">
                            <span [title]="video.title">{{video.title}}</span>   
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col-md-6">
              </div>
              <div class="col-md-6">
                  <mat-chip-list *ngIf="selectedVideos">
                      <mat-chip *ngFor="let videoChip of selectedVideos" selectable="true" removable="true"
                          (removed)="removeVideoChip(videoChip)">
                          {{videoChip.title}}
                          <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                  </mat-chip-list>
              </div>
        </div>
        <div class="row">
            <div class="col-md-6 annex">
                <input class="width-100" (change)="handleFileInput($event.target.files);" accept="" fullWidth status="primary"
                    type="file" nbInput placeholder="Anexar Arquivo">
            </div>
        </div>
    </form>

    <div class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver Anexos
                cadastrados</button>
        </div>
        <div class=" col-6 text-right width-100">
            <button (click)="addAnnex()" class="right bg-color-primary border-primary" [nbSpinner]="saving" [disabled]="saving"
                nbSpinnerStatus="warning" nbButton size="small" status="primary">Cadastrar</button>
        </div>
    </div>
</div>

<div *ngIf="showAnnexList" class="">
    <!-- filter  -->
    <nb-card size="medium">
        <nb-card-header>
            <div class="row">
                <div class="col-md">
                    <mat-form-field class="width-100">
                        <mat-select placeholder="Disciplina" (selectionChange)="getDisciplineVideos($event.value)">
                            <mat-option *ngFor="let dicsipline of filterDisciplines" [value]="dicsipline.id">
                                <span [title]="dicsipline.name">{{dicsipline.name}}</span>     
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md">
                    <mat-form-field class="width-100">
                        <mat-select placeholder="Vídeo" (selectionChange)="getVideoAnnex($event.value)">
                            <mat-option *ngFor="let video of filterVideos" [value]="video.id">
                                <span [title]="video.title">{{video.title}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <!-- <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="width-100">
                        <input matInput placeholder="Buscar" [(ngModel)]="filter">
                    </mat-form-field>
                </div>
                <div class="text-right col-md-6">
                    <button (click)="showAnnexList = false;" class="right" nbButton size="small"
                        status="primary">voltar</button>
                </div>
            </div> -->
            <!-- end filter -->
            <mat-list class="width-90 mt-3 center" *ngIf="!loading">
                <ng-container *ngFor="let a of annex">
                    <div mat-list-item class="row" style="min-height: 100px" *ngIf="verifyFilter(a.name)">
                        <div class="col">
                            <a href="#" (click)="openAnnex(a.url)" matTooltipClass="custom-tooltip" matTooltip="Abrir anexo"
                                style="background-color: none !important;">
                                {{a.name}}
                            </a>
                        </div>
                        <div class="col-3">
                            {{a.registerDate | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="col-2 text-right">
                            <button size="tiny" (click)="removeAnnex(a.id)" status="danger" nbButton ghost>
                                <nb-icon icon="trash"></nb-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </mat-list>
            <mat-spinner *ngIf="loading" class="center mt-5"></mat-spinner>
        </nb-card-body>
    </nb-card>
    <div class="text-right width-100">
        <button (click)="showAnnexList = false;" class="right" nbButton size="small" status="primary">voltar</button>
    </div>

</div>
