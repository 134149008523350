import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NbCardModule } from '@nebular/theme';

import { AddLessonDialogComponent } from '../admin/calendar/add-lesson/add-lesson-dialog/add-lesson-dialog.component';
import { AddLessonComponent } from '../admin/calendar/add-lesson/add-lesson.component';
import { AddSequenceLessonComponent } from '../admin/calendar/add-sequence-lesson/add-sequence-lesson.component';
import { CalendarComponent } from './calendar.component';
import { LessonPopoverComponent } from './lesson-popover/lesson-popover.component';

@NgModule({
  declarations: [CalendarComponent, LessonPopoverComponent, AddLessonComponent, AddLessonDialogComponent, AddSequenceLessonComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatDividerModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    MatInputModule,
    NbCardModule,
    MatListModule,
    MatDialogModule,
    NbCardModule
  ],
  exports: [
    CalendarComponent,
    AddSequenceLessonComponent
  ]
})
export class CalendarModule { }
