<div style="height: 100%;" class="login-page">
  <!-- <div style="height: 100%;" class="background-img login-page"></div> -->
    <div class="bg-opacity">
        <div class="container pt-5 text-center">
            <span><img class="login-logo" [src]="theme?.logo"></span>
            <!-- <form *ngIf="theme" [formGroup]="signinForm" (submit)="loginDelay()" class="form mt-2 pr-0 text-center" #formEl> -->
            <form *ngIf="theme" [formGroup]="signinForm" class="form mt-2 pr-0 text-center" #formEl>
                <div class="row text-center pt-1 pb-3">
                    <div class="col-md-6 col-lg-5 center">
                        <mat-card class="text-center">
                            <mat-card-header class="pt-2 text-center">
                                <mat-card-title class="text-center">
                                    <b>Acessar conta</b>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-subtitle>
                                Faça o login para acessar a plataforma
                            </mat-card-subtitle>
                            <mat-card-content class="mb-1">

                                <div class="form-group mb-0">
                                    <mat-form-field class="width-90">
                                        <input formControlName="email" matInput placeholder="E-mail">
                                        <mat-error *ngIf="signinForm?.get('email').errors?.email">
                                            Email inválido!
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="width-90">
                                        <input (keyup.enter)="loginDelay()" matInput placeholder="Senha" [type]="hide ? 'password' : 'text'" formControlName="password">
                                        <button class="visibility" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon  color="accent">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>

                            <mat-card-actions class="pb-2">
                                <div class="form-group">
                                    <div class="mb-2">
                                        <a class="small text-color-accent" routerLink="/signin/recovery"><span class="font-400 font-family">Esqueceu a senha?</span></a>
                                    </div>
                                    <button (click)="loginDelay()" *ngIf="!loading" mat-raised-button mat-button color="accent"
                                        class="width-90" [disabled]="signinForm.invalid" type="submit">
                                        Entrar
                                    </button>
                                    <mat-spinner class="center" *ngIf="loading" [diameter]="45">
                                    </mat-spinner>
                                </div>

                                <!--  <div class="form-group pt-2">
                                     <button (click)="goToRecovery()" mat-raised-button mat-button color="accent" class="width-90">
                                Recuperar Senha
                            </button> 

                                </div>-->
                            </mat-card-actions>
                            <!-- <mat-card-footer class="text-center">
                                Primeira vez aqui?<br><a href="https://www.notamaxima.com.br"> <strong class=" text-color-accent">Conheça nossos planos</strong></a>
                            </mat-card-footer> -->
                        </mat-card>
                    </div>
                </div>


            </form>
            <mat-spinner *ngIf="!theme" class="center mt-5"></mat-spinner>
        </div>
    </div>
    <img *ngIf="showFooter" class="footer desktop" src="https://jedis-images.s3.amazonaws.com/desktop-footers.png" alt="Desktop Footer">
    
    <!-- <img *ngIf="showFooter" class="footer mobile" src="../../../../assets/images/mobile-footer.png" alt="Mobile Footer"> -->

    <img *ngIf="showAcreFooter"  class="footer desktop" src="https://jedis-images.s3.amazonaws.com/barra-plataforma-1920-ccre.png" alt="Desktop Footer">

    <img *ngIf="showCeilandiaFooter"  class="footer desktop" src="https://jedis-images.s3.amazonaws.com/barra-plataforma-1920-ceilandia.png" alt="Desktop Footer">
</div>


