<div class="container teste pdfTable" id="pdfTable" #pdfTable *ngIf="!loading">
    
    <div class="container"> 
        
        <div class="certificate"><span class="bg-color-accent rectangle"></span></div>
        <div class="row">
            <div class="col-12 text-center">
                <h6 class="mt-5 certificado">Certificado</h6>
                <h1 class="nomeCurso">{{certificate.planName}}</h1>
                <h6 class="conferimos mt-5">Certificamos que</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <h3 class="nomeAluno">{{certificate.userName}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <p class="texto-padrao" *ngIf="typeC == 1">
                    finalizou o curso on-line {{certificate.planName}} em {{certificate.dateCreated | date:'dd/MM/yyyy'}}. <br>
                    O aproveitamento foi de <b>{{percent}}%</b> das questões.
                </p>
                <p class="texto-padrao" *ngIf="typeC == 2">
                    finalizou o curso on-line {{certificate.planName}} em {{certificate.dateCreated | date:'dd/MM/yyyy'}}. <br>
                    O aproveitamento foi de <b>{{percent}}%</b> de aulas assistidas.
                </p>
                <p class="texto-padrao" *ngIf="typeC == 3">
                    finalizou o curso on-line {{certificate.planName}} em {{certificate.dateCreated | date:'dd/MM/yyyy'}}. <br>
                    O aproveitamento foi de <b>{{percent}}%</b>.<!-- 
                    {{certificate.correct}}/{{certificate.totalQuestions}}
                    e {{certificate.totalWatched}}/{{certificate.totalVideo}} aulas assistidas. -->
                </p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 text-center">
                <div class="row">
                    <div class="col-12"><img class="signature" [src]="certificate.signatureImg"><br></div>
                    <div class="col-12 nomePessoa">{{certificate.signatureName}}<br></div>
                    <div *ngIf="certificate.dados0" class="col-12 dadosPessoa">{{certificate.dados0}}<br></div>
                    <div *ngIf="certificate.dados1" class="col-12 dadosPessoa">{{certificate.dados1}}<br></div>
                    <div *ngIf="certificate.dados2" class="col-12 dadosPessoa">{{certificate.dados2}}<br></div>
                    <div *ngIf="certificate.created_at" class="col-12 data">{{certificate.created_at}}</div>
                </div>
            </div>
        </div>
        <div class="row pt-5 pb-5">
            <div class="col-12 text-center"><img class="logo" [src]="certificate.logo"></div>
        </div>
    </div>
</div>
<div class="container min-height-300" #pdfTable *ngIf="loading">
    <div class="certificate"><span class="bg-color-accent rectangle"></span></div>
    <div class="col-12 mt-5 mb-3">
        <h5>Carregando informações do certificado</h5>
        <br />
        <div class="text-center"><mat-spinner class="center" color="accent"></mat-spinner></div>
    </div>
</div>