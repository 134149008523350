import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
    NbAccordionModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbListModule,
    NbPopoverModule,
    NbSpinnerModule,
    NbTooltipModule,
} from '@nebular/theme';

import { MockListComponent } from './list/mock-list.component';
import { MockReplyComponent } from './reply/mock-reply.component';
import { MockResultComponent } from './result/mock-result.component';
import { MockContestReplyComponent } from './contest/reply/mock-contest-reply.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SummationReplyComponent } from './summation-reply/summation-reply.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SummationResultComponent } from './summation-result/summation-result.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import { LottieModule } from 'ngx-lottie';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OrderModule } from 'ngx-order-pipe';

export function playerFactory() {
    return import('lottie-web');
}
@NgModule({
    declarations: [
        MockListComponent,
        MockReplyComponent,
        MockResultComponent,
        MockContestReplyComponent,
        SummationReplyComponent,
        SummationResultComponent
    ],
    imports: [
        CommonModule,
        NbListModule,
        NbCardModule,
        NbButtonModule,
        NbSpinnerModule,
        NbInputModule,
        FormsModule,
        NbIconModule,
        NbTooltipModule,
        NbAccordionModule,
        NbAlertModule,
        RouterModule,
        MatDividerModule,
        MatRadioModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatTableModule,
        BrowserAnimationsModule,
        DragDropModule,
        NbPopoverModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatChipsModule,
        MatListModule,
        MatRippleModule,
        MatCardModule,
        MatFormFieldModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        LottieModule.forRoot({ player: playerFactory }),
        OrderModule
    ],
    exports: [],
    providers: [DatePipe, CurrencyPipe],
})
export class MockModule { }
