import { Component, OnInit } from '@angular/core';
import { ForumService } from '../forum.service';
import { UserService } from '../../user/user.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ListForum } from './list-forum';
import { Router } from '@angular/router';
import { School } from '../../admin/school/school';
import { SchoolService } from '../../admin/school/school.service';
import { DisciplineService } from '../../admin/discipline/discipline.service';
import { Discipline } from '../../admin/discipline/discipline';
import { StudyPlanService } from '../../admin/study-plan/study-plan.service';
import { StudyPlan } from '../../admin/study-plan/study-plan';
import { ForumComment } from '../comment/forum-comment';
import { Video } from '../../video/video';

@Component({
  selector: 'app-forum-list',
  templateUrl: './forum-list.component.html'
})
export class ForumListComponent implements OnInit {

  public videos: Video[];
  public plans: StudyPlan[];
  public filterPlanId: number;
  public loading: boolean;
  public initializing: boolean;
  public disciplines: Discipline[];
  public filterDisciplineId: number;
  public filterVideoId: number;
  public comments: ForumComment[] = [];
  public schools: School[];
  public bloquedComments: ForumComment[];
  public totalBlockedComments: number;

  constructor(
    private forumService: ForumService,
    public userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private schoolService: SchoolService,
    private disciplineService: DisciplineService,
    private studyPlanService: StudyPlanService) { }

  ngOnInit(): void {
    this.bloquedComments = [];
    this.profileInit();
  }

  getBlockedComments(){
    this.forumService.getBlockedComments()
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.bloquedComments = response.data as ForumComment[];
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  getBlockedCommentsBySchool(schoolId: number){
    this.forumService.getBlockedCommentsBySchool(schoolId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.bloquedComments = response.data as ForumComment[];
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  getBlockedCommentsByUnit(unitId: number){
    this.forumService.getBlockedCommentsByUnit(unitId)
    .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
        this.bloquedComments = response.data as ForumComment[];
      }
      else{
        this.alertService.error(response.error);
      }
    })
  }

  getPlans(schoolId?: number) {
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
          if(this.userService.isGeneralCoordinator()){
            let plansIds = [];
            this.plans.forEach(element => {
              plansIds.push(element.id);
            });
            this.getLastCommentsByCoordinator(1, plansIds, schoolId);
          }
        } else {
          this.alertService.error(response.error);
        }

        this.initializing = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.initializing = false;
      });
  }

  getTotalBlockedComments(){
    this.forumService.getTotalBlockedComments()
    .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
            this.totalBlockedComments = response.data['total'] as number;
        }
        else{
            this.alertService.error(response.error);
        }
    })
}

getTotalBlockedCommentsBySchool(schoolId: number){
  this.forumService.getTotalBlockedCommentsBySchool(schoolId)
  .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
          this.totalBlockedComments = response.data['total'] as number;
      }
      else{
          this.alertService.error(response.error);
      }
  })
}

getTotalBlockedCommentsByUnit(unitId: number){
  this.forumService.getTotalBlockedCommentsByUnit(unitId)
  .subscribe(res => {
      const response = res.body as ResponseApi;

      if(!response.error){
          this.totalBlockedComments = response.data['total'] as number;
      }
      else{
          this.alertService.error(response.error);
      }
  })
}

  getPlanDisciplines(planId: number) {
    let schoolId = this.userService.getUserSchool();
    this.getLastComments(1, planId, schoolId);
    this.comments = [];
    this.studyPlanService.getPlanDisciplines(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];

        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getDisciplineVideos(disciplineId: number) {
    this.disciplineService.getDisciplineVideos(disciplineId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.videos = response.data as Video[];
          this.comments = [];
          if (!this.videos.length) {
            this.alertService.warning('Nenhum vídeos existente para a disciplina');
          }
        } else {
          this.alertService.error(response.error);
        }

      }, err => {
        this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente');
      });
  }

  getTopicComments(topicId: number) {
    this.loading = true;
    this.forumService.getCommentsVideo(topicId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.comments = response.data as ForumComment[];
          if (!this.comments.length) {
            this.alertService.warning('Nenhum Comentário existente para o tópico');
          }
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os comentários. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getLastComments(page: number, planId?: number, schoolId?: number) {
    this.loading = true;
    this.forumService.getLastComments(page, planId, schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.comments = response.data as ForumComment[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os comentários. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getLastCommentsByCoordinator(page: number, plansIds?: any[], schoolId?: number) {
    this.loading = true;
    this.forumService.getLastCommentsByCoordinator(page, plansIds, schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.comments = response.data as ForumComment[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os comentários. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  private getUnitDisciplines(unitId: number) {
    this.schoolService.getUnitDisciplines(unitId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde'));
  }

  private getTeacherDisciplines(teacherId: number) {
    this.schoolService.getTeacherDisciplines(teacherId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.disciplines = response.data as Discipline[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde'));
  }

  profileInit() {
    let schoolId: number = this.userService.getUserSchool();

    if (this.userService.isAdmin()) {
      this.getSchools();
      this.getLastComments(1, null);
      this.getBlockedComments();
      this.getTotalBlockedComments();

    } else if (this.userService.isGeneralCoordinator()) {
      this.getBlockedCommentsBySchool(this.userService.getUserSchool());
      this.getTotalBlockedCommentsBySchool(this.userService.getUserSchool());
      this.getPlans(this.userService.getUserSchool());      

    } else if (this.userService.isUnitCoordinator()) {
      this.getBlockedCommentsByUnit(this.userService.getUserUnit());
      this.getTotalBlockedCommentsByUnit(this.userService.getUserUnit());
      this.getUnitDisciplines(this.userService.getUserUnit());

    } else if (this.userService.isTeacher()) {
      this.getTeacherDisciplines(this.userService.getUserId());

    }
  }

  rechargeComments(){
    if(this.userService.isAdmin()){
    this.getBlockedComments();
    this.getTotalBlockedComments();
    }
    else if(this.userService.isGeneralCoordinator()){
      this.getBlockedCommentsBySchool(this.userService.getUserSchool());
      this.getTotalBlockedCommentsBySchool(this.userService.getUserSchool());
    }
    else if (this.userService.isUnitCoordinator()){
      this.getBlockedCommentsByUnit(this.userService.getUserUnit());
      this.getTotalBlockedCommentsByUnit(this.userService.getUserUnit());
    }
  }

}
