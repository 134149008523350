<div class="container pt-5 pb-5">
    <mat-accordion>
        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Podcast</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-podcast></app-create-podcast>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Cadastrar Feriado</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-holydays></app-holydays>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Lista de Exercícios</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-exercise-list></app-create-exercise-list>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Simulados / Avaliações</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-mock-create></app-mock-create>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin() || userService.isGeneralCoordinator() || userService.isUnitCoordinator()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Simulados/Alunos</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-mock-student></app-mock-student>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span style="align-items: center; display: inline-flex;">
                    <span class="text-color-accent">Sala de aula virtual</span>
                    <mat-chip-list class="ml-3">
                        <mat-chip selected color="accent">Plano</mat-chip>
                    </mat-chip-list>
                </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-classroom-create></app-classroom-create>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!userService.isStudent()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span style="align-items: center; display: inline-flex;">
                    <span class="text-color-accent">Sala de aula virtual</span>
                    <mat-chip-list class="ml-3">
                        <mat-chip selected color="accent">Turma</mat-chip>
                    </mat-chip-list>
                </span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="alert-info p-3">
                    <mat-icon class="mr-2">tips_and_updates</mat-icon> Esse novo recurso de <b>sala de aula virtual</b> assemelha-se ao antigo com a diferença de que a visibilidade da sala de aula se aplica a <b>turma</b> diferente do anterior que é exibido aos alunos do <b>plano</b>.
                </div>
                <app-classroom-create-by-class></app-classroom-create-by-class>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isTeacher()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Monitorias</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-monitory-create></app-monitory-create>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="userService.isAdmin()" class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">Link de Pagamento das Redações</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-create-essay-credit></app-create-essay-credit>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel class="width-70 center mt-4">
            <mat-expansion-panel-header>
                <span class="text-color-accent">E-book</span>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-flip-book-admin></app-flip-book-admin>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>

</div>
