<!-- Simulados Geral -->
<div class="page-container p-pg page-container-mobile discipline" [ngClass]="{'width-70 background-mock-list background-white': !layoutService.isMobile && selectedMock,
        'ml-15 mr-15 mt-120':layoutService.isMobile && selectedMock && !selectedMock.summation}">
    <div class="row m-0" [ngClass]="{'mock-question': !layoutService.isMobile}">
        <div class="col-12">
            <ng-container *ngIf="selectedMock">
                <div class="page-title-container" [ngClass]="{'left dash-container':!layoutService.isMobile, 'text-center width-80': layoutService.isMobile, 'background-white mt-4':selectedMock && !selectedMock.summation}">
                    <span class="page-title" *ngIf="!isTest">SIMULADOS</span>
                    <span class="page-title" *ngIf="isTest">AVALIAÇÕES</span>
                    <div class="mt-3 description-title">
                        <h6 *ngIf="layoutService.isMobile">{{selectedMock?.name}}</h6>
                        <h4 *ngIf="!layoutService.isMobile">{{selectedMock?.name}}</h4>
                        <p class="fs-13 pb-0">Escolha o simulado que você deseja estudar</p>
                    </div>
                </div>
                <div class="lesson-back">
                    <button (click)="backBtn()" [ngClass]="{'width-50 text-left back-button': layoutService.isMobile}" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                      <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row m-0" [ngClass]="{'mock-question': !layoutService.isMobile}">
        <div class="col-md-5 mt-2" *ngIf="!selectedMock">
            <ng-container *ngIf="!selectedMock">
                <!-- plan not selected on mobile -->
                <ng-container *ngIf="!selectedPlan || !layoutService.isMobile">
                    <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                        <span class="page-title" *ngIf="!isTest">SIMULADOS</span>
                        <span class="page-title" *ngIf="isTest">AVALIAÇÕES</span>
                        <div class="mt-3 description-title" *ngIf="layoutService.isMobile">
                            <ng-container *ngIf="!selectedMock">
                                <h6 class="mb-0">Selecione o plano</h6>
                                <p class="fs-13 pb-0">Escolha um plano para carregar as provas</p>
                            </ng-container>
                            <ng-container *ngIf="selectedMock">
                                <h6 class="mb-3 mt-3">{{selectedMock?.name}}</h6>
                            </ng-container>
                        </div>
                        <div class="mt-3 description-title" *ngIf="!layoutService.isMobile">
                            <ng-container *ngIf="!selectedMock">
                                <h4>Selecione o plano</h4>
                                <p>Escolha o curso que você deseja estudar</p>
                            </ng-container>
                            <ng-container *ngIf="selectedMock">
                                <h4>{{selectedMock?.name}}</h4>
                            </ng-container>
                        </div>
                    </div>
                    <div id="filter">
                        <mat-form-field class="width-100 m-0 p-0 form-field" appearance="fill">
                            <mat-select class="p-0 m-0 form-select" placeholder="Filtrar simulados" [(ngModel)]="mockTypeSelected" (selectionChange)="changeTypeMocks($event.value)">
                                <mat-option value="SG" *ngIf="!isTest">Simulados por plano</mat-option>
                                <mat-option value="MS" *ngIf="!isTest">Meus simulados exclusivos</mat-option>
                                <mat-option value="SG" *ngIf="isTest">Avaliações por plano</mat-option>
                                <mat-option value="MS" *ngIf="isTest">Minhas avaliações exclusiva</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="layoutService.isMobile">
                        <!--  <div class="alert alert-info text-color-primary mb-3 mt-3 alert-text-size">
                            <strong>Exclusivo para assinantes Diversidades Edu!</strong>
                        </div> -->
                        <mat-form-field class="width-100 m-0 p-0 form-field" appearance="fill" *ngIf="!loadingPlans">
                            <mat-select class="p-0 m-0 form-select" placeholder="Selecione um simulado" [(ngModel)]="firstPlan" (selectionChange)="selectPlan($event.value)">
                                <mat-option [value]="plan" *ngFor="let plan of plans">
                                    {{plan?.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                    </div>
                    <div class="plan-list" *ngIf="mockTypeSelected == 'SG' && !layoutService.isMobile">
                        <mat-list *ngIf="!loadingPlans">
                            <mat-list-item *ngFor="let plan of plans" (click)="selectPlan(plan)">
                                <div class="plan-item mb-3 hvr-grow" matRipple [ngClass]="{'bg-color-accent': selectedPlan?.id == plan?.id}">
                                    <div class="plan-icon">
                                        <mat-icon [color]="(selectedPlan?.name == plan?.name) ? null : 'accent'">class</mat-icon>
                                    </div>
                                    <div class="plan-info">
                                        <strong class="plan-name">{{ plan?.name }}</strong>
                                        <span class="plan-expire"> Válido até {{ plan?.expireDate | date:'dd/MM/y'}}</span>
                                    </div>
                                </div>
                            </mat-list-item>
                        </mat-list>
                        <mat-spinner *ngIf="loadingPlans" class="center table-spinner" color="accent"></mat-spinner>
                    </div>
                </ng-container>
                <!-- mobile only -->
                <ng-container *ngIf="layoutService.isMobile && selectedPlan">
                    <div class="selected-plan page-title-container mt-2 pt-0" [ngClass]="{'text-center': layoutService.isMobile}">
                        <span class="page-title">PLANO SELECIONADO</span>
                        <div class="mt-3 description-title">
                            <h6 class="mb-0">{{selectedPlan.name}}</h6>
                            <p class="fs-13 pb-0">Escolha o simulado que você deseja estudar</p>
                        </div>
                        <div class="lesson-back">
                            <button (click)="selectedPlan = null" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                                <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div [ngClass]="{'margin-de-cima': !layoutService.isMobile , 'min-height-150' : !layoutService.isMobile }" class="col-md-7 mt-2" [hidden]="!layoutService.isMobile && selectedMock">
            <ng-container *ngIf="!selectedMock">
                <!-- select a plan -->
                <div *ngIf="mockTypeSelected == 'SG' && !layoutService.isMobile && !noPlans && !selectedPlan" class="plan-not-selected">
                    <h4>Selecione um plano</h4>
                    <br />
                    <p>Escolha um plano para carregar as provas</p>
                </div>
                <!-- Mock list -->
                <div class="col-md-6" *ngIf="!layoutService.isMobile">
                    <mat-form-field class="width-100" *ngIf="selectedPlan">
                        <input matInput placeholder="Buscar pelo nome" [(ngModel)]="filterMock" (keyup)="applyFilter()">
                    </mat-form-field>
                </div>
                <ng-container>
                    <div *ngIf="mockDataSource?.data?.length && (!loadingMocks && ((firstPlan && layoutService.isMobile || selectedPlan) || mockTypeSelected == 'MS'))" class="table-container table-container-discipline height-100 mt-2" [ngClass]="{'position-r height-100': !layoutService.isMobile}">
                        <nb-list class="width-100 mb-3">
                            <nb-list-item class="bg-color-accent list-header brl-5 brr-5 fs-13">
                                <div class="row m-0 align-items-center">
                                    <div class="c-pointer" [ngClass]="{'col-md-5': !layoutService.isMobile, 'col-md-12': layoutService.isMobile}" [hidden]="layoutService.isMobile"(click)="getOrder('name')">
                                        <span class="d-inline-flex">
                                            <span class="mr-2">SIMULADO </span>
                                            <mat-icon class="material-icons line-height-07" *ngIf="!reverse">arrow_drop_down</mat-icon>
                                            <mat-icon class="material-icons line-height-07" *ngIf="reverse">arrow_drop_up</mat-icon>
                                        </span>
                                    </div>
                                    <div [hidden]="layoutService.isMobile" [ngClass]="{'col-md-3': !layoutService.isMobile , 'col-10' : layoutService.isMobile}" class="text-center">TIPO</div>
                                    <div [hidden]="layoutService.isMobile" [ngClass]="{'col-md-1 p-0': !layoutService.isMobile , 'col-10' : layoutService.isMobile}" class="text-center"><mat-icon [hidden]="!haveRestart">restart_alt</mat-icon></div>
                                    <div [hidden]="layoutService.isMobile" [ngClass]="{'col-md-3': !layoutService.isMobile , 'col-2' : layoutService.isMobile}" class="text-center">LINK</div>
                                </div>
                            </nb-list-item>
                            <nb-list-item *ngFor="let mock of filteredMocks | orderBy: order:reverse; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                <div class="row m-0">
                                    <div [ngClass]="{'col-md-5': !layoutService.isMobile , 'col-12 p-0' : layoutService.isMobile}">
                                        <span class="mobileDiscipline" *ngIf="!deviceService.isMobile()">{{mock.name}} </span>
                                        <button class="mobileDisciplineLink table-mobile-link p-0" *ngIf="!mock.releasedResult && deviceService.isMobile()" mat-button color="accent" (click)="selectMockDelay(mock)">
                                            <div class="classFlex align-items-center justify-content-between">
                                                <span class="col-6">{{mock.name}}</span>
                                                <mat-icon *ngIf="mock.isReset" color="accent" class="resetButton col-3" (click)="resetMock(mock.id)">restart_alt</mat-icon>
                                                <span class=" font-700 text-color-accent col-3">Acessar</span>
                                            </div>
                                        </button>
                                    </div>
                                    <div *ngIf="!deviceService.isMobile()" class="col-md-3 text-center">
                                        <span *ngIf="mock.summation">Somatória</span>
                                        <span *ngIf="!mock.summation">Objetiva</span>
                                    </div>
                                    <div class="col-md-1 p-0 d-flex justify-content-center">
                                        <mat-icon *ngIf="mock.isReset && !deviceService.isMobile()"  color="accent" class="resetButton" matTooltip="Refazer simulado" (click)="resetMock(mock.id)">restart_alt</mat-icon>
                                    </div>
                                    <div [ngClass]="{'col-md-3': !layoutService.isMobile , 'col-3' : layoutService.isMobile}" class="text-center" *ngIf="!deviceService.isMobile()">
                                        <button *ngIf="!mock.releasedResult" mat-button color="accent" (click)="selectMockDelay(mock)">
                                            <strong>Acessar</strong>
                                        </button>
                                        <button *ngIf="mock.releasedResult" mat-raised-button color="accent" class="small-btn" (click)="seeResult(mock.id, mock.summation)">
                                            <strong>Ver resultado</strong>
                                        </button>
                                    </div>
                                </div>
                            </nb-list-item>
                        </nb-list>
                    </div>
                    <!-- empty plan -->
                    <div *ngIf="!mockDataSource?.data?.length && !loadingMocks && (mockTypeSelected == 'MS' || selectedPlan)" class="plan-not-selected">
                        <h4 *ngIf="!isTest">Nenhum simulado</h4>
                        <h4 *ngIf="isTest">Nenhuma avaliação</h4>
                        <br />
                        <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                    </div>
                    <mat-spinner *ngIf="loadingMocks" class="center table-spinner" color="accent"></mat-spinner>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="row m-0" [ngClass]="{'mock-question': !layoutService.isMobile}">
        <div class="col-12">
            <!-- empty select plan -->
            <!--   <div *ngIf="noPlans && !selectedMock" class="plan-not-selected">
                <h4>Nenhum plano</h4>
                <br />
                <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
            </div> -->
            <!-- Reply -->
            <ng-container *ngIf="(selectedMock && !selectedMock.summation)">
                <div class="col-12 mock-reply">
                    <app-mock-reply class="mt--40" [mock]="selectedMock" [finalize]="finalizeMock" [timeLeft]="count" (startTimer)="startTimerEvent()" (clearMock)="clearMock()"></app-mock-reply>
                </div>
            </ng-container>
            <!-- summation mock -->
            <ng-container *ngIf="(selectedMock && selectedMock.summation)">
                <div class="col-12 summation-reply">
                    <app-summation-reply [mock]="selectedMock" [finalize]="finalizeMock" [timeLeft]="count" (startTimer)="startTimerEvent()" (clearMock)="clearMock()"></app-summation-reply>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!-- timer widget-->
<div class="fixed-timer" cdkDrag cdkDragBoundary=".drag-boundary" *ngIf="timeLimit  > 0" #timerPopover nbPopover="Você pode me mover pela tela" nbPopoverTrigger="noop">
    <div class="base-timer" >
        <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
                <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                <path id="base-timer-path-remaining" [attr.stroke-dasharray]="circleDashArray"
                    class="base-timer__path-remaining"
                    [ngClass]="(count < (timeLimit / 2)) ? 'text-color-warn' : 'text-color-primary'" d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
              "></path>
            </g>
        </svg>
        <span id="base-timer-label" class="base-timer__label bg-color-accent">{{counter$ | async}}</span>
    </div>
</div>
