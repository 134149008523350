import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { SlideFile } from 'src/app/modules/slide-file/slide-file';
import { Video } from 'src/app/modules/video/video';
import { Discipline } from '../../discipline/discipline';

import { AddLessonDialogComponent } from './add-lesson-dialog/add-lesson-dialog.component';

@Component({
  selector: 'app-add-lesson',
  templateUrl: './add-lesson.component.html',
  styleUrls: ['./add-lesson.component.scss']
})
export class AddLessonComponent implements OnInit {

  @Input() dayVideos: (Video | SlideFile)[];
  @Input() yearIndex: number;
  @Input() monthIndex: number;
  @Input() weekIndex: number;
  @Input() dayIndex: number;
  @Input() day: number;
  @Input() date: Date;
  @Output() updatedVideos = new EventEmitter<
    { videos: Video[], dayIndex: number, monthIndex: number, yearIndex: number, day: number, weekIndex: number, code: number }
  >();

  constructor(
    private dialog: MatDialog,
    private datePipe: DatePipe) { }

  ngOnInit(): void {

  }

  openBottomSheet() {
    const dialogRef = this.dialog
      .open(AddLessonDialogComponent, { data: { dayContent: this.dayVideos } });

    dialogRef.afterClosed().subscribe((updatedVideos: Video[]) => {
      if (updatedVideos) {
        this.updatedVideos.emit(
          {
            videos: [...updatedVideos],
            dayIndex: this.dayIndex,
            monthIndex: this.monthIndex,
            yearIndex: this.yearIndex,
            day: this.day,
            weekIndex: this.weekIndex,
            code: Number(this.datePipe.transform(this.date, 'yyyyMMdd'))
          }
        );
      }
    });
  }

}
