import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Discipline } from './discipline';
import { BehaviorSubject } from 'rxjs';
import { disciplineTag } from '../discipline-tag/discipline-tag-create/discipline-tag';
import { Video } from '../../video/video';
import { MessageService } from 'src/app/message.service';

@Injectable({ providedIn: 'root' })
export class DisciplineService {

    private API = environment.ApiUrl;
    private discipline = new BehaviorSubject<Discipline>(null);
    private selectedPlanIdOnDisciplinesVideos = new BehaviorSubject<number>(null);
    public emitDisciplinePlan = new EventEmitter<any>();
    public emitDiscipline = new EventEmitter<any>();

    constructor(private http: HttpClient, 
        private messageService: MessageService) { }

   /*  getChannelId(id: number): Observable<Hero> {
        // For now, assume that a hero with the specified `id` always exists.
        // Error handling will be added in the next step of the tutorial.
        const hero = HEROES.find(h => h.id === id)!;
        this.messageService.add(`HeroService: fetched hero id=${id}`);
        return of(hero);
    } */
    
    setDisciplinePlan(data:any){
        this.emitDisciplinePlan.emit(data); 
    }

    sentDiscipline(data:any){
        this.emitDiscipline.emit(data); 
    }
    getDiscipline() {
        return this.discipline.asObservable();
    }

    setDiscipline(discipline: Discipline) {
        this.discipline.next(discipline);
    }

    getSelectedPlanIdOnDisciplinesVideosSubject() {
        return this.selectedPlanIdOnDisciplinesVideos.asObservable();
    }

    setSelectedPlanIdOnDisciplinesVideosSubject(planId: number) {
        this.selectedPlanIdOnDisciplinesVideos.next(planId);
    }

    createDiscipline(discipline: Discipline) {
        return this.http.post(this.API + '/discipline/createDiscipline', discipline, { observe: 'response' });
    }

    removeDiscipline(disciplineId: number) {
        return this.http.post(this.API + '/discipline/removeDiscipline', { disciplineId }, { observe: 'response' });
    }

    getStudentDisciplines(studentId: number) {
        return this.http.post(this.API + '/discipline/getDisciplines', { studentId }, { observe: 'response' });
    }

    getDisciplines(disciplineVideosOnly?: boolean) {
        return this.http.post(this.API + '/discipline/getDisciplines', { disciplineVideosOnly }, { observe: 'response' });
    }
    getPlanDisciplines(planId?: boolean, pageSize?:number) {
        return this.http.post(this.API + '/discipline/getDisciplines', { planId, pageSize }, { observe: 'response' });
    }
    getDisciplinesSelectedByPlan(planId: number) {
        return this.http.post(this.API + '/discipline/getDisciplinesSelectedByPlan', { planId}, { observe: 'response' });
    }
    getDisciplinesNotExerciciesSelectedByPlan(planId: number) {
        return this.http.post(this.API + '/discipline/getDisciplinesNotExerciciesSelectedByPlan', { planId}, { observe: 'response' });
    }
    getSchoolDisciplinesVideosOnly(schoolId: number) {
        return this.http.post(this.API + '/discipline/getSchoolDisciplinesVideos', { schoolId }, { observe: 'response' });
    }
    getNameDiscipline(nameDiscipline: string) {
        return this.http.post(this.API + '/discipline/getNameDiscipline', { nameDiscipline}, { observe: 'response' });
    }
    getNameDisciplineCoord(nameDiscipline: string , schoolId : number) {
        return this.http.post(this.API + '/discipline/getNameDisciplineCoord', { nameDiscipline , schoolId}, { observe: 'response' });
    }
    getDisciplineVideos(disciplineId: number, order?: boolean) {
        return this.http.post(this.API + '/discipline/getDisciplineVideos', { disciplineId , order}, { observe: 'response' });
    }
    getDisciplineTagVideos(disciplineId: number , order?: boolean , tagId?: number) {
        return this.http.post(this.API + '/discipline/getDisciplineTagVideos', { disciplineId , order , tagId}, { observe: 'response' });
    }
    getDisciplineVideosV1(disciplineId: number, order:boolean , idSchool: number , userId: number) {
        return this.http.post(this.API + '/discipline/getDisciplineVideosV1', { disciplineId, order , idSchool , userId}, { observe: 'response' });
    }
    getDisciplineVideosV1ByTag(disciplineId: number, order:boolean , idSchool: number , tagId: number) {
        return this.http.post(this.API + '/discipline/getDisciplineVideosV1ByTag', { disciplineId, order , idSchool , tagId}, { observe: 'response' });
    }
    getDisciplineVideosByPlanV2(disciplineId: number, planId: number, idSchool: number) {
        return this.http.post(this.API + '/discipline/getDisciplineVideosByPlanV2', { disciplineId, planId, idSchool}, { observe: 'response' });
    }

    getDisciplineVideosByPlanV2ByVideos(videos: Video[] , planId:number , schoolId:number , userId: number , disciplineId : number) {
        return this.http.post(this.API + '/discipline/getDisciplineVideosByPlanV2ByVideos', {videos , planId , schoolId , userId , disciplineId}, { observe: 'response' });
    }

    getDisciplineVideosWithLikeAndDeslikes(disciplineId: number) {
        return this.http.post(this.API + '/discipline/getDisciplineVideosWithLikeAndDeslikes', { disciplineId}, { observe: 'response' });
    }

    getDisciplineVideosWithLikeAndDeslikesByName(disciplineId: number , videoName: string) {
        return this.http.post(this.API + '/discipline/getDisciplineVideosWithLikeAndDeslikesByName', { disciplineId , videoName}, { observe: 'response' });
    }

    getDisciplineVideosByPlanV2ByTag(disciplineId: number, planId: number, idSchool: number , tagId: number) {
        return this.http.post(this.API + '/discipline/getDisciplineVideosByPlanV2ByTag', { disciplineId, planId, idSchool , tagId}, { observe: 'response' });
    }
    
    saveDisciplineChanges(discipline: Discipline) {
        return this.http.post(this.API + '/discipline/saveDisciplineChanges', discipline, { observe: 'response' });
    }

    getDisciplinesExercisesOnly() {
        return this.http.post(this.API + '/discipline/getDisciplinesExercisesOnly', {}, { observe: 'response' });
    }

    getSchoolDisciplinesExercisesOnly(schoolId : number) {
        return this.http.post(this.API + '/discipline/getSchoolDisciplinesExercises', {schoolId}, { observe: 'response' });
    }

    createDisciplineTag(tagName: string , idSchool: number) {
        return this.http.post(this.API + '/discipline/createDisciplineTag', {tagName , idSchool}, { observe: 'response' });
    }

    createDisciplineTagWithDiscipline(tagName: string , disciplineId: number) {
        return this.http.post(this.API + '/discipline/createDisciplineTagWithDiscipline', {tagName , disciplineId}, { observe: 'response' });
    }

    getDisciplinesTags() {
        return this.http.post(this.API + '/discipline/getDisciplinesTags', {}, { observe: 'response' });
    }

    getVideoTags(videoId : number) {
        return this.http.post(this.API + '/discipline/getVideoTags', {videoId}, { observe: 'response' });
    }

    getDisciplinesTagsBySchool(idSchool: number) {
        return this.http.post(this.API + '/discipline/getDisciplinesTagsBySchool', {idSchool}, { observe: 'response' });
    }

    getDisciplinesTagsBySchoolAndDiscipline(idSchool: number , disciplineId: number) {
        return this.http.post(this.API + '/discipline/getDisciplinesTagsBySchoolAndDiscipline', {idSchool , disciplineId}, { observe: 'response' });
    }

    getDisciplinesTagsByDiscipline(disciplineId: number) {
        return this.http.post(this.API + '/discipline/getDisciplinesTagsByDiscipline', {disciplineId}, { observe: 'response' });
    }


    deleteDisciplineTag(tagId : number) {
        return this.http.post(this.API + '/discipline/deleteDisciplineTag', {tagId}, { observe: 'response' });
    }

    updateDisciplineTag(tag : disciplineTag) {
        return this.http.post(this.API + '/discipline/updateDisciplineTag', tag , { observe: 'response' });
    }

    updateDisciplineTagWithDiscipline(tag : disciplineTag) {
        return this.http.post(this.API + '/discipline/updateDisciplineTagWithDiscipline', tag , { observe: 'response' });
    }

    getSchoolDisciplines(schoolId: number) {
        return this.http.post(this.API + '/discipline/getSchoolDisciplines', { schoolId }, { observe: 'response' });
    }
    getNextPage(page:number, pageOptions:number, planId?:number, nameDiscipline?:string) {
        return this.http.post(this.API + '/discipline/getNextPage', { page, pageOptions, planId, nameDiscipline }, { observe: 'response' });
    }
    getNextPageCoord(schoolId: number, page:number, pageOptions:number, planId?:number, nameDiscipline?:string) {
        return this.http.post(this.API + '/discipline/getNextPageCoord', { page, pageOptions, planId, nameDiscipline , schoolId }, { observe: 'response' });
    }
    getPrevPage(page:number, pageOptions:number, idplano:number, disciplineName?:string) {
        return this.http.post(this.API + '/discipline/getPrevPage', { page, pageOptions, idplano, disciplineName }, { observe: 'response' });
    }
    getPrevPageCoord( schoolId: number ,page:number, pageOptions:number, idplano:number, disciplineName?:string) {
        return this.http.post(this.API + '/discipline/getPrevPageCoord', { page, pageOptions, idplano, disciplineName, schoolId }, { observe: 'response' });
    }
    getNextAllDisciplinesPage(page: number, pageOptions:number, disciplineName?:string) {
        return this.http.post(this.API + '/discipline/getNextAllDisciplinesPage', { page, pageOptions, disciplineName }, { observe: 'response' });
    }
    getPrevAllDisciplinesPage(page: number, pageOptions:number, disciplineName?:string) {
        return this.http.post(this.API + '/discipline/getPrevAllDisciplinesPage', { page, pageOptions, disciplineName }, { observe: 'response' });
    }    
    getNextPageWithSchool(page:number, pageOptions:number, planId:number, schoolId:number, disciplineName?:string) {
        return this.http.post(this.API + '/discipline/getNextPageWithSchool', { page, pageOptions, planId, schoolId, disciplineName }, { observe: 'response' });
    }
    getPrevPageWithSchool(page:number, pageOptions:number, planId:number, schoolId:number, disciplineName?:string) {
        return this.http.post(this.API + '/discipline/getPrevPageWithSchool', { page, pageOptions, planId, schoolId, disciplineName }, { observe: 'response' });
    }
    getVersionPlan(planId: number) {
        return this.http.post(this.API + '/discipline/getVersionPlan', { planId }, { observe: 'response' });
    }
    getDisciplinesSchoolByName(schoolId: number, disciplineName: string) {
        return this.http.post(this.API + '/study-plan/getDisciplinesSchoolByName', { schoolId, disciplineName  }, { observe: 'response' });
    }
}
