import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SlideFile } from './slide-file';

@Injectable({
  providedIn: 'root'
})
export class SlideFileService {

  private API = environment.ApiUrl;

  constructor(private http: HttpClient) { }

  createSlideFile(slide: SlideFile) {
    return this.http.post(this.API + '/slide-file/create', slide, { observe: 'response' });
  }

  updateSlideFile(slide: SlideFile) {
    return this.http.post(this.API + '/slide-file/update', slide, { observe: 'response' })
  }

  removeSlideFile(slideId: number) {
    return this.http.post(this.API + '/slide-file/remove', { slideId }, { observe: 'response' })
  }

  getSlideFile(slideId: number) {
    return this.http.post(this.API + '/slide-file/getSlideFile', { slideId }, { observe: 'response' })
  }

  getDisciplineSlideFiles(disciplineId: number) {
    return this.http.post(this.API + '/slide-file/getDisciplineSlideFiles', { disciplineId }, { observe: 'response' })
  }

  instanceOfSlideFile(object: any): object is SlideFile {
    return 'name' in object;
  }
}
