import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import {
    NbAccordionModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbDialogModule,
    NbIconModule,
    NbInputModule,
    NbListModule,
    NbSpinnerModule,
    NbTooltipModule,
} from '@nebular/theme';

import { SendRedactionComponent } from './send/send-redaction.component';
import { BuyCreditComponent } from './buy-credit/buy-credit.component';
import { RedactionWriteComponent } from './send/write-dialog/redaction-write-dialog.component';
import { SentRedactionsComponent } from './sent/sent-redactions.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ReadThemeComponent } from './sent/read-theme/read-theme.component';
import { LottieModule } from 'ngx-lottie';
import { SafePipe } from 'src/app/shared/pipes/Safe.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxPaginationModule } from 'ngx-pagination';

export function playerFactory() {
    return import('lottie-web');
}

@NgModule({
    declarations: [SendRedactionComponent, RedactionWriteComponent, SentRedactionsComponent, ReadThemeComponent, BuyCreditComponent],
    imports: [
        CommonModule,
        NbListModule,
        NbCardModule,
        NbButtonModule,
        NbSpinnerModule,
        NbInputModule,
        NbDialogModule.forChild(),
        FormsModule,
        NbIconModule,
        NbTooltipModule,
        NbAccordionModule,
        NbAlertModule,
        RouterModule,
        MatInputModule,
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        LottieModule.forRoot({ player: playerFactory }),
        MatTableModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatSelectModule,
        MatRippleModule,
        MatPaginatorModule,
        NgxPaginationModule
    ],
    entryComponents: [RedactionWriteComponent],
    exports: [],
    providers: []
})
export class RedactionModule { }
