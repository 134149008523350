<h1>{{School.name}}</h1>

<form [formGroup]="editSchoolRouteForm">
    <div class="col-md-6">
        <div>
            <mat-form-field class="width-100">
                <mat-select formControlName="Routes" placeholder="route">
                    <mat-option (click)="addRouteChip(route)" *ngFor="let route of routes" [value]="route.id">
                        {{route.descr}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="selectedRoutes?.length > 0">
            <mat-label class="mb-2">Links do menu ocultados</mat-label>
            <mat-chip-list *ngIf="selectedRoutes">
                <mat-chip *ngFor="let route of selectedRoutes" selectable="true" removable="true" (removed)="removeSChoollChip(route)">
                    {{route.descr}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="row mt-5 text-right">
        <button (click)="saveSchoolRouteChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" nbSpinnerStatus="warning" [disabled]="loading">Salvar</button>
    </div>
</form>