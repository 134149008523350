import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Profile } from 'src/app/core/models/profile';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AdminService } from '../../admin.service';
import { Manager } from '../manager';
import { School } from '../school';
import { SchoolService } from '../school.service';
import { Unit } from '../unit';

@Component({
    selector: 'app-create-manager',
    templateUrl: './create-manager.component.html',
})
export class CreateManagerComponent implements OnInit, OnDestroy {

    public createManagerForm: FormGroup;
    public profiles: Profile[];
    public schools: School[];
    public units: Unit[];
    public managers: Manager[];
    public showManagersList: boolean;
    public editMode: boolean;
    public loading: boolean;
    private admUpdate$: Subscription;
    public selectedSchoolId: number;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public manager: Manager,
        @Optional() public dialogRef: MatDialogRef<CreateManagerComponent>,
        private dialog: MatDialog,
        private schoolService: SchoolService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        public userService: UserService,
        private adminSerivce: AdminService) { }

    ngOnInit(): void {
        if (!this.userService.isAdmin()) {
            this.selectedSchoolId = this.userService.getUserSchool();
        }

        this.admUpdate$ = this.adminSerivce.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    init() {
        this.schools = [];
        this.getProfiles();
        this.managers = null;
        this.createManagerForm = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            password: ['', Validators.required],
            login: ['', Validators.required],
            profile: ['', Validators.required],
            school: [''],
            unit: ['']
        });
        this.createManagerForm.get('school').setValue('');

        if (this.userService.isAdmin()) {
            this.getSchools();
        } else if (this.userService.isGeneralCoordinator()) {
            this.createManagerForm.get('school').setValue(this.userService.getUserSchool());
            this.getUnits(this.userService.getUserSchool());
        }
        if (this.manager) { this.initializeEditMode(); }
    }

    getSchools() {
        this.schoolService.getSchools()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.schools = response.data as School[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as escolas. Verifique a conexão e tente novamente'));
    }

    getProfiles() {
        this.profiles = [];
        this.profiles.push({ id: 2, name: 'Coordenador Da Unidade' });

        if (this.userService.isGeneralCoordinator()) {
            this.profiles.push({ id: 3, name: 'Coordenador Geral' });
        }

        if (this.userService.isAdmin()) {
            this.profiles.push({ id: 3, name: 'Coordenador Geral' });
        }
    }

    getManagersByNameOrEmail(name?: string, email?: string) {
        this.loading = true;
        this.schoolService.getManagersByNameOrEmail(this.userService.getUserProfileId(), this.selectedSchoolId, name, email)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.managers = response.data as Manager[];
                    this.managers = this.managers.map(m => {
                        m.unit = Number(m.unit);
                        m.profile = Number(m.profile);
                        return m;
                    });
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao buscar os coordenadores. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    getUnits(schoolId: number) {
        this.schoolService.getUnits(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.units = response.data as Unit[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao buscar as unidades. Verifique a conexão e tente novamente'));
    }

    getManagers(schoolId: number) {
        if(this.userService.isAdmin()){
          this.getAdminCoords(schoolId);
        }
        if(this.userService.isGeneralCoordinator()){
          this.getCoords(schoolId);
        }
    }

    getAdminCoords(schoolId: number){
      this.loading = true;


      this.schoolService.getManagers(schoolId, this.userService.getUserProfileId())
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                  this.managers = response.data as Manager[];
                  this.managers = this.managers.map(m => {
                      m.unit = Number(m.unit);
                      m.profile = Number(m.profile);
                      return m;
                  });
              } else {
                  this.alertService.error(response.error);
              }

              this.loading = false;
          }, err => {
              this.alertService.error('Houve um erro ao buscar os coordenadores. Verifique a conexão e tente novamente');
              this.loading = false;
          });
    }
    getCoords(schoolId: number){
      this.loading = true;


      this.schoolService.getManagersCoord(schoolId)
          .subscribe(res => {
              const response = res.body as ResponseApi;

              if (!response.error) {
                  this.managers = response.data as Manager[];
                  this.managers = this.managers.map(m => {
                      m.unit = Number(m.unit);
                      m.profile = Number(m.profile);
                      return m;
                  });
              } else {
                  this.alertService.error(response.error);
              }

              this.loading = false;
          }, err => {
              this.alertService.error('Houve um erro ao buscar os coordenadores. Verifique a conexão e tente novamente');
              this.loading = false;
          });
    }




    openList() {
        // this.getClasses();
        this.managers = [];
        if (!this.userService.isAdmin()) {
            this.getManagers(this.userService.getUserSchool());
        }
        this.showManagersList = true;
    }

    createManager() {
        if (this.createManagerForm.valid) {

            this.loading = true;
            const manager = this.createManagerForm.getRawValue() as Manager;
            this.schoolService.createManager(manager)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Coordenador cadastrado', 3);
                        this.createManagerForm.reset();
                        this.adminSerivce.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao cadastrar o coordenador. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.warning('Preenche todos os campos corretamente');
        }
    }

    editManager(manager: Manager) {
        const dialogRef = this.dialog.open(CreateManagerComponent, {
            minWidth: '60vw',
            data: manager
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.adminSerivce.updateChields();
            }
        });
    }

    removeManager(managerId: number) {
        if (confirm('Deseja realmente remover o Coordenador?')) {
            this.schoolService.removeManager(managerId, this.userService.getUserProfileId())
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Coordenador removido', 3);
                        this.adminSerivce.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover o coordenador. Verifique a conexão e tente novamente'));
        }
    }

    // only edit mode methods

    initializeEditMode() {
        this.editMode = true;
        this.createManagerForm.patchValue(this.manager);
        this.getUnits(this.manager.school as number);
        this.createManagerForm.get('password').setValidators([]);
    }

    saveChanges() {
        if (this.createManagerForm.valid) {

            this.loading = true;
            this.createManagerForm.get('id').setValue(this.manager.id);
            const manager = this.createManagerForm.getRawValue() as Manager;
            this.schoolService.saveManagerChanges(manager)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('Coordenador editado com sucesso', 3);
                        this.dialogRef.close(true);
                        this.adminSerivce.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao salvar as alterações da unidade. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        } else {
            this.alertService.error('Preencha todos os campos corretamente!');
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
