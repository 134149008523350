<div class="page-container p-pg page-container-mobile discipline">
    <div class="row m-0">
        <!-- plan not selected on mobile -->
        <div class="col-md-5 mt-2">
            <ng-container *ngIf="!layoutService.isMobile || !folderSelected">
                <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
                    <span class="page-title">Biblioteca de</span>
                    <div class="mt-3 description-title">
                        <h4 *ngIf="!layoutService.isMobile">Vídeos</h4>
                        <h6 class="mb-0" *ngIf="layoutService.isMobile">Vídeos</h6>
                        <p class="fs-13 pb-0">Escolha uma pasta para carregar os vídeos</p>
                    </div>
                </div>
                <!-- SELECT PLANO LISTA DESKTOP -->
                <div class="plan-list">
                    <mat-list *ngIf="!loadingFolders">
                        <mat-list-item *ngFor="let video of videos" (click)="selectFolder(video.videos, video.folder)" [ngClass]="{'pr-0': layoutService.isMobile}">
                            <div class=" plan-item mb-3" matRipple [ngClass]="{'bg-color-accent': folderSelected == video?.folder, 'pr-0': layoutService.isMobile}">
                                <div class="plan-icon">
                                    <mat-icon [color]=" (folderSelected == video?.folder) ? null : 'accent' ">folder</mat-icon>
                                </div>
                                <div class="plan-info">
                                    <strong class="plan-name">{{ video?.folder }}</strong>
                                    <span class="plan-expire"></span>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <mat-spinner *ngIf="loadingFolders" class="center table-spinner" color="accent"></mat-spinner>
                </div>
                <!-- SELECT PLANO LISTA MOBILE
                <div class="plan-list" *ngIf="layoutService.isMobile">
                    <mat-form-field class="width-100 mat-form-plan-list" appearance="fill" *ngIf="!loadingFolders">
                        <mat-select placeholder="Selecione uma pasta" class="plan-list" (selectionChange)="selectPlan(video.videos, video.folder)">
                            <mat-option [value]="video" *ngFor="let video of videos">
                                {{video?.folder}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-spinner *ngIf="loadingFolders" class="center table-spinner" color="accent"></mat-spinner>
                </div>-->
            </ng-container>
            <!-- mobile only -->
            <ng-container *ngIf="layoutService.isMobile && folderSelected">
                <div class="selected-plan page-title-container mt-2 pt-0">
                    <div class="lesson-title">
                        <div class="page-title">Pasta selecionada</div>
                        <h6 class="mb-0">{{folderSelected}}</h6>
                        <p class="fs-13 pb-0">&nbsp;</p>
                    </div>
                    <div class="lesson-back">
                        <button (click)="folderSelected = null" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
                            <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="col-md-7 mt-2">
            <!-- video list -->
            <ng-container>
                <div *ngIf="videoDataSource?.data?.length && !loadingVideos && folderSelected"
                class="table-container table-container-discipline height-100" [ngClass]="{' position-r top-130': !layoutService.isMobile}">
                    <!-- <table mat-table [dataSource]="videoDataSource" class="table-discipline mb-3 height-100">
                        Name Column
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef [ngClass]="{'brl-5 brr-5': layoutService.isMobile, 'brl-5': !layoutService.isMobile}"> NOME </th>
                            <td mat-cell *matCellDef="let video; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                                <span class="mobileMock" *ngIf="!layoutService.isMobile">{{video.name}}</span>
                                <button class="mobileMockLink table-mobile-link mr-10px" mat-button color="accent" (click)="open(video.link, video.name, video.external)">
                                    <span class="">{{video.name}} </span>
                                    <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent">Acessar</span>
                                </button>
                                <div class="row">
                                    <div class="col">
                                        {{video.name}}
                                    </div>
                                    <div class="col mr-2" *ngIf="deviceService.isMobile()">
                                        <a mat-button color="accent">
                                            <strong>Acessar</strong>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        Link Column
                        <ng-container matColumnDef="link" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef  class="brr-5 text-center"> LINK </th>
                            <td mat-cell *matCellDef="let video; let i = index" class="text-center" [ngClass]="{'table-line-contrast': i % 2}">
                                open video
                                <button mat-button color="accent" (click)="open(video.link, video.name, video.external)">
                                    <strong>Acessar</strong>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table> -->

                    <nb-list class="width-100 mb-3">
                        <nb-list-item class="bg-color-accent list-header brl-5 brr-5 fs-13">
                            <div class="row m-0">
                                <div class="col-md-10 c-pointer" (click)="getOrder('name')" [hidden]="layoutService.isMobile">
                                    <span class="d-inline-flex">
                                        <span class="mr-2">Nome</span>
                                    <mat-icon class="material-icons line-height-07" *ngIf="!reverse">arrow_drop_down</mat-icon>
                                    <mat-icon class="material-icons line-height-07" *ngIf="reverse">arrow_drop_up</mat-icon>
                                    </span>
                                </div>
                                <div [ngClass]="{'col-md-2': !layoutService.isMobile , 'col-2' : layoutService.isMobile}" class="text-center">Link</div>
                            </div>
                        </nb-list-item>
                        <nb-list-item *ngFor="let video of selectedVideos | orderBy: order:reverse; let i = index" [ngClass]="{'table-line-contrast': i % 2}">
                            <div class="row m-0">
                                <div [ngClass]="{'col-md-9': !layoutService.isMobile , 'col-12' : layoutService.isMobile}">
                                    <span class="mobileFileList">{{video.name}}</span>
                                    <button class="mobileDisciplineLink table-mobile-link mr-10px" color="accent" *ngIf="layoutService.isMobile" mat-button (click)="open(video.link, video.name, video.external)">
                                        <span class="white-space-breack">{{video.name}} </span>
                                        <span class="d-inline-flex float-right align-text-icon font-700 text-color-accent" *ngIf="layoutService.isMobile">Acessar</span>
                                    </button>
                                </div>
                                <div [ngClass]="{'col-md-3': !layoutService.isMobile , 'col-2' : layoutService.isMobile}" *ngIf="!layoutService.isMobile">
                                    <button mat-button color="accent" (click)="open(video.link, video.name, video.external)">
                                        <strong>Acessar</strong>
                                    </button>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>



                </div>
                <!-- select a folder -->
                <div *ngIf="!folderSelected && !loadingVideos" class="plan-not-selected">
                    <h4>Selecione uma pasta</h4>
                    <br />
                    <p>Escolha uma pasta para carregar os vídeos</p>
                </div>
                <!-- empty folder -->
                <div *ngIf="videoDataSource && !videoDataSource?.data?.length && !loadingVideos" class="plan-not-selected">
                    <h4>Nenhum vídeo</h4>
                    <br />
                    <ng-lottie class="mt-3" [options]="lottieOptions"></ng-lottie>
                </div>
                <mat-spinner *ngIf="loadingVideos" class="center table-spinner" color="accent"></mat-spinner>
            </ng-container>

        </div>
    </div>
</div>
