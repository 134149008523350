import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../shared/services/alert.service';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaskStudentService {

  private API = environment.ApiUrl;
  public emitSentTask = new EventEmitter<string>();

  constructor(
    private http: HttpClient,
    private alertService: AlertService) { }


    getTasksStudent(id: number) {
        return this.http.post(this.API + '/task/getTasksStudent', { id }, { observe: 'response' });
    }

    submitTask(taskId: number, studentId:number, studentName:string, sendTasks: any){

      return this.http.post(this.API + '/task/submitTask', { taskId, studentId, studentName, sendTasks }, { observe: 'response' });
    }

    submitTaskText(taskId: number, studentId:number, studentName:string, sendTasks: string){

      return this.http.post(this.API + '/task/submitTaskText', { taskId, studentId, studentName, sendTasks }, { observe: 'response' });
    }

    submitTaskCorrection(taskId: number, studentId:number, studentName:string, taskCorrected: any){

      return this.http.post(this.API + '/task/submitTaskCorrection',  { taskId, studentId, studentName, taskCorrected }, { observe: 'response' });
    }

    getAllTaskSentStudent(studentId: number){
      return this.http.post(this.API + '/task/getAllTaskSentStudent', { studentId }, { observe: 'response' });
    }
    getChannelTasksByClass(channelId: number){
      return this.http.get(this.API + '/channel/tasks/sent?channelId='+channelId, { observe: 'response' });
    }

    getStudentCorrection(taskId: number, studentId:number){
      return this.http.post(this.API + '/task/getStudentCorrection',  { taskId, studentId } , { observe: 'response' });
    }
    getPendingTasksStudent(studentId: number, classId:number){
      return this.http.post(this.API + '/task/getPendingTasksStudent', { studentId, classId }, { observe: 'response' });
    }

    getPendingChannel(channelId:number, studentId: number, classId:number){
      return this.http.get(this.API + '/channel/tasks/pending?channelId='+ channelId + '&studentId=' + studentId + '&classId='+ classId, { observe: 'response' });
    }
}
