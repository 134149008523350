<div class="page-container">
  <mat-card class="mb-3">
    <div class="page-title-container text-center">
      <span class="page-title">{{schoolName}}</span>
      <div class="mt-2">
        <h4>Dashboard do Administrador</h4>
      </div>
      <div><span>Confira os números de engajamento da sua plataforma</span></div>
    </div>
  </mat-card>
  <div class="row  p-0 mt-4 mb-5 text-center">
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-3 pr-0">
      <button routerLink="../../student/manage" class="button-style card">
        <span class="title">
          <img class="mb-2" src="assets/icons/student.svg">
          <br>Cadastrar <br><b>Alunos</b>
        </span>
      </button>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-3 pr-0">
      <button routerLink="../../discipline/manage" class="button-style card">
        <span class="title">
          <img class="mb-2" src="assets/icons/videos.svg">
          <br>Cadastrar <br><b>Vídeos</b>
        </span>
      </button>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-3 pr-0">
      <button routerLink="../../study-plan/create" class="button-style card">
        <span class="title">
          <img class="mb-2" src="assets/icons/plans.svg">
          <br>Cadastrar <br><b>Planos</b>
        </span>
      </button>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-3 pr-0">
      <button routerLink="../../repository/manage" class="button-style card">
        <span class="title">
          <img class="mb-2" src="assets/icons/files.svg">
          <br>Cadastrar <br><b>Arquivos</b>
        </span>
      </button>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-3 pr-0">
      <a href="https://pagamentos.aletech.com.br/empresa" target="_blank" class="button-style card">
        <span class="title">
          <img class="mb-2" src="assets/icons/card-payment.svg">
          <br>Plataforma de <br><b>Pagamentos</b>
        </span>
      </a>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-3 pr-0">
      <a href="https://wa.me/47996960734" target="_blank" class="button-style card">
        <span class="title">
          <img class="mb-2" src="assets/icons/support.svg">
          <br>Preciso de <br><b>Suporte</b>
        </span>
      </a>
    </div>
  </div>
  <!--    FILTROS    -->
  <mat-spinner *ngIf="!stoploading" class="center  mb-5" color="accent"></mat-spinner>
  <mat-card class="pb-4 pt-4">
    <div class="page-container">
      <div class="page-title-container">
        <div *ngIf="stoploading" class="mb-0 " [ngClass]="{ 'pl-4 pr-4': !layoutService.isMobile, 'pl-1 pr-1': layoutService.isMobile} ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <h6>Filtre as estatísticas da sua plataforma</h6>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-3" *ngIf="userService.isAdmin()">
              <mat-form-field class="width-100 m-0 p-0 form-field">
                <mat-select class="p-0 m-0 form-select" placeholder="Escola"
                  (selectionChange)="getPlanSelection($event.value) ; setSchoolObservable($event.value) ;getMocksSchool($event.value)"
                  [(ngModel)]="schoolId">
                  <mat-option *ngFor="let school of schools" [value]="school.id">
                    {{school.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--  <div class="col-md-3" [ngClass]="{'col-md-6': !userService.isAdmin()}">
                            <mat-form-field class="width-100 m-0 p-0 form-field">
                                <mat-select class="p-0 m-0 form-select" placeholder="Plano de estudos" [(ngModel)]="planId">
                                    <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                        {{plan.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
            <div class="col-md-4 d-flex" id="filter">
              <mat-form-field class="max-140 pr-1 form-field" (click)="pickerStart.open()">
                <mat-label>Início</mat-label>
                <input class="pointer" matInput [matDatepicker]="pickerStart" [(ngModel)]="startDate" readonly>
                <mat-datepicker-toggle #pickerStartToggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="max-140 pl-1 form-field" (click)="pickerEnd.open()">
                <mat-label>Fim</mat-label>
                <input class="pointer" matInput [matDatepicker]="pickerEnd" [(ngModel)]="endDate" readonly>
                <mat-datepicker-toggle #pickerEndToggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <button class="width-100" mat-raised-button color="accent"
                (click)="getAverageData() ; controlEmits()">Filtrar</button>
            </div>
          </div>
          <div class="row mt-4 mb-2" *ngIf="!loading">
            <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
              <div class="average-card p-3 m-2 text-center">
                <div class="average-card-header">
                  <mat-icon color="accent">person</mat-icon>
                  <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="Total de alunos cadastrados na plataforma em todos os cursos"
                    matTooltipHideDelay="2000">
                  </mat-icon>
                </div>
                <div class="average-card-title mt-3">
                  <div class="title">{{statistics.totalStudentsActive | number}} / {{statistics.totalStudents | number}}
                  </div>
                  <div class="small">Alunos Ativos</div>
                </div>
                <div class="average-card-botton">
                  <div class="value mt-3">Total de alunos cadastrados na plataforma em todos os cursos</div>
                </div>
              </div>
            </div>
            <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
              <div class="average-card p-3 m-2 text-center">
                <div class="average-card-header">
                  <mat-icon class="material-symbols-outlined" color="accent">favorite</mat-icon>
                  <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="Relação de alunos cadastrados / alunos ativos na plataforma durante o período"
                    matTooltipHideDelay="2000">
                  </mat-icon>
                </div>
                <div class="average-card-title mt-3">
                  <div class="title">{{statistics.engagementRate}}%</div>
                  <div class="small">Taxa de engajamento</div>
                </div>
                <div class="average-card-botton">
                  <div class="value mt-3">Relação de alunos cadastrados / alunos ativos na plataforma durante o período
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
              <div class="average-card p-3 m-2 text-center">
                <div class="average-card-header">
                  <mat-icon color="accent">home</mat-icon>
                  <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="Total de turma ativas cadastradas na plataforma de estudos" matTooltipHideDelay="2000">
                  </mat-icon>
                </div>
                <div class="average-card-title mt-3">
                  <div class="title">{{statistics.classTotalActive | number}} / {{statistics.classTotal | number}}</div>
                  <div class="small">Turmas Ativas</div>
                </div>
                <div class="average-card-botton">
                  <div class="value mt-3">Total de turmas ativas cadastradas na plataforma de estudos</div>
                </div>
              </div>
            </div>
            <div class="col-md pr-0-sm" [ngClass]="{'pr-2 pl-2': layoutService.isMobile}">
              <div class="average-card p-3 m-2 text-center">
                <div class="average-card-header">
                  <mat-icon color="accent">play_circle</mat-icon>
                  <mat-icon svgIcon="question" height="5" class="help-icon" matTooltipClass="custom-tooltip"
                    matTooltip="Total de aulas cadastradas até hoje disponíveis na sua escola"
                    matTooltipHideDelay="2000">
                  </mat-icon>
                </div>
                <div class="average-card-title mt-3">
                  <div class="title" *ngIf="!statistics.registeredClasses">0</div>
                  <div class="title" *ngIf="statistics.registeredClasses">{{statistics.registeredClasses | number}}
                  </div>
                  <div class="small">Aulas Cadastradas</div>
                </div>
                <div class="average-card-botton">
                  <div class="value mt-3">Total de aulas cadastradas até hoje disponíveis na sua escola</div>
                </div>
              </div>
            </div>
            <div *ngIf="!statistics" class="col-md-12 text-center">
              <h6>Nenhum resultado</h6>
            </div>
          </div>
        </div>
        <div class="mb-0 " [ngClass]="{ 'pl-4 pr-4': !layoutService.isMobile, 'pl-1 pr-1': layoutService.isMobile} ">
          <div class="row mt-4">
            <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-12">
              <div class="average-card report pt-4 pb-4 pr-3 pl-3">
                <div class="p-2 d-flex">
                  <mat-icon class="icon" color="accent">group</mat-icon>
                  <div class="pl-3">
                    <h6 class="mb-0">Alunos mais engajados</h6>
                    <span class="f-description">Total de aulas cadastradas até hoje disponíveis na sua escola</span>
                  </div>
                </div>
                <app-students class="row m-0 w-100 text-left"></app-students>
              </div>
            </div>
            <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-12">
              <div class="average-card pt-4 pb-4 pr-3 pl-3">
                <div class="p-2 d-flex">
                  <mat-icon class="icon" color="accent">bar_chart</mat-icon>
                  <div class="">
                    <h6 class="mb-0">Simulados e Avaliações</h6>
                    <span class="f-description">Visualize as últimas avaliações dos alunos</span>
                  </div>
                </div>
                <app-mock class="row m-0 w-100 text-left"></app-mock>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
  <br><br><br>
  <!--    INTEGRAÇÕES    -->
  <!-- <mat-card class="pb-4 pt-4 mt-5 mb-5">
        <div class="page-container">
            <div class="page-title-container">
                <div class="mb-0 " [ngClass]="{ 'pl-4 pr-4': !layoutService.isMobile, 'pl-1 pr-1': layoutService.isMobile} ">
                    <div class="row text-center">
                        <div class="col-md"><h6>Acesse as integrações disponíveis</h6></div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <br><br><br> -->
  <!--    RELATÓRIOS GERAIS    -->
  <mat-card class="pb-4 pt-4 mt-5 mb-5">
    <div class="page-container">
      <div class="page-title-container">
        <div class="mb-0 " [ngClass]="{ 'pl-4 pr-4': !layoutService.isMobile, 'pl-1 pr-1': layoutService.isMobile} ">
          <div class="row text-center">
            <div class="col-md mb-3">
              <h6>Relatórios do(a) {{schoolName}}</h6>
            </div>
          </div>
          <div class="row mt-3">
            <nb-accordion class="width-100 center">
              <nb-accordion-item [expanded]=false>
                <nb-accordion-item-header class="background-gray">
                  <span><mat-icon class="mr-3">trending_up</mat-icon> Desempenho</span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <nb-accordion class="width-100 center">
                    <nb-accordion-item [expanded]=false>
                      <nb-accordion-item-header class="background-gray" (click)="clearForm()">
                        <span>Individual</span>
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="row">
                          <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100"
                              (click)="startPerformanceSingle.open()">
                              <mat-label>Início</mat-label>
                              <input class="pointer" matInput [matDatepicker]="startPerformanceSingle"
                                [(ngModel)]="startDate" readonly>
                              <mat-datepicker-toggle #startPerformanceSingleToggle matSuffix
                                [for]="startPerformanceSingle"></mat-datepicker-toggle>
                              <mat-datepicker #startPerformanceSingle></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100"
                              (click)="endPerformanceSingle.open()">
                              <mat-label>Fim</mat-label>
                              <input class="pointer" matInput [matDatepicker]="endPerformanceSingle"
                                [(ngModel)]="endDate" readonly>
                              <mat-datepicker-toggle #endPerformanceSingleToggle matSuffix
                                [for]="endPerformanceSingle"></mat-datepicker-toggle>
                              <mat-datepicker #endPerformanceSingle></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3" *ngIf="userService.isAdmin()">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select class="p-0 m-0 form-select" placeholder="Escola"
                                (selectionChange)="getUnitsSelection($event.value); getPlanSelection($event.value)"
                                [(ngModel)]="schoolId" matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                  {{school.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-2" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select (selectionChange)="getClassesSelection($event.value)" class="width-100"
                                placeholder="Unidade" matTooltipClass="custom-tooltip" matTooltip="Selecione a unidade"
                                [(ngModel)]="unitId">
                                <mat-option *ngFor="let unit of units" [value]="unit.id">
                                  <span [title]="unit.name">{{unit.name}}</span>
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-2">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select
                                (selectionChange)="getStudents($event.value);getPlanSelectionByClass($event.value)"
                                class="width-100" placeholder="Turma" matTooltipClass="custom-tooltip"
                                matTooltip="Selecione a turma" [(ngModel)]="classId">
                                <mat-option *ngFor="let class of classes" [value]="class.id">
                                  <span [title]="class.name">{{class.name}}</span>
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-3">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select [(ngModel)]="planId" placeholder="Plano de estudos"
                                matTooltipClass="custom-tooltip" matTooltip="Selecione o plano">
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                  {{plan.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-2">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select class="width-100" placeholder="Aluno" matTooltipClass="custom-tooltip"
                                matTooltip="Selecione o aluno" [(ngModel)]="studentId">
                                <mat-option *ngFor="let student of students" [value]="student.id">
                                  {{student.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <button class="width-100 download" mat-raised-button color="accent"
                              (click)="generateReport(1)">
                              <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                            </button>
                          </div>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
                  </nb-accordion>
                  <nb-accordion class="width-100 mt-4 center">
                    <nb-accordion-item [expanded]=false>
                      <nb-accordion-item-header class="background-gray" (click)="clearForm()">
                        <span>Geral</span>
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="row">
                          <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100"
                              (click)="startPerformanceGeneral.open()">
                              <mat-label>Início</mat-label>
                              <input class="pointer" matInput [matDatepicker]="startPerformanceGeneral"
                                [(ngModel)]="startDate" readonly>
                              <mat-datepicker-toggle #startPerformanceSingleToggle matSuffix
                                [for]="startPerformanceGeneral"></mat-datepicker-toggle>
                              <mat-datepicker #startPerformanceGeneral></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <div class="col-md-6">
                            <mat-form-field appearance="outline" class="width-100"
                              (click)="endPerformanceGeneral.open()">
                              <mat-label>Fim</mat-label>
                              <input class="pointer" matInput [matDatepicker]="endPerformanceGeneral"
                                [(ngModel)]="endDate" readonly>
                              <mat-datepicker-toggle #endPerformanceSingleToggle matSuffix
                                [for]="endPerformanceGeneral"></mat-datepicker-toggle>
                              <mat-datepicker #endPerformanceGeneral></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3" *ngIf="userService.isAdmin()">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select class="p-0 m-0 form-select" placeholder="Escola"
                                (selectionChange)="getUnitsSelection($event.value); getPlanSelection($event.value)"
                                [(ngModel)]="schoolId" matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                                <mat-option *ngFor="let school of schools" [value]="school.id">
                                  {{school.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-3" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select (selectionChange)="getClassesSelection($event.value)" class="width-100"
                                placeholder="Unidade" matTooltipClass="custom-tooltip" matTooltip="Selecione a unidade"
                                [(ngModel)]="unitId">
                                <mat-option *ngFor="let unit of units" [value]="unit.id">
                                  <span [title]="unit.name">{{unit.name}}</span>
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-3">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select
                                (selectionChange)="getStudents($event.value);getPlanSelectionByClass($event.value)"
                                class="width-100" placeholder="Turma" matTooltipClass="custom-tooltip"
                                matTooltip="Selecione a turma" [(ngModel)]="classId">
                                <mat-option *ngFor="let class of classes" [value]="class.id">
                                  <span [title]="class.name">{{class.name}}</span>
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-3">
                            <mat-form-field appearance="outline" class="width-100">
                              <mat-select [(ngModel)]="generalReportPlanId" placeholder="Plano de estudos"
                                matTooltipClass="custom-tooltip" matTooltip="Selecione o plano">
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">
                                  {{plan.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <button *ngIf="!generalReportPlanId" class="width-100 download" mat-raised-button
                              color="accent" (click)="generateReport(2)">
                              <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                            </button>
                            <button *ngIf="generalReportPlanId" class="width-100 download" mat-raised-button
                              color="accent" (click)="generateReport(2)">
                              <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                            </button>
                          </div>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
                  </nb-accordion>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
          <div class="row mt-3">
            <nb-accordion class="width-100 center">
              <nb-accordion-item [expanded]=false>
                <nb-accordion-item-header class="background-gray" (click)="clearForm()">
                  <span><mat-icon class="mr-3">timer</mat-icon> Frequência</span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="startFrequencySingle.open()">
                        <mat-label>Início</mat-label>
                        <input class="pointer" matInput [matDatepicker]="startFrequencySingle" [(ngModel)]="startDate"
                          readonly>
                        <mat-datepicker-toggle #startFrequencySingleToggle matSuffix
                          [for]="startFrequencySingle"></mat-datepicker-toggle>
                        <mat-datepicker #startFrequencySingle></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="endFrequencySingle.open()">
                        <mat-label>Fim</mat-label>
                        <input class="pointer" matInput [matDatepicker]="endFrequencySingle" [(ngModel)]="endDate"
                          readonly>
                        <mat-datepicker-toggle #endFrequencySingleToggle matSuffix
                          [for]="endFrequencySingle"></mat-datepicker-toggle>
                        <mat-datepicker #endFrequencySingle></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4" *ngIf="userService.isAdmin()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="p-0 m-0 form-select" placeholder="Escola"
                          (selectionChange)="getUnitsSelection($event.value); getPlanSelection($event.value)"
                          [(ngModel)]="schoolId" matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                          <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select (selectionChange)="getClassesSelection($event.value)" class="width-100"
                          placeholder="Unidade" matTooltipClass="custom-tooltip" matTooltip="Selecione a unidade"
                          [(ngModel)]="unitId">
                          <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select (selectionChange)="getStudents($event.value)" class="width-100" placeholder="Turma"
                          matTooltipClass="custom-tooltip" matTooltip="Selecione a turma" [(ngModel)]="classId">
                          <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="width-100 download" mat-raised-button color="accent" (click)="generateReport(3)">
                        <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                      </button>
                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
          <div class="row mt-3">
            <nb-accordion class="width-100 center">
              <nb-accordion-item [expanded]=false>
                <nb-accordion-item-header class="background-gray" (click)="clearForm()">
                  <span><mat-icon class="mr-3">edit</mat-icon> Provas e Simulados</span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="startMock.open()">
                        <mat-label>Início</mat-label>
                        <input class="pointer" matInput [matDatepicker]="startMock" [(ngModel)]="startDate" readonly>
                        <mat-datepicker-toggle #startMockToggle matSuffix [for]="startMock"></mat-datepicker-toggle>
                        <mat-datepicker #startMock></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="endMock.open()">
                        <mat-label>Fim</mat-label>
                        <input class="pointer" matInput [matDatepicker]="endMock" [(ngModel)]="endDate" readonly>
                        <mat-datepicker-toggle #endMockToggle matSuffix [for]="endMock"></mat-datepicker-toggle>
                        <mat-datepicker #endMock></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3" *ngIf="userService.isAdmin()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="p-0 m-0 form-select" placeholder="Escola"
                          (selectionChange)="getUnitsSelection($event.value); getPlanSelection($event.value); getMockBySchool($event.value)"
                          [(ngModel)]="schoolId" matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                          <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select (selectionChange)="getClassesSelection($event.value)" class="width-100"
                          placeholder="Unidade" matTooltipClass="custom-tooltip" matTooltip="Selecione a unidade"
                          [(ngModel)]="unitId">
                          <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select (selectionChange)="getStudents($event.value)" class="width-100" placeholder="Turma"
                          matTooltipClass="custom-tooltip" matTooltip="Selecione a turma" [(ngModel)]="classId">
                          <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="width-100" placeholder="Aluno" matTooltipClass="custom-tooltip"
                          matTooltip="Selecione o aluno" [(ngModel)]="studentId">
                          <mat-option *ngFor="let student of students" [value]="student.id">
                            {{student.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="width-100" [(ngModel)]="mockId" placeholder="Selecione o simulado"
                          matTooltipClass="custom-tooltip" matTooltip="Selecione o simulado">
                          <mat-option *ngFor="let mock of mocks" [value]="mock.id">
                            <span [title]="mock.name">{{mock.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="width-100 download" mat-raised-button color="accent" (click)="generateReport(4)">
                        <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                      </button>
                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
          <div class="row mt-3">
            <nb-accordion class="width-100 center">
              <nb-accordion-item [expanded]=false>
                <nb-accordion-item-header class="background-gray">
                  <span><mat-icon class="mr-3">edit</mat-icon> Provas e Simulados - Somatória</span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="startSummation.open()">
                        <mat-label>Início</mat-label>
                        <input class="pointer" matInput [matDatepicker]="startSummation" [(ngModel)]="startDate"
                          readonly>
                        <mat-datepicker-toggle #startSummationToggle matSuffix
                          [for]="startSummation"></mat-datepicker-toggle>
                        <mat-datepicker #startSummation></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="endSummation.open()">
                        <mat-label>Fim</mat-label>
                        <input class="pointer" matInput [matDatepicker]="endSummation" [(ngModel)]="endDate" readonly>
                        <mat-datepicker-toggle #endSummationToggle matSuffix
                          [for]="endSummation"></mat-datepicker-toggle>
                        <mat-datepicker #endSummation></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3" *ngIf="userService.isAdmin()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="p-0 m-0 form-select" placeholder="Escola"
                          (selectionChange)="getUnitsSelection($event.value); getPlanSelection($event.value); getMockSummationBySchool($event.value)"
                          [(ngModel)]="schoolId" matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                          <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3" *ngIf="userService.isAdmin() || userService.isGeneralCoordinator()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select (selectionChange)="getClassesSelection($event.value)" class="width-100"
                          placeholder="Unidade" matTooltipClass="custom-tooltip" matTooltip="Selecione a unidade"
                          [(ngModel)]="unitId">
                          <mat-option *ngFor="let unit of units" [value]="unit.id">
                            <span [title]="unit.name">{{unit.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select (selectionChange)="getStudents($event.value)" class="width-100" placeholder="Turma"
                          matTooltipClass="custom-tooltip" matTooltip="Selecione a turma" [(ngModel)]="classId">
                          <mat-option *ngFor="let class of classes" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="width-100" placeholder="Aluno" matTooltipClass="custom-tooltip"
                          matTooltip="Selecione o aluno" [(ngModel)]="studentId">
                          <mat-option *ngFor="let student of students" [value]="student.id">
                            {{student.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="width-100" [(ngModel)]="mockId" placeholder="Selecione o simulado"
                          matTooltipClass="custom-tooltip" matTooltip="Selecione o simulado">
                          <mat-option *ngFor="let mock of mocks" [value]="mock.id">
                            <span [title]="mock.name">{{mock.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="width-100 download" mat-raised-button color="accent" (click)="generateReport(5)">
                        <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                      </button>
                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
          <div class="row mt-3">
            <nb-accordion class="width-100 center">
              <nb-accordion-item [expanded]=false>
                <nb-accordion-item-header class="background-gray">
                  <span><mat-icon class="mr-3">person</mat-icon> Dados gerais</span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="startGeneralData.open()">
                        <mat-label>Início</mat-label>
                        <input class="pointer" matInput [matDatepicker]="startGeneralData" [(ngModel)]="startDate"
                          readonly>
                        <mat-datepicker-toggle #startGeneralDataToggle matSuffix
                          [for]="startGeneralData"></mat-datepicker-toggle>
                        <mat-datepicker #startGeneralData></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="endGeneralData.open()">
                        <mat-label>Fim</mat-label>
                        <input class="pointer" matInput [matDatepicker]="endGeneralData" [(ngModel)]="endDate" readonly>
                        <mat-datepicker-toggle #endGeneralDataToggle matSuffix
                          [for]="endGeneralData"></mat-datepicker-toggle>
                        <mat-datepicker #endGeneralData></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12" *ngIf="userService.isAdmin()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="p-0 m-0 form-select" placeholder="Escola" [(ngModel)]="schoolId"
                          matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                          <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="width-100 download" mat-raised-button color="accent" (click)="generateReport(6)">
                        <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                      </button>
                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
          <div class="row mt-3">
            <nb-accordion class="width-100 center">
              <nb-accordion-item [expanded]=false>
                <nb-accordion-item-header class="background-gray">
                  <span><mat-icon class="mr-3">library_books</mat-icon>Redações</span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="startGeneralData.open()">
                        <mat-label>Início</mat-label>
                        <input class="pointer" matInput [matDatepicker]="startGeneralData" [(ngModel)]="startDate"
                          readonly>
                        <mat-datepicker-toggle #startGeneralDataToggle matSuffix
                          [for]="startGeneralData"></mat-datepicker-toggle>
                        <mat-datepicker #startGeneralData></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="width-100" (click)="endGeneralData.open()">
                        <mat-label>Fim</mat-label>
                        <input class="pointer" matInput [matDatepicker]="endGeneralData" [(ngModel)]="endDate" readonly>
                        <mat-datepicker-toggle #endGeneralDataToggle matSuffix
                          [for]="endGeneralData"></mat-datepicker-toggle>
                        <mat-datepicker #endGeneralData></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12" *ngIf="userService.isAdmin()">
                      <mat-form-field appearance="outline" class="width-100">
                        <mat-select class="p-0 m-0 form-select" placeholder="Escola" [(ngModel)]="schoolId"
                          matTooltipClass="custom-tooltip" matTooltip="Selecione a escola">
                          <mat-option *ngFor="let school of schools" [value]="school.id">
                            {{school.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="width-100 download" mat-raised-button color="accent" (click)="generateReport(7)">
                        <mat-icon class="mr-3">cloud_download</mat-icon> Baixar Excel
                      </button>
                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
