<div id="polygon"  *ngIf="!layoutService.isMobile"></div>
<div class="col-12 "  *ngFor="let notification of notifications">      
    <div class="col-12 p-3 br-5 mb-3" [ngClass]="{'bgWhite': notification.read, 'bgGray': !notification.read}">
        <ng-container>
            <div class="row">
                <div class="col-12 d-flex">
                    <h6 class="notifs-title">{{notification.title}}</h6>
                    <h6 class="btn-close-notifis" (click)="removeNotification(notification.id)">X</h6>
                </div>
            </div>
        </ng-container>
        <ng-container>
            <div class="row">
                <div class="col-12"  *ngIf='notification.image'>
                    <img class="width-100 pt-2 pb-3" [src]="notification.image">
                </div>
                <div class="col-12"  *ngIf="notification.vimeo == '1'">
                    <iframe class="width-100 pt-2 pb-3" #player_container width="100%" height="250" [src]="notification.linkSanityzed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div class="row" *ngIf="notification.text">
                <div class="col-12">
                    <span class="fs-13 pb-0" [ngClass]="{'text-limit': notification.link, 'text-all': !notification.link}" [innerHtml]="notification.text"></span>
                </div>
            </div>
        </ng-container>    
        <ng-container>
            <div class="row mt-4">
                <div class="col-7 text-left" *ngIf="!limited">
                    <!--<button (click)="markAsRead(notification.id)" class="c-pointer read" color="accent">Marcar como lida</button>-->
                    <span (click)="markAsRead(notification)" class="c-pointer read" [ngClass]="{'active': notification.read}">
                        <span class="fs-13" *ngIf="notification.read">Marcar como não lida</span>
                        <span class="fs-13" *ngIf="!notification.read">Marcar como lida</span>
                    </span> 
                </div>
                <div class="col-5 text-right" *ngIf="!limited">
                    <button (click)="viewMore(notification)" mat-raised-button class="button-comment small-btn view-more fs-12" color="accent">Ver mais</button>
                    <!--<button routerLink="user/profile" mat-raised-button class="button-comment small-btn view-more fs-13" color="accent">Ver mais</button> -->
                </div>
                <div class="col-12 text-right lesson-back" *ngIf="limited && !(notification.image || notification.link_video)">
                    <button (click)="closeDialog()" class="closeBtn width-100" mat-raised-button color="accent">Fechar</button>
                </div>
            </div>
        </ng-container>
    </div>    
</div>
<div  *ngIf="!notifications || notifications.length === 0">
    <p class="fw-300 p-5 mb-0">Nenhuma notificação</p>
</div>
