import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ResponseApi } from '../models/response-api';
import { SystemVersion } from '../models/system-version';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import jwt_decode from 'jwt-decode';
import { EventEmitter } from '@angular/core';
import { Login } from './login';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    API = environment.ApiUrl;
    private decoded: any;
    private response: ResponseApi;
    public emitLoginIsDone = new EventEmitter<string>();
    public emitPortal = new EventEmitter<string>();

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private alertService: AlertService) { }

    authenticate(email: string, password: string) {

        return this.http
            .post(this.API + '/access/signin', { email, password }, { observe: 'response' })
            .pipe(
                tap(res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.userService.setUserLoggedData(response.data);
                        this.setVersionSystem();
                        this.emitLoginIsDone.emit('done'); 
                        const user = response.data as Login;
                        if(user.portal) this.emitPortal.emit('done');
                    }
                }));
    }

    authenticateFather(userId : number) {

        return this.http
            .post(this.API + '/access/fatherStudent', {userId}, { observe: 'response' })
            .pipe(
                tap(res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.userService.setUserLoggedData(response.data);
                        this.setVersionSystem();
                        this.emitLoginIsDone.emit('done'); 
                    }
                }));
    }
    authenticateLogin(token: string) {

        try {
            this.decoded = jwt_decode(token);

            this.response = this.decoded as ResponseApi;
            if (!this.response.error) {
                this.userService.setUserLoggedData(this.response.data);
                this.setVersionSystem();
            } else {
                this.response = { data: [], error: 'Acesso negado!' };
                this.userService.logout();
            }
        } catch (error) {
            this.response = { data: [], error: 'Acesso negado!' };
            this.userService.logout();
        }

        return this.response;
    }
    authenticateLoginServer(token: string) {

        return this.http
            .post(this.API + '/redirect/signin', { token }, { observe: 'response' })
            .pipe(
                tap(res => {
                    const response = res.body as ResponseApi;
                    if (!response.error) {
                        this.userService.setUserLoggedData(response.data);
                        this.setVersionSystem();
                    }
                }));
    }

    isUpdated() {

        return this.http.get(this.API + '/system/version')
            .pipe(
                tap((res: ResponseApi) => {
                    if (res.data) {
                        const currentBuild = res.data as SystemVersion;
                        const localBuild = this.getCurrentVersionLoged();

                        if (currentBuild[0].version !== localBuild[0].version) {
                            this.alertService
                                .success('A página será recarregada em 5 minutos para atualizar a sua versão da plataforma!', 30);
                            this.setVersionSystem();
                            setTimeout(() => {
                                location.reload();
                            }, 5 * 1000);
                        }
                    }
                })
            );
    }

    setVersionSystem() {
        this.http.get(this.API + '/system/version', { observe: 'response' })
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {

                    const version = response.data as SystemVersion;
                    window.localStorage.setItem(btoa('System Version'), btoa(JSON.stringify(version)));
                }
            });

    }

    getCurrentVersionLoged() {
        const versionRaw = window.localStorage.getItem(btoa('System Version'));

        if (versionRaw) {
            const localVersion: SystemVersion = JSON.parse(atob(versionRaw));
            return localVersion;
        }

        const empty = [{ buildDate: '0', version: '0' }];
        return empty;
    }

    initVersionCheck(frequencyMinutes: number) {
        setInterval(() => {
            this.isUpdated().subscribe();
        }, frequencyMinutes * 60 * 1000);
    }



    recovery(email: string) {
        return this.http.post(this.API + '/signin/recovery', { email }, { observe: 'response' });
    }
}
