<div class="container pt-5 pb-5">
    <nb-accordion class="width-70 mt-4 center">
        <nb-accordion-item [expanded]=false>
            <nb-accordion-item-header>
                Relatório Simulado (Somatória)
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <app-summation-students></app-summation-students>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>