<nb-layout>

  <nb-layout-column>

    <app-header>
      <router-outlet></router-outlet>
    </app-header>
    <app-network [offline]="offline"></app-network>
  </nb-layout-column>

</nb-layout>