<div>
    <form [formGroup]="formTasks">
        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="taskName">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Disciplina/Assunto" formControlName="taskSubject">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select placeholder="Turma" formControlName="classId">
                        <mat-option>Selecione uma turma</mat-option>
                        <mat-option *ngFor="let class of classes" (click)="className=class.name" [value]="class.id">
                            <span [title]="class.name">{{class.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Data de entrega" formControlName="finalDate" [textMask]="{mask: maskService.dateTime}">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100" *ngIf="!taskDescription">
                    <mat-label>Observação</mat-label>
                    <input matInput placeholder="Observação" formControlName="taskDescription">
                </mat-form-field>
                <mat-form-field class="width-100" *ngIf="taskDescription">
                    <small><i>{{taskDescription}}</i></small>
                    <button class="ml-1" size="tiny" (click)="removeDesc()" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 annex" *ngIf="taskLink">
                <a mat-button (click)="openLink(task.taskLink)" color="accent">
                    <mat-icon>attach_file</mat-icon> Arquivo Anexado
                </a>
                <button class="ml-1" size="tiny" (click)="removeLink()" status="danger" nbButton ghost>
                    <nb-icon icon="trash"></nb-icon>
                </button>
            </div>
            <div class="col-md-6 annex" *ngIf="!taskLink">
                <input class="width-100" accept="" (change)="handleFileInput($event.target.files);" fullWidth status="primary" type="file" nbInput placeholder="Anexar Arquivo">
            </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="width-100">
              <mat-select placeholder="Planos" formControlName="plans">
                  <mat-option>Selecione um plano</mat-option>
                  <mat-option (click)="addPlanChip(plan)" *ngFor="let plan of plans" [value]="plan.id">
                      <span [title]="plan.name">{{plan.name}}</span>
                  </mat-option>
              </mat-select>
          </mat-form-field>
          </div>
          <div class="col-md-6 right">
            <div *ngIf="selectedPlans?.length > 0">
                <mat-label class="mb-2">Escolas selecionadas</mat-label>
                <mat-chip-list *ngIf="selectedPlans">
                    <mat-chip *ngFor="let plan of selectedPlans" selectable="true" removable="true" (removed)="removeSchoolChip(plan)">
                        {{plan.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
      </div>
    </form>
    <div class="row mt-5 text-right">
        <button class="right" (click)="updateTask()" nbButton size="small" status="primary">Salvar</button>
    </div>
</div>
