import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Classroom } from './classroom';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ClassroomService {

    private API = environment.ApiUrl;

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe) { }

    getClassroms(planId: number) {
        return this.http.post(this.API + '/classroom/getClassRooms', { planId }, { observe: 'response' });
    }

    getRecordedClassroms(planId: number) {
        return this.http.post(this.API + '/classroom/getRecordedClassroms', { planId }, { observe: 'response' });
    }

    getClassromsByClass(userId: number , classId: number) {
        return this.http.post(this.API + '/classroom/getClassRoomsByClassId', { userId , classId}, { observe: 'response' });
    }

    getClassromsByClassPaginated(userId: number , classId: number, offset?: number, limit?: number) {
      return this.http.post(this.API + '/classroom/getClassRoomsByClassIdPaginated', { userId , classId, offset, limit}, { observe: 'response' });
    }

    getClassRoomsAdmin(planId: number, schoolIdPlan:number) {
        return this.http.post(this.API + '/classroom/getClassRoomsAdmin', { planId, schoolIdPlan }, { observe: 'response' });
    }

    getClassRoomsAdminByClass(classId: number) {
        return this.http.post(this.API + '/classroom/getClassRoomsAdminByClassId', { classId }, { observe: 'response' });
    }

    getClassByName(isClass: boolean, name:string) {
        return this.http.post(this.API + '/classroom/getClassByName', { isClass, name }, { observe: 'response' });
    }

    getClassBySchoolByName(isClass: boolean, name:string, schoolId: number) {
        return this.http.post(this.API + '/classroom/getClassBySchoolByName', { isClass, name, schoolId }, { observe: 'response' });
    }

    getClassByUnitByName(isClass: boolean, name:string, unitId: number) {
        return this.http.post(this.API + '/classroom/getClassByUnitByName', { isClass, name, unitId }, { observe: 'response' });
    }

    /* getFuturePlanClassrooms(planId: number, schoolId:number) {
        return this.http.post(this.API + '/classroom/getFuturePlanClassroom', { planId, schoolId }, { observe: 'response' });
    } */

    getFuturePlanClassrooms(planId: number) {
        return this.http.post(this.API + '/classroom/getFuturePlanClassroom', { planId }, { observe: 'response' });
    }

    getMonthClassroomRecord(planId: number, fullDate: Date) {
        const date = this.datePipe.transform(fullDate, 'yyyy-MM');
        return this.http.post(this.API + '/classroom/getMonthRecords', { planId, date }, { observe: 'response' });
    }

    getDayClassroomRecord(planId: number, day: Date) {
        const date = this.datePipe.transform(day, 'yyyy-MM-dd');
        return this.http.post(this.API + '/classroom/getDayRecords', { planId, date }, { observe: 'response' });
    }

    getDayClassroomRecordByClass(classId: number, day: Date) {
        //const date = this.datePipe.transform(day, 'yyyy-MM-dd');
        //return this.http.post(this.API + '/classroom/getDayRecordsByClass', { classId, date }, { observe: 'response' });
    }

    createClassroom(classroom: Classroom) {
        return this.http.post(this.API + '/classroom/addClassRoom', classroom, { observe: 'response' });
    }

    removeClassroom(classroom: Classroom) {
        return this.http.post(this.API + '/classroom/removeClassRoom', classroom , { observe: 'response' });
    }

    saveClassroomChanges(classroom: Classroom) {
        return this.http.post(this.API + '/classroom/saveClassRoom', classroom, { observe: 'response' });
    }
    updateClassrooms(classroom: Classroom) {
        return this.http.post(this.API + '/classroom/updateClassrooms', classroom, { observe: 'response' });
    }
    updateClassroomsByClass(classroom: Classroom) {
        return this.http.post(this.API + '/classroom/updateClassrooms', classroom, { observe: 'response' });
    }
    similarClassrooms(classroomName: string, planId:number) {
        return this.http.post(this.API + '/classroom/similarClassrooms', { classroomName, planId }, { observe: 'response' });
    }
}
