import { Component, OnInit, Input } from '@angular/core';
import { ReportService } from '../../report.service';
import { UserService } from 'src/app/modules/user/user.service';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Average } from './average';
import { AlertService } from 'src/app/shared/services/alert.service';
import { StudyPlanService } from 'src/app/modules/admin/study-plan/study-plan.service';
import { StudyPlan } from 'src/app/modules/admin/study-plan/study-plan';
import { filterDays } from './filterdays';

@Component({
    selector: 'app-average-chart',
    templateUrl: './average-chart.component.html',
    styleUrls: ['./average-chart.component.scss']
})

export class AverageChartComponent implements OnInit {
 
    @Input() averageData: any; 

    public average: Average = {
        performance: 0,
        average: 0,
        frequency: 0,
        participation: 0,
        studyPlan: 0
    };

    public filterDays =  filterDays;
 
    public selectedPlan:any;
    public period: number;
    public loading: boolean;
    constructor(
        private reportService: ReportService,
        private userService: UserService,
        private alertService: AlertService,
        private studyPlanService: StudyPlanService,) { }

    ngOnInit(): void { 
       
    } 
}
