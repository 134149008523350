<div class="col-12 w-100 mt-2 text-left" *ngIf="dataSource?.data?.length">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
        <!-- Student Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Aluno </th>
          <td mat-cell *matCellDef="let mock; let i = index"> {{mock.name}} </td>
        </ng-container>

        <!-- Mock Column -->
        <ng-container matColumnDef="mock">
          <th mat-header-cell *matHeaderCellDef> Simulado </th>
          <td mat-cell *matCellDef="let mock; let i = index" class="limit-text"> {{mock.mock}} </td>
        </ng-container>

        <!-- Questions Column -->
        <ng-container matColumnDef="questions">
        <th mat-header-cell *matHeaderCellDef> Questões </th>
        <td mat-cell *matCellDef="let mock; let i = index" class="f-2 d-inline-flex">
            <span class=" d-inline-flex" color="accent"><mat-icon class="material-symbols-outlined d-inline-flex"  color="accent">check_circle</mat-icon> {{mock.correct}} </span>
            <span>&nbsp;&nbsp;</span>
            <span color="error" class=" d-inline-flex"><mat-icon class="material-symbols-outlined" color="error">cancel</mat-icon> {{mock.erros}} </span>
        </td>
        </ng-container>

        <!-- Data Column -->
        <ng-container matColumnDef="realized" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> Realizado </th>
            <td mat-cell *matCellDef="let mock; let i = index">
              {{mock.realized | date:'dd/MM/yyyy'}} - {{mock.realized | date:'HH:mm'}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<!-- <mat-spinner *ngIf="!dataSource?.data?.length" class="center  mb-5" color="accent"></mat-spinner> -->
