<div class="mt-3 mb-3" >
    <form [formGroup]="formGenerateReport">
        <div class="row">
            <div class="col-md-6" *ngIf="userService.isAdmin()">
                <mat-form-field class="width-100">
                    <mat-select formControlName="school" (selectionChange)="getPlans($event.value)"
                        placeholder="Escola">
                        <mat-option *ngFor="let school of schools" [value]="school.id">
                           <span [title]="school.name">{{school.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="plan" placeholder="Plano" 
                        (selectionChange)="getSimulated($event.value)">
                        <mat-option value="0">Todas</mat-option>
                        <mat-option *ngFor="let plan of plans" [value]="plan.id">
                            <span [title]="plan.name">{{plan.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select  formControlName="simulated" placeholder="Simulado"
                        (selectionChange)="getDisciplinesSimulated($event.value)" >
                        <mat-option *ngFor="let simulate of simulated" [value]="simulate.id">
                            <span [title]="simulate.name">{{simulate.name}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="width-100">
                    <mat-select  formControlName="discipline" (selectionChange)="getReports()"  placeholder="Disciplinas">
                        <mat-option *ngFor="let discipline of mockDisciplines" [value]="discipline.idDiscipline">
                            <span [title]="discipline.nome_disciplina">{{discipline.nome_disciplina}}</span> 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <button (click)="downloadReport()" class="right" nbButton size="small"
                    status="primary" nbSpinnerStatus="warning">Relatório TXT</button>
            </div>
            <div class="col-md-4">
                <button (click)="generateXls()" class="right" nbButton size="small"
                    status="primary" nbSpinnerStatus="warning">Relatório Excel</button>
            </div>
        </div>
    </form>
</div>