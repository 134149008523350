import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class TopicDisciplineService {

  private API = environment.ApiUrl;
  constructor(
    private http: HttpClient
  ) { }

  getPlansByUser(userId: number, classId: number) {
    return this.http.get(this.API + '/topic/plans/'+userId+'/'+classId, { observe: 'response' });
  }

  getDisciplinesByPlan(userId: number, planId: number) {
    return this.http.get(this.API + '/topic/disciplines/'+userId+'/'+planId, { observe: 'response' });
  }

  getDisciplineTags(userId: number, disciplineId: number) {
    return this.http.get(this.API + '/topic/topics/'+userId+'/'+disciplineId, { observe: 'response' });
  }

  getTopicVideos(userId: number, topicId: number) {
    return this.http.get(this.API + '/topic/videos/'+ userId+'/'+ topicId, { observe: 'response' });
  }

}
