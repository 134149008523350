import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AdminService } from '../../../admin.service';
import { SelectPlanComponent } from '../../../shared-components/select-plan/select-plan.component';
import { SyncService } from '../../sync.service';
import { Edital, MainTheme, MotivacionalText, SelectedPlansResp, ThemeText } from '../theme';

@Component({
  selector: 'app-edit-theme',
  templateUrl: './edit-theme.component.html',
  styleUrls: ['./edit-theme.component.scss']
})
export class EditThemeComponent implements OnInit {

  public createThemeForm: FormGroup;
  public typeTheme: { name: string, type: number }[] = [{ name: 'Enem', type: 1 }, { name: 'Vestibular', type: 2 }, { name: 'Concurso', type: 3 } , { name: 'UFSC', type: 4 },
  { name: 'UFPR', type: 5 }, { name: 'UFRGS', type: 6 }, { name: 'UDESC', type: 7 }, { name: 'ACAFE', type: 8 }, { name: 'FUVEST', type: 9 }, { name: 'UNICAMP', type: 10 }];
  public loading: boolean;
  public motivacionalText: Array<MotivacionalText>
  public alertExternal: number;
  public selectedPlans: SelectedPlansResp[];
  public schoolId: number;
  public editals : {id:number , type_id:number , name:string , description:string}[] = [    {
    "id": 69,
    "type_id": 2,
    "name": "UFPR 2021",
    "description": null
},
{
    "id": 256,
    "type_id": 2,
    "name": "FUVEST",
    "description": null
},
{
    "id": 257,
    "type_id": 2,
    "name": "UFSC",
    "description": null
},
{
    "id": 477,
    "type_id": 2,
    "name": "ACAFE",
    "description": null
},
{
    "id": 479,
    "type_id": 2,
    "name": "UDESC",
    "description": null
},
{
    "id": 505,
    "type_id": 2,
    "name": "UFRGS",
    "description": null
},
{
    "id": 747,
    "type_id": 2,
    "name": "UFPR 2013",
    "description": null
},
{
    "id": 800,
    "type_id": 2,
    "name": "UFPR 2019",
    "description": null
},
{
    "id": 833,
    "type_id": 2,
    "name": "Unicamp",
    "description": null
},
{
    "id": 859,
    "type_id": 2,
    "name": "UFPR - RESUMO",
    "description": null
}];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public theme: MainTheme,
    @Optional() public dialogRef: MatDialogRef<EditThemeComponent>,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private adminService: AdminService,
    public syncService: SyncService,
    private userService: UserService
  ) { }

  ngOnInit() {

    if(this.theme.motivacionalText[0]){
    this.motivacionalText = this.theme.motivacionalText;
    }
    this.getDbEditals();

    this.createThemeForm = this.formBuilder.group({
      name: [''],
      tag: [''],
      externalId: [''],
      type: [''],
      order: [''],
      motivacionalText: [''],
      editalId:['']
    });
    this.schoolId = Number(this.userService.getUserSchool());
    this.createThemeForm.patchValue(this.theme);

  }

  updateTheme(){
    this.loading = true;
    if(this.createThemeForm.valid){
      this.updateAssociateThemeToPlan(this.theme.id, this.selectedPlans);
      const idsave = this.theme.id;
      this.theme = this.createThemeForm.getRawValue();
      this.theme.id = idsave;
      this.theme.motivacionalText = this.motivacionalText;
      this.adminService.updateTheme(this.theme)
        .subscribe(res =>{
          const response = res.body as ResponseApi

          if(!response.error){
            const id = response.data as any;
            if(this.selectedPlans){
            if(id && id > 0 && this.selectedPlans.length > 0){
              this.updateAssociateThemeToPlan(id, this.selectedPlans);
            }
          }
            this.alertService.success('Dados atualizados com sucesso!');
            this.dialogRef.close(true);
            this.loading = false;
          }
          else{
            this.alertService.error(response.error);
            this.loading = false;
          }
        }, err =>{
          this.alertService.error('Houve um erro ao criar o tema. Verifique a conexão e tente novamente');
        })
      }else{
        this.alertService.error('Prencha todos os campos!')
      }
  }

  updateAssociateThemeToPlan(themeId: number, selectedPlans: SelectedPlansResp[]){
    if(this.selectedPlans){
    if(this.selectedPlans.length > 0){
      this.syncService.updateAssociateThemeToPlan(themeId, selectedPlans, this.schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if(!response.error){
          this.selectedPlans = [];
        }else{
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao criar o tema. Verifique a conexão e tente novamente');
        this.loading = false;});
    }
  }
  }

  getThemeText(){
    if(this.createThemeForm.get('externalId').value){
      const externalId = this.createThemeForm.get('externalId').value;

      this.adminService.getThemeText(externalId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if(!response.error){
          let data = response.data as ThemeText;
          this.createThemeForm.get('name').setValue(data.title);
          this.createThemeForm.get('type').setValue(data.correction_type.id);
          data.motivational_texts.forEach(value => {

            value.motivacional_text = value.motivacional_text.replaceAll("src=\"/themes/" , 'src="https://ia.redacaonline.com.br/themes/');

          });
          this.motivacionalText = data.motivational_texts;
          this.alertExternal = 2;

        }
        else{
          this.alertService.error('erro');
          this.alertExternal = 1;
        }
      })
    }
    else{
      this.alertService.error('precisa ser prenchido o id externo para atualizar!');
    }
  }

  openSelectPlans() {
    const dialogRef = this.dialog.open(SelectPlanComponent, {
      minWidth: '60vw',
      data: []
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
        const index = this.selectedPlans.indexOf(result);
        if (index < 0) {
          result.forEach(element => {
            let planSelected = {id:element.id, name:element.name};
            this.selectedPlans.push(planSelected);
          });
        }
      }
    });
  }

  getDbEditals(){
    this.syncService.getDbEditals()
    .subscribe(res => {
      const response = res.body as ResponseApi

      if(!response.error){
        let dbedital = response.data as Edital[];

        dbedital.forEach(dbEd => {
          dbEd.id = dbEd.type_id;
          dbEd.type_id = dbEd.essayThemeTypeId;
          this.editals.push(dbEd);
        });

        //console.log(this.editals);
      }
    })
  }

  removePlanChip(plan: SelectedPlansResp) {
    const index = this.selectedPlans.indexOf(plan);

    if (index >= 0) {
      this.selectedPlans.splice(index, 1);
    }
  }

}
