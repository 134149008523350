<div *ngIf="!showSchoolList">

    <h4 *ngIf="editMode" class="mt-3 mb-3">Editar escola</h4>
    <h6 *ngIf="editMode" class="mt-3 mb-3">Total alunos: {{totalStudents}}</h6>
    <form [formGroup]="createSchoolForm">

        <!-- Name, TAG -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Tag" formControlName="tag">
                </mat-form-field>
            </div>
        </div>

        <!-- Color, Logo -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="color" placeholder="Cor">
                        <mat-option *ngFor="let color of colors" [value]="color.name">
                            <div class="row" #colorName>
                                <div class="col-10">
                                    {{color.name}}
                                </div>
                                <div class="col-2 pt-1 pb-1" [ngStyle]="{'background-color': color.color}">
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <div class="mt-1 mb-2">Logo da escola (opcional)</div>
                <input (change)="handleFileInput($event.target.files, 'logo');" accept="image/*" fullWidth status="primary" type="file" nbInput placeholder="Logo">
            </div>
        </div>

        <!-- domain -->
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="width-100">
                    <input matInput placeholder="Domínio (opcional)" formControlName="domain">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md pb-3">
                <mat-slide-toggle class="mt-3" #showHeader formControlName="showHeaderImg">Exibir imagem no header
                </mat-slide-toggle>
            </div>
        </div>

        <div class="row">
            <div class="col-md pb-3">
                <mat-slide-toggle class="mt-3" formControlName="restrict_domain">Planos restritos nesse dominio
                </mat-slide-toggle>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <div class="mt-1 mb-2">Imagem do header (opcional)</div>
                <input (change)="handleFileInput($event.target.files, 'header');" accept="image/*" fullWidth status="primary" type="file" nbInput placeholder="Imagem do header" [disabled]="!showHeader.checked">
            </div>
            <div class="col-md">
                <div class="mt-1 mb-2">Background do login (opcional)</div>
                <input (change)="handleFileInput($event.target.files, 'login');" accept="image/*" fullWidth status="primary" type="file" nbInput placeholder="Background do login">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="mt-1 mb-2">Upload Favicon
                    <mat-icon matTooltipClass="custom-tooltip" matTooltip="A imagem deve ser de 32x32.">
                        help
                    </mat-icon>
                </div>
                <input (change)="handleFileInput($event.target.files, 'favicon');" accept="image/x-icon" fullWidth status="primary" type="file" nbInput placeholder="Favicon">

            </div>
        </div>

        <!-- rotas -->
        <div class="row mt-4">
            <div class="col-md-6">
                <div>
                    <mat-form-field class="width-100">
                        <mat-select formControlName="blockRoutes" placeholder="Ocultar rotas">
                            <mat-option (click)="addRoutesChip(route)" *ngFor="let route of routes" [value]="route.id">
                                {{route.descr}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="selectedRoutes?.length > 0">
                    <mat-label class="mb-2">Links do menu ocultados</mat-label>
                    <mat-chip-list *ngIf="selectedRoutes">
                        <mat-chip *ngFor="let route of selectedRoutes" selectable="true" removable="true" (removed)="removeRoutesChip(route)">
                            {{route.descr}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="col-md-6">
                <mat-form-field class="width-100">
                    <mat-select formControlName="defaultRouteId" placeholder="Rota Padrão">
                        <mat-option *ngFor="let route of routes" [value]="route.id">
                            {{route.descr}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 pb-3">
                <mat-slide-toggle class="mt-3" formControlName="ocultMocksDashboard">Ocultar área de avaliações
                </mat-slide-toggle>
            </div>
            <div class="col-md-6 pb-3">
                <mat-slide-toggle class="mt-3" formControlName="ocultLiveClassroom">Ocultar Salas ao Vivo!
                </mat-slide-toggle>
            </div>
        </div>

    </form>


    <div *ngIf="!editMode" class="row mt-5">
        <div class=" col-6 width-100">
            <button (click)="openList()" nbButton size="small" outline status="primary" class="text-color-primary border-primary-thin">Ver escolas
                cadastradas</button>
        </div>
        <div class="col-6 text-right width-100">
            <button (click)="createSchool()" class="right bg-color-primary border-primary" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning" >Cadastrar</button>
        </div>
    </div>
    <div *ngIf="editMode" class="row mt-5">
        <div *ngIf="editMode" class="text-left col-6">
            <button (click)="duplicateSchool()" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Duplicar</button>
        </div>

        <div *ngIf="editMode" class="text-right col-6">
            <button (click)="saveChanges()" class="right" nbButton size="small" status="primary" [nbSpinner]="loading" [disabled]="loading" nbSpinnerStatus="warning">Salvar</button>
        </div>
    </div>
</div>

<div *ngIf="showSchoolList" class="">
    <nb-list class="width-90 mt-3 center">
        <nb-list-item *ngFor="let school of schools">
            <div class="row">
                <div class="col-4">
                    {{school.name}}
                </div>
                <div class="col-4">
                    criado em {{school.createDate | date:'dd/MM/yyyy'}}
                </div>
                <div class="col-2 text-right">
                    <button (click)="editSchool(school)" nbButton size="tiny" outline status="primary">Editar</button>
                </div>
                <div class="col-2 text-right">
                    <button size="tiny" (click)="removeSchool(school.id)" status="danger" nbButton ghost>
                        <nb-icon icon="trash"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="text-right width-100">
        <button (click)="backBtn()" class="right" nbButton size="small" status="primary">voltar</button>
    </div>
</div>