<div class="page-container">

    <div class="page-title-container" [ngClass]="{'text-center': layoutService.isMobile}">
        <span class="page-title">RESULTADO</span>
        <div class="mt-3 description-title">
            <ng-container>
                <h4 *ngIf="!layoutService.isMobile">Confira o seu desempenho</h4>
                <h6 class="mb-0" *ngIf="layoutService.isMobile">Confira o seu desempenho</h6>
                <p>Clique na questão para ver mais detalhes</p>
            </ng-container>
        </div>
    </div>

    <div class="lesson-back" [ngClass]="{'text-center': layoutService.isMobile}">
        <button routerLink="../../list" [ngClass]="{'width-50 text-left back-button': layoutService.isMobile}" color="accent" mat-button class="mt-1 mb-1 hvr-grow">
        <mat-icon>reply</mat-icon> <span class="back">Voltar</span>
      </button>
    </div>

    <div *ngIf="!loading">
        <div class="mt-5 mb-3  text-color-primary">
            <div class="mat-stroked-button result-text" color="primary" *ngIf="userService.isStudent() && getPunctuation()">
                Você pontuou <b>{{getPunctuation() | number:'1.2-2'}}</b> de <b>{{questions.length | number:'1.2-2'}}</b>
            </div>
            <div class="mat-stroked-button result-text" color="primary" *ngIf="userService.isAdmin()">
                Acertos do aluno: <b>{{hits}}</b> de <b>{{questions.length}}</b>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100">

            <ng-container matColumnDef="questionNumber">
                <th mat-header-cell *matHeaderCellDef> Questão </th>
                <td mat-cell *matCellDef="let question"><b>{{question.index + 1}}</b></td>
            </ng-container>

            <ng-container matColumnDef="studentResponse">
                <th mat-header-cell *matHeaderCellDef> Resposta </th>
                <td mat-cell *matCellDef="let question"><b>{{question.studentResponse}}</b></td>
            </ng-container>

            <ng-container matColumnDef="correctResponse">
                <th mat-header-cell *matHeaderCellDef> Correta </th>
                <td mat-cell *matCellDef="let question"><b>{{question.correctAlternative}}</b></td>
            </ng-container>

            <ng-container matColumnDef="punctuation">
                <th mat-header-cell *matHeaderCellDef class="text-right"> Pontuação </th>
                <td mat-cell *matCellDef="let question" class="text-right">
                    <b>{{question.punctuation | number:'1.2-2'}}</b>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let question" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail" [@detailExpand]="question == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="m-4">
                            <h4 class="mb-3">Questão:</h4>
                            <div class="mb-4 question-html" [innerHTML]="question.question"></div>
                            <div class="" *ngFor="let alt of question.alternatives">
                            <!--    ***************** NÃO REMOVER O NGIF ABAIXO - VALIDA SE OPÇÃO NÃO FOR NULA *****************    -->
                                <b class="alternative" *ngIf="alt.value">{{alt.option}})</b>
                                <div  *ngIf="alt.value" style="height: 70%; white-space: pre-line" [innerHTML]="alt.value"></div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>
    <div *ngIf="loading" class="text-center">
        <mat-spinner color="accent" class="center"></mat-spinner>
    </div>


</div>