import { Component, OnInit } from '@angular/core';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Mock } from 'src/app/modules/mock/mock';
import { MockService } from 'src/app/modules/mock/mock.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { School } from '../../../school/school';
import { SchoolService } from '../../../school/school.service';
import { StudyPlan } from '../../../study-plan/study-plan';
import { StudyPlanService } from '../../../study-plan/study-plan.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-mock-general-report',
  templateUrl: './mock-general-report.component.html',
  styleUrls: ['./mock-general-report.component.scss']
})
export class MockGeneralReportComponent implements OnInit {

  public studyPlans: StudyPlan[];
  public schools: School[];
  public mocks: Mock[];
  public mockReport: { name: string, url: string };
  public loading: boolean;
  public linkDownload: any[];

  constructor(
    private mockService: MockService,
    private schoolService: SchoolService,
    private studyPlanService: StudyPlanService,
    private alertService: AlertService,
    public userService: UserService) { }

  ngOnInit() {
    this.profileInit();
  }

  profileInit() {
    if (this.userService.isAdmin()) {
      this.getSchools();
    } else {
      this.getSchoolPlans(this.userService.getUserSchool());
    }
  }

  getSchools() {
    this.loading = true;
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getSchoolPlans(schoolId: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.studyPlans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getPlanMocks(planId: number) {
    this.loading = true;
    this.mockService.getPlanMocks(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getMockGeneralReport(mockId: number) {
    this.loading = true;
    this.mockService.getMockGeneralReport(mockId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mockReport = response.data as { name: string, url: string };
          if(response.data){
          let Auxresponse = response.data as []; 
          let auxdata = [];

          Auxresponse.forEach(element =>  {
            auxdata.push(element);
          });
          this.generateXls(auxdata[0]);
          }
          else{
            this.alertService.error('não existem dados com esses requisitos tente novamente com outros');
          }
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar o relatório geral. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  generateXls(arrRelat : any){
        const headWrite = [];
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Employee Data");

        // pega as keys de um array

        let auxHeaderName = [];

        auxHeaderName.push(arrRelat[0]);
        
        let auxKeys = auxHeaderName.flatMap(x => Object.keys(x));

        let straux = auxKeys.join(',');

        let aux2 = [];

        auxKeys.forEach(element => {
          aux2.push('"'+ element+'"');
        });

        let header = aux2;
        let headerRow = worksheet.addRow(header);

        for (let x1 of arrRelat) {
            let x2 = Object.keys(x1);
            let temp = []
            for (let y of x2) {
                temp.push(x1[y])
            }
            worksheet.addRow(temp)
        }

        let fname = "simulado_relatorio_geral";

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });

  }

  openFile() {
    window.open(this.mockReport?.url, '_blank');
  }
}
