import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseApi } from 'src/app/core/models/response-api';
import { Mock } from 'src/app/modules/mock/mock';
import { MockService } from 'src/app/modules/mock/mock.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Class } from '../../school/class';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { Unit } from '../../school/unit';
import { Student } from '../../student/student';
import { StudentService } from '../../student/student.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';

@Component({
  selector: 'app-edit-mock-student',
  templateUrl: './edit-mock-student.component.html',
  styleUrls: ['./edit-mock-student.component.scss']
})
export class EditMockStudentComponent implements OnInit {

  public schools: School[];
  public loading: boolean;
  public plans: StudyPlan[];
  public mocks: Mock[];
  public selectedMocks: Mock[];
  public units: Unit[];
  public classes: Class[];
  public selectedStudents: Student[];
  public students: Student[]
  public editMockForm: FormGroup;
  public saving: boolean;
  public editMode: boolean;
  public class: Class;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public student: Student,
  @Optional() public dialogRef: MatDialogRef<EditMockStudentComponent>,
    private schoolService: SchoolService,
    private alertService: AlertService,
    public userService: UserService,
    private dialog: MatDialog,
    private studyPlanService: StudyPlanService,
    private mockService: MockService,
    private formBuilder: FormBuilder,
    private studentService: StudentService
  ) { }

  ngOnInit() {
    this.loading = false;
    this.selectedMocks = [];
    this.selectedStudents = [];
    this.schools = [];
    this.editMockForm = this.formBuilder.group({
      schoolId: [''],
      planId: [''],
      mockId: [''],
    })
    if(this.userService.isAdmin){
      this.getSchools();
    }
    if(this.userService.isGeneralCoordinator()){
      this.getPlans(this.userService.getUserSchool());
    }
    if(this.userService.isUnitCoordinator()){
      this.getPlans(this.userService.getUserSchool());
    }
    this.getMocksStudentsAssociated();
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getPlans(schoolId?: number) {
    this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  getMocksStudentsAssociated(){
    this.selectedMocks = [];
    this.studentService.getMocksStudentAssociated(this.student.id)
    .subscribe(res =>{
      const response = res.body as ResponseApi;

      if(!response.error){
        this.selectedMocks = response.data as Mock[];
      }
      else{
        this.alertService.error(response.error);
      }
    },err =>{
      this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
    })
  }

  updateMocksStudentsAssociated(){
    this.loading = true;
    this.studyPlanService.UpdatedStudentMocksAssociated(this.selectedMocks , this.student.id)
    .subscribe(res =>{
      const response = res.body as ResponseApi;

      if(!response.error){
        this.alertService.success('Dados Atualizados com sucesso!');
        this.loading = false;
        this.dialogRef.close(true);
      }
      else{
        this.alertService.error(response.error);
        this.loading = false;
      }
    },err =>{
      this.alertService.error('Houve um erro ao carregar os planos. Verifique a conexão e tente novamente');
      this.loading = false;
    })
  }

  getPlanMocks(planId: number) {
    this.loading = true;
    this.mockService.getPlanMocks(planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.mocks = response.data as Mock[];
        } else {
          this.alertService.error(response.error);
        }
        this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao carregar os simulados. Verifique a conexão e tente novamente');
        this.loading = false;
      });
  }

  addMockChip(mock: Mock) {
    const index = this.selectedMocks.indexOf(mock);

    if (index < 0) {
      this.selectedMocks.push(mock);
      this.editMockForm.get('mockId').reset();
    }
  }

  removeMockChip(mock: Mock) {
    const index = this.selectedMocks.indexOf(mock);

    if (index >= 0) {
      this.selectedMocks.splice(index, 1);
    }
  }

}