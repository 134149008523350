// tslint:disable: triple-equals
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
import { ExerciseAlternative } from 'src/app/modules/video/alternative';
import { Video } from 'src/app/modules/video/video';
import { VideoService } from 'src/app/modules/video/video.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { environment } from 'src/environments/environment';

import { AdminService } from '../../admin.service';
import { Discipline } from '../../discipline/discipline';
import { DisciplineService } from '../../discipline/discipline.service';
import { Exercise } from '../exercise';
import { ExerciseService } from '../exercise.service';
import { UserService } from 'src/app/modules/user/user.service';
import { StudyPlanService } from '../../study-plan/study-plan.service';
import { SchoolService } from '../../school/school.service';



@Component({
    selector: 'app-create-exercise',
    templateUrl: './create-exercise.component.html',
    styleUrls: ['./create-exercise.component.scss']
})
export class CreateExerciseComponent implements OnInit, OnDestroy {

    public questionEditor = ClassicEditor;
    public aEditor = ClassicEditor;
    public bEditor = ClassicEditor;
    public cEditor = ClassicEditor;
    public dEditor = ClassicEditor;
    public eEditor = ClassicEditor;
    public commentEditor = ClassicEditor;
    public question: string;

    public exerciceTag: string;
    public tag: string;
    public exercisetag: string;

    public comment: string;
    public editorConfig;
    public filter = '';
    private apiUrl = environment.ApiUrl;

    public A: ExerciseAlternative;
    public B: ExerciseAlternative;
    public C: ExerciseAlternative;
    public D: ExerciseAlternative;
    public E: ExerciseAlternative;

    public videos: Video[];
    public selectedVideos: Video[];
    public createExerciseForm: FormGroup;
    public showExercisesList: boolean;
    public exercises: Exercise[];
    public correctAlternative: string;
    public editMode: boolean;
    public loading: boolean;
    public currentVideoEdit: number;
    public filterVideos: Video[];
    public filterDisciplines: Discipline[];
    public filterName: string;
    public filterTag: string;
    public loadingInit = true;
    public filterDisciplineId: number;
    public filterDisciplineType = 0;
    public filterVideoId: number;
    public selectedDisciplines: Discipline[];
    public disciplines: Discipline[];
    public disciplinesAux: Discipline[];
    public exerciseDisciplines: Discipline[];
    public admUpdate$: Subscription;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public exercise: Exercise,
        @Optional() public dialogRef: MatDialogRef<CreateExerciseComponent>,
        private dialog: MatDialog,
        private videoService: VideoService,
        private alertService: AlertService,
        private formBulder: FormBuilder,
        private disciplineService: DisciplineService,
        private exerciseService: ExerciseService,
        public maskService: MaskService,
        private adminService: AdminService,
        private iconRegistry: MatIconRegistry,
        public userService: UserService,
        private sanitizer: DomSanitizer,
        private schoolService: SchoolService) { }


    ngOnInit(): void {
        this.editorConfig = {
            ckfinder: {
                // tslint:disable-next-line: max-line-length
                uploadUrl: this.apiUrl + '/upload/exerciseImg',
            },
            image: {
                // You need to configure the image toolbar, too, so it uses the new style buttons.
                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

                styles: [
                    // This option is equal to a situation where no style is applied.
                    'full',

                    // This represents an image aligned to the left.
                    'alignLeft',

                    // This represents an image aligned to the right.
                    'alignRight'
                ]
            }
        };
        this.iconRegistry.addSvgIcon(
            'vimeo',
            this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/vimeo.svg'));
        this.iconRegistry.addSvgIcon(
            'youtube',
            this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/youtube.svg'));
        this.iconRegistry.addSvgIcon(
            'novideo',
            this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/novideo.svg'));
        this.admUpdate$ = this.adminService.getUpdateSubject()
            .subscribe(() => this.init());
    }

    ngOnDestroy(): void {
        this.admUpdate$.unsubscribe();
    }

    editorsConf() {
    }

    init() {

        this.A = { option: 'a', value: '' };
        this.B = { option: 'b', value: '' };
        this.C = { option: 'c', value: '' };
        this.D = { option: 'd', value: '' };
        this.E = { option: 'e', value: '' };
        this.question = '';
        this.comment = '';
        this.getDisciplinesInit();

        this.createExerciseForm = this.formBulder.group({
            id: [''],
            name: ['', Validators.required],
            tag: ['', Validators.required],
            question: ['', Validators.required],
            videos: [],
            alternatives: ['', Validators.required],
            disciplineId: [],
            correctAlternative: ['', Validators.required],
            comment: ['', Validators.required],
            embedCode: [''],
            embedType: [''],
            commentEmbedCode: [''],
            commentEmbedType: [''],
        });

        this.selectedVideos = [];
        this.selectedDisciplines = [];
        if (this.exercise) { this.initializeEditMode(); }
    }

    addVideoChip(video: Video) {
        const index = this.selectedVideos.find(obj=> obj.id=== video.id);
        if (!index) {
        this.selectedVideos.push(video);
        this.createExerciseForm.get('videos').reset();
        }
    }

    removeVideoChip(video: Video) {
        const index = this.selectedVideos.indexOf(video);

        if (index >= 0) {
            this.selectedVideos.splice(index, 1);
        }
    }

    openList() {
        this.exercises = [];
        this.showExercisesList = true;
    }

    createExercise() {

        if (this.selectedDisciplines.length === 0 && this.selectedVideos.length === 0) {
            this.alertService.error('Você deve selecionar ao menos um vídeo ou uma disciplina!');
            return;
        }

        this.loading = true;
        const videosIds = [];
        const disciplinesIds = [];
        const alternatives = [];

        alternatives.push(this.A, this.B, this.C, this.D, this.E);
        this.selectedVideos.map(v => videosIds.push(v.id));
        this.selectedDisciplines.map(d => disciplinesIds.push(d.id));
        this.createExerciseForm.get('videos').setValue(videosIds);
        this.createExerciseForm.get('disciplineId').setValue(disciplinesIds);
        this.createExerciseForm.get('correctAlternative').setValue(this.correctAlternative);
        this.createExerciseForm.get('question').setValue(this.question);
        this.createExerciseForm.get('alternatives').setValue(alternatives);
        this.createExerciseForm.get('comment').setValue(this.comment);

        const exercise = this.createExerciseForm.getRawValue() as Exercise;
        exercise.embed = { code: this.createExerciseForm.get('embedCode').value, type: this.createExerciseForm.get('embedType').value };
        exercise.commentEmbed = {
            code: this.createExerciseForm.get('commentEmbedCode').value,
            type: this.createExerciseForm.get('commentEmbedType').value
        };

        if (this.createExerciseForm.errors) {
            this.alertService.error('Preencha todos os campos corretamente');
            this.loading = false;
            return;
        }

        this.exerciseService.createExercise(exercise)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Exercício cadastrado', 3);
                    this.createExerciseForm.reset();
                    this.selectedVideos = [];
                    this.correctAlternative = '';
                    this.init();
                    this.adminService.updateChields();
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao cadastrar exercício. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    editExercise(exercise: Exercise) {
        const dialogRef = this.dialog.open(CreateExerciseComponent, {
            minWidth: '60vw',
            data: exercise,
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {


                if (this.filterDisciplineType == 0) {
                    this.getVideoExercises(this.filterVideoId);
                } else
                    if (this.filterDisciplineType == 1) {
                        this.getDisciplineExercises(this.filterDisciplineId);
                    } else {
                        this.getExercisesByName();
                    }
            }
        });
    }

    removeExercise(exerciseId: number) {
        if (confirm('Deseja realmente remover o exercício?')) {
            this.exerciseService.removeExercise(exerciseId)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.alertService.success('exercício removido', 3);
                        this.getVideoExercises(this.filterVideoId);
                        this.adminService.updateChields();
                    } else {
                        this.alertService.error(response.error);
                    }
                }, err => this.alertService.error('Houve um erro ao remover o exercício. Verifique a conexão e tente novamente'));
        }
    }

    // edit mode methods

    getExerciseCorrectAlternative(exerciseId: number) {
        this.exerciseService.getExerciseCorrectAlternative(exerciseId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    const resp = response.data as { correct: string, comment: string, tag: string };
                    console.log('Tag from API:', resp.tag); 
                    this.correctAlternative = resp.correct;
                    this.exercisetag  = resp.tag;
                    this.comment = resp.comment;

                    this.createExerciseForm.patchValue({
                        tag: this.exercisetag
                    });
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }

    initializeEditMode() {
        this.loadingInit = false;

        this.editMode = true;
        this.question = this.exercise.question;
        this.getExerciseVideos(this.exercise.id,);
        this.getExerciseCorrectAlternative(this.exercise.id);
        this.getExerciseDisciplines(this.exercise.id);

        console.log('TAG:',this.exercisetag);
        this.A.value = this.exercise.alternatives[0].value;
        this.B.value = this.exercise.alternatives[1].value;
        this.C.value = this.exercise.alternatives[2].value;
        this.D.value = this.exercise.alternatives[3].value;
        this.E.value = this.exercise.alternatives[4].value;

        this.createExerciseForm.patchValue({
            name: this.exercise.name,
        });
        console.log('Form value after patchValue:', this.createExerciseForm.value); 
        this.createExerciseForm.get('embedType').setValue(this.exercise.embed.type);
        this.createExerciseForm.get('embedCode').setValue(this.exercise.embed.code);
        this.createExerciseForm.get('commentEmbedType').setValue(this.exercise.commentEmbed.type);
        this.createExerciseForm.get('commentEmbedCode').setValue(this.exercise.commentEmbed.code);
    }

    getExerciseVideos(exerciseId: number) {
        this.exerciseService.getExerciseVideos(exerciseId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.selectedVideos = response.data as Video[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao . Verifique a conexão e tente novamente'));
    }



    saveChanges() {

        if (this.selectedDisciplines.length === 0 && this.selectedVideos.length === 0) {
            this.alertService.error('Você deve selecionar ao menos um vídeo ou uma disciplina!');
            return;
        }

        this.loading = true;
        const videosIds = [];
        const alternatives = [];
        const disciplinesIds = [];

        alternatives.push(this.A, this.B, this.C, this.D, this.E);
        this.selectedVideos.map(v => videosIds.push(v.id));
        this.selectedDisciplines.map(d => disciplinesIds.push(d.id));
        this.createExerciseForm.get('videos').setValue(videosIds);
        this.createExerciseForm.get('disciplineId').setValue(disciplinesIds);
        this.createExerciseForm.get('correctAlternative').setValue(this.correctAlternative);
        this.createExerciseForm.get('question').setValue(this.question);
        this.createExerciseForm.get('alternatives').setValue(alternatives);
        this.createExerciseForm.get('comment').setValue(this.comment);
        this.createExerciseForm.get('id').setValue(this.exercise.id);

        const exercise = this.createExerciseForm.getRawValue() as Exercise;
        exercise.embed = { code: this.createExerciseForm.get('embedCode').value, type: this.createExerciseForm.get('embedType').value };
        exercise.commentEmbed = {
            code: this.createExerciseForm.get('commentEmbedCode').value,
            type: this.createExerciseForm.get('commentEmbedType').value
        };

        if (this.createExerciseForm.errors) {
            this.alertService.error('Preencha todos os campos corretamente');
            this.loading = false;
            return;
        }

        this.exerciseService.saveExerciseChanges(exercise)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.alertService.success('Turma editada com sucesso', 3);
                    this.dialogRef.close(true);
                    this.getVideoExercises(this.filterVideoId);
                    this.adminService.updateChields();
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao salvar as alterações da turma. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    verifyFilter(value: string) {
        if (value) {
            return value.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 || !this.filter;
        } else {
            return true;
        }
    }

    getDisciplinesInit() {
        if (this.userService.isAdmin()) {
            this.getDisciplinesVideosOnly();
            this.getDisciplinesExercisesOnly();
        } else if (this.userService.isGeneralCoordinator()) {

            this.getSchoolDisciplinesVideosOnly(this.userService.getUserSchool());
            this.getSchoolDisciplinesExercisesOnly(this.userService.getUserSchool());

        } else if (this.userService.isTeacher()) {
            this.getTeacherDisciplines(this.userService.getUserId());

        } else if (this.userService.isUnitCoordinator()) {
            this.getUnitDisciplines(this.userService.getUserUnit());
        }
    }

    getDisciplines() {
        if (this.userService.isAdmin()) {
            if (this.filterDisciplineType === 0) {
                this.getDisciplinesVideosOnly();
            } else {
                if (this.filterDisciplineType === 1) {
                    this.getDisciplinesExercisesOnly();
                }
            }

        } else if (this.userService.isGeneralCoordinator()) {
            if (this.filterDisciplineType === 0) {
                this.getSchoolDisciplinesVideosOnly(this.userService.getUserSchool());
            } else {
                if (this.filterDisciplineType === 1) {
                    this.getSchoolDisciplinesExercisesOnly(this.userService.getUserSchool());
                }
            }

        } else if (this.userService.isTeacher()) {
            this.getTeacherDisciplines(this.userService.getUserId());

        } else if (this.userService.isUnitCoordinator()) {
            this.getUnitDisciplines(this.userService.getUserUnit());
        }
    }

    getSchoolDisciplines(schoolId: number) {
        if (this.filterDisciplineType === 0) {
            this.getSchoolDisciplinesVideosOnly(schoolId);
        } else {
            if (this.filterDisciplineType === 1) {
                this.getSchoolDisciplinesExercisesOnly(schoolId);
            }
        }
    }

    getDisciplinesVideosOnly() {
        this.disciplineService.getDisciplines(true)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.filterDisciplines = response.data as Discipline[];
                    this.disciplines = response.data as Discipline[];
                    this.disciplinesAux = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingInit = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
                this.loadingInit = false;
            });
    }

    getSchoolDisciplinesVideosOnly(schoolId: number) {
        this.disciplineService.getSchoolDisciplinesVideosOnly(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.filterDisciplines = response.data as Discipline[];
                    this.disciplines = response.data as Discipline[];
                    this.disciplinesAux = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingInit = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente');
                this.loadingInit = false;
            });
    }

    getSchoolDisciplinesExercisesOnly(schoolId: number) {
        this.disciplineService.getSchoolDisciplinesExercisesOnly(schoolId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.exerciseDisciplines = response.data as Discipline[];
                    this.filterDisciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas de exercícios. Verifique a conexão e tente novamente'));
    }

    private getTeacherDisciplines(teacherId: number) {
        this.schoolService.getTeacherDisciplines(teacherId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.filterDisciplines = response.data as Discipline[];
                    this.disciplines = response.data as Discipline[];
                    this.disciplinesAux = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingInit = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as disciplinas do professor. Verifique a conexão e tente mais tarde');
                this.loadingInit = false;
            });
    }

    private getUnitDisciplines(unitId: number) {
        this.schoolService.getUnitDisciplines(unitId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.filterDisciplines = response.data as Discipline[];
                    this.disciplines = response.data as Discipline[];
                    this.disciplinesAux = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
                this.loadingInit = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar as disciplinas da unidade. Verifique a conexão e tente mais tarde');
                this.loadingInit = false;
            });
    }

    getVideos(disciplineId: number) {
        this.filterVideoId = null;
        this.disciplineService.getDisciplineVideos(disciplineId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.filterVideos = response.data as Video[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getVideoExercises(videoId: number) {

        this.loading = true;
        this.currentVideoEdit = videoId;
        this.videoService.getVideoExercises(videoId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.exercises = response.data as Exercise[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar os exercícios. Verifique a conexão e tente novamente');
                this.loading = false;
            });

    }

    getExercisesByName() {

        if (this.filterName.length > 3) {
            this.loading = true;
            this.exerciseService.getExercisesByName(this.filterName)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.exercises = response.data as Exercise[];
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar os videos. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
        }
    }

    getExercisesByTag() {

        if (this.filterTag.length > 1) { 
            this.loading = true;
            this.exerciseService.getExercisesByTag(this.filterTag)
                .subscribe(res => {
                    const response = res.body as ResponseApi;

                    if (!response.error) {
                        this.exercises = response.data as Exercise[];
                    } else {
                        this.alertService.error(response.error);
                    }

                    this.loading = false;
                }, err => {
                    this.alertService.error('Houve um erro ao carregar os Exercícios. Verifique a conexão e tente novamente');
                    this.loading = false;
                });
                
        } else {
            this.alertService.warning('Precisa-se de no mínimo 4 caractéres');
        }
    }

    addDisciplineChip(discipline: Discipline) {
        const index = this.selectedDisciplines.indexOf(discipline);

        if (index < 0) {
            this.selectedDisciplines.push(discipline);
            this.createExerciseForm.get('disciplineId').reset();
        }
    }

    removeDisciplineChip(discipline: Discipline) {
        const index = this.selectedDisciplines.indexOf(discipline);

        if (index >= 0) {
            this.selectedDisciplines.splice(index, 1);
        }
    }

    getExerciseDisciplines(exerciseId: number) {
        this.exerciseService.getExerciseDisciplines(exerciseId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.selectedDisciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas. Verifique a conexão e tente novamente'));
    }

    getDisciplineVideos(disciplineId: number) {
        this.disciplineService.getDisciplineVideos(disciplineId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.videos = response.data as Video[]
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar os vídeos. Verifique a conexão e tente novamente'));
    }

    getDisciplinesExercisesOnly() {
        this.disciplineService.getDisciplinesExercisesOnly()
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.exerciseDisciplines = response.data as Discipline[];
                    this.filterDisciplines = response.data as Discipline[];
                } else {
                    this.alertService.error(response.error);
                }
            }, err => this.alertService.error('Houve um erro ao carregar as disciplinas de exercícios. Verifique a conexão e tente novamente'));
    }

    public getDisciplineExercises(disciplineId: number) {
        this.loading = true;
        this.exerciseService.getExercisesByDiscipline(disciplineId)
            .subscribe(res => {
                const response = res.body as ResponseApi;

                if (!response.error) {
                    this.exercises = response.data as Exercise[];
                } else {
                    this.alertService.error(response.error);
                }

                this.loading = false;
            }, err => {
                this.alertService.error('Houve um erro ao carregar os exercícios. Verifique a conexão e tente novamente');
                this.loading = false;
            });
    }

    filterDisciplinesRegister(filter: string) {
        this.disciplines = [...this.disciplinesAux].filter(discipline => {
            return discipline.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
    }

}
