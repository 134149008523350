import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { ElementSchemaRegistry } from '@angular/compiler';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ResponseApi } from 'src/app/core/models/response-api';
//import { Classroom } from 'src/app/modules/classroom/classroom';
import { ClassroomService } from 'src/app/modules/classroom/classroom.service';
import { UserService } from 'src/app/modules/user/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AdminService } from '../../admin.service';
import { School } from '../../school/school';
import { SchoolService } from '../../school/school.service';
import { StudyPlan } from '../../study-plan/study-plan';
import { StudyPlanService } from '../../study-plan/study-plan.service';

interface StudyPlans {
  name: string;
  id: number;
}

interface Classrooms{
  name: string;
  id: number;
  planName?: string;
}

interface Classroom {
  id: number;
  name: string;
  classId: number;
  planId?:any;
  planName?:string;
  schools: [],
  unitId: number;
  link: string;
  initDate: string | Date;
  endDate: string | Date;
  tag: string;
  recordLink?: string;
}


@Component({
  selector: 'app-edit-classes',
  templateUrl: './edit-classes.component.html',
  styleUrls: ['./edit-classes.component.scss']
})
export class EditClassesComponent implements OnInit {

  public formEdit: FormGroup;
  public classrooms: Classroom[];
  public schools: School[];
  public plans: StudyPlan[];
  private admUpdate$: Subscription;
  public studyPlanArray: StudyPlans[] = [];
  public similarClassrooms: Classroom[];
  public classroomsArray: Classrooms[] = [];
  public editMode: boolean;
  public classes: Classroom[];
  public selectedClasses: { name: string, id: number };
  public classeId: [];
  public nameClassroom:string;
  public schoolIdPlan: number;
  public schoolSelected: number;
  public planIdSelected: number;
  public showButton: boolean;
  public open: boolean;
  public classroomId:number;
  public planId: number;
  public planName:string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public  data: Classroom[], 
    @Optional() public dialogRef: MatDialogRef<EditClassesComponent>,
    private classroomService: ClassroomService,
    private alertService: AlertService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public maskService: MaskService,
    public userService: UserService,
    private studyPlanService: StudyPlanService,
    private schoolService: SchoolService) {
      this.classes = this.data;
    }

  ngOnInit(): void {
    this.admUpdate$ = this.adminService.getUpdateSubject()
      .subscribe(() => this.init());
  }

  ngOnDestroy(): void {
    this.admUpdate$.unsubscribe();
  }

  init(){
    this.formEdit = this.formBuilder.group({
      classroomId: [],
      name: [''],
      initDate: [''],
      endDate: [''],
      link: [''],
      recordLink: [''],
      tag: ['', [Validators.minLength(3), Validators.maxLength(3)]]
    });
    this.showButton = false;
  }

  similarClassroom(classroomName:string, classroomId:number, planId:number){
    this.classroomService.similarClassrooms(classroomName, planId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          let classRom = response.data as Classrooms[]; 
            classRom.forEach(element => {
              let notFind = false;
              for(var index = 0, total =this.classroomsArray.length; index < total; index++){
                var obj = this.classroomsArray[index];
                if(obj.id == element.id){
                  notFind = true;       
                }      
              }
              if(notFind == false){
              this.classroomsArray.push({"id":element.id, "name": element.name, "planName": element.planName});
              }
            });
        } else {
          this.alertService.error(response.error);
        }
    }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
  }

  openList(){
    this.open = true;
    this.similarClassroom(this.nameClassroom, this.classroomId, this.planId);
  }

  closeDialogRef(){
    this.dialogRef.close();
  }

  updateClassrooms(){
    this.classroomsArray.forEach(element => {

      this.formEdit.get('classroomId').setValue(element.id);
      const classroom = this.formEdit.getRawValue() ;

      this.classroomService.updateClassrooms(classroom)
        .subscribe(res => {
          const response = res.body as ResponseApi;
          if (!response.error) {
            //this.classrooms = response.data as Classroom[];
            this.alertService.success('Dados Alterados com sucesso!');
            this.dialogRef.close();
          } else {
            this.alertService.error(response.error);
          }
        }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
    }); 
  }

  getSchoolPlans(schoolId: number) {
    this.schoolIdPlan = schoolId;
    // this.loading = true;
    this.studyPlanService.getSelectPlans(schoolId)
      .subscribe(res => {
        const response = res.body as ResponseApi;

        if (!response.error) {
          this.plans = response.data as StudyPlan[];
        } else {
          this.alertService.error(response.error);
        }

        // this.loading = false;
      }, err => {
        this.alertService.error('Houve um erro ao buscar os planos. Verifique a conexão e tente novamente');
        // this.loading = false;
      });
  }

  removeSelectedClass(id: number){
    this.classroomsArray.forEach(element => {
        if(element.id == id){
          let index = this.classroomsArray.indexOf(element);
          this.classroomsArray.splice(index,1);
        }
    }); 
  }

  addClasseId(id:number) {
    let notFind = false;
    let row = Object.values(this.classes);
    for(var index = 0, total =this.classroomsArray.length; index < total; index++){
      var obj = this.classroomsArray[index];
      if(obj.id == id){
        notFind = true;       
      }      
    }
    if(notFind == false){ 
      row.forEach(element => {
        if(id == element.id){
          this.showButton = true;
          this.nameClassroom = element.name;
          this.classroomId = element.id;
          this.planId = element.planId;
          this.planName = element.planName;
        }
      });
      this.classroomsArray.push({"id":id, "name": this.nameClassroom, "planName":this.planName});
      
    }
  }

  initializeEditMode() { 
    this.editMode = true;
    this.formEdit.patchValue(this.data);
  }

  getSchools() {
    this.schoolService.getSchools()
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.schools = response.data as School[];
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as escolas. Verifique a conexão e tente novamente'));
  }

  getClassrooms(planId: number) {
    this.classroomService.getClassRoomsAdmin(planId, this.schoolIdPlan)
      .subscribe(res => {
        const response = res.body as ResponseApi;
        if (!response.error) {
          this.classrooms = response.data as Classroom[];
          this.planIdSelected = planId;
        } else {
          this.alertService.error(response.error);
        }
      }, err => this.alertService.error('Houve um erro ao carregar as salas de aula. Verifique a conexão e tente novamente'));
  }
}
